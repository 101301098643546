//@ts-nocheck
import { Optional } from "@infrastructure";
import { Dictionary } from "lodash";

export enum AadDirectoryApplicationObjectExpirationStatus {
    Expired = "Expired",
    ExpiringSoon = "ExpiringSoon",
    Valid = "Valid"
}

export enum AadDirectoryApplicationServicePrincipalAwsType {
    Saml = "Saml",
    Sso = "Sso"
}

export enum AadDirectoryApplicationType {
    AadAndPersonalAccount = "AadAndPersonalAccount",
    AadMultiTenant = "AadMultiTenant",
    AadSingleTenant = "AadSingleTenant",
    PersonalAccount = "PersonalAccount"
}

export enum AadDirectoryDirectoryRoleAssignmentResourceScopeType {
    AdministrativeUnit = "AdministrativeUnit",
    Application = "Application",
    Directory = "Directory",
    ServicePrincipal = "ServicePrincipal",
    Unknown = "Unknown"
}

export enum AadDirectoryDirectoryRoleAssignmentResourceStatus {
    Active = "Active",
    Eligible = "Eligible"
}

export enum AadDirectoryDirectoryRoleDefinitionType {
    BuiltIn = "BuiltIn",
    Custom = "Custom"
}

export enum AadDirectoryGroupMembershipType {
    Assigned = "Assigned",
    Dynamic = "Dynamic"
}

export enum AadDirectoryGroupType {
    Distribution = "Distribution",
    Microsoft365 = "Microsoft365",
    Microsoft365SecurityEnabled = "Microsoft365SecurityEnabled",
    Security = "Security",
    SecurityDistributionEnabled = "SecurityDistributionEnabled",
    Unknown = "Unknown"
}

export enum AadDirectoryIdentityInactiveRiskModelInfoType {
    SignInNever = "SignInNever",
    SignInTime = "SignInTime"
}

export enum AadDirectoryIdentityInactiveRiskResolutionType {
    Delete = "Delete",
    DeleteDisable = "DeleteDisable"
}

export enum AadDirectoryPrincipalType {
    Application = "Application",
    DirectoryObjectOrGroup = "DirectoryObjectOrGroup",
    DirectoryRoleTemplate = "DirectoryRoleTemplate",
    Everyone = "Everyone",
    ForeignGroup = "ForeignGroup",
    Group = "Group",
    Msi = "Msi",
    ServicePrincipal = "ServicePrincipal",
    Unknown = "Unknown",
    User = "User"
}

export enum AadDirectoryRoleManagementPolicyAssignmentActivationAuthenticationType {
    AadConditionalAccess = "AadConditionalAccess",
    Mfa = "Mfa",
    None = "None"
}

export enum AadDirectoryServicePrincipalType {
    Enterprise = "Enterprise",
    Legacy = "Legacy",
    ManagedIdentity = "ManagedIdentity",
    MicrosoftFirstParty = "MicrosoftFirstParty",
    Other = "Other",
    SocialIdentityProvider = "SocialIdentityProvider"
}

export enum AadDirectoryUserAuthenticationType {
    External = "External",
    Internal = "Internal"
}

export enum AadDirectoryUserGuestUserInvitationStatus {
    Accepted = "Accepted",
    PendingAcceptance = "PendingAcceptance"
}

export enum AadDirectoryUserOnPremisesExtensionAttribute {
    ExtensionAttribute1 = "ExtensionAttribute1",
    ExtensionAttribute2 = "ExtensionAttribute2",
    ExtensionAttribute3 = "ExtensionAttribute3",
    ExtensionAttribute4 = "ExtensionAttribute4",
    ExtensionAttribute5 = "ExtensionAttribute5",
    ExtensionAttribute6 = "ExtensionAttribute6",
    ExtensionAttribute7 = "ExtensionAttribute7",
    ExtensionAttribute8 = "ExtensionAttribute8",
    ExtensionAttribute9 = "ExtensionAttribute9",
    ExtensionAttribute10 = "ExtensionAttribute10",
    ExtensionAttribute11 = "ExtensionAttribute11",
    ExtensionAttribute12 = "ExtensionAttribute12",
    ExtensionAttribute13 = "ExtensionAttribute13",
    ExtensionAttribute14 = "ExtensionAttribute14",
    ExtensionAttribute15 = "ExtensionAttribute15"
}

export enum AadDirectoryUserType {
    Guest = "Guest",
    Member = "Member",
    SelfServiceSignup = "SelfServiceSignup"
}

export enum AadPartitionType {
    China = "China",
    Public = "Public",
    UsGov = "UsGov"
}

export enum AadTenantConfigurationAnalysisSyncType {
    Delta = "Delta",
    Full = "Full"
}

export enum AadTenantConfigurationFederationAwsRoleSessionNameUserProperty {
    JobInfoEmployeeRawId = "JobInfoEmployeeRawId",
    OnPremisesExtensionAttributesMapExtensionAttribute14 = "OnPremisesExtensionAttributesMapExtensionAttribute14"
}

export enum AadTenantEntityExternalUserDefaultRole {
    GuestUser = "GuestUser",
    RestrictedGuestUser = "RestrictedGuestUser",
    User = "User"
}

export enum AadTenantEntityExternalUserInvitationAllowedRoles {
    AdminsAndGuestInviters = "AdminsAndGuestInviters",
    AdminsGuestInvitersAndAllMembers = "AdminsGuestInvitersAndAllMembers",
    Everyone = "Everyone",
    None = "None"
}

export enum AadTenantModelStatus {
    ApplicationServicePrincipalDisabled = "ApplicationServicePrincipalDisabled",
    ApplicationUnauthorized = "ApplicationUnauthorized",
    Deleting = "Deleting",
    TenantNotExist = "TenantNotExist",
    Valid = "Valid"
}

export enum AadTenantStateMonitoringIssue {
    ApplicationServicePrincipalDisabled = "ApplicationServicePrincipalDisabled",
    ApplicationUnauthorized = "ApplicationUnauthorized",
    TenantNotExist = "TenantNotExist"
}

export enum AccessControllerGetAwsFederationEdgeModelPageRequestProperty {
    AssumeRoleActivity = "AssumeRoleActivity",
    DestinationEntityAttributes = "DestinationEntityAttributes",
    DestinationEntityId = "DestinationEntityId",
    DestinationEntityTenantId = "DestinationEntityTenantId",
    FirstSeenTime = "FirstSeenTime",
    GranteeGroupIds = "GranteeGroupIds",
    SourceEntityAttributes = "SourceEntityAttributes",
    SourceEntityGrantee = "SourceEntityGrantee",
    SourceEntityId = "SourceEntityId",
    SourceEntityTenantId = "SourceEntityTenantId",
    SourceEntityTypeName = "SourceEntityTypeName",
    Sso = "Sso",
    Unused = "Unused"
}

export enum AccessControllerGetPermissionsModelEntityType {
    DestinationEntity = "DestinationEntity",
    SourceEntity = "SourceEntity"
}

export enum AccessControllerGetPrincipalModelPageRequestProperty {
    ActionResourceCount = "ActionResourceCount",
    ActionRiskCategories = "ActionRiskCategories",
    ActionServiceCount = "ActionServiceCount",
    ActivityTime = "ActivityTime",
    AwsPermissionSetAccounts = "AwsPermissionSetAccounts",
    AwsPermissionSetPrincipals = "AwsPermissionSetPrincipals",
    EntityAttributes = "EntityAttributes",
    GroupMemberIds = "GroupMemberIds",
    Id = "Id",
    Inactive = "Inactive",
    OpenRiskedEntityRiskCount = "OpenRiskedEntityRiskCount",
    ServiceIdentityOriginatorEntities = "ServiceIdentityOriginatorEntities",
    ServiceIdentityOriginatorEntityTenantId = "ServiceIdentityOriginatorEntityTenantId",
    ServiceIdentityOriginatorEntityTypeNames = "ServiceIdentityOriginatorEntityTypeNames",
    Severity = "Severity",
    TenantId = "TenantId",
    TenantType = "TenantType",
    TypeNames = "TypeNames",
    Vendors = "Vendors"
}

export enum AccessControllerGetPrincipalSummaryRequestType {
    ExcessivePermissions = "ExcessivePermissions",
    Permissions = "Permissions"
}

export enum AccessControllerGetPrincipalSummaryResponseResult {
    Analyzing = "Analyzing",
    Empty = "Empty",
    HasData = "HasData"
}

export enum AccessGraphPermissionPathActionsExcessiveness {
    All = "All",
    None = "None",
    Some = "Some"
}

export enum AccessLevel {
    Internal = "Internal",
    CrossTenant = "CrossTenant",
    External = "External",
    Public = "Public"
}

export enum ActionRiskCategory {
    DataLeakage = "DataLeakage",
    InfrastructureModification = "InfrastructureModification",
    PermissionManagement = "PermissionManagement",
    PrivilegeEscalation = "PrivilegeEscalation",
    Reconnaissance = "Reconnaissance"
}

export enum ActivityTimeType {
    Unknown = "Unknown",
    OlderThan = "OlderThan",
    Exact = "Exact",
    NewerThan = "NewerThan"
}

export enum AdministrationControllerOrchestrationStageStatus {
    Completed = "Completed",
    NotApplicable = "NotApplicable",
    NotStarted = "NotStarted",
    Running = "Running"
}

export enum AdministrationControllerOrchestrationStageType {
    Customer = "Customer",
    Root = "Root",
    Stages = "Stages",
    Tenant = "Tenant"
}

export enum AdministrationControllerResetCognitoUserPasswordError {
    UserMailInvalid = "UserMailInvalid"
}

export enum AdministrationControllerUpdateAuthenticationConfigurationError {
    AuthenticationDomainNameExists = "AuthenticationDomainNameExists"
}

export enum AdministrationControllerUpsertSupportPrincipalError {
    UserMailDomainInvalid = "UserMailDomainInvalid"
}

export enum ApplicationCustomerConfigurationAuthenticationSamlIdentityProviderType {
    Aad = "Aad",
    AwsSso = "AwsSso",
    Duo = "Duo",
    Gci = "Gci",
    Okta = "Okta",
    OneLogin = "OneLogin",
    Other = "Other",
    PingIdentity = "PingIdentity"
}

export enum ApplicationCustomerConfigurationLicensingLicenseType {
    PermissionManagement = "PermissionManagement",
    Ciem = "Ciem",
    Cspm = "Cspm",
    Cnapp = "Cnapp",
    CnappEnterprise = "CnappEnterprise"
}

export enum ApplicationCustomerConfigurationLicensingLicenseVersion {
    Version1 = "Version1",
    Version2 = "Version2"
}

export enum AuditEventControllerGetAuditEventModelPageRequestProperty {
    PermissionEligibilityAuditEventApproverPrincipals = "PermissionEligibilityAuditEventApproverPrincipals",
    PermissionEligibilityAuditEventGranteePrincipal = "PermissionEligibilityAuditEventGranteePrincipal",
    PermissionEligibilityAuditEventGroupMembershipEligibilityGroups = "PermissionEligibilityAuditEventGroupMembershipEligibilityGroups",
    PermissionEligibilityAuditEventName = "PermissionEligibilityAuditEventName",
    PermissionEligibilityAuditEventPermissionAssignmentEligibilityPermitters = "PermissionEligibilityAuditEventPermissionAssignmentEligibilityPermitters",
    PermissionRequestAuditEventApproverUsers = "PermissionRequestAuditEventApproverUsers",
    PermissionRequestAuditEventGranteeUser = "PermissionRequestAuditEventGranteeUser",
    SystemCreationTime = "SystemCreationTime"
}

export enum AuthenticationControllerSignInCognitoUserResponseResult {
    CustomerMaintenance = "CustomerMaintenance",
    FailureUnauthenticated = "FailureUnauthenticated",
    FailureUnauthorized = "FailureUnauthorized",
    Success = "Success",
    SuccessChangePasswordRequired = "SuccessChangePasswordRequired"
}

export enum AuthenticationControllerTeamsSignInError {
    FailureNotEnabled = "FailureNotEnabled",
    FailureOrganizationNotExist = "FailureOrganizationNotExist",
    FailureUnauthenticated = "FailureUnauthenticated",
    FailureUnauthorized = "FailureUnauthorized"
}

export enum AutomaticCustomEntityAttributeDefinitionTemplateConfigurationType {
    ResourceTag = "ResourceTag",
    ResourceTagKey = "ResourceTagKey",
    ResourceTagPattern = "ResourceTagPattern"
}

export enum AwsAccessAnalyzerAnalyzerStatus {
    Active = "Active",
    Creating = "Creating",
    Disabled = "Disabled",
    Failed = "Failed"
}

export enum AwsAccessAnalyzerAnalyzerType {
    ExternalAccessAccount = "ExternalAccessAccount",
    ExternalAccessOrganization = "ExternalAccessOrganization",
    UnusedAccessAccount = "UnusedAccessAccount",
    UnusedAccessOrganization = "UnusedAccessOrganization"
}

export enum AwsAcmCertificateKeyType {
    Ecdsa256 = "Ecdsa256",
    Ecdsa384 = "Ecdsa384",
    Ecdsa521 = "Ecdsa521",
    Rsa1024 = "Rsa1024",
    Rsa2048 = "Rsa2048",
    Rsa3072 = "Rsa3072",
    Rsa4096 = "Rsa4096"
}

export enum AwsAcmCertificateRenewalEligibility {
    Eligible = "Eligible",
    Ineligible = "Ineligible"
}

export enum AwsAcmCertificateStatus {
    Expired = "Expired",
    Failed = "Failed",
    Inactive = "Inactive",
    Issued = "Issued",
    PendingValidation = "PendingValidation",
    Revoked = "Revoked",
    ValidationTimedOut = "ValidationTimedOut"
}

export enum AwsAcmCertificateType {
    AwsIssued = "AwsIssued",
    Imported = "Imported",
    Private = "Private"
}

export enum AwsApiGatewayApiEndpointType {
    EdgeOptimized = "EdgeOptimized",
    Private = "Private",
    Regional = "Regional"
}

export enum AwsApiGatewayApiRouteMethod {
    Any = "Any",
    Delete = "Delete",
    Get = "Get",
    Head = "Head",
    Options = "Options",
    Patch = "Patch",
    Post = "Post",
    Put = "Put"
}

export enum AwsApiGatewayApiType {
    Http = "Http",
    Rest = "Rest",
    Websocket = "Websocket"
}

export enum AwsApiGatewayV1ApiAuthorizationType {
    Cognito = "Cognito",
    Iam = "Iam",
    Lambda = "Lambda",
    None = "None"
}

export enum AwsApiGatewayV1ApiRouteIntegrationType {
    Http = "Http",
    Lambda = "Lambda",
    Mock = "Mock",
    Service = "Service",
    Vpc = "Vpc"
}

export enum AwsApiGatewayV2ApiAuthorizationType {
    Iam = "Iam",
    Jwt = "Jwt",
    Lambda = "Lambda",
    None = "None"
}

export enum AwsApiGatewayV2ApiRouteIntegrationType {
    AppConfig = "AppConfig",
    Elb = "Elb",
    EventBridge = "EventBridge",
    Http = "Http",
    Kinesis = "Kinesis",
    Lambda = "Lambda",
    Mock = "Mock",
    Service = "Service",
    ServiceDiscovery = "ServiceDiscovery",
    Sqs = "Sqs",
    States = "States",
    Vpc = "Vpc"
}

export enum AwsBatchComputeEnvironmentType {
    Managed = "Managed",
    Unmanaged = "Unmanaged"
}

export enum AwsBedrockAgentStatus {
    Creating = "Creating",
    Deleting = "Deleting",
    Dissociated = "Dissociated",
    Failed = "Failed",
    NotPrepared = "NotPrepared",
    Prepared = "Prepared",
    Preparing = "Preparing",
    Updating = "Updating",
    Versioning = "Versioning"
}

export enum AwsBedrockCustomModelCustomizationType {
    ContinuedPreTraining = "ContinuedPreTraining",
    FineTuning = "FineTuning"
}

export enum AwsBehaviorIdentityRiskModelInfo {
    EventActions = "EventActions",
    IpAddresses = "IpAddresses",
    PreviousBehaviorDateEver = "PreviousBehaviorDateEver",
    PreviousBehaviorDateNever = "PreviousBehaviorDateNever",
    Resources = "Resources"
}

export enum AwsCloudFormationStackDriftStatus {
    Drifted = "Drifted",
    InSync = "InSync",
    NotChecked = "NotChecked",
    Unknown = "Unknown"
}

export enum AwsCloudFormationStackStatus {
    CreateComplete = "CreateComplete",
    CreateFailed = "CreateFailed",
    CreateInProgress = "CreateInProgress",
    DeleteComplete = "DeleteComplete",
    DeleteFailed = "DeleteFailed",
    DeleteInProgress = "DeleteInProgress",
    ImportComplete = "ImportComplete",
    ImportInProgress = "ImportInProgress",
    ImportRollbackComplete = "ImportRollbackComplete",
    ImportRollbackFailed = "ImportRollbackFailed",
    ImportRollbackInProgress = "ImportRollbackInProgress",
    ReviewInProgress = "ReviewInProgress",
    RollbackComplete = "RollbackComplete",
    RollbackFailed = "RollbackFailed",
    RollbackInProgress = "RollbackInProgress",
    UpdateComplete = "UpdateComplete",
    UpdateCompleteCleanupInProgress = "UpdateCompleteCleanupInProgress",
    UpdateFailed = "UpdateFailed",
    UpdateInProgress = "UpdateInProgress",
    UpdateRollbackComplete = "UpdateRollbackComplete",
    UpdateRollbackCompleteCleanupInProgress = "UpdateRollbackCompleteCleanupInProgress",
    UpdateRollbackFailed = "UpdateRollbackFailed",
    UpdateRollbackInProgress = "UpdateRollbackInProgress"
}

export enum AwsCloudFrontDistributionBehaviorViewerProtocolPolicy {
    AllowAll = "AllowAll",
    HttpsOnly = "HttpsOnly",
    RedirectToHttps = "RedirectToHttps"
}

export enum AwsCloudFrontDistributionOriginAccess {
    OriginAccessControl = "OriginAccessControl",
    OriginAccessIdentities = "OriginAccessIdentities",
    Public = "Public"
}

export enum AwsCloudFrontDistributionOriginProtocolPolicy {
    HttpOnly = "HttpOnly",
    HttpsOnly = "HttpsOnly",
    MatchViewer = "MatchViewer"
}

export enum AwsCloudFrontDistributionOriginSslProtocol {
    SslV3 = "SslV3",
    TlsV1 = "TlsV1",
    TlsV11 = "TlsV11",
    TlsV12 = "TlsV12"
}

export enum AwsCloudFrontDistributionOriginType {
    Custom = "Custom",
    S3 = "S3"
}

export enum AwsCloudFrontDistributionStatus {
    Disabled = "Disabled",
    Enabled = "Enabled"
}

export enum AwsCloudTrailTrailAdvancedEventSelectorFieldSelectorOperator {
    EndsWith = "EndsWith",
    Equals = "Equals",
    NotEndsWith = "NotEndsWith",
    NotEquals = "NotEquals",
    NotStartsWith = "NotStartsWith",
    StartsWith = "StartsWith"
}

export enum AwsCloudTrailTrailAdvancedEventSelectorFieldSelectorType {
    Category = "Category",
    ErrorCode = "ErrorCode",
    Name = "Name",
    ReadOnly = "ReadOnly",
    ResourceArn = "ResourceArn",
    ResourceType = "ResourceType",
    SessionCredentialFromConsole = "SessionCredentialFromConsole",
    Source = "Source",
    Type = "Type",
    UserIdentityArn = "UserIdentityArn",
    VpcEndpointId = "VpcEndpointId"
}

export enum AwsCloudTrailTrailEventCategory {
    LambdaFunctionConfigurationInvoke = "LambdaFunctionConfigurationInvoke",
    Management = "Management",
    S3Object = "S3Object"
}

export enum AwsCloudTrailTrailEventSelectorType {
    All = "All",
    Read = "Read",
    Write = "Write"
}

export enum AwsCloudWatchAlarmNotExistRiskRequirement {
    CloudTrailValidTrailExists = "CloudTrailValidTrailExists",
    CloudWatchAlarmExists = "CloudWatchAlarmExists",
    CloudWatchLogGroupExists = "CloudWatchLogGroupExists",
    CloudWatchLogGroupMetricFilterExists = "CloudWatchLogGroupMetricFilterExists",
    SnsTopicForAlarmExists = "SnsTopicForAlarmExists"
}

export enum AwsConfigConfigurationRecorderStatus {
    Failure = "Failure",
    Pending = "Pending",
    Success = "Success"
}

export enum AwsConsoleView {
    Acm = "Acm",
    ApiGateway = "ApiGateway",
    AthenaWorkgroup = "AthenaWorkgroup",
    CloudFormation = "CloudFormation",
    CloudFront = "CloudFront",
    CloudTrail = "CloudTrail",
    CloudWatch = "CloudWatch",
    Config = "Config",
    DocDb = "DocDb",
    DynamoDb = "DynamoDb",
    Ec2 = "Ec2",
    Ecr = "Ecr",
    Ecs = "Ecs",
    Eks = "Eks",
    ElastiCache = "ElastiCache",
    ElasticBeanstalk = "ElasticBeanstalk",
    ElasticFileSystem = "ElasticFileSystem",
    Emr = "Emr",
    Es = "Es",
    Firehose = "Firehose",
    Iam = "Iam",
    Kinesis = "Kinesis",
    Kms = "Kms",
    Lambda = "Lambda",
    Neptune = "Neptune",
    Rds = "Rds",
    Redshift = "Redshift",
    ResourceGroups = "ResourceGroups",
    Route53 = "Route53",
    S3 = "S3",
    SageMaker = "SageMaker",
    SecretsManager = "SecretsManager",
    SecurityGroups = "SecurityGroups",
    SecurityHub = "SecurityHub",
    Sns = "Sns",
    Sqs = "Sqs",
    Ssm = "Ssm",
    Sso = "Sso",
    Subnets = "Subnets",
    Timestream = "Timestream",
    Vpc = "Vpc"
}

export enum AwsDataLeakageRiskModelDescriptionType {
    PreviousBehaviorDateEver = "PreviousBehaviorDateEver",
    PreviousBehaviorDateNever = "PreviousBehaviorDateNever"
}

export enum AwsDocDbClusterModelType {
    Elastic = "Elastic",
    Instance = "Instance"
}

export enum AwsEc2DeviceType {
    Ebs = "Ebs",
    InstanceStorage = "InstanceStorage"
}

export enum AwsEc2FlowLogFilter {
    Accept = "Accept",
    All = "All",
    Reject = "Reject"
}

export enum AwsEc2InstanceHypervisor {
    Nitro = "Nitro",
    Xen = "Xen"
}

export enum AwsEc2InstanceMetadataServiceVersion {
    V1 = "V1",
    V2 = "V2"
}

export enum AwsEc2InstanceStatus {
    Pending = "Pending",
    Running = "Running",
    ShuttingDown = "ShuttingDown",
    Stopped = "Stopped",
    Stopping = "Stopping",
    Terminated = "Terminated"
}

export enum AwsEc2InstanceUserDataSecretExistsRiskInfoType {
    AutoScalingGroups = "AutoScalingGroups",
    GroupTags = "GroupTags",
    InstanceNotExists = "InstanceNotExists",
    InstancePublicAccess = "InstancePublicAccess",
    InstancePublicAccessNotExists = "InstancePublicAccessNotExists",
    Instances = "Instances",
    Karpenter = "Karpenter",
    NotSensitive = "NotSensitive",
    Permissions = "Permissions",
    RunningInstanceNotExists = "RunningInstanceNotExists",
    RunningInstances = "RunningInstances",
    Sensitive = "Sensitive",
    SensitiveAutoScalingGroups = "SensitiveAutoScalingGroups",
    SensitiveInstances = "SensitiveInstances"
}

export enum AwsEc2LaunchTemplateRevisionIdType {
    Default = "Default",
    Exact = "Exact",
    Latest = "Latest"
}

export enum AwsEc2NetworkAclRuleAction {
    Allow = "Allow",
    Deny = "Deny"
}

export enum AwsEc2SecurityGroupManagementRiskModelDescriptionType {
    EgressRule = "EgressRule",
    EgressRuleIngressRule = "EgressRuleIngressRule",
    IngressRule = "IngressRule"
}

export enum AwsEc2SecurityGroupUnusedRiskResourceAttachInfo {
    Ever = "Ever",
    Never = "Never",
    Unknown = "Unknown"
}

export enum AwsEc2VpcBlockPublicAccessPolicyNetworkDirection {
    All = "All",
    Inbound = "Inbound",
    None = "None",
    Outbound = "Outbound"
}

export enum AwsEc2VpcEndpointStatus {
    Available = "Available",
    Deleted = "Deleted",
    Deleting = "Deleting",
    Expired = "Expired",
    Failed = "Failed",
    Pending = "Pending",
    PendingAcceptance = "PendingAcceptance",
    Rejected = "Rejected"
}

export enum AwsEc2VpcEndpointType {
    Gateway = "Gateway",
    GatewayLoadBalancer = "GatewayLoadBalancer",
    Interface = "Interface",
    Resource = "Resource",
    ServiceNetwork = "ServiceNetwork"
}

export enum AwsEc2VpcPeeringConnectionStatus {
    Active = "Active",
    Deleted = "Deleted",
    Deleting = "Deleting",
    Expired = "Expired",
    Failed = "Failed",
    InitiatingRequest = "InitiatingRequest",
    PendingAcceptance = "PendingAcceptance",
    Provisioning = "Provisioning",
    Rejected = "Rejected"
}

export enum AwsEcrRepositoryEncryptionType {
    Aes256 = "Aes256",
    Kms = "Kms",
    KmsDualLayer = "KmsDualLayer"
}

export enum AwsEcrRepositoryScanningConfiguration {
    Basic = "Basic",
    Enhanced = "Enhanced",
    Native = "Native",
    Unknown = "Unknown"
}

export enum AwsEcsServiceLaunchType {
    Ec2 = "Ec2",
    External = "External",
    Fargate = "Fargate"
}

export enum AwsEcsTaskDefinitionRevisionNetworkMode {
    Bridge = "Bridge",
    Default = "Default",
    Host = "Host",
    None = "None",
    Vpc = "Vpc"
}

export enum AwsEcsTaskDefinitionStatus {
    Active = "Active",
    Inactive = "Inactive"
}

export enum AwsEksClusterNodeGroupStatus {
    Active = "Active",
    CreateFailed = "CreateFailed",
    Creating = "Creating",
    Degraded = "Degraded",
    DeleteFailed = "DeleteFailed",
    Deleting = "Deleting",
    Updating = "Updating"
}

export enum AwsEksClusterStatus {
    Active = "Active",
    Creating = "Creating",
    Deleting = "Deleting",
    Failed = "Failed",
    Pending = "Pending",
    Updating = "Updating"
}

export enum AwsEksFargateProfileStatus {
    Active = "Active",
    CreateFailed = "CreateFailed",
    Creating = "Creating",
    DeleteFailed = "DeleteFailed",
    Deleting = "Deleting"
}

export enum AwsElastiCacheCacheAutoFailover {
    Disabled = "Disabled",
    Disabling = "Disabling",
    Enabled = "Enabled",
    Enabling = "Enabling"
}

export enum AwsElastiCacheCacheDeploymentType {
    Cluster = "Cluster",
    Serverless = "Serverless"
}

export enum AwsElastiCacheCacheStatus {
    Available = "Available",
    CreateFailed = "CreateFailed",
    Creating = "Creating",
    Deleting = "Deleting",
    Modifying = "Modifying",
    Snapshotting = "Snapshotting"
}

export enum AwsElastiCacheReplicationGroupClusterMode {
    Compatible = "Compatible",
    Disabled = "Disabled",
    Enabled = "Enabled"
}

export enum AwsElastiCacheReplicationGroupNetworkType {
    IpV4 = "IpV4",
    IpV6 = "IpV6"
}

export enum AwsElastiCacheReplicationGroupTransitEncryptionMode {
    Preferred = "Preferred",
    Required = "Required"
}

export enum AwsElasticBeanstalkEnvironmentTier {
    WebServer = "WebServer",
    Worker = "Worker"
}

export enum AwsElbLoadBalancerModelType {
    Application = "Application",
    Classic = "Classic",
    Network = "Network"
}

export enum AwsElbProtocol {
    Geneve = "Geneve",
    Http = "Http",
    Https = "Https",
    Tcp = "Tcp",
    TcpUdp = "TcpUdp",
    Tls = "Tls",
    Udp = "Udp"
}

export enum AwsElbTargetGroupType {
    ApplicationLoadBalancer = "ApplicationLoadBalancer",
    FunctionConfiguration = "FunctionConfiguration",
    Instance = "Instance",
    IpAddress = "IpAddress"
}

export enum AwsEmrClusterInstanceCollectionType {
    Core = "Core",
    Master = "Master",
    Task = "Task",
    Unknown = "Unknown"
}

export enum AwsEmrClusterStatus {
    Bootstrapping = "Bootstrapping",
    Running = "Running",
    Starting = "Starting",
    Terminated = "Terminated",
    TerminatedWithErrors = "TerminatedWithErrors",
    Terminating = "Terminating",
    Waiting = "Waiting"
}

export enum AwsEsDomainEngineType {
    Elasticsearch = "Elasticsearch",
    OpenSearch = "OpenSearch"
}

export enum AwsEventProcessResult {
    Failed = "Failed",
    NotProcessed = "NotProcessed",
    ProcessedDefault = "ProcessedDefault",
    ProcessedSpecific = "ProcessedSpecific"
}

export enum AwsExcessivePermissionIamPrincipalRiskModelInfoType {
    CreationTime = "CreationTime",
    CreationTimeAndActivityTime = "CreationTimeAndActivityTime",
    CreationTimeAndCreator = "CreationTimeAndCreator",
    CreationTimeAndCreatorAndActivityTime = "CreationTimeAndCreatorAndActivityTime",
    GroupUsers = "GroupUsers",
    OriginatorResources = "OriginatorResources",
    Policies = "Policies",
    RoleAssumingEntitiesResolved = "RoleAssumingEntitiesResolved",
    RoleAssumingEntitiesUnresolved = "RoleAssumingEntitiesUnresolved",
    SensitiveResourcesSensitiveExcessivePermissionActionsAny = "SensitiveResourcesSensitiveExcessivePermissionActionsAny",
    ServiceLevelManyExcessivePermissionServicesAll = "ServiceLevelManyExcessivePermissionServicesAll",
    ServiceLevelManyExcessivePermissionServicesSome = "ServiceLevelManyExcessivePermissionServicesSome",
    ServiceLevelSingle = "ServiceLevelSingle",
    ServiceResourceLevel = "ServiceResourceLevel",
    ServiceResourceLevelNotUsedPermissionActionResourcesAndUsedResources = "ServiceResourceLevelNotUsedPermissionActionResourcesAndUsedResources",
    ServiceResourceLevelNotUsedPermissionActions = "ServiceResourceLevelNotUsedPermissionActions",
    ServiceResourceLevelNotUsedResources = "ServiceResourceLevelNotUsedResources",
    ServiceResourceLevelNotUsedResourcesAndNotUsedPermissionActions = "ServiceResourceLevelNotUsedResourcesAndNotUsedPermissionActions",
    ServiceResourceLevelNotUsedResourcesAndUsedResources = "ServiceResourceLevelNotUsedResourcesAndUsedResources",
    ServiceResourceLevelNotUsedResourcesAndUsedResourcesAndNotUsedPermissionActions = "ServiceResourceLevelNotUsedResourcesAndUsedResourcesAndNotUsedPermissionActions",
    UserAccessKeys = "UserAccessKeys",
    UserAccessKeysAndPassword = "UserAccessKeysAndPassword",
    UserExcessiveGroupMembershipGroupIds = "UserExcessiveGroupMembershipGroupIds",
    UserMfaDisabled = "UserMfaDisabled",
    UserMfaEnabled = "UserMfaEnabled",
    UserPassword = "UserPassword"
}

export enum AwsExcessivePermissionPermissionSetRiskModelDescriptionType {
    ExcessivePermissionAccountsAll = "ExcessivePermissionAccountsAll",
    ExcessivePermissionAccountsSome = "ExcessivePermissionAccountsSome",
    ServiceLevelExcessivePermissionServicesAllAndExcessivePermissionAccountsSome = "ServiceLevelExcessivePermissionServicesAllAndExcessivePermissionAccountsSome",
    ServiceLevelExcessivePermissionServicesSome = "ServiceLevelExcessivePermissionServicesSome",
    ServiceLevelExcessivePermissionServicesSomeAndExcessivePermissionAccountsSome = "ServiceLevelExcessivePermissionServicesSomeAndExcessivePermissionAccountsSome",
    ServiceResourceLevelAndExcessivePermissionAccountsSome = "ServiceResourceLevelAndExcessivePermissionAccountsSome",
    ServiceResourceLevels = "ServiceResourceLevels"
}

export enum AwsExcessivePermissionPermissionSetRiskModelInfo {
    Assignments = "Assignments",
    ExcessivePermissionAccounts = "ExcessivePermissionAccounts",
    InactiveUsedEver = "InactiveUsedEver",
    InactiveUsedNever = "InactiveUsedNever",
    InlinePolicy = "InlinePolicy",
    InlinePolicyAndManagedPolicy = "InlinePolicyAndManagedPolicy",
    ManagedPolicy = "ManagedPolicy",
    SensitiveResourcesSensitiveExcessivePermissionActionsAny = "SensitiveResourcesSensitiveExcessivePermissionActionsAny",
    ServiceLevelExcessivePermissionServicesAllUsedEver = "ServiceLevelExcessivePermissionServicesAllUsedEver",
    ServiceLevelExcessivePermissionServicesAllUsedNever = "ServiceLevelExcessivePermissionServicesAllUsedNever",
    ServiceLevelExcessivePermissionServicesSomeUsedEver = "ServiceLevelExcessivePermissionServicesSomeUsedEver",
    ServiceLevelExcessivePermissionServicesSomeUsedEverAndServiceResourceLevel = "ServiceLevelExcessivePermissionServicesSomeUsedEverAndServiceResourceLevel",
    ServiceLevelExcessivePermissionServicesSomeUsedNever = "ServiceLevelExcessivePermissionServicesSomeUsedNever",
    ServiceLevelExcessivePermissionServicesSomeUsedNeverAndServiceResourceLevel = "ServiceLevelExcessivePermissionServicesSomeUsedNeverAndServiceResourceLevel",
    ServiceResourceLevels = "ServiceResourceLevels",
    UnknownAccounts = "UnknownAccounts"
}

export enum AwsExcessivePermissionResourceRiskAccessType {
    AnonymousIdentityWithoutStatementCondition = "AnonymousIdentityWithoutStatementCondition",
    AnonymousIdentityWithStatementCondition = "AnonymousIdentityWithStatementCondition",
    InternalTenant = "InternalTenant",
    KnownExternalTenant = "KnownExternalTenant",
    UnknownExternalTenant = "UnknownExternalTenant"
}

export enum AwsExcessivePermissionResourceRiskModelDescriptionType {
    AnonymousIdentityWithoutStatementCondition = "AnonymousIdentityWithoutStatementCondition",
    AnonymousIdentityWithStatementCondition = "AnonymousIdentityWithStatementCondition",
    InternalTenant = "InternalTenant",
    KnownExternalTenant = "KnownExternalTenant",
    UnknownExternalTenant = "UnknownExternalTenant"
}

export enum AwsExcessivePermissionResourceRiskModelInfoType {
    AccessTypeHighSeverityPermissionActionsAll = "AccessTypeHighSeverityPermissionActionsAll",
    AccessTypeHighSeverityPermissionActionsSomeMany = "AccessTypeHighSeverityPermissionActionsSomeMany",
    AccessTypeHighSeverityPermissionActionsSomeSingle = "AccessTypeHighSeverityPermissionActionsSomeSingle",
    AccessTypePermissionActions = "AccessTypePermissionActions",
    AnonymousIdentityPermissionActionUnusedAll = "AnonymousIdentityPermissionActionUnusedAll",
    AnonymousIdentityPermissionActionUnusedSome = "AnonymousIdentityPermissionActionUnusedSome",
    ExternalTenantPermissionActionUnusedSome = "ExternalTenantPermissionActionUnusedSome",
    InternalTenantPermissionActionUnusedAll = "InternalTenantPermissionActionUnusedAll",
    InternalTenantPermissionActionUnusedSome = "InternalTenantPermissionActionUnusedSome",
    PermissionActionUnusedAll = "PermissionActionUnusedAll",
    PermissionActionUnusedSome = "PermissionActionUnusedSome",
    Resource = "Resource",
    TenantS3ObjectEventsNotSynced = "TenantS3ObjectEventsNotSynced"
}

export enum AwsExcessivePermissionRoleGroupRiskModelInfoType {
    Accounts = "Accounts",
    ActivityTime = "ActivityTime",
    ExcessivePermissionActionSeverity = "ExcessivePermissionActionSeverity",
    OldestRoleCreationTime = "OldestRoleCreationTime",
    Policies = "Policies",
    Public = "Public",
    PublicOriginatorResources = "PublicOriginatorResources",
    SensitiveResourcesSensitiveExcessivePermissionActionsAny = "SensitiveResourcesSensitiveExcessivePermissionActionsAny",
    ServiceLevelManyExcessivePermissionServicesAll = "ServiceLevelManyExcessivePermissionServicesAll",
    ServiceLevelManyExcessivePermissionServicesSome = "ServiceLevelManyExcessivePermissionServicesSome",
    ServiceLevelSingle = "ServiceLevelSingle",
    ServiceResourceLevel = "ServiceResourceLevel",
    ServiceResourceLevelNotUsedPermissionActionResourcesAndUsedResources = "ServiceResourceLevelNotUsedPermissionActionResourcesAndUsedResources",
    ServiceResourceLevelNotUsedPermissionActions = "ServiceResourceLevelNotUsedPermissionActions",
    ServiceResourceLevelNotUsedResources = "ServiceResourceLevelNotUsedResources",
    ServiceResourceLevelNotUsedResourcesAndNotUsedPermissionActions = "ServiceResourceLevelNotUsedResourcesAndNotUsedPermissionActions",
    ServiceResourceLevelNotUsedResourcesAndUsedResources = "ServiceResourceLevelNotUsedResourcesAndUsedResources",
    ServiceResourceLevelNotUsedResourcesAndUsedResourcesAndNotUsedPermissionActions = "ServiceResourceLevelNotUsedResourcesAndUsedResourcesAndNotUsedPermissionActions",
    UnknownAccounts = "UnknownAccounts",
    Vendor = "Vendor",
    VulnerableOriginatorWorkloadResources = "VulnerableOriginatorWorkloadResources"
}

export enum AwsFirehoseDeliveryStreamEncryptionKeyType {
    AwsOwnedCmk = "AwsOwnedCmk",
    CustomerManagedCmk = "CustomerManagedCmk"
}

export enum AwsFirehoseDeliveryStreamStatus {
    Active = "Active",
    Creating = "Creating",
    CreatingFailed = "CreatingFailed",
    Deleting = "Deleting",
    DeletingFailed = "DeletingFailed",
    Suspended = "Suspended"
}

export enum AwsFirehoseDeliveryStreamType {
    DatabaseAsSource = "DatabaseAsSource",
    DirectPut = "DirectPut",
    KinesisStreamAsSource = "KinesisStreamAsSource",
    MskAsSource = "MskAsSource"
}

export enum AwsIamIdentityInactiveRiskModelInfoType {
    ActiveNever = "ActiveNever",
    ActivityTime = "ActivityTime"
}

export enum AwsIamPolicyDocumentStatementConditionOperator {
    ArnEquals = "ArnEquals",
    ArnLike = "ArnLike",
    ArnNotEquals = "ArnNotEquals",
    ArnNotLike = "ArnNotLike",
    BinaryEquals = "BinaryEquals",
    Bool = "Bool",
    DateEquals = "DateEquals",
    DateGreaterThan = "DateGreaterThan",
    DateGreaterThanEquals = "DateGreaterThanEquals",
    DateLessThan = "DateLessThan",
    DateLessThanEquals = "DateLessThanEquals",
    DateNotEquals = "DateNotEquals",
    IpAddress = "IpAddress",
    NotIpAddress = "NotIpAddress",
    Null = "Null",
    NumericEquals = "NumericEquals",
    NumericGreaterThan = "NumericGreaterThan",
    NumericGreaterThanEquals = "NumericGreaterThanEquals",
    NumericLessThan = "NumericLessThan",
    NumericLessThanEquals = "NumericLessThanEquals",
    NumericNotEquals = "NumericNotEquals",
    StringEquals = "StringEquals",
    StringEqualsIgnoreCase = "StringEqualsIgnoreCase",
    StringLike = "StringLike",
    StringNotEquals = "StringNotEquals",
    StringNotEqualsIgnoreCase = "StringNotEqualsIgnoreCase",
    StringNotLike = "StringNotLike"
}

export enum AwsIamPolicyDocumentStatementConditionOperatorPostfix {
    IfExists = "IfExists"
}

export enum AwsIamPolicyDocumentStatementConditionOperatorPrefix {
    ForAllValues = "ForAllValues",
    ForAnyValue = "ForAnyValue"
}

export enum AwsIamPolicyDocumentStatementEffect {
    Allow = "Allow",
    Deny = "Deny"
}

export enum AwsIamPrincipalPolicyModelManagement {
    AwsManaged = "AwsManaged",
    CustomerManaged = "CustomerManaged",
    Inline = "Inline"
}

export enum AwsIamRoleModelAssumeRoleGranteeType {
    Identity = "Identity",
    IdentityProvider = "IdentityProvider",
    Service = "Service",
    Tenant = "Tenant"
}

export enum AwsInboundExternalEc2InstanceRiskInfoType {
    AutoScalingGroups = "AutoScalingGroups",
    GroupTags = "GroupTags",
    InstanceHighSeverityRolePermissions = "InstanceHighSeverityRolePermissions",
    InstanceNotExists = "InstanceNotExists",
    InstanceRoleNotExists = "InstanceRoleNotExists",
    Instances = "Instances",
    NotSensitive = "NotSensitive",
    RunningInstanceNotExists = "RunningInstanceNotExists",
    RunningInstances = "RunningInstances",
    Sensitive = "Sensitive",
    SensitiveAutoScalingGroups = "SensitiveAutoScalingGroups",
    SensitiveInstances = "SensitiveInstances"
}

export enum AwsKinesisStreamMode {
    OnDemand = "OnDemand",
    Provisioned = "Provisioned"
}

export enum AwsKmsKeyManagementRiskModelDescriptionType {
    Grant = "Grant",
    GrantPolicy = "GrantPolicy",
    Policy = "Policy"
}

export enum AwsLambdaFunctionConfigurationModelAccessType {
    ApiGatewayApi = "ApiGatewayApi",
    FunctionUrl = "FunctionUrl",
    PolicyDocument = "PolicyDocument"
}

export enum AwsLambdaFunctionConfigurationPackageType {
    Image = "Image",
    Zip = "Zip"
}

export enum AwsLambdaFunctionConfigurationTriggersExternalTriggerType {
    Alexa = "Alexa",
    Kafka = "Kafka"
}

export enum AwsOrganizationsAccountOrganizationJoinMethod {
    Created = "Created",
    Invited = "Invited"
}

export enum AwsOrganizationsAccountStatus {
    Active = "Active",
    Pending = "Pending",
    Suspended = "Suspended"
}

export enum AwsOrganizationsPolicyType {
    ResourceControlPolicy = "ResourceControlPolicy",
    ServiceControlPolicy = "ServiceControlPolicy"
}

export enum AwsPartitionType {
    China = "China",
    EuIsoE = "EuIsoE",
    Public = "Public",
    UsGov = "UsGov",
    UsIsoA = "UsIsoA",
    UsIsoB = "UsIsoB",
    UsIsoF = "UsIsoF"
}

export enum AwsPermissionEdgeActionConditionType {
    PermissionBoundaryPolicyAllow = "PermissionBoundaryPolicyAllow",
    PermissionBoundaryPolicyDeny = "PermissionBoundaryPolicyDeny",
    PrincipalPolicyAllow = "PrincipalPolicyAllow",
    PrincipalPolicyDeny = "PrincipalPolicyDeny",
    ResourceControlPolicyDeny = "ResourceControlPolicyDeny",
    ResourcePolicyAllow = "ResourcePolicyAllow",
    ResourcePolicyDeny = "ResourcePolicyDeny",
    ServiceControlPolicyAllow = "ServiceControlPolicyAllow",
    ServiceControlPolicyDeny = "ServiceControlPolicyDeny"
}

export enum AwsPermissionManagementRiskModelDescriptionType {
    ResourcesNone = "ResourcesNone",
    ResourcesSome = "ResourcesSome"
}

export enum AwsPrivilegeEscalationRiskModelDescriptionType {
    AccessKeyUsers = "AccessKeyUsers",
    AssumedRoles = "AssumedRoles",
    AssumedRolesAndAccessKeyUsers = "AssumedRolesAndAccessKeyUsers",
    PermissionActionModification = "PermissionActionModification",
    PermissionActionModificationAndAccessKeyUsers = "PermissionActionModificationAndAccessKeyUsers",
    PermissionActionModificationAndAssumedRoles = "PermissionActionModificationAndAssumedRoles",
    PermissionActionModificationAndAssumedRolesAndAccessKeyUsers = "PermissionActionModificationAndAssumedRolesAndAccessKeyUsers"
}

export enum AwsRdsClusterEngineType {
    Aurora = "Aurora",
    AuroraMySql = "AuroraMySql",
    AuroraPostgreSql = "AuroraPostgreSql",
    MySql = "MySql",
    Postgres = "Postgres"
}

export enum AwsRdsDatabaseInstanceEngineType {
    Aurora = "Aurora",
    AuroraMySql = "AuroraMySql",
    AuroraPostgreSql = "AuroraPostgreSql",
    CustomOracleEe = "CustomOracleEe",
    CustomSqlServerDev = "CustomSqlServerDev",
    CustomSqlServerEe = "CustomSqlServerEe",
    CustomSqlServerSe = "CustomSqlServerSe",
    CustomSqlServerWeb = "CustomSqlServerWeb",
    IbmDb2Ae = "IbmDb2Ae",
    IbmDb2Se = "IbmDb2Se",
    MariaDb = "MariaDb",
    MySql = "MySql",
    OracleEe = "OracleEe",
    OracleEeCdb = "OracleEeCdb",
    OracleSe = "OracleSe",
    OracleSe1 = "OracleSe1",
    OracleSe2 = "OracleSe2",
    OracleSe2Cdb = "OracleSe2Cdb",
    Postgres = "Postgres",
    SqlServerEe = "SqlServerEe",
    SqlServerEx = "SqlServerEx",
    SqlServerSe = "SqlServerSe",
    SqlServerWeb = "SqlServerWeb"
}

export enum AwsRedshiftClusterStorageEncryptionType {
    Hsm = "Hsm",
    Kms = "Kms"
}

export enum AwsRegionType {
    UsWest2 = "UsWest2",
    UsWest1 = "UsWest1",
    UsIsoFSouth1 = "UsIsoFSouth1",
    UsIsoFEast1 = "UsIsoFEast1",
    UsIsoBEast1 = "UsIsoBEast1",
    UsIsoAWest1 = "UsIsoAWest1",
    UsIsoAEast1 = "UsIsoAEast1",
    UsGovWest1 = "UsGovWest1",
    UsGovIsoRegion1 = "UsGovIsoRegion1",
    UsGovEast1 = "UsGovEast1",
    UsEast2 = "UsEast2",
    UsEast1 = "UsEast1",
    SaEast1 = "SaEast1",
    PublicIsoRegion1 = "PublicIsoRegion1",
    MeSouth1 = "MeSouth1",
    MeCentral1 = "MeCentral1",
    IlCentral1 = "IlCentral1",
    EuWest3 = "EuWest3",
    EuWest2 = "EuWest2",
    EuWest1 = "EuWest1",
    EuSouth2 = "EuSouth2",
    EuSouth1 = "EuSouth1",
    EuNorth1 = "EuNorth1",
    EuIsoEWest1 = "EuIsoEWest1",
    EuCentral2 = "EuCentral2",
    EuCentral1 = "EuCentral1",
    CnNorthWest1 = "CnNorthWest1",
    CnNorth1 = "CnNorth1",
    CaWest1 = "CaWest1",
    CaCentral1 = "CaCentral1",
    ApSouthEast5 = "ApSouthEast5",
    ApSouthEast4 = "ApSouthEast4",
    ApSouthEast3 = "ApSouthEast3",
    ApSouthEast2 = "ApSouthEast2",
    ApSouthEast1 = "ApSouthEast1",
    ApSouth2 = "ApSouth2",
    ApSouth1 = "ApSouth1",
    ApNorthEast3 = "ApNorthEast3",
    ApNorthEast2 = "ApNorthEast2",
    ApNorthEast1 = "ApNorthEast1",
    ApEast1 = "ApEast1",
    AfSouth1 = "AfSouth1"
}

export enum AwsResourceAccessLevel {
    Public = "Public",
    CrossTenant = "CrossTenant",
    Internal = "Internal"
}

export enum AwsResourcePermitterType {
    Ec2ImagePermissions = "Ec2ImagePermissions",
    Ec2SnapshotPermissions = "Ec2SnapshotPermissions",
    KmsKeyGrantList = "KmsKeyGrantList",
    RdsSnapshotPermissions = "RdsSnapshotPermissions",
    ResourcePolicy = "ResourcePolicy",
    S3BucketAccessControlList = "S3BucketAccessControlList"
}

export enum AwsResourceProductCodeType {
    DevPay = "DevPay",
    Marketplace = "Marketplace"
}

export enum AwsResourceSnapshotType {
    Automatic = "Automatic",
    AwsBackup = "AwsBackup",
    Manual = "Manual"
}

export enum AwsRoute53HostedZoneRecordRoutingPolicy {
    Failover = "Failover",
    Geolocation = "Geolocation",
    Latency = "Latency",
    MultivalueAnswer = "MultivalueAnswer",
    Simple = "Simple",
    Weighted = "Weighted"
}

export enum AwsRoute53HostedZoneRecordType {
    A = "A",
    Aaaa = "Aaaa",
    Caa = "Caa",
    Cname = "Cname",
    Ds = "Ds",
    Https = "Https",
    Mx = "Mx",
    Naptr = "Naptr",
    Ns = "Ns",
    Ptr = "Ptr",
    Soa = "Soa",
    Spf = "Spf",
    Srv = "Srv",
    Sshfp = "Sshfp",
    Svcb = "Svcb",
    Tlsa = "Tlsa",
    Txt = "Txt"
}

export enum AwsRoute53HostedZoneSigningStatus {
    ActionNeeded = "ActionNeeded",
    Deleting = "Deleting",
    InternalFailure = "InternalFailure",
    NotSigning = "NotSigning",
    Signing = "Signing"
}

export enum AwsS3AccessControlEntryGranteeType {
    Group = "Group",
    TenantCanonicalId = "TenantCanonicalId",
    TenantMail = "TenantMail"
}

export enum AwsS3AccessControlEntryPermission {
    FullControl = "FullControl",
    Read = "Read",
    ReadAccessControlPolicy = "ReadAccessControlPolicy",
    RestoreObject = "RestoreObject",
    Write = "Write",
    WriteAccessControlPolicy = "WriteAccessControlPolicy"
}

export enum AwsS3BucketManagementRiskModelDescriptionType {
    Acl = "Acl",
    AclPolicy = "AclPolicy",
    AclPolicyPublicAccessBlock = "AclPolicyPublicAccessBlock",
    AclPublicAccessBlock = "AclPublicAccessBlock",
    Policy = "Policy",
    PolicyPublicAccessBlock = "PolicyPublicAccessBlock",
    PublicAccessBlock = "PublicAccessBlock"
}

export enum AwsS3BucketObjectOwnership {
    BucketOwnerEnforced = "BucketOwnerEnforced",
    BucketOwnerPreferred = "BucketOwnerPreferred",
    ObjectWriter = "ObjectWriter"
}

export enum AwsS3BucketServerSideEncryptionType {
    Kms = "Kms",
    KmsDualLayer = "KmsDualLayer",
    S3 = "S3"
}

export enum AwsSageMakerNotebookInstanceMetadataServiceVersion {
    V1 = "V1",
    V2 = "V2"
}

export enum AwsSageMakerNotebookInstanceStatus {
    Deleting = "Deleting",
    Failed = "Failed",
    InService = "InService",
    Pending = "Pending",
    Stopped = "Stopped",
    Stopping = "Stopping",
    Updating = "Updating"
}

export enum AwsSeverePermissionPrincipalRiskModelInfo {
    GroupUsers = "GroupUsers",
    IdentityCreationTime = "IdentityCreationTime",
    RoleMostIndirectOriginatorResources = "RoleMostIndirectOriginatorResources",
    SeverePermissionActionPermittingEntities = "SeverePermissionActionPermittingEntities",
    SeverePermissionActions = "SeverePermissionActions",
    SeverePermissionActionStartTime = "SeverePermissionActionStartTime"
}

export enum AwsSnsDeliveryProtocol {
    Application = "Application",
    Email = "Email",
    EmailJson = "EmailJson",
    Firehose = "Firehose",
    Http = "Http",
    Https = "Https",
    Lambda = "Lambda",
    Sms = "Sms",
    Sns = "Sns",
    Sqs = "Sqs"
}

export enum AwsSnsTopicType {
    Fifo = "Fifo",
    Standard = "Standard"
}

export enum AwsSqsQueueServerSideEncryptionType {
    Kms = "Kms",
    Sqs = "Sqs"
}

export enum AwsSqsQueueType {
    Fifo = "Fifo",
    Standard = "Standard"
}

export enum AwsSsmParameterType {
    SecureString = "SecureString",
    String = "String",
    StringList = "StringList"
}

export enum AwsSsoPermissionSetUnusedRiskModelInfo {
    Assignments = "Assignments",
    Unassigned = "Unassigned",
    UsedEver = "UsedEver",
    UsedNever = "UsedNever"
}

export enum AwsTenantEventCategory {
    LambdaFunctionConfigurationInvokeWrite = "LambdaFunctionConfigurationInvokeWrite",
    ManagementRead = "ManagementRead",
    ManagementWrite = "ManagementWrite",
    S3ObjectRead = "S3ObjectRead",
    S3ObjectWrite = "S3ObjectWrite"
}

export enum AwsTenantManagerValidateTenantResult {
    RegionSystemNamesMissing = "RegionSystemNamesMissing",
    RegionValidationFailure = "RegionValidationFailure",
    RoleAssumeFailure = "RoleAssumeFailure",
    RoleReadOnlyPolicyDocumentAccessDenied = "RoleReadOnlyPolicyDocumentAccessDenied",
    RoleReadOnlyPolicyDocumentMismatch = "RoleReadOnlyPolicyDocumentMismatch",
    TrailsAccessDenied = "TrailsAccessDenied",
    Valid = "Valid"
}

export enum AwsTenantManagerValidateTenantTrailBucketResult {
    AccessDenied = "AccessDenied",
    TenantDisconnected = "TenantDisconnected",
    TenantNotExist = "TenantNotExist",
    TrailAccessDenied = "TrailAccessDenied",
    Valid = "Valid"
}

export enum AwsTenantModelIssue {
    ContainerImageRepositoryAnalysisRolePolicyDocumentMismatch = "ContainerImageRepositoryAnalysisRolePolicyDocumentMismatch",
    DataAnalysisRolePolicyDocumentMismatch = "DataAnalysisRolePolicyDocumentMismatch",
    InstanceAnalysisRolePolicyDocumentMismatch = "InstanceAnalysisRolePolicyDocumentMismatch",
    PermissionManagementRolePolicyDocumentMismatch = "PermissionManagementRolePolicyDocumentMismatch",
    TerraformStateBucketAccessDenied = "TerraformStateBucketAccessDenied",
    TrailBucketAccessDenied = "TrailBucketAccessDenied",
    WorkloadAnalysisAccessDenied = "WorkloadAnalysisAccessDenied"
}

export enum AwsTenantModelStatus {
    AccessDenied = "AccessDenied",
    Deleting = "Deleting",
    RegionValidationFailure = "RegionValidationFailure",
    RoleAssumeFailure = "RoleAssumeFailure",
    RoleReadOnlyPolicyDocumentAccessDenied = "RoleReadOnlyPolicyDocumentAccessDenied",
    RoleReadOnlyPolicyDocumentMismatch = "RoleReadOnlyPolicyDocumentMismatch",
    TenantDeletePending = "TenantDeletePending",
    TenantNotExist = "TenantNotExist",
    TenantSuspended = "TenantSuspended",
    Valid = "Valid",
    ValidWithIssues = "ValidWithIssues"
}

export enum AwsTenantModelTrailStatus {
    AccessDenied = "AccessDenied",
    BucketAccessError = "BucketAccessError",
    DuplicateEvents = "DuplicateEvents",
    NotConfigured = "NotConfigured",
    Valid = "Valid",
    ValidationFailure = "ValidationFailure"
}

export enum AwsTenantStateMonitoringDataAnalysisIssue {
    RolePolicyDocumentMismatch = "RolePolicyDocumentMismatch"
}

export enum AwsTenantStateMonitoringIssue {
    RegionValidationFailure = "RegionValidationFailure",
    RoleAssumeFailure = "RoleAssumeFailure",
    RoleReadOnlyPolicyDocumentAccessDenied = "RoleReadOnlyPolicyDocumentAccessDenied",
    RoleReadOnlyPolicyDocumentMismatch = "RoleReadOnlyPolicyDocumentMismatch",
    TenantDeletePending = "TenantDeletePending",
    TenantNotExist = "TenantNotExist",
    TenantSuspended = "TenantSuspended"
}

export enum AwsTenantStateMonitoringPermissionManagementIssue {
    RolePolicyDocumentMismatch = "RolePolicyDocumentMismatch"
}

export enum AwsTenantStateMonitoringTrailIssueType {
    AccessDenied = "AccessDenied",
    BucketAccessError = "BucketAccessError",
    DuplicateEvents = "DuplicateEvents",
    ManagementEventCategoryTrailNotConfigured = "ManagementEventCategoryTrailNotConfigured",
    NotConfigured = "NotConfigured",
    ValidationFailure = "ValidationFailure"
}

export enum AwsTenantStateMonitoringWorkloadAnalysisIssue {
    ContainerImageRepositoryAnalysisRolePolicyDocumentMismatch = "ContainerImageRepositoryAnalysisRolePolicyDocumentMismatch",
    InstanceAnalysisRolePolicyDocumentMismatch = "InstanceAnalysisRolePolicyDocumentMismatch",
    ResourceAnalysisAccessDenied = "ResourceAnalysisAccessDenied"
}

export enum AwsWafWebAclDefaultAction {
    Allow = "Allow",
    Block = "Block"
}

export enum AzureAuthorizationLockType {
    DeletionDisabled = "DeletionDisabled",
    ReadOnly = "ReadOnly"
}

export enum AzureAuthorizationRoleAssignmentResourceStatus {
    Active = "Active",
    Eligible = "Eligible"
}

export enum AzureAuthorizationRoleDefinitionType {
    BuiltIn = "BuiltIn",
    Custom = "Custom"
}

export enum AzureAuthorizationRoleManagementPolicyAssignmentActivationAuthenticationType {
    AadConditionalAccess = "AadConditionalAccess",
    Mfa = "Mfa",
    None = "None"
}

export enum AzureBehaviorIdentityRiskModelInfo {
    EventActions = "EventActions",
    IpAddresses = "IpAddresses",
    PreviousBehaviorDateEver = "PreviousBehaviorDateEver",
    PreviousBehaviorDateNever = "PreviousBehaviorDateNever",
    Resources = "Resources"
}

export enum AzureCognitiveServicesAccountType {
    AiServices = "AiServices",
    AnomalyDetector = "AnomalyDetector",
    BingCustomSearch = "BingCustomSearch",
    BingSearch = "BingSearch",
    BingSearchV7 = "BingSearchV7",
    BingSpeech = "BingSpeech",
    BingSpellCheck = "BingSpellCheck",
    BingSpellCheckV7 = "BingSpellCheckV7",
    CognitiveServices = "CognitiveServices",
    ComputerVision = "ComputerVision",
    ContentModerator = "ContentModerator",
    ContentSafety = "ContentSafety",
    ConversationalLanguageUnderstanding = "ConversationalLanguageUnderstanding",
    CustomVisionPrediction = "CustomVisionPrediction",
    CustomVisionTraining = "CustomVisionTraining",
    Face = "Face",
    FormRecognizer = "FormRecognizer",
    HealthInsights = "HealthInsights",
    ImmersiveReader = "ImmersiveReader",
    InternalAllInOne = "InternalAllInOne",
    LanguageAuthoring = "LanguageAuthoring",
    Luis = "Luis",
    LuisAuthoring = "LuisAuthoring",
    MetricsAdvisor = "MetricsAdvisor",
    OpenAi = "OpenAi",
    Personalizer = "Personalizer",
    QnAMaker = "QnAMaker",
    QnAMakerV2 = "QnAMakerV2",
    SpeechServices = "SpeechServices",
    SpeechTranslation = "SpeechTranslation",
    TextAnalytics = "TextAnalytics",
    TextTranslation = "TextTranslation"
}

export enum AzureComputeDiskResourceEncryptionType {
    CustomerManagedKey = "CustomerManagedKey",
    PlatformAndCustomerManagedKeys = "PlatformAndCustomerManagedKeys",
    PlatformManagedKey = "PlatformManagedKey"
}

export enum AzureComputeDiskResourceNetworkAccessPolicyType {
    AllowAll = "AllowAll",
    AllowPrivate = "AllowPrivate",
    DenyAll = "DenyAll"
}

export enum AzureComputeDiskResourceSourceType {
    Disk = "Disk",
    Snapshot = "Snapshot"
}

export enum AzureComputeDiskSnapshotType {
    Full = "Full",
    Incremental = "Incremental"
}

export enum AzureComputeDiskStorageAccountSku {
    PremiumLrs = "PremiumLrs",
    PremiumV2Lrs = "PremiumV2Lrs",
    PremiumZrs = "PremiumZrs",
    StandardLrs = "StandardLrs",
    StandardSsdLrs = "StandardSsdLrs",
    StandardSsdZrs = "StandardSsdZrs",
    UltraSsdLrs = "UltraSsdLrs"
}

export enum AzureComputeResourceDiskRole {
    Data = "Data",
    OperatingSystem = "OperatingSystem"
}

export enum AzureComputeVirtualMachineScaleSetType {
    VirtualMachine = "VirtualMachine",
    VirtualMachineScaleSetVirtualMachine = "VirtualMachineScaleSetVirtualMachine"
}

export enum AzureComputeVirtualMachineStatus {
    Deallocated = "Deallocated",
    Deallocating = "Deallocating",
    Running = "Running",
    Starting = "Starting",
    Stopped = "Stopped",
    Stopping = "Stopping"
}

export enum AzureConsoleEntityPage {
    AccessControl = "AccessControl",
    AccessKeys = "AccessKeys",
    AccessPolicies = "AccessPolicies",
    ActiveDirectoryAdmin = "ActiveDirectoryAdmin",
    ActiveDirectoryAdmins = "ActiveDirectoryAdmins",
    ActivityLog = "ActivityLog",
    Authentication = "Authentication",
    Certificates = "Certificates",
    CertificatesReact = "CertificatesReact",
    ClusterConfiguration = "ClusterConfiguration",
    ClusterNodePools = "ClusterNodePools",
    Configuration = "Configuration",
    ConnectionSecurity = "ConnectionSecurity",
    DataEncryption = "DataEncryption",
    DataProtection = "DataProtection",
    Diagnostics = "Diagnostics",
    Disks = "Disks",
    Encryption = "Encryption",
    Firewall = "Firewall",
    InboundRules = "InboundRules",
    Keys = "Keys",
    Network = "Network",
    Networking = "Networking",
    NetworkingHub = "NetworkingHub",
    NetworkSettings = "NetworkSettings",
    Overview = "Overview",
    Properties = "Properties",
    RoleAssignments = "RoleAssignments",
    Secrets = "Secrets",
    SecurityAlertPolicy = "SecurityAlertPolicy",
    ServerAuditing = "ServerAuditing",
    ServerConfigurations = "ServerConfigurations",
    ServerTransparentDataEncryption = "ServerTransparentDataEncryption",
    VirtualNetworkSubnets = "VirtualNetworkSubnets"
}

export enum AzureContainerRegistryRegistryNetworkAccessType {
    Private = "Private",
    Public = "Public",
    Restricted = "Restricted"
}

export enum AzureContainerRegistryRegistrySku {
    Basic = "Basic",
    Classic = "Classic",
    Premium = "Premium",
    Standard = "Standard"
}

export enum AzureContainerServiceManagedClusterAgentPoolMode {
    Gateway = "Gateway",
    System = "System",
    User = "User"
}

export enum AzureContainerServiceManagedClusterAgentPoolStatus {
    Running = "Running",
    Stopped = "Stopped"
}

export enum AzureContainerServiceManagedClusterAuthenticationAndAuthorizationMode {
    AuthenticationDisabled = "AuthenticationDisabled",
    IdentityAndKubernetesRoleBinding = "IdentityAndKubernetesRoleBinding",
    IdentityAndRoleAssignment = "IdentityAndRoleAssignment",
    KubernetesUserAndKubernetesRoleBinding = "KubernetesUserAndKubernetesRoleBinding",
    KubernetesUserAndUnrestrictedAccess = "KubernetesUserAndUnrestrictedAccess"
}

export enum AzureContainerServiceManagedClusterNetworkType {
    Azure = "Azure",
    Kubenet = "Kubenet",
    None = "None"
}

export enum AzureContainerServiceManagedClusterStatus {
    Running = "Running",
    Stopped = "Stopped"
}

export enum AzureDevOpsOrganizationManagerError {
    CommonErrorAadTenantNotFound = "CommonErrorAadTenantNotFound",
    CommonErrorAccessTokenInvalid = "CommonErrorAccessTokenInvalid",
    CommonErrorApplicationUnauthorized = "CommonErrorApplicationUnauthorized",
    CommonErrorOrganizationNotFound = "CommonErrorOrganizationNotFound",
    InsertOrganizationErrorOrganizationExists = "InsertOrganizationErrorOrganizationExists"
}

export enum AzureDocumentDbDatabaseAccountBackupType {
    Continuous = "Continuous",
    Periodic = "Periodic"
}

export enum AzureDocumentDbDatabaseAccountType {
    Cassandra = "Cassandra",
    Gremlin = "Gremlin",
    MongoDb = "MongoDb",
    Sql = "Sql",
    Table = "Table"
}

export enum AzureEntityPathType {
    Extension = "Extension",
    ManagementGroup = "ManagementGroup",
    Resource = "Resource",
    ResourceGroup = "ResourceGroup",
    Root = "Root",
    Service = "Service",
    Tenant = "Tenant"
}

export enum AzureEventHubNamespaceEventHubStatus {
    Active = "Active",
    Creating = "Creating",
    Deleting = "Deleting",
    Disabled = "Disabled",
    ReceiveDisabled = "ReceiveDisabled",
    Renaming = "Renaming",
    Restoring = "Restoring",
    SendDisabled = "SendDisabled",
    Unknown = "Unknown"
}

export enum AzureEventHubNamespaceNetworkAccessType {
    Private = "Private",
    Public = "Public",
    Restricted = "Restricted"
}

export enum AzureEventHubNamespaceSku {
    Basic = "Basic",
    Premium = "Premium",
    Standard = "Standard"
}

export enum AzureEventHubNamespaceStatus {
    Activating = "Activating",
    Active = "Active",
    Disabled = "Disabled",
    Failed = "Failed",
    Removing = "Removing"
}

export enum AzureExcessivePermissionPrincipalRiskModelInfoType {
    CreationTime = "CreationTime",
    ExcessiveDirectMembershipGroupIds = "ExcessiveDirectMembershipGroupIds",
    GroupMembers = "GroupMembers",
    IdentityDisabled = "IdentityDisabled",
    OriginatorResources = "OriginatorResources",
    SensitiveResourcesSensitiveExcessivePermissionActionsAny = "SensitiveResourcesSensitiveExcessivePermissionActionsAny",
    SeverityAndResolution = "SeverityAndResolution",
    SignInNever = "SignInNever",
    SignInTime = "SignInTime",
    SignInTimeNotExact = "SignInTimeNotExact"
}

export enum AzureExcessivePermissionPrincipalRiskModelPermittersType {
    ClassicAdministrator = "ClassicAdministrator",
    ClassicAdministratorAndRoleAssignments = "ClassicAdministratorAndRoleAssignments",
    RoleAssignments = "RoleAssignments"
}

export enum AzureExcessivePermissionPrincipalRiskModelResolutionPermittersType {
    ClassicAdministrator = "ClassicAdministrator",
    ClassicAdministratorAndRoleAssignmentsAllMany = "ClassicAdministratorAndRoleAssignmentsAllMany",
    ClassicAdministratorAndRoleAssignmentsAllSingle = "ClassicAdministratorAndRoleAssignmentsAllSingle",
    ClassicAdministratorAndRoleAssignmentsSome = "ClassicAdministratorAndRoleAssignmentsSome",
    RoleAssignmentsAllMany = "RoleAssignmentsAllMany",
    RoleAssignmentsAllSingle = "RoleAssignmentsAllSingle",
    RoleAssignmentsSome = "RoleAssignmentsSome"
}

export enum AzureExcessivePermissionPrincipalRiskModelResolutionType {
    Delete = "Delete",
    DeleteAndReplaceMany = "DeleteAndReplaceMany",
    DeleteAndReplaceSingle = "DeleteAndReplaceSingle",
    ReplaceMany = "ReplaceMany",
    ReplaceSingle = "ReplaceSingle"
}

export enum AzureExcessivePermissionPrincipalRiskRoleAssignmentResourceResolution {
    Delete = "Delete",
    Replace = "Replace"
}

export enum AzureKeyVaultVaultCertificatePermission {
    All = "All",
    Backup = "Backup",
    Create = "Create",
    Delete = "Delete",
    DeleteCertificateAuthorities = "DeleteCertificateAuthorities",
    Get = "Get",
    GetCertificateAuthorities = "GetCertificateAuthorities",
    Import = "Import",
    List = "List",
    ListCertificateAuthorities = "ListCertificateAuthorities",
    ManageCertificateAuthorities = "ManageCertificateAuthorities",
    ManageContacts = "ManageContacts",
    Purge = "Purge",
    Recover = "Recover",
    Restore = "Restore",
    SetCertificateAuthorities = "SetCertificateAuthorities",
    Update = "Update"
}

export enum AzureKeyVaultVaultKeyEllipticCurveType {
    P256 = "P256",
    P256K = "P256K",
    P384 = "P384",
    P521 = "P521",
    Secp256K1 = "Secp256K1"
}

export enum AzureKeyVaultVaultKeyOperation {
    Decrypt = "Decrypt",
    DeriveBits = "DeriveBits",
    DeriveKey = "DeriveKey",
    Encrypt = "Encrypt",
    Import = "Import",
    Sign = "Sign",
    UnwrapKey = "UnwrapKey",
    Verify = "Verify",
    WrapKey = "WrapKey"
}

export enum AzureKeyVaultVaultKeyPermission {
    All = "All",
    Backup = "Backup",
    Create = "Create",
    Decrypt = "Decrypt",
    Delete = "Delete",
    Encrypt = "Encrypt",
    Get = "Get",
    GetRotationPolicy = "GetRotationPolicy",
    Import = "Import",
    List = "List",
    Purge = "Purge",
    Recover = "Recover",
    Release = "Release",
    Restore = "Restore",
    Rotate = "Rotate",
    SetRotationPolicy = "SetRotationPolicy",
    Sign = "Sign",
    UnwrapKey = "UnwrapKey",
    Update = "Update",
    Verify = "Verify",
    WrapKey = "WrapKey"
}

export enum AzureKeyVaultVaultKeyRotationPolicyType {
    AfterCreation = "AfterCreation",
    BeforeExpiration = "BeforeExpiration"
}

export enum AzureKeyVaultVaultKeyType {
    Ec = "Ec",
    EcHsm = "EcHsm",
    Oct = "Oct",
    OctHsm = "OctHsm",
    Rsa = "Rsa",
    RsaHsm = "RsaHsm"
}

export enum AzureKeyVaultVaultSecretPermission {
    All = "All",
    Backup = "Backup",
    Delete = "Delete",
    Get = "Get",
    List = "List",
    Purge = "Purge",
    Recover = "Recover",
    Restore = "Restore",
    Set = "Set"
}

export enum AzureKeyVaultVaultStorageAccountPermission {
    All = "All",
    Backup = "Backup",
    Delete = "Delete",
    DeleteSas = "DeleteSas",
    Get = "Get",
    GetSas = "GetSas",
    List = "List",
    ListSas = "ListSas",
    Purge = "Purge",
    Recover = "Recover",
    RegenerateKey = "RegenerateKey",
    Restore = "Restore",
    Set = "Set",
    SetSas = "SetSas",
    Update = "Update"
}

export enum AzureLogicWorkflowStatus {
    Completed = "Completed",
    Deleted = "Deleted",
    Disabled = "Disabled",
    Enabled = "Enabled",
    NotSpecified = "NotSpecified",
    Suspended = "Suspended"
}

export enum AzureLogicWorkflowTriggerNetworkAccessType {
    LogicApp = "LogicApp",
    Public = "Public",
    Restricted = "Restricted"
}

export enum AzureManagedIdentityManagedIdentityInactiveRiskModelInfoType {
    SignInNever = "SignInNever",
    SignInTime = "SignInTime"
}

export enum AzureManagedIdentityManagedIdentityModelType {
    System = "System",
    User = "User"
}

export enum AzureMySqlServerAuthenticationType {
    Aad = "Aad",
    AadAndMySql = "AadAndMySql",
    MySql = "MySql"
}

export enum AzureMySqlServerSkuTier {
    Basic = "Basic",
    Burstable = "Burstable",
    GeneralPurpose = "GeneralPurpose",
    MemoryOptimized = "MemoryOptimized"
}

export enum AzureMySqlServerStatus {
    Disabled = "Disabled",
    Dropping = "Dropping",
    Inaccessible = "Inaccessible",
    Ready = "Ready",
    Restarting = "Restarting",
    Starting = "Starting",
    Stopped = "Stopped",
    Stopping = "Stopping",
    Updating = "Updating",
    WaitingForMigration = "WaitingForMigration"
}

export enum AzureNetworkApplicationGatewayProtocol {
    Http = "Http",
    Https = "Https"
}

export enum AzureNetworkLoadBalancerRuleProtocol {
    All = "All",
    Tcp = "Tcp",
    Udp = "Udp"
}

export enum AzureNetworkLoadBalancerSku {
    Basic = "Basic",
    Gateway = "Gateway",
    Standard = "Standard"
}

export enum AzureNetworkLoadBalancerType {
    Internal = "Internal",
    Public = "Public"
}

export enum AzureNetworkNetworkSecurityGroupRuleAction {
    Allow = "Allow",
    Deny = "Deny"
}

export enum AzureNetworkPrivateEndpointConnectionStatus {
    Approved = "Approved",
    Disconnected = "Disconnected",
    Pending = "Pending",
    Rejected = "Rejected"
}

export enum AzureNetworkPublicIpAddressSku {
    Basic = "Basic",
    Standard = "Standard"
}

export enum AzureNetworkPublicIpAddressType {
    Dynamic = "Dynamic",
    Static = "Static"
}

export enum AzureNetworkRouteTableRouteType {
    Internet = "Internet",
    None = "None",
    VirtualAppliance = "VirtualAppliance",
    VirtualNetwork = "VirtualNetwork",
    VirtualNetworkGateway = "VirtualNetworkGateway"
}

export enum AzureNetworkServiceEndpointState {
    Deleting = "Deleting",
    Deprovisioning = "Deprovisioning",
    Failed = "Failed",
    Initializing = "Initializing",
    InProgress = "InProgress",
    NetworkSourceDeleted = "NetworkSourceDeleted",
    Provisioning = "Provisioning",
    Ready = "Ready",
    Succeeded = "Succeeded",
    Unknown = "Unknown"
}

export enum AzurePermitterType {
    AzureKeyVaultVaultAccessPolicy = "AzureKeyVaultVaultAccessPolicy",
    AzureRoleAssignmentResource = "AzureRoleAssignmentResource",
    AzureStorageStorageAccountAccessKey = "AzureStorageStorageAccountAccessKey",
    AzureStorageStorageAccountBlobContainerPublicAccess = "AzureStorageStorageAccountBlobContainerPublicAccess"
}

export enum AzurePostgreSqlServerAuthenticationType {
    Aad = "Aad",
    AadAndPostgreSql = "AadAndPostgreSql",
    PostgreSql = "PostgreSql"
}

export enum AzurePostgreSqlServerSkuTier {
    Basic = "Basic",
    Burstable = "Burstable",
    GeneralPurpose = "GeneralPurpose",
    MemoryOptimized = "MemoryOptimized"
}

export enum AzurePostgreSqlServerStatus {
    Disabled = "Disabled",
    Dropping = "Dropping",
    Inaccessible = "Inaccessible",
    Provisioning = "Provisioning",
    Ready = "Ready",
    Restarting = "Restarting",
    Starting = "Starting",
    Stopped = "Stopped",
    Stopping = "Stopping",
    Updating = "Updating"
}

export enum AzurePrincipalNotAllowedResourceRoleDefinitionRiskPolicyConfigurationRoleDefinitionsOperator {
    Overlap = "Overlap",
    Subset = "Subset"
}

export enum AzureSeverePermissionPrincipalRiskModelInfo {
    GroupIdentities = "GroupIdentities",
    IdentityCreationTime = "IdentityCreationTime",
    ManagedIdentity = "ManagedIdentity",
    SeverePermissionActionPermittingEntities = "SeverePermissionActionPermittingEntities",
    SeverePermissionActionStartTime = "SeverePermissionActionStartTime"
}

export enum AzureSqlServerAuthenticationType {
    Aad = "Aad",
    AadAndSql = "AadAndSql",
    Sql = "Sql"
}

export enum AzureSqlServerDatabaseStatus {
    AutoClosed = "AutoClosed",
    Copying = "Copying",
    Creating = "Creating",
    Disabled = "Disabled",
    EmergencyMode = "EmergencyMode",
    Inaccessible = "Inaccessible",
    Offline = "Offline",
    OfflineChangingDwPerformanceTiers = "OfflineChangingDwPerformanceTiers",
    OfflineSecondary = "OfflineSecondary",
    Online = "Online",
    OnlineChangingDwPerformanceTiers = "OnlineChangingDwPerformanceTiers",
    Paused = "Paused",
    Pausing = "Pausing",
    Recovering = "Recovering",
    RecoveryPending = "RecoveryPending",
    Restoring = "Restoring",
    Resuming = "Resuming",
    Scaling = "Scaling",
    Shutdown = "Shutdown",
    Standby = "Standby",
    Starting = "Starting",
    Stopped = "Stopped",
    Stopping = "Stopping",
    Suspect = "Suspect"
}

export enum AzureSqlServerStatus {
    Disabled = "Disabled",
    Online = "Online",
    Paused = "Paused",
    Ready = "Ready"
}

export enum AzureStorageStorageAccountBlobContainerLeaseState {
    Available = "Available",
    Breaking = "Breaking",
    Broken = "Broken",
    Expired = "Expired",
    Leased = "Leased"
}

export enum AzureStorageStorageAccountBlobContainerPublicAccess {
    Blob = "Blob",
    Container = "Container",
    None = "None"
}

export enum AzureStorageStorageAccountEncryptionKeySource {
    KeyVault = "KeyVault",
    StorageAccount = "StorageAccount"
}

export enum AzureStorageStorageAccountStatus {
    Available = "Available",
    Unavailable = "Unavailable"
}

export enum AzureStorageStorageAccountType {
    BlobStorage = "BlobStorage",
    BlockBlobStorage = "BlockBlobStorage",
    FileStorage = "FileStorage",
    Storage = "Storage",
    StorageV2 = "StorageV2"
}

export enum AzureTenantEntityStatus {
    Deleted = "Deleted",
    Disabled = "Disabled",
    Enabled = "Enabled",
    PastDue = "PastDue",
    Warned = "Warned"
}

export enum AzureTenantManagerError {
    CommonErrorAadTenantIssue = "CommonErrorAadTenantIssue",
    CommonErrorAadTenantMismatch = "CommonErrorAadTenantMismatch",
    CommonErrorMoveScopeDependencyExists = "CommonErrorMoveScopeDependencyExists",
    CommonErrorParentFolderNotExist = "CommonErrorParentFolderNotExist",
    CommonErrorTenantNotExist = "CommonErrorTenantNotExist",
    DeleteTenantErrorOrganizationExists = "DeleteTenantErrorOrganizationExists",
    InsertTenantErrorTenantExists = "InsertTenantErrorTenantExists",
    InsertTenantErrorTenantMaxCount = "InsertTenantErrorTenantMaxCount",
    UpsertOrganizationErrorServicePrincipalUnauthorized = "UpsertOrganizationErrorServicePrincipalUnauthorized",
    UpsertOrganizationErrorTenantNotExists = "UpsertOrganizationErrorTenantNotExists",
    UpsertTenantErrorServicePrincipalAssignedRoleDefinitionMismatch = "UpsertTenantErrorServicePrincipalAssignedRoleDefinitionMismatch",
    UpsertTenantErrorServicePrincipalUnauthorized = "UpsertTenantErrorServicePrincipalUnauthorized",
    UpsertTenantErrorTenantBlocked = "UpsertTenantErrorTenantBlocked",
    UpsertTenantErrorTenantDeletePending = "UpsertTenantErrorTenantDeletePending",
    UpsertTenantErrorTenantNotEnabled = "UpsertTenantErrorTenantNotEnabled",
    UpsertTenantErrorTenantNotSupported = "UpsertTenantErrorTenantNotSupported"
}

export enum AzureTenantModelIssue {
    TerraformStateStorageAccountBlobContainerAccessDenied = "TerraformStateStorageAccountBlobContainerAccessDenied"
}

export enum AzureTenantModelStatus {
    AadTenantIssue = "AadTenantIssue",
    AadTenantMismatch = "AadTenantMismatch",
    AccessDenied = "AccessDenied",
    Deleting = "Deleting",
    ServicePrincipalDataAnalysisAssignedRoleDefinitionMismatch = "ServicePrincipalDataAnalysisAssignedRoleDefinitionMismatch",
    ServicePrincipalMandatoryAssignedRoleDefinitionMismatch = "ServicePrincipalMandatoryAssignedRoleDefinitionMismatch",
    ServicePrincipalOptionalAssignedRoleDefinitionMismatch = "ServicePrincipalOptionalAssignedRoleDefinitionMismatch",
    ServicePrincipalUnauthorized = "ServicePrincipalUnauthorized",
    ServicePrincipalWorkloadAnalysisAssignedRoleDefinitionMismatch = "ServicePrincipalWorkloadAnalysisAssignedRoleDefinitionMismatch",
    TenantBlocked = "TenantBlocked",
    TenantDeletePending = "TenantDeletePending",
    TenantNotEnabled = "TenantNotEnabled",
    TenantNotExist = "TenantNotExist",
    Valid = "Valid",
    ValidWithIssues = "ValidWithIssues"
}

export enum AzureTenantStateMonitoringDataAnalysisIssue {
    ServicePrincipalAssignedRoleDefinitionMismatch = "ServicePrincipalAssignedRoleDefinitionMismatch"
}

export enum AzureTenantStateMonitoringIssue {
    AadTenantIssue = "AadTenantIssue",
    AadTenantMismatch = "AadTenantMismatch",
    ServicePrincipalDataAnalysisAssignedRoleDefinitionMismatch = "ServicePrincipalDataAnalysisAssignedRoleDefinitionMismatch",
    ServicePrincipalMandatoryAssignedRoleDefinitionMismatch = "ServicePrincipalMandatoryAssignedRoleDefinitionMismatch",
    ServicePrincipalOptionalAssignedRoleDefinitionMismatch = "ServicePrincipalOptionalAssignedRoleDefinitionMismatch",
    ServicePrincipalUnauthorized = "ServicePrincipalUnauthorized",
    ServicePrincipalWorkloadAnalysisAssignedRoleDefinitionMismatch = "ServicePrincipalWorkloadAnalysisAssignedRoleDefinitionMismatch",
    TenantBlocked = "TenantBlocked",
    TenantDeletePending = "TenantDeletePending",
    TenantNotEnabled = "TenantNotEnabled",
    TenantNotExist = "TenantNotExist",
    TenantNotSupported = "TenantNotSupported"
}

export enum AzureTenantStateMonitoringPermissionManagementIssue {
    ServicePrincipalAssignedRoleDefinitionMismatch = "ServicePrincipalAssignedRoleDefinitionMismatch"
}

export enum AzureTenantStateMonitoringWorkloadAnalysisIssue {
    ServicePrincipalAssignedRoleDefinitionMismatch = "ServicePrincipalAssignedRoleDefinitionMismatch"
}

export enum AzureWebApplicationAuthenticationType {
    Disabled = "Disabled",
    Optional = "Optional",
    Required = "Required"
}

export enum AzureWebApplicationNetworkRuleAction {
    Allow = "Allow",
    Deny = "Deny"
}

export enum AzureWebApplicationStatus {
    AdminDisabled = "AdminDisabled",
    Disabled = "Disabled",
    Running = "Running",
    Stopped = "Stopped"
}

export enum AzureWebApplicationType {
    Api = "Api",
    Function = "Function",
    Logic = "Logic",
    Mobile = "Mobile",
    Web = "Web"
}

export enum BitbucketOrganizationManagerError {
    CommonErrorAccessTokenIncorrectScope = "CommonErrorAccessTokenIncorrectScope",
    CommonErrorAccessTokenMissingPermissions = "CommonErrorAccessTokenMissingPermissions",
    CommonErrorAccessTokenUnauthorized = "CommonErrorAccessTokenUnauthorized",
    CommonErrorOrganizationEmpty = "CommonErrorOrganizationEmpty",
    CommonErrorOrganizationExists = "CommonErrorOrganizationExists",
    CommonErrorOrganizationMismatch = "CommonErrorOrganizationMismatch"
}

export enum BuiltInComplianceSectionType {
    AwsFsbp_1_0 = "AwsFsbp_1_0",
    AwsFsbp_1_0_Account = "AwsFsbp_1_0_Account",
    AwsFsbp_1_0_Account_1 = "AwsFsbp_1_0_Account_1",
    AwsFsbp_1_0_Acm = "AwsFsbp_1_0_Acm",
    AwsFsbp_1_0_Acm_1 = "AwsFsbp_1_0_Acm_1",
    AwsFsbp_1_0_Acm_2 = "AwsFsbp_1_0_Acm_2",
    AwsFsbp_1_0_ApiGateway = "AwsFsbp_1_0_ApiGateway",
    AwsFsbp_1_0_ApiGateway_1 = "AwsFsbp_1_0_ApiGateway_1",
    AwsFsbp_1_0_ApiGateway_2 = "AwsFsbp_1_0_ApiGateway_2",
    AwsFsbp_1_0_ApiGateway_3 = "AwsFsbp_1_0_ApiGateway_3",
    AwsFsbp_1_0_ApiGateway_4 = "AwsFsbp_1_0_ApiGateway_4",
    AwsFsbp_1_0_ApiGateway_5 = "AwsFsbp_1_0_ApiGateway_5",
    AwsFsbp_1_0_ApiGateway_8 = "AwsFsbp_1_0_ApiGateway_8",
    AwsFsbp_1_0_ApiGateway_9 = "AwsFsbp_1_0_ApiGateway_9",
    AwsFsbp_1_0_AppSync = "AwsFsbp_1_0_AppSync",
    AwsFsbp_1_0_AppSync_2 = "AwsFsbp_1_0_AppSync_2",
    AwsFsbp_1_0_AppSync_5 = "AwsFsbp_1_0_AppSync_5",
    AwsFsbp_1_0_Athena = "AwsFsbp_1_0_Athena",
    AwsFsbp_1_0_Athena_1 = "AwsFsbp_1_0_Athena_1",
    AwsFsbp_1_0_Athena_4 = "AwsFsbp_1_0_Athena_4",
    AwsFsbp_1_0_AutoScaling = "AwsFsbp_1_0_AutoScaling",
    AwsFsbp_1_0_AutoScaling_1 = "AwsFsbp_1_0_AutoScaling_1",
    AwsFsbp_1_0_AutoScaling_2 = "AwsFsbp_1_0_AutoScaling_2",
    AwsFsbp_1_0_AutoScaling_3 = "AwsFsbp_1_0_AutoScaling_3",
    AwsFsbp_1_0_AutoScaling_5 = "AwsFsbp_1_0_AutoScaling_5",
    AwsFsbp_1_0_AutoScaling_6 = "AwsFsbp_1_0_AutoScaling_6",
    AwsFsbp_1_0_AutoScaling_9 = "AwsFsbp_1_0_AutoScaling_9",
    AwsFsbp_1_0_Backup = "AwsFsbp_1_0_Backup",
    AwsFsbp_1_0_Backup_1 = "AwsFsbp_1_0_Backup_1",
    AwsFsbp_1_0_CloudFront = "AwsFsbp_1_0_CloudFront",
    AwsFsbp_1_0_CloudFront_1 = "AwsFsbp_1_0_CloudFront_1",
    AwsFsbp_1_0_CloudFront_3 = "AwsFsbp_1_0_CloudFront_3",
    AwsFsbp_1_0_CloudFront_4 = "AwsFsbp_1_0_CloudFront_4",
    AwsFsbp_1_0_CloudFront_5 = "AwsFsbp_1_0_CloudFront_5",
    AwsFsbp_1_0_CloudFront_6 = "AwsFsbp_1_0_CloudFront_6",
    AwsFsbp_1_0_CloudFront_7 = "AwsFsbp_1_0_CloudFront_7",
    AwsFsbp_1_0_CloudFront_8 = "AwsFsbp_1_0_CloudFront_8",
    AwsFsbp_1_0_CloudFront_9 = "AwsFsbp_1_0_CloudFront_9",
    AwsFsbp_1_0_CloudFront_10 = "AwsFsbp_1_0_CloudFront_10",
    AwsFsbp_1_0_CloudFront_12 = "AwsFsbp_1_0_CloudFront_12",
    AwsFsbp_1_0_CloudFront_13 = "AwsFsbp_1_0_CloudFront_13",
    AwsFsbp_1_0_CloudTrail = "AwsFsbp_1_0_CloudTrail",
    AwsFsbp_1_0_CloudTrail_1 = "AwsFsbp_1_0_CloudTrail_1",
    AwsFsbp_1_0_CloudTrail_2 = "AwsFsbp_1_0_CloudTrail_2",
    AwsFsbp_1_0_CloudTrail_4 = "AwsFsbp_1_0_CloudTrail_4",
    AwsFsbp_1_0_CloudTrail_5 = "AwsFsbp_1_0_CloudTrail_5",
    AwsFsbp_1_0_CodeBuild = "AwsFsbp_1_0_CodeBuild",
    AwsFsbp_1_0_CodeBuild_1 = "AwsFsbp_1_0_CodeBuild_1",
    AwsFsbp_1_0_CodeBuild_2 = "AwsFsbp_1_0_CodeBuild_2",
    AwsFsbp_1_0_CodeBuild_3 = "AwsFsbp_1_0_CodeBuild_3",
    AwsFsbp_1_0_CodeBuild_4 = "AwsFsbp_1_0_CodeBuild_4",
    AwsFsbp_1_0_CodeBuild_7 = "AwsFsbp_1_0_CodeBuild_7",
    AwsFsbp_1_0_Config = "AwsFsbp_1_0_Config",
    AwsFsbp_1_0_Config_1 = "AwsFsbp_1_0_Config_1",
    AwsFsbp_1_0_DataFirehose = "AwsFsbp_1_0_DataFirehose",
    AwsFsbp_1_0_DataFirehose_1 = "AwsFsbp_1_0_DataFirehose_1",
    AwsFsbp_1_0_DataSync = "AwsFsbp_1_0_DataSync",
    AwsFsbp_1_0_DataSync_1 = "AwsFsbp_1_0_DataSync_1",
    AwsFsbp_1_0_Dms = "AwsFsbp_1_0_Dms",
    AwsFsbp_1_0_Dms_1 = "AwsFsbp_1_0_Dms_1",
    AwsFsbp_1_0_Dms_6 = "AwsFsbp_1_0_Dms_6",
    AwsFsbp_1_0_Dms_7 = "AwsFsbp_1_0_Dms_7",
    AwsFsbp_1_0_Dms_8 = "AwsFsbp_1_0_Dms_8",
    AwsFsbp_1_0_Dms_9 = "AwsFsbp_1_0_Dms_9",
    AwsFsbp_1_0_Dms_10 = "AwsFsbp_1_0_Dms_10",
    AwsFsbp_1_0_Dms_11 = "AwsFsbp_1_0_Dms_11",
    AwsFsbp_1_0_Dms_12 = "AwsFsbp_1_0_Dms_12",
    AwsFsbp_1_0_DocumentDB = "AwsFsbp_1_0_DocumentDB",
    AwsFsbp_1_0_DocumentDB_1 = "AwsFsbp_1_0_DocumentDB_1",
    AwsFsbp_1_0_DocumentDB_2 = "AwsFsbp_1_0_DocumentDB_2",
    AwsFsbp_1_0_DocumentDB_3 = "AwsFsbp_1_0_DocumentDB_3",
    AwsFsbp_1_0_DocumentDB_4 = "AwsFsbp_1_0_DocumentDB_4",
    AwsFsbp_1_0_DocumentDB_5 = "AwsFsbp_1_0_DocumentDB_5",
    AwsFsbp_1_0_DynamoDB = "AwsFsbp_1_0_DynamoDB",
    AwsFsbp_1_0_DynamoDB_1 = "AwsFsbp_1_0_DynamoDB_1",
    AwsFsbp_1_0_DynamoDB_2 = "AwsFsbp_1_0_DynamoDB_2",
    AwsFsbp_1_0_DynamoDB_3 = "AwsFsbp_1_0_DynamoDB_3",
    AwsFsbp_1_0_DynamoDB_6 = "AwsFsbp_1_0_DynamoDB_6",
    AwsFsbp_1_0_DynamoDB_7 = "AwsFsbp_1_0_DynamoDB_7",
    AwsFsbp_1_0_Ec2 = "AwsFsbp_1_0_Ec2",
    AwsFsbp_1_0_Ec2_1 = "AwsFsbp_1_0_Ec2_1",
    AwsFsbp_1_0_Ec2_2 = "AwsFsbp_1_0_Ec2_2",
    AwsFsbp_1_0_Ec2_3 = "AwsFsbp_1_0_Ec2_3",
    AwsFsbp_1_0_Ec2_4 = "AwsFsbp_1_0_Ec2_4",
    AwsFsbp_1_0_Ec2_6 = "AwsFsbp_1_0_Ec2_6",
    AwsFsbp_1_0_Ec2_7 = "AwsFsbp_1_0_Ec2_7",
    AwsFsbp_1_0_Ec2_8 = "AwsFsbp_1_0_Ec2_8",
    AwsFsbp_1_0_Ec2_9 = "AwsFsbp_1_0_Ec2_9",
    AwsFsbp_1_0_Ec2_10 = "AwsFsbp_1_0_Ec2_10",
    AwsFsbp_1_0_Ec2_15 = "AwsFsbp_1_0_Ec2_15",
    AwsFsbp_1_0_Ec2_16 = "AwsFsbp_1_0_Ec2_16",
    AwsFsbp_1_0_Ec2_17 = "AwsFsbp_1_0_Ec2_17",
    AwsFsbp_1_0_Ec2_18 = "AwsFsbp_1_0_Ec2_18",
    AwsFsbp_1_0_Ec2_19 = "AwsFsbp_1_0_Ec2_19",
    AwsFsbp_1_0_Ec2_20 = "AwsFsbp_1_0_Ec2_20",
    AwsFsbp_1_0_Ec2_21 = "AwsFsbp_1_0_Ec2_21",
    AwsFsbp_1_0_Ec2_23 = "AwsFsbp_1_0_Ec2_23",
    AwsFsbp_1_0_Ec2_24 = "AwsFsbp_1_0_Ec2_24",
    AwsFsbp_1_0_Ec2_25 = "AwsFsbp_1_0_Ec2_25",
    AwsFsbp_1_0_Ec2_51 = "AwsFsbp_1_0_Ec2_51",
    AwsFsbp_1_0_Ecr = "AwsFsbp_1_0_Ecr",
    AwsFsbp_1_0_Ecr_1 = "AwsFsbp_1_0_Ecr_1",
    AwsFsbp_1_0_Ecr_2 = "AwsFsbp_1_0_Ecr_2",
    AwsFsbp_1_0_Ecr_3 = "AwsFsbp_1_0_Ecr_3",
    AwsFsbp_1_0_Ecs = "AwsFsbp_1_0_Ecs",
    AwsFsbp_1_0_Ecs_1 = "AwsFsbp_1_0_Ecs_1",
    AwsFsbp_1_0_Ecs_2 = "AwsFsbp_1_0_Ecs_2",
    AwsFsbp_1_0_Ecs_3 = "AwsFsbp_1_0_Ecs_3",
    AwsFsbp_1_0_Ecs_4 = "AwsFsbp_1_0_Ecs_4",
    AwsFsbp_1_0_Ecs_5 = "AwsFsbp_1_0_Ecs_5",
    AwsFsbp_1_0_Ecs_8 = "AwsFsbp_1_0_Ecs_8",
    AwsFsbp_1_0_Ecs_9 = "AwsFsbp_1_0_Ecs_9",
    AwsFsbp_1_0_Ecs_10 = "AwsFsbp_1_0_Ecs_10",
    AwsFsbp_1_0_Ecs_12 = "AwsFsbp_1_0_Ecs_12",
    AwsFsbp_1_0_Efs = "AwsFsbp_1_0_Efs",
    AwsFsbp_1_0_Efs_1 = "AwsFsbp_1_0_Efs_1",
    AwsFsbp_1_0_Efs_2 = "AwsFsbp_1_0_Efs_2",
    AwsFsbp_1_0_Efs_3 = "AwsFsbp_1_0_Efs_3",
    AwsFsbp_1_0_Efs_4 = "AwsFsbp_1_0_Efs_4",
    AwsFsbp_1_0_Efs_6 = "AwsFsbp_1_0_Efs_6",
    AwsFsbp_1_0_Efs_7 = "AwsFsbp_1_0_Efs_7",
    AwsFsbp_1_0_Eks = "AwsFsbp_1_0_Eks",
    AwsFsbp_1_0_Eks_1 = "AwsFsbp_1_0_Eks_1",
    AwsFsbp_1_0_Eks_2 = "AwsFsbp_1_0_Eks_2",
    AwsFsbp_1_0_Eks_3 = "AwsFsbp_1_0_Eks_3",
    AwsFsbp_1_0_Eks_8 = "AwsFsbp_1_0_Eks_8",
    AwsFsbp_1_0_ElastiCache = "AwsFsbp_1_0_ElastiCache",
    AwsFsbp_1_0_ElastiCache_1 = "AwsFsbp_1_0_ElastiCache_1",
    AwsFsbp_1_0_ElastiCache_2 = "AwsFsbp_1_0_ElastiCache_2",
    AwsFsbp_1_0_ElastiCache_3 = "AwsFsbp_1_0_ElastiCache_3",
    AwsFsbp_1_0_ElastiCache_4 = "AwsFsbp_1_0_ElastiCache_4",
    AwsFsbp_1_0_ElastiCache_5 = "AwsFsbp_1_0_ElastiCache_5",
    AwsFsbp_1_0_ElastiCache_6 = "AwsFsbp_1_0_ElastiCache_6",
    AwsFsbp_1_0_ElastiCache_7 = "AwsFsbp_1_0_ElastiCache_7",
    AwsFsbp_1_0_ElasticBeanstalk = "AwsFsbp_1_0_ElasticBeanstalk",
    AwsFsbp_1_0_ElasticBeanstalk_1 = "AwsFsbp_1_0_ElasticBeanstalk_1",
    AwsFsbp_1_0_ElasticBeanstalk_2 = "AwsFsbp_1_0_ElasticBeanstalk_2",
    AwsFsbp_1_0_ElasticBeanstalk_3 = "AwsFsbp_1_0_ElasticBeanstalk_3",
    AwsFsbp_1_0_Elb = "AwsFsbp_1_0_Elb",
    AwsFsbp_1_0_Elb_1 = "AwsFsbp_1_0_Elb_1",
    AwsFsbp_1_0_Elb_2 = "AwsFsbp_1_0_Elb_2",
    AwsFsbp_1_0_Elb_3 = "AwsFsbp_1_0_Elb_3",
    AwsFsbp_1_0_Elb_4 = "AwsFsbp_1_0_Elb_4",
    AwsFsbp_1_0_Elb_5 = "AwsFsbp_1_0_Elb_5",
    AwsFsbp_1_0_Elb_6 = "AwsFsbp_1_0_Elb_6",
    AwsFsbp_1_0_Elb_7 = "AwsFsbp_1_0_Elb_7",
    AwsFsbp_1_0_Elb_8 = "AwsFsbp_1_0_Elb_8",
    AwsFsbp_1_0_Elb_9 = "AwsFsbp_1_0_Elb_9",
    AwsFsbp_1_0_Elb_10 = "AwsFsbp_1_0_Elb_10",
    AwsFsbp_1_0_Elb_12 = "AwsFsbp_1_0_Elb_12",
    AwsFsbp_1_0_Elb_13 = "AwsFsbp_1_0_Elb_13",
    AwsFsbp_1_0_Elb_14 = "AwsFsbp_1_0_Elb_14",
    AwsFsbp_1_0_Emr = "AwsFsbp_1_0_Emr",
    AwsFsbp_1_0_Emr_1 = "AwsFsbp_1_0_Emr_1",
    AwsFsbp_1_0_Emr_2 = "AwsFsbp_1_0_Emr_2",
    AwsFsbp_1_0_Es = "AwsFsbp_1_0_Es",
    AwsFsbp_1_0_Es_1 = "AwsFsbp_1_0_Es_1",
    AwsFsbp_1_0_Es_2 = "AwsFsbp_1_0_Es_2",
    AwsFsbp_1_0_Es_3 = "AwsFsbp_1_0_Es_3",
    AwsFsbp_1_0_Es_4 = "AwsFsbp_1_0_Es_4",
    AwsFsbp_1_0_Es_5 = "AwsFsbp_1_0_Es_5",
    AwsFsbp_1_0_Es_6 = "AwsFsbp_1_0_Es_6",
    AwsFsbp_1_0_Es_7 = "AwsFsbp_1_0_Es_7",
    AwsFsbp_1_0_Es_8 = "AwsFsbp_1_0_Es_8",
    AwsFsbp_1_0_EventBridge = "AwsFsbp_1_0_EventBridge",
    AwsFsbp_1_0_EventBridge_3 = "AwsFsbp_1_0_EventBridge_3",
    AwsFsbp_1_0_Fsx = "AwsFsbp_1_0_Fsx",
    AwsFsbp_1_0_Fsx_1 = "AwsFsbp_1_0_Fsx_1",
    AwsFsbp_1_0_Fsx_2 = "AwsFsbp_1_0_Fsx_2",
    AwsFsbp_1_0_Glue = "AwsFsbp_1_0_Glue",
    AwsFsbp_1_0_Glue_2 = "AwsFsbp_1_0_Glue_2",
    AwsFsbp_1_0_Glue_3 = "AwsFsbp_1_0_Glue_3",
    AwsFsbp_1_0_GuardDuty = "AwsFsbp_1_0_GuardDuty",
    AwsFsbp_1_0_GuardDuty_1 = "AwsFsbp_1_0_GuardDuty_1",
    AwsFsbp_1_0_GuardDuty_5 = "AwsFsbp_1_0_GuardDuty_5",
    AwsFsbp_1_0_GuardDuty_6 = "AwsFsbp_1_0_GuardDuty_6",
    AwsFsbp_1_0_GuardDuty_8 = "AwsFsbp_1_0_GuardDuty_8",
    AwsFsbp_1_0_GuardDuty_9 = "AwsFsbp_1_0_GuardDuty_9",
    AwsFsbp_1_0_GuardDuty_10 = "AwsFsbp_1_0_GuardDuty_10",
    AwsFsbp_1_0_Iam = "AwsFsbp_1_0_Iam",
    AwsFsbp_1_0_Iam_1 = "AwsFsbp_1_0_Iam_1",
    AwsFsbp_1_0_Iam_2 = "AwsFsbp_1_0_Iam_2",
    AwsFsbp_1_0_Iam_3 = "AwsFsbp_1_0_Iam_3",
    AwsFsbp_1_0_Iam_4 = "AwsFsbp_1_0_Iam_4",
    AwsFsbp_1_0_Iam_5 = "AwsFsbp_1_0_Iam_5",
    AwsFsbp_1_0_Iam_6 = "AwsFsbp_1_0_Iam_6",
    AwsFsbp_1_0_Iam_7 = "AwsFsbp_1_0_Iam_7",
    AwsFsbp_1_0_Iam_8 = "AwsFsbp_1_0_Iam_8",
    AwsFsbp_1_0_Iam_21 = "AwsFsbp_1_0_Iam_21",
    AwsFsbp_1_0_Inspector = "AwsFsbp_1_0_Inspector",
    AwsFsbp_1_0_Inspector_1 = "AwsFsbp_1_0_Inspector_1",
    AwsFsbp_1_0_Inspector_2 = "AwsFsbp_1_0_Inspector_2",
    AwsFsbp_1_0_Inspector_3 = "AwsFsbp_1_0_Inspector_3",
    AwsFsbp_1_0_Inspector_4 = "AwsFsbp_1_0_Inspector_4",
    AwsFsbp_1_0_Kinesis = "AwsFsbp_1_0_Kinesis",
    AwsFsbp_1_0_Kinesis_1 = "AwsFsbp_1_0_Kinesis_1",
    AwsFsbp_1_0_Kms = "AwsFsbp_1_0_Kms",
    AwsFsbp_1_0_Kms_1 = "AwsFsbp_1_0_Kms_1",
    AwsFsbp_1_0_Kms_2 = "AwsFsbp_1_0_Kms_2",
    AwsFsbp_1_0_Kms_3 = "AwsFsbp_1_0_Kms_3",
    AwsFsbp_1_0_Lambda = "AwsFsbp_1_0_Lambda",
    AwsFsbp_1_0_Lambda_1 = "AwsFsbp_1_0_Lambda_1",
    AwsFsbp_1_0_Lambda_2 = "AwsFsbp_1_0_Lambda_2",
    AwsFsbp_1_0_Lambda_3 = "AwsFsbp_1_0_Lambda_3",
    AwsFsbp_1_0_Lambda_5 = "AwsFsbp_1_0_Lambda_5",
    AwsFsbp_1_0_Macie = "AwsFsbp_1_0_Macie",
    AwsFsbp_1_0_Macie_1 = "AwsFsbp_1_0_Macie_1",
    AwsFsbp_1_0_Macie_2 = "AwsFsbp_1_0_Macie_2",
    AwsFsbp_1_0_Mq = "AwsFsbp_1_0_Mq",
    AwsFsbp_1_0_Mq_2 = "AwsFsbp_1_0_Mq_2",
    AwsFsbp_1_0_Mq_3 = "AwsFsbp_1_0_Mq_3",
    AwsFsbp_1_0_Msk = "AwsFsbp_1_0_Msk",
    AwsFsbp_1_0_Msk_1 = "AwsFsbp_1_0_Msk_1",
    AwsFsbp_1_0_Neptune = "AwsFsbp_1_0_Neptune",
    AwsFsbp_1_0_Neptune_1 = "AwsFsbp_1_0_Neptune_1",
    AwsFsbp_1_0_Neptune_2 = "AwsFsbp_1_0_Neptune_2",
    AwsFsbp_1_0_Neptune_3 = "AwsFsbp_1_0_Neptune_3",
    AwsFsbp_1_0_Neptune_4 = "AwsFsbp_1_0_Neptune_4",
    AwsFsbp_1_0_Neptune_5 = "AwsFsbp_1_0_Neptune_5",
    AwsFsbp_1_0_Neptune_6 = "AwsFsbp_1_0_Neptune_6",
    AwsFsbp_1_0_Neptune_7 = "AwsFsbp_1_0_Neptune_7",
    AwsFsbp_1_0_Neptune_8 = "AwsFsbp_1_0_Neptune_8",
    AwsFsbp_1_0_NetworkFirewall = "AwsFsbp_1_0_NetworkFirewall",
    AwsFsbp_1_0_NetworkFirewall_2 = "AwsFsbp_1_0_NetworkFirewall_2",
    AwsFsbp_1_0_NetworkFirewall_3 = "AwsFsbp_1_0_NetworkFirewall_3",
    AwsFsbp_1_0_NetworkFirewall_4 = "AwsFsbp_1_0_NetworkFirewall_4",
    AwsFsbp_1_0_NetworkFirewall_5 = "AwsFsbp_1_0_NetworkFirewall_5",
    AwsFsbp_1_0_NetworkFirewall_6 = "AwsFsbp_1_0_NetworkFirewall_6",
    AwsFsbp_1_0_NetworkFirewall_9 = "AwsFsbp_1_0_NetworkFirewall_9",
    AwsFsbp_1_0_Opensearch = "AwsFsbp_1_0_Opensearch",
    AwsFsbp_1_0_Opensearch_1 = "AwsFsbp_1_0_Opensearch_1",
    AwsFsbp_1_0_Opensearch_2 = "AwsFsbp_1_0_Opensearch_2",
    AwsFsbp_1_0_Opensearch_3 = "AwsFsbp_1_0_Opensearch_3",
    AwsFsbp_1_0_Opensearch_4 = "AwsFsbp_1_0_Opensearch_4",
    AwsFsbp_1_0_Opensearch_5 = "AwsFsbp_1_0_Opensearch_5",
    AwsFsbp_1_0_Opensearch_6 = "AwsFsbp_1_0_Opensearch_6",
    AwsFsbp_1_0_Opensearch_7 = "AwsFsbp_1_0_Opensearch_7",
    AwsFsbp_1_0_Opensearch_8 = "AwsFsbp_1_0_Opensearch_8",
    AwsFsbp_1_0_Opensearch_10 = "AwsFsbp_1_0_Opensearch_10",
    AwsFsbp_1_0_Pca = "AwsFsbp_1_0_Pca",
    AwsFsbp_1_0_Pca_1 = "AwsFsbp_1_0_Pca_1",
    AwsFsbp_1_0_Rds = "AwsFsbp_1_0_Rds",
    AwsFsbp_1_0_Rds_1 = "AwsFsbp_1_0_Rds_1",
    AwsFsbp_1_0_Rds_2 = "AwsFsbp_1_0_Rds_2",
    AwsFsbp_1_0_Rds_3 = "AwsFsbp_1_0_Rds_3",
    AwsFsbp_1_0_Rds_4 = "AwsFsbp_1_0_Rds_4",
    AwsFsbp_1_0_Rds_5 = "AwsFsbp_1_0_Rds_5",
    AwsFsbp_1_0_Rds_6 = "AwsFsbp_1_0_Rds_6",
    AwsFsbp_1_0_Rds_7 = "AwsFsbp_1_0_Rds_7",
    AwsFsbp_1_0_Rds_8 = "AwsFsbp_1_0_Rds_8",
    AwsFsbp_1_0_Rds_9 = "AwsFsbp_1_0_Rds_9",
    AwsFsbp_1_0_Rds_10 = "AwsFsbp_1_0_Rds_10",
    AwsFsbp_1_0_Rds_11 = "AwsFsbp_1_0_Rds_11",
    AwsFsbp_1_0_Rds_12 = "AwsFsbp_1_0_Rds_12",
    AwsFsbp_1_0_Rds_13 = "AwsFsbp_1_0_Rds_13",
    AwsFsbp_1_0_Rds_14 = "AwsFsbp_1_0_Rds_14",
    AwsFsbp_1_0_Rds_15 = "AwsFsbp_1_0_Rds_15",
    AwsFsbp_1_0_Rds_16 = "AwsFsbp_1_0_Rds_16",
    AwsFsbp_1_0_Rds_17 = "AwsFsbp_1_0_Rds_17",
    AwsFsbp_1_0_Rds_18 = "AwsFsbp_1_0_Rds_18",
    AwsFsbp_1_0_Rds_19 = "AwsFsbp_1_0_Rds_19",
    AwsFsbp_1_0_Rds_20 = "AwsFsbp_1_0_Rds_20",
    AwsFsbp_1_0_Rds_21 = "AwsFsbp_1_0_Rds_21",
    AwsFsbp_1_0_Rds_22 = "AwsFsbp_1_0_Rds_22",
    AwsFsbp_1_0_Rds_23 = "AwsFsbp_1_0_Rds_23",
    AwsFsbp_1_0_Rds_24 = "AwsFsbp_1_0_Rds_24",
    AwsFsbp_1_0_Rds_25 = "AwsFsbp_1_0_Rds_25",
    AwsFsbp_1_0_Rds_27 = "AwsFsbp_1_0_Rds_27",
    AwsFsbp_1_0_Rds_34 = "AwsFsbp_1_0_Rds_34",
    AwsFsbp_1_0_Rds_35 = "AwsFsbp_1_0_Rds_35",
    AwsFsbp_1_0_Redshift = "AwsFsbp_1_0_Redshift",
    AwsFsbp_1_0_Redshift_1 = "AwsFsbp_1_0_Redshift_1",
    AwsFsbp_1_0_Redshift_2 = "AwsFsbp_1_0_Redshift_2",
    AwsFsbp_1_0_Redshift_3 = "AwsFsbp_1_0_Redshift_3",
    AwsFsbp_1_0_Redshift_4 = "AwsFsbp_1_0_Redshift_4",
    AwsFsbp_1_0_Redshift_6 = "AwsFsbp_1_0_Redshift_6",
    AwsFsbp_1_0_Redshift_7 = "AwsFsbp_1_0_Redshift_7",
    AwsFsbp_1_0_Redshift_8 = "AwsFsbp_1_0_Redshift_8",
    AwsFsbp_1_0_Redshift_9 = "AwsFsbp_1_0_Redshift_9",
    AwsFsbp_1_0_Redshift_10 = "AwsFsbp_1_0_Redshift_10",
    AwsFsbp_1_0_Redshift_15 = "AwsFsbp_1_0_Redshift_15",
    AwsFsbp_1_0_Route53 = "AwsFsbp_1_0_Route53",
    AwsFsbp_1_0_Route53_2 = "AwsFsbp_1_0_Route53_2",
    AwsFsbp_1_0_S3 = "AwsFsbp_1_0_S3",
    AwsFsbp_1_0_S3_1 = "AwsFsbp_1_0_S3_1",
    AwsFsbp_1_0_S3_2 = "AwsFsbp_1_0_S3_2",
    AwsFsbp_1_0_S3_3 = "AwsFsbp_1_0_S3_3",
    AwsFsbp_1_0_S3_5 = "AwsFsbp_1_0_S3_5",
    AwsFsbp_1_0_S3_6 = "AwsFsbp_1_0_S3_6",
    AwsFsbp_1_0_S3_8 = "AwsFsbp_1_0_S3_8",
    AwsFsbp_1_0_S3_9 = "AwsFsbp_1_0_S3_9",
    AwsFsbp_1_0_S3_12 = "AwsFsbp_1_0_S3_12",
    AwsFsbp_1_0_S3_13 = "AwsFsbp_1_0_S3_13",
    AwsFsbp_1_0_S3_19 = "AwsFsbp_1_0_S3_19",
    AwsFsbp_1_0_SageMaker = "AwsFsbp_1_0_SageMaker",
    AwsFsbp_1_0_SageMaker_1 = "AwsFsbp_1_0_SageMaker_1",
    AwsFsbp_1_0_SageMaker_2 = "AwsFsbp_1_0_SageMaker_2",
    AwsFsbp_1_0_SageMaker_3 = "AwsFsbp_1_0_SageMaker_3",
    AwsFsbp_1_0_SageMaker_4 = "AwsFsbp_1_0_SageMaker_4",
    AwsFsbp_1_0_SecretsManager = "AwsFsbp_1_0_SecretsManager",
    AwsFsbp_1_0_SecretsManager_1 = "AwsFsbp_1_0_SecretsManager_1",
    AwsFsbp_1_0_SecretsManager_2 = "AwsFsbp_1_0_SecretsManager_2",
    AwsFsbp_1_0_SecretsManager_3 = "AwsFsbp_1_0_SecretsManager_3",
    AwsFsbp_1_0_SecretsManager_4 = "AwsFsbp_1_0_SecretsManager_4",
    AwsFsbp_1_0_ServiceCatalog = "AwsFsbp_1_0_ServiceCatalog",
    AwsFsbp_1_0_ServiceCatalog_1 = "AwsFsbp_1_0_ServiceCatalog_1",
    AwsFsbp_1_0_Sqs = "AwsFsbp_1_0_Sqs",
    AwsFsbp_1_0_Sqs_1 = "AwsFsbp_1_0_Sqs_1",
    AwsFsbp_1_0_Ssm = "AwsFsbp_1_0_Ssm",
    AwsFsbp_1_0_Ssm_1 = "AwsFsbp_1_0_Ssm_1",
    AwsFsbp_1_0_Ssm_2 = "AwsFsbp_1_0_Ssm_2",
    AwsFsbp_1_0_Ssm_3 = "AwsFsbp_1_0_Ssm_3",
    AwsFsbp_1_0_Ssm_4 = "AwsFsbp_1_0_Ssm_4",
    AwsFsbp_1_0_StepFunctions = "AwsFsbp_1_0_StepFunctions",
    AwsFsbp_1_0_StepFunctions_1 = "AwsFsbp_1_0_StepFunctions_1",
    AwsFsbp_1_0_Transfer = "AwsFsbp_1_0_Transfer",
    AwsFsbp_1_0_Transfer_2 = "AwsFsbp_1_0_Transfer_2",
    AwsFsbp_1_0_Waf = "AwsFsbp_1_0_Waf",
    AwsFsbp_1_0_Waf_1 = "AwsFsbp_1_0_Waf_1",
    AwsFsbp_1_0_Waf_2 = "AwsFsbp_1_0_Waf_2",
    AwsFsbp_1_0_Waf_3 = "AwsFsbp_1_0_Waf_3",
    AwsFsbp_1_0_Waf_4 = "AwsFsbp_1_0_Waf_4",
    AwsFsbp_1_0_Waf_6 = "AwsFsbp_1_0_Waf_6",
    AwsFsbp_1_0_Waf_7 = "AwsFsbp_1_0_Waf_7",
    AwsFsbp_1_0_Waf_8 = "AwsFsbp_1_0_Waf_8",
    AwsFsbp_1_0_Waf_10 = "AwsFsbp_1_0_Waf_10",
    AwsFsbp_1_0_Waf_12 = "AwsFsbp_1_0_Waf_12",
    AwsFsbp_1_0_WorkSpaces = "AwsFsbp_1_0_WorkSpaces",
    AwsFsbp_1_0_WorkSpaces_1 = "AwsFsbp_1_0_WorkSpaces_1",
    AwsFsbp_1_0_WorkSpaces_2 = "AwsFsbp_1_0_WorkSpaces_2",
    AwsWellArchitected = "AwsWellArchitected",
    AwsWellArchitected_Ops = "AwsWellArchitected_Ops",
    AwsWellArchitected_Ops_4 = "AwsWellArchitected_Ops_4",
    AwsWellArchitected_Ops_8 = "AwsWellArchitected_Ops_8",
    AwsWellArchitected_Ops_9 = "AwsWellArchitected_Ops_9",
    AwsWellArchitected_Sec = "AwsWellArchitected_Sec",
    AwsWellArchitected_Sec_2 = "AwsWellArchitected_Sec_2",
    AwsWellArchitected_Sec_3 = "AwsWellArchitected_Sec_3",
    AwsWellArchitected_Sec_4 = "AwsWellArchitected_Sec_4",
    AwsWellArchitected_Sec_5 = "AwsWellArchitected_Sec_5",
    AwsWellArchitected_Sec_6 = "AwsWellArchitected_Sec_6",
    AwsWellArchitected_Sec_8 = "AwsWellArchitected_Sec_8",
    AwsWellArchitected_Sec_9 = "AwsWellArchitected_Sec_9",
    CisAks_1_2 = "CisAks_1_2",
    CisAks_1_2_3_1 = "CisAks_1_2_3_1",
    CisAks_1_2_3_1_1 = "CisAks_1_2_3_1_1",
    CisAks_1_2_3_1_2 = "CisAks_1_2_3_1_2",
    CisAks_1_2_3_1_3 = "CisAks_1_2_3_1_3",
    CisAks_1_2_3_1_4 = "CisAks_1_2_3_1_4",
    CisAks_1_2_3_2 = "CisAks_1_2_3_2",
    CisAks_1_2_3_2_1 = "CisAks_1_2_3_2_1",
    CisAks_1_2_3_2_2 = "CisAks_1_2_3_2_2",
    CisAks_1_2_3_2_3 = "CisAks_1_2_3_2_3",
    CisAks_1_2_3_2_4 = "CisAks_1_2_3_2_4",
    CisAks_1_2_3_2_5 = "CisAks_1_2_3_2_5",
    CisAks_1_2_3_2_7 = "CisAks_1_2_3_2_7",
    CisAks_1_2_3_2_8 = "CisAks_1_2_3_2_8",
    CisAks_1_2_3_2_9 = "CisAks_1_2_3_2_9",
    CisAks_1_2_3_2_10 = "CisAks_1_2_3_2_10",
    CisAks_1_2_4_1 = "CisAks_1_2_4_1",
    CisAks_1_2_4_1_1 = "CisAks_1_2_4_1_1",
    CisAks_1_2_4_1_5 = "CisAks_1_2_4_1_5",
    CisAks_1_2_4_2 = "CisAks_1_2_4_2",
    CisAks_1_2_4_2_1 = "CisAks_1_2_4_2_1",
    CisAks_1_2_4_2_2 = "CisAks_1_2_4_2_2",
    CisAks_1_2_4_2_3 = "CisAks_1_2_4_2_3",
    CisAks_1_2_4_2_4 = "CisAks_1_2_4_2_4",
    CisAks_1_2_4_2_5 = "CisAks_1_2_4_2_5",
    CisAks_1_2_4_2_6 = "CisAks_1_2_4_2_6",
    CisAks_1_2_4_2_7 = "CisAks_1_2_4_2_7",
    CisAks_1_2_4_2_8 = "CisAks_1_2_4_2_8",
    CisAks_1_2_4_5 = "CisAks_1_2_4_5",
    CisAks_1_2_4_5_1 = "CisAks_1_2_4_5_1",
    CisAks_1_2_4_7 = "CisAks_1_2_4_7",
    CisAks_1_2_4_7_1 = "CisAks_1_2_4_7_1",
    CisAks_1_2_4_7_3 = "CisAks_1_2_4_7_3",
    CisAks_1_2_5_3 = "CisAks_1_2_5_3",
    CisAks_1_2_5_3_1 = "CisAks_1_2_5_3_1",
    CisAks_1_2_5_4 = "CisAks_1_2_5_4",
    CisAks_1_2_5_4_1 = "CisAks_1_2_5_4_1",
    CisAks_1_2_5_4_3 = "CisAks_1_2_5_4_3",
    CisAks_1_2_5_5 = "CisAks_1_2_5_5",
    CisAks_1_2_5_5_1 = "CisAks_1_2_5_5_1",
    CisAws_2_0 = "CisAws_2_0",
    CisAws_2_0_1 = "CisAws_2_0_1",
    CisAws_2_0_1_4 = "CisAws_2_0_1_4",
    CisAws_2_0_1_5 = "CisAws_2_0_1_5",
    CisAws_2_0_1_7 = "CisAws_2_0_1_7",
    CisAws_2_0_1_8 = "CisAws_2_0_1_8",
    CisAws_2_0_1_9 = "CisAws_2_0_1_9",
    CisAws_2_0_1_10 = "CisAws_2_0_1_10",
    CisAws_2_0_1_12 = "CisAws_2_0_1_12",
    CisAws_2_0_1_13 = "CisAws_2_0_1_13",
    CisAws_2_0_1_14 = "CisAws_2_0_1_14",
    CisAws_2_0_1_15 = "CisAws_2_0_1_15",
    CisAws_2_0_1_16 = "CisAws_2_0_1_16",
    CisAws_2_0_1_17 = "CisAws_2_0_1_17",
    CisAws_2_0_1_18 = "CisAws_2_0_1_18",
    CisAws_2_0_1_19 = "CisAws_2_0_1_19",
    CisAws_2_0_1_20 = "CisAws_2_0_1_20",
    CisAws_2_0_2 = "CisAws_2_0_2",
    CisAws_2_0_2_1_1 = "CisAws_2_0_2_1_1",
    CisAws_2_0_2_1_2 = "CisAws_2_0_2_1_2",
    CisAws_2_0_2_1_4 = "CisAws_2_0_2_1_4",
    CisAws_2_0_2_2_1 = "CisAws_2_0_2_2_1",
    CisAws_2_0_2_3_1 = "CisAws_2_0_2_3_1",
    CisAws_2_0_2_3_2 = "CisAws_2_0_2_3_2",
    CisAws_2_0_2_3_3 = "CisAws_2_0_2_3_3",
    CisAws_2_0_2_4_1 = "CisAws_2_0_2_4_1",
    CisAws_2_0_3 = "CisAws_2_0_3",
    CisAws_2_0_3_1 = "CisAws_2_0_3_1",
    CisAws_2_0_3_2 = "CisAws_2_0_3_2",
    CisAws_2_0_3_3 = "CisAws_2_0_3_3",
    CisAws_2_0_3_4 = "CisAws_2_0_3_4",
    CisAws_2_0_3_5 = "CisAws_2_0_3_5",
    CisAws_2_0_3_6 = "CisAws_2_0_3_6",
    CisAws_2_0_3_7 = "CisAws_2_0_3_7",
    CisAws_2_0_3_8 = "CisAws_2_0_3_8",
    CisAws_2_0_3_9 = "CisAws_2_0_3_9",
    CisAws_2_0_3_10 = "CisAws_2_0_3_10",
    CisAws_2_0_3_11 = "CisAws_2_0_3_11",
    CisAws_2_0_4 = "CisAws_2_0_4",
    CisAws_2_0_4_1 = "CisAws_2_0_4_1",
    CisAws_2_0_4_2 = "CisAws_2_0_4_2",
    CisAws_2_0_4_3 = "CisAws_2_0_4_3",
    CisAws_2_0_4_4 = "CisAws_2_0_4_4",
    CisAws_2_0_4_5 = "CisAws_2_0_4_5",
    CisAws_2_0_4_6 = "CisAws_2_0_4_6",
    CisAws_2_0_4_7 = "CisAws_2_0_4_7",
    CisAws_2_0_4_8 = "CisAws_2_0_4_8",
    CisAws_2_0_4_9 = "CisAws_2_0_4_9",
    CisAws_2_0_4_10 = "CisAws_2_0_4_10",
    CisAws_2_0_4_11 = "CisAws_2_0_4_11",
    CisAws_2_0_4_12 = "CisAws_2_0_4_12",
    CisAws_2_0_4_13 = "CisAws_2_0_4_13",
    CisAws_2_0_4_14 = "CisAws_2_0_4_14",
    CisAws_2_0_4_15 = "CisAws_2_0_4_15",
    CisAws_2_0_4_16 = "CisAws_2_0_4_16",
    CisAws_2_0_5 = "CisAws_2_0_5",
    CisAws_2_0_5_1 = "CisAws_2_0_5_1",
    CisAws_2_0_5_2 = "CisAws_2_0_5_2",
    CisAws_2_0_5_3 = "CisAws_2_0_5_3",
    CisAws_2_0_5_4 = "CisAws_2_0_5_4",
    CisAws_2_0_5_6 = "CisAws_2_0_5_6",
    CisAws_3_0 = "CisAws_3_0",
    CisAws_3_0_1 = "CisAws_3_0_1",
    CisAws_3_0_1_4 = "CisAws_3_0_1_4",
    CisAws_3_0_1_5 = "CisAws_3_0_1_5",
    CisAws_3_0_1_7 = "CisAws_3_0_1_7",
    CisAws_3_0_1_8 = "CisAws_3_0_1_8",
    CisAws_3_0_1_9 = "CisAws_3_0_1_9",
    CisAws_3_0_1_10 = "CisAws_3_0_1_10",
    CisAws_3_0_1_12 = "CisAws_3_0_1_12",
    CisAws_3_0_1_13 = "CisAws_3_0_1_13",
    CisAws_3_0_1_14 = "CisAws_3_0_1_14",
    CisAws_3_0_1_15 = "CisAws_3_0_1_15",
    CisAws_3_0_1_16 = "CisAws_3_0_1_16",
    CisAws_3_0_1_17 = "CisAws_3_0_1_17",
    CisAws_3_0_1_18 = "CisAws_3_0_1_18",
    CisAws_3_0_1_19 = "CisAws_3_0_1_19",
    CisAws_3_0_1_20 = "CisAws_3_0_1_20",
    CisAws_3_0_2 = "CisAws_3_0_2",
    CisAws_3_0_2_1_1 = "CisAws_3_0_2_1_1",
    CisAws_3_0_2_1_2 = "CisAws_3_0_2_1_2",
    CisAws_3_0_2_1_4 = "CisAws_3_0_2_1_4",
    CisAws_3_0_2_2_1 = "CisAws_3_0_2_2_1",
    CisAws_3_0_2_3_1 = "CisAws_3_0_2_3_1",
    CisAws_3_0_2_3_2 = "CisAws_3_0_2_3_2",
    CisAws_3_0_2_3_3 = "CisAws_3_0_2_3_3",
    CisAws_3_0_2_4_1 = "CisAws_3_0_2_4_1",
    CisAws_3_0_3 = "CisAws_3_0_3",
    CisAws_3_0_3_1 = "CisAws_3_0_3_1",
    CisAws_3_0_3_2 = "CisAws_3_0_3_2",
    CisAws_3_0_3_3 = "CisAws_3_0_3_3",
    CisAws_3_0_3_4 = "CisAws_3_0_3_4",
    CisAws_3_0_3_5 = "CisAws_3_0_3_5",
    CisAws_3_0_3_6 = "CisAws_3_0_3_6",
    CisAws_3_0_3_7 = "CisAws_3_0_3_7",
    CisAws_3_0_3_8 = "CisAws_3_0_3_8",
    CisAws_3_0_3_9 = "CisAws_3_0_3_9",
    CisAws_3_0_4 = "CisAws_3_0_4",
    CisAws_3_0_4_1 = "CisAws_3_0_4_1",
    CisAws_3_0_4_2 = "CisAws_3_0_4_2",
    CisAws_3_0_4_3 = "CisAws_3_0_4_3",
    CisAws_3_0_4_4 = "CisAws_3_0_4_4",
    CisAws_3_0_4_5 = "CisAws_3_0_4_5",
    CisAws_3_0_4_6 = "CisAws_3_0_4_6",
    CisAws_3_0_4_7 = "CisAws_3_0_4_7",
    CisAws_3_0_4_8 = "CisAws_3_0_4_8",
    CisAws_3_0_4_9 = "CisAws_3_0_4_9",
    CisAws_3_0_4_10 = "CisAws_3_0_4_10",
    CisAws_3_0_4_11 = "CisAws_3_0_4_11",
    CisAws_3_0_4_12 = "CisAws_3_0_4_12",
    CisAws_3_0_4_13 = "CisAws_3_0_4_13",
    CisAws_3_0_4_14 = "CisAws_3_0_4_14",
    CisAws_3_0_4_15 = "CisAws_3_0_4_15",
    CisAws_3_0_4_16 = "CisAws_3_0_4_16",
    CisAws_3_0_5 = "CisAws_3_0_5",
    CisAws_3_0_5_1 = "CisAws_3_0_5_1",
    CisAws_3_0_5_2 = "CisAws_3_0_5_2",
    CisAws_3_0_5_3 = "CisAws_3_0_5_3",
    CisAws_3_0_5_4 = "CisAws_3_0_5_4",
    CisAws_3_0_5_6 = "CisAws_3_0_5_6",
    CisAzure_2_0 = "CisAzure_2_0",
    CisAzure_2_0_1 = "CisAzure_2_0_1",
    CisAzure_2_0_1_3 = "CisAzure_2_0_1_3",
    CisAzure_2_0_1_4 = "CisAzure_2_0_1_4",
    CisAzure_2_0_1_14 = "CisAzure_2_0_1_14",
    CisAzure_2_0_1_15 = "CisAzure_2_0_1_15",
    CisAzure_2_0_1_16 = "CisAzure_2_0_1_16",
    CisAzure_2_0_3 = "CisAzure_2_0_3",
    CisAzure_2_0_3_1 = "CisAzure_2_0_3_1",
    CisAzure_2_0_3_2 = "CisAzure_2_0_3_2",
    CisAzure_2_0_3_5 = "CisAzure_2_0_3_5",
    CisAzure_2_0_3_7 = "CisAzure_2_0_3_7",
    CisAzure_2_0_3_8 = "CisAzure_2_0_3_8",
    CisAzure_2_0_3_9 = "CisAzure_2_0_3_9",
    CisAzure_2_0_3_10 = "CisAzure_2_0_3_10",
    CisAzure_2_0_3_11 = "CisAzure_2_0_3_11",
    CisAzure_2_0_3_12 = "CisAzure_2_0_3_12",
    CisAzure_2_0_3_13 = "CisAzure_2_0_3_13",
    CisAzure_2_0_3_14 = "CisAzure_2_0_3_14",
    CisAzure_2_0_3_15 = "CisAzure_2_0_3_15",
    CisAzure_2_0_4 = "CisAzure_2_0_4",
    CisAzure_2_0_4_1_1 = "CisAzure_2_0_4_1_1",
    CisAzure_2_0_4_1_2 = "CisAzure_2_0_4_1_2",
    CisAzure_2_0_4_1_3 = "CisAzure_2_0_4_1_3",
    CisAzure_2_0_4_1_4 = "CisAzure_2_0_4_1_4",
    CisAzure_2_0_4_1_5 = "CisAzure_2_0_4_1_5",
    CisAzure_2_0_4_1_6 = "CisAzure_2_0_4_1_6",
    CisAzure_2_0_4_2_1 = "CisAzure_2_0_4_2_1",
    CisAzure_2_0_4_3_1 = "CisAzure_2_0_4_3_1",
    CisAzure_2_0_4_3_2 = "CisAzure_2_0_4_3_2",
    CisAzure_2_0_4_3_3 = "CisAzure_2_0_4_3_3",
    CisAzure_2_0_4_3_4 = "CisAzure_2_0_4_3_4",
    CisAzure_2_0_4_3_5 = "CisAzure_2_0_4_3_5",
    CisAzure_2_0_4_3_6 = "CisAzure_2_0_4_3_6",
    CisAzure_2_0_4_3_7 = "CisAzure_2_0_4_3_7",
    CisAzure_2_0_4_4_1 = "CisAzure_2_0_4_4_1",
    CisAzure_2_0_4_4_2 = "CisAzure_2_0_4_4_2",
    CisAzure_2_0_4_4_3 = "CisAzure_2_0_4_4_3",
    CisAzure_2_0_4_4_4 = "CisAzure_2_0_4_4_4",
    CisAzure_2_0_4_5_1 = "CisAzure_2_0_4_5_1",
    CisAzure_2_0_4_5_2 = "CisAzure_2_0_4_5_2",
    CisAzure_2_0_5 = "CisAzure_2_0_5",
    CisAzure_2_0_5_1_1 = "CisAzure_2_0_5_1_1",
    CisAzure_2_0_5_1_2 = "CisAzure_2_0_5_1_2",
    CisAzure_2_0_5_1_3 = "CisAzure_2_0_5_1_3",
    CisAzure_2_0_5_1_4 = "CisAzure_2_0_5_1_4",
    CisAzure_2_0_5_1_5 = "CisAzure_2_0_5_1_5",
    CisAzure_2_0_6 = "CisAzure_2_0_6",
    CisAzure_2_0_6_1 = "CisAzure_2_0_6_1",
    CisAzure_2_0_6_2 = "CisAzure_2_0_6_2",
    CisAzure_2_0_6_3 = "CisAzure_2_0_6_3",
    CisAzure_2_0_7 = "CisAzure_2_0_7",
    CisAzure_2_0_7_2 = "CisAzure_2_0_7_2",
    CisAzure_2_0_8 = "CisAzure_2_0_8",
    CisAzure_2_0_8_1 = "CisAzure_2_0_8_1",
    CisAzure_2_0_8_2 = "CisAzure_2_0_8_2",
    CisAzure_2_0_8_3 = "CisAzure_2_0_8_3",
    CisAzure_2_0_8_5 = "CisAzure_2_0_8_5",
    CisAzure_2_0_8_7 = "CisAzure_2_0_8_7",
    CisAzure_2_0_9 = "CisAzure_2_0_9",
    CisAzure_2_0_9_2 = "CisAzure_2_0_9_2",
    CisEks_1_2 = "CisEks_1_2",
    CisEks_1_2_3_1 = "CisEks_1_2_3_1",
    CisEks_1_2_3_1_1 = "CisEks_1_2_3_1_1",
    CisEks_1_2_3_1_2 = "CisEks_1_2_3_1_2",
    CisEks_1_2_3_1_3 = "CisEks_1_2_3_1_3",
    CisEks_1_2_3_1_4 = "CisEks_1_2_3_1_4",
    CisEks_1_2_3_2 = "CisEks_1_2_3_2",
    CisEks_1_2_3_2_1 = "CisEks_1_2_3_2_1",
    CisEks_1_2_3_2_2 = "CisEks_1_2_3_2_2",
    CisEks_1_2_3_2_3 = "CisEks_1_2_3_2_3",
    CisEks_1_2_3_2_4 = "CisEks_1_2_3_2_4",
    CisEks_1_2_3_2_5 = "CisEks_1_2_3_2_5",
    CisEks_1_2_3_2_7 = "CisEks_1_2_3_2_7",
    CisEks_1_2_3_2_8 = "CisEks_1_2_3_2_8",
    CisEks_1_2_3_2_9 = "CisEks_1_2_3_2_9",
    CisEks_1_2_3_2_11 = "CisEks_1_2_3_2_11",
    CisEks_1_2_4_1 = "CisEks_1_2_4_1",
    CisEks_1_2_4_1_1 = "CisEks_1_2_4_1_1",
    CisEks_1_2_4_1_5 = "CisEks_1_2_4_1_5",
    CisEks_1_2_4_2 = "CisEks_1_2_4_2",
    CisEks_1_2_4_2_1 = "CisEks_1_2_4_2_1",
    CisEks_1_2_4_2_2 = "CisEks_1_2_4_2_2",
    CisEks_1_2_4_2_3 = "CisEks_1_2_4_2_3",
    CisEks_1_2_4_2_4 = "CisEks_1_2_4_2_4",
    CisEks_1_2_4_2_5 = "CisEks_1_2_4_2_5",
    CisEks_1_2_4_2_6 = "CisEks_1_2_4_2_6",
    CisEks_1_2_4_2_7 = "CisEks_1_2_4_2_7",
    CisEks_1_2_4_2_8 = "CisEks_1_2_4_2_8",
    CisEks_1_2_4_4 = "CisEks_1_2_4_4",
    CisEks_1_2_4_4_1 = "CisEks_1_2_4_4_1",
    CisEks_1_2_4_5 = "CisEks_1_2_4_5",
    CisEks_1_2_4_5_1 = "CisEks_1_2_4_5_1",
    CisEks_1_2_4_5_3 = "CisEks_1_2_4_5_3",
    CisEks_1_2_5_3 = "CisEks_1_2_5_3",
    CisEks_1_2_5_3_1 = "CisEks_1_2_5_3_1",
    CisEks_1_2_5_4 = "CisEks_1_2_5_4",
    CisEks_1_2_5_4_1 = "CisEks_1_2_5_4_1",
    CisEks_1_2_5_4_3 = "CisEks_1_2_5_4_3",
    CisGcp_1_3 = "CisGcp_1_3",
    CisGcp_1_3_1 = "CisGcp_1_3_1",
    CisGcp_1_3_1_4 = "CisGcp_1_3_1_4",
    CisGcp_1_3_1_5 = "CisGcp_1_3_1_5",
    CisGcp_1_3_1_6 = "CisGcp_1_3_1_6",
    CisGcp_1_3_1_7 = "CisGcp_1_3_1_7",
    CisGcp_1_3_1_9 = "CisGcp_1_3_1_9",
    CisGcp_1_3_1_10 = "CisGcp_1_3_1_10",
    CisGcp_1_3_1_12 = "CisGcp_1_3_1_12",
    CisGcp_1_3_1_13 = "CisGcp_1_3_1_13",
    CisGcp_1_3_1_14 = "CisGcp_1_3_1_14",
    CisGcp_1_3_1_15 = "CisGcp_1_3_1_15",
    CisGcp_1_3_2 = "CisGcp_1_3_2",
    CisGcp_1_3_2_1 = "CisGcp_1_3_2_1",
    CisGcp_1_3_2_3 = "CisGcp_1_3_2_3",
    CisGcp_1_3_2_4 = "CisGcp_1_3_2_4",
    CisGcp_1_3_2_5 = "CisGcp_1_3_2_5",
    CisGcp_1_3_2_6 = "CisGcp_1_3_2_6",
    CisGcp_1_3_2_7 = "CisGcp_1_3_2_7",
    CisGcp_1_3_2_8 = "CisGcp_1_3_2_8",
    CisGcp_1_3_2_9 = "CisGcp_1_3_2_9",
    CisGcp_1_3_2_10 = "CisGcp_1_3_2_10",
    CisGcp_1_3_2_11 = "CisGcp_1_3_2_11",
    CisGcp_1_3_2_13 = "CisGcp_1_3_2_13",
    CisGcp_1_3_2_16 = "CisGcp_1_3_2_16",
    CisGcp_1_3_3 = "CisGcp_1_3_3",
    CisGcp_1_3_3_1 = "CisGcp_1_3_3_1",
    CisGcp_1_3_3_2 = "CisGcp_1_3_3_2",
    CisGcp_1_3_3_3 = "CisGcp_1_3_3_3",
    CisGcp_1_3_3_4 = "CisGcp_1_3_3_4",
    CisGcp_1_3_3_5 = "CisGcp_1_3_3_5",
    CisGcp_1_3_3_6 = "CisGcp_1_3_3_6",
    CisGcp_1_3_3_7 = "CisGcp_1_3_3_7",
    CisGcp_1_3_3_8 = "CisGcp_1_3_3_8",
    CisGcp_1_3_3_9 = "CisGcp_1_3_3_9",
    CisGcp_1_3_4 = "CisGcp_1_3_4",
    CisGcp_1_3_4_1 = "CisGcp_1_3_4_1",
    CisGcp_1_3_4_2 = "CisGcp_1_3_4_2",
    CisGcp_1_3_4_3 = "CisGcp_1_3_4_3",
    CisGcp_1_3_4_4 = "CisGcp_1_3_4_4",
    CisGcp_1_3_4_5 = "CisGcp_1_3_4_5",
    CisGcp_1_3_4_6 = "CisGcp_1_3_4_6",
    CisGcp_1_3_4_8 = "CisGcp_1_3_4_8",
    CisGcp_1_3_4_9 = "CisGcp_1_3_4_9",
    CisGcp_1_3_4_10 = "CisGcp_1_3_4_10",
    CisGcp_1_3_4_11 = "CisGcp_1_3_4_11",
    CisGcp_1_3_5 = "CisGcp_1_3_5",
    CisGcp_1_3_5_1 = "CisGcp_1_3_5_1",
    CisGcp_1_3_5_2 = "CisGcp_1_3_5_2",
    CisGcp_1_3_6 = "CisGcp_1_3_6",
    CisGcp_1_3_6_1 = "CisGcp_1_3_6_1",
    CisGcp_1_3_6_1_2 = "CisGcp_1_3_6_1_2",
    CisGcp_1_3_6_1_3 = "CisGcp_1_3_6_1_3",
    CisGcp_1_3_6_2 = "CisGcp_1_3_6_2",
    CisGcp_1_3_6_2_1 = "CisGcp_1_3_6_2_1",
    CisGcp_1_3_6_2_2 = "CisGcp_1_3_6_2_2",
    CisGcp_1_3_6_2_3 = "CisGcp_1_3_6_2_3",
    CisGcp_1_3_6_2_4 = "CisGcp_1_3_6_2_4",
    CisGcp_1_3_6_2_5 = "CisGcp_1_3_6_2_5",
    CisGcp_1_3_6_2_6 = "CisGcp_1_3_6_2_6",
    CisGcp_1_3_6_2_7 = "CisGcp_1_3_6_2_7",
    CisGcp_1_3_6_2_8 = "CisGcp_1_3_6_2_8",
    CisGcp_1_3_6_2_9 = "CisGcp_1_3_6_2_9",
    CisGcp_1_3_6_3 = "CisGcp_1_3_6_3",
    CisGcp_1_3_6_3_1 = "CisGcp_1_3_6_3_1",
    CisGcp_1_3_6_3_2 = "CisGcp_1_3_6_3_2",
    CisGcp_1_3_6_3_3 = "CisGcp_1_3_6_3_3",
    CisGcp_1_3_6_3_4 = "CisGcp_1_3_6_3_4",
    CisGcp_1_3_6_3_5 = "CisGcp_1_3_6_3_5",
    CisGcp_1_3_6_3_6 = "CisGcp_1_3_6_3_6",
    CisGcp_1_3_6_3_7 = "CisGcp_1_3_6_3_7",
    CisGcp_1_3_6_4 = "CisGcp_1_3_6_4",
    CisGcp_1_3_6_5 = "CisGcp_1_3_6_5",
    CisGcp_1_3_6_7 = "CisGcp_1_3_6_7",
    CisGcp_1_3_7 = "CisGcp_1_3_7",
    CisGcp_1_3_7_1 = "CisGcp_1_3_7_1",
    CisGcp_1_3_7_2 = "CisGcp_1_3_7_2",
    CisGcp_1_3_7_3 = "CisGcp_1_3_7_3",
    CisGcp_2_0 = "CisGcp_2_0",
    CisGcp_2_0_1 = "CisGcp_2_0_1",
    CisGcp_2_0_1_4 = "CisGcp_2_0_1_4",
    CisGcp_2_0_1_5 = "CisGcp_2_0_1_5",
    CisGcp_2_0_1_6 = "CisGcp_2_0_1_6",
    CisGcp_2_0_1_7 = "CisGcp_2_0_1_7",
    CisGcp_2_0_1_9 = "CisGcp_2_0_1_9",
    CisGcp_2_0_1_10 = "CisGcp_2_0_1_10",
    CisGcp_2_0_1_12 = "CisGcp_2_0_1_12",
    CisGcp_2_0_1_13 = "CisGcp_2_0_1_13",
    CisGcp_2_0_1_14 = "CisGcp_2_0_1_14",
    CisGcp_2_0_1_15 = "CisGcp_2_0_1_15",
    CisGcp_2_0_2 = "CisGcp_2_0_2",
    CisGcp_2_0_2_1 = "CisGcp_2_0_2_1",
    CisGcp_2_0_2_3 = "CisGcp_2_0_2_3",
    CisGcp_2_0_2_4 = "CisGcp_2_0_2_4",
    CisGcp_2_0_2_5 = "CisGcp_2_0_2_5",
    CisGcp_2_0_2_6 = "CisGcp_2_0_2_6",
    CisGcp_2_0_2_7 = "CisGcp_2_0_2_7",
    CisGcp_2_0_2_8 = "CisGcp_2_0_2_8",
    CisGcp_2_0_2_9 = "CisGcp_2_0_2_9",
    CisGcp_2_0_2_10 = "CisGcp_2_0_2_10",
    CisGcp_2_0_2_11 = "CisGcp_2_0_2_11",
    CisGcp_2_0_2_13 = "CisGcp_2_0_2_13",
    CisGcp_2_0_2_16 = "CisGcp_2_0_2_16",
    CisGcp_2_0_3 = "CisGcp_2_0_3",
    CisGcp_2_0_3_1 = "CisGcp_2_0_3_1",
    CisGcp_2_0_3_2 = "CisGcp_2_0_3_2",
    CisGcp_2_0_3_3 = "CisGcp_2_0_3_3",
    CisGcp_2_0_3_4 = "CisGcp_2_0_3_4",
    CisGcp_2_0_3_5 = "CisGcp_2_0_3_5",
    CisGcp_2_0_3_6 = "CisGcp_2_0_3_6",
    CisGcp_2_0_3_7 = "CisGcp_2_0_3_7",
    CisGcp_2_0_3_8 = "CisGcp_2_0_3_8",
    CisGcp_2_0_3_9 = "CisGcp_2_0_3_9",
    CisGcp_2_0_4 = "CisGcp_2_0_4",
    CisGcp_2_0_4_1 = "CisGcp_2_0_4_1",
    CisGcp_2_0_4_2 = "CisGcp_2_0_4_2",
    CisGcp_2_0_4_3 = "CisGcp_2_0_4_3",
    CisGcp_2_0_4_4 = "CisGcp_2_0_4_4",
    CisGcp_2_0_4_5 = "CisGcp_2_0_4_5",
    CisGcp_2_0_4_6 = "CisGcp_2_0_4_6",
    CisGcp_2_0_4_8 = "CisGcp_2_0_4_8",
    CisGcp_2_0_4_9 = "CisGcp_2_0_4_9",
    CisGcp_2_0_4_10 = "CisGcp_2_0_4_10",
    CisGcp_2_0_4_11 = "CisGcp_2_0_4_11",
    CisGcp_2_0_5 = "CisGcp_2_0_5",
    CisGcp_2_0_5_1 = "CisGcp_2_0_5_1",
    CisGcp_2_0_5_2 = "CisGcp_2_0_5_2",
    CisGcp_2_0_6 = "CisGcp_2_0_6",
    CisGcp_2_0_6_1 = "CisGcp_2_0_6_1",
    CisGcp_2_0_6_1_2 = "CisGcp_2_0_6_1_2",
    CisGcp_2_0_6_1_3 = "CisGcp_2_0_6_1_3",
    CisGcp_2_0_6_2 = "CisGcp_2_0_6_2",
    CisGcp_2_0_6_2_1 = "CisGcp_2_0_6_2_1",
    CisGcp_2_0_6_2_2 = "CisGcp_2_0_6_2_2",
    CisGcp_2_0_6_2_3 = "CisGcp_2_0_6_2_3",
    CisGcp_2_0_6_2_4 = "CisGcp_2_0_6_2_4",
    CisGcp_2_0_6_2_5 = "CisGcp_2_0_6_2_5",
    CisGcp_2_0_6_2_6 = "CisGcp_2_0_6_2_6",
    CisGcp_2_0_6_2_7 = "CisGcp_2_0_6_2_7",
    CisGcp_2_0_6_2_8 = "CisGcp_2_0_6_2_8",
    CisGcp_2_0_6_2_9 = "CisGcp_2_0_6_2_9",
    CisGcp_2_0_6_3 = "CisGcp_2_0_6_3",
    CisGcp_2_0_6_3_1 = "CisGcp_2_0_6_3_1",
    CisGcp_2_0_6_3_2 = "CisGcp_2_0_6_3_2",
    CisGcp_2_0_6_3_3 = "CisGcp_2_0_6_3_3",
    CisGcp_2_0_6_3_4 = "CisGcp_2_0_6_3_4",
    CisGcp_2_0_6_3_5 = "CisGcp_2_0_6_3_5",
    CisGcp_2_0_6_3_6 = "CisGcp_2_0_6_3_6",
    CisGcp_2_0_6_3_7 = "CisGcp_2_0_6_3_7",
    CisGcp_2_0_6_4 = "CisGcp_2_0_6_4",
    CisGcp_2_0_6_5 = "CisGcp_2_0_6_5",
    CisGcp_2_0_6_6 = "CisGcp_2_0_6_6",
    CisGcp_2_0_6_7 = "CisGcp_2_0_6_7",
    CisGcp_2_0_7 = "CisGcp_2_0_7",
    CisGcp_2_0_7_1 = "CisGcp_2_0_7_1",
    CisGcp_2_0_7_2 = "CisGcp_2_0_7_2",
    CisGcp_2_0_7_3 = "CisGcp_2_0_7_3",
    CisGke_1_3 = "CisGke_1_3",
    CisGke_1_3_3_1 = "CisGke_1_3_3_1",
    CisGke_1_3_3_1_1 = "CisGke_1_3_3_1_1",
    CisGke_1_3_3_1_2 = "CisGke_1_3_3_1_2",
    CisGke_1_3_3_1_3 = "CisGke_1_3_3_1_3",
    CisGke_1_3_3_1_4 = "CisGke_1_3_3_1_4",
    CisGke_1_3_3_2 = "CisGke_1_3_3_2",
    CisGke_1_3_3_2_1 = "CisGke_1_3_3_2_1",
    CisGke_1_3_3_2_2 = "CisGke_1_3_3_2_2",
    CisGke_1_3_3_2_3 = "CisGke_1_3_3_2_3",
    CisGke_1_3_3_2_4 = "CisGke_1_3_3_2_4",
    CisGke_1_3_3_2_5 = "CisGke_1_3_3_2_5",
    CisGke_1_3_3_2_7 = "CisGke_1_3_3_2_7",
    CisGke_1_3_3_2_8 = "CisGke_1_3_3_2_8",
    CisGke_1_3_3_2_9 = "CisGke_1_3_3_2_9",
    CisGke_1_3_3_2_12 = "CisGke_1_3_3_2_12",
    CisGke_1_3_4_1 = "CisGke_1_3_4_1",
    CisGke_1_3_4_1_1 = "CisGke_1_3_4_1_1",
    CisGke_1_3_4_1_5 = "CisGke_1_3_4_1_5",
    CisGke_1_3_4_2 = "CisGke_1_3_4_2",
    CisGke_1_3_4_2_1 = "CisGke_1_3_4_2_1",
    CisGke_1_3_4_2_2 = "CisGke_1_3_4_2_2",
    CisGke_1_3_4_2_3 = "CisGke_1_3_4_2_3",
    CisGke_1_3_4_2_4 = "CisGke_1_3_4_2_4",
    CisGke_1_3_4_2_5 = "CisGke_1_3_4_2_5",
    CisGke_1_3_4_2_6 = "CisGke_1_3_4_2_6",
    CisGke_1_3_4_2_7 = "CisGke_1_3_4_2_7",
    CisGke_1_3_4_2_8 = "CisGke_1_3_4_2_8",
    CisGke_1_3_4_4 = "CisGke_1_3_4_4",
    CisGke_1_3_4_4_1 = "CisGke_1_3_4_4_1",
    CisGke_1_3_4_6 = "CisGke_1_3_4_6",
    CisGke_1_3_4_6_1 = "CisGke_1_3_4_6_1",
    CisGke_1_3_4_6_4 = "CisGke_1_3_4_6_4",
    CisGke_1_3_5_2 = "CisGke_1_3_5_2",
    CisGke_1_3_5_2_1 = "CisGke_1_3_5_2_1",
    CisGke_1_3_5_2_2 = "CisGke_1_3_5_2_2",
    CisGke_1_3_5_3 = "CisGke_1_3_5_3",
    CisGke_1_3_5_3_1 = "CisGke_1_3_5_3_1",
    CisGke_1_3_5_4 = "CisGke_1_3_5_4",
    CisGke_1_3_5_4_1 = "CisGke_1_3_5_4_1",
    CisGke_1_3_5_4_2 = "CisGke_1_3_5_4_2",
    CisGke_1_3_5_6 = "CisGke_1_3_5_6",
    CisGke_1_3_5_6_3 = "CisGke_1_3_5_6_3",
    CisGke_1_3_5_6_5 = "CisGke_1_3_5_6_5",
    CisGke_1_3_5_8 = "CisGke_1_3_5_8",
    CisGke_1_3_5_8_4 = "CisGke_1_3_5_8_4",
    CisKubernetes_1_8 = "CisKubernetes_1_8",
    CisKubernetes_1_8_1_2 = "CisKubernetes_1_8_1_2",
    CisKubernetes_1_8_1_2_2 = "CisKubernetes_1_8_1_2_2",
    CisKubernetes_1_8_1_2_4 = "CisKubernetes_1_8_1_2_4",
    CisKubernetes_1_8_1_2_5 = "CisKubernetes_1_8_1_2_5",
    CisKubernetes_1_8_1_2_6 = "CisKubernetes_1_8_1_2_6",
    CisKubernetes_1_8_1_2_8 = "CisKubernetes_1_8_1_2_8",
    CisKubernetes_1_8_1_2_10 = "CisKubernetes_1_8_1_2_10",
    CisKubernetes_1_8_1_2_15 = "CisKubernetes_1_8_1_2_15",
    CisKubernetes_1_8_1_2_22 = "CisKubernetes_1_8_1_2_22",
    CisKubernetes_1_8_1_2_23 = "CisKubernetes_1_8_1_2_23",
    CisKubernetes_1_8_1_2_24 = "CisKubernetes_1_8_1_2_24",
    CisKubernetes_1_8_1_2_25 = "CisKubernetes_1_8_1_2_25",
    CisKubernetes_1_8_1_2_26 = "CisKubernetes_1_8_1_2_26",
    CisKubernetes_1_8_1_2_27 = "CisKubernetes_1_8_1_2_27",
    CisKubernetes_1_8_1_2_30 = "CisKubernetes_1_8_1_2_30",
    CisKubernetes_1_8_1_3 = "CisKubernetes_1_8_1_3",
    CisKubernetes_1_8_1_3_3 = "CisKubernetes_1_8_1_3_3",
    CisKubernetes_1_8_1_3_4 = "CisKubernetes_1_8_1_3_4",
    CisKubernetes_1_8_1_3_5 = "CisKubernetes_1_8_1_3_5",
    CisKubernetes_1_8_1_3_7 = "CisKubernetes_1_8_1_3_7",
    CisKubernetes_1_8_2 = "CisKubernetes_1_8_2",
    CisKubernetes_1_8_2_1 = "CisKubernetes_1_8_2_1",
    CisKubernetes_1_8_2_2 = "CisKubernetes_1_8_2_2",
    CisKubernetes_1_8_2_3 = "CisKubernetes_1_8_2_3",
    CisKubernetes_1_8_2_4 = "CisKubernetes_1_8_2_4",
    CisKubernetes_1_8_2_5 = "CisKubernetes_1_8_2_5",
    CisKubernetes_1_8_2_6 = "CisKubernetes_1_8_2_6",
    CisKubernetes_1_8_4_1 = "CisKubernetes_1_8_4_1",
    CisKubernetes_1_8_4_1_3 = "CisKubernetes_1_8_4_1_3",
    CisKubernetes_1_8_4_1_4 = "CisKubernetes_1_8_4_1_4",
    CisKubernetes_1_8_4_1_5 = "CisKubernetes_1_8_4_1_5",
    CisKubernetes_1_8_4_1_6 = "CisKubernetes_1_8_4_1_6",
    CisKubernetes_1_8_4_2 = "CisKubernetes_1_8_4_2",
    CisKubernetes_1_8_4_2_1 = "CisKubernetes_1_8_4_2_1",
    CisKubernetes_1_8_4_2_2 = "CisKubernetes_1_8_4_2_2",
    CisKubernetes_1_8_4_2_3 = "CisKubernetes_1_8_4_2_3",
    CisKubernetes_1_8_4_2_4 = "CisKubernetes_1_8_4_2_4",
    CisKubernetes_1_8_4_2_5 = "CisKubernetes_1_8_4_2_5",
    CisKubernetes_1_8_4_2_6 = "CisKubernetes_1_8_4_2_6",
    CisKubernetes_1_8_4_2_7 = "CisKubernetes_1_8_4_2_7",
    CisKubernetes_1_8_4_2_8 = "CisKubernetes_1_8_4_2_8",
    CisKubernetes_1_8_4_2_9 = "CisKubernetes_1_8_4_2_9",
    CisKubernetes_1_8_4_2_10 = "CisKubernetes_1_8_4_2_10",
    CisKubernetes_1_8_4_2_11 = "CisKubernetes_1_8_4_2_11",
    CisKubernetes_1_8_4_2_12 = "CisKubernetes_1_8_4_2_12",
    CisKubernetes_1_8_5_1 = "CisKubernetes_1_8_5_1",
    CisKubernetes_1_8_5_1_1 = "CisKubernetes_1_8_5_1_1",
    CisKubernetes_1_8_5_1_5 = "CisKubernetes_1_8_5_1_5",
    CisKubernetes_1_8_5_2 = "CisKubernetes_1_8_5_2",
    CisKubernetes_1_8_5_2_1 = "CisKubernetes_1_8_5_2_1",
    CisKubernetes_1_8_5_2_2 = "CisKubernetes_1_8_5_2_2",
    CisKubernetes_1_8_5_2_3 = "CisKubernetes_1_8_5_2_3",
    CisKubernetes_1_8_5_2_4 = "CisKubernetes_1_8_5_2_4",
    CisKubernetes_1_8_5_2_5 = "CisKubernetes_1_8_5_2_5",
    CisKubernetes_1_8_5_2_6 = "CisKubernetes_1_8_5_2_6",
    CisKubernetes_1_8_5_2_7 = "CisKubernetes_1_8_5_2_7",
    CisKubernetes_1_8_5_2_9 = "CisKubernetes_1_8_5_2_9",
    CisKubernetes_1_8_5_2_10 = "CisKubernetes_1_8_5_2_10",
    CisKubernetes_1_8_5_4 = "CisKubernetes_1_8_5_4",
    CisKubernetes_1_8_5_4_1 = "CisKubernetes_1_8_5_4_1",
    CisKubernetes_1_8_5_7 = "CisKubernetes_1_8_5_7",
    CisKubernetes_1_8_5_7_1 = "CisKubernetes_1_8_5_7_1",
    CisKubernetes_1_8_5_7_4 = "CisKubernetes_1_8_5_7_4",
    CisOci_2_0 = "CisOci_2_0",
    CisOci_2_0_1 = "CisOci_2_0_1",
    CisOci_2_0_1_4 = "CisOci_2_0_1_4",
    CisOci_2_0_1_5 = "CisOci_2_0_1_5",
    CisOci_2_0_1_6 = "CisOci_2_0_1_6",
    CisOci_2_0_1_8 = "CisOci_2_0_1_8",
    CisOci_2_0_1_9 = "CisOci_2_0_1_9",
    CisOci_2_0_1_10 = "CisOci_2_0_1_10",
    CisOci_2_0_1_11 = "CisOci_2_0_1_11",
    CisOci_2_0_1_12 = "CisOci_2_0_1_12",
    CisOci_2_0_2 = "CisOci_2_0_2",
    CisOci_2_0_2_1 = "CisOci_2_0_2_1",
    CisOci_2_0_2_2 = "CisOci_2_0_2_2",
    CisOci_2_0_2_3 = "CisOci_2_0_2_3",
    CisOci_2_0_2_4 = "CisOci_2_0_2_4",
    CisOci_2_0_3 = "CisOci_2_0_3",
    CisOci_2_0_3_1 = "CisOci_2_0_3_1",
    CisOci_2_0_3_2 = "CisOci_2_0_3_2",
    CisOci_2_0_3_3 = "CisOci_2_0_3_3",
    CisOci_2_0_4 = "CisOci_2_0_4",
    CisOci_2_0_4_13 = "CisOci_2_0_4_13",
    CisOci_2_0_4_16 = "CisOci_2_0_4_16",
    CisOci_2_0_4_17 = "CisOci_2_0_4_17",
    CisOci_2_0_5_1 = "CisOci_2_0_5_1",
    CisOci_2_0_5_1_1 = "CisOci_2_0_5_1_1",
    CisOci_2_0_5_1_2 = "CisOci_2_0_5_1_2",
    CisOci_2_0_5_1_3 = "CisOci_2_0_5_1_3",
    CisOci_2_0_5_2 = "CisOci_2_0_5_2",
    CisOci_2_0_5_2_1 = "CisOci_2_0_5_2_1",
    CisOci_2_0_5_2_2 = "CisOci_2_0_5_2_2",
    CisOci_2_0_5_3 = "CisOci_2_0_5_3",
    CisOci_2_0_5_3_1 = "CisOci_2_0_5_3_1",
    CisOpenShift_1_5 = "CisOpenShift_1_5",
    CisOpenShift_1_5_1_2 = "CisOpenShift_1_5_1_2",
    CisOpenShift_1_5_1_2_1 = "CisOpenShift_1_5_1_2_1",
    CisOpenShift_1_5_1_2_2 = "CisOpenShift_1_5_1_2_2",
    CisOpenShift_1_5_1_2_3 = "CisOpenShift_1_5_1_2_3",
    CisOpenShift_1_5_1_2_4 = "CisOpenShift_1_5_1_2_4",
    CisOpenShift_1_5_1_2_5 = "CisOpenShift_1_5_1_2_5",
    CisOpenShift_1_5_1_2_6 = "CisOpenShift_1_5_1_2_6",
    CisOpenShift_1_5_1_2_7 = "CisOpenShift_1_5_1_2_7",
    CisOpenShift_1_5_1_2_8 = "CisOpenShift_1_5_1_2_8",
    CisOpenShift_1_5_1_2_10 = "CisOpenShift_1_5_1_2_10",
    CisOpenShift_1_5_1_2_12 = "CisOpenShift_1_5_1_2_12",
    CisOpenShift_1_5_1_2_13 = "CisOpenShift_1_5_1_2_13",
    CisOpenShift_1_5_1_2_14 = "CisOpenShift_1_5_1_2_14",
    CisOpenShift_1_5_1_2_15 = "CisOpenShift_1_5_1_2_15",
    CisOpenShift_1_5_1_2_16 = "CisOpenShift_1_5_1_2_16",
    CisOpenShift_1_5_1_2_18 = "CisOpenShift_1_5_1_2_18",
    CisOpenShift_1_5_1_2_25 = "CisOpenShift_1_5_1_2_25",
    CisOpenShift_1_5_1_2_26 = "CisOpenShift_1_5_1_2_26",
    CisOpenShift_1_5_1_2_27 = "CisOpenShift_1_5_1_2_27",
    CisOpenShift_1_5_1_2_28 = "CisOpenShift_1_5_1_2_28",
    CisOpenShift_1_5_1_2_29 = "CisOpenShift_1_5_1_2_29",
    CisOpenShift_1_5_1_2_30 = "CisOpenShift_1_5_1_2_30",
    CisOpenShift_1_5_1_2_31 = "CisOpenShift_1_5_1_2_31",
    CisOpenShift_1_5_1_2_32 = "CisOpenShift_1_5_1_2_32",
    CisOpenShift_1_5_1_3 = "CisOpenShift_1_5_1_3",
    CisOpenShift_1_5_1_3_2 = "CisOpenShift_1_5_1_3_2",
    CisOpenShift_1_5_1_3_3 = "CisOpenShift_1_5_1_3_3",
    CisOpenShift_1_5_1_3_4 = "CisOpenShift_1_5_1_3_4",
    CisOpenShift_1_5_1_3_5 = "CisOpenShift_1_5_1_3_5",
    CisOpenShift_1_5_1_4 = "CisOpenShift_1_5_1_4",
    CisOpenShift_1_5_1_4_1 = "CisOpenShift_1_5_1_4_1",
    CisOpenShift_1_5_2 = "CisOpenShift_1_5_2",
    CisOpenShift_1_5_2_1 = "CisOpenShift_1_5_2_1",
    CisOpenShift_1_5_2_2 = "CisOpenShift_1_5_2_2",
    CisOpenShift_1_5_2_3 = "CisOpenShift_1_5_2_3",
    CisOpenShift_1_5_2_4 = "CisOpenShift_1_5_2_4",
    CisOpenShift_1_5_2_5 = "CisOpenShift_1_5_2_5",
    CisOpenShift_1_5_2_6 = "CisOpenShift_1_5_2_6",
    CisOpenShift_1_5_3_1 = "CisOpenShift_1_5_3_1",
    CisOpenShift_1_5_3_1_1 = "CisOpenShift_1_5_3_1_1",
    CisOpenShift_1_5_4_2 = "CisOpenShift_1_5_4_2",
    CisOpenShift_1_5_4_2_2 = "CisOpenShift_1_5_4_2_2",
    CisOpenShift_1_5_4_2_3 = "CisOpenShift_1_5_4_2_3",
    CisOpenShift_1_5_4_2_4 = "CisOpenShift_1_5_4_2_4",
    CisOpenShift_1_5_4_2_5 = "CisOpenShift_1_5_4_2_5",
    CisOpenShift_1_5_4_2_6 = "CisOpenShift_1_5_4_2_6",
    CisOpenShift_1_5_4_2_7 = "CisOpenShift_1_5_4_2_7",
    CisOpenShift_1_5_4_2_8 = "CisOpenShift_1_5_4_2_8",
    CisOpenShift_1_5_4_2_9 = "CisOpenShift_1_5_4_2_9",
    CisOpenShift_1_5_4_2_10 = "CisOpenShift_1_5_4_2_10",
    CisOpenShift_1_5_4_2_11 = "CisOpenShift_1_5_4_2_11",
    CisOpenShift_1_5_4_2_12 = "CisOpenShift_1_5_4_2_12",
    CisOpenShift_1_5_5_1 = "CisOpenShift_1_5_5_1",
    CisOpenShift_1_5_5_1_1 = "CisOpenShift_1_5_5_1_1",
    CisOpenShift_1_5_5_2 = "CisOpenShift_1_5_5_2",
    CisOpenShift_1_5_5_2_1 = "CisOpenShift_1_5_5_2_1",
    CisOpenShift_1_5_5_2_2 = "CisOpenShift_1_5_5_2_2",
    CisOpenShift_1_5_5_2_3 = "CisOpenShift_1_5_5_2_3",
    CisOpenShift_1_5_5_2_4 = "CisOpenShift_1_5_5_2_4",
    CisOpenShift_1_5_5_2_5 = "CisOpenShift_1_5_5_2_5",
    CisOpenShift_1_5_5_2_6 = "CisOpenShift_1_5_5_2_6",
    CisOpenShift_1_5_5_2_8 = "CisOpenShift_1_5_5_2_8",
    CisOpenShift_1_5_5_2_9 = "CisOpenShift_1_5_5_2_9",
    CisOpenShift_1_5_5_3 = "CisOpenShift_1_5_5_3",
    CisOpenShift_1_5_5_3_1 = "CisOpenShift_1_5_5_3_1",
    CisOpenShift_1_5_5_4 = "CisOpenShift_1_5_5_4",
    CisOpenShift_1_5_5_4_1 = "CisOpenShift_1_5_5_4_1",
    CisOpenShift_1_5_5_7 = "CisOpenShift_1_5_5_7",
    CisOpenShift_1_5_5_7_1 = "CisOpenShift_1_5_5_7_1",
    CisOpenShift_1_5_5_7_4 = "CisOpenShift_1_5_5_7_4",
    CsaCcm_4_0 = "CsaCcm_4_0",
    CsaCcm_4_0_Ccc = "CsaCcm_4_0_Ccc",
    CsaCcm_4_0_Ccc_4 = "CsaCcm_4_0_Ccc_4",
    CsaCcm_4_0_Ccc_7 = "CsaCcm_4_0_Ccc_7",
    CsaCcm_4_0_Cek = "CsaCcm_4_0_Cek",
    CsaCcm_4_0_Cek_3 = "CsaCcm_4_0_Cek_3",
    CsaCcm_4_0_Cek_9 = "CsaCcm_4_0_Cek_9",
    CsaCcm_4_0_Cek_12 = "CsaCcm_4_0_Cek_12",
    CsaCcm_4_0_Iam = "CsaCcm_4_0_Iam",
    CsaCcm_4_0_Iam_2 = "CsaCcm_4_0_Iam_2",
    CsaCcm_4_0_Iam_4 = "CsaCcm_4_0_Iam_4",
    CsaCcm_4_0_Iam_5 = "CsaCcm_4_0_Iam_5",
    CsaCcm_4_0_Iam_12 = "CsaCcm_4_0_Iam_12",
    CsaCcm_4_0_Iam_14 = "CsaCcm_4_0_Iam_14",
    CsaCcm_4_0_Iam_15 = "CsaCcm_4_0_Iam_15",
    CsaCcm_4_0_Iam_16 = "CsaCcm_4_0_Iam_16",
    CsaCcm_4_0_Ipy = "CsaCcm_4_0_Ipy",
    CsaCcm_4_0_Ipy_3 = "CsaCcm_4_0_Ipy_3",
    CsaCcm_4_0_Ivs = "CsaCcm_4_0_Ivs",
    CsaCcm_4_0_Ivs_3 = "CsaCcm_4_0_Ivs_3",
    CsaCcm_4_0_Ivs_9 = "CsaCcm_4_0_Ivs_9",
    CsaCcm_4_0_Log = "CsaCcm_4_0_Log",
    CsaCcm_4_0_Log_2 = "CsaCcm_4_0_Log_2",
    CsaCcm_4_0_Log_4 = "CsaCcm_4_0_Log_4",
    CsaCcm_4_0_Log_5 = "CsaCcm_4_0_Log_5",
    CsaCcm_4_0_Log_8 = "CsaCcm_4_0_Log_8",
    CsaCcm_4_0_Tvm = "CsaCcm_4_0_Tvm",
    CsaCcm_4_0_Tvm_4 = "CsaCcm_4_0_Tvm_4",
    CsaCcm_4_0_Tvm_7 = "CsaCcm_4_0_Tvm_7",
    Essential8 = "Essential8",
    Essential8_1 = "Essential8_1",
    Essential8_1_Mfa = "Essential8_1_Mfa",
    Essential8_1_Mfa_1504 = "Essential8_1_Mfa_1504",
    Essential8_1_Mfa_1679 = "Essential8_1_Mfa_1679",
    Essential8_1_Mfa_1681 = "Essential8_1_Mfa_1681",
    Essential8_1_Mfa_1892 = "Essential8_1_Mfa_1892",
    Essential8_1_Pa = "Essential8_1_Pa",
    Essential8_1_Pa_1876 = "Essential8_1_Pa_1876",
    Essential8_1_Pos = "Essential8_1_Pos",
    Essential8_1_Pos_1501 = "Essential8_1_Pos_1501",
    Essential8_1_Pos_1877 = "Essential8_1_Pos_1877",
    Essential8_1_Rap = "Essential8_1_Rap",
    Essential8_1_Rap_1883 = "Essential8_1_Rap_1883",
    Essential8_2 = "Essential8_2",
    Essential8_2_Mfa = "Essential8_2_Mfa",
    Essential8_2_Mfa_974 = "Essential8_2_Mfa_974",
    Essential8_2_Mfa_1173 = "Essential8_2_Mfa_1173",
    Essential8_2_Mfa_1504 = "Essential8_2_Mfa_1504",
    Essential8_2_Mfa_1679 = "Essential8_2_Mfa_1679",
    Essential8_2_Mfa_1680 = "Essential8_2_Mfa_1680",
    Essential8_2_Mfa_1681 = "Essential8_2_Mfa_1681",
    Essential8_2_Mfa_1892 = "Essential8_2_Mfa_1892",
    Essential8_2_Mfa_1893 = "Essential8_2_Mfa_1893",
    Essential8_2_Pa = "Essential8_2_Pa",
    Essential8_2_Pa_1876 = "Essential8_2_Pa_1876",
    Essential8_2_Pos = "Essential8_2_Pos",
    Essential8_2_Pos_1501 = "Essential8_2_Pos_1501",
    Essential8_2_Pos_1877 = "Essential8_2_Pos_1877",
    Essential8_2_Rap = "Essential8_2_Rap",
    Essential8_2_Rap_1685 = "Essential8_2_Rap_1685",
    Essential8_2_Rap_1883 = "Essential8_2_Rap_1883",
    Essential8_3 = "Essential8_3",
    Essential8_3_Mfa = "Essential8_3_Mfa",
    Essential8_3_Mfa_974 = "Essential8_3_Mfa_974",
    Essential8_3_Mfa_1173 = "Essential8_3_Mfa_1173",
    Essential8_3_Mfa_1504 = "Essential8_3_Mfa_1504",
    Essential8_3_Mfa_1679 = "Essential8_3_Mfa_1679",
    Essential8_3_Mfa_1680 = "Essential8_3_Mfa_1680",
    Essential8_3_Mfa_1681 = "Essential8_3_Mfa_1681",
    Essential8_3_Mfa_1892 = "Essential8_3_Mfa_1892",
    Essential8_3_Mfa_1893 = "Essential8_3_Mfa_1893",
    Essential8_3_Pa = "Essential8_3_Pa",
    Essential8_3_Pa_1692 = "Essential8_3_Pa_1692",
    Essential8_3_Pa_1876 = "Essential8_3_Pa_1876",
    Essential8_3_Pos = "Essential8_3_Pos",
    Essential8_3_Pos_1501 = "Essential8_3_Pos_1501",
    Essential8_3_Pos_1877 = "Essential8_3_Pos_1877",
    Essential8_3_Pos_1879 = "Essential8_3_Pos_1879",
    Essential8_3_Pos_1903 = "Essential8_3_Pos_1903",
    Essential8_3_Rap = "Essential8_3_Rap",
    Essential8_3_Rap_1508 = "Essential8_3_Rap_1508",
    Essential8_3_Rap_1685 = "Essential8_3_Rap_1685",
    Essential8_3_Rap_1883 = "Essential8_3_Rap_1883",
    Gdpr = "Gdpr",
    Gdpr_25 = "Gdpr_25",
    Gdpr_30 = "Gdpr_30",
    Gdpr_32 = "Gdpr_32",
    Hipaa = "Hipaa",
    Hipaa_164_312_A_1 = "Hipaa_164_312_A_1",
    Hipaa_164_312_A_2_1 = "Hipaa_164_312_A_2_1",
    Hipaa_164_312_A_2_4 = "Hipaa_164_312_A_2_4",
    Hipaa_164_312_B = "Hipaa_164_312_B",
    Hipaa_164_312_C_1 = "Hipaa_164_312_C_1",
    Hipaa_164_312_D = "Hipaa_164_312_D",
    Hipaa_164_312_E_1 = "Hipaa_164_312_E_1",
    Hipaa_164_312_E_2_1 = "Hipaa_164_312_E_2_1",
    Hipaa_164_312_E_2_2 = "Hipaa_164_312_E_2_2",
    Isms_P = "Isms_P",
    Isms_P_2 = "Isms_P_2",
    Isms_P_2_5 = "Isms_P_2_5",
    Isms_P_2_5_1 = "Isms_P_2_5_1",
    Isms_P_2_5_3 = "Isms_P_2_5_3",
    Isms_P_2_5_4 = "Isms_P_2_5_4",
    Isms_P_2_5_5 = "Isms_P_2_5_5",
    Isms_P_2_5_6 = "Isms_P_2_5_6",
    Isms_P_2_6 = "Isms_P_2_6",
    Isms_P_2_6_1 = "Isms_P_2_6_1",
    Isms_P_2_6_2 = "Isms_P_2_6_2",
    Isms_P_2_6_4 = "Isms_P_2_6_4",
    Isms_P_2_7 = "Isms_P_2_7",
    Isms_P_2_7_1 = "Isms_P_2_7_1",
    Isms_P_2_7_2 = "Isms_P_2_7_2",
    Isms_P_2_9 = "Isms_P_2_9",
    Isms_P_2_9_3 = "Isms_P_2_9_3",
    Isms_P_2_9_4 = "Isms_P_2_9_4",
    Isms_P_2_9_5 = "Isms_P_2_9_5",
    Isms_P_2_9_7 = "Isms_P_2_9_7",
    Isms_P_2_10 = "Isms_P_2_10",
    Isms_P_2_10_2 = "Isms_P_2_10_2",
    Isms_P_2_10_8 = "Isms_P_2_10_8",
    Isms_P_2_10_9 = "Isms_P_2_10_9",
    Isms_P_2_11 = "Isms_P_2_11",
    Isms_P_2_11_2 = "Isms_P_2_11_2",
    Isms_P_2_11_3 = "Isms_P_2_11_3",
    Iso_27001_2022 = "Iso_27001_2022",
    Iso_27001_2022_A_5 = "Iso_27001_2022_A_5",
    Iso_27001_2022_A_5_14 = "Iso_27001_2022_A_5_14",
    Iso_27001_2022_A_5_15 = "Iso_27001_2022_A_5_15",
    Iso_27001_2022_A_5_17 = "Iso_27001_2022_A_5_17",
    Iso_27001_2022_A_5_18 = "Iso_27001_2022_A_5_18",
    Iso_27001_2022_A_5_28 = "Iso_27001_2022_A_5_28",
    Iso_27001_2022_A_8 = "Iso_27001_2022_A_8",
    Iso_27001_2022_A_8_3 = "Iso_27001_2022_A_8_3",
    Iso_27001_2022_A_8_5 = "Iso_27001_2022_A_8_5",
    Iso_27001_2022_A_8_7 = "Iso_27001_2022_A_8_7",
    Iso_27001_2022_A_8_8 = "Iso_27001_2022_A_8_8",
    Iso_27001_2022_A_8_9 = "Iso_27001_2022_A_8_9",
    Iso_27001_2022_A_8_12 = "Iso_27001_2022_A_8_12",
    Iso_27001_2022_A_8_13 = "Iso_27001_2022_A_8_13",
    Iso_27001_2022_A_8_15 = "Iso_27001_2022_A_8_15",
    Iso_27001_2022_A_8_16 = "Iso_27001_2022_A_8_16",
    Iso_27001_2022_A_8_20 = "Iso_27001_2022_A_8_20",
    Iso_27001_2022_A_8_24 = "Iso_27001_2022_A_8_24",
    Iso_27001_2022_A_8_26 = "Iso_27001_2022_A_8_26",
    Iso_27001_2022_A_8_28 = "Iso_27001_2022_A_8_28",
    Lgpd = "Lgpd",
    Lgpd_37 = "Lgpd_37",
    Lgpd_46 = "Lgpd_46",
    Lgpd_49 = "Lgpd_49",
    Mcsb_1 = "Mcsb_1",
    Mcsb_1_Am = "Mcsb_1_Am",
    Mcsb_1_Am_1 = "Mcsb_1_Am_1",
    Mcsb_1_Am_3 = "Mcsb_1_Am_3",
    Mcsb_1_Am_4 = "Mcsb_1_Am_4",
    Mcsb_1_Br = "Mcsb_1_Br",
    Mcsb_1_Br_2 = "Mcsb_1_Br_2",
    Mcsb_1_Dp = "Mcsb_1_Dp",
    Mcsb_1_Dp_2 = "Mcsb_1_Dp_2",
    Mcsb_1_Dp_3 = "Mcsb_1_Dp_3",
    Mcsb_1_Dp_4 = "Mcsb_1_Dp_4",
    Mcsb_1_Dp_6 = "Mcsb_1_Dp_6",
    Mcsb_1_Dp_7 = "Mcsb_1_Dp_7",
    Mcsb_1_Dp_8 = "Mcsb_1_Dp_8",
    Mcsb_1_Im = "Mcsb_1_Im",
    Mcsb_1_Im_1 = "Mcsb_1_Im_1",
    Mcsb_1_Im_2 = "Mcsb_1_Im_2",
    Mcsb_1_Im_3 = "Mcsb_1_Im_3",
    Mcsb_1_Im_4 = "Mcsb_1_Im_4",
    Mcsb_1_Im_6 = "Mcsb_1_Im_6",
    Mcsb_1_Im_7 = "Mcsb_1_Im_7",
    Mcsb_1_Im_8 = "Mcsb_1_Im_8",
    Mcsb_1_Ir = "Mcsb_1_Ir",
    Mcsb_1_Ir_3 = "Mcsb_1_Ir_3",
    Mcsb_1_Ir_4 = "Mcsb_1_Ir_4",
    Mcsb_1_Lt = "Mcsb_1_Lt",
    Mcsb_1_Lt_1 = "Mcsb_1_Lt_1",
    Mcsb_1_Lt_2 = "Mcsb_1_Lt_2",
    Mcsb_1_Lt_3 = "Mcsb_1_Lt_3",
    Mcsb_1_Lt_4 = "Mcsb_1_Lt_4",
    Mcsb_1_Lt_5 = "Mcsb_1_Lt_5",
    Mcsb_1_Lt_6 = "Mcsb_1_Lt_6",
    Mcsb_1_Ns = "Mcsb_1_Ns",
    Mcsb_1_Ns_1 = "Mcsb_1_Ns_1",
    Mcsb_1_Ns_2 = "Mcsb_1_Ns_2",
    Mcsb_1_Ns_3 = "Mcsb_1_Ns_3",
    Mcsb_1_Ns_6 = "Mcsb_1_Ns_6",
    Mcsb_1_Ns_8 = "Mcsb_1_Ns_8",
    Mcsb_1_Ns_10 = "Mcsb_1_Ns_10",
    Mcsb_1_Pa = "Mcsb_1_Pa",
    Mcsb_1_Pa_3 = "Mcsb_1_Pa_3",
    Mcsb_1_Pa_4 = "Mcsb_1_Pa_4",
    Mcsb_1_Pa_7 = "Mcsb_1_Pa_7",
    Mcsb_1_Pv = "Mcsb_1_Pv",
    Mcsb_1_Pv_2 = "Mcsb_1_Pv_2",
    Mcsb_1_Pv_4 = "Mcsb_1_Pv_4",
    Mcsb_1_Pv_5 = "Mcsb_1_Pv_5",
    Mitre_13 = "Mitre_13",
    Mitre_13_Ta0001 = "Mitre_13_Ta0001",
    Mitre_13_Ta0001_T1078 = "Mitre_13_Ta0001_T1078",
    Mitre_13_Ta0001_T1133 = "Mitre_13_Ta0001_T1133",
    Mitre_13_Ta0001_T1190 = "Mitre_13_Ta0001_T1190",
    Mitre_13_Ta0001_T1195 = "Mitre_13_Ta0001_T1195",
    Mitre_13_Ta0001_T1199 = "Mitre_13_Ta0001_T1199",
    Mitre_13_Ta0002 = "Mitre_13_Ta0002",
    Mitre_13_Ta0002_T1203 = "Mitre_13_Ta0002_T1203",
    Mitre_13_Ta0002_T1648 = "Mitre_13_Ta0002_T1648",
    Mitre_13_Ta0003 = "Mitre_13_Ta0003",
    Mitre_13_Ta0003_T1078 = "Mitre_13_Ta0003_T1078",
    Mitre_13_Ta0003_T1133 = "Mitre_13_Ta0003_T1133",
    Mitre_13_Ta0004 = "Mitre_13_Ta0004",
    Mitre_13_Ta0004_T1068 = "Mitre_13_Ta0004_T1068",
    Mitre_13_Ta0004_T1078 = "Mitre_13_Ta0004_T1078",
    Mitre_13_Ta0005 = "Mitre_13_Ta0005",
    Mitre_13_Ta0005_T1211 = "Mitre_13_Ta0005_T1211",
    Mitre_13_Ta0005_T1484 = "Mitre_13_Ta0005_T1484",
    Mitre_13_Ta0005_T1578 = "Mitre_13_Ta0005_T1578",
    Mitre_13_Ta0006 = "Mitre_13_Ta0006",
    Mitre_13_Ta0006_T1110 = "Mitre_13_Ta0006_T1110",
    Mitre_13_Ta0006_T1212 = "Mitre_13_Ta0006_T1212",
    Mitre_13_Ta0006_T1552 = "Mitre_13_Ta0006_T1552",
    Mitre_13_Ta0007 = "Mitre_13_Ta0007",
    Mitre_13_Ta0007_T1087 = "Mitre_13_Ta0007_T1087",
    Mitre_13_Ta0008 = "Mitre_13_Ta0008",
    Mitre_13_Ta0008_T1021_001 = "Mitre_13_Ta0008_T1021_001",
    Mitre_13_Ta0008_T1080 = "Mitre_13_Ta0008_T1080",
    Mitre_13_Ta0008_T1210 = "Mitre_13_Ta0008_T1210",
    Mitre_13_Ta0009 = "Mitre_13_Ta0009",
    Mitre_13_Ta0009_T1530 = "Mitre_13_Ta0009_T1530",
    Mitre_13_Ta0010 = "Mitre_13_Ta0010",
    Mitre_13_Ta0010_T1537 = "Mitre_13_Ta0010_T1537",
    Mitre_13_Ta0040 = "Mitre_13_Ta0040",
    Mitre_13_Ta0040_T1485 = "Mitre_13_Ta0040_T1485",
    Mitre_13_Ta0042 = "Mitre_13_Ta0042",
    Mitre_13_Ta0042_T1585 = "Mitre_13_Ta0042_T1585",
    Mitre_13_Ta0043 = "Mitre_13_Ta0043",
    Mitre_13_Ta0043_T1595 = "Mitre_13_Ta0043_T1595",
    Nist_800_53_5 = "Nist_800_53_5",
    Nist_800_53_5_Ac = "Nist_800_53_5_Ac",
    Nist_800_53_5_Ac_2 = "Nist_800_53_5_Ac_2",
    Nist_800_53_5_Ac_3 = "Nist_800_53_5_Ac_3",
    Nist_800_53_5_Ac_4 = "Nist_800_53_5_Ac_4",
    Nist_800_53_5_Ac_5 = "Nist_800_53_5_Ac_5",
    Nist_800_53_5_Ac_6 = "Nist_800_53_5_Ac_6",
    Nist_800_53_5_Ac_17 = "Nist_800_53_5_Ac_17",
    Nist_800_53_5_Ac_20 = "Nist_800_53_5_Ac_20",
    Nist_800_53_5_Au = "Nist_800_53_5_Au",
    Nist_800_53_5_Au_2 = "Nist_800_53_5_Au_2",
    Nist_800_53_5_Au_6 = "Nist_800_53_5_Au_6",
    Nist_800_53_5_Au_9 = "Nist_800_53_5_Au_9",
    Nist_800_53_5_Au_11 = "Nist_800_53_5_Au_11",
    Nist_800_53_5_Cm = "Nist_800_53_5_Cm",
    Nist_800_53_5_Cm_3 = "Nist_800_53_5_Cm_3",
    Nist_800_53_5_Ia = "Nist_800_53_5_Ia",
    Nist_800_53_5_Ia_2 = "Nist_800_53_5_Ia_2",
    Nist_800_53_5_Ia_5 = "Nist_800_53_5_Ia_5",
    Nist_800_53_5_Ia_8 = "Nist_800_53_5_Ia_8",
    Nist_800_53_5_Ir = "Nist_800_53_5_Ir",
    Nist_800_53_5_Ir_6 = "Nist_800_53_5_Ir_6",
    Nist_800_53_5_Ra = "Nist_800_53_5_Ra",
    Nist_800_53_5_Ra_5 = "Nist_800_53_5_Ra_5",
    Nist_800_53_5_Sc = "Nist_800_53_5_Sc",
    Nist_800_53_5_Sc_7 = "Nist_800_53_5_Sc_7",
    Nist_800_53_5_Sc_8 = "Nist_800_53_5_Sc_8",
    Nist_800_53_5_Sc_12 = "Nist_800_53_5_Sc_12",
    Nist_800_53_5_Sc_13 = "Nist_800_53_5_Sc_13",
    Nist_800_53_5_Sc_28 = "Nist_800_53_5_Sc_28",
    Nist_800_53_5_Si = "Nist_800_53_5_Si",
    Nist_800_53_5_Si_3 = "Nist_800_53_5_Si_3",
    Nist_800_53_5_Si_4 = "Nist_800_53_5_Si_4",
    Nist_800_53_5_Si_7 = "Nist_800_53_5_Si_7",
    Nist_800_171_2 = "Nist_800_171_2",
    Nist_800_171_2_3_1 = "Nist_800_171_2_3_1",
    Nist_800_171_2_3_1_1 = "Nist_800_171_2_3_1_1",
    Nist_800_171_2_3_1_2 = "Nist_800_171_2_3_1_2",
    Nist_800_171_2_3_1_3 = "Nist_800_171_2_3_1_3",
    Nist_800_171_2_3_1_4 = "Nist_800_171_2_3_1_4",
    Nist_800_171_2_3_1_5 = "Nist_800_171_2_3_1_5",
    Nist_800_171_2_3_1_6 = "Nist_800_171_2_3_1_6",
    Nist_800_171_2_3_1_7 = "Nist_800_171_2_3_1_7",
    Nist_800_171_2_3_1_12 = "Nist_800_171_2_3_1_12",
    Nist_800_171_2_3_1_13 = "Nist_800_171_2_3_1_13",
    Nist_800_171_2_3_1_20 = "Nist_800_171_2_3_1_20",
    Nist_800_171_2_3_3 = "Nist_800_171_2_3_3",
    Nist_800_171_2_3_3_1 = "Nist_800_171_2_3_3_1",
    Nist_800_171_2_3_3_2 = "Nist_800_171_2_3_3_2",
    Nist_800_171_2_3_3_3 = "Nist_800_171_2_3_3_3",
    Nist_800_171_2_3_3_5 = "Nist_800_171_2_3_3_5",
    Nist_800_171_2_3_3_8 = "Nist_800_171_2_3_3_8",
    Nist_800_171_2_3_3_9 = "Nist_800_171_2_3_3_9",
    Nist_800_171_2_3_4 = "Nist_800_171_2_3_4",
    Nist_800_171_2_3_4_3 = "Nist_800_171_2_3_4_3",
    Nist_800_171_2_3_4_5 = "Nist_800_171_2_3_4_5",
    Nist_800_171_2_3_4_6 = "Nist_800_171_2_3_4_6",
    Nist_800_171_2_3_4_7 = "Nist_800_171_2_3_4_7",
    Nist_800_171_2_3_4_9 = "Nist_800_171_2_3_4_9",
    Nist_800_171_2_3_5 = "Nist_800_171_2_3_5",
    Nist_800_171_2_3_5_1 = "Nist_800_171_2_3_5_1",
    Nist_800_171_2_3_5_2 = "Nist_800_171_2_3_5_2",
    Nist_800_171_2_3_5_3 = "Nist_800_171_2_3_5_3",
    Nist_800_171_2_3_5_4 = "Nist_800_171_2_3_5_4",
    Nist_800_171_2_3_5_5 = "Nist_800_171_2_3_5_5",
    Nist_800_171_2_3_5_6 = "Nist_800_171_2_3_5_6",
    Nist_800_171_2_3_5_7 = "Nist_800_171_2_3_5_7",
    Nist_800_171_2_3_5_8 = "Nist_800_171_2_3_5_8",
    Nist_800_171_2_3_6 = "Nist_800_171_2_3_6",
    Nist_800_171_2_3_6_1 = "Nist_800_171_2_3_6_1",
    Nist_800_171_2_3_6_2 = "Nist_800_171_2_3_6_2",
    Nist_800_171_2_3_8 = "Nist_800_171_2_3_8",
    Nist_800_171_2_3_8_1 = "Nist_800_171_2_3_8_1",
    Nist_800_171_2_3_8_5 = "Nist_800_171_2_3_8_5",
    Nist_800_171_2_3_8_9 = "Nist_800_171_2_3_8_9",
    Nist_800_171_2_3_11 = "Nist_800_171_2_3_11",
    Nist_800_171_2_3_11_2 = "Nist_800_171_2_3_11_2",
    Nist_800_171_2_3_13 = "Nist_800_171_2_3_13",
    Nist_800_171_2_3_13_1 = "Nist_800_171_2_3_13_1",
    Nist_800_171_2_3_13_2 = "Nist_800_171_2_3_13_2",
    Nist_800_171_2_3_13_3 = "Nist_800_171_2_3_13_3",
    Nist_800_171_2_3_13_6 = "Nist_800_171_2_3_13_6",
    Nist_800_171_2_3_13_8 = "Nist_800_171_2_3_13_8",
    Nist_800_171_2_3_13_10 = "Nist_800_171_2_3_13_10",
    Nist_800_171_2_3_13_11 = "Nist_800_171_2_3_13_11",
    Nist_800_171_2_3_13_15 = "Nist_800_171_2_3_13_15",
    Nist_800_171_2_3_13_16 = "Nist_800_171_2_3_13_16",
    Nist_800_171_2_3_14 = "Nist_800_171_2_3_14",
    Nist_800_171_2_3_14_2 = "Nist_800_171_2_3_14_2",
    Nist_800_171_2_3_14_5 = "Nist_800_171_2_3_14_5",
    Nist_800_171_2_3_14_6 = "Nist_800_171_2_3_14_6",
    Nist_800_171_2_3_14_7 = "Nist_800_171_2_3_14_7",
    NistCsf_1_1 = "NistCsf_1_1",
    NistCsf_1_1_De_Ae = "NistCsf_1_1_De_Ae",
    NistCsf_1_1_De_Ae_1 = "NistCsf_1_1_De_Ae_1",
    NistCsf_1_1_De_Ae_2 = "NistCsf_1_1_De_Ae_2",
    NistCsf_1_1_De_Cm = "NistCsf_1_1_De_Cm",
    NistCsf_1_1_De_Cm_1 = "NistCsf_1_1_De_Cm_1",
    NistCsf_1_1_De_Cm_4 = "NistCsf_1_1_De_Cm_4",
    NistCsf_1_1_De_Cm_8 = "NistCsf_1_1_De_Cm_8",
    NistCsf_1_1_Id_Be = "NistCsf_1_1_Id_Be",
    NistCsf_1_1_Id_Be_5 = "NistCsf_1_1_Id_Be_5",
    NistCsf_1_1_Id_Ra = "NistCsf_1_1_Id_Ra",
    NistCsf_1_1_Id_Ra_1 = "NistCsf_1_1_Id_Ra_1",
    NistCsf_1_1_Pr_Ac = "NistCsf_1_1_Pr_Ac",
    NistCsf_1_1_Pr_Ac_1 = "NistCsf_1_1_Pr_Ac_1",
    NistCsf_1_1_Pr_Ac_3 = "NistCsf_1_1_Pr_Ac_3",
    NistCsf_1_1_Pr_Ac_4 = "NistCsf_1_1_Pr_Ac_4",
    NistCsf_1_1_Pr_Ac_5 = "NistCsf_1_1_Pr_Ac_5",
    NistCsf_1_1_Pr_Ds = "NistCsf_1_1_Pr_Ds",
    NistCsf_1_1_Pr_Ds_1 = "NistCsf_1_1_Pr_Ds_1",
    NistCsf_1_1_Pr_Ds_2 = "NistCsf_1_1_Pr_Ds_2",
    NistCsf_1_1_Pr_Ds_5 = "NistCsf_1_1_Pr_Ds_5",
    NistCsf_1_1_Pr_Ds_6 = "NistCsf_1_1_Pr_Ds_6",
    NistCsf_1_1_Pr_Ip = "NistCsf_1_1_Pr_Ip",
    NistCsf_1_1_Pr_Ip_3 = "NistCsf_1_1_Pr_Ip_3",
    NistCsf_1_1_Pr_Ip_12 = "NistCsf_1_1_Pr_Ip_12",
    NistCsf_1_1_Pr_Ma = "NistCsf_1_1_Pr_Ma",
    NistCsf_1_1_Pr_Ma_1 = "NistCsf_1_1_Pr_Ma_1",
    NistCsf_1_1_Pr_Ma_2 = "NistCsf_1_1_Pr_Ma_2",
    NistCsf_1_1_Pr_Pt = "NistCsf_1_1_Pr_Pt",
    NistCsf_1_1_Pr_Pt_1 = "NistCsf_1_1_Pr_Pt_1",
    NistCsf_1_1_Pr_Pt_3 = "NistCsf_1_1_Pr_Pt_3",
    NistCsf_1_1_Pr_Pt_4 = "NistCsf_1_1_Pr_Pt_4",
    NistCsf_1_1_Pr_Pt_5 = "NistCsf_1_1_Pr_Pt_5",
    NistSp_800_190 = "NistSp_800_190",
    NistSp_800_190_3 = "NistSp_800_190_3",
    NistSp_800_190_3_1 = "NistSp_800_190_3_1",
    NistSp_800_190_3_1_1 = "NistSp_800_190_3_1_1",
    NistSp_800_190_3_1_2 = "NistSp_800_190_3_1_2",
    NistSp_800_190_3_1_3 = "NistSp_800_190_3_1_3",
    NistSp_800_190_3_1_4 = "NistSp_800_190_3_1_4",
    NistSp_800_190_3_2 = "NistSp_800_190_3_2",
    NistSp_800_190_3_2_2 = "NistSp_800_190_3_2_2",
    NistSp_800_190_3_2_3 = "NistSp_800_190_3_2_3",
    NistSp_800_190_3_3 = "NistSp_800_190_3_3",
    NistSp_800_190_3_3_1 = "NistSp_800_190_3_3_1",
    NistSp_800_190_3_3_2 = "NistSp_800_190_3_3_2",
    NistSp_800_190_3_3_3 = "NistSp_800_190_3_3_3",
    NistSp_800_190_3_3_5 = "NistSp_800_190_3_3_5",
    NistSp_800_190_3_4 = "NistSp_800_190_3_4",
    NistSp_800_190_3_4_1 = "NistSp_800_190_3_4_1",
    NistSp_800_190_3_4_2 = "NistSp_800_190_3_4_2",
    NistSp_800_190_3_4_3 = "NistSp_800_190_3_4_3",
    NistSp_800_190_3_5 = "NistSp_800_190_3_5",
    NistSp_800_190_3_5_1 = "NistSp_800_190_3_5_1",
    NistSp_800_190_3_5_3 = "NistSp_800_190_3_5_3",
    NistSp_800_190_6 = "NistSp_800_190_6",
    NistSp_800_190_6_4 = "NistSp_800_190_6_4",
    Nydfs_23_Nycrr_500 = "Nydfs_23_Nycrr_500",
    Nydfs_23_Nycrr_500_02 = "Nydfs_23_Nycrr_500_02",
    Nydfs_23_Nycrr_500_02_A = "Nydfs_23_Nycrr_500_02_A",
    Nydfs_23_Nycrr_500_02_B2 = "Nydfs_23_Nycrr_500_02_B2",
    Nydfs_23_Nycrr_500_02_B3 = "Nydfs_23_Nycrr_500_02_B3",
    Nydfs_23_Nycrr_500_02_B5 = "Nydfs_23_Nycrr_500_02_B5",
    Nydfs_23_Nycrr_500_05 = "Nydfs_23_Nycrr_500_05",
    Nydfs_23_Nycrr_500_06 = "Nydfs_23_Nycrr_500_06",
    Nydfs_23_Nycrr_500_07 = "Nydfs_23_Nycrr_500_07",
    Nydfs_23_Nycrr_500_11 = "Nydfs_23_Nycrr_500_11",
    Nydfs_23_Nycrr_500_12 = "Nydfs_23_Nycrr_500_12",
    Nydfs_23_Nycrr_500_14 = "Nydfs_23_Nycrr_500_14",
    Nydfs_23_Nycrr_500_15 = "Nydfs_23_Nycrr_500_15",
    PciDss_4_0 = "PciDss_4_0",
    PciDss_4_0_1 = "PciDss_4_0_1",
    PciDss_4_0_2 = "PciDss_4_0_2",
    PciDss_4_0_3 = "PciDss_4_0_3",
    PciDss_4_0_4 = "PciDss_4_0_4",
    PciDss_4_0_5 = "PciDss_4_0_5",
    PciDss_4_0_6 = "PciDss_4_0_6",
    PciDss_4_0_7 = "PciDss_4_0_7",
    PciDss_4_0_8 = "PciDss_4_0_8",
    PciDss_4_0_10 = "PciDss_4_0_10",
    Soc2 = "Soc2",
    Soc2_Cc_6_1 = "Soc2_Cc_6_1",
    Soc2_Cc_6_3 = "Soc2_Cc_6_3",
    Soc2_Cc_6_6 = "Soc2_Cc_6_6",
    Soc2_Cc_6_7 = "Soc2_Cc_6_7",
    Soc2_Cc_6_8 = "Soc2_Cc_6_8",
    Soc2_Cc_7_1 = "Soc2_Cc_7_1",
    Soc2_Cc_7_2 = "Soc2_Cc_7_2",
    Tenable = "Tenable",
    Tenable_Compute = "Tenable_Compute",
    Tenable_Data_Encryption = "Tenable_Data_Encryption",
    Tenable_Iam = "Tenable_Iam",
    Tenable_Kubernetes = "Tenable_Kubernetes",
    Tenable_Logging = "Tenable_Logging",
    Tenable_Network = "Tenable_Network",
    Tenable_Public_Resources = "Tenable_Public_Resources",
    Tenable_Secrets = "Tenable_Secrets",
    Tenable_Traffic_Encryption = "Tenable_Traffic_Encryption",
    Tenable_Workload_Protection = "Tenable_Workload_Protection"
}

export enum CiIntegrationManagerError {
    AuthenticationFailed = "AuthenticationFailed",
    EndpointNotExist = "EndpointNotExist",
    GetRepositoriesFailure = "GetRepositoriesFailure",
    IntegrationExists = "IntegrationExists",
    RegistryEmpty = "RegistryEmpty",
    RegistryNotFound = "RegistryNotFound"
}

export enum CiIntegrationModelStatus {
    AuthenticationFailed = "AuthenticationFailed",
    Deleting = "Deleting",
    EndpointNotExist = "EndpointNotExist",
    RegistryNotFound = "RegistryNotFound",
    Valid = "Valid"
}

export enum CiIntegrationStateIssue {
    AuthenticationFailed = "AuthenticationFailed",
    Deleting = "Deleting",
    EndpointNotExist = "EndpointNotExist",
    RegistryNotFound = "RegistryNotFound",
    Valid = "Valid"
}

export enum CiTenantModelStatus {
    AuthenticationFailed = "AuthenticationFailed",
    Deleting = "Deleting",
    EndpointNotExist = "EndpointNotExist",
    GetRepositoriesFailure = "GetRepositoriesFailure",
    RegistryEmpty = "RegistryEmpty",
    RegistryNotFound = "RegistryNotFound",
    TooManyRepositories = "TooManyRepositories",
    Valid = "Valid"
}

export enum CiTenantStateMonitoringIssue {
    AuthenticationFailed = "AuthenticationFailed",
    EndpointNotExist = "EndpointNotExist",
    GetRepositoriesFailure = "GetRepositoriesFailure",
    RegistryEmpty = "RegistryEmpty",
    RegistryNotFound = "RegistryNotFound",
    TooManyRepositories = "TooManyRepositories"
}

export enum CiTenantType {
    Cloudsmith = "Cloudsmith",
    General = "General",
    Jfrog = "Jfrog"
}

export enum CloudProviderTenantPermissionType {
    ContainerImageRepositoryAnalysis = "ContainerImageRepositoryAnalysis",
    DataAnalysis = "DataAnalysis",
    PermissionManagement = "PermissionManagement",
    Read = "Read",
    WorkloadAnalysis = "WorkloadAnalysis",
    Write = "Write"
}

export enum CloudRiskFilterItemType {
    RiskedEntity = "RiskedEntity",
    RiskedEntityEnvironment = "RiskedEntityEnvironment",
    RiskedEntityOwner = "RiskedEntityOwner"
}

export enum CodeControllerGetContainerImageScanPackagePageRequestProperty {
    DisplayName = "DisplayName",
    FilePath = "FilePath",
    Type = "Type",
    VulnerabilityRawId = "VulnerabilityRawId"
}

export enum CodeControllerGetContainerImageScanPageRequestProperty {
    CommitBranchName = "CommitBranchName",
    CommitIdentityName = "CommitIdentityName",
    ContainerImageConfigurationDigest = "ContainerImageConfigurationDigest",
    Digest = "Digest",
    IntegrationId = "IntegrationId",
    OperatingSystemDisplayName = "OperatingSystemDisplayName",
    OrganizationName = "OrganizationName",
    PackageDisplayName = "PackageDisplayName",
    RepositoryRawUrl = "RepositoryRawUrl",
    Success = "Success",
    Tag = "Tag",
    TenantId = "TenantId",
    Time = "Time",
    VulnerabilityRawId = "VulnerabilityRawId",
    VulnerabilitySeverity = "VulnerabilitySeverity"
}

export enum CodeControllerGetContainerImageScanVulnerabilityPageRequestProperty {
    AttackVector = "AttackVector",
    CvssScore = "CvssScore",
    Exploitable = "Exploitable",
    PackageDisplayName = "PackageDisplayName",
    PackageFilePath = "PackageFilePath",
    RawId = "RawId",
    ResolutionVersions = "ResolutionVersions",
    Severity = "Severity",
    VprScore = "VprScore",
    VprSeverity = "VprSeverity"
}

export enum CodeControllerGetIacRiskPageRequestProperty {
    Category = "Category",
    CloudTenantType = "CloudTenantType",
    ComplianceIdOrSectionType = "ComplianceIdOrSectionType",
    FileRelativePath = "FileRelativePath",
    New = "New",
    ResourceName = "ResourceName",
    ResourceTypeSystemName = "ResourceTypeSystemName",
    RiskPolicyConfigurationTypeName = "RiskPolicyConfigurationTypeName",
    Severity = "Severity"
}

export enum CodeControllerGetIacScanPageRequestProperty {
    BlockCount = "BlockCount",
    CodeType = "CodeType",
    CommitBranchName = "CommitBranchName",
    CommitIdentityName = "CommitIdentityName",
    IntegrationId = "IntegrationId",
    RelativePath = "RelativePath",
    RiskChange = "RiskChange",
    RiskSeverityToCountMap = "RiskSeverityToCountMap",
    Severity = "Severity",
    Success = "Success",
    TenantId = "TenantId",
    Time = "Time"
}

export enum CodeOrganizationModelStatus {
    AzureDevOpsAadTenantNotFound = "AzureDevOpsAadTenantNotFound",
    AzureDevOpsApplicationUnauthorized = "AzureDevOpsApplicationUnauthorized",
    AzureDevOpsOrganizationNotFound = "AzureDevOpsOrganizationNotFound",
    BitbucketAccessTokenUnauthorized = "BitbucketAccessTokenUnauthorized",
    BitbucketOrganizationMismatch = "BitbucketOrganizationMismatch",
    CodeServerIssue = "CodeServerIssue",
    Deleting = "Deleting",
    GitHubInstallationMissingBasicPermissions = "GitHubInstallationMissingBasicPermissions",
    GitHubInstallationMissingPermissions = "GitHubInstallationMissingPermissions",
    GitHubInstallationNetworkAccessDenied = "GitHubInstallationNetworkAccessDenied",
    GitHubInstallationNotFound = "GitHubInstallationNotFound",
    GitLabAccessTokenUnauthorized = "GitLabAccessTokenUnauthorized",
    GitLabOrganizationMismatch = "GitLabOrganizationMismatch",
    Valid = "Valid"
}

export enum CodeOrganizationStateIssue {
    AzureDevOpsAadTenantNotFound = "AzureDevOpsAadTenantNotFound",
    AzureDevOpsApplicationUnauthorized = "AzureDevOpsApplicationUnauthorized",
    AzureDevOpsOrganizationNotFound = "AzureDevOpsOrganizationNotFound",
    BitbucketAccessTokenUnauthorized = "BitbucketAccessTokenUnauthorized",
    BitbucketOrganizationMismatch = "BitbucketOrganizationMismatch",
    CodeServerIssue = "CodeServerIssue",
    GitHubInstallationMissingBasicPermissions = "GitHubInstallationMissingBasicPermissions",
    GitHubInstallationMissingPermissions = "GitHubInstallationMissingPermissions",
    GitHubInstallationNetworkAccessDenied = "GitHubInstallationNetworkAccessDenied",
    GitHubInstallationNotFound = "GitHubInstallationNotFound",
    GitLabAccessTokenUnauthorized = "GitLabAccessTokenUnauthorized",
    GitLabOrganizationMismatch = "GitLabOrganizationMismatch"
}

export enum CodePipelineType {
    AzurePipeline = "AzurePipeline",
    General = "General",
    GitHubWorkflow = "GitHubWorkflow",
    GitLabPipeline = "GitLabPipeline",
    JenkinsPipeline = "JenkinsPipeline",
    TerraformCloudRunTask = "TerraformCloudRunTask"
}

export enum CodeRiskFilterItemType {
    FileRelativePath = "FileRelativePath",
    ResourceName = "ResourceName",
    ResourceTypeSystemName = "ResourceTypeSystemName"
}

export enum CodeServerModelStatus {
    Deleting = "Deleting",
    EndpointInvalid = "EndpointInvalid",
    EndpointNotExist = "EndpointNotExist",
    GitHubApplicationClientIdInvalid = "GitHubApplicationClientIdInvalid",
    GitHubApplicationClientSecretInvalid = "GitHubApplicationClientSecretInvalid",
    GitHubApplicationIdInvalid = "GitHubApplicationIdInvalid",
    GitHubApplicationPrivateKeyInvalid = "GitHubApplicationPrivateKeyInvalid",
    GitLabServerResponseInvalid = "GitLabServerResponseInvalid",
    ServerCertificateValidationFailed = "ServerCertificateValidationFailed",
    Valid = "Valid"
}

export enum CodeServerStateIssue {
    EndpointInvalid = "EndpointInvalid",
    EndpointNotExist = "EndpointNotExist",
    GitHubApplicationClientIdInvalid = "GitHubApplicationClientIdInvalid",
    GitHubApplicationClientSecretInvalid = "GitHubApplicationClientSecretInvalid",
    GitHubApplicationIdInvalid = "GitHubApplicationIdInvalid",
    GitHubApplicationPrivateKeyInvalid = "GitHubApplicationPrivateKeyInvalid",
    GitLabServerResponseInvalid = "GitLabServerResponseInvalid",
    ServerCertificateValidationFailed = "ServerCertificateValidationFailed"
}

export enum CodeTenantModelStatus {
    CodeOrganizationIssue = "CodeOrganizationIssue",
    CodeServerIssue = "CodeServerIssue",
    Deleting = "Deleting",
    IacNotExist = "IacNotExist",
    TenantEmpty = "TenantEmpty",
    TenantNotFound = "TenantNotFound",
    TenantSuspended = "TenantSuspended",
    Valid = "Valid"
}

export enum CodeTenantType {
    AzureDevOps = "AzureDevOps",
    Bitbucket = "Bitbucket",
    General = "General",
    GitHub = "GitHub",
    GitLab = "GitLab"
}

export enum CodeType {
    CloudFormation = "CloudFormation",
    Kubernetes = "Kubernetes",
    Terraform = "Terraform",
    TerraformPlan = "TerraformPlan"
}

export enum CognitoAuthenticationManagerChangeUserPasswordResult {
    FailureMailOrPasswordInvalid = "FailureMailOrPasswordInvalid",
    FailureNewPasswordInvalid = "FailureNewPasswordInvalid",
    FailureVerificationCodeIncorrect = "FailureVerificationCodeIncorrect",
    Success = "Success"
}

export enum ConfigurationControllerGetJiraRawProjectDataError {
    InstanceError = "InstanceError",
    InvalidId = "InvalidId",
    MissingPermissions = "MissingPermissions",
    MissingProductPermissions = "MissingProductPermissions",
    UnsupportedProduct = "UnsupportedProduct",
    UnsupportedProject = "UnsupportedProject"
}

export enum ConfigurationControllerInsertApiKeyError {
    ApiKeyMaxCount = "ApiKeyMaxCount"
}

export enum ConfigurationControllerInsertDatadogOrganizationError {
    InvalidApiKey = "InvalidApiKey"
}

export enum ConfigurationControllerInsertGroupRoleAssignmentError {
    GroupMaxCount = "GroupMaxCount"
}

export enum ConfigurationControllerInsertUserRoleAssignmentError {
    UserMailDomainUnauthorized = "UserMailDomainUnauthorized",
    UserMaxCount = "UserMaxCount"
}

export enum ConfigurationControllerUpdateDatadogOrganizationError {
    InvalidApiKey = "InvalidApiKey"
}

export enum ConfigurationControllerUpdateSlackWorkspacesPermissionManagementEnabledError {
    PermissionManagementSlackNotEnabled = "PermissionManagementSlackNotEnabled",
    ValidPermissionManagementEnabledWorkspaceNotExists = "ValidPermissionManagementEnabledWorkspaceNotExists"
}

export enum ConfigurationControllerUpsertJiraInstanceError {
    InvalidUrl = "InvalidUrl",
    UserNotAuthenticated = "UserNotAuthenticated"
}

export enum ConfigurationControllerUpsertJiraProjectError {
    InstanceError = "InstanceError",
    InvalidConfiguration = "InvalidConfiguration",
    InvalidId = "InvalidId",
    MissingPermissions = "MissingPermissions",
    MissingProductPermissions = "MissingProductPermissions",
    UnsupportedProduct = "UnsupportedProduct",
    UnsupportedProject = "UnsupportedProject"
}

export enum ConfigurationControllerUpsertPermissionEligibilityError {
    AwsInvalidPolicyDocumentFormat = "AwsInvalidPolicyDocumentFormat",
    AwsInvalidPolicyDocumentLength = "AwsInvalidPolicyDocumentLength",
    AwsInvalidSsoPermissionSetNamePrefixFormat = "AwsInvalidSsoPermissionSetNamePrefixFormat",
    AwsInvalidSsoPermissionSetNamePrefixLength = "AwsInvalidSsoPermissionSetNamePrefixLength",
    AwsSsoPermissionSetNamePrefixExists = "AwsSsoPermissionSetNamePrefixExists",
    NameExists = "NameExists"
}

export enum ConfigurationControllerUpsertServiceNowInstanceError {
    InvalidUrl = "InvalidUrl",
    MissingPermissions = "MissingPermissions",
    UserNotAuthenticated = "UserNotAuthenticated"
}

export enum ConfigurationControllerUpsertSplunkEventCollectorError {
    ConnectionRefused = "ConnectionRefused",
    EndpointNotExist = "EndpointNotExist",
    InvalidCertificate = "InvalidCertificate",
    InvalidToken = "InvalidToken",
    InvalidUrl = "InvalidUrl",
    ServiceUnavailable = "ServiceUnavailable",
    Timeout = "Timeout"
}

export enum ConfigurationControllerUpsertSqsQueueError {
    AssumeRoleFailed = "AssumeRoleFailed",
    InvalidUrl = "InvalidUrl",
    MissingQueuePermissions = "MissingQueuePermissions",
    MissingRole = "MissingRole"
}

export enum ConfigurationControllerValidateAuthenticationSamlIdentityProviderError {
    InvalidMetadata = "InvalidMetadata"
}

export enum ConsoleAppType {
    Customer = "Customer",
    System = "System",
    Teams = "Teams",
    User = "User"
}

export enum ContainerImageDataScanStatus {
    Scanned = "Scanned",
    ErrorAccessDenied = "ErrorAccessDenied",
    ErrorScanFailure = "ErrorScanFailure",
    ErrorUnsupportedMediaType = "ErrorUnsupportedMediaType",
    NotScanned = "NotScanned"
}

export enum CustomCodeBuilderKubernetesAdmissionControllerRiskPolicyConfigurationConditionOperator {
    Contains = "Contains",
    Equals = "Equals",
    GreaterThan = "GreaterThan",
    GreaterThanEquals = "GreaterThanEquals",
    LessThan = "LessThan",
    LessThanEquals = "LessThanEquals",
    NotContains = "NotContains",
    NotEquals = "NotEquals"
}

export enum CustomerConfigurationAnalysisDraining {
    Completed = "Completed",
    Requested = "Requested"
}

export enum CustomerConsoleAppSideViewType {
    AuditEvent = "AuditEvent",
    CodeScan = "CodeScan",
    CodeScanRisk = "CodeScanRisk",
    Compliance = "Compliance",
    DataAnalysisResourceDataSegment = "DataAnalysisResourceDataSegment",
    Documentation = "Documentation",
    Entity = "Entity",
    Event = "Event",
    KubernetesClusterAdmissionControllerEvent = "KubernetesClusterAdmissionControllerEvent",
    MaliciousFile = "MaliciousFile",
    Risk = "Risk",
    RiskPolicies = "RiskPolicies",
    Scope = "Scope",
    Vulnerability = "Vulnerability",
    WorkloadAnalysisCodeScan = "WorkloadAnalysisCodeScan"
}

export enum CustomerConsoleAppUrlBuilderGetConfigurationIntegrationsAadTenantsRelativeUrlError {
    ApplicationConsentError = "ApplicationConsentError",
    ApplicationServicePrincipalDisabled = "ApplicationServicePrincipalDisabled",
    ApplicationUnauthorized = "ApplicationUnauthorized",
    B2CTenant = "B2CTenant",
    GuestUserUnauthorized = "GuestUserUnauthorized",
    TenantExists = "TenantExists",
    TenantNotExist = "TenantNotExist",
    UserAuthenticationError = "UserAuthenticationError"
}

export enum CustomerConsoleAppUrlBuilderGetConfigurationIntegrationsAzureDevOpsOrganizationsRelativeUrlError {
    ApplicationConsentError = "ApplicationConsentError",
    AuthenticationError = "AuthenticationError",
    AuthenticationRedirectError = "AuthenticationRedirectError"
}

export enum CustomerConsoleAppUrlBuilderGetConfigurationIntegrationsGitHubOrganizationsRelativeUrlError {
    AuthenticationError = "AuthenticationError",
    AuthenticationRedirectError = "AuthenticationRedirectError"
}

export enum CustomerConsoleAppUrlBuilderGetConfigurationIntegrationsSlackRelativeUrlError {
    Failure = "Failure"
}

export enum CustomerConsoleAppUrlBuilderGetConfigurationIntegrationsTeamsRelativeUrlError {
    AadTenantNotExist = "AadTenantNotExist",
    ApplicationConsentError = "ApplicationConsentError",
    ApplicationUnauthorized = "ApplicationUnauthorized",
    OrganizationExists = "OrganizationExists"
}

export enum CustomerConsoleAppView {
    Access = "Access",
    Administration = "Administration",
    AuditEvents = "AuditEvents",
    Code = "Code",
    Compliance = "Compliance",
    Configuration = "Configuration",
    Customers = "Customers",
    Dashboard = "Dashboard",
    DataAnalysis = "DataAnalysis",
    Docs = "Docs",
    Entities = "Entities",
    Events = "Events",
    Graphql = "Graphql",
    Kubernetes = "Kubernetes",
    Reports = "Reports",
    RiskPolicies = "RiskPolicies",
    Risks = "Risks",
    Scopes = "Scopes",
    SignIn = "SignIn",
    SignInError = "SignInError",
    UdmObjects = "UdmObjects",
    WorkloadAnalysis = "WorkloadAnalysis"
}

export enum CustomerLicenseObjectType {
    AwsDynamoDbAnalyzedTable = "AwsDynamoDbAnalyzedTable",
    AwsDynamoDbTable = "AwsDynamoDbTable",
    AwsEc2Image = "AwsEc2Image",
    AwsEc2Instance = "AwsEc2Instance",
    AwsEcrRepository = "AwsEcrRepository",
    AwsEcsClusterInstance = "AwsEcsClusterInstance",
    AwsEcsFargateService = "AwsEcsFargateService",
    AwsEcsService = "AwsEcsService",
    AwsEksCluster = "AwsEksCluster",
    AwsEksClusterInstance = "AwsEksClusterInstance",
    AwsEsDomain = "AwsEsDomain",
    AwsLambdaFunctionConfiguration = "AwsLambdaFunctionConfiguration",
    AwsRdsAnalyzedCluster = "AwsRdsAnalyzedCluster",
    AwsRdsDatabaseAnalyzedInstance = "AwsRdsDatabaseAnalyzedInstance",
    AwsRdsDatabaseInstance = "AwsRdsDatabaseInstance",
    AwsRedshiftCluster = "AwsRedshiftCluster",
    AwsS3AnalyzedBucket = "AwsS3AnalyzedBucket",
    AwsS3Bucket = "AwsS3Bucket",
    AwsUnmanagedKubernetesClusterNode = "AwsUnmanagedKubernetesClusterNode",
    AzureClassicComputeVirtualMachineGenericResource = "AzureClassicComputeVirtualMachineGenericResource",
    AzureClassicStorageStorageAccountGenericResource = "AzureClassicStorageStorageAccountGenericResource",
    AzureComputeVirtualMachine = "AzureComputeVirtualMachine",
    AzureComputeVirtualMachineScaleSet = "AzureComputeVirtualMachineScaleSet",
    AzureComputeVirtualMachineScaleSetVirtualMachine = "AzureComputeVirtualMachineScaleSetVirtualMachine",
    AzureContainerInstanceContainerGroup = "AzureContainerInstanceContainerGroup",
    AzureContainerInstanceContainerGroupGenericResource = "AzureContainerInstanceContainerGroupGenericResource",
    AzureContainerRegistryRepository = "AzureContainerRegistryRepository",
    AzureContainerServiceManagedCluster = "AzureContainerServiceManagedCluster",
    AzureContainerServiceManagedClusterVirtualMachineScaleSetVirtualMachine = "AzureContainerServiceManagedClusterVirtualMachineScaleSetVirtualMachine",
    AzureDbForMariaDbServerGenericResource = "AzureDbForMariaDbServerGenericResource",
    AzureDocumentDbCassandraClusterGenericResource = "AzureDocumentDbCassandraClusterGenericResource",
    AzureDocumentDbDatabaseAccount = "AzureDocumentDbDatabaseAccount",
    AzureMySqlFlexibleServer = "AzureMySqlFlexibleServer",
    AzureMySqlSingleServer = "AzureMySqlSingleServer",
    AzurePostgreSqlFlexibleServer = "AzurePostgreSqlFlexibleServer",
    AzurePostgreSqlSingleServer = "AzurePostgreSqlSingleServer",
    AzureSqlAnalyzedServer = "AzureSqlAnalyzedServer",
    AzureSqlServer = "AzureSqlServer",
    AzureStorageAnalyzedStorageAccountBlobContainer = "AzureStorageAnalyzedStorageAccountBlobContainer",
    AzureStorageStorageAccount = "AzureStorageStorageAccount",
    AzureSynapseWorkspaceGenericResource = "AzureSynapseWorkspaceGenericResource",
    AzureUnmanagedKubernetesClusterNode = "AzureUnmanagedKubernetesClusterNode",
    AzureWebApplication = "AzureWebApplication",
    CiContainerImageRepository = "CiContainerImageRepository",
    CodeContainerImageRepository = "CodeContainerImageRepository",
    GcpArtifactContainerImageRepository = "GcpArtifactContainerImageRepository",
    GcpBigQueryAnalyzedDataset = "GcpBigQueryAnalyzedDataset",
    GcpBigQueryDataset = "GcpBigQueryDataset",
    GcpBigtableInstancesGenericResource = "GcpBigtableInstancesGenericResource",
    GcpCloudRunService = "GcpCloudRunService",
    GcpComputeInstance = "GcpComputeInstance",
    GcpContainerAutopilotCluster = "GcpContainerAutopilotCluster",
    GcpContainerClusterInstance = "GcpContainerClusterInstance",
    GcpContainerNodePoolsGenericResource = "GcpContainerNodePoolsGenericResource",
    GcpFileInstancesGenericResource = "GcpFileInstancesGenericResource",
    GcpFunctionsFunction = "GcpFunctionsFunction",
    GcpSpannerInstance = "GcpSpannerInstance",
    GcpSqlAnalyzedInstance = "GcpSqlAnalyzedInstance",
    GcpSqlInstance = "GcpSqlInstance",
    GcpStorageAnalyzedBucket = "GcpStorageAnalyzedBucket",
    GcpStorageBucket = "GcpStorageBucket",
    GcpUnmanagedKubernetesClusterNode = "GcpUnmanagedKubernetesClusterNode",
    OciComputeInstance = "OciComputeInstance",
    OciContainerEngineClusterInstance = "OciContainerEngineClusterInstance",
    OciFunctionsFunction = "OciFunctionsFunction",
    OpUnmanagedKubernetesClusterNode = "OpUnmanagedKubernetesClusterNode"
}

export enum CvssVersion {
    Cvss2 = "Cvss2",
    Cvss3 = "Cvss3",
    Cvss31 = "Cvss31"
}

export enum DagContainerType {
    Parallel = "Parallel",
    Sequential = "Sequential"
}

export enum DataAnalysisResourceModelDataAnalysisError {
    DataAnalysisDisabled = "DataAnalysisDisabled",
    DatabaseInstanceMemberOfRdsAuroraCluster = "DatabaseInstanceMemberOfRdsAuroraCluster",
    Empty = "Empty",
    InsufficientPermissions = "InsufficientPermissions",
    MaxResourceSizeExceeded = "MaxResourceSizeExceeded",
    NotSupported = "NotSupported",
    PrivateObjectStoreScanDisabled = "PrivateObjectStoreScanDisabled",
    ResourceConfigurationNotSupported = "ResourceConfigurationNotSupported",
    ScanFailure = "ScanFailure"
}

export enum DataAnalysisResourceModelDataAnalysisStatus {
    Disabled = "Disabled",
    Failed = "Failed",
    Pending = "Pending",
    Scanned = "Scanned"
}

export enum DataAnalysisSensitivity {
    Confidential = "Confidential",
    NonSensitive = "NonSensitive",
    Private = "Private",
    Proprietary = "Proprietary",
    Public = "Public",
    Restricted = "Restricted"
}

export enum DataCategory {
    Pci = "Pci",
    Phi = "Phi",
    Pii = "Pii",
    Secrets = "Secrets"
}

export enum DatadogOrganizationConfigurationSite {
    Eu1 = "Eu1",
    Us1 = "Us1",
    Us1Gov = "Us1Gov",
    Us3 = "Us3",
    Us5 = "Us5"
}

export enum DatadogOrganizationStateIssue {
    InvalidApiKey = "InvalidApiKey"
}

export enum DataSensitivity {
    NonSensitive = "NonSensitive",
    Private = "Private",
    Confidential = "Confidential",
    Proprietary = "Proprietary",
    Restricted = "Restricted"
}

export enum DayOfWeek {
    Sunday = "Sunday",
    Monday = "Monday",
    Tuesday = "Tuesday",
    Wednesday = "Wednesday",
    Thursday = "Thursday",
    Friday = "Friday",
    Saturday = "Saturday"
}

export enum EntityAccessDirection {
    Inbound = "Inbound",
    Outbound = "Outbound"
}

export enum EntityAccessEvaluatorEvaluateResult {
    None = "None",
    NoneAwsPolicyPermissionBoundaryOnly = "NoneAwsPolicyPermissionBoundaryOnly",
    NoneDeletedEntity = "NoneDeletedEntity",
    NoneEmptyGroup = "NoneEmptyGroup",
    Success = "Success"
}

export enum EntityAccessScope {
    Full = "Full",
    GciSpecialGroupExcluded = "GciSpecialGroupExcluded",
    GranteePermitterInvolved = "GranteePermitterInvolved"
}

export enum EntityAccessVariant {
    Api = "Api",
    DestinationEntity = "DestinationEntity",
    Group = "Group",
    Identity = "Identity",
    OriginatorEntity = "OriginatorEntity",
    OriginatorEntityGranteeEntity = "OriginatorEntityGranteeEntity",
    PartialGroup = "PartialGroup",
    Permitter = "Permitter",
    WorkforcePool = "WorkforcePool",
    WorkloadIdentityPool = "WorkloadIdentityPool"
}

export enum EntityAnalyzingReason {
    AwsPermissionSetNewAssignment = "AwsPermissionSetNewAssignment",
    GroupNewMember = "GroupNewMember",
    NewEntity = "NewEntity",
    RecentUpdatePermissionAction = "RecentUpdatePermissionAction"
}

export enum EntityControllerGenerateAwsResourceNonexcessivePolicyResult {
    AllExcessive = "AllExcessive",
    AllNonexcessive = "AllNonexcessive",
    Failure = "Failure",
    Modified = "Modified",
    ModifiedDocumentCharacterMaxCountExceeded = "ModifiedDocumentCharacterMaxCountExceeded",
    NoPermissions = "NoPermissions"
}

export enum EntityControllerGenerateAzureIdentityNonexcessiveRoleDefinitionError {
    AllExcessive = "AllExcessive",
    AllNonexcessive = "AllNonexcessive",
    CommonErrorGeneration = "CommonErrorGeneration",
    NoPermissions = "NoPermissions"
}

export enum EntityControllerGenerateGcpIdentityNonexcessiveRoleError {
    AllExcessive = "AllExcessive",
    AllNonexcessive = "AllNonexcessive",
    CommonErrorGeneration = "CommonErrorGeneration",
    NoPermissions = "NoPermissions"
}

export enum EntityControllerGetEntityExcessivePermissionCustomEvaluationDataResponseDateType {
    AwsEc2InstanceLaunchDate = "AwsEc2InstanceLaunchDate",
    ConfigurationDate = "ConfigurationDate",
    CreationDate = "CreationDate",
    EventStartDate = "EventStartDate"
}

export enum EntityControllerGetNetworkGraphResponseResult {
    None = "None",
    NoneDeletedEntity = "NoneDeletedEntity",
    Success = "Success"
}

export enum EntityControllerSearchEntityIdsPermissionRequestType {
    DestinationResource = "DestinationResource",
    Permitter = "Permitter",
    SourceIdentity = "SourceIdentity"
}

export enum EntityMatchConditionCollectionOperator {
    Overlap = "Overlap",
    Subset = "Subset"
}

export enum EntityMatchConditionGroupMembershipTypeOperator {
    Direct = "Direct",
    Transitive = "Transitive"
}

export enum EntityModelProperty {
    AadDirectoryApplicationCertificateThumbprint = "AadDirectoryApplicationCertificateThumbprint",
    AadDirectoryApplicationExpirationStatus = "AadDirectoryApplicationExpirationStatus",
    AadDirectoryApplicationObjectApplication = "AadDirectoryApplicationObjectApplication",
    AadDirectoryApplicationObjectApplicationOwnerPrincipals = "AadDirectoryApplicationObjectApplicationOwnerPrincipals",
    AadDirectoryApplicationObjectExpirationStatus = "AadDirectoryApplicationObjectExpirationStatus",
    AadDirectoryApplicationObjectExpirationTime = "AadDirectoryApplicationObjectExpirationTime",
    AadDirectoryApplicationOwnerPrincipals = "AadDirectoryApplicationOwnerPrincipals",
    AadDirectoryApplicationSecretDescription = "AadDirectoryApplicationSecretDescription",
    AadDirectoryApplicationServicePrincipalVendors = "AadDirectoryApplicationServicePrincipalVendors",
    AadDirectoryApplicationType = "AadDirectoryApplicationType",
    AadDirectoryDirectoryRoleDefinitionAssignedPrincipals = "AadDirectoryDirectoryRoleDefinitionAssignedPrincipals",
    AadDirectoryDirectoryRoleDefinitionSeverePermission = "AadDirectoryDirectoryRoleDefinitionSeverePermission",
    AadDirectoryDirectoryRoleDefinitionType = "AadDirectoryDirectoryRoleDefinitionType",
    AadDirectoryDirectoryRoleManagementPolicyAssignmentApprovalRequired = "AadDirectoryDirectoryRoleManagementPolicyAssignmentApprovalRequired",
    AadDirectoryDirectoryRoleManagementPolicyAssignmentApproverPrincipals = "AadDirectoryDirectoryRoleManagementPolicyAssignmentApproverPrincipals",
    AadDirectoryDirectoryRoleManagementPolicyAssignmentAssignedPrincipals = "AadDirectoryDirectoryRoleManagementPolicyAssignmentAssignedPrincipals",
    AadDirectoryDirectoryRoleManagementPolicyAssignmentDirectoryRoleDefinition = "AadDirectoryDirectoryRoleManagementPolicyAssignmentDirectoryRoleDefinition",
    AadDirectoryDirectoryRoleManagementPolicyAssignmentMaxDuration = "AadDirectoryDirectoryRoleManagementPolicyAssignmentMaxDuration",
    AadDirectoryDirectoryRoleManagementPolicyAssignmentMfaEnabled = "AadDirectoryDirectoryRoleManagementPolicyAssignmentMfaEnabled",
    AadDirectoryEntityCreationTime = "AadDirectoryEntityCreationTime",
    AadDirectoryGroupAdOrigin = "AadDirectoryGroupAdOrigin",
    AadDirectoryGroupAwsRoles = "AadDirectoryGroupAwsRoles",
    AadDirectoryGroupMemberPrincipals = "AadDirectoryGroupMemberPrincipals",
    AadDirectoryGroupMembershipType = "AadDirectoryGroupMembershipType",
    AadDirectoryGroupOwnerPrincipals = "AadDirectoryGroupOwnerPrincipals",
    AadDirectoryGroupType = "AadDirectoryGroupType",
    AadDirectoryIdentityActivityTime = "AadDirectoryIdentityActivityTime",
    AadDirectoryIdentityEnabled = "AadDirectoryIdentityEnabled",
    AadDirectoryIdentitySignInTime = "AadDirectoryIdentitySignInTime",
    AadDirectoryPrincipalAzureRoleAssignmentResources = "AadDirectoryPrincipalAzureRoleAssignmentResources",
    AadDirectoryPrincipalAzureRoleDefinitions = "AadDirectoryPrincipalAzureRoleDefinitions",
    AadDirectoryPrincipalContainedAzureRoleAssignmentResources = "AadDirectoryPrincipalContainedAzureRoleAssignmentResources",
    AadDirectoryPrincipalDirectoryRoleAssignmentResources = "AadDirectoryPrincipalDirectoryRoleAssignmentResources",
    AadDirectoryPrincipalDirectoryRoleDefinitions = "AadDirectoryPrincipalDirectoryRoleDefinitions",
    AadDirectoryServicePrincipalAssignedPrincipals = "AadDirectoryServicePrincipalAssignedPrincipals",
    AadDirectoryServicePrincipalHomepageRawUrl = "AadDirectoryServicePrincipalHomepageRawUrl",
    AadDirectoryServicePrincipalType = "AadDirectoryServicePrincipalType",
    AadDirectoryServicePrincipalVendor = "AadDirectoryServicePrincipalVendor",
    AadDirectoryUserAdOrigin = "AadDirectoryUserAdOrigin",
    AadDirectoryUserAuthenticationType = "AadDirectoryUserAuthenticationType",
    AadDirectoryUserAwsRoles = "AadDirectoryUserAwsRoles",
    AadDirectoryUserGroups = "AadDirectoryUserGroups",
    AadDirectoryUserLocation = "AadDirectoryUserLocation",
    AadDirectoryUserRawMail = "AadDirectoryUserRawMail",
    AadDirectoryUserType = "AadDirectoryUserType",
    AadDirectoryUserUserPrincipalName = "AadDirectoryUserUserPrincipalName",
    AadTenantEntityDefaultUserRoleApplicationCreationEnabled = "AadTenantEntityDefaultUserRoleApplicationCreationEnabled",
    AadTenantEntityDefaultUserRoleTenantCreationEnabled = "AadTenantEntityDefaultUserRoleTenantCreationEnabled",
    AadTenantEntityExternalUserDefaultRole = "AadTenantEntityExternalUserDefaultRole",
    AadTenantEntityExternalUserInvitationAllowedRoles = "AadTenantEntityExternalUserInvitationAllowedRoles",
    AadTenantEntityPrimaryDomainName = "AadTenantEntityPrimaryDomainName",
    AwsAcmCertificateAttachedResourceExists = "AwsAcmCertificateAttachedResourceExists",
    AwsAcmCertificateAttachedResources = "AwsAcmCertificateAttachedResources",
    AwsAcmCertificateDomainName = "AwsAcmCertificateDomainName",
    AwsAcmCertificateExpirationTime = "AwsAcmCertificateExpirationTime",
    AwsAcmCertificateKeyType = "AwsAcmCertificateKeyType",
    AwsAcmCertificateStatus = "AwsAcmCertificateStatus",
    AwsAcmCertificateType = "AwsAcmCertificateType",
    AwsAossCollectionStorageSize = "AwsAossCollectionStorageSize",
    AwsApiGatewayApiEndpointType = "AwsApiGatewayApiEndpointType",
    AwsApiGatewayApiType = "AwsApiGatewayApiType",
    AwsApiGatewayApiWafWebAcls = "AwsApiGatewayApiWafWebAcls",
    AwsAthenaWorkgroupEnabled = "AwsAthenaWorkgroupEnabled",
    AwsAthenaWorkgroupEnforceWorkgroupConfiguration = "AwsAthenaWorkgroupEnforceWorkgroupConfiguration",
    AwsAthenaWorkgroupEngineVersionName = "AwsAthenaWorkgroupEngineVersionName",
    AwsAthenaWorkgroupLoggingEnabled = "AwsAthenaWorkgroupLoggingEnabled",
    AwsAthenaWorkgroupOutputBucket = "AwsAthenaWorkgroupOutputBucket",
    AwsAutoScalingAutoScalingGroupInstances = "AwsAutoScalingAutoScalingGroupInstances",
    AwsAutoScalingAutoScalingGroupKeyName = "AwsAutoScalingAutoScalingGroupKeyName",
    AwsAutoScalingAutoScalingGroupLaunchConfiguration = "AwsAutoScalingAutoScalingGroupLaunchConfiguration",
    AwsAutoScalingAutoScalingGroupLaunchTemplate = "AwsAutoScalingAutoScalingGroupLaunchTemplate",
    AwsAutoScalingAutoScalingGroupLoadBalancers = "AwsAutoScalingAutoScalingGroupLoadBalancers",
    AwsAutoScalingLaunchConfigurationAutoScalingGroups = "AwsAutoScalingLaunchConfigurationAutoScalingGroups",
    AwsAutoScalingLaunchConfigurationVpcs = "AwsAutoScalingLaunchConfigurationVpcs",
    AwsBedrockAgentRole = "AwsBedrockAgentRole",
    AwsBedrockAgentStatus = "AwsBedrockAgentStatus",
    AwsBedrockAgentUpdateTime = "AwsBedrockAgentUpdateTime",
    AwsBedrockCustomModelCustomizationType = "AwsBedrockCustomModelCustomizationType",
    AwsCloudFormationStackDriftStatus = "AwsCloudFormationStackDriftStatus",
    AwsCloudFormationStackResources = "AwsCloudFormationStackResources",
    AwsCloudFormationStackStatus = "AwsCloudFormationStackStatus",
    AwsCloudFrontDistributionCertificate = "AwsCloudFrontDistributionCertificate",
    AwsCloudFrontDistributionOrigins = "AwsCloudFrontDistributionOrigins",
    AwsCloudFrontDistributionStatus = "AwsCloudFrontDistributionStatus",
    AwsCloudTrailTrailBucketObjectEventSelectorType = "AwsCloudTrailTrailBucketObjectEventSelectorType",
    AwsCloudTrailTrailEnabled = "AwsCloudTrailTrailEnabled",
    AwsCloudTrailTrailManagementEventSelectorType = "AwsCloudTrailTrailManagementEventSelectorType",
    AwsCloudWatchResourceCloudWatchLoggingEnabled = "AwsCloudWatchResourceCloudWatchLoggingEnabled",
    AwsCloudWatchResourceCloudWatchLogTypes = "AwsCloudWatchResourceCloudWatchLogTypes",
    AwsDocDbClusterBackupRetentionTimeFrame = "AwsDocDbClusterBackupRetentionTimeFrame",
    AwsDocDbClusterDeleteProtection = "AwsDocDbClusterDeleteProtection",
    AwsDocDbClusterEngineVersion = "AwsDocDbClusterEngineVersion",
    AwsDocDbClusterGlobalCluster = "AwsDocDbClusterGlobalCluster",
    AwsDocDbClusterInstances = "AwsDocDbClusterInstances",
    AwsDocDbClusterSnapshotCluster = "AwsDocDbClusterSnapshotCluster",
    AwsDocDbClusterSnapshotClusterType = "AwsDocDbClusterSnapshotClusterType",
    AwsDocDbClusterSnapshots = "AwsDocDbClusterSnapshots",
    AwsDocDbClusterSnapshotStorageSize = "AwsDocDbClusterSnapshotStorageSize",
    AwsDocDbClusterSnapshotType = "AwsDocDbClusterSnapshotType",
    AwsDocDbClusterStatus = "AwsDocDbClusterStatus",
    AwsDocDbClusterStorageSize = "AwsDocDbClusterStorageSize",
    AwsDocDbClusterType = "AwsDocDbClusterType",
    AwsDynamoDbTableDeleteProtection = "AwsDynamoDbTableDeleteProtection",
    AwsDynamoDbTableStorageSize = "AwsDynamoDbTableStorageSize",
    AwsEc2ElasticIpNetworkInterface = "AwsEc2ElasticIpNetworkInterface",
    AwsEc2ElasticIpOnPremiseIpAddress = "AwsEc2ElasticIpOnPremiseIpAddress",
    AwsEc2ElasticIpPrivateIpAddress = "AwsEc2ElasticIpPrivateIpAddress",
    AwsEc2ElasticIpPublicIpAddress = "AwsEc2ElasticIpPublicIpAddress",
    AwsEc2ImageAwsManaged = "AwsEc2ImageAwsManaged",
    AwsEc2ImageInstances = "AwsEc2ImageInstances",
    AwsEc2InstanceAutoScalingGroup = "AwsEc2InstanceAutoScalingGroup",
    AwsEc2InstanceEnclaveEnabled = "AwsEc2InstanceEnclaveEnabled",
    AwsEc2InstanceKeyName = "AwsEc2InstanceKeyName",
    AwsEc2InstanceLaunchTime = "AwsEc2InstanceLaunchTime",
    AwsEc2InstanceLoadBalancers = "AwsEc2InstanceLoadBalancers",
    AwsEc2InstanceOperatingSystemDescription = "AwsEc2InstanceOperatingSystemDescription",
    AwsEc2InstanceStatus = "AwsEc2InstanceStatus",
    AwsEc2InstanceStopTime = "AwsEc2InstanceStopTime",
    AwsEc2InstanceTypeDataBareMetal = "AwsEc2InstanceTypeDataBareMetal",
    AwsEc2InstanceTypeDataHypervisor = "AwsEc2InstanceTypeDataHypervisor",
    AwsEc2InstanceTypeDataRawType = "AwsEc2InstanceTypeDataRawType",
    AwsEc2InternetGatewayVpc = "AwsEc2InternetGatewayVpc",
    AwsEc2LaunchTemplateResources = "AwsEc2LaunchTemplateResources",
    AwsEc2LaunchTemplateVpcs = "AwsEc2LaunchTemplateVpcs",
    AwsEc2NatGatewayVpc = "AwsEc2NatGatewayVpc",
    AwsEc2NetworkAclVpc = "AwsEc2NetworkAclVpc",
    AwsEc2NetworkInterfaceDescription = "AwsEc2NetworkInterfaceDescription",
    AwsEc2NetworkInterfaceFlowLogDestinationResources = "AwsEc2NetworkInterfaceFlowLogDestinationResources",
    AwsEc2NetworkInterfaceFlowLogFilter = "AwsEc2NetworkInterfaceFlowLogFilter",
    AwsEc2ResourceRawId = "AwsEc2ResourceRawId",
    AwsEc2RouteTableVpc = "AwsEc2RouteTableVpc",
    AwsEc2SecurityGroupNetworkedResource = "AwsEc2SecurityGroupNetworkedResource",
    AwsEc2SecurityGroupVpc = "AwsEc2SecurityGroupVpc",
    AwsEc2SnapshotDescription = "AwsEc2SnapshotDescription",
    AwsEc2SnapshotSize = "AwsEc2SnapshotSize",
    AwsEc2SnapshotSourceVolume = "AwsEc2SnapshotSourceVolume",
    AwsEc2SnapshotSourceVolumeInstances = "AwsEc2SnapshotSourceVolumeInstances",
    AwsEc2SubnetFlowLogDestinationResources = "AwsEc2SubnetFlowLogDestinationResources",
    AwsEc2SubnetFlowLogFilter = "AwsEc2SubnetFlowLogFilter",
    AwsEc2SubnetNetworkedResources = "AwsEc2SubnetNetworkedResources",
    AwsEc2SubnetSubnets = "AwsEc2SubnetSubnets",
    AwsEc2SubnetVpc = "AwsEc2SubnetVpc",
    AwsEc2VolumeInstances = "AwsEc2VolumeInstances",
    AwsEc2VolumeInstanceTerminationDeletion = "AwsEc2VolumeInstanceTerminationDeletion",
    AwsEc2VolumeSize = "AwsEc2VolumeSize",
    AwsEc2VpcBlockPublicAccessPolicyBlockNetworkDirection = "AwsEc2VpcBlockPublicAccessPolicyBlockNetworkDirection",
    AwsEc2VpcBlockPublicAccessPolicyEnabled = "AwsEc2VpcBlockPublicAccessPolicyEnabled",
    AwsEc2VpcEndpointRouteTables = "AwsEc2VpcEndpointRouteTables",
    AwsEc2VpcEndpointServiceName = "AwsEc2VpcEndpointServiceName",
    AwsEc2VpcEndpointStatus = "AwsEc2VpcEndpointStatus",
    AwsEc2VpcEndpointSubnets = "AwsEc2VpcEndpointSubnets",
    AwsEc2VpcEndpointType = "AwsEc2VpcEndpointType",
    AwsEc2VpcFlowLogDestinationResources = "AwsEc2VpcFlowLogDestinationResources",
    AwsEc2VpcFlowLogFilter = "AwsEc2VpcFlowLogFilter",
    AwsEc2VpcNetworkedResources = "AwsEc2VpcNetworkedResources",
    AwsEc2VpcPeeringConnectionAccepterVpc = "AwsEc2VpcPeeringConnectionAccepterVpc",
    AwsEc2VpcPeeringConnectionCrossTenant = "AwsEc2VpcPeeringConnectionCrossTenant",
    AwsEc2VpcPeeringConnectionRequesterVpc = "AwsEc2VpcPeeringConnectionRequesterVpc",
    AwsEc2VpcPeeringConnectionRouteTables = "AwsEc2VpcPeeringConnectionRouteTables",
    AwsEc2VpcPeeringConnectionRouteTableSubnets = "AwsEc2VpcPeeringConnectionRouteTableSubnets",
    AwsEc2VpcPeeringConnectionStatus = "AwsEc2VpcPeeringConnectionStatus",
    AwsEc2VpcSubnets = "AwsEc2VpcSubnets",
    AwsEcrRepositoryContainerImageResources = "AwsEcrRepositoryContainerImageResources",
    AwsEcrRepositoryContainerImageVulnerabilities = "AwsEcrRepositoryContainerImageVulnerabilities",
    AwsEcrRepositoryContainerImageVulnerabilitySeverities = "AwsEcrRepositoryContainerImageVulnerabilitySeverities",
    AwsEcsServiceContainerImages = "AwsEcsServiceContainerImages",
    AwsEcsServiceContainerImageVulnerabilities = "AwsEcsServiceContainerImageVulnerabilities",
    AwsEcsServiceLaunchType = "AwsEcsServiceLaunchType",
    AwsEcsServiceTaskDefinition = "AwsEcsServiceTaskDefinition",
    AwsEcsTaskDefinitionContainerImages = "AwsEcsTaskDefinitionContainerImages",
    AwsEcsTaskDefinitionContainerImageVulnerabilities = "AwsEcsTaskDefinitionContainerImageVulnerabilities",
    AwsEcsTaskDefinitionStatus = "AwsEcsTaskDefinitionStatus",
    AwsEksClusterInstances = "AwsEksClusterInstances",
    AwsEksClusterNodeGroupAutoScalingGroups = "AwsEksClusterNodeGroupAutoScalingGroups",
    AwsEksClusterNodeGroupClusters = "AwsEksClusterNodeGroupClusters",
    AwsEksClusterNodeGroupStatus = "AwsEksClusterNodeGroupStatus",
    AwsEksClusterStatus = "AwsEksClusterStatus",
    AwsElastiCacheCacheAutoFailover = "AwsElastiCacheCacheAutoFailover",
    AwsElastiCacheCacheAutomaticBackups = "AwsElastiCacheCacheAutomaticBackups",
    AwsElastiCacheCacheAutoUpgradeMinorVersions = "AwsElastiCacheCacheAutoUpgradeMinorVersions",
    AwsElastiCacheCacheBackupRetentionTimeFrame = "AwsElastiCacheCacheBackupRetentionTimeFrame",
    AwsElastiCacheCacheDeploymentType = "AwsElastiCacheCacheDeploymentType",
    AwsElastiCacheCacheEngineVersion = "AwsElastiCacheCacheEngineVersion",
    AwsElastiCacheCacheStatus = "AwsElastiCacheCacheStatus",
    AwsElastiCacheCacheTransitEncryptionEnabled = "AwsElastiCacheCacheTransitEncryptionEnabled",
    AwsElastiCacheReplicationGroupClusterMode = "AwsElastiCacheReplicationGroupClusterMode",
    AwsElastiCacheReplicationGroupNodeType = "AwsElastiCacheReplicationGroupNodeType",
    AwsElastiCacheReplicationGroupSubnetGroupName = "AwsElastiCacheReplicationGroupSubnetGroupName",
    AwsElastiCacheReplicationGroupTransitEncryptionMode = "AwsElastiCacheReplicationGroupTransitEncryptionMode",
    AwsElasticBeanstalkEnvironmentCloudWatchLogRetentionTimeFrame = "AwsElasticBeanstalkEnvironmentCloudWatchLogRetentionTimeFrame",
    AwsElasticFileSystemFileSystemRawId = "AwsElasticFileSystemFileSystemRawId",
    AwsElasticFileSystemFileSystemStorageSize = "AwsElasticFileSystemFileSystemStorageSize",
    AwsElbLoadBalancerInternal = "AwsElbLoadBalancerInternal",
    AwsElbLoadBalancerType = "AwsElbLoadBalancerType",
    AwsElbLoadBalancerWebAcl = "AwsElbLoadBalancerWebAcl",
    AwsEmrClusterInstances = "AwsEmrClusterInstances",
    AwsEmrClusterStatus = "AwsEmrClusterStatus",
    AwsEncryptedResourceEncryptionExists = "AwsEncryptedResourceEncryptionExists",
    AwsEncryptedResourceKmsEncryptionKeys = "AwsEncryptedResourceKmsEncryptionKeys",
    AwsEsDomainEngineType = "AwsEsDomainEngineType",
    AwsEsDomainEngineVersion = "AwsEsDomainEngineVersion",
    AwsEsDomainStorageSize = "AwsEsDomainStorageSize",
    AwsFirehoseDeliveryStreamStatus = "AwsFirehoseDeliveryStreamStatus",
    AwsIamGroupUsers = "AwsIamGroupUsers",
    AwsIamIdentityActivityTime = "AwsIamIdentityActivityTime",
    AwsIamIdentityPermissionBoundary = "AwsIamIdentityPermissionBoundary",
    AwsIamPrincipalPolicies = "AwsIamPrincipalPolicies",
    AwsIamPrincipalPolicyAttachedPrincipals = "AwsIamPrincipalPolicyAttachedPrincipals",
    AwsIamPrincipalPolicyManagement = "AwsIamPrincipalPolicyManagement",
    AwsIamPrincipalPolicyUsageTime = "AwsIamPrincipalPolicyUsageTime",
    AwsIamRoleAssumeRoleGranteeTypes = "AwsIamRoleAssumeRoleGranteeTypes",
    AwsIamRoleVendor = "AwsIamRoleVendor",
    AwsIamRoleVendors = "AwsIamRoleVendors",
    AwsIamRootUserMfaEnabled = "AwsIamRootUserMfaEnabled",
    AwsIamServerCertificateExpirationTime = "AwsIamServerCertificateExpirationTime",
    AwsIamServerCertificateUniqueId = "AwsIamServerCertificateUniqueId",
    AwsIamUserAccessKey1CreationTime = "AwsIamUserAccessKey1CreationTime",
    AwsIamUserAccessKey1Enabled = "AwsIamUserAccessKey1Enabled",
    AwsIamUserAccessKey1UniqueId = "AwsIamUserAccessKey1UniqueId",
    AwsIamUserAccessKey1UsageRegionSystemName = "AwsIamUserAccessKey1UsageRegionSystemName",
    AwsIamUserAccessKey1UsageService = "AwsIamUserAccessKey1UsageService",
    AwsIamUserAccessKey1UsageTime = "AwsIamUserAccessKey1UsageTime",
    AwsIamUserAccessKey2CreationTime = "AwsIamUserAccessKey2CreationTime",
    AwsIamUserAccessKey2Enabled = "AwsIamUserAccessKey2Enabled",
    AwsIamUserAccessKey2UniqueId = "AwsIamUserAccessKey2UniqueId",
    AwsIamUserAccessKey2UsageRegionSystemName = "AwsIamUserAccessKey2UsageRegionSystemName",
    AwsIamUserAccessKey2UsageService = "AwsIamUserAccessKey2UsageService",
    AwsIamUserAccessKey2UsageTime = "AwsIamUserAccessKey2UsageTime",
    AwsIamUserAccessKeyCreationTime = "AwsIamUserAccessKeyCreationTime",
    AwsIamUserAccessKeyEnabled = "AwsIamUserAccessKeyEnabled",
    AwsIamUserAccessKeyUniqueId = "AwsIamUserAccessKeyUniqueId",
    AwsIamUserAccessKeyUsageRegionSystemName = "AwsIamUserAccessKeyUsageRegionSystemName",
    AwsIamUserAccessKeyUsageService = "AwsIamUserAccessKeyUsageService",
    AwsIamUserAccessKeyUsageTime = "AwsIamUserAccessKeyUsageTime",
    AwsIamUserGroupPolicies = "AwsIamUserGroupPolicies",
    AwsIamUserGroups = "AwsIamUserGroups",
    AwsIamUserMfaEnabled = "AwsIamUserMfaEnabled",
    AwsIamUserPasswordEnabled = "AwsIamUserPasswordEnabled",
    AwsKmsKeyAwsManaged = "AwsKmsKeyAwsManaged",
    AwsKmsKeyEnabled = "AwsKmsKeyEnabled",
    AwsKmsKeyEncryptedResources = "AwsKmsKeyEncryptedResources",
    AwsKubernetesDeploymentReplicaSets = "AwsKubernetesDeploymentReplicaSets",
    AwsKubernetesServiceAccountOriginatorWorkloadResources = "AwsKubernetesServiceAccountOriginatorWorkloadResources",
    AwsKubernetesServiceAccountRoleBindings = "AwsKubernetesServiceAccountRoleBindings",
    AwsKubernetesServiceAccountRoles = "AwsKubernetesServiceAccountRoles",
    AwsKubernetesWorkloadResourceAwsRoles = "AwsKubernetesWorkloadResourceAwsRoles",
    AwsLambdaFunctionConfigurationPackageType = "AwsLambdaFunctionConfigurationPackageType",
    AwsLambdaFunctionConfigurationPublicAccessTypes = "AwsLambdaFunctionConfigurationPublicAccessTypes",
    AwsLambdaFunctionConfigurationRoles = "AwsLambdaFunctionConfigurationRoles",
    AwsLambdaFunctionConfigurationRuntimes = "AwsLambdaFunctionConfigurationRuntimes",
    AwsLambdaFunctionConfigurationTriggerResources = "AwsLambdaFunctionConfigurationTriggerResources",
    AwsLambdaFunctionConfigurationUpdateTime = "AwsLambdaFunctionConfigurationUpdateTime",
    AwsNetworkedResourceSecurityGroups = "AwsNetworkedResourceSecurityGroups",
    AwsNetworkedResourceVpcs = "AwsNetworkedResourceVpcs",
    AwsOrganizationsPolicyAttachedAccounts = "AwsOrganizationsPolicyAttachedAccounts",
    AwsOrganizationsPolicyAwsManaged = "AwsOrganizationsPolicyAwsManaged",
    AwsOrganizationsPolicyOrganizationRawIds = "AwsOrganizationsPolicyOrganizationRawIds",
    AwsOrganizationsPolicyType = "AwsOrganizationsPolicyType",
    AwsRdsClusterEngineType = "AwsRdsClusterEngineType",
    AwsRdsClusterInstanceRawType = "AwsRdsClusterInstanceRawType",
    AwsRdsClusterMinorVersionUpgradeEnabled = "AwsRdsClusterMinorVersionUpgradeEnabled",
    AwsRdsClusterResourceBackupRetentionTimeFrame = "AwsRdsClusterResourceBackupRetentionTimeFrame",
    AwsRdsClusterResourceDeleteProtection = "AwsRdsClusterResourceDeleteProtection",
    AwsRdsClusterResourceEngineVersion = "AwsRdsClusterResourceEngineVersion",
    AwsRdsClusterResourceGlobalCluster = "AwsRdsClusterResourceGlobalCluster",
    AwsRdsClusterResourceInstances = "AwsRdsClusterResourceInstances",
    AwsRdsClusterResourceSnapshots = "AwsRdsClusterResourceSnapshots",
    AwsRdsClusterResourceStatus = "AwsRdsClusterResourceStatus",
    AwsRdsClusterResourceStorageSize = "AwsRdsClusterResourceStorageSize",
    AwsRdsDatabaseInstanceEngineType = "AwsRdsDatabaseInstanceEngineType",
    AwsRdsDatabaseInstanceResourceBackupRetentionTimeFrame = "AwsRdsDatabaseInstanceResourceBackupRetentionTimeFrame",
    AwsRdsDatabaseInstanceResourceCluster = "AwsRdsDatabaseInstanceResourceCluster",
    AwsRdsDatabaseInstanceResourceDeleteProtection = "AwsRdsDatabaseInstanceResourceDeleteProtection",
    AwsRdsDatabaseInstanceResourceEngineVersion = "AwsRdsDatabaseInstanceResourceEngineVersion",
    AwsRdsDatabaseInstanceResourceMinorVersionUpgradeEnabled = "AwsRdsDatabaseInstanceResourceMinorVersionUpgradeEnabled",
    AwsRdsDatabaseInstanceResourceRawType = "AwsRdsDatabaseInstanceResourceRawType",
    AwsRdsDatabaseInstanceResourceStatus = "AwsRdsDatabaseInstanceResourceStatus",
    AwsRdsDatabaseInstanceResourceStorageSize = "AwsRdsDatabaseInstanceResourceStorageSize",
    AwsRdsDatabaseInstanceSnapshots = "AwsRdsDatabaseInstanceSnapshots",
    AwsRdsGlobalClusterResourceClusters = "AwsRdsGlobalClusterResourceClusters",
    AwsRdsGlobalClusterResourceDeleteProtection = "AwsRdsGlobalClusterResourceDeleteProtection",
    AwsRdsGlobalClusterResourceEngineVersion = "AwsRdsGlobalClusterResourceEngineVersion",
    AwsRdsGlobalClusterResourceStatus = "AwsRdsGlobalClusterResourceStatus",
    AwsRdsNeptuneClusterSnapshotCluster = "AwsRdsNeptuneClusterSnapshotCluster",
    AwsRdsNeptuneClusterSnapshotType = "AwsRdsNeptuneClusterSnapshotType",
    AwsRdsSnapshotResourceStorageSize = "AwsRdsSnapshotResourceStorageSize",
    AwsRdsSnapshotSourceResource = "AwsRdsSnapshotSourceResource",
    AwsRdsSnapshotType = "AwsRdsSnapshotType",
    AwsRedshiftClusterBackupRetentionTimeFrame = "AwsRedshiftClusterBackupRetentionTimeFrame",
    AwsRedshiftClusterStorageSize = "AwsRedshiftClusterStorageSize",
    AwsResourceAccessLevel = "AwsResourceAccessLevel",
    AwsResourceKmsEncryptionKeys = "AwsResourceKmsEncryptionKeys",
    AwsRoute53HostedZoneDescription = "AwsRoute53HostedZoneDescription",
    AwsRoute53HostedZonePublicNetworkAccess = "AwsRoute53HostedZonePublicNetworkAccess",
    AwsRoute53HostedZoneRecordCount = "AwsRoute53HostedZoneRecordCount",
    AwsRoute53HostedZoneSigningStatus = "AwsRoute53HostedZoneSigningStatus",
    AwsS3BucketObjectCount = "AwsS3BucketObjectCount",
    AwsS3BucketObjectEventSelectorType = "AwsS3BucketObjectEventSelectorType",
    AwsS3BucketObjectLifecycleConfigurationEnabled = "AwsS3BucketObjectLifecycleConfigurationEnabled",
    AwsS3BucketObjectVersioningEnabled = "AwsS3BucketObjectVersioningEnabled",
    AwsS3BucketServerSideEncryptionType = "AwsS3BucketServerSideEncryptionType",
    AwsS3BucketStorageSize = "AwsS3BucketStorageSize",
    AwsS3BucketWebsiteEnabled = "AwsS3BucketWebsiteEnabled",
    AwsSageMakerNotebookInstanceMetadataServiceVersion = "AwsSageMakerNotebookInstanceMetadataServiceVersion",
    AwsSageMakerNotebookInstanceRawType = "AwsSageMakerNotebookInstanceRawType",
    AwsSageMakerNotebookInstanceRole = "AwsSageMakerNotebookInstanceRole",
    AwsSageMakerNotebookInstanceRootAccess = "AwsSageMakerNotebookInstanceRootAccess",
    AwsSageMakerNotebookInstanceStatus = "AwsSageMakerNotebookInstanceStatus",
    AwsSageMakerNotebookInstanceUnrestrictedOutboundAccess = "AwsSageMakerNotebookInstanceUnrestrictedOutboundAccess",
    AwsSecretsManagerSecretUsageTime = "AwsSecretsManagerSecretUsageTime",
    AwsSnsTopicSubscriptionCount = "AwsSnsTopicSubscriptionCount",
    AwsSnsTopicType = "AwsSnsTopicType",
    AwsSqsQueueType = "AwsSqsQueueType",
    AwsSsmParameterType = "AwsSsmParameterType",
    AwsSsmParameterUpdateIdentity = "AwsSsmParameterUpdateIdentity",
    AwsSsmParameterUpdateTime = "AwsSsmParameterUpdateTime",
    AwsSsoGroupUsers = "AwsSsoGroupUsers",
    AwsSsoPermissionSetAccounts = "AwsSsoPermissionSetAccounts",
    AwsSsoPermissionSetPrincipals = "AwsSsoPermissionSetPrincipals",
    AwsSsoPermissionSetProvisioned = "AwsSsoPermissionSetProvisioned",
    AwsSsoPrincipalRoles = "AwsSsoPrincipalRoles",
    AwsSsoPrincipalRoleTenants = "AwsSsoPrincipalRoleTenants",
    AwsSsoUserActive = "AwsSsoUserActive",
    AwsSsoUserGroups = "AwsSsoUserGroups",
    AwsTenantEntityAccountOrganizationJoinMethod = "AwsTenantEntityAccountOrganizationJoinMethod",
    AwsTenantEntityAccountStatus = "AwsTenantEntityAccountStatus",
    AwsTenantEntityParentPath = "AwsTenantEntityParentPath",
    AwsTenantEntityResourceControlPolicyIds = "AwsTenantEntityResourceControlPolicyIds",
    AwsTenantEntityRootUserMail = "AwsTenantEntityRootUserMail",
    AwsTenantEntityServiceControlPolicyIds = "AwsTenantEntityServiceControlPolicyIds",
    AwsTimestreamDatabaseTableDatabase = "AwsTimestreamDatabaseTableDatabase",
    AwsTimestreamDatabaseTables = "AwsTimestreamDatabaseTables",
    AwsWafWebAclAssociatedResources = "AwsWafWebAclAssociatedResources",
    AzureAuthorizationRoleAssignmentResourceContainingRoleAssignmentResources = "AzureAuthorizationRoleAssignmentResourceContainingRoleAssignmentResources",
    AzureAuthorizationRoleAssignmentResourceEndTime = "AzureAuthorizationRoleAssignmentResourceEndTime",
    AzureAuthorizationRoleAssignmentResourcePrincipal = "AzureAuthorizationRoleAssignmentResourcePrincipal",
    AzureAuthorizationRoleAssignmentResourceRoleDefinition = "AzureAuthorizationRoleAssignmentResourceRoleDefinition",
    AzureAuthorizationRoleAssignmentResourceRoleManagementPolicyAssignment = "AzureAuthorizationRoleAssignmentResourceRoleManagementPolicyAssignment",
    AzureAuthorizationRoleAssignmentResourceScopeEntity = "AzureAuthorizationRoleAssignmentResourceScopeEntity",
    AzureAuthorizationRoleAssignmentResourceScopeEntityTypeName = "AzureAuthorizationRoleAssignmentResourceScopeEntityTypeName",
    AzureAuthorizationRoleAssignmentResourceStatus = "AzureAuthorizationRoleAssignmentResourceStatus",
    AzureAuthorizationRoleDefinitionType = "AzureAuthorizationRoleDefinitionType",
    AzureAuthorizationRoleManagementPolicyAssignmentApprovalRequired = "AzureAuthorizationRoleManagementPolicyAssignmentApprovalRequired",
    AzureAuthorizationRoleManagementPolicyAssignmentApproverPrincipals = "AzureAuthorizationRoleManagementPolicyAssignmentApproverPrincipals",
    AzureAuthorizationRoleManagementPolicyAssignmentMaxDuration = "AzureAuthorizationRoleManagementPolicyAssignmentMaxDuration",
    AzureAuthorizationRoleManagementPolicyAssignmentMfaEnabled = "AzureAuthorizationRoleManagementPolicyAssignmentMfaEnabled",
    AzureAuthorizationRoleManagementPolicyAssignmentRoleDefinition = "AzureAuthorizationRoleManagementPolicyAssignmentRoleDefinition",
    AzureAuthorizationRoleManagementPolicyAssignmentScopeResource = "AzureAuthorizationRoleManagementPolicyAssignmentScopeResource",
    AzureCognitiveServicesAccountRestrictOutboundAccess = "AzureCognitiveServicesAccountRestrictOutboundAccess",
    AzureCognitiveServicesAccountType = "AzureCognitiveServicesAccountType",
    AzureComputeDiskResourceNetworkAccessPolicyType = "AzureComputeDiskResourceNetworkAccessPolicyType",
    AzureComputeDiskResourceSource = "AzureComputeDiskResourceSource",
    AzureComputeDiskRole = "AzureComputeDiskRole",
    AzureComputeDiskSize = "AzureComputeDiskSize",
    AzureComputeDiskSnapshotType = "AzureComputeDiskSnapshotType",
    AzureComputeDiskVirtualMachine = "AzureComputeDiskVirtualMachine",
    AzureComputeVirtualMachineResourceApplicationGateways = "AzureComputeVirtualMachineResourceApplicationGateways",
    AzureComputeVirtualMachineResourceLoadBalancers = "AzureComputeVirtualMachineResourceLoadBalancers",
    AzureComputeVirtualMachineResourceRawId = "AzureComputeVirtualMachineResourceRawId",
    AzureComputeVirtualMachineResourceVirtualNetwork = "AzureComputeVirtualMachineResourceVirtualNetwork",
    AzureComputeVirtualMachineScaleSetType = "AzureComputeVirtualMachineScaleSetType",
    AzureComputeVirtualMachineScaleSetVirtualMachines = "AzureComputeVirtualMachineScaleSetVirtualMachines",
    AzureComputeVirtualMachineScaleSetVirtualMachineStatus = "AzureComputeVirtualMachineScaleSetVirtualMachineStatus",
    AzureComputeVirtualMachineScaleSetVirtualMachineVirtualMachineScaleSet = "AzureComputeVirtualMachineScaleSetVirtualMachineVirtualMachineScaleSet",
    AzureComputeVirtualMachineStatus = "AzureComputeVirtualMachineStatus",
    AzureComputeVirtualMachineStopTime = "AzureComputeVirtualMachineStopTime",
    AzureContainerRegistryRegistryRepositories = "AzureContainerRegistryRegistryRepositories",
    AzureContainerRegistryRegistrySku = "AzureContainerRegistryRegistrySku",
    AzureContainerRegistryRepositoryContainerImageResources = "AzureContainerRegistryRepositoryContainerImageResources",
    AzureContainerRegistryRepositoryContainerImageVulnerabilities = "AzureContainerRegistryRepositoryContainerImageVulnerabilities",
    AzureContainerRegistryRepositoryContainerImageVulnerabilitySeverities = "AzureContainerRegistryRepositoryContainerImageVulnerabilitySeverities",
    AzureContainerRegistryRepositoryRawUrl = "AzureContainerRegistryRepositoryRawUrl",
    AzureContainerRegistryRepositoryRegistry = "AzureContainerRegistryRepositoryRegistry",
    AzureContainerServiceManagedClusterAgentPoolCluster = "AzureContainerServiceManagedClusterAgentPoolCluster",
    AzureContainerServiceManagedClusterAgentPoolMode = "AzureContainerServiceManagedClusterAgentPoolMode",
    AzureContainerServiceManagedClusterAgentPoolStatus = "AzureContainerServiceManagedClusterAgentPoolStatus",
    AzureContainerServiceManagedClusterAgentPoolVirtualMachineScaleSets = "AzureContainerServiceManagedClusterAgentPoolVirtualMachineScaleSets",
    AzureContainerServiceManagedClusterAuthenticationAndAuthorizationMode = "AzureContainerServiceManagedClusterAuthenticationAndAuthorizationMode",
    AzureContainerServiceManagedClusterStatus = "AzureContainerServiceManagedClusterStatus",
    AzureContainerServiceManagedClusterVirtualNetwork = "AzureContainerServiceManagedClusterVirtualNetwork",
    AzureDocumentDbDatabaseAccountManagementKeyAccess = "AzureDocumentDbDatabaseAccountManagementKeyAccess",
    AzureDocumentDbDatabaseAccountResources = "AzureDocumentDbDatabaseAccountResources",
    AzureDocumentDbDatabaseAccountSharedKeyAccess = "AzureDocumentDbDatabaseAccountSharedKeyAccess",
    AzureDocumentDbDatabaseAccountType = "AzureDocumentDbDatabaseAccountType",
    AzureEncryptedResourceEncryptionExists = "AzureEncryptedResourceEncryptionExists",
    AzureEncryptedResourceVaultKeys = "AzureEncryptedResourceVaultKeys",
    AzureEventHubNamespaceEventHubs = "AzureEventHubNamespaceEventHubs",
    AzureEventHubNamespaceSku = "AzureEventHubNamespaceSku",
    AzureEventHubNamespaceStatus = "AzureEventHubNamespaceStatus",
    AzureKeyVaultVaultCertificateThumbprint = "AzureKeyVaultVaultCertificateThumbprint",
    AzureKeyVaultVaultKeyType = "AzureKeyVaultVaultKeyType",
    AzureKeyVaultVaultObjectAzureManaged = "AzureKeyVaultVaultObjectAzureManaged",
    AzureKeyVaultVaultObjectEnabled = "AzureKeyVaultVaultObjectEnabled",
    AzureKeyVaultVaultObjectExpirationTime = "AzureKeyVaultVaultObjectExpirationTime",
    AzureKeyVaultVaultObjects = "AzureKeyVaultVaultObjects",
    AzureKeyVaultVaultObjectVault = "AzureKeyVaultVaultObjectVault",
    AzureKeyVaultVaultRbacEnabled = "AzureKeyVaultVaultRbacEnabled",
    AzureKubernetesDeploymentReplicaSets = "AzureKubernetesDeploymentReplicaSets",
    AzureKubernetesServiceAccountOriginatorWorkloadResources = "AzureKubernetesServiceAccountOriginatorWorkloadResources",
    AzureKubernetesServiceAccountRoleBindings = "AzureKubernetesServiceAccountRoleBindings",
    AzureKubernetesServiceAccountRoles = "AzureKubernetesServiceAccountRoles",
    AzureKubernetesWorkloadResourceAadServicePrincipals = "AzureKubernetesWorkloadResourceAadServicePrincipals",
    AzureLogicWorkflowStatus = "AzureLogicWorkflowStatus",
    AzureMachineLearningWorkspaceHighBusinessImpact = "AzureMachineLearningWorkspaceHighBusinessImpact",
    AzureManagedIdentityManagedIdentityOriginatorResources = "AzureManagedIdentityManagedIdentityOriginatorResources",
    AzureManagedIdentityManagedIdentityServicePrincipalActivityTime = "AzureManagedIdentityManagedIdentityServicePrincipalActivityTime",
    AzureManagedIdentityManagedIdentityServicePrincipalContainedRoleAssignmentResources = "AzureManagedIdentityManagedIdentityServicePrincipalContainedRoleAssignmentResources",
    AzureManagedIdentityManagedIdentityServicePrincipalRoleAssignmentResources = "AzureManagedIdentityManagedIdentityServicePrincipalRoleAssignmentResources",
    AzureManagedIdentityManagedIdentityServicePrincipalRoleDefinitions = "AzureManagedIdentityManagedIdentityServicePrincipalRoleDefinitions",
    AzureManagedIdentityManagedIdentityServicePrincipalSignInTime = "AzureManagedIdentityManagedIdentityServicePrincipalSignInTime",
    AzureManagedIdentityManagedIdentityType = "AzureManagedIdentityManagedIdentityType",
    AzureMySqlServerAdministratorUserName = "AzureMySqlServerAdministratorUserName",
    AzureMySqlServerAuthenticationType = "AzureMySqlServerAuthenticationType",
    AzureMySqlServerStatus = "AzureMySqlServerStatus",
    AzureMySqlServerTypeName = "AzureMySqlServerTypeName",
    AzureNetworkApplicationSecurityGroupVirtualMachines = "AzureNetworkApplicationSecurityGroupVirtualMachines",
    AzureNetworkDnsZoneRecordSetCount = "AzureNetworkDnsZoneRecordSetCount",
    AzureNetworkLoadBalancerSku = "AzureNetworkLoadBalancerSku",
    AzureNetworkLoadBalancerType = "AzureNetworkLoadBalancerType",
    AzureNetworkNetworkInterfaceNetworkSecurityGroup = "AzureNetworkNetworkInterfaceNetworkSecurityGroup",
    AzureNetworkNetworkInterfacePublicIpAddresses = "AzureNetworkNetworkInterfacePublicIpAddresses",
    AzureNetworkNetworkInterfaceVirtualMachine = "AzureNetworkNetworkInterfaceVirtualMachine",
    AzureNetworkNetworkInterfaceVirtualNetwork = "AzureNetworkNetworkInterfaceVirtualNetwork",
    AzureNetworkNetworkSecurityGroupSubnets = "AzureNetworkNetworkSecurityGroupSubnets",
    AzureNetworkPrivateDnsZoneRecordSetCount = "AzureNetworkPrivateDnsZoneRecordSetCount",
    AzureNetworkPrivateDnsZoneVirtualNetworks = "AzureNetworkPrivateDnsZoneVirtualNetworks",
    AzureNetworkPrivateEndpointAttachedResource = "AzureNetworkPrivateEndpointAttachedResource",
    AzureNetworkPrivateEndpointConnectionStatus = "AzureNetworkPrivateEndpointConnectionStatus",
    AzureNetworkPrivateEndpointDescription = "AzureNetworkPrivateEndpointDescription",
    AzureNetworkPublicIpAddress = "AzureNetworkPublicIpAddress",
    AzureNetworkPublicIpAddressAttachedResource = "AzureNetworkPublicIpAddressAttachedResource",
    AzureNetworkPublicIpAddressDnsName = "AzureNetworkPublicIpAddressDnsName",
    AzureNetworkPublicIpAddressSku = "AzureNetworkPublicIpAddressSku",
    AzureNetworkPublicIpAddressType = "AzureNetworkPublicIpAddressType",
    AzureNetworkPublicIpPrefixPublicIpAddresses = "AzureNetworkPublicIpPrefixPublicIpAddresses",
    AzureNetworkPublicIpPrefixSubnet = "AzureNetworkPublicIpPrefixSubnet",
    AzureNetworkRouteTableSubnets = "AzureNetworkRouteTableSubnets",
    AzureNetworkVirtualNetworkHasNetworkedResources = "AzureNetworkVirtualNetworkHasNetworkedResources",
    AzureNetworkVirtualNetworkNetworkedResources = "AzureNetworkVirtualNetworkNetworkedResources",
    AzureNetworkVirtualNetworkSubnetNetworkedResources = "AzureNetworkVirtualNetworkSubnetNetworkedResources",
    AzureNetworkVirtualNetworkSubnetNetworkSecurityGroup = "AzureNetworkVirtualNetworkSubnetNetworkSecurityGroup",
    AzureNetworkVirtualNetworkSubnets = "AzureNetworkVirtualNetworkSubnets",
    AzureNetworkVirtualNetworkSubnetSubnets = "AzureNetworkVirtualNetworkSubnetSubnets",
    AzureNetworkVirtualNetworkSubnetVirtualNetwork = "AzureNetworkVirtualNetworkSubnetVirtualNetwork",
    AzurePostgreSqlServerAdministratorUserName = "AzurePostgreSqlServerAdministratorUserName",
    AzurePostgreSqlServerAuthenticationType = "AzurePostgreSqlServerAuthenticationType",
    AzurePostgreSqlServerStatus = "AzurePostgreSqlServerStatus",
    AzurePostgreSqlServerTypeName = "AzurePostgreSqlServerTypeName",
    AzureResourceGroupResourceResourceGroup = "AzureResourceGroupResourceResourceGroup",
    AzureSqlServerAdministratorUserName = "AzureSqlServerAdministratorUserName",
    AzureSqlServerAuthenticationType = "AzureSqlServerAuthenticationType",
    AzureSqlServerDatabases = "AzureSqlServerDatabases",
    AzureSqlServerDatabaseServer = "AzureSqlServerDatabaseServer",
    AzureSqlServerDatabaseStatus = "AzureSqlServerDatabaseStatus",
    AzureSqlServerDatabaseStorageSize = "AzureSqlServerDatabaseStorageSize",
    AzureSqlServerEventRetentionTimeFrame = "AzureSqlServerEventRetentionTimeFrame",
    AzureSqlServerStatus = "AzureSqlServerStatus",
    AzureStorageStorageAccountBlobContainerEffectivePublicAccess = "AzureStorageStorageAccountBlobContainerEffectivePublicAccess",
    AzureStorageStorageAccountBlobContainerSoftDeleteEnabled = "AzureStorageStorageAccountBlobContainerSoftDeleteEnabled",
    AzureStorageStorageAccountBlobContainerStorageAccount = "AzureStorageStorageAccountBlobContainerStorageAccount",
    AzureStorageStorageAccountBlobSoftDeleteEnabled = "AzureStorageStorageAccountBlobSoftDeleteEnabled",
    AzureStorageStorageAccountHttpsOnly = "AzureStorageStorageAccountHttpsOnly",
    AzureStorageStorageAccountInfrastructureEncryption = "AzureStorageStorageAccountInfrastructureEncryption",
    AzureStorageStorageAccountSharedKeyAccess = "AzureStorageStorageAccountSharedKeyAccess",
    AzureStorageStorageAccountTlsMinVersion = "AzureStorageStorageAccountTlsMinVersion",
    AzureStorageStorageAccountType = "AzureStorageStorageAccountType",
    AzureTenantEntityParentPath = "AzureTenantEntityParentPath",
    AzureTenantEntityRawShortId = "AzureTenantEntityRawShortId",
    AzureTenantEntityResourceGroups = "AzureTenantEntityResourceGroups",
    AzureTenantEntityStatus = "AzureTenantEntityStatus",
    AzureWebApplicationAuthenticationType = "AzureWebApplicationAuthenticationType",
    AzureWebApplicationHttpsOnly = "AzureWebApplicationHttpsOnly",
    AzureWebApplicationStatus = "AzureWebApplicationStatus",
    AzureWebApplicationTlsMinVersion = "AzureWebApplicationTlsMinVersion",
    AzureWebApplicationType = "AzureWebApplicationType",
    CiContainerImageRepositoryContainerImageResources = "CiContainerImageRepositoryContainerImageResources",
    CiContainerImageRepositoryContainerImageVulnerabilities = "CiContainerImageRepositoryContainerImageVulnerabilities",
    CiContainerImageRepositoryContainerImageVulnerabilitySeverities = "CiContainerImageRepositoryContainerImageVulnerabilitySeverities",
    ContainerImageCreationTime = "ContainerImageCreationTime",
    ContainerImageDigest = "ContainerImageDigest",
    ContainerImageKubernetesWorkloadResources = "ContainerImageKubernetesWorkloadResources",
    ContainerImageRepository = "ContainerImageRepository",
    ContainerImageScanTime = "ContainerImageScanTime",
    ContainerImageTags = "ContainerImageTags",
    ContainerImageVirtualMachines = "ContainerImageVirtualMachines",
    ContainerImageVulnerabilities = "ContainerImageVulnerabilities",
    ContainerImageVulnerabilitySeverities = "ContainerImageVulnerabilitySeverities",
    ContainerImageWorkloadClusterResources = "ContainerImageWorkloadClusterResources",
    ContainerImageWorkloadExists = "ContainerImageWorkloadExists",
    DataAnalysisResourceDataCategories = "DataAnalysisResourceDataCategories",
    DataAnalysisResourceDataClassifiers = "DataAnalysisResourceDataClassifiers",
    DataAnalysisResourceDataSensitivities = "DataAnalysisResourceDataSensitivities",
    DataAnalysisResourceScanStatus = "DataAnalysisResourceScanStatus",
    DataAnalysisResourceScanTime = "DataAnalysisResourceScanTime",
    DataAnalysisResourceStorageSize = "DataAnalysisResourceStorageSize",
    DataAnalysisResourceTypeName = "DataAnalysisResourceTypeName",
    EntityAttributes = "EntityAttributes",
    EntityId = "EntityId",
    EntityProperties = "EntityProperties",
    EntityRegion = "EntityRegion",
    EntityRegionLocation = "EntityRegionLocation",
    GciDirectoryDirectoryRoleAssignedIdentities = "GciDirectoryDirectoryRoleAssignedIdentities",
    GciDirectoryDirectoryRoleType = "GciDirectoryDirectoryRoleType",
    GciDirectoryGroupMemberPrincipals = "GciDirectoryGroupMemberPrincipals",
    GciDirectoryGroupTypes = "GciDirectoryGroupTypes",
    GciDirectoryPartialPrincipalGroups = "GciDirectoryPartialPrincipalGroups",
    GciDirectoryPartialPrincipalRoles = "GciDirectoryPartialPrincipalRoles",
    GciDirectoryPartialUserActivityTime = "GciDirectoryPartialUserActivityTime",
    GciDirectoryPrincipalCreationTime = "GciDirectoryPrincipalCreationTime",
    GciDirectoryPrincipalGcpRoles = "GciDirectoryPrincipalGcpRoles",
    GciDirectoryPrincipalGroups = "GciDirectoryPrincipalGroups",
    GciDirectoryPrincipalMail = "GciDirectoryPrincipalMail",
    GciDirectoryUserActivityTime = "GciDirectoryUserActivityTime",
    GciDirectoryUserMfaEnforced = "GciDirectoryUserMfaEnforced",
    GciDirectoryUserSignInTime = "GciDirectoryUserSignInTime",
    GciDirectoryUserStatus = "GciDirectoryUserStatus",
    GciPrincipalContainedGcpRoleBindings = "GciPrincipalContainedGcpRoleBindings",
    GciPrincipalGcpRoleBindings = "GciPrincipalGcpRoleBindings",
    GciPrincipalGroups = "GciPrincipalGroups",
    GciTenantEntityCreationTime = "GciTenantEntityCreationTime",
    GciTenantEntityPrimaryDomainName = "GciTenantEntityPrimaryDomainName",
    GciTenantEntityRawId = "GciTenantEntityRawId",
    GcpApiKeysKeyRestrictionsClientRawIdentifiers = "GcpApiKeysKeyRestrictionsClientRawIdentifiers",
    GcpApiKeysKeyRestrictionsServiceHostNames = "GcpApiKeysKeyRestrictionsServiceHostNames",
    GcpAppEngineApplicationServiceApplication = "GcpAppEngineApplicationServiceApplication",
    GcpAppEngineApplicationServiceContainerImages = "GcpAppEngineApplicationServiceContainerImages",
    GcpAppEngineApplicationServiceContainerImageVulnerabilities = "GcpAppEngineApplicationServiceContainerImageVulnerabilities",
    GcpAppEngineApplicationServiceContainerImageVulnerabilitySeverities = "GcpAppEngineApplicationServiceContainerImageVulnerabilitySeverities",
    GcpAppEngineApplicationServiceRevisionMaxCreationTime = "GcpAppEngineApplicationServiceRevisionMaxCreationTime",
    GcpAppEngineApplicationServices = "GcpAppEngineApplicationServices",
    GcpArtifactContainerImageRepositoryContainerImageResources = "GcpArtifactContainerImageRepositoryContainerImageResources",
    GcpArtifactContainerImageRepositoryContainerImageVulnerabilities = "GcpArtifactContainerImageRepositoryContainerImageVulnerabilities",
    GcpArtifactContainerImageRepositoryContainerImageVulnerabilitySeverities = "GcpArtifactContainerImageRepositoryContainerImageVulnerabilitySeverities",
    GcpArtifactContainerImageRepositoryRegistry = "GcpArtifactContainerImageRepositoryRegistry",
    GcpArtifactPackageRepositoryRegistry = "GcpArtifactPackageRepositoryRegistry",
    GcpArtifactPackageRepositoryRegistryType = "GcpArtifactPackageRepositoryRegistryType",
    GcpArtifactRegistryRepositories = "GcpArtifactRegistryRepositories",
    GcpArtifactRegistryType = "GcpArtifactRegistryType",
    GcpBigQueryDatasetStorageSize = "GcpBigQueryDatasetStorageSize",
    GcpBigQueryDatasetTableDataset = "GcpBigQueryDatasetTableDataset",
    GcpBigQueryDatasetTables = "GcpBigQueryDatasetTables",
    GcpBigQueryDatasetTableSize = "GcpBigQueryDatasetTableSize",
    GcpCloudBuildBuildTriggerEnabled = "GcpCloudBuildBuildTriggerEnabled",
    GcpCloudRunServiceContainerImages = "GcpCloudRunServiceContainerImages",
    GcpCloudRunServiceContainerImageVulnerabilities = "GcpCloudRunServiceContainerImageVulnerabilities",
    GcpCloudRunServiceContainerImageVulnerabilitySeverities = "GcpCloudRunServiceContainerImageVulnerabilitySeverities",
    GcpComputeBackendServiceBackendResources = "GcpComputeBackendServiceBackendResources",
    GcpComputeBackendServiceVpc = "GcpComputeBackendServiceVpc",
    GcpComputeDiskInstances = "GcpComputeDiskInstances",
    GcpComputeDiskSize = "GcpComputeDiskSize",
    GcpComputeDiskSource = "GcpComputeDiskSource",
    GcpComputeDiskStatus = "GcpComputeDiskStatus",
    GcpComputeDiskType = "GcpComputeDiskType",
    GcpComputeFirewallPolicyAttachedResources = "GcpComputeFirewallPolicyAttachedResources",
    GcpComputeFirewallPolicyRulesCount = "GcpComputeFirewallPolicyRulesCount",
    GcpComputeFirewallPolicyScope = "GcpComputeFirewallPolicyScope",
    GcpComputeFirewallPolicyVpcs = "GcpComputeFirewallPolicyVpcs",
    GcpComputeForwardingRuleIpAddress = "GcpComputeForwardingRuleIpAddress",
    GcpComputeForwardingRuleTarget = "GcpComputeForwardingRuleTarget",
    GcpComputeForwardingRuleVpcs = "GcpComputeForwardingRuleVpcs",
    GcpComputeImageFamilyTag = "GcpComputeImageFamilyTag",
    GcpComputeImageInstances = "GcpComputeImageInstances",
    GcpComputeImageSize = "GcpComputeImageSize",
    GcpComputeImageSource = "GcpComputeImageSource",
    GcpComputeImageStatus = "GcpComputeImageStatus",
    GcpComputeImageType = "GcpComputeImageType",
    GcpComputeInstanceGroupInstanceGroupManagerStatus = "GcpComputeInstanceGroupInstanceGroupManagerStatus",
    GcpComputeInstanceGroupInstances = "GcpComputeInstanceGroupInstances",
    GcpComputeInstanceGroupType = "GcpComputeInstanceGroupType",
    GcpComputeInstanceGroupVpc = "GcpComputeInstanceGroupVpc",
    GcpComputeInstanceStatus = "GcpComputeInstanceStatus",
    GcpComputeInstanceStopTime = "GcpComputeInstanceStopTime",
    GcpComputeInstanceTargetPools = "GcpComputeInstanceTargetPools",
    GcpComputeInstanceTemplateInstanceGroups = "GcpComputeInstanceTemplateInstanceGroups",
    GcpComputeInstanceTemplateInstances = "GcpComputeInstanceTemplateInstances",
    GcpComputeInstanceTemplateVpcs = "GcpComputeInstanceTemplateVpcs",
    GcpComputeInstanceVpcs = "GcpComputeInstanceVpcs",
    GcpComputeLoadBalancerResourceLoadBalancers = "GcpComputeLoadBalancerResourceLoadBalancers",
    GcpComputeLoadBalancerType = "GcpComputeLoadBalancerType",
    GcpComputeSnapshotInstances = "GcpComputeSnapshotInstances",
    GcpComputeSnapshotSize = "GcpComputeSnapshotSize",
    GcpComputeSnapshotSourceDisk = "GcpComputeSnapshotSourceDisk",
    GcpComputeSnapshotStatus = "GcpComputeSnapshotStatus",
    GcpComputeSnapshotType = "GcpComputeSnapshotType",
    GcpComputeSslPolicyFeatureProfile = "GcpComputeSslPolicyFeatureProfile",
    GcpComputeSslPolicyTlsMinVersion = "GcpComputeSslPolicyTlsMinVersion",
    GcpComputeSubnetNetworkResources = "GcpComputeSubnetNetworkResources",
    GcpComputeSubnetSubnets = "GcpComputeSubnetSubnets",
    GcpComputeSubnetVpc = "GcpComputeSubnetVpc",
    GcpComputeTargetPoolInstances = "GcpComputeTargetPoolInstances",
    GcpComputeTargetProxyForwardingRules = "GcpComputeTargetProxyForwardingRules",
    GcpComputeTargetProxyTargetResource = "GcpComputeTargetProxyTargetResource",
    GcpComputeTargetProxyType = "GcpComputeTargetProxyType",
    GcpComputeUrlMapBackendResources = "GcpComputeUrlMapBackendResources",
    GcpComputeVpcNetworkResources = "GcpComputeVpcNetworkResources",
    GcpComputeVpcPeeringConnectionAccepterSubnets = "GcpComputeVpcPeeringConnectionAccepterSubnets",
    GcpComputeVpcPeeringConnectionAccepterVpc = "GcpComputeVpcPeeringConnectionAccepterVpc",
    GcpComputeVpcPeeringConnectionRequesterVpc = "GcpComputeVpcPeeringConnectionRequesterVpc",
    GcpComputeVpcPeeringConnectionStatus = "GcpComputeVpcPeeringConnectionStatus",
    GcpComputeVpcSubnetRegions = "GcpComputeVpcSubnetRegions",
    GcpComputeVpcSubnetSubnets = "GcpComputeVpcSubnetSubnets",
    GcpContainerClusterMode = "GcpContainerClusterMode",
    GcpContainerClusterNodePoolCluster = "GcpContainerClusterNodePoolCluster",
    GcpContainerClusterNodePoolInstanceGroups = "GcpContainerClusterNodePoolInstanceGroups",
    GcpContainerClusterNodePoolStatus = "GcpContainerClusterNodePoolStatus",
    GcpContainerClusterNodePublicIpAddressExists = "GcpContainerClusterNodePublicIpAddressExists",
    GcpContainerClusterStatus = "GcpContainerClusterStatus",
    GcpContainerClusterVpc = "GcpContainerClusterVpc",
    GcpDnsManagedZoneDescription = "GcpDnsManagedZoneDescription",
    GcpDnsManagedZoneDnsSecKeySigningKeyAlgorithm = "GcpDnsManagedZoneDnsSecKeySigningKeyAlgorithm",
    GcpDnsManagedZoneDnsSecStatus = "GcpDnsManagedZoneDnsSecStatus",
    GcpDnsManagedZoneDnsSecZoneSigningKeyAlgorithm = "GcpDnsManagedZoneDnsSecZoneSigningKeyAlgorithm",
    GcpDnsManagedZoneLoggingEnabled = "GcpDnsManagedZoneLoggingEnabled",
    GcpDnsManagedZonePrivateZoneType = "GcpDnsManagedZonePrivateZoneType",
    GcpDnsManagedZonePublicNetworkAccess = "GcpDnsManagedZonePublicNetworkAccess",
    GcpDnsManagedZoneResourceRecordSetCount = "GcpDnsManagedZoneResourceRecordSetCount",
    GcpEncryptedResourceEncryptionExists = "GcpEncryptedResourceEncryptionExists",
    GcpEncryptedResourceKmsEncryptionKeys = "GcpEncryptedResourceKmsEncryptionKeys",
    GcpFunctionsFunctionArtifactRegistry = "GcpFunctionsFunctionArtifactRegistry",
    GcpFunctionsFunctionBuildServiceAccount = "GcpFunctionsFunctionBuildServiceAccount",
    GcpFunctionsFunctionTriggerType = "GcpFunctionsFunctionTriggerType",
    GcpFunctionsFunctionType = "GcpFunctionsFunctionType",
    GcpIamPartialServiceAccountActivityTime = "GcpIamPartialServiceAccountActivityTime",
    GcpIamPartialServiceAccountGroups = "GcpIamPartialServiceAccountGroups",
    GcpIamPartialServiceAccountRoles = "GcpIamPartialServiceAccountRoles",
    GcpIamRoleBindingContainingRoleBindings = "GcpIamRoleBindingContainingRoleBindings",
    GcpIamRoleBindingExpired = "GcpIamRoleBindingExpired",
    GcpIamRoleBindingPermissionUsageType = "GcpIamRoleBindingPermissionUsageType",
    GcpIamRoleBindingPrincipal = "GcpIamRoleBindingPrincipal",
    GcpIamRoleBindingRole = "GcpIamRoleBindingRole",
    GcpIamRoleBindingScopeResource = "GcpIamRoleBindingScopeResource",
    GcpIamRoleBindingScopeResourceTypeName = "GcpIamRoleBindingScopeResourceTypeName",
    GcpIamRoleType = "GcpIamRoleType",
    GcpIamServiceAccountActivityTime = "GcpIamServiceAccountActivityTime",
    GcpIamServiceAccountDisabled = "GcpIamServiceAccountDisabled",
    GcpIamServiceAccountGroups = "GcpIamServiceAccountGroups",
    GcpIamServiceAccountIdentityRoles = "GcpIamServiceAccountIdentityRoles",
    GcpIamServiceAccountMail = "GcpIamServiceAccountMail",
    GcpIamServiceAccountOriginatorResources = "GcpIamServiceAccountOriginatorResources",
    GcpIamServiceAccountSignInTime = "GcpIamServiceAccountSignInTime",
    GcpIamWorkforcePoolContainedRoleBindings = "GcpIamWorkforcePoolContainedRoleBindings",
    GcpIamWorkforcePoolEnabled = "GcpIamWorkforcePoolEnabled",
    GcpIamWorkforcePoolProviderType = "GcpIamWorkforcePoolProviderType",
    GcpIamWorkforcePoolRawShortId = "GcpIamWorkforcePoolRawShortId",
    GcpIamWorkforcePoolRoleBindings = "GcpIamWorkforcePoolRoleBindings",
    GcpIamWorkforcePoolRoles = "GcpIamWorkforcePoolRoles",
    GcpIamWorkforcePoolSessionDuration = "GcpIamWorkforcePoolSessionDuration",
    GcpIamWorkloadIdentityPoolContainedRoleBindings = "GcpIamWorkloadIdentityPoolContainedRoleBindings",
    GcpIamWorkloadIdentityPoolEnabled = "GcpIamWorkloadIdentityPoolEnabled",
    GcpIamWorkloadIdentityPoolProviderType = "GcpIamWorkloadIdentityPoolProviderType",
    GcpIamWorkloadIdentityPoolRawShortId = "GcpIamWorkloadIdentityPoolRawShortId",
    GcpIamWorkloadIdentityPoolRoleBindings = "GcpIamWorkloadIdentityPoolRoleBindings",
    GcpIamWorkloadIdentityPoolRoles = "GcpIamWorkloadIdentityPoolRoles",
    GcpKmsKeyRingKeyEncryptedResources = "GcpKmsKeyRingKeyEncryptedResources",
    GcpKmsKeyRingKeyKeyRing = "GcpKmsKeyRingKeyKeyRing",
    GcpKmsKeyRingKeys = "GcpKmsKeyRingKeys",
    GcpKubernetesDeploymentReplicaSets = "GcpKubernetesDeploymentReplicaSets",
    GcpKubernetesServiceAccountOriginatorWorkloadResources = "GcpKubernetesServiceAccountOriginatorWorkloadResources",
    GcpKubernetesServiceAccountRoleBindings = "GcpKubernetesServiceAccountRoleBindings",
    GcpKubernetesServiceAccountRoles = "GcpKubernetesServiceAccountRoles",
    GcpKubernetesWorkloadResourceGcpServiceAccounts = "GcpKubernetesWorkloadResourceGcpServiceAccounts",
    GcpLoggingLogSinkDestination = "GcpLoggingLogSinkDestination",
    GcpLoggingLogSinkEnabled = "GcpLoggingLogSinkEnabled",
    GcpNotebooksInstanceComputeInstance = "GcpNotebooksInstanceComputeInstance",
    GcpNotebooksInstanceRootAccess = "GcpNotebooksInstanceRootAccess",
    GcpNotebooksInstanceStatus = "GcpNotebooksInstanceStatus",
    GcpPubSubSubscriptionMessageDeliveryTypes = "GcpPubSubSubscriptionMessageDeliveryTypes",
    GcpPubSubSubscriptionTopics = "GcpPubSubSubscriptionTopics",
    GcpPubSubTopicMessageRetentionDuration = "GcpPubSubTopicMessageRetentionDuration",
    GcpPubSubTopicSubscriptions = "GcpPubSubTopicSubscriptions",
    GcpResourceUpdateTime = "GcpResourceUpdateTime",
    GcpScopeResourceAccessLevel = "GcpScopeResourceAccessLevel",
    GcpScopeResourceRawId = "GcpScopeResourceRawId",
    GcpServiceAccountOriginatorScopeResourceServiceAccount = "GcpServiceAccountOriginatorScopeResourceServiceAccount",
    GcpSpannerInstanceDatabaseInstance = "GcpSpannerInstanceDatabaseInstance",
    GcpSpannerInstanceDatabases = "GcpSpannerInstanceDatabases",
    GcpSqlInstanceDatabases = "GcpSqlInstanceDatabases",
    GcpSqlInstanceDatabaseVersion = "GcpSqlInstanceDatabaseVersion",
    GcpSqlInstanceSize = "GcpSqlInstanceSize",
    GcpSqlInstanceType = "GcpSqlInstanceType",
    GcpStorageBucketObjectCount = "GcpStorageBucketObjectCount",
    GcpStorageBucketSize = "GcpStorageBucketSize",
    GcpTenantEntityOwners = "GcpTenantEntityOwners",
    GcpTenantEntityParentPath = "GcpTenantEntityParentPath",
    GcpTenantEntityRawShortNameId = "GcpTenantEntityRawShortNameId",
    GcpTenantEntityRawShortNumberId = "GcpTenantEntityRawShortNumberId",
    GcpTenantEntityStatus = "GcpTenantEntityStatus",
    KubernetesClusterApiConnectivityStatus = "KubernetesClusterApiConnectivityStatus",
    KubernetesClusterResourceUpdateTime = "KubernetesClusterResourceUpdateTime",
    KubernetesClusterVersion = "KubernetesClusterVersion",
    KubernetesIngressLoadBalancer = "KubernetesIngressLoadBalancer",
    KubernetesNamespaceResourceNamespace = "KubernetesNamespaceResourceNamespace",
    KubernetesNamespaceResources = "KubernetesNamespaceResources",
    KubernetesPrincipalRoleBindings = "KubernetesPrincipalRoleBindings",
    KubernetesPrincipalRoles = "KubernetesPrincipalRoles",
    KubernetesResourceCluster = "KubernetesResourceCluster",
    KubernetesRoleBindingPrincipals = "KubernetesRoleBindingPrincipals",
    KubernetesRoleBindingRole = "KubernetesRoleBindingRole",
    KubernetesRolePrincipals = "KubernetesRolePrincipals",
    KubernetesRoleRoleBindings = "KubernetesRoleRoleBindings",
    KubernetesServiceType = "KubernetesServiceType",
    KubernetesWorkloadContainerImageVulnerabilitySeverities = "KubernetesWorkloadContainerImageVulnerabilitySeverities",
    KubernetesWorkloadResourceContainerImages = "KubernetesWorkloadResourceContainerImages",
    KubernetesWorkloadResourceContainerImageVulnerabilities = "KubernetesWorkloadResourceContainerImageVulnerabilities",
    KubernetesWorkloadResourceControllerResource = "KubernetesWorkloadResourceControllerResource",
    KubernetesWorkloadResourceServiceAccount = "KubernetesWorkloadResourceServiceAccount",
    KubernetesWorkloadResourceTypeName = "KubernetesWorkloadResourceTypeName",
    NetworkInboundAccessType = "NetworkInboundAccessType",
    NetworkInboundExternalAccessScope = "NetworkInboundExternalAccessScope",
    NetworkInboundExternalDestinationNetworkScopes = "NetworkInboundExternalDestinationNetworkScopes",
    OciAutoscalingAutoscalingConfigurationEnabled = "OciAutoscalingAutoscalingConfigurationEnabled",
    OciAutoscalingAutoscalingConfigurationInstancePool = "OciAutoscalingAutoscalingConfigurationInstancePool",
    OciBlockStorageBlockVolumeInstances = "OciBlockStorageBlockVolumeInstances",
    OciBlockStorageBlockVolumeSize = "OciBlockStorageBlockVolumeSize",
    OciBlockStorageBlockVolumeStatus = "OciBlockStorageBlockVolumeStatus",
    OciBlockStorageBootVolumeInstance = "OciBlockStorageBootVolumeInstance",
    OciBlockStorageBootVolumeSize = "OciBlockStorageBootVolumeSize",
    OciBlockStorageBootVolumeStatus = "OciBlockStorageBootVolumeStatus",
    OciComputeImageOciManaged = "OciComputeImageOciManaged",
    OciComputeImageOperatingSystemType = "OciComputeImageOperatingSystemType",
    OciComputeInstanceConfigurationInstancePools = "OciComputeInstanceConfigurationInstancePools",
    OciComputeInstanceConfigurationMemoryEncryptionEnabled = "OciComputeInstanceConfigurationMemoryEncryptionEnabled",
    OciComputeInstanceConfigurationMetadataServiceVersion1Enabled = "OciComputeInstanceConfigurationMetadataServiceVersion1Enabled",
    OciComputeInstanceConfigurationSecureBootEnabled = "OciComputeInstanceConfigurationSecureBootEnabled",
    OciComputeInstanceConfigurationShape = "OciComputeInstanceConfigurationShape",
    OciComputeInstanceConfigurationVolumeEncryptionInTransitEnabled = "OciComputeInstanceConfigurationVolumeEncryptionInTransitEnabled",
    OciComputeInstanceInstanceConfiguration = "OciComputeInstanceInstanceConfiguration",
    OciComputeInstanceMemoryEncryptionEnabled = "OciComputeInstanceMemoryEncryptionEnabled",
    OciComputeInstanceMetadataServiceVersion1Enabled = "OciComputeInstanceMetadataServiceVersion1Enabled",
    OciComputeInstancePoolInstanceConfiguration = "OciComputeInstancePoolInstanceConfiguration",
    OciComputeInstancePoolInstances = "OciComputeInstancePoolInstances",
    OciComputeInstancePoolStatus = "OciComputeInstancePoolStatus",
    OciComputeInstancePrimaryPrivateIpAddress = "OciComputeInstancePrimaryPrivateIpAddress",
    OciComputeInstancePrimaryPublicIpAddress = "OciComputeInstancePrimaryPublicIpAddress",
    OciComputeInstanceSecureBootEnabled = "OciComputeInstanceSecureBootEnabled",
    OciComputeInstanceShape = "OciComputeInstanceShape",
    OciComputeInstanceStatus = "OciComputeInstanceStatus",
    OciComputeInstanceVolumeEncryptionInTransitEnabled = "OciComputeInstanceVolumeEncryptionInTransitEnabled",
    OciContainerEngineClusterNodePoolCluster = "OciContainerEngineClusterNodePoolCluster",
    OciContainerEngineClusterNodePoolInstances = "OciContainerEngineClusterNodePoolInstances",
    OciContainerEngineClusterNodePoolMetadataServiceVersion1Enabled = "OciContainerEngineClusterNodePoolMetadataServiceVersion1Enabled",
    OciContainerEngineClusterNodePools = "OciContainerEngineClusterNodePools",
    OciContainerEngineClusterNodePoolStatus = "OciContainerEngineClusterNodePoolStatus",
    OciContainerEngineClusterNodePoolVolumeEncryptionInTransitEnabled = "OciContainerEngineClusterNodePoolVolumeEncryptionInTransitEnabled",
    OciContainerEngineClusterStatus = "OciContainerEngineClusterStatus",
    OciEncryptedResourceEncryptionExists = "OciEncryptedResourceEncryptionExists",
    OciEncryptedResourceEncryptionVaultKey = "OciEncryptedResourceEncryptionVaultKey",
    OciFssFileSystemSize = "OciFssFileSystemSize",
    OciFssFileSystemStatus = "OciFssFileSystemStatus",
    OciFssMountTargetExportFileSystem = "OciFssMountTargetExportFileSystem",
    OciFssMountTargetExportMountTarget = "OciFssMountTargetExportMountTarget",
    OciFssMountTargetExports = "OciFssMountTargetExports",
    OciFssMountTargetExportStatus = "OciFssMountTargetExportStatus",
    OciFssMountTargetFileSystems = "OciFssMountTargetFileSystems",
    OciFssMountTargetNetworkSecurityGroups = "OciFssMountTargetNetworkSecurityGroups",
    OciFssMountTargetPrivateIpAddress = "OciFssMountTargetPrivateIpAddress",
    OciFssMountTargetStatus = "OciFssMountTargetStatus",
    OciFssMountTargetVcn = "OciFssMountTargetVcn",
    OciIamCredentialUser = "OciIamCredentialUser",
    OciIamDomainDefaultDomain = "OciIamDomainDefaultDomain",
    OciIamDomainLicenseType = "OciIamDomainLicenseType",
    OciIamGroupMemberUsers = "OciIamGroupMemberUsers",
    OciIamPrincipalDomain = "OciIamPrincipalDomain",
    OciIamResourceDescription = "OciIamResourceDescription",
    OciIamResourceEnabled = "OciIamResourceEnabled",
    OciIamUserGroups = "OciIamUserGroups",
    OciIamUserMail = "OciIamUserMail",
    OciIamUserMfaEnrolled = "OciIamUserMfaEnrolled",
    OciIamUserSecretKeyAccessKey = "OciIamUserSecretKeyAccessKey",
    OciIamUserSignInTime = "OciIamUserSignInTime",
    OciIamUserUserApiKeys = "OciIamUserUserApiKeys",
    OciIamUserUserAuthTokens = "OciIamUserUserAuthTokens",
    OciIamUserUserDatabasePasswords = "OciIamUserUserDatabasePasswords",
    OciIamUserUserSecretKeys = "OciIamUserUserSecretKeys",
    OciKmsVaultKeyAlgorithm = "OciKmsVaultKeyAlgorithm",
    OciKmsVaultKeyProtectionMode = "OciKmsVaultKeyProtectionMode",
    OciKmsVaultKeys = "OciKmsVaultKeys",
    OciKmsVaultKeyStatus = "OciKmsVaultKeyStatus",
    OciKmsVaultObjectVault = "OciKmsVaultObjectVault",
    OciKmsVaultSecrets = "OciKmsVaultSecrets",
    OciKmsVaultSecretStatus = "OciKmsVaultSecretStatus",
    OciKmsVaultStatus = "OciKmsVaultStatus",
    OciKmsVaultType = "OciKmsVaultType",
    OciLoadBalancingLoadBalancerAccessLogsEnabled = "OciLoadBalancingLoadBalancerAccessLogsEnabled",
    OciLoadBalancingLoadBalancerErrorLogsEnabled = "OciLoadBalancingLoadBalancerErrorLogsEnabled",
    OciLoadBalancingLoadBalancerExternal = "OciLoadBalancingLoadBalancerExternal",
    OciLoadBalancingLoadBalancerPrivateIpAddresses = "OciLoadBalancingLoadBalancerPrivateIpAddresses",
    OciLoadBalancingLoadBalancerPublicIpAddresses = "OciLoadBalancingLoadBalancerPublicIpAddresses",
    OciLoadBalancingLoadBalancerSecurityGroups = "OciLoadBalancingLoadBalancerSecurityGroups",
    OciLoadBalancingLoadBalancerStatus = "OciLoadBalancingLoadBalancerStatus",
    OciLoadBalancingLoadBalancerVcn = "OciLoadBalancingLoadBalancerVcn",
    OciLoadBalancingLoadBalancerWebApplicationFirewall = "OciLoadBalancingLoadBalancerWebApplicationFirewall",
    OciNetworkingInternetGatewayEnabled = "OciNetworkingInternetGatewayEnabled",
    OciNetworkingInternetGatewayStatus = "OciNetworkingInternetGatewayStatus",
    OciNetworkingInternetGatewayVcn = "OciNetworkingInternetGatewayVcn",
    OciNetworkingLocalPeeringGatewayCrossTenancyPeering = "OciNetworkingLocalPeeringGatewayCrossTenancyPeering",
    OciNetworkingLocalPeeringGatewayPeeringStatus = "OciNetworkingLocalPeeringGatewayPeeringStatus",
    OciNetworkingLocalPeeringGatewayPeerVcn = "OciNetworkingLocalPeeringGatewayPeerVcn",
    OciNetworkingLocalPeeringGatewayStatus = "OciNetworkingLocalPeeringGatewayStatus",
    OciNetworkingLocalPeeringGatewayVcn = "OciNetworkingLocalPeeringGatewayVcn",
    OciNetworkingNatGatewayBlockTraffic = "OciNetworkingNatGatewayBlockTraffic",
    OciNetworkingNatGatewayStatus = "OciNetworkingNatGatewayStatus",
    OciNetworkingNatGatewayVcn = "OciNetworkingNatGatewayVcn",
    OciNetworkingNetworkLoadBalancerExternal = "OciNetworkingNetworkLoadBalancerExternal",
    OciNetworkingNetworkLoadBalancerPrivateIpAddresses = "OciNetworkingNetworkLoadBalancerPrivateIpAddresses",
    OciNetworkingNetworkLoadBalancerPublicIpAddresses = "OciNetworkingNetworkLoadBalancerPublicIpAddresses",
    OciNetworkingNetworkLoadBalancerSecurityGroups = "OciNetworkingNetworkLoadBalancerSecurityGroups",
    OciNetworkingNetworkLoadBalancerStatus = "OciNetworkingNetworkLoadBalancerStatus",
    OciNetworkingNetworkLoadBalancerVcn = "OciNetworkingNetworkLoadBalancerVcn",
    OciNetworkingNetworkSecurityGroupAssociatedResources = "OciNetworkingNetworkSecurityGroupAssociatedResources",
    OciNetworkingNetworkSecurityGroupStatus = "OciNetworkingNetworkSecurityGroupStatus",
    OciNetworkingNetworkSecurityGroupVcn = "OciNetworkingNetworkSecurityGroupVcn",
    OciNetworkingRouteTableStatus = "OciNetworkingRouteTableStatus",
    OciNetworkingRouteTableVcn = "OciNetworkingRouteTableVcn",
    OciNetworkingSecurityListStatus = "OciNetworkingSecurityListStatus",
    OciNetworkingSecurityListVcn = "OciNetworkingSecurityListVcn",
    OciNetworkingServiceGatewayBlockTraffic = "OciNetworkingServiceGatewayBlockTraffic",
    OciNetworkingServiceGatewayServiceName = "OciNetworkingServiceGatewayServiceName",
    OciNetworkingServiceGatewayStatus = "OciNetworkingServiceGatewayStatus",
    OciNetworkingServiceGatewayVcn = "OciNetworkingServiceGatewayVcn",
    OciNetworkingSubnetFlowLogsEnabled = "OciNetworkingSubnetFlowLogsEnabled",
    OciNetworkingSubnetIpv4Subnet = "OciNetworkingSubnetIpv4Subnet",
    OciNetworkingSubnetIpv6Subnets = "OciNetworkingSubnetIpv6Subnets",
    OciNetworkingSubnetNetworkedResources = "OciNetworkingSubnetNetworkedResources",
    OciNetworkingSubnetProhibitPublicIpOnVnic = "OciNetworkingSubnetProhibitPublicIpOnVnic",
    OciNetworkingSubnetRegional = "OciNetworkingSubnetRegional",
    OciNetworkingSubnetStatus = "OciNetworkingSubnetStatus",
    OciNetworkingSubnetVcn = "OciNetworkingSubnetVcn",
    OciNetworkingVcnFlowLogsEnabled = "OciNetworkingVcnFlowLogsEnabled",
    OciNetworkingVcnIpv4Subnets = "OciNetworkingVcnIpv4Subnets",
    OciNetworkingVcnIpv6Subnets = "OciNetworkingVcnIpv6Subnets",
    OciNetworkingVcnNetworkedResources = "OciNetworkingVcnNetworkedResources",
    OciNetworkingVcnStatus = "OciNetworkingVcnStatus",
    OciNetworkingVirtualNetworkInterfaceCardPrivateIpAddress = "OciNetworkingVirtualNetworkInterfaceCardPrivateIpAddress",
    OciNetworkingVirtualNetworkInterfaceCardPublicIpAddress = "OciNetworkingVirtualNetworkInterfaceCardPublicIpAddress",
    OciNetworkingVirtualNetworkInterfaceCardSubnet = "OciNetworkingVirtualNetworkInterfaceCardSubnet",
    OciObjectStorageBucketLogCategory = "OciObjectStorageBucketLogCategory",
    OciObjectStorageBucketObjectAccessLevel = "OciObjectStorageBucketObjectAccessLevel",
    OciObjectStorageBucketStorageSize = "OciObjectStorageBucketStorageSize",
    OciObjectStorageBucketStorageTier = "OciObjectStorageBucketStorageTier",
    OciTenantEntityParentPath = "OciTenantEntityParentPath",
    OktaDirectoryEntityCreationTime = "OktaDirectoryEntityCreationTime",
    OktaDirectoryGroupUsers = "OktaDirectoryGroupUsers",
    OktaDirectoryPrincipalAwsRoles = "OktaDirectoryPrincipalAwsRoles",
    OktaDirectoryUserActivityTime = "OktaDirectoryUserActivityTime",
    OktaDirectoryUserGroups = "OktaDirectoryUserGroups",
    OktaDirectoryUserRawMail = "OktaDirectoryUserRawMail",
    OktaDirectoryUserStatus = "OktaDirectoryUserStatus",
    OneLoginDirectoryGroupUsers = "OneLoginDirectoryGroupUsers",
    OneLoginDirectoryRoleUsers = "OneLoginDirectoryRoleUsers",
    OneLoginDirectoryUserActivityTime = "OneLoginDirectoryUserActivityTime",
    OneLoginDirectoryUserAwsRoles = "OneLoginDirectoryUserAwsRoles",
    OneLoginDirectoryUserCreationTime = "OneLoginDirectoryUserCreationTime",
    OneLoginDirectoryUserGroup = "OneLoginDirectoryUserGroup",
    OneLoginDirectoryUserProvisioningState = "OneLoginDirectoryUserProvisioningState",
    OneLoginDirectoryUserRawMail = "OneLoginDirectoryUserRawMail",
    OneLoginDirectoryUserRoles = "OneLoginDirectoryUserRoles",
    OneLoginDirectoryUserStatus = "OneLoginDirectoryUserStatus",
    OpenRelatedEntityRiskHighestSeverity = "OpenRelatedEntityRiskHighestSeverity",
    OpenRelatedEntityRiskTypeIdentifiers = "OpenRelatedEntityRiskTypeIdentifiers",
    OpKubernetesDeploymentReplicaSets = "OpKubernetesDeploymentReplicaSets",
    OpKubernetesServiceAccountOriginatorWorkloadResources = "OpKubernetesServiceAccountOriginatorWorkloadResources",
    OpKubernetesServiceAccountRoleBindings = "OpKubernetesServiceAccountRoleBindings",
    OpKubernetesServiceAccountRoles = "OpKubernetesServiceAccountRoles",
    PingIdentityDirectoryEntityCreationTime = "PingIdentityDirectoryEntityCreationTime",
    PingIdentityDirectoryGroupMemberPrincipals = "PingIdentityDirectoryGroupMemberPrincipals",
    PingIdentityDirectoryUserActivityTime = "PingIdentityDirectoryUserActivityTime",
    PingIdentityDirectoryUserAwsRoles = "PingIdentityDirectoryUserAwsRoles",
    PingIdentityDirectoryUserGroups = "PingIdentityDirectoryUserGroups",
    PingIdentityDirectoryUserMail = "PingIdentityDirectoryUserMail",
    PingIdentityDirectoryUserStatus = "PingIdentityDirectoryUserStatus",
    ResourceCreationTime = "ResourceCreationTime",
    ResourceTagKeys = "ResourceTagKeys",
    ResourceTags = "ResourceTags",
    TenantId = "TenantId",
    UnmanagedKubernetesClusterCreationTime = "UnmanagedKubernetesClusterCreationTime",
    UnmanagedKubernetesClusterPlatform = "UnmanagedKubernetesClusterPlatform",
    VirtualMachineImageVirtualMachines = "VirtualMachineImageVirtualMachines",
    VirtualMachineKubernetesClusterSensor = "VirtualMachineKubernetesClusterSensor",
    VirtualMachineOperatingSystemDisplayName = "VirtualMachineOperatingSystemDisplayName",
    VirtualMachineOperatingSystemType = "VirtualMachineOperatingSystemType",
    VirtualMachineScanStatus = "VirtualMachineScanStatus",
    VirtualMachineScanTime = "VirtualMachineScanTime",
    VirtualMachineVulnerabilities = "VirtualMachineVulnerabilities",
    VirtualMachineVulnerabilitySeverities = "VirtualMachineVulnerabilitySeverities"
}

export enum EntityPropertyType {
    Custom = "Custom",
    Environment = "Environment",
    Owner = "Owner"
}

export enum EntityPropertyValueType {
    PrincipalReference = "PrincipalReference",
    String = "String"
}

export enum EnvironmentType {
    Dev = "Dev",
    Eiep = "Eiep",
    Pid = "Pid",
    Prd = "Prd",
    Stg = "Stg",
    Ugid = "Ugid",
    Ugp = "Ugp",
    Uiap = "Uiap",
    Uibp = "Uibp",
    Uifp = "Uifp"
}

export enum EventModelProperty {
    ErrorCode = "ErrorCode",
    Identity = "Identity",
    IpAddress = "IpAddress",
    Name = "Name",
    OriginatorEntity = "OriginatorEntity",
    Resources = "Resources",
    Service = "Service",
    Tenant = "Tenant",
    Time = "Time"
}

export enum FeatureName {
    AwsAccessGraphCustomExcessiveEvaluationEnabled = "AwsAccessGraphCustomExcessiveEvaluationEnabled",
    AwsDynamicMandatoryServiceEnabled = "AwsDynamicMandatoryServiceEnabled",
    AwsEc2ExternalMachineImageAnalysisEnabled = "AwsEc2ExternalMachineImageAnalysisEnabled",
    AwsEc2MachineImageAnalysisEnabled = "AwsEc2MachineImageAnalysisEnabled",
    AwsExcessivePermissionRoleGroupRiskAnalyzeEnabled = "AwsExcessivePermissionRoleGroupRiskAnalyzeEnabled",
    AwsFederationResolutionDisabled = "AwsFederationResolutionDisabled",
    AzureSqlServerAnalysisEnabled = "AzureSqlServerAnalysisEnabled",
    AzureStorageStorageAccountPrivateEndpointProvisioningEnabled = "AzureStorageStorageAccountPrivateEndpointProvisioningEnabled",
    CiContainerImageRegistryCloudsmith = "CiContainerImageRegistryCloudsmith",
    DashboardEditEnabled = "DashboardEditEnabled",
    DocumentModelExporterDisabled = "DocumentModelExporterDisabled",
    EndpointConnectorEnabled = "EndpointConnectorEnabled",
    EventAggregationDisabled = "EventAggregationDisabled",
    ExcessivePermissionPrincipalRiskLegacySeverityEnabled = "ExcessivePermissionPrincipalRiskLegacySeverityEnabled",
    GcpArtifactContainerImageRepositoryContainerImageLimitEnabled = "GcpArtifactContainerImageRepositoryContainerImageLimitEnabled",
    GcpArtifactContainerImageRepositorySyncOperatingSystemEnabled = "GcpArtifactContainerImageRepositorySyncOperatingSystemEnabled",
    GcpDlpJobsPermissionEvaluationDisabled = "GcpDlpJobsPermissionEvaluationDisabled",
    GcpTenantPermissionValidationSkipEnabled = "GcpTenantPermissionValidationSkipEnabled",
    GenerateCustomerDocumentModelsCompleted = "GenerateCustomerDocumentModelsCompleted",
    GenerateCustomerEntityModelsCompleted = "GenerateCustomerEntityModelsCompleted",
    LicensingUpdateDisabled = "LicensingUpdateDisabled",
    LocalizationEnabled = "LocalizationEnabled",
    OnboardingGuidesEnabled = "OnboardingGuidesEnabled",
    PermissionManagementGciGroupMembershipEnabled = "PermissionManagementGciGroupMembershipEnabled",
    ProjectsResourceConditionsEnabled = "ProjectsResourceConditionsEnabled",
    SplunkEventCollectorEndpointConnectorEnabled = "SplunkEventCollectorEndpointConnectorEnabled",
    TvdlEnabled = "TvdlEnabled",
    UdmEnabled = "UdmEnabled",
    UiEventsReportEnabled = "UiEventsReportEnabled"
}

export enum GciDirectoryDirectoryRoleAssignmentScopeType {
    OrganizationalUnit = "OrganizationalUnit",
    Tenant = "Tenant"
}

export enum GciDirectoryDirectoryRoleType {
    Custom = "Custom",
    System = "System"
}

export enum GciDirectoryGroupMemberPrincipalRole {
    Manager = "Manager",
    Member = "Member",
    Owner = "Owner"
}

export enum GciDirectoryGroupType {
    Dynamic = "Dynamic",
    Google = "Google",
    IdentityMapped = "IdentityMapped",
    Locked = "Locked",
    Posix = "Posix",
    Security = "Security"
}

export enum GciDirectorySpecialGroupType {
    AllAuthenticatedUsers = "AllAuthenticatedUsers",
    AllUsers = "AllUsers"
}

export enum GciDirectorySsoProfileAssignmentStatus {
    Disabled = "Disabled",
    Enabled = "Enabled",
    TenantInherited = "TenantInherited"
}

export enum GciDirectoryUserInactiveRiskModelInfoType {
    SignInNever = "SignInNever",
    SignInTime = "SignInTime",
    SignInUnknown = "SignInUnknown"
}

export enum GciDirectoryUserInactiveRiskResolutionType {
    Delete = "Delete",
    DeleteDisable = "DeleteDisable"
}

export enum GciDirectoryUserStatus {
    Active = "Active",
    Archived = "Archived",
    Suspended = "Suspended"
}

export enum GciPrincipalType {
    AllAuthenticatedUsers = "AllAuthenticatedUsers",
    AllUsers = "AllUsers",
    Domain = "Domain",
    GkeServiceAccount = "GkeServiceAccount",
    Group = "Group",
    ProjectEditors = "ProjectEditors",
    ProjectOwners = "ProjectOwners",
    ProjectViewers = "ProjectViewers",
    ServiceAccount = "ServiceAccount",
    User = "User",
    WorkforcePoolPrincipal = "WorkforcePoolPrincipal",
    WorkforcePoolPrincipalSet = "WorkforcePoolPrincipalSet",
    WorkloadIdentityPoolPrincipal = "WorkloadIdentityPoolPrincipal",
    WorkloadIdentityPoolPrincipalSet = "WorkloadIdentityPoolPrincipalSet"
}

export enum GciTenantModelStatus {
    AccessDenied = "AccessDenied",
    ApplicationServiceAccountUnauthorized = "ApplicationServiceAccountUnauthorized",
    Deleting = "Deleting",
    ServiceAccountNotExist = "ServiceAccountNotExist",
    ServiceAccountTenantExternalIdTagMismatch = "ServiceAccountTenantExternalIdTagMismatch",
    ServiceAccountTenantUnauthorized = "ServiceAccountTenantUnauthorized",
    ServiceAccountUnauthorized = "ServiceAccountUnauthorized",
    ServiceAccountUserDelegationMandatoryAuthorizationScopesMismatch = "ServiceAccountUserDelegationMandatoryAuthorizationScopesMismatch",
    ServiceAccountUserDelegationOptionalAuthorizationScopesMismatch = "ServiceAccountUserDelegationOptionalAuthorizationScopesMismatch",
    UserNotExist = "UserNotExist",
    UserTenantMismatch = "UserTenantMismatch",
    UserUnauthorized = "UserUnauthorized",
    Valid = "Valid"
}

export enum GciTenantStateMonitoringIssue {
    ApplicationServiceAccountUnauthorized = "ApplicationServiceAccountUnauthorized",
    ServiceAccountNotExist = "ServiceAccountNotExist",
    ServiceAccountTenantExternalIdTagMismatch = "ServiceAccountTenantExternalIdTagMismatch",
    ServiceAccountTenantUnauthorized = "ServiceAccountTenantUnauthorized",
    ServiceAccountUnauthorized = "ServiceAccountUnauthorized",
    ServiceAccountUserDelegationMandatoryAuthorizationScopesMismatch = "ServiceAccountUserDelegationMandatoryAuthorizationScopesMismatch",
    ServiceAccountUserDelegationOptionalAuthorizationScopesMismatch = "ServiceAccountUserDelegationOptionalAuthorizationScopesMismatch",
    UserNotExist = "UserNotExist",
    UserTenantMismatch = "UserTenantMismatch",
    UserUnauthorized = "UserUnauthorized"
}

export enum GciTenantStateMonitoringPermissionManagementIssue {
    PermissionsMismatch = "PermissionsMismatch"
}

export enum GcpApiKeysKeyRestrictionsClientType {
    AndroidApps = "AndroidApps",
    HttpReferrers = "HttpReferrers",
    IosApps = "IosApps",
    IpAddresses = "IpAddresses",
    None = "None"
}

export enum GcpAppEngineApplicationResourceStatus {
    Running = "Running",
    Stopped = "Stopped"
}

export enum GcpAppEngineApplicationServiceRevisionEnvironment {
    Flexible = "Flexible",
    Standard = "Standard"
}

export enum GcpAppEngineApplicationServiceRevisionScaling {
    Automatic = "Automatic",
    Basic = "Basic",
    Default = "Default",
    Manual = "Manual"
}

export enum GcpAppEngineApplicationServiceRevisionSecurityLevel {
    Always = "Always",
    Optional = "Optional",
    Never = "Never"
}

export enum GcpArtifactRegistryType {
    Apt = "Apt",
    ContainerImage = "ContainerImage",
    Generic = "Generic",
    Go = "Go",
    Kfp = "Kfp",
    Maven = "Maven",
    Npm = "Npm",
    Python = "Python",
    Yum = "Yum"
}

export enum GcpAuditLogType {
    AdminRead = "AdminRead",
    AdminWrite = "AdminWrite",
    DataRead = "DataRead",
    DataWrite = "DataWrite"
}

export enum GcpBehaviorIdentityRiskModelInfo {
    EventActions = "EventActions",
    IpAddresses = "IpAddresses",
    PreviousBehaviorDateEver = "PreviousBehaviorDateEver",
    PreviousBehaviorDateNever = "PreviousBehaviorDateNever",
    Resources = "Resources",
    ResourcesSensitiveScopeResources = "ResourcesSensitiveScopeResources",
    SensitiveWideScopeChildScopeResources = "SensitiveWideScopeChildScopeResources"
}

export enum GcpBehaviorIdentityRiskSeverityInfo {
    Default = "Default",
    SensitiveAction = "SensitiveAction",
    SensitiveScopeResource = "SensitiveScopeResource",
    SensitiveWideScopeChildScopeResource = "SensitiveWideScopeChildScopeResource",
    SensitiveWideScopeScopeResource = "SensitiveWideScopeScopeResource",
    WideScope = "WideScope"
}

export enum GcpComputeBackendServiceProtocol {
    Grpc = "Grpc",
    Http = "Http",
    Http2 = "Http2",
    Https = "Https",
    Ssl = "Ssl",
    Tcp = "Tcp",
    Udp = "Udp",
    Unspecified = "Unspecified"
}

export enum GcpComputeDiskStatus {
    Creating = "Creating",
    Deleting = "Deleting",
    Failed = "Failed",
    Ready = "Ready",
    Restoring = "Restoring",
    Unavailable = "Unavailable"
}

export enum GcpComputeDiskType {
    HddStandard = "HddStandard",
    HyperBalanced = "HyperBalanced",
    HyperBalancedHighAvailability = "HyperBalancedHighAvailability",
    HyperExtreme = "HyperExtreme",
    HyperThroughput = "HyperThroughput",
    Ssd = "Ssd",
    SsdBalanced = "SsdBalanced",
    SsdEphemeral = "SsdEphemeral",
    SsdExtreme = "SsdExtreme"
}

export enum GcpComputeFirewallPolicyRuleAction {
    Allow = "Allow",
    Deny = "Deny",
    GoToNext = "GoToNext"
}

export enum GcpComputeFirewallPolicyRuleDirection {
    Inbound = "Inbound",
    Outbound = "Outbound"
}

export enum GcpComputeFirewallPolicyScope {
    Folder = "Folder",
    Organization = "Organization",
    Tenant = "Tenant"
}

export enum GcpComputeFirewallRuleAction {
    Allow = "Allow",
    Deny = "Deny"
}

export enum GcpComputeFirewallRuleDirection {
    Inbound = "Inbound",
    Outbound = "Outbound"
}

export enum GcpComputeForwardingRuleNetworkTier {
    Premium = "Premium",
    Standard = "Standard"
}

export enum GcpComputeForwardingRuleProtocol {
    Ah = "Ah",
    Esp = "Esp",
    Icmp = "Icmp",
    L3Default = "L3Default",
    Sctp = "Sctp",
    Tcp = "Tcp",
    Udp = "Udp"
}

export enum GcpComputeForwardingRuleTargetServiceType {
    All = "All",
    VpcServiceControls = "VpcServiceControls"
}

export enum GcpComputeImageStatus {
    Deleting = "Deleting",
    Failed = "Failed",
    Pending = "Pending",
    Ready = "Ready"
}

export enum GcpComputeImageType {
    Custom = "Custom",
    ExternalManaged = "ExternalManaged"
}

export enum GcpComputeInstanceGroupManagerStatus {
    NotReady = "NotReady",
    Ready = "Ready"
}

export enum GcpComputeInstanceScopePropertySource {
    OrganizationPolicy = "OrganizationPolicy",
    Project = "Project",
    Resource = "Resource"
}

export enum GcpComputeInstanceStatus {
    Provisioning = "Provisioning",
    Repairing = "Repairing",
    Running = "Running",
    Staging = "Staging",
    Stopping = "Stopping",
    Suspended = "Suspended",
    Suspending = "Suspending",
    Terminated = "Terminated"
}

export enum GcpComputeLoadBalancerLoadBalancingScheme {
    External = "External",
    ExternalManaged = "ExternalManaged",
    Internal = "Internal",
    InternalManaged = "InternalManaged",
    InternalSelfManaged = "InternalSelfManaged"
}

export enum GcpComputeLoadBalancerProtocol {
    Http = "Http",
    Https = "Https",
    Ssl = "Ssl",
    Tcp = "Tcp",
    Udp = "Udp",
    Unspecified = "Unspecified"
}

export enum GcpComputeLoadBalancerType {
    HttpClassicProxy = "HttpClassicProxy",
    HttpInternalProxy = "HttpInternalProxy",
    HttpProxy = "HttpProxy",
    NetworkBackendService = "NetworkBackendService",
    NetworkInternal = "NetworkInternal",
    NetworkTargetPool = "NetworkTargetPool",
    SslTcpProxy = "SslTcpProxy",
    TcpInternalProxy = "TcpInternalProxy"
}

export enum GcpComputeNetworkEndpointGroupType {
    HybridConnectivityIpAddressEndPoint = "HybridConnectivityIpAddressEndPoint",
    InternalComputeInstanceEndPoint = "InternalComputeInstanceEndPoint",
    InternalComputeInstanceIpAddress = "InternalComputeInstanceIpAddress",
    InternetDnsNameEndPoint = "InternetDnsNameEndPoint",
    InternetIpAddressEndPoint = "InternetIpAddressEndPoint",
    PrivateServiceEndPoint = "PrivateServiceEndPoint",
    PrivateServiceInternalComputeInstanceEndPoint = "PrivateServiceInternalComputeInstanceEndPoint",
    Serverless = "Serverless"
}

export enum GcpComputeRouteNextHopType {
    Hub = "Hub",
    Instance = "Instance",
    Internet = "Internet",
    IpAddress = "IpAddress",
    LoadBalancer = "LoadBalancer",
    Vpc = "Vpc",
    VpcPeeringConnection = "VpcPeeringConnection",
    VpnTunnel = "VpnTunnel"
}

export enum GcpComputeSnapshotCreationType {
    Manual = "Manual",
    Scheduled = "Scheduled"
}

export enum GcpComputeSnapshotStatus {
    Creating = "Creating",
    Deleting = "Deleting",
    Failed = "Failed",
    Ready = "Ready",
    Uploading = "Uploading"
}

export enum GcpComputeSnapshotType {
    Archive = "Archive",
    Standard = "Standard"
}

export enum GcpComputeSslPolicyFeatureProfile {
    Compatible = "Compatible",
    Custom = "Custom",
    Modern = "Modern",
    Restricted = "Restricted"
}

export enum GcpComputeSubnetType {
    Default = "Default",
    ExternalRegionalHttpLoadbalancerReserved = "ExternalRegionalHttpLoadbalancerReserved",
    InternalCloudServicesConnection = "InternalCloudServicesConnection",
    InternalHttpLoadBalancerReserved = "InternalHttpLoadBalancerReserved",
    InternalMultiRegionLoadBalancerReserved = "InternalMultiRegionLoadBalancerReserved",
    InternalNatGatewayCrossVpcConnection = "InternalNatGatewayCrossVpcConnection"
}

export enum GcpComputeTargetProxyType {
    Http = "Http",
    Https = "Https",
    Ssl = "Ssl",
    Tcp = "Tcp"
}

export enum GcpComputeVpcPeeringConnectionStatus {
    Active = "Active",
    Inactive = "Inactive"
}

export enum GcpComputeVpcPeeringConnectionSupportedIpAddressFamilies {
    IpV4 = "IpV4",
    IpV4AndIpV6 = "IpV4AndIpV6"
}

export enum GcpConsolePage {
    ApiKeys = "ApiKeys",
    ApiLibrary = "ApiLibrary",
    AuditLogs = "AuditLogs",
    Backups = "Backups",
    Connections = "Connections",
    Edit = "Edit",
    Firewalls = "Firewalls",
    Keys = "Keys",
    LogMetrics = "LogMetrics",
    Metadata = "Metadata",
    Permissions = "Permissions",
    Resource = "Resource",
    Revisions = "Revisions",
    Security = "Security",
    Variables = "Variables",
    Versions = "Versions",
    VpcFirewallRules = "VpcFirewallRules",
    VpcSubnets = "VpcSubnets"
}

export enum GcpContainerClusterMode {
    Autopilot = "Autopilot",
    Standard = "Standard"
}

export enum GcpContainerClusterNodePoolNodeImageType {
    Cos = "Cos",
    CosContainerd = "CosContainerd",
    Ubuntu = "Ubuntu",
    UbuntuContainerd = "UbuntuContainerd",
    WindowsLtsc = "WindowsLtsc",
    WindowsLtscContainerd = "WindowsLtscContainerd",
    WindowsSac = "WindowsSac",
    WindowsSacContainerd = "WindowsSacContainerd"
}

export enum GcpContainerClusterNodePoolStatus {
    Error = "Error",
    Provisioning = "Provisioning",
    Reconciling = "Reconciling",
    Running = "Running",
    RunningWithError = "RunningWithError",
    Stopping = "Stopping"
}

export enum GcpContainerClusterStatus {
    Degraded = "Degraded",
    Error = "Error",
    Provisioning = "Provisioning",
    Reconciling = "Reconciling",
    Running = "Running",
    Stopping = "Stopping"
}

export enum GcpDnsManagedZoneDnsSecSigningKeyAlgorithm {
    EcdsaP256Sha256 = "EcdsaP256Sha256",
    EcdsaP384Sha384 = "EcdsaP384Sha384",
    RsaSha1 = "RsaSha1",
    RsaSha256 = "RsaSha256",
    RsaSha512 = "RsaSha512"
}

export enum GcpDnsManagedZoneDnsSecStatus {
    Disabled = "Disabled",
    Enabled = "Enabled",
    Transfer = "Transfer"
}

export enum GcpDnsManagedZonePrivateZoneType {
    Default = "Default",
    DnsServerForwarding = "DnsServerForwarding",
    ManagedZonePeering = "ManagedZonePeering",
    ReverseLookup = "ReverseLookup",
    ServiceDirectoryNamespace = "ServiceDirectoryNamespace"
}

export enum GcpDnsManagedZoneResourceRecordSetRoutingPolicy {
    Default = "Default",
    Failover = "Failover",
    Geolocation = "Geolocation",
    Weighted = "Weighted"
}

export enum GcpDnsManagedZoneResourceRecordSetType {
    A = "A",
    Aaaa = "Aaaa",
    Alias = "Alias",
    Caa = "Caa",
    Cname = "Cname",
    DnsKey = "DnsKey",
    Ds = "Ds",
    Https = "Https",
    IpSecKey = "IpSecKey",
    Mx = "Mx",
    Naptr = "Naptr",
    Ns = "Ns",
    Ptr = "Ptr",
    Soa = "Soa",
    Spf = "Spf",
    Srv = "Srv",
    Sshfp = "Sshfp",
    Svcb = "Svcb",
    Tlsa = "Tlsa",
    Txt = "Txt"
}

export enum GcpEventarcTriggerDestinationResourceType {
    CloudFunction = "CloudFunction",
    CloudRun = "CloudRun",
    Gke = "Gke",
    None = "None",
    Workflow = "Workflow"
}

export enum GcpExcessivePermissionPrincipalRiskModelInfo {
    CreationTime = "CreationTime",
    ExcessiveDirectMembershipGroupIds = "ExcessiveDirectMembershipGroupIds",
    ExcessiveRoleBindingIds = "ExcessiveRoleBindingIds",
    ExternalPrincipal = "ExternalPrincipal",
    GroupMembers = "GroupMembers",
    ServiceAccountOriginatorResources = "ServiceAccountOriginatorResources",
    ServiceAccountOriginatorResourcesPublicAccessAll = "ServiceAccountOriginatorResourcesPublicAccessAll",
    ServiceAccountOriginatorResourcesPublicAccessSingle = "ServiceAccountOriginatorResourcesPublicAccessSingle",
    ServiceAccountOriginatorResourcesPublicAccessSome = "ServiceAccountOriginatorResourcesPublicAccessSome",
    SignInNever = "SignInNever",
    SignInTime = "SignInTime",
    SignInTimeMissingCreationTime = "SignInTimeMissingCreationTime",
    SignInTimeNotExact = "SignInTimeNotExact",
    SignInTimeNotExactMissingCreationTime = "SignInTimeNotExactMissingCreationTime",
    SignInUnknown = "SignInUnknown",
    UserMfaNotEnabled = "UserMfaNotEnabled"
}

export enum GcpExcessivePermissionPrincipalRiskRoleBindingDataResolutionType {
    Delete = "Delete",
    DeletePermissionOverlapExistingRole = "DeletePermissionOverlapExistingRole",
    DeletePermissionOverlapUnknownExistingRole = "DeletePermissionOverlapUnknownExistingRole",
    None = "None",
    Replace = "Replace",
    ReplacePermissionOverlapExistingRoleMany = "ReplacePermissionOverlapExistingRoleMany",
    ReplacePermissionOverlapExistingRoleSingle = "ReplacePermissionOverlapExistingRoleSingle"
}

export enum GcpExcessivePermissionPrincipalRiskRoleBindingResolution {
    Delete = "Delete",
    Replace = "Replace"
}

export enum GcpFunctionsFunctionEnvironmentVariableType {
    Build = "Build",
    Runtime = "Runtime"
}

export enum GcpFunctionsFunctionTriggerType {
    Event = "Event",
    Http = "Http"
}

export enum GcpFunctionsFunctionType {
    Gen1 = "Gen1",
    Gen2 = "Gen2"
}

export enum GcpIamRoleBindingDeltaAction {
    Add = "Add",
    Remove = "Remove"
}

export enum GcpIamRoleBindingModelPermissionUsageType {
    Unknown = "Unknown",
    Unused = "Unused",
    PartiallyUsed = "PartiallyUsed",
    Used = "Used"
}

export enum GcpIamRoleStage {
    Alpha = "Alpha",
    Beta = "Beta",
    Deprecated = "Deprecated",
    Disabled = "Disabled",
    EarlyAccessProgram = "EarlyAccessProgram",
    GeneralAvailability = "GeneralAvailability"
}

export enum GcpIamRoleType {
    Basic = "Basic",
    Custom = "Custom",
    Predefined = "Predefined"
}

export enum GcpIamServiceAccountInactiveRiskModelInfoType {
    SignInNever = "SignInNever",
    SignInTime = "SignInTime",
    SignInTimeNotExact = "SignInTimeNotExact"
}

export enum GcpIamServiceAccountKeyType {
    SystemManaged = "SystemManaged",
    UserManaged = "UserManaged"
}

export enum GcpIamWorkforcePoolProviderType {
    Oidc = "Oidc",
    Saml = "Saml"
}

export enum GcpIamWorkloadIdentityPoolProviderType {
    Aws = "Aws",
    Oidc = "Oidc",
    Saml = "Saml"
}

export enum GcpInstanceGroupType {
    Managed = "Managed",
    Unmanaged = "Unmanaged"
}

export enum GcpKmsKeyRingKeyUsageType {
    AsymmetricDecrypt = "AsymmetricDecrypt",
    AsymmetricSign = "AsymmetricSign",
    EncryptDecrypt = "EncryptDecrypt",
    Mac = "Mac",
    RawEncryptDecrypt = "RawEncryptDecrypt",
    Unspecified = "Unspecified"
}

export enum GcpLoggingLogMetricType {
    Counter = "Counter",
    Distribution = "Distribution"
}

export enum GcpMonitoringAlertPolicyConditionType {
    Absent = "Absent",
    Forecast = "Forecast",
    MatchedLog = "MatchedLog",
    MonitoringQueryLanguage = "MonitoringQueryLanguage",
    None = "None",
    PrometheusQueryLanguage = "PrometheusQueryLanguage",
    Threshold = "Threshold"
}

export enum GcpNotebooksInstanceStatus {
    Active = "Active",
    Deleted = "Deleted",
    Initializing = "Initializing",
    Provisioning = "Provisioning",
    Starting = "Starting",
    Stopped = "Stopped",
    Stopping = "Stopping",
    Suspended = "Suspended",
    Suspending = "Suspending",
    Unspecified = "Unspecified",
    Upgrading = "Upgrading"
}

export enum GcpPrincipalNotAllowedResourceRoleRiskPolicyConfigurationRolesOperator {
    Overlap = "Overlap",
    Subset = "Subset"
}

export enum GcpPubSubSubscriptionMessageDeliveryRetryPolicy {
    ExponentialBackoff = "ExponentialBackoff",
    Immediately = "Immediately"
}

export enum GcpPubSubSubscriptionMessageDeliveryType {
    Pull = "Pull",
    Push = "Push"
}

export enum GcpPubSubSubscriptionStatus {
    Active = "Active",
    ResourceError = "ResourceError"
}

export enum GcpResourceAssetPathType {
    Folder = "Folder",
    Organization = "Organization",
    Resource = "Resource",
    Tenant = "Tenant"
}

export enum GcpScopeResourceAccessLevel {
    Public = "Public",
    External = "External",
    Internal = "Internal"
}

export enum GcpSecretManagerSecretReplicationType {
    Automatic = "Automatic",
    UserManaged = "UserManaged"
}

export enum GcpSecretManagerVersionStatus {
    Destroyed = "Destroyed",
    Disabled = "Disabled",
    Enabled = "Enabled"
}

export enum GcpServerlessNetworkedResourceNetworkInboundAccessType {
    External = "External",
    InternalVpc = "InternalVpc",
    LoadBalancerOrInternalVpc = "LoadBalancerOrInternalVpc"
}

export enum GcpServiceAccountOriginatorAuthorizationScopeType {
    All = "All",
    Custom = "Custom",
    DefaultComputeScopes = "DefaultComputeScopes",
    DefaultContainerScopes = "DefaultContainerScopes",
    None = "None"
}

export enum GcpSeverePermissionPrincipalRiskModelInfo {
    GroupIdentities = "GroupIdentities",
    IdentityCreationTime = "IdentityCreationTime",
    IdentityCreationTimeCreator = "IdentityCreationTimeCreator",
    IdentityCreationTimeCreatorCreatorOriginator = "IdentityCreationTimeCreatorCreatorOriginator",
    SeverePermissionActionPermittingEntities = "SeverePermissionActionPermittingEntities",
    SeverePermissionActionStartTime = "SeverePermissionActionStartTime"
}

export enum GcpSpecialGroupType {
    ProjectEditors = "ProjectEditors",
    ProjectOwners = "ProjectOwners",
    ProjectViewers = "ProjectViewers"
}

export enum GcpSqlInstanceDatabaseType {
    MySql = "MySql",
    PostgreSql = "PostgreSql",
    SqlServer = "SqlServer"
}

export enum GcpSqlInstanceDatabaseVersion {
    Mysql_5_1 = "Mysql_5_1",
    Mysql_5_5 = "Mysql_5_5",
    Mysql_5_6 = "Mysql_5_6",
    Mysql_5_7 = "Mysql_5_7",
    Mysql_8_0 = "Mysql_8_0",
    Mysql_8_0_18 = "Mysql_8_0_18",
    Mysql_8_0_26 = "Mysql_8_0_26",
    Mysql_8_0_27 = "Mysql_8_0_27",
    Mysql_8_0_28 = "Mysql_8_0_28",
    Mysql_8_0_29 = "Mysql_8_0_29",
    Mysql_8_0_30 = "Mysql_8_0_30",
    Mysql_8_0_31 = "Mysql_8_0_31",
    Mysql_8_0_32 = "Mysql_8_0_32",
    Mysql_8_0_33 = "Mysql_8_0_33",
    Mysql_8_0_34 = "Mysql_8_0_34",
    Mysql_8_0_35 = "Mysql_8_0_35",
    Mysql_8_0_36 = "Mysql_8_0_36",
    Mysql_8_0_37 = "Mysql_8_0_37",
    Mysql_8_0_39 = "Mysql_8_0_39",
    Mysql_8_0_40 = "Mysql_8_0_40",
    Mysql_8_0_41 = "Mysql_8_0_41",
    Mysql_8_4 = "Mysql_8_4",
    Postgres_9_6 = "Postgres_9_6",
    Postgres_10 = "Postgres_10",
    Postgres_11 = "Postgres_11",
    Postgres_12 = "Postgres_12",
    Postgres_13 = "Postgres_13",
    Postgres_14 = "Postgres_14",
    Postgres_15 = "Postgres_15",
    Postgres_16 = "Postgres_16",
    Postgres_17 = "Postgres_17",
    Sqlserver_2017_Enterprise = "Sqlserver_2017_Enterprise",
    Sqlserver_2017_Express = "Sqlserver_2017_Express",
    Sqlserver_2017_Standard = "Sqlserver_2017_Standard",
    Sqlserver_2017_Web = "Sqlserver_2017_Web",
    Sqlserver_2019_Enterprise = "Sqlserver_2019_Enterprise",
    Sqlserver_2019_Express = "Sqlserver_2019_Express",
    Sqlserver_2019_Standard = "Sqlserver_2019_Standard",
    Sqlserver_2019_Web = "Sqlserver_2019_Web",
    Sqlserver_2022_Enterprise = "Sqlserver_2022_Enterprise",
    Sqlserver_2022_Express = "Sqlserver_2022_Express",
    Sqlserver_2022_Standard = "Sqlserver_2022_Standard",
    Sqlserver_2022_Web = "Sqlserver_2022_Web"
}

export enum GcpSqlInstancePostgreSqlLogLevel {
    Debug5 = "Debug5",
    Debug4 = "Debug4",
    Debug3 = "Debug3",
    Debug2 = "Debug2",
    Debug1 = "Debug1",
    Info = "Info",
    Notice = "Notice",
    Warning = "Warning",
    Error = "Error",
    Log = "Log",
    Fatal = "Fatal",
    Panic = "Panic"
}

export enum GcpSqlInstancePostgreSqlLogVerbosity {
    Terse = "Terse",
    Default = "Default",
    Verbose = "Verbose"
}

export enum GcpSqlInstancePostgreSqlStatementLogType {
    None = "None",
    Ddl = "Ddl",
    Mod = "Mod",
    All = "All"
}

export enum GcpSqlInstanceStatus {
    Failed = "Failed",
    Maintenance = "Maintenance",
    OnlineMaintenance = "OnlineMaintenance",
    PendingCreate = "PendingCreate",
    PendingDelete = "PendingDelete",
    Runnable = "Runnable",
    Suspended = "Suspended"
}

export enum GcpSqlInstanceType {
    OnPremises = "OnPremises",
    Primary = "Primary",
    ReadReplica = "ReadReplica"
}

export enum GcpSqlInstanceUserType {
    BuiltIn = "BuiltIn",
    GciDirectoryGroup = "GciDirectoryGroup",
    GciDirectoryGroupMemberServiceAccount = "GciDirectoryGroupMemberServiceAccount",
    GciDirectoryGroupMemberUser = "GciDirectoryGroupMemberUser",
    GciDirectoryUser = "GciDirectoryUser",
    IamServiceAccount = "IamServiceAccount"
}

export enum GcpStorageBucketPublicAccessExistsRiskModelDescriptionType {
    Acl = "Acl",
    AclRoleBinding = "AclRoleBinding",
    RoleBinding = "RoleBinding"
}

export enum GcpTenantManagerError {
    CommonErrorGciTenantIssue = "CommonErrorGciTenantIssue",
    CommonErrorMoveScopeDependencyExists = "CommonErrorMoveScopeDependencyExists",
    CommonErrorParentFolderNotExist = "CommonErrorParentFolderNotExist",
    CommonErrorServiceAccountMandatoryOrganizationPermissionsMismatch = "CommonErrorServiceAccountMandatoryOrganizationPermissionsMismatch",
    CommonErrorServiceAccountMandatoryPermissionsMismatch = "CommonErrorServiceAccountMandatoryPermissionsMismatch",
    CommonErrorServiceAccountUnauthorized = "CommonErrorServiceAccountUnauthorized",
    CommonErrorSinkExclusionFilterMismatch = "CommonErrorSinkExclusionFilterMismatch",
    CommonErrorSinkInclusionFilterMismatch = "CommonErrorSinkInclusionFilterMismatch",
    CommonErrorSinkMultipleSinks = "CommonErrorSinkMultipleSinks",
    CommonErrorSinkNotExist = "CommonErrorSinkNotExist",
    CommonErrorSinkOrganizationConfigurationExists = "CommonErrorSinkOrganizationConfigurationExists",
    CommonErrorSinkOrganizationIncludeChildrenNotEnabled = "CommonErrorSinkOrganizationIncludeChildrenNotEnabled",
    CommonErrorSinkSubscriptionExpirationTimeFrameExists = "CommonErrorSinkSubscriptionExpirationTimeFrameExists",
    CommonErrorSinkSubscriptionFilterExists = "CommonErrorSinkSubscriptionFilterExists",
    CommonErrorSinkSubscriptionMessageDeliveryTypePush = "CommonErrorSinkSubscriptionMessageDeliveryTypePush",
    CommonErrorSinkSubscriptionMessageRetentionDuration = "CommonErrorSinkSubscriptionMessageRetentionDuration",
    CommonErrorSinkSubscriptionNotExist = "CommonErrorSinkSubscriptionNotExist",
    CommonErrorSinkSubscriptionRawIdNotValid = "CommonErrorSinkSubscriptionRawIdNotValid",
    CommonErrorSinkSubscriptionServiceAccountUnauthorized = "CommonErrorSinkSubscriptionServiceAccountUnauthorized",
    CommonErrorSinkSubscriptionTopicNotConnected = "CommonErrorSinkSubscriptionTopicNotConnected",
    DeleteTenantErrorOrganizationExists = "DeleteTenantErrorOrganizationExists",
    InsertTenantErrorTenantDeletePending = "InsertTenantErrorTenantDeletePending",
    InsertTenantErrorTenantExists = "InsertTenantErrorTenantExists",
    InsertTenantErrorTenantMaxCount = "InsertTenantErrorTenantMaxCount",
    InsertTenantErrorTenantNotFound = "InsertTenantErrorTenantNotFound",
    InsertTenantErrorTenantRawShortNameIdNotValid = "InsertTenantErrorTenantRawShortNameIdNotValid",
    InsertTenantErrorTenantSuspended = "InsertTenantErrorTenantSuspended",
    UpdateTenantErrorTenantNotExist = "UpdateTenantErrorTenantNotExist",
    UpsertOrganizationErrorTenantNotExists = "UpsertOrganizationErrorTenantNotExists"
}

export enum GcpTenantManagerValidateOrganizationResult {
    GciTenantIssue = "GciTenantIssue",
    ServiceAccountDataAnalysisPermissionsMismatch = "ServiceAccountDataAnalysisPermissionsMismatch",
    ServiceAccountMandatoryPermissionsMismatch = "ServiceAccountMandatoryPermissionsMismatch",
    ServiceAccountOptionalRoleMismatch = "ServiceAccountOptionalRoleMismatch",
    ServiceAccountTenantBillingNotEnabled = "ServiceAccountTenantBillingNotEnabled",
    ServiceAccountTenantServiceApiHostNamesMismatch = "ServiceAccountTenantServiceApiHostNamesMismatch",
    ServiceAccountUnauthorized = "ServiceAccountUnauthorized",
    ServiceAccountWorkloadAnalysisPermissionsMismatch = "ServiceAccountWorkloadAnalysisPermissionsMismatch",
    Valid = "Valid"
}

export enum GcpTenantManagerValidateTenantResult {
    GciTenantIssue = "GciTenantIssue",
    ServiceAccountDataAnalysisPermissionsMismatch = "ServiceAccountDataAnalysisPermissionsMismatch",
    ServiceAccountMandatoryOrganizationPermissionsMismatch = "ServiceAccountMandatoryOrganizationPermissionsMismatch",
    ServiceAccountMandatoryPermissionsMismatch = "ServiceAccountMandatoryPermissionsMismatch",
    ServiceAccountOptionalRoleMismatch = "ServiceAccountOptionalRoleMismatch",
    ServiceAccountTenantBillingNotEnabled = "ServiceAccountTenantBillingNotEnabled",
    ServiceAccountTenantServiceApiHostNamesMismatch = "ServiceAccountTenantServiceApiHostNamesMismatch",
    ServiceAccountUnauthorized = "ServiceAccountUnauthorized",
    ServiceAccountWorkloadAnalysisPermissionsMismatch = "ServiceAccountWorkloadAnalysisPermissionsMismatch",
    TenantDeletePending = "TenantDeletePending",
    TenantNotExist = "TenantNotExist",
    TenantRawShortNameIdNotValid = "TenantRawShortNameIdNotValid",
    TenantSuspended = "TenantSuspended",
    Valid = "Valid"
}

export enum GcpTenantModelIssue {
    TerraformStateBucketAccessDenied = "TerraformStateBucketAccessDenied"
}

export enum GcpTenantModelStatus {
    AccessDenied = "AccessDenied",
    Deleting = "Deleting",
    GciTenantIssue = "GciTenantIssue",
    ServiceAccountDataAnalysisPermissionsMismatch = "ServiceAccountDataAnalysisPermissionsMismatch",
    ServiceAccountMandatoryOrganizationPermissionsMismatch = "ServiceAccountMandatoryOrganizationPermissionsMismatch",
    ServiceAccountMandatoryPermissionsMismatch = "ServiceAccountMandatoryPermissionsMismatch",
    ServiceAccountOptionalRoleMismatch = "ServiceAccountOptionalRoleMismatch",
    ServiceAccountTenantBillingNotEnabled = "ServiceAccountTenantBillingNotEnabled",
    ServiceAccountTenantServiceApiHostNamesMismatch = "ServiceAccountTenantServiceApiHostNamesMismatch",
    ServiceAccountUnauthorized = "ServiceAccountUnauthorized",
    ServiceAccountWorkloadAnalysisPermissionsMismatch = "ServiceAccountWorkloadAnalysisPermissionsMismatch",
    TenantDeletePending = "TenantDeletePending",
    TenantNotExist = "TenantNotExist",
    TenantSuspended = "TenantSuspended",
    Valid = "Valid",
    ValidSinkExclusionFilterMismatch = "ValidSinkExclusionFilterMismatch",
    ValidSinkInclusionFilterMismatch = "ValidSinkInclusionFilterMismatch",
    ValidSinkMultipleSinks = "ValidSinkMultipleSinks",
    ValidSinkNotConfigured = "ValidSinkNotConfigured",
    ValidSinkNotExist = "ValidSinkNotExist",
    ValidSinkOrganizationIncludeChildrenNotEnabled = "ValidSinkOrganizationIncludeChildrenNotEnabled",
    ValidSinkSubscriptionExpirationTimeFrameExists = "ValidSinkSubscriptionExpirationTimeFrameExists",
    ValidSinkSubscriptionFilterExists = "ValidSinkSubscriptionFilterExists",
    ValidSinkSubscriptionMessageDeliveryTypePush = "ValidSinkSubscriptionMessageDeliveryTypePush",
    ValidSinkSubscriptionMessageRetentionDuration = "ValidSinkSubscriptionMessageRetentionDuration",
    ValidSinkSubscriptionNotExist = "ValidSinkSubscriptionNotExist",
    ValidSinkSubscriptionRawIdNotValid = "ValidSinkSubscriptionRawIdNotValid",
    ValidSinkSubscriptionServiceAccountUnauthorized = "ValidSinkSubscriptionServiceAccountUnauthorized",
    ValidSinkSubscriptionTopicNotConnected = "ValidSinkSubscriptionTopicNotConnected",
    ValidWithIssues = "ValidWithIssues"
}

export enum GcpTenantStateMonitoringIssue {
    GciTenantIssue = "GciTenantIssue",
    ServiceAccountDataAnalysisPermissionsMismatch = "ServiceAccountDataAnalysisPermissionsMismatch",
    ServiceAccountMandatoryOrganizationPermissionsMismatch = "ServiceAccountMandatoryOrganizationPermissionsMismatch",
    ServiceAccountMandatoryPermissionsMismatch = "ServiceAccountMandatoryPermissionsMismatch",
    ServiceAccountOptionalRoleMismatch = "ServiceAccountOptionalRoleMismatch",
    ServiceAccountTenantBillingNotEnabled = "ServiceAccountTenantBillingNotEnabled",
    ServiceAccountTenantServiceApiHostNamesMismatch = "ServiceAccountTenantServiceApiHostNamesMismatch",
    ServiceAccountUnauthorized = "ServiceAccountUnauthorized",
    ServiceAccountWorkloadAnalysisPermissionsMismatch = "ServiceAccountWorkloadAnalysisPermissionsMismatch",
    TenantDeletePending = "TenantDeletePending",
    TenantNotExist = "TenantNotExist",
    TenantSuspended = "TenantSuspended"
}

export enum GcpTenantStateMonitoringPermissionManagementIssue {
    ServiceAccountAssignedRoleMismatch = "ServiceAccountAssignedRoleMismatch"
}

export enum GcpTenantStateMonitoringSinkIssue {
    NotConfigured = "NotConfigured",
    SinkExclusionFilterMismatch = "SinkExclusionFilterMismatch",
    SinkInclusionFilterMismatch = "SinkInclusionFilterMismatch",
    SinkMultipleSinks = "SinkMultipleSinks",
    SinkNotExist = "SinkNotExist",
    SinkOrganizationIncludeChildrenNotEnabled = "SinkOrganizationIncludeChildrenNotEnabled",
    SubscriptionExpirationTimeFrameExists = "SubscriptionExpirationTimeFrameExists",
    SubscriptionFilterExists = "SubscriptionFilterExists",
    SubscriptionMessageDeliveryTypePush = "SubscriptionMessageDeliveryTypePush",
    SubscriptionMessageRetentionDuration = "SubscriptionMessageRetentionDuration",
    SubscriptionNotExist = "SubscriptionNotExist",
    SubscriptionRawIdNotValid = "SubscriptionRawIdNotValid",
    SubscriptionServiceAccountUnauthorized = "SubscriptionServiceAccountUnauthorized",
    SubscriptionTopicNotConnected = "SubscriptionTopicNotConnected"
}

export enum GenerateReportError {
    Timeout = "Timeout"
}

export enum GeographyType {
    Ae = "Ae",
    Af = "Af",
    Ap = "Ap",
    Au = "Au",
    Br = "Br",
    Ca = "Ca",
    Cn = "Cn",
    Eu = "Eu",
    EuIsoE = "EuIsoE",
    Il = "Il",
    In = "In",
    Jp = "Jp",
    Kr = "Kr",
    Me = "Me",
    Sa = "Sa",
    Sg = "Sg",
    Uk = "Uk",
    Us = "Us",
    UsGov = "UsGov",
    UsIsoA = "UsIsoA",
    UsIsoB = "UsIsoB",
    UsIsoF = "UsIsoF"
}

export enum GitHubOrganizationManagerError {
    CommonErrorInstallationMissingBasicPermissions = "CommonErrorInstallationMissingBasicPermissions",
    CommonErrorInstallationNetworkAccessDenied = "CommonErrorInstallationNetworkAccessDenied",
    CommonErrorInstallationNotFound = "CommonErrorInstallationNotFound",
    CommonErrorServerInvalid = "CommonErrorServerInvalid",
    InsertOrganizationErrorOrganizationExists = "InsertOrganizationErrorOrganizationExists"
}

export enum GitHubServerManagerError {
    CommonErrorApplicationClientIdInvalid = "CommonErrorApplicationClientIdInvalid",
    CommonErrorApplicationClientSecretInvalid = "CommonErrorApplicationClientSecretInvalid",
    CommonErrorApplicationIdInvalid = "CommonErrorApplicationIdInvalid",
    CommonErrorApplicationPrivateKeyInvalid = "CommonErrorApplicationPrivateKeyInvalid",
    CommonErrorEndpointInvalid = "CommonErrorEndpointInvalid",
    CommonErrorEndpointNotExist = "CommonErrorEndpointNotExist",
    CommonErrorServerCertificateValidationFailed = "CommonErrorServerCertificateValidationFailed",
    CommonErrorServerNameExists = "CommonErrorServerNameExists",
    InsertServerErrorServerExists = "InsertServerErrorServerExists"
}

export enum GitHubServerManagerValidateServerOnboardingServerInfoResult {
    EndpointInvalid = "EndpointInvalid",
    EndpointNotExist = "EndpointNotExist",
    ServerCertificateValidationFailed = "ServerCertificateValidationFailed",
    ServerExists = "ServerExists",
    ServerNameExists = "ServerNameExists",
    Valid = "Valid"
}

export enum GitLabOrganizationManagerError {
    CommonErrorAccessTokenIncorrectRole = "CommonErrorAccessTokenIncorrectRole",
    CommonErrorAccessTokenIncorrectScope = "CommonErrorAccessTokenIncorrectScope",
    CommonErrorAccessTokenMissingPermissions = "CommonErrorAccessTokenMissingPermissions",
    CommonErrorAccessTokenUnauthorized = "CommonErrorAccessTokenUnauthorized",
    CommonErrorOrganizationExists = "CommonErrorOrganizationExists",
    CommonErrorOrganizationMismatch = "CommonErrorOrganizationMismatch",
    CommonErrorServerInvalid = "CommonErrorServerInvalid"
}

export enum GitLabServerManagerError {
    CommonErrorEndpointInvalid = "CommonErrorEndpointInvalid",
    CommonErrorEndpointNotExist = "CommonErrorEndpointNotExist",
    CommonErrorServerCertificateValidationFailed = "CommonErrorServerCertificateValidationFailed",
    CommonErrorServerNameExists = "CommonErrorServerNameExists",
    CommonErrorServerResponseInvalid = "CommonErrorServerResponseInvalid",
    InsertServerErrorServerExists = "InsertServerErrorServerExists"
}

export enum GitTenantStateMonitoringIssue {
    CodeOrganizationIssue = "CodeOrganizationIssue",
    CodeServerIssue = "CodeServerIssue",
    TenantEmpty = "TenantEmpty",
    TenantNotFound = "TenantNotFound",
    TenantSuspended = "TenantSuspended"
}

export enum IdentityPermission {
    ApplicationCustomerRead = "ApplicationCustomerRead",
    ApplicationCustomerWrite = "ApplicationCustomerWrite",
    ApplicationOutpostRead = "ApplicationOutpostRead",
    ApplicationSlackRead = "ApplicationSlackRead",
    ApplicationSlackWrite = "ApplicationSlackWrite",
    ApplicationTiRead = "ApplicationTiRead",
    CodeScannerRead = "CodeScannerRead",
    CodeScannerWrite = "CodeScannerWrite",
    DataAnalysisRead = "DataAnalysisRead",
    DataAnalysisWrite = "DataAnalysisWrite",
    KubernetesClusterConnectorRead = "KubernetesClusterConnectorRead",
    KubernetesClusterConnectorWrite = "KubernetesClusterConnectorWrite",
    PermissionManagementAdministrationRead = "PermissionManagementAdministrationRead",
    PermissionManagementAdministrationWrite = "PermissionManagementAdministrationWrite",
    PermissionManagementPermissionRequest = "PermissionManagementPermissionRequest",
    PermissionManagementResourceRead = "PermissionManagementResourceRead",
    SecurityAdministrationRead = "SecurityAdministrationRead",
    SecurityAdministrationWrite = "SecurityAdministrationWrite",
    SecurityOwner = "SecurityOwner",
    SecurityRead = "SecurityRead",
    SecurityWrite = "SecurityWrite",
    TunnelSessionRead = "TunnelSessionRead",
    TunnelSessionWrite = "TunnelSessionWrite",
    WorkloadAnalysisRead = "WorkloadAnalysisRead",
    WorkloadAnalysisWrite = "WorkloadAnalysisWrite"
}

export enum IdentityRole {
    Administrator = "Administrator",
    AdministratorViewer = "AdministratorViewer",
    Collaborator = "Collaborator",
    Owner = "Owner",
    PermissionManagementAdministrator = "PermissionManagementAdministrator",
    Viewer = "Viewer"
}

export enum IntegrationType {
    Ci = "Ci",
    Datadog = "Datadog",
    EndpointConnector = "EndpointConnector",
    GitHubServer = "GitHubServer",
    GitLabServer = "GitLabServer",
    Jira = "Jira",
    Kubernetes = "Kubernetes",
    QRadar = "QRadar",
    ServiceNow = "ServiceNow",
    Slack = "Slack",
    Splunk = "Splunk",
    Sqs = "Sqs",
    Teams = "Teams",
    Webhook = "Webhook"
}

export enum IpAddressFamily {
    IpV4 = "IpV4",
    IpV6 = "IpV6"
}

export enum JiraInstanceConfigurationConnectionInfoAuthenticationType {
    Basic = "Basic",
    BearerToken = "BearerToken"
}

export enum JiraInstanceDeploymentType {
    Cloud = "Cloud",
    OnPrem = "OnPrem"
}

export enum JiraInstanceStateIssue {
    InvalidUrl = "InvalidUrl",
    UserNotAuthenticated = "UserNotAuthenticated"
}

export enum JiraInstanceStateProjectIssue {
    InstanceError = "InstanceError",
    InvalidConfiguration = "InvalidConfiguration",
    InvalidId = "InvalidId",
    MissingPermissions = "MissingPermissions",
    MissingProductPermissions = "MissingProductPermissions",
    UnsupportedProduct = "UnsupportedProduct",
    UnsupportedProject = "UnsupportedProject"
}

export enum JiraProductType {
    ServiceManagement = "ServiceManagement",
    Software = "Software",
    WorkManagement = "WorkManagement"
}

export enum JiraProjectType {
    Classic = "Classic",
    NextGeneration = "NextGeneration"
}

export enum KubernetesAdmissionControllerResourceOperation {
    Create = "Create",
    Delete = "Delete",
    Update = "Update"
}

export enum KubernetesAdmissionControllerRiskPolicyEffect {
    Alert = "Alert",
    Deny = "Deny"
}

export enum KubernetesAdmissionControllerRiskPolicyResult {
    Pass = "Pass",
    Alert = "Alert",
    Deny = "Deny"
}

export enum KubernetesClusterNodeUnrestrictedAccessFileRiskNodeDataFileUnrestrictedAccessType {
    Ownership = "Ownership",
    Permissions = "Permissions"
}

export enum KubernetesClusterPlatform {
    Kubernetes = "Kubernetes",
    OpenShift = "OpenShift"
}

export enum KubernetesControllerClusterAdmissionControllerEventProperty {
    ClusterReference = "ClusterReference",
    IdentityReference = "IdentityReference",
    OwnerResourceReference = "OwnerResourceReference",
    ResourceOperation = "ResourceOperation",
    ResourceReference = "ResourceReference",
    ResourceTypeName = "ResourceTypeName",
    RiskPolicyConfigurationTypeName = "RiskPolicyConfigurationTypeName",
    RiskPolicyResult = "RiskPolicyResult",
    TenantId = "TenantId",
    Time = "Time",
    ViolationRiskPolicyConfigurationTypeName = "ViolationRiskPolicyConfigurationTypeName"
}

export enum KubernetesControllerSystemKubernetesClusterModelProperty {
    AdmissionControllerStatus = "AdmissionControllerStatus",
    ApiConnectivityStatus = "ApiConnectivityStatus",
    ApplicationVersion = "ApplicationVersion",
    ClusterCreationTime = "ClusterCreationTime",
    ClusterReference = "ClusterReference",
    Platform = "Platform",
    ResourceUpdateTime = "ResourceUpdateTime",
    ScopeId = "ScopeId",
    SensorStatus = "SensorStatus"
}

export enum KubernetesIngressDataRulePathType {
    Exact = "Exact",
    ImplementationSpecific = "ImplementationSpecific",
    Prefix = "Prefix"
}

export enum KubernetesLabelSelectorOperator {
    DoesNotExist = "DoesNotExist",
    Exists = "Exists",
    In = "In",
    NotIn = "NotIn"
}

export enum KubernetesNamespaceDataPodSecurityStandard {
    Baseline = "Baseline",
    None = "None",
    Privileged = "Privileged",
    Restricted = "Restricted"
}

export enum KubernetesNamespaceWorkloadResourceContainerCapabilityRiskModelCapabilitiesType {
    DefaultAndSensitiveCapabilities = "DefaultAndSensitiveCapabilities",
    DefaultCapabilities = "DefaultCapabilities",
    SensitiveCapabilities = "SensitiveCapabilities"
}

export enum KubernetesNodeFileDataType {
    KubeletConfiguration = "KubeletConfiguration",
    KubeletKubernetesConfiguration = "KubeletKubernetesConfiguration",
    KubeProxyKubernetesConfiguration = "KubeProxyKubernetesConfiguration"
}

export enum KubernetesNodeKubeletAuthorizationMode {
    AlwaysAllow = "AlwaysAllow",
    Webhook = "Webhook"
}

export enum KubernetesPortProtocolType {
    Sctp = "Sctp",
    Tcp = "Tcp",
    Udp = "Udp"
}

export enum KubernetesServiceDataType {
    ClusterIpAddress = "ClusterIpAddress",
    ExternalName = "ExternalName",
    LoadBalancer = "LoadBalancer",
    NodePort = "NodePort"
}

export enum KubernetesTaintEffect {
    NoExecute = "NoExecute",
    NoSchedule = "NoSchedule",
    PreferNoSchedule = "PreferNoSchedule"
}

export enum KubernetesTolerationOperator {
    Equal = "Equal",
    Exists = "Exists"
}

export enum LocalizationControllerSupportedLanguage {
    En = "En",
    Fr = "Fr",
    Ja = "Ja"
}

export enum NetworkAccessScope {
    None = "None",
    Restricted = "Restricted",
    Wide = "Wide",
    All = "All"
}

export enum NetworkGraphSecurityPerimeterType {
    FirewallPolicy = "FirewallPolicy",
    InterfaceSecurityGroup = "InterfaceSecurityGroup",
    SubnetSecurityGroup = "SubnetSecurityGroup",
    Vpc = "Vpc"
}

export enum NetworkGraphSpecialSource {
    AnyAzureVirtualMachine = "AnyAzureVirtualMachine"
}

export enum NetworkInboundAccessType {
    ExternalDirect = "ExternalDirect",
    ExternalIndirect = "ExternalIndirect",
    Internal = "Internal"
}

export enum ObjectTypeCategory {
    Entity = "Entity",
    EntityAi = "EntityAi",
    EntityCompute = "EntityCompute",
    EntityContainers = "EntityContainers",
    EntityData = "EntityData",
    EntityIam = "EntityIam",
    EntityIot = "EntityIot",
    EntityKubernetes = "EntityKubernetes",
    EntityManagement = "EntityManagement",
    EntityNetwork = "EntityNetwork",
    EntitySecurity = "EntitySecurity",
    Risk = "Risk"
}

export enum OciBlockStorageBlockVolumeSourceResourceType {
    BlockVolume = "BlockVolume",
    BlockVolumeBackup = "BlockVolumeBackup",
    BlockVolumeReplica = "BlockVolumeReplica"
}

export enum OciBlockStorageBlockVolumeStatus {
    Available = "Available",
    Faulty = "Faulty",
    Provisioning = "Provisioning",
    Restoring = "Restoring",
    Terminated = "Terminated",
    Terminating = "Terminating"
}

export enum OciBlockStorageBootVolumeSourceResourceType {
    BootVolume = "BootVolume",
    BootVolumeBackup = "BootVolumeBackup",
    BootVolumeReplica = "BootVolumeReplica"
}

export enum OciBlockStorageBootVolumeStatus {
    Available = "Available",
    Faulty = "Faulty",
    Provisioning = "Provisioning",
    Restoring = "Restoring",
    Terminated = "Terminated",
    Terminating = "Terminating"
}

export enum OciComputeImageStatus {
    Available = "Available",
    Deleted = "Deleted",
    Disabled = "Disabled",
    Exporting = "Exporting",
    Importing = "Importing",
    Provisioning = "Provisioning"
}

export enum OciComputeInstanceBootSourceType {
    BootVolume = "BootVolume",
    Image = "Image"
}

export enum OciComputeInstanceConfigurationBootSourceType {
    BootVolume = "BootVolume",
    Image = "Image"
}

export enum OciComputeInstanceConfigurationLaunchMode {
    Custom = "Custom",
    Emulated = "Emulated",
    Native = "Native",
    ParaVirtualized = "ParaVirtualized"
}

export enum OciComputeInstanceLaunchMode {
    Custom = "Custom",
    Emulated = "Emulated",
    Native = "Native",
    ParaVirtualized = "ParaVirtualized"
}

export enum OciComputeInstancePoolStatus {
    Provisioning = "Provisioning",
    Running = "Running",
    Scaling = "Scaling",
    Starting = "Starting",
    Stopped = "Stopped",
    Stopping = "Stopping",
    Terminated = "Terminated",
    Terminating = "Terminating"
}

export enum OciComputeInstanceStatus {
    CreatingImage = "CreatingImage",
    Moving = "Moving",
    Provisioning = "Provisioning",
    Running = "Running",
    Starting = "Starting",
    Stopped = "Stopped",
    Stopping = "Stopping",
    Terminated = "Terminated",
    Terminating = "Terminating"
}

export enum OciConsoleView {
    BlockStorage = "BlockStorage",
    Compute = "Compute",
    ContainerEngine = "ContainerEngine",
    Fss = "Fss",
    Kms = "Kms",
    NetworkCommandCenter = "NetworkCommandCenter",
    Networking = "Networking",
    ObjectStorage = "ObjectStorage",
    PasswordPolicy = "PasswordPolicy",
    User = "User"
}

export enum OciContainerEngineClusterNodePoolStatus {
    Active = "Active",
    Attention = "Attention",
    Creating = "Creating",
    Deleted = "Deleted",
    Deleting = "Deleting",
    Failed = "Failed",
    Inactive = "Inactive",
    Updating = "Updating"
}

export enum OciContainerEngineClusterStatus {
    Active = "Active",
    Creating = "Creating",
    Deleted = "Deleted",
    Deleting = "Deleting",
    Failed = "Failed",
    Updating = "Updating"
}

export enum OciContainerEngineClusterType {
    Basic = "Basic",
    Enhanced = "Enhanced"
}

export enum OciFssFileSystemStatus {
    Active = "Active",
    Creating = "Creating",
    Deleted = "Deleted",
    Deleting = "Deleting",
    Failed = "Failed"
}

export enum OciFssMountTargetExportOptionAccess {
    ReadOnly = "ReadOnly",
    ReadWrite = "ReadWrite"
}

export enum OciFssMountTargetExportOptionAuthType {
    Krb5 = "Krb5",
    Krb5I = "Krb5I",
    Krb5P = "Krb5P",
    Sys = "Sys"
}

export enum OciFssMountTargetExportOptionIdentitySquash {
    All = "All",
    None = "None",
    Root = "Root"
}

export enum OciFssMountTargetExportStatus {
    Active = "Active",
    Creating = "Creating",
    Deleted = "Deleted",
    Deleting = "Deleting"
}

export enum OciFssMountTargetStatus {
    Active = "Active",
    Creating = "Creating",
    Deleted = "Deleted",
    Deleting = "Deleting",
    Failed = "Failed"
}

export enum OciIamDomainLicenseType {
    Enterprise = "Enterprise",
    ExternalUser = "ExternalUser",
    Free = "Free",
    OracleApps = "OracleApps",
    OracleAppsPremium = "OracleAppsPremium",
    OracleIdentityCloudServiceConsumerUserByolUserPerMonth = "OracleIdentityCloudServiceConsumerUserByolUserPerMonth",
    OracleIdentityCloudServiceConsumerUserUserPerMonth = "OracleIdentityCloudServiceConsumerUserUserPerMonth",
    OracleIdentityCloudServiceEnterpriseUserByolUserPerMonth = "OracleIdentityCloudServiceEnterpriseUserByolUserPerMonth",
    OracleIdentityCloudServiceEnterpriseUserUserPerMonth = "OracleIdentityCloudServiceEnterpriseUserUserPerMonth",
    OracleIdentityFoundationCloudServiceEach = "OracleIdentityFoundationCloudServiceEach",
    Premium = "Premium"
}

export enum OciKmsVaultKeyAlgorithm {
    Aes = "Aes",
    Ecdsa = "Ecdsa",
    Rsa = "Rsa"
}

export enum OciKmsVaultKeyCurveType {
    NistP256 = "NistP256",
    NistP384 = "NistP384",
    NistP521 = "NistP521"
}

export enum OciKmsVaultKeyProtectionMode {
    External = "External",
    Hsm = "Hsm",
    Software = "Software"
}

export enum OciKmsVaultKeyStatus {
    BackupInProgress = "BackupInProgress",
    CancellingDeletion = "CancellingDeletion",
    Creating = "Creating",
    Deleted = "Deleted",
    Deleting = "Deleting",
    Disabled = "Disabled",
    Disabling = "Disabling",
    Enabled = "Enabled",
    Enabling = "Enabling",
    PendingDeletion = "PendingDeletion",
    Restoring = "Restoring",
    SchedulingDeletion = "SchedulingDeletion",
    Updating = "Updating"
}

export enum OciKmsVaultKeyVersionSource {
    External = "External",
    Internal = "Internal"
}

export enum OciKmsVaultKeyVersionStatus {
    CancellingDeletion = "CancellingDeletion",
    Creating = "Creating",
    Deleted = "Deleted",
    Deleting = "Deleting",
    Disabled = "Disabled",
    Disabling = "Disabling",
    Enabled = "Enabled",
    Enabling = "Enabling",
    PendingDeletion = "PendingDeletion",
    SchedulingDeletion = "SchedulingDeletion"
}

export enum OciKmsVaultSecretStatus {
    Active = "Active",
    CancellingDeletion = "CancellingDeletion",
    Creating = "Creating",
    Deleted = "Deleted",
    Deleting = "Deleting",
    Failed = "Failed",
    PendingDeletion = "PendingDeletion",
    SchedulingDeletion = "SchedulingDeletion",
    Updating = "Updating"
}

export enum OciKmsVaultSecretVersionStatus {
    Current = "Current",
    Deprecated = "Deprecated",
    Latest = "Latest",
    Pending = "Pending",
    Previous = "Previous"
}

export enum OciKmsVaultStatus {
    Active = "Active",
    BackupInProgress = "BackupInProgress",
    CancellingDeletion = "CancellingDeletion",
    Creating = "Creating",
    Deleted = "Deleted",
    Deleting = "Deleting",
    PendingDeletion = "PendingDeletion",
    Restoring = "Restoring",
    SchedulingDeletion = "SchedulingDeletion",
    Updating = "Updating"
}

export enum OciKmsVaultType {
    Default = "Default",
    External = "External",
    VirtualPrivate = "VirtualPrivate"
}

export enum OciLoadBalancingLoadBalancerSslProtocol {
    TlsV1 = "TlsV1",
    TlsV11 = "TlsV11",
    TlsV12 = "TlsV12",
    TlsV13 = "TlsV13"
}

export enum OciLoadBalancingLoadBalancerStatus {
    Active = "Active",
    Creating = "Creating",
    Deleted = "Deleted",
    Deleting = "Deleting",
    Failed = "Failed"
}

export enum OciLoggingLogStatus {
    Active = "Active",
    Creating = "Creating",
    Deleting = "Deleting",
    Failed = "Failed",
    Inactive = "Inactive",
    Updating = "Updating"
}

export enum OciLoggingLogType {
    Custom = "Custom",
    Service = "Service"
}

export enum OciNetworkingInternetGatewayStatus {
    Available = "Available",
    Provisioning = "Provisioning",
    Terminated = "Terminated",
    Terminating = "Terminating"
}

export enum OciNetworkingLocalPeeringGatewayPeeringStatus {
    Invalid = "Invalid",
    New = "New",
    Peered = "Peered",
    Pending = "Pending",
    Revoked = "Revoked"
}

export enum OciNetworkingLocalPeeringGatewayStatus {
    Available = "Available",
    Provisioning = "Provisioning",
    Terminated = "Terminated",
    Terminating = "Terminating"
}

export enum OciNetworkingNatGatewayStatus {
    Available = "Available",
    Provisioning = "Provisioning",
    Terminated = "Terminated",
    Terminating = "Terminating"
}

export enum OciNetworkingNetworkLoadBalancerListenerProtocol {
    Any = "Any",
    Tcp = "Tcp",
    TcpAndUdp = "TcpAndUdp",
    Udp = "Udp"
}

export enum OciNetworkingNetworkLoadBalancerStatus {
    Active = "Active",
    Creating = "Creating",
    Deleted = "Deleted",
    Deleting = "Deleting",
    Failed = "Failed",
    Updating = "Updating"
}

export enum OciNetworkingNetworkSecurityGroupRuleEndpointType {
    CidrBlock = "CidrBlock",
    NetworkSecurityGroup = "NetworkSecurityGroup",
    Service = "Service"
}

export enum OciNetworkingNetworkSecurityGroupStatus {
    Available = "Available",
    Provisioning = "Provisioning",
    Terminated = "Terminated",
    Terminating = "Terminating"
}

export enum OciNetworkingRouteTableRouteRuleRouteType {
    Local = "Local",
    Static = "Static"
}

export enum OciNetworkingRouteTableStatus {
    Available = "Available",
    Provisioning = "Provisioning",
    Terminated = "Terminated",
    Terminating = "Terminating"
}

export enum OciNetworkingSecurityListStatus {
    Available = "Available",
    Provisioning = "Provisioning",
    Terminated = "Terminated",
    Terminating = "Terminating"
}

export enum OciNetworkingServiceGatewayStatus {
    Available = "Available",
    Provisioning = "Provisioning",
    Terminated = "Terminated",
    Terminating = "Terminating"
}

export enum OciNetworkingSubnetStatus {
    Available = "Available",
    Provisioning = "Provisioning",
    Terminated = "Terminated",
    Terminating = "Terminating",
    Updating = "Updating"
}

export enum OciNetworkingVcnStatus {
    Available = "Available",
    Provisioning = "Provisioning",
    Terminated = "Terminated",
    Terminating = "Terminating",
    Updating = "Updating"
}

export enum OciObjectStorageBucketLogCategory {
    All = "All",
    None = "None",
    Read = "Read",
    Write = "Write"
}

export enum OciObjectStorageBucketObjectAccessLevel {
    Private = "Private",
    PublicGet = "PublicGet",
    PublicRead = "PublicRead"
}

export enum OciObjectStorageBucketObjectVersioning {
    Disabled = "Disabled",
    Enabled = "Enabled",
    Suspended = "Suspended"
}

export enum OciObjectStorageBucketStorageTier {
    Archive = "Archive",
    Standard = "Standard"
}

export enum OciOrganizationModelStatus {
    Deleting = "Deleting",
    UserNotAuthenticated = "UserNotAuthenticated",
    UserNotAuthorized = "UserNotAuthorized",
    UserPrivateKeyNotValid = "UserPrivateKeyNotValid",
    Valid = "Valid"
}

export enum OciOrganizationStateIssue {
    UserNotAuthenticated = "UserNotAuthenticated",
    UserNotAuthorized = "UserNotAuthorized",
    UserPrivateKeyNotValid = "UserPrivateKeyNotValid"
}

export enum OciPartitionType {
    Oc1 = "Oc1",
    Oc2 = "Oc2"
}

export enum OciTenantManagerError {
    CommonErrorMoveScopeDependencyExists = "CommonErrorMoveScopeDependencyExists",
    CommonErrorParentFolderNotExist = "CommonErrorParentFolderNotExist",
    CommonErrorTenantNotExist = "CommonErrorTenantNotExist",
    CommonErrorUserDomainNotFound = "CommonErrorUserDomainNotFound",
    CommonErrorUserNotAuthenticated = "CommonErrorUserNotAuthenticated",
    CommonErrorUserNotAuthorized = "CommonErrorUserNotAuthorized",
    CommonErrorUserPrivateKeyNotValid = "CommonErrorUserPrivateKeyNotValid",
    InsertOrganizationErrorHomeRegionSystemNameMismatch = "InsertOrganizationErrorHomeRegionSystemNameMismatch",
    InsertOrganizationErrorHomeRegionSystemNameNotValid = "InsertOrganizationErrorHomeRegionSystemNameNotValid",
    InsertOrganizationErrorOrganizationExists = "InsertOrganizationErrorOrganizationExists"
}

export enum OciTenantModelStatus {
    AccessDenied = "AccessDenied",
    Deleting = "Deleting",
    OrganizationIssue = "OrganizationIssue",
    ParentTenantIssue = "ParentTenantIssue",
    TenantDeleted = "TenantDeleted",
    TenantDeletePending = "TenantDeletePending",
    TenantNotActive = "TenantNotActive",
    TenantNotExist = "TenantNotExist",
    Valid = "Valid"
}

export enum OciTenantStateMonitoringIssue {
    OrganizationIssue = "OrganizationIssue",
    ParentTenantIssue = "ParentTenantIssue",
    TenantDeleted = "TenantDeleted",
    TenantDeletePending = "TenantDeletePending",
    TenantNotActive = "TenantNotActive",
    TenantNotExist = "TenantNotExist"
}

export enum OktaDirectoryApplicationSignOnMode {
    AutoLogin = "AutoLogin",
    Basic = "Basic",
    Bookmark = "Bookmark",
    BrowserPlugin = "BrowserPlugin",
    Oidc = "Oidc",
    Saml11 = "Saml11",
    Saml20 = "Saml20",
    SecurePasswordStore = "SecurePasswordStore",
    Unsupported = "Unsupported",
    WsFederation = "WsFederation"
}

export enum OktaDirectoryApplicationStatus {
    Active = "Active",
    Inactive = "Inactive"
}

export enum OktaDirectoryApplicationUserAssignmentType {
    Group = "Group",
    User = "User"
}

export enum OktaDirectoryAwsApplicationAwsTenantType {
    Multi = "Multi",
    Single = "Single"
}

export enum OktaDirectoryGroupType {
    ApplicationImported = "ApplicationImported",
    Native = "Native",
    NativeBuiltin = "NativeBuiltin"
}

export enum OktaDirectoryUserStatus {
    Active = "Active",
    Deprovisioned = "Deprovisioned",
    LockedOut = "LockedOut",
    PasswordExpired = "PasswordExpired",
    PasswordReset = "PasswordReset",
    Provisioned = "Provisioned",
    Staged = "Staged",
    Suspended = "Suspended"
}

export enum OktaTenantModelStatus {
    AccessDenied = "AccessDenied",
    Deleting = "Deleting",
    UserAuthenticationFailure = "UserAuthenticationFailure",
    Valid = "Valid"
}

export enum OktaTenantStateMonitoringPermissionManagementIssue {
    PermissionsMismatch = "PermissionsMismatch"
}

export enum OneLoginDirectoryApplicationParameterTransformationType {
    AwsMultiRoleAmazonRoles = "AwsMultiRoleAmazonRoles",
    AwsMultiRoleGroupList = "AwsMultiRoleGroupList",
    AwsMultiRoleSemicolon = "AwsMultiRoleSemicolon",
    None = "None"
}

export enum OneLoginDirectoryApplicationParameterType {
    AttributeMapping = "AttributeMapping",
    CustomAttributeMapping = "CustomAttributeMapping",
    Macro = "Macro",
    None = "None"
}

export enum OneLoginDirectoryApplicationRuleActionType {
    SetAwsRoleArn = "SetAwsRoleArn"
}

export enum OneLoginDirectoryApplicationRuleConditionMatchType {
    All = "All",
    Any = "Any"
}

export enum OneLoginDirectoryApplicationRuleConditionOperator {
    Contains = "Contains",
    Equal = "Equal",
    Include = "Include",
    NotEqual = "NotEqual",
    NotInclude = "NotInclude"
}

export enum OneLoginDirectoryApplicationRuleConditionSource {
    CustomAttribute = "CustomAttribute",
    Department = "Department",
    Group = "Group",
    Mail = "Mail",
    ManagerMail = "ManagerMail",
    Role = "Role",
    Title = "Title"
}

export enum OneLoginDirectoryApplicationType {
    AwsMultiAccount = "AwsMultiAccount",
    AwsMultiRole = "AwsMultiRole",
    AwsSingleRole = "AwsSingleRole"
}

export enum OneLoginDirectoryUserProvisioningState {
    Approved = "Approved",
    Rejected = "Rejected",
    Unapproved = "Unapproved",
    Unlicensed = "Unlicensed"
}

export enum OneLoginDirectoryUserStatus {
    Active = "Active",
    AwaitingPasswordReset = "AwaitingPasswordReset",
    Locked = "Locked",
    PasswordExpired = "PasswordExpired",
    PasswordPending = "PasswordPending",
    SecurityQuestionsRequired = "SecurityQuestionsRequired",
    Suspended = "Suspended",
    Unactivated = "Unactivated"
}

export enum OneLoginTenantModelStatus {
    ClientAuthenticationFailure = "ClientAuthenticationFailure",
    Deleting = "Deleting",
    Valid = "Valid"
}

export enum OneLoginTenantStateMonitoringPermissionManagementIssue {
    AccountMismatch = "AccountMismatch",
    AuthenticationFailed = "AuthenticationFailed",
    PermissionsMismatch = "PermissionsMismatch"
}

export enum OperatingSystemArchitecture {
    AArch64 = "AArch64",
    Amd64 = "Amd64",
    Arm64 = "Arm64",
    Arm64Be = "Arm64Be",
    Armbe = "Armbe",
    I386 = "I386",
    Loong64 = "Loong64",
    Mips = "Mips",
    Mips64 = "Mips64",
    Mips64Le = "Mips64Le",
    Mips64P32 = "Mips64P32",
    Mips64P32Le = "Mips64P32Le",
    Mipsle = "Mipsle",
    Ppc = "Ppc",
    Ppc64 = "Ppc64",
    Ppc64Le = "Ppc64Le",
    Riscv = "Riscv",
    Riscv64 = "Riscv64",
    S390 = "S390",
    S390X = "S390X",
    Sparc = "Sparc",
    Sparc64 = "Sparc64",
    Wasm = "Wasm"
}

export enum OperatingSystemDistribution {
    Alma = "Alma",
    Alpine = "Alpine",
    Amazon = "Amazon",
    CblMariner = "CblMariner",
    CentOs = "CentOs",
    CentOsStream = "CentOsStream",
    Chainguard = "Chainguard",
    Debian = "Debian",
    Fedora = "Fedora",
    GoogleCos = "GoogleCos",
    OpenSuse = "OpenSuse",
    Oracle = "Oracle",
    Photon = "Photon",
    RedHat = "RedHat",
    RedHatCoreOs = "RedHatCoreOs",
    Rocky = "Rocky",
    Sles = "Sles",
    Ubuntu = "Ubuntu",
    Windows = "Windows"
}

export enum OperatingSystemRiskPolicyType {
    EndOfLife = "EndOfLife",
    Secured = "Secured",
    Unpatched = "Unpatched"
}

export enum OperatingSystemType {
    Linux = "Linux",
    Windows = "Windows"
}

export enum OpTenantModelStatus {
    Deleting = "Deleting",
    Valid = "Valid"
}

export enum OrchestrationType {
    AadEventAnalysis = "AadEventAnalysis",
    Analysis = "Analysis",
    AwsEc2Analysis = "AwsEc2Analysis",
    AwsEventAnalysis = "AwsEventAnalysis",
    AzureComputeVirtualMachineAnalysis = "AzureComputeVirtualMachineAnalysis",
    AzureEventAnalysis = "AzureEventAnalysis",
    CodeAnalysis = "CodeAnalysis",
    ContainerImageRepositoryAnalysis = "ContainerImageRepositoryAnalysis",
    EventAnalysis = "EventAnalysis",
    GciEventAnalysis = "GciEventAnalysis",
    GcpComputeInstanceAnalysis = "GcpComputeInstanceAnalysis",
    GcpEventAnalysis = "GcpEventAnalysis",
    ManagedDatabaseAnalysis = "ManagedDatabaseAnalysis",
    ObjectStoreAnalysis = "ObjectStoreAnalysis",
    ProvisionedManagedDatabaseAnalysis = "ProvisionedManagedDatabaseAnalysis"
}

export enum OrganizationMemberSelectionType {
    Exclude = "Exclude",
    Include = "Include"
}

export enum PagedItemSelectionType {
    Exclude = "Exclude",
    Include = "Include"
}

export enum PartitionType {
    EuIsoE = "EuIsoE",
    Public = "Public",
    PublicIso = "PublicIso",
    UsGov = "UsGov",
    UsGovIso = "UsGovIso",
    UsIsoA = "UsIsoA",
    UsIsoB = "UsIsoB",
    UsIsoF = "UsIsoF"
}

export enum PermissionActionExcessiveness {
    Excessive = "Excessive",
    Nonexcessive = "Nonexcessive"
}

export enum PermissionManagementControllerGetPermissionEligibilityModelPageRequestProperty {
    Name = "Name",
    Scope = "Scope"
}

export enum PermissionManagementEntityAttributeType {
    Groups = "Groups",
    Permissions = "Permissions"
}

export enum PermissionRequestCloseReason {
    ActivationFailed = "ActivationFailed",
    Cancelled = "Cancelled",
    Expired = "Expired",
    Rejected = "Rejected",
    RequestExpired = "RequestExpired",
    Revoked = "Revoked"
}

export enum PermissionRequestCloseReasonAndStatus {
    ActivationFailed = "ActivationFailed",
    Active = "Active",
    Cancelled = "Cancelled",
    Expired = "Expired",
    PendingActivation = "PendingActivation",
    PendingApproval = "PendingApproval",
    Rejected = "Rejected",
    RequestExpired = "RequestExpired",
    Revoked = "Revoked"
}

export enum PermissionRequestMailPermissionRequestType {
    GroupMembership = "GroupMembership",
    OneLoginDirectoryRoleAssignment = "OneLoginDirectoryRoleAssignment",
    PermissionAssignment = "PermissionAssignment"
}

export enum PermissionRequestStatus {
    Active = "Active",
    Closed = "Closed",
    PendingActivation = "PendingActivation",
    PendingApproval = "PendingApproval"
}

export enum PermitterPermissionScope {
    Exact = "Exact",
    Pattern = "Pattern",
    Any = "Any"
}

export enum PingIdentityClientRegion {
    Ap = "Ap",
    Ca = "Ca",
    Eu = "Eu",
    Us = "Us"
}

export enum PingIdentityDirectoryApplicationGroupsCondition {
    All = "All",
    Any = "Any"
}

export enum PingIdentityDirectoryApplicationParameterType {
    Core = "Core",
    Custom = "Custom",
    Scope = "Scope"
}

export enum PingIdentityDirectoryApplicationType {
    Aws = "Aws"
}

export enum PingIdentityDirectoryUserGroupMembershipType {
    Direct = "Direct",
    Indirect = "Indirect"
}

export enum PingIdentityDirectoryUserStatus {
    Disabled = "Disabled",
    Enabled = "Enabled",
    Locked = "Locked"
}

export enum PingIdentityTenantModelStatus {
    ClientAuthenticationFailure = "ClientAuthenticationFailure",
    Deleting = "Deleting",
    Valid = "Valid"
}

export enum PingIdentityTenantStateMonitoringPermissionManagementIssue {
    PermissionsMismatch = "PermissionsMismatch"
}

export enum PrincipalRiskCategory {
    ExcessivePermissionAwsPermissionSet = "ExcessivePermissionAwsPermissionSet",
    ExcessivePermissionAwsRoleGroup = "ExcessivePermissionAwsRoleGroup",
    ExcessivePermissionGroup = "ExcessivePermissionGroup",
    ExcessivePermissionServiceIdentity = "ExcessivePermissionServiceIdentity",
    ExcessivePermissionUser = "ExcessivePermissionUser",
    InactiveServiceIdentity = "InactiveServiceIdentity",
    InactiveUser = "InactiveUser",
    UnusedAwsPermissionSet = "UnusedAwsPermissionSet"
}

export enum PrincipalSeverePermissionActionRiskType {
    SeverePermissionActionPrincipal = "SeverePermissionActionPrincipal",
    AdministratorPrincipal = "AdministratorPrincipal"
}

export enum PrincipalType {
    AwsPermissionSet = "AwsPermissionSet",
    AwsRoleGroup = "AwsRoleGroup",
    FederationIdentity = "FederationIdentity",
    GcpWorkforcePool = "GcpWorkforcePool",
    GcpWorkloadIdentityPool = "GcpWorkloadIdentityPool",
    Group = "Group",
    ServiceIdentity = "ServiceIdentity",
    User = "User",
    VendorServiceIdentity = "VendorServiceIdentity"
}

export enum ProjectStateStatus {
    Pending = "Pending",
    Ready = "Ready",
    Updating = "Updating"
}

export enum ProtocolType {
    IP = "IP",
    IPv6HopByHopOptions = "IPv6HopByHopOptions",
    Unspecified = "Unspecified",
    Icmp = "Icmp",
    Igmp = "Igmp",
    Ggp = "Ggp",
    IPv4 = "IPv4",
    Tcp = "Tcp",
    Pup = "Pup",
    Udp = "Udp",
    Idp = "Idp",
    IPv6 = "IPv6",
    IPv6RoutingHeader = "IPv6RoutingHeader",
    IPv6FragmentHeader = "IPv6FragmentHeader",
    IPSecEncapsulatingSecurityPayload = "IPSecEncapsulatingSecurityPayload",
    IPSecAuthenticationHeader = "IPSecAuthenticationHeader",
    IcmpV6 = "IcmpV6",
    IPv6NoNextHeader = "IPv6NoNextHeader",
    IPv6DestinationOptions = "IPv6DestinationOptions",
    ND = "ND",
    Raw = "Raw",
    Ipx = "Ipx",
    Spx = "Spx",
    SpxII = "SpxII",
    Unknown = "Unknown"
}

export enum PublicAccessPreventionType {
    Enforced = "Enforced",
    EnforcedInherited = "EnforcedInherited",
    NotEnforced = "NotEnforced"
}

export enum RegionLocation {
    Africa = "Africa",
    Asia = "Asia",
    AsiaPacific = "AsiaPacific",
    Australia = "Australia",
    Bahrain = "Bahrain",
    Bangladesh = "Bangladesh",
    Belgium = "Belgium",
    Brazil = "Brazil",
    Canada = "Canada",
    Chile = "Chile",
    China = "China",
    Colombia = "Colombia",
    Europe = "Europe",
    Finland = "Finland",
    France = "France",
    Germany = "Germany",
    Global = "Global",
    India = "India",
    Indonesia = "Indonesia",
    Ireland = "Ireland",
    Israel = "Israel",
    Italy = "Italy",
    Japan = "Japan",
    Malaysia = "Malaysia",
    Mexico = "Mexico",
    Netherlands = "Netherlands",
    NewZealand = "NewZealand",
    NorthAmerica = "NorthAmerica",
    Norway = "Norway",
    Oman = "Oman",
    Poland = "Poland",
    Qatar = "Qatar",
    SaudiArabia = "SaudiArabia",
    Serbia = "Serbia",
    Singapore = "Singapore",
    SouthAfrica = "SouthAfrica",
    SouthKorea = "SouthKorea",
    Spain = "Spain",
    Sweden = "Sweden",
    Switzerland = "Switzerland",
    Thailand = "Thailand",
    UnitedArabEmirates = "UnitedArabEmirates",
    UnitedKingdom = "UnitedKingdom",
    UnitedStates = "UnitedStates",
    UnitedStatesGov = "UnitedStatesGov"
}

export enum ReportContentType {
    Csv = "Csv",
    Pdf = "Pdf"
}

export enum ReportScheduleCadenceInterval {
    Daily = "Daily",
    Monthly = "Monthly",
    Weekly = "Weekly"
}

export enum ReportType {
    AwsIamPolicies = "AwsIamPolicies",
    AwsIamUserAccessKeys = "AwsIamUserAccessKeys",
    AwsSsoUserTenantAssignments = "AwsSsoUserTenantAssignments",
    AzureAuthorizationRoleAssignments = "AzureAuthorizationRoleAssignments",
    CodeRisks = "CodeRisks",
    Compliance = "Compliance",
    ComplianceScopes = "ComplianceScopes",
    Dashboard = "Dashboard",
    EntityAccess = "EntityAccess",
    Events = "Events",
    GcpIamRoleBindings = "GcpIamRoleBindings",
    GcpIamServiceAccountUserManagedKeys = "GcpIamServiceAccountUserManagedKeys",
    Inventory = "Inventory",
    RiskPolicy = "RiskPolicy",
    Risks = "Risks",
    Vulnerabilities = "Vulnerabilities",
    WorkloadAnalysis = "WorkloadAnalysis",
    WorkloadResources = "WorkloadResources"
}

export enum RiskCombination {
    AwsContainerImageVulnerabilitySeverityCriticalInboundExternalEcsService = "AwsContainerImageVulnerabilitySeverityCriticalInboundExternalEcsService",
    AwsContainerImageVulnerabilitySeverityCriticalInboundExternalSeverePermissionActionEcsService = "AwsContainerImageVulnerabilitySeverityCriticalInboundExternalSeverePermissionActionEcsService",
    AwsContainerImageVulnerabilitySeverityCriticalSeverePermissionActionEcsService = "AwsContainerImageVulnerabilitySeverityCriticalSeverePermissionActionEcsService",
    AwsInboundExternalSeverePermissionActionEcsService = "AwsInboundExternalSeverePermissionActionEcsService",
    AwsMfaDisabledSeverePermissionActionIamUser = "AwsMfaDisabledSeverePermissionActionIamUser",
    AwsPublicAccessEncryptionDisabledDataResource = "AwsPublicAccessEncryptionDisabledDataResource",
    AzureInboundExternalSeverePermissionActionWebApplication = "AzureInboundExternalSeverePermissionActionWebApplication",
    AzureInboundExternalSharedKeyAccessStorageStorageAccount = "AzureInboundExternalSharedKeyAccessStorageStorageAccount",
    GcpContainerImageVulnerabilitySeverityCriticalPublicAccessCloudRunService = "GcpContainerImageVulnerabilitySeverityCriticalPublicAccessCloudRunService",
    GcpContainerImageVulnerabilitySeverityCriticalPublicAccessSeverePermissionActionCloudRunService = "GcpContainerImageVulnerabilitySeverityCriticalPublicAccessSeverePermissionActionCloudRunService",
    GcpContainerImageVulnerabilitySeverityCriticalSeverePermissionActionAppEngineApplicationService = "GcpContainerImageVulnerabilitySeverityCriticalSeverePermissionActionAppEngineApplicationService",
    GcpContainerImageVulnerabilitySeverityCriticalSeverePermissionActionCloudRunService = "GcpContainerImageVulnerabilitySeverityCriticalSeverePermissionActionCloudRunService",
    InboundExternalOperatingSystemEndOfLifeWorkloadResource = "InboundExternalOperatingSystemEndOfLifeWorkloadResource",
    InboundExternalOperatingSystemUnpatchedWorkloadResource = "InboundExternalOperatingSystemUnpatchedWorkloadResource",
    InboundExternalSensitiveVirtualMachine = "InboundExternalSensitiveVirtualMachine",
    InboundExternalSeverePermissionActionVirtualMachine = "InboundExternalSeverePermissionActionVirtualMachine",
    InboundExternalSevereVulnerabilitySeverePermissionActionWorkloadResource = "InboundExternalSevereVulnerabilitySeverePermissionActionWorkloadResource",
    InboundExternalSevereVulnerabilityWorkloadResource = "InboundExternalSevereVulnerabilityWorkloadResource",
    PublicAccessSensitiveDataResource = "PublicAccessSensitiveDataResource",
    PublicAccessSeverePermissionActionComputeFunction = "PublicAccessSeverePermissionActionComputeFunction",
    SensitiveResourcePermissionActionExternalPrincipal = "SensitiveResourcePermissionActionExternalPrincipal",
    SensitiveResourcePermissionActionGuestUser = "SensitiveResourcePermissionActionGuestUser",
    SensitiveResourcePermissionActionVendorServiceIdentity = "SensitiveResourcePermissionActionVendorServiceIdentity",
    SeverePermissionActionExternalPrincipal = "SeverePermissionActionExternalPrincipal",
    SeverePermissionActionGuestUser = "SeverePermissionActionGuestUser",
    SevereVulnerabilitySeverePermissionActionWorkloadResource = "SevereVulnerabilitySeverePermissionActionWorkloadResource"
}

export enum RiskControllerGetRiskGroupsRequestType {
    RiskPolicyType = "RiskPolicyType",
    Tenant = "Tenant"
}

export enum RiskControllerGetRiskPolicyTypeGroupFilterItemPageRequestProperty {
    AwsBehaviorIdentityRiskResources = "AwsBehaviorIdentityRiskResources",
    AwsExcessivePermissionGroupRiskGroupUsers = "AwsExcessivePermissionGroupRiskGroupUsers",
    AwsLambdaFunctionConfigurationPublicAccessExistsRiskTriggerResources = "AwsLambdaFunctionConfigurationPublicAccessExistsRiskTriggerResources",
    AzureBehaviorIdentityRiskResources = "AzureBehaviorIdentityRiskResources",
    GcpBehaviorIdentityRiskResources = "GcpBehaviorIdentityRiskResources",
    NetworkInboundExternalResourceRiskSourceSubnets = "NetworkInboundExternalResourceRiskSourceSubnets",
    RiskedEntities = "RiskedEntities"
}

export enum RiskControllerRequestSortRiskModelProperty {
    IgnoreExpirationDate = "IgnoreExpirationDate",
    OpenStatusUpdateTime = "OpenStatusUpdateTime",
    StatusUpdateTime = "StatusUpdateTime",
    SystemCreationTime = "SystemCreationTime"
}

export enum RiskControllerResolveRiskCodeAutomationError {
    PullRequestMaxSizeExceeded = "PullRequestMaxSizeExceeded",
    PullRequestMissingPermissions = "PullRequestMissingPermissions",
    PullRequestSourceCommitNotFound = "PullRequestSourceCommitNotFound"
}

export enum RiskControllerUpdateRiskPolicyConfigurationError {
    Conflict = "Conflict"
}

export enum RiskFileType {
    AwsPolicyDocumentRaw = "AwsPolicyDocumentRaw",
    Terraform = "Terraform",
    AwsCloudFormation = "AwsCloudFormation"
}

export enum RiskFilterId {
    CodeRiskCloudTenantType = "CodeRiskCloudTenantType",
    CodeRiskCodeType = "CodeRiskCodeType",
    CodeRiskFileRelativePath = "CodeRiskFileRelativePath",
    CodeRiskResourceName = "CodeRiskResourceName",
    CodeRiskResourceTypeSystemName = "CodeRiskResourceTypeSystemName",
    ComplianceIdOrSectionType = "ComplianceIdOrSectionType",
    OpenStatusUpdateTime = "OpenStatusUpdateTime",
    RiskedEntityAttribute = "RiskedEntityAttribute",
    RiskedEntityEnvironment = "RiskedEntityEnvironment",
    RiskedEntityId = "RiskedEntityId",
    RiskedEntityOwner = "RiskedEntityOwner",
    RiskIgnoreExpirationDate = "RiskIgnoreExpirationDate",
    RiskPolicyCategory = "RiskPolicyCategory",
    RiskPolicyConfigurationTypeNameOrId = "RiskPolicyConfigurationTypeNameOrId",
    RiskSeverity = "RiskSeverity",
    RiskStarred = "RiskStarred",
    RiskSubStatus = "RiskSubStatus",
    StatusUpdateTime = "StatusUpdateTime",
    SystemCreationTime = "SystemCreationTime",
    TenantId = "TenantId",
    TicketingServiceType = "TicketingServiceType"
}

export enum RiskPolicyCategory {
    Access = "Access",
    Behavior = "Behavior",
    Compute = "Compute",
    Custom = "Custom",
    CustomKubernetesAdmissionController = "CustomKubernetesAdmissionController",
    Data = "Data",
    Kubernetes = "Kubernetes",
    KubernetesAdmissionController = "KubernetesAdmissionController",
    Logging = "Logging",
    Management = "Management",
    Monitoring = "Monitoring",
    Network = "Network",
    Secrets = "Secrets",
    WorkloadAnalysis = "WorkloadAnalysis"
}

export enum RiskPolicyConfigurationExclusionDataReason {
    FalsePositive = "FalsePositive",
    ByDesign = "ByDesign",
    Exception = "Exception"
}

export enum RiskPolicyTypeMetadataAnalysisGroupType {
    AllEntities = "AllEntities",
    AwsEksCluster = "AwsEksCluster",
    AwsEntity = "AwsEntity",
    AzureContainerServiceManagedCluster = "AzureContainerServiceManagedCluster",
    GcpContainerCluster = "GcpContainerCluster",
    OpenShiftCluster = "OpenShiftCluster"
}

export enum RiskResolutionReason {
    ActiveGcpTenantEntity = "ActiveGcpTenantEntity",
    ActivePrincipal = "ActivePrincipal",
    DeletedEntity = "DeletedEntity",
    EmptyGroup = "EmptyGroup",
    EntitiesExcluded = "EntitiesExcluded",
    FalsePositive = "FalsePositive",
    InactivePrincipal = "InactivePrincipal",
    LifecycleRuleExistsAwsBucket = "LifecycleRuleExistsAwsBucket",
    Manual = "Manual",
    ManualResolution = "ManualResolution",
    NewMemberGroup = "NewMemberGroup",
    NoMaliciousFiles = "NoMaliciousFiles",
    OperatingSystemUpdated = "OperatingSystemUpdated",
    RecentUpdateAssignmentAwsPermissionSet = "RecentUpdateAssignmentAwsPermissionSet",
    RecentUpdatePermissionActionEntity = "RecentUpdatePermissionActionEntity",
    Resolution = "Resolution",
    RiskPolicyDisabled = "RiskPolicyDisabled",
    UnknownTenantAwsPermissionSet = "UnknownTenantAwsPermissionSet",
    UnusedAccessKeyAwsUser = "UnusedAccessKeyAwsUser",
    VulnerablePackageVersionRemoved = "VulnerablePackageVersionRemoved"
}

export enum RiskSenderSendRiskJiraIssueResult {
    FailureGeneral = "FailureGeneral",
    FailureSetSprint = "FailureSetSprint",
    Success = "Success"
}

export enum RiskSenderSendRiskServiceNowIncidentResult {
    FailureGeneral = "FailureGeneral",
    Success = "Success"
}

export enum RiskSeverityReason {
    AwsAccessPermissionSetRiskAssignmentNotExist = "AwsAccessPermissionSetRiskAssignmentNotExist",
    AwsAccessPermissionSetRiskAssignmentNotExistAndExcessivePermissionAction = "AwsAccessPermissionSetRiskAssignmentNotExistAndExcessivePermissionAction",
    AwsAccessPermissionSetRiskExcessivePermissionAction = "AwsAccessPermissionSetRiskExcessivePermissionAction",
    AwsAccessPermissionSetRiskManyAssignments = "AwsAccessPermissionSetRiskManyAssignments",
    AwsAccessPermissionSetRiskNonexcessivePermissionAction = "AwsAccessPermissionSetRiskNonexcessivePermissionAction",
    AwsAccessPermissionSetRiskNotManyAssignments = "AwsAccessPermissionSetRiskNotManyAssignments",
    AwsAccessRoleRiskAnonymousIdentityWithStatementCondition = "AwsAccessRoleRiskAnonymousIdentityWithStatementCondition",
    AwsAccessRoleRiskAnonymousIdentityWithStatementConditionAndExcessivePermissionAction = "AwsAccessRoleRiskAnonymousIdentityWithStatementConditionAndExcessivePermissionAction",
    AwsAccessRoleRiskAssumeRolePolicyDocumentNonComputeServiceGranteeOnly = "AwsAccessRoleRiskAssumeRolePolicyDocumentNonComputeServiceGranteeOnly",
    AwsAccessRoleRiskExcessivePermissionAction = "AwsAccessRoleRiskExcessivePermissionAction",
    AwsAccessRoleRiskInternal = "AwsAccessRoleRiskInternal",
    AwsAccessRoleRiskPublic = "AwsAccessRoleRiskPublic",
    AwsAccessRoleRiskPublicAndExcessivePermissionAction = "AwsAccessRoleRiskPublicAndExcessivePermissionAction",
    AwsAccessRoleRiskPublicOriginatorResource = "AwsAccessRoleRiskPublicOriginatorResource",
    AwsAccessRoleRiskPublicOriginatorResourceAndExcessivePermissionAction = "AwsAccessRoleRiskPublicOriginatorResourceAndExcessivePermissionAction",
    AwsAccessRoleRiskPublicOriginatorResourceAndVulnerableOriginatorWorkloadResource = "AwsAccessRoleRiskPublicOriginatorResourceAndVulnerableOriginatorWorkloadResource",
    AwsAccessRoleRiskPublicOriginatorResourceAndVulnerableOriginatorWorkloadResourceAndExcessivePermissionAction = "AwsAccessRoleRiskPublicOriginatorResourceAndVulnerableOriginatorWorkloadResourceAndExcessivePermissionAction",
    AwsAccessRoleRiskUnknownTenant = "AwsAccessRoleRiskUnknownTenant",
    AwsAccessRoleRiskUnknownTenantAndExcessivePermissionAction = "AwsAccessRoleRiskUnknownTenantAndExcessivePermissionAction",
    AwsAccessRoleRiskVendor = "AwsAccessRoleRiskVendor",
    AwsAccessRoleRiskVendorAndExcessivePermissionAction = "AwsAccessRoleRiskVendorAndExcessivePermissionAction",
    AwsAccessRoleRiskVulnerableOriginatorWorkloadResource = "AwsAccessRoleRiskVulnerableOriginatorWorkloadResource",
    AwsAccessRoleRiskVulnerableOriginatorWorkloadResourceAndExcessivePermissionAction = "AwsAccessRoleRiskVulnerableOriginatorWorkloadResourceAndExcessivePermissionAction",
    AwsAccessUserRiskAccessKeyEnabled = "AwsAccessUserRiskAccessKeyEnabled",
    AwsAccessUserRiskAccessKeyEnabledAndExcessivePermissionAction = "AwsAccessUserRiskAccessKeyEnabledAndExcessivePermissionAction",
    AwsAccessUserRiskCredentialsNotExist = "AwsAccessUserRiskCredentialsNotExist",
    AwsAccessUserRiskCredentialsNotExistAndExcessivePermissionAction = "AwsAccessUserRiskCredentialsNotExistAndExcessivePermissionAction",
    AwsAccessUserRiskMfaDisabled = "AwsAccessUserRiskMfaDisabled",
    AwsAccessUserRiskMfaDisabledAndAccessKeyEnabled = "AwsAccessUserRiskMfaDisabledAndAccessKeyEnabled",
    AwsAccessUserRiskMfaDisabledAndAccessKeyEnabledAndExcessivePermissionAction = "AwsAccessUserRiskMfaDisabledAndAccessKeyEnabledAndExcessivePermissionAction",
    AwsAccessUserRiskMfaDisabledAndExcessivePermissionAction = "AwsAccessUserRiskMfaDisabledAndExcessivePermissionAction",
    AwsAccessUserRiskMfaEnabled = "AwsAccessUserRiskMfaEnabled",
    AwsAccessUserRiskMfaEnabledAndExcessivePermissionAction = "AwsAccessUserRiskMfaEnabledAndExcessivePermissionAction",
    AwsExcessivePermissionGroupRiskExcessivePermissionAction = "AwsExcessivePermissionGroupRiskExcessivePermissionAction",
    AwsExcessivePermissionGroupRiskUserAccessKeyEnabled = "AwsExcessivePermissionGroupRiskUserAccessKeyEnabled",
    AwsExcessivePermissionGroupRiskUserExists = "AwsExcessivePermissionGroupRiskUserExists",
    AwsExcessivePermissionGroupRiskUserExistsAndExcessivePermissionAction = "AwsExcessivePermissionGroupRiskUserExistsAndExcessivePermissionAction",
    AwsExcessivePermissionGroupRiskUserMfaDisabled = "AwsExcessivePermissionGroupRiskUserMfaDisabled",
    AwsExcessivePermissionGroupRiskUserMfaDisabledAndAccessKeyEnabled = "AwsExcessivePermissionGroupRiskUserMfaDisabledAndAccessKeyEnabled",
    AwsExcessivePermissionGroupRiskUserMfaDisabledAndAccessKeyEnabledAndExcessivePermissionAction = "AwsExcessivePermissionGroupRiskUserMfaDisabledAndAccessKeyEnabledAndExcessivePermissionAction",
    AwsExcessivePermissionGroupRiskUserMfaDisabledAndExcessivePermissionAction = "AwsExcessivePermissionGroupRiskUserMfaDisabledAndExcessivePermissionAction",
    AwsExcessivePermissionGroupRiskUserMfaEnabled = "AwsExcessivePermissionGroupRiskUserMfaEnabled",
    AwsExcessivePermissionGroupRiskUserMfaEnabledAndExcessivePermissionAction = "AwsExcessivePermissionGroupRiskUserMfaEnabledAndExcessivePermissionAction",
    AwsIamRolePublicAccessExistsRiskRolePermissionNotExist = "AwsIamRolePublicAccessExistsRiskRolePermissionNotExist",
    AwsIamRolePublicAccessExistsRiskRolePermissionSeverity = "AwsIamRolePublicAccessExistsRiskRolePermissionSeverity",
    AwsIamRoleVendorAssumeRolePolicyDocumentExternalIdConditionNotExistRiskPermissionSeverity = "AwsIamRoleVendorAssumeRolePolicyDocumentExternalIdConditionNotExistRiskPermissionSeverity",
    AwsIamUserMfaDisabledRiskUserPermissionActionSeverity = "AwsIamUserMfaDisabledRiskUserPermissionActionSeverity",
    AwsIamUserMfaDisabledRiskUserPermissionNotExist = "AwsIamUserMfaDisabledRiskUserPermissionNotExist",
    AwsIamUserUnrotatedAccessKeyRiskUserPermissionActionSeverity = "AwsIamUserUnrotatedAccessKeyRiskUserPermissionActionSeverity",
    AwsIamUserUnrotatedAccessKeyRiskUserPermissionNotExist = "AwsIamUserUnrotatedAccessKeyRiskUserPermissionNotExist",
    AzureComputeVirtualMachineUnmanagedDiskExistsRiskNotRunningNotSensitive = "AzureComputeVirtualMachineUnmanagedDiskExistsRiskNotRunningNotSensitive",
    AzureComputeVirtualMachineUnmanagedDiskExistsRiskRunning = "AzureComputeVirtualMachineUnmanagedDiskExistsRiskRunning",
    AzureComputeVirtualMachineUnmanagedDiskExistsRiskSensitive = "AzureComputeVirtualMachineUnmanagedDiskExistsRiskSensitive",
    AzureInboundExternalComputeVirtualMachineRiskManagementPortNotOpenServicePrincipalNone = "AzureInboundExternalComputeVirtualMachineRiskManagementPortNotOpenServicePrincipalNone",
    AzureInboundExternalComputeVirtualMachineRiskManagementPortNotOpenServicePrincipalPermissionAction = "AzureInboundExternalComputeVirtualMachineRiskManagementPortNotOpenServicePrincipalPermissionAction",
    AzureInboundExternalComputeVirtualMachineRiskManagementPortNotOpenServicePrincipalPermissionActionNone = "AzureInboundExternalComputeVirtualMachineRiskManagementPortNotOpenServicePrincipalPermissionActionNone",
    AzureInboundExternalComputeVirtualMachineRiskManagementPortNotOpenServicePrincipalPermissionActionSevere = "AzureInboundExternalComputeVirtualMachineRiskManagementPortNotOpenServicePrincipalPermissionActionSevere",
    AzureInboundExternalComputeVirtualMachineRiskManagementPortOpenServicePrincipalNone = "AzureInboundExternalComputeVirtualMachineRiskManagementPortOpenServicePrincipalNone",
    AzureInboundExternalComputeVirtualMachineRiskManagementPortOpenServicePrincipalPermissionAction = "AzureInboundExternalComputeVirtualMachineRiskManagementPortOpenServicePrincipalPermissionAction",
    AzureInboundExternalComputeVirtualMachineRiskManagementPortOpenServicePrincipalPermissionActionNone = "AzureInboundExternalComputeVirtualMachineRiskManagementPortOpenServicePrincipalPermissionActionNone",
    AzureInboundExternalComputeVirtualMachineRiskManagementPortOpenServicePrincipalPermissionActionSevere = "AzureInboundExternalComputeVirtualMachineRiskManagementPortOpenServicePrincipalPermissionActionSevere",
    AzureInboundExternalComputeVirtualMachineRiskNotRunning = "AzureInboundExternalComputeVirtualMachineRiskNotRunning",
    AzureInboundExternalComputeVirtualMachineRiskSensitive = "AzureInboundExternalComputeVirtualMachineRiskSensitive",
    AzureInboundExternalComputeVirtualMachineScaleSetRiskManagementPortNotOpenServicePrincipalNone = "AzureInboundExternalComputeVirtualMachineScaleSetRiskManagementPortNotOpenServicePrincipalNone",
    AzureInboundExternalComputeVirtualMachineScaleSetRiskManagementPortNotOpenServicePrincipalPermissionAction = "AzureInboundExternalComputeVirtualMachineScaleSetRiskManagementPortNotOpenServicePrincipalPermissionAction",
    AzureInboundExternalComputeVirtualMachineScaleSetRiskManagementPortNotOpenServicePrincipalPermissionActionNone = "AzureInboundExternalComputeVirtualMachineScaleSetRiskManagementPortNotOpenServicePrincipalPermissionActionNone",
    AzureInboundExternalComputeVirtualMachineScaleSetRiskManagementPortNotOpenServicePrincipalPermissionActionSevere = "AzureInboundExternalComputeVirtualMachineScaleSetRiskManagementPortNotOpenServicePrincipalPermissionActionSevere",
    AzureInboundExternalComputeVirtualMachineScaleSetRiskManagementPortOpenServicePrincipalNone = "AzureInboundExternalComputeVirtualMachineScaleSetRiskManagementPortOpenServicePrincipalNone",
    AzureInboundExternalComputeVirtualMachineScaleSetRiskManagementPortOpenServicePrincipalPermissionAction = "AzureInboundExternalComputeVirtualMachineScaleSetRiskManagementPortOpenServicePrincipalPermissionAction",
    AzureInboundExternalComputeVirtualMachineScaleSetRiskManagementPortOpenServicePrincipalPermissionActionNone = "AzureInboundExternalComputeVirtualMachineScaleSetRiskManagementPortOpenServicePrincipalPermissionActionNone",
    AzureInboundExternalComputeVirtualMachineScaleSetRiskManagementPortOpenServicePrincipalPermissionActionSevere = "AzureInboundExternalComputeVirtualMachineScaleSetRiskManagementPortOpenServicePrincipalPermissionActionSevere",
    AzureInboundExternalComputeVirtualMachineScaleSetRiskNotRunning = "AzureInboundExternalComputeVirtualMachineScaleSetRiskNotRunning",
    AzureInboundExternalComputeVirtualMachineScaleSetRiskSensitive = "AzureInboundExternalComputeVirtualMachineScaleSetRiskSensitive",
    GcpExcessivePermissionServiceAccountRiskExcessivePermissionActionSeverity = "GcpExcessivePermissionServiceAccountRiskExcessivePermissionActionSeverity",
    GcpExcessivePermissionServiceAccountRiskExcessivePermissionActionSeverityExternal = "GcpExcessivePermissionServiceAccountRiskExcessivePermissionActionSeverityExternal",
    GcpExcessivePermissionServiceAccountRiskExcessivePermissionActionSeverityOriginatorPublicAccessExists = "GcpExcessivePermissionServiceAccountRiskExcessivePermissionActionSeverityOriginatorPublicAccessExists",
    GcpExcessivePermissionServiceAccountRiskServiceAccountDisabled = "GcpExcessivePermissionServiceAccountRiskServiceAccountDisabled",
    GcpExcessivePermissionUserRiskExcessivePermissionActionSeverity = "GcpExcessivePermissionUserRiskExcessivePermissionActionSeverity",
    GcpExcessivePermissionUserRiskExcessivePermissionActionSeverityExternal = "GcpExcessivePermissionUserRiskExcessivePermissionActionSeverityExternal",
    GcpExcessivePermissionUserRiskUserDisabled = "GcpExcessivePermissionUserRiskUserDisabled",
    GcpPrincipalServiceAccountWideScopeAdministratorRoleRiskGroupRoleBindingPermissionSeverity = "GcpPrincipalServiceAccountWideScopeAdministratorRoleRiskGroupRoleBindingPermissionSeverity",
    GcpPrincipalServiceAccountWideScopeAdministratorRoleRiskPartialServiceAccount = "GcpPrincipalServiceAccountWideScopeAdministratorRoleRiskPartialServiceAccount",
    GcpPrincipalServiceAccountWideScopeAdministratorRoleRiskServiceAccountDisabled = "GcpPrincipalServiceAccountWideScopeAdministratorRoleRiskServiceAccountDisabled",
    GcpPrincipalServiceAccountWideScopeAdministratorRoleRiskServiceAccountRoleBindingPermissionSeverity = "GcpPrincipalServiceAccountWideScopeAdministratorRoleRiskServiceAccountRoleBindingPermissionSeverity",
    GcpPrincipalServiceAccountWideScopeAdministratorRoleRiskTenantEntitySensitive = "GcpPrincipalServiceAccountWideScopeAdministratorRoleRiskTenantEntitySensitive",
    GcpPrincipalServiceAccountWideScopeImpersonateServiceAccountActionExistsRiskGroupServiceAccountPermissionSeverity = "GcpPrincipalServiceAccountWideScopeImpersonateServiceAccountActionExistsRiskGroupServiceAccountPermissionSeverity",
    GcpPrincipalServiceAccountWideScopeImpersonateServiceAccountActionExistsRiskPartialServiceAccount = "GcpPrincipalServiceAccountWideScopeImpersonateServiceAccountActionExistsRiskPartialServiceAccount",
    GcpPrincipalServiceAccountWideScopeImpersonateServiceAccountActionExistsRiskServiceAccountDisabled = "GcpPrincipalServiceAccountWideScopeImpersonateServiceAccountActionExistsRiskServiceAccountDisabled",
    GcpPrincipalServiceAccountWideScopeImpersonateServiceAccountActionExistsRiskServiceAccountServiceAccountPermissionSeverity = "GcpPrincipalServiceAccountWideScopeImpersonateServiceAccountActionExistsRiskServiceAccountServiceAccountPermissionSeverity",
    GcpPrincipalServiceAccountWideScopeImpersonateServiceAccountActionExistsRiskTenantEntitySensitive = "GcpPrincipalServiceAccountWideScopeImpersonateServiceAccountActionExistsRiskTenantEntitySensitive",
    GcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsRiskGciTenantEntityServiceAccountPermissionSeverity = "GcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsRiskGciTenantEntityServiceAccountPermissionSeverity",
    GcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsRiskGroupServiceAccountPermissionSeverity = "GcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsRiskGroupServiceAccountPermissionSeverity",
    GcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsRiskPartialGciTenantEntity = "GcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsRiskPartialGciTenantEntity",
    GcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsRiskPartialUser = "GcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsRiskPartialUser",
    GcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsRiskTenantEntitySensitive = "GcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsRiskTenantEntitySensitive",
    GcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsRiskUserDisabled = "GcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsRiskUserDisabled",
    GcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsRiskUserServiceAccountPermissionSeverity = "GcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsRiskUserServiceAccountPermissionSeverity"
}

export enum RiskStatus {
    Closed = "Closed",
    Ignored = "Ignored",
    Open = "Open"
}

export enum RiskSubStatus {
    ClosedFalsePositive = "ClosedFalsePositive",
    ClosedFixed = "ClosedFixed",
    ClosedManually = "ClosedManually",
    ClosedResourceDeleted = "ClosedResourceDeleted",
    ClosedSelfResolved = "ClosedSelfResolved",
    IgnoredByDesign = "IgnoredByDesign",
    IgnoredException = "IgnoredException",
    IgnoredFalsePositive = "IgnoredFalsePositive",
    OpenAnomaly = "OpenAnomaly",
    OpenExistingResource = "OpenExistingResource",
    OpenNewResource = "OpenNewResource",
    OpenNewRisk = "OpenNewRisk",
    OpenReopened = "OpenReopened",
    OpenUnignored = "OpenUnignored"
}

export enum RisksView {
    Closed = "Closed",
    Ignored = "Ignored",
    Open = "Open"
}

export enum ScopeSettingAction {
    Delete = "Delete",
    Get = "Get",
    Upsert = "Upsert"
}

export enum ScopeType {
    CiTenant = "CiTenant",
    CloudProviderTenant = "CloudProviderTenant",
    CodeTenant = "CodeTenant",
    Customer = "Customer",
    Folder = "Folder",
    IdentityProviderTenant = "IdentityProviderTenant",
    Project = "Project",
    ProjectFolder = "ProjectFolder"
}

export enum ServiceNowIncidentImpact {
    High = "High",
    Medium = "Medium",
    Low = "Low"
}

export enum ServiceNowIncidentUrgency {
    High = "High",
    Medium = "Medium",
    Low = "Low"
}

export enum ServiceNowInstanceStateIssue {
    InvalidUrl = "InvalidUrl",
    MissingPermissions = "MissingPermissions",
    UserNotAuthenticated = "UserNotAuthenticated"
}

export enum Severity {
    Information = "Information",
    Low = "Low",
    Medium = "Medium",
    High = "High",
    Critical = "Critical"
}

export enum SlackWorkspaceStateIssue {
    AuthenticationFailure = "AuthenticationFailure",
    MandatoryPermissionsNotExist = "MandatoryPermissionsNotExist",
    OptionalPermissionsNotExist = "OptionalPermissionsNotExist"
}

export enum SortDirection {
    Ascending = "Ascending",
    Descending = "Descending"
}

export enum SplunkEventCollectorStateIssue {
    ConnectionRefused = "ConnectionRefused",
    InvalidCertificate = "InvalidCertificate",
    InvalidToken = "InvalidToken",
    InvalidUrl = "InvalidUrl",
    ServiceUnavailable = "ServiceUnavailable",
    Timeout = "Timeout"
}

export enum SqsQueueStateIssue {
    AssumeRoleFailed = "AssumeRoleFailed",
    InvalidUrl = "InvalidUrl",
    MissingKmsPermissions = "MissingKmsPermissions",
    MissingQueuePermissions = "MissingQueuePermissions",
    MissingRole = "MissingRole"
}

export enum StatusCode {
    OK = "OK",
    Cancelled = "Cancelled",
    Unknown = "Unknown",
    InvalidArgument = "InvalidArgument",
    DeadlineExceeded = "DeadlineExceeded",
    NotFound = "NotFound",
    AlreadyExists = "AlreadyExists",
    PermissionDenied = "PermissionDenied",
    ResourceExhausted = "ResourceExhausted",
    FailedPrecondition = "FailedPrecondition",
    Aborted = "Aborted",
    OutOfRange = "OutOfRange",
    Unimplemented = "Unimplemented",
    Internal = "Internal",
    Unavailable = "Unavailable",
    DataLoss = "DataLoss",
    Unauthenticated = "Unauthenticated"
}

export enum SystemConsoleAppView {
    ExportActivatedPermissionAssignmentRequestMail = "ExportActivatedPermissionAssignmentRequestMail",
    ExportNewUserMail = "ExportNewUserMail",
    ExportPendingApprovalPermissionAssignmentRequestMail = "ExportPendingApprovalPermissionAssignmentRequestMail",
    ExportRejectedPermissionAssignmentRequestMail = "ExportRejectedPermissionAssignmentRequestMail",
    ExportReport = "ExportReport",
    ExportReportMail = "ExportReportMail",
    ExportRisksMail = "ExportRisksMail"
}

export enum SystemKubernetesClusterApiConnectivityStatus {
    Success = "Success",
    Pending = "Pending",
    ErrorClusterNotRunning = "ErrorClusterNotRunning",
    ErrorTunnelSessionNotConnected = "ErrorTunnelSessionNotConnected",
    ErrorTunnelSessionUnstable = "ErrorTunnelSessionUnstable",
    ErrorTunnelSessionNotExist = "ErrorTunnelSessionNotExist",
    ErrorApplicationIpAddressNotConfigured = "ErrorApplicationIpAddressNotConfigured",
    ErrorAzureAuthenticationIdentityDisabled = "ErrorAzureAuthenticationIdentityDisabled",
    ErrorAzureAuthenticationCustomRoleNotConfigured = "ErrorAzureAuthenticationCustomRoleNotConfigured",
    ErrorAuthenticationFailure = "ErrorAuthenticationFailure",
    ErrorConnectionFailure = "ErrorConnectionFailure"
}

export enum SystemKubernetesClusterModelHelmComponentStatus {
    Running = "Running",
    NotInstalled = "NotInstalled",
    NotRunning = "NotRunning"
}

export enum TeamsConsoleAppView {
    ApproverUserPermissionRequests = "ApproverUserPermissionRequests",
    GranteeUserPermissionRequests = "GranteeUserPermissionRequests",
    SignInError = "SignInError"
}

export enum TeamsOrganizationStateIssue {
    AadTenantNotExist = "AadTenantNotExist",
    ApplicationUnauthorized = "ApplicationUnauthorized"
}

export enum TenantControllerInsertOneLoginTenantError {
    AuthenticationFailed = "AuthenticationFailed",
    MissingPermissions = "MissingPermissions",
    PermissionManagementAccountMismatch = "PermissionManagementAccountMismatch",
    PermissionManagementAuthenticationFailed = "PermissionManagementAuthenticationFailed",
    TenantExists = "TenantExists"
}

export enum TenantControllerInsertPingIdentityTenantError {
    AuthenticationFailed = "AuthenticationFailed",
    MissingPermissions = "MissingPermissions",
    TenantExists = "TenantExists"
}

export enum TenantControllerUpdateOneLoginTenantError {
    AuthenticationFailed = "AuthenticationFailed",
    MissingPermissions = "MissingPermissions",
    PermissionManagementAccountMismatch = "PermissionManagementAccountMismatch",
    PermissionManagementAuthenticationFailed = "PermissionManagementAuthenticationFailed",
    TenantMismatch = "TenantMismatch"
}

export enum TenantControllerUpdatePingIdentityTenantError {
    AuthenticationFailed = "AuthenticationFailed",
    MissingPermissions = "MissingPermissions"
}

export enum TenantControllerValidateGciTenantResult {
    ApplicationServiceAccountUnauthorized = "ApplicationServiceAccountUnauthorized",
    ServiceAccountNotExist = "ServiceAccountNotExist",
    ServiceAccountTenantExternalIdTagMismatch = "ServiceAccountTenantExternalIdTagMismatch",
    ServiceAccountTenantUnauthorized = "ServiceAccountTenantUnauthorized",
    ServiceAccountUnauthorized = "ServiceAccountUnauthorized",
    ServiceAccountUserDelegationAuthorizationScopesMismatch = "ServiceAccountUserDelegationAuthorizationScopesMismatch",
    UserNotExist = "UserNotExist",
    UserTenantMismatch = "UserTenantMismatch",
    UserUnauthorized = "UserUnauthorized",
    Valid = "Valid"
}

export enum TenantControllerValidateOneLoginTenantResult {
    AuthenticationFailed = "AuthenticationFailed",
    MissingPermissions = "MissingPermissions",
    Valid = "Valid"
}

export enum TenantType {
    Aad = "Aad",
    Aws = "Aws",
    Azure = "Azure",
    Ci = "Ci",
    Code = "Code",
    Gci = "Gci",
    Gcp = "Gcp",
    Oci = "Oci",
    Okta = "Okta",
    OneLogin = "OneLogin",
    Op = "Op",
    PingIdentity = "PingIdentity",
    Unknown = "Unknown"
}

export enum TicketingServiceType {
    Jira = "Jira",
    ServiceNow = "ServiceNow"
}

export enum TiFileContentAnalysisResultCategory {
    ConfirmedTimeout = "ConfirmedTimeout",
    Failure = "Failure",
    Harmless = "Harmless",
    Malicious = "Malicious",
    Suspicious = "Suspicious",
    Timeout = "Timeout",
    TypeUnsupported = "TypeUnsupported",
    Undetected = "Undetected"
}

export enum TimeFrame {
    Long = "Long",
    Medium = "Medium",
    Short = "Short"
}

export enum TimeRangeSelectionRelativeUnit {
    Days = "Days",
    Months = "Months",
    Weeks = "Weeks"
}

export enum TimeRangeSelectionType {
    DateAfter = "DateAfter",
    DateBefore = "DateBefore",
    DateBetween = "DateBetween",
    Empty = "Empty",
    RelativeAfterTheNext = "RelativeAfterTheNext",
    RelativeBeforeTheLast = "RelativeBeforeTheLast",
    RelativeInTheLast = "RelativeInTheLast",
    RelativeInTheNext = "RelativeInTheNext"
}

export enum UdmObjectPropertyDataType {
    CommonActivity = "CommonActivity",
    CommonEntityAttributes = "CommonEntityAttributes",
    CommonEntityTags = "CommonEntityTags",
    CommonId = "CommonId",
    CommonPermissionCategories = "CommonPermissionCategories",
    CommonSelfId = "CommonSelfId",
    CommonSeverity = "CommonSeverity",
    CommonTenantType = "CommonTenantType",
    CommonVendor = "CommonVendor",
    CommonVulnerabilities = "CommonVulnerabilities",
    InfraBoolean = "InfraBoolean",
    InfraByteSize = "InfraByteSize",
    InfraDate = "InfraDate",
    InfraDateTime = "InfraDateTime",
    InfraEnum = "InfraEnum",
    InfraInteger = "InfraInteger",
    InfraIpAddress = "InfraIpAddress",
    InfraPortRange = "InfraPortRange",
    InfraString = "InfraString",
    InfraTimeSpan = "InfraTimeSpan",
    InfraUrl = "InfraUrl"
}

export enum UdmObjectPropertyId {
    AwsAccessResourceAccessLevel = "AwsAccessResourceAccessLevel",
    AwsAutoScalingGroupEc2Images = "AwsAutoScalingGroupEc2Images",
    AwsDynamoDbTableSize = "AwsDynamoDbTableSize",
    AwsEc2ImageAutoScalingGroupIds = "AwsEc2ImageAutoScalingGroupIds",
    AwsEc2ImageInstances = "AwsEc2ImageInstances",
    AwsEc2ImagePlatform = "AwsEc2ImagePlatform",
    AwsEc2InstanceAmiName = "AwsEc2InstanceAmiName",
    AwsEc2InstanceAmiRawId = "AwsEc2InstanceAmiRawId",
    AwsEc2InstanceAvailabilityZone = "AwsEc2InstanceAvailabilityZone",
    AwsEc2InstanceCpuCores = "AwsEc2InstanceCpuCores",
    AwsEc2InstanceImageId = "AwsEc2InstanceImageId",
    AwsEc2InstanceInstanceType = "AwsEc2InstanceInstanceType",
    AwsEc2InstanceKeyPair = "AwsEc2InstanceKeyPair",
    AwsEc2InstanceLaunchTime = "AwsEc2InstanceLaunchTime",
    AwsEc2InstanceMetadataVersion = "AwsEc2InstanceMetadataVersion",
    AwsEc2InstanceOperatingSystemType = "AwsEc2InstanceOperatingSystemType",
    AwsEc2InstanceOperatingSystemVersion = "AwsEc2InstanceOperatingSystemVersion",
    AwsEc2InstancePlatform = "AwsEc2InstancePlatform",
    AwsEc2InstancePublicIpAddress = "AwsEc2InstancePublicIpAddress",
    AwsEc2InstancePublicIPv4Dns = "AwsEc2InstancePublicIPv4Dns",
    AwsEc2InstanceState = "AwsEc2InstanceState",
    AwsEc2InstanceUserData = "AwsEc2InstanceUserData",
    AwsEc2SecurityGroupNetworkedResources = "AwsEc2SecurityGroupNetworkedResources",
    AwsEc2SubnetNetworkedResources = "AwsEc2SubnetNetworkedResources",
    AwsEc2VpcNetworkedResources = "AwsEc2VpcNetworkedResources",
    AwsEncryptedResourceKmsKey = "AwsEncryptedResourceKmsKey",
    AwsIamIdentityActivityTime = "AwsIamIdentityActivityTime",
    AwsIamIdentityPermissionBoundaryPolicyId = "AwsIamIdentityPermissionBoundaryPolicyId",
    AwsIamInlinePolicyPrincipalIds = "AwsIamInlinePolicyPrincipalIds",
    AwsIamManagedPolicyAwsManaged = "AwsIamManagedPolicyAwsManaged",
    AwsIamManagedPolicyPermissionBoundaryIdentityIds = "AwsIamManagedPolicyPermissionBoundaryIdentityIds",
    AwsIamManagedPolicyPermissionServiceIds = "AwsIamManagedPolicyPermissionServiceIds",
    AwsIamManagedPolicyPrincipalIds = "AwsIamManagedPolicyPrincipalIds",
    AwsIamManagedPolicyUpdateTime = "AwsIamManagedPolicyUpdateTime",
    AwsIamPrincipalInlinePolicyIds = "AwsIamPrincipalInlinePolicyIds",
    AwsIamPrincipalManagedPolicyIds = "AwsIamPrincipalManagedPolicyIds",
    AwsIamPrincipalUniqueId = "AwsIamPrincipalUniqueId",
    AwsKmsKeyEncryptedResources = "AwsKmsKeyEncryptedResources",
    AwsKmsKeyRotationTimeFrame = "AwsKmsKeyRotationTimeFrame",
    AwsNetworkedResourceSecurityGroupIds = "AwsNetworkedResourceSecurityGroupIds",
    AwsNetworkedResourceSubnetIds = "AwsNetworkedResourceSubnetIds",
    AwsNetworkedResourceVpcIds = "AwsNetworkedResourceVpcIds",
    AwsResourceArn = "AwsResourceArn",
    AwsServiceManagedPolicyIds = "AwsServiceManagedPolicyIds",
    AzureComputeVirtualMachineAvailabilityZone = "AzureComputeVirtualMachineAvailabilityZone",
    AzureComputeVirtualMachineState = "AzureComputeVirtualMachineState",
    AzureEntityPath = "AzureEntityPath",
    EntityAttributes = "EntityAttributes",
    EntityConsoleUrl = "EntityConsoleUrl",
    EntityCreationTime = "EntityCreationTime",
    EntityName = "EntityName",
    EntityNetworkAccessScope = "EntityNetworkAccessScope",
    EntityNetworkAccessType = "EntityNetworkAccessType",
    EntityRegion = "EntityRegion",
    EntityRisks = "EntityRisks",
    EntitySyncTime = "EntitySyncTime",
    EntityTags = "EntityTags",
    Id = "Id",
    RiskResolutionReason = "RiskResolutionReason",
    RiskRiskedEntityIds = "RiskRiskedEntityIds",
    RiskSeverity = "RiskSeverity",
    RiskStatus = "RiskStatus",
    RiskStatusUpdateTime = "RiskStatusUpdateTime",
    RiskSubStatus = "RiskSubStatus",
    TenantType = "TenantType",
    VendorServiceIdentityVendor = "VendorServiceIdentityVendor",
    VendorServiceIdentityVendors = "VendorServiceIdentityVendors",
    VirtualMachineVulnerabilities = "VirtualMachineVulnerabilities"
}

export enum UdmObjectType {
    Entity = "Entity",
    Risk = "Risk"
}

export enum UdmQueryRuleGroupOperator {
    And = "And",
    Or = "Or"
}

export enum UdmQueryRuleOperator {
    Between = "Between",
    Equals = "Equals",
    Gt = "Gt",
    Gte = "Gte",
    In = "In",
    Like = "Like",
    Lt = "Lt",
    Lte = "Lte",
    StringContains = "StringContains",
    StringEndsWith = "StringEndsWith",
    StringStartsWith = "StringStartsWith"
}

export enum UserConsoleAppView {
    ApproverUserPermissionRequests = "ApproverUserPermissionRequests",
    AuditEvents = "AuditEvents",
    Customers = "Customers",
    GranteeUserPermissionRequests = "GranteeUserPermissionRequests",
    PermissionEligibilities = "PermissionEligibilities",
    SignIn = "SignIn",
    SignInError = "SignInError"
}

export enum Vendor {
    AddEvent = "AddEvent",
    Adobe = "Adobe",
    Akamai = "Akamai",
    Akira = "Akira",
    Alignable = "Alignable",
    Allantis = "Allantis",
    Amazon = "Amazon",
    Amplitude = "Amplitude",
    Anadot = "Anadot",
    Anecdotes = "Anecdotes",
    Anodot = "Anodot",
    Appfluence = "Appfluence",
    Apple = "Apple",
    ApprovedContact = "ApprovedContact",
    AquaMail = "AquaMail",
    AquaSecurity = "AquaSecurity",
    Asana = "Asana",
    Atlassian = "Atlassian",
    Autodesk = "Autodesk",
    AvePoint = "AvePoint",
    Betterview = "Betterview",
    BeyondTrust = "BeyondTrust",
    BlueMail = "BlueMail",
    Boomerang = "Boomerang",
    Brightcove = "Brightcove",
    BrotherIndustries = "BrotherIndustries",
    Calendly = "Calendly",
    Canon = "Canon",
    Canva = "Canva",
    CheckPoint = "CheckPoint",
    Cisco = "Cisco",
    Citrix = "Citrix",
    CloudChekr = "CloudChekr",
    CloudSaver = "CloudSaver",
    CloudSponge = "CloudSponge",
    CloudWize = "CloudWize",
    CodeSpark = "CodeSpark",
    CodeTwo = "CodeTwo",
    Continuity = "Continuity",
    CoreStack = "CoreStack",
    Cronofy = "Cronofy",
    CrowdStrike = "CrowdStrike",
    CyberArk = "CyberArk",
    Cyderes = "Cyderes",
    Databricks = "Databricks",
    Datadog = "Datadog",
    Decisions = "Decisions",
    Deepwatch = "Deepwatch",
    Devek = "Devek",
    Dialpad = "Dialpad",
    Docusign = "Docusign",
    DoiT = "DoiT",
    Doodle = "Doodle",
    Dropbox = "Dropbox",
    Druva = "Druva",
    Dynatrace = "Dynatrace",
    Edison = "Edison",
    EdX = "EdX",
    ElasticNV = "ElasticNV",
    Eleven41Software = "Eleven41Software",
    Epiphani = "Epiphani",
    Epsagon = "Epsagon",
    Expel = "Expel",
    F5 = "F5",
    Finout = "Finout",
    FireEye = "FireEye",
    Fireflies = "Fireflies",
    Fivetran = "Fivetran",
    Flexibits = "Flexibits",
    Freshworks = "Freshworks",
    Garmin = "Garmin",
    GenDigital = "GenDigital",
    GingerLabs = "GingerLabs",
    Globus = "Globus",
    Gong = "Gong",
    GoodNotes = "GoodNotes",
    Google = "Google",
    Grafana = "Grafana",
    HP = "HP",
    HubSpot = "HubSpot",
    Hunters = "Hunters",
    IBM = "IBM",
    Imply = "Imply",
    Infinipoint = "Infinipoint",
    Instaclustr = "Instaclustr",
    IntsigInformation = "IntsigInformation",
    JGraph = "JGraph",
    Jotform = "Jotform",
    JupiterOne = "JupiterOne",
    Kahoot = "Kahoot",
    Kentik = "Kentik",
    Kochava = "Kochava",
    Lacework = "Lacework",
    Lansweeper = "Lansweeper",
    LinkedIn = "LinkedIn",
    LogzIo = "LogzIo",
    Lucid = "Lucid",
    Lucidchart = "Lucidchart",
    Lumen = "Lumen",
    Meister = "Meister",
    MicrochipTechnology = "MicrochipTechnology",
    Microsoft = "Microsoft",
    Mindjet = "Mindjet",
    Miro = "Miro",
    Monday = "Monday",
    MongoDB = "MongoDB",
    MozillaFoundation = "MozillaFoundation",
    MSP360 = "MSP360",
    Mural = "Mural",
    NetApp = "NetApp",
    NewRelic = "NewRelic",
    NineFolders = "NineFolders",
    NOps = "NOps",
    Notion = "Notion",
    OneCalendar = "OneCalendar",
    OneIdentity = "OneIdentity",
    OneSync = "OneSync",
    OpenAI = "OpenAI",
    Orca = "Orca",
    Otter = "Otter",
    OxSecurity = "OxSecurity",
    Padlet = "Padlet",
    PaloAlto = "PaloAlto",
    Pearson = "Pearson",
    Polly = "Polly",
    Prezi = "Prezi",
    Qualys = "Qualys",
    Qubole = "Qubole",
    Quest = "Quest",
    Quizizz = "Quizizz",
    Rackspace = "Rackspace",
    Radware = "Radware",
    Rapid7 = "Rapid7",
    Rclone = "Rclone",
    Redis = "Redis",
    RedLine13 = "RedLine13",
    ReMarkable = "ReMarkable",
    RenaissanceLearning = "RenaissanceLearning",
    RiverMeadow = "RiverMeadow",
    Rocketbook = "Rocketbook",
    Rubrik = "Rubrik",
    Salesforce = "Salesforce",
    Samsung = "Samsung",
    Schoology = "Schoology",
    SendGrid = "SendGrid",
    Sentra = "Sentra",
    Shopify = "Shopify",
    Skillable = "Skillable",
    Smartsheet = "Smartsheet",
    Snowflake = "Snowflake",
    Snyk = "Snyk",
    Solvo = "Solvo",
    Sonarcloud = "Sonarcloud",
    Sonrai = "Sonrai",
    Sophos = "Sophos",
    Splunk = "Splunk",
    SumoLogic = "SumoLogic",
    SurveyMonkey = "SurveyMonkey",
    Sysdig = "Sysdig",
    Tableau = "Tableau",
    Talend = "Talend",
    TasksInABox = "TasksInABox",
    Tenable = "Tenable",
    Teraproc = "Teraproc",
    ThomaBravo = "ThomaBravo",
    TrendCloudOne = "TrendCloudOne",
    TrendMicro = "TrendMicro",
    Tufin = "Tufin",
    Turbot = "Turbot",
    Twilio = "Twilio",
    Typeform = "Typeform",
    VaronisSystems = "VaronisSystems",
    Virtru = "Virtru",
    Vista = "Vista",
    Vmware = "Vmware",
    WillisTowersWatson = "WillisTowersWatson",
    Wistia = "Wistia",
    Witivio = "Witivio",
    Wiz = "Wiz",
    Workato = "Workato",
    Workleap = "Workleap",
    Wrike = "Wrike",
    XMCyber = "XMCyber",
    Yahoo = "Yahoo",
    Zapier = "Zapier",
    Zendesk = "Zendesk",
    Zesty = "Zesty",
    Zoho = "Zoho",
    Zoom = "Zoom",
    Zoominfo = "Zoominfo"
}

export enum VirtualMachineImageAccessLevel {
    Public = "Public",
    CrossTenant = "CrossTenant",
    Internal = "Internal"
}

export enum VirtualMachineImageManagementType {
    Cloud = "Cloud",
    Customer = "Customer"
}

export enum VulnerabilityAge {
    Between0And7Days = "Between0And7Days",
    Between7And30Days = "Between7And30Days",
    Between30And60Days = "Between30And60Days",
    Between60And180Days = "Between60And180Days",
    Between180And365Days = "Between180And365Days",
    Between365And730Days = "Between365And730Days",
    MoreThan730Days = "MoreThan730Days",
    NotYetPublished = "NotYetPublished"
}

export enum VulnerabilityAttackComplexity {
    High = "High",
    Low = "Low"
}

export enum VulnerabilityAttackVector {
    Physical = "Physical",
    Local = "Local",
    Adjacent = "Adjacent",
    Network = "Network"
}

export enum VulnerabilityExploitMaturity {
    Functional = "Functional",
    High = "High",
    Poc = "Poc",
    Unproven = "Unproven"
}

export enum VulnerabilityProductCoverage {
    High = "High",
    Low = "Low",
    Medium = "Medium",
    Unknown = "Unknown",
    VeryHigh = "VeryHigh"
}

export enum VulnerabilityReferenceAuthority {
    Nvd = "Nvd",
    TenableResearch = "TenableResearch"
}

export enum VulnerabilityReferenceTag {
    AptGroup = "AptGroup",
    CategorizationCloud = "CategorizationCloud",
    CategorizationIdentity = "CategorizationIdentity",
    CategorizationOt = "CategorizationOt",
    Exploit = "Exploit",
    ExploitChain = "ExploitChain",
    ExploitDisclosed = "ExploitDisclosed",
    ExploitedInTheWild = "ExploitedInTheWild",
    ExploitFunctional = "ExploitFunctional",
    ExploitHigh = "ExploitHigh",
    ExploitPoc = "ExploitPoc",
    InitialCompromiseVector = "InitialCompromiseVector",
    MalwareName = "MalwareName",
    NamedVulnerability = "NamedVulnerability",
    PersistentlyExploited = "PersistentlyExploited",
    PostCompromiseVector = "PostCompromiseVector",
    RansomwareGroup = "RansomwareGroup",
    VulnerabilityBeingMonitored = "VulnerabilityBeingMonitored",
    VulnerabilityOfConcern = "VulnerabilityOfConcern",
    VulnerabilityOfInterest = "VulnerabilityOfInterest",
    VulnerabilityTypeArbitraryFileRead = "VulnerabilityTypeArbitraryFileRead",
    VulnerabilityTypeArbitraryFileWrite = "VulnerabilityTypeArbitraryFileWrite",
    VulnerabilityTypeAuthenticationBypass = "VulnerabilityTypeAuthenticationBypass",
    VulnerabilityTypeBufferOverflow = "VulnerabilityTypeBufferOverflow",
    VulnerabilityTypeCodeInjection = "VulnerabilityTypeCodeInjection",
    VulnerabilityTypeCommandExecution = "VulnerabilityTypeCommandExecution",
    VulnerabilityTypeCommandInjection = "VulnerabilityTypeCommandInjection",
    VulnerabilityTypeCrossSiteRequestForgery = "VulnerabilityTypeCrossSiteRequestForgery",
    VulnerabilityTypeCrossSiteScripting = "VulnerabilityTypeCrossSiteScripting",
    VulnerabilityTypeDenialOfService = "VulnerabilityTypeDenialOfService",
    VulnerabilityTypeDeserializationOfUntrustedData = "VulnerabilityTypeDeserializationOfUntrustedData",
    VulnerabilityTypeDirectoryTraversal = "VulnerabilityTypeDirectoryTraversal",
    VulnerabilityTypeHardcodedCredentials = "VulnerabilityTypeHardcodedCredentials",
    VulnerabilityTypeHeapBasedBufferOverflow = "VulnerabilityTypeHeapBasedBufferOverflow",
    VulnerabilityTypeImproperAuthentication = "VulnerabilityTypeImproperAuthentication",
    VulnerabilityTypeImproperInputValidation = "VulnerabilityTypeImproperInputValidation",
    VulnerabilityTypeInformationDisclosure = "VulnerabilityTypeInformationDisclosure",
    VulnerabilityTypeMissingAuthentication = "VulnerabilityTypeMissingAuthentication",
    VulnerabilityTypeOther = "VulnerabilityTypeOther",
    VulnerabilityTypeOutOfBoundsRead = "VulnerabilityTypeOutOfBoundsRead",
    VulnerabilityTypeOutOfBoundsWrite = "VulnerabilityTypeOutOfBoundsWrite",
    VulnerabilityTypePrivilegeEscalation = "VulnerabilityTypePrivilegeEscalation",
    VulnerabilityTypeRaceCondition = "VulnerabilityTypeRaceCondition",
    VulnerabilityTypeRemoteCodeExecution = "VulnerabilityTypeRemoteCodeExecution",
    VulnerabilityTypeSecurityFeatureBypass = "VulnerabilityTypeSecurityFeatureBypass",
    VulnerabilityTypeServerSideRequestForgery = "VulnerabilityTypeServerSideRequestForgery",
    VulnerabilityTypeSqlInjection = "VulnerabilityTypeSqlInjection",
    VulnerabilityTypeUnrestrictedUploadOfFile = "VulnerabilityTypeUnrestrictedUploadOfFile",
    VulnerabilityTypeUseAfterFree = "VulnerabilityTypeUseAfterFree",
    VulnerabilityTypeXmlExternalEntity = "VulnerabilityTypeXmlExternalEntity",
    WeaponizationApt = "WeaponizationApt",
    WeaponizationMalware = "WeaponizationMalware",
    WeaponizationOther = "WeaponizationOther",
    WeaponizationRansomware = "WeaponizationRansomware",
    Wormable = "Wormable",
    ZeroDay = "ZeroDay"
}

export enum VulnerabilityReportType {
    ContainerImages = "ContainerImages",
    VirtualMachines = "VirtualMachines"
}

export enum VulnerabilityScoreType {
    Cvss = "Cvss",
    Vpr = "Vpr"
}

export enum VulnerabilitySecurityWatchType {
    VulnerabilityBeingMonitored = "VulnerabilityBeingMonitored",
    VulnerabilityOfConcern = "VulnerabilityOfConcern",
    VulnerabilityOfInterest = "VulnerabilityOfInterest"
}

export enum VulnerabilitySourceType {
    Alma = "Alma",
    Alpine = "Alpine",
    Amazon = "Amazon",
    Arch = "Arch",
    Aws = "Aws",
    Azure = "Azure",
    Bitnami = "Bitnami",
    CblMariner = "CblMariner",
    CentOs = "CentOs",
    Debian = "Debian",
    Fedora = "Fedora",
    Gcp = "Gcp",
    GitHubSecurityAdvisories = "GitHubSecurityAdvisories",
    GitLabAdvisoryDatabase = "GitLabAdvisoryDatabase",
    GoVulnerabilityDatabase = "GoVulnerabilityDatabase",
    Kubernetes = "Kubernetes",
    NodejsSecurityWorkgroup = "NodejsSecurityWorkgroup",
    Nvd = "Nvd",
    Oracle = "Oracle",
    Osv = "Osv",
    Photon = "Photon",
    PhpSecurityAdvisories = "PhpSecurityAdvisories",
    RedHat = "RedHat",
    Rocky = "Rocky",
    RubySec = "RubySec",
    Suse = "Suse",
    Tenable = "Tenable",
    Ubuntu = "Ubuntu",
    Windows = "Windows"
}

export enum VulnerabilityThreatIntensityLast28Days {
    High = "High",
    Low = "Low",
    Medium = "Medium",
    VeryHigh = "VeryHigh",
    VeryLow = "VeryLow"
}

export enum VulnerabilityThreatRecency {
    Between0And7Days = "Between0And7Days",
    Between7And30Days = "Between7And30Days",
    Between30And120Days = "Between30And120Days",
    Between120And365Days = "Between120And365Days",
    MoreThan365Days = "MoreThan365Days",
    NoRecordedEvents = "NoRecordedEvents"
}

export enum VulnerabilityThreatSourcesLast28Days {
    CodeRepoAndPasteBins = "CodeRepoAndPasteBins",
    DarkWebAndUnderground = "DarkWebAndUnderground",
    HackerForum = "HackerForum",
    MainstreamMedia = "MainstreamMedia",
    NoRecordedEvents = "NoRecordedEvents",
    Others = "Others",
    SecurityResearch = "SecurityResearch",
    SocialMedia = "SocialMedia",
    TenableResearchAnalystsDecreasedVpr = "TenableResearchAnalystsDecreasedVpr",
    TenableResearchAnalystsIncreasedVpr = "TenableResearchAnalystsIncreasedVpr"
}

export enum WorkloadAnalysisError {
    AccessDenied = "AccessDenied",
    AzureVirtualMachineDiskResourceGroupLocked = "AzureVirtualMachineDiskResourceGroupLocked",
    AzureVirtualMachineResourceGroupDeleted = "AzureVirtualMachineResourceGroupDeleted",
    AzureVirtualMachineUnmanagedDisk = "AzureVirtualMachineUnmanagedDisk",
    DiskMaxSizeExceeded = "DiskMaxSizeExceeded",
    DiskNetworkAccessDenied = "DiskNetworkAccessDenied",
    DiskNotSupported = "DiskNotSupported",
    DiskPartitionEncrypted = "DiskPartitionEncrypted",
    DiskSupportedPartitionsNotFound = "DiskSupportedPartitionsNotFound",
    KeyVaultMissingPermissions = "KeyVaultMissingPermissions",
    MissingPermissions = "MissingPermissions",
    VirtualMachineExcluded = "VirtualMachineExcluded",
    VirtualMachineManaged = "VirtualMachineManaged",
    VirtualMachineProductCodeConfigurationNotSupported = "VirtualMachineProductCodeConfigurationNotSupported",
    VirtualMachineProductCodeNotSubscribed = "VirtualMachineProductCodeNotSubscribed",
    VirtualMachineProductCodeRemoved = "VirtualMachineProductCodeRemoved",
    VirtualMachineProductCodeSignInRequired = "VirtualMachineProductCodeSignInRequired",
    VirtualMachineProductCodeTypeNotSupported = "VirtualMachineProductCodeTypeNotSupported",
    WorkloadAnalysisDisabled = "WorkloadAnalysisDisabled"
}

export enum WorkloadAnalysisStatus {
    NotSupported = "NotSupported",
    Disabled = "Disabled",
    Pending = "Pending",
    Scanned = "Scanned"
}

export enum WorkloadAnalysisWorkloadResourceType {
    ContainerImage = "ContainerImage",
    VirtualMachine = "VirtualMachine",
    VirtualMachineImage = "VirtualMachineImage"
}

export enum WorkloadControllerRequestProperty {
    ContainerImageCreationTime = "ContainerImageCreationTime",
    ContainerImageDigest = "ContainerImageDigest",
    ContainerImageId = "ContainerImageId",
    ContainerImageKubernetesWorkloadResources = "ContainerImageKubernetesWorkloadResources",
    ContainerImageRepository = "ContainerImageRepository",
    ContainerImageTags = "ContainerImageTags",
    ContainerImageWorkloadClusterResources = "ContainerImageWorkloadClusterResources",
    ContainerImageWorkloadExists = "ContainerImageWorkloadExists",
    CreationTime = "CreationTime",
    FixableVulnerability = "FixableVulnerability",
    IdentityPermissionActionSeverity = "IdentityPermissionActionSeverity",
    InboundExternalDestinationNetworkScopes = "InboundExternalDestinationNetworkScopes",
    KubernetesClusterSensor = "KubernetesClusterSensor",
    MaliciousFileContentSha256String = "MaliciousFileContentSha256String",
    MaliciousFileFirstScanTime = "MaliciousFileFirstScanTime",
    MaliciousFilePath = "MaliciousFilePath",
    MaliciousFileType = "MaliciousFileType",
    NetworkInboundAccessType = "NetworkInboundAccessType",
    NetworkInboundExternalAccessScope = "NetworkInboundExternalAccessScope",
    OperatingSystemDisplayName = "OperatingSystemDisplayName",
    OperatingSystemType = "OperatingSystemType",
    PackageDisplayName = "PackageDisplayName",
    PackageFilePath = "PackageFilePath",
    PackageType = "PackageType",
    RegionId = "RegionId",
    ResolutionVersions = "ResolutionVersions",
    ResourceTags = "ResourceTags",
    Risks = "Risks",
    ScanTimeRange = "ScanTimeRange",
    TenantId = "TenantId",
    VirtualMachineId = "VirtualMachineId",
    VirtualMachineImageAccessLevel = "VirtualMachineImageAccessLevel",
    VirtualMachineImageId = "VirtualMachineImageId",
    VirtualMachineImageManagementType = "VirtualMachineImageManagementType",
    VirtualMachineImageRawId = "VirtualMachineImageRawId",
    VirtualMachines = "VirtualMachines",
    VulnerabilityAttackVector = "VulnerabilityAttackVector",
    VulnerabilityCvssScore = "VulnerabilityCvssScore",
    VulnerabilityCvssSeverity = "VulnerabilityCvssSeverity",
    VulnerabilityDescription = "VulnerabilityDescription",
    VulnerabilityEpssScore = "VulnerabilityEpssScore",
    VulnerabilityExploitable = "VulnerabilityExploitable",
    VulnerabilityFirstScanTime = "VulnerabilityFirstScanTime",
    VulnerabilityName = "VulnerabilityName",
    VulnerabilityRawId = "VulnerabilityRawId",
    VulnerabilityResolutionPatch = "VulnerabilityResolutionPatch",
    VulnerabilityResolutionTime = "VulnerabilityResolutionTime",
    VulnerabilityResolved = "VulnerabilityResolved",
    VulnerabilitySeverity = "VulnerabilitySeverity",
    VulnerabilityVprScore = "VulnerabilityVprScore",
    VulnerabilityVprSeverity = "VulnerabilityVprSeverity",
    Vulnerable = "Vulnerable",
    VulnerablePackageVersion = "VulnerablePackageVersion",
    WorkloadAnalysisStatus = "WorkloadAnalysisStatus",
    WorkloadResourceAttribute = "WorkloadResourceAttribute",
    WorkloadResourceId = "WorkloadResourceId",
    WorkloadResourceStatus = "WorkloadResourceStatus",
    WorkloadResourceTypeName = "WorkloadResourceTypeName"
}

export enum WorkloadResourceOperatingSystemRiskPackageScope {
    All = "All",
    OperatingSystem = "OperatingSystem"
}

export enum WorkloadResourceScanFileType {
    Elf = "Elf",
    Pe = "Pe",
    Script = "Script",
    Unknown = "Unknown"
}

export enum WorkloadResourceScanModelVirtualMachineStatus {
    Running = "Running",
    Stopped = "Stopped"
}

export enum WorkloadResourceScanPackageType {
    LanguagePackage = "LanguagePackage",
    OperatingSystem = "OperatingSystem",
    OperatingSystemPackage = "OperatingSystemPackage"
}


export class AadDirectoryApplicationRoleDefinition {
    constructor(
        public name: string,
        public rawId: string,
        public value: Optional<string>) {
    }
}

export class AadDirectoryApplicationServicePrincipalAws {
    constructor(
        public ssoIdentityStoreRawId: Optional<string>,
        public type: AadDirectoryApplicationServicePrincipalAwsType) {
    }
}

export class AadDirectoryDirectoryRoleAssignmentResourceScopeInfo {
    constructor(
        public displayName: Optional<string>,
        public rawId: string,
        public type: AadDirectoryDirectoryRoleAssignmentResourceScopeType) {
    }
}

export class AadDirectoryDirectoryRoleDefinitionPermission {
    constructor(
        public allowedResourceActions: string[],
        public condition: Optional<string>,
        public excludedResourceActions: Optional<string[]>) {
    }
}

export class AadDirectoryGroupProfileIdentitiesInactive {
    constructor(
        public startTime: Optional<string>) {
    }
}

export class AadDirectoryPrincipalModelAnalyzing {
    constructor(
        public reason: EntityAnalyzingReason,
        public tenantIds: Optional<string[]>) {
    }
}

export class AadDirectoryPrincipalReference {
    constructor(
        public id: Optional<string>,
        public rawId: string,
        public type: AadDirectoryPrincipalType) {
    }
}

export class AadDirectoryRoleManagementPolicyAssignmentActivation {
    constructor(
        public approvalRequired: boolean,
        public approverPrincipalIds: string[],
        public authenticationType: AadDirectoryRoleManagementPolicyAssignmentActivationAuthenticationType,
        public maxDuration: string) {
    }
}

export class AadDirectoryServicePrincipalFederatedCredential {
    constructor(
        public identityProviderIssuerUrl: string,
        public subject: Optional<string>) {
    }
}

export class AadDirectoryUserJobInfo {
    constructor(
        public companyName: Optional<string>,
        public department: Optional<string>,
        public employeeRawId: Optional<string>,
        public managerUserId: Optional<string>,
        public title: Optional<string>) {
    }
}

export class AadSeverePermissionDirectoryRoleDefinitionData {
    constructor(
        public name: string,
        public templateId: string) {
    }
}

export class AadTenantAuthenticationProviderConfigurationSectionPartitionData {
    constructor(
        public applicationEncryptedSecret: EncryptedData,
        public applicationRawId: string) {
    }
}

export class AadTenantConfigurationAnalysis {
    constructor(
        public directorySyncConcurrencyLevel: Optional<number>,
        public syncType: Optional<AadTenantConfigurationAnalysisSyncType>,
        public userExcludedLocations: string[]) {
    }
}

export class AadTenantConfigurationApplication {
    constructor(
        public name: string,
        public rawId: string) {
    }
}

export class AadTenantConfigurationEvents {
    constructor(
        public syncTimeFrame: string) {
    }
}

export class AadTenantConfigurationFederation {
    constructor(
        public awsRoleData: Optional<AadTenantConfigurationFederationRoleData>,
        public awsRoleSessionNameUserProperties: AadTenantConfigurationFederationAwsRoleSessionNameUserProperty[],
        public awsSamlIdentityProviderName: Optional<string>,
        public awsSsoIdentityStoreRawId: Optional<string>) {
    }
}

export class AadTenantConfigurationFederationRoleData {
    constructor(
        public partitionType: AwsPartitionType,
        public rawRegexes: string[]) {
    }
}

export class AadTenantStateEvents {
    constructor(
        public signInsEnabled: Optional<boolean>) {
    }
}

export class AccessControllerGetAwsFederationEdgeFilterItemPageRequest {
    constructor(
        public entityId: Optional<string>,
        public limit: number,
        public property: AccessControllerGetAwsFederationEdgeModelPageRequestProperty,
        public searchText: Optional<string>,
        public skip: number) {
    }
}

export class AccessControllerGetAwsFederationEdgeFilterItemPageResponse {
    constructor(
        public federationEdgeFilterItemPage: ElasticsearchFilterItemPage<string>) {
    }
}

export class AccessControllerGetAwsFederationEdgeFiltersRequest {
    constructor(
        public entityId: Optional<string>) {
    }
}

export class AccessControllerGetAwsFederationEdgeFiltersResponse {
    constructor(
        public filters: AwsFederationEdgeFilters) {
    }
}

export class AccessControllerGetAwsFederationEdgeModelPageRequest {
    constructor(
        public entityId: Optional<string>,
        public filters: AccessControllerGetAwsFederationEdgeModelRequestFilters,
        public limit: number,
        public nextPageSearchCursor: Optional<ElasticsearchIndexSearchCursor>,
        public sort: AccessControllerGetAwsFederationEdgeModelPageRequestSort) {
    }
}

export class AccessControllerGetAwsFederationEdgeModelPageRequestSort {
    constructor(
        public direction: SortDirection,
        public property: AccessControllerGetAwsFederationEdgeModelPageRequestProperty) {
    }
}

export class AccessControllerGetAwsFederationEdgeModelPageResponse {
    constructor(
        public federationEdgeModelPage: ElasticsearchItemPage<AwsFederationEdgeModel>) {
    }
}

export class AccessControllerGetAwsFederationEdgeModelRequestFilters {
    constructor(
        public assumeRoleActivityTimeRangeSelection: Optional<TimeRangeSelection>,
        public destinationEntityAttributeValueSelection: Optional<ItemSelection<string>>,
        public destinationEntityIdSelection: Optional<PagedItemSelection<string>>,
        public destinationEntityTenantIdSelection: Optional<ItemSelection<string>>,
        public firstSeenTimeRangeSelection: Optional<TimeRangeSelection>,
        public granteeGroupIdSelection: Optional<PagedItemSelection<string>>,
        public sourceEntityAttributeValueSelection: Optional<ItemSelection<string>>,
        public sourceEntityGrantee: Optional<boolean>,
        public sourceEntityIdSelection: Optional<PagedItemSelection<string>>,
        public sourceEntityTenantIdSelection: Optional<ItemSelection<string>>,
        public sourceEntityTypeNameSelection: Optional<ItemSelection<string>>,
        public sso: Optional<boolean>,
        public unused: boolean) {
    }
}

export class AccessControllerGetDashboardSummaryResponse {
    constructor(
        public accessSummary: AccessControllerGetDashboardSummaryResponseAccessSummary) {
    }
}

export class AccessControllerGetDashboardSummaryResponseAccessSummary {
    constructor(
        public principalRiskCategoryToOpenRiskSeverityToPrincipalCountMap: Dictionary<Dictionary<number>>,
        public principalTypeToSummaryMap: Dictionary<AccessControllerGetDashboardSummaryResponseAccessSummaryPrincipalSummary>,
        public riskCombinationToDataMap: Dictionary<RiskCombinationData>,
        public riskDatas: AccessControllerGetDashboardSummaryResponseAccessSummaryRiskData[],
        public timeFrameToExcessivePermissionPrincipalTrendMap: Dictionary<AccessControllerGetDashboardSummaryResponseCloudProviderTenantAccessExcessivePermissionPrincipalTrend>,
        public vendorDatas: AccessControllerGetDashboardSummaryResponseAccessSummaryVendorData[]) {
    }
}

export class AccessControllerGetDashboardSummaryResponseAccessSummaryPrincipalSummary {
    constructor(
        public permissionPrincipalCount: number,
        public severePermissionPrincipalCount: number) {
    }
}

export class AccessControllerGetDashboardSummaryResponseAccessSummaryRiskData {
    constructor(
        public policyConfigurationTypeName: string,
        public severityToCountMap: Dictionary<number>,
        public tenantTypes: TenantType[]) {
    }
}

export class AccessControllerGetDashboardSummaryResponseAccessSummaryVendorData {
    constructor(
        public activity: Optional<Activity>,
        public permissionSeverity: Severity,
        public permissionTenantEntityTypeName: string,
        public permissionTenantIds: string[],
        public serviceIdentityIds: string[],
        public serviceIdentityTypeName: string,
        public vendor: Vendor) {
    }
}

export class AccessControllerGetDashboardSummaryResponseCloudProviderTenantAccessExcessivePermissionPrincipalTrend {
    constructor(
        public dateToRiskCategoryToOpenRiskSeverityToCountMap: Dictionary<Dictionary<Dictionary<number>>>,
        public riskCategoryToTenantIdToOpenRiskSeverityToTimeFrameSummaryMap: Dictionary<Dictionary<Dictionary<AccessControllerGetDashboardSummaryResponseCloudProviderTenantAccessPrincipalTimeFrameSummary>>>) {
    }
}

export class AccessControllerGetDashboardSummaryResponseCloudProviderTenantAccessPrincipalTimeFrameSummary {
    constructor(
        public endTimeCount: number,
        public startTimeCount: number) {
    }
}

export class AccessControllerGetIdentityDestinationTenantIdsRequest {
    constructor(
        public sourceEntityId: string) {
    }
}

export class AccessControllerGetIdentityDestinationTenantIdsResponse {
    constructor(
        public destinationTenantIds: string[]) {
    }
}

export class AccessControllerGetPermissionFiltersResponse {
    constructor(
        public filters: PermissionFilters) {
    }
}

export class AccessControllerGetPermissionsModelActionsRequest {
    constructor(
        public entityId: string,
        public entityType: AccessControllerGetPermissionsModelEntityType,
        public filters: PermissionManagerRequestFilters) {
    }
}

export class AccessControllerGetPermissionsModelActionsResponse {
    constructor(
        public actions: string[]) {
    }
}

export class AccessControllerGetPermissionsModelCountRequest {
    constructor(
        public entityType: AccessControllerGetPermissionsModelEntityType,
        public filters: PermissionManagerRequestFilters) {
    }
}

export class AccessControllerGetPermissionsModelCountResponse {
    constructor(
        public permissionsModelEntityCount: number) {
    }
}

export class AccessControllerGetPermissionsModelDestinationEntityIdsRequest {
    constructor(
        public filters: PermissionManagerRequestFilters,
        public sourceEntityId: string) {
    }
}

export class AccessControllerGetPermissionsModelDestinationEntityIdsResponse {
    constructor(
        public destinationEntityIds: string[]) {
    }
}

export class AccessControllerGetPermissionsModelExportRequest {
    constructor(
        public entityType: AccessControllerGetPermissionsModelEntityType,
        public filters: PermissionManagerRequestFilters,
        public limit: number,
        public skip: number) {
    }
}

export class AccessControllerGetPermissionsModelExportResponse {
    constructor(
        public entityIdToDisplayReferenceMap: Dictionary<string>,
        public entityIdToTenantIdMap: Dictionary<string>,
        public permissionsModels: PermissionsModel[]) {
    }
}

export class AccessControllerGetPermissionsModelOriginatorEntityIdsRequest {
    constructor(
        public entityId: string,
        public entityType: AccessControllerGetPermissionsModelEntityType,
        public filters: PermissionManagerRequestFilters) {
    }
}

export class AccessControllerGetPermissionsModelOriginatorEntityIdsResponse {
    constructor(
        public originatorEntityIds: string[]) {
    }
}

export class AccessControllerGetPermissionsModelPageRequest {
    constructor(
        public entityType: AccessControllerGetPermissionsModelEntityType,
        public filters: PermissionManagerRequestFilters,
        public limit: number,
        public skip: number) {
    }
}

export class AccessControllerGetPermissionsModelPageResponse {
    constructor(
        public permissionsModelPage: ItemPage<PermissionsModel>) {
    }
}

export class AccessControllerGetPermissionsModelSourceEntityIdsRequest {
    constructor(
        public destinationEntityId: string,
        public filters: PermissionManagerRequestFilters) {
    }
}

export class AccessControllerGetPermissionsModelSourceEntityIdsResponse {
    constructor(
        public sourceEntityIds: string[]) {
    }
}

export class AccessControllerGetPrincipalExcessivePermissionPrincipalRiskTenantPermissionRequest {
    constructor(
        public filters: AccessControllerGetPrincipalExcessivePermissionModelRequestFilters) {
    }
}

export class AccessControllerGetPrincipalExcessivePermissionPrincipalRiskTenantPermissionResponse {
    constructor(
        public principalRiskTenantPermission: boolean) {
    }
}

export class AccessControllerGetPrincipalExcessivePermissionRiskResolutionEnabledRequest {
    constructor(
        public riskCategory: PrincipalRiskCategory) {
    }
}

export class AccessControllerGetPrincipalExcessivePermissionRiskResolutionEnabledResponse {
    constructor(
        public riskResolutionEnabled: boolean) {
    }
}

export class AccessControllerGetPrincipalFilterItemPageRequest {
    constructor(
        public limit: number,
        public property: AccessControllerGetPrincipalModelPageRequestProperty,
        public searchText: Optional<string>,
        public skip: number) {
    }
}

export class AccessControllerGetPrincipalFilterItemPageResponseBase {
    constructor() {
    }
}

export class AccessControllerGetPrincipalIdToOpenAccessRiskIdToScopeIdToIdentityPermissionsMapRequest {
    constructor(
        public filters: AccessControllerGetPrincipalExcessivePermissionModelRequestFilters) {
    }
}

export class AccessControllerGetPrincipalIdToOpenAccessRiskIdToScopeIdToIdentityPermissionsMapResponse {
    constructor(
        public principalIdToOpenAccessRiskIdToScopeIdToIdentityPermissionsMap: Dictionary<Dictionary<Dictionary<IdentityPermission[]>>>) {
    }
}

export class AccessControllerGetPrincipalModelFiltersRequest {
    constructor() {
    }
}

export class AccessControllerGetPrincipalModelFiltersResponse {
    constructor(
        public principalModelFilters: PrincipalModelFilters) {
    }
}

export class AccessControllerGetPrincipalModelPageRequest {
    constructor(
        public filters: AccessControllerGetPrincipalModelRequestFilters,
        public limit: number,
        public skip: number,
        public sort: AccessControllerGetPrincipalModelPageRequestSort) {
    }
}

export class AccessControllerGetPrincipalModelPageRequestSort {
    constructor(
        public direction: SortDirection,
        public property: AccessControllerGetPrincipalModelPageRequestProperty) {
    }
}

export class AccessControllerGetPrincipalModelPageResponse {
    constructor(
        public principalIdToOpenAccessRiskIdToScopeIdToIdentityPermissionsMap: Optional<Dictionary<Dictionary<Dictionary<IdentityPermission[]>>>>,
        public principalModelPage: ItemPage<EntityModel>) {
    }
}

export class AccessControllerGetPrincipalModelPermissionFiltersRequest extends AccessControllerGetPrincipalModelFiltersRequest {
    constructor() {
        super();
    }
}

export class AccessControllerGetPrincipalModelRequestFilters {
    constructor(
        public actionRiskCategorySelection: Optional<ItemSelection<ActionRiskCategory>>,
        public activityTimeRangeSelection: Optional<TimeRangeSelection>,
        public attributeValueSelection: Optional<ItemSelection<string>>,
        public awsPermissionSetAccountIdSelection: Optional<PagedItemSelection<string>>,
        public awsPermissionSetPrincipalIdSelection: Optional<PagedItemSelection<string>>,
        public idSelection: Optional<PagedItemSelection<string>>,
        public serviceIdentityOriginatorEntityIdSelection: Optional<PagedItemSelection<string>>,
        public serviceIdentityOriginatorEntityTenantIdSelection: Optional<ItemSelection<string>>,
        public serviceIdentityOriginatorEntityTypeNameSelection: Optional<ItemSelection<string>>,
        public severitySelection: Optional<ItemSelection<Severity>>,
        public tenantIdSelection: Optional<ItemSelection<string>>,
        public typeNameSelection: Optional<ItemSelection<string>>,
        public vendorSelection: Optional<ItemSelection<Vendor>>) {
    }
}

export class AccessControllerGetPrincipalPermissionFilterItemPageRequest extends AccessControllerGetPrincipalFilterItemPageRequest {
    constructor(
        limit: number,
        property: AccessControllerGetPrincipalModelPageRequestProperty,
        searchText: Optional<string>,
        skip: number) {
        super(
            limit,
            property,
            searchText,
            skip);
    }
}

export class AccessControllerGetPrincipalPermissionModelRequestFilters extends AccessControllerGetPrincipalModelRequestFilters {
    constructor(
        actionRiskCategorySelection: Optional<ItemSelection<ActionRiskCategory>>,
        activityTimeRangeSelection: Optional<TimeRangeSelection>,
        attributeValueSelection: Optional<ItemSelection<string>>,
        awsPermissionSetAccountIdSelection: Optional<PagedItemSelection<string>>,
        awsPermissionSetPrincipalIdSelection: Optional<PagedItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        serviceIdentityOriginatorEntityIdSelection: Optional<PagedItemSelection<string>>,
        serviceIdentityOriginatorEntityTenantIdSelection: Optional<ItemSelection<string>>,
        serviceIdentityOriginatorEntityTypeNameSelection: Optional<ItemSelection<string>>,
        severitySelection: Optional<ItemSelection<Severity>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        typeNameSelection: Optional<ItemSelection<string>>,
        vendorSelection: Optional<ItemSelection<Vendor>>) {
        super(
            actionRiskCategorySelection,
            activityTimeRangeSelection,
            attributeValueSelection,
            awsPermissionSetAccountIdSelection,
            awsPermissionSetPrincipalIdSelection,
            idSelection,
            serviceIdentityOriginatorEntityIdSelection,
            serviceIdentityOriginatorEntityTenantIdSelection,
            serviceIdentityOriginatorEntityTypeNameSelection,
            severitySelection,
            tenantIdSelection,
            typeNameSelection,
            vendorSelection);
    }
}

export class AccessControllerGetPrincipalPermissionsModelPageRequest extends AccessControllerGetPrincipalModelPageRequest {
    constructor(
        filters: AccessControllerGetPrincipalModelRequestFilters,
        limit: number,
        skip: number,
        sort: AccessControllerGetPrincipalModelPageRequestSort) {
        super(
            filters,
            limit,
            skip,
            sort);
    }
}

export class AccessControllerGetPrincipalPropertyItemsRequest {
    constructor(
        public principalId: string,
        public property: AccessControllerGetPrincipalModelPageRequestProperty) {
    }
}

export class AccessControllerGetPrincipalPropertyItemsResponse {
    constructor() {
    }
}

export class AccessControllerGetPrincipalSummaryRequest {
    constructor(
        public type: AccessControllerGetPrincipalSummaryRequestType) {
    }
}

export class AccessControllerGetPrincipalSummaryResponse {
    constructor(
        public result: AccessControllerGetPrincipalSummaryResponseResult) {
    }
}

export class AccessGraphPermissionEdgeFragmentedData {
    constructor(
        public destinationEntitiesReferenceConsistentHash: string,
        public destinationEntitiesServiceId: string,
        public excessivePermissionActionCount: number,
        public nonexcessivePermissionActionCount: number,
        public sourceEntitiesReferenceConsistentHash: string) {
    }
}

export class AccessGraphPermissionPathIdentifier {
    constructor() {
    }
}

export class AccessListPermissionActionData {
    constructor(
        public excessive: boolean) {
    }
}

export class Activity {
    constructor(
        public sortValue: number,
        public time: Optional<string>,
        public timeType: ActivityTimeType) {
    }
}

export class AdministrationControllerConfigureTenantRequest {
    constructor(
        public enabled: boolean,
        public id: string,
        public orchestrationType: OrchestrationType) {
    }
}

export class AdministrationControllerConfigureTenantResponse {
    constructor(
        public tenantModel: TenantModel) {
    }
}

export class AdministrationControllerDeleteSupportPrincipalRequest {
    constructor(
        public group: boolean,
        public identifier: string) {
    }
}

export class AdministrationControllerGetAuthenticationConfigurationResponse {
    constructor(
        public authentication: ApplicationCustomerConfigurationAuthentication,
        public authorizationDomainNames: string[]) {
    }
}

export class AdministrationControllerGetAuthenticationUsersResponse {
    constructor(
        public mails: string[]) {
    }
}

export class AdministrationControllerGetDataAnalysisRequest {
    constructor(
        public scopeId: string) {
    }
}

export class AdministrationControllerGetDataAnalysisResponse {
    constructor(
        public defaultProvisionedManagedDatabaseMaxSize: number,
        public inherited: boolean,
        public provisionedManagedDatabaseMaxSize: number) {
    }
}

export class AdministrationControllerGetFeaturesResponse {
    constructor(
        public enabledFeatureNames: FeatureName[],
        public featureNames: FeatureName[]) {
    }
}

export class AdministrationControllerGetInformationResponse {
    constructor(
        public orchestrationTypeToPreviousTimeRangeMap: Dictionary<Optional<TimeRange>>) {
    }
}

export class AdministrationControllerGetLicensingConfigurationResponse {
    constructor(
        public licensing: ApplicationCustomerConfigurationLicensing) {
    }
}

export class AdministrationControllerGetLicensingObjectCountAveragePreviewRequest {
    constructor(
        public licenseVersion: ApplicationCustomerConfigurationLicensingLicenseVersion) {
    }
}

export class AdministrationControllerGetLicensingObjectCountAveragePreviewResponse {
    constructor(
        public dateToObjectCountMap: Dictionary<number>,
        public objectCountAverage: Optional<number>,
        public objectTypeToCountMap: Optional<Dictionary<number>>) {
    }
}

export class AdministrationControllerGetOrchestrationStageDatasResponse {
    constructor(
        public rootStageDatas: AdministrationControllerGetOrchestrationStageDatasResponseRootStagesOrchestrationStageData[]) {
    }
}

export class AdministrationControllerGetOrchestrationStageDatasResponseOrchestrationStageData {
    constructor(
        public duration: string,
        public id: string,
        public name: string,
        public status: AdministrationControllerOrchestrationStageStatus,
        public type: AdministrationControllerOrchestrationStageType) {
    }
}

export class AdministrationControllerGetOrchestrationStageDatasResponseStagesOrchestrationStageData extends AdministrationControllerGetOrchestrationStageDatasResponseOrchestrationStageData {
    constructor(
        duration: string,
        id: string,
        name: string,
        status: AdministrationControllerOrchestrationStageStatus,
        type: AdministrationControllerOrchestrationStageType,
        public childStageDatas: AdministrationControllerGetOrchestrationStageDatasResponseOrchestrationStageData[]) {
        super(
            duration,
            id,
            name,
            status,
            type);
    }
}

export class AdministrationControllerGetOrchestrationStageDatasResponseTenantOrchestrationStageData extends AdministrationControllerGetOrchestrationStageDatasResponseOrchestrationStageData {
    constructor(
        duration: string,
        id: string,
        name: string,
        status: AdministrationControllerOrchestrationStageStatus,
        type: AdministrationControllerOrchestrationStageType,
        public completedTenantCount: number,
        public retryCount: number,
        public retryMaxCount: number,
        public retryTotalCount: number,
        public tenantCount: number) {
        super(
            duration,
            id,
            name,
            status,
            type);
    }
}

export class AdministrationControllerGetSupportPrincipalsResponse {
    constructor(
        public supportPrincipalDatas: AdministrationControllerGetSupportPrincipalsResponseSupportPrincipalData[]) {
    }
}

export class AdministrationControllerGetSupportPrincipalsResponseSupportPrincipalData {
    constructor(
        public application: boolean,
        public expirationTime: Optional<string>,
        public group: boolean,
        public identifier: string,
        public role: IdentityRole) {
    }
}

export class AdministrationControllerGetWorkloadAnalysisRequest {
    constructor(
        public scopeId: string) {
    }
}

export class AdministrationControllerGetWorkloadAnalysisResponse {
    constructor(
        public diskDefaultMaxSize: number,
        public diskMaxSize: number,
        public inherited: boolean) {
    }
}

export class AdministrationControllerResetCognitoUserPasswordRequest {
    constructor(
        public mail: string) {
    }
}

export class AdministrationControllerResetCognitoUserPasswordResponse {
    constructor(
        public password: string) {
    }
}

export class AdministrationControllerUpdateAuthenticationConfigurationRequest {
    constructor(
        public authentication: ApplicationCustomerConfigurationAuthentication,
        public authorizationDomainNames: string[]) {
    }
}

export class AdministrationControllerUpdateCustomerRequest {
    constructor(
        public supportId: Optional<number>,
        public tenableId: string) {
    }
}

export class AdministrationControllerUpdateDataAnalysisRequest {
    constructor(
        public provisionedManagedDatabaseMaxSize: Optional<number>,
        public scopeId: string) {
    }
}

export class AdministrationControllerUpdateFeatureRequest {
    constructor(
        public enabled: boolean,
        public featureName: FeatureName) {
    }
}

export class AdministrationControllerUpdateLicensingRequest {
    constructor(
        public licensing: ApplicationCustomerConfigurationLicensing) {
    }
}

export class AdministrationControllerUpdateLogoRequest {
    constructor(
        public rawLogo: string) {
    }
}

export class AdministrationControllerUpdateWorkloadAnalysisRequest {
    constructor(
        public diskMaxSize: Optional<number>,
        public scopeId: string) {
    }
}

export class AdministrationControllerUpsertSupportPrincipalRequest {
    constructor(
        public expirationTime: Optional<string>,
        public group: boolean,
        public identifier: string,
        public role: IdentityRole) {
    }
}

export class ApplicationCustomerConfigurationAuthentication {
    constructor(
        public aadIdentityProviderEnabled: boolean,
        public cognitoIdentityProviderEnabled: boolean,
        public consoleAppTypeToSamlIdentityProviderMap: Dictionary<ApplicationCustomerConfigurationAuthenticationSamlIdentityProvider>,
        public domainNames: string[],
        public gciIdentityProviderEnabled: boolean) {
    }
}

export class ApplicationCustomerConfigurationAuthenticationSamlIdentityProvider {
    constructor(
        public issuerId: string,
        public metadataBytes: Optional<string>,
        public metadataUrl: Optional<string>,
        public serviceProviderDefaultAcsEnabled: boolean,
        public type: ApplicationCustomerConfigurationAuthenticationSamlIdentityProviderType) {
    }
}

export class ApplicationCustomerConfigurationLicensing {
    constructor(
        public evaluationLicense: Optional<ApplicationCustomerConfigurationLicensingEvaluationLicense>,
        public licenseVersion: ApplicationCustomerConfigurationLicensingLicenseVersion,
        public subscriptionLicense: Optional<ApplicationCustomerConfigurationLicensingSubscriptionLicense>,
        public visible: boolean) {
    }
}

export class ApplicationCustomerConfigurationLicensingLicense {
    constructor(
        public expirationTime: Optional<string>,
        public types: ApplicationCustomerConfigurationLicensingLicenseType[]) {
    }
}

export class ApplicationCustomerConfigurationLicensingSubscriptionLicense extends ApplicationCustomerConfigurationLicensingLicense {
    constructor(
        expirationTime: Optional<string>,
        types: ApplicationCustomerConfigurationLicensingLicenseType[],
        public eventRetentionTimeFrame: string,
        public objectCount: Optional<number>) {
        super(
            expirationTime,
            types);
    }
}

export class AuditEventControllerDeleteRiskNoteCreationAuditEventRequest {
    constructor(
        public id: string) {
    }
}

export class AuditEventControllerDeleteRiskStatusNoteAuditEventRequest {
    constructor(
        public id: string) {
    }
}

export class AuditEventControllerDeleteRiskStatusNoteAuditEventResponse {
    constructor(
        public auditEventModel: AuditEventModel) {
    }
}

export class AuditEventControllerGetAuditEventFiltersRequestBase {
    constructor() {
    }
}

export class AuditEventControllerGetAuditEventFiltersResponse {
    constructor(
        public filters: AuditEventFilters,
        public systemIdentityId: string) {
    }
}

export class AuditEventControllerGetAuditEventModelPageRequestBase {
    constructor(
        public limit: number,
        public nextPageSearchCursor: Optional<ElasticsearchIndexSearchCursor>) {
    }
}

export class AuditEventControllerGetAuditEventModelPageRequestFilters {
    constructor(
        public identityIdSelection: Optional<ItemSelection<string>>,
        public results: Optional<boolean>,
        public scopeIdSelection: Optional<ItemSelection<string>>,
        public systemCreationTimeRangeSelection: Optional<TimeRangeSelection>,
        public typeNameSelection: Optional<ItemSelection<string>>) {
    }
}

export class AuditEventControllerGetAuditEventModelPageRequestSort {
    constructor(
        public direction: SortDirection,
        public property: AuditEventControllerGetAuditEventModelPageRequestProperty) {
    }
}

export class AuditEventControllerGetAuditEventModelPageResponse {
    constructor(
        public auditEventModelPage: ElasticsearchItemPage<AuditEventModel>) {
    }
}

export class AuditEventControllerGetAuditEventModelsRequest {
    constructor(
        public ids: Optional<string[]>) {
    }
}

export class AuditEventControllerGetAuditEventModelsResponse {
    constructor(
        public auditEventModels: AuditEventModel[]) {
    }
}

export class AuditEventControllerGetAuditEventTypeNamesResponse {
    constructor(
        public typeNames: string[]) {
    }
}

export class AuditEventControllerGetPermissionAuditEventFilterItemPageRequest {
    constructor(
        public auditEventTypeName: string,
        public limit: number,
        public property: AuditEventControllerGetAuditEventModelPageRequestProperty,
        public searchText: Optional<string>,
        public skip: number) {
    }
}

export class AuditEventControllerGetPermissionAuditEventFilterItemPageResponseBase {
    constructor() {
    }
}

export class AuditEventControllerGetPermissionAuditEventFiltersRequest extends AuditEventControllerGetAuditEventFiltersRequestBase {
    constructor() {
        super();
    }
}

export class AuditEventControllerGetPermissionEligibilityAuditEventFiltersRequest extends AuditEventControllerGetPermissionAuditEventFiltersRequest {
    constructor() {
        super();
    }
}

export class AuditEventControllerGetPermissionEligibilityAuditEventModelPageRequest extends AuditEventControllerGetAuditEventModelPageRequestBase {
    constructor(
        limit: number,
        nextPageSearchCursor: Optional<ElasticsearchIndexSearchCursor>,
        public filters: AuditEventControllerGetPermissionEligibilityAuditEventModelPageRequestFilters,
        public sort: AuditEventControllerGetPermissionEligibilityAuditEventModelPageRequestSort) {
        super(
            limit,
            nextPageSearchCursor);
    }
}

export class AuditEventControllerGetPermissionEligibilityAuditEventModelPageRequestFilters {
    constructor(
        public approval: Optional<boolean>,
        public approverPrincipalIdSelection: Optional<PagedItemSelection<string>>,
        public granteePrincipalIdSelection: Optional<PagedItemSelection<string>>,
        public groupMembershipEligibilityGroupIdSelection: Optional<PagedItemSelection<string>>,
        public identityIdSelection: Optional<ItemSelection<string>>,
        public nameSelection: Optional<ItemSelection<string>>,
        public permissionAssignmentEligibilityPermitterIdSelection: Optional<PagedItemSelection<string>>,
        public permissionRequestScopePathSelection: Optional<ItemSelection<string>>,
        public principalTenantIdSelection: Optional<ItemSelection<string>>,
        public scopeIdSelection: Optional<ItemSelection<string>>,
        public systemCreationTimeRangeSelection: Optional<TimeRangeSelection>,
        public typeNameSelection: Optional<ItemSelection<string>>) {
    }
}

export class AuditEventControllerGetPermissionEligibilityAuditEventModelPageRequestSort {
    constructor(
        public direction: SortDirection,
        public property: AuditEventControllerGetAuditEventModelPageRequestProperty) {
    }
}

export class AuditEventControllerGetPermissionRequestAuditEventFiltersRequest extends AuditEventControllerGetPermissionAuditEventFiltersRequest {
    constructor() {
        super();
    }
}

export class AuditEventControllerGetPermissionRequestAuditEventModelPageRequest extends AuditEventControllerGetAuditEventModelPageRequestBase {
    constructor(
        limit: number,
        nextPageSearchCursor: Optional<ElasticsearchIndexSearchCursor>,
        public filters: AuditEventControllerGetPermissionRequestAuditEventModelPageRequestFilters,
        public sort: AuditEventControllerGetPermissionRequestAuditEventModelPageRequestSort) {
        super(
            limit,
            nextPageSearchCursor);
    }
}

export class AuditEventControllerGetPermissionRequestAuditEventModelPageRequestFilters {
    constructor(
        public approvalRequired: Optional<boolean>,
        public approverUserIdSelection: Optional<PagedItemSelection<string>>,
        public granteeUserIdSelection: Optional<PagedItemSelection<string>>,
        public granteeUserTenantIdSelection: Optional<ItemSelection<string>>,
        public ids: Optional<string[]>,
        public permissionAssignmentRequestTenantDisplayReferenceSelection: Optional<ItemSelection<string>>,
        public permissionEligibilityNameSelection: Optional<ItemSelection<string>>,
        public systemCreationTimeRangeSelection: Optional<TimeRangeSelection>,
        public typeNameSelection: Optional<ItemSelection<string>>) {
    }
}

export class AuditEventControllerGetPermissionRequestAuditEventModelPageRequestSort {
    constructor(
        public direction: SortDirection,
        public property: AuditEventControllerGetAuditEventModelPageRequestProperty) {
    }
}

export class AuditEventControllerGetRiskAuditEventModelPageRequest extends AuditEventControllerGetAuditEventModelPageRequestBase {
    constructor(
        limit: number,
        nextPageSearchCursor: Optional<ElasticsearchIndexSearchCursor>,
        public riskId: string) {
        super(
            limit,
            nextPageSearchCursor);
    }
}

export class AuditEventControllerInsertRiskNoteCreationAuditEventRequest {
    constructor(
        public ids: string[],
        public message: string) {
    }
}

export class AuditEventControllerInsertRiskNoteCreationAuditEventResponse {
    constructor(
        public auditEventModel: AuditEventModel[]) {
    }
}

export class AuditEventControllerUpdateRiskNoteCreationAuditEventRequest {
    constructor(
        public id: string,
        public message: string) {
    }
}

export class AuditEventControllerUpdateRiskNoteCreationAuditEventResponse {
    constructor(
        public auditEventModel: AuditEventModel) {
    }
}

export class AuditEventControllerUpdateRiskStatusUpdateAuditEventRequest {
    constructor(
        public id: string,
        public message: string) {
    }
}

export class AuditEventControllerUpdateRiskStatusUpdateAuditEventResponse {
    constructor(
        public auditEventModel: AuditEventModel) {
    }
}

export class AuditEventModel {
    constructor(
        public auditEvent: AuditEvent,
        public entityIds: string[]) {
    }
}

export class AuthenticationConfigurationSectionCognito {
    constructor(
        public clientId: string,
        public userPoolId: string) {
    }
}

export class AuthenticationConfigurationSectionOidc {
    constructor(
        public clientId: string) {
    }
}

export class AuthenticationConfigurationSectionSaml {
    constructor(
        public issuerId: string,
        public signingCertificatePems: string[]) {
    }
}

export class AuthenticationControllerChangeCognitoUserPasswordRequest {
    constructor(
        public userExistingPassword: string,
        public userMail: string,
        public userNewPassword: string) {
    }
}

export class AuthenticationControllerChangeCognitoUserPasswordResponse {
    constructor(
        public result: CognitoAuthenticationManagerChangeUserPasswordResult) {
    }
}

export class AuthenticationControllerGetTeamsSignInDeploymentRedirectRequest {
    constructor(
        public rawJwtToken: string) {
    }
}

export class AuthenticationControllerGetTeamsSignInDeploymentRedirectResponse {
    constructor(
        public error: Optional<AuthenticationControllerTeamsSignInError>,
        public redirectUrl: Optional<string>) {
    }
}

export class AuthenticationControllerGetUserCustomerDataResponse {
    constructor(
        public customer: Optional<AuthenticationControllerGetUserCustomerDataResponseCustomer>) {
    }
}

export class AuthenticationControllerGetUserCustomerDataResponseCustomer {
    constructor(
        public activeEvaluationLicenseTypes: ApplicationCustomerConfigurationLicensingLicenseType[],
        public activeSubscriptionLicenseTypes: ApplicationCustomerConfigurationLicensingLicenseType[],
        public colorIndex: Optional<number>,
        public creationTime: string,
        public id: string,
        public logoConfiguration: Optional<CustomerLogoConfiguration>,
        public name: string,
        public supportId: Optional<number>,
        public tenableId: string,
        public tenableSiteId: Optional<string>) {
    }
}

export class AuthenticationControllerGetUserCustomerDatasResponse {
    constructor(
        public customers: AuthenticationControllerGetUserCustomerDataResponseCustomer[]) {
    }
}

export class AuthenticationControllerGetUserIdentityProvidersRequest {
    constructor(
        public userMail: string) {
    }
}

export class AuthenticationControllerGetUserIdentityProvidersResponse {
    constructor(
        public aadIdentityProviderEnabled: boolean,
        public cognitoIdentityProviderEnabled: boolean,
        public gciIdentityProviderEnabled: boolean,
        public samlIdentityProviderIssuerId: Optional<string>) {
    }
}

export class AuthenticationControllerGetUserResponse {
    constructor(
        public applicationSupport: boolean,
        public customerPermissions: Optional<IdentityPermission[]>,
        public defaultScopeId: Optional<string>,
        public enabledConsoleAppTypes: ConsoleAppType[],
        public mail: string,
        public mailHash: string,
        public multipleCustomers: boolean,
        public name: string,
        public permissionToActiveCiTenantIdsMap: Optional<Dictionary<string[]>>,
        public permissionToActiveCloudProviderTenantIdsMap: Optional<Dictionary<string[]>>,
        public permissionToActiveCodeTenantIdsMap: Optional<Dictionary<string[]>>,
        public permissionToActiveIdentityProviderTenantIdsMap: Optional<Dictionary<string[]>>,
        public permissionToFolderIdsMap: Optional<Dictionary<string[]>>,
        public permissionToProjectIdsMap: Optional<Dictionary<string[]>>,
        public scopeIdToPermissionsMap: Optional<Dictionary<IdentityPermission[]>>,
        public securityAdministrationWritePermissionExists: Optional<boolean>,
        public support: boolean,
        public tenableId: Optional<string>) {
    }
}

export class AuthenticationControllerRecoverCognitoUserPasswordRequest {
    constructor(
        public userMail: string,
        public userNewPassword: string,
        public verificationCode: string) {
    }
}

export class AuthenticationControllerRecoverCognitoUserPasswordResponse {
    constructor(
        public result: CognitoAuthenticationManagerChangeUserPasswordResult) {
    }
}

export class AuthenticationControllerSetUserCustomerRequest {
    constructor(
        public id: string) {
    }
}

export class AuthenticationControllerSignInCognitoUserRequest {
    constructor(
        public redirectUrl: Optional<string>,
        public userMail: string,
        public userPassword: string) {
    }
}

export class AuthenticationControllerSignInCognitoUserResponse {
    constructor(
        public redirectUrl: Optional<string>,
        public result: AuthenticationControllerSignInCognitoUserResponseResult) {
    }
}

export class AuthenticationControllerSignInTeamsRequest {
    constructor(
        public rawJwtToken: string) {
    }
}

export class AuthenticationControllerSignInTeamsResponse {
    constructor(
        public error: Optional<AuthenticationControllerTeamsSignInError>) {
    }
}

export class AuthenticationControllerStartRecoverCognitoUserPasswordRequest {
    constructor(
        public userMail: string) {
    }
}

export class AuthenticationControllerStartRecoverCognitoUserPasswordResponse {
    constructor(
        public success: boolean) {
    }
}

export class AutomaticCustomEntityAttributeDefinitionTemplateModel {
    constructor(
        public configuration: AutomaticCustomEntityAttributeDefinitionTemplateConfiguration) {
    }
}

export class AutomationRuleDeliveryDestinationReference {
    constructor(
        public deliveryId: string,
        public identifier: string,
        public ruleId: string) {
    }
}

export class AutomationRuleDeliveryDestinationState {
    constructor(
        public sendCount: number,
        public sendCountResetDate: Optional<string>,
        public sendTime: Optional<string>) {
    }
}

export class AwsAccessGraphPermissionPathIdentifier extends AccessGraphPermissionPathIdentifier {
    constructor(
        public principalPolicyId: Optional<string>,
        public resourcePermitterType: Optional<AwsResourcePermitterType>) {
        super();
    }
}

export class AwsAccessListPermissionActionData extends AccessListPermissionActionData {
    constructor(
        excessive: boolean,
        public conditionTypeToConditionsConsistentHashesMap: Dictionary<string[]>,
        public permissionPathIdentifiers: AwsAccessGraphPermissionPathIdentifier[]) {
        super(excessive);
    }
}

export class AwsAccessPrincipalRiskSensitiveResources {
    constructor(
        public ids: string[],
        public sensitiveExcessivePermissionActions: string[]) {
    }
}

export class AwsApiGatewayV1ApiAuthorizer {
    constructor(
        public functionConfigurationArn: Optional<string>,
        public userPoolArns: string[]) {
    }
}

export class AwsApiGatewayV1ApiRouteIntegration {
    constructor(
        public action: Optional<string>,
        public arn: Optional<string>,
        public functionConfigurationArn: Optional<string>,
        public method: Optional<AwsApiGatewayApiRouteMethod>,
        public rawUrl: Optional<string>,
        public serviceId: Optional<string>,
        public type: AwsApiGatewayV1ApiRouteIntegrationType) {
    }
}

export class AwsApiGatewayV2ApiAuthorizer {
    constructor(
        public functionConfigurationArn: Optional<string>,
        public jwtIssuerUrl: Optional<string>) {
    }
}

export class AwsApiGatewayV2ApiIntegration {
    constructor(
        public action: Optional<string>,
        public methodType: Optional<AwsApiGatewayApiRouteMethod>,
        public rawUrl: Optional<string>,
        public resourceArn: Optional<string>,
        public serviceId: Optional<string>,
        public type: AwsApiGatewayV2ApiRouteIntegrationType) {
    }
}

export class AwsAuthenticationUser {
    constructor(
        public accessKeyEncryptedSecret: EncryptedData,
        public accessKeyUniqueId: string,
        public arn: string) {
    }
}

export class AwsAvailabilityZone {
    constructor(
        public name: string,
        public rawId: string) {
    }
}

export class AwsBatchComputeResource {
    constructor(
        public instanceRoleReference: Optional<AwsResourceReference>,
        public launchTemplateRevisionReference: Optional<AwsEc2LaunchTemplateRevisionReference>,
        public spotFleetServiceLinkedRoleReference: Optional<AwsResourceReference>,
        public tags: ResourceTag[]) {
    }
}

export class AwsBedrockAgentAlias {
    constructor(
        public creationTime: string,
        public description: Optional<string>,
        public id: string,
        public name: string,
        public revisionId: Optional<string>,
        public status: AwsBedrockAgentStatus,
        public updateTime: string) {
    }
}

export class AwsBedrockAgentRevision {
    constructor(
        public creationTime: string,
        public description: Optional<string>,
        public id: string,
        public kmsEncryptionKeyOrAliasArn: Optional<string>,
        public roleReference: AwsResourceReference,
        public status: AwsBedrockAgentStatus,
        public updateTime: string) {
    }
}

export class AwsCloudFormationStackOutput {
    constructor(
        public description: Optional<string>,
        public exportName: Optional<string>,
        public key: string,
        public value: Optional<string>) {
    }
}

export class AwsCloudFormationStackParameter {
    constructor(
        public defaultValue: Optional<string>,
        public key: string,
        public resolvedValue: Optional<string>,
        public value: Optional<string>) {
    }
}

export class AwsCloudFrontDistributionBehavior {
    constructor(
        public originIds: string[],
        public pathPattern: string,
        public precedence: number,
        public viewerProtocolPolicy: AwsCloudFrontDistributionBehaviorViewerProtocolPolicy) {
    }
}

export class AwsCloudFrontDistributionOrigin {
    constructor(
        public access: Optional<AwsCloudFrontDistributionOriginAccess>,
        public domainName: string,
        public id: string,
        public path: string,
        public protocolPolicy: Optional<AwsCloudFrontDistributionOriginProtocolPolicy>,
        public sslProtocols: Optional<AwsCloudFrontDistributionOriginSslProtocol[]>,
        public type: AwsCloudFrontDistributionOriginType) {
    }
}

export class AwsCloudTrailTrailAdvancedEventSelector implements IAwsCloudTrailTrailEventSelector {
    constructor(
        public fieldSelectors: AwsCloudTrailTrailAdvancedEventSelectorFieldSelector[],
        public managementEventEnabled: boolean,
        public name: Optional<string>,
        public rawResourceTypeSystemName: Optional<string>,
        public type: AwsCloudTrailTrailEventSelectorType) {
    }
}

export class AwsCloudTrailTrailAdvancedEventSelectorFieldSelector {
    constructor(
        public operatorToValuesMap: Dictionary<string[]>,
        public type: AwsCloudTrailTrailAdvancedEventSelectorFieldSelectorType) {
    }
}

export class AwsCloudTrailTrailBasicEventSelector implements IAwsCloudTrailTrailEventSelector {
    constructor(
        public dataResourceTypeSystemNameToArnPrefixesMap: Dictionary<string[]>,
        public excludeManagementEventServiceHostNames: string[],
        public managementEventEnabled: boolean,
        public type: AwsCloudTrailTrailEventSelectorType) {
    }
}

export class AwsEc2Device {
    constructor(
        public encryptionEnabled: Optional<boolean>,
        public instanceTerminationDeletion: Optional<boolean>,
        public kmsEncryptionKeyOrAliasArn: Optional<string>,
        public name: string,
        public size: Optional<number>,
        public snapshotRawId: Optional<string>) {
    }
}

export class AwsEc2InstanceMetadataService {
    constructor(
        public enabled: boolean,
        public tokenHopMaxCount: number,
        public version: AwsEc2InstanceMetadataServiceVersion) {
    }
}

export class AwsEc2InstanceTypeData {
    constructor(
        public bareMetal: boolean,
        public hypervisor: Optional<AwsEc2InstanceHypervisor>,
        public rawType: string) {
    }
}

export class AwsEc2LaunchTemplateModelRevisionData {
    constructor(
        public autoScalingGroupIds: string[],
        public imageId: Optional<string>,
        public instanceIds: string[]) {
    }
}

export class AwsEc2LaunchTemplateRevision {
    constructor(
        public creationTime: string,
        public creatorIdentityReference: AwsResourceReference,
        public description: Optional<string>,
        public devices: AwsEc2Device[],
        public id: number,
        public imageRawId: Optional<string>,
        public instanceProfileReference: Optional<AwsResourceReference>,
        public instanceRawType: Optional<string>,
        public keyName: Optional<string>,
        public metadataService: Optional<AwsEc2InstanceMetadataService>,
        public networkInterfaceIds: string[],
        public roleReference: Optional<AwsResourceReference>,
        public securityGroupIds: string[],
        public subnetIds: string[],
        public userData: Optional<string>,
        public vpcIds: string[]) {
    }
}

export class AwsEc2LaunchTemplateRevisionReference {
    constructor(
        public id: Optional<number>,
        public idType: AwsEc2LaunchTemplateRevisionIdType,
        public launchTemplateIdReference: string) {
    }
}

export class AwsEc2NetworkRule {
    constructor(
        public destinationPortRange: Optional<IntRange>,
        public icmp: Optional<AwsEc2NetworkRuleIcmp>,
        public protocolRange: IntRange) {
    }
}

export class AwsEc2NetworkRuleIcmp {
    constructor(
        public code: number,
        public type: number) {
    }
}

export class AwsEc2RouteTableRoute {
    constructor(
        public destinationPrefixListRawId: Optional<string>,
        public destinationSubnet: Optional<string>,
        public targetResourceId: Optional<string>,
        public targetResourceRawId: string,
        public valid: boolean) {
    }
}

export class AwsEc2SecurityGroupRule extends AwsEc2NetworkRule {
    constructor(
        destinationPortRange: Optional<IntRange>,
        icmp: Optional<AwsEc2NetworkRuleIcmp>,
        protocolRange: IntRange,
        public description: Optional<string>,
        public prefixListRawId: Optional<string>,
        public securityGroupId: Optional<string>,
        public securityGroupRawId: Optional<string>,
        public subnet: Optional<string>) {
        super(
            destinationPortRange,
            icmp,
            protocolRange);
    }
}

export class AwsEc2VpcBlockPublicAccessPolicyExclusion {
    constructor(
        public allowNetworkDirection: AwsEc2VpcBlockPublicAccessPolicyNetworkDirection,
        public creationTime: string,
        public name: Optional<string>) {
    }
}

export class AwsEcsTaskDefinitionRevision {
    constructor(
        public arn: string,
        public containerDefinitions: AwsEcsTaskDefinitionRevisionContainerDefinition[],
        public executionRoleReference: Optional<AwsResourceReference>,
        public networkMode: AwsEcsTaskDefinitionRevisionNetworkMode,
        public roleReference: Optional<AwsResourceReference>,
        public status: AwsEcsTaskDefinitionStatus,
        public tags: ResourceTag[]) {
    }
}

export class AwsEcsTaskDefinitionRevisionContainerDefinition {
    constructor(
        public environmentVariableNameToValueMap: Dictionary<string>,
        public imageRawUrl: string,
        public name: string,
        public portMappings: AwsEcsTaskDefinitionRevisionContainerDefinitionPortMapping[],
        public repositoryRawUri: string) {
    }
}

export class AwsEcsTaskDefinitionRevisionContainerDefinitionPortMapping {
    constructor(
        public containerPort: number,
        public hostPort: number,
        public protocolType: ProtocolType) {
    }
}

export class AwsEksClusterInstanceInboundExternalRiskInstanceData {
    constructor(
        public id: string,
        public inboundExternalWideRange: boolean) {
    }
}

export class AwsEksClusterNetwork {
    constructor(
        public authorizedSubnets: string[],
        public clusterAndNodeSecurityGroupIdReference: Optional<string>,
        public clusterSecurityGroupIdReferences: string[],
        public inboundExternal: boolean,
        public nodeInboundExternal: boolean,
        public serviceSubnet: Optional<string>,
        public subnetIds: string[],
        public vpcId: Optional<string>) {
    }
}

export class AwsEksClusterNodeGroupNode {
    constructor(
        public imageRawType: string,
        public imageRawVersion: string,
        public keyName: Optional<string>,
        public kubernetesTags: ResourceTag[],
        public kubernetesTaints: KubernetesTaint[],
        public launchTemplateRevisionReference: Optional<AwsEc2LaunchTemplateRevisionReference>,
        public rawTypes: string[],
        public roleReference: AwsResourceReference,
        public sshSecurityGroupEntityIdReferences: string[]) {
    }
}

export class AwsEksClusterNodeGroupNodeAutoscaling {
    constructor(
        public desiredCount: number,
        public maxCount: number,
        public minCount: number) {
    }
}

export class AwsEksFargateProfilePodSelector {
    constructor(
        public namespace: string,
        public tags: ResourceTag[]) {
    }
}

export class AwsElbLoadBalancerListenerSecurityPolicy {
    constructor(
        public name: Optional<string>,
        public sslProtocolNames: string[]) {
    }
}

export class AwsElbTargetGroupTarget {
    constructor(
        public applicationLoadBalancerId: Optional<string>,
        public functionConfigurationId: Optional<string>,
        public instanceId: Optional<string>,
        public ipAddress: Optional<string>,
        public port: Optional<number>) {
    }
}

export class AwsEmrClusterApplication {
    constructor(
        public name: string,
        public version: string) {
    }
}

export class AwsEventAssumeRole {
    constructor(
        public accessKeyUniqueId: string,
        public sessionName: string) {
    }
}

export class AwsExcessivePermissionPrincipalRiskModelServiceInfo {
    constructor(
        public id: string,
        public usageTime: Optional<string>) {
    }
}

export class AwsExcessivePermissionPrincipalRiskPolicyAssociationAndInfo {
    constructor(
        public policyAssociation: AwsIamPrincipalPolicyAssociation,
        public policyInfo: AwsIamPrincipalPolicyInfo) {
    }
}

export class AwsExcessivePermissionPrincipalRiskResolutionPolicyInfo {
    constructor(
        public existingDocument: Optional<AwsIamPolicyDocument>,
        public newDocument: Optional<AwsIamPolicyDocument>) {
    }
}

export class AwsExcessivePermissionResourceRiskSeverityInfo {
    constructor(
        public accessType: AwsExcessivePermissionResourceRiskAccessType,
        public existingPermissionActionIdentityIds: string[]) {
    }
}

export class AwsFolderConfigurationSections {
    constructor(
        public analysis: AwsFolderConfigurationAnalysis,
        public authentication: AwsFolderConfigurationAuthentication) {
    }
}

export class AwsIamAccessKey {
    constructor(
        public secret: string,
        public sessionToken: Optional<string>,
        public uniqueId: string) {
    }
}

export class AwsIamGroupProfileUsersInactive {
    constructor(
        public startTime: Optional<string>) {
    }
}

export class AwsIamManagedPolicyRevision {
    constructor(
        public creationTime: string,
        public document: AwsIamPolicyDocument,
        public id: string) {
    }
}

export class AwsIamPolicyDocument {
    constructor(
        public id: Optional<string>,
        public raw: string,
        public statements: AwsIamPolicyDocumentStatement[],
        public version: Optional<string>) {
    }
}

export class AwsIamPolicyDocumentStatement {
    constructor(
        public actionPatterns: Optional<string[]>,
        public conditions: Optional<AwsIamPolicyDocumentStatementConditions>,
        public effect: AwsIamPolicyDocumentStatementEffect,
        public grantees: Optional<AwsIamPolicyDocumentStatementGrantees>,
        public id: Optional<string>,
        public notActionPatterns: Optional<string[]>,
        public notGrantees: Optional<AwsIamPolicyDocumentStatementGrantees>,
        public notResourceArnPatterns: Optional<string[]>,
        public resourceArnPatterns: Optional<string[]>) {
    }
}

export class AwsIamPolicyDocumentStatementCondition {
    constructor(
        public operator: AwsIamPolicyDocumentStatementConditionOperator,
        public operatorPostfix: Optional<AwsIamPolicyDocumentStatementConditionOperatorPostfix>,
        public operatorPrefix: Optional<AwsIamPolicyDocumentStatementConditionOperatorPrefix>,
        public rawKey: string,
        public rawOperator: string,
        public values: string[]) {
    }
}

export class AwsIamPolicyDocumentStatementGrantees {
    constructor(
        public identityArnPatterns: string[],
        public identityProviderIds: string[],
        public identityUniqueIds: string[],
        public serviceHostNames: string[],
        public tenantCanonicalIds: string[]) {
    }
}

export class AwsIamPrincipalPolicyAssociation {
    constructor(
        public policyId: string,
        public principalId: string) {
    }
}

export class AwsIamPrincipalPolicyInfo {
    constructor(
        public document: AwsIamPolicyDocument,
        public name: string) {
    }
}

export class AwsIamRoleGroupPolicy {
    constructor(
        public displayName: string,
        public document: AwsIamPolicyDocument,
        public inlinePolicy: boolean) {
    }
}

export class AwsInboundExternalEc2InstanceRiskInstanceDataPublicAccess {
    constructor(
        public destinationNetworkScopes: DestinationNetworkScope[],
        public securityGroupIds: string[],
        public subnets: string[]) {
    }
}

export class AwsInboundExternalEc2InstanceRiskInstanceDataVulnerabilities {
    constructor(
        public rawIds: string[],
        public severity: Severity) {
    }
}

export class AwsInboundExternalSubnetRiskItem {
    constructor(
        public routeTableId: string,
        public subnetId: string,
        public vpcId: string) {
    }
}

export class AwsInboundExternalVpcRiskItem {
    constructor(
        public internetGatewayId: string,
        public vpcId: string) {
    }
}

export class AwsInboundExternalVpcRiskModelInternetGatewayAttachInfo {
    constructor(
        public identityIdReference: Optional<string>,
        public originatorEntityIdReference: Optional<string>,
        public time: string) {
    }
}

export class AwsKmsKeyGrant {
    constructor(
        public actions: string[],
        public constrainEncryptionContextEqualsMap: Optional<Dictionary<string>>,
        public constrainEncryptionContextSubsetMap: Optional<Dictionary<string>>,
        public creationTime: string,
        public granteeIdentityArn: Optional<string>,
        public granteeIdentityProviderId: Optional<string>,
        public granteeIdentityUniqueId: Optional<string>,
        public id: string,
        public issuingTenantArn: Optional<string>,
        public issuingTenantRawId: Optional<string>,
        public name: Optional<string>,
        public raw: string,
        public retiringIdentityArn: Optional<string>,
        public retiringIdentityProviderId: Optional<string>,
        public retiringIdentityUniqueId: Optional<string>) {
    }
}

export class AwsKubernetesNodeInstance {
    constructor(
        public id: Optional<string>,
        public nodeGroupId: Optional<string>) {
    }
}

export class AwsLambdaFunctionConfigurationAlias {
    constructor(
        public accessLevel: AwsResourceAccessLevel,
        public arn: string,
        public description: Optional<string>,
        public policyDocument: Optional<AwsIamPolicyDocument>,
        public policyDocumentAccessLevel: AwsResourceAccessLevel,
        public revisionIdToWeightMap: Dictionary<number>,
        public triggers: AwsLambdaFunctionConfigurationTriggers,
        public url: Optional<AwsLambdaFunctionConfigurationUrl>,
        public urlAccessLevel: AwsResourceAccessLevel) {
    }
}

export class AwsLambdaFunctionConfigurationPublicAccessExistsApiGatewayRouteExclusionRoutePattern {
    constructor(
        public caseSensitive: boolean,
        public message: Optional<string>,
        public methods: AwsApiGatewayApiRouteMethod[],
        public pathPattern: string) {
    }
}

export class AwsLambdaFunctionConfigurationRevision {
    constructor(
        public accessLevel: AwsResourceAccessLevel,
        public arn: string,
        public description: Optional<string>,
        public environmentVariableNameToValueMap: Dictionary<string>,
        public id: string,
        public kmsEncryptionKeyOrAliasArn: Optional<string>,
        public policyDocument: Optional<AwsIamPolicyDocument>,
        public roleReference: AwsResourceReference,
        public runtime: Optional<string>,
        public securityGroupIds: string[],
        public subnetIds: string[],
        public triggers: AwsLambdaFunctionConfigurationTriggers,
        public updateTime: string,
        public vpcId: Optional<string>) {
    }
}

export class AwsLambdaFunctionConfigurationTriggers {
    constructor(
        public externalTriggers: AwsLambdaFunctionConfigurationTriggersExternalTrigger[],
        public triggers: AwsLambdaFunctionConfigurationTriggersTrigger[]) {
    }
}

export class AwsLambdaFunctionConfigurationTriggersExternalTrigger {
    constructor(
        public identifier: string,
        public sourceName: string,
        public type: AwsLambdaFunctionConfigurationTriggersExternalTriggerType) {
    }
}

export class AwsLambdaFunctionConfigurationTriggersTrigger {
    constructor(
        public resourceArn: string,
        public resourceTypeName: string,
        public serviceId: string) {
    }
}

export class AwsLambdaFunctionConfigurationUrl {
    constructor(
        public authenticationEnabled: boolean,
        public url: string) {
    }
}

export class AwsLogsLogGroupMetricFilter {
    constructor(
        public metrics: AwsLogsLogGroupMetricFilterMetric[],
        public name: string,
        public pattern: Optional<string>) {
    }
}

export class AwsLogsLogGroupMetricFilterMetric {
    constructor(
        public name: string,
        public namespace: string) {
    }
}

export class AwsNetworkScope {
    constructor(
        public destinationPortRange: IntRange,
        public protocolRange: IntRange,
        public subnet: string) {
    }
}

export class AwsOrganizationAuditEventData {
    constructor(
        public enabled: boolean,
        public folderEnabled: boolean,
        public permissionTypes: CloudProviderTenantPermissionType[],
        public regionSystemNames: Optional<string[]>,
        public roleName: string,
        public trailName: Optional<string>) {
    }
}

export class AwsPrincipalAllowedPermissionRiskItem {
    constructor(
        public permissionActions: string[],
        public principalId: string,
        public principalPolicyIds: string[]) {
    }
}

export class AwsPrincipalNonexcessivePolicyGeneratorServiceLevel {
    constructor(
        public excessivePermissionServiceIds: string[],
        public hasExcessivePermissions: boolean,
        public nonexcessivePermissionServiceIds: string[]) {
    }
}

export class AwsPrincipalNonexcessivePolicyGeneratorServiceResourceLevel {
    constructor(
        public notUsedPermissionActionResourceIds: string[],
        public notUsedPermissionActions: string[],
        public notUsedResourceIds: string[],
        public permissionActions: string[],
        public resourceIds: string[],
        public serviceId: string,
        public usedPermissionActions: string[],
        public usedResourceIds: string[]) {
    }
}

export class AwsPrincipalResourcePermissionRiskItem {
    constructor(
        public permissionActions: string[],
        public principalId: string,
        public resourceId: string) {
    }
}

export class AwsResourceProductCode {
    constructor(
        public id: string,
        public type: AwsResourceProductCodeType) {
    }
}

export class AwsResourceProfileCreation {
    constructor(
        public eventIdentityReference: string,
        public eventOriginatorEntityReference: Optional<string>,
        public time: string) {
    }
}

export class AwsResourceReference {
    constructor(
        public arn: string,
        public idReference: string) {
    }
}

export class AwsRoute53HostedZoneRecord {
    constructor(
        public alias: boolean,
        public id: string,
        public name: string,
        public routingPolicy: AwsRoute53HostedZoneRecordRoutingPolicy,
        public type: AwsRoute53HostedZoneRecordType,
        public values: string[]) {
    }
}

export class AwsS3AccessControlEntry {
    constructor(
        public grantee: AwsS3AccessControlEntryGrantee,
        public permission: AwsS3AccessControlEntryPermission) {
    }
}

export class AwsS3AccessControlEntryGrantee {
    constructor(
        public groupUri: Optional<string>,
        public tenantCanonicalId: Optional<string>,
        public tenantMail: Optional<string>,
        public tenantName: Optional<string>,
        public type: AwsS3AccessControlEntryGranteeType) {
    }
}

export class AwsS3AccessControlList {
    constructor(
        public entries: AwsS3AccessControlEntry[],
        public logDeliveryGroupAccess: boolean,
        public otherTenantAccessCanonicalIds: string[],
        public publicAccessPermissionActions: string[],
        public tenantAccess: boolean,
        public tenantCanonicalId: string) {
    }
}

export class AwsS3PublicAccessPolicy {
    constructor(
        public blockAnyAcls: boolean,
        public blockAnyPolicy: boolean,
        public blockNewAcls: boolean,
        public blockNewPolicy: boolean) {
    }
}

export class AwsScopeConfigurationAnalysisEntities {
    constructor(
        public ecrRepositoryContainerImageScanInterval: Optional<string>,
        public ecrRepositoryContainerImageScanMaxCount: Optional<number>,
        public ecrRepositoryScanningConfiguration: Optional<AwsEcrRepositoryScanningConfiguration>,
        public lambdaFunctionConfigurationTriggerSyncDisabled: Optional<boolean>,
        public lambdaFunctionConfigurationTriggerSyncRateLimit: Optional<number>) {
    }
}

export class AwsScopeConfigurationOutpostRegionData {
    constructor(
        public availabilityZoneNames: string[],
        public customerRdsDbSubnetGroupName: Optional<string>,
        public customerSecurityGroupRawId: string) {
    }
}

export class AwsScopeConfigurationSections {
    constructor(
        public analysis: AwsScopeConfigurationAnalysis,
        public dataAnalysis: AwsScopeConfigurationDataAnalysis,
        public outpost: AwsScopeConfigurationOutpost,
        public workloadAnalysis: AwsScopeConfigurationWorkloadAnalysis) {
    }
}

export class AwsSnsTopicSubscription {
    constructor(
        public arn: string,
        public deliveryProtocol: AwsSnsDeliveryProtocol,
        public endpoint: string,
        public tenantRawId: string) {
    }
}

export class AwsSsoPermissionSetAssignmentEligibilityStatePermissionSet {
    constructor(
        public awsManagedPolicyIds: string[],
        public index: number,
        public inlinePolicyDocument: Optional<AwsIamPolicyDocument>,
        public namePrefix: string,
        public permissionRequestIds: string[]) {
    }
}

export class AwsSsoPermissionSetAssignmentRequestPermissions {
    constructor(
        public activeSessionTenantExists: boolean,
        public activeSessionTenantIds: string[],
        public permissionSetArn: string) {
    }
}

export class AwsSsoPermissionsSetCustomerManagedPolicyReference {
    constructor(
        public name: string,
        public path: string) {
    }
}

export class AwsSsoPermissionsSetPermissionsBoundary {
    constructor(
        public awsManagedPolicyId: Optional<string>,
        public customerManagedPolicyReference: Optional<AwsSsoPermissionsSetCustomerManagedPolicyReference>) {
    }
}

export class AwsTenantAuthenticationProviderConfigurationSectionPartitionData {
    constructor(
        public applicationRoleName: string,
        public applicationRoleTenantRawId: string,
        public applicationRoleUser: Optional<AwsAuthenticationUser>,
        public applicationTenantRawId: string) {
    }
}

export class AwsTenantConfigurationEvents {
    constructor(
        public originatorEntityElasticsearchResolutionEnabled: boolean,
        public syncTimeFrame: string,
        public syncWindowTimeFrame: string) {
    }
}

export class AwsTenantConfigurationFederation {
    constructor(
        public ssoUserIdentifierMail: boolean) {
    }
}

export class AwsTenantConfigurationPermissionManagement {
    constructor(
        public consoleUrl: Optional<string>) {
    }
}

export class AwsTenantConfigurationSections {
    constructor(
        public events: AwsTenantConfigurationEvents) {
    }
}

export class AwsTenantConfigurationTrail {
    constructor(
        public arn: string,
        public eventCategories: AwsTenantEventCategory[],
        public id: string) {
    }
}

export class AwsTenantConfigurationTrailBucket {
    constructor(
        public encryptionKeyArn: Optional<string>,
        public name: string) {
    }
}

export class AwsTenantEntityIam {
    constructor(
        public alias: Optional<string>,
        public passwordPolicy: Optional<AwsTenantEntityIamPasswordPolicy>) {
    }
}

export class AwsTenantEntityIamPasswordPolicy {
    constructor(
        public lowercaseCharactersRequired: boolean,
        public maxAge: Optional<number>,
        public minLength: Optional<number>,
        public numbersRequired: boolean,
        public preventReusePreviousPasswordCount: Optional<number>,
        public symbolsRequired: boolean,
        public uppercaseCharactersRequired: boolean) {
    }
}

export class AwsTenantEntityModelOrganizationsAccount {
    constructor(
        public id: string,
        public organizationJoinMethod: AwsOrganizationsAccountOrganizationJoinMethod,
        public parentEntityId: string,
        public rootUserMail: string,
        public status: AwsOrganizationsAccountStatus) {
    }
}

export class AwsTenantEntityS3 {
    constructor(
        public publicAccessPolicy: AwsS3PublicAccessPolicy) {
    }
}

export class AwsTenantStateCodeAnalysis {
    constructor(
        public terraformStateBucketNameToEncryptionKeyArnsMap: Dictionary<string[]>,
        public validTerraformStateBucketNameToObjectPathSuffixesMap: Dictionary<string[]>) {
    }
}

export class AwsTenantStateDataAnalysis {
    constructor(
        public regionSystemNameToNotDeletedRdsClusterSnapshotRawIdMap: Dictionary<string[]>,
        public regionSystemNameToNotDeletedRdsDatabaseInstanceSnapshotRawIdMap: Dictionary<string[]>) {
    }
}

export class AwsTenantStateEventAnalysis {
    constructor(
        public eventCategoryToTimeMap: Dictionary<string>,
        public trailBucketNameToEncryptionKeyArnsMap: Dictionary<string[]>,
        public unresolvedEventTime: Optional<string>,
        public validTrailMap: Dictionary<AwsTenantStateEventAnalysisTrail>) {
    }
}

export class AwsTenantStateEventAnalysisTrail {
    constructor(
        public bucketRegionSystemNameToLogObjectKeyMap: Dictionary<string>,
        public bucketTenantId: string,
        public eventCategories: AwsTenantEventCategory[]) {
    }
}

export class AwsTenantStateMonitoringTrailIssue {
    constructor(
        public trailArns: string[],
        public type: AwsTenantStateMonitoringTrailIssueType) {
    }
}

export class AwsTenantStateOrganization {
    constructor(
        public masterTenantRawId: string,
        public rawId: string,
        public ssoServiceDelegatedTenant: boolean) {
    }
}

export class AwsTenantStateWorkloadAnalysis {
    constructor(
        public accessDeniedNotDeletedInstancesArns: string[],
        public accessDeniedNotDeletedSnapshotArns: string[]) {
    }
}

export class AzureAccessGraphPermissionPathIdentifier extends AccessGraphPermissionPathIdentifier {
    constructor(
        public permitterType: AzurePermitterType,
        public roleAssignmentResourceTypeName: Optional<string>,
        public roleDefinitionId: Optional<string>,
        public scopeEntityId: Optional<string>) {
        super();
    }
}

export class AzureAccessListPermissionActionData extends AccessListPermissionActionData {
    constructor(
        excessive: boolean,
        public permissionPathIdentifiers: AzureAccessGraphPermissionPathIdentifier[]) {
        super(excessive);
    }
}

export class AzureAccessPrincipalRiskPermitters {
    constructor(
        public classicAdministrator: boolean,
        public directMembershipGroupIds: string[],
        public roleAssignmentIds: string[],
        public roleAssignmentResourceIds: string[],
        public roleAssignmentScheduleIds: string[],
        public roleEligibilityScheduleIds: string[]) {
    }
}

export class AzureAuthorizationPermission {
    constructor(
        public actionPatterns: string[],
        public dataActionPatterns: string[],
        public notActionPatterns: string[],
        public notDataActionPatterns: string[]) {
    }
}

export class AzureAuthorizationRoleManagementPolicyAssignmentActivation {
    constructor(
        public approvalRequired: boolean,
        public approverPrincipalIds: string[],
        public authenticationType: AzureAuthorizationRoleManagementPolicyAssignmentActivationAuthenticationType,
        public maxDuration: string) {
    }
}

export class AzureCognitiveServicesAccountStateNetworkFirewallRuleData {
    constructor(
        public firewallRuleSubnet: string,
        public inboundExternalNetworkScopes: AzureNetworkScope[]) {
    }
}

export class AzureComputeDiskResourceSource {
    constructor(
        public resourcePath: string,
        public type: AzureComputeDiskResourceSourceType) {
    }
}

export class AzureComputeImageInfo {
    constructor(
        public offer: string,
        public publisher: string,
        public sku: string) {
    }
}

export class AzureComputeVirtualMachineResourceSpecification {
    constructor(
        public imageInfo: Optional<AzureComputeImageInfo>,
        public imagePath: Optional<string>,
        public operatingSystemType: OperatingSystemType,
        public rawType: string) {
    }
}

export class AzureComputeVirtualMachineScaleSetVirtualMachineDisk {
    constructor(
        public encryptionVaultSecretUrl: Optional<string>,
        public operatingSystemType: Optional<OperatingSystemType>,
        public path: string,
        public size: Optional<number>) {
    }
}

export class AzureComputeVirtualMachineUnmanagedDisk {
    constructor(
        public name: string,
        public role: AzureComputeResourceDiskRole,
        public storageAccountId: Optional<string>,
        public url: string) {
    }
}

export class AzureContainerRegistryRegistryStateNetworkFirewallRuleData {
    constructor(
        public firewallRuleSubnet: string,
        public inboundExternalNetworkScopes: AzureNetworkScope[]) {
    }
}

export class AzureContainerServiceManagedClusterAgentPoolAutoscaling {
    constructor(
        public maxCount: number,
        public minCount: number) {
    }
}

export class AzureContainerServiceManagedClusterAgentPoolNode {
    constructor(
        public diskEncryption: boolean,
        public kubernetesTags: ResourceTag[],
        public kubernetesTaints: KubernetesTaint[],
        public publicIpAddressExists: boolean,
        public rawType: string) {
    }
}

export class AzureContainerServiceManagedClusterAuthentication {
    constructor(
        public identity: boolean,
        public kubernetesUser: boolean) {
    }
}

export class AzureContainerServiceManagedClusterAuthorization {
    constructor(
        public kubernetesAdministratorClusterRoleAadDirectoryGroupIds: string[],
        public kubernetesRoleBinding: boolean,
        public roleAssignment: boolean) {
    }
}

export class AzureContainerServiceManagedClusterNetwork {
    constructor(
        public authorizedSubnets: Optional<string[]>,
        public inboundExternal: boolean,
        public podInternalSubnet: Optional<string>,
        public serviceSubnet: string,
        public type: AzureContainerServiceManagedClusterNetworkType,
        public virtualNetworkId: Optional<string>) {
    }
}

export class AzureContainerServiceManagedClusterVirtualMachineInboundExternalRiskVirtualMachineData {
    constructor(
        public id: string,
        public inboundExternalWideRange: boolean) {
    }
}

export class AzureDocumentDbDatabaseAccountStateNetworkFirewallRuleData {
    constructor(
        public firewallRuleSubnet: string,
        public inboundExternalNetworkScopes: AzureNetworkScope[]) {
    }
}

export class AzureEventHubNamespaceStateNetworkFirewallRuleData {
    constructor(
        public firewallRuleSubnet: string,
        public inboundExternalNetworkScopes: AzureNetworkScope[]) {
    }
}

export class AzureExcessivePermissionPrincipalRiskScopeRoleAssignmentResourceData {
    constructor(
        public ids: string[],
        public resolution: AzureExcessivePermissionPrincipalRiskRoleAssignmentResourceResolution,
        public scopeEntityId: string) {
    }
}

export class AzureExcessivePermissionPrincipalRiskSensitiveResources {
    constructor(
        public ids: string[],
        public sensitiveExcessivePermissionActions: string[],
        public sensitiveRemovedExcessivePermissionActions: string[]) {
    }
}

export class AzureFolderConfigurationSections {
    constructor(
        public analysis: AzureFolderConfigurationAnalysis) {
    }
}

export class AzureKeyVaultVaultAccessPolicy {
    constructor(
        public applicationServicePrincipalReference: Optional<AadDirectoryPrincipalReference>,
        public certificatePermissions: AzureKeyVaultVaultCertificatePermission[],
        public keyPermissions: AzureKeyVaultVaultKeyPermission[],
        public principalReference: AadDirectoryPrincipalReference,
        public secretPermissions: AzureKeyVaultVaultSecretPermission[],
        public storageAccountPermissions: AzureKeyVaultVaultStorageAccountPermission[]) {
    }
}

export class AzureKeyVaultVaultCertificateVersion {
    constructor(
        public activationTime: Optional<string>,
        public creationTime: Optional<string>,
        public enabled: boolean,
        public expirationTime: Optional<string>,
        public issuerName: Optional<string>,
        public name: string,
        public serialNumber: Optional<string>,
        public subject: Optional<string>,
        public tags: ResourceTag[],
        public thumbprint: string,
        public updateTime: string,
        public url: string,
        public vaultKeyId: Optional<string>,
        public vaultSecretId: Optional<string>) {
    }
}

export class AzureKeyVaultVaultKeyInfo {
    constructor(
        public servicePrincipalId: Optional<string>,
        public urls: string[]) {
    }
}

export class AzureKeyVaultVaultKeyRotationPolicy {
    constructor(
        public creationTime: Optional<string>,
        public keyExpirationTime: Optional<string>,
        public rotationEnabled: boolean,
        public rotationTimeFrame: Optional<string>,
        public type: Optional<AzureKeyVaultVaultKeyRotationPolicyType>,
        public updateTime: Optional<string>) {
    }
}

export class AzureKeyVaultVaultKeyVersion {
    constructor(
        public activationTime: Optional<string>,
        public creationTime: Optional<string>,
        public ellipticCurveType: Optional<AzureKeyVaultVaultKeyEllipticCurveType>,
        public enabled: boolean,
        public expirationTime: Optional<string>,
        public keyOperations: Optional<AzureKeyVaultVaultKeyOperation[]>,
        public name: string,
        public rsaKeySize: Optional<number>,
        public tags: ResourceTag[],
        public type: AzureKeyVaultVaultKeyType,
        public updateTime: string,
        public url: string) {
    }
}

export class AzureKeyVaultVaultSecretVersion {
    constructor(
        public activationTime: Optional<string>,
        public creationTime: Optional<string>,
        public enabled: boolean,
        public expirationTime: Optional<string>,
        public name: string,
        public tags: ResourceTag[],
        public updateTime: Optional<string>,
        public url: string) {
    }
}

export class AzureKeyVaultVaultSoftDelete {
    constructor(
        public purgeProtection: boolean,
        public retentionTimeFrame: string) {
    }
}

export class AzureLogicWorkflowStateNetworkTriggerRuleData {
    constructor(
        public inboundExternalNetworkScopes: AzureNetworkScope[],
        public triggerRuleSubnet: string) {
    }
}

export class AzureManagedIdentityNotAllowedResourcePermissionRiskItem {
    constructor(
        public permissionActions: string[],
        public scopeResourceId: string) {
    }
}

export class AzureMySqlServerConfiguration {
    constructor(
        public description: string,
        public dynamic: Optional<boolean>,
        public name: string,
        public value: string) {
    }
}

export class AzureNetworkApplicationGatewayBackendAddressPool {
    constructor(
        public dnsNames: string[],
        public name: string,
        public path: string,
        public privateIpAddresses: string[],
        public publicIpAddresses: string[],
        public virtualMachineIds: string[],
        public virtualMachineScaleSetIds: string[]) {
    }
}

export class AzureNetworkApplicationGatewayRule {
    constructor(
        public listenerPort: number,
        public listenerPrivateIpAddress: Optional<string>,
        public listenerProtocol: AzureNetworkApplicationGatewayProtocol,
        public listenerPublicIpAddressId: Optional<string>,
        public name: string,
        public pathBasedRoutingPaths: Optional<string[]>,
        public pathBasedRoutingTargetName: Optional<string>) {
    }
}

export class AzureNetworkLoadBalancerBackendAddressPool {
    constructor(
        public displayName: string,
        public path: string,
        public virtualMachineIds: string[],
        public virtualMachineScaleSetIds: string[],
        public virtualNetworkIdToIpAddressesMap: Dictionary<string[]>) {
    }
}

export class AzureNetworkLoadBalancerFrontendIpConfiguration {
    constructor(
        public dnsName: Optional<string>,
        public ipAddressFamily: IpAddressFamily,
        public privateIpAddress: Optional<string>,
        public publicIpAddress: Optional<string>,
        public publicIpAddressId: Optional<string>,
        public publicIpPrefixId: Optional<string>) {
    }
}

export class AzureNetworkLoadBalancerRule {
    constructor(
        public backendPort: number,
        public floatingIp: Optional<boolean>,
        public frontendIpConfiguration: AzureNetworkLoadBalancerFrontendIpConfiguration,
        public frontendPort: number,
        public name: string,
        public path: string,
        public protocol: AzureNetworkLoadBalancerRuleProtocol) {
    }
}

export class AzureNetworkNetworkInterfaceIpConfiguration {
    constructor(
        public applicationGatewayBackendAddressPoolPaths: string[],
        public applicationSecurityGroupIds: string[],
        public dnsName: Optional<string>,
        public loadBalancerBackendAddressPoolPaths: string[],
        public loadBalancerInboundNatRulePaths: string[],
        public privateIpAddress: Optional<string>,
        public publicIpAddress: Optional<string>,
        public publicIpAddressId: Optional<string>) {
    }
}

export class AzureNetworkNetworkSecurityGroupRule {
    constructor(
        public action: AzureNetworkNetworkSecurityGroupRuleAction,
        public destination: AzureNetworkNetworkSecurityGroupRuleEntity,
        public name: string,
        public path: string,
        public priority: number,
        public protocolRange: IntRange,
        public source: AzureNetworkNetworkSecurityGroupRuleEntity) {
    }
}

export class AzureNetworkNetworkSecurityGroupRuleEntity {
    constructor(
        public any: boolean,
        public applicationSecurityGroupIds: string[],
        public portRanges: IntRange[],
        public serviceTag: Optional<string>,
        public subnets: string[]) {
    }
}

export class AzureNetworkRouteTableRoute {
    constructor(
        public destinationIpAddress: Optional<string>,
        public name: string,
        public serviceTag: Optional<string>,
        public subnet: Optional<string>,
        public type: AzureNetworkRouteTableRouteType) {
    }
}

export class AzureOrganizationAuditEventData {
    constructor(
        public enabled: boolean,
        public folderEnabled: boolean,
        public permissionTypes: CloudProviderTenantPermissionType[]) {
    }
}

export class AzurePostgreSqlServerConfiguration {
    constructor(
        public description: string,
        public dynamic: Optional<boolean>,
        public name: string,
        public value: string) {
    }
}

export class AzurePrincipalNotAllowedResourceRoleDefinitionRiskItem {
    constructor(
        public roleAssignmentResourceIds: string[],
        public scopeResourceId: string) {
    }
}

export class AzurePrincipalNotAllowedResourceRoleDefinitionRiskPolicyConfigurationRoleDefinitions {
    constructor(
        public ids: string[],
        public operator: AzurePrincipalNotAllowedResourceRoleDefinitionRiskPolicyConfigurationRoleDefinitionsOperator) {
    }
}

export class AzurePrincipalResourcePermissionRiskItem {
    constructor(
        public permissionActions: string[],
        public principalId: string,
        public resourceId: string,
        public roleAssignmentResourceIds: string[]) {
    }
}

export class AzureResourceFirewallIpAddressRangeRule {
    constructor(
        public endIpAddress: string,
        public name: string,
        public startIpAddress: string) {
    }
}

export class AzureResourceFirewallVirtualNetworkSubnetRule {
    constructor(
        public path: string,
        public state: Optional<AzureNetworkServiceEndpointState>) {
    }
}

export class AzureResourceProfileCreation {
    constructor(
        public identityReference: Optional<string>,
        public time: string) {
    }
}

export class AzureScopeConfigurationOutpostData {
    constructor(
        public availabilityZoneNames: string[],
        public resourceGroupName: string,
        public singleAvailabilityZone: boolean,
        public storageAccounts: AzureScopeConfigurationOutpostDataStorageAccount[],
        public virtualNetwork: AzureScopeConfigurationOutpostDataVirtualNetwork) {
    }
}

export class AzureScopeConfigurationOutpostDataStorageAccount {
    constructor(
        public blobContainerName: string,
        public name: string) {
    }
}

export class AzureScopeConfigurationOutpostDataVirtualNetwork {
    constructor(
        public name: string,
        public privateSubnetNames: string[],
        public resourceGroupName: string) {
    }
}

export class AzureScopeConfigurationSections {
    constructor(
        public dataAnalysis: AzureScopeConfigurationDataAnalysis,
        public outpost: AzureScopeConfigurationOutpost,
        public workloadAnalysis: AzureScopeConfigurationWorkloadAnalysis) {
    }
}

export class AzureScopeEntityReference {
    constructor(
        public id: Optional<string>,
        public path: string) {
    }
}

export class AzureStorageResourceStateNetworkFirewallRuleData {
    constructor(
        public firewallRule: AzureResourceFirewallIpAddressRangeRule,
        public inboundExternalNetworkScopes: AzureNetworkScope[]) {
    }
}

export class AzureStorageStorageAccountStateNetworkFirewallRuleData {
    constructor(
        public firewallRuleSubnet: string,
        public inboundExternalNetworkScopes: AzureNetworkScope[]) {
    }
}

export class AzureTenantConfigurationEvents {
    constructor(
        public syncTimeFrame: string,
        public syncWindowSubWindowTimeFrame: string,
        public syncWindowTimeFrame: string) {
    }
}

export class AzureTenantConfigurationSections {
    constructor(
        public events: AzureTenantConfigurationEvents) {
    }
}

export class AzureTenantStateCodeAnalysis {
    constructor(
        public terraformStateStorageAccountNameToBlobContainerNamesMap: Dictionary<string[]>) {
    }
}

export class AzureTenantStateEvents {
    constructor(
        public syncTime: Optional<string>,
        public time: Optional<string>) {
    }
}

export class AzureWebApplicationNetworkRule {
    constructor(
        public action: AzureWebApplicationNetworkRuleAction,
        public name: Optional<string>,
        public priority: Optional<number>,
        public serviceTags: string[],
        public sourceSubnetId: Optional<string>,
        public sourceSubnets: Optional<string[]>) {
    }
}

export class BuiltInComplianceSection {
    constructor(
        public sections: Optional<BuiltInComplianceSection[]>,
        public type: BuiltInComplianceSectionType) {
    }
}

export class ChangeModel {
    constructor(
        public change: Change,
        public changeable: boolean,
        public changeDatas: ChangeModelChangeData[],
        public resolvable: boolean,
        public resolved: boolean) {
    }
}

export class ChangeModelChangeData {
    constructor(
        public id: string,
        public riskId: string,
        public riskTenantId: string) {
    }
}

export class CiScopeConfigurationSections {
    constructor(
        public outpost: CiScopeConfigurationOutpost,
        public workloadAnalysis: CiScopeConfigurationWorkloadAnalysis) {
    }
}

export class CodeCommit {
    constructor(
        public branchName: Optional<string>,
        public hash: string,
        public identityName: string) {
    }
}

export class CodeContainerImageScanFilters {
    constructor(
        public codeBranchNameSelection: Optional<PagedItemSelection<string>>,
        public codeCommitIdentityNameSelection: Optional<PagedItemSelection<string>>,
        public containerConfigurationDigestSection: Optional<PagedItemSelection<string>>,
        public digestSection: Optional<PagedItemSelection<string>>,
        public integrationIdSelection: Optional<ItemSelection<string>>,
        public operatingSystemDisplayName: Optional<PagedItemSelection<string>>,
        public packageDisplayNameSelection: Optional<PagedItemSelection<string>>,
        public repositoryRawUrlSelection: Optional<PagedItemSelection<string>>,
        public success: Optional<boolean>,
        public tagSection: Optional<PagedItemSelection<string>>,
        public tenantIdSelection: Optional<ItemSelection<string>>,
        public timeRangeSelection: Optional<TimeRangeSelection>,
        public vulnerabilityRawIdSection: Optional<PagedItemSelection<string>>,
        public vulnerabilitySeveritySelection: Optional<ItemSelection<Severity>>) {
    }
}

export class CodeControllerGetContainerImagePackagePageResponse {
    constructor(
        public packagePage: ItemPage<CodeContainerImagePackage>) {
    }
}

export class CodeControllerGetContainerImageScanExistsResponse {
    constructor(
        public scanExists: boolean) {
    }
}

export class CodeControllerGetContainerImageScanFilterItemPageRequest {
    constructor(
        public limit: number,
        public property: CodeControllerGetContainerImageScanPageRequestProperty,
        public searchText: Optional<string>,
        public skip: number) {
    }
}

export class CodeControllerGetContainerImageScanFilterItemPageResponse {
    constructor(
        public scanFilterItemPage: ElasticsearchFilterItemPage<string>) {
    }
}

export class CodeControllerGetContainerImageScanFiltersResponse {
    constructor(
        public filters: CodeScanFilters) {
    }
}

export class CodeControllerGetContainerImageScanPackageFilterItemPageRequest {
    constructor(
        public limit: number,
        public property: CodeControllerGetContainerImageScanPackagePageRequestProperty,
        public scanId: string,
        public searchText: Optional<string>,
        public skip: number) {
    }
}

export class CodeControllerGetContainerImageScanPackageFilterItemPageResponse {
    constructor(
        public packageFilterItemPage: ElasticsearchFilterItemPage<string>) {
    }
}

export class CodeControllerGetContainerImageScanPackagePageRequest {
    constructor(
        public filters: CodeControllerGetContainerImageScanPackagePageRequestFilters,
        public limit: number,
        public scanId: string,
        public skip: number,
        public sort: CodeControllerGetContainerImageScanPackagePageRequestSort) {
    }
}

export class CodeControllerGetContainerImageScanPackagePageRequestFilters {
    constructor(
        public displayNameSelection: Optional<PagedItemSelection<string>>,
        public filePathSelection: Optional<PagedItemSelection<string>>,
        public typeSelection: Optional<ItemSelection<WorkloadResourceScanPackageType>>,
        public vulnerabilityRawIdSelection: Optional<PagedItemSelection<string>>) {
    }
}

export class CodeControllerGetContainerImageScanPackagePageRequestSort {
    constructor(
        public direction: SortDirection,
        public property: CodeControllerGetContainerImageScanPackagePageRequestProperty) {
    }
}

export class CodeControllerGetContainerImageScanPackageRequest {
    constructor(
        public id: string) {
    }
}

export class CodeControllerGetContainerImageScanPackageResponse {
    constructor(
        public package_: CodeContainerImagePackage) {
    }
}

export class CodeControllerGetContainerImageScanPageRequest {
    constructor(
        public filters: CodeContainerImageScanFilters,
        public limit: number,
        public skip: number,
        public sort: CodeControllerGetContainerImageScanPageRequestSort) {
    }
}

export class CodeControllerGetContainerImageScanPageRequestSort {
    constructor(
        public direction: SortDirection,
        public property: CodeControllerGetContainerImageScanPageRequestProperty) {
    }
}

export class CodeControllerGetContainerImageScanPageResponse {
    constructor(
        public scanPage: ItemPage<CodeContainerImageScan>) {
    }
}

export class CodeControllerGetContainerImageScansRequest {
    constructor(
        public ids: string[]) {
    }
}

export class CodeControllerGetContainerImageScansResponse {
    constructor(
        public scans: CodeContainerImageScan[]) {
    }
}

export class CodeControllerGetContainerImageScanVulnerabilityFilterItemPageRequest {
    constructor(
        public limit: number,
        public property: CodeControllerGetContainerImageScanVulnerabilityPageRequestProperty,
        public scanId: string,
        public searchText: Optional<string>,
        public skip: number) {
    }
}

export class CodeControllerGetContainerImageScanVulnerabilityFilterItemPageResponse {
    constructor(
        public vulnerabilityFilterItemPage: ElasticsearchFilterItemPage<string>) {
    }
}

export class CodeControllerGetContainerImageScanVulnerabilityPageRequest {
    constructor(
        public filters: CodeControllerGetContainerImageScanVulnerabilityPageRequestFilters,
        public limit: number,
        public scanId: string,
        public skip: number,
        public sort: CodeControllerGetContainerImageScanVulnerabilityPageRequestSort) {
    }
}

export class CodeControllerGetContainerImageScanVulnerabilityPageRequestFilters {
    constructor(
        public attackVectorSelection: Optional<ItemSelection<VulnerabilityAttackVector>>,
        public exploitable: Optional<boolean>,
        public packageDisplayNamesSelection: Optional<PagedItemSelection<string>>,
        public packageFilePathsSelection: Optional<PagedItemSelection<string>>,
        public rawIdSelection: Optional<PagedItemSelection<string>>,
        public severitySelection: Optional<ItemSelection<Severity>>,
        public vprSeveritySelection: Optional<ItemSelection<Severity>>) {
    }
}

export class CodeControllerGetContainerImageScanVulnerabilityPageRequestSort {
    constructor(
        public direction: SortDirection,
        public property: CodeControllerGetContainerImageScanVulnerabilityPageRequestProperty) {
    }
}

export class CodeControllerGetContainerImageScanVulnerabilityRequest {
    constructor(
        public id: string) {
    }
}

export class CodeControllerGetContainerImageScanVulnerabilityResponse {
    constructor(
        public vulnerability: CodeContainerImageVulnerability) {
    }
}

export class CodeControllerGetContainerImageVulnerabilityPageResponse {
    constructor(
        public vulnerabilityPage: ItemPage<CodeContainerImageVulnerability>) {
    }
}

export class CodeControllerGetDashboardSummaryResponse {
    constructor(
        public summary: Optional<CodeControllerGetDashboardSummaryResponseSummary>) {
    }
}

export class CodeControllerGetDashboardSummaryResponseSummary {
    constructor(
        public entityTypeEntitiesViewNameToCountMap: Dictionary<number>,
        public riskTenantIdToSeverityToRiskCountMap: Dictionary<Dictionary<number>>,
        public severityToRiskPolicyCategoryToCountMap: Dictionary<Dictionary<number>>) {
    }
}

export class CodeControllerGetIacRiskFilterItemPageRequest {
    constructor(
        public limit: number,
        public property: CodeControllerGetIacRiskPageRequestProperty,
        public scanId: string,
        public searchText: Optional<string>,
        public skip: number) {
    }
}

export class CodeControllerGetIacRiskFilterItemPageResponse {
    constructor(
        public riskFilterItemPage: ElasticsearchFilterItemPage<string>) {
    }
}

export class CodeControllerGetIacRiskFiltersRequest {
    constructor(
        public scanId: string) {
    }
}

export class CodeControllerGetIacRiskFiltersResponse {
    constructor(
        public filters: CodeIacRiskFilters) {
    }
}

export class CodeControllerGetIacRiskPageRequest {
    constructor(
        public filters: CodeControllerGetIacRiskPageRequestFilters,
        public limit: number,
        public scanId: string,
        public skip: number,
        public sort: CodeControllerGetIacRiskPageRequestSort) {
    }
}

export class CodeControllerGetIacRiskPageRequestFilters {
    constructor(
        public categorySelection: Optional<ItemSelection<RiskPolicyCategory>>,
        public cloudTenantTypeSelection: Optional<ItemSelection<TenantType>>,
        public complianceIdentifierSelection: Optional<ItemSelection<string>>,
        public fileRelativePathSelection: Optional<PagedItemSelection<string>>,
        public newRisks: Optional<boolean>,
        public resourceNameSelection: Optional<PagedItemSelection<string>>,
        public resourceTypeSystemNameSelection: Optional<PagedItemSelection<string>>,
        public riskPolicyConfigurationTypeNameSelection: Optional<ItemSelection<string>>,
        public severitySelection: Optional<ItemSelection<Severity>>) {
    }
}

export class CodeControllerGetIacRiskPageRequestSort {
    constructor(
        public direction: SortDirection,
        public property: CodeControllerGetIacRiskPageRequestProperty) {
    }
}

export class CodeControllerGetIacRiskPageResponse {
    constructor(
        public riskPage: ItemPage<CodeIacRisk>) {
    }
}

export class CodeControllerGetIacRiskRequest {
    constructor(
        public id: string) {
    }
}

export class CodeControllerGetIacRiskResponse {
    constructor(
        public risk: CodeIacRisk) {
    }
}

export class CodeControllerGetIacScanExistsResponse {
    constructor(
        public scanExists: boolean) {
    }
}

export class CodeControllerGetIacScanFilterItemPageRequest {
    constructor(
        public limit: number,
        public property: CodeControllerGetIacScanPageRequestProperty,
        public searchText: Optional<string>,
        public skip: number) {
    }
}

export class CodeControllerGetIacScanFilterItemPageResponse {
    constructor(
        public scanFilterItemPage: ElasticsearchFilterItemPage<string>) {
    }
}

export class CodeControllerGetIacScanFiltersResponse {
    constructor(
        public filters: CodeScanFilters) {
    }
}

export class CodeControllerGetIacScanPageRequest {
    constructor(
        public filters: CodeControllerGetIacScanPageRequestFilters,
        public limit: number,
        public skip: number,
        public sort: CodeControllerGetIacScanPageRequestSort) {
    }
}

export class CodeControllerGetIacScanPageRequestFilters {
    constructor(
        public codeTypeSelection: Optional<ItemSelection<CodeType>>,
        public commitBranchNameSelection: Optional<PagedItemSelection<string>>,
        public commitIdentityNameSelection: Optional<PagedItemSelection<string>>,
        public integrationIdSelection: Optional<ItemSelection<string>>,
        public relativePathSelection: Optional<PagedItemSelection<string>>,
        public riskChange: Optional<boolean>,
        public severitySelection: Optional<ItemSelection<Severity>>,
        public success: Optional<boolean>,
        public tenantIdSelection: Optional<ItemSelection<string>>,
        public timeRangeSelection: Optional<TimeRangeSelection>) {
    }
}

export class CodeControllerGetIacScanPageRequestSort {
    constructor(
        public direction: SortDirection,
        public property: CodeControllerGetIacScanPageRequestProperty) {
    }
}

export class CodeControllerGetIacScanPageResponse {
    constructor(
        public scanPage: ItemPage<CodeIacScan>) {
    }
}

export class CodeControllerGetIacScanRequest {
    constructor(
        public id: string) {
    }
}

export class CodeControllerGetIacScanResponse {
    constructor(
        public scan: CodeIacScan) {
    }
}

export class CodeControllerGetResourcesRequest {
    constructor(
        public ids: string[]) {
    }
}

export class CodeControllerGetResourcesResponse {
    constructor(
        public datas: CodeResourceData[]) {
    }
}

export class CodeFileDiff {
    constructor(
        public codeType: CodeType,
        public existingContent: string,
        public newContent: string,
        public relativePath: string) {
    }
}

export class CodeIacScanStats {
    constructor(
        public newRiskCount: number,
        public resolvedRiskCount: number,
        public riskSeverityToCountMap: Dictionary<number>) {
    }
}

export class CodeResourceData {
    constructor(
        public blockDatas: CodeResourceDataBlockData[],
        public commit: CodeCommit,
        public id: string,
        public systemCreationTime: string,
        public tenantId: string) {
    }
}

export class CodeResourceDataBlockData {
    constructor(
        public block: CodeBlock,
        public fileRelativePath: string,
        public snippet: TextBlock) {
    }
}

export class CodeRiskSnippet {
    constructor(
        public commit: CodeCommit,
        public external: boolean,
        public fileRelativePath: string,
        public resourceName: string,
        public resourceTypeSystemName: string,
        public riskedPropertyPath: string,
        public tenantEntityData: CodeTenantEntityData,
        public textBlock: TextBlock) {
    }
}

export class CodeScanPipeline {
    constructor(
        public executionId: string,
        public executionUrl: string) {
    }
}

export class CodeTenantEntityData {
    constructor(
        public name: Optional<string>,
        public organizationName: Optional<string>,
        public projectName: Optional<string>,
        public serverRawEndpoint: Optional<string>,
        public type: CodeTenantType,
        public url: string) {
    }
}

export class ComplianceData {
    constructor(
        public enabled: boolean,
        public hasResults: boolean,
        public stats: Optional<ComplianceDataStats>,
        public timeFrameToStatsMap: Optional<Dictionary<Optional<ComplianceDataStats>>>,
        public typeName: string) {
    }
}

export class ComplianceDataStats {
    constructor(
        public count: number,
        public securedCount: number,
        public securedPercentage: number,
        public unsecuredSeverityToCountMap: Dictionary<number>) {
    }
}

export class ComplianceRiskPolicyData extends ComplianceData {
    constructor(
        enabled: boolean,
        hasResults: boolean,
        stats: Optional<ComplianceDataStats>,
        timeFrameToStatsMap: Optional<Dictionary<Optional<ComplianceDataStats>>>,
        typeName: string,
        public analysisGroupType: RiskPolicyTypeMetadataAnalysisGroupType,
        public failedEntityCount: number,
        public riskPolicyConfigurationTypeName: string,
        public riskPolicyIdentifier: string) {
        super(
            enabled,
            hasResults,
            stats,
            timeFrameToStatsMap,
            typeName);
    }
}

export class ComplianceSectionData extends ComplianceData {
    constructor(
        enabled: boolean,
        hasResults: boolean,
        stats: Optional<ComplianceDataStats>,
        timeFrameToStatsMap: Optional<Dictionary<Optional<ComplianceDataStats>>>,
        typeName: string,
        public custom: boolean,
        public identifier: string,
        public riskPolicyDatas: ComplianceRiskPolicyData[],
        public scopeId: string,
        public sectionDatas: ComplianceSectionData[]) {
        super(
            enabled,
            hasResults,
            stats,
            timeFrameToStatsMap,
            typeName);
    }
}

export class ConfigurationControllerDeleteAnalysisNetworkRequest {
    constructor(
        public subnet: string) {
    }
}

export class ConfigurationControllerDeleteApiKeyRequest {
    constructor(
        public id: string,
        public scopeId: string) {
    }
}

export class ConfigurationControllerDeleteAutomaticCustomEntityAttributeDefinitionTemplateRequest {
    constructor(
        public id: string) {
    }
}

export class ConfigurationControllerDeleteAutomationRuleRequest {
    constructor(
        public id: string) {
    }
}

export class ConfigurationControllerDeleteAzureDevOpsOrganizationRequest {
    constructor(
        public organizationId: string) {
    }
}

export class ConfigurationControllerDeleteAzureDevOpsOrganizationResponse {
    constructor(
        public deletedTenantIds: string[]) {
    }
}

export class ConfigurationControllerDeleteBitbucketOrganizationRequest {
    constructor(
        public organizationId: string) {
    }
}

export class ConfigurationControllerDeleteBitbucketOrganizationResponse {
    constructor(
        public deletedTenantIds: string[]) {
    }
}

export class ConfigurationControllerDeleteCiCloudsmithIntegrationRequest {
    constructor(
        public integrationId: string) {
    }
}

export class ConfigurationControllerDeleteCiCloudsmithIntegrationResponse {
    constructor(
        public deletedTenantIds: string[]) {
    }
}

export class ConfigurationControllerDeleteCiGeneralIntegrationRequest {
    constructor(
        public integrationId: string) {
    }
}

export class ConfigurationControllerDeleteCiGeneralIntegrationResponse {
    constructor(
        public deletedTenantIds: string[]) {
    }
}

export class ConfigurationControllerDeleteCiJfrogIntegrationRequest {
    constructor(
        public integrationId: string) {
    }
}

export class ConfigurationControllerDeleteCiJfrogIntegrationResponse {
    constructor(
        public deletedTenantIds: string[]) {
    }
}

export class ConfigurationControllerDeleteCodePipelineRequest {
    constructor(
        public id: string) {
    }
}

export class ConfigurationControllerDeleteCustomComplianceRequest {
    constructor(
        public id: string) {
    }
}

export class ConfigurationControllerDeleteDatadogOrganizationRequest {
    constructor(
        public id: string) {
    }
}

export class ConfigurationControllerDeleteEndpointConnectorRequest {
    constructor(
        public id: string) {
    }
}

export class ConfigurationControllerDeleteEntityPropertyDefinitionRequest {
    constructor(
        public id: string) {
    }
}

export class ConfigurationControllerDeleteGitHubOrganizationRequest {
    constructor(
        public organizationId: string) {
    }
}

export class ConfigurationControllerDeleteGitHubOrganizationResponse {
    constructor(
        public deletedTenantIds: string[]) {
    }
}

export class ConfigurationControllerDeleteGitHubServerRequest {
    constructor(
        public serverId: string) {
    }
}

export class ConfigurationControllerDeleteGitHubServerResponse {
    constructor(
        public deletedOrganizationIds: string[],
        public deletedTenantIds: string[]) {
    }
}

export class ConfigurationControllerDeleteGitLabOrganizationRequest {
    constructor(
        public organizationId: string) {
    }
}

export class ConfigurationControllerDeleteGitLabOrganizationResponse {
    constructor(
        public deletedTenantIds: string[]) {
    }
}

export class ConfigurationControllerDeleteGitLabServerRequest {
    constructor(
        public serverId: string) {
    }
}

export class ConfigurationControllerDeleteGitLabServerResponse {
    constructor(
        public deletedOrganizationIds: string[],
        public deletedTenantIds: string[]) {
    }
}

export class ConfigurationControllerDeleteGroupRoleAssignmentRequest {
    constructor(
        public identifier: string,
        public role: IdentityRole,
        public scopeId: string) {
    }
}

export class ConfigurationControllerDeleteJiraInstanceRequest {
    constructor(
        public id: string) {
    }
}

export class ConfigurationControllerDeleteJiraProjectRequest {
    constructor(
        public instanceId: string,
        public rawId: string) {
    }
}

export class ConfigurationControllerDeleteKubernetesClusterConnectorRequest {
    constructor(
        public id: string) {
    }
}

export class ConfigurationControllerDeleteManualCustomEntityAttributeDefinitionRequest {
    constructor(
        public id: string) {
    }
}

export class ConfigurationControllerDeletePermissionEligibilityRequest {
    constructor(
        public id: string) {
    }
}

export class ConfigurationControllerDeleteProjectRequest {
    constructor(
        public id: string) {
    }
}

export class ConfigurationControllerDeleteQRadarServerRequest {
    constructor(
        public id: string) {
    }
}

export class ConfigurationControllerDeleteReportScheduleRequest {
    constructor(
        public id: string) {
    }
}

export class ConfigurationControllerDeleteScopeSettingConfigurationRequest {
    constructor(
        public id: string) {
    }
}

export class ConfigurationControllerDeleteServiceNowInstanceRequest {
    constructor(
        public id: string) {
    }
}

export class ConfigurationControllerDeleteSlackWorkspaceRequest {
    constructor(
        public id: string) {
    }
}

export class ConfigurationControllerDeleteSplunkEventCollectorRequest {
    constructor(
        public id: string) {
    }
}

export class ConfigurationControllerDeleteSqsQueueRequest {
    constructor(
        public id: string) {
    }
}

export class ConfigurationControllerDeleteTeamsOrganizationRequest {
    constructor(
        public id: string) {
    }
}

export class ConfigurationControllerDeleteUserRoleAssignmentRequest {
    constructor(
        public mail: string,
        public role: IdentityRole,
        public scopeId: string) {
    }
}

export class ConfigurationControllerDeleteWebhookEndpointRequest {
    constructor(
        public id: string) {
    }
}

export class ConfigurationControllerDeleteWorkloadAnalysisTrustedFileRequest {
    constructor(
        public contentSha256String: string,
        public scopeId: string) {
    }
}

export class ConfigurationControllerDeleteWorkloadAnalysisTrustedFileResponse {
    constructor(
        public trustedFiles: ScopeConfigurationWorkloadAnalysisTrustedFile[]) {
    }
}

export class ConfigurationControllerGetAddableScopeSettingConfigurationTypeNamesRequest {
    constructor(
        public scopeId: string,
        public typeName: string) {
    }
}

export class ConfigurationControllerGetAddableScopeSettingConfigurationTypeNamesResponse {
    constructor(
        public typeNames: string[]) {
    }
}

export class ConfigurationControllerGetAnalysisNetworkResponse {
    constructor(
        public internalSubnetToNameMap: Dictionary<string>) {
    }
}

export class ConfigurationControllerGetApiKeysRequest {
    constructor(
        public childScopeApiKeys: boolean,
        public scopeId: string) {
    }
}

export class ConfigurationControllerGetApiKeysResponse {
    constructor(
        public apiKeyDatas: ConfigurationControllerGetApiKeysResponseApiKeyData[]) {
    }
}

export class ConfigurationControllerGetApiKeysResponseApiKeyData {
    constructor(
        public apiKey: ApiKey,
        public role: IdentityRole,
        public scopeId: string,
        public state: ApiKeyState) {
    }
}

export class ConfigurationControllerGetAuthenticationConfigurationResponse {
    constructor(
        public aadIdentityProviderEnabled: boolean,
        public consoleAppTypeToSamlIdentityProviderMap: Dictionary<ApplicationCustomerConfigurationAuthenticationSamlIdentityProvider>,
        public gciIdentityProviderEnabled: boolean) {
    }
}

export class ConfigurationControllerGetAutomaticCustomEntityAttributeDefinitionTemplateModelsResponse {
    constructor(
        public automaticCustomEntityAttributeDefinitionTemplateModels: AutomaticCustomEntityAttributeDefinitionTemplateModel[]) {
    }
}

export class ConfigurationControllerGetAutomaticCustomEntityAttributeDefinitionTemplateUsageRequest {
    constructor(
        public id: string) {
    }
}

export class ConfigurationControllerGetAutomaticCustomEntityAttributeDefinitionTemplateUsageResponse {
    constructor(
        public automationRuleConfigurationIds: string[],
        public customRiskPolicyConfigurationIds: string[],
        public reportScheduleConfigurationIds: string[]) {
    }
}

export class ConfigurationControllerGetAwsOrganizationOnboardingInfoRequest {
    constructor(
        public partitionType: AwsPartitionType) {
    }
}

export class ConfigurationControllerGetAwsOrganizationOnboardingInfoRootRequest {
    constructor(
        public masterTenantId: string) {
    }
}

export class ConfigurationControllerGetAwsOrganizationOnboardingInfoRootResponse {
    constructor(
        public rootRawId: string) {
    }
}

export class ConfigurationControllerGetAwsRegionsResponse {
    constructor(
        public partitionTypeToRegionSystemNamesMap: Dictionary<string[]>) {
    }
}

export class ConfigurationControllerGetAzureDevOpsOrganizationOnboardingApplicationInfoResponse {
    constructor(
        public applicationRawId: string) {
    }
}

export class ConfigurationControllerGetAzureDevOpsOrganizationOnboardingOrganizationsInfoRequest {
    constructor(
        public aadTenantRawId: string,
        public accessToken: string) {
    }
}

export class ConfigurationControllerGetAzureDevOpsOrganizationOnboardingOrganizationsInfoResponse {
    constructor(
        public organizationNames: string[]) {
    }
}

export class ConfigurationControllerGetAzureDevOpsOrganizationOnboardingTenantsInfoRequest {
    constructor(
        public aadTenantRawId: string,
        public accessToken: Optional<string>,
        public organizationName: string) {
    }
}

export class ConfigurationControllerGetAzureDevOpsOrganizationOnboardingTenantsInfoResponse {
    constructor(
        public tenantPaths: string[]) {
    }
}

export class ConfigurationControllerGetBitbucketOrganizationOnboardingTenantsInfoRequest {
    constructor(
        public accessToken: Optional<string>,
        public organizationId: Optional<string>) {
    }
}

export class ConfigurationControllerGetBitbucketOrganizationOnboardingTenantsInfoResponse {
    constructor(
        public tenantPaths: string[]) {
    }
}

export class ConfigurationControllerGetCodePipelineApiRootUrlResponse {
    constructor(
        public apiRootUrl: string) {
    }
}

export class ConfigurationControllerGetCustomEntityAttributeDefinitionModelsResponse {
    constructor(
        public customEntityAttributeDefinitionModels: CustomEntityAttributeDefinitionModel[]) {
    }
}

export class ConfigurationControllerGetCustomEntityAttributeDefinitionUsageRequest {
    constructor(
        public id: string) {
    }
}

export class ConfigurationControllerGetCustomEntityAttributeDefinitionUsageResponse {
    constructor(
        public automationRuleConfigurationIds: string[],
        public customRiskPolicyConfigurationIds: string[],
        public reportScheduleConfigurationIds: string[]) {
    }
}

export class ConfigurationControllerGetEndpointConnectorApiKeyTokenRequest {
    constructor(
        public id: string) {
    }
}

export class ConfigurationControllerGetEndpointConnectorApiKeyTokenResponse {
    constructor(
        public apiKeyToken: string) {
    }
}

export class ConfigurationControllerGetEndpointConnectorIdToIntegrationNameMapResponse {
    constructor(
        public endpointConnectorIdToIntegrationNameMap: Dictionary<string>) {
    }
}

export class ConfigurationControllerGetEndpointConnectorTunnelSessionClientIdsResponse {
    constructor(
        public endpointConnectorTunnelSessionClientIds: string[]) {
    }
}

export class ConfigurationControllerGetEntityPropertyIdentifierUsageRequest {
    constructor(
        public identifier: EntityPropertyIdentifier) {
    }
}

export class ConfigurationControllerGetEntityPropertyIdentifierUsageResponse {
    constructor(
        public customRiskPolicyConfigurationIds: string[],
        public deletable: boolean,
        public reportScheduleConfigurationIds: string[]) {
    }
}

export class ConfigurationControllerGetFeaturesResponse {
    constructor(
        public featureNameToEnabledMap: Dictionary<boolean>) {
    }
}

export class ConfigurationControllerGetGcpOrganizationOnboardingInfoResponse {
    constructor(
        public pubSubMessageRetentionDayCount: number,
        public serviceAccountDataAnalysisPermissionActions: string[],
        public serviceAccountManagementRoleRawIdToNameMap: Dictionary<string>,
        public serviceAccountOrganizationReadOnlyPermissionActions: string[],
        public serviceAccountOrganizationReadOnlyRoleRawIdToNameMap: Dictionary<string>,
        public serviceAccountPermissionManagementRoleRawIdToNameMap: Dictionary<string>,
        public serviceAccountReadOnlyRoleRawIdToNameMap: Dictionary<string>,
        public serviceAccountWorkloadAnalysisPermissionActions: string[],
        public sinkExclusionFilter: string,
        public sinkInclusionFilter: string) {
    }
}

export class ConfigurationControllerGetGitHubOrganizationOnboardingApplicationInfoResponse {
    constructor(
        public applicationName: string,
        public defaultServerConfiguration: GitHubServerConfiguration) {
    }
}

export class ConfigurationControllerGetGitHubOrganizationOnboardingOrganizationsInfoRequest {
    constructor(
        public accessToken: string,
        public serverId: Optional<string>) {
    }
}

export class ConfigurationControllerGetGitHubOrganizationOnboardingOrganizationsInfoResponse {
    constructor(
        public organizationNames: string[]) {
    }
}

export class ConfigurationControllerGetGitHubOrganizationOnboardingTenantsInfoRequest {
    constructor(
        public accessToken: Optional<string>,
        public organizationName: string,
        public serverId: Optional<string>) {
    }
}

export class ConfigurationControllerGetGitHubOrganizationOnboardingTenantsInfoResponse {
    constructor(
        public tenantPaths: string[]) {
    }
}

export class ConfigurationControllerGetGitHubServerOnboardingApplicationInfoResponse {
    constructor(
        public applicationName: string) {
    }
}

export class ConfigurationControllerGetGitLabOrganizationOnboardingTenantsInfoRequest {
    constructor(
        public accessToken: Optional<string>,
        public organizationId: Optional<string>,
        public serverId: Optional<string>) {
    }
}

export class ConfigurationControllerGetGitLabOrganizationOnboardingTenantsInfoResponse {
    constructor(
        public tenantPaths: string[]) {
    }
}

export class ConfigurationControllerGetJiraRawProjectDataRequest {
    constructor(
        public instanceId: string,
        public rawId: string) {
    }
}

export class ConfigurationControllerGetJiraRawProjectDataResponse {
    constructor(
        public issueTypeNameToStatusesMap: Dictionary<JiraStatus[]>,
        public priorities: JiraPriority[]) {
    }
}

export class ConfigurationControllerGetKubernetesClusterConnectorIdToClusterIdsMapResponse {
    constructor(
        public kubernetesClusterConnectorIdToClusterIdsMap: Dictionary<string[]>) {
    }
}

export class ConfigurationControllerGetLicensingDataRequest {
    constructor(
        public scopeId: string) {
    }
}

export class ConfigurationControllerGetLicensingDataResponse {
    constructor(
        public dateToObjectCountMap: Dictionary<number>,
        public evaluationLicenseExpirationTime: Optional<string>,
        public licenseVersion: ApplicationCustomerConfigurationLicensingLicenseVersion,
        public objectCountAverage: Optional<number>,
        public objectTypeToCountMap: Optional<Dictionary<number>>,
        public subscriptionLicenseExpirationTime: Optional<string>,
        public subscriptionLicenseObjectCount: Optional<number>) {
    }
}

export class ConfigurationControllerGetLicensingExportDataRequest {
    constructor(
        public scopeId: string) {
    }
}

export class ConfigurationControllerGetLicensingExportDataResponse {
    constructor(
        public tenantIdToDataMap: Optional<Dictionary<ConfigurationControllerGetLicensingExportDataResponseTenantData>>) {
    }
}

export class ConfigurationControllerGetLicensingExportDataResponseTenantData {
    constructor(
        public average: Optional<number>,
        public count: Optional<number>) {
    }
}

export class ConfigurationControllerGetLicensingResponse {
    constructor(
        public activeEvaluationLicenseTypes: ApplicationCustomerConfigurationLicensingLicenseType[],
        public activeSubscriptionLicenseTypes: ApplicationCustomerConfigurationLicensingLicenseType[],
        public visible: boolean) {
    }
}

export class ConfigurationControllerGetOrganizationMemberDatasRequest {
    constructor() {
    }
}

export class ConfigurationControllerGetOrganizationMemberDatasResponse {
    constructor(
        public node: ConfigurationControllerGetOrganizationMemberDatasResponseNode) {
    }
}

export class ConfigurationControllerGetOrganizationMemberDatasResponseNode {
    constructor(
        public childrenNodes: ConfigurationControllerGetOrganizationMemberDatasResponseNode[],
        public isRoot: boolean,
        public isTenant: boolean,
        public name: string,
        public rawId: string) {
    }
}

export class ConfigurationControllerGetPrincipalRoleAssignmentsRequest {
    constructor(
        public childScopeRoles: boolean,
        public scopeId: string) {
    }
}

export class ConfigurationControllerGetPrincipalRoleAssignmentsResponse {
    constructor(
        public aadGroups: boolean,
        public authorizationDomainNames: string[],
        public groups: Group[],
        public principalRoleAssignments: ConfigurationControllerGetPrincipalRoleAssignmentsResponsePrincipalRoleAssignment[],
        public users: ConfigurationControllerGetPrincipalRoleAssignmentsResponseUser[]) {
    }
}

export class ConfigurationControllerGetPrincipalRoleAssignmentsResponsePrincipalRoleAssignment {
    constructor(
        public principalDisplayName: string,
        public principalGroup: boolean,
        public principalIdentifier: string,
        public role: IdentityRole,
        public scopeId: string) {
    }
}

export class ConfigurationControllerGetPrincipalRoleAssignmentsResponseUser {
    constructor(
        public id: string,
        public mail: string,
        public signInTime: Optional<string>) {
    }
}

export class ConfigurationControllerGetProjectConfigurationRulesMatchingTenantIdsRequest {
    constructor(
        public projectConfigurationRules: ProjectConfigurationRule[]) {
    }
}

export class ConfigurationControllerGetProjectConfigurationRulesMatchingTenantIdsResponse {
    constructor(
        public tenantIds: string[]) {
    }
}

export class ConfigurationControllerGetReportDefinitionsResponse {
    constructor(
        public reportDefinitionConfigurations: ReportDefinitionConfiguration[]) {
    }
}

export class ConfigurationControllerGetScopePathCustomComplianceIdsRequest {
    constructor(
        public scopeId: string) {
    }
}

export class ConfigurationControllerGetScopePathCustomComplianceIdsResponse {
    constructor(
        public customComplianceIds: string[]) {
    }
}

export class ConfigurationControllerGetScopeSettingConfigurationDatasRequest {
    constructor(
        public childScopeSettings: boolean,
        public effective: boolean,
        public scopeId: string,
        public typeName: string) {
    }
}

export class ConfigurationControllerGetScopeSettingConfigurationDatasResponse {
    constructor(
        public scopeSettingConfigurationDatas: ScopeSettingConfigurationData[]) {
    }
}

export class ConfigurationControllerGetScopeSystemEntityModelsRequest {
    constructor(
        public ids: Optional<string[]>) {
    }
}

export class ConfigurationControllerGetScopeSystemEntityModelsResponse {
    constructor(
        public scopeSystemEntityModels: ScopeSystemEntityModel[]) {
    }
}

export class ConfigurationControllerGetSlackWorkspaceIdToOAuthAuthorizationUrlMapRequest {
    constructor(
        public scopeId: string) {
    }
}

export class ConfigurationControllerGetSlackWorkspaceIdToOAuthAuthorizationUrlMapResponse {
    constructor(
        public workspaceIdToOAuthAuthorizationUrlMap: Dictionary<string>) {
    }
}

export class ConfigurationControllerGetSlackWorkspaceOnboardingInfoRequest {
    constructor(
        public scopeId: string) {
    }
}

export class ConfigurationControllerGetSlackWorkspaceOnboardingInfoResponse {
    constructor(
        public oAuthAuthorizationUrl: string) {
    }
}

export class ConfigurationControllerGetTeamsOrganizationOnboardingInfoRequest {
    constructor(
        public scopeId: string) {
    }
}

export class ConfigurationControllerGetTeamsOrganizationOnboardingInfoResponse {
    constructor(
        public applicationRawId: string) {
    }
}

export class ConfigurationControllerGetTerraformCloudScanApiUrlResponse {
    constructor(
        public scanApiUrl: string) {
    }
}

export class ConfigurationControllerGetWorkloadAnalysisOperatingSystemEndOfLifeTimeFrameResponse {
    constructor(
        public operatingSystemEndOfLifeTimeFrame: string) {
    }
}

export class ConfigurationControllerGetWorkloadAnalysisVulnerabilityScoreTypeResponse {
    constructor(
        public vulnerabilityScoreType: VulnerabilityScoreType) {
    }
}

export class ConfigurationControllerGetWorkspaceIdToChannelRawIdToNameMapResponse {
    constructor(
        public workspaceIdToChannelRawIdToNameMap: Dictionary<Dictionary<string>>) {
    }
}

export class ConfigurationControllerInsertApiKeyRequest {
    constructor(
        public name: string,
        public role: IdentityRole,
        public scopeId: string) {
    }
}

export class ConfigurationControllerInsertApiKeyResponse {
    constructor(
        public token: string) {
    }
}

export class ConfigurationControllerInsertAzureDevOpsOrganizationRequest {
    constructor(
        public aadTenantRawId: string,
        public accessToken: string,
        public folderEnabled: boolean,
        public memberSelection: Optional<OrganizationMemberSelection>,
        public organizationName: string,
        public syncEnabled: boolean) {
    }
}

export class ConfigurationControllerInsertAzureDevOpsOrganizationResponse {
    constructor(
        public folderScopeNodeModel: ScopeNodeModel,
        public organizationModel: CodeOrganizationModel) {
    }
}

export class ConfigurationControllerInsertBitbucketOrganizationRequest {
    constructor(
        public accessToken: string,
        public folderEnabled: boolean,
        public memberSelection: Optional<OrganizationMemberSelection>,
        public syncEnabled: boolean) {
    }
}

export class ConfigurationControllerInsertBitbucketOrganizationResponse {
    constructor(
        public folderScopeNodeModel: ScopeNodeModel,
        public organizationModel: CodeOrganizationModel) {
    }
}

export class ConfigurationControllerInsertCiCloudsmithIntegrationRequest {
    constructor(
        public endpointConnectorId: Optional<string>,
        public folderEnabled: boolean,
        public name: string,
        public rawEndpoint: Optional<string>,
        public serverCertificateValidationEnabled: boolean,
        public token: string) {
    }
}

export class ConfigurationControllerInsertCiCloudsmithIntegrationResponse {
    constructor(
        public integrationModel: CiIntegrationModel) {
    }
}

export class ConfigurationControllerInsertCiGeneralIntegrationRequest {
    constructor(
        public endpointConnectorId: Optional<string>,
        public folderEnabled: boolean,
        public name: string,
        public rawEndpoint: Optional<string>,
        public serverCertificateValidationEnabled: boolean,
        public token: string,
        public userName: string) {
    }
}

export class ConfigurationControllerInsertCiGeneralIntegrationResponse {
    constructor(
        public integrationModel: CiIntegrationModel) {
    }
}

export class ConfigurationControllerInsertCiJfrogIntegrationRequest {
    constructor(
        public endpointConnectorId: Optional<string>,
        public folderEnabled: boolean,
        public name: string,
        public rawEndpoint: Optional<string>,
        public serverCertificateValidationEnabled: boolean,
        public token: string,
        public userName: string) {
    }
}

export class ConfigurationControllerInsertCiJfrogIntegrationResponse {
    constructor(
        public integrationModel: CiIntegrationModel) {
    }
}

export class ConfigurationControllerInsertCodePipelineRequest {
    constructor(
        public name: string,
        public type: CodePipelineType) {
    }
}

export class ConfigurationControllerInsertDatadogOrganizationRequest {
    constructor(
        public apiKey: string,
        public name: string,
        public scopeId: string,
        public site: DatadogOrganizationConfigurationSite) {
    }
}

export class ConfigurationControllerInsertEndpointConnectorRequest {
    constructor(
        public endpoint: string,
        public name: string,
        public scopeId: string) {
    }
}

export class ConfigurationControllerInsertGitHubOrganizationRequest {
    constructor(
        public accessToken: string,
        public folderEnabled: boolean,
        public memberSelection: Optional<OrganizationMemberSelection>,
        public organizationName: string,
        public serverId: Optional<string>,
        public syncEnabled: boolean) {
    }
}

export class ConfigurationControllerInsertGitHubOrganizationResponse {
    constructor(
        public folderScopeNodeModel: ScopeNodeModel,
        public organizationModel: CodeOrganizationModel) {
    }
}

export class ConfigurationControllerInsertGitHubServerRequest {
    constructor(
        public applicationClientId: string,
        public applicationClientSecret: string,
        public applicationId: number,
        public applicationOrganizationName: string,
        public applicationPrivateKey: string,
        public endpointConnectorId: Optional<string>,
        public name: string,
        public rawEndpoint: Optional<string>,
        public serverCertificateValidationEnabled: boolean) {
    }
}

export class ConfigurationControllerInsertGitHubServerResponse {
    constructor(
        public codeServerModel: CodeServerModel) {
    }
}

export class ConfigurationControllerInsertGitLabOrganizationRequest {
    constructor(
        public accessToken: string,
        public folderEnabled: boolean,
        public memberSelection: Optional<OrganizationMemberSelection>,
        public serverId: Optional<string>,
        public syncEnabled: boolean) {
    }
}

export class ConfigurationControllerInsertGitLabOrganizationResponse {
    constructor(
        public folderScopeNodeModel: ScopeNodeModel,
        public organizationModel: CodeOrganizationModel) {
    }
}

export class ConfigurationControllerInsertGitLabServerRequest {
    constructor(
        public endpointConnectorId: Optional<string>,
        public name: string,
        public rawEndpoint: Optional<string>,
        public serverCertificateValidationEnabled: boolean) {
    }
}

export class ConfigurationControllerInsertGitLabServerResponse {
    constructor(
        public codeServerModel: CodeServerModel) {
    }
}

export class ConfigurationControllerInsertGroupRoleAssignmentRequest {
    constructor(
        public identifier: string,
        public name: Optional<string>,
        public role: IdentityRole,
        public scopeId: string) {
    }
}

export class ConfigurationControllerInsertOciOrganizationRequest {
    constructor(
        public folderEnabled: boolean,
        public homeRegionSystemName: string,
        public memberSelection: Optional<OrganizationMemberSelection>,
        public ocid: string,
        public permissionTypes: CloudProviderTenantPermissionType[],
        public userOcid: string,
        public userPrivateKeyPem: string,
        public userPublicKeyFingerprint: string) {
    }
}

export class ConfigurationControllerInsertOciOrganizationResponse {
    constructor(
        public organizationModel: OciOrganizationModel,
        public organizationTenantNames: string[]) {
    }
}

export class ConfigurationControllerInsertPermissionEligibilityRequest {
    constructor(
        public approval: Optional<ConfigurationControllerPermissionEligibilityApproval>,
        public description: Optional<string>,
        public expirationTimeFrame: string,
        public granteePrincipalIds: string[],
        public name: string,
        public principalTenantId: string,
        public reasonRequired: boolean,
        public scopeId: string) {
    }
}

export class ConfigurationControllerInsertProjectRequest {
    constructor(
        public description: string,
        public name: string,
        public parentScopeId: string,
        public rules: ProjectConfigurationRule[]) {
    }
}

export class ConfigurationControllerInsertProjectResponse {
    constructor(
        public projectModel: ProjectModel) {
    }
}

export class ConfigurationControllerInsertQRadarServerRequest {
    constructor(
        public endpointConnectorId: Optional<string>,
        public hostName: Optional<string>,
        public name: string,
        public port: Optional<number>,
        public scopeId: string,
        public serverCertificateValidationEnabled: boolean) {
    }
}

export class ConfigurationControllerInsertUserRoleAssignmentRequest {
    constructor(
        public mail: string,
        public role: IdentityRole,
        public scopeId: string) {
    }
}

export class ConfigurationControllerInsertWebhookEndpointRequest {
    constructor(
        public httpHeaders: WebhookManagerHttpHeader[],
        public name: string,
        public scopeId: string,
        public serverCertificateValidationEnabled: boolean,
        public url: string) {
    }
}

export class ConfigurationControllerInsertWorkloadAnalysisTrustedFilesRequest {
    constructor(
        public comment: Optional<string>,
        public contentSha256Strings: string[],
        public scopeId: string) {
    }
}

export class ConfigurationControllerInsertWorkloadAnalysisTrustedFilesResponse {
    constructor(
        public notAddedRequestTrustedFiles: ScopeConfigurationWorkloadAnalysisTrustedFile[],
        public trustedFiles: ScopeConfigurationWorkloadAnalysisTrustedFile[]) {
    }
}

export class ConfigurationControllerPermissionEligibilityApproval {
    constructor(
        public levelToApproverPrincipalIdsMap: Dictionary<string[]>,
        public mails: string[],
        public slackChannelReferences: SlackConversationReference[],
        public teamsChannelReferences: TeamsChannelReference[]) {
    }
}

export class ConfigurationControllerSetOwnerRequest {
    constructor(
        public userMail: string) {
    }
}

export class ConfigurationControllerUpdateAuthenticationConfigurationRequest {
    constructor(
        public aadIdentityProviderEnabled: boolean,
        public consoleAppTypeToSamlIdentityProviderMap: Dictionary<ApplicationCustomerConfigurationAuthenticationSamlIdentityProvider>,
        public gciIdentityProviderEnabled: boolean) {
    }
}

export class ConfigurationControllerUpdateAzureDevOpsOrganizationRequest {
    constructor(
        public folderEnabled: boolean,
        public memberSelection: Optional<OrganizationMemberSelection>,
        public organizationId: string,
        public syncEnabled: boolean) {
    }
}

export class ConfigurationControllerUpdateAzureDevOpsOrganizationResponse {
    constructor(
        public organizationModel: CodeOrganizationModel) {
    }
}

export class ConfigurationControllerUpdateAzureWorkloadAnalysisRequest {
    constructor(
        public defaultDiskSnapshotResourceGroup: Optional<boolean>,
        public diskSnapshotResourceGroupId: Optional<string>,
        public scopeId: string) {
    }
}

export class ConfigurationControllerUpdateAzureWorkloadAnalysisResponse {
    constructor(
        public defaultDiskSnapshotResourceGroup: Optional<boolean>,
        public diskSnapshotResourceGroupId: Optional<string>) {
    }
}

export class ConfigurationControllerUpdateBitbucketOrganizationRequest {
    constructor(
        public accessToken: Optional<string>,
        public folderEnabled: boolean,
        public memberSelection: Optional<OrganizationMemberSelection>,
        public organizationId: string,
        public syncEnabled: boolean) {
    }
}

export class ConfigurationControllerUpdateBitbucketOrganizationResponse {
    constructor(
        public organizationModel: CodeOrganizationModel) {
    }
}

export class ConfigurationControllerUpdateCiCloudsmithIntegrationRequest {
    constructor(
        public folderEnabled: boolean,
        public integrationId: string,
        public name: Optional<string>,
        public serverCertificateValidationEnabled: Optional<boolean>,
        public token: Optional<string>) {
    }
}

export class ConfigurationControllerUpdateCiCloudsmithIntegrationResponse {
    constructor(
        public integrationModel: CiIntegrationModel) {
    }
}

export class ConfigurationControllerUpdateCiGeneralIntegrationRequest {
    constructor(
        public folderEnabled: boolean,
        public integrationId: string,
        public name: Optional<string>,
        public serverCertificateValidationEnabled: Optional<boolean>,
        public token: Optional<string>,
        public userName: Optional<string>) {
    }
}

export class ConfigurationControllerUpdateCiGeneralIntegrationResponse {
    constructor(
        public integrationModel: CiIntegrationModel) {
    }
}

export class ConfigurationControllerUpdateCiJfrogIntegrationRequest {
    constructor(
        public folderEnabled: boolean,
        public integrationId: string,
        public name: Optional<string>,
        public serverCertificateValidationEnabled: Optional<boolean>,
        public token: Optional<string>,
        public userName: Optional<string>) {
    }
}

export class ConfigurationControllerUpdateCiJfrogIntegrationResponse {
    constructor(
        public integrationModel: CiIntegrationModel) {
    }
}

export class ConfigurationControllerUpdateCodePipelineRequest {
    constructor(
        public id: string,
        public name: string) {
    }
}

export class ConfigurationControllerUpdateCodePipelineResponse {
    constructor(
        public apiKeyToken: string,
        public scopeSystemEntityModel: ScopeSystemEntityModel) {
    }
}

export class ConfigurationControllerUpdateCodeRequest {
    constructor(
        public failureRiskPolicyMinSeverity: Optional<Severity>,
        public failureRiskPolicyMinSeverityEnabled: Optional<boolean>,
        public scopeId: string) {
    }
}

export class ConfigurationControllerUpdateDataAnalysisRequest {
    constructor(
        public managedDatabaseScanEnabled: Optional<boolean>,
        public objectStoreScanEnabled: Optional<boolean>,
        public privateObjectStoreScanEnabled: Optional<boolean>,
        public scopeId: string) {
    }
}

export class ConfigurationControllerUpdateDatadogOrganizationRequest {
    constructor(
        public apiKey: Optional<string>,
        public id: string,
        public name: Optional<string>,
        public site: Optional<DatadogOrganizationConfigurationSite>) {
    }
}

export class ConfigurationControllerUpdateGitHubOrganizationRequest {
    constructor(
        public folderEnabled: boolean,
        public memberSelection: Optional<OrganizationMemberSelection>,
        public organizationId: string,
        public syncEnabled: boolean) {
    }
}

export class ConfigurationControllerUpdateGitHubOrganizationResponse {
    constructor(
        public organizationModel: CodeOrganizationModel) {
    }
}

export class ConfigurationControllerUpdateGitHubServerRequest {
    constructor(
        public applicationClientId: string,
        public applicationClientSecret: Optional<string>,
        public applicationId: number,
        public applicationPrivateKey: Optional<string>,
        public id: string,
        public name: string,
        public serverCertificateValidationEnabled: boolean) {
    }
}

export class ConfigurationControllerUpdateGitHubServerResponse {
    constructor(
        public codeServerModel: CodeServerModel) {
    }
}

export class ConfigurationControllerUpdateGitLabOrganizationRequest {
    constructor(
        public accessToken: Optional<string>,
        public folderEnabled: boolean,
        public memberSelection: Optional<OrganizationMemberSelection>,
        public organizationId: string,
        public syncEnabled: boolean) {
    }
}

export class ConfigurationControllerUpdateGitLabOrganizationResponse {
    constructor(
        public organizationModel: CodeOrganizationModel) {
    }
}

export class ConfigurationControllerUpdateGitLabServerRequest {
    constructor(
        public id: string,
        public name: string,
        public serverCertificateValidationEnabled: boolean) {
    }
}

export class ConfigurationControllerUpdateGitLabServerResponse {
    constructor(
        public codeServerModel: CodeServerModel) {
    }
}

export class ConfigurationControllerUpdateGroupRoleAssignmentRequest {
    constructor(
        public existingRole: IdentityRole,
        public groupId: string,
        public groupName: Optional<string>,
        public role: IdentityRole,
        public scopeId: string) {
    }
}

export class ConfigurationControllerUpdateOciOrganizationRequest {
    constructor(
        public enabled: boolean,
        public folderEnabled: boolean,
        public id: string,
        public memberSelection: Optional<OrganizationMemberSelection>,
        public permissionTypes: CloudProviderTenantPermissionType[],
        public user: Optional<ConfigurationControllerUpdateOciOrganizationRequestUser>) {
    }
}

export class ConfigurationControllerUpdateOciOrganizationRequestUser {
    constructor(
        public ocid: string,
        public privateKeyPem: string,
        public publicKeyFingerprint: string) {
    }
}

export class ConfigurationControllerUpdateOciOrganizationResponse {
    constructor(
        public organizationModel: OciOrganizationModel,
        public organizationTenantNames: string[]) {
    }
}

export class ConfigurationControllerUpdatePermissionEligibilityRequest {
    constructor(
        public approval: Optional<ConfigurationControllerPermissionEligibilityApproval>,
        public description: Optional<string>,
        public expirationTimeFrame: string,
        public granteePrincipalIds: string[],
        public id: string,
        public name: string,
        public principalTenantId: string,
        public reasonRequired: boolean) {
    }
}

export class ConfigurationControllerUpdatePermissionManagementSlackEnabledRequest {
    constructor(
        public enabled: boolean) {
    }
}

export class ConfigurationControllerUpdatePermissionManagementTeamsEnabledRequest {
    constructor(
        public enabled: boolean) {
    }
}

export class ConfigurationControllerUpdateProjectRequest {
    constructor(
        public description: Optional<string>,
        public id: string,
        public name: Optional<string>,
        public parentScopeId: Optional<string>,
        public rules: Optional<ProjectConfigurationRule[]>) {
    }
}

export class ConfigurationControllerUpdateProjectResponse {
    constructor(
        public projectModel: ProjectModel) {
    }
}

export class ConfigurationControllerUpdateQRadarServerRequest {
    constructor(
        public endpointConnectorId: Optional<string>,
        public hostName: Optional<string>,
        public id: string,
        public name: string,
        public port: Optional<number>,
        public serverCertificateValidationEnabled: boolean) {
    }
}

export class ConfigurationControllerUpdateSlackWorkspacesPermissionManagementEnabledRequest {
    constructor(
        public permissionManagementEnabledWorkspaceIds: string[]) {
    }
}

export class ConfigurationControllerUpdateUserRoleAssignmentRequest {
    constructor(
        public existingRole: IdentityRole,
        public role: IdentityRole,
        public scopeId: string,
        public userId: string) {
    }
}

export class ConfigurationControllerUpdateWebhookEndpointRequest {
    constructor(
        public httpHeaders: WebhookManagerHttpHeader[],
        public id: string,
        public name: string,
        public serverCertificateValidationEnabled: boolean,
        public url: string) {
    }
}

export class ConfigurationControllerUpdateWorkloadAnalysisOperatingSystemEndOfLifeTimeFrameRequest {
    constructor(
        public operatingSystemEndOfLifeTimeFrame: string) {
    }
}

export class ConfigurationControllerUpdateWorkloadAnalysisRequest {
    constructor(
        public containerImageRepositoryEnabled: Optional<boolean>,
        public excludeUnresolvableVulnerabilities: Optional<boolean>,
        public fileScanEnabled: Optional<boolean>,
        public scopeId: string,
        public severeVulnerabilityMinSeverity: Optional<Severity>,
        public virtualMachineEnabled: Optional<boolean>,
        public virtualMachineExclusionTags: ResourceTag[],
        public virtualMachineImageEnabled: Optional<boolean>,
        public virtualMachineImageExclusionTags: ResourceTag[]) {
    }
}

export class ConfigurationControllerUpdateWorkloadAnalysisResponse {
    constructor(
        public containerImageRepositoryEnabled: Optional<boolean>,
        public excludeUnresolvableVulnerabilities: Optional<boolean>,
        public virtualMachineEnabled: Optional<boolean>,
        public virtualMachineExclusionTags: ResourceTag[],
        public virtualMachineImageEnabled: Optional<boolean>,
        public virtualMachineImageExclusionTags: ResourceTag[]) {
    }
}

export class ConfigurationControllerUpdateWorkloadAnalysisVulnerabilityScoreTypeRequest {
    constructor(
        public vulnerabilityScoreType: VulnerabilityScoreType) {
    }
}

export class ConfigurationControllerUpsertAnalysisNetworkRequest {
    constructor(
        public existingInternalSubnet: Optional<string>,
        public subnet: string,
        public subnetName: string) {
    }
}

export class ConfigurationControllerUpsertAnalysisNetworkResponse {
    constructor(
        public subnet: string,
        public subnetName: string) {
    }
}

export class ConfigurationControllerUpsertAuditEventAutomationRuleRequest {
    constructor(
        public auditEventTypeNames: Optional<string[]>,
        public deliveryRequests: DeliveryRequest[],
        public description: Optional<string>,
        public id: Optional<string>,
        public includeSystemIdentity: boolean,
        public name: string,
        public scopeId: string,
        public scopeIds: string[]) {
    }
}

export class ConfigurationControllerUpsertAutomaticCustomEntityAttributeDefinitionTemplateRequest {
    constructor(
        public colorIndex: number,
        public id: Optional<string>,
        public scopeId: string) {
    }
}

export class ConfigurationControllerUpsertAutomaticCustomEntityAttributeDefinitionTemplateResponse {
    constructor(
        public automaticCustomEntityAttributeDefinitionTemplateModel: AutomaticCustomEntityAttributeDefinitionTemplateModel) {
    }
}

export class ConfigurationControllerUpsertAutomaticCustomEntityAttributeResourceTagDefinitionTemplateRequest extends ConfigurationControllerUpsertAutomaticCustomEntityAttributeDefinitionTemplateRequest {
    constructor(
        colorIndex: number,
        id: Optional<string>,
        scopeId: string,
        public name: Optional<string>,
        public resourceTag: ResourceTag) {
        super(
            colorIndex,
            id,
            scopeId);
    }
}

export class ConfigurationControllerUpsertAutomaticCustomEntityAttributeResourceTagKeyDefinitionTemplateRequest extends ConfigurationControllerUpsertAutomaticCustomEntityAttributeDefinitionTemplateRequest {
    constructor(
        colorIndex: number,
        id: Optional<string>,
        scopeId: string,
        public name: Optional<string>,
        public resourceTagKey: string) {
        super(
            colorIndex,
            id,
            scopeId);
    }
}

export class ConfigurationControllerUpsertAutomaticCustomEntityAttributeResourceTagPatternsDefinitionTemplateRequest extends ConfigurationControllerUpsertAutomaticCustomEntityAttributeDefinitionTemplateRequest {
    constructor(
        colorIndex: number,
        id: Optional<string>,
        scopeId: string,
        public name: string,
        public resourceTagPatterns: ResourceTagPattern[]) {
        super(
            colorIndex,
            id,
            scopeId);
    }
}

export class ConfigurationControllerUpsertAwsOrganizationRequest {
    constructor(
        public enabled: boolean,
        public folderEnabled: boolean,
        public masterTenantId: string,
        public memberSelection: Optional<OrganizationMemberSelection>,
        public name: string,
        public permissionTypes: CloudProviderTenantPermissionType[],
        public regionSystemNames: Optional<string[]>,
        public roleName: string,
        public trailName: Optional<string>) {
    }
}

export class ConfigurationControllerUpsertAwsOrganizationResponse {
    constructor(
        public organizationMemberTenantNames: string[]) {
    }
}

export class ConfigurationControllerUpsertAzureOrganizationRequest {
    constructor(
        public aadTenantId: string,
        public enabled: boolean,
        public folderEnabled: boolean,
        public memberSelection: Optional<OrganizationMemberSelection>,
        public name: string,
        public permissionTypes: CloudProviderTenantPermissionType[]) {
    }
}

export class ConfigurationControllerUpsertAzureOrganizationResponse {
    constructor(
        public organizationMemberTenantNames: string[],
        public servicePrincipalAssignedRoleDefinitionNames: string[],
        public servicePrincipalMissingAssignedRoleDefinitionNames: string[],
        public valid: boolean) {
    }
}

export class ConfigurationControllerUpsertCustomComplianceRequest {
    constructor(
        public scopeId: string,
        public section: ConfigurationControllerUpsertCustomComplianceRequestSection) {
    }
}

export class ConfigurationControllerUpsertCustomComplianceRequestRiskPolicy {
    constructor(
        public analysisGroupType: RiskPolicyTypeMetadataAnalysisGroupType,
        public identifier: string) {
    }
}

export class ConfigurationControllerUpsertCustomComplianceRequestSection {
    constructor(
        public description: Optional<string>,
        public id: Optional<string>,
        public name: string,
        public riskPolicies: ConfigurationControllerUpsertCustomComplianceRequestRiskPolicy[],
        public sections: ConfigurationControllerUpsertCustomComplianceRequestSection[]) {
    }
}

export class ConfigurationControllerUpsertEntityPropertyDefinitionRequest {
    constructor(
        public id: Optional<string>,
        public identifier: EntityPropertyIdentifier,
        public resourceTagPatterns: ResourceTagPattern[],
        public scopeId: string) {
    }
}

export class ConfigurationControllerUpsertGcpOrganizationRequest {
    constructor(
        public enabled: boolean,
        public folderEnabled: boolean,
        public gciTenantId: string,
        public memberSelection: Optional<OrganizationMemberSelection>,
        public name: string,
        public permissionTypes: CloudProviderTenantPermissionType[],
        public sinkPubSubSubscriptionRawId: Optional<string>) {
    }
}

export class ConfigurationControllerUpsertGcpOrganizationResponse {
    constructor(
        public organizationMemberTenantNames: string[],
        public serviceAccountAssignedRoleNames: string[],
        public serviceAccountMissingOrganizationRoleNames: string[],
        public serviceAccountMissingTenantRoleNames: string[],
        public serviceAccountUnauthorizedErrorMessage: Optional<string>,
        public valid: boolean) {
    }
}

export class ConfigurationControllerUpsertJiraInstanceRequest {
    constructor(
        public authenticationType: JiraInstanceConfigurationConnectionInfoAuthenticationType,
        public id: Optional<string>,
        public scopeId: string,
        public url: string,
        public userName: string,
        public userToken: Optional<string>) {
    }
}

export class ConfigurationControllerUpsertJiraProjectRequest {
    constructor(
        public instanceId: string,
        public issueTypeNameToRiskStatusToJiraStatusRawIdMap: Optional<Dictionary<Dictionary<string>>>,
        public rawId: string,
        public riskSeverityToJiraPriorityMap: Optional<Dictionary<string>>) {
    }
}

export class ConfigurationControllerUpsertKubernetesClusterConnectorRequest {
    constructor(
        public id: Optional<string>,
        public name: Optional<string>,
        public scopeId: string) {
    }
}

export class ConfigurationControllerUpsertManualCustomEntityAttributeDefinitionRequest {
    constructor(
        public colorIndex: number,
        public id: Optional<string>,
        public name: string,
        public scopeId: string) {
    }
}

export class ConfigurationControllerUpsertManualCustomEntityAttributeDefinitionResponse {
    constructor(
        public customEntityAttributeDefinitionModel: CustomEntityAttributeDefinitionModel) {
    }
}

export class ConfigurationControllerUpsertReportScheduleRequest {
    constructor(
        public cadence: ReportScheduleCadence,
        public definition: ReportControllerReportRequestDefinition,
        public deliveries: Delivery[],
        public id: Optional<string>,
        public scopeId: string) {
    }
}

export class ConfigurationControllerUpsertRiskAutomationRuleRequest {
    constructor(
        public builtInEntityAttributeTypeNames: Optional<string[]>,
        public customEntityAttributeDefinitionIds: Optional<string[]>,
        public customRiskPolicyIds: Optional<string[]>,
        public deliveryRequests: DeliveryRequest[],
        public description: Optional<string>,
        public id: Optional<string>,
        public minimalSeverity: Severity,
        public name: string,
        public riskPolicyConfigurationTypeNames: Optional<string[]>,
        public riskSubStatuses: RiskSubStatus[],
        public scopeId: string,
        public scopeIds: string[]) {
    }
}

export class ConfigurationControllerUpsertScopeSettingConfigurationRequest {
    constructor(
        public id: Optional<string>,
        public scopeId: string) {
    }
}

export class ConfigurationControllerUpsertScopeSystemEntityResponse {
    constructor(
        public scopeSystemEntityModel: ScopeSystemEntityModel) {
    }
}

export class ConfigurationControllerUpsertServiceIdentityExcessivePermissionEvaluationTimeFrameConfigurationRequest extends ConfigurationControllerUpsertScopeSettingConfigurationRequest {
    constructor(
        id: Optional<string>,
        scopeId: string,
        public timeFrame: string) {
        super(
            id,
            scopeId);
    }
}

export class ConfigurationControllerUpsertServiceNowInstanceRequest {
    constructor(
        public id: Optional<string>,
        public name: string,
        public password: Optional<string>,
        public scopeId: string,
        public url: string,
        public userName: string) {
    }
}

export class ConfigurationControllerUpsertSplunkEventCollectorRequest {
    constructor(
        public endpointConnectorId: Optional<string>,
        public id: Optional<string>,
        public name: string,
        public scopeId: string,
        public serverCertificateValidationEnabled: boolean,
        public token: Optional<string>,
        public url: Optional<string>) {
    }
}

export class ConfigurationControllerUpsertSqsQueueRequest {
    constructor(
        public id: Optional<string>,
        public name: string,
        public roleArn: Optional<string>,
        public scopeId: string,
        public url: string) {
    }
}

export class ConfigurationControllerUpsertUserExcessivePermissionEvaluationTimeFrameConfigurationRequest extends ConfigurationControllerUpsertScopeSettingConfigurationRequest {
    constructor(
        id: Optional<string>,
        scopeId: string,
        public timeFrame: string) {
        super(
            id,
            scopeId);
    }
}

export class ConfigurationControllerValidateAuthenticationSamlIdentityProviderRequest {
    constructor(
        public metadataBytes: Optional<string>,
        public metadataUrl: Optional<string>) {
    }
}

export class ConfigurationControllerValidateAuthenticationSamlIdentityProviderResponse {
    constructor(
        public samlIdentityProvider: ApplicationCustomerConfigurationAuthenticationSamlIdentityProvider) {
    }
}

export class ConfigurationControllerValidateGitHubServerOnboardingServerInfoRequest {
    constructor(
        public endpointConnectorId: Optional<string>,
        public id: Optional<string>,
        public name: string,
        public rawEndpoint: Optional<string>,
        public serverCertificateValidationEnabled: boolean) {
    }
}

export class ConfigurationControllerValidateGitHubServerOnboardingServerInfoResponse {
    constructor(
        public result: GitHubServerManagerValidateServerOnboardingServerInfoResult) {
    }
}

export class ConfigurationSection {
    constructor() {
    }
}

export class ContainerImageData {
    constructor(
        public analyzed: boolean,
        public creationTime: Optional<string>,
        public digest: string,
        public operatingSystemDisplayName: Optional<string>,
        public operatingSystemDistribution: Optional<string>,
        public operatingSystemType: Optional<OperatingSystemType>,
        public operatingSystemVersion: Optional<string>,
        public repositoryRawUrl: string,
        public scanStatus: Optional<ContainerImageDataScanStatus>,
        public scanTime: Optional<string>,
        public tags: string[],
        public vulnerabilities: VulnerabilityData[]) {
    }
}

export class ContainerImageRegistryManagerConfigurationSection extends ConfigurationSection implements INullableConfigurationSection {
    constructor(
        public aadApplicationEncryptedSecret: EncryptedData,
        public aadApplicationRawId: string,
        public aadPartitionType: AadPartitionType,
        public aadTenantRawId: string,
        public azureContainerRegistry: ContainerImageRegistryManagerConfigurationSectionAzureContainerRegistry) {
        super();
    }
}

export class ContainerImageRegistryManagerConfigurationSectionAzureContainerRegistry {
    constructor(
        public host: string,
        public name: string,
        public resourceGroupName: string,
        public tenantRawShortId: string) {
    }
}

export class ContainerImageResourceStateContainerImageData {
    constructor(
        public containerImageIdReference: string,
        public repositoryIdReference: Optional<string>,
        public scanStatus: Optional<ContainerImageDataScanStatus>,
        public vulnerabilities: VulnerabilityData[]) {
    }
}

export class CustomCodeBuilderKubernetesAdmissionControllerRiskPolicyConfigurationCondition {
    constructor(
        public operator: CustomCodeBuilderKubernetesAdmissionControllerRiskPolicyConfigurationConditionOperator,
        public propertyPath: string,
        public values: string[]) {
    }
}

export class CustomEntityAttributeDefinitionModel {
    constructor(
        public configuration: CustomEntityAttributeDefinitionConfiguration) {
    }
}

export class CustomerConfigurationAnalysis {
    constructor(
        public awsRoleGroupRoleMinCount: number,
        public draining: Optional<CustomerConfigurationAnalysisDraining>,
        public entityModelGeneratorBatchSize: Optional<number>,
        public entitySyncerSubResourceTypeNameToMaxCountMap: Dictionary<number>,
        public network: CustomerConfigurationAnalysisNetwork,
        public orchestrationEnabled: boolean,
        public schedule: Optional<CustomerConfigurationAnalysisSchedule>) {
    }
}

export class CustomerConfigurationAnalysisIam {
    constructor(
        public severePermissionDirectoryRoleDefinitionTemplateIdToNameMap: Dictionary<string>) {
    }
}

export class CustomerConfigurationAnalysisNetwork {
    constructor(
        public internalSubnetToNameMap: Dictionary<string>) {
    }
}

export class CustomerConfigurationAnalysisSchedule {
    constructor(
        public hour: number,
        public timeFrame: string) {
    }
}

export class CustomerConfigurationAwsEc2Analysis {
    constructor(
        public orchestrationEnabled: boolean) {
    }
}

export class CustomerConfigurationAzureComputeVirtualMachineAnalysis {
    constructor(
        public orchestrationEnabled: boolean) {
    }
}

export class CustomerConfigurationCodeAnalysis {
    constructor(
        public codeScanRetentionTimeFrame: string,
        public orchestrationEnabled: boolean) {
    }
}

export class CustomerConfigurationContainerImageRepositoryAnalysis {
    constructor(
        public orchestrationEnabled: boolean) {
    }
}

export class CustomerConfigurationEventAnalysis {
    constructor(
        public aad: CustomerConfigurationEventAnalysisAad,
        public aws: CustomerConfigurationEventAnalysisAws,
        public azure: CustomerConfigurationEventAnalysisAzure,
        public dataEventRetentionTimeFrame: string,
        public gci: CustomerConfigurationEventAnalysisGci,
        public gcp: CustomerConfigurationEventAnalysisGcp,
        public managementEventRetentionTimeFrame: string,
        public nonEventDayCount: number,
        public orchestrationEnabled: boolean) {
    }
}

export class CustomerConfigurationEventAnalysisAad {
    constructor(
        public orchestrationEnabled: boolean) {
    }
}

export class CustomerConfigurationEventAnalysisAws {
    constructor(
        public orchestrationEnabled: boolean,
        public storeEvents: boolean) {
    }
}

export class CustomerConfigurationEventAnalysisAzure {
    constructor(
        public orchestrationEnabled: boolean,
        public storeEvents: boolean) {
    }
}

export class CustomerConfigurationEventAnalysisGci {
    constructor(
        public orchestrationEnabled: boolean) {
    }
}

export class CustomerConfigurationEventAnalysisGcp {
    constructor(
        public orchestrationEnabled: boolean,
        public storeEvents: boolean) {
    }
}

export class CustomerConfigurationGcpComputeInstanceAnalysis {
    constructor(
        public orchestrationEnabled: boolean) {
    }
}

export class CustomerConfigurationManagedDatabaseAnalysis {
    constructor(
        public orchestrationEnabled: boolean) {
    }
}

export class CustomerConfigurationObjectStoreAnalysis {
    constructor(
        public orchestrationEnabled: boolean) {
    }
}

export class CustomerConfigurationOutpost {
    constructor(
        public apiKeyId: string) {
    }
}

export class CustomerConfigurationPermissionManagement {
    constructor(
        public awsPermissionSetNamePrefix: Optional<string>,
        public permissionRequestExpirationTimeFrame: string,
        public slackEnabled: boolean,
        public teamsEnabled: boolean) {
    }
}

export class CustomerConfigurationProvisionedManagedDatabaseAnalysis {
    constructor(
        public orchestrationEnabled: boolean) {
    }
}

export class CustomerConfigurationSections {
    constructor(
        public analysis: CustomerConfigurationAnalysis,
        public awsEc2Analysis: CustomerConfigurationAwsEc2Analysis,
        public azureComputeVirtualMachineAnalysis: CustomerConfigurationAzureComputeVirtualMachineAnalysis,
        public codeAnalysis: CustomerConfigurationCodeAnalysis,
        public containerImageRepositoryAnalysis: CustomerConfigurationContainerImageRepositoryAnalysis,
        public eventAnalysis: CustomerConfigurationEventAnalysis,
        public gcpComputeInstanceAnalysis: CustomerConfigurationGcpComputeInstanceAnalysis,
        public managedDatabaseAnalysis: CustomerConfigurationManagedDatabaseAnalysis,
        public objectStoreAnalysis: CustomerConfigurationObjectStoreAnalysis,
        public outpost: CustomerConfigurationOutpost,
        public permissionManagement: CustomerConfigurationPermissionManagement,
        public provisionedManagedDatabaseAnalysis: CustomerConfigurationProvisionedManagedDatabaseAnalysis,
        public workloadAnalysis: CustomerConfigurationWorkloadAnalysis) {
    }
}

export class CustomerConfigurationWorkloadAnalysis {
    constructor(
        public operatingSystemEndOfLifeTimeFrame: string,
        public vulnerabilityScoreType: VulnerabilityScoreType) {
    }
}

export class CustomerStateAnalysis {
    constructor(
        public exportDocumentModelEndTime: Optional<string>,
        public exportDocumentModelStartTime: Optional<string>,
        public scopeEvaluationStartTime: Optional<string>,
        public updateBiEndTime: Optional<string>) {
    }
}

export class CustomerStateAutomations {
    constructor(
        public auditEventTaskTime: string,
        public auditEventTime: string,
        public riskTaskTime: string,
        public riskTime: string) {
    }
}

export class CustomerStateEvents {
    constructor(
        public tenantFiltersStartTime: string) {
    }
}

export class CustomerStateOrchestration {
    constructor(
        public orchestrationTypeToDataMap: Dictionary<CustomerStateOrchestrationData>) {
    }
}

export class CustomerStateOrchestrationData {
    constructor(
        public activeTenantIds: Optional<string[]>,
        public failureCount: number,
        public id: Optional<string>,
        public previous: Optional<CustomerStateOrchestrationDataPrevious>,
        public retryLimitEnabled: boolean,
        public startTime: Optional<string>,
        public tenantOrganizationIds: Optional<string[]>) {
    }
}

export class CustomerStateOrchestrationDataPrevious {
    constructor(
        public tenantCount: number,
        public timeRange: TimeRange) {
    }
}

export class DashboardControllerGetPublicEntityIdsRequest {
    constructor(
        public entityTypeName: string,
        public recent: boolean,
        public riskPolicyConfigurationTypeNames: string[]) {
    }
}

export class DashboardControllerGetPublicEntityIdsResponse {
    constructor(
        public ids: string[]) {
    }
}

export class DashboardControllerGetSummaryTopCriticalVulnerabilityModelsResponse {
    constructor(
        public vulnerabilityModels: WorkloadAnalysisVulnerabilityModel[]) {
    }
}

export class DashboardControllerGetSummaryVulnerabilityWorkloadResourceIdsRequest {
    constructor(
        public vulnerabilityRawId: string) {
    }
}

export class DashboardControllerGetSummaryVulnerabilityWorkloadResourceIdsResponse {
    constructor(
        public workloadResourceIds: string[]) {
    }
}

export class DashboardGetSummaryResponse {
    constructor(
        public riskTypeNameToMetadataMap: Dictionary<RiskTypeMetadata>,
        public summary: Optional<DashboardSummary>) {
    }
}

export class DashboardSummary {
    constructor(
        public dataCategoryToScannedDataAnalysisResourceCountMap: Dictionary<number>,
        public dataSensitivityToScannedDataAnalysisResourceCountMap: Dictionary<number>,
        public entityEntitiesViewTypeNameToPublicDataMap: Dictionary<ScopeSummaryCloudProviderEntityPublicData>,
        public notScannedDataAnalysisResourceCount: number,
        public recentRiskStatusUpdateDateToStatusToCountMap: Dictionary<Dictionary<number>>,
        public riskCombinationToDataMap: Dictionary<RiskCombinationData>,
        public riskTenantIdToSeverityToRiskCountMap: Dictionary<Dictionary<number>>,
        public scannedDataAnalysisResourceCount: number,
        public secretExistsRiskPolicyConfigurationTypeNameToEntityTypeNameToDataMap: Dictionary<Dictionary<DashboardSummarySecretExistsRiskEntityTypeData>>,
        public sensitiveScannedDataAnalysisResourceCount: number,
        public severityToRiskPolicyCategoryToCountMap: Dictionary<Dictionary<number>>,
        public timeFrameToRiskTrendsDataMap: Dictionary<DashboardSummaryRiskTrendsData>,
        public topRiskDatas: DashboardSummaryRiskData[],
        public topRiskPolicyIdentifierToSeverityToRiskCountMap: Dictionary<Dictionary<number>>,
        public workloadAnalysisData: Optional<DashboardSummaryWorkloadAnalysisData>) {
    }
}

export class DashboardSummaryRiskData {
    constructor(
        public id: string,
        public policyConfigurationTypeName: string,
        public policyId: string,
        public riskedEntityIds: string[],
        public riskedEntityTypeName: string,
        public severity: Severity,
        public tenantId: string) {
    }
}

export class DashboardSummaryRiskTrendsData {
    constructor(
        public closedRiskCount: number,
        public createdTicketCount: number,
        public dateToOpenBehaviorRiskPolicyConfigurationTypeNameToRiskCountMap: Dictionary<Dictionary<number>>,
        public highOrCriticalOpenRiskCount: number,
        public ignoredRiskCount: number,
        public newPolicyOpenRiskCount: number,
        public openRiskCount: number,
        public openRiskNewBuiltInRiskPolicyConfigurationTypeNames: string[],
        public openRiskNewCustomRiskPolicyIds: string[],
        public riskAverageOpenTimeFrame: string,
        public startTime: string) {
    }
}

export class DashboardSummarySecretExistsRiskEntityTypeData {
    constructor(
        public entityIds: string[],
        public secretCount: number) {
    }
}

export class DashboardSummaryWorkloadAnalysisData {
    constructor(
        public operatingSystemTypeToRiskPolicyTypeToVirtualMachineCountMap: Dictionary<Dictionary<number>>,
        public virtualMachineVulnerabilityStats: SecurityStats) {
    }
}

export class DataAnalysisResourceScanDataSegmentData {
    constructor(
        public dataClassifierIdToSamplesMap: Dictionary<DataClassifierSample[]>,
        public scanTime: string) {
    }
}

export class DataClassifier {
    constructor(
        public category: DataCategory,
        public id: string,
        public name: string,
        public priority: number,
        public sensitivity: DataSensitivity) {
    }
}

export class DataClassifierModel {
    constructor(
        public category: DataCategory,
        public id: string,
        public name: string,
        public sensitivity: DataSensitivity) {
    }
}

export class DataClassifierSample {
    constructor(
        public location: Optional<DataClassifierSampleLocation>,
        public maskedValue: Optional<string>) {
    }
}

export class DataControllerGetDataAnalysisResourceScansRequest {
    constructor(
        public dataAnalysisResourceIds: string[]) {
    }
}

export class DataControllerGetDataAnalysisResourceScansResponse {
    constructor(
        public dataAnalysisResourceScans: DataAnalysisResourceScan[]) {
    }
}

export class DataControllerGetDataClassifierModelsResponse {
    constructor(
        public dataClassifierModels: DataClassifierModel[]) {
    }
}

export class DataControllerUpdateDataAnalysisResourcePriorityScanRequestRequest {
    constructor(
        public dataAnalysisResourceId: string) {
    }
}

export class DatadogConfigurationSection extends ConfigurationSection implements INullableConfigurationSection {
    constructor(
        public rumApplication: Optional<DatadogConfigurationSectionRumApplication>) {
        super();
    }
}

export class DatadogConfigurationSectionRumApplication {
    constructor(
        public clientToken: EncryptedData,
        public enabled: boolean,
        public id: string,
        public recordingEnabled: boolean,
        public siteDomainName: string) {
    }
}

export class DataStoresExporterConfigurationSection extends ConfigurationSection {
    constructor(
        public bucketName: Optional<string>,
        public customerIds: string[],
        public enabled: boolean) {
        super();
    }
}

export class DeliveryRequest {
    constructor(
        public delivery: Delivery,
        public id: Optional<string>) {
    }
}

export class DeliveryWrapper {
    constructor(
        public delivery: Delivery,
        public id: string) {
    }
}

export class DeploymentConfigurationSection extends ConfigurationSection {
    constructor(
        public global: boolean,
        public globalDeploymentName: string,
        public local: boolean) {
        super();
    }
}

export class DeploymentDataProviderConfigurationSection extends ConfigurationSection {
    constructor(
        public deploymentNameToDataMap: Dictionary<DeploymentDataProviderConfigurationSectionData>) {
        super();
    }
}

export class DeploymentDataProviderConfigurationSectionData {
    constructor(
        public apiUrl: string,
        public applicationPrivateApiKeyEncryptedToken: EncryptedData,
        public consoleUrl: string) {
    }
}

export class DestinationNetworkScope {
    constructor(
        public destinationPortRange: IntRange,
        public protocolRange: IntRange) {
    }
}

export class DestinationNetworkScopeData {
    constructor(
        public destinationNetworkScope: DestinationNetworkScope,
        public filterId: string) {
    }
}

export class DirectoryIdentityProfileAzure {
    constructor(
        public activityTime: Optional<string>,
        public tenantIdToActivityTimeMap: Dictionary<string>) {
    }
}

export class DirectoryUserProfileAws {
    constructor(
        public activityTime: Optional<string>,
        public roleIdToActivityTimeMap: Dictionary<string>,
        public roleIdToAssumeTimeMap: Dictionary<string>) {
    }
}

export class Document implements IDocument, ITypeNameObject {
    constructor(
        public id: string,
        public systemCreationTime: string,
        public systemDeleted: boolean,
        public systemDeletionTime: Optional<string>,
        public systemUpdateTime: string,
        public typeName: string) {
    }
}

export class Document360AuthenticationProviderConfigurationSection extends ConfigurationSection implements INullableConfigurationSection {
    constructor(
        public callbackUrl: string,
        public clientEncryptedSecret: EncryptedData,
        public clientId: string,
        public codeGenerationUrl: string,
        public encryptedApiKey: EncryptedData) {
        super();
    }
}

export class DocumentModelExporterConfigurationSection extends ConfigurationSection {
    constructor(
        public enabled: boolean,
        public tenableSiteIdToBucketDataMap: Dictionary<DocumentModelExporterConfigurationSectionBucketData>) {
        super();
    }
}

export class DocumentModelExporterConfigurationSectionBucketData {
    constructor(
        public name: string,
        public objectKeyPrefix: string,
        public regionSystemName: string) {
    }
}

export class ElasticsearchClientProviderConfigurationSection extends ConfigurationSection {
    constructor(
        public domainNameToConfigurationMap: Dictionary<ElasticsearchDomainConfiguration>) {
        super();
    }
}

export class ElasticsearchDomainConfiguration {
    constructor(
        public url: string) {
    }
}

export class ElasticsearchIndexSearchCursor {
    constructor(
        public sortValues: any[]) {
    }
}

export class ElasticsearchItemPage<TItem> {
    constructor(
        public count: Optional<number>,
        public itemCount: number,
        public itemNextPageSearchCursor: Optional<ElasticsearchIndexSearchCursor>,
        public items: TItem[]) {
    }
}

export class EncryptedData {
    constructor() {
    }
}

export class EngineeringConfigurationSection extends ConfigurationSection {
    constructor(
        public aws: EngineeringConfigurationSectionAws) {
        super();
    }
}

export class EngineeringConfigurationSectionAws {
    constructor(
        public regionType: AwsRegionType,
        public tenantRawId: string) {
    }
}

export class EntityConfiguration extends Document implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        public manualCustomEntityAttributeDefinitionIdToIdentityReferenceMap: Dictionary<IdentityReference>,
        public sensitive: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "EntityConfiguration");
    }
}

export class EntityControllerGenerateAwsResourceNonexcessivePolicyRequest {
    constructor(
        public excessivePermissionEvaluationStartDate: string,
        public includeGroupPolicies: boolean,
        public resourceId: string,
        public statementResourceArnPatternPreservationDisabled: boolean) {
    }
}

export class EntityControllerGenerateAwsResourceNonexcessivePolicyResponse {
    constructor(
        public documents: AwsIamPolicyDocument[],
        public result: EntityControllerGenerateAwsResourceNonexcessivePolicyResult) {
    }
}

export class EntityControllerGenerateAzureIdentityNonexcessiveRoleDefinitionRequest {
    constructor(
        public excessivePermissionEvaluationStartDate: string,
        public identityId: string,
        public includeGroupRoleAssignments: boolean,
        public scopeResourceIds: string[]) {
    }
}

export class EntityControllerGenerateAzureIdentityNonexcessiveRoleDefinitionResponse {
    constructor(
        public error: Optional<EntityControllerGenerateAzureIdentityNonexcessiveRoleDefinitionError>,
        public rawRoleDefinitions: Optional<string[]>) {
    }
}

export class EntityControllerGenerateGcpIdentityNonexcessiveRoleRequest {
    constructor(
        public excessivePermissionEvaluationStartDate: string,
        public identityId: string,
        public includeGciDirectoryGroupRoleBindings: boolean,
        public includeGcpSpecialGroupRoleBindings: boolean,
        public scopeResourceIds: string[]) {
    }
}

export class EntityControllerGenerateGcpIdentityNonexcessiveRoleResponse {
    constructor(
        public error: Optional<EntityControllerGenerateGcpIdentityNonexcessiveRoleError>,
        public rawRoles: Optional<string[]>) {
    }
}

export class EntityControllerGetAccessDataRequest {
    constructor(
        public direction: EntityAccessDirection,
        public entityId: string,
        public filters: EntityControllerGetAccessDataRequestFilters,
        public scope: EntityAccessScope) {
    }
}

export class EntityControllerGetAccessDataRequestFilters {
    constructor(
        public accessLevelSelection: Optional<ItemSelection<AccessLevel>>,
        public destinationEntityIdSelection: Optional<ItemSelection<string>>,
        public destinationResourceBuiltInEntityAttributeTypeNameSelection: Optional<ItemSelection<string>>,
        public destinationResourceTenantIdSelection: Optional<ItemSelection<string>>,
        public groupIdSelection: Optional<ItemSelection<string>>,
        public identityIdSelection: Optional<ItemSelection<string>>,
        public originatorEntityIdSelection: Optional<ItemSelection<string>>,
        public originatorEntityTypeNameSelection: Optional<ItemSelection<string>>,
        public permissionActionExcessiveness: Optional<PermissionActionExcessiveness>,
        public permissionActionRiskCategorySelection: Optional<ItemSelection<ActionRiskCategory>>,
        public permissionActionSeveritySelection: Optional<ItemSelection<Severity>>,
        public permitterIdSelection: Optional<ItemSelection<string>>) {
    }
}

export class EntityControllerGetAccessGraphRequest extends EntityControllerGetAccessDataRequest {
    constructor(
        direction: EntityAccessDirection,
        entityId: string,
        filters: EntityControllerGetAccessDataRequestFilters,
        scope: EntityAccessScope) {
        super(
            direction,
            entityId,
            filters,
            scope);
    }
}

export class EntityControllerGetAccessGraphResponse {
    constructor(
        public accessGraph: Optional<AccessGraph>,
        public entityId: string,
        public result: EntityAccessEvaluatorEvaluateResult) {
    }
}

export class EntityControllerGetAccessListPermissionActionsRequest extends EntityControllerGetAccessDataRequest {
    constructor(
        direction: EntityAccessDirection,
        entityId: string,
        filters: EntityControllerGetAccessDataRequestFilters,
        scope: EntityAccessScope,
        public selection: EntityControllerGetAccessListPermissionActionsRequestSelection) {
        super(
            direction,
            entityId,
            filters,
            scope);
    }
}

export class EntityControllerGetAccessListPermissionActionsRequestSelection {
    constructor(
        public destinationEntityId: string,
        public sourceEntityId: string) {
    }
}

export class EntityControllerGetAccessListPermissionActionsResponse {
    constructor(
        public actionToDataMap: Dictionary<AccessListPermissionActionData>) {
    }
}

export class EntityControllerGetActionsRequest {
    constructor(
        public tenantTypes: TenantType[]) {
    }
}

export class EntityControllerGetActionsResponse {
    constructor(
        public actions: string[]) {
    }
}

export class EntityControllerGetAwsAccessDataRequestFilters extends EntityControllerGetAccessDataRequestFilters {
    constructor(
        accessLevelSelection: Optional<ItemSelection<AccessLevel>>,
        destinationEntityIdSelection: Optional<ItemSelection<string>>,
        destinationResourceBuiltInEntityAttributeTypeNameSelection: Optional<ItemSelection<string>>,
        destinationResourceTenantIdSelection: Optional<ItemSelection<string>>,
        groupIdSelection: Optional<ItemSelection<string>>,
        identityIdSelection: Optional<ItemSelection<string>>,
        originatorEntityIdSelection: Optional<ItemSelection<string>>,
        originatorEntityTypeNameSelection: Optional<ItemSelection<string>>,
        permissionActionExcessiveness: Optional<PermissionActionExcessiveness>,
        permissionActionRiskCategorySelection: Optional<ItemSelection<ActionRiskCategory>>,
        permissionActionSeveritySelection: Optional<ItemSelection<Severity>>,
        permitterIdSelection: Optional<ItemSelection<string>>,
        public excessivePermissionEvaluationStartDate: Optional<string>,
        public permissionActionSelection: Optional<ItemSelection<string>>,
        public vendorRoles: boolean) {
        super(
            accessLevelSelection,
            destinationEntityIdSelection,
            destinationResourceBuiltInEntityAttributeTypeNameSelection,
            destinationResourceTenantIdSelection,
            groupIdSelection,
            identityIdSelection,
            originatorEntityIdSelection,
            originatorEntityTypeNameSelection,
            permissionActionExcessiveness,
            permissionActionRiskCategorySelection,
            permissionActionSeveritySelection,
            permitterIdSelection);
    }
}

export class EntityControllerGetAwsAccessGraphRequest extends EntityControllerGetAccessGraphRequest {
    constructor(
        direction: EntityAccessDirection,
        entityId: string,
        filters: EntityControllerGetAccessDataRequestFilters,
        scope: EntityAccessScope) {
        super(
            direction,
            entityId,
            filters,
            scope);
    }
}

export class EntityControllerGetAwsAccessListPermissionActionConditionJsonsRequest {
    constructor(
        public conditionsConsistentHashes: string[]) {
    }
}

export class EntityControllerGetAwsAccessListPermissionActionConditionJsonsResponse {
    constructor(
        public conditionsConsistentHashToJsonMap: Dictionary<string>) {
    }
}

export class EntityControllerGetAwsAccessListPermissionActionsRequest extends EntityControllerGetAccessListPermissionActionsRequest {
    constructor(
        direction: EntityAccessDirection,
        entityId: string,
        filters: EntityControllerGetAccessDataRequestFilters,
        scope: EntityAccessScope,
        selection: EntityControllerGetAccessListPermissionActionsRequestSelection,
        public permissionPathIdentifiers: AwsAccessGraphPermissionPathIdentifier[]) {
        super(
            direction,
            entityId,
            filters,
            scope,
            selection);
    }
}

export class EntityControllerGetAwsDistributionIdsRequest {
    constructor(
        public originResourceId: string) {
    }
}

export class EntityControllerGetAwsDistributionIdsResponse {
    constructor(
        public distributionIds: string[]) {
    }
}

export class EntityControllerGetAwsKmsKeyGrantsRequest {
    constructor(
        public keyId: string) {
    }
}

export class EntityControllerGetAwsKmsKeyGrantsResponse {
    constructor(
        public keyGrants: AwsKmsKeyGrant[]) {
    }
}

export class EntityControllerGetAwsLoggingBucketDistributionIdsAsyncRequest {
    constructor(
        public bucketId: string) {
    }
}

export class EntityControllerGetAwsLoggingBucketDistributionIdsAsyncResponse {
    constructor(
        public distributionIds: string[]) {
    }
}

export class EntityControllerGetAwsTimestreamDatabaseTableIdsRequest {
    constructor(
        public databaseId: string) {
    }
}

export class EntityControllerGetAwsTimestreamDatabaseTableIdsResponse {
    constructor(
        public tableIds: string[]) {
    }
}

export class EntityControllerGetAzureAccessDataRequestFilters extends EntityControllerGetAccessDataRequestFilters {
    constructor(
        accessLevelSelection: Optional<ItemSelection<AccessLevel>>,
        destinationEntityIdSelection: Optional<ItemSelection<string>>,
        destinationResourceBuiltInEntityAttributeTypeNameSelection: Optional<ItemSelection<string>>,
        destinationResourceTenantIdSelection: Optional<ItemSelection<string>>,
        groupIdSelection: Optional<ItemSelection<string>>,
        identityIdSelection: Optional<ItemSelection<string>>,
        originatorEntityIdSelection: Optional<ItemSelection<string>>,
        originatorEntityTypeNameSelection: Optional<ItemSelection<string>>,
        permissionActionExcessiveness: Optional<PermissionActionExcessiveness>,
        permissionActionRiskCategorySelection: Optional<ItemSelection<ActionRiskCategory>>,
        permissionActionSeveritySelection: Optional<ItemSelection<Severity>>,
        permitterIdSelection: Optional<ItemSelection<string>>,
        public permissionActionSelection: Optional<ItemSelection<string>>) {
        super(
            accessLevelSelection,
            destinationEntityIdSelection,
            destinationResourceBuiltInEntityAttributeTypeNameSelection,
            destinationResourceTenantIdSelection,
            groupIdSelection,
            identityIdSelection,
            originatorEntityIdSelection,
            originatorEntityTypeNameSelection,
            permissionActionExcessiveness,
            permissionActionRiskCategorySelection,
            permissionActionSeveritySelection,
            permitterIdSelection);
    }
}

export class EntityControllerGetAzureAccessGraphRequest extends EntityControllerGetAccessGraphRequest {
    constructor(
        direction: EntityAccessDirection,
        entityId: string,
        filters: EntityControllerGetAccessDataRequestFilters,
        scope: EntityAccessScope) {
        super(
            direction,
            entityId,
            filters,
            scope);
    }
}

export class EntityControllerGetAzureAccessListPermissionActionsRequest extends EntityControllerGetAccessListPermissionActionsRequest {
    constructor(
        direction: EntityAccessDirection,
        entityId: string,
        filters: EntityControllerGetAccessDataRequestFilters,
        scope: EntityAccessScope,
        selection: EntityControllerGetAccessListPermissionActionsRequestSelection,
        public permissionPathIdentifiers: AzureAccessGraphPermissionPathIdentifier[]) {
        super(
            direction,
            entityId,
            filters,
            scope,
            selection);
    }
}

export class EntityControllerGetAzureKeyVaultVaultObjectIdsRequest {
    constructor(
        public vaultId: string) {
    }
}

export class EntityControllerGetAzureKeyVaultVaultObjectIdsResponse {
    constructor(
        public certificateIds: string[],
        public keyIds: string[],
        public secretIds: string[]) {
    }
}

export class EntityControllerGetAzureResourceGroupResourceDatasRequest {
    constructor(
        public resourceGroupId: string) {
    }
}

export class EntityControllerGetAzureResourceGroupResourceDatasResponse {
    constructor(
        public resourceTypeNameToIdsMap: Dictionary<string[]>) {
    }
}

export class EntityControllerGetAzureStorageAccountChildResourceDatasRequest {
    constructor(
        public storageAccountId: string) {
    }
}

export class EntityControllerGetAzureStorageAccountChildResourceDatasResponse {
    constructor(
        public blobContainerIds: string[],
        public fileShareIds: string[],
        public publicBlobContainerIds: string[],
        public queueIds: string[],
        public sensitiveBlobContainerIds: string[],
        public tableIds: string[]) {
    }
}

export class EntityControllerGetClusterKubernetesResourceModelsRequest {
    constructor(
        public clusterId: string) {
    }
}

export class EntityControllerGetClusterKubernetesResourceModelsResponse {
    constructor(
        public resourceModels: EntityModel[]) {
    }
}

export class EntityControllerGetEntityEntitiesViewTypeNamesRequest {
    constructor(
        public typeName: string) {
    }
}

export class EntityControllerGetEntityEntitiesViewTypeNamesResponse {
    constructor(
        public entitiesViewNames: string[]) {
    }
}

export class EntityControllerGetEntityExcessivePermissionCustomEvaluationDataRequest {
    constructor(
        public entityId: string) {
    }
}

export class EntityControllerGetEntityExcessivePermissionCustomEvaluationDataResponse {
    constructor(
        public accessGraphCustomExcessiveEvaluationEnabled: boolean,
        public dateInfos: EntityControllerGetEntityExcessivePermissionCustomEvaluationDataResponseDateInfo[],
        public eventsTime: Optional<string>,
        public maxTime: Optional<string>,
        public nonexcessivePermitterCustomGenerationEnabled: boolean) {
    }
}

export class EntityControllerGetEntityExcessivePermissionCustomEvaluationDataResponseDateInfo {
    constructor(
        public date: string,
        public type: EntityControllerGetEntityExcessivePermissionCustomEvaluationDataResponseDateType) {
    }
}

export class EntityControllerGetEntityModelFilterItemPageRequest {
    constructor(
        public entityTypeName: Optional<string>,
        public limit: number,
        public property: EntityModelProperty,
        public scopeId: Optional<string>,
        public searchText: Optional<string>,
        public skip: number,
        public tenantTypes: Optional<TenantType[]>) {
    }
}

export class EntityControllerGetEntityModelFilterItemPageResponseBase {
    constructor() {
    }
}

export class EntityControllerGetEntityModelFiltersRequest {
    constructor(
        public typeName: string) {
    }
}

export class EntityControllerGetEntityModelFiltersResponse {
    constructor(
        public entityModelFilters: EntityModelFilters) {
    }
}

export class EntityControllerGetEntityModelPageEntityRequestSection {
    constructor() {
    }
}

export class EntityControllerGetEntityModelPageEntityRequestTag extends EntityControllerGetEntityModelPageEntityRequestSection {
    constructor(
        public tagSelection: Optional<PagedItemSelection<ResourceTag>>) {
        super();
    }
}

export class EntityControllerGetEntityModelPageEntityRequestVirtualMachineDiskResource extends EntityControllerGetEntityModelPageEntityRequestSection {
    constructor(
        public operatingSystemDisplayNameSelection: Optional<PagedItemSelection<string>>,
        public operatingSystemTypeSelection: Optional<ItemSelection<OperatingSystemType>>,
        public scanTimeRangeSelection: Optional<TimeRangeSelection>,
        public vulnerabilityRawIdSelection: Optional<PagedItemSelection<string>>,
        public vulnerabilitySeveritySelection: Optional<ItemSelection<Severity>>,
        public workloadAnalysisStatusSelection: Optional<ItemSelection<WorkloadAnalysisStatus>>) {
        super();
    }
}

export class EntityControllerGetEntityModelPageEntityRequestVirtualMachineResource extends EntityControllerGetEntityModelPageEntityRequestSection {
    constructor(
        public kubernetesClusterSensorSelection: Optional<ItemSelection<boolean>>) {
        super();
    }
}

export class EntityControllerGetEntityModelPageGcpScopeResourceRequestEncrypted extends EntityControllerGetEntityModelPageEntityRequestSection {
    constructor(
        public encryptionExists: Optional<boolean>,
        public kmsEncryptionKeyIdSelection: Optional<PagedItemSelection<string>>) {
        super();
    }
}

export class EntityControllerGetEntityModelPageGcpScopeResourceRequestServiceAccount extends EntityControllerGetEntityModelPageEntityRequestSection {
    constructor(
        public serviceAccountIdSelection: Optional<PagedItemSelection<string>>) {
        super();
    }
}

export class EntityControllerGetEntityModelPageOciResourceRequestEncrypted extends EntityControllerGetEntityModelPageEntityRequestSection {
    constructor(
        public encryptionExists: Optional<boolean>,
        public encryptionVaultKeyIdSelection: Optional<PagedItemSelection<string>>) {
        super();
    }
}

export class EntityControllerGetEntityModelPageRequest {
    constructor(
        public filters: EntityControllerGetEntityModelPageRequestFilters,
        public limit: number,
        public skip: number,
        public sort: EntityControllerGetEntityModelPageRequestSort) {
    }
}

export class EntityControllerGetEntityModelPageRequestFilters {
    constructor(
        public attributeValueSelection: Optional<ItemSelection<string>>,
        public idSelection: Optional<PagedItemSelection<string>>,
        public propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        public regionIdSelection: Optional<ItemSelection<string>>,
        public regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        public relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        public relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        public tenantIdSelection: Optional<ItemSelection<string>>) {
    }
}

export class EntityControllerGetEntityModelPageRequestSort {
    constructor(
        public direction: SortDirection,
        public property: EntityModelProperty) {
    }
}

export class EntityControllerGetEntityModelPageResponse {
    constructor(
        public entityModelPage: ItemPage<EntityModel>) {
    }
}

export class EntityControllerGetEntityModelPropertyFilterItemPageRequest extends EntityControllerGetEntityModelFilterItemPageRequest {
    constructor(
        entityTypeName: Optional<string>,
        limit: number,
        property: EntityModelProperty,
        scopeId: Optional<string>,
        searchText: Optional<string>,
        skip: number,
        tenantTypes: Optional<TenantType[]>,
        public identifier: EntityPropertyIdentifier) {
        super(
            entityTypeName,
            limit,
            property,
            scopeId,
            searchText,
            skip,
            tenantTypes);
    }
}

export class EntityControllerGetEntityModelsRequest {
    constructor(
        public ids: string[]) {
    }
}

export class EntityControllerGetEntityModelsResponse {
    constructor(
        public entityModels: EntityModel[]) {
    }
}

export class EntityControllerGetEntityModelSummaryResponse {
    constructor(
        public entityModelSummary: EntityModelSummary) {
    }
}

export class EntityControllerGetEntitySearchableReferencesRequest {
    constructor(
        public ids: string[]) {
    }
}

export class EntityControllerGetEntitySearchableReferencesResponse {
    constructor(
        public entitySearchableReferences: EntitySearchableReference[]) {
    }
}

export class EntityControllerGetEntitySnapshotsRequest {
    constructor(
        public id: string,
        public riskId: Optional<string>) {
    }
}

export class EntityControllerGetEntitySnapshotsResponse {
    constructor(
        public entitySnapshots: EntitySnapshot[]) {
    }
}

export class EntityControllerGetEntityTypeEntitySearchableReferencesRequest {
    constructor(
        public anyScopeEntityTypeNames: string[],
        public entityTypeNames: string[],
        public scopeId: string) {
    }
}

export class EntityControllerGetEntityTypeEntitySearchableReferencesResponse {
    constructor(
        public entityTypeNameToSearchableReferencesMap: Dictionary<EntitySearchableReference[]>) {
    }
}

export class EntityControllerGetEntityTypeMetadataModelsResponse {
    constructor(
        public entityTypeMetadataModels: EntityTypeMetadataModel[]) {
    }
}

export class EntityControllerGetExcessivePermissionMultiRiskPrincipalIdsRequest {
    constructor(
        public riskIds: string[]) {
    }
}

export class EntityControllerGetExcessivePermissionMultiRiskPrincipalIdsResponse {
    constructor(
        public principalIds: string[]) {
    }
}

export class EntityControllerGetGcpAccessDataRequestFilters extends EntityControllerGetAccessDataRequestFilters {
    constructor(
        accessLevelSelection: Optional<ItemSelection<AccessLevel>>,
        destinationEntityIdSelection: Optional<ItemSelection<string>>,
        destinationResourceBuiltInEntityAttributeTypeNameSelection: Optional<ItemSelection<string>>,
        destinationResourceTenantIdSelection: Optional<ItemSelection<string>>,
        groupIdSelection: Optional<ItemSelection<string>>,
        identityIdSelection: Optional<ItemSelection<string>>,
        originatorEntityIdSelection: Optional<ItemSelection<string>>,
        originatorEntityTypeNameSelection: Optional<ItemSelection<string>>,
        permissionActionExcessiveness: Optional<PermissionActionExcessiveness>,
        permissionActionRiskCategorySelection: Optional<ItemSelection<ActionRiskCategory>>,
        permissionActionSeveritySelection: Optional<ItemSelection<Severity>>,
        permitterIdSelection: Optional<ItemSelection<string>>,
        public permissionActionSelection: Optional<ItemSelection<string>>) {
        super(
            accessLevelSelection,
            destinationEntityIdSelection,
            destinationResourceBuiltInEntityAttributeTypeNameSelection,
            destinationResourceTenantIdSelection,
            groupIdSelection,
            identityIdSelection,
            originatorEntityIdSelection,
            originatorEntityTypeNameSelection,
            permissionActionExcessiveness,
            permissionActionRiskCategorySelection,
            permissionActionSeveritySelection,
            permitterIdSelection);
    }
}

export class EntityControllerGetGcpAccessGraphRequest extends EntityControllerGetAccessGraphRequest {
    constructor(
        direction: EntityAccessDirection,
        entityId: string,
        filters: EntityControllerGetAccessDataRequestFilters,
        scope: EntityAccessScope) {
        super(
            direction,
            entityId,
            filters,
            scope);
    }
}

export class EntityControllerGetGcpAccessListPermissionActionsRequest extends EntityControllerGetAccessListPermissionActionsRequest {
    constructor(
        direction: EntityAccessDirection,
        entityId: string,
        filters: EntityControllerGetAccessDataRequestFilters,
        scope: EntityAccessScope,
        selection: EntityControllerGetAccessListPermissionActionsRequestSelection,
        public permissionPathIdentifiers: GcpAccessGraphPermissionPathIdentifier[]) {
        super(
            direction,
            entityId,
            filters,
            scope,
            selection);
    }
}

export class EntityControllerGetGcpBigQueryDatasetTableIdsRequest {
    constructor(
        public datasetId: string) {
    }
}

export class EntityControllerGetGcpBigQueryDatasetTableIdsResponse {
    constructor(
        public tableIds: string[]) {
    }
}

export class EntityControllerGetGcpEntityExcessivePermissionCustomEvaluationDataRequest extends EntityControllerGetEntityExcessivePermissionCustomEvaluationDataRequest {
    constructor(
        entityId: string,
        public scopeResourceIds: string[]) {
        super(entityId);
    }
}

export class EntityControllerGetGcpSpannerInstanceDatabaseIdsRequest {
    constructor(
        public instanceId: string) {
    }
}

export class EntityControllerGetGcpSpannerInstanceDatabaseIdsResponse {
    constructor(
        public databaseIds: string[]) {
    }
}

export class EntityControllerGetKubernetesSystemNamespaceIdsRequest {
    constructor(
        public namespaceResourceTypeName: string) {
    }
}

export class EntityControllerGetKubernetesSystemNamespaceIdsResponse {
    constructor(
        public kubernetesSystemNamespaceIds: Optional<string[]>) {
    }
}

export class EntityControllerGetNetworkGraphRequest {
    constructor(
        public id: string) {
    }
}

export class EntityControllerGetNetworkGraphRequestFilters {
    constructor(
        public destinationScopeFilterIdSelection: Optional<ItemSelection<string>>,
        public sourceEntityIdSelection: Optional<ItemSelection<string>>,
        public sourceSubnetSelection: Optional<ItemSelection<string>>,
        public wideRangeSourceSubnet: boolean) {
    }
}

export class EntityControllerGetNetworkGraphResponse {
    constructor(
        public networkGraph: Optional<NetworkGraph>,
        public result: EntityControllerGetNetworkGraphResponseResult) {
    }
}

export class EntityControllerGetPrincipalIdToAccessMapRequest {
    constructor(
        public entityIds: string[]) {
    }
}

export class EntityControllerGetPrincipalIdToModelAccessMapResponse {
    constructor(
        public principalIdToModelAccessMap: Dictionary<Optional<PrincipalModelAccess>>) {
    }
}

export class EntityControllerGetRegionModelsResponse {
    constructor(
        public regionModels: RegionModel[]) {
    }
}

export class EntityControllerGetSummaryRequest {
    constructor(
        public tenantType: Optional<TenantType>) {
    }
}

export class EntityControllerGetSummaryResponse {
    constructor(
        public entityTypeEntitiesViewNameToCountMap: Dictionary<number>) {
    }
}

export class EntityControllerSearchAllEntityModelsRequest {
    constructor(
        public limit: number,
        public nextPageSearchCursor: Optional<ElasticsearchIndexSearchCursor>,
        public text: Optional<string>,
        public typeName: Optional<string>) {
    }
}

export class EntityControllerSearchAllEntityModelsResponse {
    constructor(
        public entityModelPage: ElasticsearchItemPage<EntityModel>,
        public entityTypeNameToCountMap: Optional<Dictionary<number>>) {
    }
}

export class EntityControllerSearchEntityIdsRequest {
    constructor(
        public filterRelatedTenants: boolean,
        public limit: number,
        public nextPageSearchCursor: Optional<ElasticsearchIndexSearchCursor>,
        public text: Optional<string>) {
    }
}

export class EntityControllerSearchEntityIdsResponse {
    constructor(
        public entityIdPage: ElasticsearchItemPage<string>) {
    }
}

export class EntityControllerSearchEntityIdsTypeRequest extends EntityControllerSearchEntityIdsRequest {
    constructor(
        filterRelatedTenants: boolean,
        limit: number,
        nextPageSearchCursor: Optional<ElasticsearchIndexSearchCursor>,
        text: Optional<string>,
        public typeName: string) {
        super(
            filterRelatedTenants,
            limit,
            nextPageSearchCursor,
            text);
    }
}

export class EntityControllerSearchEntityModelsRequest {
    constructor(
        public filterRelatedTenants: boolean,
        public limit: number,
        public nextPageSearchCursor: Optional<ElasticsearchIndexSearchCursor>,
        public scopeId: Optional<string>,
        public text: Optional<string>) {
    }
}

export class EntityControllerSearchEntityModelsResponse {
    constructor(
        public entityModelPage: ElasticsearchItemPage<EntityModel>) {
    }
}

export class EntityControllerSearchEntityModelsTypeRequest extends EntityControllerSearchEntityModelsRequest {
    constructor(
        filterRelatedTenants: boolean,
        limit: number,
        nextPageSearchCursor: Optional<ElasticsearchIndexSearchCursor>,
        scopeId: Optional<string>,
        text: Optional<string>,
        public excludeCommonTenants: boolean,
        public typeName: string) {
        super(
            filterRelatedTenants,
            limit,
            nextPageSearchCursor,
            scopeId,
            text);
    }
}

export class EntityControllerUpdateConfigurationRequest {
    constructor(
        public id: string,
        public manualCustomEntityAttributeDefinitionIds: Optional<string[]>,
        public sensitive: Optional<boolean>) {
    }
}

export class EntityManualCustomAttributeDefinitionUpdateAuditEventModel extends AuditEventModel {
    constructor(
        public auditEvent: EntityManualCustomAttributeDefinitionUpdateAuditEvent,
        entityIds: string[]) {
        super(
            auditEvent,
            entityIds);
    }
}

export class EntityModelAttributes {
    constructor(
        public attributes: EntityAttribute[]) {
    }
}

export class EntityModelRisks {
    constructor(
        public risks: EntityModelRisksRisks) {
    }
}

export class EntityModelRisksRisks {
    constructor(
        public openRelatedEntityRiskCount: number,
        public openRelatedEntityRiskHighestSeverity: Optional<Severity>,
        public openRiskedEntityRiskIds: string[]) {
    }
}

export class EntityProfile extends Document implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName);
    }
}

export class EntityPropertyIdentifier {
    constructor(
        public name: string,
        public raw: string,
        public type: EntityPropertyType,
        public valueType: EntityPropertyValueType) {
    }
}

export class EntityReference {
    constructor(
        public id: string,
        public typeName: string) {
    }
}

export class EntityReferenceData {
    constructor(
        public id: string,
        public name: string,
        public tenantId: Optional<string>,
        public typeName: Optional<string>) {
    }
}

export class EntitySensitivityUpdateAuditEventModel extends AuditEventModel {
    constructor(
        public auditEvent: EntitySensitivityUpdateAuditEvent,
        entityIds: string[]) {
        super(
            auditEvent,
            entityIds);
    }
}

export class EntitySnapshotControllerGetSnapshotsRequest {
    constructor(
        public ids: string[]) {
    }
}

export class EntitySnapshotControllerGetSnapshotsResponse {
    constructor(
        public entitySnapshots: EntitySnapshot[]) {
    }
}

export class EntityTypeMetadataModel {
    constructor(
        public accessEvents: boolean,
        public accessGraphTenantTypes: TenantType[],
        public awsFederation: boolean,
        public awsInboundAccessGraph: boolean,
        public awsOutboundAccessGraph: boolean,
        public azureInboundAccessGraph: boolean,
        public azureOutboundAccessGraph: boolean,
        public category: Optional<ObjectTypeCategory>,
        public entitiesView: boolean,
        public entitiesViewName: string,
        public events: boolean,
        public gcpInboundAccessGraph: boolean,
        public gcpOutboundAccessGraph: boolean,
        public maliciousFiles: boolean,
        public name: string,
        public networkGraph: boolean,
        public packages: boolean,
        public permissionEvaluation: boolean,
        public snapshots: boolean,
        public tenantType: TenantType,
        public typeName: string,
        public vulnerabilities: boolean) {
    }
}

export class EventControllerGetEventFilterItemPageRequest {
    constructor(
        public limit: number,
        public property: EventModelProperty,
        public searchText: Optional<string>,
        public skip: number) {
    }
}

export class EventControllerGetEventFilterItemPageResponseBase {
    constructor() {
    }
}

export class EventControllerGetEventFiltersRequest {
    constructor() {
    }
}

export class EventControllerGetEventFiltersResponse {
    constructor(
        public errorCodeItems: Optional<FilterItems<string>>,
        public identitySearchableIdReferenceItems: Optional<FilterItems<EntitySearchableIdReference>>,
        public ipAddressItems: Optional<FilterItems<string>>,
        public nameItems: Optional<FilterItems<string>>,
        public originatorEntitySearchableIdReferenceItems: Optional<FilterItems<EntitySearchableIdReference>>,
        public resourceSearchableIdReferenceItems: Optional<FilterItems<EntitySearchableIdReference>>,
        public serviceSearchableIdReferenceItems: Optional<FilterItems<EntitySearchableIdReference>>,
        public tenantIdItems: FilterItems<string>,
        public timeRange: FilterTimeRange) {
    }
}

export class EventControllerGetEventModelCountResponse {
    constructor(
        public eventModelCount: number) {
    }
}

export class EventControllerGetEventModelPageRequest {
    constructor(
        public filters: EventControllerGetEventModelPageRequestFilters,
        public limit: number,
        public nextPageSearchCursor: Optional<ElasticsearchIndexSearchCursor>) {
    }
}

export class EventControllerGetEventModelPageRequestFilters {
    constructor(
        public errorCodeSelection: Optional<PagedItemSelection<string>>,
        public identityIdSelection: Optional<PagedItemSelection<string>>,
        public ipAddressSelection: Optional<PagedItemSelection<string>>,
        public nameSelection: Optional<PagedItemSelection<string>>,
        public originatorEntityIdSelection: Optional<PagedItemSelection<string>>,
        public resourceIdSelection: Optional<PagedItemSelection<string>>,
        public serviceIdSelection: Optional<PagedItemSelection<string>>,
        public tenantIdSelection: Optional<ItemSelection<string>>,
        public timeRangeSelection: Optional<TimeRangeSelection>) {
    }
}

export class EventControllerGetEventModelPageResponse {
    constructor(
        public eventModelPage: ElasticsearchItemPage<EventModel>) {
    }
}

export class EventControllerGetEventModelsRequest {
    constructor(
        public ids: string[]) {
    }
}

export class EventControllerGetEventModelsResponse {
    constructor(
        public eventModels: EventModel[]) {
    }
}

export class EventControllerGetRiskEventFiltersRequest extends EventControllerGetEventFiltersRequest {
    constructor(
        public riskId: string) {
        super();
    }
}

export class EventControllerGetRiskEventModelPageRequest extends EventControllerGetEventModelPageRequest {
    constructor(
        filters: EventControllerGetEventModelPageRequestFilters,
        limit: number,
        nextPageSearchCursor: Optional<ElasticsearchIndexSearchCursor>,
        public riskId: string) {
        super(
            filters,
            limit,
            nextPageSearchCursor);
    }
}

export class EventControllerGetTenantEventFiltersRequest extends EventControllerGetEventFiltersRequest {
    constructor() {
        super();
    }
}

export class EventControllerGetTenantEventModelPageRequest extends EventControllerGetEventModelPageRequest {
    constructor(
        filters: EventControllerGetEventModelPageRequestFilters,
        limit: number,
        nextPageSearchCursor: Optional<ElasticsearchIndexSearchCursor>) {
        super(
            filters,
            limit,
            nextPageSearchCursor);
    }
}

export class EventModel {
    constructor(
        public event: Event,
        public identityIdReference: Optional<string>,
        public originatorEntityIdReference: Optional<string>,
        public resourceIdReferences: string[],
        public serviceIdReference: Optional<string>) {
    }
}

export class FeatureConfigurationSection extends ConfigurationSection {
    constructor(
        public featureNameToEnabledMap: Dictionary<boolean>) {
        super();
    }
}

export class FederationEdgeModel extends Document implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        public destinationEntity: FederationEdgeModelDestinationEntity,
        public sourceEntity: FederationEdgeModelSourceEntity) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName);
    }
}

export class FederationEdgeModelEntity {
    constructor(
        public attributes: EntityModelAttributes,
        public searchableReference: EntitySearchableReference,
        public tenantId: string) {
    }
}

export class FederationEdgeModelSourceEntity extends FederationEdgeModelEntity {
    constructor(
        attributes: EntityModelAttributes,
        searchableReference: EntitySearchableReference,
        tenantId: string,
        public typeName: string) {
        super(
            attributes,
            searchableReference,
            tenantId);
    }
}

export class FileControllerGetFileModelsRequest {
    constructor(
        public contentSha256Strings: string[]) {
    }
}

export class FileControllerGetFileModelsResponse {
    constructor(
        public tiFileModels: TiFileModel[]) {
    }
}

export class FilterItems<TItem> {
    constructor(
        public emptyValue: boolean,
        public items: TItem[]) {
    }
}

export class FilterTimeRange {
    constructor(
        public emptyValue: boolean,
        public timeRange: Optional<TimeRange>) {
    }
}

export class FolderControllerDeleteFolderRequest {
    constructor(
        public id: string) {
    }
}

export class FolderControllerInsertFolderRequest {
    constructor(
        public name: string,
        public parentFolderId: string) {
    }
}

export class FolderControllerInsertFolderResponse {
    constructor(
        public folderScopeNodeModel: ScopeNodeModel) {
    }
}

export class FolderControllerUpdateFolderRequest {
    constructor(
        public id: string,
        public name: string) {
    }
}

export class FolderControllerUpdateFolderResponse {
    constructor(
        public folderScopeNodeModel: ScopeNodeModel) {
    }
}

export class GciDirectoryGroupProfileIdentitiesInactive {
    constructor(
        public startTime: Optional<string>) {
    }
}

export class GciDirectoryUserEventSyncerConfigurationSection extends ConfigurationSection {
    constructor(
        public syncBufferTimeFrame: string) {
        super();
    }
}

export class GciEntityProfile extends EntityProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName);
    }
}

export class GciIdentityProfileGcp {
    constructor(
        public activityTime: Optional<string>,
        public activityTimeUpdateTime: Optional<string>,
        public tenantIdToActivityTimeMap: Dictionary<string>) {
    }
}

export class GciParentResourceData {
    constructor(
        public displayName: string,
        public rawShortId: string) {
    }
}

export class GciPrincipalModelAnalyzing {
    constructor(
        public reason: EntityAnalyzingReason,
        public tenantIds: Optional<string[]>) {
    }
}

export class GciPrincipalReference {
    constructor(
        public id: Optional<string>,
        public rawId: string,
        public type: GciPrincipalType) {
    }
}

export class GciTenantConfigurationEvents {
    constructor(
        public syncTimeFrame: string,
        public syncWindowTimeFrame: string) {
    }
}

export class GciTenantEntityDomain {
    constructor(
        public aliases: GciTenantEntityDomainAlias[],
        public creationTime: Optional<string>,
        public name: string,
        public primary: boolean) {
    }
}

export class GciTenantEntityDomainAlias {
    constructor(
        public creationTime: string,
        public name: string) {
    }
}

export class GciTenantEntityProfile extends GciEntityProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GciTenantEntityProfile");
    }
}

export class GciTenantStateEvents {
    constructor(
        public userAuthorizationTokenCreationSyncTime: Optional<string>) {
    }
}

export class GciTenantStateGcp {
    constructor(
        public organizationRawId: string,
        public organizationRawShortId: string) {
    }
}

export class GcpAccessGraphPermissionPathIdentifier extends AccessGraphPermissionPathIdentifier {
    constructor(
        public roleId: string,
        public scopeResourceId: Optional<string>) {
        super();
    }
}

export class GcpAccessListPermissionActionData extends AccessListPermissionActionData {
    constructor(
        excessive: boolean,
        public permissionPathIdentifiers: GcpAccessGraphPermissionPathIdentifier[]) {
        super(excessive);
    }
}

export class GcpApiKeysKeyRestrictions {
    constructor(
        public clientRawIdentifiers: Optional<string[]>,
        public clientType: GcpApiKeysKeyRestrictionsClientType,
        public serviceHostNames: Optional<string[]>) {
    }
}

export class GcpAppEngineApplicationFirewallRule {
    constructor(
        public action: string,
        public description: string,
        public priority: number,
        public subnets: string[]) {
    }
}

export class GcpApplicationAuthenticationProviderConfigurationSection extends ConfigurationSection implements INullableConfigurationSection {
    constructor(
        public serviceAccount: GcpApplicationAuthenticationProviderConfigurationServiceAccount,
        public tenantRawShortNameId: string,
        public tenantRawShortNumberId: string) {
        super();
    }
}

export class GcpApplicationAuthenticationProviderConfigurationServiceAccount {
    constructor(
        public encryptedPrivateKey: EncryptedData,
        public mail: string) {
    }
}

export class GcpComputeFirewallPolicyRule {
    constructor(
        public action: GcpComputeFirewallPolicyRuleAction,
        public description: string,
        public direction: GcpComputeFirewallPolicyRuleDirection,
        public filter: GcpComputeFirewallPolicyRuleFilter,
        public name: string,
        public priority: number,
        public scopes: GcpComputeFirewallPolicyRuleScope[],
        public target: GcpComputeFirewallPolicyRuleTarget) {
    }
}

export class GcpComputeFirewallPolicyRuleFilter {
    constructor(
        public destinationSubnets: string[],
        public inboundVpcSecureTags: Optional<GcpComputeFirewallPolicyRuleSecureTag[]>,
        public sourceSubnets: string[]) {
    }
}

export class GcpComputeFirewallPolicyRuleScope {
    constructor(
        public portRanges: IntRange[],
        public protocolRange: IntRange) {
    }
}

export class GcpComputeFirewallPolicyRuleSecureTag {
    constructor(
        public enabled: boolean,
        public valueRawId: string) {
    }
}

export class GcpComputeFirewallPolicyRuleTarget {
    constructor(
        public any: boolean,
        public secureTags: Optional<GcpComputeFirewallPolicyRuleSecureTag[]>,
        public serviceAccountMails: string[],
        public vpcUrls: Optional<string[]>) {
    }
}

export class GcpComputeFirewallRuleFilter {
    constructor(
        public destinationSubnets: string[],
        public inboundVpc: Optional<GcpComputeFirewallRuleFilterInboundVpc>,
        public sourceSubnets: string[]) {
    }
}

export class GcpComputeFirewallRuleFilterInboundVpc {
    constructor(
        public networkTags: string[],
        public serviceAccountMails: string[]) {
    }
}

export class GcpComputeFirewallRuleScope {
    constructor(
        public destinationPortRanges: IntRange[],
        public protocolRange: IntRange) {
    }
}

export class GcpComputeFirewallRuleTarget {
    constructor(
        public any: boolean,
        public networkTags: string[],
        public serviceAccountMails: string[]) {
    }
}

export class GcpComputeForwardingRuleTarget {
    constructor(
        public resourceNetworkUrl: Optional<string>,
        public serviceType: Optional<GcpComputeForwardingRuleTargetServiceType>) {
    }
}

export class GcpComputeInstanceScopePropertyData {
    constructor(
        public enabled: boolean,
        public source: GcpComputeInstanceScopePropertySource) {
    }
}

export class GcpComputeLoadBalancerResources {
    constructor(
        public backendResourceIdReferences: string[],
        public forwardingRuleIds: string[],
        public forwardingRuleIpAddresses: string[],
        public targetPoolId: Optional<string>,
        public targetProxyIds: Optional<string[]>,
        public urlMapId: Optional<string>) {
    }
}

export class GcpComputeNetworkEndpointGroupEndpoint {
    constructor(
        public computeInstanceAssetPath: Optional<string>,
        public dnsName: Optional<string>,
        public ipAddress: Optional<string>,
        public port: Optional<number>) {
    }
}

export class GcpComputeRouteNextHop {
    constructor(
        public hubRawId: Optional<string>,
        public ipAddress: Optional<string>,
        public peeringRawShortId: Optional<string>,
        public type: GcpComputeRouteNextHopType,
        public url: Optional<string>) {
    }
}

export class GcpComputeSubnetFlowLogs {
    constructor(
        public aggregationTimeFrame: string,
        public includeMetadata: boolean,
        public sampleRate: number) {
    }
}

export class GcpComputeUrlMapBackend {
    constructor(
        public redirectHost: Optional<string>,
        public redirectPath: Optional<string>,
        public resourceUrls: Optional<string[]>) {
    }
}

export class GcpComputeUrlMapRule {
    constructor(
        public backend: GcpComputeUrlMapBackend,
        public host: Optional<string>,
        public path: Optional<string>) {
    }
}

export class GcpContainerClusterAuthorizedSubnetData {
    constructor(
        public name: string,
        public subnet: string) {
    }
}

export class GcpContainerClusterInstanceInboundExternalRiskInstanceData {
    constructor(
        public id: string,
        public inboundExternalWideRange: boolean) {
    }
}

export class GcpContainerClusterNetwork {
    constructor(
        public aliasSubnet: boolean,
        public authorizedSubnetDatas: Optional<GcpContainerClusterAuthorizedSubnetData[]>,
        public dnsName: Optional<string>,
        public inboundExternalGcpRange: boolean,
        public nodePublicIpAddressExists: boolean,
        public nodePublicIpv6AddressExists: boolean,
        public podSubnet: Optional<string>,
        public privateIpAddress: Optional<string>,
        public privateSubnet: Optional<string>,
        public publicIpAddress: Optional<string>,
        public serviceSubnet: Optional<string>,
        public subnetUrl: string,
        public vpcUrl: string) {
    }
}

export class GcpContainerClusterNodePoolInstanceAutoscalingLimits {
    constructor(
        public maxInstanceCount: number,
        public minInstanceCount: number) {
    }
}

export class GcpContainerClusterNodePoolNode {
    constructor(
        public imageType: GcpContainerClusterNodePoolNodeImageType,
        public kubernetesTags: ResourceTag[],
        public kubernetesTaints: KubernetesTaint[],
        public kubernetesVersion: string,
        public legacyComputeMetadataServer: boolean,
        public publicIpAddressExists: boolean,
        public rawType: string,
        public workloadIdentity: boolean) {
    }
}

export class GcpContainerClusterNodePoolNodeAutoscaling {
    constructor(
        public totalAutoscalingLimits: Optional<GcpContainerClusterNodePoolInstanceAutoscalingLimits>,
        public zonalAutoscalingLimits: Optional<GcpContainerClusterNodePoolInstanceAutoscalingLimits>) {
    }
}

export class GcpContainerClusterWorkloadIdentityDisabledRiskNodePoolData {
    constructor(
        public id: string,
        public sensitive: boolean,
        public severePermissionActionSeverityServiceAccountId: Optional<string>) {
    }
}

export class GcpDnsManagedZoneDnsSec {
    constructor(
        public keySigningKeyAlgorithm: Optional<GcpDnsManagedZoneDnsSecSigningKeyAlgorithm>,
        public status: GcpDnsManagedZoneDnsSecStatus,
        public zoneSigningKeyAlgorithm: Optional<GcpDnsManagedZoneDnsSecSigningKeyAlgorithm>) {
    }
}

export class GcpEntityProfile extends EntityProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName);
    }
}

export class GcpEventSyncerConfigurationSection extends ConfigurationSection {
    constructor(
        public syncBufferTimeFrame: string) {
        super();
    }
}

export class GcpExcessivePermissionPrincipalRiskRoleBindingData {
    constructor(
        public resolutionRoleIds: string[],
        public resolutionType: Optional<GcpExcessivePermissionPrincipalRiskRoleBindingDataResolutionType>,
        public scopeExistingResolutionRoleIds: string[]) {
    }
}

export class GcpExcessivePermissionPrincipalRiskScopeRoleBindingData {
    constructor(
        public ids: string[],
        public resolution: GcpExcessivePermissionPrincipalRiskRoleBindingResolution,
        public scopeEntityId: string) {
    }
}

export class GcpFolderConfigurationSections {
    constructor(
        public analysis: GcpFolderConfigurationAnalysis) {
    }
}

export class GcpFunctionsFunctionEnvironmentVariable {
    constructor(
        public key: string,
        public type: GcpFunctionsFunctionEnvironmentVariableType,
        public value: string) {
    }
}

export class GcpFunctionsFunctionEnvironmentVariableSecretExistsRiskEnvironmentVariableData {
    constructor(
        public key: string,
        public secretExcluded: boolean,
        public secretExists: boolean,
        public type: GcpFunctionsFunctionEnvironmentVariableType) {
    }
}

export class GcpIamGcpManagedPartialServiceAccountProfile extends GciEntityProfile implements IDocument, ITypeNameObject, IGciIdentityProfile {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        public gcp: GciIdentityProfileGcp) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpIamGcpManagedPartialServiceAccountProfile");
    }
}

export class GcpIamPartialServiceAccountProfile extends GcpEntityProfile implements IDocument, ITypeNameObject, IGciIdentityProfile {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        public gcp: GciIdentityProfileGcp) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpIamPartialServiceAccountProfile");
    }
}

export class GcpIamRoleBindingCondition {
    constructor(
        public description: string,
        public expirationTime: Optional<string>,
        public expression: string,
        public title: string) {
    }
}

export class GcpIamRoleBindingDeltaModel {
    constructor(
        public action: GcpIamRoleBindingDeltaAction,
        public principalIdReference: string,
        public roleIdReference: string,
        public scopeResourceIdReference: string) {
    }
}

export class GcpIamRoleBindingModelPermissionUsage {
    constructor(
        public excessivePermissionActions: string[],
        public nonExcessiveNotUsedPermissionActions: string[],
        public usedPermissionActions: string[]) {
    }
}

export class GcpIamWorkforcePoolAccessRestriction {
    constructor(
        public allowedServiceHostNames: string[],
        public programmaticSigninDisabled: boolean) {
    }
}

export class GcpIamWorkforcePoolProviderAttributeData {
    constructor(
        public condition: Optional<string>,
        public federationAttributeToGcpAttributeMap: Dictionary<string>) {
    }
}

export class GcpIamWorkloadIdentityPoolProviderAttributeData {
    constructor(
        public condition: Optional<string>,
        public federationAttributeToGcpAttributeMap: Dictionary<string>) {
    }
}

export class GcpInboundExternalResourceRiskNetworkData {
    constructor(
        public securityPerimeterResourceIdToExposedNetworkScopesMap: Dictionary<DestinationNetworkScope[]>,
        public sourceSubnets: string[]) {
    }
}

export class GcpMonitoringAlertPolicyCondition {
    constructor(
        public displayName: string,
        public filter: Optional<string>,
        public name: string,
        public query: Optional<string>,
        public type: GcpMonitoringAlertPolicyConditionType) {
    }
}

export class GcpOrganizationAuditEventData {
    constructor(
        public enabled: boolean,
        public folderEnabled: boolean,
        public permissionTypes: CloudProviderTenantPermissionType[],
        public sinkPubSubSubscriptionRawId: Optional<string>) {
    }
}

export class GcpPrincipalNotAllowedResourceRoleRiskItem {
    constructor(
        public roleBindingIds: string[],
        public scopeResourceId: string) {
    }
}

export class GcpPrincipalNotAllowedResourceRoleRiskPolicyConfigurationRoles {
    constructor(
        public ids: string[],
        public operator: GcpPrincipalNotAllowedResourceRoleRiskPolicyConfigurationRolesOperator) {
    }
}

export class GcpPrincipalResourcePermissionRiskItem {
    constructor(
        public permissionActions: string[],
        public principalId: string,
        public resourceId: string,
        public roleBindingIds: string[]) {
    }
}

export class GcpPubSubSubscriptionMessageDeliverySettings {
    constructor(
        public acknowledgementTimeoutDuration: string,
        public deleteAcknowledged: boolean,
        public orderingEnabled: boolean,
        public retryPolicy: GcpPubSubSubscriptionMessageDeliveryRetryPolicy,
        public singleDeliveryEnabled: boolean,
        public type: GcpPubSubSubscriptionMessageDeliveryType) {
    }
}

export class GcpResourceProfile extends GcpEntityProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        public creation: Optional<GcpResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName);
    }
}

export class GcpResourceProfileCreation {
    constructor(
        public eventIdentityReference: Optional<string>,
        public eventOriginatorEntityReference: Optional<string>,
        public time: string) {
    }
}

export class GcpScopeConfigurationOutpostRegionData {
    constructor(
        public availabilityZoneNames: string[],
        public vpcPrivateSubnetRawName: string) {
    }
}

export class GcpScopeConfigurationSections {
    constructor(
        public dataAnalysis: GcpScopeConfigurationDataAnalysis,
        public outpost: GcpScopeConfigurationOutpost,
        public workloadAnalysis: GcpScopeConfigurationWorkloadAnalysis) {
    }
}

export class GcpScopeResourceReference {
    constructor(
        public assetPath: string,
        public idReference: string) {
    }
}

export class GcpSecretManagerSecretProfile extends GcpResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<GcpResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSecretManagerSecretProfile",
            creation);
    }
}

export class GcpSecretManagerSecretVersionProfile extends GcpResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<GcpResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSecretManagerSecretVersionProfile",
            creation);
    }
}

export class GcpServiceAccountOriginatorServiceAccountData {
    constructor(
        public authorizationScopes: string[],
        public authorizationScopeType: GcpServiceAccountOriginatorAuthorizationScopeType,
        public serviceAccountMail: Optional<string>) {
    }
}

export class GcpSpannerInstanceDatabaseProfile extends GcpResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<GcpResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSpannerInstanceDatabaseProfile",
            creation);
    }
}

export class GcpSpannerInstanceProfile extends GcpResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<GcpResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSpannerInstanceProfile",
            creation);
    }
}

export class GcpSqlInstanceAuthorizedSubnetData {
    constructor(
        public name: string,
        public subnet: string) {
    }
}

export class GcpSqlInstanceDatabaseProfile extends GcpResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<GcpResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstanceDatabaseProfile",
            creation);
    }
}

export class GcpSqlInstanceProfile extends GcpResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<GcpResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstanceProfile",
            creation);
    }
}

export class GcpSqlInstanceUserProfile extends GcpResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<GcpResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstanceUserProfile",
            creation);
    }
}

export class GcpStorageBucketProfile extends GcpResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<GcpResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpStorageBucketProfile",
            creation);
    }
}

export class GcpTenantConfigurationSink {
    constructor(
        public pubSubSubscriptionRawId: string,
        public rawId: string) {
    }
}

export class GcpTenantEntityUsage {
    constructor(
        public appEngine: GcpTenantEntityUsageAppEngine,
        public bigQuery: GcpTenantEntityUsageBigQuery,
        public cloudSql: GcpTenantEntityUsageCloudSql,
        public common: GcpTenantEntityUsageCommon,
        public compute: GcpTenantEntityUsageCompute,
        public iam: GcpTenantEntityUsageIam,
        public startTime: string,
        public storage: GcpTenantEntityUsageStorage) {
    }
}

export class GcpTenantEntityUsageAppEngine {
    constructor(
        public activeInstancesDailyAverageCount: number) {
    }
}

export class GcpTenantEntityUsageBigQuery {
    constructor(
        public inflightJobDailyAverageCount: number,
        public inflightQueryDailyAverageCount: number,
        public storageDailyAverageSize: number,
        public tableDailyAverageCount: number) {
    }
}

export class GcpTenantEntityUsageCloudSql {
    constructor(
        public activeInstanceDailyAverageCount: number) {
    }
}

export class GcpTenantEntityUsageCommon {
    constructor(
        public activeOauthTokens: boolean,
        public activeOwnerCount: number,
        public apiRequestDailyAverageCount: number,
        public apiUniqueClientDailyAverageCount: number,
        public billingAccount: boolean,
        public billingServiceSystemNames: string[],
        public carbonFootprintDailyKg: Optional<number>,
        public dataStoreRequestDailyAverageCount: number,
        public unusedRecommendationExists: boolean) {
    }
}

export class GcpTenantEntityUsageCompute {
    constructor(
        public activeInstanceDailyAverageCount: number,
        public vpcEgressDailyAverageBytes: number,
        public vpcIngressDailyAverageBytes: number) {
    }
}

export class GcpTenantEntityUsageIam {
    constructor(
        public authenticatedServiceAccountDailyAverageCount: number) {
    }
}

export class GcpTenantEntityUsageStorage {
    constructor(
        public dailyAverageSize: number,
        public objectDailyAverageCount: number,
        public requestDailyAverageCount: number) {
    }
}

export class GcpTenantStateCodeAnalysis {
    constructor(
        public terraformStateBucketNames: string[],
        public validTerraformStateBucketNameToObjectPathPrefixesMap: Dictionary<string[]>) {
    }
}

export class GcpTenantStateEvents {
    constructor(
        public serviceAccountSyncTimes: GcpTenantStateEventsServiceAccountSyncTimes,
        public syncTime: Optional<string>,
        public time: Optional<string>,
        public unresolvedTime: Optional<string>) {
    }
}

export class GcpTenantStateEventsServiceAccountSyncTimes {
    constructor(
        public authentication: Optional<string>,
        public creation: Optional<string>,
        public keyAuthentication: Optional<string>) {
    }
}

export class GitHubServerConfigurationProviderConfigurationSection extends ConfigurationSection implements INullableConfigurationSection {
    constructor(
        public applicationClientId: string,
        public applicationEncryptedClientSecret: EncryptedData,
        public applicationEncryptedPrivateKey: EncryptedData,
        public applicationId: number,
        public applicationName: string) {
        super();
    }
}

export class GlobalConsoleAppConfigurationSection extends ConfigurationSection {
    constructor(
        public consoleAppGeographyTypeToUrlMap: Dictionary<string>,
        public globalConsoleAppUrl: string) {
        super();
    }
}

export class GlobalSlackConfigurationSection extends ConfigurationSection implements INullableConfigurationSection {
    constructor(
        public encryptedSecret: EncryptedData,
        public slashCommand: Optional<string>) {
        super();
    }
}

export class Graph {
    constructor(
        public edges: GraphEdge[],
        public relatedEntityIds: string[]) {
    }
}

export class GraphEdge {
    constructor(
        public destinationNodeId: string,
        public sourceNodeId: string) {
    }
}

export class GraphNode {
    constructor(
        public id: string) {
    }
}
export interface IAadFolderConfiguration extends IAadScopeConfiguration
 {
}

export interface IAadScopeConfiguration extends IChildScopeConfiguration
 {
}

export interface IAccessPrincipalRisk extends IRisk
 {
    entityId: string;
}

export interface IAction
 {
}

export interface IActivityRisk extends IRisk
 {
}

export interface IAwsAccessResource extends IResource
 {
    accessLevel: Optional<AwsResourceAccessLevel>;
}

export interface IAwsAccessResourceModel extends IResourceModel
 {
    accessLevel: Optional<AwsResourceAccessLevel>;
}

export interface IAwsActivityResourceProfile extends IDocument
 {
}

export interface IAwsCertificateResource extends IAwsResource
 {
    certificateIds: string[];
}

export interface IAwsClassicSecurityGroup extends IAwsResource
 {
    sourceEc2SecurityGroupIds: string[];
    sourceSubnets: string[];
}

export interface IAwsCloudTrailTrailEventSelector
 {
}

export interface IAwsCloudWatchLoggingResource extends IAwsResource
 {
    cloudWatchLogTypes: string[];
    cloudWatchLoggingEnabled: boolean;
}

export interface IAwsCloudWatchLoggingResourceModel extends IEntityModel
 {
}

export interface IAwsDnsRecordResource extends IAwsResource, IDnsRecordEntity
 {
}

export interface IAwsDocDbCluster extends IAwsCloudWatchLoggingResource, IAwsEncryptedResource
 {
    backupRetentionTimeFrame: string;
    deleteProtection: Optional<boolean>;
    engineVersion: Optional<string>;
    globalClusterId: Optional<string>;
    status: string;
    storageSize: number;
}

export interface IAwsDocDbClusterModel extends IAwsCloudWatchLoggingResourceModel, IAwsEncryptedResourceModel
 {
    instanceIds: Optional<string[]>;
    snapshotIds: string[];
    type: AwsDocDbClusterModelType;
}

export interface IAwsDocDbClusterSnapshot extends IAwsEncryptedResource
 {
    clusterId: Optional<string>;
    storageSize: Optional<number>;
    type: AwsResourceSnapshotType;
}

export interface IAwsDocDbClusterSnapshotModel extends IAwsEncryptedResourceModel
 {
    clusterType: AwsDocDbClusterModelType;
}

export interface IAwsEncryptedResource extends IAwsResource
 {
    encryptionExists: boolean;
    kmsEncryptionKeyOrAliasArns: string[];
}

export interface IAwsEncryptedResourceModel extends IEntityModel
 {
    kmsEncryptionKeyIdReferences: string[];
}

export interface IAwsExcessivePermissionPrincipalRisk extends IExcessivePermissionPrincipalRisk
 {
}

export interface IAwsFederationUserModel extends IEntityModel
 {
    awsRoleIds: string[];
}

export interface IAwsFolderConfiguration extends IAwsScopeConfiguration
 {
}

export interface IAwsIdentity extends IAwsResource
 {
}

export interface IAwsNetworkedResource extends IAwsResource, INetworkedResource
 {
    securityGroupIds: string[];
    subnetIds: string[];
    vpcIds: string[];
}

export interface IAwsNetworkedResourceModel extends IResourceModel
 {
}

export interface IAwsNetworkEvaluationNetworkedResource extends IAwsNetworkedResource
 {
}

export interface IAwsOriginatorComputeResource extends IAwsResource, IAwsOriginatorWorkloadResource
 {
}

export interface IAwsOriginatorIdentity extends IEntity
 {
}

export interface IAwsOriginatorKubernetesResource extends IAwsOriginatorWorkloadResource
 {
}

export interface IAwsOriginatorWorkloadResource
 {
}

export interface IAwsPolicyResource extends IAwsResource
 {
    policyDocument: Optional<AwsIamPolicyDocument>;
    policyDocumentExists: boolean;
}

export interface IAwsPolicyResourceModel extends IResourceModel
 {
}

export interface IAwsPolicyResourceProfile extends IDocument
 {
}

export interface IAwsPolicyResourceSnapshot extends IDocument
 {
    policyDocument: Optional<AwsIamPolicyDocument>;
}

export interface IAwsPrincipal extends IAwsResource
 {
}

export interface IAwsRdsDocDbResource extends IAwsResource
 {
}

export interface IAwsRdsNeptuneResource extends IAwsResource
 {
}

export interface IAwsResource extends IResource
 {
    arn: Optional<string>;
}

export interface IAwsScopeConfiguration extends IChildScopeConfiguration
 {
}

export interface IAwsTagResource extends IAwsResource
 {
}

export interface IAwsTenantEntity extends ITenantEntity
 {
}

export interface IAwsTenantEntityModel extends ITenantEntityModel
 {
}

export interface IAzureComputeVirtualMachine extends IAzureDnsRecordResource, IVirtualMachine
 {
    interfaceNetworkSecurityGroupIds: string[];
    networkInterfaceIds: string[];
    privateIpAddresses: string[];
    publicIpAddressIds: string[];
    specification: Optional<AzureComputeVirtualMachineResourceSpecification>;
    status: AzureComputeVirtualMachineStatus;
    subnetNetworkSecurityGroupIds: string[];
    virtualMachineScaleSetId: Optional<string>;
}

export interface IAzureComputeVirtualMachineProfile
 {
    stopTime: Optional<string>;
}

export interface IAzureDnsRecordResource extends IDnsRecordEntity, IResource
 {
}

export interface IAzureEncryptedResource
 {
    encryptionExists: boolean;
    vaultKeyInfo: Optional<AzureKeyVaultVaultKeyInfo>;
}

export interface IAzureEncryptedResourceModel extends IEntityModel
 {
    vaultKeyIdReferences: string[];
}

export interface IAzureEntity extends IEntity
 {
    path: Optional<string>;
    rawPath: Optional<string>;
}

export interface IAzureExcessivePermissionPrincipalRisk extends IExcessivePermissionPrincipalRisk
 {
}

export interface IAzureExtensionResource
 {
    scopeEntityReference: AzureScopeEntityReference;
}

export interface IAzureFolderConfiguration extends IAzureScopeConfiguration
 {
}

export interface IAzureManagedIdentityOriginatorResource extends IAzureOriginatorResource
 {
    managedIdentityExists: boolean;
    systemManagedIdentityServicePrincipalId: Optional<string>;
    userManagedIdentityPaths: string[];
}

export interface IAzureManagedIdentityOriginatorResourceModel
 {
    userManagedIdentityServicePrincipalIds: string[];
}

export interface IAzureNetworkAliasDnsZoneRecordSetResource
 {
    alias: boolean;
    aliasResourceEntityPath: Optional<string>;
}

export interface IAzureNetworkedResource extends INetworkedResource
 {
    applicationSecurityGroupIds: string[];
    interfaceNetworkSecurityGroupIds: string[];
    privateIpAddresses: string[];
    publicIpAddressIds: string[];
    subnetIds: string[];
    subnetNetworkSecurityGroupIds: string[];
    virtualNetworkId: Optional<string>;
}

export interface IAzureNetworkedResourceModel extends IResourceModel
 {
}

export interface IAzureNetworkEvaluationResource
 {
}

export interface IAzureNetworkInterface extends IAzureDnsRecordResource, IAzureEntity
 {
}

export interface IAzureOriginatorKubernetesResource extends IAzureOriginatorResource
 {
}

export interface IAzureOriginatorResource extends IAzureEntity
 {
}

export interface IAzurePrivateEndpointResource
 {
}

export interface IAzurePublicIpAddress extends IAzureDnsRecordResource, IAzureEntity
 {
    attachedResourcePath: Optional<string>;
    dnsName: Optional<string>;
    ipAddress: Optional<string>;
    sku: AzureNetworkPublicIpAddressSku;
    type: AzureNetworkPublicIpAddressType;
}

export interface IAzureScopeConfiguration extends IChildScopeConfiguration
 {
}

export interface IAzureTagResource extends IAzureEntity
 {
}

export interface IBehaviorIdentityRiskModel extends IRiskModel
 {
}

export interface IChildScopeConfiguration extends IScopeConfiguration
 {
    parentScopeId: string;
}

export interface ICiFolderConfiguration extends ICiScopeConfiguration
 {
}

export interface ICiScopeConfiguration extends IChildScopeConfiguration
 {
}

export interface ICloudProviderResourceChange
 {
    resourceId: string;
}

export interface ICodeFolderConfiguration extends ICodeScopeConfiguration
 {
}

export interface ICodeInfrastructureChange
 {
}

export interface ICodeScopeConfiguration extends IChildScopeConfiguration
 {
}

export interface IConsistentHashable
 {
}

export interface IContainerImage extends IWorkloadResource
 {
    data: ContainerImageData;
}

export interface IContainerImageFilters
 {
    containerImageCreationTimeRange: FilterTimeRange;
    scanTimeRange: FilterTimeRange;
}

export interface IContainerImageModel extends IRelatedTenantsEntityModel, IWorkloadResourceModel
 {
    kubernetesWorkloadResourceIds: string[];
    operatingSystemType: Optional<OperatingSystemType>;
    repositoryIdReference: string;
    virtualMachineIds: string[];
    vulnerabilitySeveritiesSortValue: string;
    workloadClusterResourceIds: string[];
}

export interface IContainerImageRepository extends IEntity
 {
    containerImageIds: string[];
    rawUrl: string;
}

export interface IContainerImageRepositoryModel extends IEntityModel
 {
    containerImageResourceIds: string[];
    containerImageScanStatus: Optional<ContainerImageDataScanStatus>;
    containerImageVulnerabilities: VulnerabilityData[];
}

export interface IContainerImageResource extends IResource
 {
    containerImageRawUrls: string[];
}

export interface IContainerImageResourceModel extends IEntityModel
 {
    containerImageIdReferences: string[];
    containerImageScanStatus: Optional<ContainerImageDataScanStatus>;
    containerImageVulnerabilities: VulnerabilityData[];
}

export interface IDataAnalysisResource extends IResource
 {
}

export interface IDataAnalysisResourceModel extends IResourceModel
 {
    dataAnalysisError: Optional<DataAnalysisResourceModelDataAnalysisError>;
    dataAnalysisPriorityScanRequested: boolean;
    dataAnalysisResourceSensitivity: Optional<DataAnalysisSensitivity>;
    dataAnalysisStatus: DataAnalysisResourceModelDataAnalysisStatus;
    dataAnalysisStatusSortValue: number;
    dataCategoryToDataSegmentCountMap: Optional<Dictionary<number>>;
    dataCategoryToSensitivityMap: Optional<Dictionary<DataSensitivity>>;
    dataClassifierIdToDataSegmentCountMap: Optional<Dictionary<number>>;
    dataLastModified: Optional<string>;
    dataSensitivities: Optional<DataSensitivity[]>;
    dataSensitivityToDataSegmentCountMap: Optional<Dictionary<number>>;
    highestDataSensitivity: Optional<DataSensitivity>;
    scannedDataSegmentCount: Optional<number>;
    scanTime: Optional<string>;
    sensitiveDataSegmentCount: Optional<number>;
    storageSize: Optional<number>;
}

export interface IDataAnalysisResourceModelFilters
 {
    dataAnalysisResource: DataAnalysisResourceModelFiltersSection;
}


export class IdentityControllerGetIdentityModelsResponse {
    constructor(
        public identityModels: IdentityModel[]) {
    }
}

export class IdentityModel {
    constructor(
        public identity: Identity) {
    }
}

export class IdentityReference {
    constructor(
        public displayName: string,
        public id: string,
        public mail: Optional<string>,
        public name: string,
        public typeName: string) {
    }
}
export interface IDirectoryEntity extends IEntity
 {
}

export interface IDirectoryGroup extends IDirectoryPrincipal
 {
}

export interface IDirectoryPrincipal extends IDirectoryEntity
 {
    mail: Optional<string>;
}

export interface IDirectoryUser extends IDirectoryPrincipal, IUser
 {
}

export interface IDirectoryUserProfile
 {
}

export interface IDnsRecordEntity extends IEntity
 {
    dnsNames: Optional<string[]>;
    publicIpAddresses: Optional<string[]>;
}

export interface IDocument
 {
    id: string;
    systemCreationTime: string;
    systemDeleted: boolean;
    typeName: string;
}

export interface IEndpointIntegrationEntityConfiguration
 {
    endpoint: Optional<string>;
    endpointConnectorId: Optional<string>;
    name: string;
}

export interface IEntity extends IDocument
 {
    displayName: string;
    displayReference: string;
    scopeIds: string[];
    tenantId: string;
}

export interface IEntityControllerGetEntityModelPageContainerImageRequest
 {
}

export interface IEntityControllerGetEntityModelPageContainerImageRequestFilters
 {
    containerImageCreationTimeRangeSelection: Optional<TimeRangeSelection>;
    containerImageTagSelection: Optional<PagedItemSelection<string>>;
    digestSelection: Optional<PagedItemSelection<string>>;
    kubernetesWorkloadResourceIdSelection: Optional<PagedItemSelection<string>>;
    repositoryIdSelection: Optional<PagedItemSelection<string>>;
    scanTimeRangeSelection: Optional<TimeRangeSelection>;
    usage: Optional<boolean>;
    virtualMachineIdSelection: Optional<PagedItemSelection<string>>;
    vulnerabilityRawIdSelection: Optional<PagedItemSelection<string>>;
    vulnerabilitySeveritySelection: Optional<ItemSelection<Severity>>;
    workloadClusterResourceIdSelection: Optional<PagedItemSelection<string>>;
}

export interface IEntityGroup extends IEntity
 {
}

export interface IEntityGroupEntity extends IEntity
 {
}

export interface IEntityGroupModel extends IEntityModel
 {
}

export interface IEntityGroupTagEntity extends IEntityGroupEntity, ITagEntity
 {
}

export interface IEntityModel extends IDocument
 {
    attributes: EntityModelAttributes;
    consoleUrl: Optional<string>;
    entity: Entity;
    entityConfiguration: Optional<EntityConfiguration>;
    entityNetwork: Optional<EntityStateNetwork>;
    tenantId: string;
    unknown: boolean;
}

export interface IEntityModelFilters
 {
    attributeValueItems: FilterItems<string>;
    openRelatedEntityRiskTypeIdentifierItems: FilterItems<string>;
    regionIdItems: FilterItems<string>;
    tenantIdItems: FilterItems<string>;
    typeNameItems: FilterItems<string>;
}

export interface IEntityNetworkFilters
 {
    networkAccess: EntityNetworkAccessFiltersSection;
}

export interface IEphemeralKubernetesResource extends IKubernetesResource
 {
}

export interface IExcessivePermissionPrincipalRisk extends IAccessPrincipalRisk
 {
}

export interface IGciDirectoryGroupMemberPrincipal extends IEntity
 {
}

export interface IGciDirectoryPrincipal extends IGciPrincipal
 {
}

export interface IGciFolderConfiguration extends IGciScopeConfiguration
 {
}

export interface IGciGcpResourceModel
 {
    gciParentResourceData: Optional<GciParentResourceData>;
}

export interface IGciGroup extends IGciDirectoryGroupMemberPrincipal, IGciPrincipal
 {
}

export interface IGciIdentity extends IGciDirectoryGroupMemberPrincipal, IGciPrincipal
 {
    mail: string;
    uniqueId: Optional<string>;
}

export interface IGciIdentityProfile
 {
    gcp: GciIdentityProfileGcp;
}

export interface IGciPartialIdentity extends IGciIdentity, IPartialEntity
 {
}

export interface IGciPartialPrincipal extends IGciPrincipal, IPartialEntity
 {
}

export interface IGciPrincipal extends IEntity
 {
}

export interface IGciPrincipalModel extends IEntityModel
 {
}

export interface IGciScopeConfiguration extends IChildScopeConfiguration
 {
}

export interface IGciTenantEntity extends IGciDirectoryPrincipal
 {
}

export interface IGciUser extends IGciIdentity, IUser
 {
}

export interface IGcpArtifactRepository
 {
    registryId: string;
}

export interface IGcpDnsRecordEntity extends IDnsRecordEntity
 {
}

export interface IGcpEncryptedResource
 {
    encryptionExists: boolean;
    kmsEncryptionKeyAssetPaths: string[];
}

export interface IGcpEncryptedResourceModel
 {
    kmsEncryptionKeyReferences: GcpScopeResourceReference[];
}

export interface IGcpExcessivePermissionPrincipalRisk extends IExcessivePermissionPrincipalRisk
 {
}

export interface IGcpFolderConfiguration extends IGcpScopeConfiguration
 {
}

export interface IGcpIamServiceAccount extends IGciIdentity, IServiceIdentity
 {
    serviceDefaultServiceId: Optional<string>;
}

export interface IGcpIamServiceAccountModel
 {
    permissionActionSeverity: Optional<Severity>;
}

export interface IGcpLoggingLogSinkDestinationResourceModel
 {
    loggingLogSinkIds: string[];
}

export interface IGcpNetworkedResource extends INetworkedResource
 {
}

export interface IGcpNetworkEvaluationEntity
 {
}

export interface IGcpPublicAccessPreventionResource
 {
}

export interface IGcpRoleBindingUsageResource extends IResource
 {
}

export interface IGcpScopeConfiguration extends IChildScopeConfiguration
 {
}

export interface IGcpServerlessNetworkedResource
 {
    networkInboundAccessType: Optional<GcpServerlessNetworkedResourceNetworkInboundAccessType>;
}

export interface IGcpServiceAccountOriginatorKubernetesResource extends IGcpServiceAccountOriginatorResource
 {
}

export interface IGcpServiceAccountOriginatorResource extends IResource
 {
}

export interface IGcpServiceAccountOriginatorScopeResource extends IGcpServiceAccountOriginatorResource
 {
    serviceAccountData: GcpServiceAccountOriginatorServiceAccountData;
}

export interface IGcpServiceAccountOriginatorScopeResourceModel extends IEntityModel
 {
    serviceAccountIdReference: Optional<string>;
}

export interface IGcpSpecialGroup extends IGciPrincipal
 {
}

export interface IGcpTagResource
 {
}

export interface IGenericResource
 {
}

export interface IGroupModel extends IPrincipalModel
 {
    memberPrincipalIds: string[];
}

export interface IIdentityModel extends IPrincipalModel
 {
}

export interface IKubernetesAdmissionControllerRiskPolicyConfiguration
 {
}

export interface IKubernetesCluster extends IEntity
 {
    platform: Optional<KubernetesClusterPlatform>;
    platformVersion: Optional<string>;
    version: Optional<string>;
}

export interface IKubernetesClusterModel extends IEntityModel
 {
    apiConnectivityStatus: Optional<SystemKubernetesClusterApiConnectivityStatus>;
    helm: KubernetesClusterModelHelm;
    resourceUpdateTime: Optional<string>;
}

export interface IKubernetesClusterModelFilters<TKubernetesClusterModelFiltersSection> extends IEntityModelFilters
 {
    kubernetesCluster: TKubernetesClusterModelFiltersSection;
}

export interface IKubernetesClusterModelFiltersSection extends ISummarySection
 {
    resourceUpdateTimeRange: FilterTimeRange;
}

export interface IKubernetesClusterRole extends IKubernetesRole
 {
    data: KubernetesClusterRoleData;
}

export interface IKubernetesClusterRoleBinding extends IKubernetesRoleBinding
 {
    data: KubernetesClusterRoleBindingData;
}

export interface IKubernetesConfigMap extends IKubernetesNamespaceResource
 {
    data: KubernetesConfigMapData;
}

export interface IKubernetesCronJob extends IKubernetesWorkloadResource
 {
    data: KubernetesCronJobData;
}

export interface IKubernetesDaemonSet extends IKubernetesWorkloadResource
 {
    data: KubernetesDaemonSetData;
}

export interface IKubernetesDeployment extends IKubernetesWorkloadResource
 {
    data: KubernetesDeploymentData;
}

export interface IKubernetesDeploymentModel extends IKubernetesWorkloadResourceModel
 {
    replicaSetIds: string[];
}

export interface IKubernetesGroup extends IKubernetesPrincipal
 {
    data: KubernetesGroupData;
}

export interface IKubernetesIngress extends IKubernetesNamespaceResource
 {
    data: KubernetesIngressData;
}

export interface IKubernetesIngressClass extends IKubernetesResource
 {
    data: KubernetesIngressClassData;
}

export interface IKubernetesIngressModel extends IKubernetesNamespaceResourceModel
 {
    loadBalancerSearchableReference: Optional<EntitySearchableReference>;
}

export interface IKubernetesJob extends IEphemeralKubernetesResource, IKubernetesWorkloadResource
 {
    data: KubernetesJobData;
}

export interface IKubernetesNamespace extends IKubernetesResource
 {
    data: KubernetesNamespaceData;
}

export interface IKubernetesNamespaceModel extends IKubernetesResourceModel
 {
    resourceIds: string[];
}

export interface IKubernetesNamespaceResource extends IKubernetesResource
 {
    data: KubernetesNamespaceResourceData;
}

export interface IKubernetesNamespaceResourceModel extends IKubernetesResourceModel
 {
}

export interface IKubernetesNamespaceRole extends IKubernetesRole, IKubernetesNamespaceResource
 {
    data: KubernetesNamespaceRoleData;
}

export interface IKubernetesNamespaceRoleBinding extends IKubernetesNamespaceResource, IKubernetesRoleBinding
 {
    data: KubernetesNamespaceRoleBindingData;
}

export interface IKubernetesNetworkPolicy extends IKubernetesNamespaceResource
 {
    data: KubernetesNetworkPolicyData;
}

export interface IKubernetesNode extends IEphemeralKubernetesResource
 {
    data: KubernetesNodeData;
}

export interface IKubernetesNodeModel extends IKubernetesResourceModel
 {
}

export interface IKubernetesPersistentVolume extends IKubernetesResource
 {
    data: KubernetesPersistentVolumeData;
}

export interface IKubernetesPod extends IEphemeralKubernetesResource, IKubernetesNamespaceResource
 {
    data: KubernetesPodData;
}

export interface IKubernetesPodSecurityPolicy extends IKubernetesResource
 {
    data: KubernetesPodSecurityPolicyData;
}

export interface IKubernetesPodTemplate extends IKubernetesNamespaceResource
 {
    data: KubernetesPodTemplateData;
}

export interface IKubernetesPrincipal extends IKubernetesResource
 {
}

export interface IKubernetesPrincipalData extends IKubernetesResourceData
 {
}

export interface IKubernetesPrincipalModel extends IEntityModel
 {
    roleBindingIds: string[];
}

export interface IKubernetesReplicaSet extends IKubernetesWorkloadResource
 {
    data: KubernetesReplicaSetData;
}

export interface IKubernetesResource extends IResource
 {
    clusterId: string;
    data: KubernetesResourceData;
    name: Optional<string>;
}

export interface IKubernetesResourceData
 {
    typeName: string;
}

export interface IKubernetesResourceModel extends IResourceModel
 {
}

export interface IKubernetesRisk extends IRisk
 {
}

export interface IKubernetesRole extends IKubernetesResource
 {
}

export interface IKubernetesRoleBinding extends IKubernetesResource
 {
}

export interface IKubernetesRoleBindingData extends IKubernetesResourceData
 {
    principalReferences: KubernetesResourceReference[];
    roleReference: KubernetesResourceReference;
}

export interface IKubernetesRoleBindingModel extends IKubernetesResourceModel
 {
    principalIdReferences: string[];
    roleIdReference: string;
}

export interface IKubernetesRoleModel extends IKubernetesResourceModel
 {
    principalIdReferences: string[];
    roleBindingIds: string[];
}

export interface IKubernetesService extends IKubernetesNamespaceResource
 {
    data: KubernetesServiceData;
}

export interface IKubernetesServiceAccount extends IKubernetesPrincipal, IKubernetesNamespaceResource
 {
    data: KubernetesServiceAccountData;
}

export interface IKubernetesServiceAccountModel extends IKubernetesPrincipalModel, IKubernetesNamespaceResourceModel
 {
    originatorWorkloadResourceIds: string[];
}

export interface IKubernetesServiceModel extends IKubernetesNamespaceResourceModel
 {
}

export interface IKubernetesStatefulSet extends IKubernetesWorkloadResource
 {
    data: KubernetesStatefulSetData;
}

export interface IKubernetesUser extends IKubernetesPrincipal
 {
    data: KubernetesUserData;
}

export interface IKubernetesWorkloadResource extends IContainerImageResource, IKubernetesNamespaceResource
 {
    data: KubernetesWorkloadResourceData;
}

export interface IKubernetesWorkloadResourceModel extends IContainerImageResourceModel, IKubernetesNamespaceResourceModel
 {
    containerImageRawUrlToDataMap: Dictionary<ContainerImageResourceStateContainerImageData>;
    serviceAccountReference: EntitySearchableIdReference;
}

export interface ILicensingDocument
 {
}

export interface IManagedKubernetesCluster extends IKubernetesCluster
 {
}

export interface IManagedKubernetesClusterModelFilters
 {
    creationTimeRange: FilterTimeRange;
}

export interface INetworkAccessResourceModel extends IResourceModel
 {
}

export interface INetworkedResource extends IResource
 {
}

export interface INetworkInboundExternalResourceRiskModel extends IRiskModel
 {
    destinationNetworkScopes: DestinationNetworkScope[];
    sourceSubnets: string[];
}


export class InfrastructureConfigurationSection extends ConfigurationSection {
    constructor(
        public aws: InfrastructureConfigurationSectionAws,
        public azure: InfrastructureConfigurationSectionAzure,
        public currentSegment: InfrastructureConfigurationSectionSegment,
        public gcp: InfrastructureConfigurationSectionGcp,
        public globalOutpostsEnabled: boolean,
        public natGatewayIpAddresses: string[],
        public oci: InfrastructureConfigurationSectionOci) {
        super();
    }
}

export class InfrastructureConfigurationSectionAws {
    constructor(
        public regionSystemNameToDataMap: Dictionary<InfrastructureConfigurationSectionAwsOutpostData>) {
    }
}

export class InfrastructureConfigurationSectionAwsOutpostData {
    constructor(
        public availabilityZoneNames: string[],
        public customerRdsDbSubnetGroupName: string,
        public customerSecurityGroupRawId: string) {
    }
}

export class InfrastructureConfigurationSectionAzure {
    constructor(
        public regionSystemNameToDataMap: Dictionary<InfrastructureConfigurationSectionAzureRegionData>) {
    }
}

export class InfrastructureConfigurationSectionAzureRegionData {
    constructor(
        public availabilityZoneNames: string[],
        public outpost: InfrastructureConfigurationSectionAzureRegionDataOutpost,
        public singleAvailabilityZone: boolean) {
    }
}

export class InfrastructureConfigurationSectionAzureRegionDataOutpost {
    constructor(
        public resourceGroupName: string,
        public storageAccounts: InfrastructureConfigurationSectionAzureRegionDataOutpostStorageAccount[],
        public virtualNetwork: InfrastructureConfigurationSectionAzureRegionDataOutpostVirtualNetwork) {
    }
}

export class InfrastructureConfigurationSectionAzureRegionDataOutpostStorageAccount {
    constructor(
        public blobContainerName: string,
        public name: string) {
    }
}

export class InfrastructureConfigurationSectionAzureRegionDataOutpostVirtualNetwork {
    constructor(
        public name: string,
        public privateSubnetNames: string[],
        public resourceGroupName: string) {
    }
}

export class InfrastructureConfigurationSectionGcp {
    constructor(
        public cloudSqlRestoreBackupRoleName: string,
        public regionSystemNameToDataMap: Dictionary<InfrastructureConfigurationSectionGcpOutpostData>) {
    }
}

export class InfrastructureConfigurationSectionGcpOutpostData {
    constructor(
        public availabilityZoneNames: string[],
        public vpcPrivateSubnetRawName: string) {
    }
}

export class InfrastructureConfigurationSectionOci {
    constructor(
        public regionSystemNameToDataMap: Dictionary<InfrastructureConfigurationSectionOciOutpostData>) {
    }
}

export class InfrastructureConfigurationSectionOciOutpostData {
    constructor(
        public availabilityZoneNames: string[]) {
    }
}

export class InfrastructureConfigurationSectionSegment {
    constructor(
        public eksClusterIndex: number,
        public entityElasticsearchIndex: number,
        public eventElasticsearchIndex: number,
        public mongoDbClusterIndex: number,
        public singleStoreWorkspaceGroupIndex: number) {
    }
}

export class IntRange {
    constructor(
        public end: number,
        public start: number) {
    }
}
export interface INullableConfigurationSection
 {
}

export interface IObjectStore extends IDataAnalysisResource
 {
}

export interface IOciEncryptedResource
 {
    encryptionExists: boolean;
    encryptionVaultKeyOcid: Optional<string>;
}

export interface IOciEncryptedResourceModel
 {
    encryptionVaultKeyIdReference: Optional<string>;
}

export interface IOciFolderConfiguration extends IOciScopeConfiguration
 {
}

export interface IOciNetworkedResource extends INetworkedResource
 {
}

export interface IOciScopeConfiguration extends IChildScopeConfiguration
 {
}

export interface IOktaFolderConfiguration extends IOktaScopeConfiguration
 {
}

export interface IOktaScopeConfiguration extends IChildScopeConfiguration
 {
}

export interface IOneLoginFolderConfiguration extends IOneLoginScopeConfiguration
 {
}

export interface IOneLoginScopeConfiguration extends IChildScopeConfiguration
 {
}

export interface IOpenShiftBuildConfig extends IKubernetesNamespaceResource
 {
    data: OpenShiftBuildConfigData;
}

export interface IOpenShiftCatalogSource extends IKubernetesNamespaceResource
 {
    data: OpenShiftCatalogSourceData;
}

export interface IOpenShiftDeploymentConfig extends IKubernetesWorkloadResource
 {
    data: OpenShiftDeploymentConfigData;
}

export interface IOpenShiftImageStream extends IKubernetesNamespaceResource
 {
    data: OpenShiftImageStreamData;
}

export interface IOpenShiftProject extends IKubernetesResource
 {
    data: OpenShiftProjectData;
}

export interface IOpenShiftRoute extends IKubernetesNamespaceResource
 {
    data: OpenShiftRouteData;
}

export interface IOpenShiftSecurityContextConstraint extends IKubernetesResource
 {
    data: OpenShiftSecurityContextConstraintData;
}

export interface IOpenShiftTemplate extends IKubernetesNamespaceResource
 {
    data: OpenShiftTemplateData;
}

export interface IOrganizationFolderConfiguration
 {
    organization: Optional<FolderConfigurationOrganization>;
}

export interface IOrganizationTenantConfiguration
 {
    organizationId: Optional<string>;
}


export class IpAddressRange {
    constructor(
        public end: string,
        public start: string) {
    }
}
export interface IPartialEntity extends IEntity
 {
}

export interface IPermissionEligibilityAuditEventPermissionAssignmentEligibilityData
 {
}

export interface IPermissionManagementGroup extends IPermissionManagementPrincipal
 {
}

export interface IPermissionManagementGroupModel extends IPermissionManagementPrincipalModel
 {
}

export interface IPermissionManagementPrincipal extends IEntity
 {
    mail: Optional<string>;
}

export interface IPermissionManagementPrincipalModel extends IEntityModel
 {
}

export interface IPermissionManagementUser extends IPermissionManagementPrincipal
 {
}

export interface IPermissionManagementUserModel extends IPermissionManagementPrincipalModel
 {
}

export interface IPingIdentityFolderConfiguration extends IPingIdentityScopeConfiguration
 {
}

export interface IPingIdentityScopeConfiguration extends IChildScopeConfiguration
 {
}

export interface IPrincipalModel extends IEntityModel
 {
    access: Optional<PrincipalModelAccess>;
    inactive: boolean;
}

export interface IPrincipalModelAccessScopes<T>
 {
}

export interface IProjectScopeConfiguration extends IChildScopeConfiguration
 {
}

export interface IPublicRisk extends IRisk
 {
}

export interface IRelatedTenantsEntityModel extends IEntityModel
 {
}

export interface IResource extends ITagEntity
 {
}

export interface IResourceModel extends ITagEntityModel
 {
    creationTime: Optional<string>;
    creatorIdentityIdReference: Optional<string>;
    creatorOriginatorEntityIdReference: Optional<string>;
}

export interface IRisk extends ITenantDocument
 {
    relatedEntityIds: string[];
    resolutionReason: Optional<RiskResolutionReason>;
    riskedEntityIds: string[];
    severity: Severity;
    status: RiskStatus;
    subStatus: RiskSubStatus;
}

export interface IRiskMessageAuditEvent
 {
    message: Optional<string>;
}

export interface IRiskModel extends IDocument
 {
    files: RiskFile[];
    risk: Risk;
    tenantId: string;
}

export interface IScopeConfiguration extends IDocument
 {
    name: string;
    scopeSections: ScopeConfigurationSections;
}

export interface IScopeConfigurationSectionOutpost
 {
}

export interface ISecretExistsRiskModel extends IDocument
 {
    secretCount: number;
}

export interface IService extends IEntity
 {
}

export interface IServiceIdentity extends IEntity
 {
}

export interface IServiceIdentityModel extends IIdentityModel
 {
}

export interface ISummarySection
 {
}

export interface ITagEntity extends IEntity
 {
    tagExists: boolean;
    tags: ResourceTag[];
}

export interface ITagEntityModel extends IEntityModel
 {
}


export class ItemPage<TItem> {
    constructor(
        public count: Optional<number>,
        public hasMore: boolean,
        public items: TItem[],
        public nextPageSearchCursor: Optional<ElasticsearchIndexSearchCursor>) {
    }
}

export class ItemSelectionBase<TItem> {
    constructor(
        public emptyValue: boolean,
        public items: TItem[]) {
    }
}
export interface ITenantDocument extends IDocument
 {
    tenantId: string;
}

export interface ITenantEntity extends IEntity
 {
}

export interface ITenantEntityModel extends IEntityModel
 {
}

export interface ITenantOrganizationConfiguration extends IDocument
 {
    folderEnabled: boolean;
    memberSelection: Optional<OrganizationMemberSelection>;
}

export interface ITunnelSessionScopeSystemEntityConfiguration
 {
}

export interface ITypeNameObject
 {
}

export interface IUdmObjectModel extends IDocument
 {
}

export interface IUnmanagedKubernetesCluster extends IKubernetesCluster
 {
    data: KubernetesClusterData;
}

export interface IUnmanagedKubernetesClusterModel extends IKubernetesClusterModel
 {
}

export interface IUnmanagedKubernetesClusterModelFilters
 {
}

export interface IUnmanagedKubernetesClusterModelFiltersSection extends IKubernetesClusterModelFiltersSection
 {
    creationTimeRange: FilterTimeRange;
}

export interface IUser extends IEntity
 {
}

export interface IUserModel extends IIdentityModel
 {
}

export interface IVendorServiceIdentity extends IServiceIdentity
 {
    vendor: boolean;
    vendors: Vendor[];
}

export interface IVendorServiceIdentityModel extends IServiceIdentityModel
 {
}

export interface IVirtualMachine extends INetworkedResource, IWorkloadResource
 {
}

export interface IVirtualMachineDiskResourceFilters
 {
    scanTimeRange: FilterTimeRange;
}

export interface IVirtualMachineDiskResourceModel extends IWorkloadResourceModel
 {
    operatingSystemType: Optional<OperatingSystemType>;
    scanTime: Optional<string>;
    vulnerabilities: Optional<VulnerabilityData[]>;
    vulnerabilitySeveritiesSortValue: string;
    workloadAnalysisError: Optional<WorkloadAnalysisError>;
    workloadAnalysisPriorityScanRequested: boolean;
    workloadAnalysisStatus: WorkloadAnalysisStatus;
    workloadAnalysisStatusSortValue: number;
}

export interface IVirtualMachineImage extends IResource, IWorkloadResource
 {
}

export interface IVirtualMachineImageModel extends IResourceModel, IVirtualMachineDiskResourceModel
 {
    virtualMachineImageAccessLevel: Optional<VirtualMachineImageAccessLevel>;
}

export interface IVirtualMachineImageRiskModel
 {
}

export interface IVirtualMachineModel extends INetworkAccessResourceModel, IVirtualMachineDiskResourceModel
 {
    diskEncryptionVaultIdReference: Optional<string>;
    identityPermissionActionSeverity: Optional<Severity>;
    kubernetesClusterSensor: boolean;
    stopped: boolean;
    stopTime: Optional<string>;
}

export interface IWorkloadResource extends IEntity
 {
}

export interface IWorkloadResourceMaliciousFileRisk
 {
    fileContentSha256Strings: string[];
}

export interface IWorkloadResourceModel extends IEntityModel
 {
    operatingSystemReleaseNotesUrl: Optional<string>;
    workloadAnalysisOperatingSystem: Optional<WorkloadResourceScanOperatingSystem>;
    workloadAnalysisOperatingSystemEndOfLifeDate: Optional<string>;
    workloadAnalysisOperatingSystemExtendedSupport: Optional<boolean>;
}

export interface IWorkloadResourceOperatingSystemRisk extends IWorkloadResourceVulnerabilityRelatedRisk
 {
    operatingSystemDisplayName: string;
    operatingSystemType: OperatingSystemType;
    packageScope: WorkloadResourceOperatingSystemRiskPackageScope;
}

export interface IWorkloadResourceOperatingSystemUnpatchedRisk extends IWorkloadResourceOperatingSystemRisk
 {
    criticalExploitableVulnerabilityPackageNames: string[];
    criticalNonExploitableVulnerabilityPackageNames: string[];
    highExploitableVulnerabilityPackageNames: string[];
    highNonExploitableVulnerabilityPackageNames: string[];
    vulnerabilityPackageNames: string[];
}

export interface IWorkloadResourceOperatingSystemUnpatchedRiskModel extends IRiskModel
 {
}

export interface IWorkloadResourceVulnerabilityRelatedRisk extends IRisk
 {
    criticalVulnerabilities: WorkloadResourceRiskVulnerability[];
    highVulnerabilities: WorkloadResourceRiskVulnerability[];
}

export interface IWorkloadResourceVulnerabilityRisk extends IWorkloadResourceVulnerabilityRelatedRisk
 {
    filePathToPackageNamesMap: Dictionary<string[]>;
    filePathToVulnerabilityRawIdsMap: Dictionary<string[]>;
    severeVulnerabilityMinSeverity: Severity;
    vulnerabilityPackageNameToResolutionVersionsMap: Dictionary<string[]>;
}

export interface IWorkloadResourceVulnerabilityRiskModel extends IRiskModel
 {
}


export class JiraAutoCompleteSuggestion {
    constructor(
        public displayName: string,
        public value: string) {
    }
}

export class JiraEpic {
    constructor(
        public name: string,
        public rawId: string) {
    }
}

export class JiraInstanceConfigurationConnectionInfo {
    constructor(
        public authenticationType: JiraInstanceConfigurationConnectionInfoAuthenticationType,
        public url: string,
        public userName: string) {
    }
}

export class JiraInstanceConfigurationProject {
    constructor(
        public issueTypeNameToRiskStatusToJiraStatusMap: Optional<Dictionary<Dictionary<JiraStatus>>>,
        public productType: JiraProductType,
        public rawId: string,
        public riskSeverityToJiraPriorityMap: Optional<Dictionary<JiraPriority>>,
        public type: JiraProjectType) {
    }
}

export class JiraInstanceStateProject {
    constructor(
        public issue: Optional<JiraInstanceStateProjectIssue>,
        public project: JiraProject) {
    }
}

export class JiraIssueCreationData {
    constructor(
        public additionalMandatoryFieldNameToValuesMap: Dictionary<string[]>,
        public description: Optional<string>,
        public epic: Optional<JiraEpic>,
        public fileNames: Optional<string[]>,
        public labels: Optional<string[]>,
        public projectRawId: string,
        public sprint: Optional<JiraSprint>,
        public summary: Optional<string>,
        public typeName: string,
        public user: Optional<JiraUser>) {
    }
}

export class JiraIssueInfo {
    constructor(
        public additionalMandatoryFieldNameToValuesMap: Dictionary<string[]>,
        public description: string,
        public epic: Optional<JiraEpic>,
        public fileNames: Optional<string[]>,
        public issueTypeName: string,
        public labels: string[],
        public projectRawId: string,
        public sprint: Optional<JiraSprint>,
        public statusRawId: string,
        public summary: string,
        public updateTime: string,
        public user: Optional<JiraUser>) {
    }
}

export class JiraPriority {
    constructor(
        public name: string,
        public rawId: string) {
    }
}

export class JiraProject {
    constructor(
        public epicLinkFieldId: Optional<string>,
        public issueTypeNames: string[],
        public issueTypeNameToDataMap: Dictionary<JiraProjectIssueTypeData>,
        public name: string,
        public priorities: JiraPriority[],
        public sprintFieldId: Optional<string>) {
    }
}

export class JiraProjectIssueTypeData {
    constructor(
        public fields: JiraField[],
        public statuses: JiraStatus[]) {
    }
}

export class JiraSprint {
    constructor(
        public name: string,
        public rawId: string) {
    }
}

export class JiraStatus {
    constructor(
        public name: string,
        public rawId: string) {
    }
}

export class JiraUser {
    constructor(
        public displayName: string,
        public mail: Optional<string>,
        public name: string,
        public rawId: string) {
    }
}

export class JobManagerConfigurationSection extends ConfigurationSection {
    constructor(
        public cpuDefaultCoreCount: Optional<number>,
        public cpuMinCoreCount: Optional<number>,
        public cpuRequestIncreaseFactor: Optional<number>,
        public memoryLimitIncreaseFactor: Optional<number>,
        public outOfMemoryIncreaseFactor: Optional<number>,
        public podAdditionalEnvironmentVariableNameToValueMap: Dictionary<string>,
        public serviceTypeNameToConfigurationMap: Dictionary<JobManagerConfigurationSectionServiceConfiguration>) {
        super();
    }
}

export class JobManagerConfigurationSectionServiceConfiguration {
    constructor(
        public memoryLimitIncreaseFactor: Optional<number>,
        public outOfMemoryIncreaseFactor: Optional<number>) {
    }
}

export class KubernetesClusterAdmissionControllerEventRiskPolicyData {
    constructor(
        public configurationTypeName: string,
        public id: string,
        public result: KubernetesAdmissionControllerRiskPolicyResult,
        public updateTime: string,
        public violationPropertyPaths: string[]) {
    }
}

export class KubernetesClusterKubeletRiskNodeData {
    constructor(
        public anonymousAuthentication: boolean,
        public authorizationModeAlwaysAllow: boolean,
        public debugApi: boolean,
        public inboundExternalPort: Optional<number>,
        public inboundExternalReadOnlyPort: Optional<number>,
        public nodeOrVirtualMachineId: string) {
    }
}

export class KubernetesClusterModelData {
    constructor(
        public apiConnectivityStatus: Optional<SystemKubernetesClusterApiConnectivityStatus>,
        public resourceUpdateTime: Optional<string>) {
    }
}

export class KubernetesClusterModelHelm {
    constructor(
        public admissionController: KubernetesClusterModelHelmAdmissionController,
        public applicationVersion: Optional<string>,
        public applicationVersionValue: Optional<string>,
        public connector: KubernetesClusterModelHelmConnector,
        public legacyVersion: boolean,
        public sensor: KubernetesClusterModelHelmSensor) {
    }
}

export class KubernetesClusterModelHelmComponent {
    constructor(
        public status: SystemKubernetesClusterModelHelmComponentStatus) {
    }
}

export class KubernetesClusterModelHelmConnector extends KubernetesClusterModelHelmComponent {
    constructor(
        status: SystemKubernetesClusterModelHelmComponentStatus,
        public identity: boolean,
        public network: boolean) {
        super(status);
    }
}

export class KubernetesClusterModelHelmSensor extends KubernetesClusterModelHelmComponent {
    constructor(
        status: SystemKubernetesClusterModelHelmComponentStatus) {
        super(status);
    }
}

export class KubernetesClusterNodeUnrestrictedAccessFileRiskModelUnrestrictedAccessItem {
    constructor(
        public filePaths: string[],
        public fileType: KubernetesNodeFileDataType,
        public unrestrictedAccessType: KubernetesClusterNodeUnrestrictedAccessFileRiskNodeDataFileUnrestrictedAccessType,
        public virtualMachineIds: string[]) {
    }
}

export class KubernetesContainer {
    constructor(
        public environmentVariables: KubernetesEnvironmentVariable[],
        public environmentVariablesResourceReferences: KubernetesResourceReference[],
        public imageRawUrl: string,
        public name: string,
        public ports: KubernetesContainerPort[],
        public securityContext: KubernetesContainerSecurityContext) {
    }
}

export class KubernetesContainerPort {
    constructor(
        public hostPort: Optional<number>,
        public port: number,
        public protocolType: KubernetesPortProtocolType) {
    }
}

export class KubernetesContainerSecurityContext {
    constructor(
        public capabilities: KubernetesContainerSecurityContextCapabilities,
        public privileged: boolean,
        public privilegeEscalation: boolean,
        public rootUserDisabled: boolean) {
    }
}

export class KubernetesContainerSecurityContextCapabilities {
    constructor(
        public addedCapabilities: string[],
        public droppedCapabilities: string[]) {
    }
}

export class KubernetesControllerDeleteSystemKubernetesClusterRequest {
    constructor(
        public id: string) {
    }
}

export class KubernetesControllerGetKubernetesClusterAdmissionControllerEventFilterItemPageRequest {
    constructor(
        public limit: number,
        public property: KubernetesControllerClusterAdmissionControllerEventProperty,
        public searchText: Optional<string>,
        public skip: number) {
    }
}

export class KubernetesControllerGetKubernetesClusterAdmissionControllerEventFilterItemPageResponseBase {
    constructor() {
    }
}

export class KubernetesControllerGetKubernetesClusterAdmissionControllerEventFiltersRequest {
    constructor() {
    }
}

export class KubernetesControllerGetKubernetesClusterAdmissionControllerEventFiltersResponse {
    constructor(
        public eventFilters: KubernetesClusterAdmissionControllerEventFilters) {
    }
}

export class KubernetesControllerGetKubernetesClusterAdmissionControllerEventModelPageRequest {
    constructor(
        public filters: KubernetesControllerGetKubernetesClusterAdmissionControllerEventModelPageRequestFilters,
        public limit: number,
        public skip: number) {
    }
}

export class KubernetesControllerGetKubernetesClusterAdmissionControllerEventModelPageRequestFilters {
    constructor(
        public clusterIdSelection: Optional<PagedItemSelection<string>>,
        public identityIdSelection: Optional<PagedItemSelection<string>>,
        public ownerResourceIdSelection: Optional<PagedItemSelection<string>>,
        public resourceIdSelection: Optional<PagedItemSelection<string>>,
        public resourceOperationSelection: Optional<ItemSelection<KubernetesAdmissionControllerResourceOperation>>,
        public resourceTypeNameSelection: Optional<ItemSelection<string>>,
        public riskPolicyConfigurationTypeNameSelection: Optional<ItemSelection<string>>,
        public riskPolicyResultSelection: Optional<ItemSelection<KubernetesAdmissionControllerRiskPolicyResult>>,
        public tenantIdSelection: Optional<ItemSelection<string>>,
        public timeRangeSelection: Optional<TimeRangeSelection>,
        public violationRiskPolicyConfigurationTypeNameSelection: Optional<ItemSelection<string>>) {
    }
}

export class KubernetesControllerGetKubernetesClusterAdmissionControllerEventModelPageResponse {
    constructor(
        public admissionControllerEventModelPage: ItemPage<KubernetesClusterAdmissionControllerEventModel>) {
    }
}

export class KubernetesControllerGetKubernetesClusterAdmissionControllerEventModelRequest {
    constructor(
        public ids: string[]) {
    }
}

export class KubernetesControllerGetKubernetesClusterAdmissionControllerEventModelResponse {
    constructor(
        public eventModels: KubernetesClusterAdmissionControllerEventModel[]) {
    }
}

export class KubernetesControllerGetKubernetesClusterOnboardingInfoRequest {
    constructor(
        public scopeId: string) {
    }
}

export class KubernetesControllerGetKubernetesClusterOnboardingInfoResponse {
    constructor(
        public clusterConnectorIdToApiKeyTokenMap: Dictionary<string>,
        public containerImageRegistryToken: Optional<KubernetesControllerGetKubernetesClusterOnboardingInfoResponseContainerImageRegistryToken>) {
    }
}

export class KubernetesControllerGetKubernetesClusterOnboardingInfoResponseContainerImageRegistryToken {
    constructor(
        public userName: string,
        public userPassword: string) {
    }
}

export class KubernetesControllerGetSystemKubernetesClusterFilterItemPageRequest {
    constructor(
        public limit: number,
        public property: KubernetesControllerSystemKubernetesClusterModelProperty,
        public scopeId: string,
        public searchText: Optional<string>,
        public skip: number) {
    }
}

export class KubernetesControllerGetSystemKubernetesClusterFilterItemPageResponseBase {
    constructor() {
    }
}

export class KubernetesControllerGetSystemKubernetesClusterFiltersRequest {
    constructor(
        public scopeId: string) {
    }
}

export class KubernetesControllerGetSystemKubernetesClusterFiltersResponse {
    constructor(
        public systemKubernetesClusterFilters: SystemKubernetesClusterFilters) {
    }
}

export class KubernetesControllerGetSystemKubernetesClusterModelPageRequest {
    constructor(
        public filters: KubernetesControllerGetSystemKubernetesClusterModelPageRequestFilters,
        public limit: number,
        public scopeId: string,
        public skip: number,
        public sort: KubernetesControllerGetSystemKubernetesClusterModelPageRequestSort) {
    }
}

export class KubernetesControllerGetSystemKubernetesClusterModelPageRequestFilters {
    constructor(
        public admissionControllerStatusSelection: Optional<ItemSelection<SystemKubernetesClusterModelHelmComponentStatus>>,
        public apiConnectivityStatusSelection: Optional<ItemSelection<SystemKubernetesClusterApiConnectivityStatus>>,
        public applicationVersionValueSelection: Optional<ItemSelection<string>>,
        public clusterCreationTimeRangeSelection: Optional<TimeRangeSelection>,
        public clusterIdSelection: Optional<PagedItemSelection<string>>,
        public platformSelection: Optional<ItemSelection<KubernetesClusterPlatform>>,
        public resourceUpdateTimeRangeSelection: Optional<TimeRangeSelection>,
        public sensorStatusSelection: Optional<ItemSelection<SystemKubernetesClusterModelHelmComponentStatus>>,
        public tenantIdSelection: Optional<ItemSelection<string>>) {
    }
}

export class KubernetesControllerGetSystemKubernetesClusterModelPageRequestSort {
    constructor(
        public direction: SortDirection,
        public property: KubernetesControllerSystemKubernetesClusterModelProperty) {
    }
}

export class KubernetesControllerGetSystemKubernetesClusterModelPageResponse {
    constructor(
        public systemKubernetesClusterModelPage: ItemPage<SystemKubernetesClusterModel>) {
    }
}

export class KubernetesEnvironmentVariable {
    constructor(
        public name: string,
        public value: Optional<string>,
        public valueReference: Optional<KubernetesEnvironmentVariableValueReference>) {
    }
}

export class KubernetesIngressDataRule {
    constructor(
        public certificateSecretName: Optional<string>,
        public hostNamePattern: Optional<string>,
        public paths: KubernetesIngressDataRulePath[]) {
    }
}

export class KubernetesLabelSelector {
    constructor(
        public expressions: KubernetesLabelSelectorExpression[],
        public keyToValueMap: Dictionary<string>) {
    }
}

export class KubernetesLabelSelectorExpression {
    constructor(
        public key: string,
        public operator: KubernetesLabelSelectorOperator,
        public values: string[]) {
    }
}

export class KubernetesNodeKubelet {
    constructor(
        public anonymousAuthentication: Optional<boolean>,
        public authorizationMode: Optional<KubernetesNodeKubeletAuthorizationMode>,
        public certificateRotation: Optional<boolean>,
        public clientCertificateAuthorityFilePath: Optional<string>,
        public clientCertificateRotation: Optional<boolean>,
        public debugApi: Optional<boolean>,
        public eventsPerSecondMaxCount: Optional<number>,
        public featureNameToEnabledMap: Optional<Dictionary<boolean>>,
        public hasTlsCipherSuiteNames: boolean,
        public manageIpTables: Optional<boolean>,
        public port: Optional<number>,
        public readOnlyPort: Optional<number>,
        public server: Optional<boolean>,
        public streamingConnectionIdleTimeout: Optional<string>,
        public tlsCertificateFilePath: Optional<string>,
        public tlsCipherSuiteNames: Optional<string[]>,
        public tlsPrivateKeyFilePath: Optional<string>) {
    }
}

export class KubernetesPodSpec {
    constructor(
        public containers: KubernetesContainer[],
        public hostNamespaceData: KubernetesPodSpecHostNamespaceData,
        public nodeLabelKeyToValueMap: Dictionary<string>,
        public nodeName: string,
        public securityContext: KubernetesPodSpecSecurityContext,
        public serviceAccountReference: KubernetesResourceReference,
        public tolerations: KubernetesToleration[]) {
    }
}

export class KubernetesPodSpecHostNamespaceData {
    constructor(
        public interProcessCommunication: boolean,
        public network: boolean,
        public processId: boolean) {
    }
}

export class KubernetesPodSpecSecurityContext {
    constructor(
        public rootUserDisabled: boolean) {
    }
}

export class KubernetesPodTemplateSpec {
    constructor(
        public podSpec: KubernetesPodSpec,
        public tags: ResourceTag[]) {
    }
}

export class KubernetesResourceReference {
    constructor(
        public idReference: string,
        public namespaceName: Optional<string>,
        public rawTypeName: string) {
    }
}

export class KubernetesServiceDataPort {
    constructor(
        public name: Optional<string>,
        public nodePort: Optional<number>,
        public port: number,
        public protocolType: KubernetesPortProtocolType,
        public targetPortName: Optional<string>) {
    }
}

export class KubernetesTaint {
    constructor(
        public effect: KubernetesTaintEffect,
        public key: string,
        public value: string) {
    }
}

export class KubernetesToleration {
    constructor(
        public effect: Optional<KubernetesTaintEffect>,
        public key: string,
        public operator: KubernetesTolerationOperator,
        public value: string) {
    }
}

export class MailProviderConfigurationSection extends ConfigurationSection {
    constructor(
        public domainName: string,
        public regionType: AwsRegionType) {
        super();
    }
}

export class MongoDbAtlasProjectConfigurationSection extends ConfigurationSection implements INullableConfigurationSection {
    constructor(
        public apiKeyEncryptedPrivateKey: EncryptedData,
        public apiKeyPublicKey: string,
        public id: string) {
        super();
    }
}

export class MongoDbConfigurationSection extends ConfigurationSection {
    constructor(
        public atlas: boolean,
        public clusterNameToHostNameMap: Dictionary<string>) {
        super();
    }
}

export class NetworkGraph extends Graph {
    constructor(
        edges: GraphEdge[],
        relatedEntityIds: string[],
        public destinationGroups: NetworkGraphDestinationGroup[],
        public gateways: NetworkGraphGateway[],
        public securityPerimeters: NetworkGraphSecurityPerimeter[],
        public sourceGroups: NetworkGraphSourceGroup[]) {
        super(
            edges,
            relatedEntityIds);
    }
}

export class NetworkGraphDestinationGroup extends GraphNode {
    constructor(
        id: string,
        public entityIds: string[],
        public entityTypeName: string,
        public parentEntityId: Optional<string>,
        public scopes: NetworkGraphDestinationGroupScope[]) {
        super(id);
    }
}

export class NetworkGraphDestinationGroupScope extends GraphNode {
    constructor(
        id: string,
        public destinationPortRange: IntRange,
        public filterId: string,
        public protocolRange: IntRange) {
        super(id);
    }
}

export class NetworkGraphGateway extends GraphNode {
    constructor(
        id: string,
        public gatewayResourceId: Optional<string>,
        public internetGatewayId: Optional<string>) {
        super(id);
    }
}

export class NetworkGraphSecurityPerimeter extends GraphNode {
    constructor(
        id: string,
        public securityPerimeterResourceId: string,
        public type: NetworkGraphSecurityPerimeterType) {
        super(id);
    }
}

export class NetworkGraphSourceGroup extends GraphNode {
    constructor(
        id: string,
        public entityIds: Optional<string[]>,
        public entityTypeName: Optional<string>,
        public serviceSubnets: Optional<boolean>,
        public specialSource: Optional<NetworkGraphSpecialSource>,
        public subnets: Optional<string[]>) {
        super(id);
    }
}

export class NetworkScope<T> {
    constructor(
        public destinationPortRanges: IntRange[],
        public destinationSubnets: string[],
        public protocolRanges: IntRange[],
        public sourceSubnets: string[]) {
    }
}

export class ObjectTypeMetadata {
    constructor() {
    }
}

export class ObjectTypeMetadataModel {
    constructor(
        public category: ObjectTypeCategory,
        public tenantTypes: TenantType[],
        public typeName: string,
        public udmProperties: UdmObjectProperty[],
        public udmType: UdmObjectType) {
    }
}

export class OciApplicationAuthenticationProviderConfigurationSection extends ConfigurationSection implements INullableConfigurationSection {
    constructor(
        public partitionTypeToDataMap: Dictionary<OciApplicationAuthenticationProviderConfigurationSectionPartitionData>) {
        super();
    }
}

export class OciApplicationAuthenticationProviderConfigurationSectionPartitionData {
    constructor(
        public organizationRawId: string,
        public regionSystemName: string,
        public tenantRawId: string,
        public user: OciApplicationAuthenticationProviderConfigurationSectionPartitionDataUser) {
    }
}

export class OciApplicationAuthenticationProviderConfigurationSectionPartitionDataUser {
    constructor(
        public encryptedPrivateKeyPem: EncryptedData,
        public publicKeyFingerprint: string,
        public rawId: string) {
    }
}

export class OciBlockStorageBlockVolumeSourceResource {
    constructor(
        public ocid: string,
        public type: OciBlockStorageBlockVolumeSourceResourceType) {
    }
}

export class OciBlockStorageBootVolumeSourceResource {
    constructor(
        public ocid: string,
        public type: OciBlockStorageBootVolumeSourceResourceType) {
    }
}

export class OciComputeInstanceBootSource {
    constructor(
        public bootVolumeOcid: Optional<string>,
        public imageKmsEncryptionVaultKeyOcid: Optional<string>,
        public imageOcid: Optional<string>,
        public type: OciComputeInstanceBootSourceType) {
    }
}

export class OciComputeInstanceConfigurationBootSource {
    constructor(
        public bootVolumeOcid: Optional<string>,
        public imageKmsEncryptionVaultKeyOcid: Optional<string>,
        public imageOcid: Optional<string>,
        public type: OciComputeInstanceConfigurationBootSourceType) {
    }
}

export class OciComputeInstanceConfigurationSecurity {
    constructor(
        public measuredBootEnabled: boolean,
        public memoryEncryptionEnabled: boolean,
        public secureBootEnabled: boolean,
        public tpmEnabled: boolean) {
    }
}

export class OciComputeInstanceConfigurationVirtualNetworkInterfaceCardParameters {
    constructor(
        public assignPublicIp: Optional<boolean>,
        public name: string,
        public privateIpAddress: Optional<string>,
        public securityGroupOcids: string[],
        public subnetOcid: Optional<string>,
        public tags: ResourceTag[]) {
    }
}

export class OciComputeInstancePoolPlacementConfiguration {
    constructor(
        public availabilityDomain: string,
        public faultDomains: string[],
        public primarySubnetOcid: string,
        public secondarySubnetOcids: string[]) {
    }
}

export class OciComputeInstanceSecurity {
    constructor(
        public measuredBootEnabled: boolean,
        public memoryEncryptionEnabled: boolean,
        public secureBootEnabled: boolean,
        public tpmEnabled: boolean) {
    }
}

export class OciContainerEngineClusterNodePoolNode {
    constructor(
        public imageOcid: string,
        public kubernetesTags: ResourceTag[],
        public kubernetesVersion: string,
        public metadata: Dictionary<string>,
        public metadataServiceVersion1Enabled: boolean,
        public shape: string,
        public tags: ResourceTag[],
        public volumeEncryptionInTransitEnabled: boolean) {
    }
}

export class OciFssMountTargetExportOption {
    constructor(
        public access: OciFssMountTargetExportOptionAccess,
        public anonymousAccess: boolean,
        public authTypes: OciFssMountTargetExportOptionAuthType[],
        public identitySquash: OciFssMountTargetExportOptionIdentitySquash,
        public requirePrivilegedSourcePort: boolean,
        public sourceSubnet: string,
        public squashGid: number,
        public squashUid: number) {
    }
}

export class OciIamDomainPasswordPolicy {
    constructor(
        public expiresAfter: Optional<number>,
        public maxLength: Optional<number>,
        public minLength: number,
        public name: string,
        public numbersRequired: boolean,
        public numPasswordsInHistory: number,
        public ocid: Optional<string>,
        public specialsRequired: boolean) {
    }
}

export class OciKmsVaultKeyVersion {
    constructor(
        public creationTime: Optional<string>,
        public ocid: string,
        public source: OciKmsVaultKeyVersionSource,
        public status: OciKmsVaultKeyVersionStatus) {
    }
}

export class OciKmsVaultSecretVersion {
    constructor(
        public autoGeneratedContent: boolean,
        public creationTime: Optional<string>,
        public statuses: OciKmsVaultSecretVersionStatus[],
        public versionNumber: number) {
    }
}

export class OciLoadBalancingLoadBalancerBackend {
    constructor(
        public ipAddress: string,
        public port: number) {
    }
}

export class OciLoadBalancingLoadBalancerBackendSet {
    constructor(
        public backends: OciLoadBalancingLoadBalancerBackend[],
        public name: string,
        public sslConfiguration: Optional<OciLoadBalancingLoadBalancerSslConfiguration>) {
    }
}

export class OciLoadBalancingLoadBalancerListener {
    constructor(
        public backendSetName: string,
        public name: string,
        public port: number,
        public protocol: string,
        public sslConfiguration: Optional<OciLoadBalancingLoadBalancerSslConfiguration>) {
    }
}

export class OciLoadBalancingLoadBalancerSslConfiguration {
    constructor(
        public cipherSuiteName: string,
        public sslProtocols: OciLoadBalancingLoadBalancerSslProtocol[]) {
    }
}

export class OciLoggingLog {
    constructor(
        public creationTime: string,
        public lastModified: string,
        public name: string,
        public retentionDuration: string,
        public sourceServiceConfiguration: Optional<OciLoggingLogSourceServiceConfiguration>,
        public status: OciLoggingLogStatus,
        public type: OciLoggingLogType) {
    }
}

export class OciLoggingLogSourceServiceConfiguration {
    constructor(
        public bucketName: Optional<string>,
        public category: string,
        public resourceOcid: Optional<string>,
        public serviceName: string) {
    }
}

export class OciNetworkingNetworkLoadBalancerBackend {
    constructor(
        public ipAddress: string,
        public port: number) {
    }
}

export class OciNetworkingNetworkLoadBalancerListener {
    constructor(
        public backendSetName: string,
        public ipAddressFamily: IpAddressFamily,
        public name: string,
        public port: number,
        public protocol: OciNetworkingNetworkLoadBalancerListenerProtocol) {
    }
}

export class OciNetworkingRouteTableRouteRule {
    constructor(
        public description: Optional<string>,
        public destinationServiceCidrLabel: Optional<string>,
        public destinationSubnet: Optional<string>,
        public privateIpTargetIpAddress: Optional<string>,
        public routeType: OciNetworkingRouteTableRouteRuleRouteType,
        public targetResourceOcid: string) {
    }
}

export class OciNetworkingSecurityRule {
    constructor(
        public description: Optional<string>,
        public destinationPortRange: Optional<IntRange>,
        public icmp: Optional<OciNetworkingSecurityRuleIcmp>,
        public protocolRange: IntRange,
        public serviceCidrLabel: Optional<string>,
        public sourcePortRange: Optional<IntRange>,
        public stateless: boolean,
        public subnet: Optional<string>) {
    }
}

export class OciNetworkingSecurityRuleIcmp {
    constructor(
        public code: Optional<number>,
        public type: Optional<number>) {
    }
}

export class OciOrganizationConfigurationUser {
    constructor(
        public domainOcid: Optional<string>,
        public ocid: string,
        public publicKeyFingerprint: string) {
    }
}

export class OciResourceProfile extends EntityProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName);
    }
}

export class OciResourceSearchGenericResourceProfile extends OciResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciResourceSearchGenericResourceProfile");
    }
}

export class OciScopeConfigurationOutpostRegionData {
    constructor(
        public availabilityZoneNames: string[]) {
    }
}

export class OciScopeConfigurationSections {
    constructor(
        public outpost: OciScopeConfigurationOutpost) {
    }
}

export class OktaDirectoryAwsApplicationMultiAwsTenant {
    constructor(
        public awsSamlIdentityProviderArnAndRoleArnPattern: Optional<string>,
        public groupNameRegex: Optional<string>,
        public groupSourceFilter: Optional<string>) {
    }
}

export class OktaDirectoryAwsApplicationSingleAwsTenant {
    constructor(
        public awsSamlIdentityProviderArn: Optional<string>,
        public joinGroupAssignmentAwsRoles: boolean) {
    }
}

export class OktaEntityProfile extends EntityProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName);
    }
}

export class OneLoginClientData {
    constructor(
        public id: string,
        public secret: string) {
    }
}

export class OneLoginDirectoryApplicationParameter {
    constructor(
        public defaultValues: Optional<string[]>,
        public name: string,
        public transformationType: OneLoginDirectoryApplicationParameterTransformationType,
        public type: OneLoginDirectoryApplicationParameterType,
        public value: Optional<string>) {
    }
}

export class OneLoginDirectoryApplicationRule {
    constructor(
        public actions: OneLoginDirectoryApplicationRuleAction[],
        public conditionMatchType: OneLoginDirectoryApplicationRuleConditionMatchType,
        public conditions: OneLoginDirectoryApplicationRuleCondition[],
        public enabled: boolean,
        public id: number,
        public name: string) {
    }
}

export class OneLoginDirectoryApplicationRuleAction {
    constructor(
        public macro: Optional<string>,
        public type: OneLoginDirectoryApplicationRuleActionType,
        public values: Optional<string[]>) {
    }
}

export class OneLoginDirectoryApplicationRuleCondition {
    constructor(
        public customAttributeName: Optional<string>,
        public operator: OneLoginDirectoryApplicationRuleConditionOperator,
        public source: OneLoginDirectoryApplicationRuleConditionSource,
        public value: string) {
    }
}

export class OneLoginEntityProfile extends EntityProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName);
    }
}

export class OneLoginTenantConfigurationClientData {
    constructor(
        public id: string) {
    }
}

export class OrchestrationStageStateData {
    constructor(
        public endTime: Optional<string>,
        public retryCount: number,
        public retryTotalCount: number,
        public scheduleTime: Optional<string>,
        public startTime: Optional<string>) {
    }
}

export class OrchestratorConfigurationSection extends ConfigurationSection {
    constructor(
        public emptyCustomerConcurrencyLevel: Optional<number>,
        public orchestrationStageTypeNameToConcurrencyLevelMap: Dictionary<number>,
        public orchestrationTypeToEmptyCustomerConcurrencyLevelMap: Dictionary<number>,
        public retryLimitEnabled: boolean,
        public tenantOrchestrationStageConcurrencyLevel: Optional<number>,
        public tenantOrchestrationStageTypeNameToConcurrencyLevelMap: Dictionary<number>) {
        super();
    }
}

export class OrganizationMemberSelection {
    constructor(
        public rawIds: string[],
        public type: OrganizationMemberSelectionType) {
    }
}

export class PagedItemSelection<TItem> extends ItemSelectionBase<TItem> {
    constructor(
        emptyValue: boolean,
        items: TItem[],
        public type: PagedItemSelectionType) {
        super(
            emptyValue,
            items);
    }
}

export class PendoConfigurationSection extends ConfigurationSection implements INullableConfigurationSection {
    constructor(
        public enabled: boolean) {
        super();
    }
}

export class PermissionEligibilityAuditEventModelPermissionEligibilityData {
    constructor(
        public entityIds: string[],
        public granteePrincipalIdReferences: string[],
        public levelToApproverPrincipalIdReferencesMap: Dictionary<string[]>) {
    }
}

export class PermissionEligibilityConfigurationApproval {
    constructor(
        public mails: string[],
        public slackChannelReferences: SlackConversationReference[],
        public teamsChannelReferences: TeamsChannelReference[]) {
    }
}

export class PermissionManagementAadTenantAuthenticationProviderConfigurationSection extends ConfigurationSection implements INullableConfigurationSection {
    constructor(
        public partitionTypeToDataMap: Dictionary<PermissionManagementAadTenantAuthenticationProviderConfigurationSectionPartitionData>) {
        super();
    }
}

export class PermissionManagementAadTenantAuthenticationProviderConfigurationSectionPartitionData {
    constructor(
        public applicationEncryptedSecret: EncryptedData,
        public applicationRawId: string) {
    }
}

export class PermissionManagementAuditEventModel extends AuditEventModel {
    constructor(
        auditEvent: AuditEvent,
        entityIds: string[]) {
        super(
            auditEvent,
            entityIds);
    }
}

export class PermissionManagementControllerApprovePermissionRequestRequest {
    constructor(
        public id: string) {
    }
}

export class PermissionManagementControllerApproverUserPermissionRequestFilters {
    constructor(
        public closeReasonAndStatusSelection: Optional<ItemSelection<PermissionRequestCloseReasonAndStatus>>,
        public granteeUserIdSelection: Optional<ItemSelection<string>>,
        public permissionAssignmentRequestTenantIdSelection: Optional<ItemSelection<string>>,
        public permissionEligibilityIdSelection: Optional<ItemSelection<string>>,
        public statusSelection: Optional<ItemSelection<PermissionRequestStatus>>,
        public systemCreationTimeRangeSelection: Optional<TimeRangeSelection>) {
    }
}

export class PermissionManagementControllerCancelPermissionRequestRequest {
    constructor(
        public id: string) {
    }
}

export class PermissionManagementControllerGetAadTenantOnboardingInfoResponse {
    constructor(
        public partitionTypeToApplicationRawIdMap: Dictionary<string>) {
    }
}

export class PermissionManagementControllerGetActivePermissionRequestPrincipalTenantIdToConsoleUrlMapResponse {
    constructor(
        public principalTenantIdToConsoleUrlMap: Dictionary<string>) {
    }
}

export class PermissionManagementControllerGetApproverUserPermissionRequestFiltersResponse {
    constructor(
        public granteeUserSearchableReferenceItems: FilterItems<EntitySearchableReference>,
        public permissionEligibilityIdItems: FilterItems<string>,
        public systemCreationTimeRange: FilterTimeRange,
        public tenantIdItems: FilterItems<string>) {
    }
}

export class PermissionManagementControllerGetApproverUserPermissionRequestModelPageRequest {
    constructor(
        public filters: PermissionManagementControllerApproverUserPermissionRequestFilters,
        public limit: number,
        public skip: number) {
    }
}

export class PermissionManagementControllerGetApproverUserPermissionRequestModelPageResponse {
    constructor(
        public permissionRequestModelPage: ItemPage<PermissionRequestModel>) {
    }
}

export class PermissionManagementControllerGetGranteeUserPermissionRequestFiltersResponse {
    constructor(
        public approverUserSearchableReferenceItems: FilterItems<EntitySearchableReference>,
        public permissionEligibilityIdItems: FilterItems<string>,
        public systemCreationTimeRange: FilterTimeRange,
        public tenantIdItems: FilterItems<string>) {
    }
}

export class PermissionManagementControllerGetGranteeUserPermissionRequestModelPageRequest {
    constructor(
        public filters: PermissionManagementControllerGranteeUserPermissionRequestFilters,
        public limit: number,
        public skip: number) {
    }
}

export class PermissionManagementControllerGetGranteeUserPermissionRequestModelPageResponse {
    constructor(
        public permissionRequestModelPage: ItemPage<PermissionRequestModel>) {
    }
}

export class PermissionManagementControllerGetInfoResponse {
    constructor(
        public permissionAuditEventExists: boolean,
        public slackEnabled: boolean,
        public teamsEnabled: boolean) {
    }
}

export class PermissionManagementControllerGetPermissionEligibilityFiltersRequest {
    constructor(
        public scope: PermissionManagementControllerPermissionEligibilityRequestScope) {
    }
}

export class PermissionManagementControllerGetPermissionEligibilityFiltersResponse {
    constructor(
        public filters: PermissionManagementControllerPermissionEligibilityFilters) {
    }
}

export class PermissionManagementControllerGetPermissionEligibilityModelPageRequest {
    constructor(
        public filters: PermissionManagementControllerGetPermissionEligibilityModelPageRequestFilters,
        public limit: number,
        public scope: PermissionManagementControllerPermissionEligibilityRequestScope,
        public skip: number,
        public sort: Optional<PermissionManagementControllerGetPermissionEligibilityModelPageRequestSort>) {
    }
}

export class PermissionManagementControllerGetPermissionEligibilityModelPageRequestFilters {
    constructor(
        public approvalRequired: Optional<boolean>,
        public approverPrincipalIdSelection: Optional<ItemSelection<string>>,
        public granteePrincipalIdSelection: Optional<ItemSelection<string>>,
        public nameSelection: Optional<ItemSelection<string>>,
        public principalTenantIdSelection: Optional<ItemSelection<string>>) {
    }
}

export class PermissionManagementControllerGetPermissionEligibilityModelPageRequestSort {
    constructor(
        public direction: SortDirection,
        public property: PermissionManagementControllerGetPermissionEligibilityModelPageRequestProperty) {
    }
}

export class PermissionManagementControllerGetPermissionEligibilityModelPageResponse {
    constructor(
        public permissionEligibilityModelPage: ItemPage<PermissionEligibilityModel>) {
    }
}

export class PermissionManagementControllerGetPermissionEligibilityPrincipalOverlapRequest {
    constructor(
        public granteePrincipalIds: string[],
        public levelToApproverPrincipalIdsMap: Dictionary<string[]>) {
    }
}

export class PermissionManagementControllerGetPermissionEligibilityPrincipalOverlapResponse {
    constructor(
        public granteeApproverOverlapUserIds: string[],
        public levelOverlapApproverUserIds: string[]) {
    }
}

export class PermissionManagementControllerGetPermissionEligibilityTenantIdsRequest {
    constructor(
        public permissionEligibilityId: string) {
    }
}

export class PermissionManagementControllerGetPermissionEligibilityTenantIdsResponse {
    constructor(
        public tenantIds: string[]) {
    }
}

export class PermissionManagementControllerGetUserInfoResponse {
    constructor(
        public approver: boolean,
        public grantee: boolean) {
    }
}

export class PermissionManagementControllerGetUserPermissionEligibilityIdsResponse {
    constructor(
        public permissionEligibilityIds: string[]) {
    }
}

export class PermissionManagementControllerGetUserPrincipalsResponse {
    constructor(
        public groupIds: string[],
        public userIds: string[]) {
    }
}

export class PermissionManagementControllerGranteeUserPermissionRequestFilters {
    constructor(
        public approverUserIdSelection: Optional<ItemSelection<string>>,
        public closeReasonAndStatusSelection: Optional<ItemSelection<PermissionRequestCloseReasonAndStatus>>,
        public permissionEligibilityIdSelection: Optional<ItemSelection<string>>,
        public statusSelection: Optional<ItemSelection<PermissionRequestStatus>>,
        public systemCreationTimeRangeSelection: Optional<TimeRangeSelection>,
        public tenantIdSelection: Optional<ItemSelection<string>>) {
    }
}

export class PermissionManagementControllerInsertPermissionRequestRequest {
    constructor(
        public expirationTimeFrame: string,
        public permissionEligibilityId: string,
        public reason: Optional<string>,
        public startTime: Optional<string>) {
    }
}

export class PermissionManagementControllerInsertPermissionRequestResponse {
    constructor(
        public permissionRequestStatus: Optional<PermissionRequestStatus>) {
    }
}

export class PermissionManagementControllerPermissionEligibilityFilters {
    constructor(
        public approverPrincipalSearchableReferenceItems: FilterItems<EntitySearchableReference>,
        public granteePrincipalSearchableReferenceItems: FilterItems<EntitySearchableReference>,
        public nameItems: FilterItems<string>,
        public principalTenantIdItems: FilterItems<string>) {
    }
}

export class PermissionManagementControllerPermissionEligibilityRequestScope {
    constructor(
        public id: string,
        public includeChildScopes: boolean) {
    }
}

export class PermissionManagementControllerRejectPermissionRequestRequest {
    constructor(
        public id: string) {
    }
}

export class PermissionManagementControllerRevokePermissionRequestRequest {
    constructor(
        public id: string) {
    }
}

export class PermissionManagementControllerValidateAwsIamPolicyDocumentRequest {
    constructor(
        public policyRawDocument: string) {
    }
}

export class PermissionManagementControllerValidateAwsIamPolicyDocumentResponse {
    constructor(
        public issues: TextIssue[]) {
    }
}

export class PermissionManagerRequestFilters {
    constructor(
        public accessLevelSelection: Optional<ItemSelection<AccessLevel>>,
        public actionConditionExists: Optional<boolean>,
        public actionExcessive: Optional<boolean>,
        public actionRiskCategorySelection: Optional<ItemSelection<ActionRiskCategory>>,
        public actionScopeSelection: Optional<ItemSelection<PermitterPermissionScope>>,
        public actionSelection: Optional<ItemSelection<string>>,
        public actionServiceIdSelection: Optional<ItemSelection<string>>,
        public destinationEntityAttributeValueSelection: Optional<ItemSelection<string>>,
        public destinationEntityIdSelection: Optional<PagedItemSelection<string>>,
        public destinationEntityScopeSelection: Optional<ItemSelection<PermitterPermissionScope>>,
        public destinationEntityTagSelection: Optional<PagedItemSelection<ResourceTag>>,
        public destinationEntityTenantIdSelection: Optional<ItemSelection<string>>,
        public destinationEntityTypeNameSelection: Optional<ItemSelection<string>>,
        public originatorEntityIdSelection: Optional<PagedItemSelection<string>>,
        public originatorEntityTenantIdSelection: Optional<ItemSelection<string>>,
        public originatorEntityTypeNameSelection: Optional<ItemSelection<string>>,
        public permitterEntityIdSelection: Optional<PagedItemSelection<string>>,
        public sourceEntityAttributeValueSelection: Optional<ItemSelection<string>>,
        public sourceEntityIdSelection: Optional<PagedItemSelection<string>>,
        public sourceEntityTagSelection: Optional<PagedItemSelection<ResourceTag>>,
        public sourceEntityTenantIdSelection: Optional<ItemSelection<string>>,
        public sourceEntityTypeNameSelection: Optional<ItemSelection<string>>) {
    }
}

export class PermissionRequestAuditEventFiltersTenantData {
    constructor(
        public tenantDisplayReference: string,
        public tenantName: string) {
    }
}

export class PermissionRequestAuditEventModel extends PermissionManagementAuditEventModel {
    constructor(
        public auditEvent: PermissionRequestAuditEvent,
        entityIds: string[],
        public approverUserIdReferences: string[],
        public permissionRequestData: PermissionRequestAuditEventModelPermissionRequestData) {
        super(
            auditEvent,
            entityIds);
    }
}

export class PermissionRequestAuditEventModelPermissionRequestActivationFailureData {
    constructor(
        public activationFailureData: PermissionRequestAuditEventPermissionRequestActivationFailureData) {
    }
}

export class PermissionRequestAuditEventModelPermissionRequestData {
    constructor(
        public granteeUserIdReference: string) {
    }
}

export class PermissionRequestAuditEventPermissionAssignmentRequestActivationFailureDataTenantData {
    constructor(
        public errorMessage: string,
        public name: string) {
    }
}

export class PermissionRequestExpirationAuditEventModel extends PermissionRequestAuditEventModel {
    constructor(
        public auditEvent: PermissionRequestExpirationAuditEvent,
        entityIds: string[],
        approverUserIdReferences: string[],
        permissionRequestData: PermissionRequestAuditEventModelPermissionRequestData,
        public activationFailureData: Optional<PermissionRequestAuditEventModelPermissionRequestActivationFailureData>) {
        super(
            auditEvent,
            entityIds,
            approverUserIdReferences,
            permissionRequestData);
    }
}

export class PermissionRequestModel {
    constructor(
        public activationFailureData: Optional<PermissionRequestModelActivationFailureData>,
        public granteeUserIdReference: string,
        public levelToApproverUserIdReferenceMap: Dictionary<string>,
        public levelToPotentialApproverUserIdReferencesMap: Dictionary<string[]>,
        public permissionRequest: PermissionRequest,
        public rejectorUserIdReference: Optional<string>,
        public revokerUserIdReference: Optional<string>) {
    }
}

export class PermissionRequestRevocationAuditEventModel extends PermissionRequestAuditEventModel {
    constructor(
        public auditEvent: PermissionRequestRevocationAuditEvent,
        entityIds: string[],
        approverUserIdReferences: string[],
        permissionRequestData: PermissionRequestAuditEventModelPermissionRequestData,
        public activationFailureData: Optional<PermissionRequestAuditEventModelPermissionRequestActivationFailureData>) {
        super(
            auditEvent,
            entityIds,
            approverUserIdReferences,
            permissionRequestData);
    }
}

export class PermissionsModel {
    constructor(
        public awsResourcePermitterTypes: AwsResourcePermitterType[],
        public azureResourcePermitterTypes: AzurePermitterType[],
        public destinationEntityCount: number,
        public destinationEntityTopIds: string[],
        public originatorEntityCount: number,
        public originatorEntityTopIds: string[],
        public permissionActionCount: number,
        public permissionTopActions: string[],
        public permitterEntityIds: string[],
        public sourceEntityCount: number,
        public sourceEntityTopIds: string[]) {
    }
}

export class PingIdentityDirectoryApplicationGroups {
    constructor(
        public condition: PingIdentityDirectoryApplicationGroupsCondition,
        public ids: string[]) {
    }
}

export class PingIdentityDirectoryApplicationParameter {
    constructor(
        public name: string,
        public type: PingIdentityDirectoryApplicationParameterType,
        public value: string) {
    }
}

export class PingIdentityEntityProfile extends EntityProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName);
    }
}

export class PrincipalModelAccessPermissions {
    constructor(
        public excessivePermissionActionStats: SecurityStats,
        public excessivePermissions: Optional<PrincipalModelAccessPermissionsPermissions>,
        public permissions: PrincipalModelAccessPermissionsPermissions) {
    }
}

export class PrincipalModelAccessPermissionsPermissions {
    constructor(
        public actionResourceCount: number,
        public actionRiskCategoryToSeverityMap: Dictionary<Severity>,
        public actionServiceCount: number,
        public severity: Optional<Severity>) {
    }
}

export class PrincipalModelAccessRisks {
    constructor(
        public jiraIssueIds: string[],
        public openAccessPrincipalRiskIds: string[],
        public serviceNowIncidentIds: string[]) {
    }
}

export class PrincipalModelAccessScopesActivity {
    constructor() {
    }
}

export class PrincipalModelAccessScopesPermissionsAccess {
    constructor(
        public actionServiceCount: number) {
    }
}

export class PrincipalModelAccessScopesPermissionStats {
    constructor() {
    }
}

export class PrincipalModelAccessScopesRisk {
    constructor(
        public jiraIssueExists: boolean,
        public jiraIssueIds: string[],
        public openAccessPrincipalRiskId: string,
        public scopeIds: string[],
        public serviceNowIncidentExists: boolean,
        public serviceNowIncidentIds: string[]) {
    }
}

export class ProjectConfigurationRule {
    constructor(
        public conditions: ProjectConfigurationRuleCondition[],
        public scopeIds: string[]) {
    }
}

export class ProjectControllerGetProjectModelsRequest {
    constructor(
        public ids: Optional<string[]>) {
    }
}

export class ProjectControllerGetProjectModelsResponse {
    constructor(
        public projectModels: ProjectModel[]) {
    }
}

export class RegionModel {
    constructor(
        public id: string,
        public location: RegionLocation,
        public name: Optional<string>,
        public systemName: string,
        public tenantType: TenantType) {
    }
}

export class ReportControllerDeleteReportRequest {
    constructor(
        public id: string) {
    }
}

export class ReportControllerGenerateReportRequest {
    constructor(
        public definition: ReportControllerReportRequestDefinition,
        public deliveries: Delivery[],
        public scopeId: string) {
    }
}

export class ReportControllerGenerateReportResponse {
    constructor(
        public report: Report) {
    }
}

export class ReportControllerGetDashboardReportRequest {
    constructor(
        public scopeId: string) {
    }
}

export class ReportControllerGetDashboardReportResponse {
    constructor(
        public entityEntitiesViewTypeNameToPublicRiskSeverityStatsMap: Dictionary<SecurityStats>,
        public identityExcessivePermissionActionStats: SecurityStats,
        public identityPermissionActionSeverityToSummaryMap: Dictionary<ReportControllerGetDashboardReportResponseCloudProviderTenantIdentitySummary>,
        public principalRiskCategoryToSummaryMap: Dictionary<ReportControllerGetDashboardReportResponseRiskCategorySummary>,
        public publicEntityRiskSeverityStats: SecurityStats,
        public riskCombinationToDataMap: Dictionary<RiskCombinationData>,
        public secretExistsRiskPolicyConfigurationTypeNameToEntityTypeNameToDataMap: Dictionary<Dictionary<DashboardSummarySecretExistsRiskEntityTypeData>>,
        public severityToRiskPolicyCategoryToCountMap: Dictionary<Dictionary<number>>,
        public timeFrameToCreatedBehaviorRiskPolicyConfigurationTypeNameToRiskCountMap: Dictionary<Dictionary<number>>,
        public timeFrameToRiskTrendsDataMap: Dictionary<DashboardSummaryRiskTrendsData>) {
    }
}

export class ReportControllerGetDashboardReportResponseCloudProviderTenantIdentitySummary {
    constructor(
        public count: number,
        public vendorCount: number) {
    }
}

export class ReportControllerGetDashboardReportResponseRiskCategorySummary {
    constructor(
        public openRiskSeverityToPrincipalCountMap: Dictionary<number>,
        public principalCount: number,
        public vendorServiceIdentityCount: number) {
    }
}

export class ReportControllerGetReportFiltersRequest {
    constructor(
        public childScopeReports: boolean,
        public scopeId: string) {
    }
}

export class ReportControllerGetReportFiltersResponse {
    constructor(
        public definitionIds: string[],
        public fileNames: string[],
        public systemCreationTimeRange: FilterTimeRange) {
    }
}

export class ReportControllerGetReportPageRequest {
    constructor(
        public childScopeReports: boolean,
        public filters: ReportControllerGetReportPageRequestFilters,
        public limit: number,
        public skip: number) {
    }
}

export class ReportControllerGetReportPageRequestFilters {
    constructor(
        public definitionIdSelection: Optional<ItemSelection<string>>,
        public fileNameSelection: Optional<ItemSelection<string>>,
        public scopeId: string,
        public systemCreationTimeRangeSelection: Optional<TimeRangeSelection>) {
    }
}

export class ReportControllerGetReportPageResponse {
    constructor(
        public reportPage: ItemPage<Report>) {
    }
}

export class ReportControllerInsertExportReportDefinitionRequest {
    constructor(
        public definition: ReportControllerReportRequestDefinition) {
    }
}

export class ReportControllerInsertExportReportDefinitionResponse {
    constructor(
        public reportDefinitionId: string) {
    }
}

export class ReportControllerReportRequestDefinition implements ITypeNameObject {
    constructor(
        public name: Optional<string>,
        public timeZoneInfoId: Optional<string>,
        public typeName: string) {
    }
}

export class ReportControllerRisksReportRequestDefinition extends ReportControllerReportRequestDefinition implements ITypeNameObject {
    constructor(
        name: Optional<string>,
        timeZoneInfoId: Optional<string>,
        typeName: string,
        public additionalColumnResourceTagKeys: Optional<string[]>,
        public filtered: boolean,
        public filters: RiskControllerRiskModelFilters,
        public riskPolicyTypeFilters: Optional<RiskControllerRiskPolicyTypeFilters>,
        public sort: Optional<RiskControllerRiskModelSort>,
        public status: RiskStatus) {
        super(
            name,
            timeZoneInfoId,
            typeName);
    }
}

export class ReportControllerWorkloadAnalysisReportRequestDefinition<TWorkloadResourceScanModel extends WorkloadResourceScanModel> extends ReportControllerReportRequestDefinition implements ITypeNameObject {
    constructor(
        name: Optional<string>,
        timeZoneInfoId: Optional<string>,
        typeName: string,
        public filtered: boolean,
        public filters: WorkloadControllerWorkloadAnalysisModelsFilters<TWorkloadResourceScanModel>) {
        super(
            name,
            timeZoneInfoId,
            typeName);
    }
}

export class ReportControllerWorkloadReportRequestDefinition extends ReportControllerReportRequestDefinition implements ITypeNameObject {
    constructor(
        name: Optional<string>,
        timeZoneInfoId: Optional<string>,
        typeName: string,
        public filtered: boolean,
        public filters: WorkloadControllerWorkloadResourceModelFilters) {
        super(
            name,
            timeZoneInfoId,
            typeName);
    }
}

export class ReportControllerWorkloadResourcePackageVulnerabilitiesReportRequestDefinition extends ReportControllerWorkloadReportRequestDefinition implements ITypeNameObject {
    constructor(
        name: Optional<string>,
        timeZoneInfoId: Optional<string>,
        typeName: string,
        filtered: boolean,
        filters: WorkloadControllerWorkloadResourceModelFilters,
        public includeDescription: Optional<boolean>) {
        super(
            name,
            timeZoneInfoId,
            typeName,
            filtered,
            filters);
    }
}

export class ReportControllerWorkloadResourcesReportRequestDefinition extends ReportControllerWorkloadReportRequestDefinition implements ITypeNameObject {
    constructor(
        name: Optional<string>,
        timeZoneInfoId: Optional<string>,
        typeName: string,
        filtered: boolean,
        filters: WorkloadControllerWorkloadResourceModelFilters,
        public sort: WorkloadControllerWorkloadResourceModelSort) {
        super(
            name,
            timeZoneInfoId,
            typeName,
            filtered,
            filters);
    }
}

export class ReportScheduleCadence {
    constructor(
        public hour: number,
        public interval: ReportScheduleCadenceInterval,
        public monthDay: Optional<number>,
        public weekDay: Optional<DayOfWeek>) {
    }
}

export class ResourceAnalysisScanManagerConfigurationSection extends ConfigurationSection {
    constructor(
        public nodeScanJobPodMaxCount: number,
        public scanJobTimeout: string) {
        super();
    }
}

export class ResourceTag implements IConsistentHashable {
    constructor(
        public key: string,
        public value: Optional<string>) {
    }
}

export class ResourceTagPattern {
    constructor(
        public caseSensitive: boolean,
        public keyPattern: string,
        public valuePattern: Optional<string>) {
    }
}

export class RiskCombinationData {
    constructor(
        public entityTypeNameToCountMap: Dictionary<number>,
        public orderedRiskPolicyCategories: RiskPolicyCategory[]) {
    }
}

export class RiskConfiguration extends Document implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        public starred: boolean,
        public starredUpdateTime: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "RiskConfiguration");
    }
}

export class RiskControllerDeleteCustomRiskPolicyRequest {
    constructor(
        public id: string) {
    }
}

export class RiskControllerExcludeEntityRequest {
    constructor(
        public entityId: string,
        public ignoreExpirationDate: Optional<string>,
        public ignoreReason: RiskPolicyConfigurationExclusionDataReason,
        public message: Optional<string>,
        public riskId: string) {
    }
}

export class RiskControllerGetBuiltInComplianceDatasResponse {
    constructor(
        public builtInComplianceDatas: RiskControllerGetBuiltInComplianceDatasResponseComplianceData[]) {
    }
}

export class RiskControllerGetBuiltInComplianceDatasResponseComplianceData {
    constructor(
        public compliance: BuiltInComplianceSection,
        public enabled: boolean) {
    }
}

export class RiskControllerGetBuiltInComplianceTypeToAnalysisGroupTypeToRiskPolicyConfigurationTypeNamesMapResponse {
    constructor(
        public builtInComplianceTypeToAnalysisGroupTypeToRiskPolicyConfigurationTypeNamesMap: Dictionary<Dictionary<string[]>>) {
    }
}

export class RiskControllerGetBuiltInRiskPolicyModelsRequest {
    constructor(
        public riskPolicyConfigurationTypeNames: string[],
        public scopeId: string) {
    }
}

export class RiskControllerGetBuiltInRiskPolicyModelsResponse {
    constructor(
        public riskPolicyConfigurationTypeNameToDataMap: Dictionary<RiskControllerGetBuiltInRiskPolicyModelsResponseData>) {
    }
}

export class RiskControllerGetBuiltInRiskPolicyModelsResponseData {
    constructor(
        public parentRiskPolicyModels: RiskPolicyModel[],
        public scopeRiskPolicyModel: RiskPolicyModel,
        public tenantRiskPolicyModels: RiskPolicyModel[]) {
    }
}

export class RiskControllerGetComplianceSectionDatasRequest {
    constructor(
        public childScopeCustomCompliances: boolean,
        public scopeId: string,
        public timeFrameData: boolean) {
    }
}

export class RiskControllerGetComplianceSectionDatasResponse {
    constructor(
        public complianceSectionIdentifierToTopRiskPolicyDatasMap: Dictionary<RiskControllerGetComplianceSectionDatasResponseRiskPolicyData[]>,
        public customComplianceSectionDatas: ComplianceSectionData[],
        public disabledBuiltInComplianceSectionDatas: ComplianceSectionData[],
        public enabledBuiltInComplianceSectionDatas: ComplianceSectionData[]) {
    }
}

export class RiskControllerGetComplianceSectionDatasResponseRiskPolicyData {
    constructor(
        public riskCount: number,
        public riskPolicyIdentifier: string) {
    }
}

export class RiskControllerGetCustomRiskEntityMatchConditionsMatchEntityModelCountRequest {
    constructor(
        public entityMatchConditions: EntityMatchCondition[],
        public entityTypeName: string,
        public excludeEntityMatchConditions: EntityMatchCondition[],
        public filterScope: boolean,
        public scopeId: string,
        public tenantTypes: TenantType[]) {
    }
}

export class RiskControllerGetCustomRiskEntityMatchConditionsMatchEntityModelCountResponse {
    constructor(
        public count: number) {
    }
}

export class RiskControllerGetCustomRiskEntityMatchConditionsMatchEntityModelPageRequest {
    constructor(
        public entityMatchConditions: EntityMatchCondition[],
        public entityTypeName: string,
        public excludeEntityMatchConditions: EntityMatchCondition[],
        public filterScope: boolean,
        public limit: number,
        public nextPageSearchCursor: Optional<ElasticsearchIndexSearchCursor>,
        public scopeId: string,
        public searchText: Optional<string>,
        public tenantTypes: TenantType[]) {
    }
}

export class RiskControllerGetCustomRiskEntityMatchConditionsMatchEntityModelPageResponse {
    constructor(
        public entityModelPage: ElasticsearchItemPage<EntityModel>) {
    }
}

export class RiskControllerGetDeletedRiskRequest {
    constructor(
        public id: string) {
    }
}

export class RiskControllerGetDeletedRiskResponse {
    constructor(
        public deletedRisk: DeletedRisk) {
    }
}

export class RiskControllerGetKubernetesAdmissionControllerRiskPolicyConfigurationTypeNameToLastViolationEventTimeMapRequest {
    constructor(
        public scopeId: Optional<string>) {
    }
}

export class RiskControllerGetKubernetesAdmissionControllerRiskPolicyConfigurationTypeNameToLastViolationEventTimeMapResponse {
    constructor(
        public riskPolicyConfigurationTypeNameToLastViolationEventTimeMap: Dictionary<string>) {
    }
}

export class RiskControllerGetRiskChangeModelsRequest {
    constructor(
        public riskIds: string[]) {
    }
}

export class RiskControllerGetRiskChangeModelsResponse {
    constructor(
        public changeModels: ChangeModel[]) {
    }
}

export class RiskControllerGetRiskCodeFileDiffsRequest {
    constructor(
        public id: string) {
    }
}

export class RiskControllerGetRiskCodeFileDiffsResponse {
    constructor(
        public data: Optional<RiskControllerGetRiskCodeFileDiffsResponseData>) {
    }
}

export class RiskControllerGetRiskCodeFileDiffsResponseData {
    constructor(
        public fileDiffs: CodeFileDiff[],
        public tenantId: string) {
    }
}

export class RiskControllerGetRiskExcludedEntityIdsRequest {
    constructor(
        public id: string) {
    }
}

export class RiskControllerGetRiskExcludedEntityIdsResponse {
    constructor(
        public excludedEntityIds: string[],
        public excludedRiskItemEntityIds: string[]) {
    }
}

export class RiskControllerGetRiskFilterItemPageRequest {
    constructor(
        public limit: number,
        public searchText: Optional<string>,
        public skip: number,
        public view: RisksView) {
    }
}

export class RiskControllerGetRiskFilterItemPageResponseBase {
    constructor() {
    }
}

export class RiskControllerGetRiskFiltersRequest {
    constructor(
        public view: RisksView) {
    }
}

export class RiskControllerGetRiskFiltersResponse {
    constructor(
        public filters: RiskFilters) {
    }
}

export class RiskControllerGetRiskGroupsRequest {
    constructor(
        public filters: RiskControllerRiskModelFilters,
        public type: RiskControllerGetRiskGroupsRequestType) {
    }
}

export class RiskControllerGetRiskGroupsResponse {
    constructor(
        public riskGroups: RiskControllerGetRiskGroupsResponseRiskGroup[],
        public riskModelCount: number) {
    }
}

export class RiskControllerGetRiskGroupsResponseRiskGroup {
    constructor(
        public riskCount: number,
        public severityToCountMap: Dictionary<number>,
        public typeName: string) {
    }
}

export class RiskControllerGetRiskGroupsResponseRiskPolicyTypeGroup extends RiskControllerGetRiskGroupsResponseRiskGroup {
    constructor(
        riskCount: number,
        severityToCountMap: Dictionary<number>,
        typeName: string,
        public analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        public customComplianceIds: string[],
        public riskPolicyConfigurationTypeName: string,
        public tenantIds: string[]) {
        super(
            riskCount,
            severityToCountMap,
            typeName);
    }
}

export class RiskControllerGetRiskGroupsResponseRiskTenantGroup extends RiskControllerGetRiskGroupsResponseRiskGroup {
    constructor(
        riskCount: number,
        severityToCountMap: Dictionary<number>,
        typeName: string,
        public builtInRiskPolicyConfigurationTypeNames: string[],
        public customRiskPolicyIds: string[],
        public tenantId: string) {
        super(
            riskCount,
            severityToCountMap,
            typeName);
    }
}

export class RiskControllerGetRiskGroupsResponseRiskTypeGroup extends RiskControllerGetRiskGroupsResponseRiskGroup {
    constructor(
        riskCount: number,
        severityToCountMap: Dictionary<number>,
        typeName: string) {
        super(
            riskCount,
            severityToCountMap,
            typeName);
    }
}

export class RiskControllerGetRiskIdToDataMapRequest {
    constructor(
        public ids: string[]) {
    }
}

export class RiskControllerGetRiskIdToDataMapResponse {
    constructor(
        public riskIdToDataMap: Dictionary<RiskControllerGetRiskIdToDataMapResponseData>) {
    }
}

export class RiskControllerGetRiskIdToDataMapResponseData {
    constructor(
        public extendedDescription: string,
        public extendedTitle: string,
        public severity: Severity) {
    }
}

export class RiskControllerGetRiskIdToJiraIssuesMapRequest {
    constructor(
        public riskIds: string[]) {
    }
}

export class RiskControllerGetRiskIdToJiraIssuesMapResponse {
    constructor(
        public riskIdToJiraIssuesMap: Dictionary<JiraIssue[]>) {
    }
}

export class RiskControllerGetRiskIdToScopeIdentityPermissionsMapRequest {
    constructor(
        public filters: RiskControllerRiskModelFilters) {
    }
}

export class RiskControllerGetRiskIdToScopeIdentityPermissionsMapResponse {
    constructor(
        public riskIdToScopeIdToIdentityPermissionsMap: Dictionary<Dictionary<IdentityPermission[]>>) {
    }
}

export class RiskControllerGetRiskIdToServiceNowIncidentsMapRequest {
    constructor(
        public riskIds: string[]) {
    }
}

export class RiskControllerGetRiskIdToServiceNowIncidentsMapResponse {
    constructor(
        public riskIdToServiceNowIncidentsMap: Dictionary<ServiceNowIncident[]>) {
    }
}

export class RiskControllerGetRiskModelPageRequest {
    constructor(
        public filters: RiskControllerRiskModelFilters,
        public limit: number,
        public skip: number,
        public sort: Optional<RiskControllerRiskModelSort>) {
    }
}

export class RiskControllerGetRiskModelPageResponse {
    constructor(
        public riskIdToScopeIdToIdentityPermissionsMap: Dictionary<Dictionary<IdentityPermission[]>>,
        public riskModelPage: ItemPage<RiskModel>) {
    }
}

export class RiskControllerGetRiskModelRequest {
    constructor(
        public id: string) {
    }
}

export class RiskControllerGetRiskModelResponse {
    constructor(
        public riskModel: RiskModel) {
    }
}

export class RiskControllerGetRiskModelsRequest {
    constructor(
        public ids: string[]) {
    }
}

export class RiskControllerGetRiskModelsResponse {
    constructor(
        public riskModels: RiskModel[]) {
    }
}

export class RiskControllerGetRiskPoliciesRequest {
    constructor(
        public scopeId: string) {
    }
}

export class RiskControllerGetRiskPoliciesResponse {
    constructor(
        public policies: RiskControllerGetRiskPoliciesResponsePolicy[]) {
    }
}

export class RiskControllerGetRiskPoliciesResponsePolicy {
    constructor(
        public enabled: boolean,
        public failedTenantCount: number,
        public riskCount: number,
        public riskPolicyConfigurationTypeName: string) {
    }
}

export class RiskControllerGetRiskPolicyAnalyzedEntityCountRequest {
    constructor(
        public riskPolicyDatas: RiskControllerGetRiskPolicyAnalyzedEntityCountRequestRiskPolicyData[],
        public scopeId: string) {
    }
}

export class RiskControllerGetRiskPolicyAnalyzedEntityCountRequestRiskPolicyData {
    constructor(
        public analysisGroupType: RiskPolicyTypeMetadataAnalysisGroupType,
        public riskPolicyIdentifier: string) {
    }
}

export class RiskControllerGetRiskPolicyAnalyzedEntityCountResponse {
    constructor(
        public riskPolicyDatas: RiskControllerGetRiskPolicyAnalyzedEntityCountResponseRiskPolicyData[]) {
    }
}

export class RiskControllerGetRiskPolicyAnalyzedEntityCountResponseRiskPolicyData {
    constructor(
        public analysisGroupType: RiskPolicyTypeMetadataAnalysisGroupType,
        public analyzedEntityCount: number,
        public riskPolicyIdentifier: string) {
    }
}

export class RiskControllerGetRiskPolicyExcludedEntityIdsRequest {
    constructor(
        public builtInRiskPolicyConfigurationTypeNames: string[],
        public customRiskPolicyIds: string[],
        public scopeId: string) {
    }
}

export class RiskControllerGetRiskPolicyExcludedEntityIdsResponse {
    constructor(
        public builtInRiskPolicyConfigurationTypeNameToExcludedEntityIdsMap: Dictionary<string[]>,
        public customRiskPolicyIdToExcludedEntityIdsMap: Dictionary<string[]>) {
    }
}

export class RiskControllerGetRiskPolicyFailedEntityCountRequest {
    constructor(
        public scopeId: Optional<string>) {
    }
}

export class RiskControllerGetRiskPolicyFailedEntityCountResponse {
    constructor(
        public riskPolicyIdentifierToFailedEntityCountMap: Dictionary<number>) {
    }
}

export class RiskControllerGetRiskPolicyFailedEntityIdsRequest {
    constructor(
        public analysisGroupType: RiskPolicyTypeMetadataAnalysisGroupType,
        public riskPolicyIdentifier: string,
        public scopeId: string) {
    }
}

export class RiskControllerGetRiskPolicyFailedEntityIdsResponse {
    constructor(
        public failedEntityIds: string[]) {
    }
}

export class RiskControllerGetRiskPolicyModelsRequestBase {
    constructor() {
    }
}

export class RiskControllerGetRiskPolicyModelsResponse {
    constructor(
        public riskPolicyModels: RiskPolicyModel[]) {
    }
}

export class RiskControllerGetRiskPolicySummaryRequest {
    constructor(
        public scopeId: string) {
    }
}

export class RiskControllerGetRiskPolicySummaryResponse {
    constructor(
        public builtInRiskPolicyConfigurationTypeNameToStatsMap: Dictionary<SecurityStats>) {
    }
}

export class RiskControllerGetRiskPolicyTypeGroupFilterItemPageRequest {
    constructor(
        public filters: RiskControllerCloudRiskModelFilters,
        public limit: number,
        public property: RiskControllerGetRiskPolicyTypeGroupFilterItemPageRequestProperty,
        public riskPolicyConfigurationTypeName: string,
        public searchText: Optional<string>,
        public skip: number) {
    }
}

export class RiskControllerGetRiskPolicyTypeGroupFilterItemPageResponseBase {
    constructor() {
    }
}

export class RiskControllerGetRiskPolicyTypeGroupFiltersRequest {
    constructor(
        public filters: RiskControllerRiskModelFilters,
        public riskPolicyConfigurationTypeName: string) {
    }
}

export class RiskControllerGetRiskPolicyTypeGroupFiltersResponse {
    constructor(
        public filters: RiskPolicyTypeGroupFilters) {
    }
}

export class RiskControllerGetRiskPolicyTypeGroupRiskIdToScopeIdentityPermissionsMapRequest extends RiskControllerGetRiskIdToScopeIdentityPermissionsMapRequest {
    constructor(
        filters: RiskControllerRiskModelFilters,
        public riskPolicyConfigurationTypeName: string) {
        super(filters);
    }
}

export class RiskControllerGetRiskPolicyTypeGroupRiskModelPageRequest extends RiskControllerGetRiskModelPageRequest {
    constructor(
        filters: RiskControllerRiskModelFilters,
        limit: number,
        skip: number,
        sort: Optional<RiskControllerRiskModelSort>,
        public riskPolicyTypeFilters: RiskControllerRiskPolicyTypeFilters) {
        super(
            filters,
            limit,
            skip,
            sort);
    }
}

export class RiskControllerGetRiskPolicyTypeMetadatasResponse {
    constructor(
        public kubernetesAdmissionControllerRiskPolicyConfigurationTypeNameToAnalysisGroupTypeToTenantTypesMap: Dictionary<Dictionary<TenantType[]>>,
        public kubernetesAdmissionControllerRiskPolicyConfigurationTypeNameToMetadataMap: Dictionary<RiskPolicyTypeMetadata>,
        public kubernetesAdmissionControllerRiskPolicyConfigurationTypeNameToTenantTypesMap: Dictionary<TenantType[]>,
        public riskPolicyConfigurationTypeNameToAnalysisGroupTypeToTenantTypesMap: Dictionary<Dictionary<TenantType[]>>,
        public riskPolicyConfigurationTypeNameToCodeRiskCloudTenantTypesMap: Dictionary<TenantType[]>,
        public riskPolicyConfigurationTypeNameToExclusionAnyScopeEntityTypeNamesMap: Dictionary<string[]>,
        public riskPolicyConfigurationTypeNameToExclusionEntityTypeNamesMap: Dictionary<string[]>,
        public riskPolicyConfigurationTypeNameToExclusionEntityTypeNameToPropertyPatternTypeNamesMap: Dictionary<Dictionary<string[]>>,
        public riskPolicyConfigurationTypeNameToMetadataMap: Dictionary<RiskPolicyTypeMetadata>,
        public riskPolicyConfigurationTypeNameToRiskTypeNamesMap: Dictionary<string[]>,
        public riskPolicyConfigurationTypeNameToTenantTypesMap: Dictionary<TenantType[]>,
        public riskPolicyConfigurationTypeNameToTenantTypeToComplianceRiskedEntityTypeNameMap: Dictionary<Dictionary<string>>,
        public riskPolicyConfigurationTypeNameToTenantTypeToRiskedEntityTypeNameMap: Dictionary<Dictionary<string>>) {
    }
}

export class RiskControllerGetRiskTypeMetadataModelsResponse {
    constructor(
        public riskTypeNameToMetadataModelMap: Dictionary<RiskTypeMetadataModel>) {
    }
}

export class RiskControllerGetScopeGroupRiskIdToScopeIdentityPermissionsMapRequest extends RiskControllerGetRiskIdToScopeIdentityPermissionsMapRequest {
    constructor(
        filters: RiskControllerRiskModelFilters,
        public tenantId: string) {
        super(filters);
    }
}

export class RiskControllerGetTenantGroupRiskModelPageRequest extends RiskControllerGetRiskModelPageRequest {
    constructor(
        filters: RiskControllerRiskModelFilters,
        limit: number,
        skip: number,
        sort: Optional<RiskControllerRiskModelSort>,
        public tenantId: string) {
        super(
            filters,
            limit,
            skip,
            sort);
    }
}

export class RiskControllerInsertCustomRiskPolicyRequest {
    constructor(
        public description: Optional<string>,
        public name: string,
        public scopeId: string,
        public severity: Severity) {
    }
}

export class RiskControllerInsertCustomRiskPolicyResponse {
    constructor(
        public riskPolicyModel: RiskPolicyModel) {
    }
}

export class RiskControllerInsertGcpEntityInboundExternalPortRiskPolicyRequest extends RiskControllerInsertCustomRiskPolicyRequest {
    constructor(
        description: Optional<string>,
        name: string,
        scopeId: string,
        severity: Severity,
        public anyIpV4AddressSubnet: boolean,
        public destinationNetworkScopes: DestinationNetworkScope[],
        public exactSubnets: Optional<string[]>,
        public inboundAccessScope: Optional<NetworkAccessScope>,
        public overlapSubnets: Optional<string[]>) {
        super(
            description,
            name,
            scopeId,
            severity);
    }
}

export class RiskControllerInsertGcpIdentityActivityRiskPolicyRequest extends RiskControllerInsertCustomRiskPolicyRequest {
    constructor(
        description: Optional<string>,
        name: string,
        scopeId: string,
        severity: Severity,
        public identityBuiltInAttributeTypeNames: Optional<string[]>,
        public identityCustomAttributeDefinitionIds: Optional<string[]>,
        public identityIds: Optional<string[]>) {
        super(
            description,
            name,
            scopeId,
            severity);
    }
}

export class RiskControllerInsertGcpPrincipalNotAllowedResourceRoleRiskPolicyRequest extends RiskControllerInsertCustomRiskPolicyRequest {
    constructor(
        description: Optional<string>,
        name: string,
        scopeId: string,
        severity: Severity,
        public excludePrincipalMatchConditions: EntityMatchCondition[],
        public excludeScopeResourceMatchConditions: EntityMatchCondition[],
        public principalMatchConditions: EntityMatchCondition[],
        public roles: GcpPrincipalNotAllowedResourceRoleRiskPolicyConfigurationRoles,
        public scopeResourceMatchConditions: EntityMatchCondition[]) {
        super(
            description,
            name,
            scopeId,
            severity);
    }
}

export class RiskControllerInsertGcpPrincipalResourcePermissionRiskPolicyRequest extends RiskControllerInsertCustomRiskPolicyRequest {
    constructor(
        description: Optional<string>,
        name: string,
        scopeId: string,
        severity: Severity,
        public actionNamePatterns: Optional<string[]>,
        public actionRiskCategories: Optional<ActionRiskCategory[]>,
        public actions: Optional<string[]>,
        public principalBuiltInAttributeTypeNames: Optional<string[]>,
        public principalIds: Optional<string[]>,
        public resourceBuiltInAttributeTypeNames: Optional<string[]>,
        public resourceCustomAttributeDefinitionIds: Optional<string[]>,
        public resourceIds: Optional<string[]>,
        public resourceTags: Optional<ResourceTag[]>) {
        super(
            description,
            name,
            scopeId,
            severity);
    }
}

export class RiskControllerInsertResourceCodeResourceNotExistsRiskPolicyRequest extends RiskControllerInsertCustomRiskPolicyRequest {
    constructor(
        description: Optional<string>,
        name: string,
        scopeId: string,
        severity: Severity,
        public allResources: boolean,
        public resourceBuiltInAttributeTypeNames: Optional<string[]>,
        public resourceCustomAttributeDefinitionIds: Optional<string[]>,
        public resourceIds: Optional<string[]>,
        public resourceTags: Optional<ResourceTag[]>,
        public resourceTypeNames: Optional<string[]>) {
        super(
            description,
            name,
            scopeId,
            severity);
    }
}

export class RiskControllerInsertResourceTagNotExistsRiskPolicyRequest extends RiskControllerInsertCustomRiskPolicyRequest {
    constructor(
        description: Optional<string>,
        name: string,
        scopeId: string,
        severity: Severity,
        public allResources: boolean,
        public existsResourceTagKeys: Optional<string[]>,
        public existsResourceTagPattern: Optional<ResourceTagPattern>,
        public existsResourceTags: Optional<ResourceTag[]>,
        public resourceBuiltInAttributeTypeNames: Optional<string[]>,
        public resourceCustomAttributeDefinitionIds: Optional<string[]>,
        public resourceIds: Optional<string[]>,
        public resourceTags: Optional<ResourceTag[]>,
        public resourceTypeNames: Optional<string[]>) {
        super(
            description,
            name,
            scopeId,
            severity);
    }
}

export class RiskControllerResolveRiskAutomationRequest {
    constructor(
        public change: Change,
        public id: string) {
    }
}

export class RiskControllerResolveRiskAutomationResponse {
    constructor(
        public errorCode: Optional<string>,
        public errorMessage: Optional<string>,
        public success: boolean) {
    }
}

export class RiskControllerResolveRiskCodeAutomationRequest {
    constructor(
        public commitMessage: string,
        public description: string,
        public id: string) {
    }
}

export class RiskControllerResolveRiskCodeAutomationResponse {
    constructor(
        public pullRequestUrl: string) {
    }
}

export class RiskControllerRiskModelSort {
    constructor(
        public direction: SortDirection,
        public riskModelProperty: RiskControllerRequestSortRiskModelProperty) {
    }
}

export class RiskControllerSendRisksMailRequest {
    constructor(
        public deliveryRequest: DeliveryRequest,
        public ids: string[]) {
    }
}

export class RiskControllerSendRisksWebhookRequest {
    constructor(
        public endpointId: string,
        public riskIds: string[]) {
    }
}

export class RiskControllerUpdateBuiltInComplianceEnabledRequest {
    constructor(
        public builtInComplianceType: BuiltInComplianceSectionType,
        public enabled: boolean) {
    }
}

export class RiskControllerUpdateCustomRiskPolicySeverityRequest {
    constructor(
        public riskPolicyId: string,
        public severity: Severity) {
    }
}

export class RiskControllerUpdateRiskChangesRequest {
    constructor(
        public riskIdToRequestedChangeIdsMap: Dictionary<string[]>,
        public riskIdToResolvedChangeIdsMap: Dictionary<string[]>) {
    }
}

export class RiskControllerUpdateRiskPolicyConfigurationRequest {
    constructor(
        public configuration: RiskPolicyConfiguration) {
    }
}

export class RiskControllerUpdateRiskStarredRequest {
    constructor(
        public ids: string[],
        public starred: boolean) {
    }
}

export class RiskControllerUpdateRiskStatusRequest {
    constructor(
        public message: Optional<string>,
        public riskIds: string[]) {
    }
}

export class RiskControllerUpsertRiskJiraIssueRequest {
    constructor(
        public id: Optional<string>,
        public instanceId: string,
        public issueCreationData: JiraIssueCreationData,
        public riskId: string) {
    }
}

export class RiskControllerUpsertRiskJiraIssuesRequest {
    constructor(
        public instanceId: string,
        public issueCreationData: JiraIssueCreationData,
        public multipleRisks: boolean,
        public riskIdToIssueIdsMap: Dictionary<string[]>) {
    }
}

export class RiskControllerUpsertRiskJiraIssuesResponse {
    constructor(
        public riskIdToFailedIssueResultsMap: Dictionary<RiskSenderUpsertRiskJiraIssueResult[]>) {
    }
}

export class RiskControllerUpsertRiskServiceNowIncidentRequest {
    constructor(
        public id: Optional<string>,
        public incidentCreationData: ServiceNowIncidentCreationData,
        public instanceId: string,
        public riskId: string) {
    }
}

export class RiskControllerUpsertRiskServiceNowIncidentsRequest {
    constructor(
        public incidentCreationData: ServiceNowIncidentCreationData,
        public instanceId: string,
        public multipleRisks: boolean,
        public riskIdToIncidentIdsMap: Dictionary<string[]>) {
    }
}

export class RiskControllerUpsertRiskServiceNowIncidentsResponse {
    constructor(
        public riskIdToFailedIncidentResultsMap: Dictionary<RiskSenderUpsertRiskServiceNowIncidentResult[]>) {
    }
}

export class RiskEngineConfigurationSection extends ConfigurationSection {
    constructor(
        public analyzeAzureAccessRiskRuleTenantConcurrencyLevel: number) {
        super();
    }
}

export class RiskFile {
    constructor(
        public contentBytes: string,
        public name: string,
        public type: RiskFileType) {
    }
}

export class RiskPolicyConfigurationCodeExclusion {
    constructor(
        public data: RiskPolicyConfigurationExclusionData,
        public fileRelativePathPattern: Optional<string>,
        public resourceNamePattern: Optional<string>,
        public resourceTypeSystemNamePattern: Optional<string>) {
    }
}

export class RiskPolicyConfigurationEntityExclusion {
    constructor(
        public entityIdToDataMap: Dictionary<RiskPolicyConfigurationExclusionData>,
        public entityPropertyPatterns: RiskPolicyConfigurationEntityExclusionEntityPropertyPattern[]) {
    }
}

export class RiskPolicyConfigurationExclusionData {
    constructor(
        public expirationDate: Optional<string>,
        public message: Optional<string>,
        public reason: RiskPolicyConfigurationExclusionDataReason) {
    }
}

export class RiskPolicyModel {
    constructor(
        public riskPolicyCategory: RiskPolicyCategory,
        public riskPolicyConfiguration: RiskPolicyConfiguration) {
    }
}

export class RiskPolicyTypeMetadata {
    constructor(
        public builtInComplianceSectionTypeToAnalysisGroupTypeMap: Dictionary<RiskPolicyTypeMetadataAnalysisGroupType>,
        public category: RiskPolicyCategory,
        public complianceRiskedEntityTypeName: Optional<string>,
        public disabledAwsTenantPartitionTypes: AwsPartitionType[],
        public licenseType: ApplicationCustomerConfigurationLicensingLicenseType,
        public riskedEntityTypeName: Optional<string>,
        public riskPolicyEntityCreationTimeAnalysisDelayTimeFrameChangeable: boolean,
        public severity: Optional<Severity>,
        public temporal: boolean) {
    }
}

export class RiskPolicyUpdateAuditEventModel extends AuditEventModel {
    constructor(
        public auditEvent: RiskPolicyUpdateAuditEvent,
        entityIds: string[]) {
        super(
            auditEvent,
            entityIds);
    }
}

export class RiskSenderUpsertRiskJiraIssueResult {
    constructor(
        public issueId: Optional<string>,
        public issueUpdated: boolean,
        public rawError: Optional<string>,
        public result: RiskSenderSendRiskJiraIssueResult) {
    }
}

export class RiskSenderUpsertRiskServiceNowIncidentResult {
    constructor(
        public incidentId: Optional<string>,
        public incidentUpdated: boolean,
        public result: RiskSenderSendRiskServiceNowIncidentResult) {
    }
}

export class RiskStateIntegration {
    constructor(
        public jira: RiskStateIntegrationJira,
        public serviceNow: RiskStateIntegrationServiceNow) {
    }
}

export class RiskStateIntegrationJira {
    constructor(
        public issueIds: string[]) {
    }
}

export class RiskStateIntegrationServiceNow {
    constructor(
        public incidentIds: string[]) {
    }
}

export class RiskTypeMetadata extends ObjectTypeMetadata {
    constructor() {
        super();
    }
}

export class RiskTypeMetadataModel {
    constructor(
        public complianceRiskedEntityTypeName: string,
        public exclusionEntityTypeNames: string[],
        public policyConfigurationTypeName: string,
        public riskedEntityTypeName: string) {
    }
}

export class ScopeConfigurationSection<TScopeConfigurationSection> {
    constructor() {
    }
}

export class ScopeConfigurationSections {
    constructor(
        public code: ScopeConfigurationCode,
        public dataAnalysis: ScopeConfigurationDataAnalysis,
        public workloadAnalysis: ScopeConfigurationWorkloadAnalysis) {
    }
}

export class ScopeConfigurationWorkloadAnalysis extends ScopeConfigurationSection<ScopeConfigurationWorkloadAnalysis> {
    constructor(
        public containerImageMaxCount: Optional<number>,
        public containerImageMaxSize: Optional<number>,
        public containerImageRepositoryEnabled: Optional<boolean>,
        public containerImageRepositoryMaxCount: Optional<number>,
        public diskMaxSize: Optional<number>,
        public excludeUnresolvableVulnerabilities: Optional<boolean>,
        public fileScanEnabled: Optional<boolean>,
        public severeVulnerabilityMinSeverity: Optional<Severity>,
        public tenantCustomResourceTags: Optional<ResourceTag[]>,
        public tenantValidationDisabled: Optional<boolean>,
        public trustedFiles: ScopeConfigurationWorkloadAnalysisTrustedFile[],
        public virtualMachine: VirtualMachineScopeConfiguration,
        public virtualMachineImage: VirtualMachineScopeConfiguration) {
        super();
    }
}

export class ScopeConfigurationWorkloadAnalysisTrustedFile {
    constructor(
        public comment: Optional<string>,
        public contentSha256String: string,
        public identityReference: Optional<IdentityReference>) {
    }
}

export class ScopeControllerGetScopeNodeModelsRequest {
    constructor(
        public ids: Optional<string[]>) {
    }
}

export class ScopeControllerGetScopeNodeModelsResponse {
    constructor(
        public scopeNodeModels: ScopeNodeModel[]) {
    }
}

export class ScopeControllerMoveScopeRequest {
    constructor(
        public id: string,
        public parentFolderId: string) {
    }
}

export class ScopeControllerMoveScopeResponse {
    constructor(
        public scopeNodeModel: ScopeNodeModel) {
    }
}

export class ScopeDocument extends Document implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        public scopeId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName);
    }
}

export class ScopeModel {
    constructor(
        public configuration: SystemEntityConfiguration,
        public state: SystemEntityState) {
    }
}

export class ScopeNodeModel {
    constructor(
        public configuration: IScopeConfiguration,
        public type: ScopeType) {
    }
}

export class ScopeSettingConfigurationData {
    constructor(
        public actions: ScopeSettingAction[],
        public scopeSettingConfiguration: ScopeSettingConfiguration) {
    }
}

export class ScopeSummaryCloudProviderEntityPublicData {
    constructor(
        public recentRiskedEntityCount: number,
        public riskedEntityCount: number,
        public riskPolicyConfigurationTypeNames: string[],
        public severity: Severity) {
    }
}

export class ScopeSummaryGeneratorConfigurationSection extends ConfigurationSection {
    constructor(
        public concurrencyLevel: number) {
        super();
    }
}

export class SearchableDataBase {
    constructor() {
    }
}

export class SecurityStats {
    constructor(
        public count: number,
        public securedCount: number,
        public unsecuredExcludedCount: number,
        public unsecuredSeverityToCountMap: Dictionary<number>) {
    }
}

export class SensorVirtualMachineMetadata {
    constructor(
        public location: Optional<string>,
        public name: Optional<string>,
        public rawId: Optional<string>,
        public rawPath: Optional<string>,
        public regionSystemName: Optional<string>,
        public tenantRawId: Optional<string>) {
    }
}

export class ServiceNowGroup {
    constructor(
        public name: string,
        public rawId: string) {
    }
}

export class ServiceNowIncidentCreationData {
    constructor(
        public additionalMandatoryFieldNameToValueMap: Dictionary<string>,
        public description: Optional<string>,
        public fileNames: Optional<string[]>,
        public group: Optional<ServiceNowGroup>,
        public impact: Optional<ServiceNowIncidentImpact>,
        public summary: Optional<string>,
        public urgency: Optional<ServiceNowIncidentUrgency>,
        public user: Optional<ServiceNowUser>) {
    }
}

export class ServiceNowIncidentInfo {
    constructor(
        public additionalMandatoryFieldNameToValueMap: Dictionary<string>,
        public description: string,
        public fileNames: Optional<string[]>,
        public group: Optional<ServiceNowGroup>,
        public impact: Optional<ServiceNowIncidentImpact>,
        public instanceId: string,
        public summary: string,
        public urgency: Optional<ServiceNowIncidentUrgency>,
        public user: Optional<ServiceNowUser>) {
    }
}

export class ServiceNowUser {
    constructor(
        public displayName: string,
        public mail: Optional<string>,
        public name: string,
        public rawId: string,
        public userName: Optional<string>) {
    }
}

export class SingleStoreClientProviderConfigurationSection extends ConfigurationSection {
    constructor(
        public workspaceGroupNameToConfigurationMap: Dictionary<SingleStoreWorkspaceGroupConfiguration>) {
        super();
    }
}

export class SingleStoreWorkspaceGroupConfiguration {
    constructor(
        public encryptedPassword: EncryptedData,
        public hostName: string,
        public name: string,
        public userName: string) {
    }
}

export class SlackConversationReference {
    constructor(
        public rawId: string,
        public workspaceId: string) {
    }
}

export class SlackManagerConfigurationSection extends ConfigurationSection implements INullableConfigurationSection {
    constructor(
        public clientEncryptedSecret: EncryptedData,
        public clientId: string) {
        super();
    }
}

export class SnowflakeAccountConfiguration {
    constructor(
        public encryptedPrivateKey: EncryptedData,
        public name: string,
        public userName: string,
        public warehouseName: string) {
    }
}

export class SnowflakeClientProviderConfigurationSection extends ConfigurationSection implements INullableConfigurationSection {
    constructor(
        public accountNameToConfigurationMap: Dictionary<SnowflakeAccountConfiguration>) {
        super();
    }
}

export class Subdocument implements ITypeNameObject {
    constructor(
        public typeName: string) {
    }
}

export class Summary {
    constructor() {
    }
}

export class SummarySection implements ISummarySection {
    constructor() {
    }
}

export class SystemControllerGetGeographyInfoResponse {
    constructor(
        public consoleAppGeographyTypeToUrlMap: Dictionary<string>,
        public deploymentAadPartitionType: AadPartitionType,
        public deploymentAwsPartitionType: AwsPartitionType,
        public deploymentNameToConsoleUrlMap: Dictionary<string>,
        public deploymentPartitionType: PartitionType,
        public globalConsoleAppUrl: string) {
    }
}

export class SystemControllerGetInfoResponse {
    constructor(
        public aadPartitionTypeToAuthorityUrlMap: Dictionary<string>,
        public aadPartitionTypeToAzureConsoleDomainNameMap: Dictionary<string>,
        public aadPartitionTypeToAzureMachineLearningDomainNameMap: Dictionary<string>,
        public amplitudeApiKey: Optional<string>,
        public authenticationCustomerAadClientId: Optional<string>,
        public authenticationUserAadClientId: Optional<string>,
        public awsPartitionTypeToConsoleDomainNameMap: Dictionary<string>,
        public cognitoEnabled: boolean,
        public consoleApiUrl: string,
        public consoleAppUrl: string,
        public customerConsoleAppUrl: string,
        public datadogRumApplication: Optional<SystemControllerGetInfoResponseDatadogRumApplication>,
        public deploymentName: string,
        public environmentType: EnvironmentType,
        public globalDeploymentConsoleUrl: string,
        public graphApiUrl: string,
        public identityRoleToCustomerConsoleAppPermissionsMap: Dictionary<IdentityPermission[]>,
        public ociRealmIdToDomainNameMap: Dictionary<string>,
        public pendoApiKey: Optional<string>,
        public teamsConsoleAppUrl: string,
        public userConsoleAppUrl: string) {
    }
}

export class SystemControllerGetInfoResponseDatadogRumApplication {
    constructor(
        public clientToken: string,
        public id: string,
        public recordingEnabled: boolean,
        public siteDomainName: string) {
    }
}

export class SystemEntity extends Document implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName);
    }
}

export class SystemEntityConfiguration extends Document implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName);
    }
}

export class SystemEntityModel extends Document implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName);
    }
}

export class SystemEntityState extends Document implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName);
    }
}

export class SystemKubernetesClusterFilters extends Summary {
    constructor(
        public applicationVersionValueItems: FilterItems<string>,
        public clusterCreationTimeRange: FilterTimeRange,
        public resourceUpdateTimeRange: FilterTimeRange,
        public tenantIdItems: FilterItems<string>) {
        super();
    }
}

export class SystemKubernetesClusterState extends SystemEntityState implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "SystemKubernetesClusterState");
    }
}

export class SystemVirtualMachineState extends SystemEntityState implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "SystemVirtualMachineState");
    }
}

export class TeamsApplicationAuthenticationProviderConfigurationSection extends ConfigurationSection implements INullableConfigurationSection {
    constructor(
        public applicationEncryptedSecret: EncryptedData,
        public applicationRawId: string) {
        super();
    }
}

export class TeamsConversationReference extends Subdocument implements ITypeNameObject {
    constructor(
        typeName: string,
        public organizationId: string,
        public rawId: string) {
        super(typeName);
    }
}

export class TeamsOrganizationState extends SystemEntityState implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        public channelRawIdToNameMap: Dictionary<string>,
        public issue: Optional<TeamsOrganizationStateIssue>,
        public teamRawIdToChannelReferencesMap: Dictionary<TeamsChannelReference[]>,
        public teamRawIdToNameMap: Dictionary<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "TeamsOrganizationState");
    }
}

export class TenantConfiguration extends SystemEntityConfiguration implements ITypeNameObject, IChildScopeConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        public active: boolean,
        public deletePending: boolean,
        public disabledOrchestrationTypes: OrchestrationType[],
        public displayReference: string,
        public name: string,
        public orchestrationTypeToEnabledMap: Dictionary<boolean>,
        public parentScopeId: string,
        public scopeSections: ScopeConfigurationSections,
        public type: TenantType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName);
    }
}

export class TenantControllerDeleteOrganizationRequest {
    constructor(
        public id: string) {
    }
}

export class TenantControllerDeleteOrganizationResponse {
    constructor(
        public deletedTenantModels: TenantModel[]) {
    }
}

export class TenantControllerDeleteTenantRequest {
    constructor(
        public id: string) {
    }
}

export class TenantControllerDeleteTenantResponse {
    constructor(
        public deletedTenantModels: TenantModel[]) {
    }
}

export class TenantControllerGetAadTenantOnboardingInfoResponse {
    constructor(
        public partitionTypes: AadPartitionType[],
        public partitionTypeToApplicationRawIdMap: Dictionary<string>) {
    }
}

export class TenantControllerGetAwsTenantOnboardingInfoRequest {
    constructor(
        public scopeId: string) {
    }
}

export class TenantControllerGetAwsTenantOnboardingPoliciesInfoRequest {
    constructor(
        public partitionType: AwsPartitionType,
        public terraformStateBucketNameToEncryptionKeyArnsMap: Dictionary<string[]>,
        public trailBucketNameToEncryptionKeyArnsMap: Dictionary<string[]>) {
    }
}

export class TenantControllerGetAwsTenantOnboardingPoliciesInfoResponse {
    constructor(
        public applicationRoleTenantRawId: Optional<string>,
        public roleContainerImageRepositoryAnalysisPolicyDocument: AwsIamPolicyDocument,
        public roleDataAnalysisPolicyDocument: AwsIamPolicyDocument,
        public roleManagementPolicyDocument: AwsIamPolicyDocument,
        public rolePermissionManagementPolicyDocument: AwsIamPolicyDocument,
        public roleReadOnlyPolicyDocument: AwsIamPolicyDocument,
        public roleTerraformStateBucketPolicyDocument: Optional<AwsIamPolicyDocument>,
        public roleTrailBucketPolicyDocument: Optional<AwsIamPolicyDocument>,
        public roleWorkloadAnalysisPolicyDocument: AwsIamPolicyDocument) {
    }
}

export class TenantControllerGetAzureTenantOnboardingInfoResponse {
    constructor(
        public servicePrincipalManagementRoleDefinitionNames: string[],
        public servicePrincipalReadOnlyRoleDefinitionNames: string[],
        public servicePrincipalWorkloadAnalysisRoleDefinitionNames: string[]) {
    }
}

export class TenantControllerGetGciTenantOnboardingInfoResponse {
    constructor(
        public applicationServiceAccountMail: string,
        public serviceAccountAuthorizationScopes: string[],
        public serviceAccountPermissionManagementAuthorizationScopes: string[],
        public serviceAccountTenantExternalIdTag: ResourceTag) {
    }
}

export class TenantControllerGetGcpTenantOnboardingInfoResponse {
    constructor(
        public pubSubMessageRetentionDayCount: number,
        public serviceAccountDataAnalysisPermissionActions: string[],
        public serviceAccountManagementRoleRawIdToNameMap: Dictionary<string>,
        public serviceAccountOrganizationReadOnlyPermissionActions: string[],
        public serviceAccountOrganizationReadOnlyRoleNames: string[],
        public serviceAccountPermissionManagementRoleRawIdToNameMap: Dictionary<string>,
        public serviceAccountReadOnlyRoleRawIdToNameMap: Dictionary<string>,
        public serviceAccountReadOnlyTerraformStateBucketRoleRawIdToNameMap: Dictionary<string>,
        public serviceAccountWorkloadAnalysisPermissionActions: string[],
        public sinkExclusionFilter: string,
        public sinkInclusionFilter: string) {
    }
}

export class TenantControllerGetTenantModelsRequest {
    constructor(
        public ids: Optional<string[]>) {
    }
}

export class TenantControllerGetTenantModelsResponse {
    constructor(
        public tenantModels: TenantModel[]) {
    }
}

export class TenantControllerInsertAadChinaTenantRequest {
    constructor(
        public applicationRawId: string,
        public applicationSecret: string,
        public rawId: string) {
    }
}

export class TenantControllerInsertAadChinaTenantResponse {
    constructor(
        public tenantModel: AadTenantModel) {
    }
}

export class TenantControllerInsertAwsTenantRequest {
    constructor(
        public name: string,
        public parentFolderId: string,
        public permissionTypes: CloudProviderTenantPermissionType[],
        public regionSystemNames: Optional<string[]>,
        public roleArn: string,
        public trailArns: Optional<string[]>,
        public trailBucket: Optional<AwsTenantConfigurationTrailBucket>) {
    }
}

export class TenantControllerInsertAwsTenantResponse {
    constructor(
        public tenantModel: AwsTenantModel) {
    }
}

export class TenantControllerInsertAzureTenantRequest {
    constructor(
        public aadTenantId: string,
        public parentFolderId: string,
        public permissionTypes: CloudProviderTenantPermissionType[],
        public rawShortId: string) {
    }
}

export class TenantControllerInsertAzureTenantResponse {
    constructor(
        public tenantModel: AzureTenantModel) {
    }
}

export class TenantControllerInsertGciTenantRequest {
    constructor(
        public rawId: string,
        public serviceAccountMail: string,
        public userMail: string) {
    }
}

export class TenantControllerInsertGciTenantResponse {
    constructor(
        public tenantModel: GciTenantModel) {
    }
}

export class TenantControllerInsertGcpTenantRequest {
    constructor(
        public gciTenantId: string,
        public parentFolderId: string,
        public permissionTypes: CloudProviderTenantPermissionType[],
        public rawShortNameId: string,
        public sinkPubSubSubscriptionRawId: Optional<string>) {
    }
}

export class TenantControllerInsertGcpTenantResponse {
    constructor(
        public tenantModel: GcpTenantModel) {
    }
}

export class TenantControllerInsertOktaTenantRequest {
    constructor(
        public name: string,
        public url: string,
        public userToken: string) {
    }
}

export class TenantControllerInsertOktaTenantResponse {
    constructor(
        public tenantModel: OktaTenantModel) {
    }
}

export class TenantControllerInsertOneLoginTenantRequest {
    constructor(
        public clientData: OneLoginClientData,
        public name: string,
        public permissionManagementClientData: Optional<OneLoginClientData>,
        public url: string) {
    }
}

export class TenantControllerInsertOneLoginTenantResponse {
    constructor(
        public tenantModel: OneLoginTenantModel) {
    }
}

export class TenantControllerInsertOpTenantRequest {
    constructor(
        public name: string,
        public parentFolderId: string) {
    }
}

export class TenantControllerInsertOpTenantResponse {
    constructor(
        public tenantModel: OpTenantModel) {
    }
}

export class TenantControllerInsertPingIdentityTenantRequest {
    constructor(
        public clientId: string,
        public clientSecret: string,
        public name: string,
        public rawId: string,
        public region: PingIdentityClientRegion) {
    }
}

export class TenantControllerInsertPingIdentityTenantResponse {
    constructor(
        public tenantModel: PingIdentityTenantModel) {
    }
}

export class TenantControllerUpdateAadTenantRequest {
    constructor(
        public applicationRawId: string,
        public applicationSecret: string,
        public id: string) {
    }
}

export class TenantControllerUpdateAadTenantResponse {
    constructor(
        public tenantModel: AadTenantModel) {
    }
}

export class TenantControllerUpdateAwsTenantRequest {
    constructor(
        public name: string,
        public permissionTypes: Optional<CloudProviderTenantPermissionType[]>,
        public regionSystemNames: Optional<string[]>,
        public roleArn: string,
        public trailArns: Optional<string[]>,
        public trailBucket: Optional<AwsTenantConfigurationTrailBucket>) {
    }
}

export class TenantControllerUpdateAwsTenantResponse {
    constructor(
        public tenantModel: AwsTenantModel) {
    }
}

export class TenantControllerUpdateAzureTenantRequest {
    constructor(
        public permissionTypes: CloudProviderTenantPermissionType[],
        public tenantId: string) {
    }
}

export class TenantControllerUpdateAzureTenantResponse {
    constructor(
        public tenantModel: AzureTenantModel) {
    }
}

export class TenantControllerUpdateGciTenantRequest {
    constructor(
        public id: string,
        public serviceAccountMail: string,
        public userMail: string) {
    }
}

export class TenantControllerUpdateGciTenantResponse {
    constructor(
        public tenantModel: GciTenantModel) {
    }
}

export class TenantControllerUpdateGcpTenantRequest {
    constructor(
        public id: string,
        public permissionTypes: CloudProviderTenantPermissionType[],
        public sinkPubSubSubscriptionRawId: Optional<string>) {
    }
}

export class TenantControllerUpdateGcpTenantResponse {
    constructor(
        public gciTenantModel: Optional<GciTenantModel>,
        public tenantModel: Optional<GcpTenantModel>) {
    }
}

export class TenantControllerUpdateOktaTenantRequest {
    constructor(
        public id: string,
        public name: string,
        public url: string,
        public userToken: Optional<string>) {
    }
}

export class TenantControllerUpdateOktaTenantResponse {
    constructor(
        public tenantModel: OktaTenantModel) {
    }
}

export class TenantControllerUpdateOneLoginTenantRequest {
    constructor(
        public clientData: Optional<TenantControllerUpdateOneLoginTenantRequestClientData>,
        public id: string,
        public name: Optional<string>,
        public permissionManagementClientData: Optional<TenantControllerUpdateOneLoginTenantRequestClientData>,
        public url: Optional<string>) {
    }
}

export class TenantControllerUpdateOneLoginTenantRequestClientData {
    constructor(
        public id: string,
        public secret: Optional<string>) {
    }
}

export class TenantControllerUpdateOneLoginTenantResponse {
    constructor(
        public tenantModel: OneLoginTenantModel) {
    }
}

export class TenantControllerUpdateOpTenantRequest {
    constructor(
        public id: string,
        public name: string,
        public parentFolderId: string) {
    }
}

export class TenantControllerUpdateOpTenantResponse {
    constructor(
        public tenantModel: OpTenantModel) {
    }
}

export class TenantControllerUpdatePingIdentityTenantRequest {
    constructor(
        public clientId: Optional<string>,
        public clientSecret: Optional<string>,
        public id: string,
        public name: Optional<string>) {
    }
}

export class TenantControllerUpdatePingIdentityTenantResponse {
    constructor(
        public tenantModel: PingIdentityTenantModel) {
    }
}

export class TenantControllerValidateAwsAccessKeyRequest {
    constructor(
        public accessKey: AwsIamAccessKey,
        public store: boolean,
        public tenantId: string) {
    }
}

export class TenantControllerValidateAwsAccessKeyResponse {
    constructor(
        public valid: boolean) {
    }
}

export class TenantControllerValidateAwsTenantRequest {
    constructor(
        public parentFolderId: string,
        public permissionTypes: CloudProviderTenantPermissionType[],
        public regionSystemNames: Optional<string[]>,
        public roleArn: string,
        public trailBucket: Optional<AwsTenantConfigurationTrailBucket>) {
    }
}

export class TenantControllerValidateAwsTenantResponse {
    constructor(
        public failedRegionSystemNames: Optional<string[]>,
        public readOnlyPolicyDocumentAccessDeniedErrorMessage: Optional<string>,
        public readOnlyPolicyDocumentMissingActions: Optional<string[]>,
        public result: AwsTenantManagerValidateTenantResult,
        public trailsAccessDeniedErrorMessage: Optional<string>,
        public validRegionSystemNames: string[],
        public validTrails: Optional<AwsCloudTrailTrail[]>) {
    }
}

export class TenantControllerValidateAwsTenantTrailBucketRequest {
    constructor(
        public parentFolderId: string,
        public regionSystemNames: Optional<string[]>,
        public tenantRoleArn: string,
        public trailArn: string) {
    }
}

export class TenantControllerValidateAwsTenantTrailBucketResponse {
    constructor(
        public result: AwsTenantManagerValidateTenantTrailBucketResult,
        public trailBucketName: Optional<string>,
        public trailBucketTenantId: Optional<string>) {
    }
}

export class TenantControllerValidateGciTenantRequest {
    constructor(
        public rawId: string,
        public serviceAccountMail: string,
        public userMail: string) {
    }
}

export class TenantControllerValidateGciTenantResponse {
    constructor(
        public name: Optional<string>,
        public primaryDomainName: Optional<string>,
        public result: TenantControllerValidateGciTenantResult,
        public serviceAccountUserDelegationMissingAuthorizationScopes: Optional<string[]>) {
    }
}

export class TenantControllerValidateGcpAccessTokenRequest {
    constructor(
        public accessToken: string,
        public store: boolean,
        public tenantIds: string[]) {
    }
}

export class TenantControllerValidateGcpAccessTokenResponse {
    constructor(
        public valid: boolean) {
    }
}

export class TenantControllerValidateGcpOrganizationRequest {
    constructor(
        public gciTenantId: string,
        public parentFolderId: string) {
    }
}

export class TenantControllerValidateGcpOrganizationResponse {
    constructor(
        public result: GcpTenantManagerValidateOrganizationResult,
        public serviceAccountMissingRoleNames: Optional<string[]>,
        public serviceAccountMissingTenantRoleNames: Optional<string[]>,
        public serviceAccountUnauthorizedErrorMessage: Optional<string>) {
    }
}

export class TenantControllerValidateGcpTenantRequest {
    constructor(
        public gciTenantId: string,
        public parentFolderId: string,
        public permissionTypes: CloudProviderTenantPermissionType[],
        public rawShortNameId: string) {
    }
}

export class TenantControllerValidateGcpTenantResponse {
    constructor(
        public result: GcpTenantManagerValidateTenantResult,
        public serviceAccountMissingOrganizationRoleNames: Optional<string[]>,
        public serviceAccountMissingRoleNames: Optional<string[]>,
        public serviceAccountUnauthorizedErrorMessage: Optional<string>) {
    }
}

export class TenantControllerValidateOneLoginTenantRequest {
    constructor(
        public clientId: string,
        public clientSecret: Optional<string>,
        public id: Optional<string>,
        public url: string) {
    }
}

export class TenantControllerValidateOneLoginTenantResponse {
    constructor(
        public accountRawId: Optional<string>,
        public result: TenantControllerValidateOneLoginTenantResult) {
    }
}

export class TenantControllerValidateStoredAwsAccessKeyRequest {
    constructor(
        public tenantId: string) {
    }
}

export class TenantControllerValidateStoredAwsAccessKeyResponse {
    constructor(
        public uniqueId: Optional<string>) {
    }
}

export class TenantControllerValidateStoredGcpAccessTokenRequest {
    constructor(
        public tenantIds: string[]) {
    }
}

export class TenantControllerValidateStoredGcpAccessTokenResponse {
    constructor(
        public stored: boolean) {
    }
}

export class TenantDocument extends Document implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        public tenantId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName);
    }
}

export class TenantModel extends ScopeModel {
    constructor(
        public configuration: TenantConfiguration,
        public state: TenantState,
        public statusSeverity: Optional<Severity>,
        public tenantType: TenantType) {
        super(
            configuration,
            state);
    }
}

export class TenantState extends SystemEntityState implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        public monitoring: TenantStateMonitoring,
        public orchestration: TenantStateOrchestration) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName);
    }
}

export class TenantStateMonitoring extends Subdocument implements ITypeNameObject {
    constructor(
        typeName: string,
        public analysisAccessDeniedErrorMessage: Optional<string>,
        public eventAnalysisAccessDeniedErrorMessage: Optional<string>,
        public systemEnabled: boolean,
        public time: string) {
        super(typeName);
    }
}

export class TenantStateOrchestration {
    constructor(
        public orchestrationTypeToDataMap: Dictionary<TenantStateOrchestrationData>) {
    }
}

export class TenantStateOrchestrationData {
    constructor(
        public disabled: boolean,
        public previousTimeRange: Optional<TimeRange>) {
    }
}

export class TextBlock {
    constructor(
        public highlightedLines: Optional<number[]>,
        public startLine: Optional<number>,
        public text: string) {
    }
}

export class TextIssue {
    constructor(
        public columnRange: IntRange,
        public lineRange: IntRange,
        public message: string,
        public severity: Severity) {
    }
}

export class TicketingServiceControllerGetJiraCustomLabelsFieldAutocompleteSuggestionsRequest {
    constructor(
        public customFieldId: number,
        public fieldName: string,
        public instanceId: string,
        public query: string) {
    }
}

export class TicketingServiceControllerGetJiraCustomLabelsFieldAutoCompleteSuggestionsResponse {
    constructor(
        public suggestions: JiraAutoCompleteSuggestion[]) {
    }
}

export class TicketingServiceControllerGetJiraProjectRequest {
    constructor(
        public id: string,
        public instanceId: string) {
    }
}

export class TicketingServiceControllerGetJiraProjectResponse {
    constructor(
        public epics: JiraEpic[],
        public issueTypeNames: string[],
        public issueTypeNameToAdditionalMandatoryFieldsMap: Dictionary<JiraField[]>,
        public issueTypeNameToFieldsMap: Dictionary<JiraField[]>,
        public sprints: JiraSprint[]) {
    }
}

export class TicketingServiceControllerGetServiceNowInstanceRequest {
    constructor(
        public id: string) {
    }
}

export class TicketingServiceControllerGetServiceNowInstanceResponse {
    constructor(
        public additionalMandatoryFields: ServiceNowField[],
        public groupRawIdToUserRawIdsMap: Dictionary<string[]>,
        public groups: ServiceNowGroup[],
        public users: ServiceNowUser[]) {
    }
}

export class TicketingServiceControllerGetServiceNowSeverityImpactAndUrgencyRequest {
    constructor(
        public severity: Severity) {
    }
}

export class TicketingServiceControllerGetServiceNowSeverityImpactAndUrgencyResponse {
    constructor(
        public impact: ServiceNowIncidentImpact,
        public urgency: ServiceNowIncidentUrgency) {
    }
}

export class TicketingServiceControllerGetTicketsRequest {
    constructor(
        public ids: string[]) {
    }
}

export class TicketingServiceControllerGetTicketsResponse {
    constructor(
        public tickets: TicketingServiceTicket[]) {
    }
}

export class TicketingServiceControllerSearchJiraProjectUsersRequest {
    constructor(
        public instanceId: string,
        public projectRawId: string,
        public searchText: Optional<string>,
        public userRawId: Optional<string>) {
    }
}

export class TicketingServiceControllerSearchJiraProjectUsersResponse {
    constructor(
        public users: JiraUser[]) {
    }
}

export class TicketingServiceControllerTryGetJiraIssueInfoRequest {
    constructor(
        public jiraIssueId: string,
        public riskId: string) {
    }
}

export class TicketingServiceControllerTryGetJiraIssueInfoResponse {
    constructor(
        public issueInfo: Optional<JiraIssueInfo>) {
    }
}

export class TicketingServiceControllerTryGetServiceNowIncidentInfoRequest {
    constructor(
        public incidentId: string,
        public riskId: string) {
    }
}

export class TicketingServiceControllerTryGetServiceNowIncidentInfoResponse {
    constructor(
        public incidentInfo: Optional<ServiceNowIncidentInfo>) {
    }
}

export class TicketingServiceTicket extends SystemEntity implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        public automationRuleDeliveryDestinationReference: Optional<AutomationRuleDeliveryDestinationReference>,
        public multipleRisks: boolean,
        public upsertTime: string,
        public url: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName);
    }
}

export class TiFile extends Document implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        public analysis: Optional<TiFileAnalysis>,
        public scan: TiFileScan,
        public virusTotalData: Optional<TiFileVirusTotalData>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "TiFile");
    }
}

export class TiFileAnalysis {
    constructor(
        public generic: boolean,
        public malicious: boolean,
        public maliciousCount: number,
        public trusted: boolean) {
    }
}

export class TiFileContentAnalysisResult {
    constructor(
        public category: TiFileContentAnalysisResultCategory,
        public rawResult: Optional<string>) {
    }
}

export class TiFileContentKnownDistributors {
    constructor(
        public dataSources: string[],
        public distributors: string[],
        public fileNames: string[],
        public links: string[],
        public products: string[]) {
    }
}

export class TiFileModel {
    constructor(
        public tiFile: TiFile) {
    }
}

export class TiFileScan {
    constructor(
        public contentEntropy: number,
        public contentMd5String: string,
        public contentSha1String: string,
        public contentSha256String: string,
        public size: number) {
    }
}

export class TiFileVirusTotalData {
    constructor(
        public capabilitiesTags: string[],
        public creationTime: Optional<string>,
        public engineNameToAnalysisResultMap: Dictionary<TiFileContentAnalysisResult>,
        public extension: Optional<string>,
        public firstSeenInTheWildTime: Optional<string>,
        public firstSubmissionTime: string,
        public knownDistributors: Optional<TiFileContentKnownDistributors>,
        public lastAnalysisTime: string,
        public lastModificationTime: string,
        public lastSubmissionTime: string,
        public magic: Optional<string>,
        public md5String: string,
        public names: string[],
        public sha1String: string,
        public sha256String: string,
        public signed: boolean,
        public size: number,
        public tags: string[],
        public type: Optional<string>,
        public typeTags: string[]) {
    }
}

export class TimeRange {
    constructor(
        public endTime: string,
        public startTime: string) {
    }
}

export class TimeRangeSelection {
    constructor(
        public endTime: Optional<string>,
        public relative: Optional<TimeRangeSelectionRelative>,
        public startTime: Optional<string>,
        public type: TimeRangeSelectionType) {
    }
}

export class TimeRangeSelectionRelative {
    constructor(
        public time: Optional<string>,
        public unit: TimeRangeSelectionRelativeUnit,
        public value: number) {
    }
}

export class Trace {
    constructor(
        public cpu: boolean,
        public memory: boolean) {
    }
}

export class TvdbState extends SystemEntityState implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        public syncedEpssHistoryObjectKey: Optional<string>,
        public syncedTviObjectKey: Optional<string>,
        public syncedVprHistoryObjectKey: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "TvdbState");
    }
}

export class UdmControllerGetObjectItemsPageRequest {
    constructor(
        public limit: number,
        public query: UdmQuery,
        public skip: number) {
    }
}

export class UdmControllerGetObjectTypeMetadatasModelsResponse {
    constructor(
        public objectTypeMetadataModels: ObjectTypeMetadataModel[]) {
    }
}

export class UdmControllerGetPropertyValuePageRequest {
    constructor(
        public limit: number,
        public objectTypeName: string,
        public propertyId: UdmObjectPropertyId,
        public searchText: Optional<string>,
        public skip: number) {
    }
}

export class UdmControllerGetPropertyValuePageResponse {
    constructor(
        public hasMore: boolean,
        public values: any[]) {
    }
}

export class UdmControllerObjectItemsPageResponse {
    constructor(
        public count: Optional<number>,
        public hasMore: boolean,
        public itemsList: Optional<any>[][]) {
    }
}

export class UdmObjectProperty {
    constructor(
        public collection: boolean,
        public dataType: UdmObjectPropertyDataType,
        public id: UdmObjectPropertyId,
        public options: UdmObjectPropertyOptions,
        public relation: Optional<UdmObjectPropertyRelation>,
        public values: Optional<string[]>) {
    }
}

export class UdmObjectPropertyOptions {
    constructor(
        public filterOnly: boolean,
        public priorityIndex: Optional<number>,
        public sortable: boolean) {
    }
}

export class UdmObjectPropertyRelation {
    constructor(
        public objectType: UdmObjectType,
        public objectTypeName: string) {
    }
}

export class UdmQueryBase {
    constructor(
        public join: Optional<UdmQueryJoin>,
        public options: Optional<UdmQueryOptions>,
        public ruleGroup: UdmQueryRuleGroup) {
    }
}

export class UdmQueryJoin extends UdmQueryBase {
    constructor(
        join: Optional<UdmQueryJoin>,
        options: Optional<UdmQueryOptions>,
        ruleGroup: UdmQueryRuleGroup,
        public propertyId: UdmObjectPropertyId) {
        super(
            join,
            options,
            ruleGroup);
    }
}

export class UdmQueryOptions {
    constructor(
        public propertyIds: UdmObjectPropertyId[],
        public sort: Optional<UdmQueryOptionsSort>) {
    }
}

export class UdmQueryOptionsSort {
    constructor(
        public direction: SortDirection,
        public propertyId: UdmObjectPropertyId) {
    }
}

export class UdmQueryRuleBase implements ITypeNameObject {
    constructor(
        public typeName: string) {
    }
}

export class UdmQueryRuleGroup extends UdmQueryRuleBase implements ITypeNameObject {
    constructor(
        typeName: string,
        public name: string,
        public not: boolean,
        public operator: UdmQueryRuleGroupOperator,
        public rules: UdmQueryRuleBase[]) {
        super(typeName);
    }
}

export class UrlBuilderConfigurationSection extends ConfigurationSection {
    constructor(
        public url: string) {
        super();
    }
}

export class UserState extends SystemEntityState implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        public scopeIdToRolesMap: Dictionary<IdentityRole[]>,
        public signInTime: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "UserState");
    }
}

export class VirtualMachineScopeConfiguration {
    constructor(
        public enabled: Optional<boolean>,
        public exclusionTags: ResourceTag[],
        public inclusionTag: Optional<ResourceTag>) {
    }
}

export class VirusTotalManagerConfigurationSection extends ConfigurationSection {
    constructor(
        public enabled: boolean) {
        super();
    }
}

export class Vulnerability extends Document implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        public activeSecurityWatchTypes: Optional<VulnerabilitySecurityWatchType[]>,
        public attackComplexity: Optional<VulnerabilityAttackComplexity>,
        public attackVector: Optional<VulnerabilityAttackVector>,
        public cisaKevData: Optional<VulnerabilityCisaKevData>,
        public creationTime: Optional<string>,
        public cvssScore: Optional<number>,
        public cvssSeverity: Optional<Severity>,
        public deprecated: boolean,
        public description: Optional<string>,
        public epssScore: Optional<number>,
        public epssScoreDatas: VulnerabilityEpssScoreData[],
        public epssScoreFirstUpdateTime: Optional<string>,
        public exploitable: boolean,
        public exploitMaturity: Optional<VulnerabilityExploitMaturity>,
        public name: Optional<string>,
        public proofOfConceptAvailable: Optional<boolean>,
        public rawId: string,
        public references: VulnerabilityReference[],
        public remotelyExploitable: boolean,
        public sourceDatas: VulnerabilitySourceData[],
        public timeline: VulnerabilityTimeline,
        public tviRawId: Optional<string>,
        public updateTime: Optional<string>,
        public userInteraction: Optional<boolean>,
        public vprMetrics: Optional<VulnerabilityVprMetrics>,
        public vprScore: Optional<number>,
        public vprScoreDatas: VulnerabilityVprScoreData[],
        public vprScoreFirstUpdateTime: Optional<string>,
        public vprSeverity: Optional<Severity>,
        public workloadAnalysis: boolean,
        public zeroDay: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "Vulnerability");
    }
}

export class VulnerabilityCisaKevData {
    constructor(
        public additionDate: string,
        public dueDate: string) {
    }
}

export class VulnerabilityControllerGetVulnerabilityModelsRequest {
    constructor(
        public rawIds: string[]) {
    }
}

export class VulnerabilityControllerGetVulnerabilityModelsResponse {
    constructor(
        public vulnerabilityModels: VulnerabilityModel[]) {
    }
}

export class VulnerabilityData {
    constructor(
        public rawId: string,
        public severity: Severity) {
    }
}

export class VulnerabilityEpssScoreData {
    constructor(
        public score: number,
        public time: string) {
    }
}

export class VulnerabilityModel {
    constructor(
        public severity: Severity,
        public tvdb: boolean,
        public vulnerability: Vulnerability) {
    }
}

export class VulnerabilityReference {
    constructor(
        public authority: Optional<VulnerabilityReferenceAuthority>,
        public publicationDate: Optional<string>,
        public tagDatas: VulnerabilityReferenceTagData[],
        public url: string) {
    }
}

export class VulnerabilityReferenceTagData {
    constructor(
        public parameter: Optional<string>,
        public tag: VulnerabilityReferenceTag) {
    }
}

export class VulnerabilitySourceData {
    constructor(
        public score: number,
        public scoreType: CvssVersion,
        public scoreVector: Optional<string>,
        public severity: Severity,
        public sourceType: VulnerabilitySourceType) {
    }
}

export class VulnerabilityTimeline {
    constructor(
        public creationDate: Optional<string>,
        public discoveryDate: Optional<string>,
        public functionalExploitDate: Optional<string>,
        public proofOfConceptDate: Optional<string>,
        public tenableCoverageDate: Optional<string>,
        public updateDate: Optional<string>) {
    }
}

export class VulnerabilityVprMetrics {
    constructor(
        public age: VulnerabilityAge,
        public cvss3ImpactScore: number,
        public exploitMaturity: VulnerabilityExploitMaturity,
        public last28DaysThreatIntensity: VulnerabilityThreatIntensityLast28Days,
        public last28DaysThreatSources: VulnerabilityThreatSourcesLast28Days[],
        public productCoverage: VulnerabilityProductCoverage,
        public threatRecency: VulnerabilityThreatRecency) {
    }
}

export class VulnerabilityVprScoreData {
    constructor(
        public score: number,
        public time: string) {
    }
}

export class WebhookEndpointConfigurationHttpHeader {
    constructor(
        public encrypted: boolean,
        public id: string,
        public name: string,
        public value: Optional<string>) {
    }
}

export class WebhookEndpointState extends SystemEntityState implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        public errorMessage: Optional<string>,
        public failed: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "WebhookEndpointState");
    }
}

export class WebhookManagerHttpHeader {
    constructor(
        public encrypted: boolean,
        public id: Optional<string>,
        public name: string,
        public value: Optional<string>) {
    }
}

export class WorkloadAnalysisFileModel {
    constructor(
        public contentSha256String: string,
        public firstScanTime: Optional<string>,
        public operatingSystemType: OperatingSystemType,
        public pathCount: number,
        public sortModelRawId: string,
        public tenantCount: number,
        public tenantTopIds: string[],
        public topPaths: string[],
        public types: WorkloadResourceScanFileType[],
        public workloadResourceCount: number,
        public workloadResourceTopIds: string[]) {
    }
}

export class WorkloadAnalysisPackageModel {
    constructor(
        public displayName: string,
        public filePathCount: number,
        public identifier: string,
        public operatingSystemType: OperatingSystemType,
        public tenantCount: number,
        public tenantTopIds: string[],
        public topFilePaths: string[],
        public topVulnerabilities: VulnerabilityData[],
        public types: WorkloadResourceScanPackageType[],
        public vulnerabilityCount: number,
        public vulnerabilityResolutionVersions: Optional<string[]>,
        public workloadResourceCount: number,
        public workloadResourceTopIds: string[]) {
    }
}

export class WorkloadAnalysisPackageVulnerabilityModel {
    constructor(
        public attackVector: Optional<VulnerabilityAttackVector>,
        public cvssScore: number,
        public cvssSeverity: Optional<Severity>,
        public epssScore: Optional<number>,
        public exploitable: boolean,
        public firstScanTime: Optional<string>,
        public name: Optional<string>,
        public operatingSystemType: OperatingSystemType,
        public packageDisplayNames: string[],
        public packageFilePathCount: number,
        public packageTopFilePaths: string[],
        public packageType: WorkloadResourceScanPackageType,
        public resolutionTime: Optional<string>,
        public resolutionVersions: string[],
        public severity: Optional<Severity>,
        public sortIdentifier: string,
        public tenantCount: number,
        public tenantTopIds: string[],
        public vprScore: Optional<number>,
        public vprSeverity: Optional<Severity>,
        public vulnerabilityRawId: string,
        public workloadResourceCount: number,
        public workloadResourceTopIds: string[]) {
    }
}

export class WorkloadAnalysisVulnerabilityModel {
    constructor(
        public exploitable: boolean,
        public firstScanTime: Optional<string>,
        public rawId: string,
        public workloadResourceCount: number) {
    }
}

export class WorkloadControllerGetMaliciousFileModelCountResponse {
    constructor(
        public maliciousFileModelCount: number) {
    }
}

export class WorkloadControllerGetMaliciousFileModelPageRequest {
    constructor(
        public filters: WorkloadControllerMaliciousFileModelsFilters,
        public limit: number,
        public nextPageAggregationCursor: Optional<ElasticsearchIndexAggregationCursor>) {
    }
}

export class WorkloadControllerGetMaliciousFileModelPageResponse {
    constructor(
        public maliciousFileModelPage: ElasticsearchItemPage<WorkloadAnalysisFileModel>) {
    }
}

export class WorkloadControllerGetPackageModelCountResponse {
    constructor(
        public packageModelCount: number) {
    }
}

export class WorkloadControllerGetPackageModelPageRequest {
    constructor(
        public filters: WorkloadControllerPackageModelsFilters,
        public includeVulnerabilityResolutionVersions: boolean,
        public limit: number,
        public nextPageAggregationCursor: Optional<ElasticsearchIndexAggregationCursor>) {
    }
}

export class WorkloadControllerGetPackageModelPageResponse {
    constructor(
        public packageModelPage: ElasticsearchItemPage<WorkloadAnalysisPackageModel>) {
    }
}

export class WorkloadControllerGetPackageVulnerabilityModelCountResponse {
    constructor(
        public packageVulnerabilityModelCount: number) {
    }
}

export class WorkloadControllerGetPackageVulnerabilityModelPageRequest {
    constructor(
        public filters: WorkloadControllerPackageVulnerabilityModelsFilters,
        public limit: number,
        public nextPageAggregationCursor: Optional<ElasticsearchIndexAggregationCursor>) {
    }
}

export class WorkloadControllerGetPackageVulnerabilityModelPageResponse {
    constructor(
        public packageVulnerabilityModelPage: ElasticsearchItemPage<WorkloadAnalysisPackageVulnerabilityModel>) {
    }
}

export class WorkloadControllerGetWorkloadFileFilterScanItemPageResponseBase {
    constructor() {
    }
}

export class WorkloadControllerGetWorkloadPackageFilterScanItemPageResponseBase {
    constructor() {
    }
}

export class WorkloadControllerGetWorkloadPackageScanFiltersRequest {
    constructor(
        public vulnerabilityRawId: Optional<string>) {
    }
}

export class WorkloadControllerGetWorkloadPackageScanFiltersResponse {
    constructor(
        public filters: WorkloadResourceScanPackageFilters) {
    }
}

export class WorkloadControllerGetWorkloadResourceFilterScanItemPageResponseBase {
    constructor() {
    }
}

export class WorkloadControllerGetWorkloadResourceModelPageRequest {
    constructor(
        public exportData: boolean,
        public filters: WorkloadControllerWorkloadResourceModelFilters,
        public limit: number,
        public nextPageSearchCursor: Optional<ElasticsearchIndexSearchCursor>,
        public sort: WorkloadControllerWorkloadResourceModelSort) {
    }
}

export class WorkloadControllerGetWorkloadResourceModelPageResponse {
    constructor(
        public workloadResourceModelPage: ElasticsearchItemPage<WorkloadResourceModel>) {
    }
}

export class WorkloadControllerGetWorkloadResourceModelPageVirtualMachineImageRequest extends WorkloadControllerGetWorkloadResourceModelPageRequest {
    constructor(
        exportData: boolean,
        filters: WorkloadControllerWorkloadResourceModelFilters,
        limit: number,
        nextPageSearchCursor: Optional<ElasticsearchIndexSearchCursor>,
        sort: WorkloadControllerWorkloadResourceModelSort) {
        super(
            exportData,
            filters,
            limit,
            nextPageSearchCursor,
            sort);
    }
}

export class WorkloadControllerGetWorkloadResourceModelPageVirtualMachineRequest extends WorkloadControllerGetWorkloadResourceModelPageRequest {
    constructor(
        exportData: boolean,
        filters: WorkloadControllerWorkloadResourceModelFilters,
        limit: number,
        nextPageSearchCursor: Optional<ElasticsearchIndexSearchCursor>,
        sort: WorkloadControllerWorkloadResourceModelSort) {
        super(
            exportData,
            filters,
            limit,
            nextPageSearchCursor,
            sort);
    }
}

export class WorkloadControllerGetWorkloadResourceRiskVulnerabilityRawIdsRequest {
    constructor(
        public riskId: string,
        public severity: Optional<Severity>) {
    }
}

export class WorkloadControllerGetWorkloadResourceRiskVulnerabilityRawIdsResponse {
    constructor(
        public vulnerabilitySeverityToRawIdsMap: Dictionary<string[]>) {
    }
}

export class WorkloadControllerGetWorkloadResourceScanFileFilterItemPageRequest {
    constructor(
        public limit: number,
        public property: WorkloadControllerRequestProperty,
        public searchText: Optional<string>,
        public skip: number,
        public workloadResourceIds: Optional<string[]>) {
    }
}

export class WorkloadControllerGetWorkloadResourceScanFileFiltersResponse {
    constructor(
        public filters: WorkloadResourceScanFileFilters) {
    }
}

export class WorkloadControllerGetWorkloadResourceScanFilterItemPageRequest {
    constructor(
        public limit: number,
        public property: WorkloadControllerRequestProperty,
        public searchText: Optional<string>,
        public skip: number,
        public vulnerabilityRawId: Optional<string>,
        public workloadResourceType: Optional<WorkloadAnalysisWorkloadResourceType>) {
    }
}

export class WorkloadControllerGetWorkloadResourceScanFiltersRequest {
    constructor(
        public vulnerabilityRawId: Optional<string>) {
    }
}

export class WorkloadControllerGetWorkloadResourceScanFiltersResponse<TFilters extends WorkloadResourceScanFilters> {
    constructor(
        public filters: TFilters) {
    }
}

export class WorkloadControllerGetWorkloadResourceScanPackageFilterItemPageRequest {
    constructor(
        public limit: number,
        public property: WorkloadControllerRequestProperty,
        public resolvedVulnerabilities: Optional<boolean>,
        public searchText: Optional<string>,
        public skip: number,
        public vulnerabilityRawId: Optional<string>,
        public vulnerableOnly: boolean,
        public workloadResourceIds: Optional<string[]>,
        public workloadResourceType: Optional<WorkloadAnalysisWorkloadResourceType>) {
    }
}

export class WorkloadControllerMaliciousFileModelPropertyItemsRequest {
    constructor(
        public filters: WorkloadControllerMaliciousFileModelsFilters,
        public property: WorkloadControllerRequestProperty,
        public sortModelRawId: string) {
    }
}

export class WorkloadControllerMaliciousFileModelPropertyItemsResponseBase {
    constructor() {
    }
}

export class WorkloadControllerPackageModelPropertyItemsRequest {
    constructor(
        public filters: WorkloadControllerPackageModelsFilters,
        public identifier: string,
        public property: WorkloadControllerRequestProperty) {
    }
}

export class WorkloadControllerPackageModelPropertyItemsResponseBase {
    constructor() {
    }
}

export class WorkloadControllerPackageVulnerabilityModelPropertyItemsRequest {
    constructor(
        public filters: WorkloadControllerPackageVulnerabilityModelsFilters,
        public property: WorkloadControllerRequestProperty,
        public sortIdentifier: string) {
    }
}

export class WorkloadControllerPackageVulnerabilityModelPropertyItemsResponseBase {
    constructor() {
    }
}

export class WorkloadControllerUpdateVirtualMachinePriorityScanRequestRequest {
    constructor(
        public virtualMachineId: string) {
    }
}

export class WorkloadControllerWorkloadAnalysisModelsFilters<T extends WorkloadResourceScanModel> extends Subdocument implements ITypeNameObject {
    constructor(
        typeName: string,
        public tenantIdSelection: Optional<ItemSelection<string>>,
        public workloadResourceIdSelection: Optional<PagedItemSelection<string>>,
        public workloadResourceTypeNameSelection: Optional<ItemSelection<string>>) {
        super(typeName);
    }
}

export class WorkloadControllerWorkloadResourceModelFilters extends Subdocument implements ITypeNameObject {
    constructor(
        typeName: string,
        public attributeValueSelection: Optional<ItemSelection<string>>,
        public exploitable: Optional<boolean>,
        public fixable: Optional<boolean>,
        public includeResolvedVulnerabilities: boolean,
        public operatingSystemDisplayNameSelection: Optional<PagedItemSelection<string>>,
        public operatingSystemTypeSelection: Optional<ItemSelection<OperatingSystemType>>,
        public packageDisplayNameSelection: Optional<PagedItemSelection<string>>,
        public riskSeveritySelection: Optional<ItemSelection<Severity>>,
        public scanTimeRangeSelection: Optional<TimeRangeSelection>,
        public tenantIdSelection: Optional<ItemSelection<string>>,
        public vulnerabilityAttackVectorSelection: Optional<ItemSelection<VulnerabilityAttackVector>>,
        public vulnerabilityRawIdSelection: Optional<PagedItemSelection<string>>,
        public vulnerabilitySeveritySelection: Optional<ItemSelection<Severity>>,
        public workloadResourceIdsSelection: Optional<PagedItemSelection<string>>,
        public workloadResourceTypeNameSelection: Optional<ItemSelection<string>>) {
        super(typeName);
    }
}

export class WorkloadControllerWorkloadResourceModelPropertyItemsRequest {
    constructor(
        public property: WorkloadControllerRequestProperty,
        public workloadResourceModelId: string) {
    }
}

export class WorkloadControllerWorkloadResourceModelPropertyItemsResponseBase {
    constructor() {
    }
}

export class WorkloadControllerWorkloadResourceModelSort extends Subdocument implements ITypeNameObject {
    constructor(
        typeName: string,
        public direction: SortDirection,
        public property: WorkloadControllerRequestProperty) {
        super(typeName);
    }
}

export class WorkloadResourceModel {
    constructor(
        public attributes: EntityModelAttributes,
        public displayName: string,
        public displayReference: string,
        public id: string,
        public operatingSystemDisplayName: Optional<string>,
        public operatingSystemType: Optional<OperatingSystemType>,
        public packageCount: number,
        public packages: WorkloadResourceModelPackage[],
        public riskSeverityToIdsMap: Dictionary<string[]>,
        public scanTime: Optional<string>,
        public tenantId: string,
        public typeName: string,
        public vulnerabilities: VulnerabilityData[],
        public vulnerabilityCount: number,
        public vulnerabilityResolutionPatches: string[]) {
    }
}

export class WorkloadResourceModelPackage {
    constructor(
        public displayName: string,
        public filePath: Optional<string>,
        public name: string,
        public version: Optional<string>) {
    }
}

export class WorkloadResourceRiskTypeMetadata extends RiskTypeMetadata {
    constructor() {
        super();
    }
}

export class WorkloadResourceRiskVulnerability {
    constructor(
        public exploitable: boolean,
        public newestResolutionPatches: Optional<string[]>,
        public rawId: string,
        public severity: Severity) {
    }
}

export class WorkloadResourceScanFileFilters extends Summary {
    constructor(
        public firstScanTimeRange: FilterTimeRange,
        public tenantIdItems: FilterItems<string>,
        public workloadResourceTypeNameItems: FilterItems<string>) {
        super();
    }
}

export class WorkloadResourceScanFilters extends Summary {
    constructor(
        public attributeValueItems: FilterItems<string>,
        public scanTimeRange: FilterTimeRange,
        public tenantIdItems: FilterItems<string>,
        public workloadResourceTypeNameItems: FilterItems<string>) {
        super();
    }
}

export class WorkloadResourceScanModel extends TenantDocument implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        public entityId: string,
        public entitySearchableIdReference: EntitySearchableIdReference,
        public scopeIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId);
    }
}

export class WorkloadResourceScanOperatingSystem {
    constructor(
        public architecture: OperatingSystemArchitecture,
        public displayName: Optional<string>,
        public distribution: Optional<OperatingSystemDistribution>,
        public kernelVersion: Optional<string>,
        public supported: boolean,
        public type: OperatingSystemType,
        public version: Optional<string>) {
    }
}

export class WorkloadResourceScanPackageFilters extends Summary {
    constructor(
        public firstScanTimeRange: FilterTimeRange,
        public resolutionTimeRange: FilterTimeRange,
        public tenantIdItems: FilterItems<string>,
        public workloadResourceTypeNameItems: FilterItems<string>) {
        super();
    }
}

export class WorkloadResourceScanPackageModel extends WorkloadResourceScanModel implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        entityId: string,
        entitySearchableIdReference: EntitySearchableIdReference,
        scopeIds: string[],
        public containerImageUsage: Optional<boolean>,
        public displayName: string,
        public displayNameSearchableData: StringSearchableData,
        public filePaths: string[],
        public filePathSearchableDatas: StringSearchableData[],
        public identifier: string,
        public name: string,
        public operatingSystemType: OperatingSystemType,
        public sortPackageVulnerabilityIdentifier: Optional<string>,
        public type: WorkloadResourceScanPackageType,
        public version: Optional<string>,
        public vulnerability: Optional<WorkloadResourceScanPackageModelVulnerability>,
        public vulnerabilityExists: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "WorkloadResourceScanPackageModel",
            tenantId,
            entityId,
            entitySearchableIdReference,
            scopeIds);
    }
}

export class WorkloadResourceScanPackageModelVulnerability {
    constructor(
        public attackVector: Optional<VulnerabilityAttackVector>,
        public attackVectorValue: number,
        public cvssScore: number,
        public cvssSeverity: Optional<Severity>,
        public cvssSeverityValue: Optional<number>,
        public cvssVersion: Optional<CvssVersion>,
        public epssScore: Optional<number>,
        public exploitable: boolean,
        public firstAnalysisTime: string,
        public name: Optional<string>,
        public nameSearchableData: Optional<StringSearchableData>,
        public rawId: string,
        public rawIdSearchableData: StringSearchableData,
        public resolutionTime: Optional<string>,
        public resolutionVersions: string[],
        public resolutionVersionsExist: boolean,
        public resolved: boolean,
        public severity: Severity,
        public severityValue: number,
        public vprScore: Optional<number>,
        public vprSeverity: Optional<Severity>,
        public vprSeverityValue: Optional<number>) {
    }
}

export class WorkloadResourceVirtualMachineImageModel extends WorkloadResourceModel {
    constructor(
        attributes: EntityModelAttributes,
        displayName: string,
        displayReference: string,
        id: string,
        operatingSystemDisplayName: Optional<string>,
        operatingSystemType: Optional<OperatingSystemType>,
        packageCount: number,
        packages: WorkloadResourceModelPackage[],
        riskSeverityToIdsMap: Dictionary<string[]>,
        scanTime: Optional<string>,
        tenantId: string,
        typeName: string,
        vulnerabilities: VulnerabilityData[],
        vulnerabilityCount: number,
        vulnerabilityResolutionPatches: string[],
        public accessLevel: Optional<VirtualMachineImageAccessLevel>,
        public creationTime: Optional<string>,
        public managementType: Optional<VirtualMachineImageManagementType>,
        public rawId: string,
        public region: Optional<string>,
        public tags: ResourceTag[],
        public virtualMachineIds: string[],
        public workloadAnalysisError: Optional<WorkloadAnalysisError>,
        public workloadAnalysisStatus: WorkloadAnalysisStatus) {
        super(
            attributes,
            displayName,
            displayReference,
            id,
            operatingSystemDisplayName,
            operatingSystemType,
            packageCount,
            packages,
            riskSeverityToIdsMap,
            scanTime,
            tenantId,
            typeName,
            vulnerabilities,
            vulnerabilityCount,
            vulnerabilityResolutionPatches);
    }
}

export class WorkloadResourceVirtualMachineModel extends WorkloadResourceModel {
    constructor(
        attributes: EntityModelAttributes,
        displayName: string,
        displayReference: string,
        id: string,
        operatingSystemDisplayName: Optional<string>,
        operatingSystemType: Optional<OperatingSystemType>,
        packageCount: number,
        packages: WorkloadResourceModelPackage[],
        riskSeverityToIdsMap: Dictionary<string[]>,
        scanTime: Optional<string>,
        tenantId: string,
        typeName: string,
        vulnerabilities: VulnerabilityData[],
        vulnerabilityCount: number,
        vulnerabilityResolutionPatches: string[],
        public diskEncryptionVaultIdReference: Optional<string>,
        public identityPermissionActionSeverity: Optional<Severity>,
        public inboundExternalDestinationNetworkScopes: Optional<DestinationNetworkScope[]>,
        public kubernetesClusterSensor: boolean,
        public networkInboundAccessType: Optional<NetworkInboundAccessType>,
        public networkInboundExternalAccessScope: Optional<NetworkAccessScope>,
        public privateIpAddresses: string[],
        public publicIpAddresses: string[],
        public status: Optional<WorkloadResourceScanModelVirtualMachineStatus>,
        public workloadAnalysisError: Optional<WorkloadAnalysisError>,
        public workloadAnalysisStatus: WorkloadAnalysisStatus) {
        super(
            attributes,
            displayName,
            displayReference,
            id,
            operatingSystemDisplayName,
            operatingSystemType,
            packageCount,
            packages,
            riskSeverityToIdsMap,
            scanTime,
            tenantId,
            typeName,
            vulnerabilities,
            vulnerabilityCount,
            vulnerabilityResolutionPatches);
    }
}

export class WorkloadResourceVulnerabilityRiskMetadata extends WorkloadResourceRiskTypeMetadata {
    constructor() {
        super();
    }
}

export class AadEntityProfile extends EntityProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName);
    }
}

export class AadTenantAuthenticationProviderConfigurationSection extends ConfigurationSection implements INullableConfigurationSection {
    constructor(
        public partitionTypeToDataMap: Dictionary<AadTenantAuthenticationProviderConfigurationSectionPartitionData>) {
        super();
    }
}

export class AadTenantEntityProfile extends AadEntityProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AadTenantEntityProfile");
    }
}

export class AadTenantModel extends TenantModel {
    constructor(
        public configuration: AadTenantConfiguration,
        public state: AadTenantState,
        statusSeverity: Optional<Severity>,
        tenantType: TenantType,
        public status: AadTenantModelStatus) {
        super(
            configuration,
            state,
            statusSeverity,
            tenantType);
    }
}

export class AadTenantStateMonitoring extends TenantStateMonitoring implements ITypeNameObject {
    constructor(
        analysisAccessDeniedErrorMessage: Optional<string>,
        eventAnalysisAccessDeniedErrorMessage: Optional<string>,
        systemEnabled: boolean,
        time: string,
        public issue: Optional<AadTenantStateMonitoringIssue>) {
        super(
            "AadTenantStateMonitoring",
            analysisAccessDeniedErrorMessage,
            eventAnalysisAccessDeniedErrorMessage,
            systemEnabled,
            time);
    }
}

export class AccessControllerGetAzureIdentityDestinationTenantIdsRequest extends AccessControllerGetIdentityDestinationTenantIdsRequest {
    constructor(
        sourceEntityId: string) {
        super(sourceEntityId);
    }
}

export class AccessControllerGetGcpIdentityDestinationTenantIdsRequest extends AccessControllerGetIdentityDestinationTenantIdsRequest {
    constructor(
        sourceEntityId: string) {
        super(sourceEntityId);
    }
}

export class AccessControllerGetPrincipalExcessivePermissionFilterItemPageRequest extends AccessControllerGetPrincipalFilterItemPageRequest {
    constructor(
        limit: number,
        property: AccessControllerGetPrincipalModelPageRequestProperty,
        searchText: Optional<string>,
        skip: number,
        public riskCategory: PrincipalRiskCategory) {
        super(
            limit,
            property,
            searchText,
            skip);
    }
}

export class AccessControllerGetPrincipalExcessivePermissionModelRequestFilters extends AccessControllerGetPrincipalModelRequestFilters {
    constructor(
        actionRiskCategorySelection: Optional<ItemSelection<ActionRiskCategory>>,
        activityTimeRangeSelection: Optional<TimeRangeSelection>,
        attributeValueSelection: Optional<ItemSelection<string>>,
        awsPermissionSetAccountIdSelection: Optional<PagedItemSelection<string>>,
        awsPermissionSetPrincipalIdSelection: Optional<PagedItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        serviceIdentityOriginatorEntityIdSelection: Optional<PagedItemSelection<string>>,
        serviceIdentityOriginatorEntityTenantIdSelection: Optional<ItemSelection<string>>,
        serviceIdentityOriginatorEntityTypeNameSelection: Optional<ItemSelection<string>>,
        severitySelection: Optional<ItemSelection<Severity>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        typeNameSelection: Optional<ItemSelection<string>>,
        vendorSelection: Optional<ItemSelection<Vendor>>,
        public inactive: Optional<boolean>,
        public riskCategory: PrincipalRiskCategory,
        public ticketingServiceTypeSelection: Optional<ItemSelection<TicketingServiceType>>) {
        super(
            actionRiskCategorySelection,
            activityTimeRangeSelection,
            attributeValueSelection,
            awsPermissionSetAccountIdSelection,
            awsPermissionSetPrincipalIdSelection,
            idSelection,
            serviceIdentityOriginatorEntityIdSelection,
            serviceIdentityOriginatorEntityTenantIdSelection,
            serviceIdentityOriginatorEntityTypeNameSelection,
            severitySelection,
            tenantIdSelection,
            typeNameSelection,
            vendorSelection);
    }
}

export class AccessControllerGetPrincipalExcessivePermissionPropertyItemsRequest extends AccessControllerGetPrincipalPropertyItemsRequest {
    constructor(
        principalId: string,
        property: AccessControllerGetPrincipalModelPageRequestProperty) {
        super(
            principalId,
            property);
    }
}

export class AccessControllerGetPrincipalExcessivePermissionsModelPageRequest extends AccessControllerGetPrincipalModelPageRequest {
    constructor(
        filters: AccessControllerGetPrincipalModelRequestFilters,
        limit: number,
        skip: number,
        sort: AccessControllerGetPrincipalModelPageRequestSort) {
        super(
            filters,
            limit,
            skip,
            sort);
    }
}

export class AccessControllerGetPrincipalExcessivePermissionSummaryResponse extends AccessControllerGetPrincipalSummaryResponse {
    constructor(
        result: AccessControllerGetPrincipalSummaryResponseResult,
        public principalRiskCategoryToOpenRiskSeverityToCountMap: Dictionary<Dictionary<number>>) {
        super(result);
    }
}

export class AccessControllerGetPrincipalFilterItemPageResponse<TItem> extends AccessControllerGetPrincipalFilterItemPageResponseBase {
    constructor(
        public principalFilterItemPage: ElasticsearchFilterItemPage<TItem>) {
        super();
    }
}

export class AccessControllerGetPrincipalModelExcessivePermissionFiltersRequest extends AccessControllerGetPrincipalModelFiltersRequest {
    constructor(
        public riskCategory: PrincipalRiskCategory) {
        super();
    }
}

export class AccessControllerGetPrincipalPermissionPropertyItemsRequest extends AccessControllerGetPrincipalPropertyItemsRequest {
    constructor(
        principalId: string,
        property: AccessControllerGetPrincipalModelPageRequestProperty) {
        super(
            principalId,
            property);
    }
}

export class AccessControllerGetPrincipalPermissionSummaryResponse extends AccessControllerGetPrincipalSummaryResponse {
    constructor(
        result: AccessControllerGetPrincipalSummaryResponseResult,
        public principalTypeToActionSeverityToCountMap: Dictionary<Dictionary<number>>) {
        super(result);
    }
}

export class AccessControllerGetPrincipalPropertyEntitySearchableReferencesResponse extends AccessControllerGetPrincipalPropertyItemsResponse {
    constructor(
        public entitySearchableReferences: EntitySearchableReference[]) {
        super();
    }
}

export class AccessGraph extends Graph {
    constructor(
        edges: GraphEdge[],
        relatedEntityIds: string[],
        public destinationEntityGroups: AccessGraphDestinationEntityGroup[],
        public destinationResourceTenantIds: string[],
        public entitiesReferenceConsistentHashToEntityIdsMap: Dictionary<string[]>,
        public groups: AccessGraphGroup[],
        public identityGroups: AccessGraphIdentityGroup[],
        public originatorEntityGranteeEntities: AccessGraphOriginatorEntityGranteeEntity[],
        public originatorEntityGroups: AccessGraphOriginatorEntityGroup[],
        public permissionActions: string[],
        public permissionEdgeIdToFragmentedDataMap: Dictionary<AccessGraphPermissionEdgeFragmentedData>,
        public permissionPaths: AccessGraphPermissionPath[],
        public typeName: string,
        public variant: EntityAccessVariant) {
        super(
            edges,
            relatedEntityIds);
    }
}

export class AccessGraphEntitiesNode extends GraphNode {
    constructor(
        id: string,
        public entityTypeEntitiesViewName: string,
        public entityTypeNames: string[]) {
        super(id);
    }
}

export class AccessGraphGroup extends GraphNode {
    constructor(
        id: string,
        public groupId: string,
        public sinister: boolean) {
        super(id);
    }
}

export class AccessGraphIdentityGroup extends AccessGraphEntitiesNode {
    constructor(
        id: string,
        entityTypeEntitiesViewName: string,
        entityTypeNames: string[],
        public identityIds: string[]) {
        super(
            id,
            entityTypeEntitiesViewName,
            entityTypeNames);
    }
}

export class AccessGraphOriginatorEntityGranteeEntity extends GraphNode {
    constructor(
        id: string,
        public granteeEntityId: string) {
        super(id);
    }
}

export class AccessGraphOriginatorEntityGroup extends AccessGraphEntitiesNode {
    constructor(
        id: string,
        entityTypeEntitiesViewName: string,
        entityTypeNames: string[],
        public originatorEntityIds: string[]) {
        super(
            id,
            entityTypeEntitiesViewName,
            entityTypeNames);
    }
}

export class AccessGraphPermissionPath extends GraphNode {
    constructor(
        id: string,
        public permissionEdgeIdToPathActionsExcessivenessMap: Dictionary<AccessGraphPermissionPathActionsExcessiveness>) {
        super(id);
    }
}

export class AdministrationControllerGetOrchestrationStageDatasResponseCustomerOrchestrationStageData extends AdministrationControllerGetOrchestrationStageDatasResponseOrchestrationStageData {
    constructor(
        duration: string,
        id: string,
        name: string,
        status: AdministrationControllerOrchestrationStageStatus,
        type: AdministrationControllerOrchestrationStageType,
        public retryCount: number,
        public retryMaxCount: number,
        public retryTotalCount: number) {
        super(
            duration,
            id,
            name,
            status,
            type);
    }
}

export class AdministrationControllerGetOrchestrationStageDatasResponseRootStagesOrchestrationStageData extends AdministrationControllerGetOrchestrationStageDatasResponseStagesOrchestrationStageData {
    constructor(
        duration: string,
        id: string,
        name: string,
        status: AdministrationControllerOrchestrationStageStatus,
        type: AdministrationControllerOrchestrationStageType,
        childStageDatas: AdministrationControllerGetOrchestrationStageDatasResponseOrchestrationStageData[],
        public orchestrationId: Optional<string>) {
        super(
            duration,
            id,
            name,
            status,
            type,
            childStageDatas);
    }
}

export class AmplitudeConfigurationSection extends ConfigurationSection implements INullableConfigurationSection {
    constructor(
        public enabled: boolean) {
        super();
    }
}

export class ApiKeyState extends SystemEntityState implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        public usageTime: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "ApiKeyState");
    }
}

export class ApiUrlBuilderConfigurationSection extends UrlBuilderConfigurationSection {
    constructor(
        url: string) {
        super(url);
    }
}

export class ApplicationCustomerConfiguration extends SystemEntityConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "ApplicationCustomerConfiguration");
    }
}

export class ApplicationCustomerConfigurationLicensingEvaluationLicense extends ApplicationCustomerConfigurationLicensingLicense {
    constructor(
        expirationTime: Optional<string>,
        types: ApplicationCustomerConfigurationLicensingLicenseType[]) {
        super(
            expirationTime,
            types);
    }
}

export class AuditEvent extends ScopeDocument implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        public errorMessage: Optional<string>,
        public identityReference: IdentityReference,
        public visible: boolean,
        public visibleUpdateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId);
    }
}

export class AuditEventControllerGetAuditEventFiltersRequest extends AuditEventControllerGetAuditEventFiltersRequestBase {
    constructor() {
        super();
    }
}

export class AuditEventControllerGetAuditEventModelPageRequest extends AuditEventControllerGetAuditEventModelPageRequestBase {
    constructor(
        limit: number,
        nextPageSearchCursor: Optional<ElasticsearchIndexSearchCursor>,
        public filters: AuditEventControllerGetAuditEventModelPageRequestFilters,
        public sort: AuditEventControllerGetAuditEventModelPageRequestSort) {
        super(
            limit,
            nextPageSearchCursor);
    }
}

export class AuditEventControllerGetPermissionAuditEventFilterItemPageResponse<TItem> extends AuditEventControllerGetPermissionAuditEventFilterItemPageResponseBase {
    constructor(
        public permissionAuditEventFilterItemPage: ElasticsearchFilterItemPage<TItem>) {
        super();
    }
}

export class AuditEventFilters extends Summary {
    constructor(
        public identitySearchableReferenceItems: FilterItems<EntitySearchableReference>,
        public scopeIdItems: FilterItems<string>,
        public systemCreationTimeRange: FilterTimeRange,
        public typeNameItems: FilterItems<string>) {
        super();
    }
}

export class AuthenticationConfigurationSection extends ConfigurationSection {
    constructor(
        public cognito: Optional<AuthenticationConfigurationSectionCognito>,
        public customerAad: Optional<AuthenticationConfigurationSectionOidc>,
        public customerGci: Optional<AuthenticationConfigurationSectionOidc>,
        public customerTenable: AuthenticationConfigurationSectionSaml,
        public userAad: Optional<AuthenticationConfigurationSectionOidc>,
        public userGci: Optional<AuthenticationConfigurationSectionOidc>) {
        super();
    }
}

export class AuthorizationConfigurationSection extends ConfigurationSection {
    constructor(
        public apiKeyRawIdToPermissionsMap: Dictionary<IdentityPermission[]>,
        public supportGroupIdentifierToRoleMap: Dictionary<IdentityRole>,
        public supportUserRawMailToRoleMap: Dictionary<IdentityRole>) {
        super();
    }
}

export class AutomaticCustomEntityAttributeDefinitionTemplateAuditEvent extends AuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        public name: Optional<string>,
        public resourceTagPatterns: ResourceTagPattern[],
        public type: AutomaticCustomEntityAttributeDefinitionTemplateConfigurationType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime);
    }
}

export class AutomaticCustomEntityAttributeDefinitionTemplateCreationAuditEvent extends AutomaticCustomEntityAttributeDefinitionTemplateAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        name: Optional<string>,
        resourceTagPatterns: ResourceTagPattern[],
        type: AutomaticCustomEntityAttributeDefinitionTemplateConfigurationType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AutomaticCustomEntityAttributeDefinitionTemplateCreationAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            name,
            resourceTagPatterns,
            type);
    }
}

export class AutomaticCustomEntityAttributeDefinitionTemplateDeletionAuditEvent extends AutomaticCustomEntityAttributeDefinitionTemplateAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        name: Optional<string>,
        resourceTagPatterns: ResourceTagPattern[],
        type: AutomaticCustomEntityAttributeDefinitionTemplateConfigurationType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AutomaticCustomEntityAttributeDefinitionTemplateDeletionAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            name,
            resourceTagPatterns,
            type);
    }
}

export class AutomaticCustomEntityAttributeDefinitionTemplateUpdateAuditEvent extends AutomaticCustomEntityAttributeDefinitionTemplateAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        name: Optional<string>,
        resourceTagPatterns: ResourceTagPattern[],
        type: AutomaticCustomEntityAttributeDefinitionTemplateConfigurationType,
        public colorChanged: boolean,
        public nameChanged: boolean,
        public resourceTagPatternsChanged: boolean,
        public typeChanged: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AutomaticCustomEntityAttributeDefinitionTemplateUpdateAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            name,
            resourceTagPatterns,
            type);
    }
}

export class AutomationRuleAuditEvent extends AuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        public description: Optional<string>,
        public name: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime);
    }
}

export class AutomationRuleState extends SystemEntityState implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        public deliveryIdToDestinationIdentifierToStateMap: Dictionary<Dictionary<AutomationRuleDeliveryDestinationState>>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AutomationRuleState");
    }
}

export class AwsAccessGraphPermissionPath extends AccessGraphPermissionPath {
    constructor(
        id: string,
        permissionEdgeIdToPathActionsExcessivenessMap: Dictionary<AccessGraphPermissionPathActionsExcessiveness>,
        public entityTypeName: string,
        public identifier: AwsAccessGraphPermissionPathIdentifier) {
        super(
            id,
            permissionEdgeIdToPathActionsExcessivenessMap);
    }
}

export class AwsApiGatewayApiRoute extends Subdocument implements ITypeNameObject {
    constructor(
        typeName: string,
        public accessLevel: Optional<AwsResourceAccessLevel>,
        public apiKeyRequired: boolean,
        public authorizerId: Optional<string>,
        public identifier: string,
        public method: Optional<AwsApiGatewayApiRouteMethod>,
        public path: string) {
        super(typeName);
    }
}

export class AwsApiGatewayV1ApiRoute extends AwsApiGatewayApiRoute implements ITypeNameObject {
    constructor(
        accessLevel: Optional<AwsResourceAccessLevel>,
        apiKeyRequired: boolean,
        authorizerId: Optional<string>,
        identifier: string,
        method: Optional<AwsApiGatewayApiRouteMethod>,
        path: string,
        public authorizationType: Optional<AwsApiGatewayV1ApiAuthorizationType>,
        public integration: Optional<AwsApiGatewayV1ApiRouteIntegration>) {
        super(
            "AwsApiGatewayV1ApiRoute",
            accessLevel,
            apiKeyRequired,
            authorizerId,
            identifier,
            method,
            path);
    }
}

export class AwsApiGatewayV2ApiRoute extends AwsApiGatewayApiRoute implements ITypeNameObject {
    constructor(
        accessLevel: Optional<AwsResourceAccessLevel>,
        apiKeyRequired: boolean,
        authorizerId: Optional<string>,
        identifier: string,
        method: Optional<AwsApiGatewayApiRouteMethod>,
        path: string,
        public authorizationType: AwsApiGatewayV2ApiAuthorizationType,
        public integrationId: Optional<string>) {
        super(
            "AwsApiGatewayV2ApiRoute",
            accessLevel,
            apiKeyRequired,
            authorizerId,
            identifier,
            method,
            path);
    }
}

export class AwsCloudWatchAlarmNotExistRiskTypeMetadata extends RiskTypeMetadata {
    constructor() {
        super();
    }
}

export class AwsEc2InstanceMetadataServiceTokenHopMaxCountRiskData extends Subdocument implements ITypeNameObject {
    constructor(
        public destinationNetworkScope: Optional<DestinationNetworkScope[]>,
        public highSeverityRolePermissionActions: Optional<string[]>,
        public securityGroupIds: Optional<string[]>) {
        super("AwsEc2InstanceMetadataServiceTokenHopMaxCountRiskData");
    }
}

export class AwsEc2InstanceMetadataServiceVersionRiskData extends Subdocument implements ITypeNameObject {
    constructor(
        public destinationNetworkScope: Optional<DestinationNetworkScope[]>,
        public highSeverityRolePermissionActions: Optional<string[]>,
        public securityGroupIds: Optional<string[]>) {
        super("AwsEc2InstanceMetadataServiceVersionRiskData");
    }
}

export class AwsEc2InstanceUserDataSecretExistsRiskInfo extends Subdocument implements ITypeNameObject {
    constructor(
        typeName: string,
        public type: AwsEc2InstanceUserDataSecretExistsRiskInfoType) {
        super(typeName);
    }
}

export class AwsEc2InstanceUserDataSecretExistsRiskPermissionsInfo extends AwsEc2InstanceUserDataSecretExistsRiskInfo implements ITypeNameObject {
    constructor(
        type: AwsEc2InstanceUserDataSecretExistsRiskInfoType,
        public actions: string[],
        public identityIds: string[],
        public vendorIds: string[]) {
        super(
            "AwsEc2InstanceUserDataSecretExistsRiskPermissionsInfo",
            type);
    }
}

export class AwsEc2InstanceUserDataSecretExistsRiskPublicAccessInfo extends AwsEc2InstanceUserDataSecretExistsRiskInfo implements ITypeNameObject {
    constructor(
        type: AwsEc2InstanceUserDataSecretExistsRiskInfoType,
        public destinationNetworkScope: DestinationNetworkScope[],
        public instanceIds: string[],
        public securityGroupIds: string[]) {
        super(
            "AwsEc2InstanceUserDataSecretExistsRiskPublicAccessInfo",
            type);
    }
}

export class AwsEc2InstanceUserDataSecretExistsRiskRunningInstancesInfo extends AwsEc2InstanceUserDataSecretExistsRiskInfo implements ITypeNameObject {
    constructor(
        type: AwsEc2InstanceUserDataSecretExistsRiskInfoType,
        public instanceIds: string[]) {
        super(
            "AwsEc2InstanceUserDataSecretExistsRiskRunningInstancesInfo",
            type);
    }
}

export class AwsEc2InstanceUserDataSecretExistsRiskSensitiveEntitiesInfo extends AwsEc2InstanceUserDataSecretExistsRiskInfo implements ITypeNameObject {
    constructor(
        type: AwsEc2InstanceUserDataSecretExistsRiskInfoType,
        public entityIds: string[]) {
        super(
            "AwsEc2InstanceUserDataSecretExistsRiskSensitiveEntitiesInfo",
            type);
    }
}

export class AwsEc2NetworkAclRule extends AwsEc2NetworkRule {
    constructor(
        destinationPortRange: Optional<IntRange>,
        icmp: Optional<AwsEc2NetworkRuleIcmp>,
        protocolRange: IntRange,
        public action: AwsEc2NetworkAclRuleAction,
        public index: number,
        public subnet: string) {
        super(
            destinationPortRange,
            icmp,
            protocolRange);
    }
}

export class AwsEc2SnapshotEncryptionDisabledRiskData extends Subdocument implements ITypeNameObject {
    constructor(
        typeName: string) {
        super(typeName);
    }
}

export class AwsEc2SnapshotEncryptionDisabledRiskLaunchTemplateData extends AwsEc2SnapshotEncryptionDisabledRiskData implements ITypeNameObject {
    constructor(
        public autoScalingGroupIds: string[],
        public instanceIds: string[],
        public launchTemplateRevisionIdToVolumeIdsMap: Dictionary<string[]>,
        public volumeIds: string[]) {
        super("AwsEc2SnapshotEncryptionDisabledRiskLaunchTemplateData");
    }
}

export class AwsEc2SnapshotEncryptionDisabledRiskSnapshotData extends AwsEc2SnapshotEncryptionDisabledRiskData implements ITypeNameObject {
    constructor(
        public imageIds: string[]) {
        super("AwsEc2SnapshotEncryptionDisabledRiskSnapshotData");
    }
}

export class AwsEc2SnapshotEncryptionDisabledRiskVolumeData extends AwsEc2SnapshotEncryptionDisabledRiskData implements ITypeNameObject {
    constructor(
        public imageIds: string[],
        public instanceIds: string[]) {
        super("AwsEc2SnapshotEncryptionDisabledRiskVolumeData");
    }
}

export class AwsEc2VolumeEncryptionDisabledRiskData extends Subdocument implements ITypeNameObject {
    constructor(
        typeName: string) {
        super(typeName);
    }
}

export class AwsEc2VolumeEncryptionDisabledRiskEc2LaunchTemplateData extends AwsEc2VolumeEncryptionDisabledRiskData implements ITypeNameObject {
    constructor(
        public autoScalingGroupIds: string[],
        public instanceIds: string[],
        public revisionIds: number[]) {
        super("AwsEc2VolumeEncryptionDisabledRiskEc2LaunchTemplateData");
    }
}

export class AwsEc2VolumeEncryptionDisabledRiskEc2VolumeData extends AwsEc2VolumeEncryptionDisabledRiskData implements ITypeNameObject {
    constructor(
        public instanceIds: string[]) {
        super("AwsEc2VolumeEncryptionDisabledRiskEc2VolumeData");
    }
}

export class AwsEc2VolumeEncryptionDisabledRiskEmrClustersData extends AwsEc2VolumeEncryptionDisabledRiskData implements ITypeNameObject {
    constructor(
        public clusterIds: string[],
        public emrClusterIdToVolumeIdsMap: Dictionary<string[]>) {
        super("AwsEc2VolumeEncryptionDisabledRiskEmrClustersData");
    }
}

export class AwsEc2VolumeEncryptionDisabledRiskEmrSecurityConfigurationData extends AwsEc2VolumeEncryptionDisabledRiskData implements ITypeNameObject {
    constructor(
        public emrClusterIds: string[]) {
        super("AwsEc2VolumeEncryptionDisabledRiskEmrSecurityConfigurationData");
    }
}

export class AwsEventSyncerConfigurationSection extends ConfigurationSection {
    constructor(
        public logObjectsSize: number,
        public storeConcurrencyLevel: number,
        public syncBufferTimeFrame: string) {
        super();
    }
}

export class AwsExcessivePermissionIamPrincipalRiskModelInfo extends Subdocument implements ITypeNameObject {
    constructor(
        typeName: string,
        public type: AwsExcessivePermissionIamPrincipalRiskModelInfoType) {
        super(typeName);
    }
}

export class AwsExcessivePermissionIamPrincipalRiskModelServiceExcessivenessInfo extends AwsExcessivePermissionIamPrincipalRiskModelInfo implements ITypeNameObject {
    constructor(
        type: AwsExcessivePermissionIamPrincipalRiskModelInfoType,
        public serviceId: string) {
        super(
            "AwsExcessivePermissionIamPrincipalRiskModelServiceExcessivenessInfo",
            type);
    }
}

export class AwsExcessivePermissionResourceRiskModelInfo extends Subdocument implements ITypeNameObject {
    constructor(
        typeName: string,
        public type: AwsExcessivePermissionResourceRiskModelInfoType) {
        super(typeName);
    }
}

export class AwsExcessivePermissionResourceRiskModelUnusedPermissionActionInfo extends AwsExcessivePermissionResourceRiskModelInfo implements ITypeNameObject {
    constructor(
        type: AwsExcessivePermissionResourceRiskModelInfoType,
        public accessType: Optional<AwsExcessivePermissionResourceRiskAccessType>,
        public identityIds: Optional<string[]>,
        public permissionActions: string[]) {
        super(
            "AwsExcessivePermissionResourceRiskModelUnusedPermissionActionInfo",
            type);
    }
}

export class AwsExcessivePermissionRoleGroupRiskModelInfo extends Subdocument implements ITypeNameObject {
    constructor(
        typeName: string,
        public type: AwsExcessivePermissionRoleGroupRiskModelInfoType) {
        super(typeName);
    }
}

export class AwsExcessivePermissionRoleGroupRiskModelServiceExcessivenessInfo extends AwsExcessivePermissionRoleGroupRiskModelInfo implements ITypeNameObject {
    constructor(
        type: AwsExcessivePermissionRoleGroupRiskModelInfoType,
        public serviceId: string) {
        super(
            "AwsExcessivePermissionRoleGroupRiskModelServiceExcessivenessInfo",
            type);
    }
}

export class AwsFederationEdgeFilters extends Summary {
    constructor(
        public assumeRoleActivityTimeRange: FilterTimeRange,
        public destinationEntityAttributeValueItems: FilterItems<string>,
        public destinationEntityTenantIdItems: FilterItems<string>,
        public firstSeenTimeRange: FilterTimeRange,
        public sourceEntityAttributeValueItems: FilterItems<string>,
        public sourceEntityTenantIdItems: FilterItems<string>,
        public sourceEntityTypeNameItems: FilterItems<string>) {
        super();
    }
}

export class AwsFederationEdgeModel extends FederationEdgeModel implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        destinationEntity: FederationEdgeModelDestinationEntity,
        sourceEntity: FederationEdgeModelSourceEntity,
        public assumeRoleActivity: Activity,
        public firstSeenTime: string,
        public granteeGroupIds: string[],
        public sourceEntityGrantee: boolean,
        public sso: boolean,
        public unused: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsFederationEdgeModel",
            destinationEntity,
            sourceEntity);
    }
}

export class AwsFolderConfigurationAnalysis extends ScopeConfigurationSection<AwsFolderConfigurationAnalysis> {
    constructor() {
        super();
    }
}

export class AwsFolderConfigurationAuthentication extends ScopeConfigurationSection<AwsFolderConfigurationAuthentication> {
    constructor(
        public partitionTypeToUserMap: Dictionary<AwsAuthenticationUser>) {
        super();
    }
}

export class AwsIamUserCredentials extends Subdocument implements ITypeNameObject {
    constructor(
        typeName: string,
        public creationTime: Optional<string>,
        public enabled: boolean,
        public usageTime: Optional<string>) {
        super(typeName);
    }
}

export class AwsIamUserPassword extends AwsIamUserCredentials implements ITypeNameObject {
    constructor(
        creationTime: Optional<string>,
        enabled: boolean,
        usageTime: Optional<string>) {
        super(
            "AwsIamUserPassword",
            creationTime,
            enabled,
            usageTime);
    }
}

export class AwsInboundExternalEc2InstanceRiskInfo extends Subdocument implements ITypeNameObject {
    constructor(
        typeName: string,
        public type: AwsInboundExternalEc2InstanceRiskInfoType) {
        super(typeName);
    }
}

export class AwsInboundExternalEc2InstanceRiskInstanceData extends Subdocument implements ITypeNameObject {
    constructor(
        public publicAccess: AwsInboundExternalEc2InstanceRiskInstanceDataPublicAccess,
        public vulnerabilities: Optional<AwsInboundExternalEc2InstanceRiskInstanceDataVulnerabilities>) {
        super("AwsInboundExternalEc2InstanceRiskInstanceData");
    }
}

export class AwsInboundExternalEc2InstanceRiskRunningInstancesInfo extends AwsInboundExternalEc2InstanceRiskInfo implements ITypeNameObject {
    constructor(
        type: AwsInboundExternalEc2InstanceRiskInfoType,
        public instanceIds: string[]) {
        super(
            "AwsInboundExternalEc2InstanceRiskRunningInstancesInfo",
            type);
    }
}

export class AwsInboundExternalEc2InstanceRiskSensitiveEntitiesInfo extends AwsInboundExternalEc2InstanceRiskInfo implements ITypeNameObject {
    constructor(
        type: AwsInboundExternalEc2InstanceRiskInfoType,
        public entityIds: string[]) {
        super(
            "AwsInboundExternalEc2InstanceRiskSensitiveEntitiesInfo",
            type);
    }
}

export class AwsOrganizationAuditEvent extends AuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        public masterTenantName: Optional<string>,
        public masterTenantRawId: Optional<string>,
        public name: string,
        public organizationRawId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime);
    }
}

export class AwsOrganizationCreationAuditEvent extends AwsOrganizationAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        masterTenantName: Optional<string>,
        masterTenantRawId: Optional<string>,
        name: string,
        organizationRawId: string,
        public data: AwsOrganizationAuditEventData,
        public memberSelection: Optional<OrganizationMemberSelection>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsOrganizationCreationAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            masterTenantName,
            masterTenantRawId,
            name,
            organizationRawId);
    }
}

export class AwsOrganizationDeletionAuditEvent extends AwsOrganizationAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        masterTenantName: Optional<string>,
        masterTenantRawId: Optional<string>,
        name: string,
        organizationRawId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsOrganizationDeletionAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            masterTenantName,
            masterTenantRawId,
            name,
            organizationRawId);
    }
}

export class AwsOrganizationUpdateAuditEvent extends AwsOrganizationAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        masterTenantName: Optional<string>,
        masterTenantRawId: Optional<string>,
        name: string,
        organizationRawId: string,
        public data: AwsOrganizationAuditEventData,
        public enabledChanged: boolean,
        public folderEnabledChanged: boolean,
        public memberSelection: Optional<OrganizationMemberSelection>,
        public nameChanged: boolean,
        public permissionTypesChanged: boolean,
        public regionSystemNamesChanged: boolean,
        public roleNameChanged: boolean,
        public trailNameChanged: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsOrganizationUpdateAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            masterTenantName,
            masterTenantRawId,
            name,
            organizationRawId);
    }
}

export class AwsResourceProfile extends EntityProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        public creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName);
    }
}

export class AwsRoute53HostedZoneProfile extends AwsResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRoute53HostedZoneProfile",
            creation);
    }
}

export class AwsS3BucketProfile extends AwsResourceProfile implements ITypeNameObject, IAwsPolicyResourceProfile {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsS3BucketProfile",
            creation);
    }
}

export class AwsSageMakerNotebookInstanceProfile extends AwsResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSageMakerNotebookInstanceProfile",
            creation);
    }
}

export class AwsScopeConfigurationAnalysis extends ScopeConfigurationSection<AwsScopeConfigurationAnalysis> {
    constructor(
        public behaviorRiskEvaluationMinTimeFrame: Optional<string>,
        public behaviorRiskNewResourceTimeFrame: Optional<string>,
        public entities: AwsScopeConfigurationAnalysisEntities,
        public recentPermissionActionUpdateTimeFrame: Optional<string>) {
        super();
    }
}

export class AwsScopeConfigurationDataAnalysis extends ScopeConfigurationSection<AwsScopeConfigurationDataAnalysis> {
    constructor(
        public managedDatabaseScanInterval: Optional<string>,
        public objectStoreScanInterval: Optional<string>,
        public regionSnapshotCreationConcurrencyLevel: Optional<number>) {
        super();
    }
}

export class AwsScopeConfigurationOutpost extends ScopeConfigurationSection<AwsScopeConfigurationOutpost> implements IScopeConfigurationSectionOutpost {
    constructor(
        public eksClusterNodeGroupInstanceRawTypes: string[],
        public regionSystemNameToDataMap: Dictionary<AwsScopeConfigurationOutpostRegionData>,
        public tags: ResourceTag[],
        public tenantId: Optional<string>) {
        super();
    }
}

export class AwsScopeConfigurationWorkloadAnalysis extends ScopeConfigurationSection<AwsScopeConfigurationWorkloadAnalysis> {
    constructor(
        public regionSnapshotCreationConcurrencyLevel: Optional<number>,
        public scanInterval: Optional<string>,
        public volumeMaxCount: Optional<number>) {
        super();
    }
}

export class AwsSecretsManagerSecretProfile extends AwsResourceProfile implements ITypeNameObject, IAwsPolicyResourceProfile {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSecretsManagerSecretProfile",
            creation);
    }
}

export class AwsSnsTopicProfile extends AwsResourceProfile implements ITypeNameObject, IAwsPolicyResourceProfile {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSnsTopicProfile",
            creation);
    }
}

export class AwsSqsQueueProfile extends AwsResourceProfile implements ITypeNameObject, IAwsPolicyResourceProfile {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSqsQueueProfile",
            creation);
    }
}

export class AwsSsmParameterProfile extends AwsResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSsmParameterProfile",
            creation);
    }
}

export class AwsSsoPermissionSetAssignmentEligibilityConfigurationPermissions extends Subdocument implements ITypeNameObject {
    constructor(
        typeName: string) {
        super(typeName);
    }
}

export class AwsSsoPermissionSetAssignmentEligibilityConfigurationPermissionSetPermissions extends AwsSsoPermissionSetAssignmentEligibilityConfigurationPermissions implements ITypeNameObject {
    constructor() {
        super("AwsSsoPermissionSetAssignmentEligibilityConfigurationPermissionSetPermissions");
    }
}

export class AwsSsoPermissionSetAssignmentEligibilityConfigurationPolicyPermissions extends AwsSsoPermissionSetAssignmentEligibilityConfigurationPermissions implements ITypeNameObject {
    constructor(
        public inlinePolicyDocument: Optional<AwsIamPolicyDocument>) {
        super("AwsSsoPermissionSetAssignmentEligibilityConfigurationPolicyPermissions");
    }
}

export class AwsSsoPermissionSetAssignmentEligibilityModelPermissions extends Subdocument implements ITypeNameObject {
    constructor(
        typeName: string) {
        super(typeName);
    }
}

export class AwsSsoPermissionSetAssignmentEligibilityModelPermissionSetPermissions extends AwsSsoPermissionSetAssignmentEligibilityModelPermissions implements ITypeNameObject {
    constructor(
        public permissionSetExists: boolean,
        public permissionSetIdReference: string) {
        super("AwsSsoPermissionSetAssignmentEligibilityModelPermissionSetPermissions");
    }
}

export class AwsSsoPermissionSetAssignmentEligibilityModelPolicyPermissions extends AwsSsoPermissionSetAssignmentEligibilityModelPermissions implements ITypeNameObject {
    constructor(
        public awsManagedPolicyIdReferences: string[],
        public existingAwsManagedPolicyIds: string[]) {
        super("AwsSsoPermissionSetAssignmentEligibilityModelPolicyPermissions");
    }
}

export class AwsSsoPermissionSetProfile extends AwsResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSsoPermissionSetProfile",
            creation);
    }
}

export class AwsSsoUserProfile extends AwsResourceProfile implements IDocument, ITypeNameObject, IDirectoryUserProfile {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>,
        public activityTime: Optional<string>,
        public aws: DirectoryUserProfileAws) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSsoUserProfile",
            creation);
    }
}

export class AwsTenantAuditEvent extends AuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        public audit: boolean,
        public tenantFolderPath: string,
        public tenantName: string,
        public tenantRawId: string,
        public tenantRoleArn: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime);
    }
}

export class AwsTenantAuthenticationProviderConfigurationSection extends ConfigurationSection {
    constructor(
        public partitionTypeToDataMap: Dictionary<AwsTenantAuthenticationProviderConfigurationSectionPartitionData>) {
        super();
    }
}

export class AwsTenantCreationAuditEvent extends AwsTenantAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        audit: boolean,
        tenantFolderPath: string,
        tenantName: string,
        tenantRawId: string,
        tenantRoleArn: string,
        public tenantConfigurationTrails: Optional<AwsTenantConfigurationTrail[]>,
        public tenantRegionSystemNames: Optional<string[]>,
        public tenantTrailBucketName: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsTenantCreationAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            audit,
            tenantFolderPath,
            tenantName,
            tenantRawId,
            tenantRoleArn);
    }
}

export class AwsTenantDeletionAuditEvent extends AwsTenantAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        audit: boolean,
        tenantFolderPath: string,
        tenantName: string,
        tenantRawId: string,
        tenantRoleArn: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsTenantDeletionAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            audit,
            tenantFolderPath,
            tenantName,
            tenantRawId,
            tenantRoleArn);
    }
}

export class AwsTenantEntityProfile extends AwsResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsTenantEntityProfile",
            creation);
    }
}

export class AwsTenantStateMonitoring extends TenantStateMonitoring implements ITypeNameObject {
    constructor(
        analysisAccessDeniedErrorMessage: Optional<string>,
        eventAnalysisAccessDeniedErrorMessage: Optional<string>,
        systemEnabled: boolean,
        time: string,
        public accessDeniedTerraformStateBucketNames: string[],
        public containerImageRepositoryAnalysisIssue: Optional<AwsTenantStateMonitoringWorkloadAnalysisIssue>,
        public dataAnalysisIssue: Optional<AwsTenantStateMonitoringDataAnalysisIssue>,
        public instanceAnalysisIssue: Optional<AwsTenantStateMonitoringWorkloadAnalysisIssue>,
        public issue: Optional<AwsTenantStateMonitoringIssue>,
        public permissionManagementIssue: Optional<AwsTenantStateMonitoringPermissionManagementIssue>,
        public resourceAnalysisAccessDeniedErrorMessage: Optional<string>,
        public trailBucketNameToAccessDeniedLogObjectKeyPrefixToAfterKeyMap: Dictionary<Dictionary<string>>,
        public trailIssue: Optional<AwsTenantStateMonitoringTrailIssue>,
        public workloadAnalysisIssue: Optional<AwsTenantStateMonitoringWorkloadAnalysisIssue>) {
        super(
            "AwsTenantStateMonitoring",
            analysisAccessDeniedErrorMessage,
            eventAnalysisAccessDeniedErrorMessage,
            systemEnabled,
            time);
    }
}

export class AwsTenantUpdateAuditEvent extends AwsTenantAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        audit: boolean,
        tenantFolderPath: string,
        tenantName: string,
        tenantRawId: string,
        tenantRoleArn: string,
        public tenantConfigurationTrails: Optional<AwsTenantConfigurationTrail[]>,
        public tenantFolderPathChanged: boolean,
        public tenantLambdaFunctionConfigurationInvokeEventCategoryTrailChanged: boolean,
        public tenantManagementEventCategoryTrailChanged: boolean,
        public tenantNameChanged: boolean,
        public tenantRegionSystemNames: Optional<string[]>,
        public tenantRegionSystemNamesChanged: boolean,
        public tenantRoleArnChanged: boolean,
        public tenantS3ObjectEventCategoryTrailChanged: boolean,
        public tenantTrailBucketChanged: boolean,
        public tenantTrailBucketName: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsTenantUpdateAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            audit,
            tenantFolderPath,
            tenantName,
            tenantRawId,
            tenantRoleArn);
    }
}

export class AwsTimestreamDatabaseProfile extends AwsResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsTimestreamDatabaseProfile",
            creation);
    }
}

export class AwsTimestreamDatabaseTableProfile extends AwsResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsTimestreamDatabaseTableProfile",
            creation);
    }
}

export class AwsWafGlobalWebAclProfile extends AwsResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsWafGlobalWebAclProfile",
            creation);
    }
}

export class AwsWafRegionalWebAclProfile extends AwsResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsWafRegionalWebAclProfile",
            creation);
    }
}

export class AwsWafWebAclRuleBase extends Subdocument implements ITypeNameObject {
    constructor(
        typeName: string,
        public name: string,
        public priority: number) {
        super(typeName);
    }
}

export class AwsWafWebAclRuleGroup extends AwsWafWebAclRuleBase implements ITypeNameObject {
    constructor(
        name: string,
        priority: number,
        public groupName: string,
        public rules: AwsWafWebAclRule[]) {
        super(
            "AwsWafWebAclRuleGroup",
            name,
            priority);
    }
}

export class AzureAccessGraphPermissionPath extends AccessGraphPermissionPath {
    constructor(
        id: string,
        permissionEdgeIdToPathActionsExcessivenessMap: Dictionary<AccessGraphPermissionPathActionsExcessiveness>,
        public identifier: AzureAccessGraphPermissionPathIdentifier) {
        super(
            id,
            permissionEdgeIdToPathActionsExcessivenessMap);
    }
}

export class AzureApplicationAuthenticationProviderConfigurationSection extends ConfigurationSection {
    constructor(
        public aadTenantRawId: string,
        public tenantRawShortId: string) {
        super();
    }
}

export class AzureDevOpsAuthenticationProviderConfigurationSection extends ConfigurationSection implements INullableConfigurationSection {
    constructor(
        public applicationEncryptedSecret: EncryptedData,
        public applicationRawId: string) {
        super();
    }
}

export class AzureEventSyncerConfigurationSection extends ConfigurationSection {
    constructor(
        public syncBufferTimeFrame: string) {
        super();
    }
}

export class AzureExcessivePermissionPrincipalRiskModelInfo extends Subdocument implements ITypeNameObject {
    constructor(
        typeName: string,
        public type: AzureExcessivePermissionPrincipalRiskModelInfoType) {
        super(typeName);
    }
}

export class AzureExcessivePermissionPrincipalRiskModelRoleAssignmentResourcesInfo extends AzureExcessivePermissionPrincipalRiskModelInfo implements ITypeNameObject {
    constructor(
        type: AzureExcessivePermissionPrincipalRiskModelInfoType,
        public permissionActionSeverity: Severity,
        public permitters: AzureAccessPrincipalRiskPermitters,
        public permittersType: AzureExcessivePermissionPrincipalRiskModelPermittersType,
        public resolutionToPermittersMap: Dictionary<AzureAccessPrincipalRiskPermitters>,
        public resolutionToPermittersTypeMap: Dictionary<AzureExcessivePermissionPrincipalRiskModelResolutionPermittersType>,
        public resolutionType: AzureExcessivePermissionPrincipalRiskModelResolutionType) {
        super(
            "AzureExcessivePermissionPrincipalRiskModelRoleAssignmentResourcesInfo",
            type);
    }
}

export class AzureFolderConfigurationAnalysis extends ScopeConfigurationSection<AzureFolderConfigurationAnalysis> {
    constructor(
        public behaviorRiskEvaluationMinTimeFrame: Optional<string>,
        public behaviorRiskNewResourceTimeFrame: Optional<string>,
        public recentPermissionActionUpdateTimeFrame: Optional<string>) {
        super();
    }
}

export class AzureNetworkApplicationGatewayBackendRule extends AzureNetworkApplicationGatewayRule {
    constructor(
        listenerPort: number,
        listenerPrivateIpAddress: Optional<string>,
        listenerProtocol: AzureNetworkApplicationGatewayProtocol,
        listenerPublicIpAddressId: Optional<string>,
        name: string,
        pathBasedRoutingPaths: Optional<string[]>,
        pathBasedRoutingTargetName: Optional<string>,
        public backendAddressPool: AzureNetworkApplicationGatewayBackendAddressPool,
        public targetPort: number,
        public targetProtocol: AzureNetworkApplicationGatewayProtocol) {
        super(
            listenerPort,
            listenerPrivateIpAddress,
            listenerProtocol,
            listenerPublicIpAddressId,
            name,
            pathBasedRoutingPaths,
            pathBasedRoutingTargetName);
    }
}

export class AzureNetworkApplicationGatewayRedirectRule extends AzureNetworkApplicationGatewayRule {
    constructor(
        listenerPort: number,
        listenerPrivateIpAddress: Optional<string>,
        listenerProtocol: AzureNetworkApplicationGatewayProtocol,
        listenerPublicIpAddressId: Optional<string>,
        name: string,
        pathBasedRoutingPaths: Optional<string[]>,
        pathBasedRoutingTargetName: Optional<string>,
        public targetPort: Optional<number>,
        public targetPrivateIpAddress: Optional<string>,
        public targetPublicIpAddressId: Optional<string>,
        public targetUrl: Optional<string>) {
        super(
            listenerPort,
            listenerPrivateIpAddress,
            listenerProtocol,
            listenerPublicIpAddressId,
            name,
            pathBasedRoutingPaths,
            pathBasedRoutingTargetName);
    }
}

export class AzureNetworkLoadBalancerInboundNatRule extends AzureNetworkLoadBalancerRule {
    constructor(
        backendPort: number,
        floatingIp: Optional<boolean>,
        frontendIpConfiguration: AzureNetworkLoadBalancerFrontendIpConfiguration,
        frontendPort: number,
        name: string,
        path: string,
        protocol: AzureNetworkLoadBalancerRuleProtocol,
        public backendVirtualMachineId: Optional<string>,
        public backendVirtualMachineScaleSetId: Optional<string>) {
        super(
            backendPort,
            floatingIp,
            frontendIpConfiguration,
            frontendPort,
            name,
            path,
            protocol);
    }
}

export class AzureNetworkLoadBalancerLoadBalancingRule extends AzureNetworkLoadBalancerRule {
    constructor(
        backendPort: number,
        floatingIp: Optional<boolean>,
        frontendIpConfiguration: AzureNetworkLoadBalancerFrontendIpConfiguration,
        frontendPort: number,
        name: string,
        path: string,
        protocol: AzureNetworkLoadBalancerRuleProtocol,
        public backendAddressPool: AzureNetworkLoadBalancerBackendAddressPool) {
        super(
            backendPort,
            floatingIp,
            frontendIpConfiguration,
            frontendPort,
            name,
            path,
            protocol);
    }
}

export class AzureNetworkScope extends NetworkScope<AzureNetworkScope> {
    constructor(
        destinationPortRanges: IntRange[],
        destinationSubnets: string[],
        protocolRanges: IntRange[],
        sourceSubnets: string[]) {
        super(
            destinationPortRanges,
            destinationSubnets,
            protocolRanges,
            sourceSubnets);
    }
}

export class AzureOrganizationAuditEvent extends AuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        public aadTenantName: string,
        public aadTenantRawId: string,
        public name: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime);
    }
}

export class AzureOrganizationCreationAuditEvent extends AzureOrganizationAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        aadTenantName: string,
        aadTenantRawId: string,
        name: string,
        public data: AzureOrganizationAuditEventData,
        public memberSelection: Optional<OrganizationMemberSelection>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureOrganizationCreationAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            aadTenantName,
            aadTenantRawId,
            name);
    }
}

export class AzureOrganizationDeletionAuditEvent extends AzureOrganizationAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        aadTenantName: string,
        aadTenantRawId: string,
        name: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureOrganizationDeletionAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            aadTenantName,
            aadTenantRawId,
            name);
    }
}

export class AzureOrganizationUpdateAuditEvent extends AzureOrganizationAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        aadTenantName: string,
        aadTenantRawId: string,
        name: string,
        public data: AzureOrganizationAuditEventData,
        public enabledChanged: boolean,
        public folderEnabledChanged: boolean,
        public memberSelection: Optional<OrganizationMemberSelection>,
        public nameChanged: boolean,
        public permissionTypesChanged: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureOrganizationUpdateAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            aadTenantName,
            aadTenantRawId,
            name);
    }
}

export class AzureResourceProfile extends EntityProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        public creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName);
    }
}

export class AzureResourcesGenericResourceProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureResourcesGenericResourceProfile",
            creation);
    }
}

export class AzureResourcesResourceGroupProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureResourcesResourceGroupProfile",
            creation);
    }
}

export class AzureScopeConfigurationDataAnalysis extends ScopeConfigurationSection<AzureScopeConfigurationDataAnalysis> {
    constructor(
        public managedDatabaseScanInterval: Optional<string>,
        public objectStoreScanInterval: Optional<string>) {
        super();
    }
}

export class AzureScopeConfigurationOutpost extends ScopeConfigurationSection<AzureScopeConfigurationOutpost> implements IScopeConfigurationSectionOutpost {
    constructor(
        public regionSystemNameToDataMap: Dictionary<AzureScopeConfigurationOutpostData>,
        public tags: ResourceTag[],
        public tenantId: Optional<string>) {
        super();
    }
}

export class AzureScopeConfigurationWorkloadAnalysis extends ScopeConfigurationSection<AzureScopeConfigurationWorkloadAnalysis> {
    constructor(
        public defaultDiskSnapshotResourceGroup: Optional<boolean>,
        public diskMaxCount: Optional<number>,
        public diskSnapshotResourceGroupId: Optional<string>,
        public scanInterval: Optional<string>) {
        super();
    }
}

export class AzureSqlServerDatabaseProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSqlServerDatabaseProfile",
            creation);
    }
}

export class AzureSqlServerProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSqlServerProfile",
            creation);
    }
}

export class AzureStorageStorageAccountBlobContainerProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountBlobContainerProfile",
            creation);
    }
}

export class AzureStorageStorageAccountFileShareProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountFileShareProfile",
            creation);
    }
}

export class AzureStorageStorageAccountProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountProfile",
            creation);
    }
}

export class AzureStorageStorageAccountQueueProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountQueueProfile",
            creation);
    }
}

export class AzureStorageStorageAccountTableProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountTableProfile",
            creation);
    }
}

export class AzureTenantAuditEvent extends AuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        public tenantFolderPath: string,
        public tenantName: string,
        public tenantRawShortId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime);
    }
}

export class AzureTenantCreationAuditEvent extends AzureTenantAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        tenantFolderPath: string,
        tenantName: string,
        tenantRawShortId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureTenantCreationAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            tenantFolderPath,
            tenantName,
            tenantRawShortId);
    }
}

export class AzureTenantDeletionAuditEvent extends AzureTenantAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        tenantFolderPath: string,
        tenantName: string,
        tenantRawShortId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureTenantDeletionAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            tenantFolderPath,
            tenantName,
            tenantRawShortId);
    }
}

export class AzureTenantEntityProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureTenantEntityProfile",
            creation);
    }
}

export class AzureTenantStateMonitoring extends TenantStateMonitoring implements ITypeNameObject {
    constructor(
        analysisAccessDeniedErrorMessage: Optional<string>,
        eventAnalysisAccessDeniedErrorMessage: Optional<string>,
        systemEnabled: boolean,
        time: string,
        public dataAnalysisIssue: Optional<AzureTenantStateMonitoringDataAnalysisIssue>,
        public issue: Optional<AzureTenantStateMonitoringIssue>,
        public permissionManagementIssue: Optional<AzureTenantStateMonitoringPermissionManagementIssue>,
        public servicePrincipalMissingAssignedRoleDefinitionNames: string[],
        public servicePrincipalMissingDataAnalysisPermissionActions: string[],
        public terraformStateStorageAccountNameToAccessDeniedBlobContainerNamesMap: Dictionary<string[]>,
        public workloadAnalysisIssue: Optional<AzureTenantStateMonitoringWorkloadAnalysisIssue>) {
        super(
            "AzureTenantStateMonitoring",
            analysisAccessDeniedErrorMessage,
            eventAnalysisAccessDeniedErrorMessage,
            systemEnabled,
            time);
    }
}

export class AzureTenantUpdateAuditEvent extends AzureTenantAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        tenantFolderPath: string,
        tenantName: string,
        tenantRawShortId: string,
        public tenantFolderPathChanged: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureTenantUpdateAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            tenantFolderPath,
            tenantName,
            tenantRawShortId);
    }
}

export class AzureWebApplicationProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWebApplicationProfile",
            creation);
    }
}

export class BiUpdaterConfigurationSection extends ConfigurationSection {
    constructor(
        public enabled: boolean,
        public snowflakeAccountName: string,
        public snowflakeDatabaseName: string) {
        super();
    }
}

export class BuiltInComplianceAuditEvent extends AuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        public builtInComplianceType: BuiltInComplianceSectionType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime);
    }
}

export class BuiltInComplianceEnabledUpdateAuditEvent extends BuiltInComplianceAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        builtInComplianceType: BuiltInComplianceSectionType,
        public enabled: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "BuiltInComplianceEnabledUpdateAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            builtInComplianceType);
    }
}

export class ChangeBase extends Subdocument implements ITypeNameObject {
    constructor(
        typeName: string,
        public entityIds: string[]) {
        super(typeName);
    }
}

export class ChangeSet extends ChangeBase implements ITypeNameObject {
    constructor(
        entityIds: string[],
        public changes: Change[]) {
        super(
            "ChangeSet",
            entityIds);
    }
}

export class CiIntegrationAuditEvent extends AuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        public endpoint: Optional<string>,
        public endpointConnectorName: Optional<string>,
        public integrationName: string,
        public integrationServerCertificateValidationEnabled: boolean,
        public integrationType: CiTenantType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime);
    }
}

export class CiIntegrationCreationAuditEvent extends CiIntegrationAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        endpoint: Optional<string>,
        endpointConnectorName: Optional<string>,
        integrationName: string,
        integrationServerCertificateValidationEnabled: boolean,
        integrationType: CiTenantType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            endpoint,
            endpointConnectorName,
            integrationName,
            integrationServerCertificateValidationEnabled,
            integrationType);
    }
}

export class CiIntegrationDeletionAuditEvent extends CiIntegrationAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        endpoint: Optional<string>,
        endpointConnectorName: Optional<string>,
        integrationName: string,
        integrationServerCertificateValidationEnabled: boolean,
        integrationType: CiTenantType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            endpoint,
            endpointConnectorName,
            integrationName,
            integrationServerCertificateValidationEnabled,
            integrationType);
    }
}

export class CiIntegrationState extends SystemEntityState implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        public endpoint: Optional<string>,
        public issue: Optional<CiIntegrationStateIssue>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "CiIntegrationState");
    }
}

export class CiIntegrationUpdateAuditEvent extends CiIntegrationAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        endpoint: Optional<string>,
        endpointConnectorName: Optional<string>,
        integrationName: string,
        integrationServerCertificateValidationEnabled: boolean,
        integrationType: CiTenantType,
        public integrationNameChanged: boolean,
        public integrationServerCertificateValidationChanged: boolean,
        public integrationTokenChanged: boolean,
        public integrationUserNameChanged: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            endpoint,
            endpointConnectorName,
            integrationName,
            integrationServerCertificateValidationEnabled,
            integrationType);
    }
}

export class CiOrganizationCreationAuditEvent extends CiIntegrationCreationAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        endpoint: Optional<string>,
        endpointConnectorName: Optional<string>,
        integrationName: string,
        integrationServerCertificateValidationEnabled: boolean,
        integrationType: CiTenantType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "CiOrganizationCreationAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            endpoint,
            endpointConnectorName,
            integrationName,
            integrationServerCertificateValidationEnabled,
            integrationType);
    }
}

export class CiOrganizationDeletionAuditEvent extends CiIntegrationDeletionAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        endpoint: Optional<string>,
        endpointConnectorName: Optional<string>,
        integrationName: string,
        integrationServerCertificateValidationEnabled: boolean,
        integrationType: CiTenantType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "CiOrganizationDeletionAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            endpoint,
            endpointConnectorName,
            integrationName,
            integrationServerCertificateValidationEnabled,
            integrationType);
    }
}

export class CiOrganizationUpdateAuditEvent extends CiIntegrationUpdateAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        endpoint: Optional<string>,
        endpointConnectorName: Optional<string>,
        integrationName: string,
        integrationServerCertificateValidationEnabled: boolean,
        integrationType: CiTenantType,
        integrationNameChanged: boolean,
        integrationServerCertificateValidationChanged: boolean,
        integrationTokenChanged: boolean,
        integrationUserNameChanged: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "CiOrganizationUpdateAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            endpoint,
            endpointConnectorName,
            integrationName,
            integrationServerCertificateValidationEnabled,
            integrationType,
            integrationNameChanged,
            integrationServerCertificateValidationChanged,
            integrationTokenChanged,
            integrationUserNameChanged);
    }
}

export class CiRegistryIntegrationCreationAuditEvent extends CiIntegrationCreationAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        endpoint: Optional<string>,
        endpointConnectorName: Optional<string>,
        integrationName: string,
        integrationServerCertificateValidationEnabled: boolean,
        integrationType: CiTenantType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "CiRegistryIntegrationCreationAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            endpoint,
            endpointConnectorName,
            integrationName,
            integrationServerCertificateValidationEnabled,
            integrationType);
    }
}

export class CiRegistryIntegrationDeletionAuditEvent extends CiIntegrationDeletionAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        endpoint: Optional<string>,
        endpointConnectorName: Optional<string>,
        integrationName: string,
        integrationServerCertificateValidationEnabled: boolean,
        integrationType: CiTenantType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "CiRegistryIntegrationDeletionAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            endpoint,
            endpointConnectorName,
            integrationName,
            integrationServerCertificateValidationEnabled,
            integrationType);
    }
}

export class CiRegistryIntegrationUpdateAuditEvent extends CiIntegrationUpdateAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        endpoint: Optional<string>,
        endpointConnectorName: Optional<string>,
        integrationName: string,
        integrationServerCertificateValidationEnabled: boolean,
        integrationType: CiTenantType,
        integrationNameChanged: boolean,
        integrationServerCertificateValidationChanged: boolean,
        integrationTokenChanged: boolean,
        integrationUserNameChanged: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "CiRegistryIntegrationUpdateAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            endpoint,
            endpointConnectorName,
            integrationName,
            integrationServerCertificateValidationEnabled,
            integrationType,
            integrationNameChanged,
            integrationServerCertificateValidationChanged,
            integrationTokenChanged,
            integrationUserNameChanged);
    }
}

export class CiScopeConfigurationOutpost extends ScopeConfigurationSection<CiScopeConfigurationOutpost> {
    constructor(
        public scopeId: Optional<string>) {
        super();
    }
}

export class CiScopeConfigurationWorkloadAnalysis extends ScopeConfigurationSection<CiScopeConfigurationWorkloadAnalysis> {
    constructor(
        public scanInterval: Optional<string>) {
        super();
    }
}

export class CiTenantAuditEvent extends AuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        public ciTenantType: CiTenantType,
        public tenantFolderPath: string,
        public tenantId: string,
        public tenantName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime);
    }
}

export class CiTenantConfiguration extends TenantConfiguration implements ITypeNameObject, ICiScopeConfiguration, IOrganizationTenantConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        active: boolean,
        deletePending: boolean,
        disabledOrchestrationTypes: OrchestrationType[],
        displayReference: string,
        name: string,
        orchestrationTypeToEnabledMap: Dictionary<boolean>,
        parentScopeId: string,
        scopeSections: ScopeConfigurationSections,
        type: TenantType,
        public ciScopeSections: CiScopeConfigurationSections,
        public ciTenantType: CiTenantType,
        public integrationId: string,
        public organizationId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            active,
            deletePending,
            disabledOrchestrationTypes,
            displayReference,
            name,
            orchestrationTypeToEnabledMap,
            parentScopeId,
            scopeSections,
            type);
    }
}

export class CiTenantCreationAuditEvent extends CiTenantAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        ciTenantType: CiTenantType,
        tenantFolderPath: string,
        tenantId: string,
        tenantName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "CiTenantCreationAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            ciTenantType,
            tenantFolderPath,
            tenantId,
            tenantName);
    }
}

export class CiTenantDeletionAuditEvent extends CiTenantAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        ciTenantType: CiTenantType,
        tenantFolderPath: string,
        tenantId: string,
        tenantName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "CiTenantDeletionAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            ciTenantType,
            tenantFolderPath,
            tenantId,
            tenantName);
    }
}

export class CiTenantModel extends TenantModel {
    constructor(
        public configuration: CiTenantConfiguration,
        public state: TenantState,
        statusSeverity: Optional<Severity>,
        tenantType: TenantType,
        public integrationId: string,
        public status: CiTenantModelStatus) {
        super(
            configuration,
            state,
            statusSeverity,
            tenantType);
    }
}

export class CiTenantState extends TenantState implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        public monitoring: CiTenantStateMonitoring,
        orchestration: TenantStateOrchestration) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "CiTenantState",
            monitoring,
            orchestration);
    }
}

export class CiTenantStateMonitoring extends TenantStateMonitoring implements ITypeNameObject {
    constructor(
        analysisAccessDeniedErrorMessage: Optional<string>,
        eventAnalysisAccessDeniedErrorMessage: Optional<string>,
        systemEnabled: boolean,
        time: string,
        public issue: Optional<CiTenantStateMonitoringIssue>) {
        super(
            "CiTenantStateMonitoring",
            analysisAccessDeniedErrorMessage,
            eventAnalysisAccessDeniedErrorMessage,
            systemEnabled,
            time);
    }
}

export class CiTenantUpdateAuditEvent extends CiTenantAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        ciTenantType: CiTenantType,
        tenantFolderPath: string,
        tenantId: string,
        tenantName: string,
        public tenantFolderPathChanged: boolean,
        public tenantNameChanged: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "CiTenantUpdateAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            ciTenantType,
            tenantFolderPath,
            tenantId,
            tenantName);
    }
}

export class CloudProviderTenantConfiguration extends TenantConfiguration implements ITypeNameObject, IChildScopeConfiguration, IOrganizationTenantConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        active: boolean,
        deletePending: boolean,
        disabledOrchestrationTypes: OrchestrationType[],
        displayReference: string,
        name: string,
        orchestrationTypeToEnabledMap: Dictionary<boolean>,
        parentScopeId: string,
        scopeSections: ScopeConfigurationSections,
        type: TenantType,
        public organizationExists: boolean,
        public organizationId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            active,
            deletePending,
            disabledOrchestrationTypes,
            displayReference,
            name,
            orchestrationTypeToEnabledMap,
            parentScopeId,
            scopeSections,
            type);
    }
}

export class CloudProviderTenantModel extends TenantModel {
    constructor(
        public configuration: CloudProviderTenantConfiguration,
        public state: CloudProviderTenantState,
        statusSeverity: Optional<Severity>,
        tenantType: TenantType) {
        super(
            configuration,
            state,
            statusSeverity,
            tenantType);
    }
}

export class CloudProviderTenantOrganizationState extends SystemEntityState implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        public permissionTypes: CloudProviderTenantPermissionType[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName);
    }
}

export class CloudProviderTenantState extends TenantState implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        monitoring: TenantStateMonitoring,
        orchestration: TenantStateOrchestration,
        public permissionManagementEnabled: boolean,
        public permissionManagementPrincipalTenantId: Optional<string>,
        public permissionTypes: CloudProviderTenantPermissionType[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            monitoring,
            orchestration);
    }
}

export class CodeEntity extends TenantDocument implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId);
    }
}

export class CodeIacRiskFilters extends Summary {
    constructor(
        public categoryItems: FilterItems<RiskPolicyCategory>,
        public cloudTenantTypeItems: FilterItems<TenantType>,
        public complianceIdentifierItems: FilterItems<string>,
        public riskPolicyConfigurationTypeNameItems: FilterItems<string>) {
        super();
    }
}

export class CodeOrganizationAuditEvent extends AuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        public organizationName: string,
        public organizationType: CodeTenantType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime);
    }
}

export class CodeOrganizationCreationAuditEvent extends CodeOrganizationAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        organizationName: string,
        organizationType: CodeTenantType,
        public memberSelection: Optional<OrganizationMemberSelection>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "CodeOrganizationCreationAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            organizationName,
            organizationType);
    }
}

export class CodeOrganizationDeletionAuditEvent extends CodeOrganizationAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        organizationName: string,
        organizationType: CodeTenantType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "CodeOrganizationDeletionAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            organizationName,
            organizationType);
    }
}

export class CodeOrganizationState extends SystemEntityState implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        public issue: Optional<CodeOrganizationStateIssue>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "CodeOrganizationState");
    }
}

export class CodeOrganizationUpdateAuditEvent extends CodeOrganizationAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        organizationName: string,
        organizationType: CodeTenantType,
        public memberSelection: Optional<OrganizationMemberSelection>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "CodeOrganizationUpdateAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            organizationName,
            organizationType);
    }
}

export class CodePipelineAuditEvent extends AuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        public pipelineName: string,
        public pipelineType: CodePipelineType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime);
    }
}

export class CodePipelineCreationAuditEvent extends CodePipelineAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        pipelineName: string,
        pipelineType: CodePipelineType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "CodePipelineCreationAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            pipelineName,
            pipelineType);
    }
}

export class CodePipelineDeletionAuditEvent extends CodePipelineAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        pipelineName: string,
        pipelineType: CodePipelineType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "CodePipelineDeletionAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            pipelineName,
            pipelineType);
    }
}

export class CodePipelineUpdateAuditEvent extends CodePipelineAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        pipelineName: string,
        pipelineType: CodePipelineType,
        public pipelinePreviousName: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "CodePipelineUpdateAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            pipelineName,
            pipelineType);
    }
}

export class CodeRiskTypeMetadata extends RiskTypeMetadata {
    constructor() {
        super();
    }
}

export class CodeScanFilters extends Summary {
    constructor(
        public integrationIdItems: FilterItems<string>,
        public tenantIdItems: FilterItems<string>,
        public timeRange: FilterTimeRange) {
        super();
    }
}

export class CodeScanResource extends TenantDocument implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId);
    }
}

export class CodeServerAuditEvent extends AuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        public serverEndpoint: Optional<string>,
        public serverEndpointConnectorName: Optional<string>,
        public serverName: string,
        public serverType: CodeTenantType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime);
    }
}

export class CodeServerCreationAuditEvent extends CodeServerAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        serverEndpoint: Optional<string>,
        serverEndpointConnectorName: Optional<string>,
        serverName: string,
        serverType: CodeTenantType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "CodeServerCreationAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            serverEndpoint,
            serverEndpointConnectorName,
            serverName,
            serverType);
    }
}

export class CodeServerDeletionAuditEvent extends CodeServerAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        serverEndpoint: Optional<string>,
        serverEndpointConnectorName: Optional<string>,
        serverName: string,
        serverType: CodeTenantType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "CodeServerDeletionAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            serverEndpoint,
            serverEndpointConnectorName,
            serverName,
            serverType);
    }
}

export class CodeServerState extends SystemEntityState implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        public endpoint: Optional<string>,
        public issue: Optional<CodeServerStateIssue>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "CodeServerState");
    }
}

export class CodeServerUpdateAuditEvent extends CodeServerAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        serverEndpoint: Optional<string>,
        serverEndpointConnectorName: Optional<string>,
        serverName: string,
        serverType: CodeTenantType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "CodeServerUpdateAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            serverEndpoint,
            serverEndpointConnectorName,
            serverName,
            serverType);
    }
}

export class CodeTenantAuditEvent extends AuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        public codeTenantType: CodeTenantType,
        public tenantFolderPath: string,
        public tenantId: string,
        public tenantName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime);
    }
}

export class CodeTenantConfiguration extends TenantConfiguration implements ITypeNameObject, ICodeScopeConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        active: boolean,
        deletePending: boolean,
        disabledOrchestrationTypes: OrchestrationType[],
        displayReference: string,
        name: string,
        orchestrationTypeToEnabledMap: Dictionary<boolean>,
        parentScopeId: string,
        scopeSections: ScopeConfigurationSections,
        type: TenantType,
        public codeTenantType: CodeTenantType,
        public path: string,
        public url: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            active,
            deletePending,
            disabledOrchestrationTypes,
            displayReference,
            name,
            orchestrationTypeToEnabledMap,
            parentScopeId,
            scopeSections,
            type);
    }
}

export class CodeTenantModel extends TenantModel {
    constructor(
        public configuration: CodeTenantConfiguration,
        public state: TenantState,
        statusSeverity: Optional<Severity>,
        tenantType: TenantType,
        public organizationName: Optional<string>,
        public serverEndpoint: Optional<string>,
        public status: Optional<CodeTenantModelStatus>) {
        super(
            configuration,
            state,
            statusSeverity,
            tenantType);
    }
}

export class CodeUpdateAuditEvent extends AuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        public failureRiskPolicyMinSeverity: Optional<Severity>,
        public failureRiskPolicyMinSeverityEnabled: Optional<boolean>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "CodeUpdateAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime);
    }
}

export class ConfigurationControllerGetAwsOrganizationMemberDatasRequest extends ConfigurationControllerGetOrganizationMemberDatasRequest {
    constructor(
        public masterTenantId: string) {
        super();
    }
}

export class ConfigurationControllerGetAzureDevOpsOrganizationMemberDatasRequest extends ConfigurationControllerGetOrganizationMemberDatasRequest {
    constructor(
        public aadTenantRawId: string,
        public accessToken: Optional<string>,
        public organizationName: string) {
        super();
    }
}

export class ConfigurationControllerGetAzureOrganizationMemberDatasRequest extends ConfigurationControllerGetOrganizationMemberDatasRequest {
    constructor(
        public aadTenantId: string) {
        super();
    }
}

export class ConfigurationControllerGetBitbucketOrganizationMemberDatasRequest extends ConfigurationControllerGetOrganizationMemberDatasRequest {
    constructor(
        public accessToken: Optional<string>,
        public organizationId: Optional<string>) {
        super();
    }
}

export class ConfigurationControllerGetGcpOrganizationMemberDatasRequest extends ConfigurationControllerGetOrganizationMemberDatasRequest {
    constructor(
        public gciTenantId: string) {
        super();
    }
}

export class ConfigurationControllerGetGitHubOrganizationMemberDatasRequest extends ConfigurationControllerGetOrganizationMemberDatasRequest {
    constructor(
        public accessToken: Optional<string>,
        public organizationName: string,
        public serverId: Optional<string>) {
        super();
    }
}

export class ConfigurationControllerGetGitLabOrganizationMemberDatasRequest extends ConfigurationControllerGetOrganizationMemberDatasRequest {
    constructor(
        public accessToken: Optional<string>,
        public organizationId: Optional<string>,
        public serverId: Optional<string>) {
        super();
    }
}

export class ConfigurationControllerGetOciOrganizationMemberDatasRequest extends ConfigurationControllerGetOrganizationMemberDatasRequest {
    constructor(
        public id: Optional<string>,
        public ocid: Optional<string>,
        public regionSystemName: Optional<string>,
        public userOcid: Optional<string>,
        public userPrivateKeyPem: Optional<string>,
        public userPublicKeyFingerprint: Optional<string>) {
        super();
    }
}

export class ConfigurationControllerInsertCodePipelineResponse extends ConfigurationControllerUpsertScopeSystemEntityResponse {
    constructor(
        scopeSystemEntityModel: ScopeSystemEntityModel,
        public apiKeyToken: string) {
        super(scopeSystemEntityModel);
    }
}

export class ConfigurationControllerInsertGroupMembershipEligibilityRequest extends ConfigurationControllerInsertPermissionEligibilityRequest {
    constructor(
        approval: Optional<ConfigurationControllerPermissionEligibilityApproval>,
        description: Optional<string>,
        expirationTimeFrame: string,
        granteePrincipalIds: string[],
        name: string,
        principalTenantId: string,
        reasonRequired: boolean,
        scopeId: string,
        public groupIds: string[]) {
        super(
            approval,
            description,
            expirationTimeFrame,
            granteePrincipalIds,
            name,
            principalTenantId,
            reasonRequired,
            scopeId);
    }
}

export class ConfigurationControllerInsertOktaDirectoryGroupMembershipEligibilityRequest extends ConfigurationControllerInsertGroupMembershipEligibilityRequest {
    constructor(
        approval: Optional<ConfigurationControllerPermissionEligibilityApproval>,
        description: Optional<string>,
        expirationTimeFrame: string,
        granteePrincipalIds: string[],
        name: string,
        principalTenantId: string,
        reasonRequired: boolean,
        scopeId: string,
        groupIds: string[]) {
        super(
            approval,
            description,
            expirationTimeFrame,
            granteePrincipalIds,
            name,
            principalTenantId,
            reasonRequired,
            scopeId,
            groupIds);
    }
}

export class ConfigurationControllerInsertOneLoginDirectoryRoleAssignmentEligibilityRequest extends ConfigurationControllerInsertPermissionEligibilityRequest {
    constructor(
        approval: Optional<ConfigurationControllerPermissionEligibilityApproval>,
        description: Optional<string>,
        expirationTimeFrame: string,
        granteePrincipalIds: string[],
        name: string,
        principalTenantId: string,
        reasonRequired: boolean,
        scopeId: string,
        public roleIds: string[]) {
        super(
            approval,
            description,
            expirationTimeFrame,
            granteePrincipalIds,
            name,
            principalTenantId,
            reasonRequired,
            scopeId);
    }
}

export class ConfigurationControllerInsertPermissionAssignmentEligibilityRequest extends ConfigurationControllerInsertPermissionEligibilityRequest {
    constructor(
        approval: Optional<ConfigurationControllerPermissionEligibilityApproval>,
        description: Optional<string>,
        expirationTimeFrame: string,
        granteePrincipalIds: string[],
        name: string,
        principalTenantId: string,
        reasonRequired: boolean,
        scopeId: string,
        public permissionRequestScopeIds: string[]) {
        super(
            approval,
            description,
            expirationTimeFrame,
            granteePrincipalIds,
            name,
            principalTenantId,
            reasonRequired,
            scopeId);
    }
}

export class ConfigurationControllerInsertPingIdentityDirectoryGroupMembershipEligibilityRequest extends ConfigurationControllerInsertGroupMembershipEligibilityRequest {
    constructor(
        approval: Optional<ConfigurationControllerPermissionEligibilityApproval>,
        description: Optional<string>,
        expirationTimeFrame: string,
        granteePrincipalIds: string[],
        name: string,
        principalTenantId: string,
        reasonRequired: boolean,
        scopeId: string,
        groupIds: string[]) {
        super(
            approval,
            description,
            expirationTimeFrame,
            granteePrincipalIds,
            name,
            principalTenantId,
            reasonRequired,
            scopeId,
            groupIds);
    }
}

export class ConfigurationControllerUpdateGroupMembershipEligibilityRequest extends ConfigurationControllerUpdatePermissionEligibilityRequest {
    constructor(
        approval: Optional<ConfigurationControllerPermissionEligibilityApproval>,
        description: Optional<string>,
        expirationTimeFrame: string,
        granteePrincipalIds: string[],
        id: string,
        name: string,
        principalTenantId: string,
        reasonRequired: boolean,
        public groupIds: string[]) {
        super(
            approval,
            description,
            expirationTimeFrame,
            granteePrincipalIds,
            id,
            name,
            principalTenantId,
            reasonRequired);
    }
}

export class ConfigurationControllerUpdateOktaDirectoryGroupMembershipEligibilityRequest extends ConfigurationControllerUpdateGroupMembershipEligibilityRequest {
    constructor(
        approval: Optional<ConfigurationControllerPermissionEligibilityApproval>,
        description: Optional<string>,
        expirationTimeFrame: string,
        granteePrincipalIds: string[],
        id: string,
        name: string,
        principalTenantId: string,
        reasonRequired: boolean,
        groupIds: string[]) {
        super(
            approval,
            description,
            expirationTimeFrame,
            granteePrincipalIds,
            id,
            name,
            principalTenantId,
            reasonRequired,
            groupIds);
    }
}

export class ConfigurationControllerUpdateOneLoginDirectoryRoleAssignmentEligibilityRequest extends ConfigurationControllerUpdatePermissionEligibilityRequest {
    constructor(
        approval: Optional<ConfigurationControllerPermissionEligibilityApproval>,
        description: Optional<string>,
        expirationTimeFrame: string,
        granteePrincipalIds: string[],
        id: string,
        name: string,
        principalTenantId: string,
        reasonRequired: boolean,
        public roleIds: string[]) {
        super(
            approval,
            description,
            expirationTimeFrame,
            granteePrincipalIds,
            id,
            name,
            principalTenantId,
            reasonRequired);
    }
}

export class ConfigurationControllerUpdatePermissionAssignmentEligibilityRequest extends ConfigurationControllerUpdatePermissionEligibilityRequest {
    constructor(
        approval: Optional<ConfigurationControllerPermissionEligibilityApproval>,
        description: Optional<string>,
        expirationTimeFrame: string,
        granteePrincipalIds: string[],
        id: string,
        name: string,
        principalTenantId: string,
        reasonRequired: boolean,
        public permissionRequestScopeIds: string[]) {
        super(
            approval,
            description,
            expirationTimeFrame,
            granteePrincipalIds,
            id,
            name,
            principalTenantId,
            reasonRequired);
    }
}

export class ConfigurationControllerUpdatePingIdentityDirectoryGroupMembershipEligibilityRequest extends ConfigurationControllerUpdateGroupMembershipEligibilityRequest {
    constructor(
        approval: Optional<ConfigurationControllerPermissionEligibilityApproval>,
        description: Optional<string>,
        expirationTimeFrame: string,
        granteePrincipalIds: string[],
        id: string,
        name: string,
        principalTenantId: string,
        reasonRequired: boolean,
        groupIds: string[]) {
        super(
            approval,
            description,
            expirationTimeFrame,
            granteePrincipalIds,
            id,
            name,
            principalTenantId,
            reasonRequired,
            groupIds);
    }
}

export class ConfigurationControllerUpsertAadSeverePermissionDirectoryRoleDefinitionsConfigurationRequest extends ConfigurationControllerUpsertScopeSettingConfigurationRequest {
    constructor(
        id: Optional<string>,
        scopeId: string,
        public directoryRoleDefinitionDatas: AadSeverePermissionDirectoryRoleDefinitionData[]) {
        super(
            id,
            scopeId);
    }
}

export class ConsoleAppUrlBuilderConfigurationSection extends UrlBuilderConfigurationSection {
    constructor(
        url: string) {
        super(url);
    }
}

export class ContainerImageRepositoryVulnerabilityRiskMetadata extends WorkloadResourceRiskTypeMetadata {
    constructor() {
        super();
    }
}

export class ContainerImageScanFilters extends WorkloadResourceScanFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        scanTimeRange: FilterTimeRange,
        tenantIdItems: FilterItems<string>,
        workloadResourceTypeNameItems: FilterItems<string>,
        public creationTimeRange: FilterTimeRange) {
        super(
            attributeValueItems,
            scanTimeRange,
            tenantIdItems,
            workloadResourceTypeNameItems);
    }
}

export class CustomComplianceAuditEvent extends AuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        public builtInRiskPolicyConfigurationTypeNames: string[],
        public customRiskPolicyNames: string[],
        public description: Optional<string>,
        public name: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime);
    }
}

export class CustomComplianceCreationAuditEvent extends CustomComplianceAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        builtInRiskPolicyConfigurationTypeNames: string[],
        customRiskPolicyNames: string[],
        description: Optional<string>,
        name: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "CustomComplianceCreationAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            builtInRiskPolicyConfigurationTypeNames,
            customRiskPolicyNames,
            description,
            name);
    }
}

export class CustomComplianceDeletionAuditEvent extends CustomComplianceAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        builtInRiskPolicyConfigurationTypeNames: string[],
        customRiskPolicyNames: string[],
        description: Optional<string>,
        name: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "CustomComplianceDeletionAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            builtInRiskPolicyConfigurationTypeNames,
            customRiskPolicyNames,
            description,
            name);
    }
}

export class CustomComplianceRiskPolicy extends Subdocument implements ITypeNameObject {
    constructor(
        public analysisGroupType: RiskPolicyTypeMetadataAnalysisGroupType,
        public identifier: string) {
        super("CustomComplianceRiskPolicy");
    }
}

export class CustomComplianceSection extends Subdocument implements ITypeNameObject {
    constructor(
        public description: Optional<string>,
        public id: string,
        public name: string,
        public riskPolicies: CustomComplianceRiskPolicy[],
        public sections: CustomComplianceSection[]) {
        super("CustomComplianceSection");
    }
}

export class CustomComplianceUpdateAuditEvent extends CustomComplianceAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        builtInRiskPolicyConfigurationTypeNames: string[],
        customRiskPolicyNames: string[],
        description: Optional<string>,
        name: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "CustomComplianceUpdateAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            builtInRiskPolicyConfigurationTypeNames,
            customRiskPolicyNames,
            description,
            name);
    }
}

export class CustomerConfiguration extends SystemEntityConfiguration implements ITypeNameObject, IScopeConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        public customerSections: CustomerConfigurationSections,
        public featureNameToEnabledMap: Dictionary<boolean>,
        public name: string,
        public scopeSections: ScopeConfigurationSections) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "CustomerConfiguration");
    }
}

export class CustomerConsoleSignInAuditEvent extends AuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "CustomerConsoleSignInAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime);
    }
}

export class CustomerConsoleSignOutAuditEvent extends AuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "CustomerConsoleSignOutAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime);
    }
}

export class CustomerLogoConfiguration extends SystemEntityConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        public contentBytes: string,
        public contentType: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "CustomerLogoConfiguration");
    }
}

export class CustomerState extends SystemEntityState implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        public analysis: CustomerStateAnalysis,
        public automations: CustomerStateAutomations,
        public events: CustomerStateEvents,
        public orchestration: CustomerStateOrchestration) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "CustomerState");
    }
}

export class CustomRiskPolicyTypeMetadata extends RiskPolicyTypeMetadata {
    constructor(
        builtInComplianceSectionTypeToAnalysisGroupTypeMap: Dictionary<RiskPolicyTypeMetadataAnalysisGroupType>,
        category: RiskPolicyCategory,
        complianceRiskedEntityTypeName: Optional<string>,
        disabledAwsTenantPartitionTypes: AwsPartitionType[],
        licenseType: ApplicationCustomerConfigurationLicensingLicenseType,
        riskedEntityTypeName: Optional<string>,
        riskPolicyEntityCreationTimeAnalysisDelayTimeFrameChangeable: boolean,
        severity: Optional<Severity>,
        temporal: boolean) {
        super(
            builtInComplianceSectionTypeToAnalysisGroupTypeMap,
            category,
            complianceRiskedEntityTypeName,
            disabledAwsTenantPartitionTypes,
            licenseType,
            riskedEntityTypeName,
            riskPolicyEntityCreationTimeAnalysisDelayTimeFrameChangeable,
            severity,
            temporal);
    }
}

export class DagNode extends Subdocument implements ITypeNameObject {
    constructor(
        typeName: string) {
        super(typeName);
    }
}

export class DataAnalysisResourceModelFiltersSection extends SummarySection implements ISummarySection {
    constructor(
        public dataCategoryItems: FilterItems<DataCategory>,
        public dataClassifierIdItems: FilterItems<string>,
        public scanTimeRange: FilterTimeRange) {
        super();
    }
}

export class DataAnalysisResourcePriorityScanRequestUpdateAuditEvent extends AuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        public dataAnalysisResourceId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "DataAnalysisResourcePriorityScanRequestUpdateAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime);
    }
}

export class DataAnalysisResourceScan extends TenantDocument implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        public classificationExists: boolean,
        public classificationExistsDataSegmentNameToDataMap: Dictionary<DataAnalysisResourceScanDataSegmentData>,
        public dataClassifierIds: string[],
        public dataSegmentNameToMetadataMap: Optional<Dictionary<DataSegmentMetadata>>,
        public resourceId: string,
        public resourceTypeName: string,
        public scanEngineVersion: Optional<number>,
        public scannedDataSegmentCount: number,
        public scanTime: string,
        public sensitiveDataSegmentCount: number) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "DataAnalysisResourceScan",
            tenantId);
    }
}

export class DataAnalysisUpdateAuditEvent extends AuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        public managedDatabaseScanEnabled: Optional<boolean>,
        public managedDatabaseScanEnabledChanged: boolean,
        public objectStoreScanEnabled: Optional<boolean>,
        public objectStoreScanEnabledChanged: boolean,
        public privateObjectStoreScanEnabled: Optional<boolean>,
        public privateObjectStoreScanEnabledChanged: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "DataAnalysisUpdateAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime);
    }
}

export class DataClassifierSampleLocation extends Subdocument implements ITypeNameObject {
    constructor(
        typeName: string) {
        super(typeName);
    }
}

export class DatadogOrganizationAuditEvent extends AuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        public datadogOrganizationName: string,
        public datadogOrganizationSite: DatadogOrganizationConfigurationSite) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime);
    }
}

export class DatadogOrganizationCreationAuditEvent extends DatadogOrganizationAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        datadogOrganizationName: string,
        datadogOrganizationSite: DatadogOrganizationConfigurationSite) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "DatadogOrganizationCreationAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            datadogOrganizationName,
            datadogOrganizationSite);
    }
}

export class DatadogOrganizationDeletionAuditEvent extends DatadogOrganizationAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        datadogOrganizationName: string,
        datadogOrganizationSite: DatadogOrganizationConfigurationSite) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "DatadogOrganizationDeletionAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            datadogOrganizationName,
            datadogOrganizationSite);
    }
}

export class DatadogOrganizationState extends SystemEntityState implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        public issue: Optional<DatadogOrganizationStateIssue>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "DatadogOrganizationState");
    }
}

export class DatadogOrganizationUpdateAuditEvent extends DatadogOrganizationAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        datadogOrganizationName: string,
        datadogOrganizationSite: DatadogOrganizationConfigurationSite,
        public datadogOrganizationApiKeyChanged: boolean,
        public datadogOrganizationNameChanged: boolean,
        public datadogOrganizationSiteChanged: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "DatadogOrganizationUpdateAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            datadogOrganizationName,
            datadogOrganizationSite);
    }
}

export class DataSegmentMetadata extends Subdocument implements ITypeNameObject {
    constructor(
        typeName: string,
        public lastModified: Optional<string>) {
        super(typeName);
    }
}

export class DeletedRisk extends TenantDocument implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        public description: string,
        public reason: string,
        public tenantType: TenantType,
        public title: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "DeletedRisk",
            tenantId);
    }
}

export class Delivery extends Subdocument implements ITypeNameObject {
    constructor(
        typeName: string,
        public destinations: DeliveryDestination[],
        public message: Optional<string>) {
        super(typeName);
    }
}

export class DeliveryDestination extends Subdocument implements ITypeNameObject {
    constructor(
        typeName: string) {
        super(typeName);
    }
}

export class DeliveryIntegration extends DeliveryDestination implements ITypeNameObject {
    constructor(
        typeName: string,
        public integrationId: string) {
        super(typeName);
    }
}

export class DocumentSharedFragmentData extends Subdocument implements ITypeNameObject, IConsistentHashable {
    constructor(
        typeName: string) {
        super(typeName);
    }
}

export class ElasticsearchFilterItemPage<TItem> extends FilterItems<TItem> {
    constructor(
        emptyValue: boolean,
        items: TItem[],
        public count: Optional<number>,
        public itemNextPageSearchCursor: Optional<ElasticsearchIndexSearchCursor>) {
        super(
            emptyValue,
            items);
    }
}

export class ElasticsearchIndexAggregationCursor extends ElasticsearchIndexSearchCursor {
    constructor(
        sortValues: any[]) {
        super(sortValues);
    }
}

export class EndpointConnectorAuditEvent extends AuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        public endpoint: string,
        public name: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime);
    }
}

export class EndpointConnectorCreationAuditEvent extends EndpointConnectorAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        endpoint: string,
        name: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "EndpointConnectorCreationAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            endpoint,
            name);
    }
}

export class EndpointConnectorDeletionAuditEvent extends EndpointConnectorAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        endpoint: string,
        name: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "EndpointConnectorDeletionAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            endpoint,
            name);
    }
}

export class Entity extends TenantDocument implements ITypeNameObject, IEntity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        public displayName: string,
        public displayReference: string,
        public regionId: Optional<string>,
        public scopeIds: string[],
        public syncTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId);
    }
}

export class EntityAttribute extends Subdocument implements ITypeNameObject {
    constructor(
        typeName: string) {
        super(typeName);
    }
}

export class EntityControllerGetAwsEntityExcessivePermissionCustomEvaluationDataRequest extends EntityControllerGetEntityExcessivePermissionCustomEvaluationDataRequest {
    constructor(
        entityId: string) {
        super(entityId);
    }
}

export class EntityControllerGetAwsNetworkGraphRequest extends EntityControllerGetNetworkGraphRequest {
    constructor(
        id: string,
        public filters: EntityControllerGetAwsNetworkGraphRequestFilters) {
        super(id);
    }
}

export class EntityControllerGetAwsNetworkGraphRequestFilters extends EntityControllerGetNetworkGraphRequestFilters {
    constructor(
        destinationScopeFilterIdSelection: Optional<ItemSelection<string>>,
        sourceEntityIdSelection: Optional<ItemSelection<string>>,
        sourceSubnetSelection: Optional<ItemSelection<string>>,
        wideRangeSourceSubnet: boolean,
        public securityGroupIdSelection: Optional<ItemSelection<string>>) {
        super(
            destinationScopeFilterIdSelection,
            sourceEntityIdSelection,
            sourceSubnetSelection,
            wideRangeSourceSubnet);
    }
}

export class EntityControllerGetAzureEntityExcessivePermissionCustomEvaluationDataRequest extends EntityControllerGetEntityExcessivePermissionCustomEvaluationDataRequest {
    constructor(
        entityId: string,
        public scopeResourceIds: string[]) {
        super(entityId);
    }
}

export class EntityControllerGetAzureNetworkGraphRequest extends EntityControllerGetNetworkGraphRequest {
    constructor(
        id: string,
        public filters: EntityControllerGetAzureNetworkGraphRequestFilters) {
        super(id);
    }
}

export class EntityControllerGetAzureNetworkGraphRequestFilters extends EntityControllerGetNetworkGraphRequestFilters {
    constructor(
        destinationScopeFilterIdSelection: Optional<ItemSelection<string>>,
        sourceEntityIdSelection: Optional<ItemSelection<string>>,
        sourceSubnetSelection: Optional<ItemSelection<string>>,
        wideRangeSourceSubnet: boolean,
        public interfaceNetworkSecurityGroupIdSelection: Optional<ItemSelection<string>>) {
        super(
            destinationScopeFilterIdSelection,
            sourceEntityIdSelection,
            sourceSubnetSelection,
            wideRangeSourceSubnet);
    }
}

export class EntityControllerGetEntityModelFilterItemPageResponse<TItem> extends EntityControllerGetEntityModelFilterItemPageResponseBase {
    constructor(
        public entityModelFilterItemPage: ElasticsearchFilterItemPage<TItem>) {
        super();
    }
}

export class EntityControllerGetEntityModelPageAadDirectoryEntityRequest extends EntityControllerGetEntityModelPageRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort) {
        super(
            filters,
            limit,
            skip,
            sort);
    }
}

export class EntityControllerGetEntityModelPageAadDirectoryEntityRequestFilters extends EntityControllerGetEntityModelPageRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        public creationTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection);
    }
}

export class EntityControllerGetEntityModelPageAadDirectoryPrincipalRequest extends EntityControllerGetEntityModelPageAadDirectoryEntityRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort) {
        super(
            filters,
            limit,
            skip,
            sort);
    }
}

export class EntityControllerGetEntityModelPageAadDirectoryPrincipalRequestFilters extends EntityControllerGetEntityModelPageAadDirectoryEntityRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public azureRoleDefinitionIdSelection: Optional<PagedItemSelection<string>>,
        public containedAzureRoleAssignmentResourceExists: Optional<boolean>,
        public directoryRoleDefinitionIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAadTenantEntityRequest extends EntityControllerGetEntityModelPageAadDirectoryEntityRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort) {
        super(
            filters,
            limit,
            skip,
            sort);
    }
}

export class EntityControllerGetEntityModelPageAadTenantEntityRequestFilters extends EntityControllerGetEntityModelPageAadDirectoryEntityRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public defaultUserRoleApplicationCreationEnabledSelection: Optional<ItemSelection<boolean>>,
        public defaultUserRoleTenantCreationEnabledSelection: Optional<ItemSelection<boolean>>,
        public externalUserDefaultRoleSelection: Optional<ItemSelection<AadTenantEntityExternalUserDefaultRole>>,
        public externalUserInvitationAllowedRolesSelection: Optional<ItemSelection<AadTenantEntityExternalUserInvitationAllowedRoles>>,
        public primaryDomainNameSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsResourceRequest extends EntityControllerGetEntityModelPageRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        public tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort);
    }
}

export class EntityControllerGetEntityModelPageAwsResourceRequestAccess extends EntityControllerGetEntityModelPageEntityRequestSection {
    constructor(
        public accessLevelSelection: Optional<ItemSelection<AwsResourceAccessLevel>>) {
        super();
    }
}

export class EntityControllerGetEntityModelPageAwsResourceRequestCloudWatchLogging extends EntityControllerGetEntityModelPageEntityRequestSection {
    constructor(
        public cloudWatchLoggingEnabled: Optional<boolean>,
        public cloudWatchLogTypeSelection: Optional<PagedItemSelection<string>>) {
        super();
    }
}

export class EntityControllerGetEntityModelPageAwsResourceRequestEncrypted extends EntityControllerGetEntityModelPageEntityRequestSection {
    constructor(
        public encryptionExists: Optional<boolean>,
        public kmsEncryptionKeyIdSelection: Optional<PagedItemSelection<string>>) {
        super();
    }
}

export class EntityControllerGetEntityModelPageAwsResourceRequestFilters extends EntityControllerGetEntityModelPageRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        public creationTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsResourceRequestNetworked extends EntityControllerGetEntityModelPageEntityRequestSection {
    constructor(
        public securityGroupIdSelection: Optional<PagedItemSelection<string>>,
        public vpcIdSelection: Optional<PagedItemSelection<string>>) {
        super();
    }
}

export class EntityControllerGetEntityModelPageAwsRoute53HostedZoneRequest extends EntityControllerGetEntityModelPageAwsResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsRoute53HostedZoneRequestFilters extends EntityControllerGetEntityModelPageAwsResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public descriptionSelection: Optional<PagedItemSelection<string>>,
        public publicNetworkAccess: Optional<boolean>,
        public signingStatusSelection: Optional<ItemSelection<AwsRoute53HostedZoneSigningStatus>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsS3BucketRequest extends EntityControllerGetEntityModelPageAwsResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public access: EntityControllerGetEntityModelPageAwsResourceRequestAccess,
        public dataAnalysis: EntityControllerGetEntityModelPageEntityRequestDataAnalysis,
        public encrypted: EntityControllerGetEntityModelPageAwsResourceRequestEncrypted) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsS3BucketRequestFilters extends EntityControllerGetEntityModelPageAwsResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public objectEventSelectorTypeSelection: Optional<ItemSelection<AwsCloudTrailTrailEventSelectorType>>,
        public objectLifecycleConfigurationEnabledSelection: Optional<ItemSelection<boolean>>,
        public objectVersioningEnabled: Optional<boolean>,
        public serverSideEncryptionTypeSelection: Optional<ItemSelection<AwsS3BucketServerSideEncryptionType>>,
        public websiteEnabled: Optional<boolean>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsSageMakerNotebookInstanceRequest extends EntityControllerGetEntityModelPageAwsResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public encrypted: EntityControllerGetEntityModelPageAwsResourceRequestEncrypted,
        public networked: EntityControllerGetEntityModelPageAwsResourceRequestNetworked) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsSageMakerNotebookInstanceRequestFilters extends EntityControllerGetEntityModelPageAwsResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public metadataServiceVersionSelection: Optional<ItemSelection<AwsSageMakerNotebookInstanceMetadataServiceVersion>>,
        public rawTypeSelection: Optional<ItemSelection<string>>,
        public roleIdSelection: Optional<PagedItemSelection<string>>,
        public rootAccess: Optional<boolean>,
        public statusSelection: Optional<ItemSelection<AwsSageMakerNotebookInstanceStatus>>,
        public unRestrictedOutboundAccess: Optional<boolean>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsSecretsManagerSecretRequest extends EntityControllerGetEntityModelPageAwsResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public access: EntityControllerGetEntityModelPageAwsResourceRequestAccess,
        public encrypted: EntityControllerGetEntityModelPageAwsResourceRequestEncrypted) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsSecretsManagerSecretRequestFilters extends EntityControllerGetEntityModelPageAwsResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public usageTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsServiceRequest extends EntityControllerGetEntityModelPageRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort) {
        super(
            filters,
            limit,
            skip,
            sort);
    }
}

export class EntityControllerGetEntityModelPageAwsServiceRequestFilters extends EntityControllerGetEntityModelPageRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsSnsTopicRequest extends EntityControllerGetEntityModelPageAwsResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public access: EntityControllerGetEntityModelPageAwsResourceRequestAccess,
        public encrypted: EntityControllerGetEntityModelPageAwsResourceRequestEncrypted) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsSnsTopicRequestFilters extends EntityControllerGetEntityModelPageAwsResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public typeSelection: Optional<ItemSelection<AwsSnsTopicType>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsSqsQueueRequest extends EntityControllerGetEntityModelPageAwsResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public access: EntityControllerGetEntityModelPageAwsResourceRequestAccess,
        public encrypted: EntityControllerGetEntityModelPageAwsResourceRequestEncrypted) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsSqsQueueRequestFilters extends EntityControllerGetEntityModelPageAwsResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public typeSelection: Optional<ItemSelection<AwsSqsQueueType>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsSsmParameterRequest extends EntityControllerGetEntityModelPageAwsResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public encrypted: EntityControllerGetEntityModelPageAwsResourceRequestEncrypted) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsSsmParameterRequestFilters extends EntityControllerGetEntityModelPageAwsResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public typeSelection: Optional<ItemSelection<AwsSsmParameterType>>,
        public updateIdentityIdSelection: Optional<PagedItemSelection<string>>,
        public updateTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsSsoPermissionSetRequest extends EntityControllerGetEntityModelPageAwsResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsSsoPermissionSetRequestFilters extends EntityControllerGetEntityModelPageAwsResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public accountIdSelection: Optional<PagedItemSelection<string>>,
        public principalIdSelection: Optional<PagedItemSelection<string>>,
        public provisioned: Optional<boolean>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsSsoPrincipalRequest extends EntityControllerGetEntityModelPageAwsResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsSsoPrincipalRequestFilters extends EntityControllerGetEntityModelPageAwsResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public roleIdSelection: Optional<PagedItemSelection<string>>,
        public roleTenantIds: Optional<ItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsSsoUserRequest extends EntityControllerGetEntityModelPageAwsSsoPrincipalRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsSsoUserRequestFilters extends EntityControllerGetEntityModelPageAwsSsoPrincipalRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        roleIdSelection: Optional<PagedItemSelection<string>>,
        roleTenantIds: Optional<ItemSelection<string>>,
        public active: Optional<boolean>,
        public groupIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            roleIdSelection,
            roleTenantIds);
    }
}

export class EntityControllerGetEntityModelPageAwsTenantEntityRequest extends EntityControllerGetEntityModelPageAwsResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsTenantEntityRequestFilters extends EntityControllerGetEntityModelPageAwsResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public accountOrganizationJoinMethodSelection: Optional<ItemSelection<AwsOrganizationsAccountOrganizationJoinMethod>>,
        public accountStatusSelection: Optional<ItemSelection<AwsOrganizationsAccountStatus>>,
        public parentPathSelection: Optional<PagedItemSelection<string>>,
        public resourceControlPolicyIdSelection: Optional<PagedItemSelection<string>>,
        public rootUserMailSelection: Optional<PagedItemSelection<string>>,
        public serviceControlPolicyIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsTimestreamDatabaseRequest extends EntityControllerGetEntityModelPageAwsResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public encrypted: EntityControllerGetEntityModelPageAwsResourceRequestEncrypted) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsTimestreamDatabaseRequestFilters extends EntityControllerGetEntityModelPageAwsResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsTimestreamDatabaseTableRequest extends EntityControllerGetEntityModelPageAwsResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsTimestreamDatabaseTableRequestFilters extends EntityControllerGetEntityModelPageAwsResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public databaseIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsUnmanagedKubernetesClusterRequest extends EntityControllerGetEntityModelPageRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort) {
        super(
            filters,
            limit,
            skip,
            sort);
    }
}

export class EntityControllerGetEntityModelPageAwsUnmanagedKubernetesClusterRequestFilters extends EntityControllerGetEntityModelPageRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        public apiConnectivityStatusSelection: Optional<ItemSelection<SystemKubernetesClusterApiConnectivityStatus>>,
        public creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public platformSelection: Optional<ItemSelection<KubernetesClusterPlatform>>,
        public versionSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsWafWebAclRequest extends EntityControllerGetEntityModelPageAwsResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsWafWebAclRequestFilters extends EntityControllerGetEntityModelPageAwsResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public associatedResourceIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAzureResourceRequest extends EntityControllerGetEntityModelPageRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        public tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort);
    }
}

export class EntityControllerGetEntityModelPageAzureResourceRequestEncrypted extends EntityControllerGetEntityModelPageEntityRequestSection {
    constructor(
        public encryptionExists: Optional<boolean>,
        public vaultKeyIdSelection: Optional<PagedItemSelection<string>>) {
        super();
    }
}

export class EntityControllerGetEntityModelPageAzureResourceRequestFilters extends EntityControllerGetEntityModelPageRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        public creationTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection);
    }
}

export class EntityControllerGetEntityModelPageAzureResourcesResourceGroupRequest extends EntityControllerGetEntityModelPageAzureResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAzureResourcesResourceGroupRequestFilters extends EntityControllerGetEntityModelPageAzureResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAzureTenantEntityRequest extends EntityControllerGetEntityModelPageAzureResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAzureTenantEntityRequestFilters extends EntityControllerGetEntityModelPageAzureResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public parentPathSelection: Optional<PagedItemSelection<string>>,
        public rawShortIdSelection: Optional<PagedItemSelection<string>>,
        public resourceGroupIdSelection: Optional<PagedItemSelection<string>>,
        public statusSelection: Optional<ItemSelection<AzureTenantEntityStatus>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAzureUnmanagedKubernetesClusterRequest extends EntityControllerGetEntityModelPageRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort) {
        super(
            filters,
            limit,
            skip,
            sort);
    }
}

export class EntityControllerGetEntityModelPageAzureUnmanagedKubernetesClusterRequestFilters extends EntityControllerGetEntityModelPageRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        public apiConnectivityStatusSelection: Optional<ItemSelection<SystemKubernetesClusterApiConnectivityStatus>>,
        public creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public platformSelection: Optional<ItemSelection<KubernetesClusterPlatform>>,
        public versionSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection);
    }
}

export class EntityControllerGetEntityModelPageCiContainerImageRepositoryRequest extends EntityControllerGetEntityModelPageRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort) {
        super(
            filters,
            limit,
            skip,
            sort);
    }
}

export class EntityControllerGetEntityModelPageCiContainerImageRepositoryRequestFilters extends EntityControllerGetEntityModelPageRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        public containerImageResourceIdSelection: Optional<PagedItemSelection<string>>,
        public containerImageVulnerabilityIdSelection: Optional<PagedItemSelection<string>>,
        public containerImageVulnerabilitySeveritySelection: Optional<ItemSelection<Severity>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection);
    }
}

export class EntityControllerGetEntityModelPageCiContainerImageRequest extends EntityControllerGetEntityModelPageRequest implements IEntityControllerGetEntityModelPageContainerImageRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort) {
        super(
            filters,
            limit,
            skip,
            sort);
    }
}

export class EntityControllerGetEntityModelPageCiContainerImageRequestFilters extends EntityControllerGetEntityModelPageRequestFilters implements IEntityControllerGetEntityModelPageContainerImageRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        public containerImageCreationTimeRangeSelection: Optional<TimeRangeSelection>,
        public containerImageTagSelection: Optional<PagedItemSelection<string>>,
        public digestSelection: Optional<PagedItemSelection<string>>,
        public kubernetesWorkloadResourceIdSelection: Optional<PagedItemSelection<string>>,
        public repositoryIdSelection: Optional<PagedItemSelection<string>>,
        public scanTimeRangeSelection: Optional<TimeRangeSelection>,
        public usage: Optional<boolean>,
        public virtualMachineIdSelection: Optional<PagedItemSelection<string>>,
        public vulnerabilityRawIdSelection: Optional<PagedItemSelection<string>>,
        public vulnerabilitySeveritySelection: Optional<ItemSelection<Severity>>,
        public workloadClusterResourceIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection);
    }
}

export class EntityControllerGetEntityModelPageCiDefaultResourceRequest extends EntityControllerGetEntityModelPageRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort) {
        super(
            filters,
            limit,
            skip,
            sort);
    }
}

export class EntityControllerGetEntityModelPageCiDefaultResourceRequestFilters extends EntityControllerGetEntityModelPageRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        public typeName: string) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection);
    }
}

export class EntityControllerGetEntityModelPageDataAnalysisResourceRequest extends EntityControllerGetEntityModelPageRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        public dataAnalysis: EntityControllerGetEntityModelPageEntityRequestDataAnalysis,
        public tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort);
    }
}

export class EntityControllerGetEntityModelPageDataAnalysisResourceRequestFilters extends EntityControllerGetEntityModelPageRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        public creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public entityTypeNameSelection: Optional<ItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection);
    }
}

export class EntityControllerGetEntityModelPageEntityRequestDataAnalysis extends EntityControllerGetEntityModelPageEntityRequestSection {
    constructor(
        public dataAnalysisStatusSelection: Optional<ItemSelection<DataAnalysisResourceModelDataAnalysisStatus>>,
        public dataCategorySelection: Optional<ItemSelection<DataCategory>>,
        public dataClassifierIdSelection: Optional<ItemSelection<string>>,
        public dataSensitivitySelection: Optional<ItemSelection<DataSensitivity>>,
        public scanTimeRangeSelection: Optional<TimeRangeSelection>) {
        super();
    }
}

export class EntityControllerGetEntityModelPageEntityRequestKubernetesIngress extends EntityControllerGetEntityModelPageEntityRequestSection {
    constructor(
        public loadBalancerIdSelection: Optional<PagedItemSelection<string>>) {
        super();
    }
}

export class EntityControllerGetEntityModelPageEntityRequestKubernetesNamespace extends EntityControllerGetEntityModelPageEntityRequestSection {
    constructor(
        public resourceIdSelection: Optional<PagedItemSelection<string>>) {
        super();
    }
}

export class EntityControllerGetEntityModelPageEntityRequestKubernetesNamespaceResource extends EntityControllerGetEntityModelPageEntityRequestSection {
    constructor(
        public namespaceIdSelection: Optional<PagedItemSelection<string>>) {
        super();
    }
}

export class EntityControllerGetEntityModelPageEntityRequestKubernetesPrincipal extends EntityControllerGetEntityModelPageEntityRequestSection {
    constructor(
        public roleIdSelection: Optional<PagedItemSelection<string>>) {
        super();
    }
}

export class EntityControllerGetEntityModelPageEntityRequestKubernetesResource extends EntityControllerGetEntityModelPageEntityRequestSection {
    constructor(
        public clusterIdSelection: Optional<PagedItemSelection<string>>) {
        super();
    }
}

export class EntityControllerGetEntityModelPageEntityRequestKubernetesRole extends EntityControllerGetEntityModelPageEntityRequestSection {
    constructor(
        public principalIdSelection: Optional<PagedItemSelection<string>>) {
        super();
    }
}

export class EntityControllerGetEntityModelPageEntityRequestKubernetesRoleBinding extends EntityControllerGetEntityModelPageEntityRequestSection {
    constructor(
        public principalIdSelection: Optional<PagedItemSelection<string>>,
        public roleIdSelection: Optional<PagedItemSelection<string>>) {
        super();
    }
}

export class EntityControllerGetEntityModelPageEntityRequestKubernetesService extends EntityControllerGetEntityModelPageEntityRequestSection {
    constructor(
        public typeSelection: Optional<ItemSelection<KubernetesServiceDataType>>) {
        super();
    }
}

export class EntityControllerGetEntityModelPageEntityRequestNetworkAccess extends EntityControllerGetEntityModelPageEntityRequestSection {
    constructor(
        public networkInboundAccessTypeSelection: Optional<ItemSelection<NetworkInboundAccessType>>,
        public networkInboundExternalAccessScopeSelection: Optional<ItemSelection<NetworkAccessScope>>,
        public networkInboundExternalDestinationNetworkScopeFilterIdSelection: Optional<ItemSelection<string>>) {
        super();
    }
}

export class EntityControllerGetEntityModelPageGciDefaultDirectoryEntityRequest extends EntityControllerGetEntityModelPageRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort) {
        super(
            filters,
            limit,
            skip,
            sort);
    }
}

export class EntityControllerGetEntityModelPageGciDefaultDirectoryEntityRequestFilters extends EntityControllerGetEntityModelPageRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        public typeName: string) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection);
    }
}

export class EntityControllerGetEntityModelPageGciDirectoryDirectoryRoleRequest extends EntityControllerGetEntityModelPageRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort) {
        super(
            filters,
            limit,
            skip,
            sort);
    }
}

export class EntityControllerGetEntityModelPageGciDirectoryDirectoryRoleRequestFilters extends EntityControllerGetEntityModelPageRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        public assignedIdentityIdSelection: Optional<PagedItemSelection<string>>,
        public typeSelection: Optional<ItemSelection<GciDirectoryDirectoryRoleType>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection);
    }
}

export class EntityControllerGetEntityModelPageGciDirectoryPartialEntityRequest extends EntityControllerGetEntityModelPageRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort) {
        super(
            filters,
            limit,
            skip,
            sort);
    }
}

export class EntityControllerGetEntityModelPageGciDirectoryPartialEntityRequestFilters extends EntityControllerGetEntityModelPageRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection);
    }
}

export class EntityControllerGetEntityModelPageGciDirectoryPartialPrincipalRequest extends EntityControllerGetEntityModelPageGciDirectoryPartialEntityRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort) {
        super(
            filters,
            limit,
            skip,
            sort);
    }
}

export class EntityControllerGetEntityModelPageGciDirectoryPartialPrincipalRequestFilters extends EntityControllerGetEntityModelPageGciDirectoryPartialEntityRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        public gcpContainedRoleBindingExists: Optional<boolean>,
        public gcpRoleIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection);
    }
}

export class EntityControllerGetEntityModelPageGciDirectoryPartialUserRequest extends EntityControllerGetEntityModelPageGciDirectoryPartialPrincipalRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort) {
        super(
            filters,
            limit,
            skip,
            sort);
    }
}

export class EntityControllerGetEntityModelPageGciDirectoryPartialUserRequestFilters extends EntityControllerGetEntityModelPageGciDirectoryPartialPrincipalRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        gcpContainedRoleBindingExists: Optional<boolean>,
        gcpRoleIdSelection: Optional<PagedItemSelection<string>>,
        public activityTimeRangeSelection: Optional<TimeRangeSelection>,
        public groupIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            gcpContainedRoleBindingExists,
            gcpRoleIdSelection);
    }
}

export class EntityControllerGetEntityModelPageGciDirectoryPrincipalRequest extends EntityControllerGetEntityModelPageRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort) {
        super(
            filters,
            limit,
            skip,
            sort);
    }
}

export class EntityControllerGetEntityModelPageGciDirectoryPrincipalRequestFilters extends EntityControllerGetEntityModelPageRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        public containedRoleBindingExists: Optional<boolean>,
        public creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public gcpRoleIdSelection: Optional<PagedItemSelection<string>>,
        public mailSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection);
    }
}

export class EntityControllerGetEntityModelPageGciDirectoryUserRequest extends EntityControllerGetEntityModelPageGciDirectoryPrincipalRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort) {
        super(
            filters,
            limit,
            skip,
            sort);
    }
}

export class EntityControllerGetEntityModelPageGciDirectoryUserRequestFilters extends EntityControllerGetEntityModelPageGciDirectoryPrincipalRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        containedRoleBindingExists: Optional<boolean>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        gcpRoleIdSelection: Optional<PagedItemSelection<string>>,
        mailSelection: Optional<PagedItemSelection<string>>,
        public activityTimeRangeSelection: Optional<TimeRangeSelection>,
        public groupIdSelection: Optional<PagedItemSelection<string>>,
        public mfaEnforced: Optional<boolean>,
        public signInTimeRangeSelection: Optional<TimeRangeSelection>,
        public statusSelection: Optional<ItemSelection<GciDirectoryUserStatus>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            containedRoleBindingExists,
            creationTimeRangeSelection,
            gcpRoleIdSelection,
            mailSelection);
    }
}

export class EntityControllerGetEntityModelPageGciTenantEntityRequest extends EntityControllerGetEntityModelPageRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort) {
        super(
            filters,
            limit,
            skip,
            sort);
    }
}

export class EntityControllerGetEntityModelPageGciTenantEntityRequestFilters extends EntityControllerGetEntityModelPageRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        public creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public primaryDomainNameSelection: Optional<PagedItemSelection<string>>,
        public rawIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection);
    }
}

export class EntityControllerGetEntityModelPageGcpEntityRequest extends EntityControllerGetEntityModelPageRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort) {
        super(
            filters,
            limit,
            skip,
            sort);
    }
}

export class EntityControllerGetEntityModelPageGcpEntityRequestFilters extends EntityControllerGetEntityModelPageRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        public creationTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection);
    }
}

export class EntityControllerGetEntityModelPageGcpIamPartialServiceAccountRequest extends EntityControllerGetEntityModelPageRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort) {
        super(
            filters,
            limit,
            skip,
            sort);
    }
}

export class EntityControllerGetEntityModelPageGcpIamPartialServiceAccountRequestFilters extends EntityControllerGetEntityModelPageRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        public activityTimeRangeSelection: Optional<TimeRangeSelection>,
        public containedRoleBindingExists: Optional<boolean>,
        public groupIdSelection: Optional<PagedItemSelection<string>>,
        public roleIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection);
    }
}

export class EntityControllerGetEntityModelPageGcpIamRoleBindingRequest extends EntityControllerGetEntityModelPageGcpEntityRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort) {
        super(
            filters,
            limit,
            skip,
            sort);
    }
}

export class EntityControllerGetEntityModelPageGcpIamRoleBindingRequestFilters extends EntityControllerGetEntityModelPageGcpEntityRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public containingRoleBindingExists: Optional<boolean>,
        public expired: Optional<boolean>,
        public permissionUsageTypeSelection: Optional<ItemSelection<GcpIamRoleBindingModelPermissionUsageType>>,
        public principalIdSelection: Optional<PagedItemSelection<string>>,
        public roleIdSelection: Optional<PagedItemSelection<string>>,
        public scopeResourceIdSelection: Optional<PagedItemSelection<string>>,
        public scopeResourceTypeNameSelection: Optional<ItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageGcpResourceRequest extends EntityControllerGetEntityModelPageGcpEntityRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        public tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort);
    }
}

export class EntityControllerGetEntityModelPageGcpResourceRequestFilters extends EntityControllerGetEntityModelPageGcpEntityRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public updateTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageGcpScopeResourceRequest extends EntityControllerGetEntityModelPageGcpResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageGcpScopeResourceRequestFilters extends EntityControllerGetEntityModelPageGcpResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        updateTimeRangeSelection: Optional<TimeRangeSelection>,
        public accessLevelSelection: Optional<ItemSelection<GcpScopeResourceAccessLevel>>,
        public rawIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            updateTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageGcpSecretManagerSecretRequest extends EntityControllerGetEntityModelPageGcpScopeResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public encrypted: EntityControllerGetEntityModelPageGcpScopeResourceRequestEncrypted) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageGcpSecretManagerSecretRequestFilters extends EntityControllerGetEntityModelPageGcpScopeResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        updateTimeRangeSelection: Optional<TimeRangeSelection>,
        accessLevelSelection: Optional<ItemSelection<GcpScopeResourceAccessLevel>>,
        rawIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            updateTimeRangeSelection,
            accessLevelSelection,
            rawIdSelection);
    }
}

export class EntityControllerGetEntityModelPageGcpSpannerInstanceDatabaseRequest extends EntityControllerGetEntityModelPageGcpScopeResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public encrypted: EntityControllerGetEntityModelPageGcpScopeResourceRequestEncrypted) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageGcpSpannerInstanceDatabaseRequestFilters extends EntityControllerGetEntityModelPageGcpScopeResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        updateTimeRangeSelection: Optional<TimeRangeSelection>,
        accessLevelSelection: Optional<ItemSelection<GcpScopeResourceAccessLevel>>,
        rawIdSelection: Optional<PagedItemSelection<string>>,
        public instanceIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            updateTimeRangeSelection,
            accessLevelSelection,
            rawIdSelection);
    }
}

export class EntityControllerGetEntityModelPageGcpSpannerInstanceRequest extends EntityControllerGetEntityModelPageGcpScopeResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageGcpSpannerInstanceRequestFilters extends EntityControllerGetEntityModelPageGcpScopeResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        updateTimeRangeSelection: Optional<TimeRangeSelection>,
        accessLevelSelection: Optional<ItemSelection<GcpScopeResourceAccessLevel>>,
        rawIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            updateTimeRangeSelection,
            accessLevelSelection,
            rawIdSelection);
    }
}

export class EntityControllerGetEntityModelPageGcpSqlInstanceRequest extends EntityControllerGetEntityModelPageGcpScopeResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public dataAnalysis: EntityControllerGetEntityModelPageEntityRequestDataAnalysis,
        public networkAccess: EntityControllerGetEntityModelPageEntityRequestNetworkAccess,
        public serviceAccount: EntityControllerGetEntityModelPageGcpScopeResourceRequestServiceAccount) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageGcpSqlInstanceRequestFilters extends EntityControllerGetEntityModelPageGcpScopeResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        updateTimeRangeSelection: Optional<TimeRangeSelection>,
        accessLevelSelection: Optional<ItemSelection<GcpScopeResourceAccessLevel>>,
        rawIdSelection: Optional<PagedItemSelection<string>>,
        public databaseIdSelection: Optional<PagedItemSelection<string>>,
        public databaseVersionSelection: Optional<ItemSelection<GcpSqlInstanceDatabaseVersion>>,
        public typeSelection: Optional<ItemSelection<GcpSqlInstanceType>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            updateTimeRangeSelection,
            accessLevelSelection,
            rawIdSelection);
    }
}

export class EntityControllerGetEntityModelPageGcpStorageBucketRequest extends EntityControllerGetEntityModelPageGcpScopeResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public dataAnalysis: EntityControllerGetEntityModelPageEntityRequestDataAnalysis,
        public encrypted: EntityControllerGetEntityModelPageGcpScopeResourceRequestEncrypted) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageGcpStorageBucketRequestFilters extends EntityControllerGetEntityModelPageGcpScopeResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        updateTimeRangeSelection: Optional<TimeRangeSelection>,
        accessLevelSelection: Optional<ItemSelection<GcpScopeResourceAccessLevel>>,
        rawIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            updateTimeRangeSelection,
            accessLevelSelection,
            rawIdSelection);
    }
}

export class EntityControllerGetEntityModelPageGcpTenantEntityRequest extends EntityControllerGetEntityModelPageGcpScopeResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageGcpTenantEntityRequestFilters extends EntityControllerGetEntityModelPageGcpScopeResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        updateTimeRangeSelection: Optional<TimeRangeSelection>,
        accessLevelSelection: Optional<ItemSelection<GcpScopeResourceAccessLevel>>,
        rawIdSelection: Optional<PagedItemSelection<string>>,
        public ownerIdSelection: Optional<PagedItemSelection<string>>,
        public parentPathSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            updateTimeRangeSelection,
            accessLevelSelection,
            rawIdSelection);
    }
}

export class EntityControllerGetEntityModelPageGcpUnmanagedKubernetesClusterRequest extends EntityControllerGetEntityModelPageRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort) {
        super(
            filters,
            limit,
            skip,
            sort);
    }
}

export class EntityControllerGetEntityModelPageGcpUnmanagedKubernetesClusterRequestFilters extends EntityControllerGetEntityModelPageRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        public apiConnectivityStatusSelection: Optional<ItemSelection<SystemKubernetesClusterApiConnectivityStatus>>,
        public creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public platformSelection: Optional<ItemSelection<KubernetesClusterPlatform>>,
        public versionSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection);
    }
}

export class EntityControllerGetEntityModelPageOciResourceRequest extends EntityControllerGetEntityModelPageRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        public tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort);
    }
}

export class EntityControllerGetEntityModelPageOciResourceRequestFilters extends EntityControllerGetEntityModelPageRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        public creationTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection);
    }
}

export class EntityControllerGetEntityModelPageOktaDirectoryEntityRequest extends EntityControllerGetEntityModelPageRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort) {
        super(
            filters,
            limit,
            skip,
            sort);
    }
}

export class EntityControllerGetEntityModelPageOktaDirectoryEntityRequestFilters extends EntityControllerGetEntityModelPageRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        public creationTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection);
    }
}

export class EntityControllerGetEntityModelPageOktaDirectoryPrincipalRequest extends EntityControllerGetEntityModelPageOktaDirectoryEntityRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort) {
        super(
            filters,
            limit,
            skip,
            sort);
    }
}

export class EntityControllerGetEntityModelPageOktaDirectoryPrincipalRequestFilters extends EntityControllerGetEntityModelPageOktaDirectoryEntityRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public awsRoleIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageOktaDirectoryUserRequest extends EntityControllerGetEntityModelPageOktaDirectoryPrincipalRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort) {
        super(
            filters,
            limit,
            skip,
            sort);
    }
}

export class EntityControllerGetEntityModelPageOktaDirectoryUserRequestFilters extends EntityControllerGetEntityModelPageOktaDirectoryPrincipalRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        awsRoleIdSelection: Optional<PagedItemSelection<string>>,
        public activityTimeRangeSelection: Optional<TimeRangeSelection>,
        public groupIdSelection: Optional<PagedItemSelection<string>>,
        public rawMailSelection: Optional<PagedItemSelection<string>>,
        public statusSelection: Optional<ItemSelection<OktaDirectoryUserStatus>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            awsRoleIdSelection);
    }
}

export class EntityControllerGetEntityModelPageOneLoginDefaultDirectoryEntityRequest extends EntityControllerGetEntityModelPageRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort) {
        super(
            filters,
            limit,
            skip,
            sort);
    }
}

export class EntityControllerGetEntityModelPageOneLoginDefaultDirectoryEntityRequestFilters extends EntityControllerGetEntityModelPageRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        public typeName: string) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection);
    }
}

export class EntityControllerGetEntityModelPageOneLoginDirectoryGroupRequest extends EntityControllerGetEntityModelPageRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort) {
        super(
            filters,
            limit,
            skip,
            sort);
    }
}

export class EntityControllerGetEntityModelPageOneLoginDirectoryGroupRequestFilters extends EntityControllerGetEntityModelPageRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        public userIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection);
    }
}

export class EntityControllerGetEntityModelPageOneLoginDirectoryRoleRequest extends EntityControllerGetEntityModelPageRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort) {
        super(
            filters,
            limit,
            skip,
            sort);
    }
}

export class EntityControllerGetEntityModelPageOneLoginDirectoryRoleRequestFilters extends EntityControllerGetEntityModelPageRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        public userIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection);
    }
}

export class EntityControllerGetEntityModelPageOneLoginDirectoryUserRequest extends EntityControllerGetEntityModelPageRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort) {
        super(
            filters,
            limit,
            skip,
            sort);
    }
}

export class EntityControllerGetEntityModelPageOneLoginDirectoryUserRequestFilters extends EntityControllerGetEntityModelPageRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        public activityTimeRangeSelection: Optional<TimeRangeSelection>,
        public awsRoleIdSelection: Optional<PagedItemSelection<string>>,
        public creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public groupIdSelection: Optional<PagedItemSelection<string>>,
        public provisioningStateSelection: Optional<ItemSelection<OneLoginDirectoryUserProvisioningState>>,
        public rawMailSelection: Optional<PagedItemSelection<string>>,
        public roleIdSelection: Optional<PagedItemSelection<string>>,
        public statusSelection: Optional<ItemSelection<OneLoginDirectoryUserStatus>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection);
    }
}

export class EntityControllerGetEntityModelPageOpResourceRequest extends EntityControllerGetEntityModelPageRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        public tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort);
    }
}

export class EntityControllerGetEntityModelPageOpResourceRequestFilters extends EntityControllerGetEntityModelPageRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        public creationTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection);
    }
}

export class EntityControllerGetEntityModelPageOpUnmanagedKubernetesClusterRequest extends EntityControllerGetEntityModelPageRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort) {
        super(
            filters,
            limit,
            skip,
            sort);
    }
}

export class EntityControllerGetEntityModelPageOpUnmanagedKubernetesClusterRequestFilters extends EntityControllerGetEntityModelPageRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        public apiConnectivityStatusSelection: Optional<ItemSelection<SystemKubernetesClusterApiConnectivityStatus>>,
        public creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public platformSelection: Optional<ItemSelection<KubernetesClusterPlatform>>,
        public versionSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection);
    }
}

export class EntityControllerGetEntityModelPageOpVirtualMachineRequest extends EntityControllerGetEntityModelPageOpResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public virtualMachineDiskResource: EntityControllerGetEntityModelPageEntityRequestVirtualMachineDiskResource,
        public virtualMachineResource: EntityControllerGetEntityModelPageEntityRequestVirtualMachineResource) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageOpVirtualMachineRequestFilters extends EntityControllerGetEntityModelPageOpResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPagePingIdentityDirectoryEntityRequest extends EntityControllerGetEntityModelPageRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort) {
        super(
            filters,
            limit,
            skip,
            sort);
    }
}

export class EntityControllerGetEntityModelPagePingIdentityDirectoryEntityRequestFilters extends EntityControllerGetEntityModelPageRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        public creationTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection);
    }
}

export class EntityControllerGetEntityModelPagePingIdentityDirectoryGroupRequest extends EntityControllerGetEntityModelPagePingIdentityDirectoryEntityRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort) {
        super(
            filters,
            limit,
            skip,
            sort);
    }
}

export class EntityControllerGetEntityModelPagePingIdentityDirectoryGroupRequestFilters extends EntityControllerGetEntityModelPagePingIdentityDirectoryEntityRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public memberPrincipalIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPagePingIdentityDirectoryUserRequest extends EntityControllerGetEntityModelPagePingIdentityDirectoryEntityRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort) {
        super(
            filters,
            limit,
            skip,
            sort);
    }
}

export class EntityControllerGetEntityModelPagePingIdentityDirectoryUserRequestFilters extends EntityControllerGetEntityModelPagePingIdentityDirectoryEntityRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public activityTimeRangeSelection: Optional<TimeRangeSelection>,
        public awsRoleIdSelection: Optional<PagedItemSelection<string>>,
        public groupIdSelection: Optional<PagedItemSelection<string>>,
        public mailSelection: Optional<PagedItemSelection<string>>,
        public statusSelection: Optional<ItemSelection<PingIdentityDirectoryUserStatus>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetGcpNetworkGraphRequest extends EntityControllerGetNetworkGraphRequest {
    constructor(
        id: string,
        public filters: EntityControllerGetGcpNetworkGraphRequestFilters) {
        super(id);
    }
}

export class EntityControllerGetGcpNetworkGraphRequestFilters extends EntityControllerGetNetworkGraphRequestFilters {
    constructor(
        destinationScopeFilterIdSelection: Optional<ItemSelection<string>>,
        sourceEntityIdSelection: Optional<ItemSelection<string>>,
        sourceSubnetSelection: Optional<ItemSelection<string>>,
        wideRangeSourceSubnet: boolean,
        public securityPerimeterResourceIdSelection: Optional<ItemSelection<string>>) {
        super(
            destinationScopeFilterIdSelection,
            sourceEntityIdSelection,
            sourceSubnetSelection,
            wideRangeSourceSubnet);
    }
}

export class EntityControllerSearchEntityIdsPermissionRequest extends EntityControllerSearchEntityIdsRequest {
    constructor(
        filterRelatedTenants: boolean,
        limit: number,
        nextPageSearchCursor: Optional<ElasticsearchIndexSearchCursor>,
        text: Optional<string>,
        public type: EntityControllerSearchEntityIdsPermissionRequestType) {
        super(
            filterRelatedTenants,
            limit,
            nextPageSearchCursor,
            text);
    }
}

export class EntityControllerSearchEntityIdsPrincipalAccessRequest extends EntityControllerSearchEntityIdsRequest {
    constructor(
        filterRelatedTenants: boolean,
        limit: number,
        nextPageSearchCursor: Optional<ElasticsearchIndexSearchCursor>,
        text: Optional<string>) {
        super(
            filterRelatedTenants,
            limit,
            nextPageSearchCursor,
            text);
    }
}

export class EntityControllerSearchEntityIdsPrincipalExcessivePermissionRequest extends EntityControllerSearchEntityIdsPrincipalAccessRequest {
    constructor(
        filterRelatedTenants: boolean,
        limit: number,
        nextPageSearchCursor: Optional<ElasticsearchIndexSearchCursor>,
        text: Optional<string>,
        public riskCategory: PrincipalRiskCategory) {
        super(
            filterRelatedTenants,
            limit,
            nextPageSearchCursor,
            text);
    }
}

export class EntityControllerSearchEntityIdsPrincipalPermissionRequest extends EntityControllerSearchEntityIdsPrincipalAccessRequest {
    constructor(
        filterRelatedTenants: boolean,
        limit: number,
        nextPageSearchCursor: Optional<ElasticsearchIndexSearchCursor>,
        text: Optional<string>) {
        super(
            filterRelatedTenants,
            limit,
            nextPageSearchCursor,
            text);
    }
}

export class EntityControllerSearchEntityModelsAwsIamManagedPolicyRequest extends EntityControllerSearchEntityModelsRequest {
    constructor(
        filterRelatedTenants: boolean,
        limit: number,
        nextPageSearchCursor: Optional<ElasticsearchIndexSearchCursor>,
        scopeId: Optional<string>,
        text: Optional<string>,
        public excludeNonPrincipalAttachablePolicy: boolean,
        public excludeServiceLinkedRolePolicy: boolean) {
        super(
            filterRelatedTenants,
            limit,
            nextPageSearchCursor,
            scopeId,
            text);
    }
}

export class EntityControllerSearchEntityModelsAzurePermissionEvaluationRequest extends EntityControllerSearchEntityModelsRequest {
    constructor(
        filterRelatedTenants: boolean,
        limit: number,
        nextPageSearchCursor: Optional<ElasticsearchIndexSearchCursor>,
        scopeId: Optional<string>,
        text: Optional<string>) {
        super(
            filterRelatedTenants,
            limit,
            nextPageSearchCursor,
            scopeId,
            text);
    }
}

export class EntityControllerSearchEntityModelsDirectoryEntityTypeRequest extends EntityControllerSearchEntityModelsRequest {
    constructor(
        filterRelatedTenants: boolean,
        limit: number,
        nextPageSearchCursor: Optional<ElasticsearchIndexSearchCursor>,
        scopeId: Optional<string>,
        text: Optional<string>,
        public typeName: string) {
        super(
            filterRelatedTenants,
            limit,
            nextPageSearchCursor,
            scopeId,
            text);
    }
}

export class EntityControllerSearchEntityModelsGcpPermissionEvaluationRequest extends EntityControllerSearchEntityModelsRequest {
    constructor(
        filterRelatedTenants: boolean,
        limit: number,
        nextPageSearchCursor: Optional<ElasticsearchIndexSearchCursor>,
        scopeId: Optional<string>,
        text: Optional<string>) {
        super(
            filterRelatedTenants,
            limit,
            nextPageSearchCursor,
            scopeId,
            text);
    }
}

export class EntityControllerSearchEntityModelsPermissionManagementAadDirectoryGroupRequest extends EntityControllerSearchEntityModelsRequest {
    constructor(
        filterRelatedTenants: boolean,
        limit: number,
        nextPageSearchCursor: Optional<ElasticsearchIndexSearchCursor>,
        scopeId: Optional<string>,
        text: Optional<string>) {
        super(
            filterRelatedTenants,
            limit,
            nextPageSearchCursor,
            scopeId,
            text);
    }
}

export class EntityControllerSearchEntityModelsPermissionManagementAwsSsoPermissionSetRequest extends EntityControllerSearchEntityModelsRequest {
    constructor(
        filterRelatedTenants: boolean,
        limit: number,
        nextPageSearchCursor: Optional<ElasticsearchIndexSearchCursor>,
        scopeId: Optional<string>,
        text: Optional<string>,
        public tenantId: string) {
        super(
            filterRelatedTenants,
            limit,
            nextPageSearchCursor,
            scopeId,
            text);
    }
}

export class EntityControllerSearchEntityModelsPermissionManagementAzureAuthorizationRoleDefinitionRequest extends EntityControllerSearchEntityModelsRequest {
    constructor(
        filterRelatedTenants: boolean,
        limit: number,
        nextPageSearchCursor: Optional<ElasticsearchIndexSearchCursor>,
        scopeId: Optional<string>,
        text: Optional<string>,
        public aadTenantId: string) {
        super(
            filterRelatedTenants,
            limit,
            nextPageSearchCursor,
            scopeId,
            text);
    }
}

export class EntityControllerSearchEntityModelsPermissionManagementGcpIamRoleRequest extends EntityControllerSearchEntityModelsRequest {
    constructor(
        filterRelatedTenants: boolean,
        limit: number,
        nextPageSearchCursor: Optional<ElasticsearchIndexSearchCursor>,
        scopeId: Optional<string>,
        text: Optional<string>,
        public gciTenantId: string) {
        super(
            filterRelatedTenants,
            limit,
            nextPageSearchCursor,
            scopeId,
            text);
    }
}

export class EntityControllerSearchEntityModelsPermissionManagementOktaDirectoryGroupRequest extends EntityControllerSearchEntityModelsRequest {
    constructor(
        filterRelatedTenants: boolean,
        limit: number,
        nextPageSearchCursor: Optional<ElasticsearchIndexSearchCursor>,
        scopeId: Optional<string>,
        text: Optional<string>) {
        super(
            filterRelatedTenants,
            limit,
            nextPageSearchCursor,
            scopeId,
            text);
    }
}

export class EntityControllerSearchEntityModelsPermissionManagementPrincipalRequest extends EntityControllerSearchEntityModelsRequest {
    constructor(
        filterRelatedTenants: boolean,
        limit: number,
        nextPageSearchCursor: Optional<ElasticsearchIndexSearchCursor>,
        scopeId: Optional<string>,
        text: Optional<string>,
        public principalTenantId: string) {
        super(
            filterRelatedTenants,
            limit,
            nextPageSearchCursor,
            scopeId,
            text);
    }
}

export class EntityControllerSearchEntityModelsPrincipalPermissionActionResourceRequest extends EntityControllerSearchEntityModelsRequest {
    constructor(
        filterRelatedTenants: boolean,
        limit: number,
        nextPageSearchCursor: Optional<ElasticsearchIndexSearchCursor>,
        scopeId: Optional<string>,
        text: Optional<string>,
        public principalId: string,
        public typeName: string) {
        super(
            filterRelatedTenants,
            limit,
            nextPageSearchCursor,
            scopeId,
            text);
    }
}

export class EntityDagNode extends DagNode implements ITypeNameObject {
    constructor(
        public entityReference: EntityReference) {
        super("EntityDagNode");
    }
}

export class EntityManualCustomAttributeDefinitionUpdateAuditEvent extends AuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        public addedDefinitionIds: string[],
        public entityId: string,
        public removedDefinitionIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "EntityManualCustomAttributeDefinitionUpdateAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime);
    }
}

export class EntityMatchCondition extends Subdocument implements ITypeNameObject {
    constructor(
        typeName: string) {
        super(typeName);
    }
}

export class EntityModel extends TenantDocument implements ITypeNameObject, IEntityModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        public attributes: EntityModelAttributes,
        public consoleUrl: Optional<string>,
        public entity: Entity,
        public entityConfiguration: Optional<EntityConfiguration>,
        public entityNetwork: Optional<EntityStateNetwork>,
        public entityProfile: Optional<EntityProfile>,
        public permissionEvaluationEntity: boolean,
        public properties: EntityProperty[],
        public risks: EntityModelRisks,
        public uiEntity: boolean,
        public unknown: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId);
    }
}

export class EntityModelFilters extends Summary implements IEntityModelFilters {
    constructor(
        public attributeValueItems: FilterItems<string>,
        public openRelatedEntityRiskTypeIdentifierItems: FilterItems<string>,
        public regionIdItems: FilterItems<string>,
        public tenantIdItems: FilterItems<string>,
        public typeNameItems: FilterItems<string>) {
        super();
    }
}

export class EntityModelSummary extends Summary {
    constructor(
        public count: number) {
        super();
    }
}

export class EntityNamePatternMatchCondition extends EntityMatchCondition implements ITypeNameObject {
    constructor(
        public namePattern: string) {
        super("EntityNamePatternMatchCondition");
    }
}

export class EntityNetworkAccessFiltersSection extends SummarySection implements ISummarySection {
    constructor(
        public destinationNetworkScopeDataItems: FilterItems<DestinationNetworkScopeData>) {
        super();
    }
}

export class EntityProperty extends Subdocument implements ITypeNameObject {
    constructor(
        typeName: string,
        public identifier: EntityPropertyIdentifier,
        public name: string,
        public type: EntityPropertyType,
        public valueDisplayNames: string[],
        public values: EntityPropertyValue[],
        public valueType: EntityPropertyValueType) {
        super(typeName);
    }
}

export class EntityPropertyDefinitionAuditEvent extends AuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        public name: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime);
    }
}

export class EntityPropertyDefinitionCreationAuditEvent extends EntityPropertyDefinitionAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        name: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "EntityPropertyDefinitionCreationAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            name);
    }
}

export class EntityPropertyDefinitionDeletionAuditEvent extends EntityPropertyDefinitionAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        name: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "EntityPropertyDefinitionDeletionAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            name);
    }
}

export class EntityPropertyDefinitionUpdateAuditEvent extends EntityPropertyDefinitionAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        name: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "EntityPropertyDefinitionUpdateAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            name);
    }
}

export class EntityPropertyMatchConditionBase extends EntityMatchCondition implements ITypeNameObject {
    constructor(
        typeName: string,
        public identifier: EntityPropertyIdentifier,
        public operator: EntityMatchConditionCollectionOperator) {
        super(typeName);
    }
}

export class EntityPropertyValue extends Subdocument implements ITypeNameObject {
    constructor(
        typeName: string,
        public displayName: string,
        public source: EntityPropertyValueSource) {
        super(typeName);
    }
}

export class EntityPropertyValueSource extends Subdocument implements ITypeNameObject {
    constructor(
        typeName: string) {
        super(typeName);
    }
}

export class EntitySensitiveMatchCondition extends EntityMatchCondition implements ITypeNameObject {
    constructor() {
        super("EntitySensitiveMatchCondition");
    }
}

export class EntitySensitivityUpdateAuditEvent extends AuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        public entityId: string,
        public sensitive: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "EntitySensitivityUpdateAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime);
    }
}

export class EntitySnapshot extends TenantDocument implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        public changes: EntitySnapshotChange[],
        public entityId: string,
        public relatedEntityIds: string[],
        public relatedEntitySnapshotIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId);
    }
}

export class EntitySnapshotChange extends Subdocument implements ITypeNameObject {
    constructor(
        typeName: string,
        public entityId: string) {
        super(typeName);
    }
}

export class EntityStateFragment extends TenantDocument implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        public entityReference: EntityReference) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId);
    }
}

export class EntityStateNetwork extends EntityStateFragment implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        entityReference: EntityReference) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            entityReference);
    }
}

export class EntityStringProperty extends EntityProperty implements ITypeNameObject {
    constructor(
        identifier: EntityPropertyIdentifier,
        name: string,
        type: EntityPropertyType,
        valueDisplayNames: string[],
        public values: EntityPropertyStringValue[],
        valueType: EntityPropertyValueType) {
        super(
            "EntityStringProperty",
            identifier,
            name,
            type,
            valueDisplayNames,
            values,
            valueType);
    }
}

export class EntityTagMatchCondition extends EntityMatchCondition implements ITypeNameObject {
    constructor(
        public operator: EntityMatchConditionCollectionOperator,
        public tags: ResourceTag[]) {
        super("EntityTagMatchCondition");
    }
}

export class EntityTenantIdMatchCondition extends EntityMatchCondition implements ITypeNameObject {
    constructor(
        public tenantIds: string[]) {
        super("EntityTenantIdMatchCondition");
    }
}

export class EntityTypeMetadata extends ObjectTypeMetadata {
    constructor() {
        super();
    }
}

export class EntityTypeNameMatchCondition extends EntityMatchCondition implements ITypeNameObject {
    constructor(
        public entityTypeNames: string[]) {
        super("EntityTypeNameMatchCondition");
    }
}

export class Event extends TenantDocument implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        public aggregatedEventCount: number,
        public correlationId: Optional<string>,
        public displayName: Optional<string>,
        public errorCode: string,
        public errorMessage: Optional<string>,
        public ipAddress: Optional<string>,
        public name: Optional<string>,
        public raw: string,
        public riskId: Optional<string>,
        public shouldReprocess: boolean,
        public shouldReprocessOriginatorEntity: boolean,
        public time: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId);
    }
}

export class EventControllerGetEntityEventFiltersRequest extends EventControllerGetEventFiltersRequest {
    constructor(
        public entityId: string) {
        super();
    }
}

export class EventControllerGetEntityEventModelPageRequest extends EventControllerGetEventModelPageRequest {
    constructor(
        filters: EventControllerGetEventModelPageRequestFilters,
        limit: number,
        nextPageSearchCursor: Optional<ElasticsearchIndexSearchCursor>,
        public entityId: string) {
        super(
            filters,
            limit,
            nextPageSearchCursor);
    }
}

export class EventControllerGetEventFilterItemPageResponse<TItem> extends EventControllerGetEventFilterItemPageResponseBase {
    constructor(
        public eventFilterItemPage: ElasticsearchFilterItemPage<TItem>) {
        super();
    }
}

export class ExportRelatedEntityModel extends EntityModel implements ITypeNameObject, IEntityModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "ExportRelatedEntityModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown);
    }
}

export class FederationEdgeModelDestinationEntity extends FederationEdgeModelEntity {
    constructor(
        attributes: EntityModelAttributes,
        searchableReference: EntitySearchableReference,
        tenantId: string) {
        super(
            attributes,
            searchableReference,
            tenantId);
    }
}

export class FolderAuditEvent extends AuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        public folderName: string,
        public folderParentPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime);
    }
}

export class FolderConfiguration extends SystemEntityConfiguration implements ITypeNameObject, IChildScopeConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        public managed: boolean,
        public name: string,
        public parentScopeId: string,
        public root: boolean,
        public scopeSections: ScopeConfigurationSections,
        public tenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName);
    }
}

export class FolderConfigurationOrganization extends Subdocument implements ITypeNameObject {
    constructor(
        public folderRawId: Optional<string>,
        public id: string) {
        super("FolderConfigurationOrganization");
    }
}

export class FolderCreationAuditEvent extends FolderAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        folderName: string,
        folderParentPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "FolderCreationAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            folderName,
            folderParentPath);
    }
}

export class FolderDeletionAuditEvent extends FolderAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        folderName: string,
        folderParentPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "FolderDeletionAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            folderName,
            folderParentPath);
    }
}

export class FolderUpdateAuditEvent extends FolderAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        folderName: string,
        folderParentPath: string,
        public folderNameChanged: boolean,
        public folderParentPathChanged: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "FolderUpdateAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            folderName,
            folderParentPath);
    }
}

export class GciDirectoryGroupProfile extends GciEntityProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        public identitiesInactive: Optional<GciDirectoryGroupProfileIdentitiesInactive>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GciDirectoryGroupProfile");
    }
}

export class GciDirectoryPartialUserModelFilters extends EntityModelFilters implements IEntityModelFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        openRelatedEntityRiskTypeIdentifierItems: FilterItems<string>,
        regionIdItems: FilterItems<string>,
        tenantIdItems: FilterItems<string>,
        typeNameItems: FilterItems<string>,
        public activityTimeRange: FilterTimeRange) {
        super(
            attributeValueItems,
            openRelatedEntityRiskTypeIdentifierItems,
            regionIdItems,
            tenantIdItems,
            typeNameItems);
    }
}

export class GciDirectoryPartialUserProfile extends GciEntityProfile implements IDocument, ITypeNameObject, IGciIdentityProfile {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        public gcp: GciIdentityProfileGcp) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GciDirectoryPartialUserProfile");
    }
}

export class GciDirectoryPrincipalModelFilters extends EntityModelFilters implements IEntityModelFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        openRelatedEntityRiskTypeIdentifierItems: FilterItems<string>,
        regionIdItems: FilterItems<string>,
        tenantIdItems: FilterItems<string>,
        typeNameItems: FilterItems<string>,
        public creationTimeRange: FilterTimeRange) {
        super(
            attributeValueItems,
            openRelatedEntityRiskTypeIdentifierItems,
            regionIdItems,
            tenantIdItems,
            typeNameItems);
    }
}

export class GciDirectoryUserModelFilters extends GciDirectoryPrincipalModelFilters implements IEntityModelFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        openRelatedEntityRiskTypeIdentifierItems: FilterItems<string>,
        regionIdItems: FilterItems<string>,
        tenantIdItems: FilterItems<string>,
        typeNameItems: FilterItems<string>,
        creationTimeRange: FilterTimeRange,
        public activityTimeRange: FilterTimeRange,
        public signInTimeRange: FilterTimeRange) {
        super(
            attributeValueItems,
            openRelatedEntityRiskTypeIdentifierItems,
            regionIdItems,
            tenantIdItems,
            typeNameItems,
            creationTimeRange);
    }
}

export class GciDirectoryUserProfile extends GciEntityProfile implements IDocument, ITypeNameObject, IGciIdentityProfile {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        public authorizationScopeToTokenCreationTimeMap: Dictionary<string>,
        public gcp: GciIdentityProfileGcp) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GciDirectoryUserProfile");
    }
}

export class GciEntity extends Entity implements ITypeNameObject, IEntity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        public name: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime);
    }
}

export class GciEntityModel extends EntityModel implements ITypeNameObject, IEntityModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GciEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown);
    }
}

export class GciEntityTypeMetadata extends EntityTypeMetadata {
    constructor() {
        super();
    }
}

export class GciFolderConfiguration extends FolderConfiguration implements ITypeNameObject, IGciFolderConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        managed: boolean,
        name: string,
        parentScopeId: string,
        root: boolean,
        scopeSections: ScopeConfigurationSections,
        tenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GciFolderConfiguration",
            managed,
            name,
            parentScopeId,
            root,
            scopeSections,
            tenantType);
    }
}

export class GciPartialEntity extends GciEntity implements ITypeNameObject, IPartialEntity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name);
    }
}

export class GciPrincipalGroupIdMatchCondition extends EntityMatchCondition implements ITypeNameObject {
    constructor(
        public groupIds: string[],
        public operator: EntityMatchConditionGroupMembershipTypeOperator) {
        super("GciPrincipalGroupIdMatchCondition");
    }
}

export class GciTenantAuditEvent extends AuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        public tenantName: string,
        public tenantPrimaryDomainName: string,
        public tenantRawId: string,
        public tenantServiceAccountMail: string,
        public tenantUserMail: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime);
    }
}

export class GciTenantCreationAuditEvent extends GciTenantAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        tenantName: string,
        tenantPrimaryDomainName: string,
        tenantRawId: string,
        tenantServiceAccountMail: string,
        tenantUserMail: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GciTenantCreationAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            tenantName,
            tenantPrimaryDomainName,
            tenantRawId,
            tenantServiceAccountMail,
            tenantUserMail);
    }
}

export class GciTenantDeletionAuditEvent extends GciTenantAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        tenantName: string,
        tenantPrimaryDomainName: string,
        tenantRawId: string,
        tenantServiceAccountMail: string,
        tenantUserMail: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GciTenantDeletionAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            tenantName,
            tenantPrimaryDomainName,
            tenantRawId,
            tenantServiceAccountMail,
            tenantUserMail);
    }
}

export class GciTenantModel extends TenantModel {
    constructor(
        public configuration: GciTenantConfiguration,
        public state: GciTenantState,
        statusSeverity: Optional<Severity>,
        tenantType: TenantType,
        public serviceAccountTenantRawShortNameId: string,
        public serviceAccountUserDelegationMissingAuthorizationScopes: string[],
        public status: GciTenantModelStatus) {
        super(
            configuration,
            state,
            statusSeverity,
            tenantType);
    }
}

export class GciTenantStateMonitoring extends TenantStateMonitoring implements ITypeNameObject {
    constructor(
        analysisAccessDeniedErrorMessage: Optional<string>,
        eventAnalysisAccessDeniedErrorMessage: Optional<string>,
        systemEnabled: boolean,
        time: string,
        public issue: Optional<GciTenantStateMonitoringIssue>,
        public permissionManagementIssue: Optional<GciTenantStateMonitoringPermissionManagementIssue>,
        public serviceAccountUserDelegationMissingAuthorizationScopes: string[]) {
        super(
            "GciTenantStateMonitoring",
            analysisAccessDeniedErrorMessage,
            eventAnalysisAccessDeniedErrorMessage,
            systemEnabled,
            time);
    }
}

export class GciTenantUpdateAuditEvent extends GciTenantAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        tenantName: string,
        tenantPrimaryDomainName: string,
        tenantRawId: string,
        tenantServiceAccountMail: string,
        tenantUserMail: Optional<string>,
        public tenantServiceAccountMailChanged: boolean,
        public tenantUserMailChanged: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GciTenantUpdateAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            tenantName,
            tenantPrimaryDomainName,
            tenantRawId,
            tenantServiceAccountMail,
            tenantUserMail);
    }
}

export class GcpAccessGraphPermissionPath extends AccessGraphPermissionPath {
    constructor(
        id: string,
        permissionEdgeIdToPathActionsExcessivenessMap: Dictionary<AccessGraphPermissionPathActionsExcessiveness>,
        public identifier: GcpAccessGraphPermissionPathIdentifier) {
        super(
            id,
            permissionEdgeIdToPathActionsExcessivenessMap);
    }
}

export class GcpApiKeysKeyProfile extends GcpResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<GcpResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpApiKeysKeyProfile",
            creation);
    }
}

export class GcpAppEngineApplicationProfile extends GcpResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<GcpResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpAppEngineApplicationProfile",
            creation);
    }
}

export class GcpAppEngineApplicationServiceProfile extends GcpResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<GcpResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpAppEngineApplicationServiceProfile",
            creation);
    }
}

export class GcpAppEngineApplicationServiceRevisionProfile extends GcpResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<GcpResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpAppEngineApplicationServiceRevisionProfile",
            creation);
    }
}

export class GcpArtifactContainerImageRepositoryProfile extends GcpEntityProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpArtifactContainerImageRepositoryProfile");
    }
}

export class GcpArtifactPackageRepositoryPackage extends Subdocument implements ITypeNameObject {
    constructor(
        typeName: string,
        public creationTime: string,
        public name: string,
        public tags: string[],
        public updateTime: string) {
        super(typeName);
    }
}

export class GcpArtifactPackageRepositoryProfile extends GcpEntityProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpArtifactPackageRepositoryProfile");
    }
}

export class GcpArtifactRegistryProfile extends GcpResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<GcpResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpArtifactRegistryProfile",
            creation);
    }
}

export class GcpArtifactRepositoryTypeMetadata extends EntityTypeMetadata {
    constructor() {
        super();
    }
}

export class GcpAssetGenericResourceProfile extends GcpResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<GcpResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpAssetGenericResourceProfile",
            creation);
    }
}

export class GcpBigQueryDatasetProfile extends GcpResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<GcpResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpBigQueryDatasetProfile",
            creation);
    }
}

export class GcpBigQueryDatasetTableProfile extends GcpResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<GcpResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpBigQueryDatasetTableProfile",
            creation);
    }
}

export class GcpCloudBuildBuildTriggerProfile extends GcpResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<GcpResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpCloudBuildBuildTriggerProfile",
            creation);
    }
}

export class GcpCloudRunServiceProfile extends GcpResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<GcpResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpCloudRunServiceProfile",
            creation);
    }
}

export class GcpCloudRunServiceRevisionProfile extends GcpResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<GcpResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpCloudRunServiceRevisionProfile",
            creation);
    }
}

export class GcpComputeAutoscalerProfile extends GcpResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<GcpResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeAutoscalerProfile",
            creation);
    }
}

export class GcpComputeBackendBucketProfile extends GcpResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<GcpResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeBackendBucketProfile",
            creation);
    }
}

export class GcpComputeBackendServiceProfile extends GcpResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<GcpResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeBackendServiceProfile",
            creation);
    }
}

export class GcpComputeDiskProfile extends GcpResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<GcpResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeDiskProfile",
            creation);
    }
}

export class GcpComputeFirewallPolicyProfile extends GcpResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<GcpResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeFirewallPolicyProfile",
            creation);
    }
}

export class GcpComputeFirewallRuleProfile extends GcpResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<GcpResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeFirewallRuleProfile",
            creation);
    }
}

export class GcpComputeForwardingRuleProfile extends GcpResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<GcpResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeForwardingRuleProfile",
            creation);
    }
}

export class GcpComputeImageProfile extends GcpResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<GcpResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeImageProfile",
            creation);
    }
}

export class GcpComputeInstanceGroupManagerProfile extends GcpResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<GcpResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeInstanceGroupManagerProfile",
            creation);
    }
}

export class GcpComputeInstanceGroupProfile extends GcpResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<GcpResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeInstanceGroupProfile",
            creation);
    }
}

export class GcpComputeInstanceProfile extends GcpResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<GcpResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeInstanceProfile",
            creation);
    }
}

export class GcpComputeInstanceTemplateProfile extends GcpResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<GcpResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeInstanceTemplateProfile",
            creation);
    }
}

export class GcpComputeLoadBalancerTypeMetadata extends EntityTypeMetadata {
    constructor() {
        super();
    }
}

export class GcpComputeNetworkEndpointGroupProfile extends GcpResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<GcpResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeNetworkEndpointGroupProfile",
            creation);
    }
}

export class GcpComputeProjectProfile extends GcpResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<GcpResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeProjectProfile",
            creation);
    }
}

export class GcpComputeRouteProfile extends GcpResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<GcpResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeRouteProfile",
            creation);
    }
}

export class GcpComputeSnapshotProfile extends GcpResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<GcpResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeSnapshotProfile",
            creation);
    }
}

export class GcpComputeSslCertificateProfile extends GcpResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<GcpResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeSslCertificateProfile",
            creation);
    }
}

export class GcpComputeSslPolicyProfile extends GcpResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<GcpResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeSslPolicyProfile",
            creation);
    }
}

export class GcpComputeSubnetProfile extends GcpResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<GcpResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeSubnetProfile",
            creation);
    }
}

export class GcpComputeTargetHttpProxyProfile extends GcpResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<GcpResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeTargetHttpProxyProfile",
            creation);
    }
}

export class GcpComputeTargetHttpsProxyProfile extends GcpResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<GcpResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeTargetHttpsProxyProfile",
            creation);
    }
}

export class GcpComputeTargetPoolProfile extends GcpResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<GcpResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeTargetPoolProfile",
            creation);
    }
}

export class GcpComputeTargetSslProxyProfile extends GcpResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<GcpResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeTargetSslProxyProfile",
            creation);
    }
}

export class GcpComputeTargetTcpProxyProfile extends GcpResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<GcpResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeTargetTcpProxyProfile",
            creation);
    }
}

export class GcpComputeUrlMapProfile extends GcpResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<GcpResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeUrlMapProfile",
            creation);
    }
}

export class GcpComputeVpcPeeringConnectionProfile extends GcpResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<GcpResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeVpcPeeringConnectionProfile",
            creation);
    }
}

export class GcpComputeVpcProfile extends GcpResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<GcpResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeVpcProfile",
            creation);
    }
}

export class GcpContainerClusterNodePoolProfile extends GcpResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<GcpResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpContainerClusterNodePoolProfile",
            creation);
    }
}

export class GcpContainerClusterProfile extends GcpResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<GcpResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpContainerClusterProfile",
            creation);
    }
}

export class GcpDnsManagedZoneProfile extends GcpResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<GcpResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpDnsManagedZoneProfile",
            creation);
    }
}

export class GcpDnsManagedZoneResourceRecordSetProfile extends GcpResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<GcpResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpDnsManagedZoneResourceRecordSetProfile",
            creation);
    }
}

export class GcpEntity extends Entity implements ITypeNameObject, IEntity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime);
    }
}

export class GcpEntityModel extends EntityModel implements ITypeNameObject, IEntityModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        public creationTime: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown);
    }
}

export class GcpEntityModelFilters extends EntityModelFilters implements IEntityModelFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        openRelatedEntityRiskTypeIdentifierItems: FilterItems<string>,
        regionIdItems: FilterItems<string>,
        tenantIdItems: FilterItems<string>,
        typeNameItems: FilterItems<string>,
        public creationTimeRange: FilterTimeRange) {
        super(
            attributeValueItems,
            openRelatedEntityRiskTypeIdentifierItems,
            regionIdItems,
            tenantIdItems,
            typeNameItems);
    }
}

export class GcpEvent extends Event implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        aggregatedEventCount: number,
        correlationId: Optional<string>,
        displayName: Optional<string>,
        errorCode: string,
        errorMessage: Optional<string>,
        ipAddress: Optional<string>,
        name: Optional<string>,
        raw: string,
        riskId: Optional<string>,
        shouldReprocess: boolean,
        shouldReprocessOriginatorEntity: boolean,
        time: string,
        public partial: boolean,
        public rawStatusCode: StatusCode,
        public userAgent: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpEvent",
            tenantId,
            aggregatedEventCount,
            correlationId,
            displayName,
            errorCode,
            errorMessage,
            ipAddress,
            name,
            raw,
            riskId,
            shouldReprocess,
            shouldReprocessOriginatorEntity,
            time);
    }
}

export class GcpEventarcTriggerProfile extends GcpResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<GcpResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpEventarcTriggerProfile",
            creation);
    }
}

export class GcpFolderConfigurationAnalysis extends ScopeConfigurationSection<GcpFolderConfigurationAnalysis> {
    constructor(
        public behaviorRiskEvaluationMinTimeFrame: Optional<string>,
        public behaviorRiskNewResourceTimeFrame: Optional<string>,
        public recentPermissionActionUpdateTimeFrame: Optional<string>) {
        super();
    }
}

export class GcpFunctionsFunctionProfile extends GcpResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<GcpResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpFunctionsFunctionProfile",
            creation);
    }
}

export class GcpIamPartialServiceAccountModelFilters extends EntityModelFilters implements IEntityModelFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        openRelatedEntityRiskTypeIdentifierItems: FilterItems<string>,
        regionIdItems: FilterItems<string>,
        tenantIdItems: FilterItems<string>,
        typeNameItems: FilterItems<string>,
        public activityTimeRange: FilterTimeRange) {
        super(
            attributeValueItems,
            openRelatedEntityRiskTypeIdentifierItems,
            regionIdItems,
            tenantIdItems,
            typeNameItems);
    }
}

export class GcpIamRoleBinding extends GcpEntity implements ITypeNameObject, IEntity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        public conditions: GcpIamRoleBindingCondition[],
        public expired: boolean,
        public name: string,
        public principalReference: GciPrincipalReference,
        public roleId: string,
        public scopeResourceReference: GcpScopeResourceReference) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpIamRoleBinding",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime);
    }
}

export class GcpIamRoleBindingModel extends GcpEntityModel implements ITypeNameObject, IUdmObjectModel, IRelatedTenantsEntityModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        public containingRoleBindingExists: boolean,
        public containingRoleBindingIds: string[],
        public gcpSpecialGroupsPermissionUsage: Optional<GcpIamRoleBindingModelPermissionUsage>,
        public permissionUsage: Optional<GcpIamRoleBindingModelPermissionUsage>,
        public permissionUsageType: Optional<GcpIamRoleBindingModelPermissionUsageType>,
        public principalIdReference: string,
        public roleName: string,
        public scopeResourceAssetPathType: GcpResourceAssetPathType,
        public scopeResourceIdReference: string,
        public scopeResourceTypeName: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpIamRoleBindingModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime);
    }
}

export class GcpIamRoleBindingTypeMetadata extends EntityTypeMetadata {
    constructor() {
        super();
    }
}

export class GcpIamRoleProfile extends GcpResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<GcpResourceProfileCreation>,
        public unusedStartTime: Optional<string>,
        public unusedStartTimeUpdateTime: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpIamRoleProfile",
            creation);
    }
}

export class GcpIamServiceAccountKeyProfile extends GcpResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<GcpResourceProfileCreation>,
        public usageTime: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpIamServiceAccountKeyProfile",
            creation);
    }
}

export class GcpIamServiceAccountProfile extends GcpResourceProfile implements IDocument, ITypeNameObject, IGciIdentityProfile {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<GcpResourceProfileCreation>,
        public gcp: GciIdentityProfileGcp,
        public signInTime: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpIamServiceAccountProfile",
            creation);
    }
}

export class GcpIamWorkforcePoolProfile extends GcpResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<GcpResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpIamWorkforcePoolProfile",
            creation);
    }
}

export class GcpIamWorkforcePoolProviderProfile extends GcpResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<GcpResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpIamWorkforcePoolProviderProfile",
            creation);
    }
}

export class GcpIamWorkloadIdentityPoolProfile extends GcpResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<GcpResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpIamWorkloadIdentityPoolProfile",
            creation);
    }
}

export class GcpIamWorkloadIdentityPoolProviderProfile extends GcpResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<GcpResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpIamWorkloadIdentityPoolProviderProfile",
            creation);
    }
}

export class GcpKmsKeyRingKeyProfile extends GcpResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<GcpResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpKmsKeyRingKeyProfile",
            creation);
    }
}

export class GcpKmsKeyRingProfile extends GcpResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<GcpResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpKmsKeyRingProfile",
            creation);
    }
}

export class GcpLoggingLogBucketProfile extends GcpResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<GcpResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpLoggingLogBucketProfile",
            creation);
    }
}

export class GcpLoggingLogMetricProfile extends GcpResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<GcpResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpLoggingLogMetricProfile",
            creation);
    }
}

export class GcpLoggingLogSinkProfile extends GcpResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<GcpResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpLoggingLogSinkProfile",
            creation);
    }
}

export class GcpMonitoringAlertPolicyNotExistRiskTypeMetadata extends RiskTypeMetadata {
    constructor() {
        super();
    }
}

export class GcpMonitoringAlertPolicyProfile extends GcpResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<GcpResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpMonitoringAlertPolicyProfile",
            creation);
    }
}

export class GcpNetworkScope extends NetworkScope<GcpNetworkScope> {
    constructor(
        destinationPortRanges: IntRange[],
        destinationSubnets: string[],
        protocolRanges: IntRange[],
        sourceSubnets: string[]) {
        super(
            destinationPortRanges,
            destinationSubnets,
            protocolRanges,
            sourceSubnets);
    }
}

export class GcpNotebooksInstanceProfile extends GcpResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<GcpResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpNotebooksInstanceProfile",
            creation);
    }
}

export class GcpOrganizationAuditEvent extends AuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        public gciTenantName: string,
        public gciTenantRawId: string,
        public name: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime);
    }
}

export class GcpOrganizationCreationAuditEvent extends GcpOrganizationAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        gciTenantName: string,
        gciTenantRawId: string,
        name: string,
        public data: GcpOrganizationAuditEventData,
        public memberSelection: Optional<OrganizationMemberSelection>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpOrganizationCreationAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            gciTenantName,
            gciTenantRawId,
            name);
    }
}

export class GcpOrganizationDeletionAuditEvent extends GcpOrganizationAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        gciTenantName: string,
        gciTenantRawId: string,
        name: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpOrganizationDeletionAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            gciTenantName,
            gciTenantRawId,
            name);
    }
}

export class GcpOrganizationState extends CloudProviderTenantOrganizationState implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        permissionTypes: CloudProviderTenantPermissionType[],
        public sinkIssue: Optional<GcpTenantStateMonitoringSinkIssue>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpOrganizationState",
            permissionTypes);
    }
}

export class GcpOrganizationUpdateAuditEvent extends GcpOrganizationAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        gciTenantName: string,
        gciTenantRawId: string,
        name: string,
        public data: GcpOrganizationAuditEventData,
        public enabledChanged: boolean,
        public folderEnabledChanged: boolean,
        public memberSelection: Optional<OrganizationMemberSelection>,
        public nameChanged: boolean,
        public permissionTypesChanged: boolean,
        public sinkPubSubSubscriptionRawIdChanged: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpOrganizationUpdateAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            gciTenantName,
            gciTenantRawId,
            name);
    }
}

export class GcpPartialEntity extends GcpEntity implements ITypeNameObject, IPartialEntity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime);
    }
}

export class GcpPartialEntityModel extends GcpEntityModel implements ITypeNameObject, IEntityModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime);
    }
}

export class GcpPartialEntityTypeMetadata extends EntityTypeMetadata {
    constructor() {
        super();
    }
}

export class GcpPartialSpecialGroup extends GcpPartialEntity implements ITypeNameObject, IGciPartialPrincipal, IGcpSpecialGroup {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        public type: GcpSpecialGroupType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpPartialSpecialGroup",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime);
    }
}

export class GcpPartialSpecialGroupModel extends GcpPartialEntityModel implements ITypeNameObject, IUdmObjectModel, IGciPrincipalModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpPartialSpecialGroupModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime);
    }
}

export class GcpPubSubSubscriptionProfile extends GcpResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<GcpResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpPubSubSubscriptionProfile",
            creation);
    }
}

export class GcpPubSubTopicProfile extends GcpResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<GcpResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpPubSubTopicProfile",
            creation);
    }
}

export class GcpResource extends GcpEntity implements ITypeNameObject, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        public name: string,
        public tagExists: boolean,
        public tags: ResourceTag[],
        public updateTime: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime);
    }
}

export class GcpResourceManagerResourceProfile extends GcpResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        creation: Optional<GcpResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            creation);
    }
}

export class GcpResourceModel extends GcpEntityModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        public creatorEntityIdReference: Optional<string>,
        public creatorIdentityIdReference: Optional<string>,
        public creatorOriginatorEntityIdReference: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime);
    }
}

export class GcpResourceModelFilters extends GcpEntityModelFilters implements IEntityModelFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        openRelatedEntityRiskTypeIdentifierItems: FilterItems<string>,
        regionIdItems: FilterItems<string>,
        tenantIdItems: FilterItems<string>,
        typeNameItems: FilterItems<string>,
        creationTimeRange: FilterTimeRange,
        public updateTimeRange: FilterTimeRange) {
        super(
            attributeValueItems,
            openRelatedEntityRiskTypeIdentifierItems,
            regionIdItems,
            tenantIdItems,
            typeNameItems,
            creationTimeRange);
    }
}

export class GcpResourceTypeMetadata extends EntityTypeMetadata {
    constructor() {
        super();
    }
}

export class GcpScopeConfigurationDataAnalysis extends ScopeConfigurationSection<GcpScopeConfigurationDataAnalysis> {
    constructor(
        public managedDatabaseScanInterval: Optional<string>,
        public objectStoreScanInterval: Optional<string>) {
        super();
    }
}

export class GcpScopeConfigurationOutpost extends ScopeConfigurationSection<GcpScopeConfigurationOutpost> implements IScopeConfigurationSectionOutpost {
    constructor(
        public regionSystemNameToDataMap: Dictionary<GcpScopeConfigurationOutpostRegionData>,
        public tags: ResourceTag[],
        public tenantId: Optional<string>) {
        super();
    }
}

export class GcpScopeConfigurationWorkloadAnalysis extends ScopeConfigurationSection<GcpScopeConfigurationWorkloadAnalysis> {
    constructor(
        public diskMaxCount: Optional<number>,
        public scanInterval: Optional<string>) {
        super();
    }
}

export class GcpScopeResource extends GcpResource implements ITypeNameObject, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        public assetPath: string,
        public parentResourceId: Optional<string>,
        public rawId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime);
    }
}

export class GcpScopeResourceModel extends GcpResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        public accessLevel: GcpScopeResourceAccessLevel,
        public roleBindingIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class GcpScopeResourceParentScopeResourceIdMatchCondition extends EntityMatchCondition implements ITypeNameObject {
    constructor(
        public parentScopeResourceIds: string[]) {
        super("GcpScopeResourceParentScopeResourceIdMatchCondition");
    }
}

export class GcpScopeResourceTypeMetadata extends GcpResourceTypeMetadata {
    constructor() {
        super();
    }
}

export class GcpSecretManagerSecret extends GcpScopeResource implements ITypeNameObject, IResource, IGcpEncryptedResource, IGcpTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        public encryptionExists: boolean,
        public expirationTime: Optional<string>,
        public kmsEncryptionKeyAssetPaths: string[],
        public nextRotationTime: Optional<string>,
        public notificationTopicRawIds: string[],
        public replicationType: GcpSecretManagerSecretReplicationType,
        public rotationTimeFrame: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSecretManagerSecret",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId);
    }
}

export class GcpSecretManagerSecretModel extends GcpScopeResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IGcpEncryptedResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[],
        public enabledVersionExists: boolean,
        public kmsEncryptionKeyReferences: GcpScopeResourceReference[],
        public notificationTopicIdReferences: string[],
        public versionIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSecretManagerSecretModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpSecretManagerSecretVersion extends GcpScopeResource implements ITypeNameObject, IResource, IGcpEncryptedResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        public encryptionExists: boolean,
        public kmsEncryptionKeyAssetPaths: string[],
        public secretId: string,
        public status: GcpSecretManagerVersionStatus,
        public version: number) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSecretManagerSecretVersion",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId);
    }
}

export class GcpSecretManagerSecretVersionModel extends GcpScopeResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IGcpEncryptedResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[],
        public kmsEncryptionKeyReferences: GcpScopeResourceReference[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSecretManagerSecretVersionModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpService extends GcpEntity implements ITypeNameObject, IService {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpService",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime);
    }
}

export class GcpServiceModel extends GcpEntityModel implements ITypeNameObject, IEntityModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpServiceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime);
    }
}

export class GcpSpannerInstance extends GcpScopeResource implements ITypeNameObject, IResource, IGcpTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSpannerInstance",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId);
    }
}

export class GcpSpannerInstanceDatabase extends GcpScopeResource implements ITypeNameObject, IResource, IGcpEncryptedResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        public defaultTableKmsEncryptionKeyAssetPath: Optional<string>,
        public encryptionExists: boolean,
        public instanceId: string,
        public instanceName: string,
        public kmsEncryptionKeyAssetPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSpannerInstanceDatabase",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId);
    }
}

export class GcpSpannerInstanceDatabaseModel extends GcpScopeResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IGcpEncryptedResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[],
        public kmsEncryptionKeyReferences: GcpScopeResourceReference[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSpannerInstanceDatabaseModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpSpannerInstanceModel extends GcpScopeResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[],
        public databaseCount: number) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSpannerInstanceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpSpecialGroup extends GcpEntity implements ITypeNameObject, IGcpSpecialGroup {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        public type: GcpSpecialGroupType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSpecialGroup",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime);
    }
}

export class GcpSpecialGroupModel extends GcpEntityModel implements ITypeNameObject, IUdmObjectModel, IGciPrincipalModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSpecialGroupModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime);
    }
}

export class GcpSpecialGroupTypeMetadata extends EntityTypeMetadata {
    constructor() {
        super();
    }
}

export class GcpSqlInstance extends GcpScopeResource implements ITypeNameObject, IDataAnalysisResource, IGcpNetworkedResource, IGcpServiceAccountOriginatorScopeResource, IGcpTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        public auditLogBucketAssetPath: Optional<string>,
        public auditLogEnabled: boolean,
        public authorizedSubnetDatas: GcpSqlInstanceAuthorizedSubnetData[],
        public backupEnabled: boolean,
        public connectionName: Optional<string>,
        public databaseConfigurationKeyToValueMap: Dictionary<string>,
        public databaseType: GcpSqlInstanceDatabaseType,
        public databaseVersion: GcpSqlInstanceDatabaseVersion,
        public httpsOnly: boolean,
        public httpsServerCertificateValidationEnabled: boolean,
        public postgreSqlLogErrorVerbosity: Optional<GcpSqlInstancePostgreSqlLogVerbosity>,
        public postgreSqlLogMinLevel: Optional<GcpSqlInstancePostgreSqlLogLevel>,
        public postgreSqlStatementLogErrorMinLevel: Optional<GcpSqlInstancePostgreSqlLogLevel>,
        public postgreSqlStatementLogMinDuration: Optional<number>,
        public postgreSqlStatementLogType: Optional<GcpSqlInstancePostgreSqlStatementLogType>,
        public primaryInstanceAssetPath: Optional<string>,
        public privateIpAddresses: string[],
        public publicIpAddresses: string[],
        public replicaInstanceAssetPaths: string[],
        public serviceAccountData: GcpServiceAccountOriginatorServiceAccountData,
        public size: Optional<number>,
        public sqlServerUserMaxConnections: Optional<number>,
        public sqlServerUserOptions: Optional<number>,
        public status: Optional<GcpSqlInstanceStatus>,
        public type: GcpSqlInstanceType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstance",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId);
    }
}

export class GcpSqlInstanceDatabase extends GcpScopeResource implements ITypeNameObject, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstanceDatabase",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId);
    }
}

export class GcpSqlInstanceDatabaseModel extends GcpScopeResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstanceDatabaseModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpSqlInstanceModel extends GcpScopeResourceModel implements ITypeNameObject, IUdmObjectModel, IDataAnalysisResourceModel, IGcpServiceAccountOriginatorScopeResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[],
        public auditLogBucketIdReference: Optional<string>,
        public dataAnalysisError: Optional<DataAnalysisResourceModelDataAnalysisError>,
        public dataAnalysisPriorityScanRequested: boolean,
        public dataAnalysisResourceSensitivity: Optional<DataAnalysisSensitivity>,
        public dataAnalysisStatus: DataAnalysisResourceModelDataAnalysisStatus,
        public dataAnalysisStatusSortValue: number,
        public databaseIds: string[],
        public dataCategoryToDataSegmentCountMap: Optional<Dictionary<number>>,
        public dataCategoryToSensitivityMap: Optional<Dictionary<DataSensitivity>>,
        public dataClassifierIdToDataSegmentCountMap: Optional<Dictionary<number>>,
        public dataLastModified: Optional<string>,
        public dataSensitivities: Optional<DataSensitivity[]>,
        public dataSensitivityToDataSegmentCountMap: Optional<Dictionary<number>>,
        public highestDataSensitivity: Optional<DataSensitivity>,
        public primaryInstanceId: Optional<string>,
        public replicaInstanceIds: string[],
        public scannedDataSegmentCount: Optional<number>,
        public scanTime: Optional<string>,
        public sensitiveDataSegmentCount: Optional<number>,
        public serviceAccountIdReference: Optional<string>,
        public storageSize: Optional<number>,
        public userIdReferences: string[],
        public vpcIdReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstanceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpSqlInstanceModelFilters extends GcpResourceModelFilters implements IEntityModelFilters, IDataAnalysisResourceModelFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        openRelatedEntityRiskTypeIdentifierItems: FilterItems<string>,
        regionIdItems: FilterItems<string>,
        tenantIdItems: FilterItems<string>,
        typeNameItems: FilterItems<string>,
        creationTimeRange: FilterTimeRange,
        updateTimeRange: FilterTimeRange,
        public dataAnalysisResource: DataAnalysisResourceModelFiltersSection) {
        super(
            attributeValueItems,
            openRelatedEntityRiskTypeIdentifierItems,
            regionIdItems,
            tenantIdItems,
            typeNameItems,
            creationTimeRange,
            updateTimeRange);
    }
}

export class GcpSqlInstanceUser extends GcpScopeResource implements ITypeNameObject, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        public hostName: Optional<string>,
        public type: GcpSqlInstanceUserType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstanceUser",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId);
    }
}

export class GcpSqlInstanceUserModel extends GcpScopeResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstanceUserModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpStorageBucket extends GcpScopeResource implements ITypeNameObject, IGcpEncryptedResource, IGcpPublicAccessPreventionResource, IGcpRoleBindingUsageResource, IGcpTagResource, IObjectStore {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        public defaultObjectKmsEncryptionKeyAssetPath: Optional<string>,
        public defaultObjectRetentionTimeFrame: Optional<string>,
        public encryptionExists: boolean,
        public kmsEncryptionKeyAssetPaths: string[],
        public locationTypeSystemName: string,
        public lockedRetentionPolicy: boolean,
        public objectCount: Optional<number>,
        public objectVersioningEnabled: boolean,
        public publicAccessPreventionType: PublicAccessPreventionType,
        public size: Optional<number>,
        public uniformAccessControl: boolean,
        public url: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpStorageBucket",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId);
    }
}

export class GcpStorageBucketModel extends GcpScopeResourceModel implements ITypeNameObject, IUdmObjectModel, IGcpEncryptedResourceModel, IGcpLoggingLogSinkDestinationResourceModel, IDataAnalysisResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[],
        public dataAnalysisError: Optional<DataAnalysisResourceModelDataAnalysisError>,
        public dataAnalysisPriorityScanRequested: boolean,
        public dataAnalysisResourceSensitivity: Optional<DataAnalysisSensitivity>,
        public dataAnalysisStatus: DataAnalysisResourceModelDataAnalysisStatus,
        public dataAnalysisStatusSortValue: number,
        public dataCategoryToDataSegmentCountMap: Optional<Dictionary<number>>,
        public dataCategoryToSensitivityMap: Optional<Dictionary<DataSensitivity>>,
        public dataClassifierIdToDataSegmentCountMap: Optional<Dictionary<number>>,
        public dataLastModified: Optional<string>,
        public dataSensitivities: Optional<DataSensitivity[]>,
        public dataSensitivityToDataSegmentCountMap: Optional<Dictionary<number>>,
        public highestDataSensitivity: Optional<DataSensitivity>,
        public kmsEncryptionKeyReferences: GcpScopeResourceReference[],
        public loggingLogSinkIds: string[],
        public scannedDataSegmentCount: Optional<number>,
        public scanTime: Optional<string>,
        public sensitiveDataSegmentCount: Optional<number>,
        public storageSize: Optional<number>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpStorageBucketModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpStorageBucketModelFilters extends GcpResourceModelFilters implements IEntityModelFilters, IDataAnalysisResourceModelFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        openRelatedEntityRiskTypeIdentifierItems: FilterItems<string>,
        regionIdItems: FilterItems<string>,
        tenantIdItems: FilterItems<string>,
        typeNameItems: FilterItems<string>,
        creationTimeRange: FilterTimeRange,
        updateTimeRange: FilterTimeRange,
        public dataAnalysisResource: DataAnalysisResourceModelFiltersSection) {
        super(
            attributeValueItems,
            openRelatedEntityRiskTypeIdentifierItems,
            regionIdItems,
            tenantIdItems,
            typeNameItems,
            creationTimeRange,
            updateTimeRange);
    }
}

export class GcpTenantAuditEvent extends AuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        public tenantFolderPath: string,
        public tenantName: string,
        public tenantRawShortNameId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime);
    }
}

export class GcpTenantConfiguration extends CloudProviderTenantConfiguration implements ITypeNameObject, IOrganizationTenantConfiguration, IGcpScopeConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        active: boolean,
        deletePending: boolean,
        disabledOrchestrationTypes: OrchestrationType[],
        displayReference: string,
        name: string,
        orchestrationTypeToEnabledMap: Dictionary<boolean>,
        parentScopeId: string,
        scopeSections: ScopeConfigurationSections,
        type: TenantType,
        organizationExists: boolean,
        organizationId: Optional<string>,
        public gciTenantId: string,
        public gcpScopeSections: GcpScopeConfigurationSections,
        public organizationRawShortId: string,
        public permissionTypes: CloudProviderTenantPermissionType[],
        public rawShortNameId: string,
        public rawShortNumberId: string,
        public serviceAccountAssignedRoleNames: string[],
        public sink: Optional<GcpTenantConfigurationSink>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpTenantConfiguration",
            active,
            deletePending,
            disabledOrchestrationTypes,
            displayReference,
            name,
            orchestrationTypeToEnabledMap,
            parentScopeId,
            scopeSections,
            type,
            organizationExists,
            organizationId);
    }
}

export class GcpTenantCreationAuditEvent extends GcpTenantAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        tenantFolderPath: string,
        tenantName: string,
        tenantRawShortNameId: string,
        public tenantSinkPubSubSubscriptionRawId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpTenantCreationAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            tenantFolderPath,
            tenantName,
            tenantRawShortNameId);
    }
}

export class GcpTenantDeletionAuditEvent extends GcpTenantAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        tenantFolderPath: string,
        tenantName: string,
        tenantRawShortNameId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpTenantDeletionAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            tenantFolderPath,
            tenantName,
            tenantRawShortNameId);
    }
}

export class GcpTenantEntityModel extends GcpScopeResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, ITenantEntityModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpTenantEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[],
        public activeOwnerReferences: string[],
        public apiKeysKeyIds: string[],
        public auditLogTypeToEnabledMap: Dictionary<boolean>,
        public computeProjectId: Optional<string>,
        public excludedPrincipalReferences: GciPrincipalReference[],
        public inactiveOwnerReferences: string[],
        public ownerReferences: string[],
        public parentEntityPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpTenantEntityModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpTenantEntityProfile extends GcpResourceManagerResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<GcpResourceProfileCreation>,
        public creationUpdateTime: Optional<string>,
        public unusedStartTime: Optional<string>,
        public unusedStartTimeUpdateTime: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpTenantEntityProfile",
            creation);
    }
}

export class GcpTenantModel extends CloudProviderTenantModel {
    constructor(
        public configuration: GcpTenantConfiguration,
        public state: GcpTenantState,
        statusSeverity: Optional<Severity>,
        tenantType: TenantType,
        public issues: GcpTenantModelIssue[],
        public status: GcpTenantModelStatus) {
        super(
            configuration,
            state,
            statusSeverity,
            tenantType);
    }
}

export class GcpTenantState extends CloudProviderTenantState implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        public monitoring: GcpTenantStateMonitoring,
        orchestration: TenantStateOrchestration,
        permissionManagementEnabled: boolean,
        permissionManagementPrincipalTenantId: Optional<string>,
        permissionTypes: CloudProviderTenantPermissionType[],
        public codeAnalysis: GcpTenantStateCodeAnalysis,
        public events: GcpTenantStateEvents) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpTenantState",
            monitoring,
            orchestration,
            permissionManagementEnabled,
            permissionManagementPrincipalTenantId,
            permissionTypes);
    }
}

export class GcpTenantStateMonitoring extends TenantStateMonitoring implements ITypeNameObject {
    constructor(
        analysisAccessDeniedErrorMessage: Optional<string>,
        eventAnalysisAccessDeniedErrorMessage: Optional<string>,
        systemEnabled: boolean,
        time: string,
        public accessDeniedTerraformStateBucketNames: string[],
        public issue: Optional<GcpTenantStateMonitoringIssue>,
        public permissionManagementIssue: Optional<GcpTenantStateMonitoringPermissionManagementIssue>,
        public serviceAccountMissingDataAnalysisPermissionActions: string[],
        public serviceAccountMissingOrganizationRoleNames: string[],
        public serviceAccountMissingRoleNames: string[],
        public serviceAccountMissingWorkloadAnalysisPermissionActions: string[],
        public serviceAccountTenantMissingServiceApiHostNames: string[],
        public serviceAccountUnauthorizedErrorMessage: Optional<string>,
        public sinkIssue: Optional<GcpTenantStateMonitoringSinkIssue>) {
        super(
            "GcpTenantStateMonitoring",
            analysisAccessDeniedErrorMessage,
            eventAnalysisAccessDeniedErrorMessage,
            systemEnabled,
            time);
    }
}

export class GcpTenantUpdateAuditEvent extends GcpTenantAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        tenantFolderPath: string,
        tenantName: string,
        tenantRawShortNameId: string,
        public tenantFolderPathChanged: boolean,
        public tenantSinkPubSubSubscriptionRawId: Optional<string>,
        public tenantSinkPubSubSubscriptionRawIdChanged: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpTenantUpdateAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            tenantFolderPath,
            tenantName,
            tenantRawShortNameId);
    }
}

export class GcpUnmanagedKubernetesCluster extends GcpEntity implements ITypeNameObject, IUnmanagedKubernetesCluster {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        public data: KubernetesClusterData,
        public platform: Optional<KubernetesClusterPlatform>,
        public platformVersion: Optional<string>,
        public version: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpUnmanagedKubernetesCluster",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime);
    }
}

export class GcpUnmanagedKubernetesClusterModel extends GcpEntityModel implements ITypeNameObject, IUdmObjectModel, IUnmanagedKubernetesClusterModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        public apiConnectivityStatus: Optional<SystemKubernetesClusterApiConnectivityStatus>,
        public data: KubernetesClusterModelData,
        public helm: KubernetesClusterModelHelm,
        public resourceUpdateTime: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpUnmanagedKubernetesClusterModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime);
    }
}

export class GcpUnmanagedKubernetesClusterModelFilters extends EntityModelFilters implements IUnmanagedKubernetesClusterModelFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        openRelatedEntityRiskTypeIdentifierItems: FilterItems<string>,
        regionIdItems: FilterItems<string>,
        tenantIdItems: FilterItems<string>,
        typeNameItems: FilterItems<string>,
        public kubernetesCluster: IUnmanagedKubernetesClusterModelFiltersSection) {
        super(
            attributeValueItems,
            openRelatedEntityRiskTypeIdentifierItems,
            regionIdItems,
            tenantIdItems,
            typeNameItems);
    }
}

export class GcpUnmanagedKubernetesClusterTypeMetadata extends EntityTypeMetadata {
    constructor() {
        super();
    }
}

export class GeneralCodeTenantConfiguration extends CodeTenantConfiguration implements ITypeNameObject, ICodeScopeConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        active: boolean,
        deletePending: boolean,
        disabledOrchestrationTypes: OrchestrationType[],
        displayReference: string,
        name: string,
        orchestrationTypeToEnabledMap: Dictionary<boolean>,
        parentScopeId: string,
        scopeSections: ScopeConfigurationSections,
        type: TenantType,
        codeTenantType: CodeTenantType,
        path: string,
        url: string,
        public organizationName: Optional<string>,
        public projectName: Optional<string>,
        public serverEndpoint: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GeneralCodeTenantConfiguration",
            active,
            deletePending,
            disabledOrchestrationTypes,
            displayReference,
            name,
            orchestrationTypeToEnabledMap,
            parentScopeId,
            scopeSections,
            type,
            codeTenantType,
            path,
            url);
    }
}

export class GeneralCodeTenantDeletionAuditEvent extends CodeTenantAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        codeTenantType: CodeTenantType,
        tenantFolderPath: string,
        tenantId: string,
        tenantName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GeneralCodeTenantDeletionAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            codeTenantType,
            tenantFolderPath,
            tenantId,
            tenantName);
    }
}

export class GeneralCodeTenantModel extends CodeTenantModel {
    constructor(
        public configuration: GeneralCodeTenantConfiguration,
        public state: TenantState,
        statusSeverity: Optional<Severity>,
        tenantType: TenantType,
        organizationName: Optional<string>,
        serverEndpoint: Optional<string>,
        status: Optional<CodeTenantModelStatus>) {
        super(
            configuration,
            state,
            statusSeverity,
            tenantType,
            organizationName,
            serverEndpoint,
            status);
    }
}

export class GeneralCodeTenantState extends TenantState implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        public monitoring: GeneralCodeTenantStateMonitoring,
        orchestration: TenantStateOrchestration) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GeneralCodeTenantState",
            monitoring,
            orchestration);
    }
}

export class GeneralCodeTenantStateMonitoring extends TenantStateMonitoring implements ITypeNameObject {
    constructor(
        analysisAccessDeniedErrorMessage: Optional<string>,
        eventAnalysisAccessDeniedErrorMessage: Optional<string>,
        systemEnabled: boolean,
        time: string) {
        super(
            "GeneralCodeTenantStateMonitoring",
            analysisAccessDeniedErrorMessage,
            eventAnalysisAccessDeniedErrorMessage,
            systemEnabled,
            time);
    }
}

export class GeneralCodeTenantUpdateAuditEvent extends CodeTenantAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        codeTenantType: CodeTenantType,
        tenantFolderPath: string,
        tenantId: string,
        tenantName: string,
        public tenantFolderPathChanged: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GeneralCodeTenantUpdateAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            codeTenantType,
            tenantFolderPath,
            tenantId,
            tenantName);
    }
}

export class GitTenantConfiguration extends CodeTenantConfiguration implements ITypeNameObject, ICodeScopeConfiguration, IOrganizationTenantConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        active: boolean,
        deletePending: boolean,
        disabledOrchestrationTypes: OrchestrationType[],
        displayReference: string,
        name: string,
        orchestrationTypeToEnabledMap: Dictionary<boolean>,
        parentScopeId: string,
        scopeSections: ScopeConfigurationSections,
        type: TenantType,
        codeTenantType: CodeTenantType,
        path: string,
        url: string,
        public branchName: string,
        public debug: Optional<boolean>,
        public organizationId: string,
        public syncEnabled: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            active,
            deletePending,
            disabledOrchestrationTypes,
            displayReference,
            name,
            orchestrationTypeToEnabledMap,
            parentScopeId,
            scopeSections,
            type,
            codeTenantType,
            path,
            url);
    }
}

export class GitTenantCreationAuditEvent extends CodeTenantAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        codeTenantType: CodeTenantType,
        tenantFolderPath: string,
        tenantId: string,
        tenantName: string,
        public branchName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GitTenantCreationAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            codeTenantType,
            tenantFolderPath,
            tenantId,
            tenantName);
    }
}

export class GitTenantDeletionAuditEvent extends CodeTenantAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        codeTenantType: CodeTenantType,
        tenantFolderPath: string,
        tenantId: string,
        tenantName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GitTenantDeletionAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            codeTenantType,
            tenantFolderPath,
            tenantId,
            tenantName);
    }
}

export class GitTenantModel extends CodeTenantModel {
    constructor(
        public configuration: GitTenantConfiguration,
        public state: TenantState,
        statusSeverity: Optional<Severity>,
        tenantType: TenantType,
        organizationName: Optional<string>,
        serverEndpoint: Optional<string>,
        status: Optional<CodeTenantModelStatus>) {
        super(
            configuration,
            state,
            statusSeverity,
            tenantType,
            organizationName,
            serverEndpoint,
            status);
    }
}

export class GitTenantState extends TenantState implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        public monitoring: GitTenantStateMonitoring,
        orchestration: TenantStateOrchestration) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GitTenantState",
            monitoring,
            orchestration);
    }
}

export class GitTenantStateMonitoring extends TenantStateMonitoring implements ITypeNameObject {
    constructor(
        analysisAccessDeniedErrorMessage: Optional<string>,
        eventAnalysisAccessDeniedErrorMessage: Optional<string>,
        systemEnabled: boolean,
        time: string,
        public iacExists: Optional<boolean>,
        public issue: Optional<GitTenantStateMonitoringIssue>) {
        super(
            "GitTenantStateMonitoring",
            analysisAccessDeniedErrorMessage,
            eventAnalysisAccessDeniedErrorMessage,
            systemEnabled,
            time);
    }
}

export class GitTenantUpdateAuditEvent extends CodeTenantAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        codeTenantType: CodeTenantType,
        tenantFolderPath: string,
        tenantId: string,
        tenantName: string,
        public branchChanged: boolean,
        public branchName: Optional<string>,
        public tenantFolderPathChanged: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GitTenantUpdateAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            codeTenantType,
            tenantFolderPath,
            tenantId,
            tenantName);
    }
}

export class GroupRoleAssignmentAuditEvent extends AuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        public groupIdentifier: string,
        public groupRole: IdentityRole) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime);
    }
}

export class GroupRoleAssignmentCreationAuditEvent extends GroupRoleAssignmentAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        groupIdentifier: string,
        groupRole: IdentityRole) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GroupRoleAssignmentCreationAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            groupIdentifier,
            groupRole);
    }
}

export class GroupRoleAssignmentDeletionAuditEvent extends GroupRoleAssignmentAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        groupIdentifier: string,
        groupRole: IdentityRole) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GroupRoleAssignmentDeletionAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            groupIdentifier,
            groupRole);
    }
}

export class GroupRoleAssignmentUpdateAuditEvent extends GroupRoleAssignmentAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        groupIdentifier: string,
        groupRole: IdentityRole) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GroupRoleAssignmentUpdateAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            groupIdentifier,
            groupRole);
    }
}

export class IdentityProviderTenantConfiguration extends TenantConfiguration implements ITypeNameObject, IChildScopeConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        active: boolean,
        deletePending: boolean,
        disabledOrchestrationTypes: OrchestrationType[],
        displayReference: string,
        name: string,
        orchestrationTypeToEnabledMap: Dictionary<boolean>,
        parentScopeId: string,
        scopeSections: ScopeConfigurationSections,
        type: TenantType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            active,
            deletePending,
            disabledOrchestrationTypes,
            displayReference,
            name,
            orchestrationTypeToEnabledMap,
            parentScopeId,
            scopeSections,
            type);
    }
}

export class IdentityProviderTenantState extends TenantState implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        monitoring: TenantStateMonitoring,
        orchestration: TenantStateOrchestration,
        public permissionManagementEnabled: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            monitoring,
            orchestration);
    }
}

export class IntegrationDelivery extends Delivery implements ITypeNameObject {
    constructor(
        typeName: string,
        public destinations: DeliveryIntegration[],
        message: Optional<string>,
        public integrationIds: string[]) {
        super(
            typeName,
            destinations,
            message);
    }
}

export class InternalSubnetCreationAuditEvent extends AuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        public subnet: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "InternalSubnetCreationAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime);
    }
}

export class InternalSubnetDeletionAuditEvent extends AuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        public subnet: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "InternalSubnetDeletionAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime);
    }
}

export class InternalSubnetUpdateAuditEvent extends AuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        public existingSubnet: string,
        public nameChanged: boolean,
        public subnet: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "InternalSubnetUpdateAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime);
    }
}

export class ItemSelection<TItem> extends ItemSelectionBase<TItem> {
    constructor(
        emptyValue: boolean,
        items: TItem[]) {
        super(
            emptyValue,
            items);
    }
}

export class JiraDelivery extends IntegrationDelivery implements ITypeNameObject {
    constructor(
        public destinations: JiraDeliveryProject[],
        message: Optional<string>,
        integrationIds: string[]) {
        super(
            "JiraDelivery",
            destinations,
            message,
            integrationIds);
    }
}

export class JiraDeliveryProject extends DeliveryIntegration implements ITypeNameObject {
    constructor(
        integrationId: string,
        public instanceId: string,
        public issueCreationData: JiraIssueCreationData) {
        super(
            "JiraDeliveryProject",
            integrationId);
    }
}

export class JiraField extends Subdocument implements ITypeNameObject {
    constructor(
        typeName: string,
        public id: string,
        public name: string,
        public required: boolean) {
        super(typeName);
    }
}

export class JiraInstanceAuditEvent extends AuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        public jiraInstanceUrl: string,
        public jiraInstanceUserName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime);
    }
}

export class JiraInstanceCreationAuditEvent extends JiraInstanceAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        jiraInstanceUrl: string,
        jiraInstanceUserName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "JiraInstanceCreationAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            jiraInstanceUrl,
            jiraInstanceUserName);
    }
}

export class JiraInstanceDeletionAuditEvent extends JiraInstanceAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        jiraInstanceUrl: string,
        jiraInstanceUserName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "JiraInstanceDeletionAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            jiraInstanceUrl,
            jiraInstanceUserName);
    }
}

export class JiraInstanceState extends SystemEntityState implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        public attachmentsEnabled: boolean,
        public deploymentType: JiraInstanceDeploymentType,
        public issue: Optional<JiraInstanceStateIssue>,
        public labels: string[],
        public projectRawIdToProjectMap: Dictionary<JiraInstanceStateProject>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "JiraInstanceState");
    }
}

export class JiraInstanceUpdateAuditEvent extends JiraInstanceAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        jiraInstanceUrl: string,
        jiraInstanceUserName: string,
        public jiraInstanceUserNameChanged: boolean,
        public jiraInstanceUserTokenChanged: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "JiraInstanceUpdateAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            jiraInstanceUrl,
            jiraInstanceUserName);
    }
}

export class JiraIssue extends TicketingServiceTicket implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        automationRuleDeliveryDestinationReference: Optional<AutomationRuleDeliveryDestinationReference>,
        multipleRisks: boolean,
        upsertTime: string,
        url: string,
        public attachmentRawIds: string[],
        public instanceId: string,
        public issueTypeName: string,
        public projectRawId: string,
        public rawId: string,
        public user: Optional<JiraUser>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "JiraIssue",
            automationRuleDeliveryDestinationReference,
            multipleRisks,
            upsertTime,
            url);
    }
}

export class JiraNumberField extends JiraField implements ITypeNameObject {
    constructor(
        id: string,
        name: string,
        required: boolean,
        public defaultValue: Optional<number>) {
        super(
            "JiraNumberField",
            id,
            name,
            required);
    }
}

export class JiraProjectAuditEvent extends JiraInstanceAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        jiraInstanceUrl: string,
        jiraInstanceUserName: string,
        public jiraProjectId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            jiraInstanceUrl,
            jiraInstanceUserName);
    }
}

export class JiraProjectCreationAuditEvent extends JiraProjectAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        jiraInstanceUrl: string,
        jiraInstanceUserName: string,
        jiraProjectId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "JiraProjectCreationAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            jiraInstanceUrl,
            jiraInstanceUserName,
            jiraProjectId);
    }
}

export class JiraProjectDeletionAuditEvent extends JiraProjectAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        jiraInstanceUrl: string,
        jiraInstanceUserName: string,
        jiraProjectId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "JiraProjectDeletionAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            jiraInstanceUrl,
            jiraInstanceUserName,
            jiraProjectId);
    }
}

export class JiraProjectUpdateAuditEvent extends JiraProjectAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        jiraInstanceUrl: string,
        jiraInstanceUserName: string,
        jiraProjectId: string,
        public jiraProjectSeverityMappingChanged: boolean,
        public jiraProjectStatusMappingChanged: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "JiraProjectUpdateAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            jiraInstanceUrl,
            jiraInstanceUserName,
            jiraProjectId);
    }
}

export class JiraSelectionField extends JiraField implements ITypeNameObject {
    constructor(
        typeName: string,
        id: string,
        name: string,
        required: boolean,
        public options: string[]) {
        super(
            typeName,
            id,
            name,
            required);
    }
}

export class JiraSingleSelectionField extends JiraSelectionField implements ITypeNameObject {
    constructor(
        id: string,
        name: string,
        required: boolean,
        options: string[],
        public defaultValue: Optional<string>) {
        super(
            "JiraSingleSelectionField",
            id,
            name,
            required,
            options);
    }
}

export class JiraStringField extends JiraField implements ITypeNameObject {
    constructor(
        id: string,
        name: string,
        required: boolean,
        public defaultValue: Optional<string>) {
        super(
            "JiraStringField",
            id,
            name,
            required);
    }
}

export class JiraUnsupportedField extends JiraField implements ITypeNameObject {
    constructor(
        id: string,
        name: string,
        required: boolean,
        public fieldTypeName: string) {
        super(
            "JiraUnsupportedField",
            id,
            name,
            required);
    }
}

export class JiraUserField extends JiraField implements ITypeNameObject {
    constructor(
        id: string,
        name: string,
        required: boolean,
        public defaultUserRawId: Optional<string>) {
        super(
            "JiraUserField",
            id,
            name,
            required);
    }
}

export class KubernetesClusterConnectorAuditEvent extends AuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        public name: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime);
    }
}

export class KubernetesClusterConnectorCreationAuditEvent extends KubernetesClusterConnectorAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        name: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterConnectorCreationAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            name);
    }
}

export class KubernetesClusterConnectorDeletionAuditEvent extends KubernetesClusterConnectorAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        name: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterConnectorDeletionAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            name);
    }
}

export class KubernetesClusterConnectorUpdateAuditEvent extends KubernetesClusterConnectorAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        name: string,
        public nameChanged: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterConnectorUpdateAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            name);
    }
}

export class KubernetesClusterData extends Subdocument implements ITypeNameObject {
    constructor(
        public creationTime: Optional<string>,
        public name: string,
        public oidcIdentityProviderIssuerUrl: Optional<string>,
        public platform: Optional<KubernetesClusterPlatform>,
        public platformVersion: Optional<string>,
        public systemNamespaceRawId: string,
        public version: Optional<string>) {
        super("KubernetesClusterData");
    }
}

export class KubernetesClusterEvent extends TenantDocument implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        public clusterReference: EntitySearchableEventReference,
        public count: number,
        public endTime: string,
        public startTime: string,
        public systemKubernetesClusterId: string,
        public time: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId);
    }
}

export class KubernetesClusterEventFilters extends Summary {
    constructor(
        public tenantIdItems: FilterItems<string>,
        public timeRange: FilterTimeRange) {
        super();
    }
}

export class KubernetesClusterEventModel extends TenantDocument implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        public clusterReference: string,
        public event: KubernetesClusterEvent) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId);
    }
}

export class KubernetesClusterModelFiltersSection<TKubernetesClusterModel extends EntityModel> extends SummarySection implements IKubernetesClusterModelFiltersSection {
    constructor(
        public resourceUpdateTimeRange: FilterTimeRange) {
        super();
    }
}

export class KubernetesClusterModelHelmAdmissionController extends KubernetesClusterModelHelmComponent {
    constructor(
        status: SystemKubernetesClusterModelHelmComponentStatus) {
        super(status);
    }
}

export class KubernetesControllerGetKubernetesClusterAdmissionControllerEventFilterItemPageResponse<TItem> extends KubernetesControllerGetKubernetesClusterAdmissionControllerEventFilterItemPageResponseBase {
    constructor(
        public kubernetesAdmissionControllerEventFilterItemPage: ElasticsearchFilterItemPage<TItem>) {
        super();
    }
}

export class KubernetesControllerGetSystemKubernetesClusterFilterItemPageResponse<TItem> extends KubernetesControllerGetSystemKubernetesClusterFilterItemPageResponseBase {
    constructor(
        public systemKubernetesClusterFilterItemPage: ElasticsearchFilterItemPage<TItem>) {
        super();
    }
}

export class KubernetesEnvironmentVariableValueReference extends Subdocument implements ITypeNameObject {
    constructor(
        typeName: string,
        public key: string) {
        super(typeName);
    }
}

export class KubernetesIngressDataRulePath extends Subdocument implements ITypeNameObject {
    constructor(
        typeName: string,
        public pattern: string,
        public type: KubernetesIngressDataRulePathType) {
        super(typeName);
    }
}

export class KubernetesIngressDataRuleResourcePath extends KubernetesIngressDataRulePath implements ITypeNameObject {
    constructor(
        pattern: string,
        type: KubernetesIngressDataRulePathType,
        public resourceReference: KubernetesResourceReference) {
        super(
            "KubernetesIngressDataRuleResourcePath",
            pattern,
            type);
    }
}

export class KubernetesIngressDataRuleServicePath extends KubernetesIngressDataRulePath implements ITypeNameObject {
    constructor(
        pattern: string,
        type: KubernetesIngressDataRulePathType,
        public servicePortName: string,
        public serviceReference: KubernetesResourceReference) {
        super(
            "KubernetesIngressDataRuleServicePath",
            pattern,
            type);
    }
}

export class KubernetesResourceData extends Subdocument implements ITypeNameObject, IKubernetesResourceData {
    constructor(
        typeName: string,
        public annotationKeyToValueMap: Dictionary<string>,
        public controllerResourceReference: Optional<KubernetesResourceReference>,
        public name: string,
        public path: string,
        public rawId: string,
        public rawYaml: Optional<string>) {
        super(typeName);
    }
}

export class KubernetesUserData extends KubernetesResourceData implements ITypeNameObject, IKubernetesPrincipalData {
    constructor(
        annotationKeyToValueMap: Dictionary<string>,
        controllerResourceReference: Optional<KubernetesResourceReference>,
        name: string,
        path: string,
        rawId: string,
        rawYaml: Optional<string>) {
        super(
            "KubernetesUserData",
            annotationKeyToValueMap,
            controllerResourceReference,
            name,
            path,
            rawId,
            rawYaml);
    }
}

export class MailDelivery extends Delivery implements ITypeNameObject {
    constructor(
        public destinations: MailDeliveryMail[],
        message: Optional<string>) {
        super(
            "MailDelivery",
            destinations,
            message);
    }
}

export class MailDeliveryMail extends DeliveryDestination implements ITypeNameObject {
    constructor(
        public mail: string) {
        super("MailDeliveryMail");
    }
}

export class MailUpdateAuditEvent extends AuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        public addedMails: string[],
        public removedMails: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "MailUpdateAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime);
    }
}

export class ManualCustomEntityAttributeDefinitionAuditEvent extends AuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        public name: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime);
    }
}

export class ManualCustomEntityAttributeDefinitionCreationAuditEvent extends ManualCustomEntityAttributeDefinitionAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        name: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "ManualCustomEntityAttributeDefinitionCreationAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            name);
    }
}

export class ManualCustomEntityAttributeDefinitionDeletionAuditEvent extends ManualCustomEntityAttributeDefinitionAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        name: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "ManualCustomEntityAttributeDefinitionDeletionAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            name);
    }
}

export class ManualCustomEntityAttributeDefinitionUpdateAuditEvent extends ManualCustomEntityAttributeDefinitionAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        name: string,
        public colorChanged: boolean,
        public nameChanged: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "ManualCustomEntityAttributeDefinitionUpdateAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            name);
    }
}

export class MetaObjectTypeMetadata extends ObjectTypeMetadata {
    constructor() {
        super();
    }
}

export class MetaRiskTypeMetadata extends MetaObjectTypeMetadata {
    constructor() {
        super();
    }
}

export class NetworkAccessResourceStateNetwork extends EntityStateNetwork implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        entityReference: EntityReference,
        public inboundAccessType: NetworkInboundAccessType,
        public inboundExternal: boolean,
        public inboundExternalAccessScope: NetworkAccessScope,
        public inboundExternalDestinationNetworkScopes: Optional<DestinationNetworkScope[]>,
        public inboundExternalWideRange: Optional<boolean>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            entityReference);
    }
}

export class NvdState extends SystemEntityState implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        public vulnerabilityUpdateTime: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "NvdState");
    }
}

export class ObjectDataSegmentMetadata extends DataSegmentMetadata implements ITypeNameObject {
    constructor(
        lastModified: Optional<string>,
        public objectSize: Optional<number>) {
        super(
            "ObjectDataSegmentMetadata",
            lastModified);
    }
}

export class OciAutoscalingAutoscalingConfigurationProfile extends OciResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciAutoscalingAutoscalingConfigurationProfile");
    }
}

export class OciBlockStorageBlockVolumeProfile extends OciResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciBlockStorageBlockVolumeProfile");
    }
}

export class OciBlockStorageBootVolumeProfile extends OciResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciBlockStorageBootVolumeProfile");
    }
}

export class OciComputeImageProfile extends OciResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciComputeImageProfile");
    }
}

export class OciComputeInstanceConfigurationProfile extends OciResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciComputeInstanceConfigurationProfile");
    }
}

export class OciComputeInstancePoolProfile extends OciResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciComputeInstancePoolProfile");
    }
}

export class OciComputeInstanceProfile extends OciResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciComputeInstanceProfile");
    }
}

export class OciContainerEngineClusterNodePoolProfile extends OciResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciContainerEngineClusterNodePoolProfile");
    }
}

export class OciContainerEngineClusterProfile extends OciResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciContainerEngineClusterProfile");
    }
}

export class OciEntity extends Entity implements ITypeNameObject, IEntity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime);
    }
}

export class OciEntityModel extends EntityModel implements ITypeNameObject, IEntityModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown);
    }
}

export class OciFssFileSystemProfile extends OciResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciFssFileSystemProfile");
    }
}

export class OciFssMountTargetExportProfile extends OciResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciFssMountTargetExportProfile");
    }
}

export class OciFssMountTargetProfile extends OciResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciFssMountTargetProfile");
    }
}

export class OciIamResourceProfile extends OciResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName);
    }
}

export class OciIamUserCredentialProfile extends OciIamResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName);
    }
}

export class OciIamUserDatabasePasswordProfile extends OciIamUserCredentialProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName);
    }
}

export class OciIamUserSecretKeyProfile extends OciIamUserCredentialProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName);
    }
}

export class OciKmsVaultKeyProfile extends OciResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciKmsVaultKeyProfile");
    }
}

export class OciKmsVaultProfile extends OciResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciKmsVaultProfile");
    }
}

export class OciKmsVaultSecretProfile extends OciResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciKmsVaultSecretProfile");
    }
}

export class OciLoadBalancingLoadBalancerProfile extends OciResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciLoadBalancingLoadBalancerProfile");
    }
}

export class OciLoggingLogGroupProfile extends OciResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciLoggingLogGroupProfile");
    }
}

export class OciNetworkingInternetGatewayProfile extends OciResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciNetworkingInternetGatewayProfile");
    }
}

export class OciNetworkingLocalPeeringGatewayProfile extends OciResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciNetworkingLocalPeeringGatewayProfile");
    }
}

export class OciNetworkingNatGatewayProfile extends OciResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciNetworkingNatGatewayProfile");
    }
}

export class OciNetworkingNetworkLoadBalancerProfile extends OciResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciNetworkingNetworkLoadBalancerProfile");
    }
}

export class OciNetworkingNetworkSecurityGroupProfile extends OciResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciNetworkingNetworkSecurityGroupProfile");
    }
}

export class OciNetworkingNetworkSecurityGroupRule extends OciNetworkingSecurityRule {
    constructor(
        description: Optional<string>,
        destinationPortRange: Optional<IntRange>,
        icmp: Optional<OciNetworkingSecurityRuleIcmp>,
        protocolRange: IntRange,
        serviceCidrLabel: Optional<string>,
        sourcePortRange: Optional<IntRange>,
        stateless: boolean,
        subnet: Optional<string>,
        public endpointType: OciNetworkingNetworkSecurityGroupRuleEndpointType,
        public securityGroupOcid: Optional<string>) {
        super(
            description,
            destinationPortRange,
            icmp,
            protocolRange,
            serviceCidrLabel,
            sourcePortRange,
            stateless,
            subnet);
    }
}

export class OciNetworkingRouteTableProfile extends OciResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciNetworkingRouteTableProfile");
    }
}

export class OciNetworkingSecurityListProfile extends OciResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciNetworkingSecurityListProfile");
    }
}

export class OciNetworkingSecurityListRule extends OciNetworkingSecurityRule {
    constructor(
        description: Optional<string>,
        destinationPortRange: Optional<IntRange>,
        icmp: Optional<OciNetworkingSecurityRuleIcmp>,
        protocolRange: IntRange,
        serviceCidrLabel: Optional<string>,
        sourcePortRange: Optional<IntRange>,
        stateless: boolean,
        subnet: Optional<string>) {
        super(
            description,
            destinationPortRange,
            icmp,
            protocolRange,
            serviceCidrLabel,
            sourcePortRange,
            stateless,
            subnet);
    }
}

export class OciNetworkingServiceGatewayProfile extends OciResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciNetworkingServiceGatewayProfile");
    }
}

export class OciNetworkingSubnetProfile extends OciResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciNetworkingSubnetProfile");
    }
}

export class OciNetworkingVcnProfile extends OciResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciNetworkingVcnProfile");
    }
}

export class OciNetworkingVirtualNetworkInterfaceCardProfile extends OciResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciNetworkingVirtualNetworkInterfaceCardProfile");
    }
}

export class OciObjectStorageBucketProfile extends OciResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciObjectStorageBucketProfile");
    }
}

export class OciOrganizationAuditEvent extends AuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        public organizationName: string,
        public organizationOcid: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime);
    }
}

export class OciOrganizationCreationAuditEvent extends OciOrganizationAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        organizationName: string,
        organizationOcid: string,
        public memberSelection: Optional<OrganizationMemberSelection>,
        public organizationUserOcid: string,
        public permissionTypes: CloudProviderTenantPermissionType[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciOrganizationCreationAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            organizationName,
            organizationOcid);
    }
}

export class OciOrganizationDeletionAuditEvent extends OciOrganizationAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        organizationName: string,
        organizationOcid: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciOrganizationDeletionAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            organizationName,
            organizationOcid);
    }
}

export class OciOrganizationState extends SystemEntityState implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        public issue: Optional<OciOrganizationStateIssue>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciOrganizationState");
    }
}

export class OciOrganizationUpdateAuditEvent extends OciOrganizationAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        organizationName: string,
        organizationOcid: string,
        public enabled: boolean,
        public enabledChanged: boolean,
        public folderEnabled: boolean,
        public folderEnabledChanged: boolean,
        public memberSelection: Optional<OrganizationMemberSelection>,
        public organizationUserChanged: boolean,
        public organizationUserOcid: string,
        public permissionTypes: CloudProviderTenantPermissionType[],
        public permissionTypesChanged: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciOrganizationUpdateAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            organizationName,
            organizationOcid);
    }
}

export class OciResource extends OciEntity implements ITypeNameObject, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        public name: Optional<string>,
        public ocid: Optional<string>,
        public tagExists: boolean,
        public tags: ResourceTag[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime);
    }
}

export class OciResourceModel extends OciEntityModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        public creationTime: Optional<string>,
        public creatorIdentityIdReference: Optional<string>,
        public creatorOriginatorEntityIdReference: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown);
    }
}

export class OciResourceModelFilters extends EntityModelFilters implements IEntityModelFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        openRelatedEntityRiskTypeIdentifierItems: FilterItems<string>,
        regionIdItems: FilterItems<string>,
        tenantIdItems: FilterItems<string>,
        typeNameItems: FilterItems<string>,
        public creationTimeRange: FilterTimeRange) {
        super(
            attributeValueItems,
            openRelatedEntityRiskTypeIdentifierItems,
            regionIdItems,
            tenantIdItems,
            typeNameItems);
    }
}

export class OciResourceSearchGenericResource extends OciResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public availabilityDomain: Optional<string>,
        public lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags);
    }
}

export class OciResourceSearchGenericResourceModel extends OciResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class OciResourceTypeMetadata extends EntityTypeMetadata {
    constructor() {
        super();
    }
}

export class OciRgbuCecsSaasEnvironmentGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciRgbuCecsSaasEnvironmentGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciRouteTableGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciRouteTableGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciRoverClusterGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciRoverClusterGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciRoverNodeGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciRoverNodeGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciScopeConfigurationOutpost extends ScopeConfigurationSection<OciScopeConfigurationOutpost> implements IScopeConfigurationSectionOutpost {
    constructor(
        public regionSystemNameToDataMap: Dictionary<OciScopeConfigurationOutpostRegionData>,
        public tags: ResourceTag[],
        public tenantId: Optional<string>) {
        super();
    }
}

export class OciSecurityAttributeNamespaceGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciSecurityAttributeNamespaceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciSecurityListGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciSecurityListGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciSecurityZonesSecurityPolicyGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciSecurityZonesSecurityPolicyGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciSecurityZonesSecurityRecipeGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciSecurityZonesSecurityRecipeGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciSecurityZonesSecurityZoneGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciSecurityZonesSecurityZoneGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciServiceConnectorGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciServiceConnectorGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciServiceGatewayGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciServiceGatewayGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciShareGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciShareGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciStackMonitoringResourceGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciStackMonitoringResourceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciStatusServiceResourceGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciStatusServiceResourceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciStorageGatewayGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciStorageGatewayGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciStreamCdnConfigGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciStreamCdnConfigGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciStreamDistributionChannelGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciStreamDistributionChannelGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciStreamGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciStreamGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciStreamPackagingConfigGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciStreamPackagingConfigGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciSubnetGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciSubnetGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciSubscriptionPricingConfigGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciSubscriptionPricingConfigGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciTagDefaultGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciTagDefaultGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciTagNamespaceGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciTagNamespaceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciTenantAuditEvent extends AuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        public tenantFolderPath: string,
        public tenantName: string,
        public tenantOcid: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime);
    }
}

export class OciTenantConfiguration extends CloudProviderTenantConfiguration implements ITypeNameObject, IOrganizationTenantConfiguration, IOciScopeConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        active: boolean,
        deletePending: boolean,
        disabledOrchestrationTypes: OrchestrationType[],
        displayReference: string,
        name: string,
        orchestrationTypeToEnabledMap: Dictionary<boolean>,
        parentScopeId: string,
        scopeSections: ScopeConfigurationSections,
        type: TenantType,
        organizationExists: boolean,
        organizationId: Optional<string>,
        public ocid: string,
        public ociScopeSections: OciScopeConfigurationSections,
        public realmId: string,
        public root: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciTenantConfiguration",
            active,
            deletePending,
            disabledOrchestrationTypes,
            displayReference,
            name,
            orchestrationTypeToEnabledMap,
            parentScopeId,
            scopeSections,
            type,
            organizationExists,
            organizationId);
    }
}

export class OciTenantCreationAuditEvent extends OciTenantAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        tenantFolderPath: string,
        tenantName: string,
        tenantOcid: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciTenantCreationAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            tenantFolderPath,
            tenantName,
            tenantOcid);
    }
}

export class OciTenantDeletionAuditEvent extends OciTenantAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        tenantFolderPath: string,
        tenantName: string,
        tenantOcid: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciTenantDeletionAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            tenantFolderPath,
            tenantName,
            tenantOcid);
    }
}

export class OciTenantEntityProfile extends OciIamResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciTenantEntityProfile");
    }
}

export class OciTenantModel extends CloudProviderTenantModel {
    constructor(
        public configuration: OciTenantConfiguration,
        public state: OciTenantState,
        statusSeverity: Optional<Severity>,
        tenantType: TenantType,
        public status: OciTenantModelStatus) {
        super(
            configuration,
            state,
            statusSeverity,
            tenantType);
    }
}

export class OciTenantState extends CloudProviderTenantState implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        public monitoring: OciTenantStateMonitoring,
        orchestration: TenantStateOrchestration,
        permissionManagementEnabled: boolean,
        permissionManagementPrincipalTenantId: Optional<string>,
        permissionTypes: CloudProviderTenantPermissionType[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciTenantState",
            monitoring,
            orchestration,
            permissionManagementEnabled,
            permissionManagementPrincipalTenantId,
            permissionTypes);
    }
}

export class OciTenantStateMonitoring extends TenantStateMonitoring implements ITypeNameObject {
    constructor(
        analysisAccessDeniedErrorMessage: Optional<string>,
        eventAnalysisAccessDeniedErrorMessage: Optional<string>,
        systemEnabled: boolean,
        time: string,
        public issue: Optional<OciTenantStateMonitoringIssue>) {
        super(
            "OciTenantStateMonitoring",
            analysisAccessDeniedErrorMessage,
            eventAnalysisAccessDeniedErrorMessage,
            systemEnabled,
            time);
    }
}

export class OciTenantUpdateAuditEvent extends OciTenantAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        tenantFolderPath: string,
        tenantName: string,
        tenantOcid: string,
        public tenantFolderPathChanged: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciTenantUpdateAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            tenantFolderPath,
            tenantName,
            tenantOcid);
    }
}

export class OciUGbuwacsSaasEnvironmentGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciUGbuwacsSaasEnvironmentGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciUnifiedAgentConfigurationGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciUnifiedAgentConfigurationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciUserGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciUserGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciVaultGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciVaultGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciVaultSecretGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciVaultSecretGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciVbsInstanceGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciVbsInstanceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciVcnGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciVcnGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciVirtualCircuitGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciVirtualCircuitGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciVirtualDeploymentGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciVirtualDeploymentGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciVirtualServiceGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciVirtualServiceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciVirtualServiceRouteTableGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciVirtualServiceRouteTableGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciVisualBuilderInstanceGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciVisualBuilderInstanceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciVlanGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciVlanGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciVmClusterGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciVmClusterGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciVmClusterNetworkGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciVmClusterNetworkGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciVmwareBillingLinkGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciVmwareBillingLinkGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciVmwareClusterGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciVmwareClusterGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciVmwareDatastoreClusterGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciVmwareDatastoreClusterGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciVmwareDatastoreGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciVmwareDatastoreGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciVmwareEsxiHostGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciVmwareEsxiHostGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciVmwareSddcGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciVmwareSddcGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciVnicGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciVnicGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciVolumeBackupGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciVolumeBackupGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciVolumeBackupPolicyGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciVolumeBackupPolicyGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciVolumeGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciVolumeGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciVolumeGroupBackupGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciVolumeGroupBackupGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciVolumeGroupGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciVolumeGroupGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciVolumeGroupReplicaGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciVolumeGroupReplicaGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciVolumeReplicaGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciVolumeReplicaGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciVssContainerScanRecipeGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciVssContainerScanRecipeGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciVssContainerScanTargetGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciVssContainerScanTargetGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciVssHostScanRecipeGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciVssHostScanRecipeGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciVssHostScanTargetGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciVssHostScanTargetGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciWaasAddressListGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciWaasAddressListGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciWaasCertificateGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciWaasCertificateGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciWaasCustomProtectionRuleGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciWaasCustomProtectionRuleGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciWaasPolicyGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciWaasPolicyGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciWebAppAccelerationGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciWebAppAccelerationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciWebAppAccelerationPolicyGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciWebAppAccelerationPolicyGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciWebAppFirewallGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciWebAppFirewallGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciWebAppFirewallNetworkAddressListGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciWebAppFirewallNetworkAddressListGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciWebAppFirewallPolicyGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciWebAppFirewallPolicyGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciWlmsWlsDomainGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciWlmsWlsDomainGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciZprPolicyGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciZprPolicyGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OktaDirectoryApplicationGroupAssignment extends Subdocument implements ITypeNameObject {
    constructor(
        typeName: string,
        public groupId: string,
        public priorityIndex: number) {
        super(typeName);
    }
}

export class OktaDirectoryApplicationUserAssignment extends Subdocument implements ITypeNameObject {
    constructor(
        typeName: string,
        public creationTime: string,
        public type: OktaDirectoryApplicationUserAssignmentType,
        public userId: string) {
        super(typeName);
    }
}

export class OktaDirectoryAwsApplicationGroupAssignment extends OktaDirectoryApplicationGroupAssignment implements ITypeNameObject {
    constructor(
        groupId: string,
        priorityIndex: number,
        public awsRoleNames: string[]) {
        super(
            "OktaDirectoryAwsApplicationGroupAssignment",
            groupId,
            priorityIndex);
    }
}

export class OktaDirectoryAwsApplicationUserAssignment extends OktaDirectoryApplicationUserAssignment implements ITypeNameObject {
    constructor(
        creationTime: string,
        type: OktaDirectoryApplicationUserAssignmentType,
        userId: string,
        public awsRoleNames: string[],
        public awsRoleSessionName: Optional<string>) {
        super(
            "OktaDirectoryAwsApplicationUserAssignment",
            creationTime,
            type,
            userId);
    }
}

export class OktaDirectoryAwsGenericApplicationGroupAssignment extends OktaDirectoryApplicationGroupAssignment implements ITypeNameObject {
    constructor(
        groupId: string,
        priorityIndex: number,
        public attributeNameToValuesMap: Dictionary<string[]>) {
        super(
            "OktaDirectoryAwsGenericApplicationGroupAssignment",
            groupId,
            priorityIndex);
    }
}

export class OktaDirectoryAwsGenericApplicationUserAssignment extends OktaDirectoryApplicationUserAssignment implements ITypeNameObject {
    constructor(
        creationTime: string,
        type: OktaDirectoryApplicationUserAssignmentType,
        userId: string,
        public attributeNameToValuesMap: Dictionary<string[]>) {
        super(
            "OktaDirectoryAwsGenericApplicationUserAssignment",
            creationTime,
            type,
            userId);
    }
}

export class OktaDirectoryAwsSsoApplicationGroupAssignment extends OktaDirectoryApplicationGroupAssignment implements ITypeNameObject {
    constructor(
        groupId: string,
        priorityIndex: number) {
        super(
            "OktaDirectoryAwsSsoApplicationGroupAssignment",
            groupId,
            priorityIndex);
    }
}

export class OktaDirectoryAwsSsoApplicationUserAssignment extends OktaDirectoryApplicationUserAssignment implements ITypeNameObject {
    constructor(
        creationTime: string,
        type: OktaDirectoryApplicationUserAssignmentType,
        userId: string,
        public awsIdentityStoreUserRawId: Optional<string>,
        public awsRoleSessionName: Optional<string>) {
        super(
            "OktaDirectoryAwsSsoApplicationUserAssignment",
            creationTime,
            type,
            userId);
    }
}

export class OktaDirectoryEntityModelFilters extends EntityModelFilters implements IEntityModelFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        openRelatedEntityRiskTypeIdentifierItems: FilterItems<string>,
        regionIdItems: FilterItems<string>,
        tenantIdItems: FilterItems<string>,
        typeNameItems: FilterItems<string>,
        public creationTimeRange: FilterTimeRange) {
        super(
            attributeValueItems,
            openRelatedEntityRiskTypeIdentifierItems,
            regionIdItems,
            tenantIdItems,
            typeNameItems);
    }
}

export class OktaDirectoryEntityTypeMetadata extends EntityTypeMetadata {
    constructor() {
        super();
    }
}

export class OktaDirectoryUserModelFilters extends OktaDirectoryEntityModelFilters implements IEntityModelFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        openRelatedEntityRiskTypeIdentifierItems: FilterItems<string>,
        regionIdItems: FilterItems<string>,
        tenantIdItems: FilterItems<string>,
        typeNameItems: FilterItems<string>,
        creationTimeRange: FilterTimeRange,
        public activityTimeRange: FilterTimeRange) {
        super(
            attributeValueItems,
            openRelatedEntityRiskTypeIdentifierItems,
            regionIdItems,
            tenantIdItems,
            typeNameItems,
            creationTimeRange);
    }
}

export class OktaDirectoryUserProfile extends OktaEntityProfile implements IDocument, ITypeNameObject, IDirectoryUserProfile {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        public activityTime: Optional<string>,
        public aws: DirectoryUserProfileAws) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OktaDirectoryUserProfile");
    }
}

export class OktaEntity extends Entity implements ITypeNameObject, IEntity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        public rawId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime);
    }
}

export class OktaEntityModel extends EntityModel implements ITypeNameObject, IEntityModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown);
    }
}

export class OktaFolderConfiguration extends FolderConfiguration implements ITypeNameObject, IOktaFolderConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        managed: boolean,
        name: string,
        parentScopeId: string,
        root: boolean,
        scopeSections: ScopeConfigurationSections,
        tenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OktaFolderConfiguration",
            managed,
            name,
            parentScopeId,
            root,
            scopeSections,
            tenantType);
    }
}

export class OktaTenantAuditEvent extends AuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        public tenantName: string,
        public tenantUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime);
    }
}

export class OktaTenantConfiguration extends IdentityProviderTenantConfiguration implements ITypeNameObject, IOktaScopeConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        active: boolean,
        deletePending: boolean,
        disabledOrchestrationTypes: OrchestrationType[],
        displayReference: string,
        name: string,
        orchestrationTypeToEnabledMap: Dictionary<boolean>,
        parentScopeId: string,
        scopeSections: ScopeConfigurationSections,
        type: TenantType,
        public adminUrl: string,
        public url: string,
        public userGroupsSync: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OktaTenantConfiguration",
            active,
            deletePending,
            disabledOrchestrationTypes,
            displayReference,
            name,
            orchestrationTypeToEnabledMap,
            parentScopeId,
            scopeSections,
            type);
    }
}

export class OktaTenantCreationAuditEvent extends OktaTenantAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        tenantName: string,
        tenantUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OktaTenantCreationAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            tenantName,
            tenantUrl);
    }
}

export class OktaTenantDeletionAuditEvent extends OktaTenantAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        tenantName: string,
        tenantUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OktaTenantDeletionAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            tenantName,
            tenantUrl);
    }
}

export class OktaTenantModel extends TenantModel {
    constructor(
        public configuration: OktaTenantConfiguration,
        public state: OktaTenantState,
        statusSeverity: Optional<Severity>,
        tenantType: TenantType,
        public status: OktaTenantModelStatus) {
        super(
            configuration,
            state,
            statusSeverity,
            tenantType);
    }
}

export class OktaTenantState extends IdentityProviderTenantState implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        public monitoring: OktaTenantStateMonitoring,
        orchestration: TenantStateOrchestration,
        permissionManagementEnabled: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OktaTenantState",
            monitoring,
            orchestration,
            permissionManagementEnabled);
    }
}

export class OktaTenantStateMonitoring extends TenantStateMonitoring implements ITypeNameObject {
    constructor(
        analysisAccessDeniedErrorMessage: Optional<string>,
        eventAnalysisAccessDeniedErrorMessage: Optional<string>,
        systemEnabled: boolean,
        time: string,
        public permissionManagementIssue: Optional<OktaTenantStateMonitoringPermissionManagementIssue>,
        public userAuthenticationValid: boolean) {
        super(
            "OktaTenantStateMonitoring",
            analysisAccessDeniedErrorMessage,
            eventAnalysisAccessDeniedErrorMessage,
            systemEnabled,
            time);
    }
}

export class OktaTenantUpdateAuditEvent extends OktaTenantAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        tenantName: string,
        tenantUrl: string,
        public tenantNameChanged: boolean,
        public tenantUserTokenChanged: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OktaTenantUpdateAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            tenantName,
            tenantUrl);
    }
}

export class OneLoginDirectoryEntityTypeMetadata extends EntityTypeMetadata {
    constructor() {
        super();
    }
}

export class OneLoginDirectoryUserModelFilters extends EntityModelFilters implements IEntityModelFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        openRelatedEntityRiskTypeIdentifierItems: FilterItems<string>,
        regionIdItems: FilterItems<string>,
        tenantIdItems: FilterItems<string>,
        typeNameItems: FilterItems<string>,
        public activityTimeRange: FilterTimeRange,
        public creationTimeRange: FilterTimeRange) {
        super(
            attributeValueItems,
            openRelatedEntityRiskTypeIdentifierItems,
            regionIdItems,
            tenantIdItems,
            typeNameItems);
    }
}

export class OneLoginDirectoryUserProfile extends OneLoginEntityProfile implements IDocument, ITypeNameObject, IDirectoryUserProfile {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        public activityTime: Optional<string>,
        public aws: DirectoryUserProfileAws) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OneLoginDirectoryUserProfile");
    }
}

export class OneLoginEntity extends Entity implements ITypeNameObject, IEntity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        public rawId: number) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime);
    }
}

export class OneLoginEntityModel extends EntityModel implements ITypeNameObject, IEntityModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown);
    }
}

export class OneLoginFolderConfiguration extends FolderConfiguration implements ITypeNameObject, IOneLoginFolderConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        managed: boolean,
        name: string,
        parentScopeId: string,
        root: boolean,
        scopeSections: ScopeConfigurationSections,
        tenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OneLoginFolderConfiguration",
            managed,
            name,
            parentScopeId,
            root,
            scopeSections,
            tenantType);
    }
}

export class OneLoginTenantAuditEvent extends AuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        public tenantName: string,
        public tenantRawId: string,
        public tenantUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime);
    }
}

export class OneLoginTenantConfiguration extends IdentityProviderTenantConfiguration implements ITypeNameObject, IOneLoginScopeConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        active: boolean,
        deletePending: boolean,
        disabledOrchestrationTypes: OrchestrationType[],
        displayReference: string,
        name: string,
        orchestrationTypeToEnabledMap: Dictionary<boolean>,
        parentScopeId: string,
        scopeSections: ScopeConfigurationSections,
        type: TenantType,
        public clientData: OneLoginTenantConfigurationClientData,
        public permissionManagementClientData: Optional<OneLoginTenantConfigurationClientData>,
        public rawId: string,
        public url: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OneLoginTenantConfiguration",
            active,
            deletePending,
            disabledOrchestrationTypes,
            displayReference,
            name,
            orchestrationTypeToEnabledMap,
            parentScopeId,
            scopeSections,
            type);
    }
}

export class OneLoginTenantCreationAuditEvent extends OneLoginTenantAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        tenantName: string,
        tenantRawId: string,
        tenantUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OneLoginTenantCreationAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            tenantName,
            tenantRawId,
            tenantUrl);
    }
}

export class OneLoginTenantDeletionAuditEvent extends OneLoginTenantAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        tenantName: string,
        tenantRawId: string,
        tenantUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OneLoginTenantDeletionAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            tenantName,
            tenantRawId,
            tenantUrl);
    }
}

export class OneLoginTenantModel extends TenantModel {
    constructor(
        public configuration: OneLoginTenantConfiguration,
        public state: OneLoginTenantState,
        statusSeverity: Optional<Severity>,
        tenantType: TenantType,
        public status: OneLoginTenantModelStatus) {
        super(
            configuration,
            state,
            statusSeverity,
            tenantType);
    }
}

export class OneLoginTenantState extends IdentityProviderTenantState implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        public monitoring: OneLoginTenantStateMonitoring,
        orchestration: TenantStateOrchestration,
        permissionManagementEnabled: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OneLoginTenantState",
            monitoring,
            orchestration,
            permissionManagementEnabled);
    }
}

export class OneLoginTenantStateMonitoring extends TenantStateMonitoring implements ITypeNameObject {
    constructor(
        analysisAccessDeniedErrorMessage: Optional<string>,
        eventAnalysisAccessDeniedErrorMessage: Optional<string>,
        systemEnabled: boolean,
        time: string,
        public clientAuthenticationValid: boolean,
        public permissionManagementIssue: Optional<OneLoginTenantStateMonitoringPermissionManagementIssue>) {
        super(
            "OneLoginTenantStateMonitoring",
            analysisAccessDeniedErrorMessage,
            eventAnalysisAccessDeniedErrorMessage,
            systemEnabled,
            time);
    }
}

export class OneLoginTenantUpdateAuditEvent extends OneLoginTenantAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        tenantName: string,
        tenantRawId: string,
        tenantUrl: string,
        public tenantClientIdChanged: boolean,
        public tenantClientSecretChanged: boolean,
        public tenantNameChanged: boolean,
        public tenantUrlChanged: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OneLoginTenantUpdateAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            tenantName,
            tenantRawId,
            tenantUrl);
    }
}

export class OpenShiftProjectData extends KubernetesResourceData implements ITypeNameObject, IKubernetesResourceData {
    constructor(
        annotationKeyToValueMap: Dictionary<string>,
        controllerResourceReference: Optional<KubernetesResourceReference>,
        name: string,
        path: string,
        rawId: string,
        rawYaml: Optional<string>) {
        super(
            "OpenShiftProjectData",
            annotationKeyToValueMap,
            controllerResourceReference,
            name,
            path,
            rawId,
            rawYaml);
    }
}

export class OpenShiftSecurityContextConstraintData extends KubernetesResourceData implements ITypeNameObject, IKubernetesResourceData {
    constructor(
        annotationKeyToValueMap: Dictionary<string>,
        controllerResourceReference: Optional<KubernetesResourceReference>,
        name: string,
        path: string,
        rawId: string,
        rawYaml: Optional<string>) {
        super(
            "OpenShiftSecurityContextConstraintData",
            annotationKeyToValueMap,
            controllerResourceReference,
            name,
            path,
            rawId,
            rawYaml);
    }
}

export class OpEntity extends Entity implements ITypeNameObject, IEntity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime);
    }
}

export class OpEntityModel extends EntityModel implements ITypeNameObject, IEntityModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown);
    }
}

export class OpEntityTypeMetadata extends EntityTypeMetadata {
    constructor() {
        super();
    }
}

export class OpKubernetesResourceTypeMetadata extends OpEntityTypeMetadata {
    constructor() {
        super();
    }
}

export class OpResource extends OpEntity implements ITypeNameObject, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        public name: string,
        public tagExists: boolean,
        public tags: ResourceTag[],
        public updateTime: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime);
    }
}

export class OpResourceModel extends OpEntityModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        public creationTime: Optional<string>,
        public creatorIdentityIdReference: Optional<string>,
        public creatorOriginatorEntityIdReference: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown);
    }
}

export class OpResourceModelFilters extends EntityModelFilters implements IEntityModelFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        openRelatedEntityRiskTypeIdentifierItems: FilterItems<string>,
        regionIdItems: FilterItems<string>,
        tenantIdItems: FilterItems<string>,
        typeNameItems: FilterItems<string>,
        public creationTimeRange: FilterTimeRange) {
        super(
            attributeValueItems,
            openRelatedEntityRiskTypeIdentifierItems,
            regionIdItems,
            tenantIdItems,
            typeNameItems);
    }
}

export class OpTenantAuditEvent extends AuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        public tenantFolderPath: string,
        public tenantName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime);
    }
}

export class OpTenantConfiguration extends CloudProviderTenantConfiguration implements ITypeNameObject, IChildScopeConfiguration, IOrganizationTenantConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        active: boolean,
        deletePending: boolean,
        disabledOrchestrationTypes: OrchestrationType[],
        displayReference: string,
        name: string,
        orchestrationTypeToEnabledMap: Dictionary<boolean>,
        parentScopeId: string,
        scopeSections: ScopeConfigurationSections,
        type: TenantType,
        organizationExists: boolean,
        organizationId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpTenantConfiguration",
            active,
            deletePending,
            disabledOrchestrationTypes,
            displayReference,
            name,
            orchestrationTypeToEnabledMap,
            parentScopeId,
            scopeSections,
            type,
            organizationExists,
            organizationId);
    }
}

export class OpTenantCreationAuditEvent extends OpTenantAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        tenantFolderPath: string,
        tenantName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpTenantCreationAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            tenantFolderPath,
            tenantName);
    }
}

export class OpTenantDeletionAuditEvent extends OpTenantAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        tenantFolderPath: string,
        tenantName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpTenantDeletionAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            tenantFolderPath,
            tenantName);
    }
}

export class OpTenantModel extends CloudProviderTenantModel {
    constructor(
        public configuration: OpTenantConfiguration,
        public state: OpTenantState,
        statusSeverity: Optional<Severity>,
        tenantType: TenantType,
        public status: OpTenantModelStatus) {
        super(
            configuration,
            state,
            statusSeverity,
            tenantType);
    }
}

export class OpTenantState extends CloudProviderTenantState implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        monitoring: TenantStateMonitoring,
        orchestration: TenantStateOrchestration,
        permissionManagementEnabled: boolean,
        permissionManagementPrincipalTenantId: Optional<string>,
        permissionTypes: CloudProviderTenantPermissionType[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpTenantState",
            monitoring,
            orchestration,
            permissionManagementEnabled,
            permissionManagementPrincipalTenantId,
            permissionTypes);
    }
}

export class OpTenantStateMonitoring extends TenantStateMonitoring implements ITypeNameObject {
    constructor(
        analysisAccessDeniedErrorMessage: Optional<string>,
        eventAnalysisAccessDeniedErrorMessage: Optional<string>,
        systemEnabled: boolean,
        time: string) {
        super(
            "OpTenantStateMonitoring",
            analysisAccessDeniedErrorMessage,
            eventAnalysisAccessDeniedErrorMessage,
            systemEnabled,
            time);
    }
}

export class OpTenantUpdateAuditEvent extends OpTenantAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        tenantFolderPath: string,
        tenantName: string,
        public tenantFolderPathChanged: boolean,
        public tenantNameChanged: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpTenantUpdateAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            tenantFolderPath,
            tenantName);
    }
}

export class OpUnmanagedKubernetesCluster extends OpEntity implements ITypeNameObject, IUnmanagedKubernetesCluster {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        public data: KubernetesClusterData,
        public platform: Optional<KubernetesClusterPlatform>,
        public platformVersion: Optional<string>,
        public version: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpUnmanagedKubernetesCluster",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime);
    }
}

export class OpUnmanagedKubernetesClusterModel extends OpEntityModel implements ITypeNameObject, IUdmObjectModel, IUnmanagedKubernetesClusterModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        public apiConnectivityStatus: Optional<SystemKubernetesClusterApiConnectivityStatus>,
        public data: KubernetesClusterModelData,
        public helm: KubernetesClusterModelHelm,
        public resourceUpdateTime: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpUnmanagedKubernetesClusterModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown);
    }
}

export class OpUnmanagedKubernetesClusterModelFilters extends EntityModelFilters implements IUnmanagedKubernetesClusterModelFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        openRelatedEntityRiskTypeIdentifierItems: FilterItems<string>,
        regionIdItems: FilterItems<string>,
        tenantIdItems: FilterItems<string>,
        typeNameItems: FilterItems<string>,
        public kubernetesCluster: IUnmanagedKubernetesClusterModelFiltersSection) {
        super(
            attributeValueItems,
            openRelatedEntityRiskTypeIdentifierItems,
            regionIdItems,
            tenantIdItems,
            typeNameItems);
    }
}

export class OpVirtualMachine extends OpResource implements ITypeNameObject, IVirtualMachine {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        public operatingSystemId: string,
        public operatingSystemType: OperatingSystemType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpVirtualMachine",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime);
    }
}

export class OpVirtualMachineModel extends OpResourceModel implements ITypeNameObject, IUdmObjectModel, IVirtualMachineModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        public entityNetwork: Optional<NetworkAccessResourceStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        public diskEncryptionVaultIdReference: Optional<string>,
        public identityPermissionActionSeverity: Optional<Severity>,
        public kubernetesClusterSensor: boolean,
        public operatingSystemReleaseNotesUrl: Optional<string>,
        public operatingSystemType: Optional<OperatingSystemType>,
        public scanTime: Optional<string>,
        public stopped: boolean,
        public stopTime: Optional<string>,
        public vulnerabilities: Optional<VulnerabilityData[]>,
        public vulnerabilitySeveritiesSortValue: string,
        public workloadAnalysisError: Optional<WorkloadAnalysisError>,
        public workloadAnalysisOperatingSystem: Optional<WorkloadResourceScanOperatingSystem>,
        public workloadAnalysisOperatingSystemEndOfLifeDate: Optional<string>,
        public workloadAnalysisOperatingSystemExtendedSupport: Optional<boolean>,
        public workloadAnalysisPriorityScanRequested: boolean,
        public workloadAnalysisStatus: WorkloadAnalysisStatus,
        public workloadAnalysisStatusSortValue: number) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpVirtualMachineModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class OpVirtualMachineModelFilters extends OpResourceModelFilters implements IEntityModelFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        openRelatedEntityRiskTypeIdentifierItems: FilterItems<string>,
        regionIdItems: FilterItems<string>,
        tenantIdItems: FilterItems<string>,
        typeNameItems: FilterItems<string>,
        creationTimeRange: FilterTimeRange,
        public scanTimeRange: FilterTimeRange) {
        super(
            attributeValueItems,
            openRelatedEntityRiskTypeIdentifierItems,
            regionIdItems,
            tenantIdItems,
            typeNameItems,
            creationTimeRange);
    }
}

export class OrchestrationStageState extends SystemEntityState implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        public data: OrchestrationStageStateData,
        public orchestrationType: OrchestrationType,
        public stageTypeName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName);
    }
}

export class PartialEntity extends Entity implements ITypeNameObject, IPartialEntity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        public name: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime);
    }
}

export class PartialEntityModel extends EntityModel implements ITypeNameObject, IEntityModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown);
    }
}

export class PartialEntityTypeMetadata extends EntityTypeMetadata {
    constructor() {
        super();
    }
}

export class PartialIdentityModel extends PartialEntityModel implements ITypeNameObject, IEntityModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown);
    }
}

export class PartialPrincipal extends PartialEntity implements ITypeNameObject, IPartialEntity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name);
    }
}

export class PermissionEligibilityAuditEventFilters extends AuditEventFilters {
    constructor(
        identitySearchableReferenceItems: FilterItems<EntitySearchableReference>,
        scopeIdItems: FilterItems<string>,
        systemCreationTimeRange: FilterTimeRange,
        typeNameItems: FilterItems<string>,
        public nameItems: FilterItems<string>,
        public permissionRequestScopePathItems: FilterItems<string>,
        public principalTenantIdItems: FilterItems<string>) {
        super(
            identitySearchableReferenceItems,
            scopeIdItems,
            systemCreationTimeRange,
            typeNameItems);
    }
}

export class PermissionEligibilityAuditEventModel extends PermissionManagementAuditEventModel {
    constructor(
        public auditEvent: PermissionEligibilityAuditEvent,
        entityIds: string[],
        public permissionEligibilityData: PermissionEligibilityAuditEventModelPermissionEligibilityData) {
        super(
            auditEvent,
            entityIds);
    }
}

export class PermissionEligibilityAuditEventModelGroupMembershipEligibilityData extends PermissionEligibilityAuditEventModelPermissionEligibilityData {
    constructor(
        entityIds: string[],
        granteePrincipalIdReferences: string[],
        levelToApproverPrincipalIdReferencesMap: Dictionary<string[]>,
        public groupIdReferences: string[]) {
        super(
            entityIds,
            granteePrincipalIdReferences,
            levelToApproverPrincipalIdReferencesMap);
    }
}

export class PermissionEligibilityAuditEventModelOneLoginDirectoryRoleAssignmentEligibilityData extends PermissionEligibilityAuditEventModelPermissionEligibilityData {
    constructor(
        entityIds: string[],
        granteePrincipalIdReferences: string[],
        levelToApproverPrincipalIdReferencesMap: Dictionary<string[]>,
        public roleIdReferences: string[]) {
        super(
            entityIds,
            granteePrincipalIdReferences,
            levelToApproverPrincipalIdReferencesMap);
    }
}

export class PermissionEligibilityAuditEventModelPermissionAssignmentEligibilityData extends PermissionEligibilityAuditEventModelPermissionEligibilityData {
    constructor(
        entityIds: string[],
        granteePrincipalIdReferences: string[],
        levelToApproverPrincipalIdReferencesMap: Dictionary<string[]>) {
        super(
            entityIds,
            granteePrincipalIdReferences,
            levelToApproverPrincipalIdReferencesMap);
    }
}

export class PermissionEligibilityAuditEventPermissionEligibilityData extends Subdocument implements ITypeNameObject {
    constructor(
        typeName: string,
        public expirationTimeFrame: string,
        public name: string,
        public principalTenantId: string,
        public reasonRequired: boolean) {
        super(typeName);
    }
}

export class PermissionEligibilityState extends SystemEntityState implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName);
    }
}

export class PermissionEligibilityUpdateAuditEventAwsSsoPermissionSetAssignmentEligibilityChangesPermissionsChange extends Subdocument implements ITypeNameObject {
    constructor(
        typeName: string) {
        super(typeName);
    }
}

export class PermissionEligibilityUpdateAuditEventAwsSsoPermissionSetAssignmentEligibilityChangesPermissionSetPermissionsChange extends PermissionEligibilityUpdateAuditEventAwsSsoPermissionSetAssignmentEligibilityChangesPermissionsChange implements ITypeNameObject {
    constructor() {
        super("PermissionEligibilityUpdateAuditEventAwsSsoPermissionSetAssignmentEligibilityChangesPermissionSetPermissionsChange");
    }
}

export class PermissionEligibilityUpdateAuditEventAwsSsoPermissionSetAssignmentEligibilityChangesPermissionsTypeChange extends PermissionEligibilityUpdateAuditEventAwsSsoPermissionSetAssignmentEligibilityChangesPermissionsChange implements ITypeNameObject {
    constructor() {
        super("PermissionEligibilityUpdateAuditEventAwsSsoPermissionSetAssignmentEligibilityChangesPermissionsTypeChange");
    }
}

export class PermissionEligibilityUpdateAuditEventAwsSsoPermissionSetAssignmentEligibilityChangesPolicyPermissionsChange extends PermissionEligibilityUpdateAuditEventAwsSsoPermissionSetAssignmentEligibilityChangesPermissionsChange implements ITypeNameObject {
    constructor(
        public awsManagedPoliciesChanged: boolean,
        public inlinePolicyDocumentChanged: boolean) {
        super("PermissionEligibilityUpdateAuditEventAwsSsoPermissionSetAssignmentEligibilityChangesPolicyPermissionsChange");
    }
}

export class PermissionEligibilityUpdateAuditEventPermissionEligibilityChanges extends Subdocument implements ITypeNameObject {
    constructor(
        typeName: string,
        public approvalRequiredChanged: boolean,
        public expirationTimeFrameChanged: boolean,
        public granteePrincipalsChanged: boolean,
        public levelToApproverPrincipalsMapChanged: boolean,
        public nameChanged: boolean,
        public principalTenantChanged: boolean,
        public reasonRequiredChanged: boolean) {
        super(typeName);
    }
}

export class PermissionFilters extends Summary {
    constructor(
        public destinationEntityAttributeValueItems: FilterItems<string>,
        public destinationEntityTenantIdItems: FilterItems<string>,
        public destinationEntityTypeNameItems: FilterItems<string>,
        public originatorEntityTenantIdItems: FilterItems<string>,
        public originatorEntityTypeNameItems: FilterItems<string>,
        public permissionActionRawValueItems: FilterItems<string>,
        public permissionActionServiceIdItems: FilterItems<string>,
        public sourceEntityAttributeValueItems: FilterItems<string>,
        public sourceEntityTenantIdItems: FilterItems<string>,
        public sourceEntityTypeNameItems: FilterItems<string>) {
        super();
    }
}

export class PermissionManagementAuditEvent extends AuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime);
    }
}

export class PermissionManagementControllerAwsPermissionEligibilityFilters extends PermissionManagementControllerPermissionEligibilityFilters {
    constructor(
        approverPrincipalSearchableReferenceItems: FilterItems<EntitySearchableReference>,
        granteePrincipalSearchableReferenceItems: FilterItems<EntitySearchableReference>,
        nameItems: FilterItems<string>,
        principalTenantIdItems: FilterItems<string>,
        public awsPermitterSearchableReferenceItems: FilterItems<EntitySearchableReference>) {
        super(
            approverPrincipalSearchableReferenceItems,
            granteePrincipalSearchableReferenceItems,
            nameItems,
            principalTenantIdItems);
    }
}

export class PermissionManagementControllerAzurePermissionEligibilityFilters extends PermissionManagementControllerPermissionEligibilityFilters {
    constructor(
        approverPrincipalSearchableReferenceItems: FilterItems<EntitySearchableReference>,
        granteePrincipalSearchableReferenceItems: FilterItems<EntitySearchableReference>,
        nameItems: FilterItems<string>,
        principalTenantIdItems: FilterItems<string>,
        public roleDefinitionSearchableReferences: EntitySearchableReference[]) {
        super(
            approverPrincipalSearchableReferenceItems,
            granteePrincipalSearchableReferenceItems,
            nameItems,
            principalTenantIdItems);
    }
}

export class PermissionManagementControllerGcpPermissionEligibilityFilters extends PermissionManagementControllerPermissionEligibilityFilters {
    constructor(
        approverPrincipalSearchableReferenceItems: FilterItems<EntitySearchableReference>,
        granteePrincipalSearchableReferenceItems: FilterItems<EntitySearchableReference>,
        nameItems: FilterItems<string>,
        principalTenantIdItems: FilterItems<string>,
        public roleSearchableReferences: EntitySearchableReference[]) {
        super(
            approverPrincipalSearchableReferenceItems,
            granteePrincipalSearchableReferenceItems,
            nameItems,
            principalTenantIdItems);
    }
}

export class PermissionManagementControllerGetAwsPermissionEligibilityModelPageRequestFilters extends PermissionManagementControllerGetPermissionEligibilityModelPageRequestFilters {
    constructor(
        approvalRequired: Optional<boolean>,
        approverPrincipalIdSelection: Optional<ItemSelection<string>>,
        granteePrincipalIdSelection: Optional<ItemSelection<string>>,
        nameSelection: Optional<ItemSelection<string>>,
        principalTenantIdSelection: Optional<ItemSelection<string>>,
        public permitterIdSelection: Optional<ItemSelection<string>>) {
        super(
            approvalRequired,
            approverPrincipalIdSelection,
            granteePrincipalIdSelection,
            nameSelection,
            principalTenantIdSelection);
    }
}

export class PermissionManagementControllerGetAzurePermissionEligibilityModelPageRequestFilters extends PermissionManagementControllerGetPermissionEligibilityModelPageRequestFilters {
    constructor(
        approvalRequired: Optional<boolean>,
        approverPrincipalIdSelection: Optional<ItemSelection<string>>,
        granteePrincipalIdSelection: Optional<ItemSelection<string>>,
        nameSelection: Optional<ItemSelection<string>>,
        principalTenantIdSelection: Optional<ItemSelection<string>>,
        public roleDefinitionIdSelection: Optional<ItemSelection<string>>) {
        super(
            approvalRequired,
            approverPrincipalIdSelection,
            granteePrincipalIdSelection,
            nameSelection,
            principalTenantIdSelection);
    }
}

export class PermissionManagementControllerGetGcpPermissionEligibilityModelPageRequestFilters extends PermissionManagementControllerGetPermissionEligibilityModelPageRequestFilters {
    constructor(
        approvalRequired: Optional<boolean>,
        approverPrincipalIdSelection: Optional<ItemSelection<string>>,
        granteePrincipalIdSelection: Optional<ItemSelection<string>>,
        nameSelection: Optional<ItemSelection<string>>,
        principalTenantIdSelection: Optional<ItemSelection<string>>,
        public roleIdSelection: Optional<ItemSelection<string>>) {
        super(
            approvalRequired,
            approverPrincipalIdSelection,
            granteePrincipalIdSelection,
            nameSelection,
            principalTenantIdSelection);
    }
}

export class PermissionManagementControllerGetGroupMembershipEligibilityFiltersRequest extends PermissionManagementControllerGetPermissionEligibilityFiltersRequest {
    constructor(
        scope: PermissionManagementControllerPermissionEligibilityRequestScope) {
        super(scope);
    }
}

export class PermissionManagementControllerGetGroupMembershipEligibilityModelPageRequestFilters extends PermissionManagementControllerGetPermissionEligibilityModelPageRequestFilters {
    constructor(
        approvalRequired: Optional<boolean>,
        approverPrincipalIdSelection: Optional<ItemSelection<string>>,
        granteePrincipalIdSelection: Optional<ItemSelection<string>>,
        nameSelection: Optional<ItemSelection<string>>,
        principalTenantIdSelection: Optional<ItemSelection<string>>,
        public groupIdSelection: Optional<ItemSelection<string>>) {
        super(
            approvalRequired,
            approverPrincipalIdSelection,
            granteePrincipalIdSelection,
            nameSelection,
            principalTenantIdSelection);
    }
}

export class PermissionManagementControllerGetGroupMembershipPermissionEligibilityModelPageRequest extends PermissionManagementControllerGetPermissionEligibilityModelPageRequest {
    constructor(
        filters: PermissionManagementControllerGetPermissionEligibilityModelPageRequestFilters,
        limit: number,
        scope: PermissionManagementControllerPermissionEligibilityRequestScope,
        skip: number,
        sort: Optional<PermissionManagementControllerGetPermissionEligibilityModelPageRequestSort>) {
        super(
            filters,
            limit,
            scope,
            skip,
            sort);
    }
}

export class PermissionManagementControllerGetOktaPermissionEligibilityModelPageRequest extends PermissionManagementControllerGetGroupMembershipPermissionEligibilityModelPageRequest {
    constructor(
        filters: PermissionManagementControllerGetPermissionEligibilityModelPageRequestFilters,
        limit: number,
        scope: PermissionManagementControllerPermissionEligibilityRequestScope,
        skip: number,
        sort: Optional<PermissionManagementControllerGetPermissionEligibilityModelPageRequestSort>) {
        super(
            filters,
            limit,
            scope,
            skip,
            sort);
    }
}

export class PermissionManagementControllerGetOneLoginEligibilityFiltersRequest extends PermissionManagementControllerGetPermissionEligibilityFiltersRequest {
    constructor(
        scope: PermissionManagementControllerPermissionEligibilityRequestScope) {
        super(scope);
    }
}

export class PermissionManagementControllerGetOneLoginEligibilityModelPageRequestFilters extends PermissionManagementControllerGetPermissionEligibilityModelPageRequestFilters {
    constructor(
        approvalRequired: Optional<boolean>,
        approverPrincipalIdSelection: Optional<ItemSelection<string>>,
        granteePrincipalIdSelection: Optional<ItemSelection<string>>,
        nameSelection: Optional<ItemSelection<string>>,
        principalTenantIdSelection: Optional<ItemSelection<string>>,
        public roleIdSelection: Optional<ItemSelection<string>>) {
        super(
            approvalRequired,
            approverPrincipalIdSelection,
            granteePrincipalIdSelection,
            nameSelection,
            principalTenantIdSelection);
    }
}

export class PermissionManagementControllerGetOneLoginPermissionEligibilityModelPageRequest extends PermissionManagementControllerGetPermissionEligibilityModelPageRequest {
    constructor(
        filters: PermissionManagementControllerGetPermissionEligibilityModelPageRequestFilters,
        limit: number,
        scope: PermissionManagementControllerPermissionEligibilityRequestScope,
        skip: number,
        sort: Optional<PermissionManagementControllerGetPermissionEligibilityModelPageRequestSort>) {
        super(
            filters,
            limit,
            scope,
            skip,
            sort);
    }
}

export class PermissionManagementControllerGetPermissionAssignmentEligibilityFiltersRequest extends PermissionManagementControllerGetPermissionEligibilityFiltersRequest {
    constructor(
        scope: PermissionManagementControllerPermissionEligibilityRequestScope) {
        super(scope);
    }
}

export class PermissionManagementControllerGetPermissionAssignmentEligibilityModelPageRequest extends PermissionManagementControllerGetPermissionEligibilityModelPageRequest {
    constructor(
        filters: PermissionManagementControllerGetPermissionEligibilityModelPageRequestFilters,
        limit: number,
        scope: PermissionManagementControllerPermissionEligibilityRequestScope,
        skip: number,
        sort: Optional<PermissionManagementControllerGetPermissionEligibilityModelPageRequestSort>) {
        super(
            filters,
            limit,
            scope,
            skip,
            sort);
    }
}

export class PermissionManagementControllerGetPingIdentityPermissionEligibilityModelPageRequest extends PermissionManagementControllerGetGroupMembershipPermissionEligibilityModelPageRequest {
    constructor(
        filters: PermissionManagementControllerGetPermissionEligibilityModelPageRequestFilters,
        limit: number,
        scope: PermissionManagementControllerPermissionEligibilityRequestScope,
        skip: number,
        sort: Optional<PermissionManagementControllerGetPermissionEligibilityModelPageRequestSort>) {
        super(
            filters,
            limit,
            scope,
            skip,
            sort);
    }
}

export class PermissionManagementControllerGroupMembershipEligibilityFilters extends PermissionManagementControllerPermissionEligibilityFilters {
    constructor(
        approverPrincipalSearchableReferenceItems: FilterItems<EntitySearchableReference>,
        granteePrincipalSearchableReferenceItems: FilterItems<EntitySearchableReference>,
        nameItems: FilterItems<string>,
        principalTenantIdItems: FilterItems<string>,
        public groupSearchableReferences: EntitySearchableReference[]) {
        super(
            approverPrincipalSearchableReferenceItems,
            granteePrincipalSearchableReferenceItems,
            nameItems,
            principalTenantIdItems);
    }
}

export class PermissionManagementControllerGroupMembershipEligibilityRequestScope extends PermissionManagementControllerPermissionEligibilityRequestScope {
    constructor(
        id: string,
        includeChildScopes: boolean) {
        super(
            id,
            includeChildScopes);
    }
}

export class PermissionManagementControllerInsertGroupMembershipRequestRequest extends PermissionManagementControllerInsertPermissionRequestRequest {
    constructor(
        expirationTimeFrame: string,
        permissionEligibilityId: string,
        reason: Optional<string>,
        startTime: Optional<string>) {
        super(
            expirationTimeFrame,
            permissionEligibilityId,
            reason,
            startTime);
    }
}

export class PermissionManagementControllerInsertOneLoginDirectoryRoleAssignmentRequestRequest extends PermissionManagementControllerInsertPermissionRequestRequest {
    constructor(
        expirationTimeFrame: string,
        permissionEligibilityId: string,
        reason: Optional<string>,
        startTime: Optional<string>) {
        super(
            expirationTimeFrame,
            permissionEligibilityId,
            reason,
            startTime);
    }
}

export class PermissionManagementControllerInsertPermissionAssignmentRequestRequest extends PermissionManagementControllerInsertPermissionRequestRequest {
    constructor(
        expirationTimeFrame: string,
        permissionEligibilityId: string,
        reason: Optional<string>,
        startTime: Optional<string>,
        public tenantIds: string[]) {
        super(
            expirationTimeFrame,
            permissionEligibilityId,
            reason,
            startTime);
    }
}

export class PermissionManagementControllerOneLoginEligibilityFilters extends PermissionManagementControllerPermissionEligibilityFilters {
    constructor(
        approverPrincipalSearchableReferenceItems: FilterItems<EntitySearchableReference>,
        granteePrincipalSearchableReferenceItems: FilterItems<EntitySearchableReference>,
        nameItems: FilterItems<string>,
        principalTenantIdItems: FilterItems<string>,
        public roleSearchableReferences: EntitySearchableReference[]) {
        super(
            approverPrincipalSearchableReferenceItems,
            granteePrincipalSearchableReferenceItems,
            nameItems,
            principalTenantIdItems);
    }
}

export class PermissionManagementControllerOneLoginEligibilityRequestScope extends PermissionManagementControllerPermissionEligibilityRequestScope {
    constructor(
        id: string,
        includeChildScopes: boolean) {
        super(
            id,
            includeChildScopes);
    }
}

export class PermissionManagementControllerPermissionAssignmentEligibilityRequestScope extends PermissionManagementControllerPermissionEligibilityRequestScope {
    constructor(
        id: string,
        includeChildScopes: boolean) {
        super(
            id,
            includeChildScopes);
    }
}

export class PermissionRequest extends ScopeDocument implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        public closeReason: Optional<PermissionRequestCloseReason>,
        public expirationTime: Optional<string>,
        public expirationTimeFrame: string,
        public granteeUserTenantId: string,
        public permissionEligibilityId: string,
        public reason: Optional<string>,
        public startTime: Optional<string>,
        public status: PermissionRequestStatus,
        public statusUpdateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId);
    }
}

export class PermissionRequestActivationAuditEventModel extends PermissionRequestAuditEventModel {
    constructor(
        public auditEvent: PermissionRequestActivationAuditEvent,
        entityIds: string[],
        approverUserIdReferences: string[],
        permissionRequestData: PermissionRequestAuditEventModelPermissionRequestData,
        public eventGranteeUserIdentityIdReferences: string[],
        public eventGranteeUserOriginatorIds: string[],
        public expirationTime: Optional<string>) {
        super(
            auditEvent,
            entityIds,
            approverUserIdReferences,
            permissionRequestData);
    }
}

export class PermissionRequestActivationFailureAuditEventModel extends PermissionRequestAuditEventModel {
    constructor(
        public auditEvent: PermissionRequestActivationFailureAuditEvent,
        entityIds: string[],
        approverUserIdReferences: string[],
        permissionRequestData: PermissionRequestAuditEventModelPermissionRequestData,
        public activationFailureData: PermissionRequestAuditEventModelPermissionRequestActivationFailureData) {
        super(
            auditEvent,
            entityIds,
            approverUserIdReferences,
            permissionRequestData);
    }
}

export class PermissionRequestActivationFailureData extends Subdocument implements ITypeNameObject {
    constructor(
        typeName: string,
        public errorMessage: Optional<string>,
        public retryCount: number) {
        super(typeName);
    }
}

export class PermissionRequestAuditEvent extends PermissionManagementAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        public permissionRequestData: PermissionRequestAuditEventPermissionRequestData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime);
    }
}

export class PermissionRequestAuditEventFilters extends AuditEventFilters {
    constructor(
        identitySearchableReferenceItems: FilterItems<EntitySearchableReference>,
        scopeIdItems: FilterItems<string>,
        systemCreationTimeRange: FilterTimeRange,
        typeNameItems: FilterItems<string>,
        public granteeUserTenantIdItems: FilterItems<string>,
        public permissionEligibilityNameItems: FilterItems<string>,
        public tenantDataItems: FilterItems<PermissionRequestAuditEventFiltersTenantData>) {
        super(
            identitySearchableReferenceItems,
            scopeIdItems,
            systemCreationTimeRange,
            typeNameItems);
    }
}

export class PermissionRequestAuditEventModelGroupMembershipRequestActivationFailureData extends PermissionRequestAuditEventModelPermissionRequestActivationFailureData {
    constructor(
        activationFailureData: PermissionRequestAuditEventPermissionRequestActivationFailureData,
        public groupIdToErrorMessageMap: Dictionary<string>) {
        super(activationFailureData);
    }
}

export class PermissionRequestAuditEventPermissionRequestActivationFailureData extends Subdocument implements ITypeNameObject {
    constructor(
        typeName: string,
        public errorMessage: Optional<string>) {
        super(typeName);
    }
}

export class PermissionRequestAuditEventPermissionRequestData extends Subdocument implements ITypeNameObject {
    constructor(
        typeName: string,
        public approvalRequired: boolean,
        public expirationTimeFrame: string,
        public granteeUserTenantId: string,
        public id: Optional<string>,
        public permissionEligibilityName: string,
        public reason: Optional<string>,
        public startTime: Optional<string>) {
        super(typeName);
    }
}

export class PermissionRequestCancellationAuditEvent extends PermissionRequestAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        permissionRequestData: PermissionRequestAuditEventPermissionRequestData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "PermissionRequestCancellationAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            permissionRequestData);
    }
}

export class PermissionRequestCreationAuditEvent extends PermissionRequestAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        permissionRequestData: PermissionRequestAuditEventPermissionRequestData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "PermissionRequestCreationAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            permissionRequestData);
    }
}

export class PermissionRequestExpirationAuditEvent extends PermissionRequestAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        permissionRequestData: PermissionRequestAuditEventPermissionRequestData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "PermissionRequestExpirationAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            permissionRequestData);
    }
}

export class PermissionRequestModelActivationFailureData extends Subdocument implements ITypeNameObject {
    constructor(
        typeName: string,
        public activationFailureData: PermissionRequestActivationFailureData) {
        super(typeName);
    }
}

export class PermissionRequestRejectionAuditEvent extends PermissionRequestAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        permissionRequestData: PermissionRequestAuditEventPermissionRequestData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "PermissionRequestRejectionAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            permissionRequestData);
    }
}

export class PermissionRequestRequestExpirationAuditEvent extends PermissionRequestAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        permissionRequestData: PermissionRequestAuditEventPermissionRequestData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "PermissionRequestRequestExpirationAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            permissionRequestData);
    }
}

export class PermissionRequestRevocationAuditEvent extends PermissionRequestAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        permissionRequestData: PermissionRequestAuditEventPermissionRequestData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "PermissionRequestRevocationAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            permissionRequestData);
    }
}

export class PingIdentityDirectoryEntityModelFilters extends EntityModelFilters implements IEntityModelFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        openRelatedEntityRiskTypeIdentifierItems: FilterItems<string>,
        regionIdItems: FilterItems<string>,
        tenantIdItems: FilterItems<string>,
        typeNameItems: FilterItems<string>,
        public creationTimeRange: FilterTimeRange) {
        super(
            attributeValueItems,
            openRelatedEntityRiskTypeIdentifierItems,
            regionIdItems,
            tenantIdItems,
            typeNameItems);
    }
}

export class PingIdentityDirectoryEntityTypeMetadata extends EntityTypeMetadata {
    constructor() {
        super();
    }
}

export class PingIdentityDirectoryUserModelFilters extends PingIdentityDirectoryEntityModelFilters implements IEntityModelFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        openRelatedEntityRiskTypeIdentifierItems: FilterItems<string>,
        regionIdItems: FilterItems<string>,
        tenantIdItems: FilterItems<string>,
        typeNameItems: FilterItems<string>,
        creationTimeRange: FilterTimeRange,
        public activityTimeRange: FilterTimeRange) {
        super(
            attributeValueItems,
            openRelatedEntityRiskTypeIdentifierItems,
            regionIdItems,
            tenantIdItems,
            typeNameItems,
            creationTimeRange);
    }
}

export class PingIdentityDirectoryUserProfile extends PingIdentityEntityProfile implements IDocument, ITypeNameObject, IDirectoryUserProfile {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        public activityTime: Optional<string>,
        public aws: DirectoryUserProfileAws) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "PingIdentityDirectoryUserProfile");
    }
}

export class PingIdentityEntity extends Entity implements ITypeNameObject, IEntity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        public rawId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime);
    }
}

export class PingIdentityEntityModel extends EntityModel implements ITypeNameObject, IEntityModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown);
    }
}

export class PingIdentityFolderConfiguration extends FolderConfiguration implements ITypeNameObject, IPingIdentityFolderConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        managed: boolean,
        name: string,
        parentScopeId: string,
        root: boolean,
        scopeSections: ScopeConfigurationSections,
        tenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "PingIdentityFolderConfiguration",
            managed,
            name,
            parentScopeId,
            root,
            scopeSections,
            tenantType);
    }
}

export class PingIdentityTenantAuditEvent extends AuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        public tenantName: string,
        public tenantRawId: string,
        public tenantRegion: PingIdentityClientRegion) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime);
    }
}

export class PingIdentityTenantConfiguration extends IdentityProviderTenantConfiguration implements ITypeNameObject, IPingIdentityScopeConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        active: boolean,
        deletePending: boolean,
        disabledOrchestrationTypes: OrchestrationType[],
        displayReference: string,
        name: string,
        orchestrationTypeToEnabledMap: Dictionary<boolean>,
        parentScopeId: string,
        scopeSections: ScopeConfigurationSections,
        type: TenantType,
        public clientId: string,
        public homeEnvironmentRawId: string,
        public rawId: string,
        public region: PingIdentityClientRegion) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "PingIdentityTenantConfiguration",
            active,
            deletePending,
            disabledOrchestrationTypes,
            displayReference,
            name,
            orchestrationTypeToEnabledMap,
            parentScopeId,
            scopeSections,
            type);
    }
}

export class PingIdentityTenantCreationAuditEvent extends PingIdentityTenantAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        tenantName: string,
        tenantRawId: string,
        tenantRegion: PingIdentityClientRegion) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "PingIdentityTenantCreationAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            tenantName,
            tenantRawId,
            tenantRegion);
    }
}

export class PingIdentityTenantDeletionAuditEvent extends PingIdentityTenantAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        tenantName: string,
        tenantRawId: string,
        tenantRegion: PingIdentityClientRegion) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "PingIdentityTenantDeletionAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            tenantName,
            tenantRawId,
            tenantRegion);
    }
}

export class PingIdentityTenantModel extends TenantModel {
    constructor(
        public configuration: PingIdentityTenantConfiguration,
        public state: PingIdentityTenantState,
        statusSeverity: Optional<Severity>,
        tenantType: TenantType,
        public status: PingIdentityTenantModelStatus) {
        super(
            configuration,
            state,
            statusSeverity,
            tenantType);
    }
}

export class PingIdentityTenantState extends IdentityProviderTenantState implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        public monitoring: PingIdentityTenantStateMonitoring,
        orchestration: TenantStateOrchestration,
        permissionManagementEnabled: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "PingIdentityTenantState",
            monitoring,
            orchestration,
            permissionManagementEnabled);
    }
}

export class PingIdentityTenantStateMonitoring extends TenantStateMonitoring implements ITypeNameObject {
    constructor(
        analysisAccessDeniedErrorMessage: Optional<string>,
        eventAnalysisAccessDeniedErrorMessage: Optional<string>,
        systemEnabled: boolean,
        time: string,
        public clientAuthenticationValid: boolean,
        public permissionManagementIssue: Optional<PingIdentityTenantStateMonitoringPermissionManagementIssue>) {
        super(
            "PingIdentityTenantStateMonitoring",
            analysisAccessDeniedErrorMessage,
            eventAnalysisAccessDeniedErrorMessage,
            systemEnabled,
            time);
    }
}

export class PingIdentityTenantUpdateAuditEvent extends PingIdentityTenantAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        tenantName: string,
        tenantRawId: string,
        tenantRegion: PingIdentityClientRegion,
        public tenantClientIdChanged: boolean,
        public tenantClientSecretChanged: boolean,
        public tenantNameChanged: boolean,
        public tenantRegionChanged: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "PingIdentityTenantUpdateAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            tenantName,
            tenantRawId,
            tenantRegion);
    }
}

export class Principal extends SystemEntity implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName);
    }
}

export class PrincipalModelAccess extends Subdocument implements ITypeNameObject {
    constructor(
        typeName: string,
        public activity: Activity,
        public permissions: PrincipalModelAccessPermissions,
        public riskCategory: Optional<PrincipalRiskCategory>,
        public risks: PrincipalModelAccessRisks,
        public scopesRisks: PrincipalModelAccessScopesRisk[],
        public types: PrincipalType[]) {
        super(typeName);
    }
}

export class PrincipalModelAccessScopesExcessivePermissions extends PrincipalModelAccessScopesPermissionsAccess {
    constructor(
        actionServiceCount: number) {
        super(actionServiceCount);
    }
}

export class PrincipalModelAccessScopesPermissions extends PrincipalModelAccessScopesPermissionsAccess {
    constructor(
        actionServiceCount: number) {
        super(actionServiceCount);
    }
}

export class PrincipalModelFilters extends EntityModelFilters implements IEntityModelFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        openRelatedEntityRiskTypeIdentifierItems: FilterItems<string>,
        regionIdItems: FilterItems<string>,
        tenantIdItems: FilterItems<string>,
        typeNameItems: FilterItems<string>,
        public activityTimeRange: FilterTimeRange,
        public serviceIdentityOriginatorEntityTenantIdItems: FilterItems<string>,
        public serviceIdentityOriginatorEntityTypeNameItems: FilterItems<string>,
        public vendorItems: FilterItems<Vendor>) {
        super(
            attributeValueItems,
            openRelatedEntityRiskTypeIdentifierItems,
            regionIdItems,
            tenantIdItems,
            typeNameItems);
    }
}

export class ProjectAuditEvent extends AuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        public projectDescription: string,
        public projectName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime);
    }
}

export class ProjectConfiguration extends SystemEntityConfiguration implements ITypeNameObject, IProjectScopeConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        public description: string,
        public name: string,
        public parentScopeId: string,
        public rules: ProjectConfigurationRule[],
        public rulesUpdateTime: string,
        public scopeSections: ScopeConfigurationSections) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "ProjectConfiguration");
    }
}

export class ProjectConfigurationRuleCondition extends Subdocument implements ITypeNameObject {
    constructor(
        typeName: string,
        public not: boolean) {
        super(typeName);
    }
}

export class ProjectConfigurationRuleEntityCondition extends ProjectConfigurationRuleCondition implements ITypeNameObject {
    constructor(
        typeName: string,
        not: boolean) {
        super(
            typeName,
            not);
    }
}

export class ProjectConfigurationRuleTagPatternEntityCondition extends ProjectConfigurationRuleEntityCondition implements ITypeNameObject {
    constructor(
        not: boolean,
        public tagPattern: ResourceTagPattern) {
        super(
            "ProjectConfigurationRuleTagPatternEntityCondition",
            not);
    }
}

export class ProjectConfigurationRuleTenantCondition extends ProjectConfigurationRuleCondition implements ITypeNameObject {
    constructor(
        typeName: string,
        not: boolean) {
        super(
            typeName,
            not);
    }
}

export class ProjectCreationAuditEvent extends ProjectAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        projectDescription: string,
        projectName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "ProjectCreationAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            projectDescription,
            projectName);
    }
}

export class ProjectDeletionAuditEvent extends ProjectAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        projectDescription: string,
        projectName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "ProjectDeletionAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            projectDescription,
            projectName);
    }
}

export class ProjectFolderConfiguration extends FolderConfiguration implements ITypeNameObject, IProjectScopeConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        managed: boolean,
        name: string,
        parentScopeId: string,
        root: boolean,
        scopeSections: ScopeConfigurationSections,
        tenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "ProjectFolderConfiguration",
            managed,
            name,
            parentScopeId,
            root,
            scopeSections,
            tenantType);
    }
}

export class ProjectModel extends ScopeModel {
    constructor(
        public configuration: ProjectConfiguration,
        public state: ProjectState,
        public tenantIds: string[]) {
        super(
            configuration,
            state);
    }
}

export class ProjectState extends SystemEntityState implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        public status: ProjectStateStatus) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "ProjectState");
    }
}

export class ProjectUpdateAuditEvent extends ProjectAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        projectDescription: string,
        projectName: string,
        public projectDescriptionChanged: boolean,
        public projectNameChanged: boolean,
        public projectParentChanged: boolean,
        public projectRulesChanged: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "ProjectUpdateAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            projectDescription,
            projectName);
    }
}

export class QRadarDelivery extends IntegrationDelivery implements ITypeNameObject {
    constructor(
        public destinations: QRadarDeliveryServer[],
        message: Optional<string>,
        integrationIds: string[]) {
        super(
            "QRadarDelivery",
            destinations,
            message,
            integrationIds);
    }
}

export class QRadarDeliveryServer extends DeliveryIntegration implements ITypeNameObject {
    constructor(
        integrationId: string,
        public serverId: string) {
        super(
            "QRadarDeliveryServer",
            integrationId);
    }
}

export class QRadarServerAuditEvent extends AuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        public serverEndpoint: Optional<string>,
        public serverEndpointConnectorName: Optional<string>,
        public serverName: string,
        public serverServerCertificateValidationEnabled: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime);
    }
}

export class QRadarServerCreationAuditEvent extends QRadarServerAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        serverEndpoint: Optional<string>,
        serverEndpointConnectorName: Optional<string>,
        serverName: string,
        serverServerCertificateValidationEnabled: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "QRadarServerCreationAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            serverEndpoint,
            serverEndpointConnectorName,
            serverName,
            serverServerCertificateValidationEnabled);
    }
}

export class QRadarServerDeletionAuditEvent extends QRadarServerAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        serverEndpoint: Optional<string>,
        serverEndpointConnectorName: Optional<string>,
        serverName: string,
        serverServerCertificateValidationEnabled: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "QRadarServerDeletionAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            serverEndpoint,
            serverEndpointConnectorName,
            serverName,
            serverServerCertificateValidationEnabled);
    }
}

export class QRadarServerState extends SystemEntityState implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        public endpoint: Optional<string>,
        public endpointConnectorName: Optional<string>,
        public errorMessage: Optional<string>,
        public failed: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "QRadarServerState");
    }
}

export class QRadarServerUpdateAuditEvent extends QRadarServerAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        serverEndpoint: Optional<string>,
        serverEndpointConnectorName: Optional<string>,
        serverName: string,
        serverServerCertificateValidationEnabled: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "QRadarServerUpdateAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            serverEndpoint,
            serverEndpointConnectorName,
            serverName,
            serverServerCertificateValidationEnabled);
    }
}

export class ReportBase extends ScopeDocument implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        public definitionId: string,
        public deliveries: Delivery[],
        public fileName: string,
        public scheduleId: Optional<string>,
        public sendTime: Optional<string>,
        public size: number) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId);
    }
}

export class ReportControllerAccessReportRequestDefinition extends ReportControllerReportRequestDefinition implements ITypeNameObject {
    constructor(
        name: Optional<string>,
        timeZoneInfoId: Optional<string>,
        typeName: string,
        public direction: EntityAccessDirection,
        public entityId: string,
        public scope: EntityAccessScope) {
        super(
            name,
            timeZoneInfoId,
            typeName);
    }
}

export class ReportControllerAwsAccessReportRequestDefinition extends ReportControllerAccessReportRequestDefinition implements ITypeNameObject {
    constructor(
        name: Optional<string>,
        timeZoneInfoId: Optional<string>,
        typeName: string,
        direction: EntityAccessDirection,
        entityId: string,
        scope: EntityAccessScope,
        public filters: EntityControllerGetAwsAccessDataRequestFilters) {
        super(
            name,
            timeZoneInfoId,
            typeName,
            direction,
            entityId,
            scope);
    }
}

export class ReportControllerAzureAccessReportRequestDefinition extends ReportControllerAccessReportRequestDefinition implements ITypeNameObject {
    constructor(
        name: Optional<string>,
        timeZoneInfoId: Optional<string>,
        typeName: string,
        direction: EntityAccessDirection,
        entityId: string,
        scope: EntityAccessScope,
        public filters: EntityControllerGetAzureAccessDataRequestFilters) {
        super(
            name,
            timeZoneInfoId,
            typeName,
            direction,
            entityId,
            scope);
    }
}

export class ReportControllerCloudRisksReportRequestDefinition extends ReportControllerRisksReportRequestDefinition implements ITypeNameObject {
    constructor(
        name: Optional<string>,
        timeZoneInfoId: Optional<string>,
        typeName: string,
        additionalColumnResourceTagKeys: Optional<string[]>,
        filtered: boolean,
        filters: RiskControllerRiskModelFilters,
        riskPolicyTypeFilters: Optional<RiskControllerRiskPolicyTypeFilters>,
        sort: Optional<RiskControllerRiskModelSort>,
        status: RiskStatus) {
        super(
            name,
            timeZoneInfoId,
            typeName,
            additionalColumnResourceTagKeys,
            filtered,
            filters,
            riskPolicyTypeFilters,
            sort,
            status);
    }
}

export class ReportControllerCodeRisksReportRequestDefinition extends ReportControllerRisksReportRequestDefinition implements ITypeNameObject {
    constructor(
        name: Optional<string>,
        timeZoneInfoId: Optional<string>,
        typeName: string,
        additionalColumnResourceTagKeys: Optional<string[]>,
        filtered: boolean,
        filters: RiskControllerRiskModelFilters,
        riskPolicyTypeFilters: Optional<RiskControllerRiskPolicyTypeFilters>,
        sort: Optional<RiskControllerRiskModelSort>,
        status: RiskStatus) {
        super(
            name,
            timeZoneInfoId,
            typeName,
            additionalColumnResourceTagKeys,
            filtered,
            filters,
            riskPolicyTypeFilters,
            sort,
            status);
    }
}

export class ReportControllerCommonReportRequestDefinition extends ReportControllerReportRequestDefinition implements ITypeNameObject {
    constructor(
        name: Optional<string>,
        timeZoneInfoId: Optional<string>,
        typeName: string,
        public type: ReportType) {
        super(
            name,
            timeZoneInfoId,
            typeName);
    }
}

export class ReportControllerComplianceReportRequestDefinition extends ReportControllerReportRequestDefinition implements ITypeNameObject {
    constructor(
        name: Optional<string>,
        timeZoneInfoId: Optional<string>,
        typeName: string,
        public contentType: ReportContentType) {
        super(
            name,
            timeZoneInfoId,
            typeName);
    }
}

export class ReportControllerComplianceScopesReportRequestDefinition extends ReportControllerReportRequestDefinition implements ITypeNameObject {
    constructor(
        name: Optional<string>,
        timeZoneInfoId: Optional<string>,
        typeName: string,
        public sectionIdentifier: string) {
        super(
            name,
            timeZoneInfoId,
            typeName);
    }
}

export class ReportControllerContainerImagePackageVulnerabilitiesReportRequestDefinition extends ReportControllerWorkloadResourcePackageVulnerabilitiesReportRequestDefinition implements ITypeNameObject {
    constructor(
        name: Optional<string>,
        timeZoneInfoId: Optional<string>,
        typeName: string,
        filtered: boolean,
        filters: WorkloadControllerWorkloadResourceModelFilters,
        includeDescription: Optional<boolean>) {
        super(
            name,
            timeZoneInfoId,
            typeName,
            filtered,
            filters,
            includeDescription);
    }
}

export class ReportControllerContainerImagesReportRequestDefinition extends ReportControllerWorkloadResourcesReportRequestDefinition implements ITypeNameObject {
    constructor(
        name: Optional<string>,
        timeZoneInfoId: Optional<string>,
        typeName: string,
        filtered: boolean,
        filters: WorkloadControllerWorkloadResourceModelFilters,
        sort: WorkloadControllerWorkloadResourceModelSort) {
        super(
            name,
            timeZoneInfoId,
            typeName,
            filtered,
            filters,
            sort);
    }
}

export class ReportControllerCustomComplianceReportRequestDefinition extends ReportControllerComplianceReportRequestDefinition implements ITypeNameObject {
    constructor(
        name: Optional<string>,
        timeZoneInfoId: Optional<string>,
        typeName: string,
        contentType: ReportContentType,
        public customComplianceId: string) {
        super(
            name,
            timeZoneInfoId,
            typeName,
            contentType);
    }
}

export class ReportControllerEventsReportRequestDefinition extends ReportControllerReportRequestDefinition implements ITypeNameObject {
    constructor(
        name: Optional<string>,
        timeZoneInfoId: Optional<string>,
        typeName: string,
        public entityId: Optional<string>,
        public filterMap: Optional<string>,
        public riskId: Optional<string>) {
        super(
            name,
            timeZoneInfoId,
            typeName);
    }
}

export class ReportControllerGcpAccessReportRequestDefinition extends ReportControllerAccessReportRequestDefinition implements ITypeNameObject {
    constructor(
        name: Optional<string>,
        timeZoneInfoId: Optional<string>,
        typeName: string,
        direction: EntityAccessDirection,
        entityId: string,
        scope: EntityAccessScope,
        public filters: EntityControllerGetGcpAccessDataRequestFilters) {
        super(
            name,
            timeZoneInfoId,
            typeName,
            direction,
            entityId,
            scope);
    }
}

export class ReportControllerInventoryReportRequestDefinition extends ReportControllerReportRequestDefinition implements ITypeNameObject {
    constructor(
        name: Optional<string>,
        timeZoneInfoId: Optional<string>,
        typeName: string,
        public data: boolean,
        public entityTypeName: Optional<string>,
        public filterMap: Optional<string>) {
        super(
            name,
            timeZoneInfoId,
            typeName);
    }
}

export class ReportControllerVirtualMachinePackageVulnerabilitiesReportRequestDefinition extends ReportControllerWorkloadResourcePackageVulnerabilitiesReportRequestDefinition implements ITypeNameObject {
    constructor(
        name: Optional<string>,
        timeZoneInfoId: Optional<string>,
        typeName: string,
        filtered: boolean,
        filters: WorkloadControllerWorkloadResourceModelFilters,
        includeDescription: Optional<boolean>,
        public includeResolvedVulnerabilities: Optional<boolean>) {
        super(
            name,
            timeZoneInfoId,
            typeName,
            filtered,
            filters,
            includeDescription);
    }
}

export class ReportControllerVirtualMachinesReportRequestDefinition extends ReportControllerWorkloadResourcesReportRequestDefinition implements ITypeNameObject {
    constructor(
        name: Optional<string>,
        timeZoneInfoId: Optional<string>,
        typeName: string,
        filtered: boolean,
        filters: WorkloadControllerWorkloadResourceModelFilters,
        sort: WorkloadControllerWorkloadResourceModelSort) {
        super(
            name,
            timeZoneInfoId,
            typeName,
            filtered,
            filters,
            sort);
    }
}

export class ReportControllerWorkloadAnalysisMaliciousFilesReportRequestDefinition extends ReportControllerWorkloadAnalysisReportRequestDefinition<WorkloadResourceScanFileModel> implements ITypeNameObject {
    constructor(
        name: Optional<string>,
        timeZoneInfoId: Optional<string>,
        typeName: string,
        filtered: boolean,
        filters: WorkloadControllerWorkloadAnalysisModelsFilters<WorkloadResourceScanFileModel>) {
        super(
            name,
            timeZoneInfoId,
            typeName,
            filtered,
            filters);
    }
}

export class ReportControllerWorkloadAnalysisPackagesReportRequestDefinition extends ReportControllerWorkloadAnalysisReportRequestDefinition<WorkloadResourceScanPackageModel> implements ITypeNameObject {
    constructor(
        name: Optional<string>,
        timeZoneInfoId: Optional<string>,
        typeName: string,
        filtered: boolean,
        filters: WorkloadControllerWorkloadAnalysisModelsFilters<WorkloadResourceScanPackageModel>,
        public includeVulnerabilityResolutionVersions: boolean) {
        super(
            name,
            timeZoneInfoId,
            typeName,
            filtered,
            filters);
    }
}

export class ReportControllerWorkloadAnalysisPackageVulnerabilitiesReportRequestDefinition extends ReportControllerWorkloadAnalysisReportRequestDefinition<WorkloadResourceScanPackageModel> implements ITypeNameObject {
    constructor(
        name: Optional<string>,
        timeZoneInfoId: Optional<string>,
        typeName: string,
        filtered: boolean,
        filters: WorkloadControllerWorkloadAnalysisModelsFilters<WorkloadResourceScanPackageModel>) {
        super(
            name,
            timeZoneInfoId,
            typeName,
            filtered,
            filters);
    }
}

export class ReportDefinitionConfiguration extends SystemEntityConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        public contentType: ReportContentType,
        public exportReport: boolean,
        public licenseType: ApplicationCustomerConfigurationLicensingLicenseType,
        public name: Optional<string>,
        public timeZoneInfoId: string,
        public type: ReportType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName);
    }
}

export class ReportScheduleAuditEvent extends AuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        public reportDefinitionId: string,
        public reportScheduleCadence: ReportScheduleCadence) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime);
    }
}

export class ReportScheduleCreationAuditEvent extends ReportScheduleAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        reportDefinitionId: string,
        reportScheduleCadence: ReportScheduleCadence) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "ReportScheduleCreationAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            reportDefinitionId,
            reportScheduleCadence);
    }
}

export class ReportScheduleDeletionAuditEvent extends ReportScheduleAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        reportDefinitionId: string,
        reportScheduleCadence: ReportScheduleCadence) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "ReportScheduleDeletionAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            reportDefinitionId,
            reportScheduleCadence);
    }
}

export class ReportScheduleUpdateAuditEvent extends ReportScheduleAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        reportDefinitionId: string,
        reportScheduleCadence: ReportScheduleCadence) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "ReportScheduleUpdateAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            reportDefinitionId,
            reportScheduleCadence);
    }
}

export class ResourceOwnerMailDelivery extends Delivery implements ITypeNameObject {
    constructor(
        public destinations: MailDeliveryMail[],
        message: Optional<string>,
        public ccMails: string[]) {
        super(
            "ResourceOwnerMailDelivery",
            destinations,
            message);
    }
}

export class Response extends Subdocument implements ITypeNameObject {
    constructor(
        typeName: string) {
        super(typeName);
    }
}

export class Risk extends TenantDocument implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        public analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        public ignoreExpirationDate: Optional<string>,
        public items: RiskItem[],
        public limitedResults: boolean,
        public openStatusUpdateTime: string,
        public policyId: string,
        public relatedEntityIds: string[],
        public resolutionChanges: Optional<ChangeBase[]>,
        public resolutionReason: Optional<RiskResolutionReason>,
        public riskedEntityIds: string[],
        public scopeIds: string[],
        public severity: Severity,
        public status: RiskStatus,
        public statusUpdateTime: string,
        public subStatus: RiskSubStatus,
        public tenantType: TenantType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId);
    }
}

export class RiskAuditEvent extends AuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        public riskId: string,
        public riskVisible: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime);
    }
}

export class RiskAutomationRuleCreationAuditEvent extends AutomationRuleAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        description: Optional<string>,
        name: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "RiskAutomationRuleCreationAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            description,
            name);
    }
}

export class RiskAutomationRuleDeletionAuditEvent extends AutomationRuleAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        description: Optional<string>,
        name: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "RiskAutomationRuleDeletionAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            description,
            name);
    }
}

export class RiskAutomationRuleUpdateAuditEvent extends AutomationRuleAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        description: Optional<string>,
        name: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "RiskAutomationRuleUpdateAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            description,
            name);
    }
}

export class RiskCodeResolutionAuditEvent extends RiskAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        riskId: string,
        riskVisible: boolean,
        public pullRequestUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "RiskCodeResolutionAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            riskId,
            riskVisible);
    }
}

export class RiskControllerGetBuiltInCloudRiskPolicyModelsRequest extends RiskControllerGetBuiltInRiskPolicyModelsRequest {
    constructor(
        riskPolicyConfigurationTypeNames: string[],
        scopeId: string) {
        super(
            riskPolicyConfigurationTypeNames,
            scopeId);
    }
}

export class RiskControllerGetBuiltInCodeRiskPolicyModelsRequest extends RiskControllerGetBuiltInRiskPolicyModelsRequest {
    constructor(
        riskPolicyConfigurationTypeNames: string[],
        scopeId: string) {
        super(
            riskPolicyConfigurationTypeNames,
            scopeId);
    }
}

export class RiskControllerGetBuiltInKubernetesAdmissionControllerRiskPolicyModelsRequest extends RiskControllerGetBuiltInRiskPolicyModelsRequest {
    constructor(
        riskPolicyConfigurationTypeNames: string[],
        scopeId: string) {
        super(
            riskPolicyConfigurationTypeNames,
            scopeId);
    }
}

export class RiskControllerGetCloudRiskFilterItemPageRequest extends RiskControllerGetRiskFilterItemPageRequest {
    constructor(
        limit: number,
        searchText: Optional<string>,
        skip: number,
        view: RisksView,
        public relatedEntityId: Optional<string>,
        public type: CloudRiskFilterItemType) {
        super(
            limit,
            searchText,
            skip,
            view);
    }
}

export class RiskControllerGetCloudRiskFiltersRequest extends RiskControllerGetRiskFiltersRequest {
    constructor(
        view: RisksView,
        public relatedEntityId: Optional<string>) {
        super(view);
    }
}

export class RiskControllerGetCloudRiskGroupsRequest extends RiskControllerGetRiskGroupsRequest {
    constructor(
        filters: RiskControllerRiskModelFilters,
        type: RiskControllerGetRiskGroupsRequestType) {
        super(
            filters,
            type);
    }
}

export class RiskControllerGetCloudRiskIdToDataMapRequest extends RiskControllerGetRiskIdToDataMapRequest {
    constructor(
        ids: string[]) {
        super(ids);
    }
}

export class RiskControllerGetCloudRiskIdToScopeIdentityPermissionsMapRequest extends RiskControllerGetRiskIdToScopeIdentityPermissionsMapRequest {
    constructor(
        filters: RiskControllerRiskModelFilters) {
        super(filters);
    }
}

export class RiskControllerGetCloudRiskModelPageRequest extends RiskControllerGetRiskModelPageRequest {
    constructor(
        filters: RiskControllerRiskModelFilters,
        limit: number,
        skip: number,
        sort: Optional<RiskControllerRiskModelSort>) {
        super(
            filters,
            limit,
            skip,
            sort);
    }
}

export class RiskControllerGetCloudRiskPoliciesRequest extends RiskControllerGetRiskPoliciesRequest {
    constructor(
        scopeId: string,
        public childScopeCustomRiskPolicies: boolean) {
        super(scopeId);
    }
}

export class RiskControllerGetCloudRiskPolicySummaryRequest extends RiskControllerGetRiskPolicySummaryRequest {
    constructor(
        scopeId: string) {
        super(scopeId);
    }
}

export class RiskControllerGetCodeRiskFilterItemPageRequest extends RiskControllerGetRiskFilterItemPageRequest {
    constructor(
        limit: number,
        searchText: Optional<string>,
        skip: number,
        view: RisksView,
        public type: CodeRiskFilterItemType) {
        super(
            limit,
            searchText,
            skip,
            view);
    }
}

export class RiskControllerGetCodeRiskFiltersRequest extends RiskControllerGetRiskFiltersRequest {
    constructor(
        view: RisksView) {
        super(view);
    }
}

export class RiskControllerGetCodeRiskGroupsRequest extends RiskControllerGetRiskGroupsRequest {
    constructor(
        filters: RiskControllerRiskModelFilters,
        type: RiskControllerGetRiskGroupsRequestType) {
        super(
            filters,
            type);
    }
}

export class RiskControllerGetCodeRiskIdToDataMapRequest extends RiskControllerGetRiskIdToDataMapRequest {
    constructor(
        ids: string[]) {
        super(ids);
    }
}

export class RiskControllerGetCodeRiskIdToScopeIdentityPermissionsMapRequest extends RiskControllerGetRiskIdToScopeIdentityPermissionsMapRequest {
    constructor(
        filters: RiskControllerRiskModelFilters) {
        super(filters);
    }
}

export class RiskControllerGetCodeRiskModelPageRequest extends RiskControllerGetRiskModelPageRequest {
    constructor(
        filters: RiskControllerRiskModelFilters,
        limit: number,
        skip: number,
        sort: Optional<RiskControllerRiskModelSort>) {
        super(
            filters,
            limit,
            skip,
            sort);
    }
}

export class RiskControllerGetCodeRiskPoliciesRequest extends RiskControllerGetRiskPoliciesRequest {
    constructor(
        scopeId: string) {
        super(scopeId);
    }
}

export class RiskControllerGetCodeRiskPolicySummaryRequest extends RiskControllerGetRiskPolicySummaryRequest {
    constructor(
        scopeId: string) {
        super(scopeId);
    }
}

export class RiskControllerGetCustomRiskPolicyModelsRequest extends RiskControllerGetRiskPolicyModelsRequestBase {
    constructor() {
        super();
    }
}

export class RiskControllerGetKubernetesAdmissionControllerRiskPoliciesRequest extends RiskControllerGetRiskPoliciesRequest {
    constructor(
        scopeId: string) {
        super(scopeId);
    }
}

export class RiskControllerGetKubernetesAdmissionControllerRiskPolicySummaryRequest extends RiskControllerGetRiskPolicySummaryRequest {
    constructor(
        scopeId: string) {
        super(scopeId);
    }
}

export class RiskControllerGetRiskFilterItemPageResponse<TItem> extends RiskControllerGetRiskFilterItemPageResponseBase {
    constructor(
        public riskFilterItemPage: ElasticsearchFilterItemPage<TItem>) {
        super();
    }
}

export class RiskControllerGetRiskPoliciesResponseCloudPolicy extends RiskControllerGetRiskPoliciesResponsePolicy {
    constructor(
        enabled: boolean,
        failedTenantCount: number,
        riskCount: number,
        riskPolicyConfigurationTypeName: string,
        public customComplianceIds: string[]) {
        super(
            enabled,
            failedTenantCount,
            riskCount,
            riskPolicyConfigurationTypeName);
    }
}

export class RiskControllerGetRiskPoliciesResponseCodePolicy extends RiskControllerGetRiskPoliciesResponsePolicy {
    constructor(
        enabled: boolean,
        failedTenantCount: number,
        riskCount: number,
        riskPolicyConfigurationTypeName: string,
        public enabledInherited: boolean) {
        super(
            enabled,
            failedTenantCount,
            riskCount,
            riskPolicyConfigurationTypeName);
    }
}

export class RiskControllerGetRiskPoliciesResponseKubernetesAdmissionControllerPolicy extends RiskControllerGetRiskPoliciesResponsePolicy {
    constructor(
        enabled: boolean,
        failedTenantCount: number,
        riskCount: number,
        riskPolicyConfigurationTypeName: string) {
        super(
            enabled,
            failedTenantCount,
            riskCount,
            riskPolicyConfigurationTypeName);
    }
}

export class RiskControllerGetRiskPolicyModelsRequest extends RiskControllerGetRiskPolicyModelsRequestBase {
    constructor(
        public ids: Optional<string[]>) {
        super();
    }
}

export class RiskControllerGetRiskPolicyTypeGroupCloudRiskFiltersRequest extends RiskControllerGetRiskPolicyTypeGroupFiltersRequest {
    constructor(
        filters: RiskControllerRiskModelFilters,
        riskPolicyConfigurationTypeName: string) {
        super(
            filters,
            riskPolicyConfigurationTypeName);
    }
}

export class RiskControllerGetRiskPolicyTypeGroupCloudRiskIdToScopeIdentityPermissionsMapRequest extends RiskControllerGetRiskPolicyTypeGroupRiskIdToScopeIdentityPermissionsMapRequest {
    constructor(
        filters: RiskControllerRiskModelFilters,
        riskPolicyConfigurationTypeName: string) {
        super(
            filters,
            riskPolicyConfigurationTypeName);
    }
}

export class RiskControllerGetRiskPolicyTypeGroupCloudRiskModelPageRequest extends RiskControllerGetRiskPolicyTypeGroupRiskModelPageRequest {
    constructor(
        filters: RiskControllerRiskModelFilters,
        limit: number,
        skip: number,
        sort: Optional<RiskControllerRiskModelSort>,
        riskPolicyTypeFilters: RiskControllerRiskPolicyTypeFilters) {
        super(
            filters,
            limit,
            skip,
            sort,
            riskPolicyTypeFilters);
    }
}

export class RiskControllerGetRiskPolicyTypeGroupCodeRiskFiltersRequest extends RiskControllerGetRiskPolicyTypeGroupFiltersRequest {
    constructor(
        filters: RiskControllerRiskModelFilters,
        riskPolicyConfigurationTypeName: string) {
        super(
            filters,
            riskPolicyConfigurationTypeName);
    }
}

export class RiskControllerGetRiskPolicyTypeGroupCodeRiskIdToScopeIdentityPermissionsMapRequest extends RiskControllerGetRiskPolicyTypeGroupRiskIdToScopeIdentityPermissionsMapRequest {
    constructor(
        filters: RiskControllerRiskModelFilters,
        riskPolicyConfigurationTypeName: string) {
        super(
            filters,
            riskPolicyConfigurationTypeName);
    }
}

export class RiskControllerGetRiskPolicyTypeGroupCodeRiskModelPageRequest extends RiskControllerGetRiskPolicyTypeGroupRiskModelPageRequest {
    constructor(
        filters: RiskControllerRiskModelFilters,
        limit: number,
        skip: number,
        sort: Optional<RiskControllerRiskModelSort>,
        riskPolicyTypeFilters: RiskControllerRiskPolicyTypeFilters) {
        super(
            filters,
            limit,
            skip,
            sort,
            riskPolicyTypeFilters);
    }
}

export class RiskControllerGetRiskPolicyTypeGroupFilterItemPageResponse<TItem> extends RiskControllerGetRiskPolicyTypeGroupFilterItemPageResponseBase {
    constructor(
        public riskPolicyTypeGroupFilterItemPage: ElasticsearchFilterItemPage<TItem>) {
        super();
    }
}

export class RiskControllerGetScopeGroupCloudRiskIdToScopeIdentityPermissionsMapRequest extends RiskControllerGetScopeGroupRiskIdToScopeIdentityPermissionsMapRequest {
    constructor(
        filters: RiskControllerRiskModelFilters,
        tenantId: string) {
        super(
            filters,
            tenantId);
    }
}

export class RiskControllerGetScopeGroupCodeRiskIdToScopeIdentityPermissionsMapRequest extends RiskControllerGetScopeGroupRiskIdToScopeIdentityPermissionsMapRequest {
    constructor(
        filters: RiskControllerRiskModelFilters,
        tenantId: string) {
        super(
            filters,
            tenantId);
    }
}

export class RiskControllerGetTenantGroupCloudRiskModelPageRequest extends RiskControllerGetTenantGroupRiskModelPageRequest {
    constructor(
        filters: RiskControllerRiskModelFilters,
        limit: number,
        skip: number,
        sort: Optional<RiskControllerRiskModelSort>,
        tenantId: string) {
        super(
            filters,
            limit,
            skip,
            sort,
            tenantId);
    }
}

export class RiskControllerGetTenantGroupCodeRiskModelPageRequest extends RiskControllerGetTenantGroupRiskModelPageRequest {
    constructor(
        filters: RiskControllerRiskModelFilters,
        limit: number,
        skip: number,
        sort: Optional<RiskControllerRiskModelSort>,
        tenantId: string) {
        super(
            filters,
            limit,
            skip,
            sort,
            tenantId);
    }
}

export class RiskControllerInsertAwsEc2SecurityGroupInboundRuleRiskPolicyRequest extends RiskControllerInsertCustomRiskPolicyRequest {
    constructor(
        description: Optional<string>,
        name: string,
        scopeId: string,
        severity: Severity,
        public allSecurityGroups: boolean,
        public anyIpV4AddressSubnet: boolean,
        public exactSubnets: Optional<string[]>,
        public inboundAccessScope: Optional<NetworkAccessScope>,
        public networkScopes: DestinationNetworkScope[],
        public overlapSubnets: Optional<string[]>,
        public securityGroupBuiltInAttributeTypeNames: Optional<string[]>,
        public securityGroupCustomAttributeDefinitionIds: Optional<string[]>,
        public securityGroupIds: Optional<string[]>,
        public securityGroupTags: Optional<ResourceTag[]>) {
        super(
            description,
            name,
            scopeId,
            severity);
    }
}

export class RiskControllerInsertAwsEc2SecurityGroupNotAllowedInboundRuleRiskPolicyRequest extends RiskControllerInsertAwsEc2SecurityGroupInboundRuleRiskPolicyRequest {
    constructor(
        description: Optional<string>,
        name: string,
        scopeId: string,
        severity: Severity,
        allSecurityGroups: boolean,
        anyIpV4AddressSubnet: boolean,
        exactSubnets: Optional<string[]>,
        inboundAccessScope: Optional<NetworkAccessScope>,
        networkScopes: DestinationNetworkScope[],
        overlapSubnets: Optional<string[]>,
        securityGroupBuiltInAttributeTypeNames: Optional<string[]>,
        securityGroupCustomAttributeDefinitionIds: Optional<string[]>,
        securityGroupIds: Optional<string[]>,
        securityGroupTags: Optional<ResourceTag[]>) {
        super(
            description,
            name,
            scopeId,
            severity,
            allSecurityGroups,
            anyIpV4AddressSubnet,
            exactSubnets,
            inboundAccessScope,
            networkScopes,
            overlapSubnets,
            securityGroupBuiltInAttributeTypeNames,
            securityGroupCustomAttributeDefinitionIds,
            securityGroupIds,
            securityGroupTags);
    }
}

export class RiskControllerInsertAwsEncryptedResourceKmsEncryptionNotExistsRiskPolicyRequest extends RiskControllerInsertCustomRiskPolicyRequest {
    constructor(
        description: Optional<string>,
        name: string,
        scopeId: string,
        severity: Severity,
        public allEncryptedResources: boolean,
        public anyCustomerManagedKmsKey: boolean,
        public anyKmsKey: boolean,
        public encryptedResourceBuiltInAttributeTypeNames: Optional<string[]>,
        public encryptedResourceCustomAttributeDefinitionIds: Optional<string[]>,
        public encryptedResourceIds: Optional<string[]>,
        public encryptedResourceTags: Optional<ResourceTag[]>,
        public encryptedResourceTypeNames: Optional<string[]>,
        public kmsKeyBuiltInAttributeTypeNames: Optional<string[]>,
        public kmsKeyCustomAttributeDefinitionIds: Optional<string[]>,
        public kmsKeyIds: Optional<string[]>,
        public kmsKeyTags: Optional<ResourceTag[]>) {
        super(
            description,
            name,
            scopeId,
            severity);
    }
}

export class RiskControllerInsertAwsIdentityActivityRiskPolicyRequest extends RiskControllerInsertCustomRiskPolicyRequest {
    constructor(
        description: Optional<string>,
        name: string,
        scopeId: string,
        severity: Severity,
        public identityBuiltInAttributeTypeNames: Optional<string[]>,
        public identityCustomAttributeDefinitionIds: Optional<string[]>,
        public identityIds: Optional<string[]>,
        public identityTags: Optional<ResourceTag[]>) {
        super(
            description,
            name,
            scopeId,
            severity);
    }
}

export class RiskControllerInsertAwsInboundExternalSubnetRiskPolicyRequest extends RiskControllerInsertCustomRiskPolicyRequest {
    constructor(
        description: Optional<string>,
        name: string,
        scopeId: string,
        severity: Severity,
        public allSubnets: boolean,
        public subnetBuiltInAttributeTypeNames: Optional<string[]>,
        public subnetCustomAttributeDefinitionIds: Optional<string[]>,
        public subnetIds: Optional<string[]>,
        public subnetTags: Optional<ResourceTag[]>) {
        super(
            description,
            name,
            scopeId,
            severity);
    }
}

export class RiskControllerInsertAwsInboundExternalVpcRiskPolicyRequest extends RiskControllerInsertCustomRiskPolicyRequest {
    constructor(
        description: Optional<string>,
        name: string,
        scopeId: string,
        severity: Severity,
        public allVpcs: boolean,
        public vpcBuiltInAttributeTypeNames: Optional<string[]>,
        public vpcCustomAttributeDefinitionIds: Optional<string[]>,
        public vpcIds: Optional<string[]>,
        public vpcTags: Optional<ResourceTag[]>) {
        super(
            description,
            name,
            scopeId,
            severity);
    }
}

export class RiskControllerInsertAwsNetworkedResourceInboundExternalPortRiskPolicyRequest extends RiskControllerInsertCustomRiskPolicyRequest {
    constructor(
        description: Optional<string>,
        name: string,
        scopeId: string,
        severity: Severity,
        public anyIpV4AddressSubnet: boolean,
        public destinationNetworkScopes: DestinationNetworkScope[],
        public exactSubnets: Optional<string[]>,
        public inboundAccessScope: Optional<NetworkAccessScope>,
        public overlapSubnets: Optional<string[]>) {
        super(
            description,
            name,
            scopeId,
            severity);
    }
}

export class RiskControllerInsertAwsPrincipalAllowedPermissionRiskPolicyRequest extends RiskControllerInsertCustomRiskPolicyRequest {
    constructor(
        description: Optional<string>,
        name: string,
        scopeId: string,
        severity: Severity,
        public actionNamePatterns: Optional<string[]>,
        public actionRiskCategories: Optional<ActionRiskCategory[]>,
        public actions: Optional<string[]>,
        public principalBuiltInAttributeTypeNames: Optional<string[]>,
        public principalCustomAttributeDefinitionIds: Optional<string[]>,
        public principalIds: Optional<string[]>,
        public principalTags: Optional<ResourceTag[]>) {
        super(
            description,
            name,
            scopeId,
            severity);
    }
}

export class RiskControllerInsertAwsPrincipalResourcePermissionRiskPolicyRequest extends RiskControllerInsertCustomRiskPolicyRequest {
    constructor(
        description: Optional<string>,
        name: string,
        scopeId: string,
        severity: Severity,
        public actionNamePatterns: Optional<string[]>,
        public actionRiskCategories: Optional<ActionRiskCategory[]>,
        public actions: Optional<string[]>,
        public excludePrincipalMatchConditions: EntityMatchCondition[],
        public excludeResourceMatchConditions: EntityMatchCondition[],
        public principalMatchConditions: EntityMatchCondition[],
        public resourceMatchConditions: EntityMatchCondition[]) {
        super(
            description,
            name,
            scopeId,
            severity);
    }
}

export class RiskControllerInsertAwsResourceCodeResourceNotExistsRiskPolicyRequest extends RiskControllerInsertResourceCodeResourceNotExistsRiskPolicyRequest {
    constructor(
        description: Optional<string>,
        name: string,
        scopeId: string,
        severity: Severity,
        allResources: boolean,
        resourceBuiltInAttributeTypeNames: Optional<string[]>,
        resourceCustomAttributeDefinitionIds: Optional<string[]>,
        resourceIds: Optional<string[]>,
        resourceTags: Optional<ResourceTag[]>,
        resourceTypeNames: Optional<string[]>) {
        super(
            description,
            name,
            scopeId,
            severity,
            allResources,
            resourceBuiltInAttributeTypeNames,
            resourceCustomAttributeDefinitionIds,
            resourceIds,
            resourceTags,
            resourceTypeNames);
    }
}

export class RiskControllerInsertAwsResourceTagNotExistsRiskPolicyRequest extends RiskControllerInsertResourceTagNotExistsRiskPolicyRequest {
    constructor(
        description: Optional<string>,
        name: string,
        scopeId: string,
        severity: Severity,
        allResources: boolean,
        existsResourceTagKeys: Optional<string[]>,
        existsResourceTagPattern: Optional<ResourceTagPattern>,
        existsResourceTags: Optional<ResourceTag[]>,
        resourceBuiltInAttributeTypeNames: Optional<string[]>,
        resourceCustomAttributeDefinitionIds: Optional<string[]>,
        resourceIds: Optional<string[]>,
        resourceTags: Optional<ResourceTag[]>,
        resourceTypeNames: Optional<string[]>) {
        super(
            description,
            name,
            scopeId,
            severity,
            allResources,
            existsResourceTagKeys,
            existsResourceTagPattern,
            existsResourceTags,
            resourceBuiltInAttributeTypeNames,
            resourceCustomAttributeDefinitionIds,
            resourceIds,
            resourceTags,
            resourceTypeNames);
    }
}

export class RiskControllerInsertAwsResourceUsageRiskPolicyRequest extends RiskControllerInsertCustomRiskPolicyRequest {
    constructor(
        description: Optional<string>,
        name: string,
        scopeId: string,
        severity: Severity,
        public allResources: Optional<boolean>,
        public resourceBuiltInAttributeTypeNames: Optional<string[]>,
        public resourceCustomAttributeDefinitionIds: Optional<string[]>,
        public resourceIds: Optional<string[]>,
        public resourceNamePattern: Optional<string>,
        public resourceTags: Optional<ResourceTag[]>) {
        super(
            description,
            name,
            scopeId,
            severity);
    }
}

export class RiskControllerInsertAwsRoleTemplateMismatchRiskPolicyRequest extends RiskControllerInsertCustomRiskPolicyRequest {
    constructor(
        description: Optional<string>,
        name: string,
        scopeId: string,
        severity: Severity,
        public roleBuiltInAttributeTypeNames: Optional<string[]>,
        public roleCustomAttributeDefinitionIds: Optional<string[]>,
        public roleIds: Optional<string[]>,
        public roleNamePattern: Optional<string>,
        public roleTags: Optional<ResourceTag[]>,
        public templateRoleId: string,
        public validateAssumeRolePolicy: boolean,
        public validatePermissionBoundaryPolicy: boolean) {
        super(
            description,
            name,
            scopeId,
            severity);
    }
}

export class RiskControllerInsertAwsS3BucketManagementRiskPolicyRequest extends RiskControllerInsertAwsResourceUsageRiskPolicyRequest {
    constructor(
        description: Optional<string>,
        name: string,
        scopeId: string,
        severity: Severity,
        allResources: Optional<boolean>,
        resourceBuiltInAttributeTypeNames: Optional<string[]>,
        resourceCustomAttributeDefinitionIds: Optional<string[]>,
        resourceIds: Optional<string[]>,
        resourceNamePattern: Optional<string>,
        resourceTags: Optional<ResourceTag[]>,
        public sensitiveBucket: boolean) {
        super(
            description,
            name,
            scopeId,
            severity,
            allResources,
            resourceBuiltInAttributeTypeNames,
            resourceCustomAttributeDefinitionIds,
            resourceIds,
            resourceNamePattern,
            resourceTags);
    }
}

export class RiskControllerInsertAwsSecretsManagerSecretManagementRiskPolicyRequest extends RiskControllerInsertAwsResourceUsageRiskPolicyRequest {
    constructor(
        description: Optional<string>,
        name: string,
        scopeId: string,
        severity: Severity,
        allResources: Optional<boolean>,
        resourceBuiltInAttributeTypeNames: Optional<string[]>,
        resourceCustomAttributeDefinitionIds: Optional<string[]>,
        resourceIds: Optional<string[]>,
        resourceNamePattern: Optional<string>,
        resourceTags: Optional<ResourceTag[]>,
        public sensitiveSecret: boolean) {
        super(
            description,
            name,
            scopeId,
            severity,
            allResources,
            resourceBuiltInAttributeTypeNames,
            resourceCustomAttributeDefinitionIds,
            resourceIds,
            resourceNamePattern,
            resourceTags);
    }
}

export class RiskControllerInsertAwsSsoPermissionSetAssignmentRiskPolicyRequest extends RiskControllerInsertAwsResourceUsageRiskPolicyRequest {
    constructor(
        description: Optional<string>,
        name: string,
        scopeId: string,
        severity: Severity,
        allResources: Optional<boolean>,
        resourceBuiltInAttributeTypeNames: Optional<string[]>,
        resourceCustomAttributeDefinitionIds: Optional<string[]>,
        resourceIds: Optional<string[]>,
        resourceNamePattern: Optional<string>,
        resourceTags: Optional<ResourceTag[]>,
        public permissionSetTenantIds: Optional<string[]>) {
        super(
            description,
            name,
            scopeId,
            severity,
            allResources,
            resourceBuiltInAttributeTypeNames,
            resourceCustomAttributeDefinitionIds,
            resourceIds,
            resourceNamePattern,
            resourceTags);
    }
}

export class RiskControllerInsertAwsSsoPermissionSetManagementRiskPolicyRequest extends RiskControllerInsertAwsResourceUsageRiskPolicyRequest {
    constructor(
        description: Optional<string>,
        name: string,
        scopeId: string,
        severity: Severity,
        allResources: Optional<boolean>,
        resourceBuiltInAttributeTypeNames: Optional<string[]>,
        resourceCustomAttributeDefinitionIds: Optional<string[]>,
        resourceIds: Optional<string[]>,
        resourceNamePattern: Optional<string>,
        resourceTags: Optional<ResourceTag[]>,
        public provisioned: Optional<boolean>) {
        super(
            description,
            name,
            scopeId,
            severity,
            allResources,
            resourceBuiltInAttributeTypeNames,
            resourceCustomAttributeDefinitionIds,
            resourceIds,
            resourceNamePattern,
            resourceTags);
    }
}

export class RiskControllerInsertAwsSsoPrincipalCreationRiskPolicyRequest extends RiskControllerInsertAwsResourceUsageRiskPolicyRequest {
    constructor(
        description: Optional<string>,
        name: string,
        scopeId: string,
        severity: Severity,
        allResources: Optional<boolean>,
        resourceBuiltInAttributeTypeNames: Optional<string[]>,
        resourceCustomAttributeDefinitionIds: Optional<string[]>,
        resourceIds: Optional<string[]>,
        resourceNamePattern: Optional<string>,
        resourceTags: Optional<ResourceTag[]>,
        public principalTypeNames: Optional<string[]>) {
        super(
            description,
            name,
            scopeId,
            severity,
            allResources,
            resourceBuiltInAttributeTypeNames,
            resourceCustomAttributeDefinitionIds,
            resourceIds,
            resourceNamePattern,
            resourceTags);
    }
}

export class RiskControllerInsertAzureManagedIdentityNotAllowedResourcePermissionRiskPolicyRequest extends RiskControllerInsertCustomRiskPolicyRequest {
    constructor(
        description: Optional<string>,
        name: string,
        scopeId: string,
        severity: Severity,
        public actionNamePatterns: Optional<string[]>,
        public actionRiskCategories: Optional<ActionRiskCategory[]>,
        public actions: Optional<string[]>,
        public excludeManagedIdentityMatchConditions: EntityMatchCondition[],
        public excludeScopeResourceMatchConditions: EntityMatchCondition[],
        public managedIdentityMatchConditions: EntityMatchCondition[],
        public scopeResourceMatchConditions: EntityMatchCondition[]) {
        super(
            description,
            name,
            scopeId,
            severity);
    }
}

export class RiskControllerInsertAzurePrincipalNotAllowedResourceRoleDefinitionRiskPolicyRequest extends RiskControllerInsertCustomRiskPolicyRequest {
    constructor(
        description: Optional<string>,
        name: string,
        scopeId: string,
        severity: Severity,
        public excludePrincipalMatchConditions: EntityMatchCondition[],
        public excludeScopeResourceMatchConditions: EntityMatchCondition[],
        public principalMatchConditions: EntityMatchCondition[],
        public roleDefinitions: AzurePrincipalNotAllowedResourceRoleDefinitionRiskPolicyConfigurationRoleDefinitions,
        public scopeResourceMatchConditions: EntityMatchCondition[]) {
        super(
            description,
            name,
            scopeId,
            severity);
    }
}

export class RiskControllerInsertAzurePrincipalResourcePermissionRiskPolicyRequest extends RiskControllerInsertCustomRiskPolicyRequest {
    constructor(
        description: Optional<string>,
        name: string,
        scopeId: string,
        severity: Severity,
        public actionNamePatterns: Optional<string[]>,
        public actionRiskCategories: Optional<ActionRiskCategory[]>,
        public actions: Optional<string[]>,
        public principalBuiltInAttributeTypeNames: Optional<string[]>,
        public principalIds: Optional<string[]>,
        public resourceBuiltInAttributeTypeNames: Optional<string[]>,
        public resourceCustomAttributeDefinitionIds: Optional<string[]>,
        public resourceIds: Optional<string[]>,
        public resourceTags: Optional<ResourceTag[]>) {
        super(
            description,
            name,
            scopeId,
            severity);
    }
}

export class RiskControllerInsertAzureResourceCodeResourceNotExistsRiskPolicyRequest extends RiskControllerInsertResourceCodeResourceNotExistsRiskPolicyRequest {
    constructor(
        description: Optional<string>,
        name: string,
        scopeId: string,
        severity: Severity,
        allResources: boolean,
        resourceBuiltInAttributeTypeNames: Optional<string[]>,
        resourceCustomAttributeDefinitionIds: Optional<string[]>,
        resourceIds: Optional<string[]>,
        resourceTags: Optional<ResourceTag[]>,
        resourceTypeNames: Optional<string[]>) {
        super(
            description,
            name,
            scopeId,
            severity,
            allResources,
            resourceBuiltInAttributeTypeNames,
            resourceCustomAttributeDefinitionIds,
            resourceIds,
            resourceTags,
            resourceTypeNames);
    }
}

export class RiskControllerInsertAzureResourceInboundExternalPortRiskPolicyRequest extends RiskControllerInsertCustomRiskPolicyRequest {
    constructor(
        description: Optional<string>,
        name: string,
        scopeId: string,
        severity: Severity,
        public anyIpV4AddressSubnet: boolean,
        public destinationNetworkScopes: DestinationNetworkScope[],
        public exactSubnets: Optional<string[]>,
        public inboundAccessScope: Optional<NetworkAccessScope>,
        public overlapSubnets: Optional<string[]>) {
        super(
            description,
            name,
            scopeId,
            severity);
    }
}

export class RiskControllerInsertAzureResourceTagNotExistsRiskPolicyRequest extends RiskControllerInsertResourceTagNotExistsRiskPolicyRequest {
    constructor(
        description: Optional<string>,
        name: string,
        scopeId: string,
        severity: Severity,
        allResources: boolean,
        existsResourceTagKeys: Optional<string[]>,
        existsResourceTagPattern: Optional<ResourceTagPattern>,
        existsResourceTags: Optional<ResourceTag[]>,
        resourceBuiltInAttributeTypeNames: Optional<string[]>,
        resourceCustomAttributeDefinitionIds: Optional<string[]>,
        resourceIds: Optional<string[]>,
        resourceTags: Optional<ResourceTag[]>,
        resourceTypeNames: Optional<string[]>) {
        super(
            description,
            name,
            scopeId,
            severity,
            allResources,
            existsResourceTagKeys,
            existsResourceTagPattern,
            existsResourceTags,
            resourceBuiltInAttributeTypeNames,
            resourceCustomAttributeDefinitionIds,
            resourceIds,
            resourceTags,
            resourceTypeNames);
    }
}

export class RiskControllerInsertGcpPrincipalAllowedResourcePermissionRiskPolicyRequest extends RiskControllerInsertGcpPrincipalResourcePermissionRiskPolicyRequest {
    constructor(
        description: Optional<string>,
        name: string,
        scopeId: string,
        severity: Severity,
        actionNamePatterns: Optional<string[]>,
        actionRiskCategories: Optional<ActionRiskCategory[]>,
        actions: Optional<string[]>,
        principalBuiltInAttributeTypeNames: Optional<string[]>,
        principalIds: Optional<string[]>,
        resourceBuiltInAttributeTypeNames: Optional<string[]>,
        resourceCustomAttributeDefinitionIds: Optional<string[]>,
        resourceIds: Optional<string[]>,
        resourceTags: Optional<ResourceTag[]>) {
        super(
            description,
            name,
            scopeId,
            severity,
            actionNamePatterns,
            actionRiskCategories,
            actions,
            principalBuiltInAttributeTypeNames,
            principalIds,
            resourceBuiltInAttributeTypeNames,
            resourceCustomAttributeDefinitionIds,
            resourceIds,
            resourceTags);
    }
}

export class RiskControllerInsertGcpPrincipalNotAllowedResourcePermissionRiskPolicyRequest extends RiskControllerInsertGcpPrincipalResourcePermissionRiskPolicyRequest {
    constructor(
        description: Optional<string>,
        name: string,
        scopeId: string,
        severity: Severity,
        actionNamePatterns: Optional<string[]>,
        actionRiskCategories: Optional<ActionRiskCategory[]>,
        actions: Optional<string[]>,
        principalBuiltInAttributeTypeNames: Optional<string[]>,
        principalIds: Optional<string[]>,
        resourceBuiltInAttributeTypeNames: Optional<string[]>,
        resourceCustomAttributeDefinitionIds: Optional<string[]>,
        resourceIds: Optional<string[]>,
        resourceTags: Optional<ResourceTag[]>,
        public allPrincipals: boolean) {
        super(
            description,
            name,
            scopeId,
            severity,
            actionNamePatterns,
            actionRiskCategories,
            actions,
            principalBuiltInAttributeTypeNames,
            principalIds,
            resourceBuiltInAttributeTypeNames,
            resourceCustomAttributeDefinitionIds,
            resourceIds,
            resourceTags);
    }
}

export class RiskControllerInsertGcpResourceCodeResourceNotExistsRiskPolicyRequest extends RiskControllerInsertResourceCodeResourceNotExistsRiskPolicyRequest {
    constructor(
        description: Optional<string>,
        name: string,
        scopeId: string,
        severity: Severity,
        allResources: boolean,
        resourceBuiltInAttributeTypeNames: Optional<string[]>,
        resourceCustomAttributeDefinitionIds: Optional<string[]>,
        resourceIds: Optional<string[]>,
        resourceTags: Optional<ResourceTag[]>,
        resourceTypeNames: Optional<string[]>) {
        super(
            description,
            name,
            scopeId,
            severity,
            allResources,
            resourceBuiltInAttributeTypeNames,
            resourceCustomAttributeDefinitionIds,
            resourceIds,
            resourceTags,
            resourceTypeNames);
    }
}

export class RiskControllerInsertGcpResourceTagNotExistsRiskPolicyRequest extends RiskControllerInsertResourceTagNotExistsRiskPolicyRequest {
    constructor(
        description: Optional<string>,
        name: string,
        scopeId: string,
        severity: Severity,
        allResources: boolean,
        existsResourceTagKeys: Optional<string[]>,
        existsResourceTagPattern: Optional<ResourceTagPattern>,
        existsResourceTags: Optional<ResourceTag[]>,
        resourceBuiltInAttributeTypeNames: Optional<string[]>,
        resourceCustomAttributeDefinitionIds: Optional<string[]>,
        resourceIds: Optional<string[]>,
        resourceTags: Optional<ResourceTag[]>,
        resourceTypeNames: Optional<string[]>) {
        super(
            description,
            name,
            scopeId,
            severity,
            allResources,
            existsResourceTagKeys,
            existsResourceTagPattern,
            existsResourceTags,
            resourceBuiltInAttributeTypeNames,
            resourceCustomAttributeDefinitionIds,
            resourceIds,
            resourceTags,
            resourceTypeNames);
    }
}

export class RiskControllerResolveAwsRiskAutomationRequest extends RiskControllerResolveRiskAutomationRequest {
    constructor(
        change: Change,
        id: string,
        public accessKey: Optional<AwsIamAccessKey>,
        public storedAccessKey: boolean) {
        super(
            change,
            id);
    }
}

export class RiskControllerResolveAzureRiskAutomationRequest extends RiskControllerResolveRiskAutomationRequest {
    constructor(
        change: Change,
        id: string) {
        super(
            change,
            id);
    }
}

export class RiskControllerResolveGcpRiskAutomationRequest extends RiskControllerResolveRiskAutomationRequest {
    constructor(
        change: Change,
        id: string,
        public accessToken: Optional<string>,
        public storedAccessToken: boolean) {
        super(
            change,
            id);
    }
}

export class RiskControllerRiskModelFilters extends Subdocument implements ITypeNameObject {
    constructor(
        typeName: string,
        public categorySelection: Optional<ItemSelection<RiskPolicyCategory>>,
        public complianceIdentifierSelection: Optional<ItemSelection<string>>,
        public ignoreExpirationTimeRangeSelection: Optional<TimeRangeSelection>,
        public openStatusUpdateTimeRangeSelection: Optional<TimeRangeSelection>,
        public riskPolicyIdentifierSelection: Optional<ItemSelection<string>>,
        public severitySelection: Optional<ItemSelection<Severity>>,
        public starred: Optional<boolean>,
        public statusSelection: Optional<ItemSelection<RiskStatus>>,
        public statusUpdateTimeRangeSelection: Optional<TimeRangeSelection>,
        public subStatusSelection: Optional<ItemSelection<RiskSubStatus>>,
        public systemCreationTimeRangeSelection: Optional<TimeRangeSelection>,
        public tenantIdSelection: Optional<ItemSelection<string>>,
        public ticketingServiceTypeSelection: Optional<ItemSelection<TicketingServiceType>>) {
        super(typeName);
    }
}

export class RiskControllerRiskPolicyTypeFilters extends Subdocument implements ITypeNameObject {
    constructor(
        typeName: string,
        public riskPolicyConfigurationTypeName: string) {
        super(typeName);
    }
}

export class RiskControllerUpdateCloudRiskPolicyConfigurationRequest extends RiskControllerUpdateRiskPolicyConfigurationRequest {
    constructor(
        configuration: RiskPolicyConfiguration,
        public entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        public riskPolicyIdToSystemUpdateTimeMap: Dictionary<string>) {
        super(configuration);
    }
}

export class RiskControllerUpdateCodeRiskPolicyConfigurationRequest extends RiskControllerUpdateRiskPolicyConfigurationRequest {
    constructor(
        configuration: RiskPolicyConfiguration,
        public codeExclusions: RiskPolicyConfigurationCodeExclusion[]) {
        super(configuration);
    }
}

export class RiskControllerUpdateKubernetesAdmissionControllerRiskPolicyConfigurationRequest extends RiskControllerUpdateRiskPolicyConfigurationRequest {
    constructor(
        configuration: RiskPolicyConfiguration) {
        super(configuration);
    }
}

export class RiskControllerUpdateRiskCloseStatusRequest extends RiskControllerUpdateRiskStatusRequest {
    constructor(
        message: Optional<string>,
        riskIds: string[]) {
        super(
            message,
            riskIds);
    }
}

export class RiskControllerUpdateRiskIgnoreStatusRequest extends RiskControllerUpdateRiskStatusRequest {
    constructor(
        message: Optional<string>,
        riskIds: string[],
        public ignoreExpirationDate: Optional<string>,
        public ignoreReason: RiskPolicyConfigurationExclusionDataReason) {
        super(
            message,
            riskIds);
    }
}

export class RiskControllerUpdateRiskOpenStatusRequest extends RiskControllerUpdateRiskStatusRequest {
    constructor(
        message: Optional<string>,
        riskIds: string[]) {
        super(
            message,
            riskIds);
    }
}

export class RiskCreationAuditEvent extends RiskAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        riskId: string,
        riskVisible: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "RiskCreationAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            riskId,
            riskVisible);
    }
}

export class RiskFilters extends Summary {
    constructor(
        public attributeValueItems: FilterItems<string>,
        public categoryItems: FilterItems<RiskPolicyCategory>,
        public complianceIdentifierItems: FilterItems<string>,
        public ignoreExpirationDateRange: FilterTimeRange,
        public openStatusUpdateTimeRange: FilterTimeRange,
        public riskPolicyIdentifierItems: FilterItems<string>,
        public statusUpdateTimeRange: FilterTimeRange,
        public subStatuses: RiskSubStatus[],
        public systemCreationTimeRange: FilterTimeRange,
        public tenantIdItems: FilterItems<string>) {
        super();
    }
}

export class RiskItem extends Subdocument implements ITypeNameObject {
    constructor(
        typeName: string,
        public entityExclusionDag: Dag,
        public entityId: string,
        public excluded: boolean) {
        super(typeName);
    }
}

export class RiskJiraIssueAuditEvent extends RiskAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        riskId: string,
        riskVisible: boolean,
        public jiraIssueUrl: Optional<string>,
        public jiraRawError: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            riskId,
            riskVisible);
    }
}

export class RiskJiraIssueCreationAuditEvent extends RiskJiraIssueAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        riskId: string,
        riskVisible: boolean,
        jiraIssueUrl: Optional<string>,
        jiraRawError: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "RiskJiraIssueCreationAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            riskId,
            riskVisible,
            jiraIssueUrl,
            jiraRawError);
    }
}

export class RiskJiraIssueUpdateAuditEvent extends RiskJiraIssueAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        riskId: string,
        riskVisible: boolean,
        jiraIssueUrl: Optional<string>,
        jiraRawError: Optional<string>,
        public priority: Optional<string>,
        public status: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "RiskJiraIssueUpdateAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            riskId,
            riskVisible,
            jiraIssueUrl,
            jiraRawError);
    }
}

export class RiskModel extends TenantDocument implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        public customComplianceIds: string[],
        public entityAttributes: EntityAttribute[],
        public files: RiskFile[],
        public openStatusTimeFrameHours: number,
        public risk: Risk,
        public riskConfiguration: Optional<RiskConfiguration>,
        public riskedEntityEnvironment: Optional<EntityStringProperty>,
        public riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        public riskedEntityTypeName: string,
        public riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId);
    }
}

export class RiskNoteCreationAuditEvent extends RiskAuditEvent implements IDocument, ITypeNameObject, IRiskMessageAuditEvent {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        riskId: string,
        riskVisible: boolean,
        public message: Optional<string>,
        public originalMessage: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "RiskNoteCreationAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            riskId,
            riskVisible);
    }
}

export class RiskNoteDeletionAuditEvent extends RiskAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        riskId: string,
        riskVisible: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "RiskNoteDeletionAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            riskId,
            riskVisible);
    }
}

export class RiskNoteUpdateAuditEvent extends RiskAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        riskId: string,
        riskVisible: boolean,
        public newMessage: string,
        public oldMessage: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "RiskNoteUpdateAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            riskId,
            riskVisible);
    }
}

export class RiskPolicyAuditEvent extends AuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        public customRiskPolicyDescription: Optional<string>,
        public customRiskPolicyName: Optional<string>,
        public riskPolicyConfigurationTypeName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime);
    }
}

export class RiskPolicyConfigurationEntityExclusionEntityPropertyPattern extends Subdocument implements ITypeNameObject {
    constructor(
        typeName: string,
        public data: RiskPolicyConfigurationExclusionData) {
        super(typeName);
    }
}

export class RiskPolicyConfigurationEntityExclusionResourceTagPattern extends RiskPolicyConfigurationEntityExclusionEntityPropertyPattern implements ITypeNameObject {
    constructor(
        data: RiskPolicyConfigurationExclusionData,
        public resourceTagPattern: ResourceTagPattern) {
        super(
            "RiskPolicyConfigurationEntityExclusionResourceTagPattern",
            data);
    }
}

export class RiskPolicyCreationAuditEvent extends RiskPolicyAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        customRiskPolicyDescription: Optional<string>,
        customRiskPolicyName: Optional<string>,
        riskPolicyConfigurationTypeName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "RiskPolicyCreationAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            customRiskPolicyDescription,
            customRiskPolicyName,
            riskPolicyConfigurationTypeName);
    }
}

export class RiskPolicyDeletionAuditEvent extends RiskPolicyAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        customRiskPolicyDescription: Optional<string>,
        customRiskPolicyName: Optional<string>,
        riskPolicyConfigurationTypeName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "RiskPolicyDeletionAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            customRiskPolicyDescription,
            customRiskPolicyName,
            riskPolicyConfigurationTypeName);
    }
}

export class RiskPolicyTypeGroupFilters extends Summary {
    constructor() {
        super();
    }
}

export class RiskPolicyUpdateAuditEvent extends RiskPolicyAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        customRiskPolicyDescription: Optional<string>,
        customRiskPolicyName: Optional<string>,
        riskPolicyConfigurationTypeName: string,
        public changes: RiskPolicyUpdateAuditEventChange[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "RiskPolicyUpdateAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            customRiskPolicyDescription,
            customRiskPolicyName,
            riskPolicyConfigurationTypeName);
    }
}

export class RiskPolicyUpdateAuditEventChange extends Subdocument implements ITypeNameObject {
    constructor(
        typeName: string) {
        super(typeName);
    }
}

export class RiskPolicyUpdateAuditEventCodeEnabledChange extends RiskPolicyUpdateAuditEventChange implements ITypeNameObject {
    constructor(
        public enabled: Optional<boolean>,
        public previousEnabled: Optional<boolean>) {
        super("RiskPolicyUpdateAuditEventCodeEnabledChange");
    }
}

export class RiskPolicyUpdateAuditEventCodeExclusionsChange extends RiskPolicyUpdateAuditEventChange implements ITypeNameObject {
    constructor(
        public addedCodeExclusions: RiskPolicyConfigurationCodeExclusion[],
        public removedCodeExclusions: RiskPolicyConfigurationCodeExclusion[]) {
        super("RiskPolicyUpdateAuditEventCodeExclusionsChange");
    }
}

export class RiskPolicyUpdateAuditEventEnabledChange extends RiskPolicyUpdateAuditEventChange implements ITypeNameObject {
    constructor(
        public enabled: Optional<boolean>,
        public previousEnabled: Optional<boolean>) {
        super("RiskPolicyUpdateAuditEventEnabledChange");
    }
}

export class RiskPolicyUpdateAuditEventEntityCreationTimeAnalysisDelayTimeFrameChange extends RiskPolicyUpdateAuditEventChange implements ITypeNameObject {
    constructor(
        public entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        public previousEntityCreationTimeAnalysisDelayTimeFrame: Optional<string>) {
        super("RiskPolicyUpdateAuditEventEntityCreationTimeAnalysisDelayTimeFrameChange");
    }
}

export class RiskPolicyUpdateAuditEventEntityExclusionsChange extends RiskPolicyUpdateAuditEventChange implements ITypeNameObject {
    constructor(
        public addedEntityTypeNameToEntityPropertyPatternsMap: Dictionary<RiskPolicyConfigurationEntityExclusionEntityPropertyPattern[]>,
        public addedExcludedEntityIds: string[],
        public removedEntityTypeNameToEntityPropertyPatternsMap: Dictionary<RiskPolicyConfigurationEntityExclusionEntityPropertyPattern[]>,
        public removedExcludedEntityIds: string[]) {
        super("RiskPolicyUpdateAuditEventEntityExclusionsChange");
    }
}

export class RiskResolutionAuditEvent extends RiskAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        riskId: string,
        riskVisible: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "RiskResolutionAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            riskId,
            riskVisible);
    }
}

export class RisksAuditEvent extends AuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        public riskIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime);
    }
}

export class RiskSendMailAuditEvent extends RisksAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        riskIds: string[],
        public mails: string[],
        public riskCount: number) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "RiskSendMailAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            riskIds);
    }
}

export class RiskSendWebhookAuditEvent extends RisksAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        riskIds: string[],
        public endpointName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "RiskSendWebhookAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            riskIds);
    }
}

export class RiskServiceNowIncidentAuditEvent extends RiskAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        riskId: string,
        riskVisible: boolean,
        public serviceNowIncidentUrl: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            riskId,
            riskVisible);
    }
}

export class RiskServiceNowIncidentCreationAuditEvent extends RiskServiceNowIncidentAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        riskId: string,
        riskVisible: boolean,
        serviceNowIncidentUrl: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "RiskServiceNowIncidentCreationAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            riskId,
            riskVisible,
            serviceNowIncidentUrl);
    }
}

export class RiskServiceNowIncidentUpdateAuditEvent extends RiskServiceNowIncidentAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        riskId: string,
        riskVisible: boolean,
        serviceNowIncidentUrl: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "RiskServiceNowIncidentUpdateAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            riskId,
            riskVisible,
            serviceNowIncidentUrl);
    }
}

export class RiskSeverityUpdateAuditEvent extends RiskAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        riskId: string,
        riskVisible: boolean,
        public newSeverity: Severity,
        public oldSeverity: Severity) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "RiskSeverityUpdateAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            riskId,
            riskVisible);
    }
}

export class RisksReportDefinitionConfiguration extends ReportDefinitionConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        contentType: ReportContentType,
        exportReport: boolean,
        licenseType: ApplicationCustomerConfigurationLicensingLicenseType,
        name: Optional<string>,
        timeZoneInfoId: string,
        type: ReportType,
        public additionalColumnResourceTagKeys: Optional<string[]>,
        public filtered: boolean,
        public filters: RiskControllerRiskModelFilters,
        public riskPolicyTypeFilters: Optional<RiskControllerRiskPolicyTypeFilters>,
        public sort: Optional<RiskControllerRiskModelSort>,
        public status: RiskStatus) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            contentType,
            exportReport,
            licenseType,
            name,
            timeZoneInfoId,
            type);
    }
}

export class RiskStarredUpdateAuditEvent extends RisksAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        riskIds: string[],
        public riskCount: number,
        public starred: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "RiskStarredUpdateAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            riskIds);
    }
}

export class RiskState extends TenantDocument implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        public integration: RiskStateIntegration) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "RiskState",
            tenantId);
    }
}

export class RiskStatusUpdateAuditEvent extends RiskAuditEvent implements IDocument, ITypeNameObject, IRiskMessageAuditEvent {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        riskId: string,
        riskVisible: boolean,
        public message: Optional<string>,
        public originalMessage: Optional<string>,
        public riskNewIgnoreExpirationDate: Optional<string>,
        public riskNewStatus: RiskStatus,
        public riskNewSubStatus: RiskSubStatus,
        public riskOldIgnoreExpirationDate: Optional<string>,
        public riskOldStatus: RiskStatus,
        public riskOldSubStatus: RiskSubStatus,
        public riskResolutionReason: Optional<RiskResolutionReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "RiskStatusUpdateAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            riskId,
            riskVisible);
    }
}

export class RiskStatusUpdateNoteDeletionAuditEvent extends RiskAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        riskId: string,
        riskVisible: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "RiskStatusUpdateNoteDeletionAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            riskId,
            riskVisible);
    }
}

export class RiskStatusUpdateNoteUpdateAuditEvent extends RiskAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        riskId: string,
        riskVisible: boolean,
        public newMessage: string,
        public oldMessage: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "RiskStatusUpdateNoteUpdateAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            riskId,
            riskVisible);
    }
}

export class ScopeConfigurationAuthorization extends ScopeConfigurationSection<ScopeConfigurationAuthorization> {
    constructor(
        public apiKeyIdToRolesMap: Dictionary<IdentityRole[]>,
        public groupIdToRolesMap: Dictionary<IdentityRole[]>,
        public userIdToRolesMap: Dictionary<IdentityRole[]>) {
        super();
    }
}

export class ScopeConfigurationCode extends ScopeConfigurationSection<ScopeConfigurationCode> {
    constructor(
        public failureRiskPolicyMinSeverity: Optional<Severity>,
        public failureRiskPolicyMinSeverityEnabled: Optional<boolean>) {
        super();
    }
}

export class ScopeConfigurationDataAnalysis extends ScopeConfigurationSection<ScopeConfigurationDataAnalysis> {
    constructor(
        public managedDatabaseScanEnabled: Optional<boolean>,
        public objectStoreScanEnabled: Optional<boolean>,
        public privateObjectStoreScanEnabled: Optional<boolean>,
        public provisionedManagedDatabaseMaxSize: Optional<number>,
        public tenantProvisionedManagedResourceScanMaxCount: Optional<number>,
        public tenantResourceScanMaxCount: Optional<number>) {
        super();
    }
}

export class ScopeSettingAuditEvent extends AuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        public scopeSettingConfigurationTypeName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime);
    }
}

export class ScopeSettingCreationAuditEvent extends ScopeSettingAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        scopeSettingConfigurationTypeName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "ScopeSettingCreationAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            scopeSettingConfigurationTypeName);
    }
}

export class ScopeSettingDeletionAuditEvent extends ScopeSettingAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        scopeSettingConfigurationTypeName: string,
        public data: ScopeSettingDeletionAuditEventData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "ScopeSettingDeletionAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            scopeSettingConfigurationTypeName);
    }
}

export class ScopeSettingDeletionAuditEventData extends Subdocument implements ITypeNameObject {
    constructor(
        typeName: string) {
        super(typeName);
    }
}

export class ScopeSettingUpdateAuditEvent extends ScopeSettingAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        scopeSettingConfigurationTypeName: string,
        public data: ScopeSettingUpdateAuditEventData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "ScopeSettingUpdateAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            scopeSettingConfigurationTypeName);
    }
}

export class ScopeSettingUpdateAuditEventData extends Subdocument implements ITypeNameObject {
    constructor(
        typeName: string) {
        super(typeName);
    }
}

export class ScopeSystemEntityConfiguration extends SystemEntityConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        public scopeId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName);
    }
}

export class ScopeSystemEntityModel extends SystemEntityModel implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        public configuration: ScopeSystemEntityConfiguration,
        public scopeId: string,
        public state: Optional<SystemEntityState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName);
    }
}

export class SearchableData<T> extends SearchableDataBase {
    constructor() {
        super();
    }
}

export class SecretExistsRiskPolicyConfigurationSecretExclusionSecretPattern extends Subdocument implements ITypeNameObject {
    constructor(
        public caseSensitive: boolean,
        public keyPattern: string,
        public message: Optional<string>,
        public valuePattern: string) {
        super("SecretExistsRiskPolicyConfigurationSecretExclusionSecretPattern");
    }
}

export class SecretExistsRiskPolicyUpdateAuditEventSecretExclusionsChange extends RiskPolicyUpdateAuditEventChange implements ITypeNameObject {
    constructor(
        public addedSecretPatterns: SecretExistsRiskPolicyConfigurationSecretExclusionSecretPattern[],
        public removedSecretPatterns: SecretExistsRiskPolicyConfigurationSecretExclusionSecretPattern[]) {
        super("SecretExistsRiskPolicyUpdateAuditEventSecretExclusionsChange");
    }
}

export class SemiStructuredDataClassifierSampleLocation extends DataClassifierSampleLocation implements ITypeNameObject {
    constructor(
        public documentUniqueId: Optional<string>,
        public propertyPath: string) {
        super("SemiStructuredDataClassifierSampleLocation");
    }
}

export class ServiceIdentityExcessivePermissionEvaluationTimeFrameDeletionAuditEventData extends ScopeSettingDeletionAuditEventData implements ITypeNameObject {
    constructor(
        public timeFrame: string) {
        super("ServiceIdentityExcessivePermissionEvaluationTimeFrameDeletionAuditEventData");
    }
}

export class ServiceIdentityExcessivePermissionEvaluationTimeFrameUpdateAuditEventData extends ScopeSettingUpdateAuditEventData implements ITypeNameObject {
    constructor(
        public previousTimeFrame: string,
        public timeFrame: string) {
        super("ServiceIdentityExcessivePermissionEvaluationTimeFrameUpdateAuditEventData");
    }
}

export class ServiceNowDelivery extends IntegrationDelivery implements ITypeNameObject {
    constructor(
        public destinations: ServiceNowDeliveryInstance[],
        message: Optional<string>,
        integrationIds: string[]) {
        super(
            "ServiceNowDelivery",
            destinations,
            message,
            integrationIds);
    }
}

export class ServiceNowDeliveryInstance extends DeliveryIntegration implements ITypeNameObject {
    constructor(
        integrationId: string,
        public incidentCreationData: ServiceNowIncidentCreationData,
        public instanceId: string) {
        super(
            "ServiceNowDeliveryInstance",
            integrationId);
    }
}

export class ServiceNowField extends Subdocument implements ITypeNameObject {
    constructor(
        typeName: string,
        public displayName: string,
        public name: string) {
        super(typeName);
    }
}

export class ServiceNowIncident extends TicketingServiceTicket implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        automationRuleDeliveryDestinationReference: Optional<AutomationRuleDeliveryDestinationReference>,
        multipleRisks: boolean,
        upsertTime: string,
        url: string,
        public attachmentRawIds: string[],
        public group: Optional<ServiceNowGroup>,
        public instanceId: string,
        public rawId: string,
        public user: Optional<ServiceNowUser>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "ServiceNowIncident",
            automationRuleDeliveryDestinationReference,
            multipleRisks,
            upsertTime,
            url);
    }
}

export class ServiceNowInstanceAuditEvent extends AuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        public serviceNowInstanceName: string,
        public serviceNowInstanceUrl: string,
        public serviceNowInstanceUserName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime);
    }
}

export class ServiceNowInstanceCreationAuditEvent extends ServiceNowInstanceAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        serviceNowInstanceName: string,
        serviceNowInstanceUrl: string,
        serviceNowInstanceUserName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "ServiceNowInstanceCreationAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            serviceNowInstanceName,
            serviceNowInstanceUrl,
            serviceNowInstanceUserName);
    }
}

export class ServiceNowInstanceDeletionAuditEvent extends ServiceNowInstanceAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        serviceNowInstanceName: string,
        serviceNowInstanceUrl: string,
        serviceNowInstanceUserName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "ServiceNowInstanceDeletionAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            serviceNowInstanceName,
            serviceNowInstanceUrl,
            serviceNowInstanceUserName);
    }
}

export class ServiceNowInstanceState extends SystemEntityState implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        public descriptionMandatory: boolean,
        public groupMandatory: boolean,
        public issue: Optional<ServiceNowInstanceStateIssue>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "ServiceNowInstanceState");
    }
}

export class ServiceNowInstanceUpdateAuditEvent extends ServiceNowInstanceAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        serviceNowInstanceName: string,
        serviceNowInstanceUrl: string,
        serviceNowInstanceUserName: string,
        public serviceNowInstanceNameChanged: boolean,
        public serviceNowUserNameChanged: boolean,
        public serviceNowUserPasswordChanged: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "ServiceNowInstanceUpdateAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            serviceNowInstanceName,
            serviceNowInstanceUrl,
            serviceNowInstanceUserName);
    }
}

export class ServiceNowSelectionField extends ServiceNowField implements ITypeNameObject {
    constructor(
        displayName: string,
        name: string,
        public optionValueToDisplayNameMap: Dictionary<string>) {
        super(
            "ServiceNowSelectionField",
            displayName,
            name);
    }
}

export class ServiceNowStringField extends ServiceNowField implements ITypeNameObject {
    constructor(
        displayName: string,
        name: string) {
        super(
            "ServiceNowStringField",
            displayName,
            name);
    }
}

export class ServiceTypeMetadata extends EntityTypeMetadata {
    constructor() {
        super();
    }
}

export class SlackDelivery extends IntegrationDelivery implements ITypeNameObject {
    constructor(
        public destinations: SlackDeliveryChannel[],
        message: Optional<string>,
        integrationIds: string[]) {
        super(
            "SlackDelivery",
            destinations,
            message,
            integrationIds);
    }
}

export class SlackDeliveryChannel extends DeliveryIntegration implements ITypeNameObject {
    constructor(
        integrationId: string,
        public slackChannelReference: SlackConversationReference) {
        super(
            "SlackDeliveryChannel",
            integrationId);
    }
}

export class SlackWorkspaceAuditEvent extends AuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        public slackWorkspaceName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime);
    }
}

export class SlackWorkspaceCreationAuditEvent extends SlackWorkspaceAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        slackWorkspaceName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "SlackWorkspaceCreationAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            slackWorkspaceName);
    }
}

export class SlackWorkspaceDeletionAuditEvent extends SlackWorkspaceAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        slackWorkspaceName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "SlackWorkspaceDeletionAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            slackWorkspaceName);
    }
}

export class SlackWorkspaceModel extends ScopeSystemEntityModel implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        public configuration: SlackWorkspaceConfiguration,
        scopeId: string,
        public state: SlackWorkspaceState) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "SlackWorkspaceModel",
            configuration,
            scopeId,
            state);
    }
}

export class SlackWorkspaceState extends SystemEntityState implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        public issue: Optional<SlackWorkspaceStateIssue>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "SlackWorkspaceState");
    }
}

export class SlackWorkspaceUpdateAuditEvent extends SlackWorkspaceAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        slackWorkspaceName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "SlackWorkspaceUpdateAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            slackWorkspaceName);
    }
}

export class SplunkDelivery extends IntegrationDelivery implements ITypeNameObject {
    constructor(
        public destinations: SplunkDeliveryEventCollector[],
        message: Optional<string>,
        integrationIds: string[]) {
        super(
            "SplunkDelivery",
            destinations,
            message,
            integrationIds);
    }
}

export class SplunkDeliveryEventCollector extends DeliveryIntegration implements ITypeNameObject {
    constructor(
        integrationId: string,
        public eventCollectorId: string) {
        super(
            "SplunkDeliveryEventCollector",
            integrationId);
    }
}

export class SplunkEventCollectorAuditEvent extends AuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        public splunkEventCollectorEndpointConnectorName: Optional<string>,
        public splunkEventCollectorName: string,
        public splunkEventCollectorServerCertificateValidationEnabled: boolean,
        public splunkEventCollectorUrl: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime);
    }
}

export class SplunkEventCollectorCreationAuditEvent extends SplunkEventCollectorAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        splunkEventCollectorEndpointConnectorName: Optional<string>,
        splunkEventCollectorName: string,
        splunkEventCollectorServerCertificateValidationEnabled: boolean,
        splunkEventCollectorUrl: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "SplunkEventCollectorCreationAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            splunkEventCollectorEndpointConnectorName,
            splunkEventCollectorName,
            splunkEventCollectorServerCertificateValidationEnabled,
            splunkEventCollectorUrl);
    }
}

export class SplunkEventCollectorDeletionAuditEvent extends SplunkEventCollectorAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        splunkEventCollectorEndpointConnectorName: Optional<string>,
        splunkEventCollectorName: string,
        splunkEventCollectorServerCertificateValidationEnabled: boolean,
        splunkEventCollectorUrl: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "SplunkEventCollectorDeletionAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            splunkEventCollectorEndpointConnectorName,
            splunkEventCollectorName,
            splunkEventCollectorServerCertificateValidationEnabled,
            splunkEventCollectorUrl);
    }
}

export class SplunkEventCollectorState extends SystemEntityState implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        public endpointConnectorName: Optional<string>,
        public issue: Optional<SplunkEventCollectorStateIssue>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "SplunkEventCollectorState");
    }
}

export class SplunkEventCollectorUpdateAuditEvent extends SplunkEventCollectorAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        splunkEventCollectorEndpointConnectorName: Optional<string>,
        splunkEventCollectorName: string,
        splunkEventCollectorServerCertificateValidationEnabled: boolean,
        splunkEventCollectorUrl: Optional<string>,
        public splunkEventCollectorNameChanged: boolean,
        public splunkEventCollectorServerCertificateValidationEnabledChanged: boolean,
        public splunkEventCollectorTokenChanged: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "SplunkEventCollectorUpdateAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            splunkEventCollectorEndpointConnectorName,
            splunkEventCollectorName,
            splunkEventCollectorServerCertificateValidationEnabled,
            splunkEventCollectorUrl);
    }
}

export class SqsDelivery extends IntegrationDelivery implements ITypeNameObject {
    constructor(
        public destinations: SqsDeliveryQueue[],
        message: Optional<string>,
        integrationIds: string[]) {
        super(
            "SqsDelivery",
            destinations,
            message,
            integrationIds);
    }
}

export class SqsDeliveryQueue extends DeliveryIntegration implements ITypeNameObject {
    constructor(
        integrationId: string,
        public queueId: string) {
        super(
            "SqsDeliveryQueue",
            integrationId);
    }
}

export class SqsQueueAuditEvent extends AuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        public queueName: string,
        public queueRoleArn: Optional<string>,
        public queueUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime);
    }
}

export class SqsQueueCreationAuditEvent extends SqsQueueAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        queueName: string,
        queueRoleArn: Optional<string>,
        queueUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "SqsQueueCreationAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            queueName,
            queueRoleArn,
            queueUrl);
    }
}

export class SqsQueueDeletionAuditEvent extends SqsQueueAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        queueName: string,
        queueRoleArn: Optional<string>,
        queueUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "SqsQueueDeletionAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            queueName,
            queueRoleArn,
            queueUrl);
    }
}

export class SqsQueueState extends SystemEntityState implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        public issue: Optional<SqsQueueStateIssue>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "SqsQueueState");
    }
}

export class SqsQueueUpdateAuditEvent extends SqsQueueAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        queueName: string,
        queueRoleArn: Optional<string>,
        queueUrl: string,
        public queueNameChanged: boolean,
        public queueRoleArnChanged: boolean,
        public queueUrlChanged: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "SqsQueueUpdateAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            queueName,
            queueRoleArn,
            queueUrl);
    }
}

export class StringSearchableData extends SearchableData<string> {
    constructor() {
        super();
    }
}

export class StructuredDataClassifierSampleLocation extends DataClassifierSampleLocation implements ITypeNameObject {
    constructor(
        public column: number,
        public columnName: string,
        public rowPrimaryKeyFieldNameToValueMap: Optional<Dictionary<string>>) {
        super("StructuredDataClassifierSampleLocation");
    }
}

export class SubnetSearchableData extends SearchableData<string> {
    constructor() {
        super();
    }
}

export class SystemConfiguration extends SystemEntityConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName);
    }
}

export class SystemKubernetesClusterConfiguration extends ScopeSystemEntityConfiguration implements IDocument, ITypeNameObject, ITunnelSessionScopeSystemEntityConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        public name: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "SystemKubernetesClusterConfiguration",
            scopeId);
    }
}

export class SystemKubernetesClusterModel extends ScopeSystemEntityModel implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        configuration: ScopeSystemEntityConfiguration,
        scopeId: string,
        public state: Optional<SystemKubernetesClusterState>,
        public apiConnectivityStatus: SystemKubernetesClusterApiConnectivityStatus,
        public clusterCreationTime: Optional<string>,
        public clusterReference: EntitySearchableIdReference,
        public clusterTypeName: Optional<string>,
        public helm: KubernetesClusterModelHelm,
        public platform: Optional<KubernetesClusterPlatform>,
        public resourceUpdateTime: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "SystemKubernetesClusterModel",
            configuration,
            scopeId,
            state);
    }
}

export class SystemVirtualMachineConfiguration extends ScopeSystemEntityConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        public creationTime: string,
        public hostName: string,
        public operatingSystemId: string,
        public operatingSystemKernelVersion: string,
        public operatingSystemType: OperatingSystemType,
        public systemKubernetesClusterId: string,
        public trace: Optional<Trace>,
        public virtualMachineId: string,
        public virtualMachineMetadata: Optional<SensorVirtualMachineMetadata>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "SystemVirtualMachineConfiguration",
            scopeId);
    }
}

export class TableDataSegmentMetadata extends DataSegmentMetadata implements ITypeNameObject {
    constructor(
        lastModified: Optional<string>) {
        super(
            "TableDataSegmentMetadata",
            lastModified);
    }
}

export class TeamsChannelReference extends TeamsConversationReference implements ITypeNameObject {
    constructor(
        organizationId: string,
        rawId: string) {
        super(
            "TeamsChannelReference",
            organizationId,
            rawId);
    }
}

export class TeamsConsoleSignInAuditEvent extends AuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "TeamsConsoleSignInAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime);
    }
}

export class TeamsDelivery extends IntegrationDelivery implements ITypeNameObject {
    constructor(
        public destinations: TeamsDeliveryChannel[],
        message: Optional<string>,
        integrationIds: string[]) {
        super(
            "TeamsDelivery",
            destinations,
            message,
            integrationIds);
    }
}

export class TeamsDeliveryChannel extends DeliveryIntegration implements ITypeNameObject {
    constructor(
        integrationId: string,
        public teamsChannelReference: TeamsChannelReference) {
        super(
            "TeamsDeliveryChannel",
            integrationId);
    }
}

export class TeamsOrganizationAuditEvent extends AuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        public teamsOrganizationName: string,
        public teamsOrganizationRawId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime);
    }
}

export class TeamsOrganizationCreationAuditEvent extends TeamsOrganizationAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        teamsOrganizationName: string,
        teamsOrganizationRawId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "TeamsOrganizationCreationAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            teamsOrganizationName,
            teamsOrganizationRawId);
    }
}

export class TeamsOrganizationDeletionAuditEvent extends TeamsOrganizationAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        teamsOrganizationName: string,
        teamsOrganizationRawId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "TeamsOrganizationDeletionAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            teamsOrganizationName,
            teamsOrganizationRawId);
    }
}

export class TenantControllerGetAwsTenantOnboardingInfoResponse extends Response implements ITypeNameObject {
    constructor(
        public chinaAuthenticationUserArn: Optional<string>,
        public cloudFormationTemplateUrl: string,
        public partitionTypes: AwsPartitionType[]) {
        super("TenantControllerGetAwsTenantOnboardingInfoResponse");
    }
}

export class TenantOrchestrationStageState extends OrchestrationStageState implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        data: OrchestrationStageStateData,
        orchestrationType: OrchestrationType,
        stageTypeName: string,
        public tenantIdToDataMap: Dictionary<OrchestrationStageStateData>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "TenantOrchestrationStageState",
            data,
            orchestrationType,
            stageTypeName);
    }
}

export class UdmControllerGetEntityTagPartValuePageRequest extends UdmControllerGetPropertyValuePageRequest {
    constructor(
        limit: number,
        objectTypeName: string,
        propertyId: UdmObjectPropertyId,
        searchText: Optional<string>,
        skip: number,
        public tagKey: Optional<string>) {
        super(
            limit,
            objectTypeName,
            propertyId,
            searchText,
            skip);
    }
}

export class UdmQuery extends UdmQueryBase {
    constructor(
        join: Optional<UdmQueryJoin>,
        options: Optional<UdmQueryOptions>,
        ruleGroup: UdmQueryRuleGroup,
        public objectTypeName: string) {
        super(
            join,
            options,
            ruleGroup);
    }
}

export class UdmQueryRelationRuleGroup extends UdmQueryRuleGroup implements ITypeNameObject {
    constructor(
        typeName: string,
        name: string,
        not: boolean,
        operator: UdmQueryRuleGroupOperator,
        rules: UdmQueryRuleBase[],
        public relationPropertyId: UdmObjectPropertyId) {
        super(
            typeName,
            name,
            not,
            operator,
            rules);
    }
}

export class UdmQueryRule extends UdmQueryRuleBase implements ITypeNameObject {
    constructor(
        typeName: string,
        public operator: UdmQueryRuleOperator,
        public propertyId: UdmObjectPropertyId,
        public values: JArray) {
        super(typeName);
    }
}

export class UnknownEntityBase extends Entity implements ITypeNameObject, IEntity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime);
    }
}

export class UnknownEntityBaseModel extends EntityModel implements ITypeNameObject, IEntityModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown);
    }
}

export class UnknownEntityModel extends UnknownEntityBaseModel implements ITypeNameObject, IEntityModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "UnknownEntityModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown);
    }
}

export class UnknownEntityTypeMetadata extends EntityTypeMetadata {
    constructor() {
        super();
    }
}

export class UnknownPrincipal extends UnknownEntityBase implements ITypeNameObject, IEntity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "UnknownPrincipal",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime);
    }
}

export class UnknownPrincipalModel extends UnknownEntityBaseModel implements ITypeNameObject, IUdmObjectModel, IPrincipalModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        public access: Optional<PrincipalModelAccess>,
        public inactive: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "UnknownPrincipalModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown);
    }
}

export class UnmanagedKubernetesClusterModelFiltersSection<TKubernetesClusterModel extends EntityModel> extends KubernetesClusterModelFiltersSection<TKubernetesClusterModel> implements IUnmanagedKubernetesClusterModelFiltersSection {
    constructor(
        resourceUpdateTimeRange: FilterTimeRange,
        public creationTimeRange: FilterTimeRange) {
        super(resourceUpdateTimeRange);
    }
}

export class UnstructuredDataClassifierSampleLocation extends DataClassifierSampleLocation implements ITypeNameObject {
    constructor(
        public line: Optional<number>) {
        super("UnstructuredDataClassifierSampleLocation");
    }
}

export class UserConsoleSignInAuditEvent extends AuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "UserConsoleSignInAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime);
    }
}

export class UserConsoleSignOutAuditEvent extends AuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "UserConsoleSignOutAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime);
    }
}

export class UserExcessivePermissionEvaluationTimeFrameDeletionAuditEventData extends ScopeSettingDeletionAuditEventData implements ITypeNameObject {
    constructor(
        public timeFrame: string) {
        super("UserExcessivePermissionEvaluationTimeFrameDeletionAuditEventData");
    }
}

export class UserExcessivePermissionEvaluationTimeFrameUpdateAuditEventData extends ScopeSettingUpdateAuditEventData implements ITypeNameObject {
    constructor(
        public previousTimeFrame: string,
        public timeFrame: string) {
        super("UserExcessivePermissionEvaluationTimeFrameUpdateAuditEventData");
    }
}

export class UserRoleAssignmentAuditEvent extends AuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        public userMail: string,
        public userRole: IdentityRole) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime);
    }
}

export class UserRoleAssignmentCreationAuditEvent extends UserRoleAssignmentAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        userMail: string,
        userRole: IdentityRole) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "UserRoleAssignmentCreationAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            userMail,
            userRole);
    }
}

export class UserRoleAssignmentDeletionAuditEvent extends UserRoleAssignmentAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        userMail: string,
        userRole: IdentityRole) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "UserRoleAssignmentDeletionAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            userMail,
            userRole);
    }
}

export class UserRoleAssignmentUpdateAuditEvent extends UserRoleAssignmentAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        userMail: string,
        userRole: IdentityRole) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "UserRoleAssignmentUpdateAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            userMail,
            userRole);
    }
}

export class VersionSearchableData extends SearchableData<string> {
    constructor() {
        super();
    }
}

export class VirtualMachineImageScanFilters extends WorkloadResourceScanFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        scanTimeRange: FilterTimeRange,
        tenantIdItems: FilterItems<string>,
        workloadResourceTypeNameItems: FilterItems<string>,
        public creationTimeRange: FilterTimeRange,
        public regionIds: FilterItems<string>) {
        super(
            attributeValueItems,
            scanTimeRange,
            tenantIdItems,
            workloadResourceTypeNameItems);
    }
}

export class VirtualMachineScanFilters extends WorkloadResourceScanFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        scanTimeRange: FilterTimeRange,
        tenantIdItems: FilterItems<string>,
        workloadResourceTypeNameItems: FilterItems<string>,
        public destinationNetworkScopeDataItems: Optional<FilterItems<DestinationNetworkScopeData>>) {
        super(
            attributeValueItems,
            scanTimeRange,
            tenantIdItems,
            workloadResourceTypeNameItems);
    }
}

export class WebhookDelivery extends IntegrationDelivery implements ITypeNameObject {
    constructor(
        public destinations: WebhookDeliveryEndpoint[],
        message: Optional<string>,
        integrationIds: string[]) {
        super(
            "WebhookDelivery",
            destinations,
            message,
            integrationIds);
    }
}

export class WebhookDeliveryEndpoint extends DeliveryIntegration implements ITypeNameObject {
    constructor(
        integrationId: string,
        public endpointId: string) {
        super(
            "WebhookDeliveryEndpoint",
            integrationId);
    }
}

export class WebhookEndpointAuditEvent extends AuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        public webhookEndpointName: string,
        public webhookEndpointServerCertificateValidationEnabled: boolean,
        public webhookEndpointUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime);
    }
}

export class WebhookEndpointCreationAuditEvent extends WebhookEndpointAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        webhookEndpointName: string,
        webhookEndpointServerCertificateValidationEnabled: boolean,
        webhookEndpointUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "WebhookEndpointCreationAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            webhookEndpointName,
            webhookEndpointServerCertificateValidationEnabled,
            webhookEndpointUrl);
    }
}

export class WebhookEndpointDeletionAuditEvent extends WebhookEndpointAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        webhookEndpointName: string,
        webhookEndpointServerCertificateValidationEnabled: boolean,
        webhookEndpointUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "WebhookEndpointDeletionAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            webhookEndpointName,
            webhookEndpointServerCertificateValidationEnabled,
            webhookEndpointUrl);
    }
}

export class WebhookEndpointUpdateAuditEvent extends WebhookEndpointAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        webhookEndpointName: string,
        webhookEndpointServerCertificateValidationEnabled: boolean,
        webhookEndpointUrl: string,
        public webhookEndpointHttpHeadersChanged: boolean,
        public webhookEndpointNameChanged: boolean,
        public webhookEndpointServerCertificateValidationEnabledChanged: boolean,
        public webhookEndpointUrlChanged: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "WebhookEndpointUpdateAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            webhookEndpointName,
            webhookEndpointServerCertificateValidationEnabled,
            webhookEndpointUrl);
    }
}

export class WorkloadAnalysisAuditEvent extends AuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime);
    }
}

export class WorkloadAnalysisReportDefinitionConfiguration<TWorkloadResourceScanModel extends WorkloadResourceScanModel> extends ReportDefinitionConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        contentType: ReportContentType,
        exportReport: boolean,
        licenseType: ApplicationCustomerConfigurationLicensingLicenseType,
        name: Optional<string>,
        timeZoneInfoId: string,
        type: ReportType,
        public filters: WorkloadControllerWorkloadAnalysisModelsFilters<TWorkloadResourceScanModel>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            contentType,
            exportReport,
            licenseType,
            name,
            timeZoneInfoId,
            type);
    }
}

export class WorkloadAnalysisUpdateAuditEvent extends WorkloadAnalysisAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        public addedTrustedFiles: Optional<ScopeConfigurationWorkloadAnalysisTrustedFile[]>,
        public containerImageRepositoryEnabled: Optional<boolean>,
        public containerImageRepositoryEnabledChanged: boolean,
        public enabled: Optional<boolean>,
        public enabledChanged: boolean,
        public excludeUnresolvableVulnerabilities: Optional<boolean>,
        public excludeUnresolvableVulnerabilitiesChanged: boolean,
        public fileScanEnabled: Optional<boolean>,
        public fileScanEnabledChanged: boolean,
        public operatingSystemEndOfLifeTimeFrame: Optional<string>,
        public operatingSystemEndOfLifeTimeFrameChanged: boolean,
        public removedTrustedFiles: Optional<ScopeConfigurationWorkloadAnalysisTrustedFile[]>,
        public severeVulnerabilityMinSeverity: Optional<Severity>,
        public severeVulnerabilityMinSeverityChanged: boolean,
        public virtualMachineAddedExclusionTags: Optional<ResourceTag[]>,
        public virtualMachineEnabled: Optional<boolean>,
        public virtualMachineEnabledChanged: boolean,
        public virtualMachineImageAddedExclusionTags: Optional<ResourceTag[]>,
        public virtualMachineImageEnabled: Optional<boolean>,
        public virtualMachineImageEnabledChanged: boolean,
        public virtualMachineImageRemovedExclusionTags: Optional<ResourceTag[]>,
        public virtualMachineRemovedExclusionTags: Optional<ResourceTag[]>,
        public vulnerabilityScoreType: VulnerabilityScoreType,
        public vulnerabilityScoreTypeChanged: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "WorkloadAnalysisUpdateAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime);
    }
}

export class WorkloadControllerContainerImageModelFilters extends WorkloadControllerWorkloadResourceModelFilters implements ITypeNameObject {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        exploitable: Optional<boolean>,
        fixable: Optional<boolean>,
        includeResolvedVulnerabilities: boolean,
        operatingSystemDisplayNameSelection: Optional<PagedItemSelection<string>>,
        operatingSystemTypeSelection: Optional<ItemSelection<OperatingSystemType>>,
        packageDisplayNameSelection: Optional<PagedItemSelection<string>>,
        riskSeveritySelection: Optional<ItemSelection<Severity>>,
        scanTimeRangeSelection: Optional<TimeRangeSelection>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        vulnerabilityAttackVectorSelection: Optional<ItemSelection<VulnerabilityAttackVector>>,
        vulnerabilityRawIdSelection: Optional<PagedItemSelection<string>>,
        vulnerabilitySeveritySelection: Optional<ItemSelection<Severity>>,
        workloadResourceIdsSelection: Optional<PagedItemSelection<string>>,
        workloadResourceTypeNameSelection: Optional<ItemSelection<string>>,
        public containerImageCreationTimeRangeSelection: Optional<TimeRangeSelection>,
        public digestSelection: Optional<PagedItemSelection<string>>,
        public kubernetesWorkloadResourceIdSelection: Optional<PagedItemSelection<string>>,
        public repositoryIdSelection: Optional<PagedItemSelection<string>>,
        public tagsSelection: Optional<PagedItemSelection<string>>,
        public usage: Optional<boolean>,
        public virtualMachineIdSelection: Optional<PagedItemSelection<string>>,
        public workloadClusterResourceIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            "WorkloadControllerContainerImageModelFilters",
            attributeValueSelection,
            exploitable,
            fixable,
            includeResolvedVulnerabilities,
            operatingSystemDisplayNameSelection,
            operatingSystemTypeSelection,
            packageDisplayNameSelection,
            riskSeveritySelection,
            scanTimeRangeSelection,
            tenantIdSelection,
            vulnerabilityAttackVectorSelection,
            vulnerabilityRawIdSelection,
            vulnerabilitySeveritySelection,
            workloadResourceIdsSelection,
            workloadResourceTypeNameSelection);
    }
}

export class WorkloadControllerContainerImageModelSort extends WorkloadControllerWorkloadResourceModelSort implements ITypeNameObject {
    constructor(
        direction: SortDirection,
        property: WorkloadControllerRequestProperty) {
        super(
            "WorkloadControllerContainerImageModelSort",
            direction,
            property);
    }
}

export class WorkloadControllerGetWorkloadFileFilterScanItemPageResponse<TItem> extends WorkloadControllerGetWorkloadFileFilterScanItemPageResponseBase {
    constructor(
        public workloadResourceScanFilterItemPage: ElasticsearchFilterItemPage<TItem>) {
        super();
    }
}

export class WorkloadControllerGetWorkloadPackageFilterScanItemPageResponse<TItem> extends WorkloadControllerGetWorkloadPackageFilterScanItemPageResponseBase {
    constructor(
        public workloadResourceScanFilterItemPage: ElasticsearchFilterItemPage<TItem>) {
        super();
    }
}

export class WorkloadControllerGetWorkloadResourceFilterScanItemPageResponse<TItem> extends WorkloadControllerGetWorkloadResourceFilterScanItemPageResponseBase {
    constructor(
        public workloadResourceScanFilterItemPage: ElasticsearchFilterItemPage<TItem>) {
        super();
    }
}

export class WorkloadControllerGetWorkloadResourceModelPageContainerImageRequest extends WorkloadControllerGetWorkloadResourceModelPageRequest {
    constructor(
        exportData: boolean,
        filters: WorkloadControllerWorkloadResourceModelFilters,
        limit: number,
        nextPageSearchCursor: Optional<ElasticsearchIndexSearchCursor>,
        sort: WorkloadControllerWorkloadResourceModelSort) {
        super(
            exportData,
            filters,
            limit,
            nextPageSearchCursor,
            sort);
    }
}

export class WorkloadControllerMaliciousFileModelPropertyItemsResponse<TItem> extends WorkloadControllerMaliciousFileModelPropertyItemsResponseBase {
    constructor(
        public items: TItem[]) {
        super();
    }
}

export class WorkloadControllerMaliciousFileModelsFilters extends WorkloadControllerWorkloadAnalysisModelsFilters<WorkloadResourceScanFileModel> implements ITypeNameObject {
    constructor(
        tenantIdSelection: Optional<ItemSelection<string>>,
        workloadResourceIdSelection: Optional<PagedItemSelection<string>>,
        workloadResourceTypeNameSelection: Optional<ItemSelection<string>>,
        public contentSha256StringSelection: Optional<PagedItemSelection<string>>,
        public firstScanTimeRangeSelection: Optional<TimeRangeSelection>,
        public operatingSystemTypeSelection: Optional<ItemSelection<OperatingSystemType>>,
        public pathSelection: Optional<PagedItemSelection<string>>,
        public typeSelection: Optional<ItemSelection<WorkloadResourceScanFileType>>) {
        super(
            "WorkloadControllerMaliciousFileModelsFilters",
            tenantIdSelection,
            workloadResourceIdSelection,
            workloadResourceTypeNameSelection);
    }
}

export class WorkloadControllerPackageModelPropertyItemsResponse<TItem> extends WorkloadControllerPackageModelPropertyItemsResponseBase {
    constructor(
        public items: TItem[]) {
        super();
    }
}

export class WorkloadControllerPackageModelsFilters extends WorkloadControllerWorkloadAnalysisModelsFilters<WorkloadResourceScanPackageModel> implements ITypeNameObject {
    constructor(
        tenantIdSelection: Optional<ItemSelection<string>>,
        workloadResourceIdSelection: Optional<PagedItemSelection<string>>,
        workloadResourceTypeNameSelection: Optional<ItemSelection<string>>,
        public displayNameSelection: Optional<PagedItemSelection<string>>,
        public filePathSelection: Optional<PagedItemSelection<string>>,
        public fixable: boolean,
        public operatingSystemTypeSelection: Optional<ItemSelection<OperatingSystemType>>,
        public typeSelection: Optional<ItemSelection<WorkloadResourceScanPackageType>>,
        public usage: Optional<boolean>,
        public vulnerabilityRawIdSelection: Optional<PagedItemSelection<string>>,
        public vulnerabilitySeveritySelection: Optional<ItemSelection<Severity>>) {
        super(
            "WorkloadControllerPackageModelsFilters",
            tenantIdSelection,
            workloadResourceIdSelection,
            workloadResourceTypeNameSelection);
    }
}

export class WorkloadControllerPackageVulnerabilityModelPropertyItemsResponse<TItem> extends WorkloadControllerPackageVulnerabilityModelPropertyItemsResponseBase {
    constructor(
        public items: TItem[]) {
        super();
    }
}

export class WorkloadControllerPackageVulnerabilityModelsFilters extends WorkloadControllerWorkloadAnalysisModelsFilters<WorkloadResourceScanPackageModel> implements ITypeNameObject {
    constructor(
        tenantIdSelection: Optional<ItemSelection<string>>,
        workloadResourceIdSelection: Optional<PagedItemSelection<string>>,
        workloadResourceTypeNameSelection: Optional<ItemSelection<string>>,
        public attackVectorSelection: Optional<ItemSelection<VulnerabilityAttackVector>>,
        public cvssSeveritySelection: Optional<ItemSelection<Severity>>,
        public exploitable: Optional<boolean>,
        public firstScanTimeRangeSelection: Optional<TimeRangeSelection>,
        public fixable: boolean,
        public nameSelection: Optional<PagedItemSelection<string>>,
        public operatingSystemTypeSelection: Optional<ItemSelection<OperatingSystemType>>,
        public packageDisplayNameSelection: Optional<PagedItemSelection<string>>,
        public packageFilePathSelection: Optional<PagedItemSelection<string>>,
        public packageTypeSelection: Optional<ItemSelection<WorkloadResourceScanPackageType>>,
        public rawIdSelection: Optional<PagedItemSelection<string>>,
        public resolutionTimeRangeSelection: Optional<TimeRangeSelection>,
        public resolved: boolean,
        public severitySelection: Optional<ItemSelection<Severity>>,
        public usage: Optional<boolean>,
        public vprSeveritySelection: Optional<ItemSelection<Severity>>) {
        super(
            "WorkloadControllerPackageVulnerabilityModelsFilters",
            tenantIdSelection,
            workloadResourceIdSelection,
            workloadResourceTypeNameSelection);
    }
}

export class WorkloadControllerVirtualMachineImageModelFilters extends WorkloadControllerWorkloadResourceModelFilters implements ITypeNameObject {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        exploitable: Optional<boolean>,
        fixable: Optional<boolean>,
        includeResolvedVulnerabilities: boolean,
        operatingSystemDisplayNameSelection: Optional<PagedItemSelection<string>>,
        operatingSystemTypeSelection: Optional<ItemSelection<OperatingSystemType>>,
        packageDisplayNameSelection: Optional<PagedItemSelection<string>>,
        riskSeveritySelection: Optional<ItemSelection<Severity>>,
        scanTimeRangeSelection: Optional<TimeRangeSelection>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        vulnerabilityAttackVectorSelection: Optional<ItemSelection<VulnerabilityAttackVector>>,
        vulnerabilityRawIdSelection: Optional<PagedItemSelection<string>>,
        vulnerabilitySeveritySelection: Optional<ItemSelection<Severity>>,
        workloadResourceIdsSelection: Optional<PagedItemSelection<string>>,
        workloadResourceTypeNameSelection: Optional<ItemSelection<string>>,
        public creationTimeSelection: Optional<TimeRangeSelection>,
        public rawIdsSelection: Optional<PagedItemSelection<string>>,
        public regionIdSelection: Optional<ItemSelection<string>>,
        public resourceTagSelection: Optional<PagedItemSelection<ResourceTag>>,
        public virtualMachineIdSelection: Optional<PagedItemSelection<string>>,
        public virtualMachineImageAccessLevelSelection: Optional<ItemSelection<VirtualMachineImageAccessLevel>>,
        public virtualMachineImageManagementTypeSelection: Optional<ItemSelection<VirtualMachineImageManagementType>>,
        public workloadAnalysisStatusSelection: Optional<ItemSelection<WorkloadAnalysisStatus>>) {
        super(
            "WorkloadControllerVirtualMachineImageModelFilters",
            attributeValueSelection,
            exploitable,
            fixable,
            includeResolvedVulnerabilities,
            operatingSystemDisplayNameSelection,
            operatingSystemTypeSelection,
            packageDisplayNameSelection,
            riskSeveritySelection,
            scanTimeRangeSelection,
            tenantIdSelection,
            vulnerabilityAttackVectorSelection,
            vulnerabilityRawIdSelection,
            vulnerabilitySeveritySelection,
            workloadResourceIdsSelection,
            workloadResourceTypeNameSelection);
    }
}

export class WorkloadControllerVirtualMachineImageModelSort extends WorkloadControllerWorkloadResourceModelSort implements ITypeNameObject {
    constructor(
        direction: SortDirection,
        property: WorkloadControllerRequestProperty) {
        super(
            "WorkloadControllerVirtualMachineImageModelSort",
            direction,
            property);
    }
}

export class WorkloadControllerVirtualMachineModelFilters extends WorkloadControllerWorkloadResourceModelFilters implements ITypeNameObject {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        exploitable: Optional<boolean>,
        fixable: Optional<boolean>,
        includeResolvedVulnerabilities: boolean,
        operatingSystemDisplayNameSelection: Optional<PagedItemSelection<string>>,
        operatingSystemTypeSelection: Optional<ItemSelection<OperatingSystemType>>,
        packageDisplayNameSelection: Optional<PagedItemSelection<string>>,
        riskSeveritySelection: Optional<ItemSelection<Severity>>,
        scanTimeRangeSelection: Optional<TimeRangeSelection>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        vulnerabilityAttackVectorSelection: Optional<ItemSelection<VulnerabilityAttackVector>>,
        vulnerabilityRawIdSelection: Optional<PagedItemSelection<string>>,
        vulnerabilitySeveritySelection: Optional<ItemSelection<Severity>>,
        workloadResourceIdsSelection: Optional<PagedItemSelection<string>>,
        workloadResourceTypeNameSelection: Optional<ItemSelection<string>>,
        public identityPermissionActionSeveritySelection: Optional<ItemSelection<Severity>>,
        public inboundExternalDestinationNetworkScopesSelection: Optional<ItemSelection<string>>,
        public kubernetesClusterSensorSelection: Optional<ItemSelection<boolean>>,
        public networkInboundAccessTypeSelection: Optional<ItemSelection<NetworkInboundAccessType>>,
        public networkInboundExternalAccessScopeSelection: Optional<ItemSelection<NetworkAccessScope>>,
        public resourceTagSelection: Optional<PagedItemSelection<ResourceTag>>,
        public statusSelection: Optional<ItemSelection<WorkloadResourceScanModelVirtualMachineStatus>>,
        public workloadAnalysisStatusSelection: Optional<ItemSelection<WorkloadAnalysisStatus>>) {
        super(
            "WorkloadControllerVirtualMachineModelFilters",
            attributeValueSelection,
            exploitable,
            fixable,
            includeResolvedVulnerabilities,
            operatingSystemDisplayNameSelection,
            operatingSystemTypeSelection,
            packageDisplayNameSelection,
            riskSeveritySelection,
            scanTimeRangeSelection,
            tenantIdSelection,
            vulnerabilityAttackVectorSelection,
            vulnerabilityRawIdSelection,
            vulnerabilitySeveritySelection,
            workloadResourceIdsSelection,
            workloadResourceTypeNameSelection);
    }
}

export class WorkloadControllerVirtualMachineModelSort extends WorkloadControllerWorkloadResourceModelSort implements ITypeNameObject {
    constructor(
        direction: SortDirection,
        property: WorkloadControllerRequestProperty) {
        super(
            "WorkloadControllerVirtualMachineModelSort",
            direction,
            property);
    }
}

export class WorkloadControllerWorkloadResourceModelPropertyItemsResponse<TItem> extends WorkloadControllerWorkloadResourceModelPropertyItemsResponseBase {
    constructor(
        public items: TItem[]) {
        super();
    }
}

export class WorkloadResourceContainerImageModel extends WorkloadResourceModel {
    constructor(
        attributes: EntityModelAttributes,
        displayName: string,
        displayReference: string,
        id: string,
        operatingSystemDisplayName: Optional<string>,
        operatingSystemType: Optional<OperatingSystemType>,
        packageCount: number,
        packages: WorkloadResourceModelPackage[],
        riskSeverityToIdsMap: Dictionary<string[]>,
        scanTime: Optional<string>,
        tenantId: string,
        typeName: string,
        vulnerabilities: VulnerabilityData[],
        vulnerabilityCount: number,
        vulnerabilityResolutionPatches: string[],
        public creationTime: Optional<string>,
        public digest: string,
        public kubernetesWorkloadResourceIds: string[],
        public repositoryIdReference: Optional<string>,
        public tags: string[],
        public virtualMachineIds: string[],
        public workloadClusterResourceIds: string[]) {
        super(
            attributes,
            displayName,
            displayReference,
            id,
            operatingSystemDisplayName,
            operatingSystemType,
            packageCount,
            packages,
            riskSeverityToIdsMap,
            scanTime,
            tenantId,
            typeName,
            vulnerabilities,
            vulnerabilityCount,
            vulnerabilityResolutionPatches);
    }
}

export class WorkloadResourceMaliciousFileRiskMetadata extends WorkloadResourceRiskTypeMetadata {
    constructor() {
        super();
    }
}

export class WorkloadResourceOperatingSystemRiskTypeMetadata extends WorkloadResourceRiskTypeMetadata {
    constructor() {
        super();
    }
}

export class WorkloadResourceOperatingSystemUnpatchedMetadata extends WorkloadResourceOperatingSystemRiskTypeMetadata {
    constructor() {
        super();
    }
}

export class WorkloadResourceScanFileModel extends WorkloadResourceScanModel implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        entityId: string,
        entitySearchableIdReference: EntitySearchableIdReference,
        scopeIds: string[],
        public contentSha256String: string,
        public contentSha256StringSearchableData: StringSearchableData,
        public creationTime: string,
        public firstScanTime: string,
        public operatingSystemType: OperatingSystemType,
        public paths: string[],
        public pathSearchableDatas: StringSearchableData[],
        public sortModelRawId: string,
        public type: WorkloadResourceScanFileType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "WorkloadResourceScanFileModel",
            tenantId,
            entityId,
            entitySearchableIdReference,
            scopeIds);
    }
}

export class WorkloadResourcesReportDefinitionConfigurationBase extends ReportDefinitionConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        contentType: ReportContentType,
        exportReport: boolean,
        licenseType: ApplicationCustomerConfigurationLicensingLicenseType,
        name: Optional<string>,
        timeZoneInfoId: string,
        type: ReportType,
        public filters: WorkloadControllerWorkloadResourceModelFilters) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            contentType,
            exportReport,
            licenseType,
            name,
            timeZoneInfoId,
            type);
    }
}

export class AadDirectoryApplicationCertificateNotRotatedRiskPolicyUpdateAuditEventCertificateRotationTimeFrameChange extends RiskPolicyUpdateAuditEventChange implements ITypeNameObject {
    constructor(
        public certificateRotationTimeFrame: Optional<string>,
        public previousCertificateRotationTimeFrame: Optional<string>) {
        super("AadDirectoryApplicationCertificateNotRotatedRiskPolicyUpdateAuditEventCertificateRotationTimeFrameChange");
    }
}

export class AadDirectoryApplicationSecretNotRotatedRiskPolicyUpdateAuditEventSecretRotationTimeFrameChange extends RiskPolicyUpdateAuditEventChange implements ITypeNameObject {
    constructor(
        public previousSecretRotationTimeFrame: Optional<string>,
        public secretRotationTimeFrame: Optional<string>) {
        super("AadDirectoryApplicationSecretNotRotatedRiskPolicyUpdateAuditEventSecretRotationTimeFrameChange");
    }
}

export class AadDirectoryEntityModelFilters extends EntityModelFilters implements IEntityModelFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        openRelatedEntityRiskTypeIdentifierItems: FilterItems<string>,
        regionIdItems: FilterItems<string>,
        tenantIdItems: FilterItems<string>,
        typeNameItems: FilterItems<string>,
        public creationTimeRange: FilterTimeRange) {
        super(
            attributeValueItems,
            openRelatedEntityRiskTypeIdentifierItems,
            regionIdItems,
            tenantIdItems,
            typeNameItems);
    }
}

export class AadDirectoryEntityTypeMetadata extends EntityTypeMetadata {
    constructor() {
        super();
    }
}

export class AadDirectoryGroupProfile extends AadEntityProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        public identitiesInactive: Optional<AadDirectoryGroupProfileIdentitiesInactive>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AadDirectoryGroupProfile");
    }
}

export class AadDirectoryIdentityInactiveRiskPolicyUpdateAuditEventIncludeDisabledIdentityChange extends RiskPolicyUpdateAuditEventChange implements ITypeNameObject {
    constructor(
        public includeDisabledIdentity: Optional<boolean>,
        public previousIncludeDisabledIdentity: Optional<boolean>) {
        super("AadDirectoryIdentityInactiveRiskPolicyUpdateAuditEventIncludeDisabledIdentityChange");
    }
}

export class AadDirectoryIdentityModelFilters extends AadDirectoryEntityModelFilters implements IEntityModelFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        openRelatedEntityRiskTypeIdentifierItems: FilterItems<string>,
        regionIdItems: FilterItems<string>,
        tenantIdItems: FilterItems<string>,
        typeNameItems: FilterItems<string>,
        creationTimeRange: FilterTimeRange,
        public activityTimeRange: FilterTimeRange,
        public signInTimeRange: FilterTimeRange,
        public vendorItems: FilterItems<Vendor>) {
        super(
            attributeValueItems,
            openRelatedEntityRiskTypeIdentifierItems,
            regionIdItems,
            tenantIdItems,
            typeNameItems,
            creationTimeRange);
    }
}

export class AadDirectoryIdentityProfile extends AadEntityProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        public azure: DirectoryIdentityProfileAzure,
        public signInTime: Optional<string>,
        public signInTimeUpdateTime: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName);
    }
}

export class AadDirectoryPrincipalGroupIdMatchCondition extends EntityMatchCondition implements ITypeNameObject {
    constructor(
        public groupIds: string[],
        public operator: EntityMatchConditionGroupMembershipTypeOperator) {
        super("AadDirectoryPrincipalGroupIdMatchCondition");
    }
}

export class AadDirectoryServicePrincipalProfile extends AadDirectoryIdentityProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        azure: DirectoryIdentityProfileAzure,
        signInTime: Optional<string>,
        signInTimeUpdateTime: Optional<string>,
        public activityTime: Optional<string>,
        public provisioningTime: Optional<string>,
        public provisioningTimeUpdateTime: Optional<string>,
        public userSignInTime: Optional<string>,
        public userSignInTimeUpdateTime: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AadDirectoryServicePrincipalProfile",
            azure,
            signInTime,
            signInTimeUpdateTime);
    }
}

export class AadDirectoryUserInactiveRiskPolicyUpdateAuditEventIncludeInvitationPendingAcceptanceGuestUserChange extends RiskPolicyUpdateAuditEventChange implements ITypeNameObject {
    constructor(
        public includeInvitationPendingAcceptanceGuestUser: Optional<boolean>,
        public previousIncludeInvitationPendingAcceptanceGuestUser: Optional<boolean>) {
        super("AadDirectoryUserInactiveRiskPolicyUpdateAuditEventIncludeInvitationPendingAcceptanceGuestUserChange");
    }
}

export class AadDirectoryUserProfile extends AadDirectoryIdentityProfile implements IDocument, ITypeNameObject, IDirectoryUserProfile {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        azure: DirectoryIdentityProfileAzure,
        signInTime: Optional<string>,
        signInTimeUpdateTime: Optional<string>,
        public activityTime: Optional<string>,
        public aws: DirectoryUserProfileAws) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AadDirectoryUserProfile",
            azure,
            signInTime,
            signInTimeUpdateTime);
    }
}

export class AadEntity extends Entity implements ITypeNameObject, IEntity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        public name: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime);
    }
}

export class AadEntityModel extends EntityModel implements ITypeNameObject, IEntityModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        public partitionType: AadPartitionType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown);
    }
}

export class AadFolderConfiguration extends FolderConfiguration implements ITypeNameObject, IAadFolderConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        managed: boolean,
        name: string,
        parentScopeId: string,
        root: boolean,
        scopeSections: ScopeConfigurationSections,
        tenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AadFolderConfiguration",
            managed,
            name,
            parentScopeId,
            root,
            scopeSections,
            tenantType);
    }
}

export class AadSeverePermissionDirectoryRoleDefinitionsDeletionAuditEventData extends ScopeSettingDeletionAuditEventData implements ITypeNameObject {
    constructor(
        public directoryRoleDefinitionDatas: AadSeverePermissionDirectoryRoleDefinitionData[]) {
        super("AadSeverePermissionDirectoryRoleDefinitionsDeletionAuditEventData");
    }
}

export class AadSeverePermissionDirectoryRoleDefinitionsUpdateAuditEventData extends ScopeSettingUpdateAuditEventData implements ITypeNameObject {
    constructor(
        public addedDirectoryRoleDefinitionDatas: AadSeverePermissionDirectoryRoleDefinitionData[],
        public removedDirectoryRoleDefinitionDatas: AadSeverePermissionDirectoryRoleDefinitionData[]) {
        super("AadSeverePermissionDirectoryRoleDefinitionsUpdateAuditEventData");
    }
}

export class AadTenantAuditEvent extends AuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        public tenantName: string,
        public tenantPrimaryDomainName: string,
        public tenantRawId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime);
    }
}

export class AadTenantConfiguration extends IdentityProviderTenantConfiguration implements ITypeNameObject, IAadScopeConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        active: boolean,
        deletePending: boolean,
        disabledOrchestrationTypes: OrchestrationType[],
        displayReference: string,
        name: string,
        orchestrationTypeToEnabledMap: Dictionary<boolean>,
        parentScopeId: string,
        scopeSections: ScopeConfigurationSections,
        type: TenantType,
        public analysis: AadTenantConfigurationAnalysis,
        public application: Optional<AadTenantConfigurationApplication>,
        public applicationServicePrincipalRawId: string,
        public azureOrganizationId: Optional<string>,
        public events: AadTenantConfigurationEvents,
        public federation: AadTenantConfigurationFederation,
        public partitionType: AadPartitionType,
        public primaryDomainName: string,
        public rawId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AadTenantConfiguration",
            active,
            deletePending,
            disabledOrchestrationTypes,
            displayReference,
            name,
            orchestrationTypeToEnabledMap,
            parentScopeId,
            scopeSections,
            type);
    }
}

export class AadTenantCreationAuditEvent extends AadTenantAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        tenantName: string,
        tenantPrimaryDomainName: string,
        tenantRawId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AadTenantCreationAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            tenantName,
            tenantPrimaryDomainName,
            tenantRawId);
    }
}

export class AadTenantDeletionAuditEvent extends AadTenantAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        tenantName: string,
        tenantPrimaryDomainName: string,
        tenantRawId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AadTenantDeletionAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            tenantName,
            tenantPrimaryDomainName,
            tenantRawId);
    }
}

export class AadTenantState extends IdentityProviderTenantState implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        public monitoring: AadTenantStateMonitoring,
        orchestration: TenantStateOrchestration,
        permissionManagementEnabled: boolean,
        public events: AadTenantStateEvents) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AadTenantState",
            monitoring,
            orchestration,
            permissionManagementEnabled);
    }
}

export class AadTenantUpdateAuditEvent extends AadTenantAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        tenantName: string,
        tenantPrimaryDomainName: string,
        tenantRawId: string,
        public applicationIdChanged: boolean,
        public applicationSecretChanged: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AadTenantUpdateAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            tenantName,
            tenantPrimaryDomainName,
            tenantRawId);
    }
}

export class AccessGraphDestinationEntityGroup extends AccessGraphEntitiesNode {
    constructor(
        id: string,
        entityTypeEntitiesViewName: string,
        entityTypeNames: string[],
        public entityIds: string[]) {
        super(
            id,
            entityTypeEntitiesViewName,
            entityTypeNames);
    }
}

export class AllEntityMatchCondition extends EntityMatchCondition implements ITypeNameObject {
    constructor() {
        super("AllEntityMatchCondition");
    }
}

export class ApiKeyAuditEvent extends AuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        public apiKeyName: string,
        public apiKeyRole: IdentityRole) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime);
    }
}

export class ApiKeyCreationAuditEvent extends ApiKeyAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        apiKeyName: string,
        apiKeyRole: IdentityRole) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "ApiKeyCreationAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            apiKeyName,
            apiKeyRole);
    }
}

export class ApiKeyDeletionAuditEvent extends ApiKeyAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        apiKeyName: string,
        apiKeyRole: IdentityRole) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "ApiKeyDeletionAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            apiKeyName,
            apiKeyRole);
    }
}

export class ApplicationConfiguration extends SystemConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        public aadTenantAuthenticationProvider: Optional<AadTenantAuthenticationProviderConfigurationSection>,
        public amplitude: Optional<AmplitudeConfigurationSection>,
        public apiUrlBuilder: ApiUrlBuilderConfigurationSection,
        public authorization: AuthorizationConfigurationSection,
        public awsTenantAuthenticationProvider: AwsTenantAuthenticationProviderConfigurationSection,
        public azureApplicationAuthenticationProvider: AzureApplicationAuthenticationProviderConfigurationSection,
        public azureDevOpsAuthenticationProvider: Optional<AzureDevOpsAuthenticationProviderConfigurationSection>,
        public biUpdater: BiUpdaterConfigurationSection,
        public consoleAppUrlBuilder: ConsoleAppUrlBuilderConfigurationSection,
        public containerImageRegistryManager: Optional<ContainerImageRegistryManagerConfigurationSection>,
        public deployment: DeploymentConfigurationSection,
        public deploymentDataProvider: DeploymentDataProviderConfigurationSection,
        public elasticsearchClientProvider: ElasticsearchClientProviderConfigurationSection,
        public feature: FeatureConfigurationSection,
        public gcpApplicationAuthenticationProvider: Optional<GcpApplicationAuthenticationProviderConfigurationSection>,
        public gitHubServerConfigurationProvider: Optional<GitHubServerConfigurationProviderConfigurationSection>,
        public infrastructure: InfrastructureConfigurationSection,
        public mailProvider: MailProviderConfigurationSection,
        public migratePostUpdateVersion: string,
        public mongoDb: MongoDbConfigurationSection,
        public ociApplicationAuthenticationProvider: Optional<OciApplicationAuthenticationProviderConfigurationSection>,
        public permissionManagementAadTenantAuthenticationProvider: Optional<PermissionManagementAadTenantAuthenticationProviderConfigurationSection>,
        public scopeSummaryGenerator: ScopeSummaryGeneratorConfigurationSection,
        public singleStoreClientProvider: SingleStoreClientProviderConfigurationSection,
        public slackManager: Optional<SlackManagerConfigurationSection>,
        public snowflakeClientProvider: Optional<SnowflakeClientProviderConfigurationSection>,
        public teamsApplicationAuthenticationProvider: Optional<TeamsApplicationAuthenticationProviderConfigurationSection>,
        public version: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "ApplicationConfiguration");
    }
}

export class AuditEventAutomationRuleCreationAuditEvent extends AutomationRuleAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        description: Optional<string>,
        name: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AuditEventAutomationRuleCreationAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            description,
            name);
    }
}

export class AuditEventAutomationRuleDeletionAuditEvent extends AutomationRuleAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        description: Optional<string>,
        name: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AuditEventAutomationRuleDeletionAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            description,
            name);
    }
}

export class AuditEventAutomationRuleUpdateAuditEvent extends AutomationRuleAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        description: Optional<string>,
        name: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AuditEventAutomationRuleUpdateAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            description,
            name);
    }
}

export class AutomaticCustomEntityAttributeDefinitionTemplateConfiguration extends ScopeSystemEntityConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        public colorIndex: number,
        public displayName: string,
        public name: Optional<string>,
        public resourceTagPatterns: ResourceTagPattern[],
        public type: AutomaticCustomEntityAttributeDefinitionTemplateConfigurationType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AutomaticCustomEntityAttributeDefinitionTemplateConfiguration",
            scopeId);
    }
}

export class AutomationRuleConfiguration extends ScopeSystemEntityConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        public deliveries: DeliveryWrapper[],
        public description: Optional<string>,
        public name: string,
        public scopeIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId);
    }
}

export class AwsAcmCertificateProfile extends AwsResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsAcmCertificateProfile",
            creation);
    }
}

export class AwsAossCollectionProfile extends AwsResourceProfile implements ITypeNameObject, IAwsPolicyResourceProfile {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsAossCollectionProfile",
            creation);
    }
}

export class AwsApiGatewayApiProfile extends AwsResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            creation);
    }
}

export class AwsApiGatewayV1ApiProfile extends AwsApiGatewayApiProfile implements ITypeNameObject, IAwsPolicyResourceProfile {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsApiGatewayV1ApiProfile",
            creation);
    }
}

export class AwsApiGatewayV2ApiProfile extends AwsApiGatewayApiProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsApiGatewayV2ApiProfile",
            creation);
    }
}

export class AwsAthenaWorkgroupProfile extends AwsResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsAthenaWorkgroupProfile",
            creation);
    }
}

export class AwsAutoScalingAutoScalingGroupProfile extends AwsResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsAutoScalingAutoScalingGroupProfile",
            creation);
    }
}

export class AwsAutoScalingLaunchConfigurationProfile extends AwsResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsAutoScalingLaunchConfigurationProfile",
            creation);
    }
}

export class AwsBatchJobDefinitionProfile extends AwsResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsBatchJobDefinitionProfile",
            creation);
    }
}

export class AwsBedrockAgentProfile extends AwsResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsBedrockAgentProfile",
            creation);
    }
}

export class AwsBedrockCustomModelProfile extends AwsResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsBedrockCustomModelProfile",
            creation);
    }
}

export class AwsBehaviorIdentityRiskPolicyGroupFilters extends RiskPolicyTypeGroupFilters {
    constructor(
        public eventActionItems: FilterItems<string>) {
        super();
    }
}

export class AwsCloudFormationStackProfile extends AwsResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudFormationStackProfile",
            creation);
    }
}

export class AwsCloudFrontDistributionProfile extends AwsResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudFrontDistributionProfile",
            creation);
    }
}

export class AwsCloudTrailTrailProfile extends AwsResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudTrailTrailProfile",
            creation);
    }
}

export class AwsDocDbClusterBackupRetentionTimeFrameRiskPolicyUpdateAuditEventBackupRetentionTimeFrameChange extends RiskPolicyUpdateAuditEventChange implements ITypeNameObject {
    constructor(
        public backupRetentionTimeFrame: Optional<string>,
        public previousBackupRetentionTimeFrame: Optional<string>) {
        super("AwsDocDbClusterBackupRetentionTimeFrameRiskPolicyUpdateAuditEventBackupRetentionTimeFrameChange");
    }
}

export class AwsDocDbElasticClusterProfile extends AwsResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsDocDbElasticClusterProfile",
            creation);
    }
}

export class AwsDocDbElasticClusterSnapshotProfile extends AwsResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsDocDbElasticClusterSnapshotProfile",
            creation);
    }
}

export class AwsDynamoDbTableProfile extends AwsResourceProfile implements ITypeNameObject, IAwsPolicyResourceProfile {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsDynamoDbTableProfile",
            creation);
    }
}

export class AwsEc2ElasticIpProfile extends AwsResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2ElasticIpProfile",
            creation);
    }
}

export class AwsEc2ImageProfile extends AwsResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2ImageProfile",
            creation);
    }
}

export class AwsEc2InstanceMetadataServiceVersionRiskPolicyGroupFilters extends RiskPolicyTypeGroupFilters {
    constructor(
        public riskedEntityTypeNameItems: FilterItems<string>) {
        super();
    }
}

export class AwsEc2InstanceProfile extends AwsResourceProfile implements ITypeNameObject, IAwsActivityResourceProfile {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2InstanceProfile",
            creation);
    }
}

export class AwsEc2InstanceUserDataSecretExistsRiskAutoScalingGroupsInfo extends AwsEc2InstanceUserDataSecretExistsRiskInfo implements ITypeNameObject {
    constructor(
        type: AwsEc2InstanceUserDataSecretExistsRiskInfoType,
        public autoScalingGroupIds: string[]) {
        super(
            "AwsEc2InstanceUserDataSecretExistsRiskAutoScalingGroupsInfo",
            type);
    }
}

export class AwsEc2InstanceUserDataSecretExistsRiskGroupTagsInfo extends AwsEc2InstanceUserDataSecretExistsRiskInfo implements ITypeNameObject {
    constructor(
        type: AwsEc2InstanceUserDataSecretExistsRiskInfoType,
        public groupTags: ResourceTag[]) {
        super(
            "AwsEc2InstanceUserDataSecretExistsRiskGroupTagsInfo",
            type);
    }
}

export class AwsEc2InternetGatewayProfile extends AwsResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2InternetGatewayProfile",
            creation);
    }
}

export class AwsEc2LaunchTemplateProfile extends AwsResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2LaunchTemplateProfile",
            creation);
    }
}

export class AwsEc2NatGatewayProfile extends AwsResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2NatGatewayProfile",
            creation);
    }
}

export class AwsEc2NetworkAclProfile extends AwsResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2NetworkAclProfile",
            creation);
    }
}

export class AwsEc2NetworkInterfaceProfile extends AwsResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2NetworkInterfaceProfile",
            creation);
    }
}

export class AwsEc2NetworkResourceInboundRuleSubnetAnyExistsRiskPolicyGroupFilters extends RiskPolicyTypeGroupFilters {
    constructor(
        public unrestrictedDestinationNetworkScopeFilterIdToDestinationNetworkScopeMap: Dictionary<DestinationNetworkScope>) {
        super();
    }
}

export class AwsEc2RouteTableProfile extends AwsResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2RouteTableProfile",
            creation);
    }
}

export class AwsEc2SecurityGroupInboundRuleRiskItem extends RiskItem implements ITypeNameObject {
    constructor(
        entityExclusionDag: Dag,
        entityId: string,
        excluded: boolean,
        public networkScopes: DestinationNetworkScope[],
        public subnets: string[]) {
        super(
            "AwsEc2SecurityGroupInboundRuleRiskItem",
            entityExclusionDag,
            entityId,
            excluded);
    }
}

export class AwsEc2SecurityGroupProfile extends AwsResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>,
        public resourceAttachTime: Optional<string>,
        public usageTime: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2SecurityGroupProfile",
            creation);
    }
}

export class AwsEc2SecurityGroupUnusedRiskPolicyUpdateAuditEventUsageTimeFrameChange extends RiskPolicyUpdateAuditEventChange implements ITypeNameObject {
    constructor(
        public previousUsageTimeFrame: Optional<string>,
        public usageTimeFrame: Optional<string>) {
        super("AwsEc2SecurityGroupUnusedRiskPolicyUpdateAuditEventUsageTimeFrameChange");
    }
}

export class AwsEc2SnapshotEncryptionDisabledRiskAutoScalingLaunchConfigurationData extends AwsEc2SnapshotEncryptionDisabledRiskData implements ITypeNameObject {
    constructor(
        public autoScalingGroupIds: string[],
        public instanceIds: string[],
        public volumeIds: string[]) {
        super("AwsEc2SnapshotEncryptionDisabledRiskAutoScalingLaunchConfigurationData");
    }
}

export class AwsEc2SnapshotProfile extends AwsResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2SnapshotProfile",
            creation);
    }
}

export class AwsEc2SubnetProfile extends AwsResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2SubnetProfile",
            creation);
    }
}

export class AwsEc2VolumeEncryptionDisabledRiskAutoScalingLaunchConfigurationData extends AwsEc2VolumeEncryptionDisabledRiskData implements ITypeNameObject {
    constructor(
        public autoScalingGroupIds: string[],
        public instanceIds: string[]) {
        super("AwsEc2VolumeEncryptionDisabledRiskAutoScalingLaunchConfigurationData");
    }
}

export class AwsEc2VolumeProfile extends AwsResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2VolumeProfile",
            creation);
    }
}

export class AwsEc2VpcBlockPublicAccessPolicyProfile extends AwsResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2VpcBlockPublicAccessPolicyProfile",
            creation);
    }
}

export class AwsEc2VpcEndpointProfile extends AwsResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2VpcEndpointProfile",
            creation);
    }
}

export class AwsEc2VpcPeeringConnectionProfile extends AwsResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2VpcPeeringConnectionProfile",
            creation);
    }
}

export class AwsEc2VpcProfile extends AwsResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2VpcProfile",
            creation);
    }
}

export class AwsEc2VpcStateNetwork extends EntityStateNetwork implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        entityReference: EntityReference,
        public defaultNetworkAclId: Optional<string>,
        public defaultRouteTableId: Optional<string>,
        public internetGatewayId: Optional<string>,
        public vpcBlockPublicAccessPolicyBlockNetworkDirection: AwsEc2VpcBlockPublicAccessPolicyNetworkDirection,
        public vpcBlockPublicAccessPolicyId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2VpcStateNetwork",
            tenantId,
            entityReference);
    }
}

export class AwsEcrRepositoryProfile extends AwsResourceProfile implements ITypeNameObject, IAwsPolicyResourceProfile {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEcrRepositoryProfile",
            creation);
    }
}

export class AwsEcsClusterProfile extends AwsResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEcsClusterProfile",
            creation);
    }
}

export class AwsEcsServiceProfile extends AwsResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEcsServiceProfile",
            creation);
    }
}

export class AwsEcsTaskDefinitionEnvironmentVariableSecretExistsRiskPolicyGroupFilters extends RiskPolicyTypeGroupFilters {
    constructor(
        public taskDefinitionRegionSystemNameItems: FilterItems<string>) {
        super();
    }
}

export class AwsEcsTaskDefinitionProfile extends AwsResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEcsTaskDefinitionProfile",
            creation);
    }
}

export class AwsEcsTaskProfile extends AwsResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEcsTaskProfile",
            creation);
    }
}

export class AwsEksClusterNodeGroupProfile extends AwsResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEksClusterNodeGroupProfile",
            creation);
    }
}

export class AwsEksClusterProfile extends AwsResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEksClusterProfile",
            creation);
    }
}

export class AwsElastiCacheReplicationGroupAutomaticBackupsDisabledRiskPolicyUpdateAuditEventBackupRetentionTimeFrameChange extends RiskPolicyUpdateAuditEventChange implements ITypeNameObject {
    constructor(
        public backupRetentionTimeFrame: Optional<string>,
        public previousBackupRetentionTimeFrame: Optional<string>) {
        super("AwsElastiCacheReplicationGroupAutomaticBackupsDisabledRiskPolicyUpdateAuditEventBackupRetentionTimeFrameChange");
    }
}

export class AwsElastiCacheReplicationGroupProfile extends AwsResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsElastiCacheReplicationGroupProfile",
            creation);
    }
}

export class AwsElastiCacheServerlessCacheProfile extends AwsResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsElastiCacheServerlessCacheProfile",
            creation);
    }
}

export class AwsElasticBeanstalkApplicationProfile extends AwsResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsElasticBeanstalkApplicationProfile",
            creation);
    }
}

export class AwsElasticBeanstalkEnvironmentCloudWatchLoggingDisabledRiskPolicyUpdateAuditEventCloudWatchLogRetentionTimeFrameChange extends RiskPolicyUpdateAuditEventChange implements ITypeNameObject {
    constructor(
        public cloudWatchLogRetentionTimeFrame: Optional<string>,
        public previousCloudWatchLogRetentionTimeFrame: Optional<string>) {
        super("AwsElasticBeanstalkEnvironmentCloudWatchLoggingDisabledRiskPolicyUpdateAuditEventCloudWatchLogRetentionTimeFrameChange");
    }
}

export class AwsElasticBeanstalkEnvironmentProfile extends AwsResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsElasticBeanstalkEnvironmentProfile",
            creation);
    }
}

export class AwsElasticFileSystemFileSystemProfile extends AwsResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsElasticFileSystemFileSystemProfile",
            creation);
    }
}

export class AwsElbApplicationLoadBalancerProfile extends AwsResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsElbApplicationLoadBalancerProfile",
            creation);
    }
}

export class AwsElbClassicLoadBalancerProfile extends AwsResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsElbClassicLoadBalancerProfile",
            creation);
    }
}

export class AwsElbNetworkLoadBalancerProfile extends AwsResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsElbNetworkLoadBalancerProfile",
            creation);
    }
}

export class AwsEmrClusterProfile extends AwsResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEmrClusterProfile",
            creation);
    }
}

export class AwsEntity extends Entity implements ITypeNameObject, IEntity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime);
    }
}

export class AwsEntityModel extends EntityModel implements ITypeNameObject, IEntityModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown);
    }
}

export class AwsEntitySnapshot extends EntitySnapshot implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        changes: EntitySnapshotChange[],
        entityId: string,
        relatedEntityIds: string[],
        relatedEntitySnapshotIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            changes,
            entityId,
            relatedEntityIds,
            relatedEntitySnapshotIds);
    }
}

export class AwsEsDomainProfile extends AwsResourceProfile implements ITypeNameObject, IAwsPolicyResourceProfile {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEsDomainProfile",
            creation);
    }
}

export class AwsEvent extends Event implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        aggregatedEventCount: number,
        correlationId: Optional<string>,
        displayName: Optional<string>,
        errorCode: string,
        errorMessage: Optional<string>,
        ipAddress: Optional<string>,
        name: Optional<string>,
        raw: string,
        riskId: Optional<string>,
        shouldReprocess: boolean,
        shouldReprocessOriginatorEntity: boolean,
        time: string,
        public assumeRole: Optional<AwsEventAssumeRole>,
        public processResult: AwsEventProcessResult,
        public regionSystemName: string,
        public userAgent: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEvent",
            tenantId,
            aggregatedEventCount,
            correlationId,
            displayName,
            errorCode,
            errorMessage,
            ipAddress,
            name,
            raw,
            riskId,
            shouldReprocess,
            shouldReprocessOriginatorEntity,
            time);
    }
}

export class AwsExcessivePermissionGroupRiskPolicyGroupFilters extends RiskPolicyTypeGroupFilters {
    constructor(
        public excessivePermissionServiceIdItems: FilterItems<string>,
        public nonexcessivePermissionServiceIdItems: FilterItems<string>) {
        super();
    }
}

export class AwsExcessivePermissionResourceRiskModelAccessTypeInfo extends AwsExcessivePermissionResourceRiskModelInfo implements ITypeNameObject {
    constructor(
        type: AwsExcessivePermissionResourceRiskModelInfoType,
        public accessType: AwsExcessivePermissionResourceRiskAccessType,
        public highSeverityPermissionActions: string[],
        public identityIds: string[],
        public permissionActions: string[]) {
        super(
            "AwsExcessivePermissionResourceRiskModelAccessTypeInfo",
            type);
    }
}

export class AwsFirehoseDeliveryStreamProfile extends AwsResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsFirehoseDeliveryStreamProfile",
            creation);
    }
}

export class AwsIamIdentityAttachPermissionBoundaryPolicySnapshotChange extends EntitySnapshotChange implements ITypeNameObject {
    constructor(
        entityId: string) {
        super(
            "AwsIamIdentityAttachPermissionBoundaryPolicySnapshotChange",
            entityId);
    }
}

export class AwsIamIdentityDetachPermissionBoundaryPolicySnapshotChange extends EntitySnapshotChange implements ITypeNameObject {
    constructor(
        entityId: string) {
        super(
            "AwsIamIdentityDetachPermissionBoundaryPolicySnapshotChange",
            entityId);
    }
}

export class AwsIamIdentityEditPermissionBoundaryPolicySnapshotChange extends EntitySnapshotChange implements ITypeNameObject {
    constructor(
        entityId: string) {
        super(
            "AwsIamIdentityEditPermissionBoundaryPolicySnapshotChange",
            entityId);
    }
}

export class AwsIamManagedPolicyProfile extends AwsResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamManagedPolicyProfile",
            creation);
    }
}

export class AwsIamPasswordLengthPolicyRiskPolicyUpdateAuditEventPasswordPolicyMinLengthChange extends RiskPolicyUpdateAuditEventChange implements ITypeNameObject {
    constructor(
        public passwordPolicyMinLength: Optional<number>,
        public previousPasswordPolicyMinLength: Optional<number>) {
        super("AwsIamPasswordLengthPolicyRiskPolicyUpdateAuditEventPasswordPolicyMinLengthChange");
    }
}

export class AwsIamPasswordReusePolicyRiskPolicyUpdateAuditEventPasswordPolicyPreventReusePreviousPasswordCountChange extends RiskPolicyUpdateAuditEventChange implements ITypeNameObject {
    constructor(
        public passwordPolicyPreventReusePreviousPasswordCount: Optional<number>,
        public previousPasswordPolicyPreventReusePreviousPasswordCount: Optional<number>) {
        super("AwsIamPasswordReusePolicyRiskPolicyUpdateAuditEventPasswordPolicyPreventReusePreviousPasswordCountChange");
    }
}

export class AwsIamPolicyDocumentStatementConditions extends DocumentSharedFragmentData implements ITypeNameObject, IConsistentHashable {
    constructor(
        public conditions: AwsIamPolicyDocumentStatementCondition[]) {
        super("AwsIamPolicyDocumentStatementConditions");
    }
}

export class AwsIamPrincipalAddInlinePolicySnapshotChange extends EntitySnapshotChange implements ITypeNameObject {
    constructor(
        entityId: string) {
        super(
            "AwsIamPrincipalAddInlinePolicySnapshotChange",
            entityId);
    }
}

export class AwsIamPrincipalAttachManagedPolicySnapshotChange extends EntitySnapshotChange implements ITypeNameObject {
    constructor(
        entityId: string) {
        super(
            "AwsIamPrincipalAttachManagedPolicySnapshotChange",
            entityId);
    }
}

export class AwsIamPrincipalCriticalActionSeverityPermissionMatchCondition extends EntityMatchCondition implements ITypeNameObject {
    constructor() {
        super("AwsIamPrincipalCriticalActionSeverityPermissionMatchCondition");
    }
}

export class AwsIamPrincipalDetachManagedPolicySnapshotChange extends EntitySnapshotChange implements ITypeNameObject {
    constructor(
        entityId: string) {
        super(
            "AwsIamPrincipalDetachManagedPolicySnapshotChange",
            entityId);
    }
}

export class AwsIamPrincipalPolicyEditSnapshotChange extends EntitySnapshotChange implements ITypeNameObject {
    constructor(
        entityId: string) {
        super(
            "AwsIamPrincipalPolicyEditSnapshotChange",
            entityId);
    }
}

export class AwsIamPrincipalPolicySnapshot extends AwsEntitySnapshot implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        changes: EntitySnapshotChange[],
        entityId: string,
        relatedEntityIds: string[],
        relatedEntitySnapshotIds: string[],
        public policyDocument: AwsIamPolicyDocument) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            changes,
            entityId,
            relatedEntityIds,
            relatedEntitySnapshotIds);
    }
}

export class AwsIamPrincipalProfile extends AwsResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            creation);
    }
}

export class AwsIamPrincipalRemoveInlinePolicySnapshotChange extends EntitySnapshotChange implements ITypeNameObject {
    constructor(
        entityId: string) {
        super(
            "AwsIamPrincipalRemoveInlinePolicySnapshotChange",
            entityId);
    }
}

export class AwsIamPrincipalSnapshot extends AwsEntitySnapshot implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        changes: EntitySnapshotChange[],
        entityId: string,
        relatedEntityIds: string[],
        relatedEntitySnapshotIds: string[],
        public policySnapshotIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            changes,
            entityId,
            relatedEntityIds,
            relatedEntitySnapshotIds);
    }
}

export class AwsIamRoleGroupModelAccess extends PrincipalModelAccess implements ITypeNameObject {
    constructor(
        activity: Activity,
        permissions: PrincipalModelAccessPermissions,
        riskCategory: Optional<PrincipalRiskCategory>,
        risks: PrincipalModelAccessRisks,
        scopesRisks: PrincipalModelAccessScopesRisk[],
        types: PrincipalType[]) {
        super(
            "AwsIamRoleGroupModelAccess",
            activity,
            permissions,
            riskCategory,
            risks,
            scopesRisks,
            types);
    }
}

export class AwsIamRoleTemplateMismatchRiskItem extends RiskItem implements ITypeNameObject {
    constructor(
        entityExclusionDag: Dag,
        entityId: string,
        excluded: boolean,
        public assumeRolePolicyRawDocument: Optional<string>,
        public inlinePolicyIds: string[],
        public managedPolicyIds: string[],
        public missingPermissionBoundary: boolean,
        public permissionBoundaryId: Optional<string>) {
        super(
            "AwsIamRoleTemplateMismatchRiskItem",
            entityExclusionDag,
            entityId,
            excluded);
    }
}

export class AwsIamRootUserMfaDisabledRiskPolicyUpdateAuditEventIncludeUnusedRootUserChange extends RiskPolicyUpdateAuditEventChange implements ITypeNameObject {
    constructor(
        public includeUnusedRootUser: Optional<boolean>,
        public previousIncludeUnusedRootUser: Optional<boolean>) {
        super("AwsIamRootUserMfaDisabledRiskPolicyUpdateAuditEventIncludeUnusedRootUserChange");
    }
}

export class AwsIamRootUserProfile extends AwsResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>,
        public accessKeyUsageTime: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamRootUserProfile",
            creation);
    }
}

export class AwsIamUserAccessKey extends AwsIamUserCredentials implements ITypeNameObject {
    constructor(
        creationTime: Optional<string>,
        enabled: boolean,
        usageTime: Optional<string>,
        public uniqueId: string,
        public usageRegionSystemName: Optional<string>,
        public usageServiceIdReference: Optional<string>) {
        super(
            "AwsIamUserAccessKey",
            creationTime,
            enabled,
            usageTime);
    }
}

export class AwsIamUserAddGroupSnapshotChange extends EntitySnapshotChange implements ITypeNameObject {
    constructor(
        entityId: string) {
        super(
            "AwsIamUserAddGroupSnapshotChange",
            entityId);
    }
}

export class AwsIamUserGroupIdMatchCondition extends EntityMatchCondition implements ITypeNameObject {
    constructor(
        public groupIds: string[]) {
        super("AwsIamUserGroupIdMatchCondition");
    }
}

export class AwsIamUserRemoveGroupSnapshotChange extends EntitySnapshotChange implements ITypeNameObject {
    constructor(
        entityId: string) {
        super(
            "AwsIamUserRemoveGroupSnapshotChange",
            entityId);
    }
}

export class AwsIamUserUnrotatedAccessKeyRiskPolicyUpdateAuditEventAccessKeyRotationTimeFrameChange extends RiskPolicyUpdateAuditEventChange implements ITypeNameObject {
    constructor(
        public accessKeyRotationTimeFrame: Optional<string>,
        public previousAccessKeyRotationTimeFrame: Optional<string>) {
        super("AwsIamUserUnrotatedAccessKeyRiskPolicyUpdateAuditEventAccessKeyRotationTimeFrameChange");
    }
}

export class AwsIamUserUnusedAccessKeysEnabledRiskPolicyUpdateAuditEventAccessKeyUsageTimeFrameChange extends RiskPolicyUpdateAuditEventChange implements ITypeNameObject {
    constructor(
        public accessKeyUsageTimeFrame: Optional<string>,
        public previousAccessKeyUsageTimeFrame: Optional<string>) {
        super("AwsIamUserUnusedAccessKeysEnabledRiskPolicyUpdateAuditEventAccessKeyUsageTimeFrameChange");
    }
}

export class AwsIamUserUnusedPasswordEnabledRiskPolicyUpdateAuditEventPasswordUsageTimeFrameChange extends RiskPolicyUpdateAuditEventChange implements ITypeNameObject {
    constructor(
        public passwordUsageTimeFrame: Optional<string>,
        public previousPasswordUsageTimeFrame: Optional<string>) {
        super("AwsIamUserUnusedPasswordEnabledRiskPolicyUpdateAuditEventPasswordUsageTimeFrameChange");
    }
}

export class AwsInboundExternalEc2InstanceRiskAutoScalingGroupsInfo extends AwsInboundExternalEc2InstanceRiskInfo implements ITypeNameObject {
    constructor(
        type: AwsInboundExternalEc2InstanceRiskInfoType,
        public autoScalingGroupIds: string[]) {
        super(
            "AwsInboundExternalEc2InstanceRiskAutoScalingGroupsInfo",
            type);
    }
}

export class AwsInboundExternalEc2InstanceRiskGroupTagsInfo extends AwsInboundExternalEc2InstanceRiskInfo implements ITypeNameObject {
    constructor(
        type: AwsInboundExternalEc2InstanceRiskInfoType,
        public groupTags: ResourceTag[]) {
        super(
            "AwsInboundExternalEc2InstanceRiskGroupTagsInfo",
            type);
    }
}

export class AwsInboundExternalEc2InstanceRiskHighSeverityInstanceRolePermissionsInfo extends AwsInboundExternalEc2InstanceRiskInfo implements ITypeNameObject {
    constructor(
        type: AwsInboundExternalEc2InstanceRiskInfoType,
        public instanceIds: string[],
        public permissionActions: string[]) {
        super(
            "AwsInboundExternalEc2InstanceRiskHighSeverityInstanceRolePermissionsInfo",
            type);
    }
}

export class AwsKinesisStreamProfile extends AwsResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKinesisStreamProfile",
            creation);
    }
}

export class AwsKmsKeyCustomerManagedMatchCondition extends EntityMatchCondition implements ITypeNameObject {
    constructor() {
        super("AwsKmsKeyCustomerManagedMatchCondition");
    }
}

export class AwsKmsKeyProfile extends AwsResourceProfile implements ITypeNameObject, IAwsPolicyResourceProfile {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKmsKeyProfile",
            creation);
    }
}

export class AwsKmsKeyRotationDisabledRiskPolicyUpdateAuditEventKeyRotationTimeFrameChange extends RiskPolicyUpdateAuditEventChange implements ITypeNameObject {
    constructor(
        public keyRotationTimeFrame: Optional<string>,
        public previousKeyRotationTimeFrame: Optional<string>) {
        super("AwsKmsKeyRotationDisabledRiskPolicyUpdateAuditEventKeyRotationTimeFrameChange");
    }
}

export class AwsLambdaFunctionConfigurationProfile extends AwsResourceProfile implements ITypeNameObject, IAwsActivityResourceProfile, IAwsPolicyResourceProfile {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsLambdaFunctionConfigurationProfile",
            creation);
    }
}

export class AwsLambdaFunctionConfigurationPublicAccessExistsRiskPolicyUpdateAuditEventApiGatewayRouteExclusionsChange extends RiskPolicyUpdateAuditEventChange implements ITypeNameObject {
    constructor(
        public addedRoutePatterns: AwsLambdaFunctionConfigurationPublicAccessExistsApiGatewayRouteExclusionRoutePattern[],
        public removedRoutePatterns: AwsLambdaFunctionConfigurationPublicAccessExistsApiGatewayRouteExclusionRoutePattern[]) {
        super("AwsLambdaFunctionConfigurationPublicAccessExistsRiskPolicyUpdateAuditEventApiGatewayRouteExclusionsChange");
    }
}

export class AwsNetworkAccessResourceStateNetwork extends NetworkAccessResourceStateNetwork implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        entityReference: EntityReference,
        inboundAccessType: NetworkInboundAccessType,
        inboundExternal: boolean,
        inboundExternalAccessScope: NetworkAccessScope,
        inboundExternalDestinationNetworkScopes: Optional<DestinationNetworkScope[]>,
        inboundExternalWideRange: Optional<boolean>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            entityReference,
            inboundAccessType,
            inboundExternal,
            inboundExternalAccessScope,
            inboundExternalDestinationNetworkScopes,
            inboundExternalWideRange);
    }
}

export class AwsNetworkedResourceInboundExternalPortRiskItem extends RiskItem implements ITypeNameObject {
    constructor(
        entityExclusionDag: Dag,
        entityId: string,
        excluded: boolean,
        public destinationNetworkScopes: DestinationNetworkScope[],
        public gatewayResourceIds: string[],
        public securityGroupIds: string[],
        public subnets: string[],
        public vpcIds: string[]) {
        super(
            "AwsNetworkedResourceInboundExternalPortRiskItem",
            entityExclusionDag,
            entityId,
            excluded);
    }
}

export class AwsNetworkedResourceStateNetwork extends AwsNetworkAccessResourceStateNetwork implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        entityReference: EntityReference,
        inboundAccessType: NetworkInboundAccessType,
        inboundExternal: boolean,
        inboundExternalAccessScope: NetworkAccessScope,
        inboundExternalDestinationNetworkScopes: DestinationNetworkScope[],
        inboundExternalWideRange: Optional<boolean>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsNetworkedResourceStateNetwork",
            tenantId,
            entityReference,
            inboundAccessType,
            inboundExternal,
            inboundExternalAccessScope,
            inboundExternalDestinationNetworkScopes,
            inboundExternalWideRange);
    }
}

export class AwsOrganizationsPolicyProfile extends AwsResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsOrganizationsPolicyProfile",
            creation);
    }
}

export class AwsOrganizationState extends CloudProviderTenantOrganizationState implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        permissionTypes: CloudProviderTenantPermissionType[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsOrganizationState",
            permissionTypes);
    }
}

export class AwsPartialEntity extends AwsEntity implements ITypeNameObject, IPartialEntity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        public name: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime);
    }
}

export class AwsPartialEntityModel extends AwsEntityModel implements ITypeNameObject, IEntityModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown);
    }
}

export class AwsPartialTenantEntity extends AwsPartialEntity implements ITypeNameObject, IPartialEntity, IAwsTenantEntity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        public rawId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsPartialTenantEntity",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name);
    }
}

export class AwsPartialTenantEntityModel extends AwsPartialEntityModel implements ITypeNameObject, IUdmObjectModel, IAwsTenantEntityModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AwsPartialTenantEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        public parentEntityPath: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsPartialTenantEntityModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown);
    }
}

export class AwsPartialTenantEntityTypeMetadata extends EntityTypeMetadata {
    constructor() {
        super();
    }
}

export class AwsPermissionPresetAuditEvent extends PermissionManagementAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        public description: Optional<string>,
        public existingInlinePolicyDocument: Optional<AwsIamPolicyDocument>,
        public inlinePolicyDocument: Optional<AwsIamPolicyDocument>,
        public name: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime);
    }
}

export class AwsPermissionPresetCreationAuditEvent extends AwsPermissionPresetAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        description: Optional<string>,
        existingInlinePolicyDocument: Optional<AwsIamPolicyDocument>,
        inlinePolicyDocument: Optional<AwsIamPolicyDocument>,
        name: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsPermissionPresetCreationAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            description,
            existingInlinePolicyDocument,
            inlinePolicyDocument,
            name);
    }
}

export class AwsPermissionPresetDeletionAuditEvent extends AwsPermissionPresetAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        description: Optional<string>,
        existingInlinePolicyDocument: Optional<AwsIamPolicyDocument>,
        inlinePolicyDocument: Optional<AwsIamPolicyDocument>,
        name: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsPermissionPresetDeletionAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            description,
            existingInlinePolicyDocument,
            inlinePolicyDocument,
            name);
    }
}

export class AwsPermissionPresetUpdateAuditEvent extends AwsPermissionPresetAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        description: Optional<string>,
        existingInlinePolicyDocument: Optional<AwsIamPolicyDocument>,
        inlinePolicyDocument: Optional<AwsIamPolicyDocument>,
        name: string,
        public descriptionChanged: boolean,
        public inlinePolicyDocumentChanged: boolean,
        public nameChanged: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsPermissionPresetUpdateAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            description,
            existingInlinePolicyDocument,
            inlinePolicyDocument,
            name);
    }
}

export class AwsPolicyResourceAddPolicySnapshotChange extends EntitySnapshotChange implements ITypeNameObject {
    constructor(
        entityId: string) {
        super(
            "AwsPolicyResourceAddPolicySnapshotChange",
            entityId);
    }
}

export class AwsPolicyResourceEditPolicySnapshotChange extends EntitySnapshotChange implements ITypeNameObject {
    constructor(
        entityId: string) {
        super(
            "AwsPolicyResourceEditPolicySnapshotChange",
            entityId);
    }
}

export class AwsPolicyResourceRemovePolicySnapshotChange extends EntitySnapshotChange implements ITypeNameObject {
    constructor(
        entityId: string) {
        super(
            "AwsPolicyResourceRemovePolicySnapshotChange",
            entityId);
    }
}

export class AwsRdsClusterBackupRetentionTimeFrameRiskPolicyUpdateAuditEventBackupRetentionTimeFrameChange extends RiskPolicyUpdateAuditEventChange implements ITypeNameObject {
    constructor(
        public backupRetentionTimeFrame: Optional<string>,
        public previousBackupRetentionTimeFrame: Optional<string>) {
        super("AwsRdsClusterBackupRetentionTimeFrameRiskPolicyUpdateAuditEventBackupRetentionTimeFrameChange");
    }
}

export class AwsRdsClusterResourceProfile extends AwsResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            creation);
    }
}

export class AwsRdsClusterSnapshotProfile extends AwsResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsClusterSnapshotProfile",
            creation);
    }
}

export class AwsRdsDatabaseInstanceBackupRetentionTimeFrameRiskPolicyUpdateAuditEventBackupRetentionTimeFrameChange extends RiskPolicyUpdateAuditEventChange implements ITypeNameObject {
    constructor(
        public backupRetentionTimeFrame: Optional<string>,
        public previousBackupRetentionTimeFrame: Optional<string>) {
        super("AwsRdsDatabaseInstanceBackupRetentionTimeFrameRiskPolicyUpdateAuditEventBackupRetentionTimeFrameChange");
    }
}

export class AwsRdsDatabaseInstanceResourceProfile extends AwsResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            creation);
    }
}

export class AwsRdsDatabaseInstanceSnapshotProfile extends AwsResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsDatabaseInstanceSnapshotProfile",
            creation);
    }
}

export class AwsRdsDocDbClusterProfile extends AwsRdsClusterResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsDocDbClusterProfile",
            creation);
    }
}

export class AwsRdsDocDbClusterSnapshotProfile extends AwsResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsDocDbClusterSnapshotProfile",
            creation);
    }
}

export class AwsRdsDocDbDatabaseInstanceProfile extends AwsRdsDatabaseInstanceResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsDocDbDatabaseInstanceProfile",
            creation);
    }
}

export class AwsRdsGlobalClusterResourceProfile extends AwsResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            creation);
    }
}

export class AwsRdsNeptuneClusterBackupRetentionTimeFrameRiskPolicyUpdateAuditEventBackupRetentionTimeFrameChange extends RiskPolicyUpdateAuditEventChange implements ITypeNameObject {
    constructor(
        public backupRetentionTimeFrame: Optional<string>,
        public previousBackupRetentionTimeFrame: Optional<string>) {
        super("AwsRdsNeptuneClusterBackupRetentionTimeFrameRiskPolicyUpdateAuditEventBackupRetentionTimeFrameChange");
    }
}

export class AwsRdsNeptuneClusterProfile extends AwsRdsClusterResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsNeptuneClusterProfile",
            creation);
    }
}

export class AwsRdsNeptuneClusterSnapshotProfile extends AwsResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsNeptuneClusterSnapshotProfile",
            creation);
    }
}

export class AwsRdsNeptuneDatabaseInstanceProfile extends AwsRdsDatabaseInstanceResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsNeptuneDatabaseInstanceProfile",
            creation);
    }
}

export class AwsRdsNeptuneGlobalClusterProfile extends AwsRdsGlobalClusterResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsNeptuneGlobalClusterProfile",
            creation);
    }
}

export class AwsRedshiftClusterBackupRetentionTimeFrameRiskPolicyUpdateAuditEventBackupRetentionTimeFrameChange extends RiskPolicyUpdateAuditEventChange implements ITypeNameObject {
    constructor(
        public backupRetentionTimeFrame: Optional<string>,
        public previousBackupRetentionTimeFrame: Optional<string>) {
        super("AwsRedshiftClusterBackupRetentionTimeFrameRiskPolicyUpdateAuditEventBackupRetentionTimeFrameChange");
    }
}

export class AwsRedshiftClusterProfile extends AwsResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRedshiftClusterProfile",
            creation);
    }
}

export class AwsRedshiftNamespaceProfile extends AwsResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRedshiftNamespaceProfile",
            creation);
    }
}

export class AwsRedshiftWorkgroupProfile extends AwsResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRedshiftWorkgroupProfile",
            creation);
    }
}

export class AwsResource extends AwsEntity implements ITypeNameObject, IAwsResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        public arn: Optional<string>,
        public crossTenantPermissionActionPossible: boolean,
        public name: Optional<string>,
        public tagExists: boolean,
        public tags: ResourceTag[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime);
    }
}

export class AwsResourceGroup extends AwsResource implements ITypeNameObject, IAwsResource, IEntityGroup {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public definitionId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsResourceModel extends AwsEntityModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        public creationTime: Optional<string>,
        public creatorEntityIdReference: Optional<string>,
        public creatorIdentityIdReference: Optional<string>,
        public creatorOriginatorEntityIdReference: Optional<string>,
        public stackId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown);
    }
}

export class AwsResourceModelFilters extends EntityModelFilters implements IEntityModelFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        openRelatedEntityRiskTypeIdentifierItems: FilterItems<string>,
        regionIdItems: FilterItems<string>,
        tenantIdItems: FilterItems<string>,
        typeNameItems: FilterItems<string>,
        public creationTimeRange: FilterTimeRange) {
        super(
            attributeValueItems,
            openRelatedEntityRiskTypeIdentifierItems,
            regionIdItems,
            tenantIdItems,
            typeNameItems);
    }
}

export class AwsResourceTypeMetadata extends EntityTypeMetadata {
    constructor() {
        super();
    }
}

export class AwsRoute53HostedZoneModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public recordCount: number,
        public recordIdToDnsRecordEntityIdsMap: Dictionary<string[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRoute53HostedZoneModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsRoute53Resource extends AwsResource implements ITypeNameObject, IAwsResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsS3BucketAccessPointModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IAwsPolicyResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsS3BucketAccessPointModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsS3BucketEditAccessControlSnapshotChange extends EntitySnapshotChange implements ITypeNameObject {
    constructor(
        entityId: string) {
        super(
            "AwsS3BucketEditAccessControlSnapshotChange",
            entityId);
    }
}

export class AwsS3BucketEditPublicAccessPolicySnapshotChange extends EntitySnapshotChange implements ITypeNameObject {
    constructor(
        entityId: string) {
        super(
            "AwsS3BucketEditPublicAccessPolicySnapshotChange",
            entityId);
    }
}

export class AwsS3BucketModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IAwsAccessResourceModel, IAwsEncryptedResourceModel, IAwsPolicyResourceModel, IDataAnalysisResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public accessControlListTenantCanonicalIdToIdMap: Dictionary<Optional<string>>,
        public accessLevel: Optional<AwsResourceAccessLevel>,
        public dataAnalysisError: Optional<DataAnalysisResourceModelDataAnalysisError>,
        public dataAnalysisPriorityScanRequested: boolean,
        public dataAnalysisResourceSensitivity: Optional<DataAnalysisSensitivity>,
        public dataAnalysisStatus: DataAnalysisResourceModelDataAnalysisStatus,
        public dataAnalysisStatusSortValue: number,
        public dataCategoryToDataSegmentCountMap: Optional<Dictionary<number>>,
        public dataCategoryToSensitivityMap: Optional<Dictionary<DataSensitivity>>,
        public dataClassifierIdToDataSegmentCountMap: Optional<Dictionary<number>>,
        public dataLastModified: Optional<string>,
        public dataSensitivities: Optional<DataSensitivity[]>,
        public dataSensitivityToDataSegmentCountMap: Optional<Dictionary<number>>,
        public dnsZoneIds: string[],
        public highestDataSensitivity: Optional<DataSensitivity>,
        public kmsEncryptionKeyIdReferences: string[],
        public objectEventSelectorType: Optional<AwsCloudTrailTrailEventSelectorType>,
        public scannedDataSegmentCount: Optional<number>,
        public scanTime: Optional<string>,
        public sensitiveDataSegmentCount: Optional<number>,
        public storageSize: Optional<number>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsS3BucketModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsS3BucketModelFilters extends AwsResourceModelFilters implements IEntityModelFilters, IDataAnalysisResourceModelFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        openRelatedEntityRiskTypeIdentifierItems: FilterItems<string>,
        regionIdItems: FilterItems<string>,
        tenantIdItems: FilterItems<string>,
        typeNameItems: FilterItems<string>,
        creationTimeRange: FilterTimeRange,
        public dataAnalysisResource: DataAnalysisResourceModelFiltersSection) {
        super(
            attributeValueItems,
            openRelatedEntityRiskTypeIdentifierItems,
            regionIdItems,
            tenantIdItems,
            typeNameItems,
            creationTimeRange);
    }
}

export class AwsS3BucketObjectModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsS3BucketObjectModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsS3BucketSnapshot extends AwsEntitySnapshot implements ITypeNameObject, IAwsPolicyResourceSnapshot {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        changes: EntitySnapshotChange[],
        entityId: string,
        relatedEntityIds: string[],
        relatedEntitySnapshotIds: string[],
        public accessControlList: AwsS3AccessControlList,
        public policyDocument: Optional<AwsIamPolicyDocument>,
        public publicAccessPolicy: AwsS3PublicAccessPolicy) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsS3BucketSnapshot",
            tenantId,
            changes,
            entityId,
            relatedEntityIds,
            relatedEntitySnapshotIds);
    }
}

export class AwsS3Resource extends AwsResource implements ITypeNameObject, IAwsResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsSageMakerNotebookInstance extends AwsResource implements ITypeNameObject, IAwsEncryptedResource, IAwsNetworkedResource, IAwsTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public encryptionExists: boolean,
        public kmsEncryptionKeyOrAliasArns: string[],
        public metadataServiceVersion: AwsSageMakerNotebookInstanceMetadataServiceVersion,
        public networkInterfaceId: Optional<string>,
        public operatingSystemName: Optional<string>,
        public rawType: string,
        public roleReference: Optional<AwsResourceReference>,
        public rootAccess: boolean,
        public securityGroupIds: string[],
        public status: AwsSageMakerNotebookInstanceStatus,
        public subnetIds: string[],
        public unrestrictedOutboundAccess: boolean,
        public updateTime: string,
        public volumeSize: number,
        public vpcIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSageMakerNotebookInstance",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsSageMakerNotebookInstanceModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IAwsEncryptedResourceModel, IAwsNetworkedResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public kmsEncryptionKeyIdReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSageMakerNotebookInstanceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsSecretsManagerSecret extends AwsResource implements ITypeNameObject, IAwsAccessResource, IAwsEncryptedResource, IAwsPolicyResource, IAwsTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public accessLevel: Optional<AwsResourceAccessLevel>,
        public description: Optional<string>,
        public encryptionExists: boolean,
        public kmsEncryptionKeyOrAliasArns: string[],
        public policyDocument: Optional<AwsIamPolicyDocument>,
        public policyDocumentExists: boolean,
        public publicAccessHighSeveritySensitivePermissionActions: string[],
        public publicAccessPermissionActions: string[],
        public rotationEnabled: boolean,
        public rotationFunctionConfigurationArn: Optional<string>,
        public rotationTime: Optional<string>,
        public rotationTimeFrame: Optional<string>,
        public updateTime: string,
        public usageTime: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSecretsManagerSecret",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsSecretsManagerSecretModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IAwsAccessResourceModel, IAwsEncryptedResourceModel, IAwsPolicyResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public accessLevel: Optional<AwsResourceAccessLevel>,
        public kmsEncryptionKeyIdReferences: string[],
        public rotationFunctionConfigurationId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSecretsManagerSecretModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsSecretsManagerSecretModelFilters extends AwsResourceModelFilters implements IEntityModelFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        openRelatedEntityRiskTypeIdentifierItems: FilterItems<string>,
        regionIdItems: FilterItems<string>,
        tenantIdItems: FilterItems<string>,
        typeNameItems: FilterItems<string>,
        creationTimeRange: FilterTimeRange,
        public usageTimeRange: FilterTimeRange) {
        super(
            attributeValueItems,
            openRelatedEntityRiskTypeIdentifierItems,
            regionIdItems,
            tenantIdItems,
            typeNameItems,
            creationTimeRange);
    }
}

export class AwsSecretsManagerSecretRotationDisabledRiskPolicyGroupFilters extends RiskPolicyTypeGroupFilters {
    constructor(
        public creationTimeRange: FilterTimeRange,
        public rotationTimeRange: FilterTimeRange,
        public usageTimeRange: FilterTimeRange) {
        super();
    }
}

export class AwsSecretsManagerSecretSnapshot extends AwsEntitySnapshot implements ITypeNameObject, IAwsPolicyResourceSnapshot {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        changes: EntitySnapshotChange[],
        entityId: string,
        relatedEntityIds: string[],
        relatedEntitySnapshotIds: string[],
        public policyDocument: Optional<AwsIamPolicyDocument>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSecretsManagerSecretSnapshot",
            tenantId,
            changes,
            entityId,
            relatedEntityIds,
            relatedEntitySnapshotIds);
    }
}

export class AwsSecurityHubHub extends AwsResource implements ITypeNameObject, IAwsResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSecurityHubHub",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsSecurityHubHubModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSecurityHubHubModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsService extends AwsEntity implements ITypeNameObject, IService {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        public shortName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsService",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime);
    }
}

export class AwsServiceModel extends AwsEntityModel implements ITypeNameObject, IEntityModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsServiceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown);
    }
}

export class AwsSesIdentityModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSesIdentityModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsSesResource extends AwsResource implements ITypeNameObject, IAwsResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsSnsTopic extends AwsResource implements ITypeNameObject, IAwsAccessResource, IAwsEncryptedResource, IAwsPolicyResource, IAwsTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public accessLevel: Optional<AwsResourceAccessLevel>,
        public encryptionExists: boolean,
        public failedDeliveryRoleReferences: AwsResourceReference[],
        public kmsEncryptionKeyOrAliasArns: string[],
        public policyDocument: AwsIamPolicyDocument,
        public policyDocumentExists: boolean,
        public publicAccessHighSeveritySensitivePermissionActions: string[],
        public publicAccessNonKmsEncryptedDataPermissionActions: string[],
        public publicAccessPermissionActions: string[],
        public subscriptionCount: number,
        public subscriptions: AwsSnsTopicSubscription[],
        public successDeliveryRoleReferences: AwsResourceReference[],
        public type: AwsSnsTopicType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSnsTopic",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsSnsTopicModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IAwsAccessResourceModel, IAwsEncryptedResourceModel, IAwsPolicyResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public accessLevel: Optional<AwsResourceAccessLevel>,
        public kmsEncryptionKeyIdReferences: string[],
        public subscriptionArnToEndpointEntityIdMap: Dictionary<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSnsTopicModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsSqsQueue extends AwsResource implements ITypeNameObject, IAwsAccessResource, IAwsDnsRecordResource, IAwsEncryptedResource, IAwsPolicyResource, IAwsTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public accessLevel: Optional<AwsResourceAccessLevel>,
        public dnsNames: string[],
        public encryptionExists: boolean,
        public kmsEncryptionKeyOrAliasArns: string[],
        public policyDocument: Optional<AwsIamPolicyDocument>,
        public policyDocumentExists: boolean,
        public publicAccessHighSeveritySensitivePermissionActions: string[],
        public publicAccessPermissionActions: string[],
        public publicIpAddresses: Optional<string[]>,
        public serverSideEncryptionType: Optional<AwsSqsQueueServerSideEncryptionType>,
        public serverSideKmsEncryptionKeyOrAliasArn: Optional<string>,
        public type: AwsSqsQueueType,
        public url: string,
        public urlEncodedUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSqsQueue",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsSqsQueueModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IAwsAccessResourceModel, IAwsEncryptedResourceModel, IAwsPolicyResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public accessLevel: Optional<AwsResourceAccessLevel>,
        public dnsZoneIds: string[],
        public kmsEncryptionKeyIdReferences: string[],
        public subscriptionTopicIds: string[],
        public triggerFunctionConfigurationIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSqsQueueModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsSsmParameterModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAwsEncryptedResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public kmsEncryptionKeyIdReferences: string[],
        public updateIdentitySearchableIdReference: EntitySearchableIdReference) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSsmParameterModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsSsmParameterModelFilters extends AwsResourceModelFilters implements IEntityModelFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        openRelatedEntityRiskTypeIdentifierItems: FilterItems<string>,
        regionIdItems: FilterItems<string>,
        tenantIdItems: FilterItems<string>,
        typeNameItems: FilterItems<string>,
        creationTimeRange: FilterTimeRange,
        public updateTimeRange: FilterTimeRange) {
        super(
            attributeValueItems,
            openRelatedEntityRiskTypeIdentifierItems,
            regionIdItems,
            tenantIdItems,
            typeNameItems,
            creationTimeRange);
    }
}

export class AwsSsmResource extends AwsResource implements ITypeNameObject, IAwsResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsSsoInstanceModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSsoInstanceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsSsoPermissionSetAssignmentEligibilityState extends PermissionEligibilityState implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        public permissionSetArnToPermissionSetMap: Dictionary<AwsSsoPermissionSetAssignmentEligibilityStatePermissionSet>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSsoPermissionSetAssignmentEligibilityState");
    }
}

export class AwsSsoPermissionSetModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IPrincipalModel, IRelatedTenantsEntityModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public access: Optional<PrincipalModelAccess>,
        public analyzingReason: Optional<EntityAnalyzingReason>,
        public inactive: boolean,
        public principalIdToAccountIdsMap: Dictionary<string[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSsoPermissionSetModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsSsoPermissionSetModelAccess extends PrincipalModelAccess implements ITypeNameObject {
    constructor(
        activity: Activity,
        permissions: PrincipalModelAccessPermissions,
        riskCategory: Optional<PrincipalRiskCategory>,
        risks: PrincipalModelAccessRisks,
        scopesRisks: PrincipalModelAccessScopesRisk[],
        types: PrincipalType[]) {
        super(
            "AwsSsoPermissionSetModelAccess",
            activity,
            permissions,
            riskCategory,
            risks,
            scopesRisks,
            types);
    }
}

export class AwsSsoPermissionSetProvisionedMatchCondition extends EntityMatchCondition implements ITypeNameObject {
    constructor(
        public provisioned: boolean) {
        super("AwsSsoPermissionSetProvisionedMatchCondition");
    }
}

export class AwsSsoPrincipalModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public assignedPrincipalIdToPermissionSetIdToTenantRawIdToRoleSearchableReferenceMap: Dictionary<Dictionary<Dictionary<Optional<EntitySearchableReference>>>>,
        public federatedIdentityReference: Optional<EntityReference>,
        public roleIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsSsoPrincipalModelFilters extends AwsResourceModelFilters implements IEntityModelFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        openRelatedEntityRiskTypeIdentifierItems: FilterItems<string>,
        regionIdItems: FilterItems<string>,
        tenantIdItems: FilterItems<string>,
        typeNameItems: FilterItems<string>,
        creationTimeRange: FilterTimeRange,
        public roleTenantIdItems: FilterItems<string>) {
        super(
            attributeValueItems,
            openRelatedEntityRiskTypeIdentifierItems,
            regionIdItems,
            tenantIdItems,
            typeNameItems,
            creationTimeRange);
    }
}

export class AwsSsoResource extends AwsResource implements ITypeNameObject, IAwsResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsSsoUserModel extends AwsSsoPrincipalModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAwsFederationUserModel, IPermissionManagementUserModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        assignedPrincipalIdToPermissionSetIdToTenantRawIdToRoleSearchableReferenceMap: Dictionary<Dictionary<Dictionary<Optional<EntitySearchableReference>>>>,
        federatedIdentityReference: Optional<EntityReference>,
        roleIds: string[],
        public awsRoleIds: string[],
        public groupIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSsoUserModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId,
            assignedPrincipalIdToPermissionSetIdToTenantRawIdToRoleSearchableReferenceMap,
            federatedIdentityReference,
            roleIds);
    }
}

export class AwsTagResourceGroup extends AwsResourceGroup implements ITypeNameObject, IAwsResource, IEntityGroup {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        definitionId: Optional<string>,
        public groupTags: ResourceTag[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            definitionId);
    }
}

export class AwsTenantConfiguration extends CloudProviderTenantConfiguration implements ITypeNameObject, IOrganizationTenantConfiguration, IAwsScopeConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        active: boolean,
        deletePending: boolean,
        disabledOrchestrationTypes: OrchestrationType[],
        displayReference: string,
        name: string,
        orchestrationTypeToEnabledMap: Dictionary<boolean>,
        parentScopeId: string,
        scopeSections: ScopeConfigurationSections,
        type: TenantType,
        organizationExists: boolean,
        organizationId: Optional<string>,
        public accessAdvisorEnabled: boolean,
        public audit: boolean,
        public awsScopeSections: AwsScopeConfigurationSections,
        public awsTenantSections: AwsTenantConfigurationSections,
        public federation: AwsTenantConfigurationFederation,
        public partitionType: AwsPartitionType,
        public permissionManagement: Optional<AwsTenantConfigurationPermissionManagement>,
        public permissionTypes: CloudProviderTenantPermissionType[],
        public rawId: string,
        public regionSystemNames: Optional<string[]>,
        public roleArn: string,
        public tenantManagement: boolean,
        public trailBucket: Optional<AwsTenantConfigurationTrailBucket>,
        public trails: AwsTenantConfigurationTrail[],
        public validRegionSystemNames: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsTenantConfiguration",
            active,
            deletePending,
            disabledOrchestrationTypes,
            displayReference,
            name,
            orchestrationTypeToEnabledMap,
            parentScopeId,
            scopeSections,
            type,
            organizationExists,
            organizationId);
    }
}

export class AwsTenantEntity extends AwsResource implements ITypeNameObject, IAwsTagResource, IAwsTenantEntity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public canonicalId: Optional<string>,
        public iam: Optional<AwsTenantEntityIam>,
        public rawId: string,
        public s3: Optional<AwsTenantEntityS3>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsTenantEntity",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsTenantEntityEditS3PublicAccessPolicySnapshotChange extends EntitySnapshotChange implements ITypeNameObject {
    constructor(
        entityId: string) {
        super(
            "AwsTenantEntityEditS3PublicAccessPolicySnapshotChange",
            entityId);
    }
}

export class AwsTenantEntityModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAwsTenantEntityModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AwsTenantEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public account: Optional<AwsTenantEntityModelOrganizationsAccount>,
        public organizationsScopeResourceIdToResourceControlPolicySearchableReferencesMap: Dictionary<EntitySearchableReference[]>,
        public organizationsScopeResourceIdToServiceControlPolicySearchableReferencesMap: Dictionary<EntitySearchableReference[]>,
        public parentEntityPath: string,
        public resourceControlPolicyIds: string[],
        public serviceControlPolicyIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsTenantEntityModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsTenantEntitySnapshot extends AwsEntitySnapshot implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        changes: EntitySnapshotChange[],
        entityId: string,
        relatedEntityIds: string[],
        relatedEntitySnapshotIds: string[],
        public s3PublicAccessPolicy: Optional<AwsS3PublicAccessPolicy>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsTenantEntitySnapshot",
            tenantId,
            changes,
            entityId,
            relatedEntityIds,
            relatedEntitySnapshotIds);
    }
}

export class AwsTenantModel extends CloudProviderTenantModel {
    constructor(
        public configuration: AwsTenantConfiguration,
        public state: AwsTenantState,
        statusSeverity: Optional<Severity>,
        tenantType: TenantType,
        public issues: AwsTenantModelIssue[],
        public master: boolean,
        public roleName: string,
        public status: AwsTenantModelStatus,
        public trailStatus: Optional<AwsTenantModelTrailStatus>,
        public trailStatusSeverity: Optional<Severity>) {
        super(
            configuration,
            state,
            statusSeverity,
            tenantType);
    }
}

export class AwsTenantState extends CloudProviderTenantState implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        public monitoring: AwsTenantStateMonitoring,
        orchestration: TenantStateOrchestration,
        permissionManagementEnabled: boolean,
        permissionManagementPrincipalTenantId: Optional<string>,
        permissionTypes: CloudProviderTenantPermissionType[],
        public codeAnalysis: AwsTenantStateCodeAnalysis,
        public dataAnalysis: AwsTenantStateDataAnalysis,
        public eventAnalysis: AwsTenantStateEventAnalysis,
        public identityUsageRawEventUpdateTime: Optional<string>,
        public organization: Optional<AwsTenantStateOrganization>,
        public workloadAnalysis: AwsTenantStateWorkloadAnalysis) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsTenantState",
            monitoring,
            orchestration,
            permissionManagementEnabled,
            permissionManagementPrincipalTenantId,
            permissionTypes);
    }
}

export class AwsTimestreamDatabase extends AwsResource implements ITypeNameObject, IAwsEncryptedResource, IAwsTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public encryptionExists: boolean,
        public kmsEncryptionKeyOrAliasArns: string[],
        public storageKmsEncryptionKeyOrAliasArn: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsTimestreamDatabase",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsTimestreamDatabaseModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAwsEncryptedResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public kmsEncryptionKeyIdReferences: string[],
        public tableCount: number) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsTimestreamDatabaseModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsTimestreamDatabaseTable extends AwsResource implements ITypeNameObject, IAwsTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public databaseId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsTimestreamDatabaseTable",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsTimestreamDatabaseTableModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsTimestreamDatabaseTableModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsUnmanagedKubernetesCluster extends AwsEntity implements ITypeNameObject, IUnmanagedKubernetesCluster {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        public data: KubernetesClusterData,
        public platform: Optional<KubernetesClusterPlatform>,
        public platformVersion: Optional<string>,
        public version: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsUnmanagedKubernetesCluster",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime);
    }
}

export class AwsUnmanagedKubernetesClusterModel extends AwsEntityModel implements ITypeNameObject, IUdmObjectModel, IUnmanagedKubernetesClusterModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        public apiConnectivityStatus: Optional<SystemKubernetesClusterApiConnectivityStatus>,
        public data: KubernetesClusterModelData,
        public helm: KubernetesClusterModelHelm,
        public resourceUpdateTime: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsUnmanagedKubernetesClusterModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown);
    }
}

export class AwsUnmanagedKubernetesClusterModelFilters extends EntityModelFilters implements IUnmanagedKubernetesClusterModelFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        openRelatedEntityRiskTypeIdentifierItems: FilterItems<string>,
        regionIdItems: FilterItems<string>,
        tenantIdItems: FilterItems<string>,
        typeNameItems: FilterItems<string>,
        public kubernetesCluster: IUnmanagedKubernetesClusterModelFiltersSection) {
        super(
            attributeValueItems,
            openRelatedEntityRiskTypeIdentifierItems,
            regionIdItems,
            tenantIdItems,
            typeNameItems);
    }
}

export class AwsUnmanagedKubernetesClusterTypeMetadata extends EntityTypeMetadata {
    constructor() {
        super();
    }
}

export class AwsWafWebAcl extends AwsResource implements ITypeNameObject, IAwsTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public associatedResourceReferences: AwsResourceReference[],
        public defaultAction: AwsWafWebAclDefaultAction,
        public description: Optional<string>,
        public rawId: string,
        public rules: AwsWafWebAclRuleBase[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsWafWebAclModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public associatedResourceIdReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsWafWebAclRule extends AwsWafWebAclRuleBase implements ITypeNameObject {
    constructor(
        name: string,
        priority: number) {
        super(
            "AwsWafWebAclRule",
            name,
            priority);
    }
}

export class AzureAuthorizationDenyAssignmentProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAuthorizationDenyAssignmentProfile",
            creation);
    }
}

export class AzureAuthorizationLockProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAuthorizationLockProfile",
            creation);
    }
}

export class AzureAuthorizationRoleAssignmentClassicAdministratorProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAuthorizationRoleAssignmentClassicAdministratorProfile",
            creation);
    }
}

export class AzureAuthorizationRoleAssignmentProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAuthorizationRoleAssignmentProfile",
            creation);
    }
}

export class AzureAuthorizationRoleAssignmentScheduleProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAuthorizationRoleAssignmentScheduleProfile",
            creation);
    }
}

export class AzureAuthorizationRoleDefinitionProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>,
        public unusedStartTime: Optional<string>,
        public unusedStartTimeUpdateTime: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAuthorizationRoleDefinitionProfile",
            creation);
    }
}

export class AzureAuthorizationRoleEligibilityScheduleProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAuthorizationRoleEligibilityScheduleProfile",
            creation);
    }
}

export class AzureAuthorizationRoleManagementPolicyAssignmentProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAuthorizationRoleManagementPolicyAssignmentProfile",
            creation);
    }
}

export class AzureBehaviorIdentityRiskPolicyGroupFilters extends RiskPolicyTypeGroupFilters {
    constructor(
        public eventActionItems: FilterItems<string>) {
        super();
    }
}

export class AzureBotServiceBotServiceProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureBotServiceBotServiceProfile",
            creation);
    }
}

export class AzureCognitiveServicesAccountProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureCognitiveServicesAccountProfile",
            creation);
    }
}

export class AzureComputeDiskEncryptionSetProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeDiskEncryptionSetProfile",
            creation);
    }
}

export class AzureComputeDiskProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeDiskProfile",
            creation);
    }
}

export class AzureComputeDiskSnapshotProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeDiskSnapshotProfile",
            creation);
    }
}

export class AzureComputeGalleryImageProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeGalleryImageProfile",
            creation);
    }
}

export class AzureComputeGalleryProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeGalleryProfile",
            creation);
    }
}

export class AzureComputeManagedImageProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeManagedImageProfile",
            creation);
    }
}

export class AzureComputeVirtualMachineProfile extends AzureResourceProfile implements IDocument, ITypeNameObject, IAzureComputeVirtualMachineProfile {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>,
        public stopTime: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeVirtualMachineProfile",
            creation);
    }
}

export class AzureComputeVirtualMachineScaleSetProfile extends AzureResourceProfile implements IDocument, ITypeNameObject, IAzureComputeVirtualMachineProfile {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>,
        public stopTime: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeVirtualMachineScaleSetProfile",
            creation);
    }
}

export class AzureComputeVirtualMachineScaleSetVirtualMachineNetworkInterfaceIpConfigurationPublicIpAddressProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeVirtualMachineScaleSetVirtualMachineNetworkInterfaceIpConfigurationPublicIpAddressProfile",
            creation);
    }
}

export class AzureComputeVirtualMachineScaleSetVirtualMachineNetworkInterfaceProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeVirtualMachineScaleSetVirtualMachineNetworkInterfaceProfile",
            creation);
    }
}

export class AzureComputeVirtualMachineScaleSetVirtualMachineProfile extends AzureResourceProfile implements IDocument, ITypeNameObject, IAzureComputeVirtualMachineProfile {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>,
        public stopTime: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeVirtualMachineScaleSetVirtualMachineProfile",
            creation);
    }
}

export class AzureContainerImageProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureContainerImageProfile",
            creation);
    }
}

export class AzureContainerRegistryRegistryProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureContainerRegistryRegistryProfile",
            creation);
    }
}

export class AzureContainerRegistryRepositoryProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureContainerRegistryRepositoryProfile",
            creation);
    }
}

export class AzureContainerServiceManagedClusterAgentPoolProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureContainerServiceManagedClusterAgentPoolProfile",
            creation);
    }
}

export class AzureContainerServiceManagedClusterProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureContainerServiceManagedClusterProfile",
            creation);
    }
}

export class AzureDevOpsTenantConfiguration extends GitTenantConfiguration implements ITypeNameObject, ICodeScopeConfiguration, IOrganizationTenantConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        active: boolean,
        deletePending: boolean,
        disabledOrchestrationTypes: OrchestrationType[],
        displayReference: string,
        name: string,
        orchestrationTypeToEnabledMap: Dictionary<boolean>,
        parentScopeId: string,
        scopeSections: ScopeConfigurationSections,
        type: TenantType,
        codeTenantType: CodeTenantType,
        path: string,
        url: string,
        branchName: string,
        debug: Optional<boolean>,
        organizationId: string,
        syncEnabled: boolean,
        public projectName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDevOpsTenantConfiguration",
            active,
            deletePending,
            disabledOrchestrationTypes,
            displayReference,
            name,
            orchestrationTypeToEnabledMap,
            parentScopeId,
            scopeSections,
            type,
            codeTenantType,
            path,
            url,
            branchName,
            debug,
            organizationId,
            syncEnabled);
    }
}

export class AzureDocumentDbDatabaseAccountCassandraKeyspaceProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDocumentDbDatabaseAccountCassandraKeyspaceProfile",
            creation);
    }
}

export class AzureDocumentDbDatabaseAccountGremlinDatabaseProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDocumentDbDatabaseAccountGremlinDatabaseProfile",
            creation);
    }
}

export class AzureDocumentDbDatabaseAccountMongoDbDatabaseProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDocumentDbDatabaseAccountMongoDbDatabaseProfile",
            creation);
    }
}

export class AzureDocumentDbDatabaseAccountProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDocumentDbDatabaseAccountProfile",
            creation);
    }
}

export class AzureDocumentDbDatabaseAccountSqlDatabaseProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDocumentDbDatabaseAccountSqlDatabaseProfile",
            creation);
    }
}

export class AzureDocumentDbDatabaseAccountTableProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDocumentDbDatabaseAccountTableProfile",
            creation);
    }
}

export class AzureEntity extends Entity implements ITypeNameObject, IAzureEntity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        public path: Optional<string>,
        public rawPath: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime);
    }
}

export class AzureEntityModel extends EntityModel implements ITypeNameObject, IEntityModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown);
    }
}

export class AzureEvent extends Event implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        aggregatedEventCount: number,
        correlationId: Optional<string>,
        displayName: string,
        errorCode: string,
        errorMessage: Optional<string>,
        ipAddress: Optional<string>,
        name: Optional<string>,
        raw: string,
        riskId: Optional<string>,
        shouldReprocess: boolean,
        shouldReprocessOriginatorEntity: boolean,
        time: string,
        public actionDescription: string,
        public permissionActionRawValue: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureEvent",
            tenantId,
            aggregatedEventCount,
            correlationId,
            displayName,
            errorCode,
            errorMessage,
            ipAddress,
            name,
            raw,
            riskId,
            shouldReprocess,
            shouldReprocessOriginatorEntity,
            time);
    }
}

export class AzureEventHubNamespaceEventHubProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureEventHubNamespaceEventHubProfile",
            creation);
    }
}

export class AzureEventHubNamespaceProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureEventHubNamespaceProfile",
            creation);
    }
}

export class AzureExcessivePermissionPrincipalRiskPolicyUpdateAuditEventInactivePrincipalDeleteGroupMembershipEnabledChange extends RiskPolicyUpdateAuditEventChange implements ITypeNameObject {
    constructor(
        public inactivePrincipalDeleteGroupMembershipEnabled: Optional<boolean>,
        public previousInactivePrincipalDeleteGroupMembershipEnabled: Optional<boolean>) {
        super("AzureExcessivePermissionPrincipalRiskPolicyUpdateAuditEventInactivePrincipalDeleteGroupMembershipEnabledChange");
    }
}

export class AzureInsightsDiagnosticSettingsProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureInsightsDiagnosticSettingsProfile",
            creation);
    }
}

export class AzureKeyVaultVaultCertificateNotRotatedRiskPolicyUpdateAuditEventCertificateRotationTimeFrameChange extends RiskPolicyUpdateAuditEventChange implements ITypeNameObject {
    constructor(
        public certificateRotationTimeFrame: Optional<string>,
        public previousCertificateRotationTimeFrame: Optional<string>) {
        super("AzureKeyVaultVaultCertificateNotRotatedRiskPolicyUpdateAuditEventCertificateRotationTimeFrameChange");
    }
}

export class AzureKeyVaultVaultCertificateProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKeyVaultVaultCertificateProfile",
            creation);
    }
}

export class AzureKeyVaultVaultKeyNotRotatedRiskPolicyUpdateAuditEventKeyRotationTimeFrameChange extends RiskPolicyUpdateAuditEventChange implements ITypeNameObject {
    constructor(
        public keyRotationTimeFrame: Optional<string>,
        public previousKeyRotationTimeFrame: Optional<string>) {
        super("AzureKeyVaultVaultKeyNotRotatedRiskPolicyUpdateAuditEventKeyRotationTimeFrameChange");
    }
}

export class AzureKeyVaultVaultKeyProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKeyVaultVaultKeyProfile",
            creation);
    }
}

export class AzureKeyVaultVaultKeyRotationDisabledRiskPolicyUpdateAuditEventKeyRotationTimeFrameChange extends RiskPolicyUpdateAuditEventChange implements ITypeNameObject {
    constructor(
        public keyRotationTimeFrame: Optional<string>,
        public previousKeyRotationTimeFrame: Optional<string>) {
        super("AzureKeyVaultVaultKeyRotationDisabledRiskPolicyUpdateAuditEventKeyRotationTimeFrameChange");
    }
}

export class AzureKeyVaultVaultProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKeyVaultVaultProfile",
            creation);
    }
}

export class AzureKeyVaultVaultSecretProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKeyVaultVaultSecretProfile",
            creation);
    }
}

export class AzureLogicWorkflowProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureLogicWorkflowProfile",
            creation);
    }
}

export class AzureMachineLearningWorkspaceProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMachineLearningWorkspaceProfile",
            creation);
    }
}

export class AzureManagedIdentitySystemManagedIdentityProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureManagedIdentitySystemManagedIdentityProfile",
            creation);
    }
}

export class AzureManagedIdentityUserManagedIdentityProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureManagedIdentityUserManagedIdentityProfile",
            creation);
    }
}

export class AzureManagementManagementGroupProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureManagementManagementGroupProfile",
            creation);
    }
}

export class AzureMySqlFlexibleServerProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMySqlFlexibleServerProfile",
            creation);
    }
}

export class AzureMySqlSingleServerProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMySqlSingleServerProfile",
            creation);
    }
}

export class AzureNetworkAccessResourceStateNetwork extends NetworkAccessResourceStateNetwork implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        entityReference: EntityReference,
        inboundAccessType: NetworkInboundAccessType,
        inboundExternal: boolean,
        inboundExternalAccessScope: NetworkAccessScope,
        inboundExternalDestinationNetworkScopes: Optional<DestinationNetworkScope[]>,
        inboundExternalWideRange: Optional<boolean>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            entityReference,
            inboundAccessType,
            inboundExternal,
            inboundExternalAccessScope,
            inboundExternalDestinationNetworkScopes,
            inboundExternalWideRange);
    }
}

export class AzureNetworkApplicationGatewayProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkApplicationGatewayProfile",
            creation);
    }
}

export class AzureNetworkApplicationGatewayStateNetwork extends AzureNetworkAccessResourceStateNetwork implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        entityReference: EntityReference,
        inboundAccessType: NetworkInboundAccessType,
        inboundExternal: boolean,
        inboundExternalAccessScope: NetworkAccessScope,
        inboundExternalDestinationNetworkScopes: Optional<DestinationNetworkScope[]>,
        inboundExternalWideRange: Optional<boolean>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkApplicationGatewayStateNetwork",
            tenantId,
            entityReference,
            inboundAccessType,
            inboundExternal,
            inboundExternalAccessScope,
            inboundExternalDestinationNetworkScopes,
            inboundExternalWideRange);
    }
}

export class AzureNetworkApplicationSecurityGroupProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkApplicationSecurityGroupProfile",
            creation);
    }
}

export class AzureNetworkDnsZoneProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkDnsZoneProfile",
            creation);
    }
}

export class AzureNetworkDnsZoneRecordSetAaaaProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkDnsZoneRecordSetAaaaProfile",
            creation);
    }
}

export class AzureNetworkDnsZoneRecordSetAProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkDnsZoneRecordSetAProfile",
            creation);
    }
}

export class AzureNetworkDnsZoneRecordSetCaaProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkDnsZoneRecordSetCaaProfile",
            creation);
    }
}

export class AzureNetworkDnsZoneRecordSetCnameProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkDnsZoneRecordSetCnameProfile",
            creation);
    }
}

export class AzureNetworkDnsZoneRecordSetMxProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkDnsZoneRecordSetMxProfile",
            creation);
    }
}

export class AzureNetworkDnsZoneRecordSetNsProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkDnsZoneRecordSetNsProfile",
            creation);
    }
}

export class AzureNetworkDnsZoneRecordSetPtrProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkDnsZoneRecordSetPtrProfile",
            creation);
    }
}

export class AzureNetworkDnsZoneRecordSetSoaProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkDnsZoneRecordSetSoaProfile",
            creation);
    }
}

export class AzureNetworkDnsZoneRecordSetSrvProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkDnsZoneRecordSetSrvProfile",
            creation);
    }
}

export class AzureNetworkDnsZoneRecordSetTxtProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkDnsZoneRecordSetTxtProfile",
            creation);
    }
}

export class AzureNetworkLoadBalancerProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkLoadBalancerProfile",
            creation);
    }
}

export class AzureNetworkLoadBalancerStateNetwork extends AzureNetworkAccessResourceStateNetwork implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        entityReference: EntityReference,
        inboundAccessType: NetworkInboundAccessType,
        inboundExternal: boolean,
        inboundExternalAccessScope: NetworkAccessScope,
        inboundExternalDestinationNetworkScopes: DestinationNetworkScope[],
        inboundExternalWideRange: Optional<boolean>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkLoadBalancerStateNetwork",
            tenantId,
            entityReference,
            inboundAccessType,
            inboundExternal,
            inboundExternalAccessScope,
            inboundExternalDestinationNetworkScopes,
            inboundExternalWideRange);
    }
}

export class AzureNetworkNetworkInterfaceProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkNetworkInterfaceProfile",
            creation);
    }
}

export class AzureNetworkNetworkSecurityGroupProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkNetworkSecurityGroupProfile",
            creation);
    }
}

export class AzureNetworkPrivateDnsZoneProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkPrivateDnsZoneProfile",
            creation);
    }
}

export class AzureNetworkPrivateDnsZoneRecordSetAaaaProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkPrivateDnsZoneRecordSetAaaaProfile",
            creation);
    }
}

export class AzureNetworkPrivateDnsZoneRecordSetAProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkPrivateDnsZoneRecordSetAProfile",
            creation);
    }
}

export class AzureNetworkPrivateDnsZoneRecordSetCnameProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkPrivateDnsZoneRecordSetCnameProfile",
            creation);
    }
}

export class AzureNetworkPrivateDnsZoneRecordSetMxProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkPrivateDnsZoneRecordSetMxProfile",
            creation);
    }
}

export class AzureNetworkPrivateDnsZoneRecordSetPtrProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkPrivateDnsZoneRecordSetPtrProfile",
            creation);
    }
}

export class AzureNetworkPrivateDnsZoneRecordSetSoaProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkPrivateDnsZoneRecordSetSoaProfile",
            creation);
    }
}

export class AzureNetworkPrivateDnsZoneRecordSetSrvProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkPrivateDnsZoneRecordSetSrvProfile",
            creation);
    }
}

export class AzureNetworkPrivateDnsZoneRecordSetTxtProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkPrivateDnsZoneRecordSetTxtProfile",
            creation);
    }
}

export class AzureNetworkPrivateEndpointProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkPrivateEndpointProfile",
            creation);
    }
}

export class AzureNetworkPublicIpAddressProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkPublicIpAddressProfile",
            creation);
    }
}

export class AzureNetworkPublicIpPrefixProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkPublicIpPrefixProfile",
            creation);
    }
}

export class AzureNetworkRouteTableProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkRouteTableProfile",
            creation);
    }
}

export class AzureNetworkVirtualNetworkProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkVirtualNetworkProfile",
            creation);
    }
}

export class AzureNetworkVirtualNetworkSubnetProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkVirtualNetworkSubnetProfile",
            creation);
    }
}

export class AzureNetworkVirtualNetworkSubnetStateNetwork extends AzureNetworkAccessResourceStateNetwork implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        entityReference: EntityReference,
        inboundAccessType: NetworkInboundAccessType,
        inboundExternal: boolean,
        inboundExternalAccessScope: NetworkAccessScope,
        inboundExternalDestinationNetworkScopes: Optional<DestinationNetworkScope[]>,
        inboundExternalWideRange: Optional<boolean>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkVirtualNetworkSubnetStateNetwork",
            tenantId,
            entityReference,
            inboundAccessType,
            inboundExternal,
            inboundExternalAccessScope,
            inboundExternalDestinationNetworkScopes,
            inboundExternalWideRange);
    }
}

export class AzureOrganizationState extends CloudProviderTenantOrganizationState implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        permissionTypes: CloudProviderTenantPermissionType[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureOrganizationState",
            permissionTypes);
    }
}

export class AzurePostgreSqlFlexibleServerProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePostgreSqlFlexibleServerProfile",
            creation);
    }
}

export class AzurePostgreSqlSingleServerProfile extends AzureResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AzureResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePostgreSqlSingleServerProfile",
            creation);
    }
}

export class AzureResource extends AzureEntity implements ITypeNameObject, IAzureEntity, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        public name: string,
        public tagExists: boolean,
        public tags: ResourceTag[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath);
    }
}

export class AzureResourceInboundExternalPortRiskItem extends RiskItem implements ITypeNameObject {
    constructor(
        entityExclusionDag: Dag,
        entityId: string,
        excluded: boolean,
        public destinationNetworkScopes: DestinationNetworkScope[],
        public gatewayResourceIds: string[],
        public networkSecurityGroupIds: string[],
        public subnets: string[],
        public virtualNetworkId: Optional<string>) {
        super(
            "AzureResourceInboundExternalPortRiskItem",
            entityExclusionDag,
            entityId,
            excluded);
    }
}

export class AzureResourceModel extends AzureEntityModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        public creationTime: Optional<string>,
        public creatorIdentityIdReference: Optional<string>,
        public creatorOriginatorEntityIdReference: Optional<string>,
        public partitionType: AadPartitionType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown);
    }
}

export class AzureResourceModelFilters extends EntityModelFilters implements IEntityModelFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        openRelatedEntityRiskTypeIdentifierItems: FilterItems<string>,
        regionIdItems: FilterItems<string>,
        tenantIdItems: FilterItems<string>,
        typeNameItems: FilterItems<string>,
        public creationTimeRange: FilterTimeRange) {
        super(
            attributeValueItems,
            openRelatedEntityRiskTypeIdentifierItems,
            regionIdItems,
            tenantIdItems,
            typeNameItems);
    }
}

export class AzureResourceTypeMetadata extends EntityTypeMetadata {
    constructor() {
        super();
    }
}

export class AzureRoot extends AzureEntity implements ITypeNameObject, IAzureEntity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureRoot",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath);
    }
}

export class AzureRootModel extends AzureEntityModel implements ITypeNameObject, IUdmObjectModel, IRelatedTenantsEntityModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureRootModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown);
    }
}

export class AzureRootTypeMetadata extends EntityTypeMetadata {
    constructor() {
        super();
    }
}

export class AzureScopeResource extends AzureResource implements ITypeNameObject, IAzureEntity, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        public parentEntityId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags);
    }
}

export class AzureScopeResourceModel extends AzureResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        public roleAssignmentResourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType);
    }
}

export class AzureScopeResourceParentEntityIdMatchCondition extends EntityMatchCondition implements ITypeNameObject {
    constructor(
        public parentEntityIds: string[]) {
        super("AzureScopeResourceParentEntityIdMatchCondition");
    }
}

export class AzureScopeResourceTypeMetadata extends AzureResourceTypeMetadata {
    constructor() {
        super();
    }
}

export class AzureService extends AzureEntity implements ITypeNameObject, IAzureEntity, IService {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        public systemName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureService",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath);
    }
}

export class AzureServiceModel extends AzureEntityModel implements ITypeNameObject, IEntityModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureServiceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown);
    }
}

export class AzureSqlServerEventRetentionTimeFrameRiskPolicyUpdateAuditEventEventRetentionTimeFrameChange extends RiskPolicyUpdateAuditEventChange implements ITypeNameObject {
    constructor(
        public eventRetentionTimeFrame: Optional<string>,
        public previousEventRetentionTimeFrame: Optional<string>) {
        super("AzureSqlServerEventRetentionTimeFrameRiskPolicyUpdateAuditEventEventRetentionTimeFrameChange");
    }
}

export class AzureSqlServerModelFilters extends AzureResourceModelFilters implements IEntityModelFilters, IDataAnalysisResourceModelFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        openRelatedEntityRiskTypeIdentifierItems: FilterItems<string>,
        regionIdItems: FilterItems<string>,
        tenantIdItems: FilterItems<string>,
        typeNameItems: FilterItems<string>,
        creationTimeRange: FilterTimeRange,
        public dataAnalysisResource: DataAnalysisResourceModelFiltersSection) {
        super(
            attributeValueItems,
            openRelatedEntityRiskTypeIdentifierItems,
            regionIdItems,
            tenantIdItems,
            typeNameItems,
            creationTimeRange);
    }
}

export class AzureStorageStorageAccountBlobContainerModelFilters extends AzureResourceModelFilters implements IEntityModelFilters, IDataAnalysisResourceModelFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        openRelatedEntityRiskTypeIdentifierItems: FilterItems<string>,
        regionIdItems: FilterItems<string>,
        tenantIdItems: FilterItems<string>,
        typeNameItems: FilterItems<string>,
        creationTimeRange: FilterTimeRange,
        public dataAnalysisResource: DataAnalysisResourceModelFiltersSection) {
        super(
            attributeValueItems,
            openRelatedEntityRiskTypeIdentifierItems,
            regionIdItems,
            tenantIdItems,
            typeNameItems,
            creationTimeRange);
    }
}

export class AzureStorageStorageAccountStateNetwork extends AzureNetworkAccessResourceStateNetwork implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        entityReference: EntityReference,
        inboundAccessType: NetworkInboundAccessType,
        inboundExternal: boolean,
        inboundExternalAccessScope: NetworkAccessScope,
        inboundExternalDestinationNetworkScopes: Optional<DestinationNetworkScope[]>,
        inboundExternalWideRange: Optional<boolean>,
        public firewallRuleDatas: AzureStorageStorageAccountStateNetworkFirewallRuleData[],
        public publicNetworkAccess: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountStateNetwork",
            tenantId,
            entityReference,
            inboundAccessType,
            inboundExternal,
            inboundExternalAccessScope,
            inboundExternalDestinationNetworkScopes,
            inboundExternalWideRange);
    }
}

export class AzureTenantConfiguration extends CloudProviderTenantConfiguration implements ITypeNameObject, IOrganizationTenantConfiguration, IAzureScopeConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        active: boolean,
        deletePending: boolean,
        disabledOrchestrationTypes: OrchestrationType[],
        displayReference: string,
        name: string,
        orchestrationTypeToEnabledMap: Dictionary<boolean>,
        parentScopeId: string,
        scopeSections: ScopeConfigurationSections,
        type: TenantType,
        organizationExists: boolean,
        organizationId: Optional<string>,
        public aadTenantId: string,
        public azureScopeSections: AzureScopeConfigurationSections,
        public azureTenantSections: AzureTenantConfigurationSections,
        public permissionTypes: CloudProviderTenantPermissionType[],
        public rawShortId: string,
        public scopeEntityPathToServicePrincipalAssignedRoleDefinitionNamesMap: Dictionary<string[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureTenantConfiguration",
            active,
            deletePending,
            disabledOrchestrationTypes,
            displayReference,
            name,
            orchestrationTypeToEnabledMap,
            parentScopeId,
            scopeSections,
            type,
            organizationExists,
            organizationId);
    }
}

export class AzureTenantEntityModel extends AzureScopeResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, ITenantEntityModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        public parentEntityPath: string,
        public resourceGroupIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureTenantEntityModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureTenantModel extends CloudProviderTenantModel {
    constructor(
        public configuration: AzureTenantConfiguration,
        public state: AzureTenantState,
        statusSeverity: Optional<Severity>,
        tenantType: TenantType,
        public issues: AzureTenantModelIssue[],
        public status: AzureTenantModelStatus) {
        super(
            configuration,
            state,
            statusSeverity,
            tenantType);
    }
}

export class AzureTenantState extends CloudProviderTenantState implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        public monitoring: AzureTenantStateMonitoring,
        orchestration: TenantStateOrchestration,
        permissionManagementEnabled: boolean,
        permissionManagementPrincipalTenantId: Optional<string>,
        permissionTypes: CloudProviderTenantPermissionType[],
        public codeAnalysis: AzureTenantStateCodeAnalysis,
        public events: AzureTenantStateEvents) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureTenantState",
            monitoring,
            orchestration,
            permissionManagementEnabled,
            permissionManagementPrincipalTenantId,
            permissionTypes);
    }
}

export class AzureUnmanagedKubernetesCluster extends AzureEntity implements ITypeNameObject, IAzureEntity, IUnmanagedKubernetesCluster {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        public data: KubernetesClusterData,
        public platform: Optional<KubernetesClusterPlatform>,
        public platformVersion: Optional<string>,
        public version: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureUnmanagedKubernetesCluster",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath);
    }
}

export class AzureUnmanagedKubernetesClusterModel extends AzureEntityModel implements ITypeNameObject, IUdmObjectModel, IUnmanagedKubernetesClusterModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        public apiConnectivityStatus: Optional<SystemKubernetesClusterApiConnectivityStatus>,
        public data: KubernetesClusterModelData,
        public helm: KubernetesClusterModelHelm,
        public resourceUpdateTime: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureUnmanagedKubernetesClusterModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown);
    }
}

export class AzureUnmanagedKubernetesClusterModelFilters extends EntityModelFilters implements IUnmanagedKubernetesClusterModelFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        openRelatedEntityRiskTypeIdentifierItems: FilterItems<string>,
        regionIdItems: FilterItems<string>,
        tenantIdItems: FilterItems<string>,
        typeNameItems: FilterItems<string>,
        public kubernetesCluster: IUnmanagedKubernetesClusterModelFiltersSection) {
        super(
            attributeValueItems,
            openRelatedEntityRiskTypeIdentifierItems,
            regionIdItems,
            tenantIdItems,
            typeNameItems);
    }
}

export class AzureUnmanagedKubernetesClusterTypeMetadata extends EntityTypeMetadata {
    constructor() {
        super();
    }
}

export class AzureWebApplicationStateNetwork extends AzureNetworkAccessResourceStateNetwork implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        entityReference: EntityReference,
        inboundAccessType: NetworkInboundAccessType,
        inboundExternal: boolean,
        inboundExternalAccessScope: NetworkAccessScope,
        inboundExternalDestinationNetworkScopes: Optional<DestinationNetworkScope[]>,
        inboundExternalWideRange: Optional<boolean>,
        public scmInboundExternalAccessScope: NetworkAccessScope,
        public scmInboundExternalWideRange: Optional<boolean>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWebApplicationStateNetwork",
            tenantId,
            entityReference,
            inboundAccessType,
            inboundExternal,
            inboundExternalAccessScope,
            inboundExternalDestinationNetworkScopes,
            inboundExternalWideRange);
    }
}

export class AzureWorkloadAnalysisUpdateAuditEvent extends WorkloadAnalysisAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        public diskSnapshotResourceGroupId: Optional<string>,
        public diskSnapshotResourceGroupIdChanged: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWorkloadAnalysisUpdateAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime);
    }
}

export class BitbucketTenantConfiguration extends GitTenantConfiguration implements ITypeNameObject, ICodeScopeConfiguration, IOrganizationTenantConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        active: boolean,
        deletePending: boolean,
        disabledOrchestrationTypes: OrchestrationType[],
        displayReference: string,
        name: string,
        orchestrationTypeToEnabledMap: Dictionary<boolean>,
        parentScopeId: string,
        scopeSections: ScopeConfigurationSections,
        type: TenantType,
        codeTenantType: CodeTenantType,
        path: string,
        url: string,
        branchName: string,
        debug: Optional<boolean>,
        organizationId: string,
        syncEnabled: boolean,
        public rawNameId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "BitbucketTenantConfiguration",
            active,
            deletePending,
            disabledOrchestrationTypes,
            displayReference,
            name,
            orchestrationTypeToEnabledMap,
            parentScopeId,
            scopeSections,
            type,
            codeTenantType,
            path,
            url,
            branchName,
            debug,
            organizationId,
            syncEnabled);
    }
}

export class BuiltInEntityAttribute extends EntityAttribute implements ITypeNameObject {
    constructor(
        typeName: string) {
        super(typeName);
    }
}

export class Change extends ChangeBase implements ITypeNameObject {
    constructor(
        typeName: string,
        entityIds: string[],
        public id: string,
        public resolved: boolean) {
        super(
            typeName,
            entityIds);
    }
}

export class CiCloudsmithTenantConfiguration extends CiTenantConfiguration implements ITypeNameObject, ICiScopeConfiguration, IOrganizationTenantConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        active: boolean,
        deletePending: boolean,
        disabledOrchestrationTypes: OrchestrationType[],
        displayReference: string,
        name: string,
        orchestrationTypeToEnabledMap: Dictionary<boolean>,
        parentScopeId: string,
        scopeSections: ScopeConfigurationSections,
        type: TenantType,
        ciScopeSections: CiScopeConfigurationSections,
        ciTenantType: CiTenantType,
        integrationId: string,
        organizationId: Optional<string>,
        public organizationName: string,
        public registryName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "CiCloudsmithTenantConfiguration",
            active,
            deletePending,
            disabledOrchestrationTypes,
            displayReference,
            name,
            orchestrationTypeToEnabledMap,
            parentScopeId,
            scopeSections,
            type,
            ciScopeSections,
            ciTenantType,
            integrationId,
            organizationId);
    }
}

export class CiCloudsmithTenantModel extends CiTenantModel {
    constructor(
        public configuration: CiCloudsmithTenantConfiguration,
        public state: TenantState,
        statusSeverity: Optional<Severity>,
        tenantType: TenantType,
        integrationId: string,
        status: CiTenantModelStatus) {
        super(
            configuration,
            state,
            statusSeverity,
            tenantType,
            integrationId,
            status);
    }
}

export class CiContainerImageModelFilters extends EntityModelFilters implements IEntityModelFilters, IContainerImageFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        openRelatedEntityRiskTypeIdentifierItems: FilterItems<string>,
        regionIdItems: FilterItems<string>,
        tenantIdItems: FilterItems<string>,
        typeNameItems: FilterItems<string>,
        public containerImageCreationTimeRange: FilterTimeRange,
        public scanTimeRange: FilterTimeRange) {
        super(
            attributeValueItems,
            openRelatedEntityRiskTypeIdentifierItems,
            regionIdItems,
            tenantIdItems,
            typeNameItems);
    }
}

export class CiEntity extends Entity implements ITypeNameObject, IEntity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime);
    }
}

export class CiEntityModel extends EntityModel implements ITypeNameObject, IEntityModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown);
    }
}

export class CiEntityTypeMetadata extends EntityTypeMetadata {
    constructor() {
        super();
    }
}

export class CiFolderConfiguration extends FolderConfiguration implements ITypeNameObject, ICiFolderConfiguration, IOrganizationFolderConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        managed: boolean,
        name: string,
        parentScopeId: string,
        root: boolean,
        scopeSections: ScopeConfigurationSections,
        tenantType: Optional<TenantType>,
        public ciScopeSections: CiScopeConfigurationSections,
        public organization: Optional<FolderConfigurationOrganization>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "CiFolderConfiguration",
            managed,
            name,
            parentScopeId,
            root,
            scopeSections,
            tenantType);
    }
}

export class CiGeneralTenantConfiguration extends CiTenantConfiguration implements ITypeNameObject, ICiScopeConfiguration, IOrganizationTenantConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        active: boolean,
        deletePending: boolean,
        disabledOrchestrationTypes: OrchestrationType[],
        displayReference: string,
        name: string,
        orchestrationTypeToEnabledMap: Dictionary<boolean>,
        parentScopeId: string,
        scopeSections: ScopeConfigurationSections,
        type: TenantType,
        ciScopeSections: CiScopeConfigurationSections,
        ciTenantType: CiTenantType,
        integrationId: string,
        organizationId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "CiGeneralTenantConfiguration",
            active,
            deletePending,
            disabledOrchestrationTypes,
            displayReference,
            name,
            orchestrationTypeToEnabledMap,
            parentScopeId,
            scopeSections,
            type,
            ciScopeSections,
            ciTenantType,
            integrationId,
            organizationId);
    }
}

export class CiGeneralTenantModel extends CiTenantModel {
    constructor(
        public configuration: CiGeneralTenantConfiguration,
        public state: TenantState,
        statusSeverity: Optional<Severity>,
        tenantType: TenantType,
        integrationId: string,
        status: CiTenantModelStatus) {
        super(
            configuration,
            state,
            statusSeverity,
            tenantType,
            integrationId,
            status);
    }
}

export class CiIntegrationConfiguration extends ScopeSystemEntityConfiguration implements ITypeNameObject, IEndpointIntegrationEntityConfiguration, ITenantOrganizationConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        public endpoint: Optional<string>,
        public endpointConnectorId: Optional<string>,
        public folderEnabled: boolean,
        public memberSelection: Optional<OrganizationMemberSelection>,
        public name: string,
        public serverCertificateValidationEnabled: boolean,
        public type: CiTenantType,
        public userName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId);
    }
}

export class CiIntegrationModel extends ScopeSystemEntityModel implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        public configuration: CiIntegrationConfiguration,
        scopeId: string,
        public state: Optional<CiIntegrationState>,
        public status: CiIntegrationModelStatus,
        public statusSeverity: Optional<Severity>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "CiIntegrationModel",
            configuration,
            scopeId,
            state);
    }
}

export class CiJfrogOrganizationConfiguration extends CiIntegrationConfiguration implements ITypeNameObject, IEndpointIntegrationEntityConfiguration, ITenantOrganizationConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        endpoint: Optional<string>,
        endpointConnectorId: Optional<string>,
        folderEnabled: boolean,
        memberSelection: Optional<OrganizationMemberSelection>,
        name: string,
        serverCertificateValidationEnabled: boolean,
        type: CiTenantType,
        userName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "CiJfrogOrganizationConfiguration",
            scopeId,
            endpoint,
            endpointConnectorId,
            folderEnabled,
            memberSelection,
            name,
            serverCertificateValidationEnabled,
            type,
            userName);
    }
}

export class CiJfrogTenantConfiguration extends CiTenantConfiguration implements ITypeNameObject, ICiScopeConfiguration, IOrganizationTenantConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        active: boolean,
        deletePending: boolean,
        disabledOrchestrationTypes: OrchestrationType[],
        displayReference: string,
        name: string,
        orchestrationTypeToEnabledMap: Dictionary<boolean>,
        parentScopeId: string,
        scopeSections: ScopeConfigurationSections,
        type: TenantType,
        ciScopeSections: CiScopeConfigurationSections,
        ciTenantType: CiTenantType,
        integrationId: string,
        organizationId: Optional<string>,
        public registryKey: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "CiJfrogTenantConfiguration",
            active,
            deletePending,
            disabledOrchestrationTypes,
            displayReference,
            name,
            orchestrationTypeToEnabledMap,
            parentScopeId,
            scopeSections,
            type,
            ciScopeSections,
            ciTenantType,
            integrationId,
            organizationId);
    }
}

export class CiJfrogTenantModel extends CiTenantModel {
    constructor(
        public configuration: CiJfrogTenantConfiguration,
        public state: TenantState,
        statusSeverity: Optional<Severity>,
        tenantType: TenantType,
        integrationId: string,
        status: CiTenantModelStatus) {
        super(
            configuration,
            state,
            statusSeverity,
            tenantType,
            integrationId,
            status);
    }
}

export class CiTenantEntity extends CiEntity implements ITypeNameObject, ITenantEntity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        public name: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "CiTenantEntity",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime);
    }
}

export class CiTenantEntityModel extends CiEntityModel implements ITypeNameObject, IUdmObjectModel, ITenantEntityModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        public parentEntityPath: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "CiTenantEntityModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown);
    }
}

export class CloudProviderTenantFolderConfiguration extends FolderConfiguration implements ITypeNameObject, IChildScopeConfiguration, IOrganizationFolderConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        managed: boolean,
        name: string,
        parentScopeId: string,
        root: boolean,
        scopeSections: ScopeConfigurationSections,
        tenantType: Optional<TenantType>,
        public organization: Optional<FolderConfigurationOrganization>,
        public organizationExists: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            managed,
            name,
            parentScopeId,
            root,
            scopeSections,
            tenantType);
    }
}

export class CloudProviderTenantOrganizationConfiguration extends ScopeSystemEntityConfiguration implements ITypeNameObject, ITenantOrganizationConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        public enabled: boolean,
        public folderEnabled: boolean,
        public memberSelection: Optional<OrganizationMemberSelection>,
        public name: string,
        public permissionTypes: CloudProviderTenantPermissionType[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId);
    }
}

export class CloudRisk extends Risk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType);
    }
}

export class CloudRiskFilters extends RiskFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        categoryItems: FilterItems<RiskPolicyCategory>,
        complianceIdentifierItems: FilterItems<string>,
        ignoreExpirationDateRange: FilterTimeRange,
        openStatusUpdateTimeRange: FilterTimeRange,
        riskPolicyIdentifierItems: FilterItems<string>,
        statusUpdateTimeRange: FilterTimeRange,
        subStatuses: RiskSubStatus[],
        systemCreationTimeRange: FilterTimeRange,
        tenantIdItems: FilterItems<string>) {
        super(
            attributeValueItems,
            categoryItems,
            complianceIdentifierItems,
            ignoreExpirationDateRange,
            openStatusUpdateTimeRange,
            riskPolicyIdentifierItems,
            statusUpdateTimeRange,
            subStatuses,
            systemCreationTimeRange,
            tenantIdItems);
    }
}

export class CloudRiskModel extends RiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        risk: Risk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class CloudRisksReportDefinitionConfiguration extends RisksReportDefinitionConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        contentType: ReportContentType,
        exportReport: boolean,
        licenseType: ApplicationCustomerConfigurationLicensingLicenseType,
        name: Optional<string>,
        timeZoneInfoId: string,
        type: ReportType,
        additionalColumnResourceTagKeys: Optional<string[]>,
        filtered: boolean,
        filters: RiskControllerRiskModelFilters,
        riskPolicyTypeFilters: Optional<RiskControllerRiskPolicyTypeFilters>,
        sort: Optional<RiskControllerRiskModelSort>,
        status: RiskStatus) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "CloudRisksReportDefinitionConfiguration",
            contentType,
            exportReport,
            licenseType,
            name,
            timeZoneInfoId,
            type,
            additionalColumnResourceTagKeys,
            filtered,
            filters,
            riskPolicyTypeFilters,
            sort,
            status);
    }
}

export class CodeBlock extends CodeEntity implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        public endLine: number,
        public fileId: string,
        public startLine: number) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId);
    }
}

export class CodeContainerImagePackage extends CodeScanResource implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        public displayName: string,
        public filePaths: string[],
        public scanId: string,
        public type: WorkloadResourceScanPackageType,
        public vulnerabilityRawIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "CodeContainerImagePackage",
            tenantId);
    }
}

export class CodeContainerImageVulnerability extends CodeScanResource implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        public attackVector: Optional<VulnerabilityAttackVector>,
        public cvssScore: Optional<number>,
        public cvssVersion: Optional<CvssVersion>,
        public exploitable: boolean,
        public exploitMaturity: Optional<VulnerabilityExploitMaturity>,
        public packageDisplayNames: string[],
        public packageFilePaths: string[],
        public rawId: string,
        public resolutionVersions: string[],
        public scanId: string,
        public severity: Severity,
        public vprScore: Optional<number>,
        public vprSeverity: Optional<Severity>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "CodeContainerImageVulnerability",
            tenantId);
    }
}

export class CodeFolderConfiguration extends FolderConfiguration implements ITypeNameObject, ICodeFolderConfiguration, IOrganizationFolderConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        managed: boolean,
        name: string,
        parentScopeId: string,
        root: boolean,
        scopeSections: ScopeConfigurationSections,
        tenantType: Optional<TenantType>,
        public organization: Optional<FolderConfigurationOrganization>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "CodeFolderConfiguration",
            managed,
            name,
            parentScopeId,
            root,
            scopeSections,
            tenantType);
    }
}

export class CodeIacRisk extends CodeScanResource implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        public cloudTenantType: Optional<TenantType>,
        public codeType: CodeType,
        public excluded: boolean,
        public fileRelativePath: string,
        public newRisk: boolean,
        public resourceName: string,
        public resourceTypeSystemName: string,
        public riskTypeName: string,
        public scanId: string,
        public severity: Severity,
        public snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "CodeIacRisk",
            tenantId);
    }
}

export class CodeIntegrationConfiguration extends ScopeSystemEntityConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        public name: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId);
    }
}

export class CodeOrganizationConfiguration extends CodeIntegrationConfiguration implements ITypeNameObject, ITenantOrganizationConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        name: string,
        public folderEnabled: boolean,
        public memberSelection: Optional<OrganizationMemberSelection>,
        public serverId: Optional<string>,
        public syncEnabled: boolean,
        public type: CodeTenantType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            name);
    }
}

export class CodeOrganizationModel extends ScopeSystemEntityModel implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        public configuration: CodeOrganizationConfiguration,
        scopeId: string,
        state: Optional<SystemEntityState>,
        public endpoint: Optional<string>,
        public status: CodeOrganizationModelStatus,
        public statusSeverity: Optional<Severity>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "CodeOrganizationModel",
            configuration,
            scopeId,
            state);
    }
}

export class CodePipelineConfiguration extends CodeIntegrationConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        name: string,
        public apiKeyId: string,
        public type: CodePipelineType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "CodePipelineConfiguration",
            scopeId,
            name);
    }
}

export class CodePipelineModel extends ScopeSystemEntityModel implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        public configuration: CodePipelineConfiguration,
        scopeId: string,
        state: Optional<SystemEntityState>,
        public usageTime: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "CodePipelineModel",
            configuration,
            scopeId,
            state);
    }
}

export class CodeResourceAttribute extends BuiltInEntityAttribute implements ITypeNameObject {
    constructor() {
        super("CodeResourceAttribute");
    }
}

export class CodeRisk extends Risk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        public codeType: CodeType,
        public fileRelativePath: string,
        public firstScanCommit: CodeCommit,
        public resourceName: string,
        public resourceTypeSystemName: string,
        public snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType);
    }
}

export class CodeRiskFilters extends RiskFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        categoryItems: FilterItems<RiskPolicyCategory>,
        complianceIdentifierItems: FilterItems<string>,
        ignoreExpirationDateRange: FilterTimeRange,
        openStatusUpdateTimeRange: FilterTimeRange,
        riskPolicyIdentifierItems: FilterItems<string>,
        statusUpdateTimeRange: FilterTimeRange,
        subStatuses: RiskSubStatus[],
        systemCreationTimeRange: FilterTimeRange,
        tenantIdItems: FilterItems<string>,
        public cloudTenantTypeItems: FilterItems<TenantType>,
        public codeTypeItems: FilterItems<CodeType>) {
        super(
            attributeValueItems,
            categoryItems,
            complianceIdentifierItems,
            ignoreExpirationDateRange,
            openStatusUpdateTimeRange,
            riskPolicyIdentifierItems,
            statusUpdateTimeRange,
            subStatuses,
            systemCreationTimeRange,
            tenantIdItems);
    }
}

export class CodeRiskModel extends RiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: CodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class CodeRisksReportDefinitionConfiguration extends RisksReportDefinitionConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        contentType: ReportContentType,
        exportReport: boolean,
        licenseType: ApplicationCustomerConfigurationLicensingLicenseType,
        name: Optional<string>,
        timeZoneInfoId: string,
        type: ReportType,
        additionalColumnResourceTagKeys: Optional<string[]>,
        filtered: boolean,
        filters: RiskControllerRiskModelFilters,
        riskPolicyTypeFilters: Optional<RiskControllerRiskPolicyTypeFilters>,
        sort: Optional<RiskControllerRiskModelSort>,
        status: RiskStatus) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "CodeRisksReportDefinitionConfiguration",
            contentType,
            exportReport,
            licenseType,
            name,
            timeZoneInfoId,
            type,
            additionalColumnResourceTagKeys,
            filtered,
            filters,
            riskPolicyTypeFilters,
            sort,
            status);
    }
}

export class CodeScan extends CodeScanResource implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        public commit: CodeCommit,
        public integrationId: string,
        public pipeline: Optional<CodeScanPipeline>,
        public success: boolean,
        public time: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId);
    }
}

export class CodeServerModel extends ScopeSystemEntityModel implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        public configuration: CodeServerConfiguration,
        scopeId: string,
        public state: Optional<CodeServerState>,
        public status: CodeServerModelStatus,
        public statusSeverity: Optional<Severity>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "CodeServerModel",
            configuration,
            scopeId,
            state);
    }
}

export class CodeTerraformDnsRecordSetExistsCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "CodeTerraformDnsRecordSetExistsCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class CodeTerraformDnsRecordSetExistsCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: CodeTerraformDnsRecordSetExistsCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "CodeTerraformDnsRecordSetExistsCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class CodeTerraformExternalExistsCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "CodeTerraformExternalExistsCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class CodeTerraformExternalExistsCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: CodeTerraformExternalExistsCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "CodeTerraformExternalExistsCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class CommonReportDefinitionConfiguration extends ReportDefinitionConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        contentType: ReportContentType,
        exportReport: boolean,
        licenseType: ApplicationCustomerConfigurationLicensingLicenseType,
        name: Optional<string>,
        timeZoneInfoId: string,
        type: ReportType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "CommonReportDefinitionConfiguration",
            contentType,
            exportReport,
            licenseType,
            name,
            timeZoneInfoId,
            type);
    }
}

export class ComplianceReportDefinitionConfiguration extends ReportDefinitionConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        contentType: ReportContentType,
        exportReport: boolean,
        licenseType: ApplicationCustomerConfigurationLicensingLicenseType,
        name: Optional<string>,
        timeZoneInfoId: string,
        type: ReportType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            contentType,
            exportReport,
            licenseType,
            name,
            timeZoneInfoId,
            type);
    }
}

export class ComplianceScopesReportDefinitionConfiguration extends ReportDefinitionConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        contentType: ReportContentType,
        exportReport: boolean,
        licenseType: ApplicationCustomerConfigurationLicensingLicenseType,
        name: Optional<string>,
        timeZoneInfoId: string,
        type: ReportType,
        public sectionIdentifier: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "ComplianceScopesReportDefinitionConfiguration",
            contentType,
            exportReport,
            licenseType,
            name,
            timeZoneInfoId,
            type);
    }
}

export class ConfigurationControllerGetAwsOrganizationOnboardingInfoResponse extends Response implements ITypeNameObject {
    constructor(
        public applicationRoleTenantRawId: Optional<string>,
        public chinaAuthenticationUserArn: Optional<string>,
        public cloudFormationTemplateUrl: string) {
        super("ConfigurationControllerGetAwsOrganizationOnboardingInfoResponse");
    }
}

export class ConfigurationControllerInsertAadDirectoryGroupMembershipEligibilityRequest extends ConfigurationControllerInsertGroupMembershipEligibilityRequest {
    constructor(
        approval: Optional<ConfigurationControllerPermissionEligibilityApproval>,
        description: Optional<string>,
        expirationTimeFrame: string,
        granteePrincipalIds: string[],
        name: string,
        principalTenantId: string,
        reasonRequired: boolean,
        scopeId: string,
        groupIds: string[]) {
        super(
            approval,
            description,
            expirationTimeFrame,
            granteePrincipalIds,
            name,
            principalTenantId,
            reasonRequired,
            scopeId,
            groupIds);
    }
}

export class ConfigurationControllerInsertAwsSsoPermissionSetAssignmentEligibilityRequest extends ConfigurationControllerInsertPermissionAssignmentEligibilityRequest {
    constructor(
        approval: Optional<ConfigurationControllerPermissionEligibilityApproval>,
        description: Optional<string>,
        expirationTimeFrame: string,
        granteePrincipalIds: string[],
        name: string,
        principalTenantId: string,
        reasonRequired: boolean,
        scopeId: string,
        permissionRequestScopeIds: string[],
        public permissionSetNamePrefix: string) {
        super(
            approval,
            description,
            expirationTimeFrame,
            granteePrincipalIds,
            name,
            principalTenantId,
            reasonRequired,
            scopeId,
            permissionRequestScopeIds);
    }
}

export class ConfigurationControllerInsertAwsSsoPermissionSetPermissionSetAssignmentEligibilityRequest extends ConfigurationControllerInsertAwsSsoPermissionSetAssignmentEligibilityRequest {
    constructor(
        approval: Optional<ConfigurationControllerPermissionEligibilityApproval>,
        description: Optional<string>,
        expirationTimeFrame: string,
        granteePrincipalIds: string[],
        name: string,
        principalTenantId: string,
        reasonRequired: boolean,
        scopeId: string,
        permissionRequestScopeIds: string[],
        permissionSetNamePrefix: string,
        public permissionSetId: string) {
        super(
            approval,
            description,
            expirationTimeFrame,
            granteePrincipalIds,
            name,
            principalTenantId,
            reasonRequired,
            scopeId,
            permissionRequestScopeIds,
            permissionSetNamePrefix);
    }
}

export class ConfigurationControllerInsertAzureRoleAssignmentEligibilityRequest extends ConfigurationControllerInsertPermissionAssignmentEligibilityRequest {
    constructor(
        approval: Optional<ConfigurationControllerPermissionEligibilityApproval>,
        description: Optional<string>,
        expirationTimeFrame: string,
        granteePrincipalIds: string[],
        name: string,
        principalTenantId: string,
        reasonRequired: boolean,
        scopeId: string,
        permissionRequestScopeIds: string[],
        public roleDefinitionIds: string[]) {
        super(
            approval,
            description,
            expirationTimeFrame,
            granteePrincipalIds,
            name,
            principalTenantId,
            reasonRequired,
            scopeId,
            permissionRequestScopeIds);
    }
}

export class ConfigurationControllerInsertGciDirectoryGroupMembershipEligibilityRequest extends ConfigurationControllerInsertGroupMembershipEligibilityRequest {
    constructor(
        approval: Optional<ConfigurationControllerPermissionEligibilityApproval>,
        description: Optional<string>,
        expirationTimeFrame: string,
        granteePrincipalIds: string[],
        name: string,
        principalTenantId: string,
        reasonRequired: boolean,
        scopeId: string,
        groupIds: string[]) {
        super(
            approval,
            description,
            expirationTimeFrame,
            granteePrincipalIds,
            name,
            principalTenantId,
            reasonRequired,
            scopeId,
            groupIds);
    }
}

export class ConfigurationControllerInsertGcpRoleBindingEligibilityRequest extends ConfigurationControllerInsertPermissionAssignmentEligibilityRequest {
    constructor(
        approval: Optional<ConfigurationControllerPermissionEligibilityApproval>,
        description: Optional<string>,
        expirationTimeFrame: string,
        granteePrincipalIds: string[],
        name: string,
        principalTenantId: string,
        reasonRequired: boolean,
        scopeId: string,
        permissionRequestScopeIds: string[],
        public roleIds: string[]) {
        super(
            approval,
            description,
            expirationTimeFrame,
            granteePrincipalIds,
            name,
            principalTenantId,
            reasonRequired,
            scopeId,
            permissionRequestScopeIds);
    }
}

export class ConfigurationControllerUpdateAadDirectoryGroupMembershipEligibilityRequest extends ConfigurationControllerUpdateGroupMembershipEligibilityRequest {
    constructor(
        approval: Optional<ConfigurationControllerPermissionEligibilityApproval>,
        description: Optional<string>,
        expirationTimeFrame: string,
        granteePrincipalIds: string[],
        id: string,
        name: string,
        principalTenantId: string,
        reasonRequired: boolean,
        groupIds: string[]) {
        super(
            approval,
            description,
            expirationTimeFrame,
            granteePrincipalIds,
            id,
            name,
            principalTenantId,
            reasonRequired,
            groupIds);
    }
}

export class ConfigurationControllerUpdateAwsSsoPermissionSetAssignmentEligibilityRequest extends ConfigurationControllerUpdatePermissionAssignmentEligibilityRequest {
    constructor(
        approval: Optional<ConfigurationControllerPermissionEligibilityApproval>,
        description: Optional<string>,
        expirationTimeFrame: string,
        granteePrincipalIds: string[],
        id: string,
        name: string,
        principalTenantId: string,
        reasonRequired: boolean,
        permissionRequestScopeIds: string[],
        public permissionSetNamePrefix: string) {
        super(
            approval,
            description,
            expirationTimeFrame,
            granteePrincipalIds,
            id,
            name,
            principalTenantId,
            reasonRequired,
            permissionRequestScopeIds);
    }
}

export class ConfigurationControllerUpdateAwsSsoPermissionSetPermissionSetAssignmentEligibilityRequest extends ConfigurationControllerUpdateAwsSsoPermissionSetAssignmentEligibilityRequest {
    constructor(
        approval: Optional<ConfigurationControllerPermissionEligibilityApproval>,
        description: Optional<string>,
        expirationTimeFrame: string,
        granteePrincipalIds: string[],
        id: string,
        name: string,
        principalTenantId: string,
        reasonRequired: boolean,
        permissionRequestScopeIds: string[],
        permissionSetNamePrefix: string,
        public permissionSetId: string) {
        super(
            approval,
            description,
            expirationTimeFrame,
            granteePrincipalIds,
            id,
            name,
            principalTenantId,
            reasonRequired,
            permissionRequestScopeIds,
            permissionSetNamePrefix);
    }
}

export class ConfigurationControllerUpdateAzureRoleAssignmentEligibilityRequest extends ConfigurationControllerUpdatePermissionAssignmentEligibilityRequest {
    constructor(
        approval: Optional<ConfigurationControllerPermissionEligibilityApproval>,
        description: Optional<string>,
        expirationTimeFrame: string,
        granteePrincipalIds: string[],
        id: string,
        name: string,
        principalTenantId: string,
        reasonRequired: boolean,
        permissionRequestScopeIds: string[],
        public roleDefinitionIds: string[]) {
        super(
            approval,
            description,
            expirationTimeFrame,
            granteePrincipalIds,
            id,
            name,
            principalTenantId,
            reasonRequired,
            permissionRequestScopeIds);
    }
}

export class ConfigurationControllerUpdateGciDirectoryGroupMembershipEligibilityRequest extends ConfigurationControllerUpdateGroupMembershipEligibilityRequest {
    constructor(
        approval: Optional<ConfigurationControllerPermissionEligibilityApproval>,
        description: Optional<string>,
        expirationTimeFrame: string,
        granteePrincipalIds: string[],
        id: string,
        name: string,
        principalTenantId: string,
        reasonRequired: boolean,
        groupIds: string[]) {
        super(
            approval,
            description,
            expirationTimeFrame,
            granteePrincipalIds,
            id,
            name,
            principalTenantId,
            reasonRequired,
            groupIds);
    }
}

export class ConfigurationControllerUpdateGcpRoleBindingEligibilityRequest extends ConfigurationControllerUpdatePermissionAssignmentEligibilityRequest {
    constructor(
        approval: Optional<ConfigurationControllerPermissionEligibilityApproval>,
        description: Optional<string>,
        expirationTimeFrame: string,
        granteePrincipalIds: string[],
        id: string,
        name: string,
        principalTenantId: string,
        reasonRequired: boolean,
        permissionRequestScopeIds: string[],
        public roleIds: string[]) {
        super(
            approval,
            description,
            expirationTimeFrame,
            granteePrincipalIds,
            id,
            name,
            principalTenantId,
            reasonRequired,
            permissionRequestScopeIds);
    }
}

export class CredentialsDisabledUserAttribute extends BuiltInEntityAttribute implements ITypeNameObject {
    constructor() {
        super("CredentialsDisabledUserAttribute");
    }
}

export class CustomActivityRiskPolicyTypeMetadata extends CustomRiskPolicyTypeMetadata {
    constructor(
        builtInComplianceSectionTypeToAnalysisGroupTypeMap: Dictionary<RiskPolicyTypeMetadataAnalysisGroupType>,
        category: RiskPolicyCategory,
        complianceRiskedEntityTypeName: Optional<string>,
        disabledAwsTenantPartitionTypes: AwsPartitionType[],
        licenseType: ApplicationCustomerConfigurationLicensingLicenseType,
        riskedEntityTypeName: Optional<string>,
        riskPolicyEntityCreationTimeAnalysisDelayTimeFrameChangeable: boolean,
        severity: Optional<Severity>,
        temporal: boolean) {
        super(
            builtInComplianceSectionTypeToAnalysisGroupTypeMap,
            category,
            complianceRiskedEntityTypeName,
            disabledAwsTenantPartitionTypes,
            licenseType,
            riskedEntityTypeName,
            riskPolicyEntityCreationTimeAnalysisDelayTimeFrameChangeable,
            severity,
            temporal);
    }
}

export class CustomComplianceConfiguration extends ScopeSystemEntityConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        public section: CustomComplianceSection) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "CustomComplianceConfiguration",
            scopeId);
    }
}

export class CustomComplianceReportDefinitionConfiguration extends ComplianceReportDefinitionConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        contentType: ReportContentType,
        exportReport: boolean,
        licenseType: ApplicationCustomerConfigurationLicensingLicenseType,
        name: Optional<string>,
        timeZoneInfoId: string,
        type: ReportType,
        public customComplianceId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "CustomComplianceReportDefinitionConfiguration",
            contentType,
            exportReport,
            licenseType,
            name,
            timeZoneInfoId,
            type);
    }
}

export class CustomEntityAttribute extends EntityAttribute implements ITypeNameObject {
    constructor(
        typeName: string,
        public definitionId: string,
        public self: boolean) {
        super(typeName);
    }
}

export class CustomEntityAttributeDefinitionConfiguration extends ScopeSystemEntityConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        public colorIndex: number,
        public name: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId);
    }
}

export class CustomerOrchestrationStageState extends OrchestrationStageState implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        data: OrchestrationStageStateData,
        orchestrationType: OrchestrationType,
        stageTypeName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "CustomerOrchestrationStageState",
            data,
            orchestrationType,
            stageTypeName);
    }
}

export class CustomRiskPolicyGroupFilters extends RiskPolicyTypeGroupFilters {
    constructor(
        public riskPolicyIdItems: FilterItems<string>) {
        super();
    }
}

export class DagContainer extends DagNode implements ITypeNameObject {
    constructor(
        typeName: string,
        public nodes: DagNode[],
        public type: DagContainerType) {
        super(typeName);
    }
}

export class DataAnalysisResourceModelFilters extends EntityModelFilters implements IEntityModelFilters, IDataAnalysisResourceModelFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        openRelatedEntityRiskTypeIdentifierItems: FilterItems<string>,
        regionIdItems: FilterItems<string>,
        tenantIdItems: FilterItems<string>,
        typeNameItems: FilterItems<string>,
        public creationTimeRange: FilterTimeRange,
        public dataAnalysisResource: DataAnalysisResourceModelFiltersSection) {
        super(
            attributeValueItems,
            openRelatedEntityRiskTypeIdentifierItems,
            regionIdItems,
            tenantIdItems,
            typeNameItems);
    }
}

export class DataAnalysisResourceModelSummary extends EntityModelSummary {
    constructor(
        count: number,
        public dataCategoryToCountMap: Dictionary<number>,
        public dataSensitiveResourceCount: number,
        public dataSensitivityToCountMap: Dictionary<number>,
        public monitoredResourceCount: number,
        public notMonitoredResourceCount: number) {
        super(count);
    }
}

export class DataClassifierConfiguration extends ScopeSystemEntityConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        public dataClassifier: DataClassifier,
        public enabled: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "DataClassifierConfiguration",
            scopeId);
    }
}

export class DatadogDelivery extends IntegrationDelivery implements ITypeNameObject {
    constructor(
        public destinations: DatadogDeliveryOrganization[],
        message: Optional<string>,
        integrationIds: string[]) {
        super(
            "DatadogDelivery",
            destinations,
            message,
            integrationIds);
    }
}

export class DatadogDeliveryOrganization extends DeliveryIntegration implements ITypeNameObject {
    constructor(
        integrationId: string,
        public organizationId: string) {
        super(
            "DatadogDeliveryOrganization",
            integrationId);
    }
}

export class DataResourceAttribute extends BuiltInEntityAttribute implements ITypeNameObject {
    constructor(
        typeName: string) {
        super(typeName);
    }
}

export class DataResourcePermissionActionPrincipalAttribute extends BuiltInEntityAttribute implements ITypeNameObject {
    constructor(
        typeName: string,
        public excessive: boolean) {
        super(typeName);
    }
}

export class DateTimeSearchableData extends SearchableData<string> {
    constructor() {
        super();
    }
}

export class DisabledIdentityAttribute extends BuiltInEntityAttribute implements ITypeNameObject {
    constructor() {
        super("DisabledIdentityAttribute");
    }
}

export class EntityAccessReportDefinitionConfiguration extends ReportDefinitionConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        contentType: ReportContentType,
        exportReport: boolean,
        licenseType: ApplicationCustomerConfigurationLicensingLicenseType,
        name: Optional<string>,
        timeZoneInfoId: string,
        type: ReportType,
        public direction: EntityAccessDirection,
        public entityId: string,
        public scope: EntityAccessScope) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            contentType,
            exportReport,
            licenseType,
            name,
            timeZoneInfoId,
            type);
    }
}

export class EntityAttributeMatchCondition extends EntityMatchCondition implements ITypeNameObject {
    constructor(
        public builtInAttributeTypeNames: string[],
        public customAttributeDefinitionIds: string[],
        public operator: EntityMatchConditionCollectionOperator) {
        super("EntityAttributeMatchCondition");
    }
}

export class EntityAwsAccessReportDefinitionConfiguration extends EntityAccessReportDefinitionConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        contentType: ReportContentType,
        exportReport: boolean,
        licenseType: ApplicationCustomerConfigurationLicensingLicenseType,
        name: Optional<string>,
        timeZoneInfoId: string,
        type: ReportType,
        direction: EntityAccessDirection,
        entityId: string,
        scope: EntityAccessScope,
        public filters: EntityControllerGetAwsAccessDataRequestFilters) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "EntityAwsAccessReportDefinitionConfiguration",
            contentType,
            exportReport,
            licenseType,
            name,
            timeZoneInfoId,
            type,
            direction,
            entityId,
            scope);
    }
}

export class EntityAzureAccessReportDefinitionConfiguration extends EntityAccessReportDefinitionConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        contentType: ReportContentType,
        exportReport: boolean,
        licenseType: ApplicationCustomerConfigurationLicensingLicenseType,
        name: Optional<string>,
        timeZoneInfoId: string,
        type: ReportType,
        direction: EntityAccessDirection,
        entityId: string,
        scope: EntityAccessScope,
        public filters: EntityControllerGetAzureAccessDataRequestFilters) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "EntityAzureAccessReportDefinitionConfiguration",
            contentType,
            exportReport,
            licenseType,
            name,
            timeZoneInfoId,
            type,
            direction,
            entityId,
            scope);
    }
}

export class EntityControllerGetEntityModelPageAadDefaultDirectoryEntityRequest extends EntityControllerGetEntityModelPageAadDirectoryEntityRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort) {
        super(
            filters,
            limit,
            skip,
            sort);
    }
}

export class EntityControllerGetEntityModelPageAadDefaultDirectoryEntityRequestFilters extends EntityControllerGetEntityModelPageAadDirectoryEntityRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public typeName: string) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAadDirectoryApplicationObjectRequest extends EntityControllerGetEntityModelPageAadDirectoryEntityRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort) {
        super(
            filters,
            limit,
            skip,
            sort);
    }
}

export class EntityControllerGetEntityModelPageAadDirectoryApplicationObjectRequestFilters extends EntityControllerGetEntityModelPageAadDirectoryEntityRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public directoryApplicationIdSelection: Optional<PagedItemSelection<string>>,
        public directoryApplicationOwnerPrincipalIdSelection: Optional<PagedItemSelection<string>>,
        public expirationStatusSelection: Optional<ItemSelection<AadDirectoryApplicationObjectExpirationStatus>>,
        public expirationTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAadDirectoryApplicationRequest extends EntityControllerGetEntityModelPageAadDirectoryEntityRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort) {
        super(
            filters,
            limit,
            skip,
            sort);
    }
}

export class EntityControllerGetEntityModelPageAadDirectoryApplicationRequestFilters extends EntityControllerGetEntityModelPageAadDirectoryEntityRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public expirationStatusSelection: Optional<ItemSelection<AadDirectoryApplicationObjectExpirationStatus>>,
        public ownerPrincipalIdSelection: Optional<PagedItemSelection<string>>,
        public typeSelection: Optional<ItemSelection<AadDirectoryApplicationType>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAadDirectoryApplicationSecretRequest extends EntityControllerGetEntityModelPageAadDirectoryApplicationObjectRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort) {
        super(
            filters,
            limit,
            skip,
            sort);
    }
}

export class EntityControllerGetEntityModelPageAadDirectoryApplicationSecretRequestFilters extends EntityControllerGetEntityModelPageAadDirectoryApplicationObjectRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        directoryApplicationIdSelection: Optional<PagedItemSelection<string>>,
        directoryApplicationOwnerPrincipalIdSelection: Optional<PagedItemSelection<string>>,
        expirationStatusSelection: Optional<ItemSelection<AadDirectoryApplicationObjectExpirationStatus>>,
        expirationTimeRangeSelection: Optional<TimeRangeSelection>,
        public descriptionSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            directoryApplicationIdSelection,
            directoryApplicationOwnerPrincipalIdSelection,
            expirationStatusSelection,
            expirationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAadDirectoryDirectoryRoleDefinitionRequest extends EntityControllerGetEntityModelPageAadDirectoryEntityRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort) {
        super(
            filters,
            limit,
            skip,
            sort);
    }
}

export class EntityControllerGetEntityModelPageAadDirectoryDirectoryRoleDefinitionRequestFilters extends EntityControllerGetEntityModelPageAadDirectoryEntityRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public assignedPrincipalIdSelection: Optional<PagedItemSelection<string>>,
        public severePermission: Optional<boolean>,
        public typeSelection: Optional<ItemSelection<AadDirectoryDirectoryRoleDefinitionType>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAadDirectoryDirectoryRoleManagementPolicyAssignmentRequest extends EntityControllerGetEntityModelPageAadDirectoryEntityRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort) {
        super(
            filters,
            limit,
            skip,
            sort);
    }
}

export class EntityControllerGetEntityModelPageAadDirectoryDirectoryRoleManagementPolicyAssignmentRequestFilters extends EntityControllerGetEntityModelPageAzureResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public approvalRequired: Optional<boolean>,
        public approverPrincipalIdsSelection: Optional<PagedItemSelection<string>>,
        public assignedPrincipalIdsSelection: Optional<PagedItemSelection<string>>,
        public directoryRoleDefinitionIdsSelection: Optional<PagedItemSelection<string>>,
        public mfaEnabled: Optional<boolean>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAadDirectoryGroupRequest extends EntityControllerGetEntityModelPageAadDirectoryPrincipalRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort) {
        super(
            filters,
            limit,
            skip,
            sort);
    }
}

export class EntityControllerGetEntityModelPageAadDirectoryGroupRequestFilters extends EntityControllerGetEntityModelPageAadDirectoryPrincipalRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        azureRoleDefinitionIdSelection: Optional<PagedItemSelection<string>>,
        containedAzureRoleAssignmentResourceExists: Optional<boolean>,
        directoryRoleDefinitionIdSelection: Optional<PagedItemSelection<string>>,
        public adOrigin: Optional<boolean>,
        public awsRoleIdSelection: Optional<PagedItemSelection<string>>,
        public memberPrincipalIdSelection: Optional<PagedItemSelection<string>>,
        public membershipTypeSelection: Optional<ItemSelection<AadDirectoryGroupMembershipType>>,
        public ownerPrincipalIdSelection: Optional<PagedItemSelection<string>>,
        public typeSelection: Optional<ItemSelection<AadDirectoryGroupType>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            azureRoleDefinitionIdSelection,
            containedAzureRoleAssignmentResourceExists,
            directoryRoleDefinitionIdSelection);
    }
}

export class EntityControllerGetEntityModelPageAadDirectoryIdentityRequest extends EntityControllerGetEntityModelPageAadDirectoryPrincipalRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort) {
        super(
            filters,
            limit,
            skip,
            sort);
    }
}

export class EntityControllerGetEntityModelPageAadDirectoryIdentityRequestFilters extends EntityControllerGetEntityModelPageAadDirectoryPrincipalRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        azureRoleDefinitionIdSelection: Optional<PagedItemSelection<string>>,
        containedAzureRoleAssignmentResourceExists: Optional<boolean>,
        directoryRoleDefinitionIdSelection: Optional<PagedItemSelection<string>>,
        public activityTimeRangeSelection: Optional<TimeRangeSelection>,
        public enabled: Optional<boolean>,
        public signInTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            azureRoleDefinitionIdSelection,
            containedAzureRoleAssignmentResourceExists,
            directoryRoleDefinitionIdSelection);
    }
}

export class EntityControllerGetEntityModelPageAadDirectoryServicePrincipalRequest extends EntityControllerGetEntityModelPageAadDirectoryIdentityRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        public tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort);
    }
}

export class EntityControllerGetEntityModelPageAadDirectoryServicePrincipalRequestFilters extends EntityControllerGetEntityModelPageAadDirectoryIdentityRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        azureRoleDefinitionIdSelection: Optional<PagedItemSelection<string>>,
        containedAzureRoleAssignmentResourceExists: Optional<boolean>,
        directoryRoleDefinitionIdSelection: Optional<PagedItemSelection<string>>,
        activityTimeRangeSelection: Optional<TimeRangeSelection>,
        enabled: Optional<boolean>,
        signInTimeRangeSelection: Optional<TimeRangeSelection>,
        public assignedPrincipalIdSelection: Optional<PagedItemSelection<string>>,
        public homepageRawUrlSelection: Optional<PagedItemSelection<string>>,
        public typeSelection: Optional<ItemSelection<AadDirectoryServicePrincipalType>>,
        public vendor: Optional<boolean>,
        public vendorSelection: Optional<ItemSelection<Vendor>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            azureRoleDefinitionIdSelection,
            containedAzureRoleAssignmentResourceExists,
            directoryRoleDefinitionIdSelection,
            activityTimeRangeSelection,
            enabled,
            signInTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAadDirectoryUserRequest extends EntityControllerGetEntityModelPageAadDirectoryIdentityRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort) {
        super(
            filters,
            limit,
            skip,
            sort);
    }
}

export class EntityControllerGetEntityModelPageAadDirectoryUserRequestFilters extends EntityControllerGetEntityModelPageAadDirectoryIdentityRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        azureRoleDefinitionIdSelection: Optional<PagedItemSelection<string>>,
        containedAzureRoleAssignmentResourceExists: Optional<boolean>,
        directoryRoleDefinitionIdSelection: Optional<PagedItemSelection<string>>,
        activityTimeRangeSelection: Optional<TimeRangeSelection>,
        enabled: Optional<boolean>,
        signInTimeRangeSelection: Optional<TimeRangeSelection>,
        public adOrigin: Optional<boolean>,
        public authenticationTypeSelection: Optional<ItemSelection<AadDirectoryUserAuthenticationType>>,
        public awsRoleIdSelection: Optional<PagedItemSelection<string>>,
        public groupIdSelection: Optional<PagedItemSelection<string>>,
        public locationSelection: Optional<PagedItemSelection<string>>,
        public rawMailSelection: Optional<PagedItemSelection<string>>,
        public typeSelection: Optional<ItemSelection<AadDirectoryUserType>>,
        public userPrincipalNameSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            azureRoleDefinitionIdSelection,
            containedAzureRoleAssignmentResourceExists,
            directoryRoleDefinitionIdSelection,
            activityTimeRangeSelection,
            enabled,
            signInTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsAcmCertificateRequest extends EntityControllerGetEntityModelPageAwsResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsAcmCertificateRequestFilters extends EntityControllerGetEntityModelPageAwsResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public attachedResourceExists: Optional<boolean>,
        public attachedResourceIdSelection: Optional<PagedItemSelection<string>>,
        public domainNameSelection: Optional<PagedItemSelection<string>>,
        public expirationTimeRangeSelection: Optional<TimeRangeSelection>,
        public keyTypeSelection: Optional<ItemSelection<AwsAcmCertificateKeyType>>,
        public statusSelection: Optional<ItemSelection<AwsAcmCertificateStatus>>,
        public typeSelection: Optional<ItemSelection<AwsAcmCertificateType>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsAossCollectionRequest extends EntityControllerGetEntityModelPageAwsResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public encrypted: EntityControllerGetEntityModelPageAwsResourceRequestEncrypted) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsAossCollectionRequestFilters extends EntityControllerGetEntityModelPageAwsResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsApiGatewayApiRequest extends EntityControllerGetEntityModelPageAwsResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public access: EntityControllerGetEntityModelPageAwsResourceRequestAccess) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsApiGatewayApiRequestFilters extends EntityControllerGetEntityModelPageAwsResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public endpointTypeSelection: Optional<ItemSelection<AwsApiGatewayApiEndpointType>>,
        public typeSelection: Optional<ItemSelection<AwsApiGatewayApiType>>,
        public wafWebAclIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsAthenaWorkgroupRequest extends EntityControllerGetEntityModelPageAwsResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public encrypted: EntityControllerGetEntityModelPageAwsResourceRequestEncrypted) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsAthenaWorkgroupRequestFilters extends EntityControllerGetEntityModelPageAwsResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public enabled: Optional<boolean>,
        public enforceWorkgroupConfiguration: Optional<boolean>,
        public engineVersionNameSelection: Optional<PagedItemSelection<string>>,
        public loggingEnabled: Optional<boolean>,
        public outputBucketIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsAutoScalingAutoScalingGroupRequest extends EntityControllerGetEntityModelPageAwsResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public networkAccess: EntityControllerGetEntityModelPageEntityRequestNetworkAccess,
        public networked: EntityControllerGetEntityModelPageAwsResourceRequestNetworked) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsAutoScalingAutoScalingGroupRequestFilters extends EntityControllerGetEntityModelPageAwsResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public instanceIdSelection: Optional<PagedItemSelection<string>>,
        public keyNameSelection: Optional<PagedItemSelection<string>>,
        public launchConfigurationIdSelection: Optional<PagedItemSelection<string>>,
        public launchTemplateIdSelection: Optional<PagedItemSelection<string>>,
        public loadBalancerIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsAutoScalingLaunchConfigurationRequest extends EntityControllerGetEntityModelPageAwsResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsAutoScalingLaunchConfigurationRequestFilters extends EntityControllerGetEntityModelPageAwsResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public autoScalingGroupIdSelection: Optional<PagedItemSelection<string>>,
        public vpcIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsBedrockAgentRequest extends EntityControllerGetEntityModelPageAwsResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public encrypted: EntityControllerGetEntityModelPageAwsResourceRequestEncrypted) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsBedrockAgentRequestFilters extends EntityControllerGetEntityModelPageAwsResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public roleIdSelection: Optional<PagedItemSelection<string>>,
        public statusSelection: Optional<ItemSelection<AwsBedrockAgentStatus>>,
        public updateTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsBedrockCustomModelRequest extends EntityControllerGetEntityModelPageAwsResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public encrypted: EntityControllerGetEntityModelPageAwsResourceRequestEncrypted) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsBedrockCustomModelRequestFilters extends EntityControllerGetEntityModelPageAwsResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public customizationTypeSelection: Optional<ItemSelection<AwsBedrockCustomModelCustomizationType>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsCloudFormationStackRequest extends EntityControllerGetEntityModelPageAwsResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsCloudFormationStackRequestFilters extends EntityControllerGetEntityModelPageAwsResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public driftStatusSelection: Optional<ItemSelection<AwsCloudFormationStackDriftStatus>>,
        public resourceIdSelection: Optional<PagedItemSelection<string>>,
        public statusSelection: Optional<ItemSelection<AwsCloudFormationStackStatus>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsCloudFrontDistributionRequest extends EntityControllerGetEntityModelPageAwsResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsCloudFrontDistributionRequestFilters extends EntityControllerGetEntityModelPageAwsResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public certificateIdSelection: Optional<PagedItemSelection<string>>,
        public originIdSelection: Optional<PagedItemSelection<string>>,
        public statusSelection: Optional<ItemSelection<AwsCloudFrontDistributionStatus>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsCloudTrailTrailRequest extends EntityControllerGetEntityModelPageAwsResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public encrypted: EntityControllerGetEntityModelPageAwsResourceRequestEncrypted) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsCloudTrailTrailRequestFilters extends EntityControllerGetEntityModelPageAwsResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public bucketObjectEventSelectorTypeSelection: Optional<ItemSelection<AwsCloudTrailTrailEventSelectorType>>,
        public enabled: Optional<boolean>,
        public managementEventSelectorTypeSelection: Optional<ItemSelection<AwsCloudTrailTrailEventSelectorType>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsContainerImageRequest extends EntityControllerGetEntityModelPageAwsResourceRequest implements IEntityControllerGetEntityModelPageContainerImageRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsContainerImageRequestFilters extends EntityControllerGetEntityModelPageAwsResourceRequestFilters implements IEntityControllerGetEntityModelPageContainerImageRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public containerImageCreationTimeRangeSelection: Optional<TimeRangeSelection>,
        public containerImageTagSelection: Optional<PagedItemSelection<string>>,
        public digestSelection: Optional<PagedItemSelection<string>>,
        public kubernetesWorkloadResourceIdSelection: Optional<PagedItemSelection<string>>,
        public repositoryIdSelection: Optional<PagedItemSelection<string>>,
        public scanTimeRangeSelection: Optional<TimeRangeSelection>,
        public usage: Optional<boolean>,
        public virtualMachineIdSelection: Optional<PagedItemSelection<string>>,
        public vulnerabilityRawIdSelection: Optional<PagedItemSelection<string>>,
        public vulnerabilitySeveritySelection: Optional<ItemSelection<Severity>>,
        public workloadClusterResourceIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsDefaultResourceRequest extends EntityControllerGetEntityModelPageAwsResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsDefaultResourceRequestFilters extends EntityControllerGetEntityModelPageAwsResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public typeName: string) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsDocDbClusterRequest extends EntityControllerGetEntityModelPageAwsResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public cloudWatchLogging: EntityControllerGetEntityModelPageAwsResourceRequestCloudWatchLogging,
        public encrypted: EntityControllerGetEntityModelPageAwsResourceRequestEncrypted) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsDocDbClusterRequestFilters extends EntityControllerGetEntityModelPageAwsResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public clusterTypeSelection: Optional<ItemSelection<AwsDocDbClusterModelType>>,
        public deleteProtection: Optional<ItemSelection<boolean>>,
        public engineVersionSelection: Optional<PagedItemSelection<string>>,
        public globalClusterIdSelection: Optional<PagedItemSelection<string>>,
        public instanceIdSelection: Optional<PagedItemSelection<string>>,
        public snapshotIdSelection: Optional<PagedItemSelection<string>>,
        public statusSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsDocDbClusterSnapshotRequest extends EntityControllerGetEntityModelPageAwsResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public access: EntityControllerGetEntityModelPageAwsResourceRequestAccess,
        public encrypted: EntityControllerGetEntityModelPageAwsResourceRequestEncrypted) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsDocDbClusterSnapshotRequestFilters extends EntityControllerGetEntityModelPageAwsResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public clusterIdSelection: Optional<PagedItemSelection<string>>,
        public clusterTypeSelection: Optional<ItemSelection<AwsDocDbClusterModelType>>,
        public typeSelection: Optional<ItemSelection<AwsResourceSnapshotType>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsDynamoDbTableRequest extends EntityControllerGetEntityModelPageAwsResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public access: EntityControllerGetEntityModelPageAwsResourceRequestAccess,
        public dataAnalysis: EntityControllerGetEntityModelPageEntityRequestDataAnalysis,
        public encrypted: EntityControllerGetEntityModelPageAwsResourceRequestEncrypted) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsDynamoDbTableRequestFilters extends EntityControllerGetEntityModelPageAwsResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public deleteProtection: Optional<boolean>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsEc2ResourceRequest extends EntityControllerGetEntityModelPageAwsResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsEc2ResourceRequestFilters extends EntityControllerGetEntityModelPageAwsResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public rawIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsEc2RouteTableRequest extends EntityControllerGetEntityModelPageAwsEc2ResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsEc2RouteTableRequestFilters extends EntityControllerGetEntityModelPageAwsEc2ResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        rawIdSelection: Optional<PagedItemSelection<string>>,
        public vpcIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            rawIdSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsEc2SecurityGroupRequest extends EntityControllerGetEntityModelPageAwsEc2ResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsEc2SecurityGroupRequestFilters extends EntityControllerGetEntityModelPageAwsEc2ResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        rawIdSelection: Optional<PagedItemSelection<string>>,
        public networkedResourceIdSelection: Optional<PagedItemSelection<string>>,
        public vpcIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            rawIdSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsEc2SnapshotRequest extends EntityControllerGetEntityModelPageAwsEc2ResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public access: EntityControllerGetEntityModelPageAwsResourceRequestAccess,
        public encrypted: EntityControllerGetEntityModelPageAwsResourceRequestEncrypted) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsEc2SnapshotRequestFilters extends EntityControllerGetEntityModelPageAwsEc2ResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        rawIdSelection: Optional<PagedItemSelection<string>>,
        public descriptionSelection: Optional<PagedItemSelection<string>>,
        public sourceVolumeIdSelection: Optional<PagedItemSelection<string>>,
        public sourceVolumeInstanceIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            rawIdSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsEc2SubnetRequest extends EntityControllerGetEntityModelPageAwsEc2ResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public networkAccess: EntityControllerGetEntityModelPageEntityRequestNetworkAccess) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsEc2SubnetRequestFilters extends EntityControllerGetEntityModelPageAwsEc2ResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        rawIdSelection: Optional<PagedItemSelection<string>>,
        public flowLogDestinationResourceIdsSelection: Optional<PagedItemSelection<string>>,
        public flowLogFilterSelection: Optional<ItemSelection<AwsEc2FlowLogFilter>>,
        public networkedResourceIdSelection: Optional<PagedItemSelection<string>>,
        public subnetSelection: Optional<PagedItemSelection<string>>,
        public vpcIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            rawIdSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsEc2VolumeRequest extends EntityControllerGetEntityModelPageAwsEc2ResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public encrypted: EntityControllerGetEntityModelPageAwsResourceRequestEncrypted) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsEc2VolumeRequestFilters extends EntityControllerGetEntityModelPageAwsEc2ResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        rawIdSelection: Optional<PagedItemSelection<string>>,
        public instanceIdSelection: Optional<PagedItemSelection<string>>,
        public instanceTerminationDeletion: Optional<boolean>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            rawIdSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsEc2VpcBlockPublicAccessPolicyRequest extends EntityControllerGetEntityModelPageAwsResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsEc2VpcBlockPublicAccessPolicyRequestFilters extends EntityControllerGetEntityModelPageAwsResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public blockNetworkDirectionSelection: Optional<ItemSelection<AwsEc2VpcBlockPublicAccessPolicyNetworkDirection>>,
        public enabled: Optional<boolean>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsEc2VpcEndpointRequest extends EntityControllerGetEntityModelPageAwsEc2ResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public networked: EntityControllerGetEntityModelPageAwsResourceRequestNetworked) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsEc2VpcEndpointRequestFilters extends EntityControllerGetEntityModelPageAwsEc2ResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        rawIdSelection: Optional<PagedItemSelection<string>>,
        public routeTableIdSelection: Optional<PagedItemSelection<string>>,
        public serviceNameSelection: Optional<PagedItemSelection<string>>,
        public statusSelection: Optional<ItemSelection<AwsEc2VpcEndpointStatus>>,
        public subnetIdSelection: Optional<PagedItemSelection<string>>,
        public typeSelection: Optional<ItemSelection<AwsEc2VpcEndpointType>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            rawIdSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsEc2VpcPeeringConnectionRequest extends EntityControllerGetEntityModelPageAwsEc2ResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsEc2VpcPeeringConnectionRequestFilters extends EntityControllerGetEntityModelPageAwsEc2ResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        rawIdSelection: Optional<PagedItemSelection<string>>,
        public accepterVpcIdSelection: Optional<PagedItemSelection<string>>,
        public crossTenant: Optional<boolean>,
        public requesterVpcIdSelection: Optional<PagedItemSelection<string>>,
        public routeTableIdSelection: Optional<PagedItemSelection<string>>,
        public routeTableSubnetIdSelection: Optional<PagedItemSelection<string>>,
        public statusSelection: Optional<ItemSelection<AwsEc2VpcPeeringConnectionStatus>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            rawIdSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsEc2VpcRequest extends EntityControllerGetEntityModelPageAwsEc2ResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsEc2VpcRequestFilters extends EntityControllerGetEntityModelPageAwsEc2ResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        rawIdSelection: Optional<PagedItemSelection<string>>,
        public flowLogDestinationResourceIdSelection: Optional<PagedItemSelection<string>>,
        public flowLogFilterSelection: Optional<ItemSelection<AwsEc2FlowLogFilter>>,
        public networkedResourceIdSelection: Optional<PagedItemSelection<string>>,
        public subnetSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            rawIdSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsEcrRepositoryRequest extends EntityControllerGetEntityModelPageAwsResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public access: EntityControllerGetEntityModelPageAwsResourceRequestAccess,
        public encrypted: EntityControllerGetEntityModelPageAwsResourceRequestEncrypted) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsEcrRepositoryRequestFilters extends EntityControllerGetEntityModelPageAwsResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public containerImageResourceIdSelection: Optional<PagedItemSelection<string>>,
        public containerImageVulnerabilityIdSelection: Optional<PagedItemSelection<string>>,
        public containerImageVulnerabilitySeveritySelection: Optional<ItemSelection<Severity>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsEcsServiceRequest extends EntityControllerGetEntityModelPageAwsResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public networkAccess: EntityControllerGetEntityModelPageEntityRequestNetworkAccess,
        public networked: EntityControllerGetEntityModelPageAwsResourceRequestNetworked) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsEcsServiceRequestFilters extends EntityControllerGetEntityModelPageAwsResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public containerImageIdSelection: Optional<PagedItemSelection<string>>,
        public containerImageVulnerabilityIdSelection: Optional<PagedItemSelection<string>>,
        public containerImageVulnerabilitySeveritySelection: Optional<ItemSelection<Severity>>,
        public launchTypeSelection: Optional<ItemSelection<AwsEcsServiceLaunchType>>,
        public taskDefinitionIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsEcsTaskDefinitionRequest extends EntityControllerGetEntityModelPageAwsResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsEcsTaskDefinitionRequestFilters extends EntityControllerGetEntityModelPageAwsResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public containerImageIdSelection: Optional<PagedItemSelection<string>>,
        public containerImageVulnerabilityIdSelection: Optional<PagedItemSelection<string>>,
        public containerImageVulnerabilitySeveritySelection: Optional<ItemSelection<Severity>>,
        public statusSelection: Optional<ItemSelection<AwsEcsTaskDefinitionStatus>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsEksClusterNodeGroupRequest extends EntityControllerGetEntityModelPageAwsResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public networked: EntityControllerGetEntityModelPageAwsResourceRequestNetworked) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsEksClusterNodeGroupRequestFilters extends EntityControllerGetEntityModelPageAwsResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public autoScalingGroupIdSelection: Optional<PagedItemSelection<string>>,
        public clusterIdSelection: Optional<PagedItemSelection<string>>,
        public statusSelection: Optional<ItemSelection<AwsEksClusterNodeGroupStatus>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsEksClusterRequest extends EntityControllerGetEntityModelPageAwsResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public encrypted: EntityControllerGetEntityModelPageAwsResourceRequestEncrypted,
        public networked: EntityControllerGetEntityModelPageAwsResourceRequestNetworked) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsEksClusterRequestFilters extends EntityControllerGetEntityModelPageAwsResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public apiConnectivityStatusSelection: Optional<ItemSelection<SystemKubernetesClusterApiConnectivityStatus>>,
        public resourceUpdateTimeSelection: Optional<TimeRangeSelection>,
        public statusSelection: Optional<ItemSelection<AwsEksClusterStatus>>,
        public versionSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsElastiCacheCacheRequest extends EntityControllerGetEntityModelPageAwsResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public encrypted: EntityControllerGetEntityModelPageAwsResourceRequestEncrypted,
        public networked: EntityControllerGetEntityModelPageAwsResourceRequestNetworked) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsElastiCacheCacheRequestFilters extends EntityControllerGetEntityModelPageAwsResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public autoFailoverSelection: Optional<ItemSelection<AwsElastiCacheCacheAutoFailover>>,
        public automaticBackupsSelection: Optional<boolean>,
        public autoUpgradeMinorVersionsSelection: Optional<ItemSelection<boolean>>,
        public clusterModeSelection: Optional<ItemSelection<AwsElastiCacheReplicationGroupClusterMode>>,
        public deploymentTypeSelection: Optional<ItemSelection<AwsElastiCacheCacheDeploymentType>>,
        public engineVersionSelection: Optional<PagedItemSelection<string>>,
        public nodeTypeSelection: Optional<PagedItemSelection<string>>,
        public statusValueSelection: Optional<ItemSelection<AwsElastiCacheCacheStatus>>,
        public subnetGroupNameSelection: Optional<PagedItemSelection<string>>,
        public transitEncryptionEnabled: Optional<boolean>,
        public transitEncryptionModeSelection: Optional<ItemSelection<AwsElastiCacheReplicationGroupTransitEncryptionMode>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsElasticBeanstalkEnvironmentRequest extends EntityControllerGetEntityModelPageAwsResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public cloudWatchLogging: EntityControllerGetEntityModelPageAwsResourceRequestCloudWatchLogging) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsElasticBeanstalkEnvironmentRequestFilters extends EntityControllerGetEntityModelPageAwsResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsElasticFileSystemFileSystemRequest extends EntityControllerGetEntityModelPageAwsResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public encrypted: EntityControllerGetEntityModelPageAwsResourceRequestEncrypted) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsElasticFileSystemFileSystemRequestFilters extends EntityControllerGetEntityModelPageAwsResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public rawIdSelection: Optional<ItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsElbLoadBalancerRequest extends EntityControllerGetEntityModelPageAwsResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public networkAccess: EntityControllerGetEntityModelPageEntityRequestNetworkAccess,
        public networked: EntityControllerGetEntityModelPageAwsResourceRequestNetworked) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsElbLoadBalancerRequestFilters extends EntityControllerGetEntityModelPageAwsResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public external: Optional<boolean>,
        public typeSelection: Optional<ItemSelection<AwsElbLoadBalancerModelType>>,
        public webAclIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsEmrClusterRequest extends EntityControllerGetEntityModelPageAwsResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public networkAccess: EntityControllerGetEntityModelPageEntityRequestNetworkAccess,
        public networked: EntityControllerGetEntityModelPageAwsResourceRequestNetworked) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsEmrClusterRequestFilters extends EntityControllerGetEntityModelPageAwsResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public instanceIdSelection: Optional<PagedItemSelection<string>>,
        public statusSelection: Optional<ItemSelection<AwsEmrClusterStatus>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsEsDomainRequest extends EntityControllerGetEntityModelPageAwsResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public access: EntityControllerGetEntityModelPageAwsResourceRequestAccess,
        public cloudWatchLogging: EntityControllerGetEntityModelPageAwsResourceRequestCloudWatchLogging,
        public encrypted: EntityControllerGetEntityModelPageAwsResourceRequestEncrypted,
        public networked: EntityControllerGetEntityModelPageAwsResourceRequestNetworked) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsEsDomainRequestFilters extends EntityControllerGetEntityModelPageAwsResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public engineTypeSelection: Optional<ItemSelection<AwsEsDomainEngineType>>,
        public engineVersionSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsFirehoseDeliveryStreamRequest extends EntityControllerGetEntityModelPageAwsResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public encrypted: EntityControllerGetEntityModelPageAwsResourceRequestEncrypted) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsFirehoseDeliveryStreamRequestFilters extends EntityControllerGetEntityModelPageAwsResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public statusSelection: Optional<ItemSelection<AwsFirehoseDeliveryStreamStatus>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsIamPrincipalPolicyRequest extends EntityControllerGetEntityModelPageAwsResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsIamPrincipalPolicyRequestFilters extends EntityControllerGetEntityModelPageAwsResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public attachedPrincipalIdSelection: Optional<PagedItemSelection<string>>,
        public managementSelection: Optional<ItemSelection<AwsIamPrincipalPolicyModelManagement>>,
        public usageTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsIamPrincipalRequest extends EntityControllerGetEntityModelPageAwsResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsIamPrincipalRequestFilters extends EntityControllerGetEntityModelPageAwsResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public policyIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsIamRootUserRequest extends EntityControllerGetEntityModelPageAwsResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsIamRootUserRequestFilters extends EntityControllerGetEntityModelPageAwsResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public mfaEnabled: Optional<boolean>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsIamServerCertificateRequest extends EntityControllerGetEntityModelPageAwsResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsIamServerCertificateRequestFilters extends EntityControllerGetEntityModelPageAwsResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public expirationTimeRangeSelection: Optional<TimeRangeSelection>,
        public uniqueIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsKinesisStreamRequest extends EntityControllerGetEntityModelPageAwsResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public encrypted: EntityControllerGetEntityModelPageAwsResourceRequestEncrypted) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsKinesisStreamRequestFilters extends EntityControllerGetEntityModelPageAwsResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsKmsKeyRequest extends EntityControllerGetEntityModelPageAwsResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public access: EntityControllerGetEntityModelPageAwsResourceRequestAccess) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsKmsKeyRequestFilters extends EntityControllerGetEntityModelPageAwsResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public awsManaged: Optional<boolean>,
        public enabled: Optional<boolean>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsKubernetesResourceRequest extends EntityControllerGetEntityModelPageAwsResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public kubernetesResource: EntityControllerGetEntityModelPageEntityRequestKubernetesResource) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsKubernetesResourceRequestFilters extends EntityControllerGetEntityModelPageAwsResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsKubernetesUserRequest extends EntityControllerGetEntityModelPageAwsKubernetesResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        kubernetesResource: EntityControllerGetEntityModelPageEntityRequestKubernetesResource,
        public kubernetesPrincipal: EntityControllerGetEntityModelPageEntityRequestKubernetesPrincipal) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag,
            kubernetesResource);
    }
}

export class EntityControllerGetEntityModelPageAwsKubernetesUserRequestFilters extends EntityControllerGetEntityModelPageAwsKubernetesResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsLambdaFunctionConfigurationRequest extends EntityControllerGetEntityModelPageAwsResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public access: EntityControllerGetEntityModelPageAwsResourceRequestAccess,
        public encrypted: EntityControllerGetEntityModelPageAwsResourceRequestEncrypted,
        public networked: EntityControllerGetEntityModelPageAwsResourceRequestNetworked) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsLambdaFunctionConfigurationRequestFilters extends EntityControllerGetEntityModelPageAwsResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public packageTypeSelection: Optional<ItemSelection<AwsLambdaFunctionConfigurationPackageType>>,
        public publicAccessTypeSelection: Optional<ItemSelection<AwsLambdaFunctionConfigurationModelAccessType>>,
        public roleIdSelection: Optional<PagedItemSelection<string>>,
        public runtimeSelection: Optional<ItemSelection<string>>,
        public triggerResourceIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsOrganizationsPolicyRequest extends EntityControllerGetEntityModelPageAwsResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsOrganizationsPolicyRequestFilters extends EntityControllerGetEntityModelPageAwsResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public attachedAccountIdSelection: Optional<PagedItemSelection<string>>,
        public awsManaged: Optional<boolean>,
        public organizationRawIdSelection: Optional<PagedItemSelection<string>>,
        public typeSelection: Optional<ItemSelection<AwsOrganizationsPolicyType>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsRdsClusterResourceRequest extends EntityControllerGetEntityModelPageAwsResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public cloudWatchLogging: EntityControllerGetEntityModelPageAwsResourceRequestCloudWatchLogging,
        public encrypted: EntityControllerGetEntityModelPageAwsResourceRequestEncrypted) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsRdsClusterResourceRequestFilters extends EntityControllerGetEntityModelPageAwsResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public deleteProtection: Optional<boolean>,
        public engineVersionSelection: Optional<PagedItemSelection<string>>,
        public globalClusterIdSelection: Optional<PagedItemSelection<string>>,
        public instanceIdSelection: Optional<PagedItemSelection<string>>,
        public snapshotIdSelection: Optional<PagedItemSelection<string>>,
        public statusSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsRdsDatabaseInstanceResourceRequest extends EntityControllerGetEntityModelPageAwsResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public cloudWatchLogging: EntityControllerGetEntityModelPageAwsResourceRequestCloudWatchLogging,
        public dataAnalysis: EntityControllerGetEntityModelPageEntityRequestDataAnalysis,
        public encrypted: EntityControllerGetEntityModelPageAwsResourceRequestEncrypted,
        public networkAccess: EntityControllerGetEntityModelPageEntityRequestNetworkAccess,
        public networked: EntityControllerGetEntityModelPageAwsResourceRequestNetworked) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsRdsDatabaseInstanceResourceRequestFilters extends EntityControllerGetEntityModelPageAwsResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public clusterIdSelection: Optional<PagedItemSelection<string>>,
        public deleteProtection: Optional<boolean>,
        public engineVersionSelection: Optional<PagedItemSelection<string>>,
        public instanceRawTypeSelection: Optional<PagedItemSelection<string>>,
        public minorVersionUpgradeEnabled: Optional<boolean>,
        public statusSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsRdsDocDbDatabaseInstanceRequest extends EntityControllerGetEntityModelPageAwsRdsDatabaseInstanceResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        cloudWatchLogging: EntityControllerGetEntityModelPageAwsResourceRequestCloudWatchLogging,
        dataAnalysis: EntityControllerGetEntityModelPageEntityRequestDataAnalysis,
        encrypted: EntityControllerGetEntityModelPageAwsResourceRequestEncrypted,
        networkAccess: EntityControllerGetEntityModelPageEntityRequestNetworkAccess,
        networked: EntityControllerGetEntityModelPageAwsResourceRequestNetworked) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag,
            cloudWatchLogging,
            dataAnalysis,
            encrypted,
            networkAccess,
            networked);
    }
}

export class EntityControllerGetEntityModelPageAwsRdsDocDbDatabaseInstanceRequestFilters extends EntityControllerGetEntityModelPageAwsRdsDatabaseInstanceResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        clusterIdSelection: Optional<PagedItemSelection<string>>,
        deleteProtection: Optional<boolean>,
        engineVersionSelection: Optional<PagedItemSelection<string>>,
        instanceRawTypeSelection: Optional<PagedItemSelection<string>>,
        minorVersionUpgradeEnabled: Optional<boolean>,
        statusSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            clusterIdSelection,
            deleteProtection,
            engineVersionSelection,
            instanceRawTypeSelection,
            minorVersionUpgradeEnabled,
            statusSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsRdsGlobalClusterResourceRequest extends EntityControllerGetEntityModelPageAwsResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public encrypted: EntityControllerGetEntityModelPageAwsResourceRequestEncrypted) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsRdsGlobalClusterResourceRequestFilters extends EntityControllerGetEntityModelPageAwsResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public clusterIdSelection: Optional<PagedItemSelection<string>>,
        public deleteProtection: Optional<boolean>,
        public engineVersionSelection: Optional<PagedItemSelection<string>>,
        public statusSelection: Optional<ItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsRdsNeptuneClusterRequest extends EntityControllerGetEntityModelPageAwsRdsClusterResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        cloudWatchLogging: EntityControllerGetEntityModelPageAwsResourceRequestCloudWatchLogging,
        encrypted: EntityControllerGetEntityModelPageAwsResourceRequestEncrypted,
        public networkAccess: EntityControllerGetEntityModelPageEntityRequestNetworkAccess,
        public networked: EntityControllerGetEntityModelPageAwsResourceRequestNetworked) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag,
            cloudWatchLogging,
            encrypted);
    }
}

export class EntityControllerGetEntityModelPageAwsRdsNeptuneClusterRequestFilters extends EntityControllerGetEntityModelPageAwsRdsClusterResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        deleteProtection: Optional<boolean>,
        engineVersionSelection: Optional<PagedItemSelection<string>>,
        globalClusterIdSelection: Optional<PagedItemSelection<string>>,
        instanceIdSelection: Optional<PagedItemSelection<string>>,
        snapshotIdSelection: Optional<PagedItemSelection<string>>,
        statusSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            deleteProtection,
            engineVersionSelection,
            globalClusterIdSelection,
            instanceIdSelection,
            snapshotIdSelection,
            statusSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsRdsNeptuneClusterSnapshotRequest extends EntityControllerGetEntityModelPageAwsResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public access: EntityControllerGetEntityModelPageAwsResourceRequestAccess,
        public encrypted: EntityControllerGetEntityModelPageAwsResourceRequestEncrypted) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsRdsNeptuneClusterSnapshotRequestFilters extends EntityControllerGetEntityModelPageAwsResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public clusterIdSelection: Optional<PagedItemSelection<string>>,
        public typeSelection: Optional<ItemSelection<AwsResourceSnapshotType>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsRdsNeptuneDatabaseInstanceRequest extends EntityControllerGetEntityModelPageAwsRdsDatabaseInstanceResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        cloudWatchLogging: EntityControllerGetEntityModelPageAwsResourceRequestCloudWatchLogging,
        dataAnalysis: EntityControllerGetEntityModelPageEntityRequestDataAnalysis,
        encrypted: EntityControllerGetEntityModelPageAwsResourceRequestEncrypted,
        networkAccess: EntityControllerGetEntityModelPageEntityRequestNetworkAccess,
        networked: EntityControllerGetEntityModelPageAwsResourceRequestNetworked) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag,
            cloudWatchLogging,
            dataAnalysis,
            encrypted,
            networkAccess,
            networked);
    }
}

export class EntityControllerGetEntityModelPageAwsRdsNeptuneDatabaseInstanceRequestFilters extends EntityControllerGetEntityModelPageAwsRdsDatabaseInstanceResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        clusterIdSelection: Optional<PagedItemSelection<string>>,
        deleteProtection: Optional<boolean>,
        engineVersionSelection: Optional<PagedItemSelection<string>>,
        instanceRawTypeSelection: Optional<PagedItemSelection<string>>,
        minorVersionUpgradeEnabled: Optional<boolean>,
        statusSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            clusterIdSelection,
            deleteProtection,
            engineVersionSelection,
            instanceRawTypeSelection,
            minorVersionUpgradeEnabled,
            statusSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsRdsNeptuneGlobalClusterRequest extends EntityControllerGetEntityModelPageAwsRdsGlobalClusterResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        encrypted: EntityControllerGetEntityModelPageAwsResourceRequestEncrypted) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag,
            encrypted);
    }
}

export class EntityControllerGetEntityModelPageAwsRdsNeptuneGlobalClusterRequestFilters extends EntityControllerGetEntityModelPageAwsRdsGlobalClusterResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        clusterIdSelection: Optional<PagedItemSelection<string>>,
        deleteProtection: Optional<boolean>,
        engineVersionSelection: Optional<PagedItemSelection<string>>,
        statusSelection: Optional<ItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            clusterIdSelection,
            deleteProtection,
            engineVersionSelection,
            statusSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsRdsSnapshotRequest extends EntityControllerGetEntityModelPageAwsResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public access: EntityControllerGetEntityModelPageAwsResourceRequestAccess,
        public encrypted: EntityControllerGetEntityModelPageAwsResourceRequestEncrypted) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsRdsSnapshotRequestFilters extends EntityControllerGetEntityModelPageAwsResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public sourceResourceIdSelection: Optional<PagedItemSelection<string>>,
        public typeSelection: Optional<ItemSelection<AwsResourceSnapshotType>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsRedshiftClusterRequest extends EntityControllerGetEntityModelPageAwsResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public encrypted: EntityControllerGetEntityModelPageAwsResourceRequestEncrypted,
        public networkAccess: EntityControllerGetEntityModelPageEntityRequestNetworkAccess,
        public networked: EntityControllerGetEntityModelPageAwsResourceRequestNetworked) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsRedshiftClusterRequestFilters extends EntityControllerGetEntityModelPageAwsResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsRedshiftNamespaceRequest extends EntityControllerGetEntityModelPageAwsResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public encrypted: EntityControllerGetEntityModelPageAwsResourceRequestEncrypted,
        public networked: EntityControllerGetEntityModelPageAwsResourceRequestNetworked) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsRedshiftNamespaceRequestFilters extends EntityControllerGetEntityModelPageAwsResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsSsoGroupRequest extends EntityControllerGetEntityModelPageAwsSsoPrincipalRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsSsoGroupRequestFilters extends EntityControllerGetEntityModelPageAwsSsoPrincipalRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        roleIdSelection: Optional<PagedItemSelection<string>>,
        roleTenantIds: Optional<ItemSelection<string>>,
        public userIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            roleIdSelection,
            roleTenantIds);
    }
}

export class EntityControllerGetEntityModelPageAzureAuthorizationRoleAssignmentResourceRequest extends EntityControllerGetEntityModelPageAzureResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAzureAuthorizationRoleAssignmentResourceRequestFilters extends EntityControllerGetEntityModelPageAzureResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public containingRoleAssignmentResourceExists: Optional<boolean>,
        public endTimeRangeSelection: Optional<TimeRangeSelection>,
        public principalIdSelection: Optional<PagedItemSelection<string>>,
        public roleDefinitionIdSelection: Optional<PagedItemSelection<string>>,
        public roleManagementPolicyAssignmentSelection: Optional<PagedItemSelection<string>>,
        public scopeEntityIdSelection: Optional<PagedItemSelection<string>>,
        public scopeEntityTypeNameSelection: Optional<ItemSelection<string>>,
        public statusSelection: Optional<ItemSelection<AzureAuthorizationRoleAssignmentResourceStatus>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAzureAuthorizationRoleDefinitionRequest extends EntityControllerGetEntityModelPageAzureResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAzureAuthorizationRoleDefinitionRequestFilters extends EntityControllerGetEntityModelPageAzureResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public typeSelection: Optional<ItemSelection<AzureAuthorizationRoleDefinitionType>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAzureAuthorizationRoleManagementPolicyAssignmentRequest extends EntityControllerGetEntityModelPageAzureResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAzureAuthorizationRoleManagementPolicyAssignmentRequestFilters extends EntityControllerGetEntityModelPageAzureResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public approvalRequired: Optional<boolean>,
        public approverPrincipalIdsSelection: Optional<PagedItemSelection<string>>,
        public mfaEnabled: Optional<boolean>,
        public roleDefinitionIdsSelection: Optional<PagedItemSelection<string>>,
        public scopeResourceIdsSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAzureContainerImageRequest extends EntityControllerGetEntityModelPageAzureResourceRequest implements IEntityControllerGetEntityModelPageContainerImageRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAzureContainerImageRequestFilters extends EntityControllerGetEntityModelPageAzureResourceRequestFilters implements IEntityControllerGetEntityModelPageContainerImageRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public containerImageCreationTimeRangeSelection: Optional<TimeRangeSelection>,
        public containerImageTagSelection: Optional<PagedItemSelection<string>>,
        public digestSelection: Optional<PagedItemSelection<string>>,
        public kubernetesWorkloadResourceIdSelection: Optional<PagedItemSelection<string>>,
        public repositoryIdSelection: Optional<PagedItemSelection<string>>,
        public scanTimeRangeSelection: Optional<TimeRangeSelection>,
        public usage: Optional<boolean>,
        public virtualMachineIdSelection: Optional<PagedItemSelection<string>>,
        public vulnerabilityRawIdSelection: Optional<PagedItemSelection<string>>,
        public vulnerabilitySeveritySelection: Optional<ItemSelection<Severity>>,
        public workloadClusterResourceIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAzureDefaultResourceRequest extends EntityControllerGetEntityModelPageAzureResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAzureDefaultResourceRequestFilters extends EntityControllerGetEntityModelPageAzureResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public typeName: string) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAzureKubernetesResourceRequest extends EntityControllerGetEntityModelPageAzureResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public kubernetesResource: EntityControllerGetEntityModelPageEntityRequestKubernetesResource) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAzureKubernetesResourceRequestFilters extends EntityControllerGetEntityModelPageAzureResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAzureKubernetesUserRequest extends EntityControllerGetEntityModelPageAzureKubernetesResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        kubernetesResource: EntityControllerGetEntityModelPageEntityRequestKubernetesResource,
        public kubernetesPrincipal: EntityControllerGetEntityModelPageEntityRequestKubernetesPrincipal) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag,
            kubernetesResource);
    }
}

export class EntityControllerGetEntityModelPageAzureKubernetesUserRequestFilters extends EntityControllerGetEntityModelPageAzureKubernetesResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAzureResourceGroupResourceRequest extends EntityControllerGetEntityModelPageAzureResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAzureResourceGroupResourceRequestFilters extends EntityControllerGetEntityModelPageAzureResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public resourceGroupIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAzureSqlServerDatabaseRequest extends EntityControllerGetEntityModelPageAzureResourceGroupResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public encrypted: EntityControllerGetEntityModelPageAzureResourceRequestEncrypted) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAzureSqlServerDatabaseRequestFilters extends EntityControllerGetEntityModelPageAzureResourceGroupResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        resourceGroupIdSelection: Optional<PagedItemSelection<string>>,
        public serverIdSelection: Optional<PagedItemSelection<string>>,
        public statusSelection: Optional<ItemSelection<AzureSqlServerDatabaseStatus>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            resourceGroupIdSelection);
    }
}

export class EntityControllerGetEntityModelPageAzureSqlServerRequest extends EntityControllerGetEntityModelPageAzureResourceGroupResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public dataAnalysis: EntityControllerGetEntityModelPageEntityRequestDataAnalysis,
        public encrypted: EntityControllerGetEntityModelPageAzureResourceRequestEncrypted,
        public networkAccess: EntityControllerGetEntityModelPageEntityRequestNetworkAccess) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAzureSqlServerRequestFilters extends EntityControllerGetEntityModelPageAzureResourceGroupResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        resourceGroupIdSelection: Optional<PagedItemSelection<string>>,
        public administratorUserNameSelection: Optional<PagedItemSelection<string>>,
        public authenticationTypeSelection: Optional<ItemSelection<AzureSqlServerAuthenticationType>>,
        public databaseIdSelection: Optional<PagedItemSelection<string>>,
        public statusSelection: Optional<ItemSelection<AzureSqlServerStatus>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            resourceGroupIdSelection);
    }
}

export class EntityControllerGetEntityModelPageAzureStorageStorageAccountBlobContainerRequest extends EntityControllerGetEntityModelPageAzureResourceGroupResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public dataAnalysis: EntityControllerGetEntityModelPageEntityRequestDataAnalysis) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAzureStorageStorageAccountBlobContainerRequestFilters extends EntityControllerGetEntityModelPageAzureResourceGroupResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        resourceGroupIdSelection: Optional<PagedItemSelection<string>>,
        public publicAccessSelection: Optional<ItemSelection<AzureStorageStorageAccountBlobContainerPublicAccess>>,
        public storageAccountIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            resourceGroupIdSelection);
    }
}

export class EntityControllerGetEntityModelPageAzureStorageStorageAccountRequest extends EntityControllerGetEntityModelPageAzureResourceGroupResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public encrypted: EntityControllerGetEntityModelPageAzureResourceRequestEncrypted,
        public networkAccess: EntityControllerGetEntityModelPageEntityRequestNetworkAccess) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAzureStorageStorageAccountRequestFilters extends EntityControllerGetEntityModelPageAzureResourceGroupResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        resourceGroupIdSelection: Optional<PagedItemSelection<string>>,
        public blobContainerSoftDeleteEnabledSelection: Optional<ItemSelection<boolean>>,
        public blobSoftDeleteEnabledSelection: Optional<ItemSelection<boolean>>,
        public httpsOnly: Optional<boolean>,
        public infrastructureEncryption: Optional<boolean>,
        public sharedKeyAccess: Optional<boolean>,
        public tlsMinVersionSelection: Optional<PagedItemSelection<string>>,
        public typeSelection: Optional<ItemSelection<AzureStorageStorageAccountType>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            resourceGroupIdSelection);
    }
}

export class EntityControllerGetEntityModelPageAzureWebApplicationRequest extends EntityControllerGetEntityModelPageAzureResourceGroupResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAzureWebApplicationRequestFilters extends EntityControllerGetEntityModelPageAzureResourceGroupResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        resourceGroupIdSelection: Optional<PagedItemSelection<string>>,
        public authenticationTypeSelection: Optional<ItemSelection<AzureWebApplicationAuthenticationType>>,
        public httpsOnly: Optional<boolean>,
        public statusSelection: Optional<ItemSelection<AzureWebApplicationStatus>>,
        public tlsMinVersionSelection: Optional<PagedItemSelection<string>>,
        public typeSelection: Optional<ItemSelection<AzureWebApplicationType>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            resourceGroupIdSelection);
    }
}

export class EntityControllerGetEntityModelPageGciDirectoryGroupRequest extends EntityControllerGetEntityModelPageGciDirectoryPrincipalRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort) {
        super(
            filters,
            limit,
            skip,
            sort);
    }
}

export class EntityControllerGetEntityModelPageGciDirectoryGroupRequestFilters extends EntityControllerGetEntityModelPageGciDirectoryPrincipalRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        containedRoleBindingExists: Optional<boolean>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        gcpRoleIdSelection: Optional<PagedItemSelection<string>>,
        mailSelection: Optional<PagedItemSelection<string>>,
        public memberPrincipalIdSelection: Optional<PagedItemSelection<string>>,
        public typeSelection: Optional<ItemSelection<GciDirectoryGroupType>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            containedRoleBindingExists,
            creationTimeRangeSelection,
            gcpRoleIdSelection,
            mailSelection);
    }
}

export class EntityControllerGetEntityModelPageGciDirectoryPartialGroupRequest extends EntityControllerGetEntityModelPageGciDirectoryPartialPrincipalRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort) {
        super(
            filters,
            limit,
            skip,
            sort);
    }
}

export class EntityControllerGetEntityModelPageGciDirectoryPartialGroupRequestFilters extends EntityControllerGetEntityModelPageGciDirectoryPartialPrincipalRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        gcpContainedRoleBindingExists: Optional<boolean>,
        gcpRoleIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            gcpContainedRoleBindingExists,
            gcpRoleIdSelection);
    }
}

export class EntityControllerGetEntityModelPageGcpApiKeysKeyRequest extends EntityControllerGetEntityModelPageGcpScopeResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageGcpApiKeysKeyRequestFilters extends EntityControllerGetEntityModelPageGcpScopeResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        updateTimeRangeSelection: Optional<TimeRangeSelection>,
        accessLevelSelection: Optional<ItemSelection<GcpScopeResourceAccessLevel>>,
        rawIdSelection: Optional<PagedItemSelection<string>>,
        public clientRawIdentifierSelection: Optional<PagedItemSelection<string>>,
        public serviceHostNamesSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            updateTimeRangeSelection,
            accessLevelSelection,
            rawIdSelection);
    }
}

export class EntityControllerGetEntityModelPageGcpAppEngineApplicationRequest extends EntityControllerGetEntityModelPageGcpScopeResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public serviceAccount: EntityControllerGetEntityModelPageGcpScopeResourceRequestServiceAccount) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageGcpAppEngineApplicationRequestFilters extends EntityControllerGetEntityModelPageGcpScopeResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        updateTimeRangeSelection: Optional<TimeRangeSelection>,
        accessLevelSelection: Optional<ItemSelection<GcpScopeResourceAccessLevel>>,
        rawIdSelection: Optional<PagedItemSelection<string>>,
        public serviceIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            updateTimeRangeSelection,
            accessLevelSelection,
            rawIdSelection);
    }
}

export class EntityControllerGetEntityModelPageGcpAppEngineApplicationServiceRequest extends EntityControllerGetEntityModelPageGcpScopeResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public serviceAccount: EntityControllerGetEntityModelPageGcpScopeResourceRequestServiceAccount) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageGcpAppEngineApplicationServiceRequestFilters extends EntityControllerGetEntityModelPageGcpScopeResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        updateTimeRangeSelection: Optional<TimeRangeSelection>,
        accessLevelSelection: Optional<ItemSelection<GcpScopeResourceAccessLevel>>,
        rawIdSelection: Optional<PagedItemSelection<string>>,
        public applicationIdSelection: Optional<PagedItemSelection<string>>,
        public containerImageIdSelection: Optional<PagedItemSelection<string>>,
        public containerImageVulnerabilityIdSelection: Optional<PagedItemSelection<string>>,
        public containerImageVulnerabilitySeveritySelection: Optional<ItemSelection<Severity>>,
        public revisionMaxCreationTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            updateTimeRangeSelection,
            accessLevelSelection,
            rawIdSelection);
    }
}

export class EntityControllerGetEntityModelPageGcpArtifactContainerImageRepositoryRequest extends EntityControllerGetEntityModelPageGcpEntityRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort) {
        super(
            filters,
            limit,
            skip,
            sort);
    }
}

export class EntityControllerGetEntityModelPageGcpArtifactContainerImageRepositoryRequestFilters extends EntityControllerGetEntityModelPageGcpEntityRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public containerImageResourceIdSelection: Optional<PagedItemSelection<string>>,
        public containerImageVulnerabilityIdSelection: Optional<PagedItemSelection<string>>,
        public containerImageVulnerabilitySeveritySelection: Optional<ItemSelection<Severity>>,
        public registryIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageGcpArtifactPackageRepositoryRequest extends EntityControllerGetEntityModelPageGcpEntityRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort) {
        super(
            filters,
            limit,
            skip,
            sort);
    }
}

export class EntityControllerGetEntityModelPageGcpArtifactPackageRepositoryRequestFilters extends EntityControllerGetEntityModelPageGcpEntityRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public registryIdSelection: Optional<PagedItemSelection<string>>,
        public registryTypeSelection: Optional<ItemSelection<GcpArtifactRegistryType>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageGcpArtifactRegistryRequest extends EntityControllerGetEntityModelPageGcpScopeResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public encrypted: EntityControllerGetEntityModelPageGcpScopeResourceRequestEncrypted) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageGcpArtifactRegistryRequestFilters extends EntityControllerGetEntityModelPageGcpScopeResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        updateTimeRangeSelection: Optional<TimeRangeSelection>,
        accessLevelSelection: Optional<ItemSelection<GcpScopeResourceAccessLevel>>,
        rawIdSelection: Optional<PagedItemSelection<string>>,
        public repositoryIdSelection: Optional<PagedItemSelection<string>>,
        public typeSelection: Optional<ItemSelection<GcpArtifactRegistryType>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            updateTimeRangeSelection,
            accessLevelSelection,
            rawIdSelection);
    }
}

export class EntityControllerGetEntityModelPageGcpBigQueryDatasetRequest extends EntityControllerGetEntityModelPageGcpScopeResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public dataAnalysis: EntityControllerGetEntityModelPageEntityRequestDataAnalysis,
        public encrypted: EntityControllerGetEntityModelPageGcpScopeResourceRequestEncrypted) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageGcpBigQueryDatasetRequestFilters extends EntityControllerGetEntityModelPageGcpScopeResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        updateTimeRangeSelection: Optional<TimeRangeSelection>,
        accessLevelSelection: Optional<ItemSelection<GcpScopeResourceAccessLevel>>,
        rawIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            updateTimeRangeSelection,
            accessLevelSelection,
            rawIdSelection);
    }
}

export class EntityControllerGetEntityModelPageGcpBigQueryDatasetTableRequest extends EntityControllerGetEntityModelPageGcpScopeResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public encrypted: EntityControllerGetEntityModelPageGcpScopeResourceRequestEncrypted) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageGcpBigQueryDatasetTableRequestFilters extends EntityControllerGetEntityModelPageGcpScopeResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        updateTimeRangeSelection: Optional<TimeRangeSelection>,
        accessLevelSelection: Optional<ItemSelection<GcpScopeResourceAccessLevel>>,
        rawIdSelection: Optional<PagedItemSelection<string>>,
        public datasetIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            updateTimeRangeSelection,
            accessLevelSelection,
            rawIdSelection);
    }
}

export class EntityControllerGetEntityModelPageGcpCloudBuildBuildTriggerRequest extends EntityControllerGetEntityModelPageGcpScopeResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public serviceAccount: EntityControllerGetEntityModelPageGcpScopeResourceRequestServiceAccount) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageGcpCloudBuildBuildTriggerRequestFilters extends EntityControllerGetEntityModelPageGcpScopeResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        updateTimeRangeSelection: Optional<TimeRangeSelection>,
        accessLevelSelection: Optional<ItemSelection<GcpScopeResourceAccessLevel>>,
        rawIdSelection: Optional<PagedItemSelection<string>>,
        public enabled: Optional<boolean>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            updateTimeRangeSelection,
            accessLevelSelection,
            rawIdSelection);
    }
}

export class EntityControllerGetEntityModelPageGcpCloudRunServiceRequest extends EntityControllerGetEntityModelPageGcpScopeResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public serviceAccount: EntityControllerGetEntityModelPageGcpScopeResourceRequestServiceAccount) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageGcpCloudRunServiceRequestFilters extends EntityControllerGetEntityModelPageGcpScopeResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        updateTimeRangeSelection: Optional<TimeRangeSelection>,
        accessLevelSelection: Optional<ItemSelection<GcpScopeResourceAccessLevel>>,
        rawIdSelection: Optional<PagedItemSelection<string>>,
        public containerImageIdSelection: Optional<PagedItemSelection<string>>,
        public containerImageVulnerabilityIdSelection: Optional<PagedItemSelection<string>>,
        public containerImageVulnerabilitySeveritySelection: Optional<ItemSelection<Severity>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            updateTimeRangeSelection,
            accessLevelSelection,
            rawIdSelection);
    }
}

export class EntityControllerGetEntityModelPageGcpComputeDiskRequest extends EntityControllerGetEntityModelPageGcpScopeResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public encrypted: EntityControllerGetEntityModelPageGcpScopeResourceRequestEncrypted) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageGcpComputeDiskRequestFilters extends EntityControllerGetEntityModelPageGcpScopeResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        updateTimeRangeSelection: Optional<TimeRangeSelection>,
        accessLevelSelection: Optional<ItemSelection<GcpScopeResourceAccessLevel>>,
        rawIdSelection: Optional<PagedItemSelection<string>>,
        public instanceIdSelection: Optional<PagedItemSelection<string>>,
        public sourceIdSelection: Optional<PagedItemSelection<string>>,
        public statusSelection: Optional<ItemSelection<GcpComputeDiskStatus>>,
        public typeSelection: Optional<ItemSelection<GcpComputeDiskType>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            updateTimeRangeSelection,
            accessLevelSelection,
            rawIdSelection);
    }
}

export class EntityControllerGetEntityModelPageGcpComputeFirewallPolicyRequest extends EntityControllerGetEntityModelPageGcpScopeResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageGcpComputeFirewallPolicyRequestFilters extends EntityControllerGetEntityModelPageGcpScopeResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        updateTimeRangeSelection: Optional<TimeRangeSelection>,
        accessLevelSelection: Optional<ItemSelection<GcpScopeResourceAccessLevel>>,
        rawIdSelection: Optional<PagedItemSelection<string>>,
        public attachedResourceIdSelection: Optional<PagedItemSelection<string>>,
        public scopeSelection: Optional<ItemSelection<GcpComputeFirewallPolicyScope>>,
        public vpcIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            updateTimeRangeSelection,
            accessLevelSelection,
            rawIdSelection);
    }
}

export class EntityControllerGetEntityModelPageGcpComputeImageRequest extends EntityControllerGetEntityModelPageGcpScopeResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public encrypted: EntityControllerGetEntityModelPageGcpScopeResourceRequestEncrypted) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageGcpComputeImageRequestFilters extends EntityControllerGetEntityModelPageGcpScopeResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        updateTimeRangeSelection: Optional<TimeRangeSelection>,
        accessLevelSelection: Optional<ItemSelection<GcpScopeResourceAccessLevel>>,
        rawIdSelection: Optional<PagedItemSelection<string>>,
        public familyTagSelection: Optional<PagedItemSelection<string>>,
        public instanceIdSelection: Optional<PagedItemSelection<string>>,
        public sourceIdSelection: Optional<PagedItemSelection<string>>,
        public statusSelection: Optional<ItemSelection<GcpComputeImageStatus>>,
        public typeSelection: Optional<ItemSelection<GcpComputeImageType>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            updateTimeRangeSelection,
            accessLevelSelection,
            rawIdSelection);
    }
}

export class EntityControllerGetEntityModelPageGcpComputeInstanceGroupRequest extends EntityControllerGetEntityModelPageGcpScopeResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public networkAccess: EntityControllerGetEntityModelPageEntityRequestNetworkAccess) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageGcpComputeInstanceGroupRequestFilters extends EntityControllerGetEntityModelPageGcpScopeResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        updateTimeRangeSelection: Optional<TimeRangeSelection>,
        accessLevelSelection: Optional<ItemSelection<GcpScopeResourceAccessLevel>>,
        rawIdSelection: Optional<PagedItemSelection<string>>,
        public instanceGroupManagerStatusSelection: Optional<ItemSelection<GcpComputeInstanceGroupManagerStatus>>,
        public instanceIdSelection: Optional<PagedItemSelection<string>>,
        public typeSelection: Optional<ItemSelection<GcpInstanceGroupType>>,
        public vpcIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            updateTimeRangeSelection,
            accessLevelSelection,
            rawIdSelection);
    }
}

export class EntityControllerGetEntityModelPageGcpComputeInstanceRequest extends EntityControllerGetEntityModelPageGcpScopeResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public networkAccess: EntityControllerGetEntityModelPageEntityRequestNetworkAccess,
        public serviceAccount: EntityControllerGetEntityModelPageGcpScopeResourceRequestServiceAccount,
        public virtualMachineDiskResource: EntityControllerGetEntityModelPageEntityRequestVirtualMachineDiskResource,
        public virtualMachineResource: EntityControllerGetEntityModelPageEntityRequestVirtualMachineResource) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageGcpComputeInstanceRequestFilters extends EntityControllerGetEntityModelPageGcpScopeResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        updateTimeRangeSelection: Optional<TimeRangeSelection>,
        accessLevelSelection: Optional<ItemSelection<GcpScopeResourceAccessLevel>>,
        rawIdSelection: Optional<PagedItemSelection<string>>,
        public statusSelection: Optional<ItemSelection<GcpComputeInstanceStatus>>,
        public stopTimeRangeSelection: Optional<TimeRangeSelection>,
        public targetPoolIdSelection: Optional<PagedItemSelection<string>>,
        public vpcIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            updateTimeRangeSelection,
            accessLevelSelection,
            rawIdSelection);
    }
}

export class EntityControllerGetEntityModelPageGcpComputeInstanceTemplateRequest extends EntityControllerGetEntityModelPageGcpScopeResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public serviceAccount: EntityControllerGetEntityModelPageGcpScopeResourceRequestServiceAccount) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageGcpComputeInstanceTemplateRequestFilters extends EntityControllerGetEntityModelPageGcpScopeResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        updateTimeRangeSelection: Optional<TimeRangeSelection>,
        accessLevelSelection: Optional<ItemSelection<GcpScopeResourceAccessLevel>>,
        rawIdSelection: Optional<PagedItemSelection<string>>,
        public instanceGroupIdSelection: Optional<PagedItemSelection<string>>,
        public instanceIdSelection: Optional<PagedItemSelection<string>>,
        public vpcIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            updateTimeRangeSelection,
            accessLevelSelection,
            rawIdSelection);
    }
}

export class EntityControllerGetEntityModelPageGcpComputeLoadBalancerRequest extends EntityControllerGetEntityModelPageGcpEntityRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        public networkAccess: EntityControllerGetEntityModelPageEntityRequestNetworkAccess) {
        super(
            filters,
            limit,
            skip,
            sort);
    }
}

export class EntityControllerGetEntityModelPageGcpComputeLoadBalancerRequestFilters extends EntityControllerGetEntityModelPageGcpEntityRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public typeSelection: Optional<ItemSelection<GcpComputeLoadBalancerType>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageGcpComputeLoadBalancerResourceRequest extends EntityControllerGetEntityModelPageGcpScopeResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageGcpComputeLoadBalancerResourceRequestFilters extends EntityControllerGetEntityModelPageGcpScopeResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        updateTimeRangeSelection: Optional<TimeRangeSelection>,
        accessLevelSelection: Optional<ItemSelection<GcpScopeResourceAccessLevel>>,
        rawIdSelection: Optional<PagedItemSelection<string>>,
        public loadBalancerIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            updateTimeRangeSelection,
            accessLevelSelection,
            rawIdSelection);
    }
}

export class EntityControllerGetEntityModelPageGcpComputeSnapshotRequest extends EntityControllerGetEntityModelPageGcpScopeResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public encrypted: EntityControllerGetEntityModelPageGcpScopeResourceRequestEncrypted) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageGcpComputeSnapshotRequestFilters extends EntityControllerGetEntityModelPageGcpScopeResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        updateTimeRangeSelection: Optional<TimeRangeSelection>,
        accessLevelSelection: Optional<ItemSelection<GcpScopeResourceAccessLevel>>,
        rawIdSelection: Optional<PagedItemSelection<string>>,
        public instanceIdSelection: Optional<PagedItemSelection<string>>,
        public sourceDiskIdSelection: Optional<PagedItemSelection<string>>,
        public statusSelection: Optional<ItemSelection<GcpComputeSnapshotStatus>>,
        public typeSelection: Optional<ItemSelection<GcpComputeSnapshotType>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            updateTimeRangeSelection,
            accessLevelSelection,
            rawIdSelection);
    }
}

export class EntityControllerGetEntityModelPageGcpComputeSslPolicyRequest extends EntityControllerGetEntityModelPageGcpComputeLoadBalancerResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageGcpComputeSslPolicyRequestFilters extends EntityControllerGetEntityModelPageGcpComputeLoadBalancerResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        updateTimeRangeSelection: Optional<TimeRangeSelection>,
        accessLevelSelection: Optional<ItemSelection<GcpScopeResourceAccessLevel>>,
        rawIdSelection: Optional<PagedItemSelection<string>>,
        loadBalancerIdSelection: Optional<PagedItemSelection<string>>,
        public featureProfileSelection: Optional<ItemSelection<GcpComputeSslPolicyFeatureProfile>>,
        public tlsMinVersionSelection: Optional<ItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            updateTimeRangeSelection,
            accessLevelSelection,
            rawIdSelection,
            loadBalancerIdSelection);
    }
}

export class EntityControllerGetEntityModelPageGcpComputeSubnetRequest extends EntityControllerGetEntityModelPageGcpScopeResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageGcpComputeSubnetRequestFilters extends EntityControllerGetEntityModelPageGcpScopeResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        updateTimeRangeSelection: Optional<TimeRangeSelection>,
        accessLevelSelection: Optional<ItemSelection<GcpScopeResourceAccessLevel>>,
        rawIdSelection: Optional<PagedItemSelection<string>>,
        public networkedResourceIdSelection: Optional<PagedItemSelection<string>>,
        public subnetSelection: Optional<PagedItemSelection<string>>,
        public vpcIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            updateTimeRangeSelection,
            accessLevelSelection,
            rawIdSelection);
    }
}

export class EntityControllerGetEntityModelPageGcpComputeTargetPoolRequest extends EntityControllerGetEntityModelPageGcpComputeLoadBalancerResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageGcpComputeTargetPoolRequestFilters extends EntityControllerGetEntityModelPageGcpComputeLoadBalancerResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        updateTimeRangeSelection: Optional<TimeRangeSelection>,
        accessLevelSelection: Optional<ItemSelection<GcpScopeResourceAccessLevel>>,
        rawIdSelection: Optional<PagedItemSelection<string>>,
        loadBalancerIdSelection: Optional<PagedItemSelection<string>>,
        public instanceIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            updateTimeRangeSelection,
            accessLevelSelection,
            rawIdSelection,
            loadBalancerIdSelection);
    }
}

export class EntityControllerGetEntityModelPageGcpComputeTargetProxyRequest extends EntityControllerGetEntityModelPageGcpComputeLoadBalancerResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageGcpComputeTargetProxyRequestFilters extends EntityControllerGetEntityModelPageGcpComputeLoadBalancerResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        updateTimeRangeSelection: Optional<TimeRangeSelection>,
        accessLevelSelection: Optional<ItemSelection<GcpScopeResourceAccessLevel>>,
        rawIdSelection: Optional<PagedItemSelection<string>>,
        loadBalancerIdSelection: Optional<PagedItemSelection<string>>,
        public forwardingRuleIdSelection: Optional<PagedItemSelection<string>>,
        public targetResourceIdSelection: Optional<PagedItemSelection<string>>,
        public typeSelection: Optional<ItemSelection<GcpComputeTargetProxyType>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            updateTimeRangeSelection,
            accessLevelSelection,
            rawIdSelection,
            loadBalancerIdSelection);
    }
}

export class EntityControllerGetEntityModelPageGcpComputeUrlMapRequest extends EntityControllerGetEntityModelPageGcpComputeLoadBalancerResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageGcpComputeUrlMapRequestFilters extends EntityControllerGetEntityModelPageGcpComputeLoadBalancerResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        updateTimeRangeSelection: Optional<TimeRangeSelection>,
        accessLevelSelection: Optional<ItemSelection<GcpScopeResourceAccessLevel>>,
        rawIdSelection: Optional<PagedItemSelection<string>>,
        loadBalancerIdSelection: Optional<PagedItemSelection<string>>,
        public backendResourceIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            updateTimeRangeSelection,
            accessLevelSelection,
            rawIdSelection,
            loadBalancerIdSelection);
    }
}

export class EntityControllerGetEntityModelPageGcpComputeVpcPeeringConnectionRequest extends EntityControllerGetEntityModelPageGcpResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageGcpComputeVpcPeeringConnectionRequestFilters extends EntityControllerGetEntityModelPageGcpResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        updateTimeRangeSelection: Optional<TimeRangeSelection>,
        public accepterSubnetIdSelection: Optional<PagedItemSelection<string>>,
        public accepterVpcIdSelection: Optional<PagedItemSelection<string>>,
        public requesterVpcIdSelection: Optional<PagedItemSelection<string>>,
        public statusSelection: Optional<ItemSelection<GcpComputeVpcPeeringConnectionStatus>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            updateTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageGcpComputeVpcRequest extends EntityControllerGetEntityModelPageGcpScopeResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageGcpComputeVpcRequestFilters extends EntityControllerGetEntityModelPageGcpScopeResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        updateTimeRangeSelection: Optional<TimeRangeSelection>,
        accessLevelSelection: Optional<ItemSelection<GcpScopeResourceAccessLevel>>,
        rawIdSelection: Optional<PagedItemSelection<string>>,
        public networkedResourceIdSelection: Optional<PagedItemSelection<string>>,
        public subnetRegionSelection: Optional<PagedItemSelection<string>>,
        public subnetSubnetSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            updateTimeRangeSelection,
            accessLevelSelection,
            rawIdSelection);
    }
}

export class EntityControllerGetEntityModelPageGcpContainerClusterNodePoolRequest extends EntityControllerGetEntityModelPageGcpScopeResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public serviceAccount: EntityControllerGetEntityModelPageGcpScopeResourceRequestServiceAccount) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageGcpContainerClusterNodePoolRequestFilters extends EntityControllerGetEntityModelPageGcpScopeResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        updateTimeRangeSelection: Optional<TimeRangeSelection>,
        accessLevelSelection: Optional<ItemSelection<GcpScopeResourceAccessLevel>>,
        rawIdSelection: Optional<PagedItemSelection<string>>,
        public clusterIdSelection: Optional<PagedItemSelection<string>>,
        public computeInstanceGroupIdSelection: Optional<PagedItemSelection<string>>,
        public statusSelection: Optional<ItemSelection<GcpContainerClusterNodePoolStatus>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            updateTimeRangeSelection,
            accessLevelSelection,
            rawIdSelection);
    }
}

export class EntityControllerGetEntityModelPageGcpContainerClusterRequest extends EntityControllerGetEntityModelPageGcpScopeResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public encrypted: EntityControllerGetEntityModelPageGcpScopeResourceRequestEncrypted,
        public serviceAccount: EntityControllerGetEntityModelPageGcpScopeResourceRequestServiceAccount) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageGcpContainerClusterRequestFilters extends EntityControllerGetEntityModelPageGcpScopeResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        updateTimeRangeSelection: Optional<TimeRangeSelection>,
        accessLevelSelection: Optional<ItemSelection<GcpScopeResourceAccessLevel>>,
        rawIdSelection: Optional<PagedItemSelection<string>>,
        public apiConnectivityStatusSelection: Optional<ItemSelection<SystemKubernetesClusterApiConnectivityStatus>>,
        public modeSelection: Optional<ItemSelection<GcpContainerClusterMode>>,
        public nodePublicIpAddressExists: Optional<boolean>,
        public resourceUpdateTimeSelection: Optional<TimeRangeSelection>,
        public statusSelection: Optional<ItemSelection<GcpContainerClusterStatus>>,
        public versionSelection: Optional<PagedItemSelection<string>>,
        public vpcIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            updateTimeRangeSelection,
            accessLevelSelection,
            rawIdSelection);
    }
}

export class EntityControllerGetEntityModelPageGcpContainerImageRequest extends EntityControllerGetEntityModelPageGcpEntityRequest implements IEntityControllerGetEntityModelPageContainerImageRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort) {
        super(
            filters,
            limit,
            skip,
            sort);
    }
}

export class EntityControllerGetEntityModelPageGcpContainerImageRequestFilters extends EntityControllerGetEntityModelPageGcpEntityRequestFilters implements IEntityControllerGetEntityModelPageContainerImageRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public containerImageCreationTimeRangeSelection: Optional<TimeRangeSelection>,
        public containerImageTagSelection: Optional<PagedItemSelection<string>>,
        public digestSelection: Optional<PagedItemSelection<string>>,
        public kubernetesWorkloadResourceIdSelection: Optional<PagedItemSelection<string>>,
        public repositoryIdSelection: Optional<PagedItemSelection<string>>,
        public scanTimeRangeSelection: Optional<TimeRangeSelection>,
        public usage: Optional<boolean>,
        public virtualMachineIdSelection: Optional<PagedItemSelection<string>>,
        public vulnerabilityRawIdSelection: Optional<PagedItemSelection<string>>,
        public vulnerabilitySeveritySelection: Optional<ItemSelection<Severity>>,
        public workloadClusterResourceIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageGcpDefaultResourceRequest extends EntityControllerGetEntityModelPageGcpResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageGcpDefaultResourceRequestFilters extends EntityControllerGetEntityModelPageGcpResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        updateTimeRangeSelection: Optional<TimeRangeSelection>,
        public typeName: string) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            updateTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageGcpDefaultScopeResourceRequest extends EntityControllerGetEntityModelPageGcpScopeResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageGcpDefaultScopeResourceRequestFilters extends EntityControllerGetEntityModelPageGcpScopeResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        updateTimeRangeSelection: Optional<TimeRangeSelection>,
        accessLevelSelection: Optional<ItemSelection<GcpScopeResourceAccessLevel>>,
        rawIdSelection: Optional<PagedItemSelection<string>>,
        public typeName: string) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            updateTimeRangeSelection,
            accessLevelSelection,
            rawIdSelection);
    }
}

export class EntityControllerGetEntityModelPageGcpDnsManagedZoneRequest extends EntityControllerGetEntityModelPageGcpScopeResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageGcpDnsManagedZoneRequestFilters extends EntityControllerGetEntityModelPageGcpScopeResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        updateTimeRangeSelection: Optional<TimeRangeSelection>,
        accessLevelSelection: Optional<ItemSelection<GcpScopeResourceAccessLevel>>,
        rawIdSelection: Optional<PagedItemSelection<string>>,
        public descriptionSelection: Optional<PagedItemSelection<string>>,
        public dnsSecKeySigningKeyAlgorithmSelection: Optional<ItemSelection<GcpDnsManagedZoneDnsSecSigningKeyAlgorithm>>,
        public dnsSecStatusSelection: Optional<ItemSelection<GcpDnsManagedZoneDnsSecStatus>>,
        public dnsSecZoneSigningKeyAlgorithmSelection: Optional<ItemSelection<GcpDnsManagedZoneDnsSecSigningKeyAlgorithm>>,
        public loggingEnabled: Optional<boolean>,
        public privateZoneTypeSelection: Optional<ItemSelection<GcpDnsManagedZonePrivateZoneType>>,
        public publicNetworkAccess: Optional<boolean>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            updateTimeRangeSelection,
            accessLevelSelection,
            rawIdSelection);
    }
}

export class EntityControllerGetEntityModelPageGcpFunctionsFunctionRequest extends EntityControllerGetEntityModelPageGcpScopeResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public encrypted: EntityControllerGetEntityModelPageGcpScopeResourceRequestEncrypted,
        public serviceAccount: EntityControllerGetEntityModelPageGcpScopeResourceRequestServiceAccount) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageGcpFunctionsFunctionRequestFilters extends EntityControllerGetEntityModelPageGcpScopeResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        updateTimeRangeSelection: Optional<TimeRangeSelection>,
        accessLevelSelection: Optional<ItemSelection<GcpScopeResourceAccessLevel>>,
        rawIdSelection: Optional<PagedItemSelection<string>>,
        public artifactRegistryRepositoryIdSelection: Optional<PagedItemSelection<string>>,
        public buildServiceAccountIdSelection: Optional<PagedItemSelection<string>>,
        public triggerTypeSelection: Optional<ItemSelection<GcpFunctionsFunctionTriggerType>>,
        public typeSelection: Optional<ItemSelection<GcpFunctionsFunctionType>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            updateTimeRangeSelection,
            accessLevelSelection,
            rawIdSelection);
    }
}

export class EntityControllerGetEntityModelPageGcpIamRoleRequest extends EntityControllerGetEntityModelPageGcpResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageGcpIamRoleRequestFilters extends EntityControllerGetEntityModelPageGcpResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        updateTimeRangeSelection: Optional<TimeRangeSelection>,
        public typeSelection: Optional<ItemSelection<GcpIamRoleType>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            updateTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageGcpIamServiceAccountRequest extends EntityControllerGetEntityModelPageGcpScopeResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageGcpIamServiceAccountRequestFilters extends EntityControllerGetEntityModelPageGcpScopeResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        updateTimeRangeSelection: Optional<TimeRangeSelection>,
        accessLevelSelection: Optional<ItemSelection<GcpScopeResourceAccessLevel>>,
        rawIdSelection: Optional<PagedItemSelection<string>>,
        public activityTimeRangeSelection: Optional<TimeRangeSelection>,
        public disabled: Optional<boolean>,
        public groupIdSelection: Optional<PagedItemSelection<string>>,
        public identityContainedRoleBindingExists: Optional<boolean>,
        public identityRoleIdSelection: Optional<PagedItemSelection<string>>,
        public mailSelection: Optional<PagedItemSelection<string>>,
        public originatorResourceIdSelection: Optional<PagedItemSelection<string>>,
        public signInTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            updateTimeRangeSelection,
            accessLevelSelection,
            rawIdSelection);
    }
}

export class EntityControllerGetEntityModelPageGcpIamWorkforcePoolRequest extends EntityControllerGetEntityModelPageGcpScopeResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageGcpIamWorkforcePoolRequestFilters extends EntityControllerGetEntityModelPageGcpScopeResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        updateTimeRangeSelection: Optional<TimeRangeSelection>,
        accessLevelSelection: Optional<ItemSelection<GcpScopeResourceAccessLevel>>,
        rawIdSelection: Optional<PagedItemSelection<string>>,
        public containedRoleBindingExists: Optional<boolean>,
        public enabled: Optional<boolean>,
        public providerTypeSelection: Optional<ItemSelection<string>>,
        public rawShortIdSelection: Optional<PagedItemSelection<string>>,
        public roleIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            updateTimeRangeSelection,
            accessLevelSelection,
            rawIdSelection);
    }
}

export class EntityControllerGetEntityModelPageGcpIamWorkloadIdentityPoolRequest extends EntityControllerGetEntityModelPageGcpScopeResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageGcpIamWorkloadIdentityPoolRequestFilters extends EntityControllerGetEntityModelPageGcpScopeResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        updateTimeRangeSelection: Optional<TimeRangeSelection>,
        accessLevelSelection: Optional<ItemSelection<GcpScopeResourceAccessLevel>>,
        rawIdSelection: Optional<PagedItemSelection<string>>,
        public enabled: Optional<boolean>,
        public identityContainedRoleBindingExists: Optional<boolean>,
        public identityRoleIdSelection: Optional<PagedItemSelection<string>>,
        public providerTypeSelection: Optional<ItemSelection<string>>,
        public rawShortIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            updateTimeRangeSelection,
            accessLevelSelection,
            rawIdSelection);
    }
}

export class EntityControllerGetEntityModelPageGcpKmsKeyRingKeyRequest extends EntityControllerGetEntityModelPageGcpScopeResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageGcpKmsKeyRingKeyRequestFilters extends EntityControllerGetEntityModelPageGcpScopeResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        updateTimeRangeSelection: Optional<TimeRangeSelection>,
        accessLevelSelection: Optional<ItemSelection<GcpScopeResourceAccessLevel>>,
        rawIdSelection: Optional<PagedItemSelection<string>>,
        public keyRingIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            updateTimeRangeSelection,
            accessLevelSelection,
            rawIdSelection);
    }
}

export class EntityControllerGetEntityModelPageGcpKmsKeyRingRequest extends EntityControllerGetEntityModelPageGcpScopeResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageGcpKmsKeyRingRequestFilters extends EntityControllerGetEntityModelPageGcpScopeResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        updateTimeRangeSelection: Optional<TimeRangeSelection>,
        accessLevelSelection: Optional<ItemSelection<GcpScopeResourceAccessLevel>>,
        rawIdSelection: Optional<PagedItemSelection<string>>,
        public keyIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            updateTimeRangeSelection,
            accessLevelSelection,
            rawIdSelection);
    }
}

export class EntityControllerGetEntityModelPageGcpKubernetesResourceRequest extends EntityControllerGetEntityModelPageGcpResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public kubernetesResource: EntityControllerGetEntityModelPageEntityRequestKubernetesResource) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageGcpKubernetesResourceRequestFilters extends EntityControllerGetEntityModelPageGcpResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        updateTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            updateTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageGcpKubernetesUserRequest extends EntityControllerGetEntityModelPageGcpKubernetesResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        kubernetesResource: EntityControllerGetEntityModelPageEntityRequestKubernetesResource,
        public kubernetesPrincipal: EntityControllerGetEntityModelPageEntityRequestKubernetesPrincipal) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag,
            kubernetesResource);
    }
}

export class EntityControllerGetEntityModelPageGcpKubernetesUserRequestFilters extends EntityControllerGetEntityModelPageGcpKubernetesResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        updateTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            updateTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageGcpLoggingLogSinkRequest extends EntityControllerGetEntityModelPageGcpScopeResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public serviceAccount: EntityControllerGetEntityModelPageGcpScopeResourceRequestServiceAccount) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageGcpLoggingLogSinkRequestFilters extends EntityControllerGetEntityModelPageGcpScopeResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        updateTimeRangeSelection: Optional<TimeRangeSelection>,
        accessLevelSelection: Optional<ItemSelection<GcpScopeResourceAccessLevel>>,
        rawIdSelection: Optional<PagedItemSelection<string>>,
        public destinationIdSelection: Optional<PagedItemSelection<string>>,
        public status: Optional<boolean>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            updateTimeRangeSelection,
            accessLevelSelection,
            rawIdSelection);
    }
}

export class EntityControllerGetEntityModelPageGcpNotebooksInstanceRequest extends EntityControllerGetEntityModelPageGcpScopeResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageGcpNotebooksInstanceRequestFilters extends EntityControllerGetEntityModelPageGcpScopeResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        updateTimeRangeSelection: Optional<TimeRangeSelection>,
        accessLevelSelection: Optional<ItemSelection<GcpScopeResourceAccessLevel>>,
        rawIdSelection: Optional<PagedItemSelection<string>>,
        public computeInstanceIdSelection: Optional<PagedItemSelection<string>>,
        public rootAccess: Optional<boolean>,
        public statusSelection: Optional<ItemSelection<GcpNotebooksInstanceStatus>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            updateTimeRangeSelection,
            accessLevelSelection,
            rawIdSelection);
    }
}

export class EntityControllerGetEntityModelPageGcpPubSubSubscriptionRequest extends EntityControllerGetEntityModelPageGcpScopeResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageGcpPubSubSubscriptionRequestFilters extends EntityControllerGetEntityModelPageGcpScopeResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        updateTimeRangeSelection: Optional<TimeRangeSelection>,
        accessLevelSelection: Optional<ItemSelection<GcpScopeResourceAccessLevel>>,
        rawIdSelection: Optional<PagedItemSelection<string>>,
        public messageDeliveryTypeSelection: Optional<ItemSelection<GcpPubSubSubscriptionMessageDeliveryType>>,
        public topicIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            updateTimeRangeSelection,
            accessLevelSelection,
            rawIdSelection);
    }
}

export class EntityControllerGetEntityModelPageGcpPubSubTopicRequest extends EntityControllerGetEntityModelPageGcpScopeResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public encrypted: EntityControllerGetEntityModelPageGcpScopeResourceRequestEncrypted) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageGcpPubSubTopicRequestFilters extends EntityControllerGetEntityModelPageGcpScopeResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        updateTimeRangeSelection: Optional<TimeRangeSelection>,
        accessLevelSelection: Optional<ItemSelection<GcpScopeResourceAccessLevel>>,
        rawIdSelection: Optional<PagedItemSelection<string>>,
        public subscriptionIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            updateTimeRangeSelection,
            accessLevelSelection,
            rawIdSelection);
    }
}

export class EntityControllerGetEntityModelPageOciAutoscalingAutoscalingConfigurationRequest extends EntityControllerGetEntityModelPageOciResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageOciAutoscalingAutoscalingConfigurationRequestFilters extends EntityControllerGetEntityModelPageOciResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public enabled: Optional<boolean>,
        public instancePoolIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageOciBlockStorageBlockVolumeRequest extends EntityControllerGetEntityModelPageOciResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public encrypted: EntityControllerGetEntityModelPageOciResourceRequestEncrypted) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageOciBlockStorageBlockVolumeRequestFilters extends EntityControllerGetEntityModelPageOciResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public instanceIdSelection: Optional<PagedItemSelection<string>>,
        public statusSelection: Optional<ItemSelection<OciBlockStorageBlockVolumeStatus>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageOciBlockStorageBootVolumeRequest extends EntityControllerGetEntityModelPageOciResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public encrypted: EntityControllerGetEntityModelPageOciResourceRequestEncrypted) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageOciBlockStorageBootVolumeRequestFilters extends EntityControllerGetEntityModelPageOciResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public instanceIdSelection: Optional<PagedItemSelection<string>>,
        public statusSelection: Optional<ItemSelection<OciBlockStorageBootVolumeStatus>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageOciComputeImageRequest extends EntityControllerGetEntityModelPageOciResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageOciComputeImageRequestFilters extends EntityControllerGetEntityModelPageOciResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public ociManaged: Optional<boolean>,
        public operatingSystemTypeSelection: Optional<ItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageOciComputeInstanceConfigurationRequest extends EntityControllerGetEntityModelPageOciResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageOciComputeInstanceConfigurationRequestFilters extends EntityControllerGetEntityModelPageOciResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public instancePoolIdSelection: Optional<PagedItemSelection<string>>,
        public memoryEncryptionEnabled: Optional<boolean>,
        public metadataServiceVersion1Enabled: Optional<boolean>,
        public secureBootEnabled: Optional<boolean>,
        public shapeSelection: Optional<ItemSelection<string>>,
        public volumeEncryptionInTransitEnabled: Optional<boolean>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageOciComputeInstancePoolRequest extends EntityControllerGetEntityModelPageOciResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageOciComputeInstancePoolRequestFilters extends EntityControllerGetEntityModelPageOciResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public instanceConfigurationIdSelection: Optional<PagedItemSelection<string>>,
        public instanceIdSelection: Optional<PagedItemSelection<string>>,
        public statusSelection: Optional<ItemSelection<OciComputeInstancePoolStatus>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageOciComputeInstanceRequest extends EntityControllerGetEntityModelPageOciResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageOciComputeInstanceRequestFilters extends EntityControllerGetEntityModelPageOciResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public instanceConfigurationIdSelection: Optional<PagedItemSelection<string>>,
        public memoryEncryptionEnabled: Optional<boolean>,
        public metadataServiceVersion1Enabled: Optional<boolean>,
        public primaryPrivateIpAddressSelection: Optional<PagedItemSelection<string>>,
        public primaryPublicIpAddressSelection: Optional<PagedItemSelection<string>>,
        public secureBootEnabled: Optional<boolean>,
        public shapeSelection: Optional<ItemSelection<string>>,
        public statusSelection: Optional<ItemSelection<OciComputeInstanceStatus>>,
        public volumeEncryptionInTransitEnabled: Optional<boolean>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageOciContainerEngineClusterNodePoolRequest extends EntityControllerGetEntityModelPageOciResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageOciContainerEngineClusterNodePoolRequestFilters extends EntityControllerGetEntityModelPageOciResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public clusterIdSelection: Optional<PagedItemSelection<string>>,
        public instanceIdSelection: Optional<PagedItemSelection<string>>,
        public metadataServiceVersion1Enabled: Optional<boolean>,
        public statusSelection: Optional<ItemSelection<OciContainerEngineClusterNodePoolStatus>>,
        public volumeEncryptionInTransitEnabled: Optional<boolean>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageOciContainerEngineClusterRequest extends EntityControllerGetEntityModelPageOciResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageOciContainerEngineClusterRequestFilters extends EntityControllerGetEntityModelPageOciResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public nodePoolIdSelection: Optional<PagedItemSelection<string>>,
        public statusSelection: Optional<ItemSelection<OciContainerEngineClusterStatus>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageOciDefaultResourceRequest extends EntityControllerGetEntityModelPageOciResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageOciDefaultResourceRequestFilters extends EntityControllerGetEntityModelPageOciResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public typeName: string) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageOciFssFileSystemRequest extends EntityControllerGetEntityModelPageOciResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public encrypted: EntityControllerGetEntityModelPageOciResourceRequestEncrypted) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageOciFssFileSystemRequestFilters extends EntityControllerGetEntityModelPageOciResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public statusSelection: Optional<ItemSelection<OciFssFileSystemStatus>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageOciFssMountTargetExportRequest extends EntityControllerGetEntityModelPageOciResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageOciFssMountTargetExportRequestFilters extends EntityControllerGetEntityModelPageOciResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public fileSystemIdSelection: Optional<PagedItemSelection<string>>,
        public mountTargetIdSelection: Optional<PagedItemSelection<string>>,
        public statusSelection: Optional<ItemSelection<OciFssMountTargetExportStatus>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageOciFssMountTargetRequest extends EntityControllerGetEntityModelPageOciResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageOciFssMountTargetRequestFilters extends EntityControllerGetEntityModelPageOciResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public exportIdSelection: Optional<PagedItemSelection<string>>,
        public fileSystemIdSelection: Optional<PagedItemSelection<string>>,
        public networkSecurityGroupIdSelection: Optional<PagedItemSelection<string>>,
        public privateIpAddressSelection: Optional<PagedItemSelection<string>>,
        public statusSelection: Optional<ItemSelection<OciFssMountTargetStatus>>,
        public vcnIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageOciIamResourceRequest extends EntityControllerGetEntityModelPageOciResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageOciIamResourceRequestFilters extends EntityControllerGetEntityModelPageOciResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public descriptionSelection: Optional<PagedItemSelection<string>>,
        public enabled: Optional<boolean>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageOciKmsVaultObjectRequest extends EntityControllerGetEntityModelPageOciResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageOciKmsVaultObjectRequestFilters extends EntityControllerGetEntityModelPageOciResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public vaultIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageOciKmsVaultRequest extends EntityControllerGetEntityModelPageOciResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageOciKmsVaultRequestFilters extends EntityControllerGetEntityModelPageOciResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public keyIdSelection: Optional<PagedItemSelection<string>>,
        public secretIdSelection: Optional<PagedItemSelection<string>>,
        public statusSelection: Optional<ItemSelection<OciKmsVaultStatus>>,
        public typeSelection: Optional<ItemSelection<OciKmsVaultType>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageOciKmsVaultSecretRequest extends EntityControllerGetEntityModelPageOciKmsVaultObjectRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageOciKmsVaultSecretRequestFilters extends EntityControllerGetEntityModelPageOciKmsVaultObjectRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        vaultIdSelection: Optional<PagedItemSelection<string>>,
        public statusSelection: Optional<ItemSelection<OciKmsVaultSecretStatus>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            vaultIdSelection);
    }
}

export class EntityControllerGetEntityModelPageOciLoadBalancingLoadBalancerRequest extends EntityControllerGetEntityModelPageOciResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageOciLoadBalancingLoadBalancerRequestFilters extends EntityControllerGetEntityModelPageOciResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public accessLogsEnabled: Optional<boolean>,
        public errorLogsEnabled: Optional<boolean>,
        public external: Optional<boolean>,
        public privateIpAddressSelection: Optional<PagedItemSelection<string>>,
        public publicIpAddressSelection: Optional<PagedItemSelection<string>>,
        public securityGroupIdSelection: Optional<PagedItemSelection<string>>,
        public statusSelection: Optional<ItemSelection<OciLoadBalancingLoadBalancerStatus>>,
        public vcnIdSelection: Optional<PagedItemSelection<string>>,
        public webApplicationFirewallIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageOciLoggingLogGroupRequest extends EntityControllerGetEntityModelPageOciResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageOciLoggingLogGroupRequestFilters extends EntityControllerGetEntityModelPageOciResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageOciNetworkingInternetGatewayRequest extends EntityControllerGetEntityModelPageOciResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageOciNetworkingInternetGatewayRequestFilters extends EntityControllerGetEntityModelPageOciResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public enabled: Optional<boolean>,
        public statusSelection: Optional<ItemSelection<OciNetworkingInternetGatewayStatus>>,
        public vcnIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageOciNetworkingLocalPeeringGatewayRequest extends EntityControllerGetEntityModelPageOciResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageOciNetworkingLocalPeeringGatewayRequestFilters extends EntityControllerGetEntityModelPageOciResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public crossTenancyPeering: Optional<boolean>,
        public peeringStatusSelection: Optional<ItemSelection<OciNetworkingLocalPeeringGatewayPeeringStatus>>,
        public peerVcnIdSelection: Optional<PagedItemSelection<string>>,
        public statusSelection: Optional<ItemSelection<OciNetworkingLocalPeeringGatewayStatus>>,
        public vcnIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageOciNetworkingNatGatewayRequest extends EntityControllerGetEntityModelPageOciResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageOciNetworkingNatGatewayRequestFilters extends EntityControllerGetEntityModelPageOciResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public blockTraffic: Optional<boolean>,
        public statusSelection: Optional<ItemSelection<OciNetworkingNatGatewayStatus>>,
        public vcnIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageOciNetworkingNetworkLoadBalancerRequest extends EntityControllerGetEntityModelPageOciResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageOciNetworkingNetworkLoadBalancerRequestFilters extends EntityControllerGetEntityModelPageOciResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public external: Optional<boolean>,
        public privateIpAddressSelection: Optional<PagedItemSelection<string>>,
        public publicIpAddressSelection: Optional<PagedItemSelection<string>>,
        public securityGroupIdSelection: Optional<PagedItemSelection<string>>,
        public statusSelection: Optional<ItemSelection<OciNetworkingNetworkLoadBalancerStatus>>,
        public vcnIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageOciNetworkingNetworkSecurityGroupRequest extends EntityControllerGetEntityModelPageOciResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageOciNetworkingNetworkSecurityGroupRequestFilters extends EntityControllerGetEntityModelPageOciResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public associatedResourceIdSelection: Optional<PagedItemSelection<string>>,
        public statusSelection: Optional<ItemSelection<OciNetworkingNetworkSecurityGroupStatus>>,
        public vcnIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageOciNetworkingRouteTableRequest extends EntityControllerGetEntityModelPageOciResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageOciNetworkingRouteTableRequestFilters extends EntityControllerGetEntityModelPageOciResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public statusSelection: Optional<ItemSelection<OciNetworkingRouteTableStatus>>,
        public vcnIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageOciNetworkingSecurityListRequest extends EntityControllerGetEntityModelPageOciResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageOciNetworkingSecurityListRequestFilters extends EntityControllerGetEntityModelPageOciResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public statusSelection: Optional<ItemSelection<OciNetworkingSecurityListStatus>>,
        public vcnIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageOciNetworkingServiceGatewayRequest extends EntityControllerGetEntityModelPageOciResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageOciNetworkingServiceGatewayRequestFilters extends EntityControllerGetEntityModelPageOciResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public blockTraffic: Optional<boolean>,
        public serviceNameSelection: Optional<ItemSelection<string>>,
        public statusSelection: Optional<ItemSelection<OciNetworkingServiceGatewayStatus>>,
        public vcnIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageOciNetworkingSubnetRequest extends EntityControllerGetEntityModelPageOciResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageOciNetworkingSubnetRequestFilters extends EntityControllerGetEntityModelPageOciResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public flowLogsEnabled: Optional<boolean>,
        public ipv4SubnetSelection: Optional<PagedItemSelection<string>>,
        public ipv6SubnetSelection: Optional<PagedItemSelection<string>>,
        public networkedResourceIdSelection: Optional<PagedItemSelection<string>>,
        public prohibitPublicIpOnVnic: Optional<boolean>,
        public regional: Optional<boolean>,
        public statusSelection: Optional<ItemSelection<OciNetworkingSubnetStatus>>,
        public vcnIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageOciNetworkingVcnRequest extends EntityControllerGetEntityModelPageOciResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageOciNetworkingVcnRequestFilters extends EntityControllerGetEntityModelPageOciResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public flowLogsEnabled: Optional<boolean>,
        public ipv4SubnetSelection: Optional<PagedItemSelection<string>>,
        public ipv6SubnetSelection: Optional<PagedItemSelection<string>>,
        public networkedResourceIdSelection: Optional<PagedItemSelection<string>>,
        public statusSelection: Optional<ItemSelection<OciNetworkingVcnStatus>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageOciNetworkingVirtualNetworkInterfaceCardRequest extends EntityControllerGetEntityModelPageOciResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageOciNetworkingVirtualNetworkInterfaceCardRequestFilters extends EntityControllerGetEntityModelPageOciResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public privateIpAddressSelection: Optional<PagedItemSelection<string>>,
        public publicIpAddressSelection: Optional<PagedItemSelection<string>>,
        public subnetIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageOciObjectStorageBucketRequest extends EntityControllerGetEntityModelPageOciResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public encrypted: EntityControllerGetEntityModelPageOciResourceRequestEncrypted) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageOciObjectStorageBucketRequestFilters extends EntityControllerGetEntityModelPageOciResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public logCategorySelection: Optional<ItemSelection<OciObjectStorageBucketLogCategory>>,
        public objectAccessLevelSelection: Optional<ItemSelection<OciObjectStorageBucketObjectAccessLevel>>,
        public storageTierSelection: Optional<ItemSelection<OciObjectStorageBucketStorageTier>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageOciTenantEntityRequest extends EntityControllerGetEntityModelPageOciIamResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageOciTenantEntityRequestFilters extends EntityControllerGetEntityModelPageOciIamResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        descriptionSelection: Optional<PagedItemSelection<string>>,
        enabled: Optional<boolean>,
        public parentPathSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            descriptionSelection,
            enabled);
    }
}

export class EntityControllerGetEntityModelPageOktaDefaultDirectoryEntityRequest extends EntityControllerGetEntityModelPageOktaDirectoryEntityRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort) {
        super(
            filters,
            limit,
            skip,
            sort);
    }
}

export class EntityControllerGetEntityModelPageOktaDefaultDirectoryEntityRequestFilters extends EntityControllerGetEntityModelPageOktaDirectoryEntityRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public typeName: string) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageOktaDirectoryGroupRequest extends EntityControllerGetEntityModelPageOktaDirectoryPrincipalRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort) {
        super(
            filters,
            limit,
            skip,
            sort);
    }
}

export class EntityControllerGetEntityModelPageOktaDirectoryGroupRequestFilters extends EntityControllerGetEntityModelPageOktaDirectoryPrincipalRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        awsRoleIdSelection: Optional<PagedItemSelection<string>>,
        public userIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            awsRoleIdSelection);
    }
}

export class EntityControllerGetEntityModelPageOpContainerImageRequest extends EntityControllerGetEntityModelPageOpResourceRequest implements IEntityControllerGetEntityModelPageContainerImageRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageOpContainerImageRequestFilters extends EntityControllerGetEntityModelPageOpResourceRequestFilters implements IEntityControllerGetEntityModelPageContainerImageRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public containerImageCreationTimeRangeSelection: Optional<TimeRangeSelection>,
        public containerImageTagSelection: Optional<PagedItemSelection<string>>,
        public digestSelection: Optional<PagedItemSelection<string>>,
        public kubernetesWorkloadResourceIdSelection: Optional<PagedItemSelection<string>>,
        public repositoryIdSelection: Optional<PagedItemSelection<string>>,
        public scanTimeRangeSelection: Optional<TimeRangeSelection>,
        public usage: Optional<boolean>,
        public virtualMachineIdSelection: Optional<PagedItemSelection<string>>,
        public vulnerabilityRawIdSelection: Optional<PagedItemSelection<string>>,
        public vulnerabilitySeveritySelection: Optional<ItemSelection<Severity>>,
        public workloadClusterResourceIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageOpKubernetesResourceRequest extends EntityControllerGetEntityModelPageOpResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public kubernetesResource: EntityControllerGetEntityModelPageEntityRequestKubernetesResource) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageOpKubernetesResourceRequestFilters extends EntityControllerGetEntityModelPageOpResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageOpKubernetesUserRequest extends EntityControllerGetEntityModelPageOpKubernetesResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        kubernetesResource: EntityControllerGetEntityModelPageEntityRequestKubernetesResource,
        public kubernetesPrincipal: EntityControllerGetEntityModelPageEntityRequestKubernetesPrincipal) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag,
            kubernetesResource);
    }
}

export class EntityControllerGetEntityModelPageOpKubernetesUserRequestFilters extends EntityControllerGetEntityModelPageOpKubernetesResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPagePingIdentityDefaultDirectoryEntityRequest extends EntityControllerGetEntityModelPagePingIdentityDirectoryEntityRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort) {
        super(
            filters,
            limit,
            skip,
            sort);
    }
}

export class EntityControllerGetEntityModelPagePingIdentityDefaultDirectoryEntityRequestFilters extends EntityControllerGetEntityModelPagePingIdentityDirectoryEntityRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public typeName: string) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityGcpAccessReportDefinitionConfiguration extends EntityAccessReportDefinitionConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        contentType: ReportContentType,
        exportReport: boolean,
        licenseType: ApplicationCustomerConfigurationLicensingLicenseType,
        name: Optional<string>,
        timeZoneInfoId: string,
        type: ReportType,
        direction: EntityAccessDirection,
        entityId: string,
        scope: EntityAccessScope,
        public filters: EntityControllerGetGcpAccessDataRequestFilters) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "EntityGcpAccessReportDefinitionConfiguration",
            contentType,
            exportReport,
            licenseType,
            name,
            timeZoneInfoId,
            type,
            direction,
            entityId,
            scope);
    }
}

export class EntityGroupDefinitionConfiguration extends ScopeSystemEntityConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        public entityTypeName: string,
        public name: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId);
    }
}

export class EntityIdMatchCondition extends EntityMatchCondition implements ITypeNameObject {
    constructor(
        public ids: string[]) {
        super("EntityIdMatchCondition");
    }
}

export class EntityPrincipalReferenceProperty extends EntityProperty implements ITypeNameObject {
    constructor(
        identifier: EntityPropertyIdentifier,
        name: string,
        type: EntityPropertyType,
        valueDisplayNames: string[],
        public values: EntityPropertyPrincipalReferenceValue[],
        valueType: EntityPropertyValueType) {
        super(
            "EntityPrincipalReferenceProperty",
            identifier,
            name,
            type,
            valueDisplayNames,
            values,
            valueType);
    }
}

export class EntityPropertyDefinitionConfiguration extends ScopeSystemEntityConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        public identifier: EntityPropertyIdentifier,
        public resourceTagPatterns: ResourceTagPattern[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "EntityPropertyDefinitionConfiguration",
            scopeId);
    }
}

export class EntityPropertyMatchCondition<TValue> extends EntityPropertyMatchConditionBase implements ITypeNameObject {
    constructor(
        typeName: string,
        identifier: EntityPropertyIdentifier,
        operator: EntityMatchConditionCollectionOperator,
        public values: TValue[]) {
        super(
            typeName,
            identifier,
            operator);
    }
}

export class EntityPropertyPrincipalReferenceValue extends EntityPropertyValue implements ITypeNameObject {
    constructor(
        displayName: string,
        source: EntityPropertyValueSource,
        public principalSearchableIdReference: EntitySearchableIdReference) {
        super(
            "EntityPropertyPrincipalReferenceValue",
            displayName,
            source);
    }
}

export class EntityPropertyStringValue extends EntityPropertyValue implements ITypeNameObject {
    constructor(
        displayName: string,
        source: EntityPropertyValueSource,
        public string: string) {
        super(
            "EntityPropertyStringValue",
            displayName,
            source);
    }
}

export class EntityPropertyValueAutomaticSource extends EntityPropertyValueSource implements ITypeNameObject {
    constructor(
        public entityId: string,
        public tag: ResourceTag) {
        super("EntityPropertyValueAutomaticSource");
    }
}

export class EntityPropertyValueManualSource extends EntityPropertyValueSource implements ITypeNameObject {
    constructor(
        public creationTime: string,
        public creatorIdentityReference: IdentityReference) {
        super("EntityPropertyValueManualSource");
    }
}

export class EntityRisk extends CloudRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        public entityId: string,
        public severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType);
    }
}

export class EntitySearchableReferenceBase<T> extends SearchableData<T> {
    constructor(
        public displayName: string) {
        super();
    }
}

export class EntityStringPropertyMatchCondition extends EntityPropertyMatchCondition<string> implements ITypeNameObject {
    constructor(
        identifier: EntityPropertyIdentifier,
        operator: EntityMatchConditionCollectionOperator,
        values: string[]) {
        super(
            "EntityStringPropertyMatchCondition",
            identifier,
            operator,
            values);
    }
}

export class EventsReportDefinitionConfiguration extends ReportDefinitionConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        contentType: ReportContentType,
        exportReport: boolean,
        licenseType: ApplicationCustomerConfigurationLicensingLicenseType,
        name: Optional<string>,
        timeZoneInfoId: string,
        type: ReportType,
        public entityId: Optional<string>,
        public filterMap: Optional<string>,
        public riskId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "EventsReportDefinitionConfiguration",
            contentType,
            exportReport,
            licenseType,
            name,
            timeZoneInfoId,
            type);
    }
}

export class GciChange extends Change implements ITypeNameObject {
    constructor(
        typeName: string,
        entityIds: string[],
        id: string,
        resolved: boolean) {
        super(
            typeName,
            entityIds,
            id,
            resolved);
    }
}

export class GciDeletePrincipalGroupMembershipChange extends GciChange implements ITypeNameObject, ICodeInfrastructureChange {
    constructor(
        entityIds: string[],
        id: string,
        resolved: boolean,
        public groupId: string,
        public principalId: string) {
        super(
            "GciDeletePrincipalGroupMembershipChange",
            entityIds,
            id,
            resolved);
    }
}

export class GciDirectoryEntity extends GciEntity implements ITypeNameObject, IDirectoryEntity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        public creationTime: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name);
    }
}

export class GciDirectoryEntityModel extends GciEntityModel implements ITypeNameObject, IEntityModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GciEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown);
    }
}

export class GciDirectoryGroupInactiveRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public groupIdentityCount: number,
        public groupIdentityExists: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GciDirectoryGroupInactiveRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GciDirectoryGroupInactiveRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GciDirectoryGroupInactiveRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GciDirectoryGroupInactiveRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GciDirectoryOrganizationalUnit extends GciDirectoryEntity implements ITypeNameObject, IDirectoryEntity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        creationTime: Optional<string>,
        public description: string,
        public parentOrganizationalUnitRawId: Optional<string>,
        public path: string,
        public uniqueId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GciDirectoryOrganizationalUnit",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            creationTime);
    }
}

export class GciDirectoryOrganizationalUnitModel extends GciDirectoryEntityModel implements ITypeNameObject, IEntityModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GciEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GciDirectoryOrganizationalUnitModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown);
    }
}

export class GciDirectoryPartialEntity extends GciPartialEntity implements ITypeNameObject, IPartialEntity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name);
    }
}

export class GciDirectoryPartialEntityModel extends GciEntityModel implements ITypeNameObject, IUdmObjectModel, IRelatedTenantsEntityModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GciEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        public containedGcpRoleBindingIds: string[],
        public gcpRoleBindingIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown);
    }
}

export class GciDirectoryPartialPrincipal extends GciDirectoryPartialEntity implements ITypeNameObject, IGciDirectoryPrincipal, IGciPartialPrincipal {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        public mail: string,
        public uniqueId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name);
    }
}

export class GciDirectoryPartialPrincipalModel extends GciDirectoryPartialEntityModel implements ITypeNameObject, IUdmObjectModel, IRelatedTenantsEntityModel, IGciPrincipalModel, IPrincipalModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GciEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        containedGcpRoleBindingIds: string[],
        gcpRoleBindingIds: string[],
        public access: Optional<PrincipalModelAccess>,
        public analyzing: Optional<GciPrincipalModelAnalyzing>,
        public groupIds: string[],
        public inactive: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            containedGcpRoleBindingIds,
            gcpRoleBindingIds);
    }
}

export class GciDirectoryPartialUser extends GciDirectoryPartialPrincipal implements ITypeNameObject, IGciDirectoryPrincipal, IGciPartialPrincipal, IGciPartialIdentity, IGciUser {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        mail: string,
        uniqueId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GciDirectoryPartialUser",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            mail,
            uniqueId);
    }
}

export class GciDirectoryPartialUserModel extends GciDirectoryPartialPrincipalModel implements ITypeNameObject, IUdmObjectModel, IRelatedTenantsEntityModel, IGciPrincipalModel, IUserModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GciEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        containedGcpRoleBindingIds: string[],
        gcpRoleBindingIds: string[],
        public access: Optional<UserModelAccess>,
        analyzing: Optional<GciPrincipalModelAnalyzing>,
        groupIds: string[],
        inactive: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GciDirectoryPartialUserModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            containedGcpRoleBindingIds,
            gcpRoleBindingIds,
            access,
            analyzing,
            groupIds,
            inactive);
    }
}

export class GciDirectoryPrincipal extends GciDirectoryEntity implements ITypeNameObject, IDirectoryPrincipal, IGciDirectoryPrincipal {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        creationTime: Optional<string>,
        public mail: string,
        public mailAliases: string[],
        public uniqueId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            creationTime);
    }
}

export class GciDirectoryPrincipalModel extends GciDirectoryEntityModel implements ITypeNameObject, IUdmObjectModel, IPrincipalModel, IGciPrincipalModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GciEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        public access: Optional<PrincipalModelAccess>,
        public analyzing: Optional<GciPrincipalModelAnalyzing>,
        public containedGcpRoleBindingIds: string[],
        public gcpRoleBindingIds: string[],
        public groupIds: string[],
        public inactive: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown);
    }
}

export class GciDirectorySamlSsoProfile extends GciDirectoryEntity implements ITypeNameObject, IDirectoryEntity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        creationTime: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GciDirectorySamlSsoProfile",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            creationTime);
    }
}

export class GciDirectorySamlSsoProfileModel extends GciDirectoryEntityModel implements ITypeNameObject, IEntityModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GciEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GciDirectorySamlSsoProfileModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown);
    }
}

export class GciDirectorySpecialGroup extends GciDirectoryEntity implements ITypeNameObject, IDirectoryEntity, IGciPrincipal {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        creationTime: Optional<string>,
        public type: GciDirectorySpecialGroupType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GciDirectorySpecialGroup",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            creationTime);
    }
}

export class GciDirectorySpecialGroupModel extends GciDirectoryEntityModel implements ITypeNameObject, IUdmObjectModel, IGciPrincipalModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GciEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GciDirectorySpecialGroupModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown);
    }
}

export class GciDirectorySsoProfileAssignment extends GciDirectoryEntity implements ITypeNameObject, IDirectoryEntity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        creationTime: Optional<string>,
        public profileId: Optional<string>,
        public rank: Optional<number>,
        public scopeId: string,
        public status: GciDirectorySsoProfileAssignmentStatus) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GciDirectorySsoProfileAssignment",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            creationTime);
    }
}

export class GciDirectorySsoProfileAssignmentModel extends GciDirectoryEntityModel implements ITypeNameObject, IEntityModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GciEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GciDirectorySsoProfileAssignmentModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown);
    }
}

export class GciDirectoryUser extends GciDirectoryPrincipal implements ITypeNameObject, IGciDirectoryPrincipal, IDirectoryUser, IGciUser, IPermissionManagementUser {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        creationTime: Optional<string>,
        mail: string,
        mailAliases: string[],
        uniqueId: string,
        public mfaEnforced: boolean,
        public organizationalUnitId: Optional<string>,
        public signInTime: Optional<string>,
        public status: GciDirectoryUserStatus) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GciDirectoryUser",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            creationTime,
            mail,
            mailAliases,
            uniqueId);
    }
}

export class GciDirectoryUserInactiveRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public authenticationActivity: Activity,
        public resolutionType: GciDirectoryUserInactiveRiskResolutionType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GciDirectoryUserInactiveRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GciDirectoryUserInactiveRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GciDirectoryUserInactiveRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public infoType: GciDirectoryUserInactiveRiskModelInfoType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GciDirectoryUserInactiveRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GciDirectoryUserInactiveRiskPolicyUpdateAuditEventIncludeDisabledUserChange extends RiskPolicyUpdateAuditEventChange implements ITypeNameObject {
    constructor(
        public includeDisabledUser: Optional<boolean>,
        public previousIncludeDisabledUser: Optional<boolean>) {
        super("GciDirectoryUserInactiveRiskPolicyUpdateAuditEventIncludeDisabledUserChange");
    }
}

export class GciDirectoryUserModel extends GciDirectoryPrincipalModel implements ITypeNameObject, IUdmObjectModel, IGciPrincipalModel, IPermissionManagementUserModel, IUserModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GciEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        public access: Optional<UserModelAccess>,
        analyzing: Optional<GciPrincipalModelAnalyzing>,
        containedGcpRoleBindingIds: string[],
        gcpRoleBindingIds: string[],
        groupIds: string[],
        inactive: boolean,
        public authorizationScopeToTokenCreationTimeMap: Dictionary<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GciDirectoryUserModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            access,
            analyzing,
            containedGcpRoleBindingIds,
            gcpRoleBindingIds,
            groupIds,
            inactive);
    }
}

export class GciPartialTenantEntity extends GciDirectoryPartialEntity implements ITypeNameObject, IGciPartialPrincipal, IGciTenantEntity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        public primaryDomainName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GciPartialTenantEntity",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name);
    }
}

export class GciPartialTenantEntityModel extends GciDirectoryPartialEntityModel implements ITypeNameObject, IUdmObjectModel, IRelatedTenantsEntityModel, IGciPrincipalModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GciEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        containedGcpRoleBindingIds: string[],
        gcpRoleBindingIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GciPartialTenantEntityModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            containedGcpRoleBindingIds,
            gcpRoleBindingIds);
    }
}

export class GciTenantConfiguration extends IdentityProviderTenantConfiguration implements ITypeNameObject, IGciScopeConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        active: boolean,
        deletePending: boolean,
        disabledOrchestrationTypes: OrchestrationType[],
        displayReference: string,
        name: string,
        orchestrationTypeToEnabledMap: Dictionary<boolean>,
        parentScopeId: string,
        scopeSections: ScopeConfigurationSections,
        type: TenantType,
        public events: GciTenantConfigurationEvents,
        public gcpOrganizationId: Optional<string>,
        public primaryDomainName: string,
        public rawId: string,
        public samlSsoEnabled: boolean,
        public secondaryDomainNames: string[],
        public serviceAccountMail: string,
        public syncEnabled: boolean,
        public userMail: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GciTenantConfiguration",
            active,
            deletePending,
            disabledOrchestrationTypes,
            displayReference,
            name,
            orchestrationTypeToEnabledMap,
            parentScopeId,
            scopeSections,
            type);
    }
}

export class GciTenantEntity extends GciDirectoryEntity implements ITypeNameObject, IDirectoryEntity, IGciDirectoryGroupMemberPrincipal, IGciTenantEntity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        creationTime: Optional<string>,
        public primaryDomain: GciTenantEntityDomain,
        public primaryDomainName: string,
        public secondaryDomains: GciTenantEntityDomain[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GciTenantEntity",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            creationTime);
    }
}

export class GciTenantEntityModel extends GciDirectoryEntityModel implements ITypeNameObject, IUdmObjectModel, IGciPrincipalModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GciEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        public gcpRoleBindingIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GciTenantEntityModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown);
    }
}

export class GciTenantState extends IdentityProviderTenantState implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        public monitoring: GciTenantStateMonitoring,
        orchestration: TenantStateOrchestration,
        permissionManagementEnabled: boolean,
        public events: GciTenantStateEvents,
        public gcp: Optional<GciTenantStateGcp>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GciTenantState",
            monitoring,
            orchestration,
            permissionManagementEnabled);
    }
}

export class GcpAccessPrincipalRisk extends EntityRisk implements ITypeNameObject, IAccessPrincipalRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public groupIdentityIds: string[],
        public principalMail: string,
        public signInActivity: Optional<Activity>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GcpAccessPrincipalRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpAccessPrincipalRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public principalCreationTime: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpActivityRisk extends CloudRisk implements ITypeNameObject, IActivityRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType);
    }
}

export class GcpActivityRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        risk: Risk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public originatorEntityIdReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpApiKeysKey extends GcpScopeResource implements ITypeNameObject, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        public restrictions: GcpApiKeysKeyRestrictions) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpApiKeysKey",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId);
    }
}

export class GcpApiKeysKeyClientRestrictionNotExistsCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpApiKeysKeyClientRestrictionNotExistsCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class GcpApiKeysKeyClientRestrictionNotExistsCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpApiKeysKeyClientRestrictionNotExistsCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpApiKeysKeyClientRestrictionNotExistsCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class GcpApiKeysKeyClientRestrictionNotExistsRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpApiKeysKeyClientRestrictionNotExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GcpApiKeysKeyClientRestrictionNotExistsRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpApiKeysKeyClientRestrictionNotExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpApiKeysKeyClientRestrictionNotExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpApiKeysKeyExistsRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public apiKeysKeyIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpApiKeysKeyExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GcpApiKeysKeyExistsRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpApiKeysKeyExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpApiKeysKeyExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpApiKeysKeyModel extends GcpScopeResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpApiKeysKeyModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpApiKeysKeyNotRotatedRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public keyRotationTimeFrame: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpApiKeysKeyNotRotatedRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GcpApiKeysKeyNotRotatedRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpApiKeysKeyNotRotatedRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpApiKeysKeyNotRotatedRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpApiKeysKeyNotRotatedRiskPolicyUpdateAuditEventKeyRotationTimeFrameChange extends RiskPolicyUpdateAuditEventChange implements ITypeNameObject {
    constructor(
        public keyRotationTimeFrame: Optional<string>,
        public previousKeyRotationTimeFrame: Optional<string>) {
        super("GcpApiKeysKeyNotRotatedRiskPolicyUpdateAuditEventKeyRotationTimeFrameChange");
    }
}

export class GcpApiKeysKeyServiceRestrictionNotExistsCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpApiKeysKeyServiceRestrictionNotExistsCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class GcpApiKeysKeyServiceRestrictionNotExistsCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpApiKeysKeyServiceRestrictionNotExistsCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpApiKeysKeyServiceRestrictionNotExistsCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class GcpApiKeysKeyServiceRestrictionNotExistsRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpApiKeysKeyServiceRestrictionNotExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GcpApiKeysKeyServiceRestrictionNotExistsRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpApiKeysKeyServiceRestrictionNotExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpApiKeysKeyServiceRestrictionNotExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpAppEngineApplication extends GcpScopeResource implements ITypeNameObject, IGcpServiceAccountOriginatorScopeResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        public authenticationDomain: string,
        public defaultBucketAssetPath: Optional<string>,
        public dnsName: string,
        public firewallRules: GcpAppEngineApplicationFirewallRule[],
        public serviceAccountData: GcpServiceAccountOriginatorServiceAccountData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpAppEngineApplication",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId);
    }
}

export class GcpAppEngineApplicationModel extends GcpScopeResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IGcpServiceAccountOriginatorScopeResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[],
        public defaultBucketId: Optional<string>,
        public serviceAccountIdReference: Optional<string>,
        public serviceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpAppEngineApplicationModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpAppEngineApplicationService extends GcpScopeResource implements ITypeNameObject, IGcpServerlessNetworkedResource, IGcpServiceAccountOriginatorScopeResource, IGcpTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        public applicationId: string,
        public applicationName: string,
        public dnsName: string,
        public networkInboundAccessType: Optional<GcpServerlessNetworkedResourceNetworkInboundAccessType>,
        public serviceAccountData: GcpServiceAccountOriginatorServiceAccountData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpAppEngineApplicationService",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId);
    }
}

export class GcpAppEngineApplicationServiceEnvironmentVariableSecretExistsCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpAppEngineApplicationServiceEnvironmentVariableSecretExistsCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class GcpAppEngineApplicationServiceEnvironmentVariableSecretExistsCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpAppEngineApplicationServiceEnvironmentVariableSecretExistsCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpAppEngineApplicationServiceEnvironmentVariableSecretExistsCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class GcpAppEngineApplicationServiceEnvironmentVariableSecretExistsRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public applicationId: string,
        public excludedSecretEnvironmentVariableNames: string[],
        public nonPublicReadPermissionActions: string[],
        public principalIdToExternalMap: Dictionary<boolean>,
        public publicReadPermissionActions: string[],
        public revisionIdToSecretEnvironmentVariableNamesMap: Dictionary<string[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpAppEngineApplicationServiceEnvironmentVariableSecretExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GcpAppEngineApplicationServiceEnvironmentVariableSecretExistsRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel, ISecretExistsRiskModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpAppEngineApplicationServiceEnvironmentVariableSecretExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public deployVersionDocumentationUrl: string,
        public secretCount: number,
        public secretEnvironmentVariableNames: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpAppEngineApplicationServiceEnvironmentVariableSecretExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpAppEngineApplicationServiceModel extends GcpScopeResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IContainerImageResourceModel, IGcpServiceAccountOriginatorScopeResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[],
        public containerImageIdReferences: string[],
        public containerImageScanStatus: Optional<ContainerImageDataScanStatus>,
        public containerImageVulnerabilities: VulnerabilityData[],
        public revisionIds: string[],
        public revisionMaxCreationTime: Optional<string>,
        public serviceAccountIdReference: Optional<string>,
        public status: GcpAppEngineApplicationResourceStatus) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpAppEngineApplicationServiceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpAppEngineApplicationServiceModelFilters extends GcpResourceModelFilters implements IEntityModelFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        openRelatedEntityRiskTypeIdentifierItems: FilterItems<string>,
        regionIdItems: FilterItems<string>,
        tenantIdItems: FilterItems<string>,
        typeNameItems: FilterItems<string>,
        creationTimeRange: FilterTimeRange,
        updateTimeRange: FilterTimeRange,
        public revisionMaxCreationTimeRange: FilterTimeRange) {
        super(
            attributeValueItems,
            openRelatedEntityRiskTypeIdentifierItems,
            regionIdItems,
            tenantIdItems,
            typeNameItems,
            creationTimeRange,
            updateTimeRange);
    }
}

export class GcpAppEngineApplicationServiceRevision extends GcpScopeResource implements ITypeNameObject, IContainerImageResource, IGcpServiceAccountOriginatorScopeResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        public containerImageRawUrls: string[],
        public creatorUserMail: Optional<string>,
        public environment: GcpAppEngineApplicationServiceRevisionEnvironment,
        public environmentVariableNameToValueMap: Dictionary<string>,
        public instanceClass: string,
        public instanceCount: Optional<number>,
        public runtime: string,
        public scaling: GcpAppEngineApplicationServiceRevisionScaling,
        public securityLevel: GcpAppEngineApplicationServiceRevisionSecurityLevel,
        public serviceAccountData: GcpServiceAccountOriginatorServiceAccountData,
        public serviceId: string,
        public status: GcpAppEngineApplicationResourceStatus,
        public url: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpAppEngineApplicationServiceRevision",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId);
    }
}

export class GcpAppEngineApplicationServiceRevisionModel extends GcpScopeResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[],
        public containerImageIdReferences: string[],
        public containerImageScanStatus: Optional<ContainerImageDataScanStatus>,
        public containerImageVulnerabilities: VulnerabilityData[],
        public creatorUserIdReference: Optional<string>,
        public serviceAccountIdReference: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpAppEngineApplicationServiceRevisionModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpAppEngineApplicationServiceUnencryptedTransportExistsCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpAppEngineApplicationServiceUnencryptedTransportExistsCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class GcpAppEngineApplicationServiceUnencryptedTransportExistsCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpAppEngineApplicationServiceUnencryptedTransportExistsCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpAppEngineApplicationServiceUnencryptedTransportExistsCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class GcpAppEngineApplicationServiceUnencryptedTransportExistsRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public unencryptedTransportRevisionIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpAppEngineApplicationServiceUnencryptedTransportExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GcpAppEngineApplicationServiceUnencryptedTransportExistsRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpAppEngineApplicationServiceUnencryptedTransportExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public appEngineApplicationDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpAppEngineApplicationServiceUnencryptedTransportExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpArtifactContainerImageRepository extends GcpEntity implements ITypeNameObject, IContainerImageRepository, IGcpArtifactRepository {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        public containerImageCount: number,
        public containerImageIds: string[],
        public containerImageTags: string[],
        public name: string,
        public rawUrl: string,
        public registryId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpArtifactContainerImageRepository",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime);
    }
}

export class GcpArtifactContainerImageRepositoryModel extends GcpEntityModel implements ITypeNameObject, IUdmObjectModel, IContainerImageRepositoryModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        public containerImageResourceIds: string[],
        public containerImageScanStatus: Optional<ContainerImageDataScanStatus>,
        public containerImageVulnerabilities: VulnerabilityData[],
        public registrySearchableReference: EntitySearchableReference) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpArtifactContainerImageRepositoryModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime);
    }
}

export class GcpArtifactPackageRepository extends GcpEntity implements ITypeNameObject, IEntity, IGcpArtifactRepository {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        public name: string,
        public packageCount: number,
        public packages: GcpArtifactPackageRepositoryPackage[],
        public packageTags: string[],
        public registryId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpArtifactPackageRepository",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime);
    }
}

export class GcpArtifactPackageRepositoryModel extends GcpEntityModel implements ITypeNameObject, IEntityModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        public registrySearchableReference: EntitySearchableReference,
        public registryType: GcpArtifactRegistryType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpArtifactPackageRepositoryModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime);
    }
}

export class GcpArtifactPackageRepositoryNpmPackage extends GcpArtifactPackageRepositoryPackage implements ITypeNameObject {
    constructor(
        creationTime: string,
        name: string,
        tags: string[],
        updateTime: string,
        public version: string) {
        super(
            "GcpArtifactPackageRepositoryNpmPackage",
            creationTime,
            name,
            tags,
            updateTime);
    }
}

export class GcpArtifactRegistry extends GcpScopeResource implements ITypeNameObject, IResource, IGcpEncryptedResource, IGcpTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        public artifactCount: number,
        public description: string,
        public encryptionExists: boolean,
        public kmsEncryptionKeyAssetPaths: string[],
        public storageSize: number,
        public type: GcpArtifactRegistryType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpArtifactRegistry",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId);
    }
}

export class GcpArtifactRegistryModel extends GcpScopeResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IGcpEncryptedResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[],
        public kmsEncryptionKeyReferences: GcpScopeResourceReference[],
        public repositoryIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpArtifactRegistryModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpArtifactRegistryPublicAccessExistsCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpArtifactRegistryPublicAccessExistsCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class GcpArtifactRegistryPublicAccessExistsCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpArtifactRegistryPublicAccessExistsCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpArtifactRegistryPublicAccessExistsCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class GcpAssetGenericResource extends GcpScopeResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        public parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId);
    }
}

export class GcpAssetGenericResourceModel extends GcpScopeResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpAssetGenericResourceTypeMetadata extends GcpScopeResourceTypeMetadata {
    constructor() {
        super();
    }
}

export class GcpAssuredWorkloadsWorkloadGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpAssuredWorkloadsWorkloadGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpBackupdrBackupPlanAssociationsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpBackupdrBackupPlanAssociationsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpBackupdrBackupPlansGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpBackupdrBackupPlansGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpBackupdrManagementServersGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpBackupdrManagementServersGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpBatchJobsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpBatchJobsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpBehaviorIdentityRisk extends GcpActivityRisk implements ITypeNameObject, IActivityRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        public endTime: string,
        public eventActions: string[],
        public identityId: string,
        public ipAddresses: string[],
        public previousBehaviorDate: Optional<string>,
        public severityInfos: GcpBehaviorIdentityRiskSeverityInfo[],
        public startTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType);
    }
}

export class GcpBehaviorIdentityRiskModel extends GcpActivityRiskModel implements ITypeNameObject, IUdmObjectModel, IBehaviorIdentityRiskModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpBehaviorIdentityRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        originatorEntityIdReferences: string[],
        public infos: GcpBehaviorIdentityRiskModelInfo[],
        public resourceIdReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            originatorEntityIdReferences);
    }
}

export class GcpBehaviorIdentityRiskPolicyGroupFilters extends RiskPolicyTypeGroupFilters {
    constructor(
        public eventActionItems: FilterItems<string>) {
        super();
    }
}

export class GcpBeyondcorpAppConnectionsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpBeyondcorpAppConnectionsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpBeyondcorpAppConnectorsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpBeyondcorpAppConnectorsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpBeyondcorpAppGatewaysGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpBeyondcorpAppGatewaysGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpBeyondcorpClientConnectorServicesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpBeyondcorpClientConnectorServicesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpBeyondcorpClientGatewaysGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpBeyondcorpClientGatewaysGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpBigQueryDatasetEncryptionDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpBigQueryDatasetEncryptionDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class GcpBigQueryDatasetEncryptionDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpBigQueryDatasetEncryptionDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpBigQueryDatasetEncryptionDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class GcpBigQueryDatasetEncryptionDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpBigQueryDatasetEncryptionDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GcpBigQueryDatasetEncryptionDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpBigQueryDatasetEncryptionDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public managedEncryptionDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpBigQueryDatasetEncryptionDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpBigQueryDatasetModel extends GcpScopeResourceModel implements ITypeNameObject, IUdmObjectModel, IDataAnalysisResourceModel, IGcpEncryptedResourceModel, IGcpLoggingLogSinkDestinationResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[],
        public dataAnalysisError: Optional<DataAnalysisResourceModelDataAnalysisError>,
        public dataAnalysisPriorityScanRequested: boolean,
        public dataAnalysisResourceSensitivity: Optional<DataAnalysisSensitivity>,
        public dataAnalysisStatus: DataAnalysisResourceModelDataAnalysisStatus,
        public dataAnalysisStatusSortValue: number,
        public dataCategoryToDataSegmentCountMap: Optional<Dictionary<number>>,
        public dataCategoryToSensitivityMap: Optional<Dictionary<DataSensitivity>>,
        public dataClassifierIdToDataSegmentCountMap: Optional<Dictionary<number>>,
        public dataLastModified: Optional<string>,
        public dataSensitivities: Optional<DataSensitivity[]>,
        public dataSensitivityToDataSegmentCountMap: Optional<Dictionary<number>>,
        public highestDataSensitivity: Optional<DataSensitivity>,
        public kmsEncryptionKeyReferences: GcpScopeResourceReference[],
        public loggingLogSinkIds: string[],
        public scannedDataSegmentCount: Optional<number>,
        public scanTime: Optional<string>,
        public sensitiveDataSegmentCount: Optional<number>,
        public storageSize: Optional<number>,
        public tableCount: number) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpBigQueryDatasetModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpBigQueryDatasetModelFilters extends GcpResourceModelFilters implements IEntityModelFilters, IDataAnalysisResourceModelFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        openRelatedEntityRiskTypeIdentifierItems: FilterItems<string>,
        regionIdItems: FilterItems<string>,
        tenantIdItems: FilterItems<string>,
        typeNameItems: FilterItems<string>,
        creationTimeRange: FilterTimeRange,
        updateTimeRange: FilterTimeRange,
        public dataAnalysisResource: DataAnalysisResourceModelFiltersSection) {
        super(
            attributeValueItems,
            openRelatedEntityRiskTypeIdentifierItems,
            regionIdItems,
            tenantIdItems,
            typeNameItems,
            creationTimeRange,
            updateTimeRange);
    }
}

export class GcpBigQueryDatasetPublicAccessExistsCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpBigQueryDatasetPublicAccessExistsCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class GcpBigQueryDatasetPublicAccessExistsCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpBigQueryDatasetPublicAccessExistsCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpBigQueryDatasetPublicAccessExistsCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class GcpBigQueryDatasetsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpBigQueryDatasetsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpBigQueryDatasetTableEncryptionDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpBigQueryDatasetTableEncryptionDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class GcpBigQueryDatasetTableEncryptionDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpBigQueryDatasetTableEncryptionDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpBigQueryDatasetTableEncryptionDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class GcpBigQueryDatasetTableModel extends GcpScopeResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IGcpEncryptedResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[],
        public kmsEncryptionKeyReferences: GcpScopeResourceReference[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpBigQueryDatasetTableModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpBigquerymigrationMigrationWorkflowsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpBigquerymigrationMigrationWorkflowsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpBigQueryModelsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource, IGcpTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpBigQueryModelsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpBigQueryModelsGenericResourceModel extends GcpAssetGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpBigQueryModelsGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpBigQueryResource extends GcpScopeResource implements ITypeNameObject, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        public description: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId);
    }
}

export class GcpBigQueryTablesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpBigQueryTablesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpBigtableAppProfilesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpBigtableAppProfilesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpBigtableBackupsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpBigtableBackupsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpBigtableClustersGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource, IGcpTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpBigtableClustersGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpBigtableClustersGenericResourceModel extends GcpAssetGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpBigtableClustersGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpBigtableInstancesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource, IGcpTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpBigtableInstancesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpBigtableInstancesGenericResourceModel extends GcpAssetGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpBigtableInstancesGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpBigtableTablesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource, IGcpTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpBigtableTablesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpBigtableTablesGenericResourceModel extends GcpAssetGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpBigtableTablesGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpBillingAccountsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpBillingAccountsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpBillingProjectBillingInfosGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpBillingProjectBillingInfosGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpBlockchainnodeengineBlockchainNodesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpBlockchainnodeengineBlockchainNodesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpCertificatemanagerCertificateIssuanceConfigsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpCertificatemanagerCertificateIssuanceConfigsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpCertificatemanagerCertificateMapEntriesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpCertificatemanagerCertificateMapEntriesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpCertificatemanagerCertificateMapsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpCertificatemanagerCertificateMapsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpCertificatemanagerCertificatesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpCertificatemanagerCertificatesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpCertificatemanagerDnsauthorizationsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpCertificatemanagerDnsauthorizationsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpCertificatemanagerTrustconfigsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpCertificatemanagerTrustconfigsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpChange extends Change implements ITypeNameObject {
    constructor(
        typeName: string,
        entityIds: string[],
        id: string,
        resolved: boolean) {
        super(
            typeName,
            entityIds,
            id,
            resolved);
    }
}

export class GcpCloudaicompanionCodeRepositoryIndicesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpCloudaicompanionCodeRepositoryIndicesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpCloudaicompanionRepositoryGroupsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpCloudaicompanionRepositoryGroupsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpCloudAssetFeedsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpCloudAssetFeedsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpCloudBuildBitbucketServerConfigsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpCloudBuildBitbucketServerConfigsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpCloudBuildBuildsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpCloudBuildBuildsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpCloudBuildBuildTrigger extends GcpScopeResource implements ITypeNameObject, IGcpServiceAccountOriginatorScopeResource, IGcpTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        public enabled: boolean,
        public serviceAccountData: GcpServiceAccountOriginatorServiceAccountData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpCloudBuildBuildTrigger",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId);
    }
}

export class GcpCloudBuildBuildTriggerDefaultServiceAccountRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public principalIdToExternalMap: Dictionary<boolean>,
        public updatePermissionAction: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpCloudBuildBuildTriggerDefaultServiceAccountRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GcpCloudBuildBuildTriggerDefaultServiceAccountRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpCloudBuildBuildTriggerDefaultServiceAccountRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public createCustomServiceAccountUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpCloudBuildBuildTriggerDefaultServiceAccountRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpCloudBuildBuildTriggerModel extends GcpScopeResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IGcpServiceAccountOriginatorScopeResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[],
        public serviceAccountIdReference: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpCloudBuildBuildTriggerModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpCloudBuildBuildTriggersGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpCloudBuildBuildTriggersGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpCloudBuildConnectionsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpCloudBuildConnectionsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpCloudBuildGithubEnterpriseConfigsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpCloudBuildGithubEnterpriseConfigsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpCloudBuildGlobalTriggerSettingsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpCloudBuildGlobalTriggerSettingsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpCloudBuildInstallationsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpCloudBuildInstallationsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpCloudBuildRepositoriesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpCloudBuildRepositoriesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpCloudBuildWorkerpoolsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpCloudBuildWorkerpoolsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpCloudcontrolspartnerCustomersGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpCloudcontrolspartnerCustomersGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpCloudcontrolspartnerPartnersGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpCloudcontrolspartnerPartnersGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpCloudcontrolspartnerWorkloadGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpCloudcontrolspartnerWorkloadGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpClouddeployDeliveryPipelinesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpClouddeployDeliveryPipelinesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpClouddeployReleasesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpClouddeployReleasesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpClouddeployRolloutsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpClouddeployRolloutsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpClouddeployTargetsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpClouddeployTargetsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpCloudFunctionsFunctionsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpCloudFunctionsFunctionsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpCloudKmsAutokeyConfigsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpCloudKmsAutokeyConfigsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpCloudKmsCryptoKeysGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpCloudKmsCryptoKeysGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpCloudKmsCryptoKeyVersionsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpCloudKmsCryptoKeyVersionsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpCloudKmsEkmConfigsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpCloudKmsEkmConfigsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpCloudKmsEkmConnectionsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpCloudKmsEkmConnectionsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpCloudKmsImportJobsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpCloudKmsImportJobsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpCloudKmsKeyHandlesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpCloudKmsKeyHandlesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpCloudKmsKeyRingsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpCloudKmsKeyRingsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpCloudquotasQuotaPreferencesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpCloudquotasQuotaPreferencesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpCloudRunService extends GcpScopeResource implements ITypeNameObject, IGcpServerlessNetworkedResource, IGcpServiceAccountOriginatorScopeResource, IGcpTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        public creatorIdentityMail: string,
        public networkInboundAccessType: Optional<GcpServerlessNetworkedResourceNetworkInboundAccessType>,
        public serviceAccountData: GcpServiceAccountOriginatorServiceAccountData,
        public updaterRawIdentifier: string,
        public url: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpCloudRunService",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId);
    }
}

export class GcpCloudRunServiceEnvironmentVariableSecretExistsCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpCloudRunServiceEnvironmentVariableSecretExistsCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class GcpCloudRunServiceEnvironmentVariableSecretExistsCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpCloudRunServiceEnvironmentVariableSecretExistsCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpCloudRunServiceEnvironmentVariableSecretExistsCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class GcpCloudRunServiceEnvironmentVariableSecretExistsRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public excludedSecretEnvironmentVariableNames: string[],
        public nonPublicReadPermissionActions: string[],
        public principalIdToExternalMap: Dictionary<boolean>,
        public publicReadPermissionActions: string[],
        public revisionIdToSecretEnvironmentVariableNamesMap: Dictionary<string[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpCloudRunServiceEnvironmentVariableSecretExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GcpCloudRunServiceEnvironmentVariableSecretExistsRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel, ISecretExistsRiskModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpCloudRunServiceEnvironmentVariableSecretExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public secretCount: number,
        public secretEnvironmentVariableNames: string[],
        public secretManagerConsoleUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpCloudRunServiceEnvironmentVariableSecretExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpCloudRunServiceModel extends GcpScopeResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IContainerImageResourceModel, IGcpServiceAccountOriginatorScopeResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[],
        public containerImageIdReferences: string[],
        public containerImageScanStatus: Optional<ContainerImageDataScanStatus>,
        public containerImageVulnerabilities: VulnerabilityData[],
        public functionIds: string[],
        public revisionIds: string[],
        public revisionLastDeployerIdentityIdReference: Optional<string>,
        public revisionLastDeployerRawIdentifier: Optional<string>,
        public revisionMaxCreationTime: Optional<string>,
        public serviceAccountIdReference: Optional<string>,
        public triggerIds: string[],
        public updaterIdentityIdReference: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpCloudRunServiceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpCloudRunServicePublicAccessExistsCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpCloudRunServicePublicAccessExistsCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class GcpCloudRunServicePublicAccessExistsCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpCloudRunServicePublicAccessExistsCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpCloudRunServicePublicAccessExistsCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class GcpCloudRunServiceRevision extends GcpScopeResource implements ITypeNameObject, IContainerImageResource, IGcpEncryptedResource, IGcpServiceAccountOriginatorScopeResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        public cloudSqlInstanceConnectionName: Optional<string>,
        public containerImageRawUrls: string[],
        public containerPort: Optional<number>,
        public deployerRawIdentifier: string,
        public encryptionExists: boolean,
        public environmentVariableNameToValueMap: Dictionary<string>,
        public kmsEncryptionKeyAssetPaths: string[],
        public maxInstances: Optional<number>,
        public secretAssetPaths: string[],
        public serviceAccountData: GcpServiceAccountOriginatorServiceAccountData,
        public serviceId: string,
        public trafficPercent: number) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpCloudRunServiceRevision",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId);
    }
}

export class GcpCloudRunServiceRevisionModel extends GcpScopeResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IGcpEncryptedResourceModel, IGcpServiceAccountOriginatorScopeResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[],
        public cloudSqlInstanceIdReference: Optional<string>,
        public containerImageIdReferences: string[],
        public containerImageScanStatus: Optional<ContainerImageDataScanStatus>,
        public containerImageVulnerabilities: VulnerabilityData[],
        public deployerIdentityIdReference: Optional<string>,
        public kmsEncryptionKeyReferences: GcpScopeResourceReference[],
        public secretIdReferences: string[],
        public serviceAccountIdReference: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpCloudRunServiceRevisionModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpCloudSqlBackupRunsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpCloudSqlBackupRunsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpCloudSqlBackupsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpCloudSqlBackupsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpCloudSqlInstancesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpCloudSqlInstancesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpCloudTasksQueuesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpCloudTasksQueuesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpComposerEnvironmentsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComposerEnvironmentsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpComputeAddressesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeAddressesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpComputeAutoscalerModel extends GcpScopeResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeAutoscalerModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpComputeAutoscalersGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeAutoscalersGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpComputeBackendBucketsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeBackendBucketsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpComputeBackendServiceLoggingNotEnabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeBackendServiceLoggingNotEnabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class GcpComputeBackendServiceLoggingNotEnabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpComputeBackendServiceLoggingNotEnabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeBackendServiceLoggingNotEnabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class GcpComputeBackendServiceLoggingNotEnabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeBackendServiceLoggingNotEnabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GcpComputeBackendServiceLoggingNotEnabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpComputeBackendServiceLoggingNotEnabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeBackendServiceLoggingNotEnabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpComputeBackendServicesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeBackendServicesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpComputeCommitmentsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeCommitmentsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpComputeDiskModel extends GcpScopeResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IGcpEncryptedResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[],
        public instanceIdReferences: string[],
        public kmsEncryptionKeyReferences: GcpScopeResourceReference[],
        public sourceIdReference: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeDiskModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpComputeDisksGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeDisksGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpComputeExternalVpnGatewaysGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource, IGcpTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeExternalVpnGatewaysGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpComputeExternalVpnGatewaysGenericResourceModel extends GcpAssetGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeExternalVpnGatewaysGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpComputeFirewallPoliciesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeFirewallPoliciesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpComputeFirewallPolicyModel extends GcpScopeResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IGciGcpResourceModel, IRelatedTenantsEntityModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[],
        public attachedResourceIdReferences: string[],
        public gciParentResourceData: Optional<GciParentResourceData>,
        public targetServiceAccountMailToIdReferenceMap: Dictionary<string>,
        public targetVpcUrlToIdReferenceMap: Dictionary<string>,
        public vpcIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeFirewallPolicyModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpComputeFirewallRuleModel extends GcpScopeResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[],
        public filterInboundVpcServiceAccountIdReferences: string[],
        public targetServiceAccountIdReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeFirewallRuleModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpComputeFirewallsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeFirewallsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpComputeForwardingRulesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeForwardingRulesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpComputeGlobalAddressesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeGlobalAddressesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpComputeGlobalForwardingRulesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeGlobalForwardingRulesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpComputeHealthChecksGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeHealthChecksGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpComputeHttpHealthChecksGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeHttpHealthChecksGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpComputeHttpsHealthChecksGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeHttpsHealthChecksGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpComputeImageModel extends GcpScopeResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IGcpEncryptedResourceModel, IRelatedTenantsEntityModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[],
        public instanceIdReferences: string[],
        public kmsEncryptionKeyReferences: GcpScopeResourceReference[],
        public sourceIdReference: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeImageModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpComputeImagePublicAccessExistsCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeImagePublicAccessExistsCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class GcpComputeImagePublicAccessExistsCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpComputeImagePublicAccessExistsCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeImagePublicAccessExistsCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class GcpComputeImagesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeImagesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpComputeInstanceBlockProjectWideSshKeysNotEnabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeInstanceBlockProjectWideSshKeysNotEnabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class GcpComputeInstanceBlockProjectWideSshKeysNotEnabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpComputeInstanceBlockProjectWideSshKeysNotEnabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeInstanceBlockProjectWideSshKeysNotEnabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class GcpComputeInstanceDefaultServiceAccountCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeInstanceDefaultServiceAccountCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class GcpComputeInstanceDefaultServiceAccountCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpComputeInstanceDefaultServiceAccountCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeInstanceDefaultServiceAccountCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class GcpComputeInstanceGroupManagerModel extends GcpScopeResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeInstanceGroupManagerModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpComputeInstanceGroupManagersGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeInstanceGroupManagersGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpComputeInstanceGroupModel extends GcpScopeResourceModel implements ITypeNameObject, IUdmObjectModel, INetworkAccessResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        public entityNetwork: Optional<NetworkAccessResourceStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[],
        public backendServiceIds: string[],
        public instanceGroupManagerStatus: Optional<GcpComputeInstanceGroupManagerStatus>,
        public instanceIds: string[],
        public instanceTemplateIdReference: Optional<string>,
        public maxCapacity: Optional<number>,
        public minCapacity: Optional<number>,
        public nodePoolId: Optional<string>,
        public subnetId: Optional<string>,
        public type: GcpInstanceGroupType,
        public vpcIdReference: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeInstanceGroupModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpComputeInstanceGroupModelFilters extends GcpResourceModelFilters implements IEntityModelFilters, IEntityNetworkFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        openRelatedEntityRiskTypeIdentifierItems: FilterItems<string>,
        regionIdItems: FilterItems<string>,
        tenantIdItems: FilterItems<string>,
        typeNameItems: FilterItems<string>,
        creationTimeRange: FilterTimeRange,
        updateTimeRange: FilterTimeRange,
        public networkAccess: EntityNetworkAccessFiltersSection) {
        super(
            attributeValueItems,
            openRelatedEntityRiskTypeIdentifierItems,
            regionIdItems,
            tenantIdItems,
            typeNameItems,
            creationTimeRange,
            updateTimeRange);
    }
}

export class GcpComputeInstanceGroupsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeInstanceGroupsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpComputeInstanceIpForwardingEnabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeInstanceIpForwardingEnabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class GcpComputeInstanceIpForwardingEnabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpComputeInstanceIpForwardingEnabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeInstanceIpForwardingEnabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class GcpComputeInstanceMemoryEncryptionNotEnabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeInstanceMemoryEncryptionNotEnabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class GcpComputeInstanceMemoryEncryptionNotEnabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpComputeInstanceMemoryEncryptionNotEnabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeInstanceMemoryEncryptionNotEnabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class GcpComputeInstanceModel extends GcpScopeResourceModel implements ITypeNameObject, IUdmObjectModel, IGcpServiceAccountOriginatorScopeResourceModel, IVirtualMachineModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        public entityNetwork: Optional<NetworkAccessResourceStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[],
        public bootDiskIdReference: Optional<string>,
        public bootDiskSourceResourceIdReference: Optional<string>,
        public diskEncryptionVaultIdReference: Optional<string>,
        public diskIdReferences: string[],
        public dnsZoneIds: string[],
        public effectiveSshIamEnabled: Optional<boolean>,
        public effectiveSshSerialPortEnabled: Optional<GcpComputeInstanceScopePropertyData>,
        public identityPermissionActionSeverity: Optional<Severity>,
        public instanceGroupIds: string[],
        public instanceTemplateIdReference: Optional<string>,
        public kubernetesClusterSensor: boolean,
        public operatingSystemReleaseNotesUrl: Optional<string>,
        public operatingSystemType: Optional<OperatingSystemType>,
        public scanTime: Optional<string>,
        public serviceAccountIdReference: Optional<string>,
        public stopped: boolean,
        public stopTime: Optional<string>,
        public subnetIdReferences: string[],
        public targetPoolIds: string[],
        public vpcIdReferences: string[],
        public vulnerabilities: Optional<VulnerabilityData[]>,
        public vulnerabilitySeveritiesSortValue: string,
        public workloadAnalysisError: Optional<WorkloadAnalysisError>,
        public workloadAnalysisOperatingSystem: Optional<WorkloadResourceScanOperatingSystem>,
        public workloadAnalysisOperatingSystemEndOfLifeDate: Optional<string>,
        public workloadAnalysisOperatingSystemExtendedSupport: Optional<boolean>,
        public workloadAnalysisPriorityScanRequested: boolean,
        public workloadAnalysisStatus: WorkloadAnalysisStatus,
        public workloadAnalysisStatusSortValue: number) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeInstanceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpComputeInstanceModelFilters extends GcpResourceModelFilters implements IEntityModelFilters, IEntityNetworkFilters, IVirtualMachineDiskResourceFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        openRelatedEntityRiskTypeIdentifierItems: FilterItems<string>,
        regionIdItems: FilterItems<string>,
        tenantIdItems: FilterItems<string>,
        typeNameItems: FilterItems<string>,
        creationTimeRange: FilterTimeRange,
        updateTimeRange: FilterTimeRange,
        public networkAccess: EntityNetworkAccessFiltersSection,
        public scanTimeRange: FilterTimeRange,
        public stopTimeRange: FilterTimeRange) {
        super(
            attributeValueItems,
            openRelatedEntityRiskTypeIdentifierItems,
            regionIdItems,
            tenantIdItems,
            typeNameItems,
            creationTimeRange,
            updateTimeRange);
    }
}

export class GcpComputeInstancesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeInstancesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpComputeInstanceShieldedNotEnabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeInstanceShieldedNotEnabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class GcpComputeInstanceShieldedNotEnabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpComputeInstanceShieldedNotEnabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeInstanceShieldedNotEnabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class GcpComputeInstanceSshSerialPortEnabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeInstanceSshSerialPortEnabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class GcpComputeInstanceSshSerialPortEnabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpComputeInstanceSshSerialPortEnabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeInstanceSshSerialPortEnabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class GcpComputeInstanceStartupScriptSecretExistsCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeInstanceStartupScriptSecretExistsCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class GcpComputeInstanceStartupScriptSecretExistsCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpComputeInstanceStartupScriptSecretExistsCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeInstanceStartupScriptSecretExistsCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class GcpComputeInstanceTemplateModel extends GcpScopeResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IGcpServiceAccountOriginatorScopeResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[],
        public effectiveSshIamEnabled: Optional<boolean>,
        public effectiveSshSerialPortEnabled: Optional<GcpComputeInstanceScopePropertyData>,
        public instanceGroupIds: string[],
        public instanceIds: string[],
        public serviceAccountIdReference: Optional<string>,
        public vpcIdReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeInstanceTemplateModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpComputeInstanceTemplatesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeInstanceTemplatesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpComputeInstantSnapshotsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeInstantSnapshotsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpComputeInterconnectAttachmentsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeInterconnectAttachmentsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpComputeInterconnectsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeInterconnectsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpComputeLicensesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeLicensesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpComputeLoadBalancer extends GcpEntity implements ITypeNameObject, IGcpDnsRecordEntity, IGcpNetworkEvaluationEntity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        public dnsNames: Optional<string[]>,
        public loadBalancingScheme: GcpComputeLoadBalancerLoadBalancingScheme,
        public name: string,
        public protocols: GcpComputeLoadBalancerProtocol[],
        public publicIpAddresses: string[],
        public resources: GcpComputeLoadBalancerResources,
        public sourceResourceId: string,
        public type: GcpComputeLoadBalancerType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeLoadBalancer",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime);
    }
}

export class GcpComputeLoadBalancerInsecureSslPolicyCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeLoadBalancerInsecureSslPolicyCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class GcpComputeLoadBalancerInsecureSslPolicyCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpComputeLoadBalancerInsecureSslPolicyCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeLoadBalancerInsecureSslPolicyCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class GcpComputeLoadBalancerModel extends GcpEntityModel implements ITypeNameObject, IEntityModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        public creatorIdentityIdReference: Optional<string>,
        public creatorOriginatorEntityIdReference: Optional<string>,
        public dnsZoneIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeLoadBalancerModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime);
    }
}

export class GcpComputeLoadBalancerModelFilters extends GcpEntityModelFilters implements IEntityModelFilters, IEntityNetworkFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        openRelatedEntityRiskTypeIdentifierItems: FilterItems<string>,
        regionIdItems: FilterItems<string>,
        tenantIdItems: FilterItems<string>,
        typeNameItems: FilterItems<string>,
        creationTimeRange: FilterTimeRange,
        public networkAccess: EntityNetworkAccessFiltersSection) {
        super(
            attributeValueItems,
            openRelatedEntityRiskTypeIdentifierItems,
            regionIdItems,
            tenantIdItems,
            typeNameItems,
            creationTimeRange);
    }
}

export class GcpComputeLoadBalancerResourceModel extends GcpScopeResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[],
        public loadBalancerIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpComputeMachineImagesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeMachineImagesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpComputeNetworkAttachmentsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeNetworkAttachmentsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpComputeNetworkEdgeSecurityServicesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeNetworkEdgeSecurityServicesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpComputeNetworkEndpointGroupModel extends GcpScopeResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[],
        public backendServiceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeNetworkEndpointGroupModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpComputeNetworkEndpointGroupsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeNetworkEndpointGroupsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpComputeNetworksGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeNetworksGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpComputeNodeGroupsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource, IGcpTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeNodeGroupsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpComputeNodeGroupsGenericResourceModel extends GcpAssetGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeNodeGroupsGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpComputeNodeTemplatesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource, IGcpTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeNodeTemplatesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpComputeNodeTemplatesGenericResourceModel extends GcpAssetGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeNodeTemplatesGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpComputePacketMirroringsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputePacketMirroringsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpComputeProjectModel extends GcpScopeResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeProjectModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpComputeProjectsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeProjectsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpComputeProjectSshIamNotEnabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeProjectSshIamNotEnabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class GcpComputeProjectSshIamNotEnabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpComputeProjectSshIamNotEnabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeProjectSshIamNotEnabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class GcpComputeProjectSshIamNotEnabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public runningInstanceIds: string[],
        public sshIamDisabledInstanceIds: string[],
        public sshIamDisabledInstanceTemplateIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeProjectSshIamNotEnabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GcpComputeProjectSshIamNotEnabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpComputeProjectSshIamNotEnabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeProjectSshIamNotEnabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpComputePublicDelegatedPrefixesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputePublicDelegatedPrefixesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpComputeRegionBackendServicesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeRegionBackendServicesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpComputeRegionDisksGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeRegionDisksGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpComputeReservationsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeReservationsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpComputeResource extends GcpScopeResource implements ITypeNameObject, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        public url: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId);
    }
}

export class GcpComputeResourcePoliciesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeResourcePoliciesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpComputeRouteModel extends GcpScopeResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[],
        public nextHopResourceId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeRouteModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpComputeRoutersGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeRoutersGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpComputeRoutesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeRoutesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpComputeSecurityPoliciesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeSecurityPoliciesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpComputeServiceAttachmentsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeServiceAttachmentsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpComputeSnapshot extends GcpComputeResource implements ITypeNameObject, IResource, IGcpEncryptedResource, IGcpTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        url: string,
        public creationType: GcpComputeSnapshotCreationType,
        public encryptionExists: boolean,
        public kmsEncryptionKeyAssetPaths: string[],
        public size: number,
        public status: GcpComputeSnapshotStatus,
        public type: GcpComputeSnapshotType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeSnapshot",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            url);
    }
}

export class GcpComputeSnapshotModel extends GcpScopeResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IGcpEncryptedResourceModel, IRelatedTenantsEntityModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[],
        public instanceIdReferences: string[],
        public kmsEncryptionKeyReferences: GcpScopeResourceReference[],
        public sourceDiskIdReference: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeSnapshotModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpComputeSnapshotsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeSnapshotsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpComputeSslCertificateModel extends GcpComputeLoadBalancerResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[],
        loadBalancerIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeSslCertificateModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds,
            loadBalancerIds);
    }
}

export class GcpComputeSslCertificatesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeSslCertificatesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpComputeSslPoliciesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeSslPoliciesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpComputeSslPolicyModel extends GcpComputeLoadBalancerResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[],
        loadBalancerIds: string[],
        public targetProxyIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeSslPolicyModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds,
            loadBalancerIds);
    }
}

export class GcpComputeStoragePoolsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeStoragePoolsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpComputeSubnetModel extends GcpScopeResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[],
        public networkedResourceTypeNameToIdsMap: Dictionary<string[]>,
        public vpcIdReference: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeSubnetModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpComputeSubnetworksGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeSubnetworksGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpComputeTargetGrpcProxiesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeTargetGrpcProxiesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpComputeTargetHttpProxiesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeTargetHttpProxiesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpComputeTargetHttpsProxiesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeTargetHttpsProxiesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpComputeTargetInstancesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeTargetInstancesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpComputeTargetPoolModel extends GcpComputeLoadBalancerResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[],
        loadBalancerIds: string[],
        public instanceIdReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeTargetPoolModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds,
            loadBalancerIds);
    }
}

export class GcpComputeTargetPoolsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeTargetPoolsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpComputeTargetProxyModel extends GcpComputeLoadBalancerResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[],
        loadBalancerIds: string[],
        public forwardingRuleIds: string[],
        public forwardingRuleSearchableReferences: EntitySearchableReference[],
        public targetResourceIdReference: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds,
            loadBalancerIds);
    }
}

export class GcpComputeTargetSslProxiesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeTargetSslProxiesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpComputeTargetSslProxyModel extends GcpComputeTargetProxyModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[],
        loadBalancerIds: string[],
        forwardingRuleIds: string[],
        forwardingRuleSearchableReferences: EntitySearchableReference[],
        targetResourceIdReference: string,
        public sslCertificateIds: string[],
        public sslPolicyId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeTargetSslProxyModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds,
            loadBalancerIds,
            forwardingRuleIds,
            forwardingRuleSearchableReferences,
            targetResourceIdReference);
    }
}

export class GcpComputeTargetTcpProxiesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeTargetTcpProxiesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpComputeTargetTcpProxyModel extends GcpComputeTargetProxyModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[],
        loadBalancerIds: string[],
        forwardingRuleIds: string[],
        forwardingRuleSearchableReferences: EntitySearchableReference[],
        targetResourceIdReference: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeTargetTcpProxyModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds,
            loadBalancerIds,
            forwardingRuleIds,
            forwardingRuleSearchableReferences,
            targetResourceIdReference);
    }
}

export class GcpComputeTargetVpnGatewaysGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource, IGcpTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeTargetVpnGatewaysGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpComputeTargetVpnGatewaysGenericResourceModel extends GcpAssetGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeTargetVpnGatewaysGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpComputeUrlMapModel extends GcpComputeLoadBalancerResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[],
        loadBalancerIds: string[],
        public backendResourceIdReferences: string[],
        public backendResourceUrlToSearchableIdReferenceMap: Dictionary<EntitySearchableIdReference>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeUrlMapModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds,
            loadBalancerIds);
    }
}

export class GcpComputeUrlMapsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeUrlMapsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpComputeVpc extends GcpComputeResource implements ITypeNameObject, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        url: string,
        public description: Optional<string>,
        public legacy: boolean,
        public subnetUrls: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeVpc",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            url);
    }
}

export class GcpComputeVpcFlowLogsNotEnabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeVpcFlowLogsNotEnabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class GcpComputeVpcFlowLogsNotEnabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpComputeVpcFlowLogsNotEnabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeVpcFlowLogsNotEnabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class GcpComputeVpcFlowLogsNotEnabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public flowLogsNotEnabledSubnetIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeVpcFlowLogsNotEnabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GcpComputeVpcFlowLogsNotEnabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpComputeVpcFlowLogsNotEnabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeVpcFlowLogsNotEnabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpComputeVpcLegacyVpcRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeVpcLegacyVpcRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GcpComputeVpcLegacyVpcRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpComputeVpcLegacyVpcRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public deleteLegacyVpcDocumentationUrl: string,
        public updateLegacyVpcDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeVpcLegacyVpcRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpComputeVpcModel extends GcpScopeResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[],
        public associatedVpcPeeringConnectionIds: string[],
        public firewallPolicyIds: string[],
        public firewallRuleIds: string[],
        public networkedResourceTypeNameToIdsMap: Dictionary<string[]>,
        public routeIds: string[],
        public subnetIds: string[],
        public subnetRegions: string[],
        public subnetSubnets: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeVpcModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpComputeVpcPeeringConnection extends GcpResource implements ITypeNameObject, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        public accepterVpcUrl: string,
        public exportCustomRoutes: boolean,
        public exportPublicSubnetRoutes: boolean,
        public importCustomRoutes: boolean,
        public importPublicSubnetRoutes: boolean,
        public requesterVpcId: string,
        public status: GcpComputeVpcPeeringConnectionStatus,
        public supportedIpAddressFamilies: GcpComputeVpcPeeringConnectionSupportedIpAddressFamilies) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeVpcPeeringConnection",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime);
    }
}

export class GcpComputeVpcPeeringConnectionModel extends GcpResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        public accepterSubnetIds: string[],
        public accepterVpcIdReference: string,
        public accepterVpcSubnetSubnets: string[],
        public requesterVpcId: string,
        public requesterVpcSubnetSubnets: string[],
        public respectiveVpcPeeringConnectionId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeVpcPeeringConnectionModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class GcpComputeVpcUnrestrictedIcmpInboundAccessCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeVpcUnrestrictedIcmpInboundAccessCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class GcpComputeVpcUnrestrictedIcmpInboundAccessCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpComputeVpcUnrestrictedIcmpInboundAccessCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeVpcUnrestrictedIcmpInboundAccessCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class GcpComputeVpcUnrestrictedInboundAccessRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public firewallRuleIdToUnrestrictedInboundAccessMap: Dictionary<boolean>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GcpComputeVpcUnrestrictedInboundAccessRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpComputeVpcUnrestrictedInboundAccessRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public protocolName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpComputeVpcUnrestrictedRdpInboundAccessCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeVpcUnrestrictedRdpInboundAccessCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class GcpComputeVpcUnrestrictedRdpInboundAccessCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpComputeVpcUnrestrictedRdpInboundAccessCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeVpcUnrestrictedRdpInboundAccessCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class GcpComputeVpcUnrestrictedRdpInboundAccessRisk extends GcpComputeVpcUnrestrictedInboundAccessRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        firewallRuleIdToUnrestrictedInboundAccessMap: Dictionary<boolean>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeVpcUnrestrictedRdpInboundAccessRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            firewallRuleIdToUnrestrictedInboundAccessMap);
    }
}

export class GcpComputeVpcUnrestrictedRdpInboundAccessRiskModel extends GcpComputeVpcUnrestrictedInboundAccessRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpComputeVpcUnrestrictedInboundAccessRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        protocolName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeVpcUnrestrictedRdpInboundAccessRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            protocolName);
    }
}

export class GcpComputeVpcUnrestrictedSshInboundAccessCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeVpcUnrestrictedSshInboundAccessCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class GcpComputeVpcUnrestrictedSshInboundAccessCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpComputeVpcUnrestrictedSshInboundAccessCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeVpcUnrestrictedSshInboundAccessCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class GcpComputeVpcUnrestrictedSshInboundAccessRisk extends GcpComputeVpcUnrestrictedInboundAccessRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        firewallRuleIdToUnrestrictedInboundAccessMap: Dictionary<boolean>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeVpcUnrestrictedSshInboundAccessRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            firewallRuleIdToUnrestrictedInboundAccessMap);
    }
}

export class GcpComputeVpcUnrestrictedSshInboundAccessRiskModel extends GcpComputeVpcUnrestrictedInboundAccessRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpComputeVpcUnrestrictedInboundAccessRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        protocolName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeVpcUnrestrictedSshInboundAccessRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            protocolName);
    }
}

export class GcpComputeVpnGatewaysGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource, IGcpTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeVpnGatewaysGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpComputeVpnGatewaysGenericResourceModel extends GcpAssetGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeVpnGatewaysGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpComputeVpnTunnelsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeVpnTunnelsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpConfigDeploymentsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpConfigDeploymentsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpConfigPreviewsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpConfigPreviewsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpConnectorsConnectionsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpConnectorsConnectionsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpConnectorsEndpointAttachmentsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpConnectorsEndpointAttachmentsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpConnectorsEventSubscriptionsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpConnectorsEventSubscriptionsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpConnectorsManagedZonesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpConnectorsManagedZonesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpContactcenterinsightsIssueModelsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpContactcenterinsightsIssueModelsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpContactcenterinsightsPhraseMatchersGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpContactcenterinsightsPhraseMatchersGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpContactcenterinsightsViewsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpContactcenterinsightsViewsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpContainerClusterAttributeBasedAuthorizationEnabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpContainerClusterAttributeBasedAuthorizationEnabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GcpContainerClusterAttributeBasedAuthorizationEnabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpContainerClusterAttributeBasedAuthorizationEnabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpContainerClusterAttributeBasedAuthorizationEnabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpContainerClusterDefaultServiceAccountRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public nodePoolIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpContainerClusterDefaultServiceAccountRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GcpContainerClusterDefaultServiceAccountRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpContainerClusterDefaultServiceAccountRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public leastPrivilegeDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpContainerClusterDefaultServiceAccountRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpContainerClusterInboundExternalWideRangeRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpContainerClusterInboundExternalWideRangeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GcpContainerClusterInboundExternalWideRangeRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpContainerClusterInboundExternalWideRangeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpContainerClusterInboundExternalWideRangeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpContainerClusterInstanceInboundExternalRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public instanceDatas: GcpContainerClusterInstanceInboundExternalRiskInstanceData[],
        public nodePublicIpAddressExistsNodePoolIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpContainerClusterInstanceInboundExternalRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GcpContainerClusterInstanceInboundExternalRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpContainerClusterInstanceInboundExternalRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public containerClusterPublicNatDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpContainerClusterInstanceInboundExternalRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpContainerClusterKubernetesSecretEncryptionDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpContainerClusterKubernetesSecretEncryptionDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class GcpContainerClusterKubernetesSecretEncryptionDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpContainerClusterKubernetesSecretEncryptionDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpContainerClusterKubernetesSecretEncryptionDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class GcpContainerClusterKubernetesSecretEncryptionDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpContainerClusterKubernetesSecretEncryptionDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GcpContainerClusterKubernetesSecretEncryptionDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpContainerClusterKubernetesSecretEncryptionDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public cloudKmsConsoleDocumentationUrl: string,
        public clusterSecretApplicationLayerEncryptionDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpContainerClusterKubernetesSecretEncryptionDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpContainerClusterLegacyComputeMetadataServerEnabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public nodePoolIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpContainerClusterLegacyComputeMetadataServerEnabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GcpContainerClusterLegacyComputeMetadataServerEnabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpContainerClusterLegacyComputeMetadataServerEnabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpContainerClusterLegacyComputeMetadataServerEnabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpContainerClusterModel extends GcpScopeResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IGcpEncryptedResourceModel, IGcpServiceAccountOriginatorScopeResourceModel, IKubernetesClusterModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[],
        public apiConnectivityStatus: Optional<SystemKubernetesClusterApiConnectivityStatus>,
        public computeInstanceGroupIds: string[],
        public helm: KubernetesClusterModelHelm,
        public kmsEncryptionKeyReferences: GcpScopeResourceReference[],
        public kmsEncryptionKeySearchableReferences: GcpScopeResourceSearchableReference[],
        public nodePoolIds: string[],
        public resourceUpdateTime: Optional<string>,
        public serviceAccountIdReference: Optional<string>,
        public subnetIdReference: string,
        public vpcIdReference: string,
        public wideRangeAuthorizedSubnetDatas: Optional<GcpContainerClusterAuthorizedSubnetData[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpContainerClusterModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpContainerClusterModelFilters extends GcpResourceModelFilters implements IManagedKubernetesClusterModelFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        openRelatedEntityRiskTypeIdentifierItems: FilterItems<string>,
        regionIdItems: FilterItems<string>,
        tenantIdItems: FilterItems<string>,
        typeNameItems: FilterItems<string>,
        creationTimeRange: FilterTimeRange,
        updateTimeRange: FilterTimeRange,
        public kubernetesCluster: IKubernetesClusterModelFiltersSection) {
        super(
            attributeValueItems,
            openRelatedEntityRiskTypeIdentifierItems,
            regionIdItems,
            tenantIdItems,
            typeNameItems,
            creationTimeRange,
            updateTimeRange);
    }
}

export class GcpContainerClusterNodePoolModel extends GcpScopeResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IGcpServiceAccountOriginatorScopeResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[],
        public computeInstanceGroupIds: string[],
        public serviceAccountIdReference: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpContainerClusterNodePoolModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpContainerClusterRoleBindingsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpContainerClusterRoleBindingsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpContainerClusterRolesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpContainerClusterRolesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpContainerClustersGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpContainerClustersGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpContainerClusterWorkloadIdentityDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public nodePoolDatas: GcpContainerClusterWorkloadIdentityDisabledRiskNodePoolData[],
        public serviceAccountPermissionActionMaxSeverity: Optional<Severity>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpContainerClusterWorkloadIdentityDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GcpContainerClusterWorkloadIdentityDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpContainerClusterWorkloadIdentityDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public clusterWorkloadIdentityDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpContainerClusterWorkloadIdentityDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpContainerCronJobsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpContainerCronJobsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpContainerDaemonSetsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpContainerDaemonSetsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpContainerDeploymentsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpContainerDeploymentsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpContainerEndpointsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpContainerEndpointsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpContainerExtensionsDaemonSetGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpContainerExtensionsDaemonSetGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpContainerExtensionsIngressGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpContainerExtensionsIngressGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpContainerHorizontalPodAutoscalersGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpContainerHorizontalPodAutoscalersGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpContainerImage extends GcpResource implements ITypeNameObject, IResource, IContainerImage {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        public data: ContainerImageData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpContainerImage",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime);
    }
}

export class GcpContainerImageModel extends GcpResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IContainerImageModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        public kubernetesWorkloadResourceIds: string[],
        public operatingSystemReleaseNotesUrl: Optional<string>,
        public operatingSystemSupported: Optional<boolean>,
        public operatingSystemType: Optional<OperatingSystemType>,
        public repositoryIdReference: string,
        public virtualMachineIds: string[],
        public vulnerabilitySeveritiesSortValue: string,
        public workloadAnalysisOperatingSystem: Optional<WorkloadResourceScanOperatingSystem>,
        public workloadAnalysisOperatingSystemEndOfLifeDate: Optional<string>,
        public workloadAnalysisOperatingSystemExtendedSupport: Optional<boolean>,
        public workloadClusterResourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpContainerImageModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class GcpContainerImageModelFilters extends GcpResourceModelFilters implements IEntityModelFilters, IContainerImageFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        openRelatedEntityRiskTypeIdentifierItems: FilterItems<string>,
        regionIdItems: FilterItems<string>,
        tenantIdItems: FilterItems<string>,
        typeNameItems: FilterItems<string>,
        creationTimeRange: FilterTimeRange,
        updateTimeRange: FilterTimeRange,
        public containerImageCreationTimeRange: FilterTimeRange,
        public scanTimeRange: FilterTimeRange) {
        super(
            attributeValueItems,
            openRelatedEntityRiskTypeIdentifierItems,
            regionIdItems,
            tenantIdItems,
            typeNameItems,
            creationTimeRange,
            updateTimeRange);
    }
}

export class GcpContainerJobsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpContainerJobsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpContainerNamespacesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpContainerNamespacesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpContainerNetworkingIngressGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpContainerNetworkingIngressGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpContainerNetworkPoliciesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpContainerNetworkPoliciesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpContainerNodePoolsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource, IGcpTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpContainerNodePoolsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpContainerNodePoolsGenericResourceModel extends GcpAssetGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpContainerNodePoolsGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpContainerNodesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpContainerNodesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpContainerPersistentVolumeClaimsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpContainerPersistentVolumeClaimsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpContainerPersistentVolumesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpContainerPersistentVolumesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpContainerPodsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpContainerPodsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpContainerPodTemplatesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpContainerPodTemplatesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpContainerReplicaSetsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpContainerReplicaSetsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpContainerReplicationControllersGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpContainerReplicationControllersGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpContainerResource extends GcpScopeResource implements ITypeNameObject, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId);
    }
}

export class GcpContainerResourceQuotaGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpContainerResourceQuotaGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpContainerRoleBindingsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpContainerRoleBindingsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpContainerRolesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpContainerRolesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpContainerSecretsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpContainerSecretsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpContainerServiceAccountsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpContainerServiceAccountsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpContainerServicesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpContainerServicesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpContainerStatefulSetsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpContainerStatefulSetsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpContainerStorageClassesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpContainerStorageClassesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpCustomActivityRiskModel extends GcpActivityRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        risk: Risk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        originatorEntityIdReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            originatorEntityIdReferences);
    }
}

export class GcpCustomRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        risk: Risk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpDataflowJobsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource, IGcpTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpDataflowJobsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpDataflowJobsGenericResourceModel extends GcpAssetGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpDataflowJobsGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpDataformCompilationResultsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpDataformCompilationResultsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpDataformReleaseConfigsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpDataformReleaseConfigsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpDataformRepositoriesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpDataformRepositoriesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpDataformWorkflowConfigsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpDataformWorkflowConfigsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpDataformWorkflowInvocationsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpDataformWorkflowInvocationsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpDataformWorkspacesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpDataformWorkspacesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpDataFusionDnsPeeringsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpDataFusionDnsPeeringsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpDataFusionInstancesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpDataFusionInstancesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpDatalineageProcessesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpDatalineageProcessesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpDatamigrationConnectionprofilesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpDatamigrationConnectionprofilesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpDatamigrationConversionworkspacesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpDatamigrationConversionworkspacesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpDatamigrationMigrationjobsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpDatamigrationMigrationjobsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpDatamigrationPrivateconnectionsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpDatamigrationPrivateconnectionsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpDataplexAspectTypesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpDataplexAspectTypesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpDataplexAssetsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpDataplexAssetsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpDataplexDatascansGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpDataplexDatascansGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpDataplexEntryGroupsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpDataplexEntryGroupsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpDataplexEntryTypesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpDataplexEntryTypesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpDataplexEnvironmentsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpDataplexEnvironmentsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpDataplexLakesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpDataplexLakesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpDataplexTasksGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpDataplexTasksGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpDataplexZonesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpDataplexZonesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpDataprocAutoscalingPoliciesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpDataprocAutoscalingPoliciesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpDataprocBatchesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpDataprocBatchesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpDataprocClustersGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource, IGcpTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpDataprocClustersGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpDataprocClustersGenericResourceModel extends GcpAssetGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpDataprocClustersGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpDataprocJobsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpDataprocJobsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpDataprocSessionsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpDataprocSessionsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpDataprocWorkflowTemplatesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpDataprocWorkflowTemplatesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpDatastoreDatabasesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpDatastoreDatabasesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpDatastreamConnectionProfilesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpDatastreamConnectionProfilesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpDatastreamPrivateConnectionsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpDatastreamPrivateConnectionsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpDatastreamStreamsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpDatastreamStreamsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpDeveloperconnectConnectionsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpDeveloperconnectConnectionsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpDeveloperconnectGitRepositoryLinksGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpDeveloperconnectGitRepositoryLinksGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpDialogflowAgentsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpDialogflowAgentsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpDialogflowConversationProfilesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpDialogflowConversationProfilesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpDialogflowKnowledgeBasesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpDialogflowKnowledgeBasesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpDialogflowLocationSettingsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpDialogflowLocationSettingsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpDiscoveryengineCollectionsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpDiscoveryengineCollectionsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpDiscoveryengineDataStoresGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpDiscoveryengineDataStoresGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpDiscoveryengineEnginesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpDiscoveryengineEnginesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpDlpDeidentifyTemplatesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpDlpDeidentifyTemplatesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpDlpDiscoveryConfigsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpDlpDiscoveryConfigsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpDlpInspectTemplatesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpDlpInspectTemplatesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpDlpJobsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpDlpJobsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpDlpJobTriggersGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpDlpJobTriggersGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpDlpStoredInfoTypesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpDlpStoredInfoTypesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpDnsManagedZone extends GcpScopeResource implements ITypeNameObject, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        public description: Optional<string>,
        public dnsName: string,
        public dnsSec: Optional<GcpDnsManagedZoneDnsSec>,
        public loggingEnabled: boolean,
        public privateZoneType: Optional<GcpDnsManagedZonePrivateZoneType>,
        public publicNetworkAccess: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpDnsManagedZone",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId);
    }
}

export class GcpDnsManagedZoneDnsSecNotEnabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpDnsManagedZoneDnsSecNotEnabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GcpDnsManagedZoneDnsSecNotEnabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpDnsManagedZoneDnsSecNotEnabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpDnsManagedZoneDnsSecNotEnabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpDnsManagedZoneInsecureDnsSecKeySigningKeyAlgorithmRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpDnsManagedZoneInsecureDnsSecKeySigningKeyAlgorithmRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GcpDnsManagedZoneInsecureDnsSecKeySigningKeyAlgorithmRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpDnsManagedZoneInsecureDnsSecKeySigningKeyAlgorithmRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public signingKeyAlgorithmUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpDnsManagedZoneInsecureDnsSecKeySigningKeyAlgorithmRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpDnsManagedZoneInsecureDnsSecZoneSigningKeyAlgorithmRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpDnsManagedZoneInsecureDnsSecZoneSigningKeyAlgorithmRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GcpDnsManagedZoneInsecureDnsSecZoneSigningKeyAlgorithmRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpDnsManagedZoneInsecureDnsSecZoneSigningKeyAlgorithmRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public signingKeyAlgorithmUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpDnsManagedZoneInsecureDnsSecZoneSigningKeyAlgorithmRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpDnsManagedZoneModel extends GcpScopeResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[],
        public resourceRecordSetCount: number,
        public resourceRecordSetIds: string[],
        public resourceRecordSetIdToDnsRecordEntityIdsMap: Dictionary<string[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpDnsManagedZoneModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpDnsManagedZoneResourceRecordSet extends GcpScopeResource implements ITypeNameObject, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        public routingPolicy: GcpDnsManagedZoneResourceRecordSetRoutingPolicy,
        public type: GcpDnsManagedZoneResourceRecordSetType,
        public values: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpDnsManagedZoneResourceRecordSet",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId);
    }
}

export class GcpDnsManagedZoneResourceRecordSetModel extends GcpScopeResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpDnsManagedZoneResourceRecordSetModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpDnsManagedZonesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpDnsManagedZonesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpDnsPoliciesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpDnsPoliciesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpDocumentAiHumanReviewConfigsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpDocumentAiHumanReviewConfigsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpDocumentAiLabelerPoolsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpDocumentAiLabelerPoolsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpDocumentAiProcessorsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpDocumentAiProcessorsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpDocumentAiProcessorVersionsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpDocumentAiProcessorVersionsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpDomainsRegistrationsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpDomainsRegistrationsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpEntityInboundExternalPortRisk extends CloudRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpEntityInboundExternalPortRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType);
    }
}

export class GcpEntityInboundExternalPortRiskItem extends RiskItem implements ITypeNameObject {
    constructor(
        entityExclusionDag: Dag,
        entityId: string,
        excluded: boolean,
        public destinationNetworkScopes: DestinationNetworkScope[],
        public gatewayResourceIds: string[],
        public subnets: string[],
        public vpcIdReferences: string[]) {
        super(
            "GcpEntityInboundExternalPortRiskItem",
            entityExclusionDag,
            entityId,
            excluded);
    }
}

export class GcpEntityInboundExternalPortRiskModel extends GcpCustomRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        risk: Risk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public destinationNetworkScopes: DestinationNetworkScope[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpEntityInboundExternalPortRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpEssentialcontactsContactsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpEssentialcontactsContactsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpEventarcTrigger extends GcpScopeResource implements ITypeNameObject, IGcpServiceAccountOriginatorScopeResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        public additionalEventFilterKeyToValueMap: Dictionary<string>,
        public destinationResourceAssetPath: Optional<string>,
        public destinationResourceType: GcpEventarcTriggerDestinationResourceType,
        public eventFilterServiceName: Optional<string>,
        public eventFilterType: string,
        public serviceAccountData: GcpServiceAccountOriginatorServiceAccountData,
        public subscriptionRawId: Optional<string>,
        public topicRawId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpEventarcTrigger",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId);
    }
}

export class GcpEventarcTriggerModel extends GcpScopeResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IGcpServiceAccountOriginatorScopeResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[],
        public serviceAccountIdReference: Optional<string>,
        public subscriptionIdReference: Optional<string>,
        public topicIdReference: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpEventarcTriggerModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpEventarcTriggersGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpEventarcTriggersGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpExcessivePermissionPrincipalRisk extends GcpAccessPrincipalRisk implements ITypeNameObject, IGcpExcessivePermissionPrincipalRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        groupIdentityIds: string[],
        principalMail: string,
        signInActivity: Optional<Activity>,
        public excessiveDirectMembershipGroupIds: string[],
        public excessiveDirectMembershipGroupsPermissionActionSeverity: Optional<Severity>,
        public excessivePermissionActionSeverity: Severity,
        public excessivePermissionEvaluationStartDate: string,
        public excessiveRoleBindingIds: string[],
        public excessiveRoleBindingResolutionToIdsMap: Dictionary<string[]>,
        public excessiveRoleBindingsPermissionActionSeverity: Optional<Severity>,
        public excessiveScopeRoleBindingDatas: GcpExcessivePermissionPrincipalRiskScopeRoleBindingData[],
        public roleBindingIdToDataMap: Dictionary<GcpExcessivePermissionPrincipalRiskRoleBindingData>,
        public serviceAccountOriginatorResourceIdToPublicMap: Dictionary<boolean>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            groupIdentityIds,
            principalMail,
            signInActivity);
    }
}

export class GcpExcessivePermissionPrincipalRiskModel extends GcpAccessPrincipalRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpExcessivePermissionPrincipalRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        principalCreationTime: Optional<string>,
        public infos: GcpExcessivePermissionPrincipalRiskModelInfo[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            principalCreationTime);
    }
}

export class GcpExcessivePermissionPrincipalRiskPolicyUpdateAuditEventInactivePrincipalDeleteGroupMembershipEnabledChange extends RiskPolicyUpdateAuditEventChange implements ITypeNameObject {
    constructor(
        public inactivePrincipalDeleteGroupMembershipEnabled: Optional<boolean>,
        public previousInactivePrincipalDeleteGroupMembershipEnabled: Optional<boolean>) {
        super("GcpExcessivePermissionPrincipalRiskPolicyUpdateAuditEventInactivePrincipalDeleteGroupMembershipEnabledChange");
    }
}

export class GcpExcessivePermissionServiceAccountRisk extends GcpExcessivePermissionPrincipalRisk implements ITypeNameObject, IGcpExcessivePermissionPrincipalRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        groupIdentityIds: string[],
        principalMail: string,
        signInActivity: Optional<Activity>,
        excessiveDirectMembershipGroupIds: string[],
        excessiveDirectMembershipGroupsPermissionActionSeverity: Optional<Severity>,
        excessivePermissionActionSeverity: Severity,
        excessivePermissionEvaluationStartDate: string,
        excessiveRoleBindingIds: string[],
        excessiveRoleBindingResolutionToIdsMap: Dictionary<string[]>,
        excessiveRoleBindingsPermissionActionSeverity: Optional<Severity>,
        excessiveScopeRoleBindingDatas: GcpExcessivePermissionPrincipalRiskScopeRoleBindingData[],
        roleBindingIdToDataMap: Dictionary<GcpExcessivePermissionPrincipalRiskRoleBindingData>,
        serviceAccountOriginatorResourceIdToPublicMap: Dictionary<boolean>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpExcessivePermissionServiceAccountRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            groupIdentityIds,
            principalMail,
            signInActivity,
            excessiveDirectMembershipGroupIds,
            excessiveDirectMembershipGroupsPermissionActionSeverity,
            excessivePermissionActionSeverity,
            excessivePermissionEvaluationStartDate,
            excessiveRoleBindingIds,
            excessiveRoleBindingResolutionToIdsMap,
            excessiveRoleBindingsPermissionActionSeverity,
            excessiveScopeRoleBindingDatas,
            roleBindingIdToDataMap,
            serviceAccountOriginatorResourceIdToPublicMap);
    }
}

export class GcpExcessivePermissionServiceAccountRiskModel extends GcpExcessivePermissionPrincipalRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpExcessivePermissionPrincipalRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        principalCreationTime: Optional<string>,
        infos: GcpExcessivePermissionPrincipalRiskModelInfo[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpExcessivePermissionServiceAccountRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            principalCreationTime,
            infos);
    }
}

export class GcpExcessivePermissionUserRisk extends GcpExcessivePermissionPrincipalRisk implements ITypeNameObject, IGcpExcessivePermissionPrincipalRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        groupIdentityIds: string[],
        principalMail: string,
        signInActivity: Optional<Activity>,
        excessiveDirectMembershipGroupIds: string[],
        excessiveDirectMembershipGroupsPermissionActionSeverity: Optional<Severity>,
        excessivePermissionActionSeverity: Severity,
        excessivePermissionEvaluationStartDate: string,
        excessiveRoleBindingIds: string[],
        excessiveRoleBindingResolutionToIdsMap: Dictionary<string[]>,
        excessiveRoleBindingsPermissionActionSeverity: Optional<Severity>,
        excessiveScopeRoleBindingDatas: GcpExcessivePermissionPrincipalRiskScopeRoleBindingData[],
        roleBindingIdToDataMap: Dictionary<GcpExcessivePermissionPrincipalRiskRoleBindingData>,
        serviceAccountOriginatorResourceIdToPublicMap: Dictionary<boolean>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpExcessivePermissionUserRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            groupIdentityIds,
            principalMail,
            signInActivity,
            excessiveDirectMembershipGroupIds,
            excessiveDirectMembershipGroupsPermissionActionSeverity,
            excessivePermissionActionSeverity,
            excessivePermissionEvaluationStartDate,
            excessiveRoleBindingIds,
            excessiveRoleBindingResolutionToIdsMap,
            excessiveRoleBindingsPermissionActionSeverity,
            excessiveScopeRoleBindingDatas,
            roleBindingIdToDataMap,
            serviceAccountOriginatorResourceIdToPublicMap);
    }
}

export class GcpExcessivePermissionUserRiskModel extends GcpExcessivePermissionPrincipalRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpExcessivePermissionPrincipalRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        principalCreationTime: Optional<string>,
        infos: GcpExcessivePermissionPrincipalRiskModelInfo[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpExcessivePermissionUserRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            principalCreationTime,
            infos);
    }
}

export class GcpFileBackupsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpFileBackupsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpFileInstancesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource, IGcpTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpFileInstancesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpFileInstancesGenericResourceModel extends GcpAssetGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpFileInstancesGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpFileSnapshotsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpFileSnapshotsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpFinancialservicesBacktestResultsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpFinancialservicesBacktestResultsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpFinancialservicesDatasetsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpFinancialservicesDatasetsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpFinancialservicesEngineConfigsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpFinancialservicesEngineConfigsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpFinancialservicesInstancesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpFinancialservicesInstancesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpFinancialservicesModelsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpFinancialservicesModelsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpFinancialservicesPredictionResultsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpFinancialservicesPredictionResultsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpFirebaseFirebaseAppInfosGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpFirebaseFirebaseAppInfosGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpFirebaseFirebaseProjectsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpFirebaseFirebaseProjectsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpFirebaseRulesReleasesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpFirebaseRulesReleasesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpFirebaseRulesRulesetsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpFirebaseRulesRulesetsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpFolderConfiguration extends CloudProviderTenantFolderConfiguration implements ITypeNameObject, IOrganizationFolderConfiguration, IGcpFolderConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        managed: boolean,
        name: string,
        parentScopeId: string,
        root: boolean,
        scopeSections: ScopeConfigurationSections,
        tenantType: Optional<TenantType>,
        organization: Optional<FolderConfigurationOrganization>,
        organizationExists: boolean,
        public gcpFolderSections: GcpFolderConfigurationSections,
        public gcpScopeSections: GcpScopeConfigurationSections) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpFolderConfiguration",
            managed,
            name,
            parentScopeId,
            root,
            scopeSections,
            tenantType,
            organization,
            organizationExists);
    }
}

export class GcpFunctionsFunction extends GcpScopeResource implements ITypeNameObject, IGcpEncryptedResource, IGcpServerlessNetworkedResource, IGcpServiceAccountOriginatorScopeResource, IGcpTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        public artifactRegistryAssetPath: Optional<string>,
        public buildServiceAccountData: GcpServiceAccountOriginatorServiceAccountData,
        public cloudRunServiceAssetPath: Optional<string>,
        public encryptionExists: boolean,
        public environmentVariables: GcpFunctionsFunctionEnvironmentVariable[],
        public httpsOnly: Optional<boolean>,
        public kmsEncryptionKeyAssetPaths: string[],
        public networkInboundAccessType: Optional<GcpServerlessNetworkedResourceNetworkInboundAccessType>,
        public pubSubTriggerTopicAssetPath: Optional<string>,
        public serviceAccountData: GcpServiceAccountOriginatorServiceAccountData,
        public triggerType: GcpFunctionsFunctionTriggerType,
        public triggerUrl: Optional<string>,
        public type: GcpFunctionsFunctionType,
        public version: Optional<number>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpFunctionsFunction",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId);
    }
}

export class GcpFunctionsFunctionBuildDefaultServiceAccountRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public principalIdToExternalMap: Dictionary<boolean>,
        public updatePermissionAction: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpFunctionsFunctionBuildDefaultServiceAccountRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GcpFunctionsFunctionBuildDefaultServiceAccountRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpFunctionsFunctionBuildDefaultServiceAccountRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public createCustomServiceAccountUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpFunctionsFunctionBuildDefaultServiceAccountRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpFunctionsFunctionEnvironmentVariableSecretExistsCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpFunctionsFunctionEnvironmentVariableSecretExistsCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class GcpFunctionsFunctionEnvironmentVariableSecretExistsCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpFunctionsFunctionEnvironmentVariableSecretExistsCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpFunctionsFunctionEnvironmentVariableSecretExistsCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class GcpFunctionsFunctionEnvironmentVariableSecretExistsRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public environmentVariableDatas: GcpFunctionsFunctionEnvironmentVariableSecretExistsRiskEnvironmentVariableData[],
        public nonPublicReadActions: string[],
        public principalIdToExternalMap: Dictionary<boolean>,
        public publicReadActions: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpFunctionsFunctionEnvironmentVariableSecretExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GcpFunctionsFunctionEnvironmentVariableSecretExistsRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel, ISecretExistsRiskModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpFunctionsFunctionEnvironmentVariableSecretExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public secretCount: number,
        public secretExistsEnvironmentVariables: string[],
        public secretManagerConsoleUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpFunctionsFunctionEnvironmentVariableSecretExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpFunctionsFunctionModel extends GcpScopeResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IGcpEncryptedResourceModel, IGcpServiceAccountOriginatorScopeResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[],
        public artifactRegistryIdReference: Optional<string>,
        public buildServiceAccountIdReference: Optional<string>,
        public cloudRunServiceIdReference: Optional<string>,
        public kmsEncryptionKeyReferences: GcpScopeResourceReference[],
        public pubSubTriggerTopicIdReference: Optional<string>,
        public serviceAccountIdReference: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpFunctionsFunctionModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpFunctionsFunctionPublicAccessExistsCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpFunctionsFunctionPublicAccessExistsCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class GcpFunctionsFunctionPublicAccessExistsCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpFunctionsFunctionPublicAccessExistsCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpFunctionsFunctionPublicAccessExistsCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class GcpGameServicesGameServerClustersGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpGameServicesGameServerClustersGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpGameServicesGameServerConfigsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpGameServicesGameServerConfigsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpGameServicesGameServerDeploymentsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpGameServicesGameServerDeploymentsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpGameServicesRealmsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpGameServicesRealmsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpGkebackupBackupPlansGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpGkebackupBackupPlansGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpGkebackupBackupsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpGkebackupBackupsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpGkebackupRestorePlansGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpGkebackupRestorePlansGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpGkebackupRestoresGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpGkebackupRestoresGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpGkebackupVolumeBackupsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpGkebackupVolumeBackupsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpGkebackupVolumeRestoresGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpGkebackupVolumeRestoresGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpGkeHubFeaturesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpGkeHubFeaturesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpGkeHubFleetsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpGkeHubFleetsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpGkeHubMembershipbindingsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpGkeHubMembershipbindingsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpGkeHubMembershipsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpGkeHubMembershipsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpGkeHubNamespacesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpGkeHubNamespacesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpGkeHubRbacrolebindingsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpGkeHubRbacrolebindingsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpGkeHubScopesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpGkeHubScopesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpGkemulticloudAttachedClustersGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpGkemulticloudAttachedClustersGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpGkemulticloudAwsClustersGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpGkemulticloudAwsClustersGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpGkemulticloudAwsNodePoolsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpGkemulticloudAwsNodePoolsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpGkemulticloudAzureClientsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpGkemulticloudAzureClientsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpGkemulticloudAzureClustersGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpGkemulticloudAzureClustersGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpGkemulticloudAzureNodePoolsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpGkemulticloudAzureNodePoolsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpGkemulticloudConnectedClustersGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpGkemulticloudConnectedClustersGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpGkeonpremBareMetalClustersGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpGkeonpremBareMetalClustersGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpGkeonpremBareMetalNodePoolsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpGkeonpremBareMetalNodePoolsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpGkeonpremVmwareClustersGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpGkeonpremVmwareClustersGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpGkeonpremVmwareNodePoolsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpGkeonpremVmwareNodePoolsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpHealthcareConsentStoresGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpHealthcareConsentStoresGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpHealthcareDatasetsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpHealthcareDatasetsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpHealthcareDicomStoresGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpHealthcareDicomStoresGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpHealthcareFhirStoresGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpHealthcareFhirStoresGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpHealthcareHl7V2StoresGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpHealthcareHl7V2StoresGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpIamPartialPrincipal extends GcpPartialEntity implements ITypeNameObject, IPartialEntity, IGciDirectoryGroupMemberPrincipal, IGciPrincipal {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        public mail: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime);
    }
}

export class GcpIamPartialPrincipalModel extends GcpPartialEntityModel implements ITypeNameObject, IUdmObjectModel, IPrincipalModel, IGciPrincipalModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        public access: Optional<PrincipalModelAccess>,
        public analyzing: Optional<GciPrincipalModelAnalyzing>,
        public inactive: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime);
    }
}

export class GcpIamPartialServiceAccount extends GcpIamPartialPrincipal implements ITypeNameObject, IGciPartialIdentity, IGcpIamServiceAccount {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        mail: string,
        public serviceDefaultServiceId: Optional<string>,
        public uniqueId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpIamPartialServiceAccount",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            mail);
    }
}

export class GcpIamPartialServiceAccountModel extends GcpIamPartialPrincipalModel implements ITypeNameObject, IUdmObjectModel, IGciPrincipalModel, IGcpIamServiceAccountModel, IRelatedTenantsEntityModel, IServiceIdentityModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        public access: Optional<ServiceIdentityModelAccess>,
        analyzing: Optional<GciPrincipalModelAnalyzing>,
        inactive: boolean,
        public containedRoleBindingIds: string[],
        public groupIds: string[],
        public permissionActionSeverity: Optional<Severity>,
        public roleBindingIds: string[],
        public roleIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpIamPartialServiceAccountModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            access,
            analyzing,
            inactive);
    }
}

export class GcpIamPolicyV2sGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpIamPolicyV2sGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpIamPrincipal extends GcpScopeResource implements ITypeNameObject, IResource, IGciPrincipal {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId);
    }
}

export class GcpIamPrincipalModel extends GcpScopeResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IGciPrincipalModel, IPrincipalModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[],
        public access: Optional<PrincipalModelAccess>,
        public analyzing: Optional<GciPrincipalModelAnalyzing>,
        public inactive: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpIamRole extends GcpResource implements ITypeNameObject, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        public actions: string[],
        public deleted: boolean,
        public description: Optional<string>,
        public ignored: boolean,
        public rawId: string,
        public rawRole: string,
        public stage: GcpIamRoleStage,
        public type: GcpIamRoleType,
        public urlRawId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpIamRole",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime);
    }
}

export class GcpIamRoleBindingModelFilters extends GcpResourceModelFilters implements IEntityModelFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        openRelatedEntityRiskTypeIdentifierItems: FilterItems<string>,
        regionIdItems: FilterItems<string>,
        tenantIdItems: FilterItems<string>,
        typeNameItems: FilterItems<string>,
        creationTimeRange: FilterTimeRange,
        updateTimeRange: FilterTimeRange,
        public scopeResourceTypeNameItems: FilterItems<string>) {
        super(
            attributeValueItems,
            openRelatedEntityRiskTypeIdentifierItems,
            regionIdItems,
            tenantIdItems,
            typeNameItems,
            creationTimeRange,
            updateTimeRange);
    }
}

export class GcpIamRoleModel extends GcpResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IRelatedTenantsEntityModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        public unused: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpIamRoleModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class GcpIamRolesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpIamRolesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpIamServiceAccount extends GcpIamPrincipal implements ITypeNameObject, IResource, IGcpIamServiceAccount {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        public description: string,
        public disabled: boolean,
        public mail: string,
        public serviceDefaultServiceId: Optional<string>,
        public uniqueId: string,
        public userManagedKeyIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpIamServiceAccount",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId);
    }
}

export class GcpIamServiceAccountInactiveRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public signInActivity: Activity) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpIamServiceAccountInactiveRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GcpIamServiceAccountInactiveRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpIamServiceAccountInactiveRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public infoType: GcpIamServiceAccountInactiveRiskModelInfoType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpIamServiceAccountInactiveRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpIamServiceAccountInactiveRiskPolicyUpdateAuditEventIncludeDisabledServiceAccountChange extends RiskPolicyUpdateAuditEventChange implements ITypeNameObject {
    constructor(
        public includeDisabledServiceAccount: Optional<boolean>,
        public previousIncludeDisabledServiceAccount: Optional<boolean>) {
        super("GcpIamServiceAccountInactiveRiskPolicyUpdateAuditEventIncludeDisabledServiceAccountChange");
    }
}

export class GcpIamServiceAccountKey extends GcpScopeResource implements ITypeNameObject, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        public active: boolean,
        public disabled: boolean,
        public expirationTime: Optional<string>,
        public expired: boolean,
        public serviceAccountId: string,
        public type: GcpIamServiceAccountKeyType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpIamServiceAccountKey",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId);
    }
}

export class GcpIamServiceAccountKeyModel extends GcpScopeResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpIamServiceAccountKeyModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpIamServiceAccountKeysGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpIamServiceAccountKeysGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpIamServiceAccountModel extends GcpIamPrincipalModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IGciPrincipalModel, IGcpIamServiceAccountModel, IServiceIdentityModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[],
        public access: Optional<ServiceIdentityModelAccess>,
        analyzing: Optional<GciPrincipalModelAnalyzing>,
        inactive: boolean,
        public groupIds: string[],
        public identityContainedRoleBindingIds: string[],
        public identityRoleBindingIds: string[],
        public identityRoleIds: string[],
        public permissionActionSeverity: Optional<Severity>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpIamServiceAccountModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds,
            access,
            analyzing,
            inactive);
    }
}

export class GcpIamServiceAccountModelFilters extends GcpResourceModelFilters implements IEntityModelFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        openRelatedEntityRiskTypeIdentifierItems: FilterItems<string>,
        regionIdItems: FilterItems<string>,
        tenantIdItems: FilterItems<string>,
        typeNameItems: FilterItems<string>,
        creationTimeRange: FilterTimeRange,
        updateTimeRange: FilterTimeRange,
        public activityTimeRange: FilterTimeRange,
        public signInTimeRange: FilterTimeRange) {
        super(
            attributeValueItems,
            openRelatedEntityRiskTypeIdentifierItems,
            regionIdItems,
            tenantIdItems,
            typeNameItems,
            creationTimeRange,
            updateTimeRange);
    }
}

export class GcpIamServiceAccountsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpIamServiceAccountsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpIamServiceAccountUnrotatedUserManagedKeyCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpIamServiceAccountUnrotatedUserManagedKeyCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class GcpIamServiceAccountUnrotatedUserManagedKeyCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpIamServiceAccountUnrotatedUserManagedKeyCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpIamServiceAccountUnrotatedUserManagedKeyCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class GcpIamServiceAccountUnrotatedUserManagedKeyRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public keyRotationTimeFrame: string,
        public unrotatedActiveUserManagedKeyIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpIamServiceAccountUnrotatedUserManagedKeyRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GcpIamServiceAccountUnrotatedUserManagedKeyRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpIamServiceAccountUnrotatedUserManagedKeyRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpIamServiceAccountUnrotatedUserManagedKeyRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpIamServiceAccountUnrotatedUserManagedKeyRiskPolicyUpdateAuditEventKeyRotationTimeFrameChange extends RiskPolicyUpdateAuditEventChange implements ITypeNameObject {
    constructor(
        public keyRotationTimeFrame: Optional<string>,
        public previousKeyRotationTimeFrame: Optional<string>) {
        super("GcpIamServiceAccountUnrotatedUserManagedKeyRiskPolicyUpdateAuditEventKeyRotationTimeFrameChange");
    }
}

export class GcpIamServiceAccountUserManagedKeyExistsCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpIamServiceAccountUserManagedKeyExistsCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class GcpIamServiceAccountUserManagedKeyExistsCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpIamServiceAccountUserManagedKeyExistsCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpIamServiceAccountUserManagedKeyExistsCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class GcpIamServiceAccountUserManagedKeyExistsRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpIamServiceAccountUserManagedKeyExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GcpIamServiceAccountUserManagedKeyExistsRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpIamServiceAccountUserManagedKeyExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public managedKeysDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpIamServiceAccountUserManagedKeyExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpIamWorkforcePool extends GcpScopeResource implements ITypeNameObject, IResource, IGciPrincipal {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        public accessRestriction: GcpIamWorkforcePoolAccessRestriction,
        public enabled: boolean,
        public sessionDuration: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpIamWorkforcePool",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId);
    }
}

export class GcpIamWorkforcePoolModel extends GcpScopeResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IGciGcpResourceModel, IPrincipalModel, IRelatedTenantsEntityModel, IGciPrincipalModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[],
        public access: Optional<PrincipalModelAccess>,
        public gciParentResourceData: Optional<GciParentResourceData>,
        public identityContainedRoleBindingIds: string[],
        public identityRoleBindingIds: string[],
        public inactive: boolean,
        public rawShortId: string,
        public workforcePoolProviderIds: string[],
        public workforcePoolProviderTypes: GcpIamWorkforcePoolProviderType[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpIamWorkforcePoolModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpIamWorkforcePoolModelAccess extends PrincipalModelAccess implements ITypeNameObject {
    constructor(
        activity: Activity,
        permissions: PrincipalModelAccessPermissions,
        riskCategory: Optional<PrincipalRiskCategory>,
        risks: PrincipalModelAccessRisks,
        scopesRisks: PrincipalModelAccessScopesRisk[],
        types: PrincipalType[]) {
        super(
            "GcpIamWorkforcePoolModelAccess",
            activity,
            permissions,
            riskCategory,
            risks,
            scopesRisks,
            types);
    }
}

export class GcpIamWorkforcePoolProvider extends GcpScopeResource implements ITypeNameObject, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        public attributeData: GcpIamWorkforcePoolProviderAttributeData,
        public enabled: boolean,
        public type: GcpIamWorkforcePoolProviderType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpIamWorkforcePoolProvider",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId);
    }
}

export class GcpIamWorkforcePoolProviderModel extends GcpScopeResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpIamWorkforcePoolProviderModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpIamWorkloadIdentityPool extends GcpScopeResource implements ITypeNameObject, IResource, IGciPrincipal {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        public enabled: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpIamWorkloadIdentityPool",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId);
    }
}

export class GcpIamWorkloadIdentityPoolModel extends GcpScopeResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IPrincipalModel, IGciPrincipalModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[],
        public access: Optional<PrincipalModelAccess>,
        public identityContainedRoleBindingIds: string[],
        public identityRoleBindingIds: string[],
        public identityRoleIds: string[],
        public inactive: boolean,
        public rawShortId: string,
        public workloadIdentityPoolProviderIds: string[],
        public workloadIdentityPoolProviderTypes: GcpIamWorkloadIdentityPoolProviderType[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpIamWorkloadIdentityPoolModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpIamWorkloadIdentityPoolModelAccess extends PrincipalModelAccess implements ITypeNameObject {
    constructor(
        activity: Activity,
        permissions: PrincipalModelAccessPermissions,
        riskCategory: Optional<PrincipalRiskCategory>,
        risks: PrincipalModelAccessRisks,
        scopesRisks: PrincipalModelAccessScopesRisk[],
        types: PrincipalType[]) {
        super(
            "GcpIamWorkloadIdentityPoolModelAccess",
            activity,
            permissions,
            riskCategory,
            risks,
            scopesRisks,
            types);
    }
}

export class GcpIamWorkloadIdentityPoolProvider extends GcpScopeResource implements ITypeNameObject, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        public attributeData: GcpIamWorkloadIdentityPoolProviderAttributeData,
        public awsTenantRawId: Optional<string>,
        public enabled: boolean,
        public type: GcpIamWorkloadIdentityPoolProviderType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpIamWorkloadIdentityPoolProvider",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId);
    }
}

export class GcpIamWorkloadIdentityPoolProviderModel extends GcpScopeResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpIamWorkloadIdentityPoolProviderModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpIapTunnelDestGroupsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpIapTunnelDestGroupsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpIapTunnelInstancesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpIapTunnelInstancesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpIapTunnelsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpIapTunnelsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpIapTunnelZonesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpIapTunnelZonesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpIapWebServicesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpIapWebServicesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpIapWebServiceVersionsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpIapWebServiceVersionsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpIapWebsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpIapWebsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpIapWebTypesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpIapWebTypesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpIdentityActivityRisk extends GcpActivityRisk implements ITypeNameObject, IActivityRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        public eventNames: string[],
        public identityId: string,
        public serviceReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpIdentityActivityRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType);
    }
}

export class GcpIdentityActivityRiskModel extends GcpCustomActivityRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpIdentityActivityRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        originatorEntityIdReferences: string[],
        public serviceIdReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpIdentityActivityRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            originatorEntityIdReferences);
    }
}

export class GcpIdentitytoolkitConfigsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpIdentitytoolkitConfigsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpIdentitytoolkitDefaultSupportedIdpConfigsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpIdentitytoolkitDefaultSupportedIdpConfigsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpIdentitytoolkitInboundSamlConfigsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpIdentitytoolkitInboundSamlConfigsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpIdentitytoolkitOauthIdpConfigsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpIdentitytoolkitOauthIdpConfigsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpIdentitytoolkitTenantsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpIdentitytoolkitTenantsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpIdsEndpointsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpIdsEndpointsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpIntegrationsAuthConfigsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpIntegrationsAuthConfigsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpIntegrationsCertificatesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpIntegrationsCertificatesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpIntegrationsExecutionsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpIntegrationsExecutionsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpIntegrationsIntegrationsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpIntegrationsIntegrationsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpIntegrationsIntegrationVersionsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpIntegrationsIntegrationVersionsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpIntegrationsSfdcChannelsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpIntegrationsSfdcChannelsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpIntegrationsSfdcInstancesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpIntegrationsSfdcInstancesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpIntegrationsSuspensionsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpIntegrationsSuspensionsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpKmsKeyRing extends GcpScopeResource implements ITypeNameObject, IResource, IGcpTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpKmsKeyRing",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId);
    }
}

export class GcpKmsKeyRingKey extends GcpScopeResource implements ITypeNameObject, IResource, IGcpTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        public keyRingId: string,
        public keyRingName: string,
        public nextRotationTime: Optional<string>,
        public rotationTimeFrame: Optional<string>,
        public usageType: GcpKmsKeyRingKeyUsageType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpKmsKeyRingKey",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId);
    }
}

export class GcpKmsKeyRingKeyModel extends GcpScopeResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[],
        public encryptedResourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpKmsKeyRingKeyModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpKmsKeyRingKeyPublicAccessExistsCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpKmsKeyRingKeyPublicAccessExistsCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class GcpKmsKeyRingKeyPublicAccessExistsCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpKmsKeyRingKeyPublicAccessExistsCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpKmsKeyRingKeyPublicAccessExistsCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class GcpKmsKeyRingKeyRotationTimeFrameCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpKmsKeyRingKeyRotationTimeFrameCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class GcpKmsKeyRingKeyRotationTimeFrameCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpKmsKeyRingKeyRotationTimeFrameCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpKmsKeyRingKeyRotationTimeFrameCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class GcpKmsKeyRingKeyRotationTimeFrameRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public keyRotationTimeFrame: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpKmsKeyRingKeyRotationTimeFrameRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GcpKmsKeyRingKeyRotationTimeFrameRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpKmsKeyRingKeyRotationTimeFrameRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpKmsKeyRingKeyRotationTimeFrameRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpKmsKeyRingKeyRotationTimeFrameRiskPolicyUpdateAuditEventKeyRotationTimeFrameChange extends RiskPolicyUpdateAuditEventChange implements ITypeNameObject {
    constructor(
        public keyRotationTimeFrame: Optional<string>,
        public previousKeyRotationTimeFrame: Optional<string>) {
        super("GcpKmsKeyRingKeyRotationTimeFrameRiskPolicyUpdateAuditEventKeyRotationTimeFrameChange");
    }
}

export class GcpKmsKeyRingModel extends GcpScopeResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[],
        public keyIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpKmsKeyRingModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpKmsKeyRingPublicAccessExistsCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpKmsKeyRingPublicAccessExistsCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class GcpKmsKeyRingPublicAccessExistsCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpKmsKeyRingPublicAccessExistsCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpKmsKeyRingPublicAccessExistsCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class GcpKrmapihostingKrmApiHostsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpKrmapihostingKrmApiHostsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpKubernetesResourceBase extends GcpResource implements ITypeNameObject, IKubernetesResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        public clusterDisplayReference: string,
        public clusterId: string,
        public data: KubernetesResourceData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime);
    }
}

export class GcpKubernetesResourceModel extends GcpResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class GcpKubernetesResourceTypeMetadata extends GcpResourceTypeMetadata {
    constructor() {
        super();
    }
}

export class GcpKubernetesUserModel extends GcpKubernetesResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesResourceModel, IKubernetesPrincipalModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        public roleBindingIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpKubernetesUserModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class GcpLivestreamAssetsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpLivestreamAssetsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpLivestreamChannelsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpLivestreamChannelsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpLivestreamInputsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpLivestreamInputsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpLivestreamPoolsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpLivestreamPoolsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpLoggingAuditLogTenantDefaultNotEnabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpLoggingAuditLogTenantDefaultNotEnabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class GcpLoggingAuditLogTenantDefaultNotEnabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpLoggingAuditLogTenantDefaultNotEnabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpLoggingAuditLogTenantDefaultNotEnabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class GcpLoggingAuditLogTenantDefaultNotEnabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpLoggingAuditLogTenantDefaultNotEnabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GcpLoggingAuditLogTenantDefaultNotEnabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpLoggingAuditLogTenantDefaultNotEnabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpLoggingAuditLogTenantDefaultNotEnabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpLoggingBucketsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpLoggingBucketsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpLoggingLinksGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpLoggingLinksGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpLoggingLogBucket extends GcpScopeResource implements ITypeNameObject, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpLoggingLogBucket",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId);
    }
}

export class GcpLoggingLogMetric extends GcpScopeResource implements ITypeNameObject, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        public enabled: boolean,
        public filter: string,
        public type: GcpLoggingLogMetricType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpLoggingLogMetric",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId);
    }
}

export class GcpLoggingLogMetricModel extends GcpScopeResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpLoggingLogMetricModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpLoggingLogMetricsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpLoggingLogMetricsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpLoggingLogSink extends GcpScopeResource implements ITypeNameObject, IGcpServiceAccountOriginatorScopeResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        public childResourceAggregation: boolean,
        public description: Optional<string>,
        public destinationAssetPath: string,
        public enabled: boolean,
        public exclusionNameToFilterMap: Dictionary<string>,
        public inclusionFilter: Optional<string>,
        public serviceAccountData: GcpServiceAccountOriginatorServiceAccountData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpLoggingLogSink",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId);
    }
}

export class GcpLoggingLogViewsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpLoggingLogViewsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpLoggingRecentQueriesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpLoggingRecentQueriesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpLoggingResourceModel extends GcpScopeResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IGciGcpResourceModel, IRelatedTenantsEntityModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[],
        public gciParentResourceData: Optional<GciParentResourceData>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpLoggingSavedQueriesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpLoggingSavedQueriesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpLoggingSettingsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpLoggingSettingsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpLoggingSinksGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpLoggingSinksGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpLookerInstancesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpLookerInstancesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpManagedIdentitiesDomainsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpManagedIdentitiesDomainsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpManagedkafkaClustersGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpManagedkafkaClustersGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpMemcacheInstancesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource, IGcpTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpMemcacheInstancesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpMemcacheInstancesGenericResourceModel extends GcpAssetGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpMemcacheInstancesGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpMetastoreBackupsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpMetastoreBackupsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpMetastoreImportsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpMetastoreImportsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpMetastoreServicesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource, IGcpTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpMetastoreServicesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpMetastoreServicesGenericResourceModel extends GcpAssetGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpMetastoreServicesGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpMonitoringAlertPoliciesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpMonitoringAlertPoliciesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpMonitoringAlertPolicy extends GcpScopeResource implements ITypeNameObject, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        public conditions: GcpMonitoringAlertPolicyCondition[],
        public enabled: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpMonitoringAlertPolicy",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId);
    }
}

export class GcpMonitoringAlertPolicyModel extends GcpScopeResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpMonitoringAlertPolicyModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpMonitoringAlertPolicyNotExistRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public loggingLogMetricFilterPattern: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GcpMonitoringAlertPolicyNotExistRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpMonitoringAlertPolicyNotExistRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpMonitoringAlertPolicyProjectOwnershipChangesNotExistRisk extends GcpMonitoringAlertPolicyNotExistRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        loggingLogMetricFilterPattern: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpMonitoringAlertPolicyProjectOwnershipChangesNotExistRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            loggingLogMetricFilterPattern);
    }
}

export class GcpMonitoringAlertPolicyProjectOwnershipChangesNotExistRiskModel extends GcpMonitoringAlertPolicyNotExistRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpMonitoringAlertPolicyNotExistRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpMonitoringAlertPolicyProjectOwnershipChangesNotExistRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpMonitoringDashboardsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpMonitoringDashboardsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpMonitoringNotificationChannelsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpMonitoringNotificationChannelsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpMonitoringSnoozesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpMonitoringSnoozesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpMonitoringUptimeCheckConfigsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpMonitoringUptimeCheckConfigsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpNetappActiveDirectoriesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpNetappActiveDirectoriesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpNetappBackupPoliciesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpNetappBackupPoliciesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpNetappBackupsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpNetappBackupsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpNetappBackupVaultsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpNetappBackupVaultsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpNetappKmsConfigsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpNetappKmsConfigsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpNetappReplicationsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpNetappReplicationsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpNetappSnapshotsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpNetappSnapshotsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpNetappStoragePoolsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpNetappStoragePoolsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpNetappVolumesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpNetappVolumesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpNetworkAccessResourceStateNetwork extends NetworkAccessResourceStateNetwork implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        entityReference: EntityReference,
        inboundAccessType: NetworkInboundAccessType,
        inboundExternal: boolean,
        inboundExternalAccessScope: NetworkAccessScope,
        inboundExternalDestinationNetworkScopes: Optional<DestinationNetworkScope[]>,
        inboundExternalWideRange: Optional<boolean>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            entityReference,
            inboundAccessType,
            inboundExternal,
            inboundExternalAccessScope,
            inboundExternalDestinationNetworkScopes,
            inboundExternalWideRange);
    }
}

export class GcpNetworkconnectivityHubRoutesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpNetworkconnectivityHubRoutesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpNetworkconnectivityHubsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpNetworkconnectivityHubsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpNetworkconnectivityPolicyBasedRoutesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpNetworkconnectivityPolicyBasedRoutesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpNetworkconnectivityRouteTablesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpNetworkconnectivityRouteTablesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpNetworkconnectivitySpokesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpNetworkconnectivitySpokesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpNetworkManagementConnectivitytestsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpNetworkManagementConnectivitytestsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpNetworkSecurityAddressGroupsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpNetworkSecurityAddressGroupsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpNetworkSecurityFirewallEndpointAssociationsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpNetworkSecurityFirewallEndpointAssociationsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpNetworkSecurityFirewallEndpointsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpNetworkSecurityFirewallEndpointsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpNetworkSecurityGatewaySecurityPoliciesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpNetworkSecurityGatewaySecurityPoliciesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpNetworkSecurityGatewaySecurityPolicyRulesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpNetworkSecurityGatewaySecurityPolicyRulesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpNetworkSecuritySecurityProfileGroupsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpNetworkSecuritySecurityProfileGroupsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpNetworkSecuritySecurityProfilesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpNetworkSecuritySecurityProfilesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpNetworkSecurityTlsInspectionPoliciesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpNetworkSecurityTlsInspectionPoliciesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpNetworkSecurityUrlListsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpNetworkSecurityUrlListsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpNetworkServicesEdgeCacheKeysetsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpNetworkServicesEdgeCacheKeysetsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpNetworkServicesEdgeCacheOriginsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpNetworkServicesEdgeCacheOriginsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpNetworkServicesEdgeCacheServicesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpNetworkServicesEdgeCacheServicesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpNetworkServicesEndpointPoliciesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpNetworkServicesEndpointPoliciesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpNetworkServicesGatewaysGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpNetworkServicesGatewaysGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpNetworkServicesGrpcRoutesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpNetworkServicesGrpcRoutesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpNetworkServicesHttpRoutesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpNetworkServicesHttpRoutesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpNetworkServicesLbRouteExtensionsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpNetworkServicesLbRouteExtensionsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpNetworkServicesLbTrafficExtensionsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpNetworkServicesLbTrafficExtensionsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpNetworkServicesMeshesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpNetworkServicesMeshesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpNetworkServicesServiceBindingsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpNetworkServicesServiceBindingsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpNetworkServicesServiceLbPoliciesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpNetworkServicesServiceLbPoliciesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpNetworkServicesTcpRoutesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpNetworkServicesTcpRoutesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpNetworkServicesTlsRoutesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpNetworkServicesTlsRoutesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpNetworkServicesWasmPluginsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpNetworkServicesWasmPluginsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpNetworkServicesWasmPluginVersionsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpNetworkServicesWasmPluginVersionsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpNotebooksInstance extends GcpScopeResource implements ITypeNameObject, IResource, IGcpTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        public rootAccess: boolean,
        public status: GcpNotebooksInstanceStatus) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpNotebooksInstance",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId);
    }
}

export class GcpNotebooksInstanceBootIntegrityValidationNotEnabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public computeInstanceId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpNotebooksInstanceBootIntegrityValidationNotEnabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GcpNotebooksInstanceBootIntegrityValidationNotEnabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpNotebooksInstanceBootIntegrityValidationNotEnabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpNotebooksInstanceBootIntegrityValidationNotEnabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpNotebooksInstanceDefaultEncryptionRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public computeInstanceId: string,
        public diskIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpNotebooksInstanceDefaultEncryptionRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GcpNotebooksInstanceDefaultEncryptionRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpNotebooksInstanceDefaultEncryptionRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpNotebooksInstanceDefaultEncryptionRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpNotebooksInstanceModel extends GcpScopeResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[],
        public computeInstanceIdReference: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpNotebooksInstanceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpNotebooksInstanceRootAccessEnabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpNotebooksInstanceRootAccessEnabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GcpNotebooksInstanceRootAccessEnabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpNotebooksInstanceRootAccessEnabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpNotebooksInstanceRootAccessEnabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpNotebooksInstanceSecureBootNotEnabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public computeInstanceId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpNotebooksInstanceSecureBootNotEnabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GcpNotebooksInstanceSecureBootNotEnabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpNotebooksInstanceSecureBootNotEnabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpNotebooksInstanceSecureBootNotEnabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpNotebooksInstancesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpNotebooksInstancesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpNotebooksInstanceVirtualTpmNotEnabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public computeInstanceId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpNotebooksInstanceVirtualTpmNotEnabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GcpNotebooksInstanceVirtualTpmNotEnabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpNotebooksInstanceVirtualTpmNotEnabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpNotebooksInstanceVirtualTpmNotEnabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpOpenShiftProjectModel extends GcpKubernetesResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpOpenShiftProjectModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class GcpOpenShiftSecurityContextConstraintModel extends GcpKubernetesResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpOpenShiftSecurityContextConstraintModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class GcpOrganizationConfiguration extends CloudProviderTenantOrganizationConfiguration implements ITypeNameObject, ITenantOrganizationConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        enabled: boolean,
        folderEnabled: boolean,
        memberSelection: Optional<OrganizationMemberSelection>,
        name: string,
        permissionTypes: CloudProviderTenantPermissionType[],
        public gciTenantId: string,
        public sink: Optional<GcpTenantConfigurationSink>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpOrganizationConfiguration",
            scopeId,
            enabled,
            folderEnabled,
            memberSelection,
            name,
            permissionTypes);
    }
}

export class GcpOrgPolicyCustomConstraintsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpOrgPolicyCustomConstraintsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpOrgPolicyPoliciesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpOrgPolicyPoliciesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpOsconfigOsPolicyAssignmentReportsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpOsconfigOsPolicyAssignmentReportsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpOsconfigOsPolicyAssignmentsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpOsconfigOsPolicyAssignmentsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpOsconfigPatchDeploymentsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpOsconfigPatchDeploymentsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpOsconfigVulnerabilityReportsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpOsconfigVulnerabilityReportsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpPermissionManagementRisk extends GcpBehaviorIdentityRisk implements ITypeNameObject, IActivityRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        endTime: string,
        eventActions: string[],
        identityId: string,
        ipAddresses: string[],
        previousBehaviorDate: Optional<string>,
        severityInfos: GcpBehaviorIdentityRiskSeverityInfo[],
        startTime: string,
        public sensitiveScopeResourceIds: string[],
        public sensitiveWideScopeChildScopeResourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpPermissionManagementRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            endTime,
            eventActions,
            identityId,
            ipAddresses,
            previousBehaviorDate,
            severityInfos,
            startTime);
    }
}

export class GcpPermissionManagementRiskModel extends GcpBehaviorIdentityRiskModel implements ITypeNameObject, IUdmObjectModel, IBehaviorIdentityRiskModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpPermissionManagementRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        originatorEntityIdReferences: string[],
        infos: GcpBehaviorIdentityRiskModelInfo[],
        resourceIdReferences: string[],
        public roleBindingDeltaModels: GcpIamRoleBindingDeltaModel[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpPermissionManagementRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            originatorEntityIdReferences,
            infos,
            resourceIdReferences);
    }
}

export class GcpPrincipalNotAllowedResourceRoleRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public resourceRoleItems: GcpPrincipalNotAllowedResourceRoleRiskItem[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpPrincipalNotAllowedResourceRoleRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GcpPrincipalNotAllowedResourceRoleRiskModel extends GcpCustomRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpPrincipalNotAllowedResourceRoleRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public roleBindingIds: string[],
        public scopeResourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpPrincipalNotAllowedResourceRoleRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpPrincipalResourcePermissionRisk extends CloudRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        public principalResourcePermissionItems: GcpPrincipalResourcePermissionRiskItem[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType);
    }
}

export class GcpPrincipalResourcePermissionRiskModel extends GcpCustomRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpPrincipalResourcePermissionRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public permissionActions: string[],
        public principalIds: string[],
        public resourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpPrincipalServiceAccountWideScopeAdministratorRoleCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpPrincipalServiceAccountWideScopeAdministratorRoleCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class GcpPrincipalServiceAccountWideScopeAdministratorRoleCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpPrincipalServiceAccountWideScopeAdministratorRoleCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpPrincipalServiceAccountWideScopeAdministratorRoleCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class GcpPrincipalServiceAccountWideScopeAdministratorRoleRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public groupServiceAccountIds: string[],
        public orderedResourceManagerResourceIds: string[],
        public principalMail: string,
        public principalServiceAccountCreatorIdentityIdReference: Optional<string>,
        public principalServiceAccountCreatorOriginatorEntityIdReference: Optional<string>,
        public resourceManagerResourceIdToRoleIdsMap: Dictionary<string[]>,
        public roleBindingIds: string[],
        public serviceAccountDisabled: Optional<boolean>,
        public serviceAccountSignInActivity: Optional<Activity>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpPrincipalServiceAccountWideScopeAdministratorRoleRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GcpPrincipalServiceAccountWideScopeAdministratorRoleRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpPrincipalServiceAccountWideScopeAdministratorRoleRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public roleIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpPrincipalServiceAccountWideScopeAdministratorRoleRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpPrincipalServiceAccountWideScopeImpersonateServiceAccountActionExistsCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpPrincipalServiceAccountWideScopeImpersonateServiceAccountActionExistsCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class GcpPrincipalServiceAccountWideScopeImpersonateServiceAccountActionExistsCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpPrincipalServiceAccountWideScopeImpersonateServiceAccountActionExistsCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpPrincipalServiceAccountWideScopeImpersonateServiceAccountActionExistsCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class GcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class GcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class GcpPrincipalWideScopeImpersonateServiceAccountActionExistsRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public groupIdentityIds: string[],
        public identityDisabled: Optional<boolean>,
        public orderedResourceManagerResourceIds: string[],
        public resourceManagerResourceIdToRoleIdsMap: Dictionary<string[]>,
        public roleBindingIdToImpersonatePermissionActionsMap: Dictionary<string[]>,
        public serviceAccountIdToSeverityMap: Dictionary<Optional<Severity>>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GcpPrincipalWideScopeImpersonateServiceAccountActionExistsRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpPrincipalWideScopeImpersonateServiceAccountActionExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public roleIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpPrivateCaCaPoolsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpPrivateCaCaPoolsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpPrivateCaCertificateAuthoritiesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpPrivateCaCertificateAuthoritiesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpPrivateCaCertificateRevocationListsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpPrivateCaCertificateRevocationListsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpPrivateCaCertificatesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpPrivateCaCertificatesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpPrivateCaCertificateTemplatesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpPrivateCaCertificateTemplatesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpPrivilegedaccessmanagerGrantsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpPrivilegedaccessmanagerGrantsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpPubSubSchemasGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpPubSubSchemasGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpPubSubSnapshotsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource, IGcpTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpPubSubSnapshotsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpPubSubSnapshotsGenericResourceModel extends GcpAssetGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpPubSubSnapshotsGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpPubSubSubscription extends GcpScopeResource implements ITypeNameObject, IResource, IGcpTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        public deadLetterTopicRawId: Optional<string>,
        public expirationTimeFrame: Optional<string>,
        public filter: Optional<string>,
        public messageDeliverySettings: GcpPubSubSubscriptionMessageDeliverySettings,
        public messageRetentionDuration: string,
        public status: GcpPubSubSubscriptionStatus,
        public topicMessageRetentionDuration: Optional<string>,
        public topicRawId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpPubSubSubscription",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId);
    }
}

export class GcpPubSubSubscriptionModel extends GcpScopeResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[],
        public deadLetterTopicIdReference: Optional<string>,
        public topicIdReference: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpPubSubSubscriptionModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpPubSubSubscriptionPublicAccessExistsCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpPubSubSubscriptionPublicAccessExistsCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class GcpPubSubSubscriptionPublicAccessExistsCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpPubSubSubscriptionPublicAccessExistsCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpPubSubSubscriptionPublicAccessExistsCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class GcpPubSubSubscriptionsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpPubSubSubscriptionsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpPubSubTopic extends GcpScopeResource implements ITypeNameObject, IResource, IGcpEncryptedResource, IGcpTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        public encryptionExists: boolean,
        public kmsEncryptionKeyAssetPaths: string[],
        public messageRetentionDuration: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpPubSubTopic",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId);
    }
}

export class GcpPubSubTopicModel extends GcpScopeResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IGcpEncryptedResourceModel, IGcpLoggingLogSinkDestinationResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[],
        public kmsEncryptionKeyReferences: GcpScopeResourceReference[],
        public loggingLogSinkIds: string[],
        public subscriptionIds: string[],
        public triggerFunctionIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpPubSubTopicModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpPubSubTopicPublicAccessExistsCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpPubSubTopicPublicAccessExistsCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class GcpPubSubTopicPublicAccessExistsCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpPubSubTopicPublicAccessExistsCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpPubSubTopicPublicAccessExistsCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class GcpPubSubTopicsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpPubSubTopicsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpRecaptchaEnterpriseFirewallpoliciesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpRecaptchaEnterpriseFirewallpoliciesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpRecaptchaEnterpriseKeysGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpRecaptchaEnterpriseKeysGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpRedisClustersGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpRedisClustersGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpRedisInstancesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource, IGcpTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpRedisInstancesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpRedisInstancesGenericResourceModel extends GcpAssetGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpRedisInstancesGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpResourceChange extends GcpChange implements ITypeNameObject, ICloudProviderResourceChange {
    constructor(
        typeName: string,
        entityIds: string[],
        id: string,
        resolved: boolean,
        public resourceId: string) {
        super(
            typeName,
            entityIds,
            id,
            resolved);
    }
}

export class GcpResourceCodeResourceNotExistsRisk extends CloudRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpResourceCodeResourceNotExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType);
    }
}

export class GcpResourceCodeResourceNotExistsRiskModel extends GcpCustomRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpResourceCodeResourceNotExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpResourceCodeResourceNotExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpResourceManagerFolderModel extends GcpScopeResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IRelatedTenantsEntityModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpResourceManagerFolderModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpResourceManagerFolderProfile extends GcpResourceManagerResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<GcpResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpResourceManagerFolderProfile",
            creation);
    }
}

export class GcpResourceManagerFoldersGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpResourceManagerFoldersGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpResourceManagerLiensGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpResourceManagerLiensGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpResourceManagerOrganizationModel extends GcpScopeResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IRelatedTenantsEntityModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpResourceManagerOrganizationModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpResourceManagerOrganizationProfile extends GcpResourceManagerResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<GcpResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpResourceManagerOrganizationProfile",
            creation);
    }
}

export class GcpResourceManagerOrganizationsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpResourceManagerOrganizationsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpResourceManagerProjectsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpResourceManagerProjectsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpResourceManagerResource extends GcpScopeResource implements ITypeNameObject, IGcpRoleBindingUsageResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        public rawShortId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId);
    }
}

export class GcpResourceManagerTagBindingsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpResourceManagerTagBindingsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpResourceManagerTagKeysGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpResourceManagerTagKeysGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpResourceManagerTagValuesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpResourceManagerTagValuesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpResourceTagNotExistsRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpResourceTagNotExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GcpResourceTagNotExistsRiskModel extends GcpCustomRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpResourceTagNotExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public existsResourceTagKeys: Optional<string[]>,
        public existsResourceTagPattern: Optional<ResourceTagPattern>,
        public existsResourceTags: Optional<ResourceTag[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpResourceTagNotExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpResourceTagSecretExistsRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public excludedSecretTagKeys: string[],
        public readTagPermissionActionPrincipalIds: string[],
        public riskedEntityTypeName: string,
        public secretTagKeys: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpResourceTagSecretExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GcpResourceTagSecretExistsRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel, ISecretExistsRiskModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpResourceTagSecretExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public secretCount: number,
        public secretManagerConsoleUrl: string,
        public secretManagerDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpResourceTagSecretExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpRunDomainMappingsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpRunDomainMappingsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpRunExecutionsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpRunExecutionsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpRunJobsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpRunJobsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpRunRevisionsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpRunRevisionsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpRunServicesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpRunServicesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpScopeResourcePublicAccessExistsRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        risk: Risk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpScopeResourceSearchableReference extends EntitySearchableReferenceBase<GcpScopeResourceReference> {
    constructor(
        displayName: string,
        public scopeResourceReference: GcpScopeResourceReference) {
        super(displayName);
    }
}

export class GcpSecretManagerSecretRotationTimeFrameCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSecretManagerSecretRotationTimeFrameCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class GcpSecretManagerSecretRotationTimeFrameCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpSecretManagerSecretRotationTimeFrameCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSecretManagerSecretRotationTimeFrameCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class GcpSecretManagerSecretRotationTimeFrameRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public secretRotationTimeFrame: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSecretManagerSecretRotationTimeFrameRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GcpSecretManagerSecretRotationTimeFrameRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpSecretManagerSecretRotationTimeFrameRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public pubSubDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSecretManagerSecretRotationTimeFrameRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpSecretManagerSecretRotationTimeFrameRiskPolicyUpdateAuditEventSecretRotationTimeFrameChange extends RiskPolicyUpdateAuditEventChange implements ITypeNameObject {
    constructor(
        public previousSecretRotationTimeFrame: Optional<string>,
        public secretRotationTimeFrame: Optional<string>) {
        super("GcpSecretManagerSecretRotationTimeFrameRiskPolicyUpdateAuditEventSecretRotationTimeFrameChange");
    }
}

export class GcpSecretManagerSecretsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSecretManagerSecretsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpSecretManagerVersionsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSecretManagerVersionsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpSecuresourcemanagerInstancesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSecuresourcemanagerInstancesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpSecurityposturePostureDeploymentsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSecurityposturePostureDeploymentsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpSecurityposturePosturesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSecurityposturePosturesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpServicedirectoryEndpointsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpServicedirectoryEndpointsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpServicedirectoryNamespacesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpServicedirectoryNamespacesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpServicedirectoryServicesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpServicedirectoryServicesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpServiceManagementServicesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpServiceManagementServicesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpServiceUsageServicesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpServiceUsageServicesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpSeverePermissionPrincipalRisk extends GcpAccessPrincipalRisk implements ITypeNameObject, IAccessPrincipalRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        groupIdentityIds: string[],
        principalMail: string,
        signInActivity: Optional<Activity>,
        public identityCreationTime: Optional<string>,
        public principalServiceAccountCreatorIdentityIdReference: Optional<string>,
        public principalServiceAccountCreatorOriginatorEntityIdReference: Optional<string>,
        public severePermissionActionPermittingEntityIds: string[],
        public severePermissionActionStartTime: string,
        public type: PrincipalSeverePermissionActionRiskType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSeverePermissionPrincipalRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            groupIdentityIds,
            principalMail,
            signInActivity);
    }
}

export class GcpSeverePermissionPrincipalRiskModel extends GcpAccessPrincipalRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpSeverePermissionPrincipalRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        principalCreationTime: Optional<string>,
        public infos: GcpSeverePermissionPrincipalRiskModelInfo[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSeverePermissionPrincipalRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            principalCreationTime);
    }
}

export class GcpSpannerBackupsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSpannerBackupsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpSpannerDatabasesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSpannerDatabasesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpSpannerInstanceConfigsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSpannerInstanceConfigsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpSpannerInstancesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSpannerInstancesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpSpeakeridPhrasesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSpeakeridPhrasesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpSpeakeridSettingsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSpeakeridSettingsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpSpeakeridSpeakersGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSpeakeridSpeakersGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpSpeechConfigsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSpeechConfigsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpSpeechCustomClassesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSpeechCustomClassesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpSpeechPhraseSetsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSpeechPhraseSetsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpSpeechRecognizersGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSpeechRecognizersGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpSqlInstanceBackupDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstanceBackupDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class GcpSqlInstanceBackupDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpSqlInstanceBackupDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstanceBackupDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class GcpSqlInstanceBackupDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstanceBackupDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GcpSqlInstanceBackupDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpSqlInstanceBackupDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstanceBackupDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpSqlInstanceHttpsOnlyDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstanceHttpsOnlyDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class GcpSqlInstanceHttpsOnlyDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpSqlInstanceHttpsOnlyDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstanceHttpsOnlyDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class GcpSqlInstanceHttpsOnlyDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstanceHttpsOnlyDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GcpSqlInstanceHttpsOnlyDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpSqlInstanceHttpsOnlyDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstanceHttpsOnlyDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpSqlInstanceMySqlListDatabasesPermissionRequiredDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstanceMySqlListDatabasesPermissionRequiredDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class GcpSqlInstanceMySqlListDatabasesPermissionRequiredDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpSqlInstanceMySqlListDatabasesPermissionRequiredDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstanceMySqlListDatabasesPermissionRequiredDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class GcpSqlInstanceMySqlListDatabasesPermissionRequiredDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstanceMySqlListDatabasesPermissionRequiredDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GcpSqlInstanceMySqlListDatabasesPermissionRequiredDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpSqlInstanceMySqlListDatabasesPermissionRequiredDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstanceMySqlListDatabasesPermissionRequiredDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpSqlInstanceMySqlLoadClientFileEnabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstanceMySqlLoadClientFileEnabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class GcpSqlInstanceMySqlLoadClientFileEnabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpSqlInstanceMySqlLoadClientFileEnabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstanceMySqlLoadClientFileEnabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class GcpSqlInstanceMySqlLoadClientFileEnabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstanceMySqlLoadClientFileEnabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GcpSqlInstanceMySqlLoadClientFileEnabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpSqlInstanceMySqlLoadClientFileEnabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstanceMySqlLoadClientFileEnabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpSqlInstancePostgreSqlAuditDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstancePostgreSqlAuditDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class GcpSqlInstancePostgreSqlAuditDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpSqlInstancePostgreSqlAuditDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstancePostgreSqlAuditDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class GcpSqlInstancePostgreSqlAuditDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstancePostgreSqlAuditDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GcpSqlInstancePostgreSqlAuditDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpSqlInstancePostgreSqlAuditDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstancePostgreSqlAuditDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpSqlInstancePostgreSqlLogConnectionsDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstancePostgreSqlLogConnectionsDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class GcpSqlInstancePostgreSqlLogConnectionsDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpSqlInstancePostgreSqlLogConnectionsDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstancePostgreSqlLogConnectionsDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class GcpSqlInstancePostgreSqlLogConnectionsDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstancePostgreSqlLogConnectionsDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GcpSqlInstancePostgreSqlLogConnectionsDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpSqlInstancePostgreSqlLogConnectionsDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstancePostgreSqlLogConnectionsDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpSqlInstancePostgreSqlLogConnectionsHostNameDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstancePostgreSqlLogConnectionsHostNameDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class GcpSqlInstancePostgreSqlLogConnectionsHostNameDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpSqlInstancePostgreSqlLogConnectionsHostNameDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstancePostgreSqlLogConnectionsHostNameDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class GcpSqlInstancePostgreSqlLogConnectionsHostNameDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstancePostgreSqlLogConnectionsHostNameDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GcpSqlInstancePostgreSqlLogConnectionsHostNameDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpSqlInstancePostgreSqlLogConnectionsHostNameDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstancePostgreSqlLogConnectionsHostNameDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpSqlInstancePostgreSqlLogDisconnectionsDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstancePostgreSqlLogDisconnectionsDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class GcpSqlInstancePostgreSqlLogDisconnectionsDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpSqlInstancePostgreSqlLogDisconnectionsDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstancePostgreSqlLogDisconnectionsDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class GcpSqlInstancePostgreSqlLogDisconnectionsDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstancePostgreSqlLogDisconnectionsDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GcpSqlInstancePostgreSqlLogDisconnectionsDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpSqlInstancePostgreSqlLogDisconnectionsDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstancePostgreSqlLogDisconnectionsDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpSqlInstancePostgreSqlLogErrorVerbosityCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstancePostgreSqlLogErrorVerbosityCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class GcpSqlInstancePostgreSqlLogErrorVerbosityCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpSqlInstancePostgreSqlLogErrorVerbosityCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstancePostgreSqlLogErrorVerbosityCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class GcpSqlInstancePostgreSqlLogErrorVerbosityRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstancePostgreSqlLogErrorVerbosityRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GcpSqlInstancePostgreSqlLogErrorVerbosityRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpSqlInstancePostgreSqlLogErrorVerbosityRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstancePostgreSqlLogErrorVerbosityRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpSqlInstancePostgreSqlLogMinLevelCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstancePostgreSqlLogMinLevelCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class GcpSqlInstancePostgreSqlLogMinLevelCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpSqlInstancePostgreSqlLogMinLevelCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstancePostgreSqlLogMinLevelCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class GcpSqlInstancePostgreSqlLogMinLevelRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstancePostgreSqlLogMinLevelRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GcpSqlInstancePostgreSqlLogMinLevelRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpSqlInstancePostgreSqlLogMinLevelRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstancePostgreSqlLogMinLevelRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpSqlInstancePostgreSqlMinDurationLogQueryEnabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstancePostgreSqlMinDurationLogQueryEnabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class GcpSqlInstancePostgreSqlMinDurationLogQueryEnabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpSqlInstancePostgreSqlMinDurationLogQueryEnabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstancePostgreSqlMinDurationLogQueryEnabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class GcpSqlInstancePostgreSqlMinDurationLogQueryEnabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstancePostgreSqlMinDurationLogQueryEnabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GcpSqlInstancePostgreSqlMinDurationLogQueryEnabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpSqlInstancePostgreSqlMinDurationLogQueryEnabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstancePostgreSqlMinDurationLogQueryEnabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpSqlInstancePostgreSqlMinLogLevelLogQueryCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstancePostgreSqlMinLogLevelLogQueryCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class GcpSqlInstancePostgreSqlMinLogLevelLogQueryCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpSqlInstancePostgreSqlMinLogLevelLogQueryCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstancePostgreSqlMinLogLevelLogQueryCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class GcpSqlInstancePostgreSqlMinLogLevelLogQueryRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstancePostgreSqlMinLogLevelLogQueryRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GcpSqlInstancePostgreSqlMinLogLevelLogQueryRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpSqlInstancePostgreSqlMinLogLevelLogQueryRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstancePostgreSqlMinLogLevelLogQueryRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpSqlInstancePostgreSqlStatementLogDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstancePostgreSqlStatementLogDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class GcpSqlInstancePostgreSqlStatementLogDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpSqlInstancePostgreSqlStatementLogDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstancePostgreSqlStatementLogDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class GcpSqlInstancePostgreSqlStatementLogDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstancePostgreSqlStatementLogDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GcpSqlInstancePostgreSqlStatementLogDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpSqlInstancePostgreSqlStatementLogDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstancePostgreSqlStatementLogDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpSqlInstanceSqlServerContainedDatabaseAuthenticationEnabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstanceSqlServerContainedDatabaseAuthenticationEnabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class GcpSqlInstanceSqlServerContainedDatabaseAuthenticationEnabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpSqlInstanceSqlServerContainedDatabaseAuthenticationEnabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstanceSqlServerContainedDatabaseAuthenticationEnabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class GcpSqlInstanceSqlServerContainedDatabaseAuthenticationEnabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstanceSqlServerContainedDatabaseAuthenticationEnabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GcpSqlInstanceSqlServerContainedDatabaseAuthenticationEnabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpSqlInstanceSqlServerContainedDatabaseAuthenticationEnabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstanceSqlServerContainedDatabaseAuthenticationEnabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpSqlInstanceSqlServerCrossDatabaseImplicitPermissionsEnabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstanceSqlServerCrossDatabaseImplicitPermissionsEnabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class GcpSqlInstanceSqlServerCrossDatabaseImplicitPermissionsEnabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpSqlInstanceSqlServerCrossDatabaseImplicitPermissionsEnabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstanceSqlServerCrossDatabaseImplicitPermissionsEnabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class GcpSqlInstanceSqlServerCrossDatabaseImplicitPermissionsEnabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstanceSqlServerCrossDatabaseImplicitPermissionsEnabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GcpSqlInstanceSqlServerCrossDatabaseImplicitPermissionsEnabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpSqlInstanceSqlServerCrossDatabaseImplicitPermissionsEnabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstanceSqlServerCrossDatabaseImplicitPermissionsEnabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpSqlInstanceSqlServerCrossInstanceProcedureExecutionEnabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstanceSqlServerCrossInstanceProcedureExecutionEnabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class GcpSqlInstanceSqlServerCrossInstanceProcedureExecutionEnabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpSqlInstanceSqlServerCrossInstanceProcedureExecutionEnabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstanceSqlServerCrossInstanceProcedureExecutionEnabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class GcpSqlInstanceSqlServerCrossInstanceProcedureExecutionEnabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstanceSqlServerCrossInstanceProcedureExecutionEnabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GcpSqlInstanceSqlServerCrossInstanceProcedureExecutionEnabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpSqlInstanceSqlServerCrossInstanceProcedureExecutionEnabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstanceSqlServerCrossInstanceProcedureExecutionEnabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpSqlInstanceSqlServerLogMaskParametersDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstanceSqlServerLogMaskParametersDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class GcpSqlInstanceSqlServerLogMaskParametersDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpSqlInstanceSqlServerLogMaskParametersDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstanceSqlServerLogMaskParametersDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class GcpSqlInstanceSqlServerLogMaskParametersDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstanceSqlServerLogMaskParametersDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GcpSqlInstanceSqlServerLogMaskParametersDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpSqlInstanceSqlServerLogMaskParametersDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstanceSqlServerLogMaskParametersDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpSqlInstanceSqlServerScriptExecutionEnabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstanceSqlServerScriptExecutionEnabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class GcpSqlInstanceSqlServerScriptExecutionEnabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpSqlInstanceSqlServerScriptExecutionEnabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstanceSqlServerScriptExecutionEnabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class GcpSqlInstanceSqlServerScriptExecutionEnabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstanceSqlServerScriptExecutionEnabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GcpSqlInstanceSqlServerScriptExecutionEnabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpSqlInstanceSqlServerScriptExecutionEnabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstanceSqlServerScriptExecutionEnabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpSqlInstanceSqlServerUserMaxConnectionsExistsCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstanceSqlServerUserMaxConnectionsExistsCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class GcpSqlInstanceSqlServerUserMaxConnectionsExistsCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpSqlInstanceSqlServerUserMaxConnectionsExistsCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstanceSqlServerUserMaxConnectionsExistsCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class GcpSqlInstanceSqlServerUserMaxConnectionsExistsRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstanceSqlServerUserMaxConnectionsExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GcpSqlInstanceSqlServerUserMaxConnectionsExistsRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpSqlInstanceSqlServerUserMaxConnectionsExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstanceSqlServerUserMaxConnectionsExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpSqlInstanceSqlServerUserOptionsEnabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstanceSqlServerUserOptionsEnabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class GcpSqlInstanceSqlServerUserOptionsEnabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpSqlInstanceSqlServerUserOptionsEnabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstanceSqlServerUserOptionsEnabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class GcpSqlInstanceSqlServerUserOptionsEnabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstanceSqlServerUserOptionsEnabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GcpSqlInstanceSqlServerUserOptionsEnabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpSqlInstanceSqlServerUserOptionsEnabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstanceSqlServerUserOptionsEnabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpSqlInstanceStateNetwork extends GcpNetworkAccessResourceStateNetwork implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        entityReference: EntityReference,
        inboundAccessType: NetworkInboundAccessType,
        inboundExternal: boolean,
        inboundExternalAccessScope: NetworkAccessScope,
        inboundExternalDestinationNetworkScopes: Optional<DestinationNetworkScope[]>,
        inboundExternalWideRange: Optional<boolean>,
        public inboundExternalScopes: GcpNetworkScope[],
        public wideRangeInboundAuthorizedSubnets: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstanceStateNetwork",
            tenantId,
            entityReference,
            inboundAccessType,
            inboundExternal,
            inboundExternalAccessScope,
            inboundExternalDestinationNetworkScopes,
            inboundExternalWideRange);
    }
}

export class GcpSqlInstanceWideRangeInboundCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstanceWideRangeInboundCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class GcpSqlInstanceWideRangeInboundCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpSqlInstanceWideRangeInboundCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstanceWideRangeInboundCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class GcpSqlInstanceWideRangeInboundRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpSqlInstanceWideRangeInboundRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstanceWideRangeInboundRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpStorageBucketLockedRetentionPolicyNotEnabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpStorageBucketLockedRetentionPolicyNotEnabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class GcpStorageBucketLockedRetentionPolicyNotEnabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpStorageBucketLockedRetentionPolicyNotEnabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpStorageBucketLockedRetentionPolicyNotEnabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class GcpStorageBucketLockedRetentionPolicyNotEnabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpStorageBucketLockedRetentionPolicyNotEnabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GcpStorageBucketLockedRetentionPolicyNotEnabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpStorageBucketLockedRetentionPolicyNotEnabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpStorageBucketLockedRetentionPolicyNotEnabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpStorageBucketPublicAccessExistsCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpStorageBucketPublicAccessExistsCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class GcpStorageBucketPublicAccessExistsCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpStorageBucketPublicAccessExistsCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpStorageBucketPublicAccessExistsCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class GcpStorageBucketPublicAccessExistsRiskModel extends GcpScopeResourcePublicAccessExistsRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpStorageBucketPublicAccessExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public descriptionType: GcpStorageBucketPublicAccessExistsRiskModelDescriptionType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpStorageBucketPublicAccessExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpStorageBucketsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpStorageBucketsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpStorageBucketUniformAccessControlDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpStorageBucketUniformAccessControlDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class GcpStorageBucketUniformAccessControlDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpStorageBucketUniformAccessControlDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpStorageBucketUniformAccessControlDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class GcpStorageBucketUniformAccessControlDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpStorageBucketUniformAccessControlDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GcpStorageBucketUniformAccessControlDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpStorageBucketUniformAccessControlDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpStorageBucketUniformAccessControlDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpStorageinsightsReportConfigsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpStorageinsightsReportConfigsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpStorageinsightsReportDetailsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpStorageinsightsReportDetailsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpTenantEntity extends GcpResourceManagerResource implements ITypeNameObject, IGcpRoleBindingUsageResource, IGcpTagResource, ITenantEntity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        rawShortId: string,
        public enabledServiceApiServiceHostNames: string[],
        public organizationPolicySshSerialPortNotEnabledEnforced: boolean,
        public rawShortNameId: string,
        public rawShortNumberId: string,
        public usage: Optional<GcpTenantEntityUsage>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpTenantEntity",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            rawShortId);
    }
}

export class GcpTenantEntityCloudAssetServiceNotEnabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpTenantEntityCloudAssetServiceNotEnabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GcpTenantEntityCloudAssetServiceNotEnabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpTenantEntityCloudAssetServiceNotEnabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpTenantEntityCloudAssetServiceNotEnabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpTenantEntityCreationRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpTenantEntityCreationRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GcpTenantEntityCreationRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpTenantEntityCreationRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpTenantEntityCreationRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpTenantEntityUnusedRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public projectUsageTimeFrame: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpTenantEntityUnusedRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GcpTenantEntityUnusedRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpTenantEntityUnusedRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public manageProjectDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpTenantEntityUnusedRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpTenantEntityUnusedRiskPolicyUpdateAuditEventProjectUsageTimeFrameChange extends RiskPolicyUpdateAuditEventChange implements ITypeNameObject {
    constructor(
        public previousProjectUsageTimeFrame: Optional<string>,
        public projectUsageTimeFrame: Optional<string>) {
        super("GcpTenantEntityUnusedRiskPolicyUpdateAuditEventProjectUsageTimeFrameChange");
    }
}

export class GcpTpuNodesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpTpuNodesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpTranscoderJobsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpTranscoderJobsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpTranscoderJobTemplatesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpTranscoderJobTemplatesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpUnusedTenantEntityAttribute extends BuiltInEntityAttribute implements ITypeNameObject {
    constructor() {
        super("GcpUnusedTenantEntityAttribute");
    }
}

export class GcpUserManagedKeyExistsServiceAccountAttribute extends BuiltInEntityAttribute implements ITypeNameObject {
    constructor() {
        super("GcpUserManagedKeyExistsServiceAccountAttribute");
    }
}

export class GcpVideoStitcherCdnKeysGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpVideoStitcherCdnKeysGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpVideoStitcherLiveConfigsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpVideoStitcherLiveConfigsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpVideoStitcherSlatesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpVideoStitcherSlatesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpVideoStitcherVodConfigsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpVideoStitcherVodConfigsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpVmMigrationCloneJobsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpVmMigrationCloneJobsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpVmMigrationCutoverJobsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpVmMigrationCutoverJobsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpVmMigrationDatacenterConnectorsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpVmMigrationDatacenterConnectorsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpVmMigrationGroupsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpVmMigrationGroupsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpVmMigrationMigratingVmsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpVmMigrationMigratingVmsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpVmMigrationSourcesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpVmMigrationSourcesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpVmMigrationTargetProjectsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpVmMigrationTargetProjectsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpVmMigrationUtilizationReportsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpVmMigrationUtilizationReportsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpVmwareengineClustersGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpVmwareengineClustersGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpVmwareengineExternalAccessRulesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpVmwareengineExternalAccessRulesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpVmwareengineExternalAddressesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpVmwareengineExternalAddressesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpVmwareengineNetworkPeeringsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpVmwareengineNetworkPeeringsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpVmwareengineNetworkPoliciesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpVmwareengineNetworkPoliciesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpVmwareenginePrivateCloudsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpVmwareenginePrivateCloudsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpVmwareenginePrivateConnectionsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpVmwareenginePrivateConnectionsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpVmwareengineVmwareEngineNetworksGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpVmwareengineVmwareEngineNetworksGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpVpcAccessConnectorsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpVpcAccessConnectorsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpWorkflowsWorkflowsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpWorkflowsWorkflowsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpWorkstationsWorkstationClustersGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpWorkstationsWorkstationClustersGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpWorkstationsWorkstationConfigsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpWorkstationsWorkstationConfigsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpWorkstationsWorkstationsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpWorkstationsWorkstationsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GitHubOrganizationConfiguration extends CodeOrganizationConfiguration implements ITypeNameObject, ITenantOrganizationConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        name: string,
        folderEnabled: boolean,
        memberSelection: Optional<OrganizationMemberSelection>,
        serverId: Optional<string>,
        syncEnabled: boolean,
        type: CodeTenantType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GitHubOrganizationConfiguration",
            scopeId,
            name,
            folderEnabled,
            memberSelection,
            serverId,
            syncEnabled,
            type);
    }
}

export class GitHubTenantConfiguration extends GitTenantConfiguration implements ITypeNameObject, ICodeScopeConfiguration, IOrganizationTenantConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        active: boolean,
        deletePending: boolean,
        disabledOrchestrationTypes: OrchestrationType[],
        displayReference: string,
        name: string,
        orchestrationTypeToEnabledMap: Dictionary<boolean>,
        parentScopeId: string,
        scopeSections: ScopeConfigurationSections,
        type: TenantType,
        codeTenantType: CodeTenantType,
        path: string,
        url: string,
        branchName: string,
        debug: Optional<boolean>,
        organizationId: string,
        syncEnabled: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GitHubTenantConfiguration",
            active,
            deletePending,
            disabledOrchestrationTypes,
            displayReference,
            name,
            orchestrationTypeToEnabledMap,
            parentScopeId,
            scopeSections,
            type,
            codeTenantType,
            path,
            url,
            branchName,
            debug,
            organizationId,
            syncEnabled);
    }
}

export class GitLabOrganizationConfiguration extends CodeOrganizationConfiguration implements ITypeNameObject, ITenantOrganizationConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        name: string,
        folderEnabled: boolean,
        memberSelection: Optional<OrganizationMemberSelection>,
        serverId: Optional<string>,
        syncEnabled: boolean,
        type: CodeTenantType,
        public encryptedAccessToken: EncryptedData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GitLabOrganizationConfiguration",
            scopeId,
            name,
            folderEnabled,
            memberSelection,
            serverId,
            syncEnabled,
            type);
    }
}

export class GitLabTenantConfiguration extends GitTenantConfiguration implements ITypeNameObject, ICodeScopeConfiguration, IOrganizationTenantConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        active: boolean,
        deletePending: boolean,
        disabledOrchestrationTypes: OrchestrationType[],
        displayReference: string,
        name: string,
        orchestrationTypeToEnabledMap: Dictionary<boolean>,
        parentScopeId: string,
        scopeSections: ScopeConfigurationSections,
        type: TenantType,
        codeTenantType: CodeTenantType,
        path: string,
        url: string,
        branchName: string,
        debug: Optional<boolean>,
        organizationId: string,
        syncEnabled: boolean,
        public subGroupPath: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GitLabTenantConfiguration",
            active,
            deletePending,
            disabledOrchestrationTypes,
            displayReference,
            name,
            orchestrationTypeToEnabledMap,
            parentScopeId,
            scopeSections,
            type,
            codeTenantType,
            path,
            url,
            branchName,
            debug,
            organizationId,
            syncEnabled);
    }
}

export class Group extends Principal implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        public identifier: string,
        public name: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "Group");
    }
}

export class GroupMembershipRequest extends PermissionRequest implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        closeReason: Optional<PermissionRequestCloseReason>,
        expirationTime: Optional<string>,
        expirationTimeFrame: string,
        granteeUserTenantId: string,
        permissionEligibilityId: string,
        reason: Optional<string>,
        startTime: Optional<string>,
        status: PermissionRequestStatus,
        statusUpdateTime: string,
        public assignedGroupIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            closeReason,
            expirationTime,
            expirationTimeFrame,
            granteeUserTenantId,
            permissionEligibilityId,
            reason,
            startTime,
            status,
            statusUpdateTime);
    }
}

export class GroupMembershipRequestActivationFailureData extends PermissionRequestActivationFailureData implements ITypeNameObject {
    constructor(
        errorMessage: Optional<string>,
        retryCount: number) {
        super(
            "GroupMembershipRequestActivationFailureData",
            errorMessage,
            retryCount);
    }
}

export class GroupMembershipRequestModelActivationFailureData extends PermissionRequestModelActivationFailureData implements ITypeNameObject {
    constructor(
        activationFailureData: PermissionRequestActivationFailureData,
        public groupIdToErrorMessageMap: Dictionary<string>) {
        super(
            "GroupMembershipRequestModelActivationFailureData",
            activationFailureData);
    }
}

export class GroupModelAccess extends PrincipalModelAccess implements ITypeNameObject {
    constructor(
        activity: Activity,
        permissions: PrincipalModelAccessPermissions,
        riskCategory: Optional<PrincipalRiskCategory>,
        risks: PrincipalModelAccessRisks,
        scopesRisks: PrincipalModelAccessScopesRisk[],
        types: PrincipalType[]) {
        super(
            "GroupModelAccess",
            activity,
            permissions,
            riskCategory,
            risks,
            scopesRisks,
            types);
    }
}

export class Identity extends Principal implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        public name: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName);
    }
}

export class IdentityModelAccess extends PrincipalModelAccess implements ITypeNameObject {
    constructor(
        typeName: string,
        activity: Activity,
        permissions: PrincipalModelAccessPermissions,
        riskCategory: Optional<PrincipalRiskCategory>,
        risks: PrincipalModelAccessRisks,
        scopesRisks: PrincipalModelAccessScopesRisk[],
        types: PrincipalType[]) {
        super(
            typeName,
            activity,
            permissions,
            riskCategory,
            risks,
            scopesRisks,
            types);
    }
}

export class InactiveIdentityAttribute extends BuiltInEntityAttribute implements ITypeNameObject {
    constructor() {
        super("InactiveIdentityAttribute");
    }
}

export class IntegrationEntityConfiguration extends ScopeSystemEntityConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        public type: IntegrationType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId);
    }
}

export class InventoryReportDefinitionConfiguration extends ReportDefinitionConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        contentType: ReportContentType,
        exportReport: boolean,
        licenseType: ApplicationCustomerConfigurationLicensingLicenseType,
        name: Optional<string>,
        timeZoneInfoId: string,
        type: ReportType,
        public data: boolean,
        public entityTypeName: Optional<string>,
        public filterMap: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "InventoryReportDefinitionConfiguration",
            contentType,
            exportReport,
            licenseType,
            name,
            timeZoneInfoId,
            type);
    }
}

export class IpAddressSearchableData extends SearchableData<string> {
    constructor() {
        super();
    }
}

export class JiraCustomLabelsField extends JiraField implements ITypeNameObject {
    constructor(
        id: string,
        name: string,
        required: boolean,
        public customId: number,
        public defaultValues: Optional<string[]>) {
        super(
            "JiraCustomLabelsField",
            id,
            name,
            required);
    }
}

export class JiraDateField extends JiraField implements ITypeNameObject {
    constructor(
        id: string,
        name: string,
        required: boolean,
        public defaultDate: Optional<string>) {
        super(
            "JiraDateField",
            id,
            name,
            required);
    }
}

export class JiraDateTimeField extends JiraField implements ITypeNameObject {
    constructor(
        id: string,
        name: string,
        required: boolean,
        public defaultTime: Optional<string>) {
        super(
            "JiraDateTimeField",
            id,
            name,
            required);
    }
}

export class JiraInstanceConfiguration extends IntegrationEntityConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        type: IntegrationType,
        public connectionInfo: JiraInstanceConfigurationConnectionInfo,
        public projects: JiraInstanceConfigurationProject[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "JiraInstanceConfiguration",
            scopeId,
            type);
    }
}

export class JiraMultiSelectionField extends JiraSelectionField implements ITypeNameObject {
    constructor(
        id: string,
        name: string,
        required: boolean,
        options: string[],
        public defaultValues: Optional<string[]>) {
        super(
            "JiraMultiSelectionField",
            id,
            name,
            required,
            options);
    }
}

export class KubernetesClusterAdmissionControllerEvent extends KubernetesClusterEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        clusterReference: EntitySearchableEventReference,
        count: number,
        endTime: string,
        startTime: string,
        systemKubernetesClusterId: string,
        time: string,
        public dryRun: boolean,
        public identityReference: Optional<EntitySearchableEventReference>,
        public ownerResourceReference: Optional<EntitySearchableEventReference>,
        public raw: Optional<string>,
        public resourceOperation: KubernetesAdmissionControllerResourceOperation,
        public resourceReference: EntitySearchableEventReference,
        public resourceTypeName: string,
        public riskPolicyDatas: KubernetesClusterAdmissionControllerEventRiskPolicyData[],
        public riskPolicyResult: KubernetesAdmissionControllerRiskPolicyResult) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterAdmissionControllerEvent",
            tenantId,
            clusterReference,
            count,
            endTime,
            startTime,
            systemKubernetesClusterId,
            time);
    }
}

export class KubernetesClusterAdmissionControllerEventFilters extends KubernetesClusterEventFilters {
    constructor(
        tenantIdItems: FilterItems<string>,
        timeRange: FilterTimeRange,
        public resourceTypeNameItems: FilterItems<string>,
        public riskPolicyConfigurationTypeNames: FilterItems<string>,
        public violationRiskPolicyConfigurationTypeNames: FilterItems<string>) {
        super(
            tenantIdItems,
            timeRange);
    }
}

export class KubernetesClusterAdmissionControllerEventModel extends KubernetesClusterEventModel implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        clusterReference: string,
        event: KubernetesClusterEvent,
        public identityReference: Optional<string>,
        public ownerResourceReference: Optional<string>,
        public resourceReference: string,
        public violationRiskPolicyDatas: KubernetesClusterAdmissionControllerEventRiskPolicyData[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterAdmissionControllerEventModel",
            tenantId,
            clusterReference,
            event);
    }
}

export class KubernetesClusterApiServerAlwaysAdmitAdmissionControllerPluginEnabledRisk extends EntityRisk implements ITypeNameObject, IKubernetesRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterApiServerAlwaysAdmitAdmissionControllerPluginEnabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class KubernetesClusterApiServerAlwaysAdmitAdmissionControllerPluginEnabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: KubernetesClusterApiServerAlwaysAdmitAdmissionControllerPluginEnabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterApiServerAlwaysAdmitAdmissionControllerPluginEnabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class KubernetesClusterApiServerAuthorizationModeAlwaysAllowRisk extends EntityRisk implements ITypeNameObject, IKubernetesRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterApiServerAuthorizationModeAlwaysAllowRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class KubernetesClusterApiServerAuthorizationModeAlwaysAllowRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: KubernetesClusterApiServerAuthorizationModeAlwaysAllowRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterApiServerAuthorizationModeAlwaysAllowRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class KubernetesClusterApiServerBasicAuthenticationEnabledRisk extends EntityRisk implements ITypeNameObject, IKubernetesRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterApiServerBasicAuthenticationEnabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class KubernetesClusterApiServerBasicAuthenticationEnabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: KubernetesClusterApiServerBasicAuthenticationEnabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterApiServerBasicAuthenticationEnabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class KubernetesClusterApiServerClientCertificateAuthenticationDisabledRisk extends EntityRisk implements ITypeNameObject, IKubernetesRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterApiServerClientCertificateAuthenticationDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class KubernetesClusterApiServerClientCertificateAuthenticationDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: KubernetesClusterApiServerClientCertificateAuthenticationDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterApiServerClientCertificateAuthenticationDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class KubernetesClusterApiServerEtcdCertificateAuthorityCertificateNotExistRisk extends EntityRisk implements ITypeNameObject, IKubernetesRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterApiServerEtcdCertificateAuthorityCertificateNotExistRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class KubernetesClusterApiServerEtcdCertificateAuthorityCertificateNotExistRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: KubernetesClusterApiServerEtcdCertificateAuthorityCertificateNotExistRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterApiServerEtcdCertificateAuthorityCertificateNotExistRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class KubernetesClusterApiServerEtcdClientCertificateAuthenticationDisabledRisk extends EntityRisk implements ITypeNameObject, IKubernetesRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterApiServerEtcdClientCertificateAuthenticationDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class KubernetesClusterApiServerEtcdClientCertificateAuthenticationDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: KubernetesClusterApiServerEtcdClientCertificateAuthenticationDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public etcdSecuringCommunicationDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterApiServerEtcdClientCertificateAuthenticationDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class KubernetesClusterApiServerInsecureListenerEnabledRisk extends EntityRisk implements ITypeNameObject, IKubernetesRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterApiServerInsecureListenerEnabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class KubernetesClusterApiServerInsecureListenerEnabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: KubernetesClusterApiServerInsecureListenerEnabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterApiServerInsecureListenerEnabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class KubernetesClusterApiServerInsecureTlsProtocolRisk extends EntityRisk implements ITypeNameObject, IKubernetesRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterApiServerInsecureTlsProtocolRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class KubernetesClusterApiServerInsecureTlsProtocolRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: KubernetesClusterApiServerInsecureTlsProtocolRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterApiServerInsecureTlsProtocolRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class KubernetesClusterApiServerKubeletCertificateValidationDisabledRisk extends EntityRisk implements ITypeNameObject, IKubernetesRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterApiServerKubeletCertificateValidationDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class KubernetesClusterApiServerKubeletCertificateValidationDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: KubernetesClusterApiServerKubeletCertificateValidationDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterApiServerKubeletCertificateValidationDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class KubernetesClusterApiServerKubeletClientCertificateAuthenticationDisabledRisk extends EntityRisk implements ITypeNameObject, IKubernetesRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterApiServerKubeletClientCertificateAuthenticationDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class KubernetesClusterApiServerKubeletClientCertificateAuthenticationDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: KubernetesClusterApiServerKubeletClientCertificateAuthenticationDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public kubeletAuthenticationDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterApiServerKubeletClientCertificateAuthenticationDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class KubernetesClusterApiServerNamespaceLifecycleAdmissionControllerPluginDisabledRisk extends EntityRisk implements ITypeNameObject, IKubernetesRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterApiServerNamespaceLifecycleAdmissionControllerPluginDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class KubernetesClusterApiServerNamespaceLifecycleAdmissionControllerPluginDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: KubernetesClusterApiServerNamespaceLifecycleAdmissionControllerPluginDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterApiServerNamespaceLifecycleAdmissionControllerPluginDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class KubernetesClusterApiServerNodeRestrictionAdmissionControllerPluginDisabledRisk extends EntityRisk implements ITypeNameObject, IKubernetesRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterApiServerNodeRestrictionAdmissionControllerPluginDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class KubernetesClusterApiServerNodeRestrictionAdmissionControllerPluginDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: KubernetesClusterApiServerNodeRestrictionAdmissionControllerPluginDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterApiServerNodeRestrictionAdmissionControllerPluginDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class KubernetesClusterApiServerRoleBasedAccessControlAuthorizationModeDisabledRisk extends EntityRisk implements ITypeNameObject, IKubernetesRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterApiServerRoleBasedAccessControlAuthorizationModeDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class KubernetesClusterApiServerRoleBasedAccessControlAuthorizationModeDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: KubernetesClusterApiServerRoleBasedAccessControlAuthorizationModeDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterApiServerRoleBasedAccessControlAuthorizationModeDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class KubernetesClusterApiServerSecurityContextConstraintAdmissionControllerPluginDisabledRisk extends EntityRisk implements ITypeNameObject, IKubernetesRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterApiServerSecurityContextConstraintAdmissionControllerPluginDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class KubernetesClusterApiServerSecurityContextConstraintAdmissionControllerPluginDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: KubernetesClusterApiServerSecurityContextConstraintAdmissionControllerPluginDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterApiServerSecurityContextConstraintAdmissionControllerPluginDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class KubernetesClusterApiServerServiceAccountAdmissionControllerPluginDisabledRisk extends EntityRisk implements ITypeNameObject, IKubernetesRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterApiServerServiceAccountAdmissionControllerPluginDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class KubernetesClusterApiServerServiceAccountAdmissionControllerPluginDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: KubernetesClusterApiServerServiceAccountAdmissionControllerPluginDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterApiServerServiceAccountAdmissionControllerPluginDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class KubernetesClusterApiServerServiceAccountLookupDisabledRisk extends EntityRisk implements ITypeNameObject, IKubernetesRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterApiServerServiceAccountLookupDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class KubernetesClusterApiServerServiceAccountLookupDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: KubernetesClusterApiServerServiceAccountLookupDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterApiServerServiceAccountLookupDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class KubernetesClusterApiServerServiceAccountTokenKeyNotExistRisk extends EntityRisk implements ITypeNameObject, IKubernetesRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterApiServerServiceAccountTokenKeyNotExistRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class KubernetesClusterApiServerServiceAccountTokenKeyNotExistRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: KubernetesClusterApiServerServiceAccountTokenKeyNotExistRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterApiServerServiceAccountTokenKeyNotExistRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class KubernetesClusterApiServerStaticTokenAuthenticationEnabledRisk extends EntityRisk implements ITypeNameObject, IKubernetesRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterApiServerStaticTokenAuthenticationEnabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class KubernetesClusterApiServerStaticTokenAuthenticationEnabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: KubernetesClusterApiServerStaticTokenAuthenticationEnabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterApiServerStaticTokenAuthenticationEnabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class KubernetesClusterApiServerTlsCertificateNotExistRisk extends EntityRisk implements ITypeNameObject, IKubernetesRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterApiServerTlsCertificateNotExistRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class KubernetesClusterApiServerTlsCertificateNotExistRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: KubernetesClusterApiServerTlsCertificateNotExistRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterApiServerTlsCertificateNotExistRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class KubernetesClusterAuthenticationPrincipalRoleBindingExistsRisk extends EntityRisk implements ITypeNameObject, IKubernetesRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public defaultClusterRoleBindingIds: string[],
        public defaultNamespaceRoleBindingIds: string[],
        public systemAnonymousUserRoleBindingIds: string[],
        public systemAuthenticatedGroupRoleBindingIds: string[],
        public systemUnauthenticatedGroupRoleBindingIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterAuthenticationPrincipalRoleBindingExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class KubernetesClusterAuthenticationPrincipalRoleBindingExistsRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: KubernetesClusterAuthenticationPrincipalRoleBindingExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public systemAuthenticatedGroupNonDefaultRoleBindingIds: string[],
        public systemUnauthenticatedGroupNonDefaultRoleBindingIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterAuthenticationPrincipalRoleBindingExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class KubernetesClusterClusterAdminRoleBindingExistsRisk extends EntityRisk implements ITypeNameObject, IKubernetesRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public clusterRoleBindingIds: string[],
        public namespaceRoleBindingIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterClusterAdminRoleBindingExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class KubernetesClusterClusterAdminRoleBindingExistsRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: KubernetesClusterClusterAdminRoleBindingExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterClusterAdminRoleBindingExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class KubernetesClusterConnectorConfiguration extends IntegrationEntityConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        type: IntegrationType,
        public apiKeyId: string,
        public name: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterConnectorConfiguration",
            scopeId,
            type);
    }
}

export class KubernetesClusterControllerManagerInsecureListenerIpAddressRisk extends EntityRisk implements ITypeNameObject, IKubernetesRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public clusterPlatform: KubernetesClusterPlatform) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterControllerManagerInsecureListenerIpAddressRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class KubernetesClusterControllerManagerInsecureListenerIpAddressRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: KubernetesClusterControllerManagerInsecureListenerIpAddressRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterControllerManagerInsecureListenerIpAddressRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class KubernetesClusterControllerManagerServiceAccountCredentialsDisabledRisk extends EntityRisk implements ITypeNameObject, IKubernetesRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterControllerManagerServiceAccountCredentialsDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class KubernetesClusterControllerManagerServiceAccountCredentialsDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: KubernetesClusterControllerManagerServiceAccountCredentialsDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterControllerManagerServiceAccountCredentialsDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class KubernetesClusterControllerManagerServiceAccountTokenKeyNotExistRisk extends EntityRisk implements ITypeNameObject, IKubernetesRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterControllerManagerServiceAccountTokenKeyNotExistRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class KubernetesClusterControllerManagerServiceAccountTokenKeyNotExistRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: KubernetesClusterControllerManagerServiceAccountTokenKeyNotExistRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterControllerManagerServiceAccountTokenKeyNotExistRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class KubernetesClusterControllerManagerServiceAccountTokenSecretCertificateAuthorityCertificateNotExistRisk extends EntityRisk implements ITypeNameObject, IKubernetesRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterControllerManagerServiceAccountTokenSecretCertificateAuthorityCertificateNotExistRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class KubernetesClusterControllerManagerServiceAccountTokenSecretCertificateAuthorityCertificateNotExistRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: KubernetesClusterControllerManagerServiceAccountTokenSecretCertificateAuthorityCertificateNotExistRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterControllerManagerServiceAccountTokenSecretCertificateAuthorityCertificateNotExistRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class KubernetesClusterDefaultNamespaceUsedRisk extends EntityRisk implements ITypeNameObject, IKubernetesRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public defaultNamespaceResourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterDefaultNamespaceUsedRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class KubernetesClusterDefaultNamespaceUsedRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: KubernetesClusterDefaultNamespaceUsedRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public createKubernetesNamespacesDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterDefaultNamespaceUsedRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class KubernetesClusterEndOfLifeRisk extends EntityRisk implements ITypeNameObject, IKubernetesRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterEndOfLifeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class KubernetesClusterEndOfLifeRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: KubernetesClusterEndOfLifeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public updateVersionDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterEndOfLifeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class KubernetesClusterEtcdClientCertificateAuthenticationDisabledRisk extends EntityRisk implements ITypeNameObject, IKubernetesRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterEtcdClientCertificateAuthenticationDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class KubernetesClusterEtcdClientCertificateAuthenticationDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: KubernetesClusterEtcdClientCertificateAuthenticationDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterEtcdClientCertificateAuthenticationDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class KubernetesClusterEtcdPeerClientCertificateAuthenticationDisabledRisk extends EntityRisk implements ITypeNameObject, IKubernetesRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterEtcdPeerClientCertificateAuthenticationDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class KubernetesClusterEtcdPeerClientCertificateAuthenticationDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: KubernetesClusterEtcdPeerClientCertificateAuthenticationDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterEtcdPeerClientCertificateAuthenticationDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class KubernetesClusterEtcdPeerSelfSignedTlsCertificateGenerationEnabledRisk extends EntityRisk implements ITypeNameObject, IKubernetesRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterEtcdPeerSelfSignedTlsCertificateGenerationEnabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class KubernetesClusterEtcdPeerSelfSignedTlsCertificateGenerationEnabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: KubernetesClusterEtcdPeerSelfSignedTlsCertificateGenerationEnabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterEtcdPeerSelfSignedTlsCertificateGenerationEnabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class KubernetesClusterEtcdPeerTlsCertificateNotExistRisk extends EntityRisk implements ITypeNameObject, IKubernetesRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterEtcdPeerTlsCertificateNotExistRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class KubernetesClusterEtcdPeerTlsCertificateNotExistRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: KubernetesClusterEtcdPeerTlsCertificateNotExistRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterEtcdPeerTlsCertificateNotExistRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class KubernetesClusterEtcdSelfSignedTlsCertificateGenerationEnabledRisk extends EntityRisk implements ITypeNameObject, IKubernetesRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterEtcdSelfSignedTlsCertificateGenerationEnabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class KubernetesClusterEtcdSelfSignedTlsCertificateGenerationEnabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: KubernetesClusterEtcdSelfSignedTlsCertificateGenerationEnabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterEtcdSelfSignedTlsCertificateGenerationEnabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class KubernetesClusterEtcdTlsCertificateNotExistRisk extends EntityRisk implements ITypeNameObject, IKubernetesRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterEtcdTlsCertificateNotExistRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class KubernetesClusterEtcdTlsCertificateNotExistRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: KubernetesClusterEtcdTlsCertificateNotExistRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterEtcdTlsCertificateNotExistRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class KubernetesClusterIdentityProviderAuthenticationDisabledRisk extends EntityRisk implements ITypeNameObject, IKubernetesRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterIdentityProviderAuthenticationDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class KubernetesClusterIdentityProviderAuthenticationDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: KubernetesClusterIdentityProviderAuthenticationDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public openShiftIdentityProviderConfigurationDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterIdentityProviderAuthenticationDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class KubernetesClusterKubeletAnonymousAuthenticationEnabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: KubernetesClusterKubeletAnonymousAuthenticationEnabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterKubeletAnonymousAuthenticationEnabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class KubernetesClusterKubeletAuthorizationModeAlwaysAllowRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: KubernetesClusterKubeletAuthorizationModeAlwaysAllowRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterKubeletAuthorizationModeAlwaysAllowRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class KubernetesClusterKubeletClientCertificateAuthenticationDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: KubernetesClusterKubeletClientCertificateAuthenticationDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterKubeletClientCertificateAuthenticationDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class KubernetesClusterKubeletClientCertificateRotationDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: KubernetesClusterKubeletClientCertificateRotationDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterKubeletClientCertificateRotationDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class KubernetesClusterKubeletHostnameOverrideUsedRisk extends EntityRisk implements ITypeNameObject, IKubernetesRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public virtualMachineIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterKubeletHostnameOverrideUsedRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class KubernetesClusterKubeletHostnameOverrideUsedRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: KubernetesClusterKubeletHostnameOverrideUsedRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterKubeletHostnameOverrideUsedRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class KubernetesClusterKubeletInsecureTlsProtocolRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: KubernetesClusterKubeletInsecureTlsProtocolRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterKubeletInsecureTlsProtocolRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class KubernetesClusterKubeletInsufficientEventsPerSecondMaxCountRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: KubernetesClusterKubeletInsufficientEventsPerSecondMaxCountRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterKubeletInsufficientEventsPerSecondMaxCountRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class KubernetesClusterKubeletManageIpTablesDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: KubernetesClusterKubeletManageIpTablesDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterKubeletManageIpTablesDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class KubernetesClusterKubeletReadOnlyPortEnabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: KubernetesClusterKubeletReadOnlyPortEnabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterKubeletReadOnlyPortEnabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class KubernetesClusterKubeletRisk extends EntityRisk implements ITypeNameObject, IKubernetesRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public nodeDatas: KubernetesClusterKubeletRiskNodeData[],
        public nodeOrVirtualMachineIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class KubernetesClusterKubeletServerCertificateRotationDisabledRisk extends KubernetesClusterKubeletRisk implements ITypeNameObject, IKubernetesRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        nodeDatas: KubernetesClusterKubeletRiskNodeData[],
        nodeOrVirtualMachineIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterKubeletServerCertificateRotationDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            nodeDatas,
            nodeOrVirtualMachineIds);
    }
}

export class KubernetesClusterKubeletServerCertificateRotationDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: KubernetesClusterKubeletServerCertificateRotationDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterKubeletServerCertificateRotationDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class KubernetesClusterKubeletStreamingConnectionIdleTimeoutDisabledRisk extends KubernetesClusterKubeletRisk implements ITypeNameObject, IKubernetesRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        nodeDatas: KubernetesClusterKubeletRiskNodeData[],
        nodeOrVirtualMachineIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterKubeletStreamingConnectionIdleTimeoutDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            nodeDatas,
            nodeOrVirtualMachineIds);
    }
}

export class KubernetesClusterKubeletStreamingConnectionIdleTimeoutDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: KubernetesClusterKubeletStreamingConnectionIdleTimeoutDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterKubeletStreamingConnectionIdleTimeoutDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class KubernetesClusterKubeletTlsCertificateNotExistRisk extends KubernetesClusterKubeletRisk implements ITypeNameObject, IKubernetesRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        nodeDatas: KubernetesClusterKubeletRiskNodeData[],
        nodeOrVirtualMachineIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterKubeletTlsCertificateNotExistRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            nodeDatas,
            nodeOrVirtualMachineIds);
    }
}

export class KubernetesClusterKubeletTlsCertificateNotExistRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: KubernetesClusterKubeletTlsCertificateNotExistRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterKubeletTlsCertificateNotExistRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class KubernetesClusterNetworkPolicyUnsupportedRisk extends EntityRisk implements ITypeNameObject, IKubernetesRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterNetworkPolicyUnsupportedRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class KubernetesClusterNetworkPolicyUnsupportedRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: KubernetesClusterNetworkPolicyUnsupportedRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterNetworkPolicyUnsupportedRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class KubernetesClusterNodeUnrestrictedAccessFileRisk extends EntityRisk implements ITypeNameObject, IKubernetesRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterNodeUnrestrictedAccessFileRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class KubernetesClusterNodeUnrestrictedAccessFileRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: KubernetesClusterNodeUnrestrictedAccessFileRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public unrestrictedAccessItems: KubernetesClusterNodeUnrestrictedAccessFileRiskModelUnrestrictedAccessItem[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterNodeUnrestrictedAccessFileRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class KubernetesClusterPlatformEndOfLifeRisk extends EntityRisk implements ITypeNameObject, IKubernetesRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public clusterPlatform: KubernetesClusterPlatform) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterPlatformEndOfLifeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class KubernetesClusterPlatformEndOfLifeRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: KubernetesClusterPlatformEndOfLifeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public updateVersionDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterPlatformEndOfLifeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class KubernetesClusterResourceEncryptionDisabledRisk extends EntityRisk implements ITypeNameObject, IKubernetesRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterResourceEncryptionDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class KubernetesClusterResourceEncryptionDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: KubernetesClusterResourceEncryptionDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public openShiftEtcdDataEncryptionDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterResourceEncryptionDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class KubernetesClusterRoleBindingData extends KubernetesResourceData implements ITypeNameObject, IKubernetesRoleBindingData {
    constructor(
        annotationKeyToValueMap: Dictionary<string>,
        controllerResourceReference: Optional<KubernetesResourceReference>,
        name: string,
        path: string,
        rawId: string,
        rawYaml: Optional<string>,
        public principalReferences: KubernetesResourceReference[],
        public roleReference: KubernetesResourceReference) {
        super(
            "KubernetesClusterRoleBindingData",
            annotationKeyToValueMap,
            controllerResourceReference,
            name,
            path,
            rawId,
            rawYaml);
    }
}

export class KubernetesClusterRoleData extends KubernetesResourceData implements ITypeNameObject, IKubernetesResourceData {
    constructor(
        annotationKeyToValueMap: Dictionary<string>,
        controllerResourceReference: Optional<KubernetesResourceReference>,
        name: string,
        path: string,
        rawId: string,
        rawYaml: Optional<string>) {
        super(
            "KubernetesClusterRoleData",
            annotationKeyToValueMap,
            controllerResourceReference,
            name,
            path,
            rawId,
            rawYaml);
    }
}

export class KubernetesClusterSchedulerRoleBasedAccessAuthorizationDisabledRisk extends EntityRisk implements ITypeNameObject, IKubernetesRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterSchedulerRoleBasedAccessAuthorizationDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class KubernetesClusterSchedulerRoleBasedAccessAuthorizationDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: KubernetesClusterSchedulerRoleBasedAccessAuthorizationDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterSchedulerRoleBasedAccessAuthorizationDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class KubernetesEnvironmentVariableContainerPropertyReference extends KubernetesEnvironmentVariableValueReference implements ITypeNameObject {
    constructor(
        key: string,
        public containerName: string) {
        super(
            "KubernetesEnvironmentVariableContainerPropertyReference",
            key);
    }
}

export class KubernetesEnvironmentVariableResourceReference extends KubernetesEnvironmentVariableValueReference implements ITypeNameObject {
    constructor(
        key: string,
        public resourceReference: KubernetesResourceReference) {
        super(
            "KubernetesEnvironmentVariableResourceReference",
            key);
    }
}

export class KubernetesGroupData extends KubernetesResourceData implements ITypeNameObject, IKubernetesPrincipalData {
    constructor(
        annotationKeyToValueMap: Dictionary<string>,
        controllerResourceReference: Optional<KubernetesResourceReference>,
        name: string,
        path: string,
        rawId: string,
        rawYaml: Optional<string>) {
        super(
            "KubernetesGroupData",
            annotationKeyToValueMap,
            controllerResourceReference,
            name,
            path,
            rawId,
            rawYaml);
    }
}

export class KubernetesIngressClassData extends KubernetesResourceData implements ITypeNameObject, IKubernetesResourceData {
    constructor(
        annotationKeyToValueMap: Dictionary<string>,
        controllerResourceReference: Optional<KubernetesResourceReference>,
        name: string,
        path: string,
        rawId: string,
        rawYaml: Optional<string>) {
        super(
            "KubernetesIngressClassData",
            annotationKeyToValueMap,
            controllerResourceReference,
            name,
            path,
            rawId,
            rawYaml);
    }
}

export class KubernetesNamespaceData extends KubernetesResourceData implements ITypeNameObject, IKubernetesResourceData {
    constructor(
        annotationKeyToValueMap: Dictionary<string>,
        controllerResourceReference: Optional<KubernetesResourceReference>,
        name: string,
        path: string,
        rawId: string,
        rawYaml: Optional<string>) {
        super(
            "KubernetesNamespaceData",
            annotationKeyToValueMap,
            controllerResourceReference,
            name,
            path,
            rawId,
            rawYaml);
    }
}

export class KubernetesNamespaceDefaultServiceAccountRoleBindingExistsRisk extends EntityRisk implements ITypeNameObject, IKubernetesRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public clusterRoleBindingIds: string[],
        public namespaceRoleBindingIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesNamespaceDefaultServiceAccountRoleBindingExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class KubernetesNamespaceDefaultServiceAccountRoleBindingExistsRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: KubernetesNamespaceDefaultServiceAccountRoleBindingExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesNamespaceDefaultServiceAccountRoleBindingExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class KubernetesNamespaceResourceData extends KubernetesResourceData implements ITypeNameObject, IKubernetesResourceData {
    constructor(
        typeName: string,
        annotationKeyToValueMap: Dictionary<string>,
        controllerResourceReference: Optional<KubernetesResourceReference>,
        name: string,
        path: string,
        rawId: string,
        rawYaml: Optional<string>,
        public namespaceId: string,
        public namespaceName: string) {
        super(
            typeName,
            annotationKeyToValueMap,
            controllerResourceReference,
            name,
            path,
            rawId,
            rawYaml);
    }
}

export class KubernetesNamespaceRestrictedPodSecurityStandardNotExistRisk extends EntityRisk implements ITypeNameObject, IKubernetesRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public podSecurityStandard: KubernetesNamespaceDataPodSecurityStandard) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesNamespaceRestrictedPodSecurityStandardNotExistRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class KubernetesNamespaceRestrictedPodSecurityStandardNotExistRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: KubernetesNamespaceRestrictedPodSecurityStandardNotExistRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesNamespaceRestrictedPodSecurityStandardNotExistRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class KubernetesNamespaceRoleBindingData extends KubernetesNamespaceResourceData implements ITypeNameObject, IKubernetesRoleBindingData {
    constructor(
        annotationKeyToValueMap: Dictionary<string>,
        controllerResourceReference: Optional<KubernetesResourceReference>,
        name: string,
        path: string,
        rawId: string,
        rawYaml: Optional<string>,
        namespaceId: string,
        namespaceName: string,
        public principalReferences: KubernetesResourceReference[],
        public roleReference: KubernetesResourceReference) {
        super(
            "KubernetesNamespaceRoleBindingData",
            annotationKeyToValueMap,
            controllerResourceReference,
            name,
            path,
            rawId,
            rawYaml,
            namespaceId,
            namespaceName);
    }
}

export class KubernetesNamespaceRoleData extends KubernetesNamespaceResourceData implements ITypeNameObject, IKubernetesResourceData {
    constructor(
        annotationKeyToValueMap: Dictionary<string>,
        controllerResourceReference: Optional<KubernetesResourceReference>,
        name: string,
        path: string,
        rawId: string,
        rawYaml: Optional<string>,
        namespaceId: string,
        namespaceName: string) {
        super(
            "KubernetesNamespaceRoleData",
            annotationKeyToValueMap,
            controllerResourceReference,
            name,
            path,
            rawId,
            rawYaml,
            namespaceId,
            namespaceName);
    }
}

export class KubernetesNamespaceWorkloadResourceRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public podSecurityStandard: KubernetesNamespaceDataPodSecurityStandard,
        public workloadResourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class KubernetesNamespaceWorkloadResourceRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: KubernetesNamespaceWorkloadResourceRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class KubernetesNetworkPolicyData extends KubernetesNamespaceResourceData implements ITypeNameObject, IKubernetesResourceData {
    constructor(
        annotationKeyToValueMap: Dictionary<string>,
        controllerResourceReference: Optional<KubernetesResourceReference>,
        name: string,
        path: string,
        rawId: string,
        rawYaml: Optional<string>,
        namespaceId: string,
        namespaceName: string) {
        super(
            "KubernetesNetworkPolicyData",
            annotationKeyToValueMap,
            controllerResourceReference,
            name,
            path,
            rawId,
            rawYaml,
            namespaceId,
            namespaceName);
    }
}

export class KubernetesNodeData extends KubernetesResourceData implements ITypeNameObject, IKubernetesResourceData {
    constructor(
        annotationKeyToValueMap: Dictionary<string>,
        controllerResourceReference: Optional<KubernetesResourceReference>,
        name: string,
        path: string,
        rawId: string,
        rawYaml: Optional<string>,
        public kubelet: Optional<KubernetesNodeKubelet>,
        public taints: KubernetesTaint[]) {
        super(
            "KubernetesNodeData",
            annotationKeyToValueMap,
            controllerResourceReference,
            name,
            path,
            rawId,
            rawYaml);
    }
}

export class KubernetesPersistentVolumeData extends KubernetesResourceData implements ITypeNameObject, IKubernetesResourceData {
    constructor(
        annotationKeyToValueMap: Dictionary<string>,
        controllerResourceReference: Optional<KubernetesResourceReference>,
        name: string,
        path: string,
        rawId: string,
        rawYaml: Optional<string>) {
        super(
            "KubernetesPersistentVolumeData",
            annotationKeyToValueMap,
            controllerResourceReference,
            name,
            path,
            rawId,
            rawYaml);
    }
}

export class KubernetesPodData extends KubernetesNamespaceResourceData implements ITypeNameObject, IKubernetesResourceData {
    constructor(
        annotationKeyToValueMap: Dictionary<string>,
        controllerResourceReference: Optional<KubernetesResourceReference>,
        name: string,
        path: string,
        rawId: string,
        rawYaml: Optional<string>,
        namespaceId: string,
        namespaceName: string,
        public podSpec: KubernetesPodSpec) {
        super(
            "KubernetesPodData",
            annotationKeyToValueMap,
            controllerResourceReference,
            name,
            path,
            rawId,
            rawYaml,
            namespaceId,
            namespaceName);
    }
}

export class KubernetesPodSecurityPolicyData extends KubernetesResourceData implements ITypeNameObject, IKubernetesResourceData {
    constructor(
        annotationKeyToValueMap: Dictionary<string>,
        controllerResourceReference: Optional<KubernetesResourceReference>,
        name: string,
        path: string,
        rawId: string,
        rawYaml: Optional<string>) {
        super(
            "KubernetesPodSecurityPolicyData",
            annotationKeyToValueMap,
            controllerResourceReference,
            name,
            path,
            rawId,
            rawYaml);
    }
}

export class KubernetesPodTemplateData extends KubernetesNamespaceResourceData implements ITypeNameObject, IKubernetesResourceData {
    constructor(
        annotationKeyToValueMap: Dictionary<string>,
        controllerResourceReference: Optional<KubernetesResourceReference>,
        name: string,
        path: string,
        rawId: string,
        rawYaml: Optional<string>,
        namespaceId: string,
        namespaceName: string) {
        super(
            "KubernetesPodTemplateData",
            annotationKeyToValueMap,
            controllerResourceReference,
            name,
            path,
            rawId,
            rawYaml,
            namespaceId,
            namespaceName);
    }
}

export class KubernetesServiceAccountData extends KubernetesNamespaceResourceData implements ITypeNameObject, IKubernetesPrincipalData {
    constructor(
        annotationKeyToValueMap: Dictionary<string>,
        controllerResourceReference: Optional<KubernetesResourceReference>,
        name: string,
        path: string,
        rawId: string,
        rawYaml: Optional<string>,
        namespaceId: string,
        namespaceName: string,
        public containerImageRepositoryPullSecretNames: string[],
        public mountableSecretNames: string[]) {
        super(
            "KubernetesServiceAccountData",
            annotationKeyToValueMap,
            controllerResourceReference,
            name,
            path,
            rawId,
            rawYaml,
            namespaceId,
            namespaceName);
    }
}

export class KubernetesServiceData extends KubernetesNamespaceResourceData implements ITypeNameObject, IKubernetesResourceData {
    constructor(
        annotationKeyToValueMap: Dictionary<string>,
        controllerResourceReference: Optional<KubernetesResourceReference>,
        name: string,
        path: string,
        rawId: string,
        rawYaml: Optional<string>,
        namespaceId: string,
        namespaceName: string,
        public podLabelSelector: KubernetesLabelSelector,
        public ports: KubernetesServiceDataPort[],
        public type: KubernetesServiceDataType) {
        super(
            "KubernetesServiceData",
            annotationKeyToValueMap,
            controllerResourceReference,
            name,
            path,
            rawId,
            rawYaml,
            namespaceId,
            namespaceName);
    }
}

export class KubernetesWorkloadResourceContainerPrivilegedCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesWorkloadResourceContainerPrivilegedCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class KubernetesWorkloadResourceContainerPrivilegedCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: KubernetesWorkloadResourceContainerPrivilegedCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesWorkloadResourceContainerPrivilegedCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class KubernetesWorkloadResourceData extends KubernetesNamespaceResourceData implements ITypeNameObject, IKubernetesResourceData {
    constructor(
        typeName: string,
        annotationKeyToValueMap: Dictionary<string>,
        controllerResourceReference: Optional<KubernetesResourceReference>,
        name: string,
        path: string,
        rawId: string,
        rawYaml: Optional<string>,
        namespaceId: string,
        namespaceName: string,
        public podTemplateSpec: KubernetesPodTemplateSpec) {
        super(
            typeName,
            annotationKeyToValueMap,
            controllerResourceReference,
            name,
            path,
            rawId,
            rawYaml,
            namespaceId,
            namespaceName);
    }
}

export class KubernetesWorkloadResourceEnvironmentVariableSecretReferenceRisk extends EntityRisk implements ITypeNameObject, IKubernetesRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public containerNames: string[],
        public yaml: TextBlock) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesWorkloadResourceEnvironmentVariableSecretReferenceRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class KubernetesWorkloadResourceEnvironmentVariableSecretReferenceRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: KubernetesWorkloadResourceEnvironmentVariableSecretReferenceRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesWorkloadResourceEnvironmentVariableSecretReferenceRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class MailAddressSearchableData extends SearchableData<string> {
    constructor() {
        super();
    }
}

export class MaliciousFilesReportDefinitionConfiguration extends WorkloadAnalysisReportDefinitionConfiguration<WorkloadResourceScanFileModel> implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        contentType: ReportContentType,
        exportReport: boolean,
        licenseType: ApplicationCustomerConfigurationLicensingLicenseType,
        name: Optional<string>,
        timeZoneInfoId: string,
        type: ReportType,
        filters: WorkloadControllerWorkloadAnalysisModelsFilters<WorkloadResourceScanFileModel>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "MaliciousFilesReportDefinitionConfiguration",
            contentType,
            exportReport,
            licenseType,
            name,
            timeZoneInfoId,
            type,
            filters);
    }
}

export class ManualCustomEntityAttribute extends CustomEntityAttribute implements ITypeNameObject {
    constructor(
        definitionId: string,
        self: boolean,
        public identityReference: Optional<IdentityReference>) {
        super(
            "ManualCustomEntityAttribute",
            definitionId,
            self);
    }
}

export class ManualCustomEntityAttributeDefinitionConfiguration extends CustomEntityAttributeDefinitionConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        colorIndex: number,
        name: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "ManualCustomEntityAttributeDefinitionConfiguration",
            scopeId,
            colorIndex,
            name);
    }
}

export class MetaEntityTypeMetadata extends MetaObjectTypeMetadata {
    constructor() {
        super();
    }
}

export class MfaDisabledUserAttribute extends BuiltInEntityAttribute implements ITypeNameObject {
    constructor() {
        super("MfaDisabledUserAttribute");
    }
}

export class NetworkInboundExternalResourceRiskPolicyGroupFilters extends RiskPolicyTypeGroupFilters {
    constructor(
        public destinationNetworkScopeFilterIdToDestinationNetworkScopeMap: Dictionary<DestinationNetworkScope>) {
        super();
    }
}

export class OciAccessPolicyGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciAccessPolicyGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciAdmKnowledgeBaseGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciAdmKnowledgeBaseGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciAdmRemediationRecipeGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciAdmRemediationRecipeGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciAdmRemediationRunGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciAdmRemediationRunGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciAdmVulnerabilityAuditGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciAdmVulnerabilityAuditGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciAiAnomalyDetectionDataAssetGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciAiAnomalyDetectionDataAssetGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciAiAnomalyDetectionModelGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciAiAnomalyDetectionModelGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciAiAnomalyDetectionProjectGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciAiAnomalyDetectionProjectGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciAiAnomalyDetectionPvtEndpointGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciAiAnomalyDetectionPvtEndpointGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciAiDocumentModelGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciAiDocumentModelGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciAiDocumentProjectGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciAiDocumentProjectGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciAiForecastDataAssetGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciAiForecastDataAssetGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciAiForecastForecastGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciAiForecastForecastGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciAiForecastProjectGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciAiForecastProjectGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciAiLanguageEndpointGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciAiLanguageEndpointGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciAiLanguageModelGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciAiLanguageModelGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciAiLanguageProjectGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciAiLanguageProjectGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciAiModelDeploymentGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciAiModelDeploymentGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciAiModelGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciAiModelGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciAiProjectGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciAiProjectGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciAiVisionModelGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciAiVisionModelGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciAiVisionProjectGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciAiVisionProjectGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciAlarmGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciAlarmGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciAnalyticsInstanceGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciAnalyticsInstanceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciApiDeploymentGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciApiDeploymentGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciApiGatewayApiGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciApiGatewayApiGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciApiGatewayCertificateGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciApiGatewayCertificateGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciApiGatewayGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciApiGatewayGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciApiGatewaySdkGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciApiGatewaySdkGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciApiGatewaySubscriberGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciApiGatewaySubscriberGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciApiGatewayUsagePlanGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciApiGatewayUsagePlanGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciApmDomainGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciApmDomainGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciAppGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciAppGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciApplicationGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciApplicationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciApplicationVipGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciApplicationVipGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciArcsSaasEnvironmentGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciArcsSaasEnvironmentGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciAtatGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciAtatGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciAutonomousContainerDatabaseGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciAutonomousContainerDatabaseGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciAutonomousDatabaseBackupGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciAutonomousDatabaseBackupGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciAutonomousDatabaseGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciAutonomousDatabaseGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciAutonomousDatabaseSoftwareImageGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciAutonomousDatabaseSoftwareImageGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciAutonomousDataSecurityInstanceGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciAutonomousDataSecurityInstanceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciAutonomousExadataInfrastructureGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciAutonomousExadataInfrastructureGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciAutonomousVirtualMachineGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciAutonomousVirtualMachineGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciAutonomousVmClusterGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciAutonomousVmClusterGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciAutoscalingAutoscalingConfiguration extends OciResource implements ITypeNameObject, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public enabled: boolean,
        public instancePoolOcid: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciAutoscalingAutoscalingConfiguration",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags);
    }
}

export class OciAutoscalingAutoscalingConfigurationModel extends OciResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        public instancePoolIdReference: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciAutoscalingAutoscalingConfigurationModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class OciAutoScalingConfigurationGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciAutoScalingConfigurationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciBackupDestinationGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciBackupDestinationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciBastionGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciBastionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciBigDataServiceApiKeyGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciBigDataServiceApiKeyGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciBigDataServiceAutoscaleGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciBigDataServiceAutoscaleGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciBigDataServiceGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciBigDataServiceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciBigDataServiceLakeConfigGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciBigDataServiceLakeConfigGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciBigDataServiceMetastoreConfigGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciBigDataServiceMetastoreConfigGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciBlockchainPlatformGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciBlockchainPlatformGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciBlockStorageBlockVolume extends OciResource implements ITypeNameObject, IResource, IOciEncryptedResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public availabilityDomain: string,
        public encryptionExists: boolean,
        public encryptionVaultKeyOcid: Optional<string>,
        public size: number,
        public sourceResource: Optional<OciBlockStorageBlockVolumeSourceResource>,
        public status: OciBlockStorageBlockVolumeStatus) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciBlockStorageBlockVolume",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags);
    }
}

export class OciBlockStorageBlockVolumeDefaultEncryptionRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciBlockStorageBlockVolumeDefaultEncryptionRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class OciBlockStorageBlockVolumeDefaultEncryptionRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: OciBlockStorageBlockVolumeDefaultEncryptionRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciBlockStorageBlockVolumeDefaultEncryptionRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class OciBlockStorageBlockVolumeModel extends OciResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IOciEncryptedResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        public encryptionVaultKeyIdReference: Optional<string>,
        public instanceIdReferences: string[],
        public sourceResourceIdReference: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciBlockStorageBlockVolumeModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class OciBlockStorageBootVolume extends OciResource implements ITypeNameObject, IResource, IOciEncryptedResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public availabilityDomain: string,
        public encryptionExists: boolean,
        public encryptionVaultKeyOcid: Optional<string>,
        public imageOcid: string,
        public size: number,
        public sourceResource: Optional<OciBlockStorageBootVolumeSourceResource>,
        public status: OciBlockStorageBootVolumeStatus) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciBlockStorageBootVolume",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags);
    }
}

export class OciBlockStorageBootVolumeDefaultEncryptionCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciBlockStorageBootVolumeDefaultEncryptionCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class OciBlockStorageBootVolumeDefaultEncryptionCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: OciBlockStorageBootVolumeDefaultEncryptionCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciBlockStorageBootVolumeDefaultEncryptionCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class OciBlockStorageBootVolumeDefaultEncryptionRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciBlockStorageBootVolumeDefaultEncryptionRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class OciBlockStorageBootVolumeDefaultEncryptionRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: OciBlockStorageBootVolumeDefaultEncryptionRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciBlockStorageBootVolumeDefaultEncryptionRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class OciBlockStorageBootVolumeModel extends OciResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IOciEncryptedResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        public encryptionVaultKeyIdReference: Optional<string>,
        public imageIdReference: string,
        public instanceIdReference: Optional<string>,
        public sourceResourceIdReference: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciBlockStorageBootVolumeModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class OciBootVolumeBackupGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciBootVolumeBackupGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciBootVolumeGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciBootVolumeGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciBootVolumeReplicaGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciBootVolumeReplicaGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciBucketGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciBucketGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciBudgetGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciBudgetGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciByoipRangeGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciByoipRangeGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciCaBundleAssociationGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciCaBundleAssociationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciCaBundleGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciCaBundleGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciCccInfrastructureGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciCccInfrastructureGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciCccUpgradeScheduleGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciCccUpgradeScheduleGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciCertificateAssociationGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciCertificateAssociationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciCertificateAuthorityAssociationGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciCertificateAuthorityAssociationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciCertificateAuthorityGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciCertificateAuthorityGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciCertificateGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciCertificateGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciCimDemoSaasEnvironmentGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciCimDemoSaasEnvironmentGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciCloudAutonomousVmClusterGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciCloudAutonomousVmClusterGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciCloudExadataInfrastructureGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciCloudExadataInfrastructureGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciCloudGuardAdhocQueryGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciCloudGuardAdhocQueryGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciCloudGuardDataSourceGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciCloudGuardDataSourceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciCloudGuardDetectorRecipeGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciCloudGuardDetectorRecipeGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciCloudGuardManagedListGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciCloudGuardManagedListGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciCloudGuardResponderRecipeGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciCloudGuardResponderRecipeGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciCloudGuardSavedQueryGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciCloudGuardSavedQueryGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciCloudGuardTargetGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciCloudGuardTargetGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciCloudVmClusterGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciCloudVmClusterGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciClusterNetworkGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciClusterNetworkGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciClusterPlacementGroupDevGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciClusterPlacementGroupDevGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciClusterPlacementGroupGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciClusterPlacementGroupGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciClustersClusterGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciClustersClusterGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciCommerceCloudSaasEnvironmentGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciCommerceCloudSaasEnvironmentGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciCompartmentGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciCompartmentGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciComputeCapacityReservationGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciComputeCapacityReservationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciComputeImage extends OciResource implements ITypeNameObject, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public baseImageOcid: Optional<string>,
        public ociManaged: boolean,
        public operatingSystemType: string,
        public operatingSystemVersion: string,
        public size: number,
        public status: OciComputeImageStatus) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciComputeImage",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags);
    }
}

export class OciComputeImageModel extends OciResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        public baseImageIdReference: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciComputeImageModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class OciComputeInstance extends OciResource implements ITypeNameObject, IOciNetworkedResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public availabilityDomain: string,
        public blockVolumeOcids: string[],
        public bootSource: Optional<OciComputeInstanceBootSource>,
        public bootVolumeOcid: Optional<string>,
        public faultDomain: Optional<string>,
        public instanceConfigurationOcid: Optional<string>,
        public launchMode: OciComputeInstanceLaunchMode,
        public metadataServiceVersion1Enabled: boolean,
        public security: OciComputeInstanceSecurity,
        public shape: string,
        public status: OciComputeInstanceStatus,
        public virtualNetworkInterfaceCardsOcids: string[],
        public volumeEncryptionInTransitEnabled: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciComputeInstance",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags);
    }
}

export class OciComputeInstanceConfiguration extends OciResource implements ITypeNameObject, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public availabilityDomain: string,
        public bootSource: OciComputeInstanceConfigurationBootSource,
        public faultDomain: string,
        public instanceTags: ResourceTag[],
        public instanceTenantOcid: string,
        public launchMode: Optional<OciComputeInstanceConfigurationLaunchMode>,
        public metadataServiceVersion1Enabled: boolean,
        public primaryVirtualNetworkInterfaceCardParameters: OciComputeInstanceConfigurationVirtualNetworkInterfaceCardParameters,
        public secondaryVirtualNetworkInterfaceCards: OciComputeInstanceConfigurationVirtualNetworkInterfaceCardParameters[],
        public security: OciComputeInstanceConfigurationSecurity,
        public shape: string,
        public volumeEncryptionInTransitEnabled: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciComputeInstanceConfiguration",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags);
    }
}

export class OciComputeInstanceConfigurationModel extends OciResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        public bootSourceResourceIdReference: string,
        public instanceIds: string[],
        public instancePoolIds: string[],
        public instanceTenantIdReference: string,
        public primaryPrivateIpAddress: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciComputeInstanceConfigurationModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class OciComputeInstanceModel extends OciResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        public blockVolumeIdReferences: string[],
        public bootSourceResourceIdReference: Optional<string>,
        public bootVolumeIdReference: Optional<string>,
        public instanceConfigurationIdReference: Optional<string>,
        public instanceConfigurationSearchableIdReference: Optional<EntitySearchableIdReference>,
        public instancePoolId: Optional<string>,
        public primaryPrivateIpAddress: Optional<string>,
        public primaryPublicIpAddress: Optional<string>,
        public virtualNetworkInterfaceCardIdReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciComputeInstanceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class OciComputeInstancePool extends OciResource implements ITypeNameObject, IOciNetworkedResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public instanceConfigurationOcid: string,
        public instanceCount: number,
        public instanceOcids: string[],
        public loadBalancerOcids: string[],
        public placementConfigurations: OciComputeInstancePoolPlacementConfiguration[],
        public status: OciComputeInstancePoolStatus) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciComputeInstancePool",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags);
    }
}

export class OciComputeInstancePoolModel extends OciResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        public autoscalingConfigurationId: Optional<string>,
        public instanceConfigurationIdReference: string,
        public instanceIdReferences: string[],
        public loadBalancerIdReferences: string[],
        public subnetIdReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciComputeInstancePoolModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class OciConnectHarnesGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciConnectHarnesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciConsoleConnectionGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciConsoleConnectionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciConsoleDashboardGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciConsoleDashboardGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciConsoleDashboardGroupGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciConsoleDashboardGroupGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciConsoleHistoryGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciConsoleHistoryGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciConsoleResourceCollectionGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciConsoleResourceCollectionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciContainerEngineCluster extends OciResource implements ITypeNameObject, IOciNetworkedResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public oidcIdentityProviderIssuerUrl: Optional<string>,
        public publicEndpoint: Optional<string>,
        public status: OciContainerEngineClusterStatus,
        public type: OciContainerEngineClusterType,
        public version: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciContainerEngineCluster",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags);
    }
}

export class OciContainerEngineClusterModel extends OciResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        public nodePoolIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciContainerEngineClusterModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class OciContainerEngineClusterNodePool extends OciResource implements ITypeNameObject, IOciNetworkedResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public clusterOcid: string,
        public instanceOcids: string[],
        public node: OciContainerEngineClusterNodePoolNode,
        public nodeCount: number,
        public status: OciContainerEngineClusterNodePoolStatus) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciContainerEngineClusterNodePool",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags);
    }
}

export class OciContainerEngineClusterNodePoolModel extends OciResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        public clusterIdReference: string,
        public instanceIdReferences: string[],
        public nodeNodeImageIdReference: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciContainerEngineClusterNodePoolModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class OciContainerGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciContainerGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciContainerImageGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciContainerImageGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciContainerInstanceGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciContainerInstanceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciContainerRepoGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciContainerRepoGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciContinuousQueryGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciContinuousQueryGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciCpeGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciCpeGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciCpqSaasEnvironmentGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciCpqSaasEnvironmentGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciCrossConnectGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciCrossConnectGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciCrossConnectGroupGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciCrossConnectGroupGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciCustomerDnsZoneGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciCustomerDnsZoneGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciCxOpaSaasEnvironmentGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciCxOpaSaasEnvironmentGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciCxUnitySaasEnvironmentGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciCxUnitySaasEnvironmentGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDatabaseGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDatabaseGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDatabaseSoftwareImageGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDatabaseSoftwareImageGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDatabaseToolsConnectionGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDatabaseToolsConnectionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDatabaseToolsPrivateEndpointGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDatabaseToolsPrivateEndpointGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDataCatalogGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDataCatalogGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDataCatalogMetastoreGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDataCatalogMetastoreGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDataCatalogPrivateEndpointGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDataCatalogPrivateEndpointGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDataFlowApplicationGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDataFlowApplicationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDataFlowClusterGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDataFlowClusterGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDataFlowPoolGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDataFlowPoolGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDataFlowRunGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDataFlowRunGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDataFlowSqlEndpointGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDataFlowSqlEndpointGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDataLabelingDatasetGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDataLabelingDatasetGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDataSaasEnvironmentGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDataSaasEnvironmentGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDataSafeAlertPolicyGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDataSafeAlertPolicyGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDataSafeArchiveRetrievalGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDataSafeArchiveRetrievalGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDataSafeAuditPolicyGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDataSafeAuditPolicyGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDataSafeAuditProfileGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDataSafeAuditProfileGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDataSafeAuditTrailGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDataSafeAuditTrailGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDataSafeDatabaseSecurityConfigGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDataSafeDatabaseSecurityConfigGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDataSafeDiscoveryJobGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDataSafeDiscoveryJobGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDataSafeLibraryMaskingFormatGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDataSafeLibraryMaskingFormatGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDataSafeMaskingPolicyGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDataSafeMaskingPolicyGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDataSafeMaskingReportGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDataSafeMaskingReportGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDataSafeMaskPolicyHealthReportGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDataSafeMaskPolicyHealthReportGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDataSafeOnpremConnectorGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDataSafeOnpremConnectorGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDataSafePrivateEndpointGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDataSafePrivateEndpointGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDataSafeReportDefinitionGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDataSafeReportDefinitionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDataSafeReportGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDataSafeReportGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDataSafeSdmMaskingPolicyDifferenceGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDataSafeSdmMaskingPolicyDifferenceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDataSafeSecurityAssessmentGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDataSafeSecurityAssessmentGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDataSafeSecurityPolicyDeploymentGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDataSafeSecurityPolicyDeploymentGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDataSafeSecurityPolicyGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDataSafeSecurityPolicyGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDataSafeSensitiveDataModelGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDataSafeSensitiveDataModelGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDataSafeSensitiveTypeGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDataSafeSensitiveTypeGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDataSafeSensitiveTypesExportGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDataSafeSensitiveTypesExportGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDataSafeSqlCollectionGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDataSafeSqlCollectionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDataSafeSqlFirewallAllowedSqlGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDataSafeSqlFirewallAllowedSqlGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDataSafeSqlFirewallPolicyGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDataSafeSqlFirewallPolicyGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDataSafeSqlFirewallViolationGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDataSafeSqlFirewallViolationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDataSafeTargetAlertPolicyAssociationGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDataSafeTargetAlertPolicyAssociationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDataSafeTargetDatabaseGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDataSafeTargetDatabaseGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDataSafeUserAssessmentGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDataSafeUserAssessmentGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDataScienceJobGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDataScienceJobGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDataScienceJobRunGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDataScienceJobRunGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDataScienceModelDeploymentGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDataScienceModelDeploymentGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDataScienceModelGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDataScienceModelGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDataScienceModelVersionSetGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDataScienceModelVersionSetGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDataScienceNotebookSessionGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDataScienceNotebookSessionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDataSciencePipelineGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDataSciencePipelineGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDataSciencePipelineRunGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDataSciencePipelineRunGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDataSciencePrivateEndpointGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDataSciencePrivateEndpointGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDataScienceProjectGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDataScienceProjectGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDataScienceScheduleGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDataScienceScheduleGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDataTransferApplianceExportJobGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDataTransferApplianceExportJobGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDataTransferApplianceGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDataTransferApplianceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDataTransferDeviceGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDataTransferDeviceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDataTransferJobGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDataTransferJobGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDataTransferPackageGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDataTransferPackageGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDbCloudSchedulingPolicyGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDbCloudSchedulingPolicyGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDbCloudSchedulingWindowGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDbCloudSchedulingWindowGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDbHomeGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDbHomeGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDbKeyStoreGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDbKeyStoreGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDbmgmtExternalAsmGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDbmgmtExternalAsmGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDbmgmtExternalAsmInstanceGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDbmgmtExternalAsmInstanceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDbmgmtExternalClusterGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDbmgmtExternalClusterGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDbmgmtExternalClusterInstanceGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDbmgmtExternalClusterInstanceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDbmgmtExternalDbHomeGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDbmgmtExternalDbHomeGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDbmgmtExternalDbNodeGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDbmgmtExternalDbNodeGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDbmgmtExternalDbSystemConnectorGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDbmgmtExternalDbSystemConnectorGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDbmgmtExternalDbSystemGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDbmgmtExternalDbSystemGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDbmgmtExternalExadataInfrastructureGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDbmgmtExternalExadataInfrastructureGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDbmgmtExternalExadataStorageConnectorGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDbmgmtExternalExadataStorageConnectorGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDbmgmtExternalExadataStorageGridGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDbmgmtExternalExadataStorageGridGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDbmgmtExternalExadataStorageServerGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDbmgmtExternalExadataStorageServerGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDbmgmtExternalListenerGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDbmgmtExternalListenerGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDbmgmtJobGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDbmgmtJobGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDbmgmtManagedDatabaseGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDbmgmtManagedDatabaseGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDbmgmtManagedDatabaseGroupGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDbmgmtManagedDatabaseGroupGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDbmgmtNamedCredentialGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDbmgmtNamedCredentialGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDbmgmtPrivateEndpointGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDbmgmtPrivateEndpointGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDbNfsStorageGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDbNfsStorageGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDbNodeConsoleConnectionGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDbNodeConsoleConnectionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDbNodeConsoleHistoryGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDbNodeConsoleHistoryGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDbNodeGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDbNodeGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDbServerGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDbServerGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDbSystemGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDbSystemGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDcmsEndpointGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDcmsEndpointGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDcmsRegistryGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDcmsRegistryGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDedicatedVmHostGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDedicatedVmHostGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDelegationManagementDelegatedResourceAccessRequestGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDelegationManagementDelegatedResourceAccessRequestGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDelegationManagementDelegationControlGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDelegationManagementDelegationControlGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDelegationManagementDelegationSubscriptionGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDelegationManagementDelegationSubscriptionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDesktopPoolGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDesktopPoolGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDevOpsBuildPipelineGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDevOpsBuildPipelineGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDevOpsBuildPipelineStageGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDevOpsBuildPipelineStageGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDevOpsBuildRunGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDevOpsBuildRunGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDevOpsConnectionGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDevOpsConnectionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDevOpsDeployArtifactGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDevOpsDeployArtifactGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDevOpsDeployEnvironmentGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDevOpsDeployEnvironmentGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDevOpsDeploymentGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDevOpsDeploymentGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDevOpsDeployPipelineGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDevOpsDeployPipelineGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDevOpsDeployStageGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDevOpsDeployStageGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDevOpsProjectGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDevOpsProjectGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDevOpsRepositoryGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDevOpsRepositoryGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDevOpsTriggerGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDevOpsTriggerGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDhcpOptionGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDhcpOptionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDisWorkspaceGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDisWorkspaceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDnsPolicyAttachmentGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDnsPolicyAttachmentGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDnsPolicyGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDnsPolicyGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDnsResolverGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDnsResolverGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDnsTsigKeyGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDnsTsigKeyGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDnsViewGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDnsViewGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDrgAttachmentGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDrgAttachmentGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDrgGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDrgGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDrgRouteDistributionGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDrgRouteDistributionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDrgRouteTableGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDrgRouteTableGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDrPlanExecutionGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDrPlanExecutionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDrPlanGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDrPlanGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDrProtectionGroupGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDrProtectionGroupGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciDynamicResourceGroupGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciDynamicResourceGroupGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciEdmcsSaasEnvironmentGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciEdmcsSaasEnvironmentGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciEkmsPrivateEndpointGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciEkmsPrivateEndpointGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciEmailDkimGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciEmailDkimGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciEmailDomainGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciEmailDomainGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciEmailReturnPathGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciEmailReturnPathGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciEmailSenderGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciEmailSenderGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciEprcsSaasEnvironmentGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciEprcsSaasEnvironmentGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciEventRuleGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciEventRuleGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciExadataInfrastructureGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciExadataInfrastructureGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciExadbVmClusterGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciExadbVmClusterGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciExascaleDbStorageVaultGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciExascaleDbStorageVaultGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciExportGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciExportGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciExternalContainerDatabaseGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciExternalContainerDatabaseGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciExternalDatabaseConnectorGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciExternalDatabaseConnectorGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciExternalNonContainerDatabaseGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciExternalNonContainerDatabaseGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciExternalPluggableDatabaseGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciExternalPluggableDatabaseGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciFamscompliancepolicyGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciFamscompliancepolicyGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciFamsfleetGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciFamsfleetGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciFamsmaintenancewindowGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciFamsmaintenancewindowGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciFamspatchGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciFamspatchGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciFamsplatformconfigurationGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciFamsplatformconfigurationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciFamsrunbookGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciFamsrunbookGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciFamsschedulerdefinitionGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciFamsschedulerdefinitionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciFawServiceGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciFawServiceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciFileSystemGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciFileSystemGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciFilesystemSnapshotPolicyGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciFilesystemSnapshotPolicyGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciFolderConfiguration extends CloudProviderTenantFolderConfiguration implements ITypeNameObject, IOrganizationFolderConfiguration, IOciFolderConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        managed: boolean,
        name: string,
        parentScopeId: string,
        root: boolean,
        scopeSections: ScopeConfigurationSections,
        tenantType: Optional<TenantType>,
        organization: Optional<FolderConfigurationOrganization>,
        organizationExists: boolean,
        public ociScopeSections: OciScopeConfigurationSections) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciFolderConfiguration",
            managed,
            name,
            parentScopeId,
            root,
            scopeSections,
            tenantType,
            organization,
            organizationExists);
    }
}

export class OciFsgbuascsSaasEnvironmentGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciFsgbuascsSaasEnvironmentGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciFsgbuccaSaasEnvironmentGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciFsgbuccaSaasEnvironmentGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciFsgbuerfSaasEnvironmentGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciFsgbuerfSaasEnvironmentGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciFsGbuFccmamlcsSaasEnvironmentGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciFsGbuFccmamlcsSaasEnvironmentGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciFsgbuinsSaasEnvironmentGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciFsgbuinsSaasEnvironmentGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciFsGbuObcsSaasEnvironmentGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciFsGbuObcsSaasEnvironmentGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciFsgbupbsmSaasEnvironmentGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciFsgbupbsmSaasEnvironmentGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciFssFileSystem extends OciResource implements ITypeNameObject, IResource, IOciEncryptedResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public availabilityDomain: string,
        public encryptionExists: boolean,
        public encryptionVaultKeyOcid: Optional<string>,
        public size: number,
        public status: OciFssFileSystemStatus) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciFssFileSystem",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags);
    }
}

export class OciFssFileSystemDefaultEncryptionCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciFssFileSystemDefaultEncryptionCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class OciFssFileSystemDefaultEncryptionCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: OciFssFileSystemDefaultEncryptionCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciFssFileSystemDefaultEncryptionCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class OciFssFileSystemDefaultEncryptionRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciFssFileSystemDefaultEncryptionRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class OciFssFileSystemDefaultEncryptionRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: OciFssFileSystemDefaultEncryptionRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public grantingFssKeyAccessUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciFssFileSystemDefaultEncryptionRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class OciFssFileSystemModel extends OciResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IOciEncryptedResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        public encryptionVaultKeyIdReference: Optional<string>,
        public mountTargetExportIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciFssFileSystemModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class OciFssFileSystemMountTargetExportOptionIdentitySquashRootNotEnabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciFssFileSystemMountTargetExportOptionIdentitySquashRootNotEnabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class OciFssFileSystemMountTargetExportOptionIdentitySquashRootNotEnabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: OciFssFileSystemMountTargetExportOptionIdentitySquashRootNotEnabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciFssFileSystemMountTargetExportOptionIdentitySquashRootNotEnabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class OciFssFileSystemMountTargetExportOptionIdentitySquashRootNotEnabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciFssFileSystemMountTargetExportOptionIdentitySquashRootNotEnabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class OciFssFileSystemMountTargetExportOptionIdentitySquashRootNotEnabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: OciFssFileSystemMountTargetExportOptionIdentitySquashRootNotEnabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public exportOptionsDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciFssFileSystemMountTargetExportOptionIdentitySquashRootNotEnabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class OciFssMountTarget extends OciResource implements ITypeNameObject, IOciNetworkedResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public networkSecurityGroupOcids: string[],
        public privateIpAddress: Optional<string>,
        public status: Optional<OciFssMountTargetStatus>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciFssMountTarget",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags);
    }
}

export class OciFssMountTargetExport extends OciResource implements ITypeNameObject, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public fileSystemOcid: string,
        public options: OciFssMountTargetExportOption[],
        public status: OciFssMountTargetExportStatus) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciFssMountTargetExport",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags);
    }
}

export class OciFssMountTargetExportModel extends OciResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        public fileSystemIdReference: string,
        public mountTargetId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciFssMountTargetExportModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class OciFssMountTargetModel extends OciResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        public exportIds: string[],
        public fileSystemIdReferences: string[],
        public networkSecurityGroupIdReferences: string[],
        public subnetIdReference: string,
        public vcnIdReference: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciFssMountTargetModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class OciFssReplicationGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciFssReplicationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciFssReplicationTargetGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciFssReplicationTargetGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciFsuActionGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciFsuActionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciFsuCollectionGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciFsuCollectionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciFsuCycleGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciFsuCycleGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciFsuDiscoveryGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciFsuDiscoveryGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciFsuJobGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciFsuJobGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciFunctionsApplicationGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciFunctionsApplicationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciFunctionsFunctionGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciFunctionsFunctionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciFunctionsFunctionGenericResourceModel extends OciResourceSearchGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciFunctionsFunctionGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class OciFusionEnvironmentFamilyGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciFusionEnvironmentFamilyGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciFusionEnvironmentGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciFusionEnvironmentGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciGenAiAgentDevelopmentGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciGenAiAgentDevelopmentGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciGenerativeAiDedicatedAiClusterGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciGenerativeAiDedicatedAiClusterGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciGenerativeAiEndpointGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciGenerativeAiEndpointGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciGenerativeAiModelGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciGenerativeAiModelGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciGoldenGateConnectionGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciGoldenGateConnectionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciGoldenGateDatabaseRegistrationGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciGoldenGateDatabaseRegistrationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciGoldenGateDeploymentBackupGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciGoldenGateDeploymentBackupGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciGoldenGateDeploymentGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciGoldenGateDeploymentGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciGroupGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciGroupGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciHttpRedirectGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciHttpRedirectGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciIamDomainPasswordExpirationPolicyCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciIamDomainPasswordExpirationPolicyCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class OciIamDomainPasswordExpirationPolicyCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: OciIamDomainPasswordExpirationPolicyCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciIamDomainPasswordExpirationPolicyCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class OciIamDomainPasswordExpirationPolicyRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public passwordPolicyExpirationIntervalDays: number) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciIamDomainPasswordExpirationPolicyRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class OciIamDomainPasswordExpirationPolicyRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: OciIamDomainPasswordExpirationPolicyRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciIamDomainPasswordExpirationPolicyRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class OciIamDomainPasswordExpirationPolicyRiskPolicyUpdateAuditEventPasswordPolicyExpirationIntervalDaysChange extends RiskPolicyUpdateAuditEventChange implements ITypeNameObject {
    constructor(
        public passwordPolicyExpirationIntervalDays: Optional<number>) {
        super("OciIamDomainPasswordExpirationPolicyRiskPolicyUpdateAuditEventPasswordPolicyExpirationIntervalDaysChange");
    }
}

export class OciIamDomainPasswordLengthPolicyCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciIamDomainPasswordLengthPolicyCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class OciIamDomainPasswordLengthPolicyCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: OciIamDomainPasswordLengthPolicyCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciIamDomainPasswordLengthPolicyCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class OciIamDomainPasswordLengthPolicyRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public passwordPolicyMinLength: number) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciIamDomainPasswordLengthPolicyRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class OciIamDomainPasswordLengthPolicyRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: OciIamDomainPasswordLengthPolicyRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciIamDomainPasswordLengthPolicyRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class OciIamDomainPasswordLengthPolicyRiskPolicyUpdateAuditEventPasswordPolicyMinLengthChange extends RiskPolicyUpdateAuditEventChange implements ITypeNameObject {
    constructor(
        public passwordPolicyMinLength: Optional<number>) {
        super("OciIamDomainPasswordLengthPolicyRiskPolicyUpdateAuditEventPasswordPolicyMinLengthChange");
    }
}

export class OciIamDomainPasswordReusePolicyCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciIamDomainPasswordReusePolicyCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class OciIamDomainPasswordReusePolicyCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: OciIamDomainPasswordReusePolicyCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciIamDomainPasswordReusePolicyCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class OciIamDomainPasswordReusePolicyRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public passwordPolicyPreventReusePreviousPasswordCount: number) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciIamDomainPasswordReusePolicyRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class OciIamDomainPasswordReusePolicyRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: OciIamDomainPasswordReusePolicyRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciIamDomainPasswordReusePolicyRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class OciIamDomainPasswordReusePolicyRiskPolicyUpdateAuditEventPasswordPolicyPreventReusePreviousPasswordCountChange extends RiskPolicyUpdateAuditEventChange implements ITypeNameObject {
    constructor(
        public passwordPolicyPreventReusePreviousPasswordCount: Optional<number>) {
        super("OciIamDomainPasswordReusePolicyRiskPolicyUpdateAuditEventPasswordPolicyPreventReusePreviousPasswordCountChange");
    }
}

export class OciIamDomainProfile extends OciIamResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciIamDomainProfile");
    }
}

export class OciIamPolicyProfile extends OciIamResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciIamPolicyProfile");
    }
}

export class OciIamPrincipalProfile extends OciIamResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName);
    }
}

export class OciIamResource extends OciResource implements ITypeNameObject, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public description: Optional<string>,
        public enabled: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags);
    }
}

export class OciIamResourceModel extends OciResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class OciIamUserApiKeyNotRotatedRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: OciIamUserApiKeyNotRotatedRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciIamUserApiKeyNotRotatedRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class OciIamUserApiKeyProfile extends OciIamUserCredentialProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName);
    }
}

export class OciIamUserAuthTokenNotRotatedRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: OciIamUserAuthTokenNotRotatedRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciIamUserAuthTokenNotRotatedRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class OciIamUserAuthTokenProfile extends OciIamUserCredentialProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName);
    }
}

export class OciIamUserCredential extends OciIamResource implements ITypeNameObject, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        description: Optional<string>,
        enabled: boolean,
        public domainOcid: Optional<string>,
        public userId: string,
        public userOcid: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            description,
            enabled);
    }
}

export class OciIamUserCredentialModel extends OciIamResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        public userId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class OciIamUserCredentialNotRotatedRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public credentialRotationTimeFrame: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class OciIamUserCredentialNotRotatedRiskPolicyUpdateAuditEventCredentialRotationTimeFrameChange extends RiskPolicyUpdateAuditEventChange implements ITypeNameObject {
    constructor(
        public credentialRotationTimeFrame: Optional<string>,
        public previousCredentialRotationTimeFrame: Optional<string>) {
        super("OciIamUserCredentialNotRotatedRiskPolicyUpdateAuditEventCredentialRotationTimeFrameChange");
    }
}

export class OciIamUserDatabasePassword extends OciIamUserCredential implements ITypeNameObject, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        description: Optional<string>,
        enabled: boolean,
        domainOcid: Optional<string>,
        userId: string,
        userOcid: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciIamUserDatabasePassword",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            description,
            enabled,
            domainOcid,
            userId,
            userOcid);
    }
}

export class OciIamUserDatabasePasswordModel extends OciIamUserCredentialModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        userId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciIamUserDatabasePasswordModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            userId);
    }
}

export class OciIamUserDatabasePasswordNotRotatedRisk extends OciIamUserCredentialNotRotatedRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        credentialRotationTimeFrame: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciIamUserDatabasePasswordNotRotatedRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            credentialRotationTimeFrame);
    }
}

export class OciIamUserDatabasePasswordNotRotatedRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: OciIamUserDatabasePasswordNotRotatedRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciIamUserDatabasePasswordNotRotatedRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class OciIamUserModelFilters extends OciResourceModelFilters implements IEntityModelFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        openRelatedEntityRiskTypeIdentifierItems: FilterItems<string>,
        regionIdItems: FilterItems<string>,
        tenantIdItems: FilterItems<string>,
        typeNameItems: FilterItems<string>,
        creationTimeRange: FilterTimeRange,
        public signInTimeRange: FilterTimeRange) {
        super(
            attributeValueItems,
            openRelatedEntityRiskTypeIdentifierItems,
            regionIdItems,
            tenantIdItems,
            typeNameItems,
            creationTimeRange);
    }
}

export class OciIamUserOrganizationAdministratorApiKeyExistsRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciIamUserOrganizationAdministratorApiKeyExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class OciIamUserOrganizationAdministratorApiKeyExistsRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: OciIamUserOrganizationAdministratorApiKeyExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciIamUserOrganizationAdministratorApiKeyExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class OciIamUserProfile extends OciIamPrincipalProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciIamUserProfile");
    }
}

export class OciIamUserSecretKey extends OciIamUserCredential implements ITypeNameObject, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        description: Optional<string>,
        enabled: boolean,
        domainOcid: Optional<string>,
        userId: string,
        userOcid: string,
        public accessKey: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciIamUserSecretKey",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            description,
            enabled,
            domainOcid,
            userId,
            userOcid);
    }
}

export class OciIamUserSecretKeyModel extends OciIamUserCredentialModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        userId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciIamUserSecretKeyModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            userId);
    }
}

export class OciIamUserSecretKeyNotRotatedRisk extends OciIamUserCredentialNotRotatedRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        credentialRotationTimeFrame: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciIamUserSecretKeyNotRotatedRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            credentialRotationTimeFrame);
    }
}

export class OciIamUserSecretKeyNotRotatedRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: OciIamUserSecretKeyNotRotatedRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciIamUserSecretKeyNotRotatedRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class OciIdentityProviderGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciIdentityProviderGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciImageGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciImageGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciIngressGatewayGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciIngressGatewayGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciIngressGatewayRouteTableGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciIngressGatewayRouteTableGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciInstanceConfigurationGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciInstanceConfigurationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciInstanceGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciInstanceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciInstancePoolGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciInstancePoolGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciIntegrationInstanceGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciIntegrationInstanceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciInternetGatewayGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciInternetGatewayGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciInventoryAssetGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciInventoryAssetGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciIpSecConnectionGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciIpSecConnectionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciIpv6GenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciIpv6GenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciJmsFleetGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciJmsFleetGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciKafkaClusterConfigGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciKafkaClusterConfigGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciKafkaClusterGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciKafkaClusterGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciKeyGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciKeyGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciKmsHsmClusterGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciKmsHsmClusterGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciKmsHsmPartitionGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciKmsHsmPartitionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciKmsVault extends OciResource implements ITypeNameObject, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public managementEndpoint: string,
        public status: OciKmsVaultStatus,
        public type: OciKmsVaultType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciKmsVault",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags);
    }
}

export class OciKmsVaultKeyNotRotatedRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public keyRotationTimeFrame: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciKmsVaultKeyNotRotatedRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class OciKmsVaultKeyNotRotatedRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: OciKmsVaultKeyNotRotatedRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciKmsVaultKeyNotRotatedRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class OciKmsVaultKeyNotRotatedRiskPolicyUpdateAuditEventKeyRotationTimeFrameChange extends RiskPolicyUpdateAuditEventChange implements ITypeNameObject {
    constructor(
        public keyRotationTimeFrame: Optional<string>,
        public previousKeyRotationTimeFrame: Optional<string>) {
        super("OciKmsVaultKeyNotRotatedRiskPolicyUpdateAuditEventKeyRotationTimeFrameChange");
    }
}

export class OciKmsVaultModel extends OciResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        public keyIds: string[],
        public secretIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciKmsVaultModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class OciKmsVaultObject extends OciResource implements ITypeNameObject, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public vaultOcid: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags);
    }
}

export class OciKmsVaultObjectModel extends OciResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        public vaultIdReference: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class OciKmsVaultSecret extends OciKmsVaultObject implements ITypeNameObject, IResource, IOciEncryptedResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        vaultOcid: string,
        public autoGenerationEnabled: boolean,
        public currentVersionNumber: number,
        public description: string,
        public encryptionExists: boolean,
        public encryptionVaultKeyOcid: Optional<string>,
        public nextRotationTime: Optional<string>,
        public rotationEnabled: boolean,
        public rotationTimeFrame: Optional<string>,
        public status: OciKmsVaultSecretStatus,
        public versionNumberToVersionMap: Dictionary<OciKmsVaultSecretVersion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciKmsVaultSecret",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            vaultOcid);
    }
}

export class OciKmsVaultSecretModel extends OciKmsVaultObjectModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IOciEncryptedResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        vaultIdReference: string,
        public encryptionVaultKeyIdReference: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciKmsVaultSecretModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            vaultIdReference);
    }
}

export class OciLicenseManagerLicenseRecordGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciLicenseManagerLicenseRecordGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciLicenseManagerProductLicenseGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciLicenseManagerProductLicenseGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciLoadBalancerGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciLoadBalancerGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciLoadBalancingLoadBalancer extends OciResource implements ITypeNameObject, IOciNetworkedResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public backendSetNameToBackendSetMap: Dictionary<OciLoadBalancingLoadBalancerBackendSet>,
        public external: boolean,
        public listeners: OciLoadBalancingLoadBalancerListener[],
        public privateIpAddresses: string[],
        public publicIpAddresses: string[],
        public securityGroupOcids: string[],
        public status: OciLoadBalancingLoadBalancerStatus,
        public subnetOcids: string[],
        public webApplicationFirewallOcid: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciLoadBalancingLoadBalancer",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags);
    }
}

export class OciLoadBalancingLoadBalancerModel extends OciResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        public accessLogsEnabled: boolean,
        public backendIpAddressToInstanceIdMap: Dictionary<string>,
        public errorLogsEnabled: boolean,
        public securityGroupIdReferences: string[],
        public subnetIdReferences: string[],
        public vcnIdReference: Optional<string>,
        public webApplicationFirewallIdReference: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciLoadBalancingLoadBalancerModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class OciLocalPeeringGatewayGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciLocalPeeringGatewayGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciLogAnalyticsEntityGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciLogAnalyticsEntityGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciLogAsyncSearchGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciLogAsyncSearchGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciLogDataModelGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciLogDataModelGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciLogFireSaasEnvironmentGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciLogFireSaasEnvironmentGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciLogGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciLogGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciLoggingLogGroup extends OciResource implements ITypeNameObject, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public description: Optional<string>,
        public logs: OciLoggingLog[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciLoggingLogGroup",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags);
    }
}

export class OciLoggingLogGroupModel extends OciResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        public logBucketNameToIdReferenceMap: Dictionary<string>,
        public logResourceOcidToIdReferenceMap: Dictionary<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciLoggingLogGroupModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class OciLogGroupGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciLogGroupGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciLogRuleGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciLogRuleGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciLogSavedSearchGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciLogSavedSearchGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciManagementAgentGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciManagementAgentGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciManagementAgentInstallKeyGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciManagementAgentInstallKeyGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciMaxymiserSaasEnvironmentGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciMaxymiserSaasEnvironmentGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciMediaAssetGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciMediaAssetGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciMediaWorkflowConfigurationGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciMediaWorkflowConfigurationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciMediaWorkflowGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciMediaWorkflowGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciMediaWorkflowJobGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciMediaWorkflowJobGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciMeshGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciMeshGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciMigrationGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciMigrationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciMigrationPlanGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciMigrationPlanGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciMktPubArtifactGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciMktPubArtifactGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciMktPubListingGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciMktPubListingGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciMktPubListingRevisionGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciMktPubListingRevisionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciMktPubTermGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciMktPubTermGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciMktPubTermVersionGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciMktPubTermVersionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciMockCimImSaasEnvironmentGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciMockCimImSaasEnvironmentGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciMountTargetGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciMountTargetGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciNatGatewayGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciNatGatewayGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciNetworkFirewallGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciNetworkFirewallGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciNetworkFirewallPolicyGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciNetworkFirewallPolicyGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciNetworkingInternetGateway extends OciResource implements ITypeNameObject, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public enabled: boolean,
        public routeTableOcid: Optional<string>,
        public status: OciNetworkingInternetGatewayStatus,
        public vcnOcid: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciNetworkingInternetGateway",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags);
    }
}

export class OciNetworkingInternetGatewayModel extends OciResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        public routeTableIdReference: Optional<string>,
        public vcnIdReference: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciNetworkingInternetGatewayModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class OciNetworkingLocalPeeringGateway extends OciResource implements ITypeNameObject, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public crossTenancyPeering: boolean,
        public peerAdvertisedSubnets: Optional<string[]>,
        public peeringStatus: OciNetworkingLocalPeeringGatewayPeeringStatus,
        public peerLocalPeeringGatewayOcid: Optional<string>,
        public routeTableOcid: Optional<string>,
        public status: OciNetworkingLocalPeeringGatewayStatus,
        public vcnOcid: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciNetworkingLocalPeeringGateway",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags);
    }
}

export class OciNetworkingLocalPeeringGatewayModel extends OciResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        public advertisedSubnets: Optional<string[]>,
        public associatedRouteTableIds: string[],
        public associatedRouteTableSubnetIds: string[],
        public peerLocalPeeringGatewayIdReference: Optional<string>,
        public peerVcnIdReference: Optional<string>,
        public routeTableIdReference: Optional<string>,
        public vcnIdReference: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciNetworkingLocalPeeringGatewayModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class OciNetworkingNatGateway extends OciResource implements ITypeNameObject, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public blockTraffic: boolean,
        public publicIpAddress: string,
        public routeTableOcid: Optional<string>,
        public status: OciNetworkingNatGatewayStatus,
        public vcnOcid: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciNetworkingNatGateway",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags);
    }
}

export class OciNetworkingNatGatewayModel extends OciResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        public routeTableIdReference: Optional<string>,
        public vcnIdReference: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciNetworkingNatGatewayModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class OciNetworkingNetworkLoadBalancer extends OciResource implements ITypeNameObject, IOciNetworkedResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public backendSetNameToBackendsMap: Dictionary<OciNetworkingNetworkLoadBalancerBackend[]>,
        public external: boolean,
        public listeners: OciNetworkingNetworkLoadBalancerListener[],
        public privateIpAddresses: string[],
        public publicIpAddresses: string[],
        public securityGroupOcids: string[],
        public status: OciNetworkingNetworkLoadBalancerStatus,
        public subnetOcids: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciNetworkingNetworkLoadBalancer",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags);
    }
}

export class OciNetworkingNetworkLoadBalancerModel extends OciResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        public backendIpAddressToInstanceIdMap: Dictionary<string>,
        public securityGroupIdReferences: string[],
        public subnetIdReferences: string[],
        public vcnIdReference: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciNetworkingNetworkLoadBalancerModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class OciNetworkingNetworkResourceInboundRuleSubnetAnyExistsRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class OciNetworkingNetworkSecurityGroup extends OciResource implements ITypeNameObject, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public inboundRules: OciNetworkingNetworkSecurityGroupRule[],
        public outboundRules: OciNetworkingNetworkSecurityGroupRule[],
        public status: OciNetworkingNetworkSecurityGroupStatus,
        public vcnOcid: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciNetworkingNetworkSecurityGroup",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags);
    }
}

export class OciNetworkingNetworkSecurityGroupInboundRuleSubnetAnyExistsRisk extends OciNetworkingNetworkResourceInboundRuleSubnetAnyExistsRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public unrestrictedInboundRules: OciNetworkingNetworkSecurityGroupRule[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class OciNetworkingNetworkSecurityGroupInboundRuleSubnetAnyExistsRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: OciNetworkingNetworkSecurityGroupInboundRuleSubnetAnyExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class OciNetworkingNetworkSecurityGroupInboundRuleSubnetAnyRdpExistsCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciNetworkingNetworkSecurityGroupInboundRuleSubnetAnyRdpExistsCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class OciNetworkingNetworkSecurityGroupInboundRuleSubnetAnyRdpExistsCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: OciNetworkingNetworkSecurityGroupInboundRuleSubnetAnyRdpExistsCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciNetworkingNetworkSecurityGroupInboundRuleSubnetAnyRdpExistsCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class OciNetworkingNetworkSecurityGroupInboundRuleSubnetAnyRdpExistsRisk extends OciNetworkingNetworkSecurityGroupInboundRuleSubnetAnyExistsRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        unrestrictedInboundRules: OciNetworkingNetworkSecurityGroupRule[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciNetworkingNetworkSecurityGroupInboundRuleSubnetAnyRdpExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            unrestrictedInboundRules);
    }
}

export class OciNetworkingNetworkSecurityGroupInboundRuleSubnetAnyRdpExistsRiskModel extends OciNetworkingNetworkSecurityGroupInboundRuleSubnetAnyExistsRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: OciNetworkingNetworkSecurityGroupInboundRuleSubnetAnyExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciNetworkingNetworkSecurityGroupInboundRuleSubnetAnyRdpExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class OciNetworkingNetworkSecurityGroupInboundRuleSubnetAnySshExistsCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciNetworkingNetworkSecurityGroupInboundRuleSubnetAnySshExistsCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class OciNetworkingNetworkSecurityGroupInboundRuleSubnetAnySshExistsCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: OciNetworkingNetworkSecurityGroupInboundRuleSubnetAnySshExistsCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciNetworkingNetworkSecurityGroupInboundRuleSubnetAnySshExistsCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class OciNetworkingNetworkSecurityGroupInboundRuleSubnetAnySshExistsRisk extends OciNetworkingNetworkSecurityGroupInboundRuleSubnetAnyExistsRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        unrestrictedInboundRules: OciNetworkingNetworkSecurityGroupRule[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciNetworkingNetworkSecurityGroupInboundRuleSubnetAnySshExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            unrestrictedInboundRules);
    }
}

export class OciNetworkingNetworkSecurityGroupInboundRuleSubnetAnySshExistsRiskModel extends OciNetworkingNetworkSecurityGroupInboundRuleSubnetAnyExistsRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: OciNetworkingNetworkSecurityGroupInboundRuleSubnetAnyExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciNetworkingNetworkSecurityGroupInboundRuleSubnetAnySshExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class OciNetworkingNetworkSecurityGroupModel extends OciResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        public associatedResourceTypeNameToIdReferencesMap: Dictionary<string[]>,
        public networkSecurityGroupOcidToEntityIdReferenceMap: Dictionary<string>,
        public vcnIdReference: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciNetworkingNetworkSecurityGroupModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class OciNetworkingRouteTable extends OciResource implements ITypeNameObject, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public routeRules: OciNetworkingRouteTableRouteRule[],
        public status: OciNetworkingRouteTableStatus,
        public vcnOcid: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciNetworkingRouteTable",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags);
    }
}

export class OciNetworkingRouteTableModel extends OciResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        public subnetIds: string[],
        public targetResourceOcidToEntityIdReferenceMap: Dictionary<string>,
        public vcnIdReference: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciNetworkingRouteTableModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class OciNetworkingSecurityList extends OciResource implements ITypeNameObject, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public inboundRules: OciNetworkingSecurityListRule[],
        public outboundRules: OciNetworkingSecurityListRule[],
        public status: OciNetworkingSecurityListStatus,
        public vcnOcid: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciNetworkingSecurityList",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags);
    }
}

export class OciNetworkingSecurityListInboundRuleSubnetAnyExistsRisk extends OciNetworkingNetworkResourceInboundRuleSubnetAnyExistsRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public unrestrictedInboundRules: OciNetworkingSecurityListRule[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class OciNetworkingSecurityListInboundRuleSubnetAnyExistsRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: OciNetworkingSecurityListInboundRuleSubnetAnyExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class OciNetworkingSecurityListInboundRuleSubnetAnyRdpExistsCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciNetworkingSecurityListInboundRuleSubnetAnyRdpExistsCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class OciNetworkingSecurityListInboundRuleSubnetAnyRdpExistsCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: OciNetworkingSecurityListInboundRuleSubnetAnyRdpExistsCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciNetworkingSecurityListInboundRuleSubnetAnyRdpExistsCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class OciNetworkingSecurityListInboundRuleSubnetAnyRdpExistsRisk extends OciNetworkingSecurityListInboundRuleSubnetAnyExistsRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        unrestrictedInboundRules: OciNetworkingSecurityListRule[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciNetworkingSecurityListInboundRuleSubnetAnyRdpExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            unrestrictedInboundRules);
    }
}

export class OciNetworkingSecurityListInboundRuleSubnetAnyRdpExistsRiskModel extends OciNetworkingSecurityListInboundRuleSubnetAnyExistsRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: OciNetworkingSecurityListInboundRuleSubnetAnyExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciNetworkingSecurityListInboundRuleSubnetAnyRdpExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class OciNetworkingSecurityListInboundRuleSubnetAnySshExistsCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciNetworkingSecurityListInboundRuleSubnetAnySshExistsCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class OciNetworkingSecurityListInboundRuleSubnetAnySshExistsCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: OciNetworkingSecurityListInboundRuleSubnetAnySshExistsCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciNetworkingSecurityListInboundRuleSubnetAnySshExistsCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class OciNetworkingSecurityListInboundRuleSubnetAnySshExistsRisk extends OciNetworkingSecurityListInboundRuleSubnetAnyExistsRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        unrestrictedInboundRules: OciNetworkingSecurityListRule[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciNetworkingSecurityListInboundRuleSubnetAnySshExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            unrestrictedInboundRules);
    }
}

export class OciNetworkingSecurityListInboundRuleSubnetAnySshExistsRiskModel extends OciNetworkingSecurityListInboundRuleSubnetAnyExistsRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: OciNetworkingSecurityListInboundRuleSubnetAnyExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciNetworkingSecurityListInboundRuleSubnetAnySshExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class OciNetworkingSecurityListModel extends OciResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        public subnetIds: string[],
        public vcnIdReference: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciNetworkingSecurityListModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class OciNetworkingServiceGateway extends OciResource implements ITypeNameObject, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public blockTraffic: boolean,
        public routeTableOcid: Optional<string>,
        public serviceName: string,
        public status: OciNetworkingServiceGatewayStatus,
        public vcnOcid: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciNetworkingServiceGateway",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags);
    }
}

export class OciNetworkingServiceGatewayModel extends OciResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        public routeTableIdReference: Optional<string>,
        public vcnIdReference: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciNetworkingServiceGatewayModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class OciNetworkingSubnet extends OciResource implements ITypeNameObject, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public availabilityDomain: Optional<string>,
        public domainName: Optional<string>,
        public ipv4Subnet: string,
        public ipv6Subnets: string[],
        public prohibitPublicIpOnVnic: boolean,
        public regional: boolean,
        public routeTableOcid: string,
        public securityListOcids: string[],
        public status: OciNetworkingSubnetStatus,
        public vcnOcid: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciNetworkingSubnet",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags);
    }
}

export class OciNetworkingSubnetModel extends OciResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        public flowLogsEnabled: boolean,
        public networkedResourceTypeNameToIdsMap: Dictionary<string[]>,
        public routeTableIdReference: string,
        public securityListIdReferences: string[],
        public vcnIdReference: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciNetworkingSubnetModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class OciNetworkingVcn extends OciResource implements ITypeNameObject, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public defaultRouteTableOcid: string,
        public defaultSecurityListOcid: string,
        public domainName: Optional<string>,
        public ipv4Subnets: string[],
        public ipv6Subnets: string[],
        public status: OciNetworkingVcnStatus) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciNetworkingVcn",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags);
    }
}

export class OciNetworkingVcnFlowLogsNotEnabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciNetworkingVcnFlowLogsNotEnabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class OciNetworkingVcnFlowLogsNotEnabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: OciNetworkingVcnFlowLogsNotEnabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciNetworkingVcnFlowLogsNotEnabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class OciNetworkingVcnFlowLogsNotEnabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciNetworkingVcnFlowLogsNotEnabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class OciNetworkingVcnFlowLogsNotEnabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: OciNetworkingVcnFlowLogsNotEnabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciNetworkingVcnFlowLogsNotEnabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class OciNetworkingVcnModel extends OciResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        public defaultRouteTableIdReference: string,
        public defaultSecurityListIdReference: string,
        public flowLogsEnabled: boolean,
        public internetGatewayId: Optional<string>,
        public localPeeringGatewayIds: string[],
        public natGatewayIds: string[],
        public networkedResourceTypeNameToIdsMap: Dictionary<string[]>,
        public networkSecurityGroupIds: string[],
        public securityListIds: string[],
        public serviceGatewayIds: string[],
        public subnetIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciNetworkingVcnModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class OciNetworkingVirtualNetworkInterfaceCard extends OciResource implements ITypeNameObject, IOciNetworkedResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public availabilityDomain: string,
        public macAddress: string,
        public primary: boolean,
        public privateIpAddress: Optional<string>,
        public publicIpAddress: Optional<string>,
        public securityGroupOcids: string[],
        public subnetOcids: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciNetworkingVirtualNetworkInterfaceCard",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags);
    }
}

export class OciNetworkingVirtualNetworkInterfaceCardModel extends OciResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        public securityGroupIdReferences: string[],
        public subnetIdReference: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciNetworkingVirtualNetworkInterfaceCardModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class OciNetworkSecurityGroupGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciNetworkSecurityGroupGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciNoSqlTableGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciNoSqlTableGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciObjectStorageBucket extends OciResource implements ITypeNameObject, IResource, IOciEncryptedResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public encryptionExists: boolean,
        public encryptionVaultKeyOcid: Optional<string>,
        public namespace: string,
        public objectAccessLevel: OciObjectStorageBucketObjectAccessLevel,
        public objectCount: number,
        public objectEventsEnabled: boolean,
        public objectTieringEnabled: boolean,
        public objectVersioning: OciObjectStorageBucketObjectVersioning,
        public storageSize: number,
        public storageTier: OciObjectStorageBucketStorageTier) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciObjectStorageBucket",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags);
    }
}

export class OciObjectStorageBucketDefaultEncryptionRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciObjectStorageBucketDefaultEncryptionRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class OciObjectStorageBucketDefaultEncryptionRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: OciObjectStorageBucketDefaultEncryptionRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciObjectStorageBucketDefaultEncryptionRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class OciObjectStorageBucketLogCategoryWriteNotExistsCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciObjectStorageBucketLogCategoryWriteNotExistsCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class OciObjectStorageBucketLogCategoryWriteNotExistsCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: OciObjectStorageBucketLogCategoryWriteNotExistsCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciObjectStorageBucketLogCategoryWriteNotExistsCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class OciObjectStorageBucketLogCategoryWriteNotExistsRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciObjectStorageBucketLogCategoryWriteNotExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class OciObjectStorageBucketLogCategoryWriteNotExistsRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: OciObjectStorageBucketLogCategoryWriteNotExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciObjectStorageBucketLogCategoryWriteNotExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class OciObjectStorageBucketModel extends OciResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IOciEncryptedResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        public encryptionVaultKeyIdReference: Optional<string>,
        public logCategory: OciObjectStorageBucketLogCategory) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciObjectStorageBucketModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class OciObjectStorageBucketObjectEventsDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciObjectStorageBucketObjectEventsDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class OciObjectStorageBucketObjectEventsDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: OciObjectStorageBucketObjectEventsDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciObjectStorageBucketObjectEventsDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class OciObjectStorageBucketObjectVersioningDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciObjectStorageBucketObjectVersioningDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class OciObjectStorageBucketObjectVersioningDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: OciObjectStorageBucketObjectVersioningDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciObjectStorageBucketObjectVersioningDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class OciObjectStorageBucketPublicAccessExistsRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: OciObjectStorageBucketPublicAccessExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciObjectStorageBucketPublicAccessExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class OciOcbAgentDependencyGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciOcbAgentDependencyGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciOcbAgentGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciOcbAgentGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciOcbAssetSourceGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciOcbAssetSourceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciOcbAwsEbsAssetGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciOcbAwsEbsAssetGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciOcbAwsEcTwoAssetGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciOcbAwsEcTwoAssetGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciOcbDiscoveryScheduleGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciOcbDiscoveryScheduleGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciOcbEnvironmentGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciOcbEnvironmentGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciOcbInventoryGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciOcbInventoryGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciOcbInventoryRelationGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciOcbInventoryRelationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciOcbOracleDbAssetGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciOcbOracleDbAssetGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciOcbVmAssetGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciOcbVmAssetGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciOcbVmwareVmAssetGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciOcbVmwareVmAssetGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciOccAvailabilityCatalogGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciOccAvailabilityCatalogGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciOccCapacityRequestGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciOccCapacityRequestGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciOceInstanceGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciOceInstanceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciOdaInstanceGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciOdaInstanceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciOdaPrivateEndpointGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciOdaPrivateEndpointGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciOdmsAgentGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciOdmsAgentGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciOdmsConnectionGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciOdmsConnectionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciOdmsJobGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciOdmsJobGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciOdmsMigrationGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciOdmsMigrationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciOneoffPatchGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciOneoffPatchGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciOnsPhoneApplicationGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciOnsPhoneApplicationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciOnsPhoneNumberGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciOnsPhoneNumberGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciOnsSubscriptionGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciOnsSubscriptionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciOnsTopicGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciOnsTopicGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciOpctlOperatorControlAssignmentGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciOpctlOperatorControlAssignmentGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciOpctlOperatorControlGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciOpctlOperatorControlGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciOpsiDatabaseInsightGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciOpsiDatabaseInsightGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciOracleDbAzureBlobContainerGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciOracleDbAzureBlobContainerGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciOracleDbAzureBlobMountGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciOracleDbAzureBlobMountGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciOracleDbAzureConnectorGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciOracleDbAzureConnectorGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciOracleDbAzureVaultAssociationGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciOracleDbAzureVaultAssociationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciOracleDbAzureVaultGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciOracleDbAzureVaultGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciOrganizationConfiguration extends CloudProviderTenantOrganizationConfiguration implements ITypeNameObject, ITenantOrganizationConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        enabled: boolean,
        folderEnabled: boolean,
        memberSelection: Optional<OrganizationMemberSelection>,
        name: string,
        permissionTypes: CloudProviderTenantPermissionType[],
        public homeRegionSystemName: string,
        public ocid: string,
        public user: OciOrganizationConfigurationUser) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciOrganizationConfiguration",
            scopeId,
            enabled,
            folderEnabled,
            memberSelection,
            name,
            permissionTypes);
    }
}

export class OciOrganizationModel extends ScopeSystemEntityModel implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        public configuration: OciOrganizationConfiguration,
        scopeId: string,
        state: Optional<SystemEntityState>,
        public status: OciOrganizationModelStatus,
        public statusSeverity: Optional<Severity>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciOrganizationModel",
            configuration,
            scopeId,
            state);
    }
}

export class OciOrganizationsGovernanceRuleGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciOrganizationsGovernanceRuleGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciOrmConfigSourceProviderGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciOrmConfigSourceProviderGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciOrmJobGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciOrmJobGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciOrmPrivateEndpointGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciOrmPrivateEndpointGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciOrmStackGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciOrmStackGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciOrmTemplateGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciOrmTemplateGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciOsdPrivateEndpointGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciOsdPrivateEndpointGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciOsdShardedDatabaseGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciOsdShardedDatabaseGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciOsfpcsSaasEnvironmentGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciOsfpcsSaasEnvironmentGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciOsmhLifecycleEnvironmentGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciOsmhLifecycleEnvironmentGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciOsmhLifecycleStageGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciOsmhLifecycleStageGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciOsmhManagedInstanceGroupGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciOsmhManagedInstanceGroupGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciOsmhManagementStationGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciOsmhManagementStationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciOsmhProfileGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciOsmhProfileGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciOsmhScheduledJobGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciOsmhScheduledJobGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciOsmhSoftwareSourceGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciOsmhSoftwareSourceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciOsmsManagedInstanceGroupGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciOsmsManagedInstanceGroupGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciOsmsScheduledJobGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciOsmsScheduledJobGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciOsmsSoftwareSourceGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciOsmsSoftwareSourceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciOtmgtmSaasEnvironmentGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciOtmgtmSaasEnvironmentGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciOutboundConnectorGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciOutboundConnectorGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciPathAnalyzerTestGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciPathAnalyzerTestGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciPcmcsSaasEnvironmentGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciPcmcsSaasEnvironmentGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciPlanningSaasEnvironmentGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciPlanningSaasEnvironmentGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciPluggableDatabaseGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciPluggableDatabaseGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciPolicyGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciPolicyGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciPostgresqlBackupGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciPostgresqlBackupGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciPostgresqlConfigurationGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciPostgresqlConfigurationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciPostgresqlDbSystemGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciPostgresqlDbSystemGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciPriceListGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciPriceListGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciPriceListItemGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciPriceListItemGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciPricingRuleGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciPricingRuleGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciPrivateIpGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciPrivateIpGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciProcessAutomationInstanceGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciProcessAutomationInstanceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciProductGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciProductGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciProtectedDatabaseGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciProtectedDatabaseGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciProtectionPolicyGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciProtectionPolicyGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciPublicIpGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciPublicIpGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciPublicIpPoolGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciPublicIpPoolGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciQueryServiceProjectGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciQueryServiceProjectGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciQueueConsumerGroupGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciQueueConsumerGroupGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciQueueGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciQueueGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciQuotumGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciQuotumGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciRcmsAppConfigurationGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciRcmsAppConfigurationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciRecoveryServiceSubnetGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciRecoveryServiceSubnetGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciRecoverySystemGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciRecoverySystemGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciRecoverySystemNetworkInterfaceGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciRecoverySystemNetworkInterfaceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciRecoverySystemPolicyGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciRecoverySystemPolicyGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciRedisClusterGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciRedisClusterGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciRemotePeeringConnectionGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciRemotePeeringConnectionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciReplicationPolicyGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciReplicationPolicyGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciReplicationScheduleGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciReplicationScheduleGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciResourceScheduleGenericResource extends OciResourceSearchGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        availabilityDomain: Optional<string>,
        lifecycleState: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciResourceScheduleGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            availabilityDomain,
            lifecycleState);
    }
}

export class OciResourceSearchGenericResourceTypeMetadata extends OciResourceTypeMetadata {
    constructor() {
        super();
    }
}

export class OciTenantEntity extends OciIamResource implements ITypeNameObject, IResource, ITenantEntity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        description: Optional<string>,
        enabled: boolean,
        public parentTenantEntityId: Optional<string>,
        public root: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciTenantEntity",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            description,
            enabled);
    }
}

export class OciTenantEntityModel extends OciIamResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, ITenantEntityModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        public parentEntityPath: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciTenantEntityModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class OktaDirectoryEntity extends OktaEntity implements ITypeNameObject, IDirectoryEntity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        rawId: string,
        public creationTime: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            rawId);
    }
}

export class OktaDirectoryEntityModel extends OktaEntityModel implements ITypeNameObject, IEntityModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown);
    }
}

export class OktaDirectoryGroupMembershipRequest extends GroupMembershipRequest implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        closeReason: Optional<PermissionRequestCloseReason>,
        expirationTime: Optional<string>,
        expirationTimeFrame: string,
        granteeUserTenantId: string,
        permissionEligibilityId: string,
        reason: Optional<string>,
        startTime: Optional<string>,
        status: PermissionRequestStatus,
        statusUpdateTime: string,
        assignedGroupIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OktaDirectoryGroupMembershipRequest",
            scopeId,
            closeReason,
            expirationTime,
            expirationTimeFrame,
            granteeUserTenantId,
            permissionEligibilityId,
            reason,
            startTime,
            status,
            statusUpdateTime,
            assignedGroupIds);
    }
}

export class OktaDirectoryPrincipal extends OktaDirectoryEntity implements ITypeNameObject, IDirectoryEntity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        rawId: string,
        creationTime: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            rawId,
            creationTime);
    }
}

export class OktaDirectoryPrincipalModel extends OktaDirectoryEntityModel implements ITypeNameObject, IEntityModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        public awsRoleIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown);
    }
}

export class OktaDirectoryUser extends OktaDirectoryPrincipal implements ITypeNameObject, IAwsOriginatorIdentity, IDirectoryUser, IPermissionManagementUser {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        rawId: string,
        creationTime: Optional<string>,
        public firstName: Optional<string>,
        public lastName: Optional<string>,
        public login: string,
        public mail: Optional<string>,
        public passwordUpdateTime: Optional<string>,
        public rawDisplayName: Optional<string>,
        public rawMail: string,
        public status: OktaDirectoryUserStatus) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OktaDirectoryUser",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            rawId,
            creationTime);
    }
}

export class OktaDirectoryUserModel extends OktaDirectoryPrincipalModel implements ITypeNameObject, IUdmObjectModel, IAwsFederationUserModel, IPermissionManagementUserModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        awsRoleIds: string[],
        public groupIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OktaDirectoryUserModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            awsRoleIds);
    }
}

export class OneLoginDirectoryEntity extends OneLoginEntity implements ITypeNameObject, IDirectoryEntity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        rawId: number,
        public creationTime: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            rawId);
    }
}

export class OneLoginDirectoryEntityModel extends OneLoginEntityModel implements ITypeNameObject, IEntityModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown);
    }
}

export class OneLoginDirectoryPrincipal extends OneLoginDirectoryEntity implements ITypeNameObject, IDirectoryEntity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        rawId: number,
        creationTime: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            rawId,
            creationTime);
    }
}

export class OneLoginDirectoryPrincipalModel extends OneLoginDirectoryEntityModel implements ITypeNameObject, IEntityModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown);
    }
}

export class OneLoginDirectoryRole extends OneLoginDirectoryPrincipal implements ITypeNameObject, IDirectoryEntity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        rawId: number,
        creationTime: Optional<string>,
        public name: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OneLoginDirectoryRole",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            rawId,
            creationTime);
    }
}

export class OneLoginDirectoryRoleAssignmentRequest extends PermissionRequest implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        closeReason: Optional<PermissionRequestCloseReason>,
        expirationTime: Optional<string>,
        expirationTimeFrame: string,
        granteeUserTenantId: string,
        permissionEligibilityId: string,
        reason: Optional<string>,
        startTime: Optional<string>,
        status: PermissionRequestStatus,
        statusUpdateTime: string,
        public assignedRoleIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OneLoginDirectoryRoleAssignmentRequest",
            scopeId,
            closeReason,
            expirationTime,
            expirationTimeFrame,
            granteeUserTenantId,
            permissionEligibilityId,
            reason,
            startTime,
            status,
            statusUpdateTime);
    }
}

export class OneLoginDirectoryRoleAssignmentRequestActivationFailureData extends PermissionRequestActivationFailureData implements ITypeNameObject {
    constructor(
        errorMessage: Optional<string>,
        retryCount: number) {
        super(
            "OneLoginDirectoryRoleAssignmentRequestActivationFailureData",
            errorMessage,
            retryCount);
    }
}

export class OneLoginDirectoryRoleModel extends OneLoginDirectoryPrincipalModel implements ITypeNameObject, IEntityModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        public userIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OneLoginDirectoryRoleModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown);
    }
}

export class OneLoginDirectoryUser extends OneLoginDirectoryPrincipal implements ITypeNameObject, IAwsOriginatorIdentity, IDirectoryUser, IPermissionManagementUser {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        rawId: number,
        creationTime: Optional<string>,
        public applicationIds: string[],
        public company: Optional<string>,
        public customRawAttributeMap: Dictionary<string>,
        public department: Optional<string>,
        public firstName: Optional<string>,
        public groupRawId: Optional<number>,
        public lastName: Optional<string>,
        public mail: Optional<string>,
        public managerRawId: Optional<number>,
        public passwordUpdateTime: Optional<string>,
        public provisioningState: OneLoginDirectoryUserProvisioningState,
        public rawMail: Optional<string>,
        public roleRawIds: number[],
        public samName: Optional<string>,
        public status: OneLoginDirectoryUserStatus,
        public title: Optional<string>,
        public userName: Optional<string>,
        public userPrincipalName: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OneLoginDirectoryUser",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            rawId,
            creationTime);
    }
}

export class OneLoginDirectoryUserModel extends OneLoginDirectoryPrincipalModel implements ITypeNameObject, IUdmObjectModel, IAwsFederationUserModel, IPermissionManagementUserModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        public awsRoleIds: string[],
        public groupId: Optional<string>,
        public managerId: Optional<string>,
        public roleIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OneLoginDirectoryUserModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown);
    }
}

export class OpContainerImage extends OpResource implements ITypeNameObject, IResource, IContainerImage {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        public data: ContainerImageData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpContainerImage",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime);
    }
}

export class OpContainerImageModel extends OpResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IContainerImageModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        public kubernetesWorkloadResourceIds: string[],
        public operatingSystemReleaseNotesUrl: Optional<string>,
        public operatingSystemSupported: Optional<boolean>,
        public operatingSystemType: Optional<OperatingSystemType>,
        public repositoryIdReference: string,
        public virtualMachineIds: string[],
        public vulnerabilitySeveritiesSortValue: string,
        public workloadAnalysisOperatingSystem: Optional<WorkloadResourceScanOperatingSystem>,
        public workloadAnalysisOperatingSystemEndOfLifeDate: Optional<string>,
        public workloadAnalysisOperatingSystemExtendedSupport: Optional<boolean>,
        public workloadClusterResourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpContainerImageModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class OpContainerImageModelFilters extends OpResourceModelFilters implements IEntityModelFilters, IContainerImageFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        openRelatedEntityRiskTypeIdentifierItems: FilterItems<string>,
        regionIdItems: FilterItems<string>,
        tenantIdItems: FilterItems<string>,
        typeNameItems: FilterItems<string>,
        creationTimeRange: FilterTimeRange,
        public containerImageCreationTimeRange: FilterTimeRange,
        public scanTimeRange: FilterTimeRange) {
        super(
            attributeValueItems,
            openRelatedEntityRiskTypeIdentifierItems,
            regionIdItems,
            tenantIdItems,
            typeNameItems,
            creationTimeRange);
    }
}

export class OpenShiftBuildConfigData extends KubernetesNamespaceResourceData implements ITypeNameObject, IKubernetesResourceData {
    constructor(
        annotationKeyToValueMap: Dictionary<string>,
        controllerResourceReference: Optional<KubernetesResourceReference>,
        name: string,
        path: string,
        rawId: string,
        rawYaml: Optional<string>,
        namespaceId: string,
        namespaceName: string) {
        super(
            "OpenShiftBuildConfigData",
            annotationKeyToValueMap,
            controllerResourceReference,
            name,
            path,
            rawId,
            rawYaml,
            namespaceId,
            namespaceName);
    }
}

export class OpenShiftCatalogSourceData extends KubernetesNamespaceResourceData implements ITypeNameObject, IKubernetesResourceData {
    constructor(
        annotationKeyToValueMap: Dictionary<string>,
        controllerResourceReference: Optional<KubernetesResourceReference>,
        name: string,
        path: string,
        rawId: string,
        rawYaml: Optional<string>,
        namespaceId: string,
        namespaceName: string) {
        super(
            "OpenShiftCatalogSourceData",
            annotationKeyToValueMap,
            controllerResourceReference,
            name,
            path,
            rawId,
            rawYaml,
            namespaceId,
            namespaceName);
    }
}

export class OpenShiftDeploymentConfigData extends KubernetesWorkloadResourceData implements ITypeNameObject, IKubernetesResourceData {
    constructor(
        annotationKeyToValueMap: Dictionary<string>,
        controllerResourceReference: Optional<KubernetesResourceReference>,
        name: string,
        path: string,
        rawId: string,
        rawYaml: Optional<string>,
        namespaceId: string,
        namespaceName: string,
        podTemplateSpec: KubernetesPodTemplateSpec) {
        super(
            "OpenShiftDeploymentConfigData",
            annotationKeyToValueMap,
            controllerResourceReference,
            name,
            path,
            rawId,
            rawYaml,
            namespaceId,
            namespaceName,
            podTemplateSpec);
    }
}

export class OpenShiftImageStreamData extends KubernetesNamespaceResourceData implements ITypeNameObject, IKubernetesResourceData {
    constructor(
        annotationKeyToValueMap: Dictionary<string>,
        controllerResourceReference: Optional<KubernetesResourceReference>,
        name: string,
        path: string,
        rawId: string,
        rawYaml: Optional<string>,
        namespaceId: string,
        namespaceName: string) {
        super(
            "OpenShiftImageStreamData",
            annotationKeyToValueMap,
            controllerResourceReference,
            name,
            path,
            rawId,
            rawYaml,
            namespaceId,
            namespaceName);
    }
}

export class OpenShiftRouteData extends KubernetesNamespaceResourceData implements ITypeNameObject, IKubernetesResourceData {
    constructor(
        annotationKeyToValueMap: Dictionary<string>,
        controllerResourceReference: Optional<KubernetesResourceReference>,
        name: string,
        path: string,
        rawId: string,
        rawYaml: Optional<string>,
        namespaceId: string,
        namespaceName: string) {
        super(
            "OpenShiftRouteData",
            annotationKeyToValueMap,
            controllerResourceReference,
            name,
            path,
            rawId,
            rawYaml,
            namespaceId,
            namespaceName);
    }
}

export class OpenShiftTemplateData extends KubernetesNamespaceResourceData implements ITypeNameObject, IKubernetesResourceData {
    constructor(
        annotationKeyToValueMap: Dictionary<string>,
        controllerResourceReference: Optional<KubernetesResourceReference>,
        name: string,
        path: string,
        rawId: string,
        rawYaml: Optional<string>,
        namespaceId: string,
        namespaceName: string) {
        super(
            "OpenShiftTemplateData",
            annotationKeyToValueMap,
            controllerResourceReference,
            name,
            path,
            rawId,
            rawYaml,
            namespaceId,
            namespaceName);
    }
}

export class OpFolderConfiguration extends CloudProviderTenantFolderConfiguration implements ITypeNameObject, IChildScopeConfiguration, IOrganizationFolderConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        managed: boolean,
        name: string,
        parentScopeId: string,
        root: boolean,
        scopeSections: ScopeConfigurationSections,
        tenantType: Optional<TenantType>,
        organization: Optional<FolderConfigurationOrganization>,
        organizationExists: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpFolderConfiguration",
            managed,
            name,
            parentScopeId,
            root,
            scopeSections,
            tenantType,
            organization,
            organizationExists);
    }
}

export class OpKubernetesResourceBase extends OpResource implements ITypeNameObject, IKubernetesResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        public clusterDisplayReference: string,
        public clusterId: string,
        public data: KubernetesResourceData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime);
    }
}

export class OpKubernetesResourceModel extends OpResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class OpKubernetesUserModel extends OpKubernetesResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesResourceModel, IKubernetesPrincipalModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        public roleBindingIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpKubernetesUserModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class OpOpenShiftProjectModel extends OpKubernetesResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpOpenShiftProjectModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class OpOpenShiftSecurityContextConstraintModel extends OpKubernetesResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpOpenShiftSecurityContextConstraintModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class PackagesReportDefinitionConfiguration extends WorkloadAnalysisReportDefinitionConfiguration<WorkloadResourceScanPackageModel> implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        contentType: ReportContentType,
        exportReport: boolean,
        licenseType: ApplicationCustomerConfigurationLicensingLicenseType,
        name: Optional<string>,
        timeZoneInfoId: string,
        type: ReportType,
        filters: WorkloadControllerWorkloadAnalysisModelsFilters<WorkloadResourceScanPackageModel>,
        public includeVulnerabilityResolutionVersions: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "PackagesReportDefinitionConfiguration",
            contentType,
            exportReport,
            licenseType,
            name,
            timeZoneInfoId,
            type,
            filters);
    }
}

export class PackageVulnerabilitiesReportDefinitionConfiguration extends WorkloadAnalysisReportDefinitionConfiguration<WorkloadResourceScanPackageModel> implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        contentType: ReportContentType,
        exportReport: boolean,
        licenseType: ApplicationCustomerConfigurationLicensingLicenseType,
        name: Optional<string>,
        timeZoneInfoId: string,
        type: ReportType,
        filters: WorkloadControllerWorkloadAnalysisModelsFilters<WorkloadResourceScanPackageModel>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "PackageVulnerabilitiesReportDefinitionConfiguration",
            contentType,
            exportReport,
            licenseType,
            name,
            timeZoneInfoId,
            type,
            filters);
    }
}

export class PartialAnonymousIdentityModel extends PartialIdentityModel implements ITypeNameObject, IEntityModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "PartialAnonymousIdentityModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown);
    }
}

export class PartialIdentity extends PartialPrincipal implements ITypeNameObject, IPartialEntity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name);
    }
}

export class PartialUser extends PartialIdentity implements ITypeNameObject, IPartialEntity, IUser {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "PartialUser",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name);
    }
}

export class PermissionAssignmentRequest extends PermissionRequest implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        closeReason: Optional<PermissionRequestCloseReason>,
        expirationTime: Optional<string>,
        expirationTimeFrame: string,
        granteeUserTenantId: string,
        permissionEligibilityId: string,
        reason: Optional<string>,
        startTime: Optional<string>,
        status: PermissionRequestStatus,
        statusUpdateTime: string,
        public tenantIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            closeReason,
            expirationTime,
            expirationTimeFrame,
            granteeUserTenantId,
            permissionEligibilityId,
            reason,
            startTime,
            status,
            statusUpdateTime);
    }
}

export class PermissionAssignmentRequestActivationFailureData extends PermissionRequestActivationFailureData implements ITypeNameObject {
    constructor(
        typeName: string,
        errorMessage: Optional<string>,
        retryCount: number,
        public tenantIdToErrorMessageMap: Dictionary<string>) {
        super(
            typeName,
            errorMessage,
            retryCount);
    }
}

export class PermissionEligibilityAuditEvent extends PermissionManagementAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        public permissionEligibilityData: PermissionEligibilityAuditEventPermissionEligibilityData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime);
    }
}

export class PermissionEligibilityAuditEventGroupMembershipEligibilityData extends PermissionEligibilityAuditEventPermissionEligibilityData implements ITypeNameObject {
    constructor(
        expirationTimeFrame: string,
        name: string,
        principalTenantId: string,
        reasonRequired: boolean) {
        super(
            "PermissionEligibilityAuditEventGroupMembershipEligibilityData",
            expirationTimeFrame,
            name,
            principalTenantId,
            reasonRequired);
    }
}

export class PermissionEligibilityAuditEventModelAwsSsoPermissionSetAssignmentEligibilityData extends PermissionEligibilityAuditEventModelPermissionAssignmentEligibilityData {
    constructor(
        entityIds: string[],
        granteePrincipalIdReferences: string[],
        levelToApproverPrincipalIdReferencesMap: Dictionary<string[]>,
        public permitterIdReferences: string[]) {
        super(
            entityIds,
            granteePrincipalIdReferences,
            levelToApproverPrincipalIdReferencesMap);
    }
}

export class PermissionEligibilityAuditEventModelAzureRoleAssignmentEligibilityData extends PermissionEligibilityAuditEventModelPermissionAssignmentEligibilityData {
    constructor(
        entityIds: string[],
        granteePrincipalIdReferences: string[],
        levelToApproverPrincipalIdReferencesMap: Dictionary<string[]>,
        public roleDefinitionIdReferences: string[]) {
        super(
            entityIds,
            granteePrincipalIdReferences,
            levelToApproverPrincipalIdReferencesMap);
    }
}

export class PermissionEligibilityAuditEventModelGcpRoleBindingEligibilityData extends PermissionEligibilityAuditEventModelPermissionAssignmentEligibilityData {
    constructor(
        entityIds: string[],
        granteePrincipalIdReferences: string[],
        levelToApproverPrincipalIdReferencesMap: Dictionary<string[]>,
        public roleIdReferences: string[]) {
        super(
            entityIds,
            granteePrincipalIdReferences,
            levelToApproverPrincipalIdReferencesMap);
    }
}

export class PermissionEligibilityAuditEventOneLoginDirectoryRoleAssignmentEligibilityData extends PermissionEligibilityAuditEventPermissionEligibilityData implements ITypeNameObject, IPermissionEligibilityAuditEventPermissionAssignmentEligibilityData {
    constructor(
        expirationTimeFrame: string,
        name: string,
        principalTenantId: string,
        reasonRequired: boolean) {
        super(
            "PermissionEligibilityAuditEventOneLoginDirectoryRoleAssignmentEligibilityData",
            expirationTimeFrame,
            name,
            principalTenantId,
            reasonRequired);
    }
}

export class PermissionEligibilityAuditEventPermissionAssignmentEligibilityData extends PermissionEligibilityAuditEventPermissionEligibilityData implements ITypeNameObject, IPermissionEligibilityAuditEventPermissionAssignmentEligibilityData {
    constructor(
        typeName: string,
        expirationTimeFrame: string,
        name: string,
        principalTenantId: string,
        reasonRequired: boolean,
        public permissionRequestScopePaths: string[]) {
        super(
            typeName,
            expirationTimeFrame,
            name,
            principalTenantId,
            reasonRequired);
    }
}

export class PermissionEligibilityConfiguration extends ScopeSystemEntityConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        public approval: Optional<PermissionEligibilityConfigurationApproval>,
        public description: Optional<string>,
        public expirationTimeFrame: string,
        public name: string,
        public principalTenantId: string,
        public reasonRequired: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId);
    }
}

export class PermissionEligibilityCreationAuditEvent extends PermissionEligibilityAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        permissionEligibilityData: PermissionEligibilityAuditEventPermissionEligibilityData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "PermissionEligibilityCreationAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            permissionEligibilityData);
    }
}

export class PermissionEligibilityDeletionAuditEvent extends PermissionEligibilityAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        permissionEligibilityData: PermissionEligibilityAuditEventPermissionEligibilityData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "PermissionEligibilityDeletionAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            permissionEligibilityData);
    }
}

export class PermissionEligibilityModel extends ScopeSystemEntityModel implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        public configuration: PermissionEligibilityConfiguration,
        scopeId: string,
        state: Optional<SystemEntityState>,
        public approverPrincipalIdReferences: string[],
        public existingGranteePrincipalIds: string[],
        public granteePrincipalIdReferences: string[],
        public levelToApproverPrincipalIdReferencesMap: Dictionary<string[]>,
        public levelToExistingApproverPrincipalIdsMap: Dictionary<string[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            configuration,
            scopeId,
            state);
    }
}

export class PermissionEligibilityUpdateAuditEvent extends PermissionEligibilityAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        permissionEligibilityData: PermissionEligibilityAuditEventPermissionEligibilityData,
        public permissionEligibilityChanges: PermissionEligibilityUpdateAuditEventPermissionEligibilityChanges) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "PermissionEligibilityUpdateAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            permissionEligibilityData);
    }
}

export class PermissionEligibilityUpdateAuditEventGroupMembershipEligibilityChanges extends PermissionEligibilityUpdateAuditEventPermissionEligibilityChanges implements ITypeNameObject {
    constructor(
        approvalRequiredChanged: boolean,
        expirationTimeFrameChanged: boolean,
        granteePrincipalsChanged: boolean,
        levelToApproverPrincipalsMapChanged: boolean,
        nameChanged: boolean,
        principalTenantChanged: boolean,
        reasonRequiredChanged: boolean,
        public groupsChanged: boolean) {
        super(
            "PermissionEligibilityUpdateAuditEventGroupMembershipEligibilityChanges",
            approvalRequiredChanged,
            expirationTimeFrameChanged,
            granteePrincipalsChanged,
            levelToApproverPrincipalsMapChanged,
            nameChanged,
            principalTenantChanged,
            reasonRequiredChanged);
    }
}

export class PermissionEligibilityUpdateAuditEventOneLoginDirectoryRoleAssignmentEligibilityChanges extends PermissionEligibilityUpdateAuditEventPermissionEligibilityChanges implements ITypeNameObject {
    constructor(
        approvalRequiredChanged: boolean,
        expirationTimeFrameChanged: boolean,
        granteePrincipalsChanged: boolean,
        levelToApproverPrincipalsMapChanged: boolean,
        nameChanged: boolean,
        principalTenantChanged: boolean,
        reasonRequiredChanged: boolean,
        public rolesChanged: boolean) {
        super(
            "PermissionEligibilityUpdateAuditEventOneLoginDirectoryRoleAssignmentEligibilityChanges",
            approvalRequiredChanged,
            expirationTimeFrameChanged,
            granteePrincipalsChanged,
            levelToApproverPrincipalsMapChanged,
            nameChanged,
            principalTenantChanged,
            reasonRequiredChanged);
    }
}

export class PermissionEligibilityUpdateAuditEventPermissionAssignmentEligibilityChanges extends PermissionEligibilityUpdateAuditEventPermissionEligibilityChanges implements ITypeNameObject {
    constructor(
        typeName: string,
        approvalRequiredChanged: boolean,
        expirationTimeFrameChanged: boolean,
        granteePrincipalsChanged: boolean,
        levelToApproverPrincipalsMapChanged: boolean,
        nameChanged: boolean,
        principalTenantChanged: boolean,
        reasonRequiredChanged: boolean,
        public permissionRequestScopesChanged: boolean) {
        super(
            typeName,
            approvalRequiredChanged,
            expirationTimeFrameChanged,
            granteePrincipalsChanged,
            levelToApproverPrincipalsMapChanged,
            nameChanged,
            principalTenantChanged,
            reasonRequiredChanged);
    }
}

export class PermissionManagementControllerGetAadPermissionEligibilityModelPageRequest extends PermissionManagementControllerGetGroupMembershipPermissionEligibilityModelPageRequest {
    constructor(
        filters: PermissionManagementControllerGetPermissionEligibilityModelPageRequestFilters,
        limit: number,
        scope: PermissionManagementControllerPermissionEligibilityRequestScope,
        skip: number,
        sort: Optional<PermissionManagementControllerGetPermissionEligibilityModelPageRequestSort>) {
        super(
            filters,
            limit,
            scope,
            skip,
            sort);
    }
}

export class PermissionManagementControllerGetAwsPermissionEligibilityModelPageRequest extends PermissionManagementControllerGetPermissionAssignmentEligibilityModelPageRequest {
    constructor(
        filters: PermissionManagementControllerGetPermissionEligibilityModelPageRequestFilters,
        limit: number,
        scope: PermissionManagementControllerPermissionEligibilityRequestScope,
        skip: number,
        sort: Optional<PermissionManagementControllerGetPermissionEligibilityModelPageRequestSort>) {
        super(
            filters,
            limit,
            scope,
            skip,
            sort);
    }
}

export class PermissionManagementControllerGetAzurePermissionEligibilityModelPageRequest extends PermissionManagementControllerGetPermissionAssignmentEligibilityModelPageRequest {
    constructor(
        filters: PermissionManagementControllerGetPermissionEligibilityModelPageRequestFilters,
        limit: number,
        scope: PermissionManagementControllerPermissionEligibilityRequestScope,
        skip: number,
        sort: Optional<PermissionManagementControllerGetPermissionEligibilityModelPageRequestSort>) {
        super(
            filters,
            limit,
            scope,
            skip,
            sort);
    }
}

export class PermissionManagementControllerGetGciPermissionEligibilityModelPageRequest extends PermissionManagementControllerGetGroupMembershipPermissionEligibilityModelPageRequest {
    constructor(
        filters: PermissionManagementControllerGetPermissionEligibilityModelPageRequestFilters,
        limit: number,
        scope: PermissionManagementControllerPermissionEligibilityRequestScope,
        skip: number,
        sort: Optional<PermissionManagementControllerGetPermissionEligibilityModelPageRequestSort>) {
        super(
            filters,
            limit,
            scope,
            skip,
            sort);
    }
}

export class PermissionManagementControllerGetGcpPermissionEligibilityModelPageRequest extends PermissionManagementControllerGetPermissionAssignmentEligibilityModelPageRequest {
    constructor(
        filters: PermissionManagementControllerGetPermissionEligibilityModelPageRequestFilters,
        limit: number,
        scope: PermissionManagementControllerPermissionEligibilityRequestScope,
        skip: number,
        sort: Optional<PermissionManagementControllerGetPermissionEligibilityModelPageRequestSort>) {
        super(
            filters,
            limit,
            scope,
            skip,
            sort);
    }
}

export class PermissionManagementEntityAttribute extends BuiltInEntityAttribute implements ITypeNameObject {
    constructor(
        public type: PermissionManagementEntityAttributeType) {
        super("PermissionManagementEntityAttribute");
    }
}

export class PermissionRequestActivationAuditEvent extends PermissionRequestAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        permissionRequestData: PermissionRequestAuditEventPermissionRequestData,
        public tenantIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "PermissionRequestActivationAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            permissionRequestData);
    }
}

export class PermissionRequestActivationFailureAuditEvent extends PermissionRequestAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        permissionRequestData: PermissionRequestAuditEventPermissionRequestData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "PermissionRequestActivationFailureAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            permissionRequestData);
    }
}

export class PermissionRequestApprovalAuditEvent extends PermissionRequestAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        permissionRequestData: PermissionRequestAuditEventPermissionRequestData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "PermissionRequestApprovalAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime,
            permissionRequestData);
    }
}

export class PermissionRequestAuditEventGroupMembershipRequestActivationFailureData extends PermissionRequestAuditEventPermissionRequestActivationFailureData implements ITypeNameObject {
    constructor(
        errorMessage: Optional<string>) {
        super(
            "PermissionRequestAuditEventGroupMembershipRequestActivationFailureData",
            errorMessage);
    }
}

export class PermissionRequestAuditEventOneLoginDirectoryRoleAssignmentRequestActivationFailureData extends PermissionRequestAuditEventPermissionRequestActivationFailureData implements ITypeNameObject {
    constructor(
        errorMessage: Optional<string>) {
        super(
            "PermissionRequestAuditEventOneLoginDirectoryRoleAssignmentRequestActivationFailureData",
            errorMessage);
    }
}

export class PermissionRequestAuditEventPermissionAssignmentRequestActivationFailureData extends PermissionRequestAuditEventPermissionRequestActivationFailureData implements ITypeNameObject {
    constructor(
        errorMessage: Optional<string>,
        public tenantDisplayReferenceToDataMap: Dictionary<PermissionRequestAuditEventPermissionAssignmentRequestActivationFailureDataTenantData>) {
        super(
            "PermissionRequestAuditEventPermissionAssignmentRequestActivationFailureData",
            errorMessage);
    }
}

export class PermissionRequestAuditEventPermissionAssignmentRequestData extends PermissionRequestAuditEventPermissionRequestData implements ITypeNameObject {
    constructor(
        approvalRequired: boolean,
        expirationTimeFrame: string,
        granteeUserTenantId: string,
        id: Optional<string>,
        permissionEligibilityName: string,
        reason: Optional<string>,
        startTime: Optional<string>,
        public tenantDisplayReferenceToNameMap: Dictionary<string>) {
        super(
            "PermissionRequestAuditEventPermissionAssignmentRequestData",
            approvalRequired,
            expirationTimeFrame,
            granteeUserTenantId,
            id,
            permissionEligibilityName,
            reason,
            startTime);
    }
}

export class PingIdentityDirectoryEntity extends PingIdentityEntity implements ITypeNameObject, IDirectoryEntity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        rawId: string,
        public creationTime: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            rawId);
    }
}

export class PingIdentityDirectoryEntityModel extends PingIdentityEntityModel implements ITypeNameObject, IEntityModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown);
    }
}

export class PingIdentityDirectoryGroupMembershipRequest extends GroupMembershipRequest implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        closeReason: Optional<PermissionRequestCloseReason>,
        expirationTime: Optional<string>,
        expirationTimeFrame: string,
        granteeUserTenantId: string,
        permissionEligibilityId: string,
        reason: Optional<string>,
        startTime: Optional<string>,
        status: PermissionRequestStatus,
        statusUpdateTime: string,
        assignedGroupIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "PingIdentityDirectoryGroupMembershipRequest",
            scopeId,
            closeReason,
            expirationTime,
            expirationTimeFrame,
            granteeUserTenantId,
            permissionEligibilityId,
            reason,
            startTime,
            status,
            statusUpdateTime,
            assignedGroupIds);
    }
}

export class PingIdentityDirectoryPrincipal extends PingIdentityDirectoryEntity implements ITypeNameObject, IDirectoryEntity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        rawId: string,
        creationTime: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            rawId,
            creationTime);
    }
}

export class PingIdentityDirectoryPrincipalModel extends PingIdentityDirectoryEntityModel implements ITypeNameObject, IEntityModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown);
    }
}

export class PingIdentityDirectoryUser extends PingIdentityDirectoryPrincipal implements ITypeNameObject, IAwsOriginatorIdentity, IDirectoryUser, IPermissionManagementUser {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        rawId: string,
        creationTime: Optional<string>,
        public customRawAttributeMap: Dictionary<string[]>,
        public firstName: Optional<string>,
        public groupIdToMembershipTypeMap: Dictionary<PingIdentityDirectoryUserGroupMembershipType>,
        public lastName: Optional<string>,
        public mail: Optional<string>,
        public mfaEnabled: Optional<boolean>,
        public signInTime: Optional<string>,
        public status: PingIdentityDirectoryUserStatus,
        public title: Optional<string>,
        public updateTime: string,
        public userName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "PingIdentityDirectoryUser",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            rawId,
            creationTime);
    }
}

export class PingIdentityDirectoryUserModel extends PingIdentityDirectoryPrincipalModel implements ITypeNameObject, IUdmObjectModel, IAwsFederationUserModel, IPermissionManagementUserModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        public awsRoleIds: string[],
        public groupIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "PingIdentityDirectoryUserModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown);
    }
}

export class ProjectConfigurationRuleAzureResourceGroupNamePatternEntityCondition extends ProjectConfigurationRuleEntityCondition implements ITypeNameObject {
    constructor(
        not: boolean,
        public caseSensitive: boolean,
        public resourceGroupNamePattern: string) {
        super(
            "ProjectConfigurationRuleAzureResourceGroupNamePatternEntityCondition",
            not);
    }
}

export class ProjectConfigurationRuleNamePatternTenantCondition extends ProjectConfigurationRuleTenantCondition implements ITypeNameObject {
    constructor(
        not: boolean,
        public caseSensitive: boolean,
        public namePattern: string) {
        super(
            "ProjectConfigurationRuleNamePatternTenantCondition",
            not);
    }
}

export class ProjectConfigurationRuleTagPatternTenantCondition extends ProjectConfigurationRuleTenantCondition implements ITypeNameObject {
    constructor(
        not: boolean,
        public tagPattern: ResourceTagPattern) {
        super(
            "ProjectConfigurationRuleTagPatternTenantCondition",
            not);
    }
}

export class PublicEntityAttribute extends BuiltInEntityAttribute implements ITypeNameObject {
    constructor(
        public access: boolean,
        public network: boolean) {
        super("PublicEntityAttribute");
    }
}

export class PublicEntityRisk extends EntityRisk implements ITypeNameObject, IPublicRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class QRadarServerConfiguration extends IntegrationEntityConfiguration implements IDocument, ITypeNameObject, IEndpointIntegrationEntityConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        type: IntegrationType,
        public endpoint: Optional<string>,
        public endpointConnectorId: Optional<string>,
        public name: string,
        public serverCertificateValidationEnabled: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "QRadarServerConfiguration",
            scopeId,
            type);
    }
}

export class RelatedPublicComputeAttribute extends BuiltInEntityAttribute implements ITypeNameObject {
    constructor() {
        super("RelatedPublicComputeAttribute");
    }
}

export class RelatedWorkloadResourceVulnerabilityAttribute extends BuiltInEntityAttribute implements ITypeNameObject {
    constructor() {
        super("RelatedWorkloadResourceVulnerabilityAttribute");
    }
}

export class Report extends ReportBase implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        definitionId: string,
        deliveries: Delivery[],
        fileName: string,
        scheduleId: Optional<string>,
        sendTime: Optional<string>,
        size: number) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "Report",
            scopeId,
            definitionId,
            deliveries,
            fileName,
            scheduleId,
            sendTime,
            size);
    }
}

export class ReportControllerBuiltInComplianceReportRequestDefinition extends ReportControllerComplianceReportRequestDefinition implements ITypeNameObject {
    constructor(
        name: Optional<string>,
        timeZoneInfoId: Optional<string>,
        typeName: string,
        contentType: ReportContentType,
        public sectionType: BuiltInComplianceSectionType) {
        super(
            name,
            timeZoneInfoId,
            typeName,
            contentType);
    }
}

export class ReportScheduleConfiguration extends ScopeSystemEntityConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        public cadence: ReportScheduleCadence,
        public definitionId: string,
        public deliveries: Delivery[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "ReportScheduleConfiguration",
            scopeId);
    }
}

export class ResourceTagSearchableData extends SearchableData<ResourceTag> {
    constructor() {
        super();
    }
}

export class RiskAutomationRuleConfiguration extends AutomationRuleConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        deliveries: DeliveryWrapper[],
        description: Optional<string>,
        name: string,
        scopeIds: string[],
        public builtInEntityAttributeTypeNames: Optional<string[]>,
        public customEntityAttributeDefinitionIds: Optional<string[]>,
        public customRiskPolicyIds: Optional<string[]>,
        public entityAttributeValues: Optional<string[]>,
        public minSeverity: Severity,
        public riskPolicyConfigurationTypeNames: Optional<string[]>,
        public riskSubStatuses: RiskSubStatus[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "RiskAutomationRuleConfiguration",
            scopeId,
            deliveries,
            description,
            name,
            scopeIds);
    }
}

export class RiskControllerCloudRiskModelFilters extends RiskControllerRiskModelFilters implements ITypeNameObject {
    constructor(
        categorySelection: Optional<ItemSelection<RiskPolicyCategory>>,
        complianceIdentifierSelection: Optional<ItemSelection<string>>,
        ignoreExpirationTimeRangeSelection: Optional<TimeRangeSelection>,
        openStatusUpdateTimeRangeSelection: Optional<TimeRangeSelection>,
        riskPolicyIdentifierSelection: Optional<ItemSelection<string>>,
        severitySelection: Optional<ItemSelection<Severity>>,
        starred: Optional<boolean>,
        statusSelection: Optional<ItemSelection<RiskStatus>>,
        statusUpdateTimeRangeSelection: Optional<TimeRangeSelection>,
        subStatusSelection: Optional<ItemSelection<RiskSubStatus>>,
        systemCreationTimeRangeSelection: Optional<TimeRangeSelection>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        ticketingServiceTypeSelection: Optional<ItemSelection<TicketingServiceType>>,
        public entityAttributeValueSelection: Optional<ItemSelection<string>>,
        public relatedEntityId: Optional<string>,
        public riskedEntityEnvironmentValueSelection: Optional<PagedItemSelection<string>>,
        public riskedEntityIdSelection: Optional<PagedItemSelection<string>>,
        public riskedEntityOwnerValueSelection: Optional<PagedItemSelection<string>>) {
        super(
            "RiskControllerCloudRiskModelFilters",
            categorySelection,
            complianceIdentifierSelection,
            ignoreExpirationTimeRangeSelection,
            openStatusUpdateTimeRangeSelection,
            riskPolicyIdentifierSelection,
            severitySelection,
            starred,
            statusSelection,
            statusUpdateTimeRangeSelection,
            subStatusSelection,
            systemCreationTimeRangeSelection,
            tenantIdSelection,
            ticketingServiceTypeSelection);
    }
}

export class RiskControllerCloudRiskPolicyTypeFilters extends RiskControllerRiskPolicyTypeFilters implements ITypeNameObject {
    constructor(
        typeName: string,
        riskPolicyConfigurationTypeName: string,
        public riskedEntityIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            typeName,
            riskPolicyConfigurationTypeName);
    }
}

export class RiskControllerCodeRiskModelFilters extends RiskControllerRiskModelFilters implements ITypeNameObject {
    constructor(
        categorySelection: Optional<ItemSelection<RiskPolicyCategory>>,
        complianceIdentifierSelection: Optional<ItemSelection<string>>,
        ignoreExpirationTimeRangeSelection: Optional<TimeRangeSelection>,
        openStatusUpdateTimeRangeSelection: Optional<TimeRangeSelection>,
        riskPolicyIdentifierSelection: Optional<ItemSelection<string>>,
        severitySelection: Optional<ItemSelection<Severity>>,
        starred: Optional<boolean>,
        statusSelection: Optional<ItemSelection<RiskStatus>>,
        statusUpdateTimeRangeSelection: Optional<TimeRangeSelection>,
        subStatusSelection: Optional<ItemSelection<RiskSubStatus>>,
        systemCreationTimeRangeSelection: Optional<TimeRangeSelection>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        ticketingServiceTypeSelection: Optional<ItemSelection<TicketingServiceType>>,
        public cloudTenantTypeSelection: Optional<ItemSelection<TenantType>>,
        public codeTypeSelection: Optional<ItemSelection<CodeType>>,
        public fileRelativePathSelection: Optional<PagedItemSelection<string>>,
        public resourceNameSelection: Optional<PagedItemSelection<string>>,
        public resourceTypeSystemNameSelection: Optional<PagedItemSelection<string>>) {
        super(
            "RiskControllerCodeRiskModelFilters",
            categorySelection,
            complianceIdentifierSelection,
            ignoreExpirationTimeRangeSelection,
            openStatusUpdateTimeRangeSelection,
            riskPolicyIdentifierSelection,
            severitySelection,
            starred,
            statusSelection,
            statusUpdateTimeRangeSelection,
            subStatusSelection,
            systemCreationTimeRangeSelection,
            tenantIdSelection,
            ticketingServiceTypeSelection);
    }
}

export class RiskControllerCodeRiskPolicyTypeFilters extends RiskControllerRiskPolicyTypeFilters implements ITypeNameObject {
    constructor(
        riskPolicyConfigurationTypeName: string) {
        super(
            "RiskControllerCodeRiskPolicyTypeFilters",
            riskPolicyConfigurationTypeName);
    }
}

export class RiskControllerCustomRiskPolicyTypeFilters extends RiskControllerCloudRiskPolicyTypeFilters implements ITypeNameObject {
    constructor(
        riskPolicyConfigurationTypeName: string,
        riskedEntityIdSelection: Optional<PagedItemSelection<string>>,
        public policyIdSelection: Optional<ItemSelection<string>>) {
        super(
            "RiskControllerCustomRiskPolicyTypeFilters",
            riskPolicyConfigurationTypeName,
            riskedEntityIdSelection);
    }
}

export class RiskControllerGciDirectoryGroupInactiveRiskPolicyTypeFilters extends RiskControllerCloudRiskPolicyTypeFilters implements ITypeNameObject {
    constructor(
        riskPolicyConfigurationTypeName: string,
        riskedEntityIdSelection: Optional<PagedItemSelection<string>>,
        public groupIdentityExists: Optional<boolean>) {
        super(
            "RiskControllerGciDirectoryGroupInactiveRiskPolicyTypeFilters",
            riskPolicyConfigurationTypeName,
            riskedEntityIdSelection);
    }
}

export class RiskControllerGcpBehaviorIdentityRiskPolicyTypeFilters extends RiskControllerCloudRiskPolicyTypeFilters implements ITypeNameObject {
    constructor(
        riskPolicyConfigurationTypeName: string,
        riskedEntityIdSelection: Optional<PagedItemSelection<string>>,
        public eventActionSelection: Optional<ItemSelection<string>>,
        public resourceIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            "RiskControllerGcpBehaviorIdentityRiskPolicyTypeFilters",
            riskPolicyConfigurationTypeName,
            riskedEntityIdSelection);
    }
}

export class RiskControllerGetCloudRiskPolicyModelsRequest extends RiskControllerGetRiskPolicyModelsRequest {
    constructor(
        ids: Optional<string[]>) {
        super(ids);
    }
}

export class RiskControllerGetCodeRiskPolicyModelsRequest extends RiskControllerGetRiskPolicyModelsRequest {
    constructor(
        ids: Optional<string[]>) {
        super(ids);
    }
}

export class RiskControllerGetRiskPoliciesResponseCloudBuiltInPolicy extends RiskControllerGetRiskPoliciesResponseCloudPolicy {
    constructor(
        enabled: boolean,
        failedTenantCount: number,
        riskCount: number,
        riskPolicyConfigurationTypeName: string,
        customComplianceIds: string[],
        public enabledInherited: boolean) {
        super(
            enabled,
            failedTenantCount,
            riskCount,
            riskPolicyConfigurationTypeName,
            customComplianceIds);
    }
}

export class RiskControllerGetRiskPoliciesResponseCloudCustomPolicy extends RiskControllerGetRiskPoliciesResponseCloudPolicy {
    constructor(
        enabled: boolean,
        failedTenantCount: number,
        riskCount: number,
        riskPolicyConfigurationTypeName: string,
        customComplianceIds: string[],
        public id: string,
        public scopeId: string) {
        super(
            enabled,
            failedTenantCount,
            riskCount,
            riskPolicyConfigurationTypeName,
            customComplianceIds);
    }
}

export class RiskControllerGetRiskPoliciesResponseKubernetesAdmissionControllerBuiltInPolicy extends RiskControllerGetRiskPoliciesResponseKubernetesAdmissionControllerPolicy {
    constructor(
        enabled: boolean,
        failedTenantCount: number,
        riskCount: number,
        riskPolicyConfigurationTypeName: string,
        public effect: KubernetesAdmissionControllerRiskPolicyEffect,
        public enabledInherited: boolean) {
        super(
            enabled,
            failedTenantCount,
            riskCount,
            riskPolicyConfigurationTypeName);
    }
}

export class RiskControllerInsertAwsEc2SecurityGroupAllowedInboundRuleRiskPolicyRequest extends RiskControllerInsertAwsEc2SecurityGroupInboundRuleRiskPolicyRequest {
    constructor(
        description: Optional<string>,
        name: string,
        scopeId: string,
        severity: Severity,
        allSecurityGroups: boolean,
        anyIpV4AddressSubnet: boolean,
        exactSubnets: Optional<string[]>,
        inboundAccessScope: Optional<NetworkAccessScope>,
        networkScopes: DestinationNetworkScope[],
        overlapSubnets: Optional<string[]>,
        securityGroupBuiltInAttributeTypeNames: Optional<string[]>,
        securityGroupCustomAttributeDefinitionIds: Optional<string[]>,
        securityGroupIds: Optional<string[]>,
        securityGroupTags: Optional<ResourceTag[]>) {
        super(
            description,
            name,
            scopeId,
            severity,
            allSecurityGroups,
            anyIpV4AddressSubnet,
            exactSubnets,
            inboundAccessScope,
            networkScopes,
            overlapSubnets,
            securityGroupBuiltInAttributeTypeNames,
            securityGroupCustomAttributeDefinitionIds,
            securityGroupIds,
            securityGroupTags);
    }
}

export class RiskControllerInsertAwsEc2SecurityGroupManagementRiskPolicyRequest extends RiskControllerInsertAwsResourceUsageRiskPolicyRequest {
    constructor(
        description: Optional<string>,
        name: string,
        scopeId: string,
        severity: Severity,
        allResources: Optional<boolean>,
        resourceBuiltInAttributeTypeNames: Optional<string[]>,
        resourceCustomAttributeDefinitionIds: Optional<string[]>,
        resourceIds: Optional<string[]>,
        resourceNamePattern: Optional<string>,
        resourceTags: Optional<ResourceTag[]>,
        public sensitiveSecurityGroup: boolean) {
        super(
            description,
            name,
            scopeId,
            severity,
            allResources,
            resourceBuiltInAttributeTypeNames,
            resourceCustomAttributeDefinitionIds,
            resourceIds,
            resourceNamePattern,
            resourceTags);
    }
}

export class RiskControllerInsertAwsIamPrincipalCreationRiskPolicyRequest extends RiskControllerInsertAwsResourceUsageRiskPolicyRequest {
    constructor(
        description: Optional<string>,
        name: string,
        scopeId: string,
        severity: Severity,
        allResources: Optional<boolean>,
        resourceBuiltInAttributeTypeNames: Optional<string[]>,
        resourceCustomAttributeDefinitionIds: Optional<string[]>,
        resourceIds: Optional<string[]>,
        resourceNamePattern: Optional<string>,
        resourceTags: Optional<ResourceTag[]>,
        public principalTypeNames: Optional<string[]>) {
        super(
            description,
            name,
            scopeId,
            severity,
            allResources,
            resourceBuiltInAttributeTypeNames,
            resourceCustomAttributeDefinitionIds,
            resourceIds,
            resourceNamePattern,
            resourceTags);
    }
}

export class RiskControllerInsertAwsIamRoleManagementRiskPolicyRequest extends RiskControllerInsertAwsResourceUsageRiskPolicyRequest {
    constructor(
        description: Optional<string>,
        name: string,
        scopeId: string,
        severity: Severity,
        allResources: Optional<boolean>,
        resourceBuiltInAttributeTypeNames: Optional<string[]>,
        resourceCustomAttributeDefinitionIds: Optional<string[]>,
        resourceIds: Optional<string[]>,
        resourceNamePattern: Optional<string>,
        resourceTags: Optional<ResourceTag[]>,
        public criticalActionSeverityPermissionRole: boolean) {
        super(
            description,
            name,
            scopeId,
            severity,
            allResources,
            resourceBuiltInAttributeTypeNames,
            resourceCustomAttributeDefinitionIds,
            resourceIds,
            resourceNamePattern,
            resourceTags);
    }
}

export class RiskControllerInsertAwsKmsKeyManagementRiskPolicyRequest extends RiskControllerInsertAwsResourceUsageRiskPolicyRequest {
    constructor(
        description: Optional<string>,
        name: string,
        scopeId: string,
        severity: Severity,
        allResources: Optional<boolean>,
        resourceBuiltInAttributeTypeNames: Optional<string[]>,
        resourceCustomAttributeDefinitionIds: Optional<string[]>,
        resourceIds: Optional<string[]>,
        resourceNamePattern: Optional<string>,
        resourceTags: Optional<ResourceTag[]>,
        public sensitiveKey: boolean) {
        super(
            description,
            name,
            scopeId,
            severity,
            allResources,
            resourceBuiltInAttributeTypeNames,
            resourceCustomAttributeDefinitionIds,
            resourceIds,
            resourceNamePattern,
            resourceTags);
    }
}

export class RiskControllerInsertAwsPrincipalAllowedResourcePermissionRiskPolicyRequest extends RiskControllerInsertAwsPrincipalResourcePermissionRiskPolicyRequest {
    constructor(
        description: Optional<string>,
        name: string,
        scopeId: string,
        severity: Severity,
        actionNamePatterns: Optional<string[]>,
        actionRiskCategories: Optional<ActionRiskCategory[]>,
        actions: Optional<string[]>,
        excludePrincipalMatchConditions: EntityMatchCondition[],
        excludeResourceMatchConditions: EntityMatchCondition[],
        principalMatchConditions: EntityMatchCondition[],
        resourceMatchConditions: EntityMatchCondition[]) {
        super(
            description,
            name,
            scopeId,
            severity,
            actionNamePatterns,
            actionRiskCategories,
            actions,
            excludePrincipalMatchConditions,
            excludeResourceMatchConditions,
            principalMatchConditions,
            resourceMatchConditions);
    }
}

export class RiskControllerInsertAwsPrincipalNotAllowedResourcePermissionRiskPolicyRequest extends RiskControllerInsertAwsPrincipalResourcePermissionRiskPolicyRequest {
    constructor(
        description: Optional<string>,
        name: string,
        scopeId: string,
        severity: Severity,
        actionNamePatterns: Optional<string[]>,
        actionRiskCategories: Optional<ActionRiskCategory[]>,
        actions: Optional<string[]>,
        excludePrincipalMatchConditions: EntityMatchCondition[],
        excludeResourceMatchConditions: EntityMatchCondition[],
        principalMatchConditions: EntityMatchCondition[],
        resourceMatchConditions: EntityMatchCondition[]) {
        super(
            description,
            name,
            scopeId,
            severity,
            actionNamePatterns,
            actionRiskCategories,
            actions,
            excludePrincipalMatchConditions,
            excludeResourceMatchConditions,
            principalMatchConditions,
            resourceMatchConditions);
    }
}

export class RiskControllerInsertAzurePrincipalAllowedResourcePermissionRiskPolicyRequest extends RiskControllerInsertAzurePrincipalResourcePermissionRiskPolicyRequest {
    constructor(
        description: Optional<string>,
        name: string,
        scopeId: string,
        severity: Severity,
        actionNamePatterns: Optional<string[]>,
        actionRiskCategories: Optional<ActionRiskCategory[]>,
        actions: Optional<string[]>,
        principalBuiltInAttributeTypeNames: Optional<string[]>,
        principalIds: Optional<string[]>,
        resourceBuiltInAttributeTypeNames: Optional<string[]>,
        resourceCustomAttributeDefinitionIds: Optional<string[]>,
        resourceIds: Optional<string[]>,
        resourceTags: Optional<ResourceTag[]>) {
        super(
            description,
            name,
            scopeId,
            severity,
            actionNamePatterns,
            actionRiskCategories,
            actions,
            principalBuiltInAttributeTypeNames,
            principalIds,
            resourceBuiltInAttributeTypeNames,
            resourceCustomAttributeDefinitionIds,
            resourceIds,
            resourceTags);
    }
}

export class RiskControllerInsertAzurePrincipalNotAllowedResourcePermissionRiskPolicyRequest extends RiskControllerInsertAzurePrincipalResourcePermissionRiskPolicyRequest {
    constructor(
        description: Optional<string>,
        name: string,
        scopeId: string,
        severity: Severity,
        actionNamePatterns: Optional<string[]>,
        actionRiskCategories: Optional<ActionRiskCategory[]>,
        actions: Optional<string[]>,
        principalBuiltInAttributeTypeNames: Optional<string[]>,
        principalIds: Optional<string[]>,
        resourceBuiltInAttributeTypeNames: Optional<string[]>,
        resourceCustomAttributeDefinitionIds: Optional<string[]>,
        resourceIds: Optional<string[]>,
        resourceTags: Optional<ResourceTag[]>,
        public allPrincipals: boolean) {
        super(
            description,
            name,
            scopeId,
            severity,
            actionNamePatterns,
            actionRiskCategories,
            actions,
            principalBuiltInAttributeTypeNames,
            principalIds,
            resourceBuiltInAttributeTypeNames,
            resourceCustomAttributeDefinitionIds,
            resourceIds,
            resourceTags);
    }
}

export class RiskControllerNetworkInboundExternalResourceRiskPolicyTypeFilters extends RiskControllerCloudRiskPolicyTypeFilters implements ITypeNameObject {
    constructor(
        riskPolicyConfigurationTypeName: string,
        riskedEntityIdSelection: Optional<PagedItemSelection<string>>,
        public destinationNetworkScopeFilterIdSelection: Optional<ItemSelection<string>>,
        public sourceSubnetSelection: Optional<PagedItemSelection<string>>) {
        super(
            "RiskControllerNetworkInboundExternalResourceRiskPolicyTypeFilters",
            riskPolicyConfigurationTypeName,
            riskedEntityIdSelection);
    }
}

export class RiskControllerRequestAwsEcsTaskDefinitionEnvironmentVariableSecretExistsRiskPolicyGroupFilters extends RiskControllerCloudRiskPolicyTypeFilters implements ITypeNameObject {
    constructor(
        riskPolicyConfigurationTypeName: string,
        riskedEntityIdSelection: Optional<PagedItemSelection<string>>,
        public taskDefinitionRegionSystemNameSelection: Optional<ItemSelection<string>>,
        public taskDefinitionStatusSelection: Optional<ItemSelection<AwsEcsTaskDefinitionStatus>>) {
        super(
            "RiskControllerRequestAwsEcsTaskDefinitionEnvironmentVariableSecretExistsRiskPolicyGroupFilters",
            riskPolicyConfigurationTypeName,
            riskedEntityIdSelection);
    }
}

export class RiskPolicyConfiguration extends ScopeSystemEntityConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        public codeEnabled: Optional<boolean>,
        public codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        public creationTime: string,
        public enabled: Optional<boolean>,
        public entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        public entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId);
    }
}

export class RiskPolicyConfigurationEntityExclusionAwsResourceArnPattern extends RiskPolicyConfigurationEntityExclusionEntityPropertyPattern implements ITypeNameObject {
    constructor(
        data: RiskPolicyConfigurationExclusionData,
        public arnPattern: string) {
        super(
            "RiskPolicyConfigurationEntityExclusionAwsResourceArnPattern",
            data);
    }
}

export class RiskPolicyConfigurationEntityExclusionEntityNamePattern extends RiskPolicyConfigurationEntityExclusionEntityPropertyPattern implements ITypeNameObject {
    constructor(
        data: RiskPolicyConfigurationExclusionData,
        public namePattern: string) {
        super(
            "RiskPolicyConfigurationEntityExclusionEntityNamePattern",
            data);
    }
}

export class ScopeSettingConfiguration extends ScopeSystemEntityConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId);
    }
}

export class SensitiveResourceAttribute extends BuiltInEntityAttribute implements ITypeNameObject {
    constructor() {
        super("SensitiveResourceAttribute");
    }
}

export class SensitiveResourcePermissionActionPrincipalAttribute extends BuiltInEntityAttribute implements ITypeNameObject {
    constructor(
        public excessive: boolean) {
        super("SensitiveResourcePermissionActionPrincipalAttribute");
    }
}

export class ServiceConfiguration extends SystemConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName);
    }
}

export class ServiceIdentityModelAccess extends IdentityModelAccess implements ITypeNameObject {
    constructor(
        activity: Activity,
        permissions: PrincipalModelAccessPermissions,
        riskCategory: Optional<PrincipalRiskCategory>,
        risks: PrincipalModelAccessRisks,
        scopesRisks: PrincipalModelAccessScopesRisk[],
        types: PrincipalType[],
        public originatorEntityIds: string[]) {
        super(
            "ServiceIdentityModelAccess",
            activity,
            permissions,
            riskCategory,
            risks,
            scopesRisks,
            types);
    }
}

export class ServiceNowInstanceConfiguration extends IntegrationEntityConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        type: IntegrationType,
        public name: string,
        public url: string,
        public userName: string,
        public userRawId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "ServiceNowInstanceConfiguration",
            scopeId,
            type);
    }
}

export class SevereDirectoryPermissionAttribute extends BuiltInEntityAttribute implements ITypeNameObject {
    constructor() {
        super("SevereDirectoryPermissionAttribute");
    }
}

export class SevereExcessivePermissionActionPrincipalAttribute extends BuiltInEntityAttribute implements ITypeNameObject {
    constructor() {
        super("SevereExcessivePermissionActionPrincipalAttribute");
    }
}

export class SeverePermissionActionPrincipalAttribute extends BuiltInEntityAttribute implements ITypeNameObject {
    constructor() {
        super("SeverePermissionActionPrincipalAttribute");
    }
}

export class SlackWorkspaceConfiguration extends IntegrationEntityConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        type: IntegrationType,
        public name: string,
        public permissionManagementEnabled: boolean,
        public rawId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "SlackWorkspaceConfiguration",
            scopeId,
            type);
    }
}

export class SplunkEventCollectorConfiguration extends IntegrationEntityConfiguration implements IDocument, ITypeNameObject, IEndpointIntegrationEntityConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        type: IntegrationType,
        public channelRawId: string,
        public endpoint: Optional<string>,
        public endpointConnectorId: Optional<string>,
        public name: string,
        public serverCertificateValidationEnabled: boolean,
        public url: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "SplunkEventCollectorConfiguration",
            scopeId,
            type);
    }
}

export class SqsQueueConfiguration extends IntegrationEntityConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        type: IntegrationType,
        public name: string,
        public roleArn: Optional<string>,
        public url: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "SqsQueueConfiguration",
            scopeId,
            type);
    }
}

export class SystemApplicationServiceConfiguration extends ServiceConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "SystemApplicationServiceConfiguration");
    }
}

export class SystemIdentity extends Identity implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        name: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "SystemIdentity",
            name);
    }
}

export class SystemInfrastructureServiceConfiguration extends ServiceConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        public engineering: EngineeringConfigurationSection,
        public mongoDbAtlasProject: Optional<MongoDbAtlasProjectConfigurationSection>,
        public virusTotalManager: VirusTotalManagerConfigurationSection) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "SystemInfrastructureServiceConfiguration");
    }
}

export class SystemPermissionManagementServiceConfiguration extends ServiceConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "SystemPermissionManagementServiceConfiguration");
    }
}

export class TagResourceGroupDefinitionConfiguration extends EntityGroupDefinitionConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        entityTypeName: string,
        name: string,
        public groupTagKeys: string[],
        public nameTagKey: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            entityTypeName,
            name);
    }
}

export class TeamsOrganizationConfiguration extends IntegrationEntityConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        type: IntegrationType,
        public name: string,
        public rawId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "TeamsOrganizationConfiguration",
            scopeId,
            type);
    }
}

export class TerraformBlock extends CodeBlock implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        endLine: number,
        fileId: string,
        startLine: number,
        public address: string,
        public name: string,
        public typeSystemName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            endLine,
            fileId,
            startLine);
    }
}

export class TerraformDataBlock extends TerraformBlock implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        endLine: number,
        fileId: string,
        startLine: number,
        address: string,
        name: string,
        typeSystemName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "TerraformDataBlock",
            tenantId,
            endLine,
            fileId,
            startLine,
            address,
            name,
            typeSystemName);
    }
}

export class TerraformResourceBlock extends TerraformBlock implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        endLine: number,
        fileId: string,
        startLine: number,
        address: string,
        name: string,
        typeSystemName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "TerraformResourceBlock",
            tenantId,
            endLine,
            fileId,
            startLine,
            address,
            name,
            typeSystemName);
    }
}

export class UnknownEntity extends UnknownEntityBase implements ITypeNameObject, IEntity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "UnknownEntity",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime);
    }
}

export class User extends Identity implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        name: string,
        public identifier: string,
        public mail: string,
        public upn: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "User",
            name);
    }
}

export class UserModelAccess extends IdentityModelAccess implements ITypeNameObject {
    constructor(
        activity: Activity,
        permissions: PrincipalModelAccessPermissions,
        riskCategory: Optional<PrincipalRiskCategory>,
        risks: PrincipalModelAccessRisks,
        scopesRisks: PrincipalModelAccessScopesRisk[],
        types: PrincipalType[]) {
        super(
            "UserModelAccess",
            activity,
            permissions,
            riskCategory,
            risks,
            scopesRisks,
            types);
    }
}

export class VendorServiceIdentityAttribute extends BuiltInEntityAttribute implements ITypeNameObject {
    constructor() {
        super("VendorServiceIdentityAttribute");
    }
}

export class VirtualMachinePriorityScanRequestUpdateAuditEvent extends WorkloadAnalysisAuditEvent implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        errorMessage: Optional<string>,
        identityReference: IdentityReference,
        visible: boolean,
        visibleUpdateTime: string,
        public virtualMachineId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "VirtualMachinePriorityScanRequestUpdateAuditEvent",
            scopeId,
            errorMessage,
            identityReference,
            visible,
            visibleUpdateTime);
    }
}

export class WebhookEndpointConfiguration extends IntegrationEntityConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        type: IntegrationType,
        public httpHeaders: WebhookEndpointConfigurationHttpHeader[],
        public name: string,
        public serverCertificateValidationEnabled: boolean,
        public url: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "WebhookEndpointConfiguration",
            scopeId,
            type);
    }
}

export class WorkloadAnalysisRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public criticalVulnerabilities: WorkloadResourceRiskVulnerability[],
        public highVulnerabilities: WorkloadResourceRiskVulnerability[],
        public singleVirtualMachine: boolean,
        public virtualMachineIds: string[],
        public vulnerabilitySeverityToCountMap: Dictionary<number>,
        public windowsUpdateDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class WorkloadAnalysisRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: WorkloadAnalysisRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class WorkloadResourceMaliciousFileAttribute extends BuiltInEntityAttribute implements ITypeNameObject {
    constructor() {
        super("WorkloadResourceMaliciousFileAttribute");
    }
}

export class WorkloadResourceOperatingSystemEndOfLifeAttribute extends BuiltInEntityAttribute implements ITypeNameObject {
    constructor() {
        super("WorkloadResourceOperatingSystemEndOfLifeAttribute");
    }
}

export class WorkloadResourceOperatingSystemEndOfLifeRiskMetadata extends WorkloadResourceOperatingSystemRiskTypeMetadata {
    constructor() {
        super();
    }
}

export class WorkloadResourceOperatingSystemUnpatchedAttribute extends BuiltInEntityAttribute implements ITypeNameObject {
    constructor() {
        super("WorkloadResourceOperatingSystemUnpatchedAttribute");
    }
}

export class WorkloadResourcePackageVulnerabilitiesReportDefinitionConfiguration extends WorkloadResourcesReportDefinitionConfigurationBase implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        contentType: ReportContentType,
        exportReport: boolean,
        licenseType: ApplicationCustomerConfigurationLicensingLicenseType,
        name: Optional<string>,
        timeZoneInfoId: string,
        type: ReportType,
        filters: WorkloadControllerWorkloadResourceModelFilters,
        public filtered: boolean,
        public includeDescription: Optional<boolean>,
        public vulnerabilityReportType: VulnerabilityReportType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            contentType,
            exportReport,
            licenseType,
            name,
            timeZoneInfoId,
            type,
            filters);
    }
}

export class WorkloadResourcesReportDefinitionConfiguration extends WorkloadResourcesReportDefinitionConfigurationBase implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        contentType: ReportContentType,
        exportReport: boolean,
        licenseType: ApplicationCustomerConfigurationLicensingLicenseType,
        name: Optional<string>,
        timeZoneInfoId: string,
        type: ReportType,
        filters: WorkloadControllerWorkloadResourceModelFilters,
        public sort: WorkloadControllerWorkloadResourceModelSort) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            contentType,
            exportReport,
            licenseType,
            name,
            timeZoneInfoId,
            type,
            filters);
    }
}

export class WorkloadResourceVulnerabilityAttribute extends BuiltInEntityAttribute implements ITypeNameObject {
    constructor() {
        super("WorkloadResourceVulnerabilityAttribute");
    }
}

export class AadChange extends Change implements ITypeNameObject {
    constructor(
        typeName: string,
        entityIds: string[],
        id: string,
        resolved: boolean) {
        super(
            typeName,
            entityIds,
            id,
            resolved);
    }
}

export class AadDeletePrincipalGroupMembershipChange extends AadChange implements ITypeNameObject, ICodeInfrastructureChange {
    constructor(
        entityIds: string[],
        id: string,
        resolved: boolean,
        public groupId: string,
        public principalId: string) {
        super(
            "AadDeletePrincipalGroupMembershipChange",
            entityIds,
            id,
            resolved);
    }
}

export class AadDirectoryApplicationObjectModelFilters extends AadDirectoryEntityModelFilters implements IEntityModelFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        openRelatedEntityRiskTypeIdentifierItems: FilterItems<string>,
        regionIdItems: FilterItems<string>,
        tenantIdItems: FilterItems<string>,
        typeNameItems: FilterItems<string>,
        creationTimeRange: FilterTimeRange,
        public expirationTimeRange: FilterTimeRange) {
        super(
            attributeValueItems,
            openRelatedEntityRiskTypeIdentifierItems,
            regionIdItems,
            tenantIdItems,
            typeNameItems,
            creationTimeRange);
    }
}

export class AadDirectoryEntity extends AadEntity implements ITypeNameObject, IDirectoryEntity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        public creationTime: Optional<string>,
        public rawId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name);
    }
}

export class AadDirectoryEntityModel extends AadEntityModel implements ITypeNameObject, IEntityModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        partitionType: AadPartitionType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            partitionType);
    }
}

export class AadDirectoryGroupInactiveRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public groupIdentityCount: number,
        public groupIdentityExists: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AadDirectoryGroupInactiveRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AadDirectoryGroupInactiveRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AadDirectoryGroupInactiveRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AadDirectoryGroupInactiveRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AadDirectoryGroupMembershipRequest extends GroupMembershipRequest implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        closeReason: Optional<PermissionRequestCloseReason>,
        expirationTime: Optional<string>,
        expirationTimeFrame: string,
        granteeUserTenantId: string,
        permissionEligibilityId: string,
        reason: Optional<string>,
        startTime: Optional<string>,
        status: PermissionRequestStatus,
        statusUpdateTime: string,
        assignedGroupIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AadDirectoryGroupMembershipRequest",
            scopeId,
            closeReason,
            expirationTime,
            expirationTimeFrame,
            granteeUserTenantId,
            permissionEligibilityId,
            reason,
            startTime,
            status,
            statusUpdateTime,
            assignedGroupIds);
    }
}

export class AadDirectoryIdentityInactiveRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public resolutionType: AadDirectoryIdentityInactiveRiskResolutionType,
        public signInTime: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AadDirectoryIdentityInactiveRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AadDirectoryIdentityInactiveRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public infoType: AadDirectoryIdentityInactiveRiskModelInfoType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AadDirectoryPrincipal extends AadDirectoryEntity implements ITypeNameObject, IDirectoryEntity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        creationTime: Optional<string>,
        rawId: string,
        public groupRawIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            creationTime,
            rawId);
    }
}

export class AadDirectoryPrincipalModel extends AadDirectoryEntityModel implements ITypeNameObject, IUdmObjectModel, IPrincipalModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        partitionType: AadPartitionType,
        public access: Optional<PrincipalModelAccess>,
        public analyzing: Optional<AadDirectoryPrincipalModelAnalyzing>,
        public azureRoleAssignmentResourceIds: string[],
        public azureRoleDefinitionIds: string[],
        public containedAzureRoleAssignmentResourceIds: string[],
        public directoryRoleAssignmentResourceIds: string[],
        public directoryRoleDefinitionIds: string[],
        public groupIds: string[],
        public inactive: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            partitionType);
    }
}

export class AadDirectoryUserExternalAttribute extends BuiltInEntityAttribute implements ITypeNameObject {
    constructor() {
        super("AadDirectoryUserExternalAttribute");
    }
}

export class AadDirectoryUserGuestAttribute extends BuiltInEntityAttribute implements ITypeNameObject {
    constructor() {
        super("AadDirectoryUserGuestAttribute");
    }
}

export class AadDirectoryUserInactiveRisk extends AadDirectoryIdentityInactiveRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        resolutionType: AadDirectoryIdentityInactiveRiskResolutionType,
        signInTime: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AadDirectoryUserInactiveRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            resolutionType,
            signInTime);
    }
}

export class AadDirectoryUserInactiveRiskModel extends AadDirectoryIdentityInactiveRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AadDirectoryUserInactiveRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        infoType: AadDirectoryIdentityInactiveRiskModelInfoType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AadDirectoryUserInactiveRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            infoType);
    }
}

export class AadEventAnalysisServiceConfiguration extends ServiceConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AadEventAnalysisServiceConfiguration");
    }
}

export class AadSeverePermissionPrincipalRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public groupIdentityIds: string[],
        public severeDirectoryPermissionDirectoryRoleAssignmentResourceIds: string[],
        public severeDirectoryPermissionStartTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AadSeverePermissionPrincipalRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AadSeverePermissionPrincipalRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AadSeverePermissionPrincipalRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AadSeverePermissionPrincipalRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AadTenantEntity extends AadDirectoryEntity implements ITypeNameObject, IDirectoryEntity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        creationTime: Optional<string>,
        rawId: string,
        public defaultUserRoleApplicationCreationEnabled: Optional<boolean>,
        public defaultUserRoleTenantCreationEnabled: Optional<boolean>,
        public externalUserDefaultRole: Optional<AadTenantEntityExternalUserDefaultRole>,
        public externalUserInvitationAllowedRoles: Optional<AadTenantEntityExternalUserInvitationAllowedRoles>,
        public primaryDomainName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AadTenantEntity",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            creationTime,
            rawId);
    }
}

export class AadTenantEntityDefaultUserRoleApplicationCreationEnabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AadTenantEntityDefaultUserRoleApplicationCreationEnabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AadTenantEntityDefaultUserRoleApplicationCreationEnabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AadTenantEntityDefaultUserRoleApplicationCreationEnabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AadTenantEntityDefaultUserRoleApplicationCreationEnabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AadTenantEntityDefaultUserRoleTenantCreationEnabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AadTenantEntityDefaultUserRoleTenantCreationEnabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AadTenantEntityDefaultUserRoleTenantCreationEnabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AadTenantEntityDefaultUserRoleTenantCreationEnabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AadTenantEntityDefaultUserRoleTenantCreationEnabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AadTenantEntityModel extends AadDirectoryEntityModel implements ITypeNameObject, IEntityModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        partitionType: AadPartitionType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AadTenantEntityModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            partitionType);
    }
}

export class AadTenantEntityUnrestrictedExternalUserDefaultRoleRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AadTenantEntityUnrestrictedExternalUserDefaultRoleRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AadTenantEntityUnrestrictedExternalUserDefaultRoleRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AadTenantEntityUnrestrictedExternalUserDefaultRoleRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AadTenantEntityUnrestrictedExternalUserDefaultRoleRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AadTenantEntityUnrestrictedExternalUserInvitationAllowedRolesRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AadTenantEntityUnrestrictedExternalUserInvitationAllowedRolesRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AadTenantEntityUnrestrictedExternalUserInvitationAllowedRolesRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AadTenantEntityUnrestrictedExternalUserInvitationAllowedRolesRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AadTenantEntityUnrestrictedExternalUserInvitationAllowedRolesRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AccessSettingConfiguration extends ScopeSettingConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId);
    }
}

export class AdministratorPrincipalAttribute extends BuiltInEntityAttribute implements ITypeNameObject {
    constructor() {
        super("AdministratorPrincipalAttribute");
    }
}

export class AggregatedEntityRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public aggregatedEntityIds: string[],
        public aggregatingEntityTypeName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AggregatedEntityRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AggregatedEntityRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AnalysisServiceConfiguration extends ServiceConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        public dataStoresExporter: DataStoresExporterConfigurationSection,
        public documentModelExporter: DocumentModelExporterConfigurationSection,
        public riskEngine: RiskEngineConfigurationSection) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AnalysisServiceConfiguration");
    }
}

export class ApiKey extends Identity implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        name: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "ApiKey",
            name);
    }
}

export class ApiServiceConfiguration extends ServiceConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "ApiServiceConfiguration");
    }
}

export class ApplicationDataResourceAttribute extends DataResourceAttribute implements ITypeNameObject {
    constructor(
        typeName: string,
        public sensitivity: DataSensitivity) {
        super(typeName);
    }
}

export class ApplicationDataResourcePermissionActionPrincipalAttribute extends DataResourcePermissionActionPrincipalAttribute implements ITypeNameObject {
    constructor(
        typeName: string,
        excessive: boolean) {
        super(
            typeName,
            excessive);
    }
}

export class ApplicationPciDataResourceAttribute extends ApplicationDataResourceAttribute implements ITypeNameObject {
    constructor(
        sensitivity: DataSensitivity) {
        super(
            "ApplicationPciDataResourceAttribute",
            sensitivity);
    }
}

export class ApplicationPciDataResourcePermissionActionPrincipalAttribute extends ApplicationDataResourcePermissionActionPrincipalAttribute implements ITypeNameObject {
    constructor(
        excessive: boolean) {
        super(
            "ApplicationPciDataResourcePermissionActionPrincipalAttribute",
            excessive);
    }
}

export class ApplicationPhiDataResourceAttribute extends ApplicationDataResourceAttribute implements ITypeNameObject {
    constructor(
        sensitivity: DataSensitivity) {
        super(
            "ApplicationPhiDataResourceAttribute",
            sensitivity);
    }
}

export class ApplicationPhiDataResourcePermissionActionPrincipalAttribute extends ApplicationDataResourcePermissionActionPrincipalAttribute implements ITypeNameObject {
    constructor(
        excessive: boolean) {
        super(
            "ApplicationPhiDataResourcePermissionActionPrincipalAttribute",
            excessive);
    }
}

export class ApplicationPiiDataResourceAttribute extends ApplicationDataResourceAttribute implements ITypeNameObject {
    constructor(
        sensitivity: DataSensitivity) {
        super(
            "ApplicationPiiDataResourceAttribute",
            sensitivity);
    }
}

export class ApplicationPiiDataResourcePermissionActionPrincipalAttribute extends ApplicationDataResourcePermissionActionPrincipalAttribute implements ITypeNameObject {
    constructor(
        excessive: boolean) {
        super(
            "ApplicationPiiDataResourcePermissionActionPrincipalAttribute",
            excessive);
    }
}

export class ApplicationSecretsDataResourceAttribute extends ApplicationDataResourceAttribute implements ITypeNameObject {
    constructor(
        sensitivity: DataSensitivity) {
        super(
            "ApplicationSecretsDataResourceAttribute",
            sensitivity);
    }
}

export class ApplicationSecretsDataResourcePermissionActionPrincipalAttribute extends ApplicationDataResourcePermissionActionPrincipalAttribute implements ITypeNameObject {
    constructor(
        excessive: boolean) {
        super(
            "ApplicationSecretsDataResourcePermissionActionPrincipalAttribute",
            excessive);
    }
}

export class AuditEventAutomationRuleConfiguration extends AutomationRuleConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        deliveries: DeliveryWrapper[],
        description: Optional<string>,
        name: string,
        scopeIds: string[],
        public auditEventTypeNames: Optional<string[]>,
        public includeSystemIdentity: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AuditEventAutomationRuleConfiguration",
            scopeId,
            deliveries,
            description,
            name,
            scopeIds);
    }
}

export class AutomaticCustomEntityAttribute extends CustomEntityAttribute implements ITypeNameObject {
    constructor(
        definitionId: string,
        self: boolean) {
        super(
            "AutomaticCustomEntityAttribute",
            definitionId,
            self);
    }
}

export class AutomaticCustomEntityAttributeDefinitionConfiguration extends CustomEntityAttributeDefinitionConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        colorIndex: number,
        name: string,
        public templateId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AutomaticCustomEntityAttributeDefinitionConfiguration",
            scopeId,
            colorIndex,
            name);
    }
}

export class AwsAccessAnalyzerAnalyzer extends AwsResource implements ITypeNameObject, IAwsResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public status: AwsAccessAnalyzerAnalyzerStatus,
        public type: AwsAccessAnalyzerAnalyzerType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsAccessAnalyzerAnalyzer",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsAccessAnalyzerAnalyzerModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsAccessAnalyzerAnalyzerModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsAccessAnalyzerRegionAnalyzerNotExistRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public regionSystemNames: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsAccessAnalyzerRegionAnalyzerNotExistRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsAccessAnalyzerRegionAnalyzerNotExistRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsAccessAnalyzerRegionAnalyzerNotExistRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsAccessAnalyzerRegionAnalyzerNotExistRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsAccessKeyEnabledUserAttribute extends BuiltInEntityAttribute implements ITypeNameObject {
    constructor() {
        super("AwsAccessKeyEnabledUserAttribute");
    }
}

export class AwsAccessPrincipalRisk extends EntityRisk implements ITypeNameObject, IAccessPrincipalRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public activityTime: Optional<string>,
        public excessivePermissionActionSeverity: Optional<Severity>,
        public groupUserIds: string[],
        public permissionActions: string[],
        public roleMostIndirectOriginatorResourceIds: string[],
        public roleOriginatorResourceIds: string[],
        public sensitiveResources: AwsAccessPrincipalRiskSensitiveResources,
        public serviceIdToUsageTimeMap: Dictionary<Optional<string>>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsAccessPrincipalRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsAccessPrincipalRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public userActiveAccessKeyCount: Optional<number>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsAcmCertificate extends AwsResource implements ITypeNameObject, IAwsTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public alternativeDomainNames: string[],
        public domainName: Optional<string>,
        public expirationTime: Optional<string>,
        public importTime: Optional<string>,
        public issuedTime: Optional<string>,
        public keyType: AwsAcmCertificateKeyType,
        public rawId: string,
        public renewalEligibility: AwsAcmCertificateRenewalEligibility,
        public revokedTime: Optional<string>,
        public status: AwsAcmCertificateStatus,
        public type: AwsAcmCertificateType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsAcmCertificate",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsAcmCertificateKeyMinLengthRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsAcmCertificateKeyMinLengthRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsAcmCertificateKeyMinLengthRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsAcmCertificateKeyMinLengthRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsAcmCertificateKeyMinLengthRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsAcmCertificateModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public attachedResourceExists: boolean,
        public attachedResourceIdReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsAcmCertificateModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsAcmCertificateModelFilters extends AwsResourceModelFilters implements IEntityModelFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        openRelatedEntityRiskTypeIdentifierItems: FilterItems<string>,
        regionIdItems: FilterItems<string>,
        tenantIdItems: FilterItems<string>,
        typeNameItems: FilterItems<string>,
        creationTimeRange: FilterTimeRange,
        public expirationTimeRange: FilterTimeRange) {
        super(
            attributeValueItems,
            openRelatedEntityRiskTypeIdentifierItems,
            regionIdItems,
            tenantIdItems,
            typeNameItems,
            creationTimeRange);
    }
}

export class AwsActivityRisk extends CloudRisk implements ITypeNameObject, IActivityRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType);
    }
}

export class AwsActivityRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        risk: Risk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public originatorEntityIdReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsAossCollection extends AwsResource implements ITypeNameObject, IAwsEncryptedResource, IAwsTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public encryptionExists: boolean,
        public kmsEncryptionKeyOrAliasArns: string[],
        public storageKmsEncryptionKeyOrAliasArn: Optional<string>,
        public storageSize: number) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsAossCollection",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsAossCollectionModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAwsEncryptedResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public kmsEncryptionKeyIdReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsAossCollectionModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsApiGatewayApi extends AwsResource implements ITypeNameObject, IAwsAccessResource, IAwsTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public accessLevel: Optional<AwsResourceAccessLevel>,
        public endpointType: AwsApiGatewayApiEndpointType,
        public executeApiArn: string,
        public rawId: string,
        public type: AwsApiGatewayApiType,
        public url: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsApiGatewayApiModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IAwsAccessResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public accessLevel: Optional<AwsResourceAccessLevel>,
        public resourceArnToReferenceMap: Dictionary<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsApiGatewayApiWebAclNotExistsCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsApiGatewayApiWebAclNotExistsCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsApiGatewayApiWebAclNotExistsCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsApiGatewayApiWebAclNotExistsCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsApiGatewayApiWebAclNotExistsCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsApiGatewayApiWebAclNotExistsRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsApiGatewayApiWebAclNotExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsApiGatewayApiWebAclNotExistsRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsApiGatewayApiWebAclNotExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public creatingWebAclUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsApiGatewayApiWebAclNotExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsApiGatewayV1Api extends AwsApiGatewayApi implements ITypeNameObject, IAwsAccessResource, IAwsTagResource, IAwsPolicyResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        accessLevel: Optional<AwsResourceAccessLevel>,
        endpointType: AwsApiGatewayApiEndpointType,
        executeApiArn: string,
        rawId: string,
        type: AwsApiGatewayApiType,
        url: string,
        public authorizerMap: Dictionary<AwsApiGatewayV1ApiAuthorizer>,
        public policyDocument: Optional<AwsIamPolicyDocument>,
        public policyDocumentExists: boolean,
        public routes: AwsApiGatewayV1ApiRoute[],
        public wafWebAclArns: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsApiGatewayV1Api",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            accessLevel,
            endpointType,
            executeApiArn,
            rawId,
            type,
            url);
    }
}

export class AwsApiGatewayV1ApiModel extends AwsApiGatewayApiModel implements ITypeNameObject, IUdmObjectModel, IAwsAccessResourceModel, IAwsPolicyResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        accessLevel: Optional<AwsResourceAccessLevel>,
        resourceArnToReferenceMap: Dictionary<string>,
        public integratedResourceIdReferences: string[],
        public wafWebAclIdReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsApiGatewayV1ApiModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId,
            accessLevel,
            resourceArnToReferenceMap);
    }
}

export class AwsApiGatewayV2Api extends AwsApiGatewayApi implements ITypeNameObject, IAwsAccessResource, IAwsTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        accessLevel: Optional<AwsResourceAccessLevel>,
        endpointType: AwsApiGatewayApiEndpointType,
        executeApiArn: string,
        rawId: string,
        type: AwsApiGatewayApiType,
        url: string,
        public authorizerMap: Dictionary<AwsApiGatewayV2ApiAuthorizer>,
        public integrationMap: Dictionary<AwsApiGatewayV2ApiIntegration>,
        public routes: AwsApiGatewayV2ApiRoute[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsApiGatewayV2Api",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            accessLevel,
            endpointType,
            executeApiArn,
            rawId,
            type,
            url);
    }
}

export class AwsApiGatewayV2ApiModel extends AwsApiGatewayApiModel implements ITypeNameObject, IUdmObjectModel, IAwsAccessResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        accessLevel: Optional<AwsResourceAccessLevel>,
        resourceArnToReferenceMap: Dictionary<string>,
        public resourceUrlToIdToMap: Dictionary<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsApiGatewayV2ApiModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId,
            accessLevel,
            resourceArnToReferenceMap);
    }
}

export class AwsAssumeRolePolicyDocumentNonComputeServiceGranteeOnlyRoleAttribute extends BuiltInEntityAttribute implements ITypeNameObject {
    constructor() {
        super("AwsAssumeRolePolicyDocumentNonComputeServiceGranteeOnlyRoleAttribute");
    }
}

export class AwsAthenaWorkgroup extends AwsResource implements ITypeNameObject, IAwsEncryptedResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public enabled: boolean,
        public encryptionExists: boolean,
        public encryptionKmsKeyArn: Optional<string>,
        public enforceWorkgroupConfiguration: boolean,
        public engineVersionName: string,
        public kmsEncryptionKeyOrAliasArns: string[],
        public loggingEnabled: boolean,
        public outputBucketArn: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsAthenaWorkgroup",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsAthenaWorkgroupEncryptionDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsAthenaWorkgroupEncryptionDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsAthenaWorkgroupEncryptionDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsAthenaWorkgroupEncryptionDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsAthenaWorkgroupEncryptionDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsAthenaWorkgroupEncryptionDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsAthenaWorkgroupEncryptionDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsAthenaWorkgroupEncryptionDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsAthenaWorkgroupEncryptionDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsAthenaWorkgroupEncryptionDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsAthenaWorkgroupLoggingDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsAthenaWorkgroupLoggingDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsAthenaWorkgroupLoggingDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsAthenaWorkgroupLoggingDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsAthenaWorkgroupLoggingDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsAthenaWorkgroupLoggingDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsAthenaWorkgroupLoggingDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsAthenaWorkgroupLoggingDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsAthenaWorkgroupLoggingDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsAthenaWorkgroupLoggingDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsAthenaWorkgroupModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAwsEncryptedResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public kmsEncryptionKeyIdReferences: string[],
        public outputBucketIdReference: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsAthenaWorkgroupModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsAutoScalingAutoScalingGroupModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IAwsNetworkedResourceModel, INetworkAccessResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        public entityNetwork: Optional<NetworkAccessResourceStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public imageId: Optional<string>,
        public loadBalancerIdReferences: string[],
        public nodeGroupId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsAutoScalingAutoScalingGroupModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsAutoScalingAutoScalingGroupModelFilters extends AwsResourceModelFilters implements IEntityModelFilters, IEntityNetworkFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        openRelatedEntityRiskTypeIdentifierItems: FilterItems<string>,
        regionIdItems: FilterItems<string>,
        tenantIdItems: FilterItems<string>,
        typeNameItems: FilterItems<string>,
        creationTimeRange: FilterTimeRange,
        public networkAccess: EntityNetworkAccessFiltersSection) {
        super(
            attributeValueItems,
            openRelatedEntityRiskTypeIdentifierItems,
            regionIdItems,
            tenantIdItems,
            typeNameItems,
            creationTimeRange);
    }
}

export class AwsAutoScalingLaunchConfigurationModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public autoScalingGroupIds: string[],
        public imageId: Optional<string>,
        public snapshotIds: string[],
        public snapshotRawIdToIdMap: Dictionary<string>,
        public vpcIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsAutoScalingLaunchConfigurationModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsAutoScalingResource extends AwsResource implements ITypeNameObject, IAwsResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsBatchComputeEnvironmentModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsBatchComputeEnvironmentModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsBatchJobDefinitionModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsBatchJobDefinitionModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsBatchResource extends AwsResource implements ITypeNameObject, IAwsResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsBedrockAgent extends AwsResource implements ITypeNameObject, IAwsEncryptedResource, IAwsTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public aliases: AwsBedrockAgentAlias[],
        public description: Optional<string>,
        public encryptionExists: boolean,
        public kmsEncryptionKeyOrAliasArns: string[],
        public rawId: string,
        public revisionMap: Dictionary<AwsBedrockAgentRevision>,
        public roleReference: Optional<AwsResourceReference>,
        public status: AwsBedrockAgentStatus,
        public updateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsBedrockAgent",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsBedrockAgentModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAwsEncryptedResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public kmsEncryptionKeyIdReferences: string[],
        public revisionIdToKeyIdReferenceMap: Dictionary<Optional<string>>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsBedrockAgentModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsBedrockAgentModelFilters extends AwsResourceModelFilters implements IEntityModelFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        openRelatedEntityRiskTypeIdentifierItems: FilterItems<string>,
        regionIdItems: FilterItems<string>,
        tenantIdItems: FilterItems<string>,
        typeNameItems: FilterItems<string>,
        creationTimeRange: FilterTimeRange,
        public updateTimeRange: FilterTimeRange) {
        super(
            attributeValueItems,
            openRelatedEntityRiskTypeIdentifierItems,
            regionIdItems,
            tenantIdItems,
            typeNameItems,
            creationTimeRange);
    }
}

export class AwsBedrockCustomModel extends AwsResource implements ITypeNameObject, IAwsEncryptedResource, IAwsTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public customizationType: Optional<AwsBedrockCustomModelCustomizationType>,
        public encryptionExists: boolean,
        public inputTrainingBucketArn: string,
        public inputValidationBucketArns: Optional<string[]>,
        public kmsEncryptionKeyOrAliasArns: string[],
        public outputBucketArn: string,
        public sourceModelArn: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsBedrockCustomModel",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsBedrockCustomModelModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAwsEncryptedResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public inputTrainingBucketId: Optional<string>,
        public inputValidationBucketIds: Optional<string[]>,
        public kmsEncryptionKeyIdReferences: string[],
        public outputBucketId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsBedrockCustomModelModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsBehaviorIdentityRisk extends AwsActivityRisk implements ITypeNameObject, IActivityRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        public endTime: string,
        public eventActions: string[],
        public identityId: string,
        public ipAddresses: string[],
        public previousBehaviorDate: Optional<string>,
        public startTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType);
    }
}

export class AwsBehaviorIdentityRiskModel extends AwsActivityRiskModel implements ITypeNameObject, IUdmObjectModel, IBehaviorIdentityRiskModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsBehaviorIdentityRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        originatorEntityIdReferences: string[],
        public infos: AwsBehaviorIdentityRiskModelInfo[],
        public resourceIdReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            originatorEntityIdReferences);
    }
}

export class AwsChange extends Change implements ITypeNameObject {
    constructor(
        typeName: string,
        entityIds: string[],
        id: string,
        resolved: boolean) {
        super(
            typeName,
            entityIds,
            id,
            resolved);
    }
}

export class AwsCloudFormationChangeSet extends AwsResource implements ITypeNameObject, IAwsResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public stackId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudFormationChangeSet",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsCloudFormationChangeSetModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudFormationChangeSetModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsCloudFormationStack extends AwsResource implements ITypeNameObject, IAwsTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public description: Optional<string>,
        public driftCheckTime: Optional<string>,
        public driftStatus: AwsCloudFormationStackDriftStatus,
        public outputs: AwsCloudFormationStackOutput[],
        public parameterDefaultValuesMissing: boolean,
        public parameters: AwsCloudFormationStackParameter[],
        public rawId: string,
        public resourceTypeNameToRawIdsMap: Dictionary<string[]>,
        public roleArn: Optional<string>,
        public rootRawId: Optional<string>,
        public status: AwsCloudFormationStackStatus,
        public template: Optional<string>,
        public terminationProtection: boolean,
        public updateTime: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudFormationStack",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsCloudFormationStackModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public outputResourceReferences: AwsResourceReference[],
        public resourceIdReferences: string[],
        public resourceTypeNameToIdReferencesMap: Dictionary<string[]>,
        public roleIdReference: Optional<string>,
        public rootId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudFormationStackModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsCloudFormationStackSecretExistsCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudFormationStackSecretExistsCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsCloudFormationStackSecretExistsCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsCloudFormationStackSecretExistsCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudFormationStackSecretExistsCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsCloudFormationStackSecretExistsRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public excludedSecrets: string[],
        public secretOutputKeys: string[],
        public secretParameterDefaultValueKeys: string[],
        public secretParameterValueKeys: string[],
        public stackPermissionsIdentityIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudFormationStackSecretExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsCloudFormationStackSecretExistsRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel, ISecretExistsRiskModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsCloudFormationStackSecretExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public secretCount: number,
        public secretsManagerDocumentationUrl: string,
        public stackSecretsManagerDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudFormationStackSecretExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsCloudFrontDistribution extends AwsResource implements ITypeNameObject, IAwsTagResource, IAwsCertificateResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public alternativeDomainNames: string[],
        public behaviors: AwsCloudFrontDistributionBehavior[],
        public certificateIds: string[],
        public defaultRootObjectName: Optional<string>,
        public domainName: string,
        public loggingEnabled: boolean,
        public origins: AwsCloudFrontDistributionOrigin[],
        public rawId: string,
        public status: AwsCloudFrontDistributionStatus,
        public updateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudFrontDistribution",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsCloudFrontDistributionDefaultSslCertificateCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudFrontDistributionDefaultSslCertificateCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsCloudFrontDistributionDefaultSslCertificateCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsCloudFrontDistributionDefaultSslCertificateCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudFrontDistributionDefaultSslCertificateCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsCloudFrontDistributionDefaultSslCertificateRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsCloudFrontDistributionDefaultSslCertificateRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudFrontDistributionDefaultSslCertificateRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsCloudFrontDistributionHttpsOnlyDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudFrontDistributionHttpsOnlyDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsCloudFrontDistributionHttpsOnlyDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsCloudFrontDistributionHttpsOnlyDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudFrontDistributionHttpsOnlyDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsCloudFrontDistributionHttpsOnlyDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsCloudFrontDistributionHttpsOnlyDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudFrontDistributionHttpsOnlyDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsCloudFrontDistributionInsecureTlsProtocolCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudFrontDistributionInsecureTlsProtocolCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsCloudFrontDistributionInsecureTlsProtocolCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsCloudFrontDistributionInsecureTlsProtocolCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudFrontDistributionInsecureTlsProtocolCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsCloudFrontDistributionInsecureTlsProtocolRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsCloudFrontDistributionInsecureTlsProtocolRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public installCertificateDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudFrontDistributionInsecureTlsProtocolRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsCloudFrontDistributionLoggingDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudFrontDistributionLoggingDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsCloudFrontDistributionLoggingDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsCloudFrontDistributionLoggingDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudFrontDistributionLoggingDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsCloudFrontDistributionLoggingDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsCloudFrontDistributionLoggingDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudFrontDistributionLoggingDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsCloudFrontDistributionMisconfigurationRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsCloudFrontDistributionModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public certificateIdReference: Optional<string>,
        public loggingBucketIdReference: Optional<string>,
        public originIdToResourceIdReferenceMap: Dictionary<string>,
        public webAclId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudFrontDistributionModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsCloudFrontDistributionOriginHttpsOnlyDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudFrontDistributionOriginHttpsOnlyDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsCloudFrontDistributionOriginHttpsOnlyDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsCloudFrontDistributionOriginHttpsOnlyDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudFrontDistributionOriginHttpsOnlyDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsCloudFrontDistributionOriginHttpsOnlyDisabledRisk extends AwsCloudFrontDistributionMisconfigurationRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public riskedOriginIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudFrontDistributionOriginHttpsOnlyDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsCloudFrontDistributionOriginHttpsOnlyDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsCloudFrontDistributionOriginHttpsOnlyDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public installCertificateDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudFrontDistributionOriginHttpsOnlyDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsCloudFrontDistributionRootObjectNotExistsCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudFrontDistributionRootObjectNotExistsCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsCloudFrontDistributionRootObjectNotExistsCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsCloudFrontDistributionRootObjectNotExistsCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudFrontDistributionRootObjectNotExistsCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsCloudFrontDistributionRootObjectNotExistsRisk extends AwsCloudFrontDistributionMisconfigurationRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudFrontDistributionRootObjectNotExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsCloudFrontDistributionRootObjectNotExistsRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsCloudFrontDistributionRootObjectNotExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudFrontDistributionRootObjectNotExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsCloudFrontDistributionS3BucketOriginAccessControlDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudFrontDistributionS3BucketOriginAccessControlDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsCloudFrontDistributionS3BucketOriginAccessControlDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsCloudFrontDistributionS3BucketOriginAccessControlDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudFrontDistributionS3BucketOriginAccessControlDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsCloudFrontDistributionS3BucketOriginAccessControlDisabledRisk extends AwsCloudFrontDistributionMisconfigurationRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public riskedOriginIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudFrontDistributionS3BucketOriginAccessControlDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsCloudFrontDistributionS3BucketOriginAccessControlDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsCloudFrontDistributionS3BucketOriginAccessControlDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public createOacDocumentationUrl: string,
        public oaiToOacDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudFrontDistributionS3BucketOriginAccessControlDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsCloudFrontDistributionWebAclNotExistsCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudFrontDistributionWebAclNotExistsCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsCloudFrontDistributionWebAclNotExistsCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsCloudFrontDistributionWebAclNotExistsCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudFrontDistributionWebAclNotExistsCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsCloudFrontDistributionWebAclNotExistsRisk extends AwsCloudFrontDistributionMisconfigurationRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudFrontDistributionWebAclNotExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsCloudFrontDistributionWebAclNotExistsRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsCloudFrontDistributionWebAclNotExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public creatingWebAclUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudFrontDistributionWebAclNotExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsCloudTrailTrail extends AwsResource implements ITypeNameObject, IAwsEncryptedResource, IAwsTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public advancedEventSelectors: AwsCloudTrailTrailAdvancedEventSelector[],
        public basicEventSelectors: AwsCloudTrailTrailBasicEventSelector[],
        public bucketArn: string,
        public bucketKmsEncryptionKeyOrAliasArn: Optional<string>,
        public bucketName: string,
        public bucketObjectEventSelectorType: Optional<AwsCloudTrailTrailEventSelectorType>,
        public bucketObjectKeyPrefix: Optional<string>,
        public digestEnabled: boolean,
        public enabled: boolean,
        public encryptionExists: boolean,
        public eventCategoryToSelectorTypeMap: Dictionary<AwsCloudTrailTrailEventSelectorType>,
        public globalServiceEventEnabled: boolean,
        public kmsEncryptionKeyOrAliasArns: string[],
        public lambdaFunctionConfigurationInvokeEventSelectorType: Optional<AwsCloudTrailTrailEventSelectorType>,
        public logGroupArn: Optional<string>,
        public logGroupDeliveryTime: Optional<string>,
        public managementEventSelectorType: Optional<AwsCloudTrailTrailEventSelectorType>,
        public multiRegion: boolean,
        public organizationTrail: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudTrailTrail",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsCloudTrailTrailBucketComplianceEntityRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public trailNoncompliantBucketId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsCloudTrailTrailBucketEventDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudTrailTrailBucketEventDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsCloudTrailTrailBucketEventDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsCloudTrailTrailBucketEventDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudTrailTrailBucketEventDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsCloudTrailTrailBucketEventDisabledRisk extends AwsCloudTrailTrailBucketComplianceEntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        trailNoncompliantBucketId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudTrailTrailBucketEventDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            trailNoncompliantBucketId);
    }
}

export class AwsCloudTrailTrailBucketEventDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsCloudTrailTrailBucketEventDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudTrailTrailBucketEventDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsCloudTrailTrailBucketPublicAccessEnabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudTrailTrailBucketPublicAccessEnabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsCloudTrailTrailBucketPublicAccessEnabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsCloudTrailTrailBucketPublicAccessEnabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudTrailTrailBucketPublicAccessEnabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsCloudTrailTrailBucketPublicAccessEnabledRisk extends AwsCloudTrailTrailBucketComplianceEntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        trailNoncompliantBucketId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudTrailTrailBucketPublicAccessEnabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            trailNoncompliantBucketId);
    }
}

export class AwsCloudTrailTrailBucketPublicAccessEnabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsCloudTrailTrailBucketPublicAccessEnabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudTrailTrailBucketPublicAccessEnabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsCloudTrailTrailDigestDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudTrailTrailDigestDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsCloudTrailTrailDigestDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsCloudTrailTrailDigestDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudTrailTrailDigestDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsCloudTrailTrailDigestDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudTrailTrailDigestDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsCloudTrailTrailDigestDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsCloudTrailTrailDigestDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudTrailTrailDigestDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsCloudTrailTrailEncryptionDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudTrailTrailEncryptionDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsCloudTrailTrailEncryptionDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsCloudTrailTrailEncryptionDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudTrailTrailEncryptionDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsCloudTrailTrailEncryptionDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudTrailTrailEncryptionDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsCloudTrailTrailEncryptionDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsCloudTrailTrailEncryptionDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudTrailTrailEncryptionDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsCloudTrailTrailLogGroupDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudTrailTrailLogGroupDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsCloudTrailTrailLogGroupDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsCloudTrailTrailLogGroupDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudTrailTrailLogGroupDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsCloudTrailTrailLogGroupDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudTrailTrailLogGroupDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsCloudTrailTrailLogGroupDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsCloudTrailTrailLogGroupDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudTrailTrailLogGroupDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsCloudTrailTrailModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAwsEncryptedResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public bucketId: Optional<string>,
        public kmsEncryptionKeyIdReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudTrailTrailModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsCloudTrailTrailMultiRegionNotExistRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public trailIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudTrailTrailMultiRegionNotExistRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsCloudTrailTrailMultiRegionNotExistRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsCloudTrailTrailMultiRegionNotExistRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public createCloudTrailDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudTrailTrailMultiRegionNotExistRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsCloudTrailTrailS3ObjectReadEventSelectorNotExistRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudTrailTrailS3ObjectReadEventSelectorNotExistRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsCloudTrailTrailS3ObjectReadEventSelectorNotExistRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsCloudTrailTrailS3ObjectReadEventSelectorNotExistRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudTrailTrailS3ObjectReadEventSelectorNotExistRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsCloudTrailTrailS3ObjectWriteEventSelectorNotExistRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudTrailTrailS3ObjectWriteEventSelectorNotExistRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsCloudTrailTrailS3ObjectWriteEventSelectorNotExistRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsCloudTrailTrailS3ObjectWriteEventSelectorNotExistRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudTrailTrailS3ObjectWriteEventSelectorNotExistRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsCloudWatchAlarm extends AwsResource implements ITypeNameObject, IAwsResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public actionResourceRawArns: string[],
        public actionsEnabled: boolean,
        public metricName: Optional<string>,
        public metricNamespace: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudWatchAlarm",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsCloudWatchAlarmModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudWatchAlarmModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsCloudWatchAlarmNotExistRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public logGroupMetricFilterPattern: string,
        public requirementToSuccessMap: Dictionary<boolean>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsCloudWatchAlarmNotExistRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsCloudWatchAlarmNotExistRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public createAlarmDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsCloudWatchAlarmOrganizationsOrganizationChangesNotExistRisk extends AwsCloudWatchAlarmNotExistRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        logGroupMetricFilterPattern: string,
        requirementToSuccessMap: Dictionary<boolean>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudWatchAlarmOrganizationsOrganizationChangesNotExistRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            logGroupMetricFilterPattern,
            requirementToSuccessMap);
    }
}

export class AwsCloudWatchAlarmOrganizationsOrganizationChangesNotExistRiskModel extends AwsCloudWatchAlarmNotExistRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsCloudWatchAlarmNotExistRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        createAlarmDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudWatchAlarmOrganizationsOrganizationChangesNotExistRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            createAlarmDocumentationUrl);
    }
}

export class AwsCloudWatchAlarmRootUsageNotExistRisk extends AwsCloudWatchAlarmNotExistRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        logGroupMetricFilterPattern: string,
        requirementToSuccessMap: Dictionary<boolean>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudWatchAlarmRootUsageNotExistRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            logGroupMetricFilterPattern,
            requirementToSuccessMap);
    }
}

export class AwsCloudWatchAlarmRootUsageNotExistRiskModel extends AwsCloudWatchAlarmNotExistRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsCloudWatchAlarmNotExistRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        createAlarmDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudWatchAlarmRootUsageNotExistRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            createAlarmDocumentationUrl);
    }
}

export class AwsCloudWatchAlarmS3BucketPolicyChangesNotExistRisk extends AwsCloudWatchAlarmNotExistRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        logGroupMetricFilterPattern: string,
        requirementToSuccessMap: Dictionary<boolean>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudWatchAlarmS3BucketPolicyChangesNotExistRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            logGroupMetricFilterPattern,
            requirementToSuccessMap);
    }
}

export class AwsCloudWatchAlarmS3BucketPolicyChangesNotExistRiskModel extends AwsCloudWatchAlarmNotExistRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsCloudWatchAlarmNotExistRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        createAlarmDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudWatchAlarmS3BucketPolicyChangesNotExistRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            createAlarmDocumentationUrl);
    }
}

export class AwsCloudWatchAlarmUnauthorizedApiCallsNotExistRisk extends AwsCloudWatchAlarmNotExistRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        logGroupMetricFilterPattern: string,
        requirementToSuccessMap: Dictionary<boolean>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudWatchAlarmUnauthorizedApiCallsNotExistRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            logGroupMetricFilterPattern,
            requirementToSuccessMap);
    }
}

export class AwsCloudWatchAlarmUnauthorizedApiCallsNotExistRiskModel extends AwsCloudWatchAlarmNotExistRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsCloudWatchAlarmNotExistRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        createAlarmDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudWatchAlarmUnauthorizedApiCallsNotExistRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            createAlarmDocumentationUrl);
    }
}

export class AwsConfigConfigurationRecorder extends AwsResource implements ITypeNameObject, IAwsResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public allResources: boolean,
        public enabled: boolean,
        public includeGlobalResources: boolean,
        public status: Optional<AwsConfigConfigurationRecorderStatus>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsConfigConfigurationRecorder",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsConfigConfigurationRecorderModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsConfigConfigurationRecorderModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsConfigRegionConfigurationRecorderNotExistRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public globalResourceEnabled: boolean,
        public regionSystemNames: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsConfigRegionConfigurationRecorderNotExistRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsConfigRegionConfigurationRecorderNotExistRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsConfigRegionConfigurationRecorderNotExistRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsConfigRegionConfigurationRecorderNotExistRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsContainerImage extends AwsResource implements ITypeNameObject, IAwsResource, IContainerImage {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public data: ContainerImageData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsContainerImage",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsContainerImageModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IContainerImageModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public kubernetesWorkloadResourceIds: string[],
        public operatingSystemReleaseNotesUrl: Optional<string>,
        public operatingSystemSupported: Optional<boolean>,
        public operatingSystemType: Optional<OperatingSystemType>,
        public repositoryIdReference: string,
        public virtualMachineIds: string[],
        public vulnerabilitySeveritiesSortValue: string,
        public workloadAnalysisOperatingSystem: Optional<WorkloadResourceScanOperatingSystem>,
        public workloadAnalysisOperatingSystemEndOfLifeDate: Optional<string>,
        public workloadAnalysisOperatingSystemExtendedSupport: Optional<boolean>,
        public workloadClusterResourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsContainerImageModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsContainerImageModelFilters extends AwsResourceModelFilters implements IEntityModelFilters, IContainerImageFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        openRelatedEntityRiskTypeIdentifierItems: FilterItems<string>,
        regionIdItems: FilterItems<string>,
        tenantIdItems: FilterItems<string>,
        typeNameItems: FilterItems<string>,
        creationTimeRange: FilterTimeRange,
        public containerImageCreationTimeRange: FilterTimeRange,
        public scanTimeRange: FilterTimeRange) {
        super(
            attributeValueItems,
            openRelatedEntityRiskTypeIdentifierItems,
            regionIdItems,
            tenantIdItems,
            typeNameItems,
            creationTimeRange);
    }
}

export class AwsCustomActivityRiskModel extends AwsActivityRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        risk: Risk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        originatorEntityIdReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            originatorEntityIdReferences);
    }
}

export class AwsCustomRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        risk: Risk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsDataLeakageRisk extends AwsBehaviorIdentityRisk implements ITypeNameObject, IActivityRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        endTime: string,
        eventActions: string[],
        identityId: string,
        ipAddresses: string[],
        previousBehaviorDate: Optional<string>,
        startTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsDataLeakageRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            endTime,
            eventActions,
            identityId,
            ipAddresses,
            previousBehaviorDate,
            startTime);
    }
}

export class AwsDataLeakageRiskModel extends AwsBehaviorIdentityRiskModel implements ITypeNameObject, IUdmObjectModel, IBehaviorIdentityRiskModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsDataLeakageRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        originatorEntityIdReferences: string[],
        infos: AwsBehaviorIdentityRiskModelInfo[],
        resourceIdReferences: string[],
        public descriptionType: AwsDataLeakageRiskModelDescriptionType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsDataLeakageRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            originatorEntityIdReferences,
            infos,
            resourceIdReferences);
    }
}

export class AwsDocDbClusterBackupRetentionTimeFrameCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsDocDbClusterBackupRetentionTimeFrameCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsDocDbClusterBackupRetentionTimeFrameCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsDocDbClusterBackupRetentionTimeFrameCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsDocDbClusterBackupRetentionTimeFrameCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsDocDbClusterBackupRetentionTimeFrameRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public backupRetentionTimeFrame: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsDocDbClusterBackupRetentionTimeFrameRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsDocDbClusterBackupRetentionTimeFrameRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsDocDbClusterBackupRetentionTimeFrameRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsDocDbClusterBackupRetentionTimeFrameRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsDocDbElasticCluster extends AwsResource implements ITypeNameObject, IAwsDocDbCluster, IAwsTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public backupRetentionTimeFrame: string,
        public cloudWatchLoggingEnabled: boolean,
        public cloudWatchLogTypes: string[],
        public deleteProtection: Optional<boolean>,
        public encryptionExists: boolean,
        public engineVersion: Optional<string>,
        public globalClusterId: Optional<string>,
        public kmsEncryptionKeyOrAliasArns: string[],
        public shardCapacity: number,
        public shardCount: number,
        public shardInstanceCount: number,
        public status: string,
        public storageKmsEncryptionKeyOrAliasArn: Optional<string>,
        public storageSize: number) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsDocDbElasticCluster",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsDocDbElasticClusterModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAwsDocDbClusterModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public instanceIds: Optional<string[]>,
        public kmsEncryptionKeyIdReferences: string[],
        public snapshotIds: string[],
        public type: AwsDocDbClusterModelType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsDocDbElasticClusterModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsDocDbElasticClusterSnapshot extends AwsResource implements ITypeNameObject, IAwsDocDbClusterSnapshot, IAwsTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public clusterId: Optional<string>,
        public encryptionExists: boolean,
        public kmsEncryptionKeyOrAliasArns: string[],
        public status: string,
        public storageKmsEncryptionKeyOrAliasArn: Optional<string>,
        public storageSize: Optional<number>,
        public type: AwsResourceSnapshotType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsDocDbElasticClusterSnapshot",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsDocDbElasticClusterSnapshotModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAwsDocDbClusterSnapshotModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public clusterType: AwsDocDbClusterModelType,
        public kmsEncryptionKeyIdReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsDocDbElasticClusterSnapshotModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsDynamoDbTable extends AwsResource implements ITypeNameObject, IAwsAccessResource, IAwsEncryptedResource, IAwsPolicyResource, IAwsTagResource, IDataAnalysisResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public accessLevel: Optional<AwsResourceAccessLevel>,
        public deleteProtection: boolean,
        public encryptionExists: boolean,
        public getPolicyDocumentError: boolean,
        public itemCount: number,
        public kmsEncryptionKeyOrAliasArns: string[],
        public policyDocument: Optional<AwsIamPolicyDocument>,
        public policyDocumentExists: boolean,
        public rawId: string,
        public storageKmsEncryptionKeyOrAliasArn: Optional<string>,
        public storageSize: number) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsDynamoDbTable",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsDynamoDbTableDeleteProtectionDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsDynamoDbTableDeleteProtectionDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsDynamoDbTableDeleteProtectionDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsDynamoDbTableDeleteProtectionDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsDynamoDbTableDeleteProtectionDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsDynamoDbTableDeleteProtectionDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsDynamoDbTableDeleteProtectionDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsDynamoDbTableDeleteProtectionDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsDynamoDbTableDeleteProtectionDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsDynamoDbTableDeleteProtectionDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsDynamoDbTableEncryptionDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsDynamoDbTableEncryptionDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsDynamoDbTableEncryptionDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsDynamoDbTableEncryptionDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsDynamoDbTableEncryptionDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsDynamoDbTableEncryptionDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsDynamoDbTableEncryptionDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsDynamoDbTableEncryptionDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsDynamoDbTableEncryptionDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsDynamoDbTableEncryptionDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsDynamoDbTableModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IAwsAccessResourceModel, IAwsEncryptedResourceModel, IAwsPolicyResourceModel, IDataAnalysisResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public accessLevel: Optional<AwsResourceAccessLevel>,
        public dataAnalysisError: Optional<DataAnalysisResourceModelDataAnalysisError>,
        public dataAnalysisPriorityScanRequested: boolean,
        public dataAnalysisResourceSensitivity: Optional<DataAnalysisSensitivity>,
        public dataAnalysisStatus: DataAnalysisResourceModelDataAnalysisStatus,
        public dataAnalysisStatusSortValue: number,
        public dataCategoryToDataSegmentCountMap: Optional<Dictionary<number>>,
        public dataCategoryToSensitivityMap: Optional<Dictionary<DataSensitivity>>,
        public dataClassifierIdToDataSegmentCountMap: Optional<Dictionary<number>>,
        public dataLastModified: Optional<string>,
        public dataSensitivities: Optional<DataSensitivity[]>,
        public dataSensitivityToDataSegmentCountMap: Optional<Dictionary<number>>,
        public highestDataSensitivity: Optional<DataSensitivity>,
        public kmsEncryptionKeyIdReferences: string[],
        public scannedDataSegmentCount: Optional<number>,
        public scanTime: Optional<string>,
        public sensitiveDataSegmentCount: Optional<number>,
        public storageSize: Optional<number>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsDynamoDbTableModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsDynamoDbTableModelFilters extends AwsResourceModelFilters implements IEntityModelFilters, IDataAnalysisResourceModelFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        openRelatedEntityRiskTypeIdentifierItems: FilterItems<string>,
        regionIdItems: FilterItems<string>,
        tenantIdItems: FilterItems<string>,
        typeNameItems: FilterItems<string>,
        creationTimeRange: FilterTimeRange,
        public dataAnalysisResource: DataAnalysisResourceModelFiltersSection) {
        super(
            attributeValueItems,
            openRelatedEntityRiskTypeIdentifierItems,
            regionIdItems,
            tenantIdItems,
            typeNameItems,
            creationTimeRange);
    }
}

export class AwsEc2AnalysisServiceConfiguration extends ServiceConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2AnalysisServiceConfiguration");
    }
}

export class AwsEc2DatabricksInstanceGroup extends AwsTagResourceGroup implements ITypeNameObject, IAwsResource, IEntityGroup {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        definitionId: Optional<string>,
        groupTags: ResourceTag[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2DatabricksInstanceGroup",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            definitionId,
            groupTags);
    }
}

export class AwsEc2DatabricksInstanceGroupDefinitionConfiguration extends TagResourceGroupDefinitionConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        entityTypeName: string,
        name: string,
        groupTagKeys: string[],
        nameTagKey: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2DatabricksInstanceGroupDefinitionConfiguration",
            scopeId,
            entityTypeName,
            name,
            groupTagKeys,
            nameTagKey);
    }
}

export class AwsEc2ImageModelFilters extends AwsResourceModelFilters implements IEntityModelFilters, IVirtualMachineDiskResourceFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        openRelatedEntityRiskTypeIdentifierItems: FilterItems<string>,
        regionIdItems: FilterItems<string>,
        tenantIdItems: FilterItems<string>,
        typeNameItems: FilterItems<string>,
        creationTimeRange: FilterTimeRange,
        public scanTimeRange: FilterTimeRange) {
        super(
            attributeValueItems,
            openRelatedEntityRiskTypeIdentifierItems,
            regionIdItems,
            tenantIdItems,
            typeNameItems,
            creationTimeRange);
    }
}

export class AwsEc2ImagePublicAccessExistsCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2ImagePublicAccessExistsCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsEc2ImagePublicAccessExistsCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsEc2ImagePublicAccessExistsCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2ImagePublicAccessExistsCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsEc2ImagePublicAccessExistsRisk extends PublicEntityRisk implements ITypeNameObject, IPublicRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public instanceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2ImagePublicAccessExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsEc2ImagePublicAccessExistsRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsEc2ImagePublicAccessExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2ImagePublicAccessExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsEc2InstanceInstanceProfileNotExistCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2InstanceInstanceProfileNotExistCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsEc2InstanceInstanceProfileNotExistCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsEc2InstanceInstanceProfileNotExistCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2InstanceInstanceProfileNotExistCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsEc2InstanceInstanceProfileNotExistRisk extends AggregatedEntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        aggregatedEntityIds: string[],
        aggregatingEntityTypeName: string,
        public autoScalingGroupIds: Optional<string[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2InstanceInstanceProfileNotExistRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            aggregatedEntityIds,
            aggregatingEntityTypeName);
    }
}

export class AwsEc2InstanceInstanceProfileNotExistRiskModel extends AggregatedEntityRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsEc2InstanceInstanceProfileNotExistRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public databricksDocumentationUrl: string,
        public spotDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2InstanceInstanceProfileNotExistRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsEc2InstanceMetadataServiceTokenHopMaxCountRisk extends AggregatedEntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        aggregatedEntityIds: string[],
        aggregatingEntityTypeName: string,
        public autoScalingGroupIds: Optional<string[]>,
        public highSeverityRolePermissionInstanceIds: string[],
        public instanceIdToDataMap: Dictionary<AwsEc2InstanceMetadataServiceTokenHopMaxCountRiskData>,
        public launchTemplateInstanceIdToRolePermissionActionSeverityMap: Optional<Dictionary<Severity>>,
        public maxTokenHopMaxCount: number,
        public publicInstanceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2InstanceMetadataServiceTokenHopMaxCountRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            aggregatedEntityIds,
            aggregatingEntityTypeName);
    }
}

export class AwsEc2InstanceMetadataServiceTokenHopMaxCountRiskModel extends AggregatedEntityRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsEc2InstanceMetadataServiceTokenHopMaxCountRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public databricksDocumentationUrl: string,
        public spotDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2InstanceMetadataServiceTokenHopMaxCountRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsEc2InstanceMetadataServiceVersionCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2InstanceMetadataServiceVersionCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsEc2InstanceMetadataServiceVersionCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsEc2InstanceMetadataServiceVersionCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2InstanceMetadataServiceVersionCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsEc2InstanceMetadataServiceVersionRisk extends AggregatedEntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        aggregatedEntityIds: string[],
        aggregatingEntityTypeName: string,
        public autoScalingGroupIds: Optional<string[]>,
        public highSeverityRolePermissionInstanceIds: string[],
        public instanceIdToDataMap: Dictionary<AwsEc2InstanceMetadataServiceVersionRiskData>,
        public instanceMetadataServiceVersionV1Used: boolean,
        public latestInstanceMetadataServiceVersionV1UsageTime: Optional<string>,
        public launchTemplateInstanceIdToRolePermissionActionSeverityMap: Optional<Dictionary<Severity>>,
        public publicInstanceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2InstanceMetadataServiceVersionRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            aggregatedEntityIds,
            aggregatingEntityTypeName);
    }
}

export class AwsEc2InstanceMetadataServiceVersionRiskModel extends AggregatedEntityRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsEc2InstanceMetadataServiceVersionRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public cloudWatchMetadataNoTokenMetricUrlInstanceRawIdsParameterDelimiter: string,
        public databricksDocumentationUrl: string,
        public instanceMetadataDocumentationUrl: string,
        public spotDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2InstanceMetadataServiceVersionRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsEc2InstanceModelFilters extends AwsResourceModelFilters implements IEntityModelFilters, IEntityNetworkFilters, IVirtualMachineDiskResourceFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        openRelatedEntityRiskTypeIdentifierItems: FilterItems<string>,
        regionIdItems: FilterItems<string>,
        tenantIdItems: FilterItems<string>,
        typeNameItems: FilterItems<string>,
        creationTimeRange: FilterTimeRange,
        public launchTimeRange: FilterTimeRange,
        public networkAccess: EntityNetworkAccessFiltersSection,
        public rawTypeItems: FilterItems<string>,
        public scanTimeRange: FilterTimeRange,
        public stopTimeRange: FilterTimeRange) {
        super(
            attributeValueItems,
            openRelatedEntityRiskTypeIdentifierItems,
            regionIdItems,
            tenantIdItems,
            typeNameItems,
            creationTimeRange);
    }
}

export class AwsEc2InstanceUserDataSecretExistsCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2InstanceUserDataSecretExistsCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsEc2InstanceUserDataSecretExistsCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsEc2InstanceUserDataSecretExistsCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2InstanceUserDataSecretExistsCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsEc2InstanceUserDataSecretExistsRisk extends AggregatedEntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        aggregatedEntityIds: string[],
        aggregatingEntityTypeName: string,
        public infos: AwsEc2InstanceUserDataSecretExistsRiskInfo[],
        public instanceIdToUserDataSecretExistsTextBlockMap: Optional<Dictionary<TextBlock>>,
        public launchConfigurationUserDataSecretExistsTextBlock: Optional<TextBlock>,
        public launchTemplateRevisionIdToUserDataSecretExistsTextBlockMap: Optional<Dictionary<TextBlock>>,
        public userDataExcludedSecrets: string[],
        public userDataSecrets: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2InstanceUserDataSecretExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            aggregatedEntityIds,
            aggregatingEntityTypeName);
    }
}

export class AwsEc2InstanceUserDataSecretExistsRiskModel extends AggregatedEntityRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel, ISecretExistsRiskModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsEc2InstanceUserDataSecretExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public databricksDocumentationUrl: string,
        public secretCount: number,
        public secretsManagerDocumentationUrl: string,
        public spotDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2InstanceUserDataSecretExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsEc2LoadBalancerListenerInsecureSslProtocolExistsCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2LoadBalancerListenerInsecureSslProtocolExistsCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsEc2LoadBalancerListenerInsecureSslProtocolExistsCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsEc2LoadBalancerListenerInsecureSslProtocolExistsCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2LoadBalancerListenerInsecureSslProtocolExistsCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsEc2LoadBalancerListenerInsecureSslProtocolExistsRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public insecureSslProtocolLoadBalancerListenerIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2LoadBalancerListenerInsecureSslProtocolExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsEc2LoadBalancerListenerInsecureSslProtocolExistsRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsEc2LoadBalancerListenerInsecureSslProtocolExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2LoadBalancerListenerInsecureSslProtocolExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsEc2NetworkAclInboundRuleSubnetAnyExistsCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2NetworkAclInboundRuleSubnetAnyExistsCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsEc2NetworkAclInboundRuleSubnetAnyExistsCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsEc2NetworkAclInboundRuleSubnetAnyExistsCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2NetworkAclInboundRuleSubnetAnyExistsCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsEc2NetworkResourceInboundRuleSubnetAnyExistsRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public attachedResourceIds: string[],
        public directWideRangeInboundExternalAttachedResourceIds: string[],
        public lambdaFunctionConfigurationOnlyAttached: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsEc2NetworkResourceInboundRuleSubnetAnyExistsRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        risk: Risk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public allPorts: boolean,
        public unrestrictedDestinationNetworkScopes: DestinationNetworkScope[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsEc2Resource extends AwsResource implements ITypeNameObject, IAwsResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public rawId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsEc2ResourceModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsEc2RouteTable extends AwsEc2Resource implements ITypeNameObject, IAwsTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        rawId: string,
        public defaultRouteTable: boolean,
        public routes: AwsEc2RouteTableRoute[],
        public vpcId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2RouteTable",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            rawId);
    }
}

export class AwsEc2RouteTableModel extends AwsEc2ResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public subnetIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2RouteTableModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsEc2SecurityGroup extends AwsEc2Resource implements ITypeNameObject, IAwsTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        rawId: string,
        public defaultSecurityGroup: boolean,
        public description: string,
        public inboundRules: AwsEc2SecurityGroupRule[],
        public outboundRules: AwsEc2SecurityGroupRule[],
        public vpcId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2SecurityGroup",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            rawId);
    }
}

export class AwsEc2SecurityGroupInboundRuleRisk extends CloudRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType);
    }
}

export class AwsEc2SecurityGroupInboundRuleRiskModel extends AwsCustomRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        risk: Risk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public networkScopes: DestinationNetworkScope[],
        public securityGroupIds: string[],
        public subnets: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsEc2SecurityGroupInboundRuleSubnetAnyExistsCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2SecurityGroupInboundRuleSubnetAnyExistsCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsEc2SecurityGroupInboundRuleSubnetAnyExistsCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsEc2SecurityGroupInboundRuleSubnetAnyExistsCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2SecurityGroupInboundRuleSubnetAnyExistsCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsEc2SecurityGroupInboundRuleSubnetAnyExistsRisk extends AwsEc2NetworkResourceInboundRuleSubnetAnyExistsRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        attachedResourceIds: string[],
        directWideRangeInboundExternalAttachedResourceIds: string[],
        lambdaFunctionConfigurationOnlyAttached: boolean,
        public unrestrictedInboundRules: AwsEc2SecurityGroupRule[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2SecurityGroupInboundRuleSubnetAnyExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            attachedResourceIds,
            directWideRangeInboundExternalAttachedResourceIds,
            lambdaFunctionConfigurationOnlyAttached);
    }
}

export class AwsEc2SecurityGroupInboundRuleSubnetAnyExistsRiskModel extends AwsEc2NetworkResourceInboundRuleSubnetAnyExistsRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsEc2SecurityGroupInboundRuleSubnetAnyExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        allPorts: boolean,
        unrestrictedDestinationNetworkScopes: DestinationNetworkScope[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2SecurityGroupInboundRuleSubnetAnyExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            allPorts,
            unrestrictedDestinationNetworkScopes);
    }
}

export class AwsEc2SecurityGroupManagementRiskModel extends AwsCustomActivityRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsEc2SecurityGroupManagementRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        originatorEntityIdReferences: string[],
        public descriptionType: AwsEc2SecurityGroupManagementRiskModelDescriptionType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2SecurityGroupManagementRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            originatorEntityIdReferences);
    }
}

export class AwsEc2SecurityGroupModel extends AwsEc2ResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public networkedResourceTypeNameToIdsMap: Dictionary<string[]>,
        public referencingSecurityGroupIds: string[],
        public wideRangeInboundRuleExists: Optional<boolean>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2SecurityGroupModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsEc2SecurityGroupNotAllowedInboundRuleRisk extends AwsEc2SecurityGroupInboundRuleRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2SecurityGroupNotAllowedInboundRuleRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType);
    }
}

export class AwsEc2SecurityGroupNotAllowedInboundRuleRiskModel extends AwsEc2SecurityGroupInboundRuleRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        risk: Risk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        networkScopes: DestinationNetworkScope[],
        securityGroupIds: string[],
        subnets: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2SecurityGroupNotAllowedInboundRuleRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            networkScopes,
            securityGroupIds,
            subnets);
    }
}

export class AwsEc2SecurityGroupUnusedRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public securityGroupResourceAttachInfo: AwsEc2SecurityGroupUnusedRiskResourceAttachInfo,
        public securityGroupUsageTime: Optional<string>,
        public tenantManagementEventStartDate: string,
        public usageTimeFrame: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2SecurityGroupUnusedRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsEc2SecurityGroupUnusedRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsEc2SecurityGroupUnusedRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2SecurityGroupUnusedRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsEc2Snapshot extends AwsEc2Resource implements ITypeNameObject, IAwsAccessResource, IAwsEncryptedResource, IAwsTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        rawId: string,
        public accessLevel: Optional<AwsResourceAccessLevel>,
        public accessLevelUpdateTime: string,
        public awsManaged: boolean,
        public description: Optional<string>,
        public encryptionExists: boolean,
        public kmsEncryptionKeyOrAliasArns: string[],
        public sharedTenantRawIds: string[],
        public size: number,
        public sourceVolumeId: Optional<string>,
        public sourceVolumeRawId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2Snapshot",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            rawId);
    }
}

export class AwsEc2SnapshotEncryptionDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2SnapshotEncryptionDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsEc2SnapshotEncryptionDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsEc2SnapshotEncryptionDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2SnapshotEncryptionDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsEc2SnapshotEncryptionDisabledRisk extends AggregatedEntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        aggregatedEntityIds: string[],
        aggregatingEntityTypeName: string,
        public accessLevel: Optional<AwsResourceAccessLevel>,
        public data: AwsEc2SnapshotEncryptionDisabledRiskData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2SnapshotEncryptionDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            aggregatedEntityIds,
            aggregatingEntityTypeName);
    }
}

export class AwsEc2SnapshotEncryptionDisabledRiskModel extends AggregatedEntityRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsEc2SnapshotEncryptionDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public ebsEncryptionDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2SnapshotEncryptionDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsEc2SnapshotModel extends AwsEc2ResourceModel implements ITypeNameObject, IUdmObjectModel, IAwsAccessResourceModel, IAwsEncryptedResourceModel, IRelatedTenantsEntityModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public accessLevel: Optional<AwsResourceAccessLevel>,
        public imageIds: string[],
        public kmsEncryptionKeyIdReferences: string[],
        public sourceVolumeInstanceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2SnapshotModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsEc2SnapshotPublicAccessExistsRisk extends PublicEntityRisk implements ITypeNameObject, IPublicRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public sourceVolumeId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2SnapshotPublicAccessExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsEc2SnapshotPublicAccessExistsRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsEc2SnapshotPublicAccessExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2SnapshotPublicAccessExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsEc2SpotInstanceGroup extends AwsTagResourceGroup implements ITypeNameObject, IAwsResource, IEntityGroup {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        definitionId: Optional<string>,
        groupTags: ResourceTag[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2SpotInstanceGroup",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            definitionId,
            groupTags);
    }
}

export class AwsEc2SpotInstanceGroupDefinitionConfiguration extends TagResourceGroupDefinitionConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        entityTypeName: string,
        name: string,
        groupTagKeys: string[],
        nameTagKey: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2SpotInstanceGroupDefinitionConfiguration",
            scopeId,
            entityTypeName,
            name,
            groupTagKeys,
            nameTagKey);
    }
}

export class AwsEc2Subnet extends AwsEc2Resource implements ITypeNameObject, IAwsTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        rawId: string,
        public autoAssignPublicIpv4Addresses: boolean,
        public autoAssignPublicIpv6Addresses: boolean,
        public availabilityZone: AwsAvailabilityZone,
        public defaultSubnet: boolean,
        public flowLogIds: string[],
        public subnets: string[],
        public vpcId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2Subnet",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            rawId);
    }
}

export class AwsEc2SubnetModel extends AwsEc2ResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public flowLogDestinationResourceIdReferences: string[],
        public flowLogFilter: Optional<AwsEc2FlowLogFilter>,
        public networkedResourceTypeNameToIdsMap: Dictionary<string[]>,
        public networkInterfaceSecurityGroupIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2SubnetModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsEc2SubnetStateNetwork extends AwsNetworkAccessResourceStateNetwork implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        entityReference: EntityReference,
        inboundAccessType: NetworkInboundAccessType,
        inboundExternal: boolean,
        inboundExternalAccessScope: NetworkAccessScope,
        inboundExternalDestinationNetworkScopes: Optional<DestinationNetworkScope[]>,
        inboundExternalWideRange: Optional<boolean>,
        public internetGatewayId: Optional<string>,
        public networkAclId: Optional<string>,
        public routeTableId: Optional<string>,
        public vpcBlockPublicAccessPolicyBlockNetworkDirection: AwsEc2VpcBlockPublicAccessPolicyNetworkDirection,
        public vpcBlockPublicAccessPolicyId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2SubnetStateNetwork",
            tenantId,
            entityReference,
            inboundAccessType,
            inboundExternal,
            inboundExternalAccessScope,
            inboundExternalDestinationNetworkScopes,
            inboundExternalWideRange);
    }
}

export class AwsEc2Volume extends AwsEc2Resource implements ITypeNameObject, IAwsEncryptedResource, IAwsTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        rawId: string,
        public availabilityZone: AwsAvailabilityZone,
        public encryptionExists: boolean,
        public instanceRawIdToDeviceNameMap: Dictionary<string>,
        public instanceTerminationDeletion: boolean,
        public kmsEncryptionKeyOrAliasArns: string[],
        public productCode: Optional<AwsResourceProductCode>,
        public rawType: string,
        public size: number,
        public sourceSnapshotRawId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2Volume",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            rawId);
    }
}

export class AwsEc2VolumeEncryptionDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2VolumeEncryptionDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsEc2VolumeEncryptionDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsEc2VolumeEncryptionDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2VolumeEncryptionDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsEc2VolumeEncryptionDisabledRisk extends AggregatedEntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        aggregatedEntityIds: string[],
        aggregatingEntityTypeName: string,
        public data: AwsEc2VolumeEncryptionDisabledRiskData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2VolumeEncryptionDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            aggregatedEntityIds,
            aggregatingEntityTypeName);
    }
}

export class AwsEc2VolumeEncryptionDisabledRiskModel extends AggregatedEntityRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsEc2VolumeEncryptionDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2VolumeEncryptionDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsEc2VolumeModel extends AwsEc2ResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAwsEncryptedResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public instanceIds: string[],
        public kmsEncryptionKeyIdReferences: string[],
        public root: boolean,
        public snapshotIds: string[],
        public sourceSnapshotId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2VolumeModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsEc2Vpc extends AwsEc2Resource implements ITypeNameObject, IAwsTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        rawId: string,
        public defaultVpc: boolean,
        public flowLogIds: string[],
        public subnets: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2Vpc",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            rawId);
    }
}

export class AwsEc2VpcBlockPublicAccessPolicy extends AwsResource implements ITypeNameObject, IAwsResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public blockNetworkDirection: AwsEc2VpcBlockPublicAccessPolicyNetworkDirection) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2VpcBlockPublicAccessPolicy",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsEc2VpcBlockPublicAccessPolicyModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public enabled: boolean,
        public resourceIdToExclusionMap: Dictionary<AwsEc2VpcBlockPublicAccessPolicyExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2VpcBlockPublicAccessPolicyModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsEc2VpcDefaultSecurityGroupRuleExistsRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public securityGroupId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2VpcDefaultSecurityGroupRuleExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsEc2VpcDefaultSecurityGroupRuleExistsRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsEc2VpcDefaultSecurityGroupRuleExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2VpcDefaultSecurityGroupRuleExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsEc2VpcEndpoint extends AwsEc2Resource implements ITypeNameObject, IAwsTagResource, IAwsNetworkedResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        rawId: string,
        public dnsNames: string[],
        public networkInterfaceIds: string[],
        public securityGroupIds: string[],
        public serviceName: Optional<string>,
        public servicePolicyDocument: AwsIamPolicyDocument,
        public status: AwsEc2VpcEndpointStatus,
        public subnetIds: string[],
        public type: AwsEc2VpcEndpointType,
        public unrestrictedPrincipalsAccess: boolean,
        public unrestrictedResourcesAccess: boolean,
        public vpcIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2VpcEndpoint",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            rawId);
    }
}

export class AwsEc2VpcEndpointModel extends AwsEc2ResourceModel implements ITypeNameObject, IUdmObjectModel, IAwsNetworkedResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public routeTableIds: string[],
        public subnetIds: string[],
        public subnetIdToNetworkInterfaceIdMap: Dictionary<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2VpcEndpointModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsEc2VpcEndpointUnrestrictedServiceAccessRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2VpcEndpointUnrestrictedServiceAccessRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsEc2VpcEndpointUnrestrictedServiceAccessRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        risk: Risk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public newPolicyFileTypeToFileMap: Dictionary<RiskFile>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2VpcEndpointUnrestrictedServiceAccessRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsEc2VpcModel extends AwsEc2ResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public defaultSecurityGroupId: Optional<string>,
        public flowLogDestinationResourceIdReferences: string[],
        public flowLogFilter: Optional<AwsEc2FlowLogFilter>,
        public hostedZoneIds: string[],
        public networkedResourceTypeNameToIdsMap: Dictionary<string[]>,
        public securityGroupIds: string[],
        public subnetIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2VpcModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsEc2VpcPeeringConnection extends AwsEc2Resource implements ITypeNameObject, IAwsTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        rawId: string,
        public accepterVpcArn: string,
        public accepterVpcSubnets: string[],
        public requesterVpcArn: string,
        public requesterVpcSubnets: string[],
        public status: AwsEc2VpcPeeringConnectionStatus) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2VpcPeeringConnection",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            rawId);
    }
}

export class AwsEc2VpcPeeringConnectionModel extends AwsEc2ResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public accepterVpcIdReference: string,
        public requesterVpcIdReference: string,
        public respectiveVpcPeeringConnectionId: Optional<string>,
        public routeTableIds: string[],
        public routeTableSubnetIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2VpcPeeringConnectionModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsEc2VpcRejectFlowLogNotExistCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2VpcRejectFlowLogNotExistCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsEc2VpcRejectFlowLogNotExistCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsEc2VpcRejectFlowLogNotExistCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2VpcRejectFlowLogNotExistCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsEc2VpcRejectFlowLogNotExistRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2VpcRejectFlowLogNotExistRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsEc2VpcRejectFlowLogNotExistRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsEc2VpcRejectFlowLogNotExistRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2VpcRejectFlowLogNotExistRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsEcrRepositoryModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IAwsAccessResourceModel, IAwsEncryptedResourceModel, IAwsPolicyResourceModel, IContainerImageRepositoryModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public accessLevel: Optional<AwsResourceAccessLevel>,
        public containerImageResourceIds: string[],
        public containerImageScanStatus: Optional<ContainerImageDataScanStatus>,
        public containerImageVulnerabilities: VulnerabilityData[],
        public dnsZoneIds: string[],
        public kmsEncryptionKeyIdReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEcrRepositoryModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsEcrRepositoryPublicAccessExistsCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEcrRepositoryPublicAccessExistsCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsEcrRepositoryPublicAccessExistsCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsEcrRepositoryPublicAccessExistsCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEcrRepositoryPublicAccessExistsCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsEcrRepositoryPublicAccessExistsRisk extends PublicEntityRisk implements ITypeNameObject, IPublicRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEcrRepositoryPublicAccessExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsEcrRepositoryPublicAccessExistsRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsEcrRepositoryPublicAccessExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEcrRepositoryPublicAccessExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsEcrRepositorySnapshot extends AwsEntitySnapshot implements ITypeNameObject, IAwsPolicyResourceSnapshot {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        changes: EntitySnapshotChange[],
        entityId: string,
        relatedEntityIds: string[],
        relatedEntitySnapshotIds: string[],
        public policyDocument: Optional<AwsIamPolicyDocument>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEcrRepositorySnapshot",
            tenantId,
            changes,
            entityId,
            relatedEntityIds,
            relatedEntitySnapshotIds);
    }
}

export class AwsEcrResource extends AwsResource implements ITypeNameObject, IAwsResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsEcsClusterModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEcsClusterModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsEcsResource extends AwsResource implements ITypeNameObject, IAwsResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsEcsService extends AwsEcsResource implements ITypeNameObject, IAwsNetworkEvaluationNetworkedResource, IAwsOriginatorComputeResource, IAwsTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public clusterId: string,
        public clusterName: string,
        public launchType: Optional<AwsEcsServiceLaunchType>,
        public securityGroupIds: string[],
        public serviceLinkedRoleReference: Optional<AwsResourceReference>,
        public subnetIds: string[],
        public taskDefinitionReference: Optional<AwsResourceReference>,
        public taskDefinitionRevisionArn: Optional<string>,
        public vpcIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEcsService",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsEcsServiceModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IAwsNetworkedResourceModel, IContainerImageResourceModel, INetworkAccessResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        public entityNetwork: Optional<NetworkAccessResourceStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public containerImageIdReferences: string[],
        public containerImageScanStatus: Optional<ContainerImageDataScanStatus>,
        public containerImageVulnerabilities: VulnerabilityData[],
        public networkInterfaceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEcsServiceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsEcsTask extends AwsEcsResource implements ITypeNameObject, IAwsDnsRecordResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public availabilityZone: Optional<AwsAvailabilityZone>,
        public clusterId: string,
        public dnsNames: string[],
        public networkInterfaceIds: string[],
        public publicIpAddresses: string[],
        public rawId: string,
        public serviceId: Optional<string>,
        public taskDefinitionReference: AwsResourceReference,
        public taskDefinitionRevisionArn: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEcsTask",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsEcsTaskDefinition extends AwsEcsResource implements ITypeNameObject, IAwsOriginatorComputeResource, IAwsTagResource, IContainerImageResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public containerImageRawUrls: string[],
        public latestRevisionId: Optional<number>,
        public revisionMap: Dictionary<AwsEcsTaskDefinitionRevision>,
        public roleIds: string[],
        public status: AwsEcsTaskDefinitionStatus) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEcsTaskDefinition",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsEcsTaskDefinitionEnvironmentVariableSecretExistsCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEcsTaskDefinitionEnvironmentVariableSecretExistsCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsEcsTaskDefinitionEnvironmentVariableSecretExistsCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsEcsTaskDefinitionEnvironmentVariableSecretExistsCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEcsTaskDefinitionEnvironmentVariableSecretExistsCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsEcsTaskDefinitionEnvironmentVariableSecretExistsRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public excludedSecretEnvironmentVariableNames: string[],
        public secretEnvironmentVariableNameToContainerDefinitionNameToRevisionIdsMap: Dictionary<Dictionary<number[]>>,
        public taskDefinitionRegionSystemName: string,
        public taskDefinitionStatus: AwsEcsTaskDefinitionStatus) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEcsTaskDefinitionEnvironmentVariableSecretExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsEcsTaskDefinitionEnvironmentVariableSecretExistsRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel, ISecretExistsRiskModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsEcsTaskDefinitionEnvironmentVariableSecretExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public secretCount: number,
        public secretsManagerDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEcsTaskDefinitionEnvironmentVariableSecretExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsEcsTaskDefinitionModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IContainerImageResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public containerImageIdReferences: string[],
        public containerImageRawUrlToDataMap: Dictionary<ContainerImageResourceStateContainerImageData>,
        public containerImageScanStatus: Optional<ContainerImageDataScanStatus>,
        public containerImageVulnerabilities: VulnerabilityData[],
        public networkInterfaceIds: string[],
        public serviceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEcsTaskDefinitionModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsEcsTaskModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public dnsZoneIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEcsTaskModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsEksClusterInboundExternalWideRangeRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEksClusterInboundExternalWideRangeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsEksClusterInboundExternalWideRangeRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsEksClusterInboundExternalWideRangeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEksClusterInboundExternalWideRangeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsEksClusterInstanceInboundExternalRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public instanceDatas: AwsEksClusterInstanceInboundExternalRiskInstanceData[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEksClusterInstanceInboundExternalRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsEksClusterInstanceInboundExternalRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsEksClusterInstanceInboundExternalRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public eksClusterSubnetDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEksClusterInstanceInboundExternalRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsEksClusterKubernetesSecretEncryptionDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEksClusterKubernetesSecretEncryptionDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsEksClusterKubernetesSecretEncryptionDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsEksClusterKubernetesSecretEncryptionDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEksClusterKubernetesSecretEncryptionDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsEksClusterKubernetesSecretEncryptionDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEksClusterKubernetesSecretEncryptionDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsEksClusterKubernetesSecretEncryptionDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsEksClusterKubernetesSecretEncryptionDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public clusterSecretApplicationLayerEncryptionDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEksClusterKubernetesSecretEncryptionDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsEksClusterModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IAwsEncryptedResourceModel, IAwsNetworkedResourceModel, IKubernetesClusterModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public apiConnectivityStatus: Optional<SystemKubernetesClusterApiConnectivityStatus>,
        public autoScalingGroupIds: string[],
        public dnsZoneIds: string[],
        public fargateProfileIds: string[],
        public helm: KubernetesClusterModelHelm,
        public kmsEncryptionKeyIdReferences: string[],
        public nodeGroupIds: string[],
        public resourceUpdateTime: Optional<string>,
        public statusValue: number,
        public wideRangeAuthorizedSubnets: Optional<string[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEksClusterModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsEksClusterModelFilters extends AwsResourceModelFilters implements IManagedKubernetesClusterModelFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        openRelatedEntityRiskTypeIdentifierItems: FilterItems<string>,
        regionIdItems: FilterItems<string>,
        tenantIdItems: FilterItems<string>,
        typeNameItems: FilterItems<string>,
        creationTimeRange: FilterTimeRange,
        public kubernetesCluster: IKubernetesClusterModelFiltersSection) {
        super(
            attributeValueItems,
            openRelatedEntityRiskTypeIdentifierItems,
            regionIdItems,
            tenantIdItems,
            typeNameItems,
            creationTimeRange);
    }
}

export class AwsEksClusterNodeGroupModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IAwsNetworkedResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public autoScalingGroupSearchableReferences: EntitySearchableReference[],
        public clusterSearchableReference: EntitySearchableReference,
        public statusValue: number) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEksClusterNodeGroupModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsEksFargateProfileModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEksFargateProfileModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsEksResource extends AwsResource implements ITypeNameObject, IAwsResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsElastiCacheCache extends AwsResource implements ITypeNameObject, IAwsEncryptedResource, IAwsNetworkedResource, IAwsTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public autoFailover: AwsElastiCacheCacheAutoFailover,
        public automaticBackups: boolean,
        public autoUpgradeMinorVersions: boolean,
        public backupRetentionTimeFrame: string,
        public description: Optional<string>,
        public encryptionExists: boolean,
        public endpoint: Optional<string>,
        public engineVersion: Optional<string>,
        public kmsEncryptionKeyOrAliasArn: Optional<string>,
        public kmsEncryptionKeyOrAliasArns: string[],
        public securityGroupIds: string[],
        public serverSideEncryptionEnabled: boolean,
        public status: AwsElastiCacheCacheStatus,
        public subnetIds: string[],
        public transitEncryptionEnabled: boolean,
        public vpcIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsElastiCacheCacheModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IAwsEncryptedResourceModel, IAwsNetworkedResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public deploymentType: AwsElastiCacheCacheDeploymentType,
        public kmsEncryptionKeyIdReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsElastiCacheReplicationGroup extends AwsElastiCacheCache implements ITypeNameObject, IAwsEncryptedResource, IAwsNetworkedResource, IAwsTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        autoFailover: AwsElastiCacheCacheAutoFailover,
        automaticBackups: boolean,
        autoUpgradeMinorVersions: boolean,
        backupRetentionTimeFrame: string,
        description: Optional<string>,
        encryptionExists: boolean,
        endpoint: Optional<string>,
        engineVersion: Optional<string>,
        kmsEncryptionKeyOrAliasArn: Optional<string>,
        kmsEncryptionKeyOrAliasArns: string[],
        securityGroupIds: string[],
        serverSideEncryptionEnabled: boolean,
        status: AwsElastiCacheCacheStatus,
        subnetIds: string[],
        transitEncryptionEnabled: boolean,
        vpcIds: string[],
        public clusterMode: AwsElastiCacheReplicationGroupClusterMode,
        public networkTypes: AwsElastiCacheReplicationGroupNetworkType[],
        public nodeType: Optional<string>,
        public subnetGroupName: Optional<string>,
        public transitEncryptionMode: Optional<AwsElastiCacheReplicationGroupTransitEncryptionMode>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsElastiCacheReplicationGroup",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            autoFailover,
            automaticBackups,
            autoUpgradeMinorVersions,
            backupRetentionTimeFrame,
            description,
            encryptionExists,
            endpoint,
            engineVersion,
            kmsEncryptionKeyOrAliasArn,
            kmsEncryptionKeyOrAliasArns,
            securityGroupIds,
            serverSideEncryptionEnabled,
            status,
            subnetIds,
            transitEncryptionEnabled,
            vpcIds);
    }
}

export class AwsElastiCacheReplicationGroupAutoFailoverDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsElastiCacheReplicationGroupAutoFailoverDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsElastiCacheReplicationGroupAutoFailoverDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsElastiCacheReplicationGroupAutoFailoverDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsElastiCacheReplicationGroupAutoFailoverDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsElastiCacheReplicationGroupAutoFailoverDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsElastiCacheReplicationGroupAutoFailoverDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsElastiCacheReplicationGroupAutoFailoverDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsElastiCacheReplicationGroupAutoFailoverDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public elastiCacheReplicationReadReplicaDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsElastiCacheReplicationGroupAutoFailoverDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsElastiCacheReplicationGroupAutomaticBackupsDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsElastiCacheReplicationGroupAutomaticBackupsDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsElastiCacheReplicationGroupAutomaticBackupsDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsElastiCacheReplicationGroupAutomaticBackupsDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsElastiCacheReplicationGroupAutomaticBackupsDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsElastiCacheReplicationGroupAutomaticBackupsDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public backupRetentionTimeFrame: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsElastiCacheReplicationGroupAutomaticBackupsDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsElastiCacheReplicationGroupAutomaticBackupsDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsElastiCacheReplicationGroupAutomaticBackupsDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsElastiCacheReplicationGroupAutomaticBackupsDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsElastiCacheReplicationGroupAutoUpgradeMinorVersionsDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsElastiCacheReplicationGroupAutoUpgradeMinorVersionsDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsElastiCacheReplicationGroupAutoUpgradeMinorVersionsDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsElastiCacheReplicationGroupAutoUpgradeMinorVersionsDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsElastiCacheReplicationGroupAutoUpgradeMinorVersionsDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsElastiCacheReplicationGroupAutoUpgradeMinorVersionsDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsElastiCacheReplicationGroupAutoUpgradeMinorVersionsDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsElastiCacheReplicationGroupAutoUpgradeMinorVersionsDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsElastiCacheReplicationGroupAutoUpgradeMinorVersionsDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsElastiCacheReplicationGroupAutoUpgradeMinorVersionsDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsElastiCacheReplicationGroupDefaultSubnetGroupCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsElastiCacheReplicationGroupDefaultSubnetGroupCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsElastiCacheReplicationGroupDefaultSubnetGroupCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsElastiCacheReplicationGroupDefaultSubnetGroupCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsElastiCacheReplicationGroupDefaultSubnetGroupCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsElastiCacheReplicationGroupDefaultSubnetGroupRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsElastiCacheReplicationGroupDefaultSubnetGroupRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsElastiCacheReplicationGroupDefaultSubnetGroupRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsElastiCacheReplicationGroupDefaultSubnetGroupRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsElastiCacheReplicationGroupDefaultSubnetGroupRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsElastiCacheReplicationGroupEncryptionDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsElastiCacheReplicationGroupEncryptionDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsElastiCacheReplicationGroupEncryptionDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsElastiCacheReplicationGroupEncryptionDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsElastiCacheReplicationGroupEncryptionDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsElastiCacheReplicationGroupModel extends AwsElastiCacheCacheModel implements ITypeNameObject, IUdmObjectModel, IAwsEncryptedResourceModel, IAwsNetworkedResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        deploymentType: AwsElastiCacheCacheDeploymentType,
        kmsEncryptionKeyIdReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsElastiCacheReplicationGroupModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId,
            deploymentType,
            kmsEncryptionKeyIdReferences);
    }
}

export class AwsElastiCacheReplicationGroupTransitEncryptionModeNotRequiredCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsElastiCacheReplicationGroupTransitEncryptionModeNotRequiredCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsElastiCacheReplicationGroupTransitEncryptionModeNotRequiredCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsElastiCacheReplicationGroupTransitEncryptionModeNotRequiredCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsElastiCacheReplicationGroupTransitEncryptionModeNotRequiredCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsElastiCacheReplicationGroupTransitEncryptionModeNotRequiredRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsElastiCacheReplicationGroupTransitEncryptionModeNotRequiredRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsElastiCacheReplicationGroupTransitEncryptionModeNotRequiredRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsElastiCacheReplicationGroupTransitEncryptionModeNotRequiredRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsElastiCacheReplicationGroupTransitEncryptionModeNotRequiredRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsElastiCacheServerlessCache extends AwsElastiCacheCache implements ITypeNameObject, IAwsEncryptedResource, IAwsNetworkedResource, IAwsTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        autoFailover: AwsElastiCacheCacheAutoFailover,
        automaticBackups: boolean,
        autoUpgradeMinorVersions: boolean,
        backupRetentionTimeFrame: string,
        description: Optional<string>,
        encryptionExists: boolean,
        endpoint: Optional<string>,
        engineVersion: Optional<string>,
        kmsEncryptionKeyOrAliasArn: Optional<string>,
        kmsEncryptionKeyOrAliasArns: string[],
        securityGroupIds: string[],
        serverSideEncryptionEnabled: boolean,
        status: AwsElastiCacheCacheStatus,
        subnetIds: string[],
        transitEncryptionEnabled: boolean,
        vpcIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsElastiCacheServerlessCache",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            autoFailover,
            automaticBackups,
            autoUpgradeMinorVersions,
            backupRetentionTimeFrame,
            description,
            encryptionExists,
            endpoint,
            engineVersion,
            kmsEncryptionKeyOrAliasArn,
            kmsEncryptionKeyOrAliasArns,
            securityGroupIds,
            serverSideEncryptionEnabled,
            status,
            subnetIds,
            transitEncryptionEnabled,
            vpcIds);
    }
}

export class AwsElastiCacheServerlessCacheModel extends AwsElastiCacheCacheModel implements ITypeNameObject, IUdmObjectModel, IAwsEncryptedResourceModel, IAwsNetworkedResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        deploymentType: AwsElastiCacheCacheDeploymentType,
        kmsEncryptionKeyIdReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsElastiCacheServerlessCacheModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId,
            deploymentType,
            kmsEncryptionKeyIdReferences);
    }
}

export class AwsElasticBeanstalkApplicationModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsElasticBeanstalkApplicationModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsElasticBeanstalkEnvironmentCloudWatchLoggingDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public cloudWatchLogRetentionTimeFrame: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsElasticBeanstalkEnvironmentCloudWatchLoggingDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsElasticBeanstalkEnvironmentCloudWatchLoggingDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsElasticBeanstalkEnvironmentCloudWatchLoggingDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsElasticBeanstalkEnvironmentCloudWatchLoggingDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsElasticBeanstalkEnvironmentModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAwsCloudWatchLoggingResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public dnsZoneIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsElasticBeanstalkEnvironmentModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsElasticBeanstalkResource extends AwsResource implements ITypeNameObject, IAwsResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public description: Optional<string>,
        public updateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsElasticFileSystemFileSystem extends AwsResource implements ITypeNameObject, IAwsEncryptedResource, IAwsTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public encryptionExists: boolean,
        public kmsEncryptionKeyOrAliasArns: string[],
        public rawId: string,
        public storageSize: number) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsElasticFileSystemFileSystem",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsElasticFileSystemFileSystemEncryptionDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsElasticFileSystemFileSystemEncryptionDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsElasticFileSystemFileSystemEncryptionDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsElasticFileSystemFileSystemEncryptionDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsElasticFileSystemFileSystemEncryptionDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsElasticFileSystemFileSystemEncryptionDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsElasticFileSystemFileSystemEncryptionDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsElasticFileSystemFileSystemEncryptionDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsElasticFileSystemFileSystemEncryptionDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsElasticFileSystemFileSystemEncryptionDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsElasticFileSystemFileSystemModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAwsEncryptedResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public kmsEncryptionKeyIdReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsElasticFileSystemFileSystemModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsElbLoadBalancerListenerModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsElbLoadBalancerModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IAwsNetworkedResourceModel, INetworkAccessResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        public entityNetwork: Optional<NetworkAccessResourceStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public autoScalingGroupIds: string[],
        public dnsZoneIds: string[],
        public listenerIds: string[],
        public targetGroupIds: string[],
        public type: AwsElbLoadBalancerModelType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsElbLoadBalancerModelFilters extends AwsResourceModelFilters implements IEntityModelFilters, IEntityNetworkFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        openRelatedEntityRiskTypeIdentifierItems: FilterItems<string>,
        regionIdItems: FilterItems<string>,
        tenantIdItems: FilterItems<string>,
        typeNameItems: FilterItems<string>,
        creationTimeRange: FilterTimeRange,
        public networkAccess: EntityNetworkAccessFiltersSection) {
        super(
            attributeValueItems,
            openRelatedEntityRiskTypeIdentifierItems,
            regionIdItems,
            tenantIdItems,
            typeNameItems,
            creationTimeRange);
    }
}

export class AwsElbNetworkLoadBalancerListenerModel extends AwsElbLoadBalancerListenerModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsElbNetworkLoadBalancerListenerModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsElbNetworkLoadBalancerModel extends AwsElbLoadBalancerModel implements ITypeNameObject, IUdmObjectModel, IAwsNetworkedResourceModel, INetworkAccessResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        public entityNetwork: Optional<NetworkAccessResourceStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        autoScalingGroupIds: string[],
        dnsZoneIds: string[],
        listenerIds: string[],
        targetGroupIds: string[],
        type: AwsElbLoadBalancerModelType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsElbNetworkLoadBalancerModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId,
            autoScalingGroupIds,
            dnsZoneIds,
            listenerIds,
            targetGroupIds,
            type);
    }
}

export class AwsElbResource extends AwsResource implements ITypeNameObject, IAwsResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsElbTargetGroup extends AwsElbResource implements ITypeNameObject, IAwsResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public applicationLoadBalancerIds: string[],
        public functionConfigurationIds: string[],
        public instanceIds: string[],
        public ipAddresses: string[],
        public loadBalancerArns: string[],
        public port: Optional<number>,
        public protocol: Optional<AwsElbProtocol>,
        public targets: AwsElbTargetGroupTarget[],
        public type: AwsElbTargetGroupType,
        public vpcId: Optional<string>,
        public vpcRawId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsElbTargetGroup",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsElbTargetGroupModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsElbTargetGroupModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsEmrCluster extends AwsResource implements ITypeNameObject, IAwsNetworkedResource, IAwsTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public applications: AwsEmrClusterApplication[],
        public autoScalingRoleArn: Optional<string>,
        public autoTermination: boolean,
        public dnsName: string,
        public instanceCollectionTypeToInstanceReferencesMap: Dictionary<AwsResourceReference[]>,
        public instanceRoleReference: Optional<AwsResourceReference>,
        public keyName: Optional<string>,
        public logsBucketArn: Optional<string>,
        public rawId: string,
        public releaseLabel: string,
        public securityConfigurationName: Optional<string>,
        public securityGroupIds: string[],
        public serviceRoleArn: string,
        public status: AwsEmrClusterStatus,
        public subnetIds: string[],
        public terminationTime: Optional<string>,
        public vpcIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEmrCluster",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsEmrClusterModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IAwsNetworkedResourceModel, INetworkAccessResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        public entityNetwork: Optional<NetworkAccessResourceStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public autoScalingRoleReference: Optional<string>,
        public logsBucketReference: Optional<string>,
        public serviceRoleReference: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEmrClusterModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsEmrSecurityConfiguration extends AwsResource implements ITypeNameObject, IAwsResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public volumeKmsEncryptionKey: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEmrSecurityConfiguration",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsEmrSecurityConfigurationModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEmrSecurityConfigurationModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsEncryptedResourceKmsEncryptionNotExistsRisk extends CloudRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEncryptedResourceKmsEncryptionNotExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType);
    }
}

export class AwsEncryptedResourceKmsEncryptionNotExistsRiskModel extends AwsCustomRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsEncryptedResourceKmsEncryptionNotExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public anyCustomerManagedKmsKey: boolean,
        public anyKmsKey: boolean,
        public kmsKeyBuiltInAttributeTypeNames: Optional<string[]>,
        public kmsKeyCustomAttributeDefinitionIds: Optional<string[]>,
        public kmsKeyIds: Optional<string[]>,
        public kmsKeyTags: Optional<ResourceTag[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEncryptedResourceKmsEncryptionNotExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsEsDomain extends AwsResource implements ITypeNameObject, IAwsAccessResource, IAwsCloudWatchLoggingResource, IAwsDnsRecordResource, IAwsEncryptedResource, IAwsNetworkedResource, IAwsPolicyResource, IAwsTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public accessLevel: Optional<AwsResourceAccessLevel>,
        public availabilityZones: AwsAvailabilityZone[],
        public cloudWatchLoggingEnabled: boolean,
        public cloudWatchLogTypes: string[],
        public dnsNames: string[],
        public encryptionExists: boolean,
        public engineType: AwsEsDomainEngineType,
        public engineVersion: string,
        public httpsEnabled: boolean,
        public kmsEncryptionKeyOrAliasArns: string[],
        public nodeCommunicationEncryptionEnabled: boolean,
        public policyDocument: Optional<AwsIamPolicyDocument>,
        public policyDocumentExists: boolean,
        public publicAccessHighSeveritySensitivePermissionActions: string[],
        public publicAccessNonKmsEncryptedDataPermissionActions: string[],
        public publicAccessPermissionActions: string[],
        public publicIpAddresses: Optional<string[]>,
        public securityGroupIds: string[],
        public storageKmsEncryptionKeyOrAliasArn: Optional<string>,
        public storageSize: number,
        public subnetIds: string[],
        public vpcIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEsDomain",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsEsDomainCloudWatchLoggingDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEsDomainCloudWatchLoggingDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsEsDomainCloudWatchLoggingDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsEsDomainCloudWatchLoggingDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEsDomainCloudWatchLoggingDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsEsDomainEncryptionDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEsDomainEncryptionDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsEsDomainEncryptionDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsEsDomainEncryptionDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEsDomainEncryptionDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsEsDomainEncryptionDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEsDomainEncryptionDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsEsDomainEncryptionDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsEsDomainEncryptionDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEsDomainEncryptionDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsEsDomainModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IAwsAccessResourceModel, IAwsCloudWatchLoggingResourceModel, IAwsEncryptedResourceModel, IAwsNetworkedResourceModel, IAwsPolicyResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public accessLevel: Optional<AwsResourceAccessLevel>,
        public dnsZoneIds: string[],
        public kmsEncryptionKeyIdReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEsDomainModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsEsDomainNodeCommunicationEncryptionDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEsDomainNodeCommunicationEncryptionDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsEsDomainNodeCommunicationEncryptionDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsEsDomainNodeCommunicationEncryptionDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEsDomainNodeCommunicationEncryptionDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsEsDomainNodeCommunicationEncryptionDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEsDomainNodeCommunicationEncryptionDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsEsDomainNodeCommunicationEncryptionDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsEsDomainNodeCommunicationEncryptionDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEsDomainNodeCommunicationEncryptionDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsEsDomainPublicAccessExistsCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEsDomainPublicAccessExistsCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsEsDomainPublicAccessExistsCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsEsDomainPublicAccessExistsCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEsDomainPublicAccessExistsCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsEsDomainPublicAccessExistsRisk extends PublicEntityRisk implements ITypeNameObject, IPublicRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEsDomainPublicAccessExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsEsDomainPublicAccessExistsRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsEsDomainPublicAccessExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEsDomainPublicAccessExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsEventAnalysisServiceConfiguration extends ServiceConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        public awsEventSyncer: AwsEventSyncerConfigurationSection) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEventAnalysisServiceConfiguration");
    }
}

export class AwsExcessivePermissionPrincipalRisk extends AwsAccessPrincipalRisk implements ITypeNameObject, IAwsExcessivePermissionPrincipalRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        activityTime: Optional<string>,
        excessivePermissionActionSeverity: Optional<Severity>,
        groupUserIds: string[],
        permissionActions: string[],
        roleMostIndirectOriginatorResourceIds: string[],
        roleOriginatorResourceIds: string[],
        sensitiveResources: AwsAccessPrincipalRiskSensitiveResources,
        serviceIdToUsageTimeMap: Dictionary<Optional<string>>,
        public policyAssociationAndInfos: AwsExcessivePermissionPrincipalRiskPolicyAssociationAndInfo[],
        public resolutionChangeIdToPolicyInfoMap: Dictionary<AwsExcessivePermissionPrincipalRiskResolutionPolicyInfo>,
        public resourceLevelServiceIds: string[],
        public serviceLevel: Optional<AwsPrincipalNonexcessivePolicyGeneratorServiceLevel>,
        public serviceResourceLevel: Optional<AwsPrincipalNonexcessivePolicyGeneratorServiceResourceLevel>,
        public serviceResourceLevelNotUsedPermissionActionResourceIds: string[],
        public serviceResourceLevels: AwsPrincipalNonexcessivePolicyGeneratorServiceResourceLevel[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            activityTime,
            excessivePermissionActionSeverity,
            groupUserIds,
            permissionActions,
            roleMostIndirectOriginatorResourceIds,
            roleOriginatorResourceIds,
            sensitiveResources,
            serviceIdToUsageTimeMap);
    }
}

export class AwsExcessivePermissionPrincipalRiskModel extends AwsAccessPrincipalRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsExcessivePermissionPrincipalRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        userActiveAccessKeyCount: Optional<number>,
        public lastUsedServiceInfo: Optional<AwsExcessivePermissionPrincipalRiskModelServiceInfo>,
        public serviceIds: string[],
        public serviceInfos: AwsExcessivePermissionPrincipalRiskModelServiceInfo[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            userActiveAccessKeyCount);
    }
}

export class AwsExcessivePermissionResourceRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public existingPermissionActionServiceExists: boolean,
        public existingPermissionActionVendorTenantIds: string[],
        public newPolicyDocument: Optional<AwsIamPolicyDocument>,
        public nonexcessivePermissionActionServiceExists: boolean,
        public severityInfo: AwsExcessivePermissionResourceRiskSeverityInfo) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsExcessivePermissionResourceRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsExcessivePermissionResourceRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public descriptionType: AwsExcessivePermissionResourceRiskModelDescriptionType,
        public infos: AwsExcessivePermissionResourceRiskModelInfo[],
        public newPolicyFileTypeToFileMap: Dictionary<RiskFile>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsExcessivePermissionRoleGroupRisk extends AwsExcessivePermissionPrincipalRisk implements ITypeNameObject, IAwsExcessivePermissionPrincipalRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        activityTime: Optional<string>,
        excessivePermissionActionSeverity: Optional<Severity>,
        groupUserIds: string[],
        permissionActions: string[],
        roleMostIndirectOriginatorResourceIds: string[],
        roleOriginatorResourceIds: string[],
        sensitiveResources: AwsAccessPrincipalRiskSensitiveResources,
        serviceIdToUsageTimeMap: Dictionary<Optional<string>>,
        policyAssociationAndInfos: AwsExcessivePermissionPrincipalRiskPolicyAssociationAndInfo[],
        resolutionChangeIdToPolicyInfoMap: Dictionary<AwsExcessivePermissionPrincipalRiskResolutionPolicyInfo>,
        resourceLevelServiceIds: string[],
        serviceLevel: Optional<AwsPrincipalNonexcessivePolicyGeneratorServiceLevel>,
        serviceResourceLevel: Optional<AwsPrincipalNonexcessivePolicyGeneratorServiceResourceLevel>,
        serviceResourceLevelNotUsedPermissionActionResourceIds: string[],
        serviceResourceLevels: AwsPrincipalNonexcessivePolicyGeneratorServiceResourceLevel[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsExcessivePermissionRoleGroupRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            activityTime,
            excessivePermissionActionSeverity,
            groupUserIds,
            permissionActions,
            roleMostIndirectOriginatorResourceIds,
            roleOriginatorResourceIds,
            sensitiveResources,
            serviceIdToUsageTimeMap,
            policyAssociationAndInfos,
            resolutionChangeIdToPolicyInfoMap,
            resourceLevelServiceIds,
            serviceLevel,
            serviceResourceLevel,
            serviceResourceLevelNotUsedPermissionActionResourceIds,
            serviceResourceLevels);
    }
}

export class AwsExcessivePermissionRoleGroupRiskModel extends AwsExcessivePermissionPrincipalRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsExcessivePermissionRoleGroupRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        userActiveAccessKeyCount: Optional<number>,
        lastUsedServiceInfo: Optional<AwsExcessivePermissionPrincipalRiskModelServiceInfo>,
        serviceIds: string[],
        serviceInfos: AwsExcessivePermissionPrincipalRiskModelServiceInfo[],
        public awsManagedPolicyIds: string[],
        public infos: AwsExcessivePermissionRoleGroupRiskModelInfo[],
        public lastUsedExcessivePermissionServiceInfo: Optional<AwsExcessivePermissionPrincipalRiskModelServiceInfo>,
        public nonAwsManagedInlinePolicyIdToDisplayNameMap: Dictionary<string>,
        public nonAwsManagedManagedPolicyIdToDisplayNameMap: Dictionary<string>,
        public policyIdReferences: string[],
        public upsertPrincipalManagedPolicyChangeIdToFileTypeToFileMap: Dictionary<Dictionary<RiskFile>>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsExcessivePermissionRoleGroupRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            userActiveAccessKeyCount,
            lastUsedServiceInfo,
            serviceIds,
            serviceInfos);
    }
}

export class AwsExcessivePermissionSecretRisk extends AwsExcessivePermissionResourceRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        existingPermissionActionServiceExists: boolean,
        existingPermissionActionVendorTenantIds: string[],
        newPolicyDocument: Optional<AwsIamPolicyDocument>,
        nonexcessivePermissionActionServiceExists: boolean,
        severityInfo: AwsExcessivePermissionResourceRiskSeverityInfo) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsExcessivePermissionSecretRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            existingPermissionActionServiceExists,
            existingPermissionActionVendorTenantIds,
            newPolicyDocument,
            nonexcessivePermissionActionServiceExists,
            severityInfo);
    }
}

export class AwsExcessivePermissionSecretRiskModel extends AwsExcessivePermissionResourceRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsExcessivePermissionResourceRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        descriptionType: AwsExcessivePermissionResourceRiskModelDescriptionType,
        infos: AwsExcessivePermissionResourceRiskModelInfo[],
        newPolicyFileTypeToFileMap: Dictionary<RiskFile>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsExcessivePermissionSecretRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            descriptionType,
            infos,
            newPolicyFileTypeToFileMap);
    }
}

export class AwsFirehoseDeliveryStream extends AwsResource implements ITypeNameObject, IAwsEncryptedResource, IAwsTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public encryptionExists: boolean,
        public encryptionKeyType: Optional<AwsFirehoseDeliveryStreamEncryptionKeyType>,
        public kmsEncryptionKeyOrAliasArns: string[],
        public serverSideKmsEncryptionKeyOrAliasArn: Optional<string>,
        public status: AwsFirehoseDeliveryStreamStatus,
        public type: AwsFirehoseDeliveryStreamType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsFirehoseDeliveryStream",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsFirehoseDeliveryStreamEncryptionDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsFirehoseDeliveryStreamEncryptionDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsFirehoseDeliveryStreamEncryptionDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsFirehoseDeliveryStreamEncryptionDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsFirehoseDeliveryStreamEncryptionDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsFirehoseDeliveryStreamEncryptionDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsFirehoseDeliveryStreamEncryptionDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsFirehoseDeliveryStreamEncryptionDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsFirehoseDeliveryStreamEncryptionDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsFirehoseDeliveryStreamEncryptionDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsFirehoseDeliveryStreamModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAwsEncryptedResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public kmsEncryptionKeyIdReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsFirehoseDeliveryStreamModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsFolderConfiguration extends CloudProviderTenantFolderConfiguration implements ITypeNameObject, IOrganizationFolderConfiguration, IAwsFolderConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        managed: boolean,
        name: string,
        parentScopeId: string,
        root: boolean,
        scopeSections: ScopeConfigurationSections,
        tenantType: Optional<TenantType>,
        organization: Optional<FolderConfigurationOrganization>,
        organizationExists: boolean,
        public awsFolderSections: AwsFolderConfigurationSections,
        public awsScopeSections: AwsScopeConfigurationSections) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsFolderConfiguration",
            managed,
            name,
            parentScopeId,
            root,
            scopeSections,
            tenantType,
            organization,
            organizationExists);
    }
}

export class AwsIamGroupInactiveRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public groupUserExists: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamGroupInactiveRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsIamGroupInactiveRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsIamGroupInactiveRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamGroupInactiveRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsIamGroupProfile extends AwsIamPrincipalProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>,
        public usersInactive: Optional<AwsIamGroupProfileUsersInactive>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamGroupProfile",
            creation);
    }
}

export class AwsIamGroupSnapshot extends AwsIamPrincipalSnapshot implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        changes: EntitySnapshotChange[],
        entityId: string,
        relatedEntityIds: string[],
        relatedEntitySnapshotIds: string[],
        policySnapshotIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamGroupSnapshot",
            tenantId,
            changes,
            entityId,
            relatedEntityIds,
            relatedEntitySnapshotIds,
            policySnapshotIds);
    }
}

export class AwsIamIdentityInactiveRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public activityTime: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsIamIdentityInactiveRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsIamIdentityInactiveRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public infoType: AwsIamIdentityInactiveRiskModelInfoType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsIamIdentityModelFilters extends AwsResourceModelFilters implements IEntityModelFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        openRelatedEntityRiskTypeIdentifierItems: FilterItems<string>,
        regionIdItems: FilterItems<string>,
        tenantIdItems: FilterItems<string>,
        typeNameItems: FilterItems<string>,
        creationTimeRange: FilterTimeRange,
        public activityTimeRange: FilterTimeRange) {
        super(
            attributeValueItems,
            openRelatedEntityRiskTypeIdentifierItems,
            regionIdItems,
            tenantIdItems,
            typeNameItems,
            creationTimeRange);
    }
}

export class AwsIamIdentityProfile extends AwsIamPrincipalProfile implements ITypeNameObject, IAwsActivityResourceProfile {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            creation);
    }
}

export class AwsIamIdentitySnapshot extends AwsIamPrincipalSnapshot implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        changes: EntitySnapshotChange[],
        entityId: string,
        relatedEntityIds: string[],
        relatedEntitySnapshotIds: string[],
        policySnapshotIds: string[],
        public permissionBoundaryPolicySnapshotId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            changes,
            entityId,
            relatedEntityIds,
            relatedEntitySnapshotIds,
            policySnapshotIds);
    }
}

export class AwsIamInlinePolicySnapshot extends AwsIamPrincipalPolicySnapshot implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        changes: EntitySnapshotChange[],
        entityId: string,
        relatedEntityIds: string[],
        relatedEntitySnapshotIds: string[],
        policyDocument: AwsIamPolicyDocument) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamInlinePolicySnapshot",
            tenantId,
            changes,
            entityId,
            relatedEntityIds,
            relatedEntitySnapshotIds,
            policyDocument);
    }
}

export class AwsIamInstanceProfileModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamInstanceProfileModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsIamManagedPolicySnapshot extends AwsIamPrincipalPolicySnapshot implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        changes: EntitySnapshotChange[],
        entityId: string,
        relatedEntityIds: string[],
        relatedEntitySnapshotIds: string[],
        policyDocument: AwsIamPolicyDocument) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamManagedPolicySnapshot",
            tenantId,
            changes,
            entityId,
            relatedEntityIds,
            relatedEntitySnapshotIds,
            policyDocument);
    }
}

export class AwsIamOidcIdentityProviderModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamOidcIdentityProviderModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsIamPasswordLengthPolicyCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamPasswordLengthPolicyCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsIamPasswordLengthPolicyCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsIamPasswordLengthPolicyCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamPasswordLengthPolicyCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsIamPasswordLengthPolicyRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public passwordPolicyMinLength: number) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamPasswordLengthPolicyRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsIamPasswordLengthPolicyRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsIamPasswordLengthPolicyRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamPasswordLengthPolicyRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsIamPasswordReusePolicyRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public passwordPolicyPreventReusePreviousPasswordCount: number) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamPasswordReusePolicyRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsIamPasswordReusePolicyRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsIamPasswordReusePolicyRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamPasswordReusePolicyRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsIamPrincipalAttachedPolicyPermissionScopeAnyCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamPrincipalAttachedPolicyPermissionScopeAnyCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsIamPrincipalAttachedPolicyPermissionScopeAnyCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsIamPrincipalAttachedPolicyPermissionScopeAnyCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamPrincipalAttachedPolicyPermissionScopeAnyCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsIamPrincipalAttachedPolicyPermissionScopeAnyRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public permissionScopeAnyPolicyIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamPrincipalAttachedPolicyPermissionScopeAnyRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsIamPrincipalAttachedPolicyPermissionScopeAnyRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsIamPrincipalAttachedPolicyPermissionScopeAnyRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamPrincipalAttachedPolicyPermissionScopeAnyRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsIamPrincipalCreationRiskModel extends AwsCustomActivityRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsIamPrincipalCreationRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        originatorEntityIdReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamPrincipalCreationRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            originatorEntityIdReferences);
    }
}

export class AwsIamPrincipalModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IPrincipalModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public access: Optional<PrincipalModelAccess>,
        public analyzingReason: Optional<EntityAnalyzingReason>,
        public inactive: boolean,
        public permissionActionSeverity: Optional<Severity>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsIamPrincipalPolicyModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IRelatedTenantsEntityModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public management: AwsIamPrincipalPolicyModelManagement,
        public permissionAttachedPrincipalIds: string[],
        public permissionBoundaryAttachedIdentityIds: string[],
        public permissionUsageTime: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsIamResource extends AwsResource implements ITypeNameObject, IAwsResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public path: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsIamRoleGroup extends AwsResourceGroup implements ITypeNameObject, IEntityGroup, IAwsPrincipal {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        definitionId: Optional<string>,
        public awsManagedPolicyIds: string[],
        public nonAwsManagedPolicies: AwsIamRoleGroupPolicy[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamRoleGroup",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            definitionId);
    }
}

export class AwsIamRoleGroupProfile extends AwsIamIdentityProfile implements ITypeNameObject, IAwsActivityResourceProfile {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamRoleGroupProfile",
            creation);
    }
}

export class AwsIamRoleInactiveRisk extends AwsIamIdentityInactiveRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        activityTime: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamRoleInactiveRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            activityTime);
    }
}

export class AwsIamRoleInactiveRiskModel extends AwsIamIdentityInactiveRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsIamRoleInactiveRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        infoType: AwsIamIdentityInactiveRiskModelInfoType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamRoleInactiveRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            infoType);
    }
}

export class AwsIamRoleManagementRiskModel extends AwsCustomActivityRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsIamRoleManagementRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        originatorEntityIdReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamRoleManagementRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            originatorEntityIdReferences);
    }
}

export class AwsIamRoleModelFilters extends AwsIamIdentityModelFilters implements IEntityModelFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        openRelatedEntityRiskTypeIdentifierItems: FilterItems<string>,
        regionIdItems: FilterItems<string>,
        tenantIdItems: FilterItems<string>,
        typeNameItems: FilterItems<string>,
        creationTimeRange: FilterTimeRange,
        activityTimeRange: FilterTimeRange,
        public vendorItems: FilterItems<Vendor>) {
        super(
            attributeValueItems,
            openRelatedEntityRiskTypeIdentifierItems,
            regionIdItems,
            tenantIdItems,
            typeNameItems,
            creationTimeRange,
            activityTimeRange);
    }
}

export class AwsIamRoleProfile extends AwsIamIdentityProfile implements ITypeNameObject, IAwsActivityResourceProfile, IAwsPolicyResourceProfile {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamRoleProfile",
            creation);
    }
}

export class AwsIamRolePublicAccessExistsCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamRolePublicAccessExistsCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsIamRolePublicAccessExistsCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsIamRolePublicAccessExistsCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamRolePublicAccessExistsCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsIamRolePublicAccessExistsRisk extends PublicEntityRisk implements ITypeNameObject, IPublicRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public roleAssumeRolePolicyDocument: AwsIamPolicyDocument,
        public rolePermissionActionSeverity: Optional<Severity>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamRolePublicAccessExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsIamRolePublicAccessExistsRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsIamRolePublicAccessExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamRolePublicAccessExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsIamRoleSnapshot extends AwsIamIdentitySnapshot implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        changes: EntitySnapshotChange[],
        entityId: string,
        relatedEntityIds: string[],
        relatedEntitySnapshotIds: string[],
        policySnapshotIds: string[],
        permissionBoundaryPolicySnapshotId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamRoleSnapshot",
            tenantId,
            changes,
            entityId,
            relatedEntityIds,
            relatedEntitySnapshotIds,
            policySnapshotIds,
            permissionBoundaryPolicySnapshotId);
    }
}

export class AwsIamRoleVendorAssumeRolePolicyDocumentExternalIdConditionNotExistCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamRoleVendorAssumeRolePolicyDocumentExternalIdConditionNotExistCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsIamRoleVendorAssumeRolePolicyDocumentExternalIdConditionNotExistCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsIamRoleVendorAssumeRolePolicyDocumentExternalIdConditionNotExistCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamRoleVendorAssumeRolePolicyDocumentExternalIdConditionNotExistCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsIamRoleVendorAssumeRolePolicyDocumentExternalIdConditionNotExistRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public permissionsRiskSeverity: Severity,
        public vendorTenantRawIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamRoleVendorAssumeRolePolicyDocumentExternalIdConditionNotExistRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsIamRoleVendorAssumeRolePolicyDocumentExternalIdConditionNotExistRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsIamRoleVendorAssumeRolePolicyDocumentExternalIdConditionNotExistRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public tenantRawIdToVendorMap: Dictionary<Optional<Vendor>>,
        public vendors: Vendor[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamRoleVendorAssumeRolePolicyDocumentExternalIdConditionNotExistRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsIamRootUser extends AwsIamResource implements ITypeNameObject, IAwsIdentity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        path: string,
        public accessKey1Enabled: boolean,
        public accessKey1UsageTime: Optional<string>,
        public accessKey2Enabled: boolean,
        public accessKey2UsageTime: Optional<string>,
        public mfaEnabled: boolean,
        public passwordEnabled: Optional<boolean>,
        public passwordUsageTime: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamRootUser",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            path);
    }
}

export class AwsIamRootUserAccessKeyEnabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamRootUserAccessKeyEnabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsIamRootUserAccessKeyEnabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsIamRootUserAccessKeyEnabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamRootUserAccessKeyEnabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsIamRootUserActivityRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public accessKeyEnabled: boolean,
        public accessKeyUsageTime: Optional<string>,
        public activityTimeRange: TimeRange,
        public passwordUsageTime: Optional<string>,
        public reopened: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamRootUserActivityRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsIamRootUserActivityRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsIamRootUserActivityRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public ssoDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamRootUserActivityRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsIamRootUserMfaDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamRootUserMfaDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsIamRootUserMfaDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsIamRootUserMfaDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamRootUserMfaDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsIamRootUserModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamRootUserModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsIamSamlIdentityProviderModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamSamlIdentityProviderModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsIamServerCertificate extends AwsIamResource implements ITypeNameObject, IAwsResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        path: string,
        public expirationTime: string,
        public uniqueId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamServerCertificate",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            path);
    }
}

export class AwsIamServerCertificateExpiredRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamServerCertificateExpiredRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsIamServerCertificateExpiredRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsIamServerCertificateExpiredRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamServerCertificateExpiredRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsIamServerCertificateModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamServerCertificateModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsIamServerCertificateModelFilters extends AwsResourceModelFilters implements IEntityModelFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        openRelatedEntityRiskTypeIdentifierItems: FilterItems<string>,
        regionIdItems: FilterItems<string>,
        tenantIdItems: FilterItems<string>,
        typeNameItems: FilterItems<string>,
        creationTimeRange: FilterTimeRange,
        public expirationTimeRange: FilterTimeRange) {
        super(
            attributeValueItems,
            openRelatedEntityRiskTypeIdentifierItems,
            regionIdItems,
            tenantIdItems,
            typeNameItems,
            creationTimeRange);
    }
}

export class AwsIamSupportRoleNotExistRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamSupportRoleNotExistRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsIamSupportRoleNotExistRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsIamSupportRoleNotExistRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamSupportRoleNotExistRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsIamUserAttachedPolicyCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamUserAttachedPolicyCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsIamUserAttachedPolicyCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsIamUserAttachedPolicyCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamUserAttachedPolicyCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsIamUserAttachedPolicyRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamUserAttachedPolicyRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsIamUserAttachedPolicyRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsIamUserAttachedPolicyRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamUserAttachedPolicyRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsIamUserInactiveRisk extends AwsIamIdentityInactiveRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        activityTime: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamUserInactiveRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            activityTime);
    }
}

export class AwsIamUserInactiveRiskModel extends AwsIamIdentityInactiveRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsIamUserInactiveRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        infoType: AwsIamIdentityInactiveRiskModelInfoType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamUserInactiveRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            infoType);
    }
}

export class AwsIamUserMfaDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public userPassword: AwsIamUserPassword,
        public userPermissionActionSeverity: Optional<Severity>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamUserMfaDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsIamUserMfaDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsIamUserMfaDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamUserMfaDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsIamUserModelFilters extends AwsIamIdentityModelFilters implements IEntityModelFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        openRelatedEntityRiskTypeIdentifierItems: FilterItems<string>,
        regionIdItems: FilterItems<string>,
        tenantIdItems: FilterItems<string>,
        typeNameItems: FilterItems<string>,
        creationTimeRange: FilterTimeRange,
        activityTimeRange: FilterTimeRange,
        public accessKeyCreationTimeRange: FilterTimeRange,
        public accessKeyUsageRegionSystemNameItems: FilterItems<string>,
        public accessKeyUsageTimeRange: FilterTimeRange) {
        super(
            attributeValueItems,
            openRelatedEntityRiskTypeIdentifierItems,
            regionIdItems,
            tenantIdItems,
            typeNameItems,
            creationTimeRange,
            activityTimeRange);
    }
}

export class AwsIamUserMultipleEnabledAccessKeyRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamUserMultipleEnabledAccessKeyRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsIamUserMultipleEnabledAccessKeyRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsIamUserMultipleEnabledAccessKeyRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamUserMultipleEnabledAccessKeyRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsIamUserProfile extends AwsIamIdentityProfile implements ITypeNameObject, IAwsActivityResourceProfile {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamUserProfile",
            creation);
    }
}

export class AwsIamUserSnapshot extends AwsIamIdentitySnapshot implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        changes: EntitySnapshotChange[],
        entityId: string,
        relatedEntityIds: string[],
        relatedEntitySnapshotIds: string[],
        policySnapshotIds: string[],
        permissionBoundaryPolicySnapshotId: Optional<string>,
        public groupIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamUserSnapshot",
            tenantId,
            changes,
            entityId,
            relatedEntityIds,
            relatedEntitySnapshotIds,
            policySnapshotIds,
            permissionBoundaryPolicySnapshotId);
    }
}

export class AwsIamUserUnrotatedAccessKeyRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public accessKeyRotationTimeFrame: string,
        public invalidAccessKeyExists: boolean,
        public userAccessKeys: AwsIamUserAccessKey[],
        public userPermissionActionSeverity: Optional<Severity>,
        public userUnrotatedAccessKeys: AwsIamUserAccessKey[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamUserUnrotatedAccessKeyRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsIamUserUnrotatedAccessKeyRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsIamUserUnrotatedAccessKeyRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamUserUnrotatedAccessKeyRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsIamUserUnusedAccessKeysEnabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public accessKeyUsageTimeFrame: string,
        public unusedAccessKeys: AwsIamUserAccessKey[],
        public userMfaEnabled: boolean,
        public userPermissionActionSeverity: Optional<Severity>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamUserUnusedAccessKeysEnabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsIamUserUnusedAccessKeysEnabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsIamUserUnusedAccessKeysEnabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamUserUnusedAccessKeysEnabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsIamUserUnusedPasswordEnabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public passwordUsageTimeFrame: string,
        public userMfaEnabled: boolean,
        public userPermissionActionSeverity: Optional<Severity>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamUserUnusedPasswordEnabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsIamUserUnusedPasswordEnabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsIamUserUnusedPasswordEnabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamUserUnusedPasswordEnabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsIamVirtualMfaDevice extends AwsIamResource implements ITypeNameObject, IAwsResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        path: string,
        public enableTime: Optional<string>,
        public userId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamVirtualMfaDevice",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            path);
    }
}

export class AwsIamVirtualMfaDeviceModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamVirtualMfaDeviceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsIdentityActivityRisk extends AwsActivityRisk implements ITypeNameObject, IActivityRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        public eventNames: string[],
        public identityId: string,
        public serviceReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIdentityActivityRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType);
    }
}

export class AwsIdentityActivityRiskModel extends AwsCustomActivityRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsIdentityActivityRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        originatorEntityIdReferences: string[],
        public serviceIdReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIdentityActivityRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            originatorEntityIdReferences);
    }
}

export class AwsInboundExternalEc2InstanceCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsInboundExternalEc2InstanceCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsInboundExternalEc2InstanceCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsInboundExternalEc2InstanceCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsInboundExternalEc2InstanceCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsInboundExternalEc2InstanceRisk extends AggregatedEntityRisk implements ITypeNameObject, IPublicRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        aggregatedEntityIds: string[],
        aggregatingEntityTypeName: string,
        public infos: AwsInboundExternalEc2InstanceRiskInfo[],
        public instanceIdToDataMap: Dictionary<AwsInboundExternalEc2InstanceRiskInstanceData>,
        public launchTemplateInstanceIdToRolePermissionActionSeverityMap: Optional<Dictionary<Severity>>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsInboundExternalEc2InstanceRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            aggregatedEntityIds,
            aggregatingEntityTypeName);
    }
}

export class AwsInboundExternalEc2InstanceRiskModel extends AggregatedEntityRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsInboundExternalEc2InstanceRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsInboundExternalEc2InstanceRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsInboundExternalElbLoadBalancerCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsInboundExternalElbLoadBalancerCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsInboundExternalElbLoadBalancerCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsInboundExternalElbLoadBalancerCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsInboundExternalElbLoadBalancerCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsInboundExternalRdsDatabaseInstanceCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsInboundExternalRdsDatabaseInstanceCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsInboundExternalRdsDatabaseInstanceCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsInboundExternalRdsDatabaseInstanceCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsInboundExternalRdsDatabaseInstanceCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsInboundExternalRedshiftClusterCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsInboundExternalRedshiftClusterCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsInboundExternalRedshiftClusterCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsInboundExternalRedshiftClusterCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsInboundExternalRedshiftClusterCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsInboundExternalResourceRisk extends PublicEntityRisk implements ITypeNameObject, IPublicRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public gatewayResourceIds: string[],
        public networkScopes: AwsNetworkScope[],
        public securityGroupIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsInboundExternalResourceRiskModel extends CloudRiskModel implements ITypeNameObject, IUdmObjectModel, INetworkInboundExternalResourceRiskModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsInboundExternalResourceRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public destinationNetworkScopes: DestinationNetworkScope[],
        public sourceSubnets: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsInboundExternalSubnetRisk extends CloudRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        public inboundExternalSubnetItems: AwsInboundExternalSubnetRiskItem[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsInboundExternalSubnetRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType);
    }
}

export class AwsInboundExternalSubnetRiskModel extends AwsCustomRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsInboundExternalSubnetRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public subnetIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsInboundExternalSubnetRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsInboundExternalVpcRisk extends CloudRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        public inboundExternalVpcItems: AwsInboundExternalVpcRiskItem[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsInboundExternalVpcRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType);
    }
}

export class AwsInboundExternalVpcRiskModel extends AwsCustomRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsInboundExternalVpcRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public vpcIds: string[],
        public vpcIdToInternetGatewayAttachInfoMap: Dictionary<AwsInboundExternalVpcRiskModelInternetGatewayAttachInfo>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsInboundExternalVpcRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsKinesisStream extends AwsResource implements ITypeNameObject, IAwsEncryptedResource, IAwsTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public encryptionExists: boolean,
        public kmsEncryptionKeyOrAliasArns: string[],
        public mode: AwsKinesisStreamMode,
        public retentionPeriodHours: number,
        public serverSideKmsEncryptionKeyOrAliasArn: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKinesisStream",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsKinesisStreamEncryptionDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKinesisStreamEncryptionDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsKinesisStreamEncryptionDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsKinesisStreamEncryptionDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKinesisStreamEncryptionDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsKinesisStreamEncryptionDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKinesisStreamEncryptionDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsKinesisStreamEncryptionDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsKinesisStreamEncryptionDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKinesisStreamEncryptionDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsKinesisStreamModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAwsEncryptedResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public kmsEncryptionKeyIdReferences: string[],
        public triggerFunctionConfigurationIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKinesisStreamModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsKmsAliasModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKmsAliasModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsKmsKeyGrantListPart extends AwsResource implements ITypeNameObject, IAwsResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public grants: AwsKmsKeyGrant[],
        public keyId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKmsKeyGrantListPart",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsKmsKeyGrantListPartModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKmsKeyGrantListPartModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsKmsKeyManagementRiskModel extends AwsCustomActivityRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsKmsKeyManagementRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        originatorEntityIdReferences: string[],
        public descriptionType: AwsKmsKeyManagementRiskModelDescriptionType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKmsKeyManagementRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            originatorEntityIdReferences);
    }
}

export class AwsKmsKeyModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IAwsAccessResourceModel, IAwsPolicyResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public accessLevel: Optional<AwsResourceAccessLevel>,
        public encryptedResourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKmsKeyModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsKmsKeyPublicAccessExistsCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKmsKeyPublicAccessExistsCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsKmsKeyPublicAccessExistsCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsKmsKeyPublicAccessExistsCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKmsKeyPublicAccessExistsCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsKmsKeyPublicAccessExistsRisk extends PublicEntityRisk implements ITypeNameObject, IPublicRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKmsKeyPublicAccessExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsKmsKeyPublicAccessExistsRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsKmsKeyPublicAccessExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKmsKeyPublicAccessExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsKmsKeyRotationDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKmsKeyRotationDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsKmsKeyRotationDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsKmsKeyRotationDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKmsKeyRotationDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsKmsKeyRotationDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public keyRotationTimeFrame: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKmsKeyRotationDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsKmsKeyRotationDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsKmsKeyRotationDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public rotationDisabledCustomerManagedKeyIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKmsKeyRotationDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsKmsResource extends AwsResource implements ITypeNameObject, IAwsResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsKubernetesResourceBase extends AwsResource implements ITypeNameObject, IAwsResource, IKubernetesResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public clusterDisplayReference: string,
        public clusterId: string,
        public data: KubernetesResourceData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsKubernetesResourceModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsKubernetesResourceTypeMetadata extends AwsResourceTypeMetadata {
    constructor() {
        super();
    }
}

export class AwsKubernetesUserModel extends AwsKubernetesResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesResourceModel, IKubernetesPrincipalModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public roleBindingIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKubernetesUserModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsLambdaFunctionConfiguration extends AwsResource implements ITypeNameObject, IAwsAccessResource, IAwsEncryptedResource, IAwsNetworkedResource, IAwsOriginatorComputeResource, IAwsPolicyResource, IAwsTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public accessLevel: Optional<AwsResourceAccessLevel>,
        public aliasMap: Dictionary<AwsLambdaFunctionConfigurationAlias>,
        public aliasNameToPolicyDocumentPublicAccessPermissionActionsMap: Dictionary<string[]>,
        public encryptionExists: boolean,
        public kmsEncryptionKeyOrAliasArns: string[],
        public masterFunctionConfigurationId: Optional<string>,
        public packageType: AwsLambdaFunctionConfigurationPackageType,
        public policyDocument: Optional<AwsIamPolicyDocument>,
        public policyDocumentExists: boolean,
        public publicAccessHighSeveritySensitivePermissionActions: string[],
        public publicAccessPermissionActions: string[],
        public revisionCount: number,
        public revisionIdToPolicyDocumentPublicAccessPermissionActionsMap: Dictionary<string[]>,
        public revisionMap: Dictionary<AwsLambdaFunctionConfigurationRevision>,
        public roleIds: string[],
        public runtimes: string[],
        public securityGroupIds: string[],
        public subnetIds: string[],
        public updateTime: string,
        public url: Optional<AwsLambdaFunctionConfigurationUrl>,
        public urlAccessLevel: AwsResourceAccessLevel,
        public vpcId: Optional<string>,
        public vpcIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsLambdaFunctionConfiguration",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsLambdaFunctionConfigurationDeprecatedRuntimeCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsLambdaFunctionConfigurationDeprecatedRuntimeCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsLambdaFunctionConfigurationDeprecatedRuntimeCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsLambdaFunctionConfigurationDeprecatedRuntimeCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsLambdaFunctionConfigurationDeprecatedRuntimeCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsLambdaFunctionConfigurationDeprecatedRuntimeRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public deprecatedRuntimes: string[],
        public deprecatedRuntimeVersions: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsLambdaFunctionConfigurationDeprecatedRuntimeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsLambdaFunctionConfigurationDeprecatedRuntimeRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsLambdaFunctionConfigurationDeprecatedRuntimeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsLambdaFunctionConfigurationDeprecatedRuntimeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsLambdaFunctionConfigurationEnvironmentVariableSecretExistsCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsLambdaFunctionConfigurationEnvironmentVariableSecretExistsCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsLambdaFunctionConfigurationEnvironmentVariableSecretExistsCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsLambdaFunctionConfigurationEnvironmentVariableSecretExistsCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsLambdaFunctionConfigurationEnvironmentVariableSecretExistsCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsLambdaFunctionConfigurationEnvironmentVariableSecretExistsRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public environmentVariablesPermissionsIdentityIds: string[],
        public excludedSecretEnvironmentVariableNames: string[],
        public revisionIdToSecretEnvironmentVariableNamesMap: Dictionary<string[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsLambdaFunctionConfigurationEnvironmentVariableSecretExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsLambdaFunctionConfigurationEnvironmentVariableSecretExistsRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel, ISecretExistsRiskModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsLambdaFunctionConfigurationEnvironmentVariableSecretExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public secretCount: number,
        public secretEnvironmentVariableNames: string[],
        public secretsManagerDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsLambdaFunctionConfigurationEnvironmentVariableSecretExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsLambdaFunctionConfigurationModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IAwsAccessResourceModel, IAwsEncryptedResourceModel, IAwsNetworkedResourceModel, IAwsPolicyResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public accessLevel: Optional<AwsResourceAccessLevel>,
        public aliasNameToAccessLevelMap: Optional<Dictionary<AwsResourceAccessLevel>>,
        public kmsEncryptionKeyIdReferences: string[],
        public publicAccessTriggerApiIdToRouteIdentifiersMap: Dictionary<string[]>,
        public publicAccessTypes: Optional<AwsLambdaFunctionConfigurationModelAccessType[]>,
        public revisionIdToAccessLevelMap: Optional<Dictionary<AwsResourceAccessLevel>>,
        public revisionIdToKeyIdReferenceMap: Dictionary<Optional<string>>,
        public triggerResourceArnToIdReferenceMap: Dictionary<string>,
        public triggerResourceIdReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsLambdaFunctionConfigurationModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsLambdaFunctionConfigurationMultiAvailabilityZoneNotExistCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsLambdaFunctionConfigurationMultiAvailabilityZoneNotExistCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsLambdaFunctionConfigurationMultiAvailabilityZoneNotExistCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsLambdaFunctionConfigurationMultiAvailabilityZoneNotExistCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsLambdaFunctionConfigurationMultiAvailabilityZoneNotExistCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsLambdaFunctionConfigurationMultiAvailabilityZoneNotExistRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public multiAvailabilityZoneNotExistVersions: string[],
        public multiAvailabilityZoneNotExistVpcIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsLambdaFunctionConfigurationMultiAvailabilityZoneNotExistRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsLambdaFunctionConfigurationMultiAvailabilityZoneNotExistRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsLambdaFunctionConfigurationMultiAvailabilityZoneNotExistRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsLambdaFunctionConfigurationMultiAvailabilityZoneNotExistRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsLambdaFunctionConfigurationPublicAccessExistsCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsLambdaFunctionConfigurationPublicAccessExistsCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsLambdaFunctionConfigurationPublicAccessExistsCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsLambdaFunctionConfigurationPublicAccessExistsCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsLambdaFunctionConfigurationPublicAccessExistsCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsLambdaFunctionConfigurationPublicAccessExistsRisk extends PublicEntityRisk implements ITypeNameObject, IPublicRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public highSeverityRolePermissionActions: string[],
        public publicAccessTriggerApiIdToExcludedRoutesMap: Dictionary<AwsApiGatewayApiRoute[]>,
        public publicAccessTriggerApiIdToNotExcludedRouteIdentifiersMap: Dictionary<string[]>,
        public publicAccessTypes: AwsLambdaFunctionConfigurationModelAccessType[],
        public triggerResourceIdReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsLambdaFunctionConfigurationPublicAccessExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsLambdaFunctionConfigurationPublicAccessExistsRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsLambdaFunctionConfigurationPublicAccessExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public apiGateWayAuthorizationDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsLambdaFunctionConfigurationPublicAccessExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsLambdaFunctionConfigurationVpcNotExistsCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsLambdaFunctionConfigurationVpcNotExistsCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsLambdaFunctionConfigurationVpcNotExistsCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsLambdaFunctionConfigurationVpcNotExistsCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsLambdaFunctionConfigurationVpcNotExistsCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsLambdaFunctionConfigurationVpcNotExistsRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public vpcNotExistsVersions: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsLambdaFunctionConfigurationVpcNotExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsLambdaFunctionConfigurationVpcNotExistsRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsLambdaFunctionConfigurationVpcNotExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public attachingVpcUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsLambdaFunctionConfigurationVpcNotExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsLogsLogGroup extends AwsResource implements ITypeNameObject, IAwsResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public metricFilters: AwsLogsLogGroupMetricFilter[],
        public retentionTimeFrame: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsLogsLogGroup",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsLogsLogGroupModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsLogsLogGroupModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsNetworkAccessManagementRisk extends AwsBehaviorIdentityRisk implements ITypeNameObject, IActivityRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        endTime: string,
        eventActions: string[],
        identityId: string,
        ipAddresses: string[],
        previousBehaviorDate: Optional<string>,
        startTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsNetworkAccessManagementRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            endTime,
            eventActions,
            identityId,
            ipAddresses,
            previousBehaviorDate,
            startTime);
    }
}

export class AwsNetworkAccessManagementRiskModel extends AwsBehaviorIdentityRiskModel implements ITypeNameObject, IUdmObjectModel, IBehaviorIdentityRiskModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsNetworkAccessManagementRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        originatorEntityIdReferences: string[],
        infos: AwsBehaviorIdentityRiskModelInfo[],
        resourceIdReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsNetworkAccessManagementRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            originatorEntityIdReferences,
            infos,
            resourceIdReferences);
    }
}

export class AwsNetworkedResourceInboundExternalPortRisk extends CloudRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsNetworkedResourceInboundExternalPortRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType);
    }
}

export class AwsNetworkedResourceInboundExternalPortRiskModel extends AwsCustomRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsNetworkedResourceInboundExternalPortRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public destinationNetworkScopes: DestinationNetworkScope[],
        public resourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsNetworkedResourceInboundExternalPortRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsNotSyncedResource extends AwsResource implements ITypeNameObject, IAwsResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsOpenShiftProjectModel extends AwsKubernetesResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsOpenShiftProjectModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsOpenShiftSecurityContextConstraintModel extends AwsKubernetesResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsOpenShiftSecurityContextConstraintModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsOrganizationConfiguration extends CloudProviderTenantOrganizationConfiguration implements ITypeNameObject, ITenantOrganizationConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        enabled: boolean,
        folderEnabled: boolean,
        memberSelection: Optional<OrganizationMemberSelection>,
        name: string,
        permissionTypes: CloudProviderTenantPermissionType[],
        public masterTenantId: string,
        public rawId: string,
        public regionSystemNames: Optional<string[]>,
        public roleName: string,
        public trailNamePattern: Optional<string>,
        public updateIssueExistsTenants: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsOrganizationConfiguration",
            scopeId,
            enabled,
            folderEnabled,
            memberSelection,
            name,
            permissionTypes);
    }
}

export class AwsOrganizationsAccountModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsOrganizationsAccountModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsOrganizationsOrganizationalUnitModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsOrganizationsOrganizationalUnitModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsOrganizationsOrganizationModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsOrganizationsOrganizationModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsOrganizationsPolicyModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IRelatedTenantsEntityModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public attachedAccountTenantIdReferences: string[],
        public organizationRawIds: string[],
        public organizationRawIdSearchableDatas: StringSearchableData[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsOrganizationsPolicyModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsOrganizationsResource extends AwsResource implements ITypeNameObject, IAwsResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsOrganizationsRootModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsOrganizationsRootModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsOrganizationsScopeResource extends AwsOrganizationsResource implements ITypeNameObject, IAwsResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public parentEntityId: Optional<string>,
        public rawId: string,
        public resourceControlPolicyIds: Optional<string[]>,
        public serviceControlPolicyIds: Optional<string[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsPermissionManagementRisk extends AwsBehaviorIdentityRisk implements ITypeNameObject, IActivityRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        endTime: string,
        eventActions: string[],
        identityId: string,
        ipAddresses: string[],
        previousBehaviorDate: Optional<string>,
        startTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsPermissionManagementRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            endTime,
            eventActions,
            identityId,
            ipAddresses,
            previousBehaviorDate,
            startTime);
    }
}

export class AwsPermissionManagementRiskModel extends AwsBehaviorIdentityRiskModel implements ITypeNameObject, IUdmObjectModel, IBehaviorIdentityRiskModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsPermissionManagementRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        originatorEntityIdReferences: string[],
        infos: AwsBehaviorIdentityRiskModelInfo[],
        resourceIdReferences: string[],
        public descriptionType: AwsPermissionManagementRiskModelDescriptionType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsPermissionManagementRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            originatorEntityIdReferences,
            infos,
            resourceIdReferences);
    }
}

export class AwsPermitter extends AwsResource implements ITypeNameObject, IAwsResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsPrincipalAllowedPermissionRisk extends CloudRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        public principalItems: AwsPrincipalAllowedPermissionRiskItem[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsPrincipalAllowedPermissionRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType);
    }
}

export class AwsPrincipalAllowedPermissionRiskModel extends AwsCustomRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsPrincipalAllowedPermissionRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public permissionActions: string[],
        public principalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsPrincipalAllowedPermissionRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsPrincipalResourcePermissionRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public principalResourcePermissionItems: AwsPrincipalResourcePermissionRiskItem[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsPrincipalResourcePermissionRiskModel extends AwsCustomRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsPrincipalResourcePermissionRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public permissionActions: string[],
        public principalIds: string[],
        public resourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsPrivilegeEscalationRisk extends AwsBehaviorIdentityRisk implements ITypeNameObject, IActivityRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        endTime: string,
        eventActions: string[],
        identityId: string,
        ipAddresses: string[],
        previousBehaviorDate: Optional<string>,
        startTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsPrivilegeEscalationRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            endTime,
            eventActions,
            identityId,
            ipAddresses,
            previousBehaviorDate,
            startTime);
    }
}

export class AwsPrivilegeEscalationRiskModel extends AwsBehaviorIdentityRiskModel implements ITypeNameObject, IUdmObjectModel, IBehaviorIdentityRiskModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsPrivilegeEscalationRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        originatorEntityIdReferences: string[],
        infos: AwsBehaviorIdentityRiskModelInfo[],
        resourceIdReferences: string[],
        public accessKeyUserIds: string[],
        public assumedRoleIds: string[],
        public descriptionType: AwsPrivilegeEscalationRiskModelDescriptionType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsPrivilegeEscalationRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            originatorEntityIdReferences,
            infos,
            resourceIdReferences);
    }
}

export class AwsRdsAuroraGlobalClusterProfile extends AwsRdsGlobalClusterResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsAuroraGlobalClusterProfile",
            creation);
    }
}

export class AwsRdsClusterBackupRetentionTimeFrameCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsClusterBackupRetentionTimeFrameCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsRdsClusterBackupRetentionTimeFrameCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsRdsClusterBackupRetentionTimeFrameCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsClusterBackupRetentionTimeFrameCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsRdsClusterBackupRetentionTimeFrameRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public backupRetentionTimeFrame: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsClusterBackupRetentionTimeFrameRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsRdsClusterBackupRetentionTimeFrameRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsRdsClusterBackupRetentionTimeFrameRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsClusterBackupRetentionTimeFrameRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsRdsClusterCloudWatchLoggingDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsClusterCloudWatchLoggingDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsRdsClusterCloudWatchLoggingDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsRdsClusterCloudWatchLoggingDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsClusterCloudWatchLoggingDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsRdsClusterDeleteProtectionDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsClusterDeleteProtectionDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsRdsClusterDeleteProtectionDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsRdsClusterDeleteProtectionDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsClusterDeleteProtectionDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsRdsClusterDeleteProtectionDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsClusterDeleteProtectionDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsRdsClusterDeleteProtectionDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsRdsClusterDeleteProtectionDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsClusterDeleteProtectionDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsRdsClusterMinorVersionUpgradeDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsClusterMinorVersionUpgradeDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsRdsClusterMinorVersionUpgradeDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsRdsClusterMinorVersionUpgradeDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsClusterMinorVersionUpgradeDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsRdsClusterModelFilters extends AwsResourceModelFilters implements IEntityModelFilters, IDataAnalysisResourceModelFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        openRelatedEntityRiskTypeIdentifierItems: FilterItems<string>,
        regionIdItems: FilterItems<string>,
        tenantIdItems: FilterItems<string>,
        typeNameItems: FilterItems<string>,
        creationTimeRange: FilterTimeRange,
        public dataAnalysisResource: DataAnalysisResourceModelFiltersSection) {
        super(
            attributeValueItems,
            openRelatedEntityRiskTypeIdentifierItems,
            regionIdItems,
            tenantIdItems,
            typeNameItems,
            creationTimeRange);
    }
}

export class AwsRdsClusterProfile extends AwsRdsClusterResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsClusterProfile",
            creation);
    }
}

export class AwsRdsClusterResourceModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAwsCloudWatchLoggingResourceModel, IAwsEncryptedResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public instanceIds: string[],
        public kmsEncryptionKeyIdReferences: string[],
        public snapshotIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsRdsClusterResourceTypeMetadata extends AwsResourceTypeMetadata {
    constructor() {
        super();
    }
}

export class AwsRdsClusterSnapshotTypeMetadata extends AwsResourceTypeMetadata {
    constructor() {
        super();
    }
}

export class AwsRdsClusterStorageEncryptionDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsClusterStorageEncryptionDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsRdsClusterStorageEncryptionDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsRdsClusterStorageEncryptionDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsClusterStorageEncryptionDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsRdsDatabaseInstanceBackupRetentionTimeFrameCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsDatabaseInstanceBackupRetentionTimeFrameCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsRdsDatabaseInstanceBackupRetentionTimeFrameCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsRdsDatabaseInstanceBackupRetentionTimeFrameCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsDatabaseInstanceBackupRetentionTimeFrameCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsRdsDatabaseInstanceBackupRetentionTimeFrameRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public backupRetentionTimeFrame: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsDatabaseInstanceBackupRetentionTimeFrameRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsRdsDatabaseInstanceBackupRetentionTimeFrameRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsRdsDatabaseInstanceBackupRetentionTimeFrameRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsDatabaseInstanceBackupRetentionTimeFrameRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsRdsDatabaseInstanceCloudWatchLoggingDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsDatabaseInstanceCloudWatchLoggingDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsRdsDatabaseInstanceCloudWatchLoggingDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsRdsDatabaseInstanceCloudWatchLoggingDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsDatabaseInstanceCloudWatchLoggingDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsRdsDatabaseInstanceDeleteProtectionDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsDatabaseInstanceDeleteProtectionDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsRdsDatabaseInstanceDeleteProtectionDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsRdsDatabaseInstanceDeleteProtectionDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsDatabaseInstanceDeleteProtectionDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsRdsDatabaseInstanceDeleteProtectionDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsDatabaseInstanceDeleteProtectionDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsRdsDatabaseInstanceDeleteProtectionDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsRdsDatabaseInstanceDeleteProtectionDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsDatabaseInstanceDeleteProtectionDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsRdsDatabaseInstanceMinorVersionUpgradeDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsDatabaseInstanceMinorVersionUpgradeDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsRdsDatabaseInstanceMinorVersionUpgradeDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsRdsDatabaseInstanceMinorVersionUpgradeDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsDatabaseInstanceMinorVersionUpgradeDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsRdsDatabaseInstanceMinorVersionUpgradeDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsDatabaseInstanceMinorVersionUpgradeDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsRdsDatabaseInstanceMinorVersionUpgradeDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsRdsDatabaseInstanceMinorVersionUpgradeDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsDatabaseInstanceMinorVersionUpgradeDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsRdsDatabaseInstanceModelFilters extends AwsResourceModelFilters implements IEntityModelFilters, IDataAnalysisResourceModelFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        openRelatedEntityRiskTypeIdentifierItems: FilterItems<string>,
        regionIdItems: FilterItems<string>,
        tenantIdItems: FilterItems<string>,
        typeNameItems: FilterItems<string>,
        creationTimeRange: FilterTimeRange,
        public dataAnalysisResource: DataAnalysisResourceModelFiltersSection) {
        super(
            attributeValueItems,
            openRelatedEntityRiskTypeIdentifierItems,
            regionIdItems,
            tenantIdItems,
            typeNameItems,
            creationTimeRange);
    }
}

export class AwsRdsDatabaseInstanceProfile extends AwsRdsDatabaseInstanceResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsDatabaseInstanceProfile",
            creation);
    }
}

export class AwsRdsDatabaseInstanceResourceModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IAwsCloudWatchLoggingResourceModel, IAwsEncryptedResourceModel, IAwsNetworkedResourceModel, INetworkAccessResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        public entityNetwork: Optional<NetworkAccessResourceStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public dnsZoneIds: string[],
        public kmsEncryptionKeyIdReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsRdsDatabaseInstanceResourceTypeMetadata extends AwsResourceTypeMetadata {
    constructor() {
        super();
    }
}

export class AwsRdsDatabaseInstanceStorageEncryptionDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsDatabaseInstanceStorageEncryptionDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsRdsDatabaseInstanceStorageEncryptionDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsRdsDatabaseInstanceStorageEncryptionDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsDatabaseInstanceStorageEncryptionDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsRdsDatabaseInstanceStorageEncryptionDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsDatabaseInstanceStorageEncryptionDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsRdsDatabaseInstanceStorageEncryptionDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsRdsDatabaseInstanceStorageEncryptionDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsDatabaseInstanceStorageEncryptionDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsRdsDatabaseSecurityGroupModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsDatabaseSecurityGroupModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsRdsDocDbClusterCloudWatchLoggingDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsDocDbClusterCloudWatchLoggingDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsRdsDocDbClusterCloudWatchLoggingDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsRdsDocDbClusterCloudWatchLoggingDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsDocDbClusterCloudWatchLoggingDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsRdsDocDbClusterDeleteProtectionDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsDocDbClusterDeleteProtectionDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsRdsDocDbClusterDeleteProtectionDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsRdsDocDbClusterDeleteProtectionDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsDocDbClusterDeleteProtectionDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsRdsDocDbClusterDeleteProtectionDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsDocDbClusterDeleteProtectionDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsRdsDocDbClusterDeleteProtectionDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsRdsDocDbClusterDeleteProtectionDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsDocDbClusterDeleteProtectionDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsRdsDocDbClusterModel extends AwsRdsClusterResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAwsDocDbClusterModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        instanceIds: string[],
        kmsEncryptionKeyIdReferences: string[],
        snapshotIds: string[],
        public type: AwsDocDbClusterModelType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsDocDbClusterModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId,
            instanceIds,
            kmsEncryptionKeyIdReferences,
            snapshotIds);
    }
}

export class AwsRdsDocDbClusterSnapshotEncryptionDisabledRisk extends AggregatedEntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        aggregatedEntityIds: string[],
        aggregatingEntityTypeName: string,
        public accessLevel: Optional<AwsResourceAccessLevel>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsDocDbClusterSnapshotEncryptionDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            aggregatedEntityIds,
            aggregatingEntityTypeName);
    }
}

export class AwsRdsDocDbClusterSnapshotEncryptionDisabledRiskModel extends AggregatedEntityRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsRdsDocDbClusterSnapshotEncryptionDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsDocDbClusterSnapshotEncryptionDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsRdsDocDbClusterSnapshotPublicAccessExistsRisk extends PublicEntityRisk implements ITypeNameObject, IPublicRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsDocDbClusterSnapshotPublicAccessExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsRdsDocDbClusterSnapshotPublicAccessExistsRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsRdsDocDbClusterSnapshotPublicAccessExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsDocDbClusterSnapshotPublicAccessExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsRdsDocDbDatabaseInstanceModel extends AwsRdsDatabaseInstanceResourceModel implements ITypeNameObject, IUdmObjectModel, IAwsCloudWatchLoggingResourceModel, IAwsEncryptedResourceModel, IAwsNetworkedResourceModel, INetworkAccessResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        public entityNetwork: Optional<NetworkAccessResourceStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        dnsZoneIds: string[],
        kmsEncryptionKeyIdReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsDocDbDatabaseInstanceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId,
            dnsZoneIds,
            kmsEncryptionKeyIdReferences);
    }
}

export class AwsRdsDocDbGlobalClusterProfile extends AwsRdsGlobalClusterResourceProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        creation: Optional<AwsResourceProfileCreation>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsDocDbGlobalClusterProfile",
            creation);
    }
}

export class AwsRdsGlobalClusterResourceModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAwsEncryptedResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public clusterIdReferences: string[],
        public kmsEncryptionKeyIdReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsRdsGlobalClusterResourceTypeMetadata extends AwsResourceTypeMetadata {
    constructor() {
        super();
    }
}

export class AwsRdsNeptuneClusterBackupRetentionTimeFrameCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsNeptuneClusterBackupRetentionTimeFrameCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsRdsNeptuneClusterBackupRetentionTimeFrameCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsRdsNeptuneClusterBackupRetentionTimeFrameCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsNeptuneClusterBackupRetentionTimeFrameCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsRdsNeptuneClusterBackupRetentionTimeFrameRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public backupRetentionTimeFrame: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsNeptuneClusterBackupRetentionTimeFrameRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsRdsNeptuneClusterBackupRetentionTimeFrameRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsRdsNeptuneClusterBackupRetentionTimeFrameRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsNeptuneClusterBackupRetentionTimeFrameRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsRdsNeptuneClusterCloudWatchLoggingDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsNeptuneClusterCloudWatchLoggingDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsRdsNeptuneClusterCloudWatchLoggingDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsRdsNeptuneClusterCloudWatchLoggingDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsNeptuneClusterCloudWatchLoggingDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsRdsNeptuneClusterDeleteProtectionDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsNeptuneClusterDeleteProtectionDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsRdsNeptuneClusterDeleteProtectionDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsRdsNeptuneClusterDeleteProtectionDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsNeptuneClusterDeleteProtectionDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsRdsNeptuneClusterDeleteProtectionDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsNeptuneClusterDeleteProtectionDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsRdsNeptuneClusterDeleteProtectionDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsRdsNeptuneClusterDeleteProtectionDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsNeptuneClusterDeleteProtectionDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsRdsNeptuneClusterModel extends AwsRdsClusterResourceModel implements ITypeNameObject, IUdmObjectModel, IAwsCloudWatchLoggingResourceModel, IAwsEncryptedResourceModel, IAwsNetworkedResourceModel, INetworkAccessResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        public entityNetwork: Optional<NetworkAccessResourceStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        instanceIds: string[],
        kmsEncryptionKeyIdReferences: string[],
        snapshotIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsNeptuneClusterModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId,
            instanceIds,
            kmsEncryptionKeyIdReferences,
            snapshotIds);
    }
}

export class AwsRdsNeptuneClusterSnapshotEncryptionDisabledRisk extends AggregatedEntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        aggregatedEntityIds: string[],
        aggregatingEntityTypeName: string,
        public accessLevel: Optional<AwsResourceAccessLevel>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsNeptuneClusterSnapshotEncryptionDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            aggregatedEntityIds,
            aggregatingEntityTypeName);
    }
}

export class AwsRdsNeptuneClusterSnapshotEncryptionDisabledRiskModel extends AggregatedEntityRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsRdsNeptuneClusterSnapshotEncryptionDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsNeptuneClusterSnapshotEncryptionDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsRdsNeptuneClusterSnapshotPublicAccessExistsRisk extends PublicEntityRisk implements ITypeNameObject, IPublicRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsNeptuneClusterSnapshotPublicAccessExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsRdsNeptuneClusterSnapshotPublicAccessExistsRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsRdsNeptuneClusterSnapshotPublicAccessExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsNeptuneClusterSnapshotPublicAccessExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsRdsNeptuneDatabaseInstanceMinorVersionUpgradeDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsNeptuneDatabaseInstanceMinorVersionUpgradeDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsRdsNeptuneDatabaseInstanceMinorVersionUpgradeDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsRdsNeptuneDatabaseInstanceMinorVersionUpgradeDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsNeptuneDatabaseInstanceMinorVersionUpgradeDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsRdsNeptuneDatabaseInstanceModel extends AwsRdsDatabaseInstanceResourceModel implements ITypeNameObject, IUdmObjectModel, IAwsCloudWatchLoggingResourceModel, IAwsEncryptedResourceModel, IAwsNetworkedResourceModel, INetworkAccessResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        public entityNetwork: Optional<NetworkAccessResourceStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        dnsZoneIds: string[],
        kmsEncryptionKeyIdReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsNeptuneDatabaseInstanceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId,
            dnsZoneIds,
            kmsEncryptionKeyIdReferences);
    }
}

export class AwsRdsNeptuneGlobalClusterModel extends AwsRdsGlobalClusterResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAwsEncryptedResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        clusterIdReferences: string[],
        kmsEncryptionKeyIdReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsNeptuneGlobalClusterModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId,
            clusterIdReferences,
            kmsEncryptionKeyIdReferences);
    }
}

export class AwsRdsResource extends AwsResource implements ITypeNameObject, IAwsResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public rawId: string,
        public resourceId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsRdsSnapshotEncryptionDisabledRisk extends AggregatedEntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        aggregatedEntityIds: string[],
        aggregatingEntityTypeName: string,
        public accessLevel: Optional<AwsResourceAccessLevel>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsSnapshotEncryptionDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            aggregatedEntityIds,
            aggregatingEntityTypeName);
    }
}

export class AwsRdsSnapshotEncryptionDisabledRiskModel extends AggregatedEntityRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsRdsSnapshotEncryptionDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsSnapshotEncryptionDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsRdsSnapshotPublicAccessExistsCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsSnapshotPublicAccessExistsCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsRdsSnapshotPublicAccessExistsCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsRdsSnapshotPublicAccessExistsCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsSnapshotPublicAccessExistsCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsRdsSnapshotPublicAccessExistsRisk extends PublicEntityRisk implements ITypeNameObject, IPublicRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsSnapshotPublicAccessExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsRdsSnapshotPublicAccessExistsRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsRdsSnapshotPublicAccessExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsSnapshotPublicAccessExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsRdsSnapshotResource extends AwsRdsResource implements ITypeNameObject, IAwsAccessResource, IAwsEncryptedResource, IAwsTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        rawId: string,
        resourceId: string,
        public accessLevel: Optional<AwsResourceAccessLevel>,
        public encryptionExists: boolean,
        public kmsEncryptionKeyOrAliasArns: string[],
        public rawStatus: string,
        public sharedTenantRawIds: string[],
        public storageKmsEncryptionKeyOrAliasArn: Optional<string>,
        public storageSize: number,
        public type: AwsResourceSnapshotType,
        public vpcId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            rawId,
            resourceId);
    }
}

export class AwsRdsSnapshotResourceModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IAwsAccessResourceModel, IAwsEncryptedResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public accessLevel: Optional<AwsResourceAccessLevel>,
        public kmsEncryptionKeyIdReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsReconnaissanceRisk extends AwsBehaviorIdentityRisk implements ITypeNameObject, IActivityRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        endTime: string,
        eventActions: string[],
        identityId: string,
        ipAddresses: string[],
        previousBehaviorDate: Optional<string>,
        startTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsReconnaissanceRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            endTime,
            eventActions,
            identityId,
            ipAddresses,
            previousBehaviorDate,
            startTime);
    }
}

export class AwsReconnaissanceRiskModel extends AwsBehaviorIdentityRiskModel implements ITypeNameObject, IUdmObjectModel, IBehaviorIdentityRiskModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsReconnaissanceRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        originatorEntityIdReferences: string[],
        infos: AwsBehaviorIdentityRiskModelInfo[],
        resourceIdReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsReconnaissanceRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            originatorEntityIdReferences,
            infos,
            resourceIdReferences);
    }
}

export class AwsRedshiftClusterBackupRetentionTimeFrameCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRedshiftClusterBackupRetentionTimeFrameCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsRedshiftClusterBackupRetentionTimeFrameCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsRedshiftClusterBackupRetentionTimeFrameCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRedshiftClusterBackupRetentionTimeFrameCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsRedshiftClusterBackupRetentionTimeFrameRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public backupRetentionTimeFrame: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRedshiftClusterBackupRetentionTimeFrameRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsRedshiftClusterBackupRetentionTimeFrameRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsRedshiftClusterBackupRetentionTimeFrameRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRedshiftClusterBackupRetentionTimeFrameRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsRedshiftClusterEncryptionDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRedshiftClusterEncryptionDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsRedshiftClusterEncryptionDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsRedshiftClusterEncryptionDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRedshiftClusterEncryptionDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsRedshiftClusterEncryptionDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRedshiftClusterEncryptionDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsRedshiftClusterEncryptionDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsRedshiftClusterEncryptionDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRedshiftClusterEncryptionDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsRedshiftClusterModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IAwsEncryptedResourceModel, IAwsNetworkedResourceModel, INetworkAccessResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        public entityNetwork: Optional<NetworkAccessResourceStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public dnsZoneIds: string[],
        public kmsEncryptionKeyIdReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRedshiftClusterModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsRedshiftClusterSecurityGroupModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRedshiftClusterSecurityGroupModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsRedshiftNamespaceModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IAwsEncryptedResourceModel, IAwsNetworkedResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public kmsEncryptionKeyIdReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRedshiftNamespaceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsRedshiftResource extends AwsResource implements ITypeNameObject, IAwsResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsRedshiftWorkgroup extends AwsRedshiftResource implements ITypeNameObject, IAwsResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public namespaceName: Optional<string>,
        public securityGroupIds: string[],
        public subnetIds: string[],
        public vpcIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRedshiftWorkgroup",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsRedshiftWorkgroupModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRedshiftWorkgroupModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsResourceChange extends AwsChange implements ITypeNameObject, ICloudProviderResourceChange {
    constructor(
        typeName: string,
        entityIds: string[],
        id: string,
        resolved: boolean,
        public resourceId: string) {
        super(
            typeName,
            entityIds,
            id,
            resolved);
    }
}

export class AwsResourceCodeResourceNotExistsRisk extends CloudRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsResourceCodeResourceNotExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType);
    }
}

export class AwsResourceCodeResourceNotExistsRiskModel extends AwsCustomRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsResourceCodeResourceNotExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsResourceCodeResourceNotExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsResourceGroupModel extends AwsResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IEntityGroupModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsResourceTagNotExistsRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsResourceTagNotExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsResourceTagNotExistsRiskModel extends AwsCustomRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsResourceTagNotExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public existsResourceTagKeys: Optional<string[]>,
        public existsResourceTagPattern: Optional<ResourceTagPattern>,
        public existsResourceTags: Optional<ResourceTag[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsResourceTagNotExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsResourceTagSecretExistsRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public excludedSecretTagKeys: string[],
        public riskedEntityTypeName: string,
        public secretTagKeys: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsResourceTagSecretExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsResourceTagSecretExistsRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel, ISecretExistsRiskModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsResourceTagSecretExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public secretCount: number,
        public secretsManagerDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsResourceTagSecretExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsResourceUsageRisk extends AwsActivityRisk implements ITypeNameObject, IActivityRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        public eventNames: string[],
        public identityIds: string[],
        public resourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType);
    }
}

export class AwsRoleTemplateMismatchRisk extends CloudRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRoleTemplateMismatchRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType);
    }
}

export class AwsRoleTemplateMismatchRiskModel extends AwsCustomRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsRoleTemplateMismatchRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRoleTemplateMismatchRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsRoute53HostedZone extends AwsRoute53Resource implements ITypeNameObject, IAwsTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public description: Optional<string>,
        public publicNetworkAccess: boolean,
        public rawId: string,
        public records: AwsRoute53HostedZoneRecord[],
        public signingStatus: Optional<AwsRoute53HostedZoneSigningStatus>,
        public vpcIds: Optional<string[]>,
        public vpcRawIds: Optional<string[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRoute53HostedZone",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsRoute53HostedZoneSigningDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRoute53HostedZoneSigningDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsRoute53HostedZoneSigningDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsRoute53HostedZoneSigningDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRoute53HostedZoneSigningDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsRoute53HostedZoneSigningDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRoute53HostedZoneSigningDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsRoute53HostedZoneSigningDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsRoute53HostedZoneSigningDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public secretManagerEnvironmentVariableDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRoute53HostedZoneSigningDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsS3Bucket extends AwsS3Resource implements ITypeNameObject, IAwsAccessResource, IAwsDnsRecordResource, IAwsEncryptedResource, IAwsPolicyResource, IAwsTagResource, IObjectStore {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public accessControlList: AwsS3AccessControlList,
        public accessControlListEffectivePublicAccess: boolean,
        public accessControlListPublicAccessHighSeveritySensitivePermissionActions: string[],
        public accessControlListPublicAccessNonKmsEncryptedDataPermissionActions: string[],
        public accessLevel: Optional<AwsResourceAccessLevel>,
        public dnsNames: string[],
        public encryptionExists: boolean,
        public eventEnabled: boolean,
        public kmsEncryptionKeyOrAliasArns: string[],
        public objectCount: number,
        public objectLifecycleConfigurationEnabled: Optional<boolean>,
        public objectLockEnabled: boolean,
        public objectMfaDeleteEnabled: boolean,
        public objectOwnership: AwsS3BucketObjectOwnership,
        public objectVersioningEnabled: boolean,
        public policyDocument: Optional<AwsIamPolicyDocument>,
        public policyDocumentEffectivePublicAccess: boolean,
        public policyDocumentExists: boolean,
        public policyDocumentPublicAccess: Optional<boolean>,
        public policyDocumentPublicAccessHighSeveritySensitivePermissionActions: string[],
        public policyDocumentPublicAccessNonKmsEncryptedDataPermissionActions: string[],
        public policyDocumentPublicAccessPermissionActions: string[],
        public publicAccessPolicy: AwsS3PublicAccessPolicy,
        public publicIpAddresses: Optional<string[]>,
        public serverSideEncryptionType: Optional<AwsS3BucketServerSideEncryptionType>,
        public serverSideKmsEncryptionKeyOrAliasArn: Optional<string>,
        public storageSize: number,
        public url: Optional<string>,
        public websiteEnabled: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsS3Bucket",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsS3BucketAccessPoint extends AwsS3Resource implements ITypeNameObject, IAwsPolicyResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public bucketId: string,
        public policyDocument: Optional<AwsIamPolicyDocument>,
        public policyDocumentExists: boolean,
        public policyDocumentPublicAccess: Optional<boolean>,
        public publicAccessPolicy: AwsS3PublicAccessPolicy,
        public vpcRawId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsS3BucketAccessPoint",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsS3BucketBlockPublicAccessDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsS3BucketBlockPublicAccessDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsS3BucketBlockPublicAccessDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsS3BucketBlockPublicAccessDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsS3BucketBlockPublicAccessDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsS3BucketBlockPublicAccessDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsS3BucketBlockPublicAccessDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsS3BucketBlockPublicAccessDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsS3BucketBlockPublicAccessDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsS3BucketBlockPublicAccessDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsS3BucketEncryptionDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsS3BucketEncryptionDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsS3BucketEncryptionDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsS3BucketEncryptionDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsS3BucketEncryptionDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsS3BucketEncryptionDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsS3BucketEncryptionDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsS3BucketEncryptionDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsS3BucketEncryptionDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsS3BucketEncryptionDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsS3BucketHttpsOnlyDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsS3BucketHttpsOnlyDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsS3BucketHttpsOnlyDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsS3BucketHttpsOnlyDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsS3BucketHttpsOnlyDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsS3BucketHttpsOnlyDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsS3BucketHttpsOnlyDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsS3BucketHttpsOnlyDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsS3BucketHttpsOnlyDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public resolutionPolicyDocumentRawStatement: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsS3BucketHttpsOnlyDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsS3BucketManagementRisk extends AwsResourceUsageRisk implements ITypeNameObject, IActivityRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        eventNames: string[],
        identityIds: string[],
        resourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsS3BucketManagementRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            eventNames,
            identityIds,
            resourceIds);
    }
}

export class AwsS3BucketManagementRiskModel extends AwsCustomActivityRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsS3BucketManagementRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        originatorEntityIdReferences: string[],
        public descriptionType: AwsS3BucketManagementRiskModelDescriptionType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsS3BucketManagementRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            originatorEntityIdReferences);
    }
}

export class AwsS3BucketObject extends AwsS3Resource implements ITypeNameObject, IAwsResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public bucketId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsS3BucketObject",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsS3BucketObjectMfaDeleteDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsS3BucketObjectMfaDeleteDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsS3BucketObjectMfaDeleteDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsS3BucketObjectMfaDeleteDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsS3BucketObjectMfaDeleteDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsS3BucketObjectMfaDeleteDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public bucketTrailIds: string[],
        public tenantRoleId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsS3BucketObjectMfaDeleteDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsS3BucketObjectMfaDeleteDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsS3BucketObjectMfaDeleteDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsS3BucketObjectMfaDeleteDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsS3BucketPublicAccessExistsCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsS3BucketPublicAccessExistsCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsS3BucketPublicAccessExistsCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsS3BucketPublicAccessExistsCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsS3BucketPublicAccessExistsCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsS3BucketPublicAccessExistsRisk extends PublicEntityRisk implements ITypeNameObject, IPublicRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsS3BucketPublicAccessExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsS3BucketPublicAccessExistsRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsS3BucketPublicAccessExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsS3BucketPublicAccessExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsSageMakerNotebookInstanceDefaultEncryptionRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSageMakerNotebookInstanceDefaultEncryptionRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsSageMakerNotebookInstanceDefaultEncryptionRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsSageMakerNotebookInstanceDefaultEncryptionRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSageMakerNotebookInstanceDefaultEncryptionRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsSageMakerNotebookInstanceRootAccessEnabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSageMakerNotebookInstanceRootAccessEnabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsSageMakerNotebookInstanceRootAccessEnabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsSageMakerNotebookInstanceRootAccessEnabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSageMakerNotebookInstanceRootAccessEnabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsSageMakerNotebookInstanceUnrestrictedOutboundAccessRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSageMakerNotebookInstanceUnrestrictedOutboundAccessRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsSageMakerNotebookInstanceUnrestrictedOutboundAccessRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsSageMakerNotebookInstanceUnrestrictedOutboundAccessRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSageMakerNotebookInstanceUnrestrictedOutboundAccessRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsSecretsManagerSecretManagementRisk extends AwsResourceUsageRisk implements ITypeNameObject, IActivityRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        eventNames: string[],
        identityIds: string[],
        resourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSecretsManagerSecretManagementRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            eventNames,
            identityIds,
            resourceIds);
    }
}

export class AwsSecretsManagerSecretManagementRiskModel extends AwsCustomActivityRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsSecretsManagerSecretManagementRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        originatorEntityIdReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSecretsManagerSecretManagementRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            originatorEntityIdReferences);
    }
}

export class AwsSecretsManagerSecretPublicAccessExistsCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSecretsManagerSecretPublicAccessExistsCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsSecretsManagerSecretPublicAccessExistsCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsSecretsManagerSecretPublicAccessExistsCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSecretsManagerSecretPublicAccessExistsCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsSecretsManagerSecretPublicAccessExistsRisk extends PublicEntityRisk implements ITypeNameObject, IPublicRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSecretsManagerSecretPublicAccessExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsSecretsManagerSecretPublicAccessExistsRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsSecretsManagerSecretPublicAccessExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSecretsManagerSecretPublicAccessExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsSecretsManagerSecretRotationDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSecretsManagerSecretRotationDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsSecretsManagerSecretRotationDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsSecretsManagerSecretRotationDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSecretsManagerSecretRotationDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsSecretsManagerSecretRotationDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public creationTime: string,
        public rotationTime: Optional<string>,
        public usageTime: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSecretsManagerSecretRotationDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsSecretsManagerSecretRotationDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsSecretsManagerSecretRotationDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public secretRotationDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSecretsManagerSecretRotationDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsSecurityHubRegionHubDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public regionSystemNames: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSecurityHubRegionHubDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsSecurityHubRegionHubDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsSecurityHubRegionHubDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSecurityHubRegionHubDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsServiceDiscoveryService extends AwsNotSyncedResource implements ITypeNameObject, IAwsResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsServiceDiscoveryService",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsSesIdentity extends AwsSesResource implements ITypeNameObject, IAwsResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSesIdentity",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsSetInstanceMetadataServiceTokenHopMaxCountChange extends AwsResourceChange implements ITypeNameObject, ICloudProviderResourceChange {
    constructor(
        entityIds: string[],
        id: string,
        resolved: boolean,
        resourceId: string,
        public tokenHopMaxCount: number) {
        super(
            "AwsSetInstanceMetadataServiceTokenHopMaxCountChange",
            entityIds,
            id,
            resolved,
            resourceId);
    }
}

export class AwsSetInstanceMetadataServiceV2VersionChange extends AwsResourceChange implements ITypeNameObject, ICloudProviderResourceChange {
    constructor(
        entityIds: string[],
        id: string,
        resolved: boolean,
        resourceId: string) {
        super(
            "AwsSetInstanceMetadataServiceV2VersionChange",
            entityIds,
            id,
            resolved,
            resourceId);
    }
}

export class AwsSeverePermissionPrincipalRisk extends AwsAccessPrincipalRisk implements ITypeNameObject, IAccessPrincipalRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        activityTime: Optional<string>,
        excessivePermissionActionSeverity: Optional<Severity>,
        groupUserIds: string[],
        permissionActions: string[],
        roleMostIndirectOriginatorResourceIds: string[],
        roleOriginatorResourceIds: string[],
        sensitiveResources: AwsAccessPrincipalRiskSensitiveResources,
        serviceIdToUsageTimeMap: Dictionary<Optional<string>>,
        public severePermissionActionPermittingEntityIds: string[],
        public severePermissionActions: string[],
        public severePermissionActionStartTime: string,
        public type: PrincipalSeverePermissionActionRiskType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSeverePermissionPrincipalRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            activityTime,
            excessivePermissionActionSeverity,
            groupUserIds,
            permissionActions,
            roleMostIndirectOriginatorResourceIds,
            roleOriginatorResourceIds,
            sensitiveResources,
            serviceIdToUsageTimeMap);
    }
}

export class AwsSeverePermissionPrincipalRiskModel extends AwsAccessPrincipalRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsSeverePermissionPrincipalRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        userActiveAccessKeyCount: Optional<number>,
        public infos: AwsSeverePermissionPrincipalRiskModelInfo[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSeverePermissionPrincipalRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            userActiveAccessKeyCount);
    }
}

export class AwsSnsTopicEncryptionDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSnsTopicEncryptionDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsSnsTopicEncryptionDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsSnsTopicEncryptionDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSnsTopicEncryptionDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsSnsTopicEncryptionDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSnsTopicEncryptionDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsSnsTopicEncryptionDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsSnsTopicEncryptionDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSnsTopicEncryptionDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsSnsTopicHttpsOnlyDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSnsTopicHttpsOnlyDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsSnsTopicHttpsOnlyDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsSnsTopicHttpsOnlyDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public resolutionPolicyDocumentRawStatement: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSnsTopicHttpsOnlyDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsSnsTopicPublicAccessExistsCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSnsTopicPublicAccessExistsCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsSnsTopicPublicAccessExistsCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsSnsTopicPublicAccessExistsCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSnsTopicPublicAccessExistsCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsSnsTopicPublicAccessExistsRisk extends PublicEntityRisk implements ITypeNameObject, IPublicRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSnsTopicPublicAccessExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsSnsTopicPublicAccessExistsRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsSnsTopicPublicAccessExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSnsTopicPublicAccessExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsSqsQueueEncryptionDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSqsQueueEncryptionDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsSqsQueueEncryptionDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsSqsQueueEncryptionDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSqsQueueEncryptionDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsSqsQueueEncryptionDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public crossTenantAccessDataLeakagePermissionActions: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSqsQueueEncryptionDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsSqsQueueEncryptionDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsSqsQueueEncryptionDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSqsQueueEncryptionDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsSqsQueuePublicAccessExistsCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSqsQueuePublicAccessExistsCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsSqsQueuePublicAccessExistsCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsSqsQueuePublicAccessExistsCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSqsQueuePublicAccessExistsCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsSqsQueuePublicAccessExistsRisk extends PublicEntityRisk implements ITypeNameObject, IPublicRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSqsQueuePublicAccessExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsSqsQueuePublicAccessExistsRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsSqsQueuePublicAccessExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSqsQueuePublicAccessExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsSsmParameter extends AwsSsmResource implements ITypeNameObject, IAwsEncryptedResource, IAwsTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public description: Optional<string>,
        public encryptionExists: boolean,
        public kmsEncryptionKeyOrAliasArns: string[],
        public type: AwsSsmParameterType,
        public updateIdentityReference: AwsResourceReference,
        public updateTime: string,
        public version: number) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSsmParameter",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsSsmParameterSecretExistsCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSsmParameterSecretExistsCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AwsSsmParameterSecretExistsCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsSsmParameterSecretExistsCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSsmParameterSecretExistsCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AwsSsmParameterSecretExistsRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public parameterPermissionsIdentityIds: string[],
        public parameterPermissionsThirdPartyRoleIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSsmParameterSecretExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsSsmParameterSecretExistsRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel, ISecretExistsRiskModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsSsmParameterSecretExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public secretCount: number) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSsmParameterSecretExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsSsmServiceSetting extends AwsNotSyncedResource implements ITypeNameObject, IAwsResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSsmServiceSetting",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsSsoGroupModel extends AwsSsoPrincipalModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IPermissionManagementGroupModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        assignedPrincipalIdToPermissionSetIdToTenantRawIdToRoleSearchableReferenceMap: Dictionary<Dictionary<Dictionary<Optional<EntitySearchableReference>>>>,
        federatedIdentityReference: Optional<EntityReference>,
        roleIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSsoGroupModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId,
            assignedPrincipalIdToPermissionSetIdToTenantRawIdToRoleSearchableReferenceMap,
            federatedIdentityReference,
            roleIds);
    }
}

export class AwsSsoInstance extends AwsSsoResource implements ITypeNameObject, IAwsResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public identityStoreRawId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSsoInstance",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsSsoPermissionSet extends AwsSsoResource implements ITypeNameObject, IAwsPrincipal, IAwsTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public awsManagedPolicyIds: string[],
        public customerManagedPolicyExists: boolean,
        public customerManagedPolicyReferences: AwsSsoPermissionsSetCustomerManagedPolicyReference[],
        public description: Optional<string>,
        public inlinePolicyDocument: Optional<AwsIamPolicyDocument>,
        public instanceId: string,
        public permissionsBoundary: Optional<AwsSsoPermissionsSetPermissionsBoundary>,
        public permissionsBoundaryExists: boolean,
        public provisioned: boolean,
        public rawId: string,
        public relayStateRawUrl: Optional<string>,
        public sessionDuration: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSsoPermissionSet",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsSsoPermissionSetAssignmentRequest extends PermissionAssignmentRequest implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        closeReason: Optional<PermissionRequestCloseReason>,
        expirationTime: Optional<string>,
        expirationTimeFrame: string,
        granteeUserTenantId: string,
        permissionEligibilityId: string,
        reason: Optional<string>,
        startTime: Optional<string>,
        status: PermissionRequestStatus,
        statusUpdateTime: string,
        tenantIds: string[],
        public permissions: Optional<AwsSsoPermissionSetAssignmentRequestPermissions>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSsoPermissionSetAssignmentRequest",
            scopeId,
            closeReason,
            expirationTime,
            expirationTimeFrame,
            granteeUserTenantId,
            permissionEligibilityId,
            reason,
            startTime,
            status,
            statusUpdateTime,
            tenantIds);
    }
}

export class AwsSsoPermissionSetAssignmentRequestActivationFailureData extends PermissionAssignmentRequestActivationFailureData implements ITypeNameObject {
    constructor(
        errorMessage: Optional<string>,
        retryCount: number,
        tenantIdToErrorMessageMap: Dictionary<string>) {
        super(
            "AwsSsoPermissionSetAssignmentRequestActivationFailureData",
            errorMessage,
            retryCount,
            tenantIdToErrorMessageMap);
    }
}

export class AwsSsoPermissionSetAssignmentRisk extends AwsResourceUsageRisk implements ITypeNameObject, IActivityRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        eventNames: string[],
        identityIds: string[],
        resourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSsoPermissionSetAssignmentRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            eventNames,
            identityIds,
            resourceIds);
    }
}

export class AwsSsoPermissionSetAssignmentRiskModel extends AwsCustomActivityRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsSsoPermissionSetAssignmentRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        originatorEntityIdReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSsoPermissionSetAssignmentRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            originatorEntityIdReferences);
    }
}

export class AwsSsoPermissionSetManagementRisk extends AwsResourceUsageRisk implements ITypeNameObject, IActivityRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        eventNames: string[],
        identityIds: string[],
        resourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSsoPermissionSetManagementRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            eventNames,
            identityIds,
            resourceIds);
    }
}

export class AwsSsoPermissionSetManagementRiskModel extends AwsCustomActivityRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsSsoPermissionSetManagementRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        originatorEntityIdReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSsoPermissionSetManagementRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            originatorEntityIdReferences);
    }
}

export class AwsSsoPermissionSetUnusedRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public accountIds: string[],
        public activityTime: Optional<string>,
        public assignedPrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSsoPermissionSetUnusedRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsSsoPermissionSetUnusedRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsSsoPermissionSetUnusedRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public infos: AwsSsoPermissionSetUnusedRiskModelInfo[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSsoPermissionSetUnusedRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsSsoPrincipal extends AwsSsoResource implements ITypeNameObject, IAwsResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public externalId: Optional<string>,
        public permissionSetIdToTenantRawIdsMap: Dictionary<string[]>,
        public rawId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsSsoPrincipalCreationRisk extends AwsResourceUsageRisk implements ITypeNameObject, IActivityRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        eventNames: string[],
        identityIds: string[],
        resourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSsoPrincipalCreationRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            eventNames,
            identityIds,
            resourceIds);
    }
}

export class AwsSsoPrincipalCreationRiskModel extends AwsCustomActivityRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsSsoPrincipalCreationRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        originatorEntityIdReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSsoPrincipalCreationRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            originatorEntityIdReferences);
    }
}

export class AwsSsoUser extends AwsSsoPrincipal implements ITypeNameObject, IAwsResource, IAwsOriginatorIdentity, IDirectoryUser, IPermissionManagementUser {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        externalId: Optional<string>,
        permissionSetIdToTenantRawIdsMap: Dictionary<string[]>,
        rawId: string,
        public active: boolean,
        public firstName: string,
        public identifier: string,
        public lastName: string,
        public mail: Optional<string>,
        public rawMail: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSsoUser",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            externalId,
            permissionSetIdToTenantRawIdsMap,
            rawId);
    }
}

export class AwsStepsFunctionStateMachine extends AwsNotSyncedResource implements ITypeNameObject, IAwsResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsStepsFunctionStateMachine",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsStsFederatedUser extends AwsNotSyncedResource implements ITypeNameObject, IAwsResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsStsFederatedUser",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsTagResourceGroupModel extends AwsResourceGroupModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IEntityGroupModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsTenantEntityCreationRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsTenantEntityCreationRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsTenantEntityCreationRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsTenantEntityCreationRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsTenantEntityCreationRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AwsUpsertPolicyChange extends AwsResourceChange implements ITypeNameObject, ICloudProviderResourceChange {
    constructor(
        typeName: string,
        entityIds: string[],
        id: string,
        resolved: boolean,
        resourceId: string,
        public rawDocument: string) {
        super(
            typeName,
            entityIds,
            id,
            resolved,
            resourceId);
    }
}

export class AwsUpsertPrincipalManagedPolicyChange extends AwsUpsertPolicyChange implements ITypeNameObject, ICloudProviderResourceChange, ICodeInfrastructureChange {
    constructor(
        entityIds: string[],
        id: string,
        resolved: boolean,
        resourceId: string,
        rawDocument: string,
        public create: boolean,
        public description: Optional<string>,
        public name: string,
        public policyId: Optional<string>) {
        super(
            "AwsUpsertPrincipalManagedPolicyChange",
            entityIds,
            id,
            resolved,
            resourceId,
            rawDocument);
    }
}

export class AwsUpsertResourcePolicyChange extends AwsUpsertPolicyChange implements ITypeNameObject, ICloudProviderResourceChange {
    constructor(
        typeName: string,
        entityIds: string[],
        id: string,
        resolved: boolean,
        resourceId: string,
        rawDocument: string) {
        super(
            typeName,
            entityIds,
            id,
            resolved,
            resourceId,
            rawDocument);
    }
}

export class AwsUpsertSecretPolicyChange extends AwsUpsertResourcePolicyChange implements ITypeNameObject, ICloudProviderResourceChange {
    constructor(
        entityIds: string[],
        id: string,
        resolved: boolean,
        resourceId: string,
        rawDocument: string) {
        super(
            "AwsUpsertSecretPolicyChange",
            entityIds,
            id,
            resolved,
            resourceId,
            rawDocument);
    }
}

export class AwsUpsertVpcEndpointServicePolicyChange extends AwsUpsertResourcePolicyChange implements ITypeNameObject, ICloudProviderResourceChange {
    constructor(
        entityIds: string[],
        id: string,
        resolved: boolean,
        resourceId: string,
        rawDocument: string) {
        super(
            "AwsUpsertVpcEndpointServicePolicyChange",
            entityIds,
            id,
            resolved,
            resourceId,
            rawDocument);
    }
}

export class AwsWafGlobalWebAcl extends AwsWafWebAcl implements ITypeNameObject, IAwsTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        associatedResourceReferences: AwsResourceReference[],
        defaultAction: AwsWafWebAclDefaultAction,
        description: Optional<string>,
        rawId: string,
        rules: AwsWafWebAclRuleBase[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsWafGlobalWebAcl",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            associatedResourceReferences,
            defaultAction,
            description,
            rawId,
            rules);
    }
}

export class AwsWafGlobalWebAclModel extends AwsWafWebAclModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        associatedResourceIdReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsWafGlobalWebAclModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId,
            associatedResourceIdReferences);
    }
}

export class AwsWafRegionalWebAcl extends AwsWafWebAcl implements ITypeNameObject, IAwsTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        associatedResourceReferences: AwsResourceReference[],
        defaultAction: AwsWafWebAclDefaultAction,
        description: Optional<string>,
        rawId: string,
        rules: AwsWafWebAclRuleBase[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsWafRegionalWebAcl",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            associatedResourceReferences,
            defaultAction,
            description,
            rawId,
            rules);
    }
}

export class AwsWafRegionalWebAclModel extends AwsWafWebAclModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        associatedResourceIdReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsWafRegionalWebAclModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId,
            associatedResourceIdReferences);
    }
}

export class AzureAccessPrincipalRisk extends EntityRisk implements ITypeNameObject, IAccessPrincipalRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public groupIdentityIds: string[],
        public identityEnabled: Optional<boolean>,
        public originatorResourceIds: string[],
        public removedExcessivePermissionActionsSeverity: Optional<Severity>,
        public servicePrincipalApplicationId: Optional<string>,
        public signInTime: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureAccessPrincipalRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureAccessPrincipalRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureApplicationAppSettingsSecretExistsCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureApplicationAppSettingsSecretExistsCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AzureApplicationAppSettingsSecretExistsCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureApplicationAppSettingsSecretExistsCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureApplicationAppSettingsSecretExistsCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AzureAuthorizationDenyAssignmentModel extends AzureResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAuthorizationDenyAssignmentModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType);
    }
}

export class AzureAuthorizationLockModel extends AzureResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAuthorizationLockModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType);
    }
}

export class AzureAuthorizationRoleAssignmentResourceModel extends AzureResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IRelatedTenantsEntityModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        public containingRoleAssignmentResourceExists: boolean,
        public containingRoleAssignmentResourceIds: string[],
        public endTime: Optional<string>,
        public principalIdReference: string,
        public roleDefinitionName: string,
        public roleDefinitionType: AzureAuthorizationRoleDefinitionType,
        public roleManagementPolicyAssignmentId: Optional<string>,
        public scopeEntityIdReference: string,
        public scopeEntityPathType: AzureEntityPathType,
        public scopeEntityTypeName: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType);
    }
}

export class AzureAuthorizationRoleAssignmentResourceModelFilters extends AzureResourceModelFilters implements IEntityModelFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        openRelatedEntityRiskTypeIdentifierItems: FilterItems<string>,
        regionIdItems: FilterItems<string>,
        tenantIdItems: FilterItems<string>,
        typeNameItems: FilterItems<string>,
        creationTimeRange: FilterTimeRange,
        public endTimeRange: FilterTimeRange,
        public scopeEntityTypeNameItems: FilterItems<string>) {
        super(
            attributeValueItems,
            openRelatedEntityRiskTypeIdentifierItems,
            regionIdItems,
            tenantIdItems,
            typeNameItems,
            creationTimeRange);
    }
}

export class AzureAuthorizationRoleAssignmentScheduleModel extends AzureAuthorizationRoleAssignmentResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IRelatedTenantsEntityModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        containingRoleAssignmentResourceExists: boolean,
        containingRoleAssignmentResourceIds: string[],
        endTime: Optional<string>,
        principalIdReference: string,
        roleDefinitionName: string,
        roleDefinitionType: AzureAuthorizationRoleDefinitionType,
        roleManagementPolicyAssignmentId: Optional<string>,
        scopeEntityIdReference: string,
        scopeEntityPathType: AzureEntityPathType,
        scopeEntityTypeName: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAuthorizationRoleAssignmentScheduleModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            containingRoleAssignmentResourceExists,
            containingRoleAssignmentResourceIds,
            endTime,
            principalIdReference,
            roleDefinitionName,
            roleDefinitionType,
            roleManagementPolicyAssignmentId,
            scopeEntityIdReference,
            scopeEntityPathType,
            scopeEntityTypeName);
    }
}

export class AzureAuthorizationRoleDefinitionModel extends AzureResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IRelatedTenantsEntityModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        public unused: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAuthorizationRoleDefinitionModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType);
    }
}

export class AzureAuthorizationRoleEligibilityScheduleModel extends AzureAuthorizationRoleAssignmentResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IRelatedTenantsEntityModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        containingRoleAssignmentResourceExists: boolean,
        containingRoleAssignmentResourceIds: string[],
        endTime: Optional<string>,
        principalIdReference: string,
        roleDefinitionName: string,
        roleDefinitionType: AzureAuthorizationRoleDefinitionType,
        roleManagementPolicyAssignmentId: Optional<string>,
        scopeEntityIdReference: string,
        scopeEntityPathType: AzureEntityPathType,
        scopeEntityTypeName: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAuthorizationRoleEligibilityScheduleModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            containingRoleAssignmentResourceExists,
            containingRoleAssignmentResourceIds,
            endTime,
            principalIdReference,
            roleDefinitionName,
            roleDefinitionType,
            roleManagementPolicyAssignmentId,
            scopeEntityIdReference,
            scopeEntityPathType,
            scopeEntityTypeName);
    }
}

export class AzureAuthorizationRoleManagementPolicyAssignmentModel extends AzureResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IRelatedTenantsEntityModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        public mfaEnabled: boolean,
        public roleDefinitionId: string,
        public roleEligibilityScheduleIds: string[],
        public scopeResourceId: string,
        public scopeResourceTypeName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAuthorizationRoleManagementPolicyAssignmentModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType);
    }
}

export class AzureBehaviorIdentityRisk extends CloudRisk implements ITypeNameObject, IActivityRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        public endTime: string,
        public eventActions: string[],
        public identityId: string,
        public ipAddresses: string[],
        public previousBehaviorDate: Optional<string>,
        public startTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType);
    }
}

export class AzureBehaviorIdentityRiskModel extends CloudRiskModel implements ITypeNameObject, IUdmObjectModel, IBehaviorIdentityRiskModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureBehaviorIdentityRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public infos: AzureBehaviorIdentityRiskModelInfo[],
        public resourceIdReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureBotServiceBotServiceInboundExternalWideRangeRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureBotServiceBotServiceInboundExternalWideRangeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureBotServiceBotServiceInboundExternalWideRangeRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureBotServiceBotServiceInboundExternalWideRangeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureBotServiceBotServiceInboundExternalWideRangeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureBotServiceBotServiceSharedKeyAccessEnabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureBotServiceBotServiceSharedKeyAccessEnabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureBotServiceBotServiceSharedKeyAccessEnabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureBotServiceBotServiceSharedKeyAccessEnabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureBotServiceBotServiceSharedKeyAccessEnabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureBotServiceBotServiceStateNetwork extends AzureNetworkAccessResourceStateNetwork implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        entityReference: EntityReference,
        inboundAccessType: NetworkInboundAccessType,
        inboundExternal: boolean,
        inboundExternalAccessScope: NetworkAccessScope,
        inboundExternalDestinationNetworkScopes: Optional<DestinationNetworkScope[]>,
        inboundExternalWideRange: Optional<boolean>,
        public publicNetworkAccess: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureBotServiceBotServiceStateNetwork",
            tenantId,
            entityReference,
            inboundAccessType,
            inboundExternal,
            inboundExternalAccessScope,
            inboundExternalDestinationNetworkScopes,
            inboundExternalWideRange);
    }
}

export class AzureChange extends Change implements ITypeNameObject {
    constructor(
        typeName: string,
        entityIds: string[],
        id: string,
        resolved: boolean) {
        super(
            typeName,
            entityIds,
            id,
            resolved);
    }
}

export class AzureCognitiveServicesAccountInboundExternalWideRangeRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureCognitiveServicesAccountInboundExternalWideRangeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureCognitiveServicesAccountInboundExternalWideRangeRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureCognitiveServicesAccountInboundExternalWideRangeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureCognitiveServicesAccountInboundExternalWideRangeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureCognitiveServicesAccountSharedKeyAccessEnabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureCognitiveServicesAccountSharedKeyAccessEnabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureCognitiveServicesAccountSharedKeyAccessEnabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureCognitiveServicesAccountSharedKeyAccessEnabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureCognitiveServicesAccountSharedKeyAccessEnabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureCognitiveServicesAccountStateNetwork extends AzureNetworkAccessResourceStateNetwork implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        entityReference: EntityReference,
        inboundAccessType: NetworkInboundAccessType,
        inboundExternal: boolean,
        inboundExternalAccessScope: NetworkAccessScope,
        inboundExternalDestinationNetworkScopes: Optional<DestinationNetworkScope[]>,
        inboundExternalWideRange: Optional<boolean>,
        public firewallRuleDatas: AzureCognitiveServicesAccountStateNetworkFirewallRuleData[],
        public publicNetworkAccess: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureCognitiveServicesAccountStateNetwork",
            tenantId,
            entityReference,
            inboundAccessType,
            inboundExternal,
            inboundExternalAccessScope,
            inboundExternalDestinationNetworkScopes,
            inboundExternalWideRange);
    }
}

export class AzureCognitiveServicesAccountUnrestrictedOutboundAccessRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureCognitiveServicesAccountUnrestrictedOutboundAccessRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureCognitiveServicesAccountUnrestrictedOutboundAccessRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureCognitiveServicesAccountUnrestrictedOutboundAccessRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public enablingDataLossPreventionDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureCognitiveServicesAccountUnrestrictedOutboundAccessRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureComputeResourceStateNetwork extends AzureNetworkAccessResourceStateNetwork implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        entityReference: EntityReference,
        inboundAccessType: NetworkInboundAccessType,
        inboundExternal: boolean,
        inboundExternalAccessScope: NetworkAccessScope,
        inboundExternalDestinationNetworkScopes: DestinationNetworkScope[],
        inboundExternalWideRange: Optional<boolean>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeResourceStateNetwork",
            tenantId,
            entityReference,
            inboundAccessType,
            inboundExternal,
            inboundExternalAccessScope,
            inboundExternalDestinationNetworkScopes,
            inboundExternalWideRange);
    }
}

export class AzureComputeVirtualMachineAnalysisServiceConfiguration extends ServiceConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeVirtualMachineAnalysisServiceConfiguration");
    }
}

export class AzureComputeVirtualMachineModelFilters extends AzureResourceModelFilters implements IEntityModelFilters, IEntityNetworkFilters, IVirtualMachineDiskResourceFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        openRelatedEntityRiskTypeIdentifierItems: FilterItems<string>,
        regionIdItems: FilterItems<string>,
        tenantIdItems: FilterItems<string>,
        typeNameItems: FilterItems<string>,
        creationTimeRange: FilterTimeRange,
        public networkAccess: EntityNetworkAccessFiltersSection,
        public scanTimeRange: FilterTimeRange,
        public stopTimeRange: FilterTimeRange) {
        super(
            attributeValueItems,
            openRelatedEntityRiskTypeIdentifierItems,
            regionIdItems,
            tenantIdItems,
            typeNameItems,
            creationTimeRange);
    }
}

export class AzureComputeVirtualMachineScaleSetModelFilters extends AzureResourceModelFilters implements IEntityModelFilters, IEntityNetworkFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        openRelatedEntityRiskTypeIdentifierItems: FilterItems<string>,
        regionIdItems: FilterItems<string>,
        tenantIdItems: FilterItems<string>,
        typeNameItems: FilterItems<string>,
        creationTimeRange: FilterTimeRange,
        public networkAccess: EntityNetworkAccessFiltersSection) {
        super(
            attributeValueItems,
            openRelatedEntityRiskTypeIdentifierItems,
            regionIdItems,
            tenantIdItems,
            typeNameItems,
            creationTimeRange);
    }
}

export class AzureComputeVirtualMachineScaleSetVirtualMachineModelFilters extends AzureResourceModelFilters implements IEntityModelFilters, IEntityNetworkFilters, IVirtualMachineDiskResourceFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        openRelatedEntityRiskTypeIdentifierItems: FilterItems<string>,
        regionIdItems: FilterItems<string>,
        tenantIdItems: FilterItems<string>,
        typeNameItems: FilterItems<string>,
        creationTimeRange: FilterTimeRange,
        public networkAccess: EntityNetworkAccessFiltersSection,
        public scanTimeRange: FilterTimeRange) {
        super(
            attributeValueItems,
            openRelatedEntityRiskTypeIdentifierItems,
            regionIdItems,
            tenantIdItems,
            typeNameItems,
            creationTimeRange);
    }
}

export class AzureComputeVirtualMachineUnmanagedDiskExistsCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeVirtualMachineUnmanagedDiskExistsCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AzureComputeVirtualMachineUnmanagedDiskExistsCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureComputeVirtualMachineUnmanagedDiskExistsCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeVirtualMachineUnmanagedDiskExistsCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AzureComputeVirtualMachineUnmanagedDiskExistsRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public unmanagedDisks: AzureComputeVirtualMachineUnmanagedDisk[],
        public virtualMachineStatus: AzureComputeVirtualMachineStatus) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeVirtualMachineUnmanagedDiskExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureComputeVirtualMachineUnmanagedDiskExistsRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureComputeVirtualMachineUnmanagedDiskExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public unmanagedDiskNames: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeVirtualMachineUnmanagedDiskExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureComputeVirtualMachineUserDataSecretExistsCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeVirtualMachineUserDataSecretExistsCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AzureComputeVirtualMachineUserDataSecretExistsCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureComputeVirtualMachineUserDataSecretExistsCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeVirtualMachineUserDataSecretExistsCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AzureContainerImage extends AzureResource implements ITypeNameObject, IAzureEntity, IResource, IContainerImage {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        public data: ContainerImageData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureContainerImage",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags);
    }
}

export class AzureContainerImageModel extends AzureResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IContainerImageModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        public kubernetesWorkloadResourceIds: string[],
        public operatingSystemReleaseNotesUrl: Optional<string>,
        public operatingSystemSupported: Optional<boolean>,
        public operatingSystemType: Optional<OperatingSystemType>,
        public repositoryIdReference: string,
        public virtualMachineIds: string[],
        public vulnerabilitySeveritiesSortValue: string,
        public workloadAnalysisOperatingSystem: Optional<WorkloadResourceScanOperatingSystem>,
        public workloadAnalysisOperatingSystemEndOfLifeDate: Optional<string>,
        public workloadAnalysisOperatingSystemExtendedSupport: Optional<boolean>,
        public workloadClusterResourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureContainerImageModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType);
    }
}

export class AzureContainerImageModelFilters extends AzureResourceModelFilters implements IEntityModelFilters, IContainerImageFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        openRelatedEntityRiskTypeIdentifierItems: FilterItems<string>,
        regionIdItems: FilterItems<string>,
        tenantIdItems: FilterItems<string>,
        typeNameItems: FilterItems<string>,
        creationTimeRange: FilterTimeRange,
        public containerImageCreationTimeRange: FilterTimeRange,
        public scanTimeRange: FilterTimeRange) {
        super(
            attributeValueItems,
            openRelatedEntityRiskTypeIdentifierItems,
            regionIdItems,
            tenantIdItems,
            typeNameItems,
            creationTimeRange);
    }
}

export class AzureContainerRegistryRegistryAccessKeyEnabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureContainerRegistryRegistryAccessKeyEnabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AzureContainerRegistryRegistryAccessKeyEnabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureContainerRegistryRegistryAccessKeyEnabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureContainerRegistryRegistryAccessKeyEnabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AzureContainerRegistryRegistryAccessKeyEnabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public listCredentialsPermissionActionIdentityIds: string[],
        public repositoryIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureContainerRegistryRegistryAccessKeyEnabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureContainerRegistryRegistryAccessKeyEnabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureContainerRegistryRegistryAccessKeyEnabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureContainerRegistryRegistryAccessKeyEnabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureContainerRegistryRegistryInboundExternalWideRangeCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureContainerRegistryRegistryInboundExternalWideRangeCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AzureContainerRegistryRegistryInboundExternalWideRangeCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureContainerRegistryRegistryInboundExternalWideRangeCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureContainerRegistryRegistryInboundExternalWideRangeCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AzureContainerRegistryRegistryInboundExternalWideRangeRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public listCredentialsPermissionActionIdentityIds: string[],
        public repositoryIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureContainerRegistryRegistryInboundExternalWideRangeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureContainerRegistryRegistryInboundExternalWideRangeRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureContainerRegistryRegistryInboundExternalWideRangeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureContainerRegistryRegistryInboundExternalWideRangeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureContainerRegistryRegistryStateNetwork extends AzureNetworkAccessResourceStateNetwork implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        entityReference: EntityReference,
        inboundAccessType: NetworkInboundAccessType,
        inboundExternal: boolean,
        inboundExternalAccessScope: NetworkAccessScope,
        inboundExternalDestinationNetworkScopes: Optional<DestinationNetworkScope[]>,
        inboundExternalWideRange: Optional<boolean>,
        public firewallRuleDatas: AzureContainerRegistryRegistryStateNetworkFirewallRuleData[],
        public publicNetworkAccess: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureContainerRegistryRegistryStateNetwork",
            tenantId,
            entityReference,
            inboundAccessType,
            inboundExternal,
            inboundExternalAccessScope,
            inboundExternalDestinationNetworkScopes,
            inboundExternalWideRange);
    }
}

export class AzureContainerResource extends AzureScopeResource implements ITypeNameObject, IAzureEntity, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId);
    }
}

export class AzureContainerResourceTypeMetadata extends AzureScopeResourceTypeMetadata {
    constructor() {
        super();
    }
}

export class AzureContainerServiceManagedClusterIdentityAuthenticationDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureContainerServiceManagedClusterIdentityAuthenticationDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureContainerServiceManagedClusterIdentityAuthenticationDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureContainerServiceManagedClusterIdentityAuthenticationDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public manageAadDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureContainerServiceManagedClusterIdentityAuthenticationDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureContainerServiceManagedClusterInboundExternalWideRangeRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureContainerServiceManagedClusterInboundExternalWideRangeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureContainerServiceManagedClusterInboundExternalWideRangeRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureContainerServiceManagedClusterInboundExternalWideRangeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureContainerServiceManagedClusterInboundExternalWideRangeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureContainerServiceManagedClusterKubernetesSecretEncryptionDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureContainerServiceManagedClusterKubernetesSecretEncryptionDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AzureContainerServiceManagedClusterKubernetesSecretEncryptionDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureContainerServiceManagedClusterKubernetesSecretEncryptionDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureContainerServiceManagedClusterKubernetesSecretEncryptionDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AzureContainerServiceManagedClusterKubernetesSecretEncryptionDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureContainerServiceManagedClusterKubernetesSecretEncryptionDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureContainerServiceManagedClusterKubernetesSecretEncryptionDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureContainerServiceManagedClusterKubernetesSecretEncryptionDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public aksKmsEtcdEncryptionDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureContainerServiceManagedClusterKubernetesSecretEncryptionDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureContainerServiceManagedClusterModelFilters extends AzureResourceModelFilters implements IManagedKubernetesClusterModelFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        openRelatedEntityRiskTypeIdentifierItems: FilterItems<string>,
        regionIdItems: FilterItems<string>,
        tenantIdItems: FilterItems<string>,
        typeNameItems: FilterItems<string>,
        creationTimeRange: FilterTimeRange,
        public kubernetesCluster: IKubernetesClusterModelFiltersSection) {
        super(
            attributeValueItems,
            openRelatedEntityRiskTypeIdentifierItems,
            regionIdItems,
            tenantIdItems,
            typeNameItems,
            creationTimeRange);
    }
}

export class AzureContainerServiceManagedClusterVirtualMachineInboundExternalRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public nodePublicIpAddressExistsAgentPoolIds: string[],
        public virtualMachineDatas: AzureContainerServiceManagedClusterVirtualMachineInboundExternalRiskVirtualMachineData[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureContainerServiceManagedClusterVirtualMachineInboundExternalRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureContainerServiceManagedClusterVirtualMachineInboundExternalRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureContainerServiceManagedClusterVirtualMachineInboundExternalRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureContainerServiceManagedClusterVirtualMachineInboundExternalRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureCustomRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        risk: Risk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureDatabaseResourceStateNetwork extends AzureNetworkAccessResourceStateNetwork implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        entityReference: EntityReference,
        inboundAccessType: NetworkInboundAccessType,
        inboundExternal: boolean,
        inboundExternalAccessScope: NetworkAccessScope,
        inboundExternalDestinationNetworkScopes: Optional<DestinationNetworkScope[]>,
        inboundExternalWideRange: Optional<boolean>,
        public allowAnyAzureInternalIpAddress: boolean,
        public firewallRuleDatas: AzureStorageResourceStateNetworkFirewallRuleData[],
        public portRange: IntRange,
        public wideRangeFirewallIpAddressRangeRules: AzureResourceFirewallIpAddressRangeRule[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDatabaseResourceStateNetwork",
            tenantId,
            entityReference,
            inboundAccessType,
            inboundExternal,
            inboundExternalAccessScope,
            inboundExternalDestinationNetworkScopes,
            inboundExternalWideRange);
    }
}

export class AzureDevOpsOrganizationConfiguration extends CodeOrganizationConfiguration implements ITypeNameObject, ITenantOrganizationConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        name: string,
        folderEnabled: boolean,
        memberSelection: Optional<OrganizationMemberSelection>,
        serverId: Optional<string>,
        syncEnabled: boolean,
        type: CodeTenantType,
        public aadTenantRawId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDevOpsOrganizationConfiguration",
            scopeId,
            name,
            folderEnabled,
            memberSelection,
            serverId,
            syncEnabled,
            type);
    }
}

export class AzureDocumentDbDatabaseAccountManagementKeyAccessEnabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDocumentDbDatabaseAccountManagementKeyAccessEnabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AzureDocumentDbDatabaseAccountManagementKeyAccessEnabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureDocumentDbDatabaseAccountManagementKeyAccessEnabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDocumentDbDatabaseAccountManagementKeyAccessEnabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AzureDocumentDbDatabaseAccountManagementKeyAccessEnabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDocumentDbDatabaseAccountManagementKeyAccessEnabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureDocumentDbDatabaseAccountManagementKeyAccessEnabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureDocumentDbDatabaseAccountManagementKeyAccessEnabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public rbacDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDocumentDbDatabaseAccountManagementKeyAccessEnabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureDocumentDbDatabaseAccountPrivateEndpointNotExistsCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDocumentDbDatabaseAccountPrivateEndpointNotExistsCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AzureDocumentDbDatabaseAccountPrivateEndpointNotExistsCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureDocumentDbDatabaseAccountPrivateEndpointNotExistsCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDocumentDbDatabaseAccountPrivateEndpointNotExistsCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AzureDocumentDbDatabaseAccountPrivateEndpointNotExistsRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDocumentDbDatabaseAccountPrivateEndpointNotExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureDocumentDbDatabaseAccountPrivateEndpointNotExistsRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureDocumentDbDatabaseAccountPrivateEndpointNotExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDocumentDbDatabaseAccountPrivateEndpointNotExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureDocumentDbDatabaseAccountStateNetwork extends AzureNetworkAccessResourceStateNetwork implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        entityReference: EntityReference,
        inboundAccessType: NetworkInboundAccessType,
        inboundExternal: boolean,
        inboundExternalAccessScope: NetworkAccessScope,
        inboundExternalDestinationNetworkScopes: Optional<DestinationNetworkScope[]>,
        inboundExternalWideRange: Optional<boolean>,
        public allNetworkAccess: boolean,
        public allowAnyAzureInternalIpAddress: boolean,
        public firewallRuleDatas: AzureDocumentDbDatabaseAccountStateNetworkFirewallRuleData[],
        public portRange: IntRange,
        public wideRangeFirewallRuleSubnets: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDocumentDbDatabaseAccountStateNetwork",
            tenantId,
            entityReference,
            inboundAccessType,
            inboundExternal,
            inboundExternalAccessScope,
            inboundExternalDestinationNetworkScopes,
            inboundExternalWideRange);
    }
}

export class AzureDocumentDbDatabaseAccountWideRangeInboundCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDocumentDbDatabaseAccountWideRangeInboundCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AzureDocumentDbDatabaseAccountWideRangeInboundCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureDocumentDbDatabaseAccountWideRangeInboundCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDocumentDbDatabaseAccountWideRangeInboundCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AzureDocumentDbDatabaseAccountWideRangeInboundRisk extends PublicEntityRisk implements ITypeNameObject, IPublicRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDocumentDbDatabaseAccountWideRangeInboundRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureDocumentDbDatabaseAccountWideRangeInboundRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureDocumentDbDatabaseAccountWideRangeInboundRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDocumentDbDatabaseAccountWideRangeInboundRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureEventAnalysisServiceConfiguration extends ServiceConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        public azureEventSyncer: AzureEventSyncerConfigurationSection) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureEventAnalysisServiceConfiguration");
    }
}

export class AzureEventHubNamespaceInboundExternalWideRangeCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureEventHubNamespaceInboundExternalWideRangeCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AzureEventHubNamespaceInboundExternalWideRangeCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureEventHubNamespaceInboundExternalWideRangeCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureEventHubNamespaceInboundExternalWideRangeCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AzureEventHubNamespaceInboundExternalWideRangeRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureEventHubNamespaceInboundExternalWideRangeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureEventHubNamespaceInboundExternalWideRangeRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureEventHubNamespaceInboundExternalWideRangeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureEventHubNamespaceInboundExternalWideRangeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureEventHubNamespaceStateNetwork extends AzureNetworkAccessResourceStateNetwork implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        entityReference: EntityReference,
        inboundAccessType: NetworkInboundAccessType,
        inboundExternal: boolean,
        inboundExternalAccessScope: NetworkAccessScope,
        inboundExternalDestinationNetworkScopes: Optional<DestinationNetworkScope[]>,
        inboundExternalWideRange: Optional<boolean>,
        public firewallRuleDatas: AzureEventHubNamespaceStateNetworkFirewallRuleData[],
        public publicNetworkAccess: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureEventHubNamespaceStateNetwork",
            tenantId,
            entityReference,
            inboundAccessType,
            inboundExternal,
            inboundExternalAccessScope,
            inboundExternalDestinationNetworkScopes,
            inboundExternalWideRange);
    }
}

export class AzureExcessivePermissionPrincipalRisk extends AzureAccessPrincipalRisk implements ITypeNameObject, IAzureExcessivePermissionPrincipalRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        groupIdentityIds: string[],
        identityEnabled: Optional<boolean>,
        originatorResourceIds: string[],
        removedExcessivePermissionActionsSeverity: Optional<Severity>,
        servicePrincipalApplicationId: Optional<string>,
        signInTime: Optional<string>,
        public activityTime: Optional<string>,
        public excessiveDirectMembershipGroupIds: string[],
        public excessiveDirectMembershipGroupsPermissionActionSeverity: Optional<Severity>,
        public excessivePermissionEvaluationStartDate: string,
        public excessiveScopeRoleAssignmentResourceDatas: AzureExcessivePermissionPrincipalRiskScopeRoleAssignmentResourceData[],
        public remainingPermissionActionSeverity: Optional<Severity>,
        public roleAssignmentResourceResolutionToPermittersMap: Dictionary<AzureAccessPrincipalRiskPermitters>,
        public sensitiveResources: AzureExcessivePermissionPrincipalRiskSensitiveResources,
        public tenantEventStartDate: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            groupIdentityIds,
            identityEnabled,
            originatorResourceIds,
            removedExcessivePermissionActionsSeverity,
            servicePrincipalApplicationId,
            signInTime);
    }
}

export class AzureExcessivePermissionPrincipalRiskModel extends AzureAccessPrincipalRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureExcessivePermissionPrincipalRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public infos: AzureExcessivePermissionPrincipalRiskModelInfo[],
        public roleAssignmentResourceResolutionToPermittersTypeMap: Dictionary<AzureExcessivePermissionPrincipalRiskModelResolutionPermittersType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureExcessivePermissionUserRisk extends AzureExcessivePermissionPrincipalRisk implements ITypeNameObject, IAzureExcessivePermissionPrincipalRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        groupIdentityIds: string[],
        identityEnabled: Optional<boolean>,
        originatorResourceIds: string[],
        removedExcessivePermissionActionsSeverity: Optional<Severity>,
        servicePrincipalApplicationId: Optional<string>,
        signInTime: Optional<string>,
        activityTime: Optional<string>,
        excessiveDirectMembershipGroupIds: string[],
        excessiveDirectMembershipGroupsPermissionActionSeverity: Optional<Severity>,
        excessivePermissionEvaluationStartDate: string,
        excessiveScopeRoleAssignmentResourceDatas: AzureExcessivePermissionPrincipalRiskScopeRoleAssignmentResourceData[],
        remainingPermissionActionSeverity: Optional<Severity>,
        roleAssignmentResourceResolutionToPermittersMap: Dictionary<AzureAccessPrincipalRiskPermitters>,
        sensitiveResources: AzureExcessivePermissionPrincipalRiskSensitiveResources,
        tenantEventStartDate: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureExcessivePermissionUserRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            groupIdentityIds,
            identityEnabled,
            originatorResourceIds,
            removedExcessivePermissionActionsSeverity,
            servicePrincipalApplicationId,
            signInTime,
            activityTime,
            excessiveDirectMembershipGroupIds,
            excessiveDirectMembershipGroupsPermissionActionSeverity,
            excessivePermissionEvaluationStartDate,
            excessiveScopeRoleAssignmentResourceDatas,
            remainingPermissionActionSeverity,
            roleAssignmentResourceResolutionToPermittersMap,
            sensitiveResources,
            tenantEventStartDate);
    }
}

export class AzureExcessivePermissionUserRiskModel extends AzureExcessivePermissionPrincipalRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureExcessivePermissionPrincipalRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        infos: AzureExcessivePermissionPrincipalRiskModelInfo[],
        roleAssignmentResourceResolutionToPermittersTypeMap: Dictionary<AzureExcessivePermissionPrincipalRiskModelResolutionPermittersType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureExcessivePermissionUserRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            infos,
            roleAssignmentResourceResolutionToPermittersTypeMap);
    }
}

export class AzureExtensionResource extends AzureResource implements ITypeNameObject, IAzureEntity, IResource, IAzureExtensionResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        public scopeEntityReference: AzureScopeEntityReference) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags);
    }
}

export class AzureExtensionResourceTypeMetadata extends AzureResourceTypeMetadata {
    constructor() {
        super();
    }
}

export class AzureFolderConfiguration extends CloudProviderTenantFolderConfiguration implements ITypeNameObject, IOrganizationFolderConfiguration, IAzureFolderConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        managed: boolean,
        name: string,
        parentScopeId: string,
        root: boolean,
        scopeSections: ScopeConfigurationSections,
        tenantType: Optional<TenantType>,
        organization: Optional<FolderConfigurationOrganization>,
        organizationExists: boolean,
        public azureFolderSections: AzureFolderConfigurationSections,
        public azureScopeSections: AzureScopeConfigurationSections) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureFolderConfiguration",
            managed,
            name,
            parentScopeId,
            root,
            scopeSections,
            tenantType,
            organization,
            organizationExists);
    }
}

export class AzureGuestUserAdministratorCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureGuestUserAdministratorCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AzureGuestUserAdministratorCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureGuestUserAdministratorCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureGuestUserAdministratorCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AzureGuestUserAdministratorRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public roleAssignmentResourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureGuestUserAdministratorRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureGuestUserAdministratorRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureGuestUserAdministratorRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureGuestUserAdministratorRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureInboundExternalComputeVirtualMachineRiskModel extends CloudRiskModel implements ITypeNameObject, IUdmObjectModel, INetworkInboundExternalResourceRiskModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureInboundExternalComputeVirtualMachineRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public destinationNetworkScopes: DestinationNetworkScope[],
        public manageNetworkSecurityGroupDocumentationUrl: string,
        public sourceSubnets: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureInboundExternalComputeVirtualMachineRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureInboundExternalComputeVirtualMachineScaleSetRiskModel extends CloudRiskModel implements ITypeNameObject, IUdmObjectModel, INetworkInboundExternalResourceRiskModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureInboundExternalComputeVirtualMachineScaleSetRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public destinationNetworkScopes: DestinationNetworkScope[],
        public manageNetworkSecurityGroupDocumentationUrl: string,
        public sourceSubnets: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureInboundExternalComputeVirtualMachineScaleSetRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureInboundExternalResourceRisk extends PublicEntityRisk implements ITypeNameObject, IPublicRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public exposedManagementNetworkScopes: DestinationNetworkScope[],
        public exposedNetworkScopes: DestinationNetworkScope[],
        public interfaceNetworkSecurityGroupIds: string[],
        public sourceSubnets: string[],
        public subnetNetworkSecurityGroupIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureInsightsDiagnosticSettings extends AzureExtensionResource implements ITypeNameObject, IAzureEntity, IResource, IAzureExtensionResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        scopeEntityReference: AzureScopeEntityReference,
        public logCategoryToEnabledMap: Dictionary<boolean>,
        public storageAccountPath: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureInsightsDiagnosticSettings",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            scopeEntityReference);
    }
}

export class AzureInsightsDiagnosticSettingsModel extends AzureResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureInsightsDiagnosticSettingsModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType);
    }
}

export class AzureInsightsDiagnosticSettingsStorageAccountBlobContainerDefaultEncryptionCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureInsightsDiagnosticSettingsStorageAccountBlobContainerDefaultEncryptionCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AzureInsightsDiagnosticSettingsStorageAccountBlobContainerDefaultEncryptionCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureInsightsDiagnosticSettingsStorageAccountBlobContainerDefaultEncryptionCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureInsightsDiagnosticSettingsStorageAccountBlobContainerDefaultEncryptionCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AzureInsightsDiagnosticSettingsStorageAccountBlobContainerDefaultEncryptionRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public blobContainerIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureInsightsDiagnosticSettingsStorageAccountBlobContainerDefaultEncryptionRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureInsightsDiagnosticSettingsStorageAccountBlobContainerDefaultEncryptionRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureInsightsDiagnosticSettingsStorageAccountBlobContainerDefaultEncryptionRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureInsightsDiagnosticSettingsStorageAccountBlobContainerDefaultEncryptionRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureInsightsDiagnosticSettingsStorageAccountBlobContainerPublicAccessEnabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureInsightsDiagnosticSettingsStorageAccountBlobContainerPublicAccessEnabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AzureInsightsDiagnosticSettingsStorageAccountBlobContainerPublicAccessEnabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureInsightsDiagnosticSettingsStorageAccountBlobContainerPublicAccessEnabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureInsightsDiagnosticSettingsStorageAccountBlobContainerPublicAccessEnabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AzureInsightsDiagnosticSettingsStorageAccountBlobContainerPublicAccessEnabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public storageAccountId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureInsightsDiagnosticSettingsStorageAccountBlobContainerPublicAccessEnabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureInsightsDiagnosticSettingsStorageAccountBlobContainerPublicAccessEnabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureInsightsDiagnosticSettingsStorageAccountBlobContainerPublicAccessEnabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureInsightsDiagnosticSettingsStorageAccountBlobContainerPublicAccessEnabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureInsightsDiagnosticSettingsTenantLogCategoryAdministrativeNotExistsRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureInsightsDiagnosticSettingsTenantLogCategoryAdministrativeNotExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureInsightsDiagnosticSettingsTenantLogCategoryAdministrativeNotExistsRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureInsightsDiagnosticSettingsTenantLogCategoryAdministrativeNotExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureInsightsDiagnosticSettingsTenantLogCategoryAdministrativeNotExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureKeyVaultVaultCertificateNotRotatedRisk extends PublicEntityRisk implements ITypeNameObject, IPublicRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public applicationIdToSeverityToTenantIdsMap: Dictionary<Dictionary<string[]>>,
        public certificateRotationTimeFrame: string,
        public notRotatedVersionNames: string[],
        public vaultId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKeyVaultVaultCertificateNotRotatedRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureKeyVaultVaultCertificateNotRotatedRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureKeyVaultVaultCertificateNotRotatedRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKeyVaultVaultCertificateNotRotatedRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureKeyVaultVaultEventDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKeyVaultVaultEventDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AzureKeyVaultVaultEventDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureKeyVaultVaultEventDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKeyVaultVaultEventDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AzureKeyVaultVaultEventDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKeyVaultVaultEventDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureKeyVaultVaultEventDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureKeyVaultVaultEventDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKeyVaultVaultEventDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureKeyVaultVaultKeyExpirationDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKeyVaultVaultKeyExpirationDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AzureKeyVaultVaultKeyExpirationDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureKeyVaultVaultKeyExpirationDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKeyVaultVaultKeyExpirationDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AzureKeyVaultVaultKeyExpirationDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public vaultId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKeyVaultVaultKeyExpirationDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureKeyVaultVaultKeyExpirationDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureKeyVaultVaultKeyExpirationDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKeyVaultVaultKeyExpirationDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureKeyVaultVaultKeyNotRotatedCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKeyVaultVaultKeyNotRotatedCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AzureKeyVaultVaultKeyNotRotatedCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureKeyVaultVaultKeyNotRotatedCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKeyVaultVaultKeyNotRotatedCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AzureKeyVaultVaultKeyNotRotatedRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public keyRotationTimeFrame: string,
        public notRotatedVersionNames: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKeyVaultVaultKeyNotRotatedRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureKeyVaultVaultKeyNotRotatedRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureKeyVaultVaultKeyNotRotatedRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKeyVaultVaultKeyNotRotatedRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureKeyVaultVaultKeyRotationDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKeyVaultVaultKeyRotationDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AzureKeyVaultVaultKeyRotationDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureKeyVaultVaultKeyRotationDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKeyVaultVaultKeyRotationDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AzureKeyVaultVaultKeyRotationDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public keyRotationTimeFrame: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKeyVaultVaultKeyRotationDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureKeyVaultVaultKeyRotationDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureKeyVaultVaultKeyRotationDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKeyVaultVaultKeyRotationDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureKeyVaultVaultObjectModelFilters extends AzureResourceModelFilters implements IEntityModelFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        openRelatedEntityRiskTypeIdentifierItems: FilterItems<string>,
        regionIdItems: FilterItems<string>,
        tenantIdItems: FilterItems<string>,
        typeNameItems: FilterItems<string>,
        creationTimeRange: FilterTimeRange,
        public expirationTimeRange: FilterTimeRange) {
        super(
            attributeValueItems,
            openRelatedEntityRiskTypeIdentifierItems,
            regionIdItems,
            tenantIdItems,
            typeNameItems,
            creationTimeRange);
    }
}

export class AzureKeyVaultVaultPrivateEndpointNotExistsCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKeyVaultVaultPrivateEndpointNotExistsCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AzureKeyVaultVaultPrivateEndpointNotExistsCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureKeyVaultVaultPrivateEndpointNotExistsCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKeyVaultVaultPrivateEndpointNotExistsCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AzureKeyVaultVaultPrivateEndpointNotExistsRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKeyVaultVaultPrivateEndpointNotExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureKeyVaultVaultPrivateEndpointNotExistsRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureKeyVaultVaultPrivateEndpointNotExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKeyVaultVaultPrivateEndpointNotExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureKeyVaultVaultRbacDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKeyVaultVaultRbacDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AzureKeyVaultVaultRbacDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureKeyVaultVaultRbacDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKeyVaultVaultRbacDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AzureKeyVaultVaultRbacDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKeyVaultVaultRbacDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureKeyVaultVaultRbacDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureKeyVaultVaultRbacDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public rbacDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKeyVaultVaultRbacDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureKeyVaultVaultSecretExpirationDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKeyVaultVaultSecretExpirationDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AzureKeyVaultVaultSecretExpirationDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureKeyVaultVaultSecretExpirationDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKeyVaultVaultSecretExpirationDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AzureKeyVaultVaultSecretExpirationDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public expirationDisabledSecretIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKeyVaultVaultSecretExpirationDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureKeyVaultVaultSecretExpirationDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureKeyVaultVaultSecretExpirationDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKeyVaultVaultSecretExpirationDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureKeyVaultVaultSoftDeleteDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKeyVaultVaultSoftDeleteDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AzureKeyVaultVaultSoftDeleteDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureKeyVaultVaultSoftDeleteDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKeyVaultVaultSoftDeleteDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AzureKeyVaultVaultSoftDeleteDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKeyVaultVaultSoftDeleteDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureKeyVaultVaultSoftDeleteDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureKeyVaultVaultSoftDeleteDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKeyVaultVaultSoftDeleteDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureKeyVaultVaultWideRangeInboundCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKeyVaultVaultWideRangeInboundCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AzureKeyVaultVaultWideRangeInboundCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureKeyVaultVaultWideRangeInboundCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKeyVaultVaultWideRangeInboundCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AzureKeyVaultVaultWideRangeInboundRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKeyVaultVaultWideRangeInboundRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureKeyVaultVaultWideRangeInboundRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureKeyVaultVaultWideRangeInboundRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKeyVaultVaultWideRangeInboundRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureKubernetesResourceBase extends AzureResource implements ITypeNameObject, IAzureEntity, IKubernetesResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        public clusterDisplayReference: string,
        public clusterId: string,
        public data: KubernetesResourceData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags);
    }
}

export class AzureKubernetesResourceModel extends AzureResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType);
    }
}

export class AzureKubernetesResourceTypeMetadata extends AzureResourceTypeMetadata {
    constructor() {
        super();
    }
}

export class AzureKubernetesUserModel extends AzureKubernetesResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesResourceModel, IKubernetesPrincipalModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        public roleBindingIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKubernetesUserModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType);
    }
}

export class AzureLogicWorkflowInboundExternalWideRangeCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureLogicWorkflowInboundExternalWideRangeCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AzureLogicWorkflowInboundExternalWideRangeCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureLogicWorkflowInboundExternalWideRangeCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureLogicWorkflowInboundExternalWideRangeCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AzureLogicWorkflowInboundExternalWideRangeRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureLogicWorkflowInboundExternalWideRangeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureLogicWorkflowInboundExternalWideRangeRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureLogicWorkflowInboundExternalWideRangeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public restrictInboundIpAddressesDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureLogicWorkflowInboundExternalWideRangeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureLogicWorkflowSecretExistsCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureLogicWorkflowSecretExistsCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AzureLogicWorkflowSecretExistsCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureLogicWorkflowSecretExistsCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureLogicWorkflowSecretExistsCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AzureLogicWorkflowSecretExistsRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public definition: TextBlock,
        public excludedSecrets: string[],
        public publicTriggerNetworkAccess: boolean,
        public readPermissionActionGuestUserIds: string[],
        public readPermissionActionIdentityIds: string[],
        public readPermissionActionVendorApplicationServicePrincipalIds: string[],
        public secretActionNames: string[],
        public secretParameterNameToActionNamesMap: Dictionary<string[]>,
        public secureOutputDisabledKeyVaultActionNames: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureLogicWorkflowSecretExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureLogicWorkflowSecretExistsRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel, ISecretExistsRiskModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureLogicWorkflowSecretExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public actionNames: string[],
        public keyVaultUrl: string,
        public secretCount: number) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureLogicWorkflowSecretExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureLogicWorkflowStateNetwork extends AzureNetworkAccessResourceStateNetwork implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        entityReference: EntityReference,
        inboundAccessType: NetworkInboundAccessType,
        inboundExternal: boolean,
        inboundExternalAccessScope: NetworkAccessScope,
        inboundExternalDestinationNetworkScopes: Optional<DestinationNetworkScope[]>,
        inboundExternalWideRange: Optional<boolean>,
        public publicNetworkAccess: boolean,
        public triggerRuleDatas: AzureLogicWorkflowStateNetworkTriggerRuleData[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureLogicWorkflowStateNetwork",
            tenantId,
            entityReference,
            inboundAccessType,
            inboundExternal,
            inboundExternalAccessScope,
            inboundExternalDestinationNetworkScopes,
            inboundExternalWideRange);
    }
}

export class AzureMachineLearningWorkspaceDiagnosticSettingsLogCategoryNotEnabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMachineLearningWorkspaceDiagnosticSettingsLogCategoryNotEnabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureMachineLearningWorkspaceDiagnosticSettingsLogCategoryNotEnabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureMachineLearningWorkspaceDiagnosticSettingsLogCategoryNotEnabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMachineLearningWorkspaceDiagnosticSettingsLogCategoryNotEnabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureMachineLearningWorkspaceInboundExternalWideRangeRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMachineLearningWorkspaceInboundExternalWideRangeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureMachineLearningWorkspaceInboundExternalWideRangeRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureMachineLearningWorkspaceInboundExternalWideRangeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMachineLearningWorkspaceInboundExternalWideRangeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureMachineLearningWorkspaceStateNetwork extends AzureNetworkAccessResourceStateNetwork implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        entityReference: EntityReference,
        inboundAccessType: NetworkInboundAccessType,
        inboundExternal: boolean,
        inboundExternalAccessScope: NetworkAccessScope,
        inboundExternalDestinationNetworkScopes: Optional<DestinationNetworkScope[]>,
        inboundExternalWideRange: Optional<boolean>,
        public allNetworkAccess: boolean,
        public publicNetworkAccess: boolean,
        public wideRangeFirewallRuleSubnets: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMachineLearningWorkspaceStateNetwork",
            tenantId,
            entityReference,
            inboundAccessType,
            inboundExternal,
            inboundExternalAccessScope,
            inboundExternalDestinationNetworkScopes,
            inboundExternalWideRange);
    }
}

export class AzureManagedIdentityManagedIdentityInactiveRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public servicePrincipalSignInTime: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureManagedIdentityManagedIdentityInactiveRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureManagedIdentityManagedIdentityInactiveRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureManagedIdentityManagedIdentityInactiveRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public infoType: AzureManagedIdentityManagedIdentityInactiveRiskModelInfoType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureManagedIdentityManagedIdentityInactiveRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureManagedIdentityManagedIdentityModelFilters extends AzureResourceModelFilters implements IEntityModelFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        openRelatedEntityRiskTypeIdentifierItems: FilterItems<string>,
        regionIdItems: FilterItems<string>,
        tenantIdItems: FilterItems<string>,
        typeNameItems: FilterItems<string>,
        creationTimeRange: FilterTimeRange,
        public servicePrincipalActivityTimeRange: FilterTimeRange,
        public servicePrincipalSignInTimeRange: FilterTimeRange) {
        super(
            attributeValueItems,
            openRelatedEntityRiskTypeIdentifierItems,
            regionIdItems,
            tenantIdItems,
            typeNameItems,
            creationTimeRange);
    }
}

export class AzureManagedIdentityNotAllowedResourcePermissionRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public resourcePermissionItems: AzureManagedIdentityNotAllowedResourcePermissionRiskItem[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureManagedIdentityNotAllowedResourcePermissionRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureManagedIdentityNotAllowedResourcePermissionRiskModel extends AzureCustomRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureManagedIdentityNotAllowedResourcePermissionRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public permissionActions: string[],
        public scopeResourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureManagedIdentityNotAllowedResourcePermissionRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureManagementManagementGroup extends AzureContainerResource implements ITypeNameObject, IAzureEntity, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        public rawShortId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureManagementManagementGroup",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId);
    }
}

export class AzureManagementManagementGroupModel extends AzureScopeResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IRelatedTenantsEntityModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureManagementManagementGroupModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureMySqlFlexibleServerAadOnlyAuthenticationDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMySqlFlexibleServerAadOnlyAuthenticationDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AzureMySqlFlexibleServerAadOnlyAuthenticationDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureMySqlFlexibleServerAadOnlyAuthenticationDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMySqlFlexibleServerAadOnlyAuthenticationDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AzureMySqlFlexibleServerAadOnlyAuthenticationDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMySqlFlexibleServerAadOnlyAuthenticationDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureMySqlFlexibleServerAadOnlyAuthenticationDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureMySqlFlexibleServerAadOnlyAuthenticationDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public aadAuthenticationDocumentationUrl: string,
        public applicationPermissionDocumentationUrl: string,
        public groupPermissionDocumentationUrl: string,
        public userPermissionDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMySqlFlexibleServerAadOnlyAuthenticationDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureMySqlFlexibleServerAuditLogConnectionEventDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMySqlFlexibleServerAuditLogConnectionEventDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AzureMySqlFlexibleServerAuditLogConnectionEventDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureMySqlFlexibleServerAuditLogConnectionEventDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMySqlFlexibleServerAuditLogConnectionEventDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AzureMySqlFlexibleServerAuditLogConnectionEventDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMySqlFlexibleServerAuditLogConnectionEventDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureMySqlFlexibleServerAuditLogConnectionEventDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureMySqlFlexibleServerAuditLogConnectionEventDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMySqlFlexibleServerAuditLogConnectionEventDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureMySqlFlexibleServerAuditLogDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMySqlFlexibleServerAuditLogDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AzureMySqlFlexibleServerAuditLogDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureMySqlFlexibleServerAuditLogDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMySqlFlexibleServerAuditLogDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AzureMySqlFlexibleServerAuditLogDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMySqlFlexibleServerAuditLogDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureMySqlFlexibleServerAuditLogDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureMySqlFlexibleServerAuditLogDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMySqlFlexibleServerAuditLogDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureMySqlFlexibleServerInsecureTlsProtocolCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMySqlFlexibleServerInsecureTlsProtocolCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AzureMySqlFlexibleServerInsecureTlsProtocolCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureMySqlFlexibleServerInsecureTlsProtocolCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMySqlFlexibleServerInsecureTlsProtocolCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AzureMySqlFlexibleServerInsecureTlsProtocolRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMySqlFlexibleServerInsecureTlsProtocolRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureMySqlFlexibleServerInsecureTlsProtocolRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureMySqlFlexibleServerInsecureTlsProtocolRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMySqlFlexibleServerInsecureTlsProtocolRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureMySqlServerHttpsOnlyDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMySqlServerHttpsOnlyDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AzureMySqlServerHttpsOnlyDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureMySqlServerHttpsOnlyDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMySqlServerHttpsOnlyDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AzureMySqlServerHttpsOnlyDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMySqlServerHttpsOnlyDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureMySqlServerHttpsOnlyDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureMySqlServerHttpsOnlyDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMySqlServerHttpsOnlyDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureMySqlServerWideRangeInboundCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMySqlServerWideRangeInboundCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AzureMySqlServerWideRangeInboundCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureMySqlServerWideRangeInboundCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMySqlServerWideRangeInboundCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AzureMySqlServerWideRangeInboundRisk extends PublicEntityRisk implements ITypeNameObject, IPublicRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMySqlServerWideRangeInboundRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureMySqlServerWideRangeInboundRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureMySqlServerWideRangeInboundRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMySqlServerWideRangeInboundRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureNetworkAccessManagementRisk extends AzureBehaviorIdentityRisk implements ITypeNameObject, IActivityRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        endTime: string,
        eventActions: string[],
        identityId: string,
        ipAddresses: string[],
        previousBehaviorDate: Optional<string>,
        startTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkAccessManagementRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            endTime,
            eventActions,
            identityId,
            ipAddresses,
            previousBehaviorDate,
            startTime);
    }
}

export class AzureNetworkAccessManagementRiskModel extends AzureBehaviorIdentityRiskModel implements ITypeNameObject, IUdmObjectModel, IBehaviorIdentityRiskModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureNetworkAccessManagementRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        infos: AzureBehaviorIdentityRiskModelInfo[],
        resourceIdReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkAccessManagementRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            infos,
            resourceIdReferences);
    }
}

export class AzureNetworkLoadBalancerModelFilters extends AzureResourceModelFilters implements IEntityModelFilters, IEntityNetworkFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        openRelatedEntityRiskTypeIdentifierItems: FilterItems<string>,
        regionIdItems: FilterItems<string>,
        tenantIdItems: FilterItems<string>,
        typeNameItems: FilterItems<string>,
        creationTimeRange: FilterTimeRange,
        public networkAccess: EntityNetworkAccessFiltersSection) {
        super(
            attributeValueItems,
            openRelatedEntityRiskTypeIdentifierItems,
            regionIdItems,
            tenantIdItems,
            typeNameItems,
            creationTimeRange);
    }
}

export class AzureNetworkNetworkSecurityGroupInboundRuleInsecureServiceTagCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkNetworkSecurityGroupInboundRuleInsecureServiceTagCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AzureNetworkNetworkSecurityGroupInboundRuleInsecureServiceTagCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureNetworkNetworkSecurityGroupInboundRuleInsecureServiceTagCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkNetworkSecurityGroupInboundRuleInsecureServiceTagCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AzureNetworkNetworkSecurityGroupInboundRuleInsecureServiceTagRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureNetworkNetworkSecurityGroupInboundRuleInsecureServiceTagRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkNetworkSecurityGroupInboundRuleInsecureServiceTagRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnyExistsRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnyExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnyRdpExistsCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnyRdpExistsCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnyRdpExistsCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnyRdpExistsCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnyRdpExistsCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnyRdpExistsRiskModel extends AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnyExistsRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnyExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnyRdpExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnySshExistsCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnySshExistsCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnySshExistsCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnySshExistsCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnySshExistsCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnySshExistsRiskModel extends AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnyExistsRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnyExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnySshExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnyUdpExistsCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnyUdpExistsCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnyUdpExistsCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnyUdpExistsCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnyUdpExistsCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnyUdpExistsRiskModel extends AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnyExistsRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnyExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnyUdpExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureNetworkNetworkSecurityGroupInsecureInboundRuleExistsRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public insecureNetworkSecurityGroupRulePaths: string[],
        public sensitivePermissionActionSeverityNetworkedResourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureNetworkVirtualNetworkSubnetUnrestrictedInboundAccessCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkVirtualNetworkSubnetUnrestrictedInboundAccessCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AzureNetworkVirtualNetworkSubnetUnrestrictedInboundAccessCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureNetworkVirtualNetworkSubnetUnrestrictedInboundAccessCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkVirtualNetworkSubnetUnrestrictedInboundAccessCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AzureNetworkVirtualNetworkSubnetUnrestrictedInboundAccessRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkVirtualNetworkSubnetUnrestrictedInboundAccessRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureNetworkVirtualNetworkSubnetUnrestrictedInboundAccessRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureNetworkVirtualNetworkSubnetUnrestrictedInboundAccessRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkVirtualNetworkSubnetUnrestrictedInboundAccessRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureOpenShiftProjectModel extends AzureKubernetesResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureOpenShiftProjectModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType);
    }
}

export class AzureOpenShiftSecurityContextConstraintModel extends AzureKubernetesResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureOpenShiftSecurityContextConstraintModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType);
    }
}

export class AzureOrganizationConfiguration extends CloudProviderTenantOrganizationConfiguration implements ITypeNameObject, ITenantOrganizationConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        enabled: boolean,
        folderEnabled: boolean,
        memberSelection: Optional<OrganizationMemberSelection>,
        name: string,
        permissionTypes: CloudProviderTenantPermissionType[],
        public aadTenantId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureOrganizationConfiguration",
            scopeId,
            enabled,
            folderEnabled,
            memberSelection,
            name,
            permissionTypes);
    }
}

export class AzurePermissionManagementRisk extends AzureBehaviorIdentityRisk implements ITypeNameObject, IActivityRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        endTime: string,
        eventActions: string[],
        identityId: string,
        ipAddresses: string[],
        previousBehaviorDate: Optional<string>,
        startTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePermissionManagementRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            endTime,
            eventActions,
            identityId,
            ipAddresses,
            previousBehaviorDate,
            startTime);
    }
}

export class AzurePermissionManagementRiskModel extends AzureBehaviorIdentityRiskModel implements ITypeNameObject, IUdmObjectModel, IBehaviorIdentityRiskModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzurePermissionManagementRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        infos: AzureBehaviorIdentityRiskModelInfo[],
        resourceIdReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePermissionManagementRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            infos,
            resourceIdReferences);
    }
}

export class AzurePostgreSqlFlexibleServerAadOnlyAuthenticationDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePostgreSqlFlexibleServerAadOnlyAuthenticationDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AzurePostgreSqlFlexibleServerAadOnlyAuthenticationDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzurePostgreSqlFlexibleServerAadOnlyAuthenticationDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePostgreSqlFlexibleServerAadOnlyAuthenticationDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AzurePostgreSqlFlexibleServerAadOnlyAuthenticationDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePostgreSqlFlexibleServerAadOnlyAuthenticationDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzurePostgreSqlFlexibleServerAadOnlyAuthenticationDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzurePostgreSqlFlexibleServerAadOnlyAuthenticationDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public aadAuthenticationDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePostgreSqlFlexibleServerAadOnlyAuthenticationDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzurePostgreSqlServerConnectionThrottlingDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePostgreSqlServerConnectionThrottlingDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AzurePostgreSqlServerConnectionThrottlingDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzurePostgreSqlServerConnectionThrottlingDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePostgreSqlServerConnectionThrottlingDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AzurePostgreSqlServerConnectionThrottlingDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePostgreSqlServerConnectionThrottlingDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzurePostgreSqlServerConnectionThrottlingDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzurePostgreSqlServerConnectionThrottlingDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public connectionThrottlingParameterName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePostgreSqlServerConnectionThrottlingDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzurePostgreSqlServerHttpsOnlyDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePostgreSqlServerHttpsOnlyDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AzurePostgreSqlServerHttpsOnlyDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzurePostgreSqlServerHttpsOnlyDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePostgreSqlServerHttpsOnlyDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AzurePostgreSqlServerHttpsOnlyDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePostgreSqlServerHttpsOnlyDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzurePostgreSqlServerHttpsOnlyDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzurePostgreSqlServerHttpsOnlyDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePostgreSqlServerHttpsOnlyDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzurePostgreSqlServerLogCheckpointsDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePostgreSqlServerLogCheckpointsDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AzurePostgreSqlServerLogCheckpointsDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzurePostgreSqlServerLogCheckpointsDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePostgreSqlServerLogCheckpointsDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AzurePostgreSqlServerLogCheckpointsDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePostgreSqlServerLogCheckpointsDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzurePostgreSqlServerLogCheckpointsDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzurePostgreSqlServerLogCheckpointsDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePostgreSqlServerLogCheckpointsDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzurePostgreSqlServerLogConnectionsDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePostgreSqlServerLogConnectionsDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AzurePostgreSqlServerLogConnectionsDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzurePostgreSqlServerLogConnectionsDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePostgreSqlServerLogConnectionsDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AzurePostgreSqlServerLogConnectionsDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePostgreSqlServerLogConnectionsDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzurePostgreSqlServerLogConnectionsDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzurePostgreSqlServerLogConnectionsDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePostgreSqlServerLogConnectionsDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzurePostgreSqlServerLogDisconnectionsDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePostgreSqlServerLogDisconnectionsDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AzurePostgreSqlServerLogDisconnectionsDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzurePostgreSqlServerLogDisconnectionsDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePostgreSqlServerLogDisconnectionsDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AzurePostgreSqlServerLogDisconnectionsDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePostgreSqlServerLogDisconnectionsDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzurePostgreSqlServerLogDisconnectionsDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzurePostgreSqlServerLogDisconnectionsDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePostgreSqlServerLogDisconnectionsDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzurePostgreSqlServerLogRetentionCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePostgreSqlServerLogRetentionCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AzurePostgreSqlServerLogRetentionCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzurePostgreSqlServerLogRetentionCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePostgreSqlServerLogRetentionCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AzurePostgreSqlServerLogRetentionRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePostgreSqlServerLogRetentionRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzurePostgreSqlServerLogRetentionRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzurePostgreSqlServerLogRetentionRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePostgreSqlServerLogRetentionRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzurePostgreSqlServerWideRangeInboundCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePostgreSqlServerWideRangeInboundCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AzurePostgreSqlServerWideRangeInboundCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzurePostgreSqlServerWideRangeInboundCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePostgreSqlServerWideRangeInboundCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AzurePostgreSqlServerWideRangeInboundRisk extends PublicEntityRisk implements ITypeNameObject, IPublicRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePostgreSqlServerWideRangeInboundRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzurePostgreSqlServerWideRangeInboundRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzurePostgreSqlServerWideRangeInboundRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePostgreSqlServerWideRangeInboundRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzurePrincipalNotAllowedResourceRoleDefinitionRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public resourceRoleDefinitionItems: AzurePrincipalNotAllowedResourceRoleDefinitionRiskItem[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePrincipalNotAllowedResourceRoleDefinitionRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzurePrincipalNotAllowedResourceRoleDefinitionRiskModel extends AzureCustomRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzurePrincipalNotAllowedResourceRoleDefinitionRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public roleAssignmentResourceIds: string[],
        public scopeResourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePrincipalNotAllowedResourceRoleDefinitionRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzurePrincipalResourcePermissionRisk extends CloudRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        public principalResourcePermissionItems: AzurePrincipalResourcePermissionRiskItem[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType);
    }
}

export class AzurePrincipalResourcePermissionRiskModel extends AzureCustomRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzurePrincipalResourcePermissionRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public permissionActions: string[],
        public principalIds: string[],
        public resourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureResourceChange extends AzureChange implements ITypeNameObject, ICloudProviderResourceChange {
    constructor(
        typeName: string,
        entityIds: string[],
        id: string,
        resolved: boolean,
        public resourceId: string) {
        super(
            typeName,
            entityIds,
            id,
            resolved);
    }
}

export class AzureResourceCodeResourceNotExistsRisk extends CloudRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureResourceCodeResourceNotExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType);
    }
}

export class AzureResourceCodeResourceNotExistsRiskModel extends AzureCustomRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureResourceCodeResourceNotExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureResourceCodeResourceNotExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureResourceGroupResource extends AzureScopeResource implements ITypeNameObject, IAzureEntity, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        public resourceGroupId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId);
    }
}

export class AzureResourceGroupResourceModel extends AzureScopeResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureResourceGroupResourceTypeMetadata extends AzureScopeResourceTypeMetadata {
    constructor() {
        super();
    }
}

export class AzureResourceInboundExternalPortRisk extends CloudRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureResourceInboundExternalPortRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType);
    }
}

export class AzureResourceInboundExternalPortRiskModel extends AzureCustomRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        risk: Risk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public destinationNetworkScopes: DestinationNetworkScope[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureResourceInboundExternalPortRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureResourcesGenericResource extends AzureResourceGroupResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        public managedIdentityExists: boolean,
        public systemManagedIdentityServicePrincipalId: Optional<string>,
        public userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId);
    }
}

export class AzureResourcesGenericResourceModel extends AzureResourceGroupResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        public userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureResourcesGenericResourceTypeMetadata extends AzureResourceGroupResourceTypeMetadata {
    constructor() {
        super();
    }
}

export class AzureResourcesResourceGroup extends AzureContainerResource implements ITypeNameObject, IResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureResourcesResourceGroup",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId);
    }
}

export class AzureResourcesResourceGroupModel extends AzureScopeResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureResourcesResourceGroupModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureResourceTagNotExistsRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureResourceTagNotExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureResourceTagNotExistsRiskModel extends AzureCustomRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureResourceTagNotExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public existsResourceTagKeys: Optional<string[]>,
        public existsResourceTagPattern: Optional<ResourceTagPattern>,
        public existsResourceTags: Optional<ResourceTag[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureResourceTagNotExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureResourceTagSecretExistsRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public excludedSecretTagKeys: string[],
        public riskedEntityTypeName: string,
        public secretTagKeys: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureResourceTagSecretExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureResourceTagSecretExistsRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel, ISecretExistsRiskModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureResourceTagSecretExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public keyVaultCreateSecretDocumentationUrl: string,
        public secretCount: number) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureResourceTagSecretExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureRoleAssignmentRequest extends PermissionAssignmentRequest implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        closeReason: Optional<PermissionRequestCloseReason>,
        expirationTime: Optional<string>,
        expirationTimeFrame: string,
        granteeUserTenantId: string,
        permissionEligibilityId: string,
        reason: Optional<string>,
        startTime: Optional<string>,
        status: PermissionRequestStatus,
        statusUpdateTime: string,
        tenantIds: string[],
        public tenantIdToAssignedRoleAssignmentPathsMap: Dictionary<string[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureRoleAssignmentRequest",
            scopeId,
            closeReason,
            expirationTime,
            expirationTimeFrame,
            granteeUserTenantId,
            permissionEligibilityId,
            reason,
            startTime,
            status,
            statusUpdateTime,
            tenantIds);
    }
}

export class AzureRoleAssignmentRequestActivationFailureData extends PermissionAssignmentRequestActivationFailureData implements ITypeNameObject {
    constructor(
        errorMessage: Optional<string>,
        retryCount: number,
        tenantIdToErrorMessageMap: Dictionary<string>,
        public tenantIdToFailedRoleDefinitionIdsMap: Dictionary<string[]>) {
        super(
            "AzureRoleAssignmentRequestActivationFailureData",
            errorMessage,
            retryCount,
            tenantIdToErrorMessageMap);
    }
}

export class AzureSaaSApplicationGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSaaSApplicationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureSaaSHubCloudServiceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSaaSHubCloudServiceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureSaaSResourceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSaaSResourceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureScanScanningAccountGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureScanScanningAccountGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureScomManagedInstanceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureScomManagedInstanceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureScvmmAvailabilitySetGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureScvmmAvailabilitySetGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureScvmmCloudGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureScvmmCloudGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureScvmmVirtualmachineGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureScvmmVirtualmachineGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureScvmmVirtualMachinesExtensionGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureScvmmVirtualMachinesExtensionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureScvmmVirtualmachinetemplateGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureScvmmVirtualmachinetemplateGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureScvmmVirtualnetworkGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureScvmmVirtualnetworkGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureScvmmVmmserverGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureScvmmVmmserverGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureSearchSearchServiceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSearchSearchServiceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureSecretManagementSampleProviderForecastGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSecretManagementSampleProviderForecastGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureSecretSyncControllerAzureKeyVaultSecretProviderClassGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSecretSyncControllerAzureKeyVaultSecretProviderClassGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureSecretSyncControllerSecretSyncGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSecretSyncControllerSecretSyncGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureSecurityAssignmentGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSecurityAssignmentGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureSecurityAutomationGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSecurityAutomationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureSecurityCopilotCapacityGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSecurityCopilotCapacityGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureSecurityCustomAssessmentAutomationGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSecurityCustomAssessmentAutomationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureSecurityDataScannerGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSecurityDataScannerGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureSecurityDetonationChamberGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSecurityDetonationChamberGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureSecurityIotSecuritySolutionGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSecurityIotSecuritySolutionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureSecurityPlatformAccountLinkGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSecurityPlatformAccountLinkGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureSecuritySecurityConnectorGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSecuritySecurityConnectorGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureSecurityStandardGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSecurityStandardGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureServiceBusNamespaceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureServiceBusNamespaceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureServiceBusNamespaceGenericResourceModel extends AzureResourcesGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureServiceBusNamespaceGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            userManagedIdentityServicePrincipalIds);
    }
}

export class AzureServiceFabricClusterGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureServiceFabricClusterGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureServiceFabricClusterGenericResourceModel extends AzureResourcesGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureServiceFabricClusterGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            userManagedIdentityServicePrincipalIds);
    }
}

export class AzureServiceFabricManagedclusterGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureServiceFabricManagedclusterGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureServiceFabricManagedclusterGenericResourceModel extends AzureResourcesGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureServiceFabricManagedclusterGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            userManagedIdentityServicePrincipalIds);
    }
}

export class AzureServiceFabricMeshApplicationGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureServiceFabricMeshApplicationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureServiceFabricMeshGatewayGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureServiceFabricMeshGatewayGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureServiceFabricMeshNetworkGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureServiceFabricMeshNetworkGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureServiceFabricMeshSecretGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureServiceFabricMeshSecretGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureServiceFabricMeshVolumeGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureServiceFabricMeshVolumeGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureServiceNetworkingTrafficControllerGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureServiceNetworkingTrafficControllerGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureServiceNetworkingTrafficControllersAssociationGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureServiceNetworkingTrafficControllersAssociationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureServiceNetworkingTrafficControllersFrontendGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureServiceNetworkingTrafficControllersFrontendGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureServiceNetworkingTrafficControllersSecurityPolicyGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureServiceNetworkingTrafficControllersSecurityPolicyGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureServicesHubConnectorGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureServicesHubConnectorGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureServicesHubConnectorGenericResourceModel extends AzureResourcesGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureServicesHubConnectorGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            userManagedIdentityServicePrincipalIds);
    }
}

export class AzureSeverePermissionPrincipalRisk extends AzureAccessPrincipalRisk implements ITypeNameObject, IAccessPrincipalRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        groupIdentityIds: string[],
        identityEnabled: Optional<boolean>,
        originatorResourceIds: string[],
        removedExcessivePermissionActionsSeverity: Optional<Severity>,
        servicePrincipalApplicationId: Optional<string>,
        signInTime: Optional<string>,
        public severePermissionActionPermittingEntityIds: string[],
        public severePermissionActionStartTime: string,
        public type: PrincipalSeverePermissionActionRiskType,
        public userClassicAdministrator: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSeverePermissionPrincipalRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            groupIdentityIds,
            identityEnabled,
            originatorResourceIds,
            removedExcessivePermissionActionsSeverity,
            servicePrincipalApplicationId,
            signInTime);
    }
}

export class AzureSeverePermissionPrincipalRiskModel extends AzureAccessPrincipalRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureSeverePermissionPrincipalRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public infos: AzureSeverePermissionPrincipalRiskModelInfo[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSeverePermissionPrincipalRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureSignalRServiceSignalRGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSignalRServiceSignalRGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureSignalRServiceSignalRGenericResourceModel extends AzureResourcesGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSignalRServiceSignalRGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            userManagedIdentityServicePrincipalIds);
    }
}

export class AzureSignalRServiceSignalRReplicaGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSignalRServiceSignalRReplicaGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureSignalRServiceWebPubSubGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSignalRServiceWebPubSubGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureSignalRServiceWebPubSubReplicaGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSignalRServiceWebPubSubReplicaGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureSingularityAccountGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSingularityAccountGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureSoftwarePlanSoftwareSubscriptionGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSoftwarePlanSoftwareSubscriptionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureSolarWindsObservabilityOrganizationGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSolarWindsObservabilityOrganizationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureSolutionsApplicationDefinitionGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSolutionsApplicationDefinitionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureSolutionsApplicationGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSolutionsApplicationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureSolutionsJitRequestGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSolutionsJitRequestGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureSovereignLandingZoneAccountGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSovereignLandingZoneAccountGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureSphereCatalogGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSphereCatalogGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureSphereCatalogsProductGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSphereCatalogsProductGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureSqlInstancePoolGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSqlInstancePoolGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureSqlManagedInstanceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSqlManagedInstanceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureSqlManagedInstancesDatabaseGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSqlManagedInstancesDatabaseGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureSqlServer extends AzureResourceGroupResource implements ITypeNameObject, IAzureEncryptedResource, IAzureManagedIdentityOriginatorResource, IAzureTagResource, IDataAnalysisResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        public aadAuthenticationAdministratorPrincipalIdReference: Optional<string>,
        public administratorUserName: string,
        public allowAnyAzureInternalIpAddress: boolean,
        public authenticationType: AzureSqlServerAuthenticationType,
        public databaseIds: string[],
        public encryptionExists: boolean,
        public endpoint: string,
        public engineVersion: string,
        public eventEnabled: boolean,
        public eventRetentionTimeFrame: Optional<string>,
        public firewallIpAddressRangeRules: AzureResourceFirewallIpAddressRangeRule[],
        public managedIdentityExists: boolean,
        public publicNetworkAccess: boolean,
        public securityAlertPolicyEnabled: boolean,
        public status: AzureSqlServerStatus,
        public storageSize: Optional<number>,
        public systemManagedIdentityServicePrincipalId: Optional<string>,
        public userManagedIdentityPaths: string[],
        public vaultKeyInfo: Optional<AzureKeyVaultVaultKeyInfo>,
        public virtualNetworkPathToFirewallSubnetRulesMap: Dictionary<AzureResourceFirewallVirtualNetworkSubnetRule[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSqlServer",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId);
    }
}

export class AzureSqlServerAadAuthenticationAdministratorPrincipalNotExistsCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSqlServerAadAuthenticationAdministratorPrincipalNotExistsCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AzureSqlServerAadAuthenticationAdministratorPrincipalNotExistsCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureSqlServerAadAuthenticationAdministratorPrincipalNotExistsCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSqlServerAadAuthenticationAdministratorPrincipalNotExistsCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AzureSqlServerAadAuthenticationAdministratorPrincipalNotExistsRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSqlServerAadAuthenticationAdministratorPrincipalNotExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureSqlServerAadAuthenticationAdministratorPrincipalNotExistsRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureSqlServerAadAuthenticationAdministratorPrincipalNotExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSqlServerAadAuthenticationAdministratorPrincipalNotExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureSqlServerAadOnlyAuthenticationDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSqlServerAadOnlyAuthenticationDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AzureSqlServerAadOnlyAuthenticationDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureSqlServerAadOnlyAuthenticationDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSqlServerAadOnlyAuthenticationDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AzureSqlServerAadOnlyAuthenticationDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSqlServerAadOnlyAuthenticationDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureSqlServerAadOnlyAuthenticationDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureSqlServerAadOnlyAuthenticationDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public aadAuthenticationDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSqlServerAadOnlyAuthenticationDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureSqlServerDatabase extends AzureResourceGroupResource implements ITypeNameObject, IResource, IAzureEncryptedResource, IAzureManagedIdentityOriginatorResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        public encryptionExists: boolean,
        public managedIdentityExists: boolean,
        public serverId: string,
        public status: AzureSqlServerDatabaseStatus,
        public storageSize: Optional<number>,
        public systemManagedIdentityServicePrincipalId: Optional<string>,
        public userManagedIdentityPaths: string[],
        public vaultKeyInfo: Optional<AzureKeyVaultVaultKeyInfo>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSqlServerDatabase",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId);
    }
}

export class AzureSqlServerDatabaseEncryptionDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSqlServerDatabaseEncryptionDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AzureSqlServerDatabaseEncryptionDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureSqlServerDatabaseEncryptionDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSqlServerDatabaseEncryptionDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AzureSqlServerDatabaseEncryptionDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSqlServerDatabaseEncryptionDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureSqlServerDatabaseEncryptionDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureSqlServerDatabaseEncryptionDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSqlServerDatabaseEncryptionDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureSqlServerDatabaseModel extends AzureResourceGroupResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureEncryptedResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        public userManagedIdentityServicePrincipalIds: string[],
        public vaultKeyIdReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSqlServerDatabaseModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureSqlServerDefaultEncryptionCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSqlServerDefaultEncryptionCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AzureSqlServerDefaultEncryptionCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureSqlServerDefaultEncryptionCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSqlServerDefaultEncryptionCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AzureSqlServerDefaultEncryptionRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSqlServerDefaultEncryptionRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureSqlServerDefaultEncryptionRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureSqlServerDefaultEncryptionRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSqlServerDefaultEncryptionRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureSqlServerEventDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSqlServerEventDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AzureSqlServerEventDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureSqlServerEventDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSqlServerEventDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AzureSqlServerEventDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSqlServerEventDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureSqlServerEventDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureSqlServerEventDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSqlServerEventDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureSqlServerEventRetentionTimeFrameCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSqlServerEventRetentionTimeFrameCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AzureSqlServerEventRetentionTimeFrameCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureSqlServerEventRetentionTimeFrameCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSqlServerEventRetentionTimeFrameCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AzureSqlServerEventRetentionTimeFrameRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public eventRetentionTimeFrame: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSqlServerEventRetentionTimeFrameRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureSqlServerEventRetentionTimeFrameRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureSqlServerEventRetentionTimeFrameRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSqlServerEventRetentionTimeFrameRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureSqlServerGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSqlServerGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureSqlServerModel extends AzureResourceGroupResourceModel implements ITypeNameObject, IUdmObjectModel, IAzureEncryptedResourceModel, IAzureManagedIdentityOriginatorResourceModel, IDataAnalysisResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        public dataAnalysisError: Optional<DataAnalysisResourceModelDataAnalysisError>,
        public dataAnalysisPriorityScanRequested: boolean,
        public dataAnalysisResourceSensitivity: Optional<DataAnalysisSensitivity>,
        public dataAnalysisStatus: DataAnalysisResourceModelDataAnalysisStatus,
        public dataAnalysisStatusSortValue: number,
        public dataCategoryToDataSegmentCountMap: Optional<Dictionary<number>>,
        public dataCategoryToSensitivityMap: Optional<Dictionary<DataSensitivity>>,
        public dataClassifierIdToDataSegmentCountMap: Optional<Dictionary<number>>,
        public dataLastModified: Optional<string>,
        public dataSensitivities: Optional<DataSensitivity[]>,
        public dataSensitivityToDataSegmentCountMap: Optional<Dictionary<number>>,
        public highestDataSensitivity: Optional<DataSensitivity>,
        public networkResourcePathToIdReferencesMap: Dictionary<string>,
        public scannedDataSegmentCount: Optional<number>,
        public scanTime: Optional<string>,
        public sensitiveDataSegmentCount: Optional<number>,
        public storageSize: Optional<number>,
        public userManagedIdentityServicePrincipalIds: string[],
        public vaultKeyIdReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSqlServerModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureSqlServersDatabaseGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSqlServersDatabaseGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureSqlServerSecurityAlertPolicyDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSqlServerSecurityAlertPolicyDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AzureSqlServerSecurityAlertPolicyDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureSqlServerSecurityAlertPolicyDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSqlServerSecurityAlertPolicyDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AzureSqlServerSecurityAlertPolicyDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSqlServerSecurityAlertPolicyDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureSqlServerSecurityAlertPolicyDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureSqlServerSecurityAlertPolicyDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSqlServerSecurityAlertPolicyDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureSqlServersElasticPoolGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSqlServersElasticPoolGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureSqlServersJobAccountGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSqlServersJobAccountGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureSqlServersJobAgentGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSqlServersJobAgentGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureSqlServerWideRangeInboundCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSqlServerWideRangeInboundCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AzureSqlServerWideRangeInboundCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureSqlServerWideRangeInboundCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSqlServerWideRangeInboundCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AzureSqlServerWideRangeInboundRisk extends PublicEntityRisk implements ITypeNameObject, IPublicRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSqlServerWideRangeInboundRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureSqlServerWideRangeInboundRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureSqlServerWideRangeInboundRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSqlServerWideRangeInboundRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureSqlVirtualClusterGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSqlVirtualClusterGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureSqlVirtualMachineSqlVirtualMachineGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSqlVirtualMachineSqlVirtualMachineGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureSqlVirtualMachineSqlVirtualMachineGroupGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSqlVirtualMachineSqlVirtualMachineGroupGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureStackHciClusterGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStackHciClusterGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureStackHciEdgeNodePoolGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStackHciEdgeNodePoolGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureStackHciGalleryImageGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStackHciGalleryImageGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureStackHciLogicalNetworkGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStackHciLogicalNetworkGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureStackHciMarketplaceGalleryImageGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStackHciMarketplaceGalleryImageGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureStackHciNetworkInterfaceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStackHciNetworkInterfaceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureStackHciNetworkSecurityGroupGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStackHciNetworkSecurityGroupGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureStackHciStorageContainerGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStackHciStorageContainerGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureStackHciVirtualHardDiskGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStackHciVirtualHardDiskGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureStackHciVirtualMachineGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStackHciVirtualMachineGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureStackHciVirtualMachinesExtensionGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStackHciVirtualMachinesExtensionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureStackHciVirtualNetworkGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStackHciVirtualNetworkGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureStackLinkedSubscriptionGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStackLinkedSubscriptionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureStackRegistrationGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStackRegistrationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureStandbyPoolStandbyContainerGroupPoolGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStandbyPoolStandbyContainerGroupPoolGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureStandbyPoolStandbyVirtualMachinePoolGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStandbyPoolStandbyVirtualMachinePoolGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureStorageActionsStorageTaskGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageActionsStorageTaskGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureStorageCacheAmlFilesystemGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageCacheAmlFilesystemGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureStorageCacheCacheGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageCacheCacheGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureStorageMoverStorageMoverGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageMoverStorageMoverGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureStorageStorageAccount extends AzureResourceGroupResource implements ITypeNameObject, IResource, IAzureEncryptedResource, IAzureManagedIdentityOriginatorResource, IAzurePrivateEndpointResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        public allowAnyAzureTrustedServicesNetworkAccess: boolean,
        public blobContainerEnabled: boolean,
        public blobContainerPublicAccess: boolean,
        public blobContainerSoftDeleteEnabled: Optional<boolean>,
        public blobContainerSoftDeleteRetentionTimeFrame: Optional<string>,
        public blobSoftDeleteEnabled: Optional<boolean>,
        public blobSoftDeleteRetentionTimeFrame: Optional<string>,
        public encryptionExists: boolean,
        public encryptionKeySource: AzureStorageStorageAccountEncryptionKeySource,
        public fileShareEnabled: boolean,
        public firewallRuleSubnets: string[],
        public httpsOnly: boolean,
        public infrastructureEncryption: boolean,
        public managedIdentityExists: boolean,
        public premium: boolean,
        public publicNetworkAccess: boolean,
        public queueEnabled: boolean,
        public secondaryRegionId: Optional<string>,
        public secondaryStatus: Optional<AzureStorageStorageAccountStatus>,
        public sharedKeyAccess: boolean,
        public staticWebsiteEnabled: Optional<boolean>,
        public status: AzureStorageStorageAccountStatus,
        public systemManagedIdentityServicePrincipalId: Optional<string>,
        public tableEnabled: boolean,
        public tlsMinVersion: string,
        public type: AzureStorageStorageAccountType,
        public userManagedIdentityPaths: string[],
        public vaultKeyInfo: Optional<AzureKeyVaultVaultKeyInfo>,
        public virtualNetworkPathToFirewallSubnetRulesMap: Dictionary<AzureResourceFirewallVirtualNetworkSubnetRule[]>,
        public wideRangeFirewallRuleSubnets: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccount",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId);
    }
}

export class AzureStorageStorageAccountAllowAnyAzureTrustedServicesNetworkAccessDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountAllowAnyAzureTrustedServicesNetworkAccessDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AzureStorageStorageAccountAllowAnyAzureTrustedServicesNetworkAccessDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureStorageStorageAccountAllowAnyAzureTrustedServicesNetworkAccessDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountAllowAnyAzureTrustedServicesNetworkAccessDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AzureStorageStorageAccountAllowAnyAzureTrustedServicesNetworkAccessDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountAllowAnyAzureTrustedServicesNetworkAccessDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureStorageStorageAccountAllowAnyAzureTrustedServicesNetworkAccessDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureStorageStorageAccountAllowAnyAzureTrustedServicesNetworkAccessDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountAllowAnyAzureTrustedServicesNetworkAccessDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureStorageStorageAccountBlobContainerPublicAccessExistsCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountBlobContainerPublicAccessExistsCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AzureStorageStorageAccountBlobContainerPublicAccessExistsCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureStorageStorageAccountBlobContainerPublicAccessExistsCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountBlobContainerPublicAccessExistsCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AzureStorageStorageAccountBlobContainerPublicAccessExistsRisk extends PublicEntityRisk implements ITypeNameObject, IPublicRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountBlobContainerPublicAccessExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureStorageStorageAccountBlobContainerPublicAccessExistsRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureStorageStorageAccountBlobContainerPublicAccessExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountBlobContainerPublicAccessExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureStorageStorageAccountBlobServiceDiagnosticSettingsLogCategoryNotEnabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountBlobServiceDiagnosticSettingsLogCategoryNotEnabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AzureStorageStorageAccountBlobServiceDiagnosticSettingsLogCategoryNotEnabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureStorageStorageAccountBlobServiceDiagnosticSettingsLogCategoryNotEnabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountBlobServiceDiagnosticSettingsLogCategoryNotEnabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AzureStorageStorageAccountDefaultEncryptionCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountDefaultEncryptionCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AzureStorageStorageAccountDefaultEncryptionCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureStorageStorageAccountDefaultEncryptionCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountDefaultEncryptionCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AzureStorageStorageAccountDefaultEncryptionRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountDefaultEncryptionRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureStorageStorageAccountDefaultEncryptionRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureStorageStorageAccountDefaultEncryptionRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountDefaultEncryptionRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureStorageStorageAccountGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureStorageStorageAccountHttpsOnlyDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountHttpsOnlyDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AzureStorageStorageAccountHttpsOnlyDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureStorageStorageAccountHttpsOnlyDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountHttpsOnlyDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AzureStorageStorageAccountHttpsOnlyDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountHttpsOnlyDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureStorageStorageAccountHttpsOnlyDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureStorageStorageAccountHttpsOnlyDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountHttpsOnlyDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureStorageStorageAccountInfrastructureEncryptionDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountInfrastructureEncryptionDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AzureStorageStorageAccountInfrastructureEncryptionDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureStorageStorageAccountInfrastructureEncryptionDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountInfrastructureEncryptionDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AzureStorageStorageAccountInfrastructureEncryptionDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountInfrastructureEncryptionDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureStorageStorageAccountInfrastructureEncryptionDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureStorageStorageAccountInfrastructureEncryptionDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public infrastructureEncryptionDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountInfrastructureEncryptionDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureStorageStorageAccountInsecureTlsProtocolCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountInsecureTlsProtocolCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AzureStorageStorageAccountInsecureTlsProtocolCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureStorageStorageAccountInsecureTlsProtocolCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountInsecureTlsProtocolCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AzureStorageStorageAccountInsecureTlsProtocolRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountInsecureTlsProtocolRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureStorageStorageAccountInsecureTlsProtocolRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureStorageStorageAccountInsecureTlsProtocolRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountInsecureTlsProtocolRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureStorageStorageAccountModel extends AzureResourceGroupResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureEncryptedResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        public networkResourcePathToIdReferencesMap: Dictionary<string>,
        public privateEndpointsIdReferences: string[],
        public userManagedIdentityServicePrincipalIds: string[],
        public vaultKeyIdReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureStorageStorageAccountPrivateEndpointNotExistsCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountPrivateEndpointNotExistsCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AzureStorageStorageAccountPrivateEndpointNotExistsCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureStorageStorageAccountPrivateEndpointNotExistsCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountPrivateEndpointNotExistsCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AzureStorageStorageAccountPrivateEndpointNotExistsRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountPrivateEndpointNotExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureStorageStorageAccountPrivateEndpointNotExistsRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureStorageStorageAccountPrivateEndpointNotExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountPrivateEndpointNotExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureStorageStorageAccountQueueServiceDiagnosticSettingsLogCategoryNotEnabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountQueueServiceDiagnosticSettingsLogCategoryNotEnabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AzureStorageStorageAccountQueueServiceDiagnosticSettingsLogCategoryNotEnabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureStorageStorageAccountQueueServiceDiagnosticSettingsLogCategoryNotEnabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountQueueServiceDiagnosticSettingsLogCategoryNotEnabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AzureStorageStorageAccountResource extends AzureResourceGroupResource implements ITypeNameObject, IAzureEntity, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        public storageAccountId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId);
    }
}

export class AzureStorageStorageAccountResourceModel extends AzureResourceGroupResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureStorageStorageAccountServiceDiagnosticSettingsLogCategoryNotEnabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureStorageStorageAccountServiceDiagnosticSettingsLogCategoryNotEnabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureStorageStorageAccountServiceDiagnosticSettingsLogCategoryNotEnabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureStorageStorageAccountSharedKeyAccessEnabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountSharedKeyAccessEnabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AzureStorageStorageAccountSharedKeyAccessEnabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureStorageStorageAccountSharedKeyAccessEnabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountSharedKeyAccessEnabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AzureStorageStorageAccountSharedKeyAccessEnabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public listKeysPermissionActionIdentityIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountSharedKeyAccessEnabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureStorageStorageAccountSharedKeyAccessEnabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureStorageStorageAccountSharedKeyAccessEnabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public sharedKeyAccessDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountSharedKeyAccessEnabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureStorageStorageAccountSoftDeleteDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountSoftDeleteDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AzureStorageStorageAccountSoftDeleteDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureStorageStorageAccountSoftDeleteDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountSoftDeleteDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AzureStorageStorageAccountSoftDeleteDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountSoftDeleteDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureStorageStorageAccountSoftDeleteDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureStorageStorageAccountSoftDeleteDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountSoftDeleteDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureStorageStorageAccountTable extends AzureStorageStorageAccountResource implements ITypeNameObject, IAzureEntity, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        storageAccountId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountTable",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            storageAccountId);
    }
}

export class AzureStorageStorageAccountTableModel extends AzureStorageStorageAccountResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountTableModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureStorageStorageAccountTableServiceDiagnosticSettingsLogCategoryNotEnabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountTableServiceDiagnosticSettingsLogCategoryNotEnabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AzureStorageStorageAccountTableServiceDiagnosticSettingsLogCategoryNotEnabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureStorageStorageAccountTableServiceDiagnosticSettingsLogCategoryNotEnabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountTableServiceDiagnosticSettingsLogCategoryNotEnabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AzureStorageStorageAccountTableServiceDiagnosticSettingsLogCategoryNotEnabledRisk extends AzureStorageStorageAccountServiceDiagnosticSettingsLogCategoryNotEnabledRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountTableServiceDiagnosticSettingsLogCategoryNotEnabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureStorageStorageAccountTableServiceDiagnosticSettingsLogCategoryNotEnabledRiskModel extends AzureStorageStorageAccountServiceDiagnosticSettingsLogCategoryNotEnabledRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureStorageStorageAccountServiceDiagnosticSettingsLogCategoryNotEnabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountTableServiceDiagnosticSettingsLogCategoryNotEnabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureStorageStorageAccountWideRangeInboundCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountWideRangeInboundCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AzureStorageStorageAccountWideRangeInboundCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureStorageStorageAccountWideRangeInboundCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountWideRangeInboundCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AzureStorageStorageAccountWideRangeInboundRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountWideRangeInboundRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureStorageStorageAccountWideRangeInboundRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureStorageStorageAccountWideRangeInboundRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountWideRangeInboundRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureStorageStorageTaskGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageTaskGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureStorageSyncStorageSyncServiceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageSyncStorageSyncServiceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureStorageTasksStorageTaskGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageTasksStorageTaskGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureStreamAnalyticsClusterGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStreamAnalyticsClusterGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureStreamAnalyticsStreamingJobGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStreamAnalyticsStreamingJobGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureSustainabilityServicesCalculationGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSustainabilityServicesCalculationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureSynapsePrivateLinkHubGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSynapsePrivateLinkHubGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureSynapseWorkspaceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSynapseWorkspaceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureSynapseWorkspaceGenericResourceModel extends AzureResourcesGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSynapseWorkspaceGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            userManagedIdentityServicePrincipalIds);
    }
}

export class AzureSynapseWorkspacesBigDataPoolGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSynapseWorkspacesBigDataPoolGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureSynapseWorkspacesKustoPoolGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSynapseWorkspacesKustoPoolGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureSynapseWorkspacesSqlPoolGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSynapseWorkspacesSqlPoolGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureSyntexAccountGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSyntexAccountGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureSyntexDocumentProcessorGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSyntexDocumentProcessorGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureTenantEntity extends AzureContainerResource implements ITypeNameObject, IResource, IAzureTagResource, ITenantEntity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        public rawShortId: string,
        public status: AzureTenantEntityStatus) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureTenantEntity",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId);
    }
}

export class AzureTenantEntityCreationRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureTenantEntityCreationRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureTenantEntityCreationRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureTenantEntityCreationRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureTenantEntityCreationRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureToolchainOrchestratorActivationGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureToolchainOrchestratorActivationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureToolchainOrchestratorCampaignGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureToolchainOrchestratorCampaignGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureToolchainOrchestratorCampaignsVersionGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureToolchainOrchestratorCampaignsVersionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureToolchainOrchestratorCatalogGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureToolchainOrchestratorCatalogGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureToolchainOrchestratorCatalogsVersionGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureToolchainOrchestratorCatalogsVersionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureToolchainOrchestratorDiagnosticGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureToolchainOrchestratorDiagnosticGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureToolchainOrchestratorInstanceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureToolchainOrchestratorInstanceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureToolchainOrchestratorInstancesVersionGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureToolchainOrchestratorInstancesVersionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureToolchainOrchestratorSolutionGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureToolchainOrchestratorSolutionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureToolchainOrchestratorSolutionsVersionGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureToolchainOrchestratorSolutionsVersionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureToolchainOrchestratorTargetGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureToolchainOrchestratorTargetGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureToolchainOrchestratorTargetsVersionGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureToolchainOrchestratorTargetsVersionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureUsageBillingAccountGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureUsageBillingAccountGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureVideoIndexerAccountGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureVideoIndexerAccountGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureVirtualMachineImagesImageTemplateGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureVirtualMachineImagesImageTemplateGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureVisualStudioAccountExtensionGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureVisualStudioAccountExtensionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureVisualStudioAccountGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureVisualStudioAccountGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureVisualStudioAccountProjectGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureVisualStudioAccountProjectGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureVMwareArczoneGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureVMwareArczoneGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureVMwareResourcepoolGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureVMwareResourcepoolGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureVMwareVcenterGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureVMwareVcenterGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureVMwareVirtualmachineGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureVMwareVirtualmachineGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureVMwareVirtualmachineGenericResourceModel extends AzureResourcesGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureVMwareVirtualmachineGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            userManagedIdentityServicePrincipalIds);
    }
}

export class AzureVMwareVirtualmachinetemplateGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureVMwareVirtualmachinetemplateGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureVMwareVirtualnetworkGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureVMwareVirtualnetworkGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureVMwareVirtualnetworkGenericResourceModel extends AzureResourcesGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureVMwareVirtualnetworkGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            userManagedIdentityServicePrincipalIds);
    }
}

export class AzureVoiceServicesCommunicationsGatewayGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureVoiceServicesCommunicationsGatewayGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureVoiceServicesCommunicationsGatewaysContactGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureVoiceServicesCommunicationsGatewaysContactGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureVoiceServicesCommunicationsGatewaysTestLineGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureVoiceServicesCommunicationsGatewaysTestLineGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureVoiceServicesOperatorVoicemailInstanceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureVoiceServicesOperatorVoicemailInstanceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureWandiscoFusionFusionGroupGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWandiscoFusionFusionGroupGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureWandiscoFusionFusionGroupsAzureZoneGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWandiscoFusionFusionGroupsAzureZoneGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureWandiscoFusionFusionGroupsAzureZonesPluginGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWandiscoFusionFusionGroupsAzureZonesPluginGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureWandiscoFusionFusionGroupsManagedOnPremZoneGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWandiscoFusionFusionGroupsManagedOnPremZoneGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureWandiscoFusionFusionGroupsReplicationRuleGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWandiscoFusionFusionGroupsReplicationRuleGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureWandiscoFusionMigratorGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWandiscoFusionMigratorGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureWandiscoFusionMigratorsDataTransferAgentGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWandiscoFusionMigratorsDataTransferAgentGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureWandiscoFusionMigratorsExclusionTemplateGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWandiscoFusionMigratorsExclusionTemplateGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureWandiscoFusionMigratorsLiveDataMigrationGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWandiscoFusionMigratorsLiveDataMigrationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureWandiscoFusionMigratorsMetadataMigrationGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWandiscoFusionMigratorsMetadataMigrationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureWandiscoFusionMigratorsMetadataTargetGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWandiscoFusionMigratorsMetadataTargetGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureWandiscoFusionMigratorsPathMappingGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWandiscoFusionMigratorsPathMappingGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureWandiscoFusionMigratorsTargetGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWandiscoFusionMigratorsTargetGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureWandiscoFusionMigratorsVerificationGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWandiscoFusionMigratorsVerificationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureWebApiManagementAccountGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWebApiManagementAccountGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureWebApimanagementaccountsApiGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWebApimanagementaccountsApiGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureWebApplication extends AzureResourceGroupResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        public authenticationType: Optional<AzureWebApplicationAuthenticationType>,
        public httpsOnly: boolean,
        public managedIdentityExists: boolean,
        public networkRules: Optional<AzureWebApplicationNetworkRule[]>,
        public publicNetworkAccess: boolean,
        public scmNetworkRules: Optional<AzureWebApplicationNetworkRule[]>,
        public scmUrl: Optional<string>,
        public status: Optional<AzureWebApplicationStatus>,
        public systemManagedIdentityServicePrincipalId: Optional<string>,
        public tlsMinVersion: Optional<string>,
        public type: AzureWebApplicationType,
        public url: Optional<string>,
        public userManagedIdentityPaths: string[],
        public wideRangeNetworkRules: Optional<AzureWebApplicationNetworkRule[]>,
        public wideRangeScmNetworkRules: Optional<AzureWebApplicationNetworkRule[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWebApplication",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId);
    }
}

export class AzureWebApplicationHttpsOnlyDisabledCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWebApplicationHttpsOnlyDisabledCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AzureWebApplicationHttpsOnlyDisabledCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureWebApplicationHttpsOnlyDisabledCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWebApplicationHttpsOnlyDisabledCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AzureWebApplicationHttpsOnlyDisabledRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWebApplicationHttpsOnlyDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureWebApplicationHttpsOnlyDisabledRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureWebApplicationHttpsOnlyDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWebApplicationHttpsOnlyDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureWebApplicationInboundExternalWideRangeCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWebApplicationInboundExternalWideRangeCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AzureWebApplicationInboundExternalWideRangeCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureWebApplicationInboundExternalWideRangeCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWebApplicationInboundExternalWideRangeCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AzureWebApplicationInboundExternalWideRangeRisk extends PublicEntityRisk implements ITypeNameObject, IPublicRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWebApplicationInboundExternalWideRangeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureWebApplicationInboundExternalWideRangeRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureWebApplicationInboundExternalWideRangeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWebApplicationInboundExternalWideRangeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureWebApplicationInsecureTlsProtocolCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWebApplicationInsecureTlsProtocolCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AzureWebApplicationInsecureTlsProtocolCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureWebApplicationInsecureTlsProtocolCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWebApplicationInsecureTlsProtocolCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AzureWebApplicationInsecureTlsProtocolRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWebApplicationInsecureTlsProtocolRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureWebApplicationInsecureTlsProtocolRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureWebApplicationInsecureTlsProtocolRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWebApplicationInsecureTlsProtocolRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureWebApplicationModel extends AzureResourceGroupResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        public userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWebApplicationModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureWebApplicationScmInboundExternalWideRangeCodeRisk extends CodeRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        codeType: CodeType,
        fileRelativePath: string,
        firstScanCommit: CodeCommit,
        resourceName: string,
        resourceTypeSystemName: string,
        snippets: CodeRiskSnippet[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWebApplicationScmInboundExternalWideRangeCodeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            codeType,
            fileRelativePath,
            firstScanCommit,
            resourceName,
            resourceTypeSystemName,
            snippets);
    }
}

export class AzureWebApplicationScmInboundExternalWideRangeCodeRiskModel extends CodeRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureWebApplicationScmInboundExternalWideRangeCodeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        cloudTenantType: Optional<TenantType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWebApplicationScmInboundExternalWideRangeCodeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            cloudTenantType);
    }
}

export class AzureWebApplicationScmInboundExternalWideRangeRisk extends EntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWebApplicationScmInboundExternalWideRangeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureWebApplicationScmInboundExternalWideRangeRiskModel extends CloudRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureWebApplicationScmInboundExternalWideRangeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWebApplicationScmInboundExternalWideRangeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureWebCertificateGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWebCertificateGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureWebConnectionGatewayGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWebConnectionGatewayGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureWebConnectionGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWebConnectionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureWebContainerAppGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWebContainerAppGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureWebCustomApiGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWebCustomApiGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureWebHostingEnvironmentGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWebHostingEnvironmentGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureWebKubeEnvironmentGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWebKubeEnvironmentGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureWebServerfarmGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWebServerfarmGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureWebSiteGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWebSiteGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureWebSitesSlotGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWebSitesSlotGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureWebSitesWorkflowGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWebSitesWorkflowGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureWebStaticSiteGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWebStaticSiteGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureWebWorkerAppGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWebWorkerAppGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureWindows365CloudPcDelegatedMsiGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWindows365CloudPcDelegatedMsiGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureWindowsPushNotificationServicesRegistrationGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWindowsPushNotificationServicesRegistrationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureWorkloadBuilderMigrationAgentGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWorkloadBuilderMigrationAgentGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureWorkloadBuilderWorkloadGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWorkloadBuilderWorkloadGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureWorkloadsConnectorGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWorkloadsConnectorGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureWorkloadsConnectorsAcssBackupGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWorkloadsConnectorsAcssBackupGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureWorkloadsConnectorsAmsInsightGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWorkloadsConnectorsAmsInsightGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureWorkloadsConnectorsSapVirtualInstanceMonitorGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWorkloadsConnectorsSapVirtualInstanceMonitorGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureWorkloadsEpicVirtualInstanceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWorkloadsEpicVirtualInstanceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureWorkloadsEpicVirtualInstancesDatabaseInstanceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWorkloadsEpicVirtualInstancesDatabaseInstanceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureWorkloadsEpicVirtualInstancesHyperspaceWebInstanceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWorkloadsEpicVirtualInstancesHyperspaceWebInstanceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureWorkloadsEpicVirtualInstancesPresentationInstanceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWorkloadsEpicVirtualInstancesPresentationInstanceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureWorkloadsEpicVirtualInstancesSharedInstanceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWorkloadsEpicVirtualInstancesSharedInstanceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureWorkloadsEpicVirtualInstancesWssInstanceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWorkloadsEpicVirtualInstancesWssInstanceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureWorkloadsInsightGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWorkloadsInsightGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureWorkloadsMonitorGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWorkloadsMonitorGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureWorkloadsOracleVirtualInstanceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWorkloadsOracleVirtualInstanceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureWorkloadsOracleVirtualInstancesDatabaseInstanceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWorkloadsOracleVirtualInstancesDatabaseInstanceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureWorkloadsPhpWorkloadGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWorkloadsPhpWorkloadGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureWorkloadsSapDiscoverySiteGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWorkloadsSapDiscoverySiteGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureWorkloadsSapDiscoverySitesSapInstanceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWorkloadsSapDiscoverySitesSapInstanceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureWorkloadsSapVirtualInstanceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWorkloadsSapVirtualInstanceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureWorkloadsSapVirtualInstancesApplicationInstanceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWorkloadsSapVirtualInstancesApplicationInstanceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureWorkloadsSapVirtualInstancesCentralInstanceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWorkloadsSapVirtualInstancesCentralInstanceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureWorkloadsSapVirtualInstancesDatabaseInstanceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWorkloadsSapVirtualInstancesDatabaseInstanceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class BehaviorRiskIdentityAttribute extends BuiltInEntityAttribute implements ITypeNameObject {
    constructor() {
        super("BehaviorRiskIdentityAttribute");
    }
}

export class BitbucketOrganizationConfiguration extends CodeOrganizationConfiguration implements ITypeNameObject, ITenantOrganizationConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        name: string,
        folderEnabled: boolean,
        memberSelection: Optional<OrganizationMemberSelection>,
        serverId: Optional<string>,
        syncEnabled: boolean,
        type: CodeTenantType,
        public encryptedAccessToken: EncryptedData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "BitbucketOrganizationConfiguration",
            scopeId,
            name,
            folderEnabled,
            memberSelection,
            serverId,
            syncEnabled,
            type);
    }
}

export class BuiltInComplianceReportDefinitionConfiguration extends ComplianceReportDefinitionConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        contentType: ReportContentType,
        exportReport: boolean,
        licenseType: ApplicationCustomerConfigurationLicensingLicenseType,
        name: Optional<string>,
        timeZoneInfoId: string,
        type: ReportType,
        public sectionType: BuiltInComplianceSectionType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "BuiltInComplianceReportDefinitionConfiguration",
            contentType,
            exportReport,
            licenseType,
            name,
            timeZoneInfoId,
            type);
    }
}

export class BuiltInRiskPolicyConfiguration extends RiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class CiCloudsmithIntegrationConfiguration extends CiIntegrationConfiguration implements ITypeNameObject, IEndpointIntegrationEntityConfiguration, ITenantOrganizationConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        endpoint: Optional<string>,
        endpointConnectorId: Optional<string>,
        folderEnabled: boolean,
        memberSelection: Optional<OrganizationMemberSelection>,
        name: string,
        serverCertificateValidationEnabled: boolean,
        type: CiTenantType,
        userName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "CiCloudsmithIntegrationConfiguration",
            scopeId,
            endpoint,
            endpointConnectorId,
            folderEnabled,
            memberSelection,
            name,
            serverCertificateValidationEnabled,
            type,
            userName);
    }
}

export class CiContainerImage extends CiEntity implements ITypeNameObject, IContainerImage {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        public data: ContainerImageData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "CiContainerImage",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime);
    }
}

export class CiContainerImageModel extends CiEntityModel implements ITypeNameObject, IUdmObjectModel, IContainerImageModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        public kubernetesWorkloadResourceIds: string[],
        public operatingSystemReleaseNotesUrl: Optional<string>,
        public operatingSystemSupported: Optional<boolean>,
        public operatingSystemType: Optional<OperatingSystemType>,
        public repositoryIdReference: string,
        public virtualMachineIds: string[],
        public vulnerabilitySeveritiesSortValue: string,
        public workloadAnalysisOperatingSystem: Optional<WorkloadResourceScanOperatingSystem>,
        public workloadAnalysisOperatingSystemEndOfLifeDate: Optional<string>,
        public workloadAnalysisOperatingSystemExtendedSupport: Optional<boolean>,
        public workloadClusterResourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "CiContainerImageModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown);
    }
}

export class CiContainerImageRepository extends CiEntity implements ITypeNameObject, IContainerImageRepository {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        public containerImageCount: number,
        public containerImageIds: string[],
        public name: string,
        public rawId: string,
        public rawUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "CiContainerImageRepository",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime);
    }
}

export class CiContainerImageRepositoryModel extends CiEntityModel implements ITypeNameObject, IUdmObjectModel, IContainerImageRepositoryModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        public containerImageResourceIds: string[],
        public containerImageScanStatus: Optional<ContainerImageDataScanStatus>,
        public containerImageVulnerabilities: VulnerabilityData[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "CiContainerImageRepositoryModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown);
    }
}

export class CiGeneralIntegrationConfiguration extends CiIntegrationConfiguration implements ITypeNameObject, IEndpointIntegrationEntityConfiguration, ITenantOrganizationConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        endpoint: Optional<string>,
        endpointConnectorId: Optional<string>,
        folderEnabled: boolean,
        memberSelection: Optional<OrganizationMemberSelection>,
        name: string,
        serverCertificateValidationEnabled: boolean,
        type: CiTenantType,
        userName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "CiGeneralIntegrationConfiguration",
            scopeId,
            endpoint,
            endpointConnectorId,
            folderEnabled,
            memberSelection,
            name,
            serverCertificateValidationEnabled,
            type,
            userName);
    }
}

export class CodeAnalysisServiceConfiguration extends ServiceConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "CodeAnalysisServiceConfiguration");
    }
}

export class CodeApiServiceConfiguration extends ServiceConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "CodeApiServiceConfiguration");
    }
}

export class CodeContainerImageScan extends CodeScan implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        commit: CodeCommit,
        integrationId: string,
        pipeline: Optional<CodeScanPipeline>,
        success: boolean,
        time: string,
        public configurationDigest: string,
        public digest: Optional<string>,
        public operatingSystem: WorkloadResourceScanOperatingSystem,
        public packageDisplayNames: string[],
        public repositoryRawUrl: Optional<string>,
        public tag: Optional<string>,
        public vulnerabilityRawIds: string[],
        public vulnerabilitySeverities: Severity[],
        public vulnerabilitySeverityToRawIdsMap: Dictionary<string[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "CodeContainerImageScan",
            tenantId,
            commit,
            integrationId,
            pipeline,
            success,
            time);
    }
}

export class CodeIacScan extends CodeScan implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        commit: CodeCommit,
        integrationId: string,
        pipeline: Optional<CodeScanPipeline>,
        success: boolean,
        time: string,
        public blockCount: Optional<number>,
        public codeTypes: CodeType[],
        public previousScanId: Optional<string>,
        public relativePath: Optional<string>,
        public stats: CodeIacScanStats) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "CodeIacScan",
            tenantId,
            commit,
            integrationId,
            pipeline,
            success,
            time);
    }
}

export class CodeServerConfiguration extends IntegrationEntityConfiguration implements IDocument, ITypeNameObject, IEndpointIntegrationEntityConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        type: IntegrationType,
        public codeTenantType: CodeTenantType,
        public endpoint: Optional<string>,
        public endpointConnectorId: Optional<string>,
        public name: string,
        public serverCertificateValidationEnabled: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            type);
    }
}

export class CodeTerraformDnsRecordSetExistsRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "CodeTerraformDnsRecordSetExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class CodeTerraformExternalExistsRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "CodeTerraformExternalExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class ConfigurationControllerInsertAwsPolicySsoPermissionSetAssignmentEligibilityRequest extends ConfigurationControllerInsertAwsSsoPermissionSetAssignmentEligibilityRequest {
    constructor(
        approval: Optional<ConfigurationControllerPermissionEligibilityApproval>,
        description: Optional<string>,
        expirationTimeFrame: string,
        granteePrincipalIds: string[],
        name: string,
        principalTenantId: string,
        reasonRequired: boolean,
        scopeId: string,
        permissionRequestScopeIds: string[],
        permissionSetNamePrefix: string,
        public awsManagedPolicyIds: string[],
        public inlinePolicyRawDocument: Optional<string>) {
        super(
            approval,
            description,
            expirationTimeFrame,
            granteePrincipalIds,
            name,
            principalTenantId,
            reasonRequired,
            scopeId,
            permissionRequestScopeIds,
            permissionSetNamePrefix);
    }
}

export class ConfigurationControllerUpdateAwsPolicySsoPermissionSetAssignmentEligibilityRequest extends ConfigurationControllerUpdateAwsSsoPermissionSetAssignmentEligibilityRequest {
    constructor(
        approval: Optional<ConfigurationControllerPermissionEligibilityApproval>,
        description: Optional<string>,
        expirationTimeFrame: string,
        granteePrincipalIds: string[],
        id: string,
        name: string,
        principalTenantId: string,
        reasonRequired: boolean,
        permissionRequestScopeIds: string[],
        permissionSetNamePrefix: string,
        public awsManagedPolicyIds: string[],
        public inlinePolicyRawDocument: Optional<string>) {
        super(
            approval,
            description,
            expirationTimeFrame,
            granteePrincipalIds,
            id,
            name,
            principalTenantId,
            reasonRequired,
            permissionRequestScopeIds,
            permissionSetNamePrefix);
    }
}

export class ConsoleApiServiceConfiguration extends ServiceConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        public authentication: AuthenticationConfigurationSection,
        public datadog: Optional<DatadogConfigurationSection>,
        public document360AuthenticationProvider: Optional<Document360AuthenticationProviderConfigurationSection>,
        public globalConsoleApp: GlobalConsoleAppConfigurationSection,
        public pendo: Optional<PendoConfigurationSection>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "ConsoleApiServiceConfiguration");
    }
}

export class ContainerImageMaliciousFileRiskModel extends WorkloadAnalysisRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: ContainerImageMaliciousFileRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "ContainerImageMaliciousFileRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class ContainerImageMaliciousFileRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "ContainerImageMaliciousFileRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class ContainerImageOperatingSystemEndOfLifeRiskModel extends WorkloadAnalysisRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: ContainerImageOperatingSystemEndOfLifeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "ContainerImageOperatingSystemEndOfLifeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class ContainerImageOperatingSystemEndOfLifeRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "ContainerImageOperatingSystemEndOfLifeRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class ContainerImageOperatingSystemUnpatchedRiskModel extends WorkloadAnalysisRiskModel implements ITypeNameObject, IUdmObjectModel, IWorkloadResourceOperatingSystemUnpatchedRiskModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: ContainerImageOperatingSystemUnpatchedRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "ContainerImageOperatingSystemUnpatchedRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class ContainerImageOperatingSystemUnpatchedRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "ContainerImageOperatingSystemUnpatchedRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class ContainerImagePackageVulnerabilitiesReportDefinitionConfiguration extends WorkloadResourcePackageVulnerabilitiesReportDefinitionConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        contentType: ReportContentType,
        exportReport: boolean,
        licenseType: ApplicationCustomerConfigurationLicensingLicenseType,
        name: Optional<string>,
        timeZoneInfoId: string,
        type: ReportType,
        filters: WorkloadControllerWorkloadResourceModelFilters,
        filtered: boolean,
        includeDescription: Optional<boolean>,
        vulnerabilityReportType: VulnerabilityReportType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "ContainerImagePackageVulnerabilitiesReportDefinitionConfiguration",
            contentType,
            exportReport,
            licenseType,
            name,
            timeZoneInfoId,
            type,
            filters,
            filtered,
            includeDescription,
            vulnerabilityReportType);
    }
}

export class ContainerImageRepositoryAnalysisServiceConfiguration extends ServiceConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "ContainerImageRepositoryAnalysisServiceConfiguration");
    }
}

export class ContainerImageRepositoryVulnerabilityRiskModel extends WorkloadAnalysisRiskModel implements ITypeNameObject, IUdmObjectModel, IWorkloadResourceVulnerabilityRiskModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: ContainerImageRepositoryVulnerabilityRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "ContainerImageRepositoryVulnerabilityRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class ContainerImageRepositoryVulnerabilityRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "ContainerImageRepositoryVulnerabilityRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class ContainerImageRepositoryWorkloadAnalysisRisk extends WorkloadAnalysisRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        criticalVulnerabilities: WorkloadResourceRiskVulnerability[],
        highVulnerabilities: WorkloadResourceRiskVulnerability[],
        singleVirtualMachine: boolean,
        virtualMachineIds: string[],
        vulnerabilitySeverityToCountMap: Dictionary<number>,
        windowsUpdateDocumentationUrl: string,
        public containerImageId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            criticalVulnerabilities,
            highVulnerabilities,
            singleVirtualMachine,
            virtualMachineIds,
            vulnerabilitySeverityToCountMap,
            windowsUpdateDocumentationUrl);
    }
}

export class ContainerImagesReportDefinitionConfiguration extends WorkloadResourcesReportDefinitionConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        contentType: ReportContentType,
        exportReport: boolean,
        licenseType: ApplicationCustomerConfigurationLicensingLicenseType,
        name: Optional<string>,
        timeZoneInfoId: string,
        type: ReportType,
        filters: WorkloadControllerWorkloadResourceModelFilters,
        sort: WorkloadControllerWorkloadResourceModelSort) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "ContainerImagesReportDefinitionConfiguration",
            contentType,
            exportReport,
            licenseType,
            name,
            timeZoneInfoId,
            type,
            filters,
            sort);
    }
}

export class ContainerImageVulnerabilityRiskModel extends WorkloadAnalysisRiskModel implements ITypeNameObject, IUdmObjectModel, IWorkloadResourceVulnerabilityRiskModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: ContainerImageVulnerabilityRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "ContainerImageVulnerabilityRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class ContainerImageVulnerabilityRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "ContainerImageVulnerabilityRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class ContainerImageWorkloadAnalysisRisk extends WorkloadAnalysisRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        criticalVulnerabilities: WorkloadResourceRiskVulnerability[],
        highVulnerabilities: WorkloadResourceRiskVulnerability[],
        singleVirtualMachine: boolean,
        virtualMachineIds: string[],
        vulnerabilitySeverityToCountMap: Dictionary<number>,
        windowsUpdateDocumentationUrl: string,
        public containerImageIds: string[],
        public containerImageRepositoryReference: string,
        public resolutionDeferTime: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            criticalVulnerabilities,
            highVulnerabilities,
            singleVirtualMachine,
            virtualMachineIds,
            vulnerabilitySeverityToCountMap,
            windowsUpdateDocumentationUrl);
    }
}

export class CustomRiskPolicyConfiguration extends RiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        public description: Optional<string>,
        public name: string,
        public severity: Severity,
        public updateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class Dag extends DagContainer implements ITypeNameObject {
    constructor(
        nodes: DagNode[],
        type: DagContainerType) {
        super(
            "Dag",
            nodes,
            type);
    }
}

export class DatadogOrganizationConfiguration extends IntegrationEntityConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        type: IntegrationType,
        public name: string,
        public site: DatadogOrganizationConfigurationSite) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "DatadogOrganizationConfiguration",
            scopeId,
            type);
    }
}

export class EndpointConnectorConfiguration extends IntegrationEntityConfiguration implements IDocument, ITypeNameObject, ITunnelSessionScopeSystemEntityConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        type: IntegrationType,
        public apiKeyId: string,
        public endpoint: string,
        public name: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "EndpointConnectorConfiguration",
            scopeId,
            type);
    }
}

export class EntityControllerGetEntityModelPageAadDirectoryApplicationCertificateRequest extends EntityControllerGetEntityModelPageAadDirectoryApplicationObjectRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort) {
        super(
            filters,
            limit,
            skip,
            sort);
    }
}

export class EntityControllerGetEntityModelPageAadDirectoryApplicationCertificateRequestFilters extends EntityControllerGetEntityModelPageAadDirectoryApplicationObjectRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        directoryApplicationIdSelection: Optional<PagedItemSelection<string>>,
        directoryApplicationOwnerPrincipalIdSelection: Optional<PagedItemSelection<string>>,
        expirationStatusSelection: Optional<ItemSelection<AadDirectoryApplicationObjectExpirationStatus>>,
        expirationTimeRangeSelection: Optional<TimeRangeSelection>,
        public thumbprintSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            directoryApplicationIdSelection,
            directoryApplicationOwnerPrincipalIdSelection,
            expirationStatusSelection,
            expirationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsDefaultKubernetesResourceRequest extends EntityControllerGetEntityModelPageAwsKubernetesResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        kubernetesResource: EntityControllerGetEntityModelPageEntityRequestKubernetesResource) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag,
            kubernetesResource);
    }
}

export class EntityControllerGetEntityModelPageAwsDefaultKubernetesResourceRequestFilters extends EntityControllerGetEntityModelPageAwsKubernetesResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public typeName: string) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsEc2ElasticIpRequest extends EntityControllerGetEntityModelPageAwsEc2ResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsEc2ElasticIpRequestFilters extends EntityControllerGetEntityModelPageAwsEc2ResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        rawIdSelection: Optional<PagedItemSelection<string>>,
        public networkInterfaceIdSelection: Optional<PagedItemSelection<string>>,
        public onPremiseIpAddressSelection: Optional<PagedItemSelection<string>>,
        public privateIpAddressSelection: Optional<PagedItemSelection<string>>,
        public publicIpAddressSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            rawIdSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsEc2ImageRequest extends EntityControllerGetEntityModelPageAwsEc2ResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public access: EntityControllerGetEntityModelPageAwsResourceRequestAccess,
        public virtualMachineDiskResource: EntityControllerGetEntityModelPageEntityRequestVirtualMachineDiskResource) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsEc2ImageRequestFilters extends EntityControllerGetEntityModelPageAwsEc2ResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        rawIdSelection: Optional<PagedItemSelection<string>>,
        public awsManaged: Optional<boolean>,
        public instanceIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            rawIdSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsEc2InstanceRequest extends EntityControllerGetEntityModelPageAwsEc2ResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public networkAccess: EntityControllerGetEntityModelPageEntityRequestNetworkAccess,
        public networked: EntityControllerGetEntityModelPageAwsResourceRequestNetworked,
        public virtualMachineDiskResource: EntityControllerGetEntityModelPageEntityRequestVirtualMachineDiskResource,
        public virtualMachineResource: EntityControllerGetEntityModelPageEntityRequestVirtualMachineResource) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsEc2InstanceRequestFilters extends EntityControllerGetEntityModelPageAwsEc2ResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        rawIdSelection: Optional<PagedItemSelection<string>>,
        public autoScalingGroupIdSelection: Optional<PagedItemSelection<string>>,
        public bareMetal: Optional<boolean>,
        public enclaveEnabledList: Optional<ItemSelection<boolean>>,
        public hypervisorSelection: Optional<ItemSelection<AwsEc2InstanceHypervisor>>,
        public keyNameSelection: Optional<PagedItemSelection<string>>,
        public launchTimeRangeSelection: Optional<TimeRangeSelection>,
        public loadBalancerIdSelection: Optional<PagedItemSelection<string>>,
        public operatingSystemDescriptionSelection: Optional<PagedItemSelection<string>>,
        public rawTypeSelection: Optional<ItemSelection<string>>,
        public statusSelection: Optional<ItemSelection<AwsEc2InstanceStatus>>,
        public stopTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            rawIdSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsEc2InternetGatewayRequest extends EntityControllerGetEntityModelPageAwsEc2ResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsEc2InternetGatewayRequestFilters extends EntityControllerGetEntityModelPageAwsEc2ResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        rawIdSelection: Optional<PagedItemSelection<string>>,
        public vpcIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            rawIdSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsEc2LaunchTemplateRequest extends EntityControllerGetEntityModelPageAwsEc2ResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public encrypted: EntityControllerGetEntityModelPageAwsResourceRequestEncrypted) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsEc2LaunchTemplateRequestFilters extends EntityControllerGetEntityModelPageAwsEc2ResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        rawIdSelection: Optional<PagedItemSelection<string>>,
        public resourceIdSelection: Optional<PagedItemSelection<string>>,
        public vpcIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            rawIdSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsEc2NatGatewayRequest extends EntityControllerGetEntityModelPageAwsEc2ResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsEc2NatGatewayRequestFilters extends EntityControllerGetEntityModelPageAwsEc2ResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        rawIdSelection: Optional<PagedItemSelection<string>>,
        public vpcIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            rawIdSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsEc2NetworkAclRequest extends EntityControllerGetEntityModelPageAwsEc2ResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsEc2NetworkAclRequestFilters extends EntityControllerGetEntityModelPageAwsEc2ResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        rawIdSelection: Optional<PagedItemSelection<string>>,
        public vpcIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            rawIdSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsEc2NetworkInterfaceRequest extends EntityControllerGetEntityModelPageAwsEc2ResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public networked: EntityControllerGetEntityModelPageAwsResourceRequestNetworked) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsEc2NetworkInterfaceRequestFilters extends EntityControllerGetEntityModelPageAwsEc2ResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        rawIdSelection: Optional<PagedItemSelection<string>>,
        public descriptionSelection: Optional<PagedItemSelection<string>>,
        public flowLogDestinationResourceIdsSelection: Optional<PagedItemSelection<string>>,
        public flowLogFilterSelection: Optional<ItemSelection<AwsEc2FlowLogFilter>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            rawIdSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsIamGroupRequest extends EntityControllerGetEntityModelPageAwsIamPrincipalRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsIamGroupRequestFilters extends EntityControllerGetEntityModelPageAwsIamPrincipalRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        policyIdSelection: Optional<PagedItemSelection<string>>,
        public userIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            policyIdSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsIamIdentityRequest extends EntityControllerGetEntityModelPageAwsIamPrincipalRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsIamIdentityRequestFilters extends EntityControllerGetEntityModelPageAwsIamPrincipalRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        policyIdSelection: Optional<PagedItemSelection<string>>,
        public activityTimeRangeSelection: Optional<TimeRangeSelection>,
        public permissionBoundaryIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            policyIdSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsIamRoleRequest extends EntityControllerGetEntityModelPageAwsIamIdentityRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public access: EntityControllerGetEntityModelPageAwsResourceRequestAccess) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsIamRoleRequestFilters extends EntityControllerGetEntityModelPageAwsIamIdentityRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        policyIdSelection: Optional<PagedItemSelection<string>>,
        activityTimeRangeSelection: Optional<TimeRangeSelection>,
        permissionBoundaryIdSelection: Optional<PagedItemSelection<string>>,
        public assumeRoleGranteeTypeSelection: Optional<ItemSelection<AwsIamRoleModelAssumeRoleGranteeType>>,
        public vendor: Optional<boolean>,
        public vendorSelection: Optional<ItemSelection<Vendor>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            policyIdSelection,
            activityTimeRangeSelection,
            permissionBoundaryIdSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsIamUserRequest extends EntityControllerGetEntityModelPageAwsIamIdentityRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAwsIamUserRequestFilters extends EntityControllerGetEntityModelPageAwsIamIdentityRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        policyIdSelection: Optional<PagedItemSelection<string>>,
        activityTimeRangeSelection: Optional<TimeRangeSelection>,
        permissionBoundaryIdSelection: Optional<PagedItemSelection<string>>,
        public accessKeyCreationTimeRangeSelection: Optional<TimeRangeSelection>,
        public accessKeyEnabled: Optional<boolean>,
        public accessKeyUniqueIdSelection: Optional<PagedItemSelection<string>>,
        public accessKeyUsageRegionSystemNameSelection: Optional<ItemSelection<string>>,
        public accessKeyUsageServiceIdSelection: Optional<PagedItemSelection<string>>,
        public accessKeyUsageTimeRangeSelection: Optional<TimeRangeSelection>,
        public groupIdSelection: Optional<PagedItemSelection<string>>,
        public groupPolicyIdSelection: Optional<PagedItemSelection<string>>,
        public mfaEnabled: Optional<boolean>,
        public passwordEnabled: Optional<boolean>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            policyIdSelection,
            activityTimeRangeSelection,
            permissionBoundaryIdSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsKubernetesClusterRoleBindingRequest extends EntityControllerGetEntityModelPageAwsKubernetesResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        kubernetesResource: EntityControllerGetEntityModelPageEntityRequestKubernetesResource,
        public kubernetesRoleBinding: EntityControllerGetEntityModelPageEntityRequestKubernetesRoleBinding) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag,
            kubernetesResource);
    }
}

export class EntityControllerGetEntityModelPageAwsKubernetesClusterRoleBindingRequestFilters extends EntityControllerGetEntityModelPageAwsKubernetesResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsKubernetesClusterRoleRequest extends EntityControllerGetEntityModelPageAwsKubernetesResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        kubernetesResource: EntityControllerGetEntityModelPageEntityRequestKubernetesResource,
        public kubernetesRole: EntityControllerGetEntityModelPageEntityRequestKubernetesRole) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag,
            kubernetesResource);
    }
}

export class EntityControllerGetEntityModelPageAwsKubernetesClusterRoleRequestFilters extends EntityControllerGetEntityModelPageAwsKubernetesResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsKubernetesGroupRequest extends EntityControllerGetEntityModelPageAwsKubernetesResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        kubernetesResource: EntityControllerGetEntityModelPageEntityRequestKubernetesResource,
        public kubernetesPrincipal: EntityControllerGetEntityModelPageEntityRequestKubernetesPrincipal) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag,
            kubernetesResource);
    }
}

export class EntityControllerGetEntityModelPageAwsKubernetesGroupRequestFilters extends EntityControllerGetEntityModelPageAwsKubernetesResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsKubernetesNamespaceRequest extends EntityControllerGetEntityModelPageAwsKubernetesResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        kubernetesResource: EntityControllerGetEntityModelPageEntityRequestKubernetesResource,
        public kubernetesNamespace: EntityControllerGetEntityModelPageEntityRequestKubernetesNamespace) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag,
            kubernetesResource);
    }
}

export class EntityControllerGetEntityModelPageAwsKubernetesNamespaceRequestFilters extends EntityControllerGetEntityModelPageAwsKubernetesResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsKubernetesNamespaceResourceRequest extends EntityControllerGetEntityModelPageAwsKubernetesResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        kubernetesResource: EntityControllerGetEntityModelPageEntityRequestKubernetesResource,
        public kubernetesNamespaceResource: EntityControllerGetEntityModelPageEntityRequestKubernetesNamespaceResource) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag,
            kubernetesResource);
    }
}

export class EntityControllerGetEntityModelPageAwsKubernetesNamespaceResourceRequestFilters extends EntityControllerGetEntityModelPageAwsKubernetesResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsKubernetesNamespaceRoleBindingRequest extends EntityControllerGetEntityModelPageAwsKubernetesNamespaceResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        kubernetesResource: EntityControllerGetEntityModelPageEntityRequestKubernetesResource,
        kubernetesNamespaceResource: EntityControllerGetEntityModelPageEntityRequestKubernetesNamespaceResource,
        public kubernetesRoleBinding: EntityControllerGetEntityModelPageEntityRequestKubernetesRoleBinding) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag,
            kubernetesResource,
            kubernetesNamespaceResource);
    }
}

export class EntityControllerGetEntityModelPageAwsKubernetesNamespaceRoleBindingRequestFilters extends EntityControllerGetEntityModelPageAwsKubernetesNamespaceResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsKubernetesNamespaceRoleRequest extends EntityControllerGetEntityModelPageAwsKubernetesNamespaceResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        kubernetesResource: EntityControllerGetEntityModelPageEntityRequestKubernetesResource,
        kubernetesNamespaceResource: EntityControllerGetEntityModelPageEntityRequestKubernetesNamespaceResource,
        public kubernetesRole: EntityControllerGetEntityModelPageEntityRequestKubernetesRole) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag,
            kubernetesResource,
            kubernetesNamespaceResource);
    }
}

export class EntityControllerGetEntityModelPageAwsKubernetesNamespaceRoleRequestFilters extends EntityControllerGetEntityModelPageAwsKubernetesNamespaceResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsKubernetesServiceAccountRequest extends EntityControllerGetEntityModelPageAwsKubernetesNamespaceResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        kubernetesResource: EntityControllerGetEntityModelPageEntityRequestKubernetesResource,
        kubernetesNamespaceResource: EntityControllerGetEntityModelPageEntityRequestKubernetesNamespaceResource) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag,
            kubernetesResource,
            kubernetesNamespaceResource);
    }
}

export class EntityControllerGetEntityModelPageAwsKubernetesServiceAccountRequestFilters extends EntityControllerGetEntityModelPageAwsKubernetesNamespaceResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public originatorWorkloadResourceIdSelection: Optional<PagedItemSelection<string>>,
        public roleIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsKubernetesServiceRequest extends EntityControllerGetEntityModelPageAwsKubernetesNamespaceResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        kubernetesResource: EntityControllerGetEntityModelPageEntityRequestKubernetesResource,
        kubernetesNamespaceResource: EntityControllerGetEntityModelPageEntityRequestKubernetesNamespaceResource,
        public kubernetesService: EntityControllerGetEntityModelPageEntityRequestKubernetesService) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag,
            kubernetesResource,
            kubernetesNamespaceResource);
    }
}

export class EntityControllerGetEntityModelPageAwsKubernetesServiceRequestFilters extends EntityControllerGetEntityModelPageAwsKubernetesNamespaceResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsKubernetesWorkloadResourceRequest extends EntityControllerGetEntityModelPageAwsKubernetesNamespaceResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        kubernetesResource: EntityControllerGetEntityModelPageEntityRequestKubernetesResource,
        kubernetesNamespaceResource: EntityControllerGetEntityModelPageEntityRequestKubernetesNamespaceResource) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag,
            kubernetesResource,
            kubernetesNamespaceResource);
    }
}

export class EntityControllerGetEntityModelPageAwsKubernetesWorkloadResourceRequestFilters extends EntityControllerGetEntityModelPageAwsKubernetesNamespaceResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public awsRoleIdSelection: Optional<PagedItemSelection<string>>,
        public containerImageIdSelection: Optional<PagedItemSelection<string>>,
        public containerImageVulnerabilityIdSelection: Optional<PagedItemSelection<string>>,
        public containerImageVulnerabilitySeveritySelection: Optional<ItemSelection<Severity>>,
        public controllerResourceIdSelection: Optional<PagedItemSelection<string>>,
        public serviceAccountIdSelection: Optional<PagedItemSelection<string>>,
        public typeNameSelection: Optional<ItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsRdsAuroraGlobalClusterRequest extends EntityControllerGetEntityModelPageAwsRdsGlobalClusterResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        encrypted: EntityControllerGetEntityModelPageAwsResourceRequestEncrypted) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag,
            encrypted);
    }
}

export class EntityControllerGetEntityModelPageAwsRdsAuroraGlobalClusterRequestFilters extends EntityControllerGetEntityModelPageAwsRdsGlobalClusterResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        clusterIdSelection: Optional<PagedItemSelection<string>>,
        deleteProtection: Optional<boolean>,
        engineVersionSelection: Optional<PagedItemSelection<string>>,
        statusSelection: Optional<ItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            clusterIdSelection,
            deleteProtection,
            engineVersionSelection,
            statusSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsRdsClusterRequest extends EntityControllerGetEntityModelPageAwsRdsClusterResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        cloudWatchLogging: EntityControllerGetEntityModelPageAwsResourceRequestCloudWatchLogging,
        encrypted: EntityControllerGetEntityModelPageAwsResourceRequestEncrypted,
        public dataAnalysis: EntityControllerGetEntityModelPageEntityRequestDataAnalysis,
        public networkAccess: EntityControllerGetEntityModelPageEntityRequestNetworkAccess,
        public networked: EntityControllerGetEntityModelPageAwsResourceRequestNetworked) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag,
            cloudWatchLogging,
            encrypted);
    }
}

export class EntityControllerGetEntityModelPageAwsRdsClusterRequestFilters extends EntityControllerGetEntityModelPageAwsRdsClusterResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        deleteProtection: Optional<boolean>,
        engineVersionSelection: Optional<PagedItemSelection<string>>,
        globalClusterIdSelection: Optional<PagedItemSelection<string>>,
        instanceIdSelection: Optional<PagedItemSelection<string>>,
        snapshotIdSelection: Optional<PagedItemSelection<string>>,
        statusSelection: Optional<PagedItemSelection<string>>,
        public engineTypeSelection: Optional<ItemSelection<AwsRdsClusterEngineType>>,
        public instanceRawTypeSelection: Optional<PagedItemSelection<string>>,
        public minorVersionUpgradeEnabled: Optional<boolean>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            deleteProtection,
            engineVersionSelection,
            globalClusterIdSelection,
            instanceIdSelection,
            snapshotIdSelection,
            statusSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsRdsDatabaseInstanceRequest extends EntityControllerGetEntityModelPageAwsRdsDatabaseInstanceResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        cloudWatchLogging: EntityControllerGetEntityModelPageAwsResourceRequestCloudWatchLogging,
        dataAnalysis: EntityControllerGetEntityModelPageEntityRequestDataAnalysis,
        encrypted: EntityControllerGetEntityModelPageAwsResourceRequestEncrypted,
        networkAccess: EntityControllerGetEntityModelPageEntityRequestNetworkAccess,
        networked: EntityControllerGetEntityModelPageAwsResourceRequestNetworked) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag,
            cloudWatchLogging,
            dataAnalysis,
            encrypted,
            networkAccess,
            networked);
    }
}

export class EntityControllerGetEntityModelPageAwsRdsDatabaseInstanceRequestFilters extends EntityControllerGetEntityModelPageAwsRdsDatabaseInstanceResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        clusterIdSelection: Optional<PagedItemSelection<string>>,
        deleteProtection: Optional<boolean>,
        engineVersionSelection: Optional<PagedItemSelection<string>>,
        instanceRawTypeSelection: Optional<PagedItemSelection<string>>,
        minorVersionUpgradeEnabled: Optional<boolean>,
        statusSelection: Optional<PagedItemSelection<string>>,
        public engineTypeSelection: Optional<ItemSelection<AwsRdsDatabaseInstanceEngineType>>,
        public snapshotIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            clusterIdSelection,
            deleteProtection,
            engineVersionSelection,
            instanceRawTypeSelection,
            minorVersionUpgradeEnabled,
            statusSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsRdsDocDbGlobalClusterRequest extends EntityControllerGetEntityModelPageAwsRdsGlobalClusterResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        encrypted: EntityControllerGetEntityModelPageAwsResourceRequestEncrypted) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag,
            encrypted);
    }
}

export class EntityControllerGetEntityModelPageAwsRdsDocDbGlobalClusterRequestFilters extends EntityControllerGetEntityModelPageAwsRdsGlobalClusterResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        clusterIdSelection: Optional<PagedItemSelection<string>>,
        deleteProtection: Optional<boolean>,
        engineVersionSelection: Optional<PagedItemSelection<string>>,
        statusSelection: Optional<ItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            clusterIdSelection,
            deleteProtection,
            engineVersionSelection,
            statusSelection);
    }
}

export class EntityControllerGetEntityModelPageAzureBotServiceBotServiceRequest extends EntityControllerGetEntityModelPageAzureResourceGroupResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public encrypted: EntityControllerGetEntityModelPageAzureResourceRequestEncrypted,
        public networkAccess: EntityControllerGetEntityModelPageEntityRequestNetworkAccess) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAzureBotServiceBotServiceRequestFilters extends EntityControllerGetEntityModelPageAzureResourceGroupResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        resourceGroupIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            resourceGroupIdSelection);
    }
}

export class EntityControllerGetEntityModelPageAzureCognitiveServicesAccountRequest extends EntityControllerGetEntityModelPageAzureResourceGroupResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public encrypted: EntityControllerGetEntityModelPageAzureResourceRequestEncrypted,
        public networkAccess: EntityControllerGetEntityModelPageEntityRequestNetworkAccess) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAzureCognitiveServicesAccountRequestFilters extends EntityControllerGetEntityModelPageAzureResourceGroupResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        resourceGroupIdSelection: Optional<PagedItemSelection<string>>,
        public restrictOutboundAccess: Optional<boolean>,
        public typeSelection: Optional<ItemSelection<AzureCognitiveServicesAccountType>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            resourceGroupIdSelection);
    }
}

export class EntityControllerGetEntityModelPageAzureComputeDiskResourceRequest extends EntityControllerGetEntityModelPageAzureResourceGroupResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAzureComputeDiskResourceRequestFilters extends EntityControllerGetEntityModelPageAzureResourceGroupResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        resourceGroupIdSelection: Optional<PagedItemSelection<string>>,
        public networkAccessPolicyTypeSelection: Optional<ItemSelection<AzureComputeDiskResourceNetworkAccessPolicyType>>,
        public sourceIdSelections: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            resourceGroupIdSelection);
    }
}

export class EntityControllerGetEntityModelPageAzureComputeDiskSnapshotRequest extends EntityControllerGetEntityModelPageAzureComputeDiskResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAzureComputeDiskSnapshotRequestFilters extends EntityControllerGetEntityModelPageAzureComputeDiskResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        resourceGroupIdSelection: Optional<PagedItemSelection<string>>,
        networkAccessPolicyTypeSelection: Optional<ItemSelection<AzureComputeDiskResourceNetworkAccessPolicyType>>,
        sourceIdSelections: Optional<PagedItemSelection<string>>,
        public typeSelection: Optional<ItemSelection<AzureComputeDiskSnapshotType>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            resourceGroupIdSelection,
            networkAccessPolicyTypeSelection,
            sourceIdSelections);
    }
}

export class EntityControllerGetEntityModelPageAzureComputeVirtualMachineResourceRequest extends EntityControllerGetEntityModelPageAzureResourceGroupResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAzureComputeVirtualMachineResourceRequestFilters extends EntityControllerGetEntityModelPageAzureResourceGroupResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        resourceGroupIdSelection: Optional<PagedItemSelection<string>>,
        public applicationGatewayIdSelection: Optional<PagedItemSelection<string>>,
        public loadBalancerIdSelection: Optional<PagedItemSelection<string>>,
        public rawIdSelection: Optional<PagedItemSelection<string>>,
        public virtualNetworkIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            resourceGroupIdSelection);
    }
}

export class EntityControllerGetEntityModelPageAzureComputeVirtualMachineScaleSetRequest extends EntityControllerGetEntityModelPageAzureComputeVirtualMachineResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public networkAccess: EntityControllerGetEntityModelPageEntityRequestNetworkAccess) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAzureComputeVirtualMachineScaleSetRequestFilters extends EntityControllerGetEntityModelPageAzureComputeVirtualMachineResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        resourceGroupIdSelection: Optional<PagedItemSelection<string>>,
        applicationGatewayIdSelection: Optional<PagedItemSelection<string>>,
        loadBalancerIdSelection: Optional<PagedItemSelection<string>>,
        rawIdSelection: Optional<PagedItemSelection<string>>,
        virtualNetworkIdSelection: Optional<PagedItemSelection<string>>,
        public typeSelection: Optional<ItemSelection<AzureComputeVirtualMachineScaleSetType>>,
        public virtualMachineIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            resourceGroupIdSelection,
            applicationGatewayIdSelection,
            loadBalancerIdSelection,
            rawIdSelection,
            virtualNetworkIdSelection);
    }
}

export class EntityControllerGetEntityModelPageAzureComputeVirtualMachineScaleSetVirtualMachineRequest extends EntityControllerGetEntityModelPageAzureComputeVirtualMachineResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public networkAccess: EntityControllerGetEntityModelPageEntityRequestNetworkAccess,
        public virtualMachineDiskResource: EntityControllerGetEntityModelPageEntityRequestVirtualMachineDiskResource,
        public virtualMachineResource: EntityControllerGetEntityModelPageEntityRequestVirtualMachineResource) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAzureComputeVirtualMachineScaleSetVirtualMachineRequestFilters extends EntityControllerGetEntityModelPageAzureComputeVirtualMachineResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        resourceGroupIdSelection: Optional<PagedItemSelection<string>>,
        applicationGatewayIdSelection: Optional<PagedItemSelection<string>>,
        loadBalancerIdSelection: Optional<PagedItemSelection<string>>,
        rawIdSelection: Optional<PagedItemSelection<string>>,
        virtualNetworkIdSelection: Optional<PagedItemSelection<string>>,
        public statusSelection: Optional<ItemSelection<AzureComputeVirtualMachineStatus>>,
        public virtualMachineScaleSetIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            resourceGroupIdSelection,
            applicationGatewayIdSelection,
            loadBalancerIdSelection,
            rawIdSelection,
            virtualNetworkIdSelection);
    }
}

export class EntityControllerGetEntityModelPageAzureContainerRegistryRegistryRequest extends EntityControllerGetEntityModelPageAzureResourceGroupResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public encrypted: EntityControllerGetEntityModelPageAzureResourceRequestEncrypted,
        public networkAccess: EntityControllerGetEntityModelPageEntityRequestNetworkAccess) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAzureContainerRegistryRegistryRequestFilters extends EntityControllerGetEntityModelPageAzureResourceGroupResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        resourceGroupIdSelection: Optional<PagedItemSelection<string>>,
        public repositoryIdSelection: Optional<PagedItemSelection<string>>,
        public skuSelection: Optional<ItemSelection<AzureContainerRegistryRegistrySku>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            resourceGroupIdSelection);
    }
}

export class EntityControllerGetEntityModelPageAzureContainerRegistryRepositoryRequest extends EntityControllerGetEntityModelPageAzureResourceGroupResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAzureContainerRegistryRepositoryRequestFilters extends EntityControllerGetEntityModelPageAzureResourceGroupResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        resourceGroupIdSelection: Optional<PagedItemSelection<string>>,
        public containerImageResourceIdSelection: Optional<PagedItemSelection<string>>,
        public containerImageVulnerabilityIdSelection: Optional<PagedItemSelection<string>>,
        public containerImageVulnerabilitySeveritySelection: Optional<ItemSelection<Severity>>,
        public registryIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            resourceGroupIdSelection);
    }
}

export class EntityControllerGetEntityModelPageAzureContainerServiceManagedClusterAgentPoolRequest extends EntityControllerGetEntityModelPageAzureResourceGroupResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAzureContainerServiceManagedClusterAgentPoolRequestFilters extends EntityControllerGetEntityModelPageAzureResourceGroupResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        resourceGroupIdSelection: Optional<PagedItemSelection<string>>,
        public clusterIdSelection: Optional<PagedItemSelection<string>>,
        public modeSelection: Optional<ItemSelection<AzureContainerServiceManagedClusterAgentPoolMode>>,
        public statusSelection: Optional<ItemSelection<AzureContainerServiceManagedClusterAgentPoolStatus>>,
        public virtualMachineScaleSetIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            resourceGroupIdSelection);
    }
}

export class EntityControllerGetEntityModelPageAzureContainerServiceManagedClusterRequest extends EntityControllerGetEntityModelPageAzureResourceGroupResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public encrypted: EntityControllerGetEntityModelPageAzureResourceRequestEncrypted) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAzureContainerServiceManagedClusterRequestFilters extends EntityControllerGetEntityModelPageAzureResourceGroupResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        resourceGroupIdSelection: Optional<PagedItemSelection<string>>,
        public apiConnectivityStatusSelection: Optional<ItemSelection<SystemKubernetesClusterApiConnectivityStatus>>,
        public authenticationAndAuthorizationModeSelection: Optional<ItemSelection<AzureContainerServiceManagedClusterAuthenticationAndAuthorizationMode>>,
        public resourceUpdateTimeSelection: Optional<TimeRangeSelection>,
        public statusSelection: Optional<ItemSelection<AzureContainerServiceManagedClusterStatus>>,
        public versionSelection: Optional<PagedItemSelection<string>>,
        public virtualNetworkIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            resourceGroupIdSelection);
    }
}

export class EntityControllerGetEntityModelPageAzureDefaultKubernetesResourceRequest extends EntityControllerGetEntityModelPageAzureKubernetesResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        kubernetesResource: EntityControllerGetEntityModelPageEntityRequestKubernetesResource) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag,
            kubernetesResource);
    }
}

export class EntityControllerGetEntityModelPageAzureDefaultKubernetesResourceRequestFilters extends EntityControllerGetEntityModelPageAzureKubernetesResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public typeName: string) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAzureDefaultResourceGroupResourceRequest extends EntityControllerGetEntityModelPageAzureResourceGroupResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAzureDefaultResourceGroupResourceRequestFilters extends EntityControllerGetEntityModelPageAzureResourceGroupResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        resourceGroupIdSelection: Optional<PagedItemSelection<string>>,
        public typeName: string) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            resourceGroupIdSelection);
    }
}

export class EntityControllerGetEntityModelPageAzureDocumentDbDatabaseAccountRequest extends EntityControllerGetEntityModelPageAzureResourceGroupResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public encrypted: EntityControllerGetEntityModelPageAzureResourceRequestEncrypted,
        public networkAccess: EntityControllerGetEntityModelPageEntityRequestNetworkAccess) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAzureDocumentDbDatabaseAccountRequestFilters extends EntityControllerGetEntityModelPageAzureResourceGroupResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        resourceGroupIdSelection: Optional<PagedItemSelection<string>>,
        public databaseAccountResourceIdSelection: Optional<PagedItemSelection<string>>,
        public managementKeyAccess: Optional<boolean>,
        public sharedKeyAccess: Optional<boolean>,
        public typeSelection: Optional<ItemSelection<AzureDocumentDbDatabaseAccountType>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            resourceGroupIdSelection);
    }
}

export class EntityControllerGetEntityModelPageAzureEventHubNamespaceRequest extends EntityControllerGetEntityModelPageAzureResourceGroupResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public encrypted: EntityControllerGetEntityModelPageAzureResourceRequestEncrypted,
        public networkAccess: EntityControllerGetEntityModelPageEntityRequestNetworkAccess) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAzureEventHubNamespaceRequestFilters extends EntityControllerGetEntityModelPageAzureResourceGroupResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        resourceGroupIdSelection: Optional<PagedItemSelection<string>>,
        public eventHubIdSelection: Optional<PagedItemSelection<string>>,
        public skuSelection: Optional<ItemSelection<AzureEventHubNamespaceSku>>,
        public statusSelection: Optional<ItemSelection<AzureEventHubNamespaceStatus>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            resourceGroupIdSelection);
    }
}

export class EntityControllerGetEntityModelPageAzureKeyVaultVaultObjectRequest extends EntityControllerGetEntityModelPageAzureResourceGroupResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAzureKeyVaultVaultObjectRequestFilters extends EntityControllerGetEntityModelPageAzureResourceGroupResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        resourceGroupIdSelection: Optional<PagedItemSelection<string>>,
        public azureManaged: Optional<boolean>,
        public enabled: Optional<boolean>,
        public expirationTimeRangeSelection: Optional<TimeRangeSelection>,
        public vaultIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            resourceGroupIdSelection);
    }
}

export class EntityControllerGetEntityModelPageAzureKeyVaultVaultRequest extends EntityControllerGetEntityModelPageAzureResourceGroupResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAzureKeyVaultVaultRequestFilters extends EntityControllerGetEntityModelPageAzureResourceGroupResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        resourceGroupIdSelection: Optional<PagedItemSelection<string>>,
        public rbacEnabled: Optional<boolean>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            resourceGroupIdSelection);
    }
}

export class EntityControllerGetEntityModelPageAzureKeyVaultVaultSecretRequest extends EntityControllerGetEntityModelPageAzureKeyVaultVaultObjectRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAzureKeyVaultVaultSecretRequestFilters extends EntityControllerGetEntityModelPageAzureKeyVaultVaultObjectRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        resourceGroupIdSelection: Optional<PagedItemSelection<string>>,
        azureManaged: Optional<boolean>,
        enabled: Optional<boolean>,
        expirationTimeRangeSelection: Optional<TimeRangeSelection>,
        vaultIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            resourceGroupIdSelection,
            azureManaged,
            enabled,
            expirationTimeRangeSelection,
            vaultIdSelection);
    }
}

export class EntityControllerGetEntityModelPageAzureKubernetesClusterRoleBindingRequest extends EntityControllerGetEntityModelPageAzureKubernetesResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        kubernetesResource: EntityControllerGetEntityModelPageEntityRequestKubernetesResource,
        public kubernetesRoleBinding: EntityControllerGetEntityModelPageEntityRequestKubernetesRoleBinding) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag,
            kubernetesResource);
    }
}

export class EntityControllerGetEntityModelPageAzureKubernetesClusterRoleBindingRequestFilters extends EntityControllerGetEntityModelPageAzureKubernetesResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAzureKubernetesClusterRoleRequest extends EntityControllerGetEntityModelPageAzureKubernetesResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        kubernetesResource: EntityControllerGetEntityModelPageEntityRequestKubernetesResource,
        public kubernetesRole: EntityControllerGetEntityModelPageEntityRequestKubernetesRole) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag,
            kubernetesResource);
    }
}

export class EntityControllerGetEntityModelPageAzureKubernetesClusterRoleRequestFilters extends EntityControllerGetEntityModelPageAzureKubernetesResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAzureKubernetesGroupRequest extends EntityControllerGetEntityModelPageAzureKubernetesResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        kubernetesResource: EntityControllerGetEntityModelPageEntityRequestKubernetesResource,
        public kubernetesPrincipal: EntityControllerGetEntityModelPageEntityRequestKubernetesPrincipal) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag,
            kubernetesResource);
    }
}

export class EntityControllerGetEntityModelPageAzureKubernetesGroupRequestFilters extends EntityControllerGetEntityModelPageAzureKubernetesResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAzureKubernetesNamespaceRequest extends EntityControllerGetEntityModelPageAzureKubernetesResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        kubernetesResource: EntityControllerGetEntityModelPageEntityRequestKubernetesResource,
        public kubernetesNamespace: EntityControllerGetEntityModelPageEntityRequestKubernetesNamespace) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag,
            kubernetesResource);
    }
}

export class EntityControllerGetEntityModelPageAzureKubernetesNamespaceRequestFilters extends EntityControllerGetEntityModelPageAzureKubernetesResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAzureKubernetesNamespaceResourceRequest extends EntityControllerGetEntityModelPageAzureKubernetesResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        kubernetesResource: EntityControllerGetEntityModelPageEntityRequestKubernetesResource,
        public kubernetesNamespaceResource: EntityControllerGetEntityModelPageEntityRequestKubernetesNamespaceResource) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag,
            kubernetesResource);
    }
}

export class EntityControllerGetEntityModelPageAzureKubernetesNamespaceResourceRequestFilters extends EntityControllerGetEntityModelPageAzureKubernetesResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAzureKubernetesNamespaceRoleBindingRequest extends EntityControllerGetEntityModelPageAzureKubernetesNamespaceResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        kubernetesResource: EntityControllerGetEntityModelPageEntityRequestKubernetesResource,
        kubernetesNamespaceResource: EntityControllerGetEntityModelPageEntityRequestKubernetesNamespaceResource,
        public kubernetesRoleBinding: EntityControllerGetEntityModelPageEntityRequestKubernetesRoleBinding) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag,
            kubernetesResource,
            kubernetesNamespaceResource);
    }
}

export class EntityControllerGetEntityModelPageAzureKubernetesNamespaceRoleBindingRequestFilters extends EntityControllerGetEntityModelPageAzureKubernetesNamespaceResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAzureKubernetesNamespaceRoleRequest extends EntityControllerGetEntityModelPageAzureKubernetesNamespaceResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        kubernetesResource: EntityControllerGetEntityModelPageEntityRequestKubernetesResource,
        kubernetesNamespaceResource: EntityControllerGetEntityModelPageEntityRequestKubernetesNamespaceResource,
        public kubernetesRole: EntityControllerGetEntityModelPageEntityRequestKubernetesRole) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag,
            kubernetesResource,
            kubernetesNamespaceResource);
    }
}

export class EntityControllerGetEntityModelPageAzureKubernetesNamespaceRoleRequestFilters extends EntityControllerGetEntityModelPageAzureKubernetesNamespaceResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAzureKubernetesServiceAccountRequest extends EntityControllerGetEntityModelPageAzureKubernetesNamespaceResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        kubernetesResource: EntityControllerGetEntityModelPageEntityRequestKubernetesResource,
        kubernetesNamespaceResource: EntityControllerGetEntityModelPageEntityRequestKubernetesNamespaceResource) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag,
            kubernetesResource,
            kubernetesNamespaceResource);
    }
}

export class EntityControllerGetEntityModelPageAzureKubernetesServiceAccountRequestFilters extends EntityControllerGetEntityModelPageAzureKubernetesNamespaceResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public originatorWorkloadResourceIdSelection: Optional<PagedItemSelection<string>>,
        public roleIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAzureKubernetesServiceRequest extends EntityControllerGetEntityModelPageAzureKubernetesNamespaceResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        kubernetesResource: EntityControllerGetEntityModelPageEntityRequestKubernetesResource,
        kubernetesNamespaceResource: EntityControllerGetEntityModelPageEntityRequestKubernetesNamespaceResource,
        public kubernetesService: EntityControllerGetEntityModelPageEntityRequestKubernetesService) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag,
            kubernetesResource,
            kubernetesNamespaceResource);
    }
}

export class EntityControllerGetEntityModelPageAzureKubernetesServiceRequestFilters extends EntityControllerGetEntityModelPageAzureKubernetesNamespaceResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAzureKubernetesWorkloadResourceRequest extends EntityControllerGetEntityModelPageAzureKubernetesNamespaceResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        kubernetesResource: EntityControllerGetEntityModelPageEntityRequestKubernetesResource,
        kubernetesNamespaceResource: EntityControllerGetEntityModelPageEntityRequestKubernetesNamespaceResource) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag,
            kubernetesResource,
            kubernetesNamespaceResource);
    }
}

export class EntityControllerGetEntityModelPageAzureKubernetesWorkloadResourceRequestFilters extends EntityControllerGetEntityModelPageAzureKubernetesNamespaceResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public aadServicePrincipalIdSelection: Optional<PagedItemSelection<string>>,
        public containerImageIdSelection: Optional<PagedItemSelection<string>>,
        public containerImageVulnerabilityIdSelection: Optional<PagedItemSelection<string>>,
        public containerImageVulnerabilitySeveritySelection: Optional<ItemSelection<Severity>>,
        public controllerResourceIdSelection: Optional<PagedItemSelection<string>>,
        public serviceAccountIdSelection: Optional<PagedItemSelection<string>>,
        public typeNameSelection: Optional<ItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAzureLogicWorkflowRequest extends EntityControllerGetEntityModelPageAzureResourceGroupResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAzureLogicWorkflowRequestFilters extends EntityControllerGetEntityModelPageAzureResourceGroupResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        resourceGroupIdSelection: Optional<PagedItemSelection<string>>,
        public statusSelection: Optional<ItemSelection<AzureLogicWorkflowStatus>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            resourceGroupIdSelection);
    }
}

export class EntityControllerGetEntityModelPageAzureMachineLearningWorkspaceRequest extends EntityControllerGetEntityModelPageAzureResourceGroupResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public encrypted: EntityControllerGetEntityModelPageAzureResourceRequestEncrypted,
        public networkAccess: EntityControllerGetEntityModelPageEntityRequestNetworkAccess) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAzureMachineLearningWorkspaceRequestFilters extends EntityControllerGetEntityModelPageAzureResourceGroupResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        resourceGroupIdSelection: Optional<PagedItemSelection<string>>,
        public highBusinessImpact: Optional<boolean>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            resourceGroupIdSelection);
    }
}

export class EntityControllerGetEntityModelPageAzureManagedIdentityManagedIdentityRequest extends EntityControllerGetEntityModelPageAzureResourceGroupResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAzureManagedIdentityManagedIdentityRequestFilters extends EntityControllerGetEntityModelPageAzureResourceGroupResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        resourceGroupIdSelection: Optional<PagedItemSelection<string>>,
        public containedRoleAssignmentResourceExists: Optional<boolean>,
        public originatorResourceIdSelection: Optional<PagedItemSelection<string>>,
        public servicePrincipalActivityTimeRangeSelection: Optional<TimeRangeSelection>,
        public servicePrincipalRoleDefinitionIdSelection: Optional<PagedItemSelection<string>>,
        public servicePrincipalSignInTimeRangeSelection: Optional<TimeRangeSelection>,
        public typeSelection: Optional<ItemSelection<AzureManagedIdentityManagedIdentityModelType>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            resourceGroupIdSelection);
    }
}

export class EntityControllerGetEntityModelPageAzureMySqlServerRequest extends EntityControllerGetEntityModelPageAzureResourceGroupResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public networkAccess: EntityControllerGetEntityModelPageEntityRequestNetworkAccess) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAzureMySqlServerRequestFilters extends EntityControllerGetEntityModelPageAzureResourceGroupResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        resourceGroupIdSelection: Optional<PagedItemSelection<string>>,
        public administratorUserNameSelection: Optional<PagedItemSelection<string>>,
        public authenticationTypeSelection: Optional<ItemSelection<AzureMySqlServerAuthenticationType>>,
        public statusSelection: Optional<ItemSelection<AzureMySqlServerStatus>>,
        public typeNameSelection: Optional<ItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            resourceGroupIdSelection);
    }
}

export class EntityControllerGetEntityModelPageAzureNetworkApplicationGatewayRequest extends EntityControllerGetEntityModelPageAzureResourceGroupResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAzureNetworkApplicationGatewayRequestFilters extends EntityControllerGetEntityModelPageAzureResourceGroupResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        resourceGroupIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            resourceGroupIdSelection);
    }
}

export class EntityControllerGetEntityModelPageAzureNetworkApplicationSecurityGroupRequest extends EntityControllerGetEntityModelPageAzureResourceGroupResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAzureNetworkApplicationSecurityGroupRequestFilters extends EntityControllerGetEntityModelPageAzureResourceGroupResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        resourceGroupIdSelection: Optional<PagedItemSelection<string>>,
        public virtualMachineIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            resourceGroupIdSelection);
    }
}

export class EntityControllerGetEntityModelPageAzureNetworkDnsZoneRequest extends EntityControllerGetEntityModelPageAzureResourceGroupResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAzureNetworkDnsZoneRequestFilters extends EntityControllerGetEntityModelPageAzureResourceGroupResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        resourceGroupIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            resourceGroupIdSelection);
    }
}

export class EntityControllerGetEntityModelPageAzureNetworkLoadBalancerRequest extends EntityControllerGetEntityModelPageAzureResourceGroupResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public networkAccess: EntityControllerGetEntityModelPageEntityRequestNetworkAccess) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAzureNetworkLoadBalancerRequestFilters extends EntityControllerGetEntityModelPageAzureResourceGroupResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        resourceGroupIdSelection: Optional<PagedItemSelection<string>>,
        public skuSelection: Optional<ItemSelection<AzureNetworkLoadBalancerSku>>,
        public typeSelection: Optional<ItemSelection<AzureNetworkLoadBalancerType>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            resourceGroupIdSelection);
    }
}

export class EntityControllerGetEntityModelPageAzureNetworkNetworkInterfaceRequest extends EntityControllerGetEntityModelPageAzureResourceGroupResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAzureNetworkNetworkInterfaceRequestFilters extends EntityControllerGetEntityModelPageAzureResourceGroupResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        resourceGroupIdSelection: Optional<PagedItemSelection<string>>,
        public networkSecurityGroupIdSelection: Optional<PagedItemSelection<string>>,
        public publicIpAddressIdSelection: Optional<PagedItemSelection<string>>,
        public virtualMachineIdSelection: Optional<PagedItemSelection<string>>,
        public virtualNetworkIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            resourceGroupIdSelection);
    }
}

export class EntityControllerGetEntityModelPageAzureNetworkNetworkSecurityGroupRequest extends EntityControllerGetEntityModelPageAzureResourceGroupResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAzureNetworkNetworkSecurityGroupRequestFilters extends EntityControllerGetEntityModelPageAzureResourceGroupResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        resourceGroupIdSelection: Optional<PagedItemSelection<string>>,
        public subnetIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            resourceGroupIdSelection);
    }
}

export class EntityControllerGetEntityModelPageAzureNetworkPrivateDnsZoneRequest extends EntityControllerGetEntityModelPageAzureResourceGroupResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAzureNetworkPrivateDnsZoneRequestFilters extends EntityControllerGetEntityModelPageAzureResourceGroupResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        resourceGroupIdSelection: Optional<PagedItemSelection<string>>,
        public virtualNetworkIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            resourceGroupIdSelection);
    }
}

export class EntityControllerGetEntityModelPageAzureNetworkPrivateEndpointRequest extends EntityControllerGetEntityModelPageAzureResourceGroupResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAzureNetworkPrivateEndpointRequestFilters extends EntityControllerGetEntityModelPageAzureResourceGroupResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        resourceGroupIdSelection: Optional<PagedItemSelection<string>>,
        public attachedResourceIdSelection: Optional<PagedItemSelection<string>>,
        public connectionStatusSelection: Optional<ItemSelection<AzureNetworkPrivateEndpointConnectionStatus>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            resourceGroupIdSelection);
    }
}

export class EntityControllerGetEntityModelPageAzureNetworkPublicIpAddressRequest extends EntityControllerGetEntityModelPageAzureResourceGroupResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAzureNetworkPublicIpAddressRequestFilters extends EntityControllerGetEntityModelPageAzureResourceGroupResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        resourceGroupIdSelection: Optional<PagedItemSelection<string>>,
        public attachedResourceIdSelection: Optional<PagedItemSelection<string>>,
        public dnsNameSelection: Optional<PagedItemSelection<string>>,
        public ipAddressSelection: Optional<PagedItemSelection<string>>,
        public skuSelection: Optional<ItemSelection<AzureNetworkPublicIpAddressSku>>,
        public typeSelection: Optional<ItemSelection<AzureNetworkPublicIpAddressType>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            resourceGroupIdSelection);
    }
}

export class EntityControllerGetEntityModelPageAzureNetworkPublicIpPrefixRequest extends EntityControllerGetEntityModelPageAzureResourceGroupResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAzureNetworkPublicIpPrefixRequestFilters extends EntityControllerGetEntityModelPageAzureResourceGroupResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        resourceGroupIdSelection: Optional<PagedItemSelection<string>>,
        public publicIpAddressIdSelection: Optional<PagedItemSelection<string>>,
        public subnetSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            resourceGroupIdSelection);
    }
}

export class EntityControllerGetEntityModelPageAzureNetworkRouteTableRequest extends EntityControllerGetEntityModelPageAzureResourceGroupResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAzureNetworkRouteTableRequestFilters extends EntityControllerGetEntityModelPageAzureResourceGroupResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        resourceGroupIdSelection: Optional<PagedItemSelection<string>>,
        public subnetIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            resourceGroupIdSelection);
    }
}

export class EntityControllerGetEntityModelPageAzureNetworkVirtualNetworkRequest extends EntityControllerGetEntityModelPageAzureResourceGroupResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAzureNetworkVirtualNetworkRequestFilters extends EntityControllerGetEntityModelPageAzureResourceGroupResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        resourceGroupIdSelection: Optional<PagedItemSelection<string>>,
        public networkedResourceIdSelection: Optional<PagedItemSelection<string>>,
        public subnetSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            resourceGroupIdSelection);
    }
}

export class EntityControllerGetEntityModelPageAzureNetworkVirtualNetworkSubnetRequest extends EntityControllerGetEntityModelPageAzureResourceGroupResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public networkAccess: EntityControllerGetEntityModelPageEntityRequestNetworkAccess) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAzureNetworkVirtualNetworkSubnetRequestFilters extends EntityControllerGetEntityModelPageAzureResourceGroupResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        resourceGroupIdSelection: Optional<PagedItemSelection<string>>,
        public networkedResourceIdSelection: Optional<PagedItemSelection<string>>,
        public networkSecurityGroupIdSelection: Optional<PagedItemSelection<string>>,
        public subnetSelection: Optional<PagedItemSelection<string>>,
        public virtualNetworkIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            resourceGroupIdSelection);
    }
}

export class EntityControllerGetEntityModelPageAzurePostgreSqlServerRequest extends EntityControllerGetEntityModelPageAzureResourceGroupResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public networkAccess: EntityControllerGetEntityModelPageEntityRequestNetworkAccess) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAzurePostgreSqlServerRequestFilters extends EntityControllerGetEntityModelPageAzureResourceGroupResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        resourceGroupIdSelection: Optional<PagedItemSelection<string>>,
        public administratorUserNameSelection: Optional<PagedItemSelection<string>>,
        public authenticationTypeSelection: Optional<ItemSelection<AzurePostgreSqlServerAuthenticationType>>,
        public statusSelection: Optional<ItemSelection<AzurePostgreSqlServerStatus>>,
        public typeNameSelection: Optional<ItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            resourceGroupIdSelection);
    }
}

export class EntityControllerGetEntityModelPageGcpComputeBackendServiceRequest extends EntityControllerGetEntityModelPageGcpComputeLoadBalancerResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageGcpComputeBackendServiceRequestFilters extends EntityControllerGetEntityModelPageGcpComputeLoadBalancerResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        updateTimeRangeSelection: Optional<TimeRangeSelection>,
        accessLevelSelection: Optional<ItemSelection<GcpScopeResourceAccessLevel>>,
        rawIdSelection: Optional<PagedItemSelection<string>>,
        loadBalancerIdSelection: Optional<PagedItemSelection<string>>,
        public backendResourceIdSelection: Optional<PagedItemSelection<string>>,
        public vpcIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            updateTimeRangeSelection,
            accessLevelSelection,
            rawIdSelection,
            loadBalancerIdSelection);
    }
}

export class EntityControllerGetEntityModelPageGcpComputeForwardingRuleRequest extends EntityControllerGetEntityModelPageGcpComputeLoadBalancerResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageGcpComputeForwardingRuleRequestFilters extends EntityControllerGetEntityModelPageGcpComputeLoadBalancerResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        updateTimeRangeSelection: Optional<TimeRangeSelection>,
        accessLevelSelection: Optional<ItemSelection<GcpScopeResourceAccessLevel>>,
        rawIdSelection: Optional<PagedItemSelection<string>>,
        loadBalancerIdSelection: Optional<PagedItemSelection<string>>,
        public ipAddressSelection: Optional<PagedItemSelection<string>>,
        public targetIdSelection: Optional<PagedItemSelection<string>>,
        public vpcIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            updateTimeRangeSelection,
            accessLevelSelection,
            rawIdSelection,
            loadBalancerIdSelection);
    }
}

export class EntityControllerGetEntityModelPageGcpDefaultKubernetesResourceRequest extends EntityControllerGetEntityModelPageGcpKubernetesResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        kubernetesResource: EntityControllerGetEntityModelPageEntityRequestKubernetesResource,
        public typeName: string) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag,
            kubernetesResource);
    }
}

export class EntityControllerGetEntityModelPageGcpDefaultKubernetesResourceRequestFilters extends EntityControllerGetEntityModelPageGcpKubernetesResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        updateTimeRangeSelection: Optional<TimeRangeSelection>,
        public typeName: string) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            updateTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageGcpKubernetesClusterRoleBindingRequest extends EntityControllerGetEntityModelPageGcpKubernetesResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        kubernetesResource: EntityControllerGetEntityModelPageEntityRequestKubernetesResource,
        public kubernetesRoleBinding: EntityControllerGetEntityModelPageEntityRequestKubernetesRoleBinding) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag,
            kubernetesResource);
    }
}

export class EntityControllerGetEntityModelPageGcpKubernetesClusterRoleBindingRequestFilters extends EntityControllerGetEntityModelPageGcpKubernetesResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        updateTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            updateTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageGcpKubernetesClusterRoleRequest extends EntityControllerGetEntityModelPageGcpKubernetesResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        kubernetesResource: EntityControllerGetEntityModelPageEntityRequestKubernetesResource,
        public kubernetesRole: EntityControllerGetEntityModelPageEntityRequestKubernetesRole) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag,
            kubernetesResource);
    }
}

export class EntityControllerGetEntityModelPageGcpKubernetesClusterRoleRequestFilters extends EntityControllerGetEntityModelPageGcpKubernetesResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        updateTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            updateTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageGcpKubernetesGroupRequest extends EntityControllerGetEntityModelPageGcpKubernetesResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        kubernetesResource: EntityControllerGetEntityModelPageEntityRequestKubernetesResource,
        public kubernetesPrincipal: EntityControllerGetEntityModelPageEntityRequestKubernetesPrincipal) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag,
            kubernetesResource);
    }
}

export class EntityControllerGetEntityModelPageGcpKubernetesGroupRequestFilters extends EntityControllerGetEntityModelPageGcpKubernetesResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        updateTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            updateTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageGcpKubernetesNamespaceRequest extends EntityControllerGetEntityModelPageGcpKubernetesResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        kubernetesResource: EntityControllerGetEntityModelPageEntityRequestKubernetesResource,
        public kubernetesNamespace: EntityControllerGetEntityModelPageEntityRequestKubernetesNamespace) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag,
            kubernetesResource);
    }
}

export class EntityControllerGetEntityModelPageGcpKubernetesNamespaceRequestFilters extends EntityControllerGetEntityModelPageGcpKubernetesResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        updateTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            updateTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageGcpKubernetesNamespaceResourceRequest extends EntityControllerGetEntityModelPageGcpKubernetesResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        kubernetesResource: EntityControllerGetEntityModelPageEntityRequestKubernetesResource,
        public kubernetesNamespaceResource: EntityControllerGetEntityModelPageEntityRequestKubernetesNamespaceResource) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag,
            kubernetesResource);
    }
}

export class EntityControllerGetEntityModelPageGcpKubernetesNamespaceResourceRequestFilters extends EntityControllerGetEntityModelPageGcpKubernetesResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        updateTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            updateTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageGcpKubernetesNamespaceRoleBindingRequest extends EntityControllerGetEntityModelPageGcpKubernetesNamespaceResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        kubernetesResource: EntityControllerGetEntityModelPageEntityRequestKubernetesResource,
        kubernetesNamespaceResource: EntityControllerGetEntityModelPageEntityRequestKubernetesNamespaceResource,
        public kubernetesRoleBinding: EntityControllerGetEntityModelPageEntityRequestKubernetesRoleBinding) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag,
            kubernetesResource,
            kubernetesNamespaceResource);
    }
}

export class EntityControllerGetEntityModelPageGcpKubernetesNamespaceRoleBindingRequestFilters extends EntityControllerGetEntityModelPageGcpKubernetesNamespaceResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        updateTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            updateTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageGcpKubernetesNamespaceRoleRequest extends EntityControllerGetEntityModelPageGcpKubernetesNamespaceResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        kubernetesResource: EntityControllerGetEntityModelPageEntityRequestKubernetesResource,
        kubernetesNamespaceResource: EntityControllerGetEntityModelPageEntityRequestKubernetesNamespaceResource,
        public kubernetesRole: EntityControllerGetEntityModelPageEntityRequestKubernetesRole) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag,
            kubernetesResource,
            kubernetesNamespaceResource);
    }
}

export class EntityControllerGetEntityModelPageGcpKubernetesNamespaceRoleRequestFilters extends EntityControllerGetEntityModelPageGcpKubernetesNamespaceResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        updateTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            updateTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageGcpKubernetesServiceAccountRequest extends EntityControllerGetEntityModelPageGcpKubernetesNamespaceResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        kubernetesResource: EntityControllerGetEntityModelPageEntityRequestKubernetesResource,
        kubernetesNamespaceResource: EntityControllerGetEntityModelPageEntityRequestKubernetesNamespaceResource) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag,
            kubernetesResource,
            kubernetesNamespaceResource);
    }
}

export class EntityControllerGetEntityModelPageGcpKubernetesServiceAccountRequestFilters extends EntityControllerGetEntityModelPageGcpKubernetesNamespaceResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        updateTimeRangeSelection: Optional<TimeRangeSelection>,
        public originatorWorkloadResourceIdSelection: Optional<PagedItemSelection<string>>,
        public roleIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            updateTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageGcpKubernetesServiceRequest extends EntityControllerGetEntityModelPageGcpKubernetesNamespaceResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        kubernetesResource: EntityControllerGetEntityModelPageEntityRequestKubernetesResource,
        kubernetesNamespaceResource: EntityControllerGetEntityModelPageEntityRequestKubernetesNamespaceResource,
        public kubernetesService: EntityControllerGetEntityModelPageEntityRequestKubernetesService) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag,
            kubernetesResource,
            kubernetesNamespaceResource);
    }
}

export class EntityControllerGetEntityModelPageGcpKubernetesServiceRequestFilters extends EntityControllerGetEntityModelPageGcpKubernetesNamespaceResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        updateTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            updateTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageGcpKubernetesWorkloadResourceRequest extends EntityControllerGetEntityModelPageGcpKubernetesNamespaceResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        kubernetesResource: EntityControllerGetEntityModelPageEntityRequestKubernetesResource,
        kubernetesNamespaceResource: EntityControllerGetEntityModelPageEntityRequestKubernetesNamespaceResource) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag,
            kubernetesResource,
            kubernetesNamespaceResource);
    }
}

export class EntityControllerGetEntityModelPageGcpKubernetesWorkloadResourceRequestFilters extends EntityControllerGetEntityModelPageGcpKubernetesNamespaceResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        updateTimeRangeSelection: Optional<TimeRangeSelection>,
        public containerImageIdSelection: Optional<PagedItemSelection<string>>,
        public containerImageVulnerabilityIdSelection: Optional<PagedItemSelection<string>>,
        public containerImageVulnerabilitySeveritySelection: Optional<ItemSelection<Severity>>,
        public controllerResourceIdSelection: Optional<PagedItemSelection<string>>,
        public gcpServiceAccountIdSelection: Optional<PagedItemSelection<string>>,
        public serviceAccountIdSelection: Optional<PagedItemSelection<string>>,
        public typeNameSelection: Optional<ItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            updateTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageOciIamCredentialRequest extends EntityControllerGetEntityModelPageOciIamResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageOciIamCredentialRequestFilters extends EntityControllerGetEntityModelPageOciIamResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        descriptionSelection: Optional<PagedItemSelection<string>>,
        enabled: Optional<boolean>,
        public userIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            descriptionSelection,
            enabled);
    }
}

export class EntityControllerGetEntityModelPageOciIamDomainRequest extends EntityControllerGetEntityModelPageOciIamResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageOciIamDomainRequestFilters extends EntityControllerGetEntityModelPageOciIamResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        descriptionSelection: Optional<PagedItemSelection<string>>,
        enabled: Optional<boolean>,
        public defaultDomain: Optional<boolean>,
        public licenseTypeSelection: Optional<ItemSelection<OciIamDomainLicenseType>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            descriptionSelection,
            enabled);
    }
}

export class EntityControllerGetEntityModelPageOciIamPolicyRequest extends EntityControllerGetEntityModelPageOciIamResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageOciIamPolicyRequestFilters extends EntityControllerGetEntityModelPageOciIamResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        descriptionSelection: Optional<PagedItemSelection<string>>,
        enabled: Optional<boolean>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            descriptionSelection,
            enabled);
    }
}

export class EntityControllerGetEntityModelPageOciIamPrincipalRequest extends EntityControllerGetEntityModelPageOciIamResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageOciIamPrincipalRequestFilters extends EntityControllerGetEntityModelPageOciIamResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        descriptionSelection: Optional<PagedItemSelection<string>>,
        enabled: Optional<boolean>,
        public domainIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            descriptionSelection,
            enabled);
    }
}

export class EntityControllerGetEntityModelPageOciIamUserApiKeyRequest extends EntityControllerGetEntityModelPageOciIamCredentialRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageOciIamUserApiKeyRequestFilters extends EntityControllerGetEntityModelPageOciIamCredentialRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        descriptionSelection: Optional<PagedItemSelection<string>>,
        enabled: Optional<boolean>,
        userIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            descriptionSelection,
            enabled,
            userIdSelection);
    }
}

export class EntityControllerGetEntityModelPageOciIamUserAuthTokenRequest extends EntityControllerGetEntityModelPageOciIamCredentialRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageOciIamUserAuthTokenRequestFilters extends EntityControllerGetEntityModelPageOciIamCredentialRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        descriptionSelection: Optional<PagedItemSelection<string>>,
        enabled: Optional<boolean>,
        userIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            descriptionSelection,
            enabled,
            userIdSelection);
    }
}

export class EntityControllerGetEntityModelPageOciIamUserDatabasePasswordRequest extends EntityControllerGetEntityModelPageOciIamCredentialRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageOciIamUserDatabasePasswordRequestFilters extends EntityControllerGetEntityModelPageOciIamCredentialRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        descriptionSelection: Optional<PagedItemSelection<string>>,
        enabled: Optional<boolean>,
        userIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            descriptionSelection,
            enabled,
            userIdSelection);
    }
}

export class EntityControllerGetEntityModelPageOciIamUserRequest extends EntityControllerGetEntityModelPageOciIamPrincipalRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageOciIamUserRequestFilters extends EntityControllerGetEntityModelPageOciIamPrincipalRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        descriptionSelection: Optional<PagedItemSelection<string>>,
        enabled: Optional<boolean>,
        domainIdSelection: Optional<PagedItemSelection<string>>,
        public groupIdSelection: Optional<PagedItemSelection<string>>,
        public mailSelection: Optional<PagedItemSelection<string>>,
        public mfaEnrolled: Optional<boolean>,
        public signInTimeRangeSelection: Optional<TimeRangeSelection>,
        public userApiKeyIdSelection: Optional<PagedItemSelection<string>>,
        public userAuthTokenIdSelection: Optional<PagedItemSelection<string>>,
        public userDatabasePasswordIdSelection: Optional<PagedItemSelection<string>>,
        public userSecretKeyIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            descriptionSelection,
            enabled,
            domainIdSelection);
    }
}

export class EntityControllerGetEntityModelPageOciIamUserSecretKeyRequest extends EntityControllerGetEntityModelPageOciIamCredentialRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageOciIamUserSecretKeyRequestFilters extends EntityControllerGetEntityModelPageOciIamCredentialRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        descriptionSelection: Optional<PagedItemSelection<string>>,
        enabled: Optional<boolean>,
        userIdSelection: Optional<PagedItemSelection<string>>,
        public accessKeySelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            descriptionSelection,
            enabled,
            userIdSelection);
    }
}

export class EntityControllerGetEntityModelPageOciKmsVaultKeyRequest extends EntityControllerGetEntityModelPageOciKmsVaultObjectRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageOciKmsVaultKeyRequestFilters extends EntityControllerGetEntityModelPageOciKmsVaultObjectRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        vaultIdSelection: Optional<PagedItemSelection<string>>,
        public algorithmSelection: Optional<ItemSelection<OciKmsVaultKeyAlgorithm>>,
        public protectionModeSelection: Optional<ItemSelection<OciKmsVaultKeyProtectionMode>>,
        public statusSelection: Optional<ItemSelection<OciKmsVaultKeyStatus>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            vaultIdSelection);
    }
}

export class EntityControllerGetEntityModelPageOpDefaultKubernetesResourceRequest extends EntityControllerGetEntityModelPageOpKubernetesResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        kubernetesResource: EntityControllerGetEntityModelPageEntityRequestKubernetesResource,
        public typeName: string) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag,
            kubernetesResource);
    }
}

export class EntityControllerGetEntityModelPageOpDefaultKubernetesResourceRequestFilters extends EntityControllerGetEntityModelPageOpKubernetesResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public typeName: string) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageOpKubernetesClusterRoleBindingRequest extends EntityControllerGetEntityModelPageOpKubernetesResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        kubernetesResource: EntityControllerGetEntityModelPageEntityRequestKubernetesResource,
        public kubernetesRoleBinding: EntityControllerGetEntityModelPageEntityRequestKubernetesRoleBinding) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag,
            kubernetesResource);
    }
}

export class EntityControllerGetEntityModelPageOpKubernetesClusterRoleBindingRequestFilters extends EntityControllerGetEntityModelPageOpKubernetesResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageOpKubernetesClusterRoleRequest extends EntityControllerGetEntityModelPageOpKubernetesResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        kubernetesResource: EntityControllerGetEntityModelPageEntityRequestKubernetesResource,
        public kubernetesRole: EntityControllerGetEntityModelPageEntityRequestKubernetesRole) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag,
            kubernetesResource);
    }
}

export class EntityControllerGetEntityModelPageOpKubernetesClusterRoleRequestFilters extends EntityControllerGetEntityModelPageOpKubernetesResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageOpKubernetesGroupRequest extends EntityControllerGetEntityModelPageOpKubernetesResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        kubernetesResource: EntityControllerGetEntityModelPageEntityRequestKubernetesResource,
        public kubernetesPrincipal: EntityControllerGetEntityModelPageEntityRequestKubernetesPrincipal) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag,
            kubernetesResource);
    }
}

export class EntityControllerGetEntityModelPageOpKubernetesGroupRequestFilters extends EntityControllerGetEntityModelPageOpKubernetesResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageOpKubernetesNamespaceRequest extends EntityControllerGetEntityModelPageOpKubernetesResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        kubernetesResource: EntityControllerGetEntityModelPageEntityRequestKubernetesResource,
        public kubernetesNamespace: EntityControllerGetEntityModelPageEntityRequestKubernetesNamespace) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag,
            kubernetesResource);
    }
}

export class EntityControllerGetEntityModelPageOpKubernetesNamespaceRequestFilters extends EntityControllerGetEntityModelPageOpKubernetesResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageOpKubernetesNamespaceResourceRequest extends EntityControllerGetEntityModelPageOpKubernetesResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        kubernetesResource: EntityControllerGetEntityModelPageEntityRequestKubernetesResource,
        public kubernetesNamespaceResource: EntityControllerGetEntityModelPageEntityRequestKubernetesNamespaceResource) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag,
            kubernetesResource);
    }
}

export class EntityControllerGetEntityModelPageOpKubernetesNamespaceResourceRequestFilters extends EntityControllerGetEntityModelPageOpKubernetesResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageOpKubernetesNamespaceRoleBindingRequest extends EntityControllerGetEntityModelPageOpKubernetesNamespaceResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        kubernetesResource: EntityControllerGetEntityModelPageEntityRequestKubernetesResource,
        kubernetesNamespaceResource: EntityControllerGetEntityModelPageEntityRequestKubernetesNamespaceResource,
        public kubernetesRoleBinding: EntityControllerGetEntityModelPageEntityRequestKubernetesRoleBinding) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag,
            kubernetesResource,
            kubernetesNamespaceResource);
    }
}

export class EntityControllerGetEntityModelPageOpKubernetesNamespaceRoleBindingRequestFilters extends EntityControllerGetEntityModelPageOpKubernetesNamespaceResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageOpKubernetesNamespaceRoleRequest extends EntityControllerGetEntityModelPageOpKubernetesNamespaceResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        kubernetesResource: EntityControllerGetEntityModelPageEntityRequestKubernetesResource,
        kubernetesNamespaceResource: EntityControllerGetEntityModelPageEntityRequestKubernetesNamespaceResource,
        public kubernetesRole: EntityControllerGetEntityModelPageEntityRequestKubernetesRole) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag,
            kubernetesResource,
            kubernetesNamespaceResource);
    }
}

export class EntityControllerGetEntityModelPageOpKubernetesNamespaceRoleRequestFilters extends EntityControllerGetEntityModelPageOpKubernetesNamespaceResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageOpKubernetesServiceAccountRequest extends EntityControllerGetEntityModelPageOpKubernetesNamespaceResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        kubernetesResource: EntityControllerGetEntityModelPageEntityRequestKubernetesResource,
        kubernetesNamespaceResource: EntityControllerGetEntityModelPageEntityRequestKubernetesNamespaceResource) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag,
            kubernetesResource,
            kubernetesNamespaceResource);
    }
}

export class EntityControllerGetEntityModelPageOpKubernetesServiceAccountRequestFilters extends EntityControllerGetEntityModelPageOpKubernetesNamespaceResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public originatorWorkloadResourceIdSelection: Optional<PagedItemSelection<string>>,
        public roleIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageOpKubernetesServiceRequest extends EntityControllerGetEntityModelPageOpKubernetesNamespaceResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        kubernetesResource: EntityControllerGetEntityModelPageEntityRequestKubernetesResource,
        kubernetesNamespaceResource: EntityControllerGetEntityModelPageEntityRequestKubernetesNamespaceResource,
        public kubernetesService: EntityControllerGetEntityModelPageEntityRequestKubernetesService) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag,
            kubernetesResource,
            kubernetesNamespaceResource);
    }
}

export class EntityControllerGetEntityModelPageOpKubernetesServiceRequestFilters extends EntityControllerGetEntityModelPageOpKubernetesNamespaceResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageOpKubernetesWorkloadResourceRequest extends EntityControllerGetEntityModelPageOpKubernetesNamespaceResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        kubernetesResource: EntityControllerGetEntityModelPageEntityRequestKubernetesResource,
        kubernetesNamespaceResource: EntityControllerGetEntityModelPageEntityRequestKubernetesNamespaceResource) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag,
            kubernetesResource,
            kubernetesNamespaceResource);
    }
}

export class EntityControllerGetEntityModelPageOpKubernetesWorkloadResourceRequestFilters extends EntityControllerGetEntityModelPageOpKubernetesNamespaceResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public containerImageIdSelection: Optional<PagedItemSelection<string>>,
        public containerImageVulnerabilityIdSelection: Optional<PagedItemSelection<string>>,
        public containerImageVulnerabilitySeveritySelection: Optional<ItemSelection<Severity>>,
        public controllerResourceIdSelection: Optional<PagedItemSelection<string>>,
        public serviceAccountIdSelection: Optional<PagedItemSelection<string>>,
        public typeNameSelection: Optional<ItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityPrincipalReferencePropertyMatchCondition extends EntityPropertyMatchCondition<string> implements ITypeNameObject {
    constructor(
        identifier: EntityPropertyIdentifier,
        operator: EntityMatchConditionCollectionOperator,
        values: string[]) {
        super(
            "EntityPrincipalReferencePropertyMatchCondition",
            identifier,
            operator,
            values);
    }
}

export class EntitySearchableEventReference extends EntitySearchableReferenceBase<string> {
    constructor(
        displayName: string,
        public eventReference: string) {
        super(displayName);
    }
}

export class EntitySearchableIdReference extends EntitySearchableReferenceBase<string> {
    constructor(
        displayName: string,
        public idReference: string) {
        super(displayName);
    }
}

export class EntitySearchableReference extends EntitySearchableReferenceBase<string> {
    constructor(
        displayName: string,
        public id: string) {
        super(displayName);
    }
}

export class EventAnalysisServiceConfiguration extends ServiceConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "EventAnalysisServiceConfiguration");
    }
}

export class GciDirectoryDirectoryRoleAssignmentModel extends GciDirectoryEntityModel implements ITypeNameObject, IEntityModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GciEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GciDirectoryDirectoryRoleAssignmentModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown);
    }
}

export class GciDirectoryDirectoryRoleEntity extends GciDirectoryEntity implements ITypeNameObject, IDirectoryEntity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        creationTime: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            creationTime);
    }
}

export class GciDirectoryDirectoryRoleModel extends GciDirectoryEntityModel implements ITypeNameObject, IEntityModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GciEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        public assignedIdentityIdReferences: string[],
        public directoryRoleAssignmentIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GciDirectoryDirectoryRoleModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown);
    }
}

export class GciDirectoryGroup extends GciDirectoryPrincipal implements ITypeNameObject, IGciDirectoryPrincipal, IDirectoryGroup, IGciGroup, IPermissionManagementGroup {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        creationTime: Optional<string>,
        mail: string,
        mailAliases: string[],
        uniqueId: string,
        public description: string,
        public memberPrincipalRoleToIdsMap: Dictionary<string[]>,
        public types: GciDirectoryGroupType[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GciDirectoryGroup",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            creationTime,
            mail,
            mailAliases,
            uniqueId);
    }
}

export class GciDirectoryGroupInactiveRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GciDirectoryGroupInactiveRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GciDirectoryGroupMembershipRequest extends GroupMembershipRequest implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        closeReason: Optional<PermissionRequestCloseReason>,
        expirationTime: Optional<string>,
        expirationTimeFrame: string,
        granteeUserTenantId: string,
        permissionEligibilityId: string,
        reason: Optional<string>,
        startTime: Optional<string>,
        status: PermissionRequestStatus,
        statusUpdateTime: string,
        assignedGroupIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GciDirectoryGroupMembershipRequest",
            scopeId,
            closeReason,
            expirationTime,
            expirationTimeFrame,
            granteeUserTenantId,
            permissionEligibilityId,
            reason,
            startTime,
            status,
            statusUpdateTime,
            assignedGroupIds);
    }
}

export class GciDirectoryGroupModel extends GciDirectoryPrincipalModel implements ITypeNameObject, IUdmObjectModel, IGciPrincipalModel, IGroupModel, IPermissionManagementGroupModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GciEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        public access: Optional<GroupModelAccess>,
        analyzing: Optional<GciPrincipalModelAnalyzing>,
        containedGcpRoleBindingIds: string[],
        gcpRoleBindingIds: string[],
        groupIds: string[],
        inactive: boolean,
        public memberPrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GciDirectoryGroupModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            access,
            analyzing,
            containedGcpRoleBindingIds,
            gcpRoleBindingIds,
            groupIds,
            inactive);
    }
}

export class GciDirectoryPartialGroup extends GciDirectoryPartialPrincipal implements ITypeNameObject, IGciDirectoryPrincipal, IGciPartialPrincipal, IGciGroup {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        mail: string,
        uniqueId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GciDirectoryPartialGroup",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            mail,
            uniqueId);
    }
}

export class GciDirectoryPartialGroupModel extends GciDirectoryPartialPrincipalModel implements ITypeNameObject, IUdmObjectModel, IRelatedTenantsEntityModel, IGciPrincipalModel, IGroupModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GciEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        containedGcpRoleBindingIds: string[],
        gcpRoleBindingIds: string[],
        public access: Optional<GroupModelAccess>,
        analyzing: Optional<GciPrincipalModelAnalyzing>,
        groupIds: string[],
        inactive: boolean,
        public memberPrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GciDirectoryPartialGroupModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            containedGcpRoleBindingIds,
            gcpRoleBindingIds,
            access,
            analyzing,
            groupIds,
            inactive);
    }
}

export class GciDirectoryUserInactiveRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        public includeDisabledUser: Optional<boolean>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GciDirectoryUserInactiveRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GciEventAnalysisServiceConfiguration extends ServiceConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        public gciDirectoryUserEventSyncer: GciDirectoryUserEventSyncerConfigurationSection) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GciEventAnalysisServiceConfiguration");
    }
}

export class GcpAccessApprovalSettingsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpAccessApprovalSettingsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpAccessContextManagerAccessLevelsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpAccessContextManagerAccessLevelsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpAccessContextManagerAccessPoliciesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpAccessContextManagerAccessPoliciesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpAccessContextManagerAuthorizedOrgsDescsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpAccessContextManagerAuthorizedOrgsDescsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpAccessContextManagerServicePerimetersGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpAccessContextManagerServicePerimetersGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpAiPlatformBatchPredictionJobsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpAiPlatformBatchPredictionJobsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpAiPlatformCustomJobsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpAiPlatformCustomJobsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpAiPlatformDataLabelingJobsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpAiPlatformDataLabelingJobsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpAiPlatformDatasetsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpAiPlatformDatasetsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpAiPlatformEndpointsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpAiPlatformEndpointsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpAiPlatformFeatureGroupsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpAiPlatformFeatureGroupsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpAiPlatformFeatureOnlineStoresGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpAiPlatformFeatureOnlineStoresGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpAiPlatformFeaturestoresGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpAiPlatformFeaturestoresGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpAiPlatformHyperparameterTuningJobsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpAiPlatformHyperparameterTuningJobsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpAiPlatformIndexEndpointsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpAiPlatformIndexEndpointsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpAiPlatformIndicesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpAiPlatformIndicesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpAiPlatformMetadataStoresGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpAiPlatformMetadataStoresGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpAiPlatformModelDeploymentMonitoringJobsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpAiPlatformModelDeploymentMonitoringJobsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpAiPlatformModelsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpAiPlatformModelsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpAiPlatformNasJobsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpAiPlatformNasJobsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpAiPlatformNotebookExecutionJobsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpAiPlatformNotebookExecutionJobsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpAiPlatformNotebookRuntimesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpAiPlatformNotebookRuntimesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpAiPlatformNotebookRuntimeTemplatesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpAiPlatformNotebookRuntimeTemplatesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpAiPlatformPipelineJobsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpAiPlatformPipelineJobsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpAiPlatformSpecialistPoolsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpAiPlatformSpecialistPoolsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpAiPlatformTensorboardsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpAiPlatformTensorboardsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpAiPlatformTrainingPipelinesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpAiPlatformTrainingPipelinesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpAiPlatformTuningJobsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpAiPlatformTuningJobsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpAlloydbBackupsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpAlloydbBackupsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpAlloydbClustersGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpAlloydbClustersGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpAlloydbInstancesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpAlloydbInstancesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpApiGatewayApiconfigsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpApiGatewayApiconfigsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpApiGatewayApisGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpApiGatewayApisGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpApiGatewayGatewaysGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource, IGcpTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpApiGatewayGatewaysGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpApiGatewayGatewaysGenericResourceModel extends GcpAssetGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpApiGatewayGatewaysGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds);
    }
}

export class GcpApigeeInstancesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpApigeeInstancesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpApigeeOrganizationsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpApigeeOrganizationsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpApihubApiHubInstancesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpApihubApiHubInstancesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpApiKeysKeyClientRestrictionNotExistsRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpApiKeysKeyClientRestrictionNotExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpApiKeysKeyExistsRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpApiKeysKeyExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpApiKeysKeyNotRotatedRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        public keyRotationTimeFrame: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpApiKeysKeyNotRotatedRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpApiKeysKeyServiceRestrictionNotExistsRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpApiKeysKeyServiceRestrictionNotExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpApikeysKeysGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpApikeysKeysGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpAppEngineApplicationServiceUnencryptedTransportExistsRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpAppEngineApplicationServiceUnencryptedTransportExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpAppEngineApplicationsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpAppEngineApplicationsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpAppEngineServicesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpAppEngineServicesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpAppEngineVersionsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpAppEngineVersionsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpApphubApplicationsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpApphubApplicationsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpApphubServiceProjectAttachmentsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpApphubServiceProjectAttachmentsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpApphubServicesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpApphubServicesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpApphubWorkloadGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpApphubWorkloadGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpArtifactDockerimagesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpArtifactDockerimagesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpArtifactMavenartifactsGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpArtifactMavenartifactsGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpArtifactNpmpackagesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpArtifactNpmpackagesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpArtifactPythonpackagesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpArtifactPythonpackagesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpArtifactRegistryPublicAccessExistsRiskModel extends GcpScopeResourcePublicAccessExistsRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpArtifactRegistryPublicAccessExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpArtifactRegistryPublicAccessExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpArtifactRegistryPublicAccessExistsRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpArtifactRegistryPublicAccessExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpArtifactRepositoriesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpArtifactRepositoriesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpArtifactRulesGenericResource extends GcpAssetGenericResource implements ITypeNameObject, IResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        parentResourceAssetPath: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpArtifactRulesGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            parentResourceAssetPath);
    }
}

export class GcpBigQueryDataset extends GcpBigQueryResource implements ITypeNameObject, IDataAnalysisResource, IGcpEncryptedResource, IGcpTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        description: string,
        public defaultTableExpirationTimeFrame: Optional<string>,
        public defaultTableKmsEncryptionKeyAssetPath: Optional<string>,
        public encryptionExists: boolean,
        public hidden: boolean,
        public kmsEncryptionKeyAssetPaths: string[],
        public storageSize: number,
        public tableCount: number) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpBigQueryDataset",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            description);
    }
}

export class GcpBigQueryDatasetEncryptionDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpBigQueryDatasetEncryptionDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpBigQueryDatasetPublicAccessExistsRiskModel extends GcpScopeResourcePublicAccessExistsRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpBigQueryDatasetPublicAccessExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpBigQueryDatasetPublicAccessExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpBigQueryDatasetPublicAccessExistsRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpBigQueryDatasetPublicAccessExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpBigQueryDatasetTable extends GcpBigQueryResource implements ITypeNameObject, IResource, IGcpEncryptedResource, IGcpTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        description: string,
        public datasetId: string,
        public datasetRawShortId: string,
        public encryptionExists: boolean,
        public expirationTime: Optional<string>,
        public kmsEncryptionKeyAssetPaths: string[],
        public size: number) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpBigQueryDatasetTable",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            description);
    }
}

export class GcpBigQueryDatasetTableEncryptionDisabledRisk extends AggregatedEntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        aggregatedEntityIds: string[],
        aggregatingEntityTypeName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpBigQueryDatasetTableEncryptionDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            aggregatedEntityIds,
            aggregatingEntityTypeName);
    }
}

export class GcpBigQueryDatasetTableEncryptionDisabledRiskModel extends AggregatedEntityRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpBigQueryDatasetTableEncryptionDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public managedEncryptionDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpBigQueryDatasetTableEncryptionDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpBigQueryDatasetTableEncryptionDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpBigQueryDatasetTableEncryptionDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpCloudBuildBuildTriggerDefaultServiceAccountRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpCloudBuildBuildTriggerDefaultServiceAccountRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpCloudRunServicePublicAccessExistsRiskModel extends GcpScopeResourcePublicAccessExistsRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpCloudRunServicePublicAccessExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpCloudRunServicePublicAccessExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpCloudRunServicePublicAccessExistsRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpCloudRunServicePublicAccessExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpComputeAutoscaler extends GcpComputeResource implements ITypeNameObject, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        url: string,
        public maxCapacity: number,
        public minCapacity: number) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeAutoscaler",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            url);
    }
}

export class GcpComputeBackendBucketModel extends GcpComputeLoadBalancerResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[],
        loadBalancerIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeBackendBucketModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds,
            loadBalancerIds);
    }
}

export class GcpComputeBackendServiceLoggingNotEnabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeBackendServiceLoggingNotEnabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpComputeBackendServiceModel extends GcpComputeLoadBalancerResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[],
        loadBalancerIds: string[],
        public backendResourceIds: string[],
        public forwardingRuleIds: string[],
        public targetProxyIds: string[],
        public urlMapIds: string[],
        public vpcIdReference: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeBackendServiceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds,
            loadBalancerIds);
    }
}

export class GcpComputeDisk extends GcpComputeResource implements ITypeNameObject, IResource, IGcpEncryptedResource, IGcpTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        url: string,
        public encryptionExists: boolean,
        public kmsEncryptionKeyAssetPaths: string[],
        public size: number,
        public status: GcpComputeDiskStatus,
        public type: GcpComputeDiskType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeDisk",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            url);
    }
}

export class GcpComputeFirewallPolicy extends GcpComputeResource implements ITypeNameObject, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        url: string,
        public attachedNonTenantResourceManagerResourceRawIds: Optional<string[]>,
        public attachedVpcUrls: Optional<string[]>,
        public rules: GcpComputeFirewallPolicyRule[],
        public scope: GcpComputeFirewallPolicyScope) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeFirewallPolicy",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            url);
    }
}

export class GcpComputeForwardingRuleModel extends GcpComputeLoadBalancerResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[],
        loadBalancerIds: string[],
        public dnsZoneIds: string[],
        public targetIdReference: string,
        public vpcIdReference: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeForwardingRuleModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds,
            loadBalancerIds);
    }
}

export class GcpComputeImage extends GcpComputeResource implements ITypeNameObject, IResource, IGcpEncryptedResource, IGcpTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        url: string,
        public encryptionExists: boolean,
        public familyTag: Optional<string>,
        public kmsEncryptionKeyAssetPaths: string[],
        public size: number,
        public status: GcpComputeImageStatus,
        public storageSize: number,
        public type: GcpComputeImageType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeImage",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            url);
    }
}

export class GcpComputeImagePublicAccessExistsRiskModel extends GcpScopeResourcePublicAccessExistsRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpComputeImagePublicAccessExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeImagePublicAccessExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpComputeImagePublicAccessExistsRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeImagePublicAccessExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpComputeInstance extends GcpComputeResource implements ITypeNameObject, IGcpDnsRecordEntity, IGcpNetworkedResource, IGcpNetworkEvaluationEntity, IGcpServiceAccountOriginatorScopeResource, IGcpTagResource, IVirtualMachine {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        url: string,
        public bootIntegrityValidationEnabled: boolean,
        public computeProjectSshKeysBlockEnabled: boolean,
        public dnsNames: Optional<string[]>,
        public instanceTemplateAssetPath: Optional<string>,
        public ipForwardingEnabled: Optional<boolean>,
        public memoryEncryptionEnabled: boolean,
        public metadataKeyToValueMap: Dictionary<string>,
        public networkTags: string[],
        public operatingSystemType: Optional<OperatingSystemType>,
        public privateIpAddresses: string[],
        public publicIpAddresses: string[],
        public rawType: string,
        public secureBootEnabled: boolean,
        public serviceAccountData: GcpServiceAccountOriginatorServiceAccountData,
        public sshIamEnabled: Optional<boolean>,
        public sshSerialPortEnabled: Optional<boolean>,
        public startupScriptNameToValueMap: Dictionary<string>,
        public status: GcpComputeInstanceStatus,
        public stopTime: Optional<string>,
        public virtualTpmEnabled: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeInstance",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            url);
    }
}

export class GcpComputeInstanceAnalysisServiceConfiguration extends ServiceConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeInstanceAnalysisServiceConfiguration");
    }
}

export class GcpComputeInstanceBlockProjectWideSshKeysNotEnabledRisk extends AggregatedEntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        aggregatedEntityIds: string[],
        aggregatingEntityTypeName: string,
        public instanceGroupIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeInstanceBlockProjectWideSshKeysNotEnabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            aggregatedEntityIds,
            aggregatingEntityTypeName);
    }
}

export class GcpComputeInstanceBlockProjectWideSshKeysNotEnabledRiskModel extends AggregatedEntityRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpComputeInstanceBlockProjectWideSshKeysNotEnabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeInstanceBlockProjectWideSshKeysNotEnabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpComputeInstanceBlockProjectWideSshKeysNotEnabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeInstanceBlockProjectWideSshKeysNotEnabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpComputeInstanceDefaultServiceAccountRisk extends AggregatedEntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        aggregatedEntityIds: string[],
        aggregatingEntityTypeName: string,
        public instanceGroupIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeInstanceDefaultServiceAccountRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            aggregatedEntityIds,
            aggregatingEntityTypeName);
    }
}

export class GcpComputeInstanceDefaultServiceAccountRiskModel extends AggregatedEntityRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpComputeInstanceDefaultServiceAccountRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeInstanceDefaultServiceAccountRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpComputeInstanceDefaultServiceAccountRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeInstanceDefaultServiceAccountRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpComputeInstanceGroup extends GcpComputeResource implements ITypeNameObject, IGcpNetworkedResource, IGcpNetworkEvaluationEntity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        url: string,
        public instanceUrls: string[],
        public portNameToRangesMap: Dictionary<IntRange[]>,
        public subnetUrl: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeInstanceGroup",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            url);
    }
}

export class GcpComputeInstanceGroupManager extends GcpComputeResource implements ITypeNameObject, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        url: string,
        public autoscalerUrl: Optional<string>,
        public instanceGroupUrl: string,
        public instanceTemplateUrl: Optional<string>,
        public status: GcpComputeInstanceGroupManagerStatus) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeInstanceGroupManager",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            url);
    }
}

export class GcpComputeInstanceIpForwardingEnabledRisk extends AggregatedEntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        aggregatedEntityIds: string[],
        aggregatingEntityTypeName: string,
        public instanceGroupIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeInstanceIpForwardingEnabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            aggregatedEntityIds,
            aggregatingEntityTypeName);
    }
}

export class GcpComputeInstanceIpForwardingEnabledRiskModel extends AggregatedEntityRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpComputeInstanceIpForwardingEnabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeInstanceIpForwardingEnabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpComputeInstanceIpForwardingEnabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeInstanceIpForwardingEnabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpComputeInstanceMemoryEncryptionNotEnabledRisk extends AggregatedEntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        aggregatedEntityIds: string[],
        aggregatingEntityTypeName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeInstanceMemoryEncryptionNotEnabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            aggregatedEntityIds,
            aggregatingEntityTypeName);
    }
}

export class GcpComputeInstanceMemoryEncryptionNotEnabledRiskModel extends AggregatedEntityRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpComputeInstanceMemoryEncryptionNotEnabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeInstanceMemoryEncryptionNotEnabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpComputeInstanceMemoryEncryptionNotEnabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeInstanceMemoryEncryptionNotEnabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpComputeInstanceShieldedNotEnabledRisk extends AggregatedEntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        aggregatedEntityIds: string[],
        aggregatingEntityTypeName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeInstanceShieldedNotEnabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            aggregatedEntityIds,
            aggregatingEntityTypeName);
    }
}

export class GcpComputeInstanceShieldedNotEnabledRiskModel extends AggregatedEntityRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpComputeInstanceShieldedNotEnabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeInstanceShieldedNotEnabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpComputeInstanceShieldedNotEnabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeInstanceShieldedNotEnabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpComputeInstanceSshSerialPortEnabledRisk extends AggregatedEntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        aggregatedEntityIds: string[],
        aggregatingEntityTypeName: string,
        public instanceGroupIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeInstanceSshSerialPortEnabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            aggregatedEntityIds,
            aggregatingEntityTypeName);
    }
}

export class GcpComputeInstanceSshSerialPortEnabledRiskModel extends AggregatedEntityRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpComputeInstanceSshSerialPortEnabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeInstanceSshSerialPortEnabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpComputeInstanceSshSerialPortEnabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeInstanceSshSerialPortEnabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpComputeInstanceStartupScriptSecretExistsRisk extends AggregatedEntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        aggregatedEntityIds: string[],
        aggregatingEntityTypeName: string,
        public excludedSecrets: string[],
        public instanceGroupIds: string[],
        public readActions: string[],
        public readResourcePermissionActionPrincipalIds: string[],
        public riskedEntityTypeName: string,
        public secrets: string[],
        public startupScriptNameToSecretExistsTextBlockMap: Dictionary<TextBlock>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeInstanceStartupScriptSecretExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            aggregatedEntityIds,
            aggregatingEntityTypeName);
    }
}

export class GcpComputeInstanceStartupScriptSecretExistsRiskModel extends AggregatedEntityRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel, ISecretExistsRiskModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpComputeInstanceStartupScriptSecretExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public secretCount: number,
        public secretManagerDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeInstanceStartupScriptSecretExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpComputeInstanceTemplate extends GcpComputeResource implements ITypeNameObject, IGcpNetworkedResource, IGcpServiceAccountOriginatorScopeResource, IGcpTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        url: string,
        public bootIntegrityValidationEnabled: boolean,
        public computeProjectSshKeysBlockEnabled: boolean,
        public description: string,
        public ipForwardingEnabled: Optional<boolean>,
        public machineType: string,
        public memoryEncryptionEnabled: boolean,
        public metadataKeyToValueMap: Dictionary<string>,
        public networkTags: string[],
        public serviceAccountData: GcpServiceAccountOriginatorServiceAccountData,
        public sshIamEnabled: Optional<boolean>,
        public sshSerialPortEnabled: Optional<boolean>,
        public startupScriptNameToValueMap: Dictionary<string>,
        public virtualTpmEnabled: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeInstanceTemplate",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            url);
    }
}

export class GcpComputeLoadBalancerInsecureSslPolicyRisk extends AggregatedEntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        aggregatedEntityIds: string[],
        aggregatingEntityTypeName: string,
        public weakCipherFeatures: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeLoadBalancerInsecureSslPolicyRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            aggregatedEntityIds,
            aggregatingEntityTypeName);
    }
}

export class GcpComputeLoadBalancerInsecureSslPolicyRiskModel extends AggregatedEntityRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpComputeLoadBalancerInsecureSslPolicyRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeLoadBalancerInsecureSslPolicyRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpComputeLoadBalancerInsecureSslPolicyRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeLoadBalancerInsecureSslPolicyRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpComputeLoadBalancerResource extends GcpComputeResource implements ITypeNameObject, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        url: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            url);
    }
}

export class GcpComputeNetworkEndpointGroup extends GcpComputeResource implements ITypeNameObject, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        url: string,
        public defaultEndpointPort: Optional<number>,
        public endpoints: Optional<GcpComputeNetworkEndpointGroupEndpoint[]>,
        public serverlessResourceAssetPath: Optional<string>,
        public type: GcpComputeNetworkEndpointGroupType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeNetworkEndpointGroup",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            url);
    }
}

export class GcpComputeNetworkResource extends GcpComputeResource implements ITypeNameObject, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        url: string,
        public vpcUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            url);
    }
}

export class GcpComputeProject extends GcpComputeResource implements ITypeNameObject, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        url: string,
        public defaultServiceAccountMail: string,
        public instanceDefaultMetadataKeyToValueMap: Dictionary<string>,
        public instanceDefaultSshIamEnabled: boolean,
        public instanceDefaultSshSerialPortEnabled: boolean,
        public sshKeys: string[],
        public startupScriptNameToValueMap: Dictionary<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeProject",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            url);
    }
}

export class GcpComputeProjectSshIamNotEnabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeProjectSshIamNotEnabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpComputeResourceStateNetwork extends GcpNetworkAccessResourceStateNetwork implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        entityReference: EntityReference,
        inboundAccessType: NetworkInboundAccessType,
        inboundExternal: boolean,
        inboundExternalAccessScope: NetworkAccessScope,
        inboundExternalDestinationNetworkScopes: DestinationNetworkScope[],
        inboundExternalWideRange: Optional<boolean>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeResourceStateNetwork",
            tenantId,
            entityReference,
            inboundAccessType,
            inboundExternal,
            inboundExternalAccessScope,
            inboundExternalDestinationNetworkScopes,
            inboundExternalWideRange);
    }
}

export class GcpComputeRoute extends GcpComputeNetworkResource implements ITypeNameObject, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        url: string,
        vpcUrl: string,
        public description: string,
        public destinationSubnet: string,
        public networkTags: string[],
        public nextHop: GcpComputeRouteNextHop,
        public priority: number) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeRoute",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            url,
            vpcUrl);
    }
}

export class GcpComputeSslCertificate extends GcpComputeLoadBalancerResource implements ITypeNameObject, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        url: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeSslCertificate",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            url);
    }
}

export class GcpComputeSslPolicy extends GcpComputeLoadBalancerResource implements ITypeNameObject, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        url: string,
        public customFeatures: Optional<string[]>,
        public description: Optional<string>,
        public featureProfile: GcpComputeSslPolicyFeatureProfile,
        public tlsMinVersion: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeSslPolicy",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            url);
    }
}

export class GcpComputeSubnet extends GcpComputeNetworkResource implements ITypeNameObject, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        url: string,
        vpcUrl: string,
        public flowLogs: Optional<GcpComputeSubnetFlowLogs>,
        public flowLogsEnabled: boolean,
        public gatewayIpAddress: string,
        public subnets: string[],
        public type: GcpComputeSubnetType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeSubnet",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            url,
            vpcUrl);
    }
}

export class GcpComputeTargetHttpProxyModel extends GcpComputeTargetProxyModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[],
        loadBalancerIds: string[],
        forwardingRuleIds: string[],
        forwardingRuleSearchableReferences: EntitySearchableReference[],
        targetResourceIdReference: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeTargetHttpProxyModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds,
            loadBalancerIds,
            forwardingRuleIds,
            forwardingRuleSearchableReferences,
            targetResourceIdReference);
    }
}

export class GcpComputeTargetHttpsProxyModel extends GcpComputeTargetProxyModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[],
        loadBalancerIds: string[],
        forwardingRuleIds: string[],
        forwardingRuleSearchableReferences: EntitySearchableReference[],
        targetResourceIdReference: string,
        public sslCertificateIds: string[],
        public sslPolicyId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeTargetHttpsProxyModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds,
            loadBalancerIds,
            forwardingRuleIds,
            forwardingRuleSearchableReferences,
            targetResourceIdReference);
    }
}

export class GcpComputeTargetPool extends GcpComputeLoadBalancerResource implements ITypeNameObject, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        url: string,
        public instanceUrls: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeTargetPool",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            url);
    }
}

export class GcpComputeTargetProxy extends GcpComputeLoadBalancerResource implements ITypeNameObject, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        url: string,
        public targetResourceUrl: string,
        public type: GcpComputeTargetProxyType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            url);
    }
}

export class GcpComputeTargetSslProxy extends GcpComputeTargetProxy implements ITypeNameObject, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        url: string,
        targetResourceUrl: string,
        type: GcpComputeTargetProxyType,
        public sslCertificateUrls: string[],
        public sslPolicyUrl: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeTargetSslProxy",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            url,
            targetResourceUrl,
            type);
    }
}

export class GcpComputeTargetTcpProxy extends GcpComputeTargetProxy implements ITypeNameObject, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        url: string,
        targetResourceUrl: string,
        type: GcpComputeTargetProxyType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeTargetTcpProxy",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            url,
            targetResourceUrl,
            type);
    }
}

export class GcpComputeUrlMap extends GcpComputeLoadBalancerResource implements ITypeNameObject, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        url: string,
        public defaultBackend: GcpComputeUrlMapBackend,
        public rules: GcpComputeUrlMapRule[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeUrlMap",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            url);
    }
}

export class GcpComputeVpcFlowLogsNotEnabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeVpcFlowLogsNotEnabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpComputeVpcLegacyVpcRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeVpcLegacyVpcRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpComputeVpcUnrestrictedIcmpInboundAccessRisk extends GcpComputeVpcUnrestrictedInboundAccessRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        firewallRuleIdToUnrestrictedInboundAccessMap: Dictionary<boolean>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeVpcUnrestrictedIcmpInboundAccessRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            firewallRuleIdToUnrestrictedInboundAccessMap);
    }
}

export class GcpComputeVpcUnrestrictedIcmpInboundAccessRiskModel extends GcpComputeVpcUnrestrictedInboundAccessRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpComputeVpcUnrestrictedInboundAccessRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        protocolName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeVpcUnrestrictedIcmpInboundAccessRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            protocolName);
    }
}

export class GcpComputeVpcUnrestrictedIcmpInboundAccessRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeVpcUnrestrictedIcmpInboundAccessRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpComputeVpcUnrestrictedRdpInboundAccessRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeVpcUnrestrictedRdpInboundAccessRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpComputeVpcUnrestrictedSshInboundAccessRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeVpcUnrestrictedSshInboundAccessRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpContainerCluster extends GcpContainerResource implements ITypeNameObject, IGcpEncryptedResource, IGcpNetworkedResource, IGcpServiceAccountOriginatorScopeResource, IGcpTagResource, IManagedKubernetesCluster {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        public attributeBasedAccessControl: boolean,
        public encryptionExists: boolean,
        public instanceGroupManagerUrls: string[],
        public kmsEncryptionKeyAssetPath: Optional<string>,
        public kmsEncryptionKeyAssetPaths: string[],
        public mode: GcpContainerClusterMode,
        public network: GcpContainerClusterNetwork,
        public platform: Optional<KubernetesClusterPlatform>,
        public platformVersion: string,
        public serviceAccountData: GcpServiceAccountOriginatorServiceAccountData,
        public shieldedNodes: boolean,
        public status: GcpContainerClusterStatus,
        public url: Optional<string>,
        public version: string,
        public workloadIdentity: boolean,
        public workloadIdentityPoolRawId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpContainerCluster",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId);
    }
}

export class GcpContainerClusterAttributeBasedAuthorizationEnabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpContainerClusterAttributeBasedAuthorizationEnabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpContainerClusterDefaultServiceAccountRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpContainerClusterDefaultServiceAccountRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpContainerClusterInboundExternalWideRangeRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpContainerClusterInboundExternalWideRangeRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpContainerClusterInstanceInboundExternalRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpContainerClusterInstanceInboundExternalRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpContainerClusterKubernetesSecretEncryptionDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpContainerClusterKubernetesSecretEncryptionDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpContainerClusterLegacyComputeMetadataServerEnabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpContainerClusterLegacyComputeMetadataServerEnabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpContainerClusterNodePool extends GcpContainerResource implements ITypeNameObject, IGcpServiceAccountOriginatorScopeResource, IGcpTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        public clusterId: string,
        public instanceGroupManagerUrls: string[],
        public node: GcpContainerClusterNodePoolNode,
        public nodeAutoscaling: Optional<GcpContainerClusterNodePoolNodeAutoscaling>,
        public podSubnet: Optional<string>,
        public serviceAccountData: GcpServiceAccountOriginatorServiceAccountData,
        public status: GcpContainerClusterNodePoolStatus) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpContainerClusterNodePool",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId);
    }
}

export class GcpContainerClusterWorkloadIdentityDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpContainerClusterWorkloadIdentityDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpCreateRoleBindingChange extends GcpResourceChange implements ITypeNameObject, ICloudProviderResourceChange {
    constructor(
        entityIds: string[],
        id: string,
        resolved: boolean,
        resourceId: string,
        public principalId: string,
        public scopeResourceId: string) {
        super(
            "GcpCreateRoleBindingChange",
            entityIds,
            id,
            resolved,
            resourceId);
    }
}

export class GcpDeleteRoleBindingChange extends GcpResourceChange implements ITypeNameObject, ICloudProviderResourceChange, ICodeInfrastructureChange {
    constructor(
        entityIds: string[],
        id: string,
        resolved: boolean,
        resourceId: string,
        public scopeResourceId: string) {
        super(
            "GcpDeleteRoleBindingChange",
            entityIds,
            id,
            resolved,
            resourceId);
    }
}

export class GcpDeleteScopeResourcePrincipalRoleBindingsChange extends GcpResourceChange implements ITypeNameObject, ICloudProviderResourceChange, ICodeInfrastructureChange {
    constructor(
        entityIds: string[],
        id: string,
        resolved: boolean,
        resourceId: string,
        public principalId: string) {
        super(
            "GcpDeleteScopeResourcePrincipalRoleBindingsChange",
            entityIds,
            id,
            resolved,
            resourceId);
    }
}

export class GcpDeleteServiceAccountChange extends GcpResourceChange implements ITypeNameObject, ICloudProviderResourceChange, ICodeInfrastructureChange {
    constructor(
        entityIds: string[],
        id: string,
        resolved: boolean,
        resourceId: string) {
        super(
            "GcpDeleteServiceAccountChange",
            entityIds,
            id,
            resolved,
            resourceId);
    }
}

export class GcpDisableServiceAccountChange extends GcpResourceChange implements ITypeNameObject, ICloudProviderResourceChange, ICodeInfrastructureChange {
    constructor(
        entityIds: string[],
        id: string,
        resolved: boolean,
        resourceId: string,
        public deleteServiceAccount: boolean) {
        super(
            "GcpDisableServiceAccountChange",
            entityIds,
            id,
            resolved,
            resourceId);
    }
}

export class GcpDnsManagedZoneDnsSecNotEnabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpDnsManagedZoneDnsSecNotEnabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpDnsManagedZoneInsecureDnsSecKeySigningKeyAlgorithmRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpDnsManagedZoneInsecureDnsSecKeySigningKeyAlgorithmRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpDnsManagedZoneInsecureDnsSecZoneSigningKeyAlgorithmRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpDnsManagedZoneInsecureDnsSecZoneSigningKeyAlgorithmRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpEntityInboundExternalPortRiskPolicyConfiguration extends CustomRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        description: Optional<string>,
        name: string,
        severity: Severity,
        updateTime: string,
        public anyIpV4AddressSubnet: boolean,
        public destinationNetworkScopes: DestinationNetworkScope[],
        public exactSubnets: Optional<string[]>,
        public inboundAccessScope: Optional<NetworkAccessScope>,
        public overlapSubnets: Optional<string[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpEntityInboundExternalPortRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            description,
            name,
            severity,
            updateTime);
    }
}

export class GcpEventAnalysisServiceConfiguration extends ServiceConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        public gcpEventSyncer: GcpEventSyncerConfigurationSection) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpEventAnalysisServiceConfiguration");
    }
}

export class GcpExcessivePermissionGroupRisk extends GcpExcessivePermissionPrincipalRisk implements ITypeNameObject, IGcpExcessivePermissionPrincipalRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        groupIdentityIds: string[],
        principalMail: string,
        signInActivity: Optional<Activity>,
        excessiveDirectMembershipGroupIds: string[],
        excessiveDirectMembershipGroupsPermissionActionSeverity: Optional<Severity>,
        excessivePermissionActionSeverity: Severity,
        excessivePermissionEvaluationStartDate: string,
        excessiveRoleBindingIds: string[],
        excessiveRoleBindingResolutionToIdsMap: Dictionary<string[]>,
        excessiveRoleBindingsPermissionActionSeverity: Optional<Severity>,
        excessiveScopeRoleBindingDatas: GcpExcessivePermissionPrincipalRiskScopeRoleBindingData[],
        roleBindingIdToDataMap: Dictionary<GcpExcessivePermissionPrincipalRiskRoleBindingData>,
        serviceAccountOriginatorResourceIdToPublicMap: Dictionary<boolean>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpExcessivePermissionGroupRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            groupIdentityIds,
            principalMail,
            signInActivity,
            excessiveDirectMembershipGroupIds,
            excessiveDirectMembershipGroupsPermissionActionSeverity,
            excessivePermissionActionSeverity,
            excessivePermissionEvaluationStartDate,
            excessiveRoleBindingIds,
            excessiveRoleBindingResolutionToIdsMap,
            excessiveRoleBindingsPermissionActionSeverity,
            excessiveScopeRoleBindingDatas,
            roleBindingIdToDataMap,
            serviceAccountOriginatorResourceIdToPublicMap);
    }
}

export class GcpExcessivePermissionGroupRiskModel extends GcpExcessivePermissionPrincipalRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpExcessivePermissionPrincipalRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        principalCreationTime: Optional<string>,
        infos: GcpExcessivePermissionPrincipalRiskModelInfo[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpExcessivePermissionGroupRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            principalCreationTime,
            infos);
    }
}

export class GcpExcessivePermissionPrincipalRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        public inactivePrincipalDeleteGroupMembershipEnabled: Optional<boolean>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpExcessivePermissionServiceAccountRiskPolicyConfiguration extends GcpExcessivePermissionPrincipalRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        inactivePrincipalDeleteGroupMembershipEnabled: Optional<boolean>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpExcessivePermissionServiceAccountRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            inactivePrincipalDeleteGroupMembershipEnabled);
    }
}

export class GcpExcessivePermissionUserRiskPolicyConfiguration extends GcpExcessivePermissionPrincipalRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        inactivePrincipalDeleteGroupMembershipEnabled: Optional<boolean>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpExcessivePermissionUserRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            inactivePrincipalDeleteGroupMembershipEnabled);
    }
}

export class GcpFunctionsFunctionBuildDefaultServiceAccountRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpFunctionsFunctionBuildDefaultServiceAccountRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpFunctionsFunctionPublicAccessExistsRiskModel extends GcpScopeResourcePublicAccessExistsRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpFunctionsFunctionPublicAccessExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpFunctionsFunctionPublicAccessExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpFunctionsFunctionPublicAccessExistsRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpFunctionsFunctionPublicAccessExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpIamGcpManagedPartialServiceAccount extends GcpIamPartialPrincipal implements ITypeNameObject, IPartialEntity, IGciDirectoryGroupMemberPrincipal, IGciPrincipal {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        mail: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpIamGcpManagedPartialServiceAccount",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            mail);
    }
}

export class GcpIamGcpManagedPartialServiceAccountModel extends GcpIamPartialPrincipalModel implements ITypeNameObject, IUdmObjectModel, IPrincipalModel, IGciPrincipalModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        access: Optional<PrincipalModelAccess>,
        analyzing: Optional<GciPrincipalModelAnalyzing>,
        inactive: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpIamGcpManagedPartialServiceAccountModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            access,
            analyzing,
            inactive);
    }
}

export class GcpIamServiceAccountInactiveRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        public includeDisabledServiceAccount: Optional<boolean>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpIamServiceAccountInactiveRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpIamServiceAccountUnrotatedUserManagedKeyRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        public keyRotationTimeFrame: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpIamServiceAccountUnrotatedUserManagedKeyRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpIamServiceAccountUserManagedKeyExistsRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpIamServiceAccountUserManagedKeyExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpInboundExternalComputeInstanceRisk extends AggregatedEntityRisk implements ITypeNameObject, IPublicRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        aggregatedEntityIds: string[],
        aggregatingEntityTypeName: string,
        public networkData: GcpInboundExternalResourceRiskNetworkData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpInboundExternalComputeInstanceRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            aggregatedEntityIds,
            aggregatingEntityTypeName);
    }
}

export class GcpInboundExternalComputeInstanceRiskModel extends AggregatedEntityRiskModel implements ITypeNameObject, IUdmObjectModel, INetworkInboundExternalResourceRiskModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpInboundExternalComputeInstanceRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public destinationNetworkScopes: DestinationNetworkScope[],
        public sourceSubnets: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpInboundExternalComputeInstanceRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpInboundExternalComputeInstanceRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpInboundExternalComputeInstanceRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpKmsKeyRingKeyPublicAccessExistsRiskModel extends GcpScopeResourcePublicAccessExistsRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpKmsKeyRingKeyPublicAccessExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpKmsKeyRingKeyPublicAccessExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpKmsKeyRingKeyPublicAccessExistsRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpKmsKeyRingKeyPublicAccessExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpKmsKeyRingKeyRotationTimeFrameRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        public keyRotationTimeFrame: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpKmsKeyRingKeyRotationTimeFrameRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpKmsKeyRingPublicAccessExistsRiskModel extends GcpScopeResourcePublicAccessExistsRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpKmsKeyRingPublicAccessExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpKmsKeyRingPublicAccessExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpKmsKeyRingPublicAccessExistsRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpKmsKeyRingPublicAccessExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpKubernetesClusterRoleBindingModel extends GcpKubernetesResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesRoleBindingModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        public principalIdReferences: string[],
        public roleIdReference: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpKubernetesClusterRoleBindingModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class GcpKubernetesClusterRoleModel extends GcpKubernetesResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesRoleModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        public principalIdReferences: string[],
        public roleBindingIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpKubernetesClusterRoleModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class GcpKubernetesGroupModel extends GcpKubernetesResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesResourceModel, IKubernetesPrincipalModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        public roleBindingIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpKubernetesGroupModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class GcpKubernetesIngressClassModel extends GcpKubernetesResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpKubernetesIngressClassModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class GcpKubernetesNamespaceModel extends GcpKubernetesResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesNamespaceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        public resourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpKubernetesNamespaceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class GcpKubernetesNamespaceResourceModel extends GcpKubernetesResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesNamespaceResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class GcpKubernetesNamespaceRoleBindingModel extends GcpKubernetesNamespaceResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesNamespaceResourceModel, IKubernetesRoleBindingModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        public principalIdReferences: string[],
        public roleIdReference: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpKubernetesNamespaceRoleBindingModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class GcpKubernetesNamespaceRoleModel extends GcpKubernetesNamespaceResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesNamespaceResourceModel, IKubernetesRoleModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        public principalIdReferences: string[],
        public roleBindingIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpKubernetesNamespaceRoleModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class GcpKubernetesNetworkPolicyModel extends GcpKubernetesNamespaceResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesNamespaceResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpKubernetesNetworkPolicyModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class GcpKubernetesNodeModel extends GcpKubernetesResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesNodeModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpKubernetesNodeModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class GcpKubernetesPersistentVolumeModel extends GcpKubernetesResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpKubernetesPersistentVolumeModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class GcpKubernetesPodModel extends GcpKubernetesNamespaceResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesNamespaceResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpKubernetesPodModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class GcpKubernetesPodSecurityPolicyModel extends GcpKubernetesResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpKubernetesPodSecurityPolicyModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class GcpKubernetesPodTemplateModel extends GcpKubernetesNamespaceResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesNamespaceResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpKubernetesPodTemplateModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class GcpKubernetesResource<TKubernetesResourceData extends KubernetesResourceData> extends GcpKubernetesResourceBase implements ITypeNameObject, IKubernetesResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: TKubernetesResourceData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class GcpKubernetesServiceAccountModel extends GcpKubernetesNamespaceResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesServiceAccountModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        public gcpServiceAccountId: Optional<string>,
        public originatorWorkloadResourceIds: string[],
        public roleBindingIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpKubernetesServiceAccountModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class GcpKubernetesServiceModel extends GcpKubernetesNamespaceResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesServiceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        public ingressIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpKubernetesServiceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class GcpKubernetesUser extends GcpKubernetesResource<KubernetesUserData> implements ITypeNameObject, IKubernetesUser {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesUserData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpKubernetesUser",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class GcpKubernetesWorkloadResourceModel extends GcpKubernetesNamespaceResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesWorkloadResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        public containerImageIdReferences: string[],
        public containerImageRawUrlToDataMap: Dictionary<ContainerImageResourceStateContainerImageData>,
        public containerImageScanStatus: Optional<ContainerImageDataScanStatus>,
        public containerImageVulnerabilities: VulnerabilityData[],
        public controllerResourceReference: Optional<EntitySearchableIdReference>,
        public gcpServiceAccountIds: string[],
        public nodePoolIds: string[],
        public serviceAccountReference: EntitySearchableIdReference) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class GcpLoggingAuditLogTenantDefaultNotEnabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpLoggingAuditLogTenantDefaultNotEnabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpLoggingLogBucketModel extends GcpLoggingResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IGciGcpResourceModel, IRelatedTenantsEntityModel, IGcpLoggingLogSinkDestinationResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[],
        gciParentResourceData: Optional<GciParentResourceData>,
        public loggingLogSinkIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpLoggingLogBucketModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds,
            gciParentResourceData);
    }
}

export class GcpLoggingLogSinkModel extends GcpLoggingResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IGciGcpResourceModel, IRelatedTenantsEntityModel, IGcpServiceAccountOriginatorScopeResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        accessLevel: GcpScopeResourceAccessLevel,
        roleBindingIds: string[],
        gciParentResourceData: Optional<GciParentResourceData>,
        public destinationIdReference: string,
        public serviceAccountIdReference: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpLoggingLogSinkModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            accessLevel,
            roleBindingIds,
            gciParentResourceData);
    }
}

export class GcpMonitoringAlertPolicyAuditConfigurationChangesNotExistRisk extends GcpMonitoringAlertPolicyNotExistRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        loggingLogMetricFilterPattern: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpMonitoringAlertPolicyAuditConfigurationChangesNotExistRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            loggingLogMetricFilterPattern);
    }
}

export class GcpMonitoringAlertPolicyAuditConfigurationChangesNotExistRiskModel extends GcpMonitoringAlertPolicyNotExistRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpMonitoringAlertPolicyNotExistRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpMonitoringAlertPolicyAuditConfigurationChangesNotExistRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpMonitoringAlertPolicyAuditConfigurationChangesNotExistRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpMonitoringAlertPolicyAuditConfigurationChangesNotExistRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpMonitoringAlertPolicyCloudSqlInstanceConfigurationChangesNotExistRisk extends GcpMonitoringAlertPolicyNotExistRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        loggingLogMetricFilterPattern: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpMonitoringAlertPolicyCloudSqlInstanceConfigurationChangesNotExistRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            loggingLogMetricFilterPattern);
    }
}

export class GcpMonitoringAlertPolicyCloudSqlInstanceConfigurationChangesNotExistRiskModel extends GcpMonitoringAlertPolicyNotExistRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpMonitoringAlertPolicyNotExistRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpMonitoringAlertPolicyCloudSqlInstanceConfigurationChangesNotExistRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpMonitoringAlertPolicyCloudSqlInstanceConfigurationChangesNotExistRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpMonitoringAlertPolicyCloudSqlInstanceConfigurationChangesNotExistRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpMonitoringAlertPolicyComputeFirewallRuleChangesNotExistRisk extends GcpMonitoringAlertPolicyNotExistRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        loggingLogMetricFilterPattern: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpMonitoringAlertPolicyComputeFirewallRuleChangesNotExistRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            loggingLogMetricFilterPattern);
    }
}

export class GcpMonitoringAlertPolicyComputeFirewallRuleChangesNotExistRiskModel extends GcpMonitoringAlertPolicyNotExistRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpMonitoringAlertPolicyNotExistRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpMonitoringAlertPolicyComputeFirewallRuleChangesNotExistRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpMonitoringAlertPolicyComputeFirewallRuleChangesNotExistRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpMonitoringAlertPolicyComputeFirewallRuleChangesNotExistRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpMonitoringAlertPolicyComputeRouteChangesNotExistRisk extends GcpMonitoringAlertPolicyNotExistRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        loggingLogMetricFilterPattern: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpMonitoringAlertPolicyComputeRouteChangesNotExistRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            loggingLogMetricFilterPattern);
    }
}

export class GcpMonitoringAlertPolicyComputeRouteChangesNotExistRiskModel extends GcpMonitoringAlertPolicyNotExistRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpMonitoringAlertPolicyNotExistRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpMonitoringAlertPolicyComputeRouteChangesNotExistRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpMonitoringAlertPolicyComputeRouteChangesNotExistRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpMonitoringAlertPolicyComputeRouteChangesNotExistRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpMonitoringAlertPolicyComputeVpcChangesNotExistRisk extends GcpMonitoringAlertPolicyNotExistRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        loggingLogMetricFilterPattern: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpMonitoringAlertPolicyComputeVpcChangesNotExistRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            loggingLogMetricFilterPattern);
    }
}

export class GcpMonitoringAlertPolicyComputeVpcChangesNotExistRiskModel extends GcpMonitoringAlertPolicyNotExistRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpMonitoringAlertPolicyNotExistRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpMonitoringAlertPolicyComputeVpcChangesNotExistRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpMonitoringAlertPolicyComputeVpcChangesNotExistRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpMonitoringAlertPolicyComputeVpcChangesNotExistRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpMonitoringAlertPolicyIamPolicyChangesNotExistRisk extends GcpMonitoringAlertPolicyNotExistRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        loggingLogMetricFilterPattern: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpMonitoringAlertPolicyIamPolicyChangesNotExistRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            loggingLogMetricFilterPattern);
    }
}

export class GcpMonitoringAlertPolicyIamPolicyChangesNotExistRiskModel extends GcpMonitoringAlertPolicyNotExistRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpMonitoringAlertPolicyNotExistRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpMonitoringAlertPolicyIamPolicyChangesNotExistRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpMonitoringAlertPolicyIamPolicyChangesNotExistRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpMonitoringAlertPolicyIamPolicyChangesNotExistRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpMonitoringAlertPolicyIamRoleChangesNotExistRisk extends GcpMonitoringAlertPolicyNotExistRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        loggingLogMetricFilterPattern: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpMonitoringAlertPolicyIamRoleChangesNotExistRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            loggingLogMetricFilterPattern);
    }
}

export class GcpMonitoringAlertPolicyIamRoleChangesNotExistRiskModel extends GcpMonitoringAlertPolicyNotExistRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpMonitoringAlertPolicyNotExistRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpMonitoringAlertPolicyIamRoleChangesNotExistRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpMonitoringAlertPolicyIamRoleChangesNotExistRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpMonitoringAlertPolicyIamRoleChangesNotExistRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpMonitoringAlertPolicyProjectOwnershipChangesNotExistRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpMonitoringAlertPolicyProjectOwnershipChangesNotExistRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpNotebooksInstanceBootIntegrityValidationNotEnabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpNotebooksInstanceBootIntegrityValidationNotEnabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpNotebooksInstanceDefaultEncryptionRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpNotebooksInstanceDefaultEncryptionRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpNotebooksInstanceRootAccessEnabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpNotebooksInstanceRootAccessEnabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpNotebooksInstanceSecureBootNotEnabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpNotebooksInstanceSecureBootNotEnabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpNotebooksInstanceVirtualTpmNotEnabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpNotebooksInstanceVirtualTpmNotEnabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpOpenShiftBuildConfigModel extends GcpKubernetesNamespaceResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesNamespaceResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpOpenShiftBuildConfigModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class GcpOpenShiftCatalogSourceModel extends GcpKubernetesNamespaceResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesNamespaceResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpOpenShiftCatalogSourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class GcpOpenShiftDeploymentConfigModel extends GcpKubernetesWorkloadResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesWorkloadResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        containerImageIdReferences: string[],
        containerImageRawUrlToDataMap: Dictionary<ContainerImageResourceStateContainerImageData>,
        containerImageScanStatus: Optional<ContainerImageDataScanStatus>,
        containerImageVulnerabilities: VulnerabilityData[],
        controllerResourceReference: Optional<EntitySearchableIdReference>,
        gcpServiceAccountIds: string[],
        nodePoolIds: string[],
        serviceAccountReference: EntitySearchableIdReference) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpOpenShiftDeploymentConfigModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            containerImageIdReferences,
            containerImageRawUrlToDataMap,
            containerImageScanStatus,
            containerImageVulnerabilities,
            controllerResourceReference,
            gcpServiceAccountIds,
            nodePoolIds,
            serviceAccountReference);
    }
}

export class GcpOpenShiftImageStreamModel extends GcpKubernetesNamespaceResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesNamespaceResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpOpenShiftImageStreamModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class GcpOpenShiftProject extends GcpKubernetesResource<OpenShiftProjectData> implements ITypeNameObject, IOpenShiftProject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: OpenShiftProjectData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpOpenShiftProject",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class GcpOpenShiftRouteModel extends GcpKubernetesNamespaceResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesNamespaceResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpOpenShiftRouteModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class GcpOpenShiftSecurityContextConstraint extends GcpKubernetesResource<OpenShiftSecurityContextConstraintData> implements ITypeNameObject, IOpenShiftSecurityContextConstraint {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: OpenShiftSecurityContextConstraintData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpOpenShiftSecurityContextConstraint",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class GcpOpenShiftTemplateModel extends GcpKubernetesNamespaceResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesNamespaceResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpOpenShiftTemplateModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class GcpPermissionManagementRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpPermissionManagementRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpPrincipalAllowedResourcePermissionRisk extends GcpPrincipalResourcePermissionRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        principalResourcePermissionItems: GcpPrincipalResourcePermissionRiskItem[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpPrincipalAllowedResourcePermissionRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            principalResourcePermissionItems);
    }
}

export class GcpPrincipalAllowedResourcePermissionRiskModel extends GcpPrincipalResourcePermissionRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpPrincipalAllowedResourcePermissionRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        permissionActions: string[],
        principalIds: string[],
        resourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpPrincipalAllowedResourcePermissionRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            permissionActions,
            principalIds,
            resourceIds);
    }
}

export class GcpPrincipalNotAllowedResourcePermissionRisk extends GcpPrincipalResourcePermissionRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        principalResourcePermissionItems: GcpPrincipalResourcePermissionRiskItem[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpPrincipalNotAllowedResourcePermissionRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            principalResourcePermissionItems);
    }
}

export class GcpPrincipalNotAllowedResourcePermissionRiskModel extends GcpPrincipalResourcePermissionRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpPrincipalNotAllowedResourcePermissionRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        permissionActions: string[],
        principalIds: string[],
        resourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpPrincipalNotAllowedResourcePermissionRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            permissionActions,
            principalIds,
            resourceIds);
    }
}

export class GcpPrincipalNotAllowedResourceRoleRiskPolicyConfiguration extends CustomRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        description: Optional<string>,
        name: string,
        severity: Severity,
        updateTime: string,
        public excludePrincipalMatchConditions: EntityMatchCondition[],
        public excludeScopeResourceMatchConditions: EntityMatchCondition[],
        public principalMatchConditions: EntityMatchCondition[],
        public roles: GcpPrincipalNotAllowedResourceRoleRiskPolicyConfigurationRoles,
        public scopeResourceMatchConditions: EntityMatchCondition[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpPrincipalNotAllowedResourceRoleRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            description,
            name,
            severity,
            updateTime);
    }
}

export class GcpPrincipalResourcePermissionRiskPolicyConfiguration extends CustomRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        description: Optional<string>,
        name: string,
        severity: Severity,
        updateTime: string,
        public actionNamePatterns: Optional<string[]>,
        public actionRiskCategories: Optional<ActionRiskCategory[]>,
        public actions: Optional<string[]>,
        public principalBuiltInAttributeTypeNames: Optional<string[]>,
        public principalIds: Optional<string[]>,
        public resourceBuiltInAttributeTypeNames: Optional<string[]>,
        public resourceCustomAttributeDefinitionIds: Optional<string[]>,
        public resourceIds: Optional<string[]>,
        public resourceTags: Optional<ResourceTag[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            description,
            name,
            severity,
            updateTime);
    }
}

export class GcpPrincipalServiceAccountWideScopeAdministratorRoleRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpPrincipalServiceAccountWideScopeAdministratorRoleRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpPrincipalServiceAccountWideScopeImpersonateServiceAccountActionExistsRisk extends GcpPrincipalWideScopeImpersonateServiceAccountActionExistsRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        groupIdentityIds: string[],
        identityDisabled: Optional<boolean>,
        orderedResourceManagerResourceIds: string[],
        resourceManagerResourceIdToRoleIdsMap: Dictionary<string[]>,
        roleBindingIdToImpersonatePermissionActionsMap: Dictionary<string[]>,
        serviceAccountIdToSeverityMap: Dictionary<Optional<Severity>>,
        public principalMail: string,
        public principalServiceAccountCreatorIdentityIdReference: Optional<string>,
        public principalServiceAccountCreatorOriginatorEntityIdReference: Optional<string>,
        public signInActivity: Optional<Activity>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpPrincipalServiceAccountWideScopeImpersonateServiceAccountActionExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            groupIdentityIds,
            identityDisabled,
            orderedResourceManagerResourceIds,
            resourceManagerResourceIdToRoleIdsMap,
            roleBindingIdToImpersonatePermissionActionsMap,
            serviceAccountIdToSeverityMap);
    }
}

export class GcpPrincipalServiceAccountWideScopeImpersonateServiceAccountActionExistsRiskModel extends GcpPrincipalWideScopeImpersonateServiceAccountActionExistsRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpPrincipalServiceAccountWideScopeImpersonateServiceAccountActionExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        roleIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpPrincipalServiceAccountWideScopeImpersonateServiceAccountActionExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            roleIds);
    }
}

export class GcpPrincipalServiceAccountWideScopeImpersonateServiceAccountActionExistsRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpPrincipalServiceAccountWideScopeImpersonateServiceAccountActionExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsRisk extends GcpPrincipalWideScopeImpersonateServiceAccountActionExistsRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        groupIdentityIds: string[],
        identityDisabled: Optional<boolean>,
        orderedResourceManagerResourceIds: string[],
        resourceManagerResourceIdToRoleIdsMap: Dictionary<string[]>,
        roleBindingIdToImpersonatePermissionActionsMap: Dictionary<string[]>,
        serviceAccountIdToSeverityMap: Dictionary<Optional<Severity>>,
        public principalRoleBindingIdentifier: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            groupIdentityIds,
            identityDisabled,
            orderedResourceManagerResourceIds,
            resourceManagerResourceIdToRoleIdsMap,
            roleBindingIdToImpersonatePermissionActionsMap,
            serviceAccountIdToSeverityMap);
    }
}

export class GcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsRiskModel extends GcpPrincipalWideScopeImpersonateServiceAccountActionExistsRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        roleIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            roleIds);
    }
}

export class GcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpPubSubSubscriptionPublicAccessExistsRiskModel extends GcpScopeResourcePublicAccessExistsRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpPubSubSubscriptionPublicAccessExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpPubSubSubscriptionPublicAccessExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpPubSubSubscriptionPublicAccessExistsRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpPubSubSubscriptionPublicAccessExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpPubSubTopicPublicAccessExistsRiskModel extends GcpScopeResourcePublicAccessExistsRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: GcpPubSubTopicPublicAccessExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpPubSubTopicPublicAccessExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class GcpPubSubTopicPublicAccessExistsRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpPubSubTopicPublicAccessExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpResourceManagerFolder extends GcpResourceManagerResource implements ITypeNameObject, IGcpRoleBindingUsageResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        rawShortId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpResourceManagerFolder",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            rawShortId);
    }
}

export class GcpResourceManagerOrganization extends GcpResourceManagerResource implements ITypeNameObject, IGcpRoleBindingUsageResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        rawShortId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpResourceManagerOrganization",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            rawShortId);
    }
}

export class GcpRoleBindingRequest extends PermissionAssignmentRequest implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        closeReason: Optional<PermissionRequestCloseReason>,
        expirationTime: Optional<string>,
        expirationTimeFrame: string,
        granteeUserTenantId: string,
        permissionEligibilityId: string,
        reason: Optional<string>,
        startTime: Optional<string>,
        status: PermissionRequestStatus,
        statusUpdateTime: string,
        tenantIds: string[],
        public tenantIdToAssignedRoleIdsMap: Dictionary<string[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpRoleBindingRequest",
            scopeId,
            closeReason,
            expirationTime,
            expirationTimeFrame,
            granteeUserTenantId,
            permissionEligibilityId,
            reason,
            startTime,
            status,
            statusUpdateTime,
            tenantIds);
    }
}

export class GcpRoleBindingRequestActivationFailureData extends PermissionAssignmentRequestActivationFailureData implements ITypeNameObject {
    constructor(
        errorMessage: Optional<string>,
        retryCount: number,
        tenantIdToErrorMessageMap: Dictionary<string>,
        public tenantIdToFailedRoleIdsMap: Dictionary<string[]>) {
        super(
            "GcpRoleBindingRequestActivationFailureData",
            errorMessage,
            retryCount,
            tenantIdToErrorMessageMap);
    }
}

export class GcpScopeResourcePublicAccessExistsRisk extends PublicEntityRisk implements ITypeNameObject, IPublicRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public invocationOnly: boolean,
        public permissionActionToHighSeverityMap: Dictionary<boolean>,
        public principalIds: string[],
        public roleIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GcpSecretManagerSecretRotationTimeFrameRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        public secretRotationTimeFrame: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSecretManagerSecretRotationTimeFrameRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpSeverePermissionPrincipalRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSeverePermissionPrincipalRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpSqlInstanceBackupDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstanceBackupDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpSqlInstanceHttpsOnlyDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstanceHttpsOnlyDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpSqlInstanceMySqlListDatabasesPermissionRequiredDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstanceMySqlListDatabasesPermissionRequiredDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpSqlInstanceMySqlLoadClientFileEnabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstanceMySqlLoadClientFileEnabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpSqlInstancePostgreSqlAuditDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstancePostgreSqlAuditDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpSqlInstancePostgreSqlLogConnectionsDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstancePostgreSqlLogConnectionsDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpSqlInstancePostgreSqlLogConnectionsHostNameDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstancePostgreSqlLogConnectionsHostNameDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpSqlInstancePostgreSqlLogDisconnectionsDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstancePostgreSqlLogDisconnectionsDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpSqlInstancePostgreSqlLogErrorVerbosityRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstancePostgreSqlLogErrorVerbosityRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpSqlInstancePostgreSqlLogMinLevelRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstancePostgreSqlLogMinLevelRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpSqlInstancePostgreSqlMinDurationLogQueryEnabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstancePostgreSqlMinDurationLogQueryEnabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpSqlInstancePostgreSqlMinLogLevelLogQueryRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstancePostgreSqlMinLogLevelLogQueryRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpSqlInstancePostgreSqlStatementLogDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstancePostgreSqlStatementLogDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpSqlInstanceSqlServerContainedDatabaseAuthenticationEnabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstanceSqlServerContainedDatabaseAuthenticationEnabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpSqlInstanceSqlServerCrossDatabaseImplicitPermissionsEnabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstanceSqlServerCrossDatabaseImplicitPermissionsEnabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpSqlInstanceSqlServerCrossInstanceProcedureExecutionEnabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstanceSqlServerCrossInstanceProcedureExecutionEnabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpSqlInstanceSqlServerLogMaskParametersDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstanceSqlServerLogMaskParametersDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpSqlInstanceSqlServerScriptExecutionEnabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstanceSqlServerScriptExecutionEnabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpSqlInstanceSqlServerUserMaxConnectionsExistsRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstanceSqlServerUserMaxConnectionsExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpSqlInstanceSqlServerUserOptionsEnabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstanceSqlServerUserOptionsEnabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpSqlInstanceWideRangeInboundRisk extends PublicEntityRisk implements ITypeNameObject, IPublicRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstanceWideRangeInboundRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class GcpSqlInstanceWideRangeInboundRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpSqlInstanceWideRangeInboundRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpStorageBucketLockedRetentionPolicyNotEnabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpStorageBucketLockedRetentionPolicyNotEnabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpStorageBucketPublicAccessExistsRisk extends GcpScopeResourcePublicAccessExistsRisk implements ITypeNameObject, IPublicRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        invocationOnly: boolean,
        permissionActionToHighSeverityMap: Dictionary<boolean>,
        principalIds: string[],
        roleIds: string[],
        public publicAclExists: boolean,
        public publicRoleBindingExists: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpStorageBucketPublicAccessExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            invocationOnly,
            permissionActionToHighSeverityMap,
            principalIds,
            roleIds);
    }
}

export class GcpStorageBucketPublicAccessExistsRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpStorageBucketPublicAccessExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpStorageBucketUniformAccessControlDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpStorageBucketUniformAccessControlDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpTenantEntityCloudAssetServiceNotEnabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpTenantEntityCloudAssetServiceNotEnabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpTenantEntityCreationRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpTenantEntityCreationRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GcpTenantEntityUnusedRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        public minProjectUsageTimeFrame: string,
        public projectUsageTimeFrame: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpTenantEntityUnusedRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class GitHubServerConfiguration extends CodeServerConfiguration implements IDocument, ITypeNameObject, IEndpointIntegrationEntityConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        type: IntegrationType,
        codeTenantType: CodeTenantType,
        endpoint: Optional<string>,
        endpointConnectorId: Optional<string>,
        name: string,
        serverCertificateValidationEnabled: boolean,
        public applicationClientId: string,
        public applicationEncryptedClientSecret: EncryptedData,
        public applicationEncryptedPrivateKey: EncryptedData,
        public applicationId: number,
        public applicationOrganizationName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GitHubServerConfiguration",
            scopeId,
            type,
            codeTenantType,
            endpoint,
            endpointConnectorId,
            name,
            serverCertificateValidationEnabled);
    }
}

export class GitLabServerConfiguration extends CodeServerConfiguration implements IDocument, ITypeNameObject, IEndpointIntegrationEntityConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        type: IntegrationType,
        codeTenantType: CodeTenantType,
        endpoint: Optional<string>,
        endpointConnectorId: Optional<string>,
        name: string,
        serverCertificateValidationEnabled: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GitLabServerConfiguration",
            scopeId,
            type,
            codeTenantType,
            endpoint,
            endpointConnectorId,
            name,
            serverCertificateValidationEnabled);
    }
}

export class GroupMembershipEligibilityConfiguration extends PermissionEligibilityConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        approval: Optional<PermissionEligibilityConfigurationApproval>,
        description: Optional<string>,
        expirationTimeFrame: string,
        name: string,
        principalTenantId: string,
        reasonRequired: boolean,
        public groupReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            approval,
            description,
            expirationTimeFrame,
            name,
            principalTenantId,
            reasonRequired);
    }
}

export class GroupMembershipEligibilityModel extends PermissionEligibilityModel implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        public configuration: GroupMembershipEligibilityConfiguration,
        scopeId: string,
        state: Optional<SystemEntityState>,
        approverPrincipalIdReferences: string[],
        existingGranteePrincipalIds: string[],
        granteePrincipalIdReferences: string[],
        levelToApproverPrincipalIdReferencesMap: Dictionary<string[]>,
        levelToExistingApproverPrincipalIdsMap: Dictionary<string[]>,
        public existingGroupIds: string[],
        public groupIdReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GroupMembershipEligibilityModel",
            configuration,
            scopeId,
            state,
            approverPrincipalIdReferences,
            existingGranteePrincipalIds,
            granteePrincipalIdReferences,
            levelToApproverPrincipalIdReferencesMap,
            levelToExistingApproverPrincipalIdsMap);
    }
}

export class IntegrationApiServiceConfiguration extends ServiceConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        public globalSlack: Optional<GlobalSlackConfigurationSection>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "IntegrationApiServiceConfiguration");
    }
}

export class KubernetesAdmissionControllerRiskPolicyConfiguration extends RiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument, IKubernetesAdmissionControllerRiskPolicyConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        public effect: Optional<KubernetesAdmissionControllerRiskPolicyEffect>,
        public rawResourceTypeNames: Optional<string[]>,
        public resourceOperations: KubernetesAdmissionControllerResourceOperation[],
        public systemNamespacesEnabled: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class KubernetesApiServiceConfiguration extends ServiceConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesApiServiceConfiguration");
    }
}

export class KubernetesClusterApiServerAlwaysAdmitAdmissionControllerPluginEnabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterApiServerAlwaysAdmitAdmissionControllerPluginEnabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class KubernetesClusterApiServerAuthorizationModeAlwaysAllowRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterApiServerAuthorizationModeAlwaysAllowRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class KubernetesClusterApiServerBasicAuthenticationEnabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterApiServerBasicAuthenticationEnabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class KubernetesClusterApiServerClientCertificateAuthenticationDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterApiServerClientCertificateAuthenticationDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class KubernetesClusterApiServerEtcdCertificateAuthorityCertificateNotExistRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterApiServerEtcdCertificateAuthorityCertificateNotExistRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class KubernetesClusterApiServerEtcdClientCertificateAuthenticationDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterApiServerEtcdClientCertificateAuthenticationDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class KubernetesClusterApiServerInsecureListenerEnabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterApiServerInsecureListenerEnabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class KubernetesClusterApiServerInsecureTlsProtocolRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterApiServerInsecureTlsProtocolRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class KubernetesClusterApiServerKubeletCertificateValidationDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterApiServerKubeletCertificateValidationDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class KubernetesClusterApiServerKubeletClientCertificateAuthenticationDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterApiServerKubeletClientCertificateAuthenticationDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class KubernetesClusterApiServerNamespaceLifecycleAdmissionControllerPluginDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterApiServerNamespaceLifecycleAdmissionControllerPluginDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class KubernetesClusterApiServerNodeRestrictionAdmissionControllerPluginDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterApiServerNodeRestrictionAdmissionControllerPluginDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class KubernetesClusterApiServerRoleBasedAccessControlAuthorizationModeDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterApiServerRoleBasedAccessControlAuthorizationModeDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class KubernetesClusterApiServerSecurityContextConstraintAdmissionControllerPluginDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterApiServerSecurityContextConstraintAdmissionControllerPluginDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class KubernetesClusterApiServerServiceAccountAdmissionControllerPluginDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterApiServerServiceAccountAdmissionControllerPluginDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class KubernetesClusterApiServerServiceAccountLookupDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterApiServerServiceAccountLookupDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class KubernetesClusterApiServerServiceAccountTokenKeyNotExistRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterApiServerServiceAccountTokenKeyNotExistRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class KubernetesClusterApiServerStaticTokenAuthenticationEnabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterApiServerStaticTokenAuthenticationEnabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class KubernetesClusterApiServerTlsCertificateNotExistRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterApiServerTlsCertificateNotExistRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class KubernetesClusterAuthenticationPrincipalRoleBindingExistsRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterAuthenticationPrincipalRoleBindingExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class KubernetesClusterClusterAdminRoleBindingExistsRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterClusterAdminRoleBindingExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class KubernetesClusterControllerManagerInsecureListenerIpAddressRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterControllerManagerInsecureListenerIpAddressRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class KubernetesClusterControllerManagerServiceAccountCredentialsDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterControllerManagerServiceAccountCredentialsDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class KubernetesClusterControllerManagerServiceAccountTokenKeyNotExistRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterControllerManagerServiceAccountTokenKeyNotExistRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class KubernetesClusterControllerManagerServiceAccountTokenSecretCertificateAuthorityCertificateNotExistRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterControllerManagerServiceAccountTokenSecretCertificateAuthorityCertificateNotExistRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class KubernetesClusterDefaultNamespaceUsedRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterDefaultNamespaceUsedRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class KubernetesClusterEndOfLifeRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterEndOfLifeRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class KubernetesClusterEtcdClientCertificateAuthenticationDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterEtcdClientCertificateAuthenticationDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class KubernetesClusterEtcdPeerClientCertificateAuthenticationDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterEtcdPeerClientCertificateAuthenticationDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class KubernetesClusterEtcdPeerSelfSignedTlsCertificateGenerationEnabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterEtcdPeerSelfSignedTlsCertificateGenerationEnabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class KubernetesClusterEtcdPeerTlsCertificateNotExistRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterEtcdPeerTlsCertificateNotExistRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class KubernetesClusterEtcdSelfSignedTlsCertificateGenerationEnabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterEtcdSelfSignedTlsCertificateGenerationEnabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class KubernetesClusterEtcdTlsCertificateNotExistRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterEtcdTlsCertificateNotExistRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class KubernetesClusterIdentityProviderAuthenticationDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterIdentityProviderAuthenticationDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class KubernetesClusterKubeletAnonymousAuthenticationEnabledRisk extends KubernetesClusterKubeletRisk implements ITypeNameObject, IKubernetesRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        nodeDatas: KubernetesClusterKubeletRiskNodeData[],
        nodeOrVirtualMachineIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterKubeletAnonymousAuthenticationEnabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            nodeDatas,
            nodeOrVirtualMachineIds);
    }
}

export class KubernetesClusterKubeletAnonymousAuthenticationEnabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterKubeletAnonymousAuthenticationEnabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class KubernetesClusterKubeletAuthorizationModeAlwaysAllowRisk extends KubernetesClusterKubeletRisk implements ITypeNameObject, IKubernetesRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        nodeDatas: KubernetesClusterKubeletRiskNodeData[],
        nodeOrVirtualMachineIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterKubeletAuthorizationModeAlwaysAllowRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            nodeDatas,
            nodeOrVirtualMachineIds);
    }
}

export class KubernetesClusterKubeletAuthorizationModeAlwaysAllowRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterKubeletAuthorizationModeAlwaysAllowRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class KubernetesClusterKubeletClientCertificateAuthenticationDisabledRisk extends KubernetesClusterKubeletRisk implements ITypeNameObject, IKubernetesRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        nodeDatas: KubernetesClusterKubeletRiskNodeData[],
        nodeOrVirtualMachineIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterKubeletClientCertificateAuthenticationDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            nodeDatas,
            nodeOrVirtualMachineIds);
    }
}

export class KubernetesClusterKubeletClientCertificateAuthenticationDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterKubeletClientCertificateAuthenticationDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class KubernetesClusterKubeletClientCertificateRotationDisabledRisk extends KubernetesClusterKubeletRisk implements ITypeNameObject, IKubernetesRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        nodeDatas: KubernetesClusterKubeletRiskNodeData[],
        nodeOrVirtualMachineIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterKubeletClientCertificateRotationDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            nodeDatas,
            nodeOrVirtualMachineIds);
    }
}

export class KubernetesClusterKubeletClientCertificateRotationDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterKubeletClientCertificateRotationDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class KubernetesClusterKubeletHostnameOverrideUsedRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterKubeletHostnameOverrideUsedRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class KubernetesClusterKubeletInsecureTlsProtocolRisk extends KubernetesClusterKubeletRisk implements ITypeNameObject, IKubernetesRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        nodeDatas: KubernetesClusterKubeletRiskNodeData[],
        nodeOrVirtualMachineIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterKubeletInsecureTlsProtocolRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            nodeDatas,
            nodeOrVirtualMachineIds);
    }
}

export class KubernetesClusterKubeletInsecureTlsProtocolRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterKubeletInsecureTlsProtocolRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class KubernetesClusterKubeletInsufficientEventsPerSecondMaxCountRisk extends KubernetesClusterKubeletRisk implements ITypeNameObject, IKubernetesRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        nodeDatas: KubernetesClusterKubeletRiskNodeData[],
        nodeOrVirtualMachineIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterKubeletInsufficientEventsPerSecondMaxCountRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            nodeDatas,
            nodeOrVirtualMachineIds);
    }
}

export class KubernetesClusterKubeletInsufficientEventsPerSecondMaxCountRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterKubeletInsufficientEventsPerSecondMaxCountRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class KubernetesClusterKubeletManageIpTablesDisabledRisk extends KubernetesClusterKubeletRisk implements ITypeNameObject, IKubernetesRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        nodeDatas: KubernetesClusterKubeletRiskNodeData[],
        nodeOrVirtualMachineIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterKubeletManageIpTablesDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            nodeDatas,
            nodeOrVirtualMachineIds);
    }
}

export class KubernetesClusterKubeletManageIpTablesDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterKubeletManageIpTablesDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class KubernetesClusterKubeletReadOnlyPortEnabledRisk extends KubernetesClusterKubeletRisk implements ITypeNameObject, IKubernetesRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        nodeDatas: KubernetesClusterKubeletRiskNodeData[],
        nodeOrVirtualMachineIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterKubeletReadOnlyPortEnabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            nodeDatas,
            nodeOrVirtualMachineIds);
    }
}

export class KubernetesClusterKubeletReadOnlyPortEnabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterKubeletReadOnlyPortEnabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class KubernetesClusterKubeletServerCertificateRotationDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterKubeletServerCertificateRotationDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class KubernetesClusterKubeletStreamingConnectionIdleTimeoutDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterKubeletStreamingConnectionIdleTimeoutDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class KubernetesClusterKubeletTlsCertificateNotExistRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterKubeletTlsCertificateNotExistRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class KubernetesClusterNetworkPolicyUnsupportedRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterNetworkPolicyUnsupportedRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class KubernetesClusterNodeUnrestrictedAccessFileRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterNodeUnrestrictedAccessFileRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class KubernetesClusterPlatformEndOfLifeRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterPlatformEndOfLifeRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class KubernetesClusterResourceEncryptionDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterResourceEncryptionDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class KubernetesClusterSchedulerRoleBasedAccessAuthorizationDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesClusterSchedulerRoleBasedAccessAuthorizationDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class KubernetesConfigMapData extends KubernetesNamespaceResourceData implements ITypeNameObject, IKubernetesResourceData {
    constructor(
        annotationKeyToValueMap: Dictionary<string>,
        controllerResourceReference: Optional<KubernetesResourceReference>,
        name: string,
        path: string,
        rawId: string,
        rawYaml: Optional<string>,
        namespaceId: string,
        namespaceName: string) {
        super(
            "KubernetesConfigMapData",
            annotationKeyToValueMap,
            controllerResourceReference,
            name,
            path,
            rawId,
            rawYaml,
            namespaceId,
            namespaceName);
    }
}

export class KubernetesCronJobData extends KubernetesWorkloadResourceData implements ITypeNameObject, IKubernetesResourceData {
    constructor(
        annotationKeyToValueMap: Dictionary<string>,
        controllerResourceReference: Optional<KubernetesResourceReference>,
        name: string,
        path: string,
        rawId: string,
        rawYaml: Optional<string>,
        namespaceId: string,
        namespaceName: string,
        podTemplateSpec: KubernetesPodTemplateSpec) {
        super(
            "KubernetesCronJobData",
            annotationKeyToValueMap,
            controllerResourceReference,
            name,
            path,
            rawId,
            rawYaml,
            namespaceId,
            namespaceName,
            podTemplateSpec);
    }
}

export class KubernetesDaemonSetData extends KubernetesWorkloadResourceData implements ITypeNameObject, IKubernetesResourceData {
    constructor(
        annotationKeyToValueMap: Dictionary<string>,
        controllerResourceReference: Optional<KubernetesResourceReference>,
        name: string,
        path: string,
        rawId: string,
        rawYaml: Optional<string>,
        namespaceId: string,
        namespaceName: string,
        podTemplateSpec: KubernetesPodTemplateSpec) {
        super(
            "KubernetesDaemonSetData",
            annotationKeyToValueMap,
            controllerResourceReference,
            name,
            path,
            rawId,
            rawYaml,
            namespaceId,
            namespaceName,
            podTemplateSpec);
    }
}

export class KubernetesDeploymentData extends KubernetesWorkloadResourceData implements ITypeNameObject, IKubernetesResourceData {
    constructor(
        annotationKeyToValueMap: Dictionary<string>,
        controllerResourceReference: Optional<KubernetesResourceReference>,
        name: string,
        path: string,
        rawId: string,
        rawYaml: Optional<string>,
        namespaceId: string,
        namespaceName: string,
        podTemplateSpec: KubernetesPodTemplateSpec,
        public podLabelSelector: KubernetesLabelSelector,
        public replicaCount: Optional<number>) {
        super(
            "KubernetesDeploymentData",
            annotationKeyToValueMap,
            controllerResourceReference,
            name,
            path,
            rawId,
            rawYaml,
            namespaceId,
            namespaceName,
            podTemplateSpec);
    }
}

export class KubernetesIngressData extends KubernetesNamespaceResourceData implements ITypeNameObject, IKubernetesResourceData {
    constructor(
        annotationKeyToValueMap: Dictionary<string>,
        controllerResourceReference: Optional<KubernetesResourceReference>,
        name: string,
        path: string,
        rawId: string,
        rawYaml: Optional<string>,
        namespaceId: string,
        namespaceName: string,
        public loadBalancerHostNames: string[],
        public rules: KubernetesIngressDataRule[]) {
        super(
            "KubernetesIngressData",
            annotationKeyToValueMap,
            controllerResourceReference,
            name,
            path,
            rawId,
            rawYaml,
            namespaceId,
            namespaceName);
    }
}

export class KubernetesJobData extends KubernetesWorkloadResourceData implements ITypeNameObject, IKubernetesResourceData {
    constructor(
        annotationKeyToValueMap: Dictionary<string>,
        controllerResourceReference: Optional<KubernetesResourceReference>,
        name: string,
        path: string,
        rawId: string,
        rawYaml: Optional<string>,
        namespaceId: string,
        namespaceName: string,
        podTemplateSpec: KubernetesPodTemplateSpec) {
        super(
            "KubernetesJobData",
            annotationKeyToValueMap,
            controllerResourceReference,
            name,
            path,
            rawId,
            rawYaml,
            namespaceId,
            namespaceName,
            podTemplateSpec);
    }
}

export class KubernetesNamespaceDefaultServiceAccountRoleBindingExistsRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesNamespaceDefaultServiceAccountRoleBindingExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class KubernetesNamespaceRestrictedPodSecurityStandardNotExistRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesNamespaceRestrictedPodSecurityStandardNotExistRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class KubernetesNamespaceWorkloadResourceContainerCapabilityRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesNamespaceWorkloadResourceContainerCapabilityRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class KubernetesNamespaceWorkloadResourceContainerPrivilegedRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesNamespaceWorkloadResourceContainerPrivilegedRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class KubernetesNamespaceWorkloadResourceContainerPrivilegeEscalationRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesNamespaceWorkloadResourceContainerPrivilegeEscalationRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class KubernetesNamespaceWorkloadResourceContainerRootUserEnabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesNamespaceWorkloadResourceContainerRootUserEnabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class KubernetesNamespaceWorkloadResourceContainersRisk extends KubernetesNamespaceWorkloadResourceRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        podSecurityStandard: KubernetesNamespaceDataPodSecurityStandard,
        workloadResourceIds: string[],
        public containerNames: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            podSecurityStandard,
            workloadResourceIds);
    }
}

export class KubernetesNamespaceWorkloadResourceContainersRiskModel extends KubernetesNamespaceWorkloadResourceRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: KubernetesNamespaceWorkloadResourceContainersRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class KubernetesNamespaceWorkloadResourceHostNamespaceInterProcessCommunicationEnabledRisk extends KubernetesNamespaceWorkloadResourceRisk implements ITypeNameObject, IKubernetesRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        podSecurityStandard: KubernetesNamespaceDataPodSecurityStandard,
        workloadResourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesNamespaceWorkloadResourceHostNamespaceInterProcessCommunicationEnabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            podSecurityStandard,
            workloadResourceIds);
    }
}

export class KubernetesNamespaceWorkloadResourceHostNamespaceInterProcessCommunicationEnabledRiskModel extends KubernetesNamespaceWorkloadResourceRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: KubernetesNamespaceWorkloadResourceHostNamespaceInterProcessCommunicationEnabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesNamespaceWorkloadResourceHostNamespaceInterProcessCommunicationEnabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class KubernetesNamespaceWorkloadResourceHostNamespaceInterProcessCommunicationEnabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesNamespaceWorkloadResourceHostNamespaceInterProcessCommunicationEnabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class KubernetesNamespaceWorkloadResourceHostNamespaceNetworkEnabledRisk extends KubernetesNamespaceWorkloadResourceRisk implements ITypeNameObject, IKubernetesRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        podSecurityStandard: KubernetesNamespaceDataPodSecurityStandard,
        workloadResourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesNamespaceWorkloadResourceHostNamespaceNetworkEnabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            podSecurityStandard,
            workloadResourceIds);
    }
}

export class KubernetesNamespaceWorkloadResourceHostNamespaceNetworkEnabledRiskModel extends KubernetesNamespaceWorkloadResourceRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: KubernetesNamespaceWorkloadResourceHostNamespaceNetworkEnabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesNamespaceWorkloadResourceHostNamespaceNetworkEnabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class KubernetesNamespaceWorkloadResourceHostNamespaceNetworkEnabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesNamespaceWorkloadResourceHostNamespaceNetworkEnabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class KubernetesNamespaceWorkloadResourceHostNamespaceProcessIdEnabledRisk extends KubernetesNamespaceWorkloadResourceRisk implements ITypeNameObject, IKubernetesRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        podSecurityStandard: KubernetesNamespaceDataPodSecurityStandard,
        workloadResourceIds: string[],
        public processTraceCapabilityEnabledContainers: boolean,
        public vulnerabilitySeverity: Optional<Severity>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesNamespaceWorkloadResourceHostNamespaceProcessIdEnabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            podSecurityStandard,
            workloadResourceIds);
    }
}

export class KubernetesNamespaceWorkloadResourceHostNamespaceProcessIdEnabledRiskModel extends KubernetesNamespaceWorkloadResourceRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: KubernetesNamespaceWorkloadResourceHostNamespaceProcessIdEnabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesNamespaceWorkloadResourceHostNamespaceProcessIdEnabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class KubernetesNamespaceWorkloadResourceHostNamespaceProcessIdEnabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesNamespaceWorkloadResourceHostNamespaceProcessIdEnabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class KubernetesReplicaSetData extends KubernetesWorkloadResourceData implements ITypeNameObject, IKubernetesResourceData {
    constructor(
        annotationKeyToValueMap: Dictionary<string>,
        controllerResourceReference: Optional<KubernetesResourceReference>,
        name: string,
        path: string,
        rawId: string,
        rawYaml: Optional<string>,
        namespaceId: string,
        namespaceName: string,
        podTemplateSpec: KubernetesPodTemplateSpec,
        public podLabelSelector: KubernetesLabelSelector,
        public replicaCount: Optional<number>) {
        super(
            "KubernetesReplicaSetData",
            annotationKeyToValueMap,
            controllerResourceReference,
            name,
            path,
            rawId,
            rawYaml,
            namespaceId,
            namespaceName,
            podTemplateSpec);
    }
}

export class KubernetesStatefulSetData extends KubernetesWorkloadResourceData implements ITypeNameObject, IKubernetesResourceData {
    constructor(
        annotationKeyToValueMap: Dictionary<string>,
        controllerResourceReference: Optional<KubernetesResourceReference>,
        name: string,
        path: string,
        rawId: string,
        rawYaml: Optional<string>,
        namespaceId: string,
        namespaceName: string,
        podTemplateSpec: KubernetesPodTemplateSpec) {
        super(
            "KubernetesStatefulSetData",
            annotationKeyToValueMap,
            controllerResourceReference,
            name,
            path,
            rawId,
            rawYaml,
            namespaceId,
            namespaceName,
            podTemplateSpec);
    }
}

export class KubernetesWorkloadResourceContainerPrivilegedRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesWorkloadResourceContainerPrivilegedRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class KubernetesWorkloadResourceEnvironmentVariableSecretReferenceRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesWorkloadResourceEnvironmentVariableSecretReferenceRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class ManagedDatabaseAnalysisServiceConfiguration extends ServiceConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "ManagedDatabaseAnalysisServiceConfiguration");
    }
}

export class ObjectStoreAnalysisServiceConfiguration extends ServiceConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "ObjectStoreAnalysisServiceConfiguration");
    }
}

export class ObservabilityApiServiceConfiguration extends ServiceConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "ObservabilityApiServiceConfiguration");
    }
}

export class OciBlockStorageBlockVolumeDefaultEncryptionRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciBlockStorageBlockVolumeDefaultEncryptionRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class OciBlockStorageBootVolumeDefaultEncryptionRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciBlockStorageBootVolumeDefaultEncryptionRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class OciComputeInstanceMetadataServiceVersionRisk extends AggregatedEntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        aggregatedEntityIds: string[],
        aggregatingEntityTypeName: string,
        public nonInstancePoolInstanceIds: Optional<string[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciComputeInstanceMetadataServiceVersionRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            aggregatedEntityIds,
            aggregatingEntityTypeName);
    }
}

export class OciComputeInstanceMetadataServiceVersionRiskModel extends AggregatedEntityRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: OciComputeInstanceMetadataServiceVersionRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public containerEngineClusterNodePoolInstanceMetadataServiceVersion1DocumentationUrl: string,
        public instanceMetadataServiceVersion1RequestMetricDocumentationUrl: string,
        public instanceMetadataServiceVersion2SupportedImagesDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciComputeInstanceMetadataServiceVersionRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class OciComputeInstanceMetadataServiceVersionRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciComputeInstanceMetadataServiceVersionRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class OciComputeInstanceSecureBootNotEnabledRisk extends AggregatedEntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        aggregatedEntityIds: string[],
        aggregatingEntityTypeName: string,
        public nonInstancePoolInstanceIds: Optional<string[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciComputeInstanceSecureBootNotEnabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            aggregatedEntityIds,
            aggregatingEntityTypeName);
    }
}

export class OciComputeInstanceSecureBootNotEnabledRiskModel extends AggregatedEntityRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: OciComputeInstanceSecureBootNotEnabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public instanceShieldedInstanceSupportedShapesAndImagesDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciComputeInstanceSecureBootNotEnabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class OciComputeInstanceSecureBootNotEnabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciComputeInstanceSecureBootNotEnabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class OciComputeInstanceVolumeEncryptionInTransitDisabledRisk extends AggregatedEntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        aggregatedEntityIds: string[],
        aggregatingEntityTypeName: string,
        public nonInstancePoolInstanceIds: Optional<string[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciComputeInstanceVolumeEncryptionInTransitDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            aggregatedEntityIds,
            aggregatingEntityTypeName);
    }
}

export class OciComputeInstanceVolumeEncryptionInTransitDisabledRiskModel extends AggregatedEntityRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: OciComputeInstanceVolumeEncryptionInTransitDisabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public instanceVolumeEncryptionInTransitSupportedShapesAndImagesDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciComputeInstanceVolumeEncryptionInTransitDisabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class OciComputeInstanceVolumeEncryptionInTransitDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciComputeInstanceVolumeEncryptionInTransitDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class OciFssFileSystemDefaultEncryptionRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciFssFileSystemDefaultEncryptionRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class OciFssFileSystemMountTargetExportOptionIdentitySquashRootNotEnabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciFssFileSystemMountTargetExportOptionIdentitySquashRootNotEnabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class OciIamDomain extends OciIamResource implements ITypeNameObject, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        description: Optional<string>,
        enabled: boolean,
        public consoleLoginEnabled: boolean,
        public defaultDomain: boolean,
        public licenseType: OciIamDomainLicenseType,
        public passwordPolicies: OciIamDomainPasswordPolicy[],
        public replicationRegionSystemNames: string[],
        public url: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciIamDomain",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            description,
            enabled);
    }
}

export class OciIamDomainModel extends OciIamResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciIamDomainModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class OciIamDomainPasswordExpirationPolicyRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        public passwordPolicyExpirationIntervalDays: Optional<number>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciIamDomainPasswordExpirationPolicyRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class OciIamDomainPasswordLengthPolicyRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        public passwordPolicyMinLength: Optional<number>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciIamDomainPasswordLengthPolicyRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class OciIamDomainPasswordReusePolicyRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        public passwordPolicyPreventReusePreviousPasswordCount: Optional<number>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciIamDomainPasswordReusePolicyRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class OciIamDynamicResourceGroupProfile extends OciIamPrincipalProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciIamDynamicResourceGroupProfile");
    }
}

export class OciIamGroupProfile extends OciIamPrincipalProfile implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciIamGroupProfile");
    }
}

export class OciIamPolicy extends OciIamResource implements ITypeNameObject, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        description: Optional<string>,
        enabled: boolean,
        public statements: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciIamPolicy",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            description,
            enabled);
    }
}

export class OciIamPolicyModel extends OciIamResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciIamPolicyModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class OciIamPrincipal extends OciIamResource implements ITypeNameObject, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        description: Optional<string>,
        enabled: boolean,
        public domainId: Optional<string>,
        public domainOcid: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            description,
            enabled);
    }
}

export class OciIamPrincipalModel extends OciIamResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class OciIamUser extends OciIamPrincipal implements ITypeNameObject, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        description: Optional<string>,
        enabled: boolean,
        domainId: Optional<string>,
        domainOcid: Optional<string>,
        public groupOcids: string[],
        public mail: Optional<string>,
        public mfaEnrolled: boolean,
        public signInTime: Optional<string>,
        public userName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciIamUser",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            description,
            enabled,
            domainId,
            domainOcid);
    }
}

export class OciIamUserApiKey extends OciIamUserCredential implements ITypeNameObject, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        description: Optional<string>,
        enabled: boolean,
        domainOcid: Optional<string>,
        userId: string,
        userOcid: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciIamUserApiKey",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            description,
            enabled,
            domainOcid,
            userId,
            userOcid);
    }
}

export class OciIamUserApiKeyModel extends OciIamUserCredentialModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        userId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciIamUserApiKeyModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            userId);
    }
}

export class OciIamUserApiKeyNotRotatedRisk extends OciIamUserCredentialNotRotatedRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        credentialRotationTimeFrame: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciIamUserApiKeyNotRotatedRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            credentialRotationTimeFrame);
    }
}

export class OciIamUserAuthToken extends OciIamUserCredential implements ITypeNameObject, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        description: Optional<string>,
        enabled: boolean,
        domainOcid: Optional<string>,
        userId: string,
        userOcid: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciIamUserAuthToken",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            description,
            enabled,
            domainOcid,
            userId,
            userOcid);
    }
}

export class OciIamUserAuthTokenModel extends OciIamUserCredentialModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        userId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciIamUserAuthTokenModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            userId);
    }
}

export class OciIamUserAuthTokenNotRotatedRisk extends OciIamUserCredentialNotRotatedRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        credentialRotationTimeFrame: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciIamUserAuthTokenNotRotatedRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            credentialRotationTimeFrame);
    }
}

export class OciIamUserCredentialNotRotatedRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        public credentialRotationTimeFrame: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class OciIamUserDatabasePasswordNotRotatedRiskPolicyConfiguration extends OciIamUserCredentialNotRotatedRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        credentialRotationTimeFrame: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciIamUserDatabasePasswordNotRotatedRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            credentialRotationTimeFrame);
    }
}

export class OciIamUserModel extends OciIamPrincipalModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        public apiKeyIds: string[],
        public authTokenIds: string[],
        public databasePasswordIds: string[],
        public groupIds: string[],
        public secretKeyIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciIamUserModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class OciIamUserOrganizationAdministratorApiKeyExistsRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciIamUserOrganizationAdministratorApiKeyExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class OciIamUserSecretKeyNotRotatedRiskPolicyConfiguration extends OciIamUserCredentialNotRotatedRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        credentialRotationTimeFrame: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciIamUserSecretKeyNotRotatedRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            credentialRotationTimeFrame);
    }
}

export class OciKmsVaultKey extends OciKmsVaultObject implements ITypeNameObject, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        vaultOcid: string,
        public algorithm: OciKmsVaultKeyAlgorithm,
        public currentVersionOcid: string,
        public curveType: Optional<OciKmsVaultKeyCurveType>,
        public keyLength: Optional<number>,
        public nextRotationTime: Optional<string>,
        public protectionMode: OciKmsVaultKeyProtectionMode,
        public rotationEnabled: boolean,
        public rotationTimeFrame: Optional<string>,
        public status: OciKmsVaultKeyStatus,
        public versionRawOcidToVersionMap: Dictionary<OciKmsVaultKeyVersion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciKmsVaultKey",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            vaultOcid);
    }
}

export class OciKmsVaultKeyModel extends OciKmsVaultObjectModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        vaultIdReference: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciKmsVaultKeyModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            vaultIdReference);
    }
}

export class OciKmsVaultKeyNotRotatedRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        public keyRotationTimeFrame: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciKmsVaultKeyNotRotatedRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class OciNetworkingNetworkSecurityGroupInboundRuleSubnetAnyRdpExistsRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciNetworkingNetworkSecurityGroupInboundRuleSubnetAnyRdpExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class OciNetworkingNetworkSecurityGroupInboundRuleSubnetAnySshExistsRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciNetworkingNetworkSecurityGroupInboundRuleSubnetAnySshExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class OciNetworkingSecurityListInboundRuleSubnetAnyRdpExistsRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciNetworkingSecurityListInboundRuleSubnetAnyRdpExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class OciNetworkingSecurityListInboundRuleSubnetAnySshExistsRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciNetworkingSecurityListInboundRuleSubnetAnySshExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class OciNetworkingVcnFlowLogsNotEnabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciNetworkingVcnFlowLogsNotEnabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class OciObjectStorageBucketDefaultEncryptionRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciObjectStorageBucketDefaultEncryptionRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class OciObjectStorageBucketLogCategoryWriteNotExistsRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciObjectStorageBucketLogCategoryWriteNotExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class OciObjectStorageBucketObjectEventsDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciObjectStorageBucketObjectEventsDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class OciObjectStorageBucketObjectVersioningDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciObjectStorageBucketObjectVersioningDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class OciObjectStorageBucketPublicAccessExistsRisk extends PublicEntityRisk implements ITypeNameObject, IPublicRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciObjectStorageBucketPublicAccessExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class OciObjectStorageBucketPublicAccessExistsRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciObjectStorageBucketPublicAccessExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class OktaDirectoryApplication extends OktaDirectoryEntity implements ITypeNameObject, IDirectoryEntity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        rawId: string,
        creationTime: Optional<string>,
        public name: string,
        public signOnMode: Optional<OktaDirectoryApplicationSignOnMode>,
        public status: OktaDirectoryApplicationStatus) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            rawId,
            creationTime);
    }
}

export class OktaDirectoryAwsApplication extends OktaDirectoryApplication implements ITypeNameObject, IDirectoryEntity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        rawId: string,
        creationTime: Optional<string>,
        name: string,
        signOnMode: Optional<OktaDirectoryApplicationSignOnMode>,
        status: OktaDirectoryApplicationStatus,
        public awsTenantType: OktaDirectoryAwsApplicationAwsTenantType,
        public groupIdToAssignmentMap: Dictionary<OktaDirectoryAwsApplicationGroupAssignment>,
        public multiAwsTenant: OktaDirectoryAwsApplicationMultiAwsTenant,
        public singleAwsTenant: OktaDirectoryAwsApplicationSingleAwsTenant,
        public userIdToAssignmentMap: Dictionary<OktaDirectoryAwsApplicationUserAssignment>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OktaDirectoryAwsApplication",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            rawId,
            creationTime,
            name,
            signOnMode,
            status);
    }
}

export class OktaDirectoryAwsApplicationModel extends OktaDirectoryEntityModel implements ITypeNameObject, IEntityModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OktaDirectoryAwsApplicationModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown);
    }
}

export class OktaDirectoryAwsGenericApplication extends OktaDirectoryApplication implements ITypeNameObject, IDirectoryEntity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        rawId: string,
        creationTime: Optional<string>,
        name: string,
        signOnMode: Optional<OktaDirectoryApplicationSignOnMode>,
        status: OktaDirectoryApplicationStatus,
        public attributeNameToValueMap: Dictionary<string>,
        public groupIdToAssignmentMap: Dictionary<OktaDirectoryAwsGenericApplicationGroupAssignment>,
        public userIdToAssignmentMap: Dictionary<OktaDirectoryAwsGenericApplicationUserAssignment>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OktaDirectoryAwsGenericApplication",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            rawId,
            creationTime,
            name,
            signOnMode,
            status);
    }
}

export class OktaDirectoryAwsGenericApplicationModel extends OktaDirectoryEntityModel implements ITypeNameObject, IEntityModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OktaDirectoryAwsGenericApplicationModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown);
    }
}

export class OktaDirectoryAwsSsoApplication extends OktaDirectoryApplication implements ITypeNameObject, IDirectoryEntity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        rawId: string,
        creationTime: Optional<string>,
        name: string,
        signOnMode: Optional<OktaDirectoryApplicationSignOnMode>,
        status: OktaDirectoryApplicationStatus,
        public acsUrl: Optional<string>,
        public awsSsoIdentityStoreRawId: Optional<string>,
        public entityId: Optional<string>,
        public groupIdToAssignmentMap: Dictionary<OktaDirectoryAwsSsoApplicationGroupAssignment>,
        public userIdToAssignmentMap: Dictionary<OktaDirectoryAwsSsoApplicationUserAssignment>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OktaDirectoryAwsSsoApplication",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            rawId,
            creationTime,
            name,
            signOnMode,
            status);
    }
}

export class OktaDirectoryAwsSsoApplicationModel extends OktaDirectoryEntityModel implements ITypeNameObject, IEntityModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OktaDirectoryAwsSsoApplicationModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown);
    }
}

export class OktaDirectoryGroup extends OktaDirectoryPrincipal implements ITypeNameObject, IDirectoryGroup, IPermissionManagementGroup {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        rawId: string,
        creationTime: Optional<string>,
        public description: Optional<string>,
        public mail: Optional<string>,
        public name: string,
        public type: OktaDirectoryGroupType,
        public userIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OktaDirectoryGroup",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            rawId,
            creationTime);
    }
}

export class OktaDirectoryGroupMembershipEligibilityConfiguration extends GroupMembershipEligibilityConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        approval: Optional<PermissionEligibilityConfigurationApproval>,
        description: Optional<string>,
        expirationTimeFrame: string,
        name: string,
        principalTenantId: string,
        reasonRequired: boolean,
        groupReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OktaDirectoryGroupMembershipEligibilityConfiguration",
            scopeId,
            approval,
            description,
            expirationTimeFrame,
            name,
            principalTenantId,
            reasonRequired,
            groupReferences);
    }
}

export class OktaDirectoryGroupModel extends OktaDirectoryPrincipalModel implements ITypeNameObject, IUdmObjectModel, IPermissionManagementGroupModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        awsRoleIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OktaDirectoryGroupModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            awsRoleIds);
    }
}

export class OneLoginDirectoryApplication extends OneLoginDirectoryEntity implements ITypeNameObject, IDirectoryEntity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        rawId: number,
        creationTime: Optional<string>,
        public awsSamlIdentityProviderArns: string[],
        public name: string,
        public parameterMap: Dictionary<OneLoginDirectoryApplicationParameter>,
        public rules: OneLoginDirectoryApplicationRule[],
        public type: OneLoginDirectoryApplicationType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OneLoginDirectoryApplication",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            rawId,
            creationTime);
    }
}

export class OneLoginDirectoryApplicationModel extends OneLoginDirectoryEntityModel implements ITypeNameObject, IEntityModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OneLoginDirectoryApplicationModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown);
    }
}

export class OneLoginDirectoryGroup extends OneLoginDirectoryPrincipal implements ITypeNameObject, IDirectoryGroup, IPermissionManagementGroup {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        rawId: number,
        creationTime: Optional<string>,
        public mail: Optional<string>,
        public name: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OneLoginDirectoryGroup",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            rawId,
            creationTime);
    }
}

export class OneLoginDirectoryGroupModel extends OneLoginDirectoryPrincipalModel implements ITypeNameObject, IUdmObjectModel, IPermissionManagementGroupModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        public userIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OneLoginDirectoryGroupModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown);
    }
}

export class OneLoginDirectoryRoleAssignmentEligibilityConfiguration extends PermissionEligibilityConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        approval: Optional<PermissionEligibilityConfigurationApproval>,
        description: Optional<string>,
        expirationTimeFrame: string,
        name: string,
        principalTenantId: string,
        reasonRequired: boolean,
        public roleReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OneLoginDirectoryRoleAssignmentEligibilityConfiguration",
            scopeId,
            approval,
            description,
            expirationTimeFrame,
            name,
            principalTenantId,
            reasonRequired);
    }
}

export class OneLoginDirectoryRoleAssignmentEligibilityModel extends PermissionEligibilityModel implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        public configuration: OneLoginDirectoryRoleAssignmentEligibilityConfiguration,
        scopeId: string,
        state: Optional<SystemEntityState>,
        approverPrincipalIdReferences: string[],
        existingGranteePrincipalIds: string[],
        granteePrincipalIdReferences: string[],
        levelToApproverPrincipalIdReferencesMap: Dictionary<string[]>,
        levelToExistingApproverPrincipalIdsMap: Dictionary<string[]>,
        public existingRoleIds: string[],
        public roleIdReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OneLoginDirectoryRoleAssignmentEligibilityModel",
            configuration,
            scopeId,
            state,
            approverPrincipalIdReferences,
            existingGranteePrincipalIds,
            granteePrincipalIdReferences,
            levelToApproverPrincipalIdReferencesMap,
            levelToExistingApproverPrincipalIdsMap);
    }
}

export class OpKubernetesClusterRoleBindingModel extends OpKubernetesResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesRoleBindingModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        public principalIdReferences: string[],
        public roleIdReference: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpKubernetesClusterRoleBindingModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class OpKubernetesClusterRoleModel extends OpKubernetesResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesRoleModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        public principalIdReferences: string[],
        public roleBindingIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpKubernetesClusterRoleModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class OpKubernetesGroupModel extends OpKubernetesResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesResourceModel, IKubernetesPrincipalModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        public roleBindingIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpKubernetesGroupModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class OpKubernetesIngressClassModel extends OpKubernetesResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpKubernetesIngressClassModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class OpKubernetesNamespaceModel extends OpKubernetesResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesNamespaceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        public resourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpKubernetesNamespaceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class OpKubernetesNamespaceResourceModel extends OpKubernetesResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesNamespaceResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class OpKubernetesNamespaceRoleBindingModel extends OpKubernetesNamespaceResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesNamespaceResourceModel, IKubernetesRoleBindingModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        public principalIdReferences: string[],
        public roleIdReference: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpKubernetesNamespaceRoleBindingModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class OpKubernetesNamespaceRoleModel extends OpKubernetesNamespaceResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesNamespaceResourceModel, IKubernetesRoleModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        public principalIdReferences: string[],
        public roleBindingIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpKubernetesNamespaceRoleModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class OpKubernetesNetworkPolicyModel extends OpKubernetesNamespaceResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesNamespaceResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpKubernetesNetworkPolicyModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class OpKubernetesNodeModel extends OpKubernetesResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesNodeModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpKubernetesNodeModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class OpKubernetesPersistentVolumeModel extends OpKubernetesResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpKubernetesPersistentVolumeModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class OpKubernetesPodModel extends OpKubernetesNamespaceResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesNamespaceResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpKubernetesPodModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class OpKubernetesPodSecurityPolicyModel extends OpKubernetesResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpKubernetesPodSecurityPolicyModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class OpKubernetesPodTemplateModel extends OpKubernetesNamespaceResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesNamespaceResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpKubernetesPodTemplateModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class OpKubernetesResource<TKubernetesResourceData extends KubernetesResourceData> extends OpKubernetesResourceBase implements ITypeNameObject, IKubernetesResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: TKubernetesResourceData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class OpKubernetesServiceAccountModel extends OpKubernetesNamespaceResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesServiceAccountModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        public originatorWorkloadResourceIds: string[],
        public roleBindingIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpKubernetesServiceAccountModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class OpKubernetesServiceModel extends OpKubernetesNamespaceResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesServiceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        public ingressIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpKubernetesServiceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class OpKubernetesUser extends OpKubernetesResource<KubernetesUserData> implements ITypeNameObject, IKubernetesUser {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesUserData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpKubernetesUser",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class OpKubernetesWorkloadResourceModel extends OpKubernetesNamespaceResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesWorkloadResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        public containerImageIdReferences: string[],
        public containerImageRawUrlToDataMap: Dictionary<ContainerImageResourceStateContainerImageData>,
        public containerImageScanStatus: Optional<ContainerImageDataScanStatus>,
        public containerImageVulnerabilities: VulnerabilityData[],
        public controllerResourceReference: Optional<EntitySearchableIdReference>,
        public serviceAccountReference: EntitySearchableIdReference) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class OpOpenShiftBuildConfigModel extends OpKubernetesNamespaceResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesNamespaceResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpOpenShiftBuildConfigModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class OpOpenShiftCatalogSourceModel extends OpKubernetesNamespaceResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesNamespaceResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpOpenShiftCatalogSourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class OpOpenShiftDeploymentConfigModel extends OpKubernetesWorkloadResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesWorkloadResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        containerImageIdReferences: string[],
        containerImageRawUrlToDataMap: Dictionary<ContainerImageResourceStateContainerImageData>,
        containerImageScanStatus: Optional<ContainerImageDataScanStatus>,
        containerImageVulnerabilities: VulnerabilityData[],
        controllerResourceReference: Optional<EntitySearchableIdReference>,
        serviceAccountReference: EntitySearchableIdReference) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpOpenShiftDeploymentConfigModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            containerImageIdReferences,
            containerImageRawUrlToDataMap,
            containerImageScanStatus,
            containerImageVulnerabilities,
            controllerResourceReference,
            serviceAccountReference);
    }
}

export class OpOpenShiftImageStreamModel extends OpKubernetesNamespaceResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesNamespaceResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpOpenShiftImageStreamModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class OpOpenShiftProject extends OpKubernetesResource<OpenShiftProjectData> implements ITypeNameObject, IOpenShiftProject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: OpenShiftProjectData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpOpenShiftProject",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class OpOpenShiftRouteModel extends OpKubernetesNamespaceResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesNamespaceResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpOpenShiftRouteModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class OpOpenShiftSecurityContextConstraint extends OpKubernetesResource<OpenShiftSecurityContextConstraintData> implements ITypeNameObject, IOpenShiftSecurityContextConstraint {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: OpenShiftSecurityContextConstraintData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpOpenShiftSecurityContextConstraint",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class OpOpenShiftTemplateModel extends OpKubernetesNamespaceResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesNamespaceResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpOpenShiftTemplateModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class OrchestrationServiceConfiguration extends ServiceConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        public jobManager: JobManagerConfigurationSection,
        public orchestrator: OrchestratorConfigurationSection) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OrchestrationServiceConfiguration");
    }
}

export class OutpostApiServiceConfiguration extends ServiceConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OutpostApiServiceConfiguration");
    }
}

export class PartialAnonymousIdentity extends PartialIdentity implements ITypeNameObject, IPartialEntity, IUser {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "PartialAnonymousIdentity",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name);
    }
}

export class PermissionAssignmentEligibilityConfiguration extends PermissionEligibilityConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        approval: Optional<PermissionEligibilityConfigurationApproval>,
        description: Optional<string>,
        expirationTimeFrame: string,
        name: string,
        principalTenantId: string,
        reasonRequired: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            approval,
            description,
            expirationTimeFrame,
            name,
            principalTenantId,
            reasonRequired);
    }
}

export class PermissionAssignmentEligibilityModel extends PermissionEligibilityModel implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        public configuration: PermissionAssignmentEligibilityConfiguration,
        scopeId: string,
        state: Optional<SystemEntityState>,
        approverPrincipalIdReferences: string[],
        existingGranteePrincipalIds: string[],
        granteePrincipalIdReferences: string[],
        levelToApproverPrincipalIdReferencesMap: Dictionary<string[]>,
        levelToExistingApproverPrincipalIdsMap: Dictionary<string[]>,
        public permissionRequestScopeIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            configuration,
            scopeId,
            state,
            approverPrincipalIdReferences,
            existingGranteePrincipalIds,
            granteePrincipalIdReferences,
            levelToApproverPrincipalIdReferencesMap,
            levelToExistingApproverPrincipalIdsMap);
    }
}

export class PermissionEligibilityAuditEventAwsSsoPermissionSetAssignmentEligibilityData extends PermissionEligibilityAuditEventPermissionAssignmentEligibilityData implements ITypeNameObject, IPermissionEligibilityAuditEventPermissionAssignmentEligibilityData {
    constructor(
        expirationTimeFrame: string,
        name: string,
        principalTenantId: string,
        reasonRequired: boolean,
        permissionRequestScopePaths: string[],
        public existingInlinePolicyDocument: Optional<AwsIamPolicyDocument>,
        public inlinePolicyDocument: Optional<AwsIamPolicyDocument>) {
        super(
            "PermissionEligibilityAuditEventAwsSsoPermissionSetAssignmentEligibilityData",
            expirationTimeFrame,
            name,
            principalTenantId,
            reasonRequired,
            permissionRequestScopePaths);
    }
}

export class PermissionEligibilityAuditEventAzureRoleAssignmentEligibilityData extends PermissionEligibilityAuditEventPermissionAssignmentEligibilityData implements ITypeNameObject, IPermissionEligibilityAuditEventPermissionAssignmentEligibilityData {
    constructor(
        expirationTimeFrame: string,
        name: string,
        principalTenantId: string,
        reasonRequired: boolean,
        permissionRequestScopePaths: string[]) {
        super(
            "PermissionEligibilityAuditEventAzureRoleAssignmentEligibilityData",
            expirationTimeFrame,
            name,
            principalTenantId,
            reasonRequired,
            permissionRequestScopePaths);
    }
}

export class PermissionEligibilityAuditEventGcpRoleBindingEligibilityData extends PermissionEligibilityAuditEventPermissionAssignmentEligibilityData implements ITypeNameObject, IPermissionEligibilityAuditEventPermissionAssignmentEligibilityData {
    constructor(
        expirationTimeFrame: string,
        name: string,
        principalTenantId: string,
        reasonRequired: boolean,
        permissionRequestScopePaths: string[]) {
        super(
            "PermissionEligibilityAuditEventGcpRoleBindingEligibilityData",
            expirationTimeFrame,
            name,
            principalTenantId,
            reasonRequired,
            permissionRequestScopePaths);
    }
}

export class PermissionEligibilityUpdateAuditEventAwsSsoPermissionSetAssignmentEligibilityChanges extends PermissionEligibilityUpdateAuditEventPermissionAssignmentEligibilityChanges implements ITypeNameObject {
    constructor(
        approvalRequiredChanged: boolean,
        expirationTimeFrameChanged: boolean,
        granteePrincipalsChanged: boolean,
        levelToApproverPrincipalsMapChanged: boolean,
        nameChanged: boolean,
        principalTenantChanged: boolean,
        reasonRequiredChanged: boolean,
        permissionRequestScopesChanged: boolean,
        public permissionsChange: Optional<PermissionEligibilityUpdateAuditEventAwsSsoPermissionSetAssignmentEligibilityChangesPermissionsChange>) {
        super(
            "PermissionEligibilityUpdateAuditEventAwsSsoPermissionSetAssignmentEligibilityChanges",
            approvalRequiredChanged,
            expirationTimeFrameChanged,
            granteePrincipalsChanged,
            levelToApproverPrincipalsMapChanged,
            nameChanged,
            principalTenantChanged,
            reasonRequiredChanged,
            permissionRequestScopesChanged);
    }
}

export class PermissionEligibilityUpdateAuditEventAzureRoleAssignmentEligibilityChanges extends PermissionEligibilityUpdateAuditEventPermissionAssignmentEligibilityChanges implements ITypeNameObject {
    constructor(
        approvalRequiredChanged: boolean,
        expirationTimeFrameChanged: boolean,
        granteePrincipalsChanged: boolean,
        levelToApproverPrincipalsMapChanged: boolean,
        nameChanged: boolean,
        principalTenantChanged: boolean,
        reasonRequiredChanged: boolean,
        permissionRequestScopesChanged: boolean,
        public roleDefinitionsChanged: boolean) {
        super(
            "PermissionEligibilityUpdateAuditEventAzureRoleAssignmentEligibilityChanges",
            approvalRequiredChanged,
            expirationTimeFrameChanged,
            granteePrincipalsChanged,
            levelToApproverPrincipalsMapChanged,
            nameChanged,
            principalTenantChanged,
            reasonRequiredChanged,
            permissionRequestScopesChanged);
    }
}

export class PermissionEligibilityUpdateAuditEventGcpRoleBindingEligibilityChanges extends PermissionEligibilityUpdateAuditEventPermissionAssignmentEligibilityChanges implements ITypeNameObject {
    constructor(
        approvalRequiredChanged: boolean,
        expirationTimeFrameChanged: boolean,
        granteePrincipalsChanged: boolean,
        levelToApproverPrincipalsMapChanged: boolean,
        nameChanged: boolean,
        principalTenantChanged: boolean,
        reasonRequiredChanged: boolean,
        permissionRequestScopesChanged: boolean,
        public rolesChanged: boolean) {
        super(
            "PermissionEligibilityUpdateAuditEventGcpRoleBindingEligibilityChanges",
            approvalRequiredChanged,
            expirationTimeFrameChanged,
            granteePrincipalsChanged,
            levelToApproverPrincipalsMapChanged,
            nameChanged,
            principalTenantChanged,
            reasonRequiredChanged,
            permissionRequestScopesChanged);
    }
}

export class PingIdentityDirectoryApplication extends PingIdentityDirectoryEntity implements ITypeNameObject, IDirectoryEntity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        rawId: string,
        creationTime: Optional<string>,
        public groups: Optional<PingIdentityDirectoryApplicationGroups>,
        public name: string,
        public parameterMap: Dictionary<PingIdentityDirectoryApplicationParameter>,
        public type: PingIdentityDirectoryApplicationType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "PingIdentityDirectoryApplication",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            rawId,
            creationTime);
    }
}

export class PingIdentityDirectoryApplicationModel extends PingIdentityDirectoryEntityModel implements ITypeNameObject, IEntityModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "PingIdentityDirectoryApplicationModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown);
    }
}

export class PingIdentityDirectoryGroup extends PingIdentityDirectoryPrincipal implements ITypeNameObject, IDirectoryGroup, IPermissionManagementGroup {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        rawId: string,
        creationTime: Optional<string>,
        public description: Optional<string>,
        public directParentGroupsIds: string[],
        public mail: Optional<string>,
        public name: string,
        public updateTime: string,
        public userFilter: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "PingIdentityDirectoryGroup",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            rawId,
            creationTime);
    }
}

export class PingIdentityDirectoryGroupMembershipEligibilityConfiguration extends GroupMembershipEligibilityConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        approval: Optional<PermissionEligibilityConfigurationApproval>,
        description: Optional<string>,
        expirationTimeFrame: string,
        name: string,
        principalTenantId: string,
        reasonRequired: boolean,
        groupReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "PingIdentityDirectoryGroupMembershipEligibilityConfiguration",
            scopeId,
            approval,
            description,
            expirationTimeFrame,
            name,
            principalTenantId,
            reasonRequired,
            groupReferences);
    }
}

export class PingIdentityDirectoryGroupModel extends PingIdentityDirectoryPrincipalModel implements ITypeNameObject, IUdmObjectModel, IPermissionManagementGroupModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        public memberPrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "PingIdentityDirectoryGroupModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown);
    }
}

export class ProvisionedManagedDatabaseAnalysisServiceConfiguration extends ServiceConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "ProvisionedManagedDatabaseAnalysisServiceConfiguration");
    }
}

export class ResourceAnalysisManagementServiceConfiguration extends ServiceConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        public resourceAnalysisScanManager: ResourceAnalysisScanManagerConfigurationSection) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "ResourceAnalysisManagementServiceConfiguration");
    }
}

export class ResourceAnalysisScanningServiceConfiguration extends ServiceConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "ResourceAnalysisScanningServiceConfiguration");
    }
}

export class ResourceCodeResourceNotExistsRiskPolicyConfiguration extends CustomRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        description: Optional<string>,
        name: string,
        severity: Severity,
        updateTime: string,
        public allResources: boolean,
        public resourceBuiltInAttributeTypeNames: Optional<string[]>,
        public resourceCustomAttributeDefinitionIds: Optional<string[]>,
        public resourceIds: Optional<string[]>,
        public resourceTags: Optional<ResourceTag[]>,
        public resourceTypeNames: Optional<string[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            description,
            name,
            severity,
            updateTime);
    }
}

export class ResourceTagNotExistsRiskPolicyConfiguration extends CustomRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        description: Optional<string>,
        name: string,
        severity: Severity,
        updateTime: string,
        public allResources: boolean,
        public existsResourceTagKeys: Optional<string[]>,
        public existsResourceTagPattern: Optional<ResourceTagPattern>,
        public existsResourceTags: Optional<ResourceTag[]>,
        public resourceBuiltInAttributeTypeNames: Optional<string[]>,
        public resourceCustomAttributeDefinitionIds: Optional<string[]>,
        public resourceIds: Optional<string[]>,
        public resourceTags: Optional<ResourceTag[]>,
        public resourceTypeNames: Optional<string[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            description,
            name,
            severity,
            updateTime);
    }
}

export class RiskControllerAadDirectoryGroupInactiveRiskPolicyTypeFilters extends RiskControllerCloudRiskPolicyTypeFilters implements ITypeNameObject {
    constructor(
        riskPolicyConfigurationTypeName: string,
        riskedEntityIdSelection: Optional<PagedItemSelection<string>>,
        public groupIdentityExists: Optional<boolean>) {
        super(
            "RiskControllerAadDirectoryGroupInactiveRiskPolicyTypeFilters",
            riskPolicyConfigurationTypeName,
            riskedEntityIdSelection);
    }
}

export class RiskControllerAwsBehaviorIdentityRiskPolicyTypeFilters extends RiskControllerCloudRiskPolicyTypeFilters implements ITypeNameObject {
    constructor(
        riskPolicyConfigurationTypeName: string,
        riskedEntityIdSelection: Optional<PagedItemSelection<string>>,
        public eventActionSelection: Optional<ItemSelection<string>>,
        public resourceIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            "RiskControllerAwsBehaviorIdentityRiskPolicyTypeFilters",
            riskPolicyConfigurationTypeName,
            riskedEntityIdSelection);
    }
}

export class RiskControllerAwsEc2InstanceMetadataServiceVersionRiskPolicyTypeFilters extends RiskControllerCloudRiskPolicyTypeFilters implements ITypeNameObject {
    constructor(
        riskPolicyConfigurationTypeName: string,
        riskedEntityIdSelection: Optional<PagedItemSelection<string>>,
        public instanceMetadataServiceVersionV1Used: Optional<boolean>,
        public riskedEntityTypeNameSelection: Optional<ItemSelection<string>>) {
        super(
            "RiskControllerAwsEc2InstanceMetadataServiceVersionRiskPolicyTypeFilters",
            riskPolicyConfigurationTypeName,
            riskedEntityIdSelection);
    }
}

export class RiskControllerAwsEc2NetworkResourceInboundRuleSubnetAnyExistsRiskPolicyTypeFilters extends RiskControllerCloudRiskPolicyTypeFilters implements ITypeNameObject {
    constructor(
        riskPolicyConfigurationTypeName: string,
        riskedEntityIdSelection: Optional<PagedItemSelection<string>>,
        public unrestrictedDestinationNetworkScopeFilterIdSelection: Optional<ItemSelection<string>>) {
        super(
            "RiskControllerAwsEc2NetworkResourceInboundRuleSubnetAnyExistsRiskPolicyTypeFilters",
            riskPolicyConfigurationTypeName,
            riskedEntityIdSelection);
    }
}

export class RiskControllerAwsExcessivePermissionRiskPolicyTypeFilters extends RiskControllerCloudRiskPolicyTypeFilters implements ITypeNameObject {
    constructor(
        riskPolicyConfigurationTypeName: string,
        riskedEntityIdSelection: Optional<PagedItemSelection<string>>,
        public excessivePermissionServiceIdSelection: Optional<ItemSelection<string>>,
        public groupUserIdSelection: Optional<PagedItemSelection<string>>,
        public nonexcessivePermissionServiceIdSelection: Optional<ItemSelection<string>>) {
        super(
            "RiskControllerAwsExcessivePermissionRiskPolicyTypeFilters",
            riskPolicyConfigurationTypeName,
            riskedEntityIdSelection);
    }
}

export class RiskControllerAwsIamGroupInactiveRiskPolicyTypeFilters extends RiskControllerCloudRiskPolicyTypeFilters implements ITypeNameObject {
    constructor(
        riskPolicyConfigurationTypeName: string,
        riskedEntityIdSelection: Optional<PagedItemSelection<string>>,
        public groupUserExists: Optional<boolean>) {
        super(
            "RiskControllerAwsIamGroupInactiveRiskPolicyTypeFilters",
            riskPolicyConfigurationTypeName,
            riskedEntityIdSelection);
    }
}

export class RiskControllerAwsIamRoleVendorAssumeRolePolicyDocumentExternalIdConditionNotExistRiskPolicyTypeFilters extends RiskControllerCloudRiskPolicyTypeFilters implements ITypeNameObject {
    constructor(
        riskPolicyConfigurationTypeName: string,
        riskedEntityIdSelection: Optional<PagedItemSelection<string>>,
        public permissionsRiskSeveritySelection: Optional<ItemSelection<Severity>>) {
        super(
            "RiskControllerAwsIamRoleVendorAssumeRolePolicyDocumentExternalIdConditionNotExistRiskPolicyTypeFilters",
            riskPolicyConfigurationTypeName,
            riskedEntityIdSelection);
    }
}

export class RiskControllerAwsLambdaFunctionConfigurationPublicAccessExistsRiskPolicyTypeFilters extends RiskControllerCloudRiskPolicyTypeFilters implements ITypeNameObject {
    constructor(
        riskPolicyConfigurationTypeName: string,
        riskedEntityIdSelection: Optional<PagedItemSelection<string>>,
        public publicAccessTypeSelection: Optional<ItemSelection<AwsLambdaFunctionConfigurationModelAccessType>>,
        public triggerResourceIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            "RiskControllerAwsLambdaFunctionConfigurationPublicAccessExistsRiskPolicyTypeFilters",
            riskPolicyConfigurationTypeName,
            riskedEntityIdSelection);
    }
}

export class RiskControllerAwsSecretsManagerSecretRotationDisabledRiskPolicyTypeFilters extends RiskControllerCloudRiskPolicyTypeFilters implements ITypeNameObject {
    constructor(
        riskPolicyConfigurationTypeName: string,
        riskedEntityIdSelection: Optional<PagedItemSelection<string>>,
        public creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public rotationTimeRangeSelection: Optional<TimeRangeSelection>,
        public usageTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            "RiskControllerAwsSecretsManagerSecretRotationDisabledRiskPolicyTypeFilters",
            riskPolicyConfigurationTypeName,
            riskedEntityIdSelection);
    }
}

export class RiskControllerAzureBehaviorIdentityRiskPolicyTypeFilters extends RiskControllerCloudRiskPolicyTypeFilters implements ITypeNameObject {
    constructor(
        riskPolicyConfigurationTypeName: string,
        riskedEntityIdSelection: Optional<PagedItemSelection<string>>,
        public eventActionSelection: Optional<ItemSelection<string>>,
        public resourceIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            "RiskControllerAzureBehaviorIdentityRiskPolicyTypeFilters",
            riskPolicyConfigurationTypeName,
            riskedEntityIdSelection);
    }
}

export class RiskControllerCloudCommonRiskPolicyTypeFilters extends RiskControllerCloudRiskPolicyTypeFilters implements ITypeNameObject {
    constructor(
        riskPolicyConfigurationTypeName: string,
        riskedEntityIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            "RiskControllerCloudCommonRiskPolicyTypeFilters",
            riskPolicyConfigurationTypeName,
            riskedEntityIdSelection);
    }
}

export class SecretExistsRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        public exclusionSecretPatterns: Optional<SecretExistsRiskPolicyConfigurationSecretExclusionSecretPattern[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class ServiceIdentityExcessivePermissionEvaluationTimeFrameConfiguration extends AccessSettingConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        public timeFrame: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "ServiceIdentityExcessivePermissionEvaluationTimeFrameConfiguration",
            scopeId);
    }
}

export class UserExcessivePermissionEvaluationTimeFrameConfiguration extends AccessSettingConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        public timeFrame: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "UserExcessivePermissionEvaluationTimeFrameConfiguration",
            scopeId);
    }
}

export class VirtualMachineImageOperatingSystemEndOfLifeRiskModel extends WorkloadAnalysisRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel, IVirtualMachineImageRiskModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: VirtualMachineImageOperatingSystemEndOfLifeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "VirtualMachineImageOperatingSystemEndOfLifeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class VirtualMachineImageOperatingSystemEndOfLifeRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "VirtualMachineImageOperatingSystemEndOfLifeRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class VirtualMachineImageOperatingSystemUnpatchedRiskModel extends WorkloadAnalysisRiskModel implements ITypeNameObject, IUdmObjectModel, IVirtualMachineImageRiskModel, IWorkloadResourceOperatingSystemUnpatchedRiskModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: VirtualMachineImageOperatingSystemUnpatchedRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "VirtualMachineImageOperatingSystemUnpatchedRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class VirtualMachineImageOperatingSystemUnpatchedRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "VirtualMachineImageOperatingSystemUnpatchedRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class VirtualMachineImageVulnerabilityRiskModel extends WorkloadAnalysisRiskModel implements ITypeNameObject, IUdmObjectModel, IVirtualMachineImageRiskModel, IWorkloadResourceVulnerabilityRiskModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: VirtualMachineImageVulnerabilityRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "VirtualMachineImageVulnerabilityRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class VirtualMachineImageVulnerabilityRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "VirtualMachineImageVulnerabilityRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class VirtualMachineImageWorkloadAnalysisRisk extends WorkloadAnalysisRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        criticalVulnerabilities: WorkloadResourceRiskVulnerability[],
        highVulnerabilities: WorkloadResourceRiskVulnerability[],
        singleVirtualMachine: boolean,
        virtualMachineIds: string[],
        vulnerabilitySeverityToCountMap: Dictionary<number>,
        windowsUpdateDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            criticalVulnerabilities,
            highVulnerabilities,
            singleVirtualMachine,
            virtualMachineIds,
            vulnerabilitySeverityToCountMap,
            windowsUpdateDocumentationUrl);
    }
}

export class VirtualMachineMaliciousFileRiskModel extends WorkloadAnalysisRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: VirtualMachineMaliciousFileRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "VirtualMachineMaliciousFileRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class VirtualMachineMaliciousFileRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "VirtualMachineMaliciousFileRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class VirtualMachineOperatingSystemEndOfLifeRiskModel extends WorkloadAnalysisRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: VirtualMachineOperatingSystemEndOfLifeRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "VirtualMachineOperatingSystemEndOfLifeRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class VirtualMachineOperatingSystemEndOfLifeRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "VirtualMachineOperatingSystemEndOfLifeRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class VirtualMachineOperatingSystemUnpatchedRiskModel extends WorkloadAnalysisRiskModel implements ITypeNameObject, IUdmObjectModel, IWorkloadResourceOperatingSystemUnpatchedRiskModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: VirtualMachineOperatingSystemUnpatchedRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "VirtualMachineOperatingSystemUnpatchedRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class VirtualMachineOperatingSystemUnpatchedRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "VirtualMachineOperatingSystemUnpatchedRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class VirtualMachinePackageVulnerabilitiesReportDefinitionConfiguration extends WorkloadResourcePackageVulnerabilitiesReportDefinitionConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        contentType: ReportContentType,
        exportReport: boolean,
        licenseType: ApplicationCustomerConfigurationLicensingLicenseType,
        name: Optional<string>,
        timeZoneInfoId: string,
        type: ReportType,
        filters: WorkloadControllerWorkloadResourceModelFilters,
        filtered: boolean,
        includeDescription: Optional<boolean>,
        vulnerabilityReportType: VulnerabilityReportType,
        public includeResolvedVulnerabilities: Optional<boolean>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "VirtualMachinePackageVulnerabilitiesReportDefinitionConfiguration",
            contentType,
            exportReport,
            licenseType,
            name,
            timeZoneInfoId,
            type,
            filters,
            filtered,
            includeDescription,
            vulnerabilityReportType);
    }
}

export class VirtualMachinesReportDefinitionConfiguration extends WorkloadResourcesReportDefinitionConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        contentType: ReportContentType,
        exportReport: boolean,
        licenseType: ApplicationCustomerConfigurationLicensingLicenseType,
        name: Optional<string>,
        timeZoneInfoId: string,
        type: ReportType,
        filters: WorkloadControllerWorkloadResourceModelFilters,
        sort: WorkloadControllerWorkloadResourceModelSort) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "VirtualMachinesReportDefinitionConfiguration",
            contentType,
            exportReport,
            licenseType,
            name,
            timeZoneInfoId,
            type,
            filters,
            sort);
    }
}

export class VirtualMachineVulnerabilityRiskModel extends WorkloadAnalysisRiskModel implements ITypeNameObject, IUdmObjectModel, IWorkloadResourceVulnerabilityRiskModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: VirtualMachineVulnerabilityRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public virtualMachineImageRiskRawId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "VirtualMachineVulnerabilityRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class VirtualMachineVulnerabilityRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "VirtualMachineVulnerabilityRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class VirtualMachineWorkloadAnalysisRisk extends WorkloadAnalysisRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        criticalVulnerabilities: WorkloadResourceRiskVulnerability[],
        highVulnerabilities: WorkloadResourceRiskVulnerability[],
        singleVirtualMachine: boolean,
        virtualMachineIds: string[],
        vulnerabilitySeverityToCountMap: Dictionary<number>,
        windowsUpdateDocumentationUrl: string,
        public riskedVirtualMachineImage: boolean,
        public virtualMachineImageId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            criticalVulnerabilities,
            highVulnerabilities,
            singleVirtualMachine,
            virtualMachineIds,
            vulnerabilitySeverityToCountMap,
            windowsUpdateDocumentationUrl);
    }
}

export class AadDirectoryApplication extends AadDirectoryEntity implements ITypeNameObject, IDirectoryEntity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        creationTime: Optional<string>,
        rawId: string,
        public applicationRawId: string,
        public homepageRawUrl: Optional<string>,
        public ownerPrincipalIds: string[],
        public servicePrincipalFederatedCredentials: AadDirectoryServicePrincipalFederatedCredential[],
        public type: Optional<AadDirectoryApplicationType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AadDirectoryApplication",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            creationTime,
            rawId);
    }
}

export class AadDirectoryApplicationCertificateNotRotatedRisk extends AggregatedEntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        aggregatedEntityIds: string[],
        aggregatingEntityTypeName: string,
        public certificateRotationTimeFrame: string,
        public permissionActionSeverityToTenantIdsMap: Dictionary<string[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AadDirectoryApplicationCertificateNotRotatedRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            aggregatedEntityIds,
            aggregatingEntityTypeName);
    }
}

export class AadDirectoryApplicationCertificateNotRotatedRiskModel extends AggregatedEntityRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AadDirectoryApplicationCertificateNotRotatedRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AadDirectoryApplicationCertificateNotRotatedRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AadDirectoryApplicationCertificateNotRotatedRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        public certificateRotationTimeFrame: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AadDirectoryApplicationCertificateNotRotatedRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AadDirectoryApplicationModel extends AadDirectoryEntityModel implements ITypeNameObject, IEntityModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        partitionType: AadPartitionType,
        public certificateIds: string[],
        public certificateThumbprintToCertificateIdsMap: Dictionary<string[]>,
        public credentialsExpirationStatus: Optional<AadDirectoryApplicationObjectExpirationStatus>,
        public credentialsMinExpirationTime: Optional<string>,
        public secretIds: string[],
        public servicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AadDirectoryApplicationModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            partitionType);
    }
}

export class AadDirectoryApplicationObject extends AadDirectoryEntity implements ITypeNameObject, IDirectoryEntity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        creationTime: Optional<string>,
        rawId: string,
        public applicationId: string,
        public description: Optional<string>,
        public expirationStatus: AadDirectoryApplicationObjectExpirationStatus,
        public expirationTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            creationTime,
            rawId);
    }
}

export class AadDirectoryApplicationObjectModel extends AadDirectoryEntityModel implements ITypeNameObject, IEntityModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        partitionType: AadPartitionType,
        public applicationOwnerPrincipalIds: string[],
        public applicationRawId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            partitionType);
    }
}

export class AadDirectoryApplicationSecret extends AadDirectoryApplicationObject implements ITypeNameObject, IDirectoryEntity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        creationTime: Optional<string>,
        rawId: string,
        applicationId: string,
        description: Optional<string>,
        expirationStatus: AadDirectoryApplicationObjectExpirationStatus,
        expirationTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AadDirectoryApplicationSecret",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            creationTime,
            rawId,
            applicationId,
            description,
            expirationStatus,
            expirationTime);
    }
}

export class AadDirectoryApplicationSecretModel extends AadDirectoryApplicationObjectModel implements ITypeNameObject, IEntityModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        partitionType: AadPartitionType,
        applicationOwnerPrincipalIds: string[],
        applicationRawId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AadDirectoryApplicationSecretModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            partitionType,
            applicationOwnerPrincipalIds,
            applicationRawId);
    }
}

export class AadDirectoryApplicationSecretNotRotatedRisk extends AggregatedEntityRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        aggregatedEntityIds: string[],
        aggregatingEntityTypeName: string,
        public permissionActionSeverityToTenantIdsMap: Dictionary<string[]>,
        public secretRotationTimeFrame: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AadDirectoryApplicationSecretNotRotatedRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            aggregatedEntityIds,
            aggregatingEntityTypeName);
    }
}

export class AadDirectoryApplicationSecretNotRotatedRiskModel extends AggregatedEntityRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AadDirectoryApplicationSecretNotRotatedRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AadDirectoryApplicationSecretNotRotatedRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AadDirectoryApplicationSecretNotRotatedRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        public secretRotationTimeFrame: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AadDirectoryApplicationSecretNotRotatedRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AadDirectoryApplicationServicePrincipalInactiveRisk extends AadDirectoryIdentityInactiveRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        resolutionType: AadDirectoryIdentityInactiveRiskResolutionType,
        signInTime: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AadDirectoryApplicationServicePrincipalInactiveRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            resolutionType,
            signInTime);
    }
}

export class AadDirectoryApplicationServicePrincipalInactiveRiskModel extends AadDirectoryIdentityInactiveRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AadDirectoryApplicationServicePrincipalInactiveRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        infoType: AadDirectoryIdentityInactiveRiskModelInfoType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AadDirectoryApplicationServicePrincipalInactiveRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            infoType);
    }
}

export class AadDirectoryDirectoryRoleAssignmentResourceModel extends AadDirectoryEntityModel implements ITypeNameObject, IEntityModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        partitionType: AadPartitionType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            partitionType);
    }
}

export class AadDirectoryDirectoryRoleDefinitionModel extends AadDirectoryEntityModel implements ITypeNameObject, IEntityModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        partitionType: AadPartitionType,
        public assignedPrincipalIds: string[],
        public directoryRoleAssignmentResourceIds: string[],
        public severePermission: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AadDirectoryDirectoryRoleDefinitionModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            partitionType);
    }
}

export class AadDirectoryDirectoryRoleEligibilityScheduleModel extends AadDirectoryDirectoryRoleAssignmentResourceModel implements ITypeNameObject, IEntityModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        partitionType: AadPartitionType,
        public directoryRoleManagementPolicyAssignmentId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AadDirectoryDirectoryRoleEligibilityScheduleModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            partitionType);
    }
}

export class AadDirectoryDirectoryRoleEntity extends AadDirectoryEntity implements ITypeNameObject, IDirectoryEntity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        creationTime: Optional<string>,
        rawId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            creationTime,
            rawId);
    }
}

export class AadDirectoryDirectoryRoleManagementPolicyAssignment extends AadDirectoryDirectoryRoleEntity implements ITypeNameObject, IDirectoryEntity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        creationTime: Optional<string>,
        rawId: string,
        public activation: AadDirectoryRoleManagementPolicyAssignmentActivation,
        public directoryRoleDefinitionId: string,
        public policyRawId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AadDirectoryDirectoryRoleManagementPolicyAssignment",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            creationTime,
            rawId);
    }
}

export class AadDirectoryDirectoryRoleManagementPolicyAssignmentModel extends AadDirectoryEntityModel implements ITypeNameObject, IEntityModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        partitionType: AadPartitionType,
        public assignedPrincipalIds: string[],
        public directoryRoleDefinitionId: string,
        public directoryRoleEligibilityScheduleIds: string[],
        public mfaEnabled: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AadDirectoryDirectoryRoleManagementPolicyAssignmentModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            partitionType);
    }
}

export class AadDirectoryGroup extends AadDirectoryPrincipal implements ITypeNameObject, IDirectoryGroup, IPermissionManagementGroup {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        creationTime: Optional<string>,
        rawId: string,
        groupRawIds: string[],
        public adOrigin: boolean,
        public adSamAccountName: Optional<string>,
        public adSyncUpdateTime: Optional<string>,
        public deviceMemberExists: boolean,
        public mail: Optional<string>,
        public membershipType: AadDirectoryGroupMembershipType,
        public ownerPrincipalIds: string[],
        public type: AadDirectoryGroupType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AadDirectoryGroup",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            creationTime,
            rawId,
            groupRawIds);
    }
}

export class AadDirectoryGroupInactiveRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AadDirectoryGroupInactiveRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AadDirectoryGroupMembershipEligibilityConfiguration extends GroupMembershipEligibilityConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        approval: Optional<PermissionEligibilityConfigurationApproval>,
        description: Optional<string>,
        expirationTimeFrame: string,
        name: string,
        principalTenantId: string,
        reasonRequired: boolean,
        groupReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AadDirectoryGroupMembershipEligibilityConfiguration",
            scopeId,
            approval,
            description,
            expirationTimeFrame,
            name,
            principalTenantId,
            reasonRequired,
            groupReferences);
    }
}

export class AadDirectoryGroupModel extends AadDirectoryPrincipalModel implements ITypeNameObject, IUdmObjectModel, IGroupModel, IPermissionManagementGroupModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        partitionType: AadPartitionType,
        public access: Optional<GroupModelAccess>,
        analyzing: Optional<AadDirectoryPrincipalModelAnalyzing>,
        azureRoleAssignmentResourceIds: string[],
        azureRoleDefinitionIds: string[],
        containedAzureRoleAssignmentResourceIds: string[],
        directoryRoleAssignmentResourceIds: string[],
        directoryRoleDefinitionIds: string[],
        groupIds: string[],
        inactive: boolean,
        public awsRoleIds: string[],
        public memberPrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AadDirectoryGroupModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            partitionType,
            access,
            analyzing,
            azureRoleAssignmentResourceIds,
            azureRoleDefinitionIds,
            containedAzureRoleAssignmentResourceIds,
            directoryRoleAssignmentResourceIds,
            directoryRoleDefinitionIds,
            groupIds,
            inactive);
    }
}

export class AadDirectoryIdentity extends AadDirectoryPrincipal implements ITypeNameObject, IDirectoryEntity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        creationTime: Optional<string>,
        rawId: string,
        groupRawIds: string[],
        public enabled: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            creationTime,
            rawId,
            groupRawIds);
    }
}

export class AadDirectoryIdentityInactiveRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        public includeDisabledIdentity: Optional<boolean>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AadDirectoryIdentityModel extends AadDirectoryPrincipalModel implements ITypeNameObject, IUdmObjectModel, IIdentityModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        partitionType: AadPartitionType,
        public access: Optional<IdentityModelAccess>,
        analyzing: Optional<AadDirectoryPrincipalModelAnalyzing>,
        azureRoleAssignmentResourceIds: string[],
        azureRoleDefinitionIds: string[],
        containedAzureRoleAssignmentResourceIds: string[],
        directoryRoleAssignmentResourceIds: string[],
        directoryRoleDefinitionIds: string[],
        groupIds: string[],
        inactive: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            partitionType,
            access,
            analyzing,
            azureRoleAssignmentResourceIds,
            azureRoleDefinitionIds,
            containedAzureRoleAssignmentResourceIds,
            directoryRoleAssignmentResourceIds,
            directoryRoleDefinitionIds,
            groupIds,
            inactive);
    }
}

export class AadDirectoryServicePrincipal extends AadDirectoryIdentity implements ITypeNameObject, IDirectoryEntity, IServiceIdentity, ITagEntity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        creationTime: Optional<string>,
        rawId: string,
        groupRawIds: string[],
        enabled: boolean,
        public applicationRawId: string,
        public applicationRoleDefinitions: AadDirectoryApplicationRoleDefinition[],
        public applicationTemplateRawId: Optional<string>,
        public assignmentRequired: Optional<boolean>,
        public homepageRawUrl: Optional<string>,
        public ownerPrincipalIds: string[],
        public ownerTenantId: Optional<string>,
        public signInEventsDisabled: boolean,
        public tagExists: boolean,
        public tags: ResourceTag[],
        public type: AadDirectoryServicePrincipalType,
        public visible: Optional<boolean>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            creationTime,
            rawId,
            groupRawIds,
            enabled);
    }
}

export class AadDirectoryServicePrincipalModel extends AadDirectoryIdentityModel implements ITypeNameObject, IUdmObjectModel, IServiceIdentityModel, ITagEntityModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        partitionType: AadPartitionType,
        public access: Optional<ServiceIdentityModelAccess>,
        analyzing: Optional<AadDirectoryPrincipalModelAnalyzing>,
        azureRoleAssignmentResourceIds: string[],
        azureRoleDefinitionIds: string[],
        containedAzureRoleAssignmentResourceIds: string[],
        directoryRoleAssignmentResourceIds: string[],
        directoryRoleDefinitionIds: string[],
        groupIds: string[],
        inactive: boolean,
        public assignedPrincipalIds: string[],
        public assignedPrincipalIdToApplicationRoleDefinitionRawIdsMap: Dictionary<string[]>,
        public microsoftGraphRoleAssignmentRoleDefinitions: AadDirectoryApplicationRoleDefinition[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            partitionType,
            access,
            analyzing,
            azureRoleAssignmentResourceIds,
            azureRoleDefinitionIds,
            containedAzureRoleAssignmentResourceIds,
            directoryRoleAssignmentResourceIds,
            directoryRoleDefinitionIds,
            groupIds,
            inactive);
    }
}

export class AadDirectoryUser extends AadDirectoryIdentity implements ITypeNameObject, IAwsOriginatorIdentity, IDirectoryUser, IPermissionManagementUser {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        creationTime: Optional<string>,
        rawId: string,
        groupRawIds: string[],
        enabled: boolean,
        public adOrigin: boolean,
        public adSyncUpdateTime: Optional<string>,
        public authenticationType: AadDirectoryUserAuthenticationType,
        public guestUserInvitationStatus: Optional<AadDirectoryUserGuestUserInvitationStatus>,
        public jobInfo: AadDirectoryUserJobInfo,
        public location: Optional<string>,
        public mail: Optional<string>,
        public onPremisesExtensionAttributesMap: Dictionary<string>,
        public rawMail: Optional<string>,
        public type: AadDirectoryUserType,
        public userPrincipalName: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AadDirectoryUser",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            creationTime,
            rawId,
            groupRawIds,
            enabled);
    }
}

export class AadDirectoryUserInactiveRiskPolicyConfiguration extends AadDirectoryIdentityInactiveRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        includeDisabledIdentity: Optional<boolean>,
        public includeInvitationPendingAcceptanceGuestUser: Optional<boolean>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AadDirectoryUserInactiveRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            includeDisabledIdentity);
    }
}

export class AadDirectoryUserModel extends AadDirectoryIdentityModel implements ITypeNameObject, IUdmObjectModel, IAwsFederationUserModel, IPermissionManagementUserModel, IUserModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        partitionType: AadPartitionType,
        public access: Optional<UserModelAccess>,
        analyzing: Optional<AadDirectoryPrincipalModelAnalyzing>,
        azureRoleAssignmentResourceIds: string[],
        azureRoleDefinitionIds: string[],
        containedAzureRoleAssignmentResourceIds: string[],
        directoryRoleAssignmentResourceIds: string[],
        directoryRoleDefinitionIds: string[],
        groupIds: string[],
        inactive: boolean,
        public awsRoleIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AadDirectoryUserModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            partitionType,
            access,
            analyzing,
            azureRoleAssignmentResourceIds,
            azureRoleDefinitionIds,
            containedAzureRoleAssignmentResourceIds,
            directoryRoleAssignmentResourceIds,
            directoryRoleDefinitionIds,
            groupIds,
            inactive);
    }
}

export class AadSeverePermissionDirectoryRoleDefinitionsConfiguration extends AccessSettingConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        public directoryRoleDefinitionDatas: AadSeverePermissionDirectoryRoleDefinitionData[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AadSeverePermissionDirectoryRoleDefinitionsConfiguration",
            scopeId);
    }
}

export class AadSeverePermissionPrincipalRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AadSeverePermissionPrincipalRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AadTenantEntityDefaultUserRoleApplicationCreationEnabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AadTenantEntityDefaultUserRoleApplicationCreationEnabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AadTenantEntityDefaultUserRoleTenantCreationEnabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AadTenantEntityDefaultUserRoleTenantCreationEnabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AadTenantEntityUnrestrictedExternalUserDefaultRoleRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AadTenantEntityUnrestrictedExternalUserDefaultRoleRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AadTenantEntityUnrestrictedExternalUserInvitationAllowedRolesRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AadTenantEntityUnrestrictedExternalUserInvitationAllowedRolesRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsAccessAnalyzerRegionAnalyzerNotExistRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsAccessAnalyzerRegionAnalyzerNotExistRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsAcmCertificateKeyMinLengthRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsAcmCertificateKeyMinLengthRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsApiGatewayApiWebAclNotExistsRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsApiGatewayApiWebAclNotExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsAppConfigApplication extends AwsNotSyncedResource implements ITypeNameObject, IAwsResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsAppConfigApplication",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsAppSyncGraphQlApi extends AwsNotSyncedResource implements ITypeNameObject, IAwsResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsAppSyncGraphQlApi",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsAthenaWorkgroupEncryptionDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsAthenaWorkgroupEncryptionDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsAthenaWorkgroupLoggingDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsAthenaWorkgroupLoggingDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsAutoScalingAutoScalingGroup extends AwsAutoScalingResource implements ITypeNameObject, IAwsNetworkedResource, IAwsOriginatorComputeResource, IAwsTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public imageRawId: Optional<string>,
        public instanceReferences: AwsResourceReference[],
        public keyName: Optional<string>,
        public launchConfigurationId: Optional<string>,
        public launchTemplateRevisionReference: Optional<AwsEc2LaunchTemplateRevisionReference>,
        public loadBalancerArns: string[],
        public maxCapacity: number,
        public roleReference: Optional<AwsResourceReference>,
        public securityGroupIds: string[],
        public serviceLinkedRoleReference: AwsResourceReference,
        public subnetIds: string[],
        public vpcIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsAutoScalingAutoScalingGroup",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsAutoScalingLaunchConfiguration extends AwsAutoScalingResource implements ITypeNameObject, IAwsResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public devices: AwsEc2Device[],
        public imageRawId: string,
        public instanceProfileReference: Optional<AwsResourceReference>,
        public instanceRawType: string,
        public keyName: Optional<string>,
        public metadataService: AwsEc2InstanceMetadataService,
        public roleReference: Optional<AwsResourceReference>,
        public securityGroupIds: string[],
        public userData: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsAutoScalingLaunchConfiguration",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsBatchComputeEnvironment extends AwsBatchResource implements ITypeNameObject, IAwsResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public ecsClusterId: Optional<string>,
        public resource: Optional<AwsBatchComputeResource>,
        public serviceRoleReference: AwsResourceReference,
        public type: AwsBatchComputeEnvironmentType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsBatchComputeEnvironment",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsBatchJobDefinition extends AwsBatchResource implements ITypeNameObject, IAwsOriginatorComputeResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public latestRevisionArn: string,
        public revisionKeyToArnMap: Dictionary<string>,
        public roleIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsBatchJobDefinition",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsCloudFormationStackSecretExistsRiskPolicyConfiguration extends SecretExistsRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        exclusionSecretPatterns: Optional<SecretExistsRiskPolicyConfigurationSecretExclusionSecretPattern[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudFormationStackSecretExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            exclusionSecretPatterns);
    }
}

export class AwsCloudFrontDistributionDefaultSslCertificateRisk extends AwsCloudFrontDistributionMisconfigurationRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudFrontDistributionDefaultSslCertificateRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsCloudFrontDistributionDefaultSslCertificateRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudFrontDistributionDefaultSslCertificateRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsCloudFrontDistributionHttpsOnlyDisabledRisk extends AwsCloudFrontDistributionMisconfigurationRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public nonHttpsOnlyBehaviorPrecedences: number[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudFrontDistributionHttpsOnlyDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsCloudFrontDistributionHttpsOnlyDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudFrontDistributionHttpsOnlyDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsCloudFrontDistributionInsecureTlsProtocolRisk extends AwsCloudFrontDistributionMisconfigurationRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        public riskedOriginIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudFrontDistributionInsecureTlsProtocolRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsCloudFrontDistributionInsecureTlsProtocolRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudFrontDistributionInsecureTlsProtocolRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsCloudFrontDistributionLoggingDisabledRisk extends AwsCloudFrontDistributionMisconfigurationRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudFrontDistributionLoggingDisabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AwsCloudFrontDistributionLoggingDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudFrontDistributionLoggingDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsCloudFrontDistributionOriginHttpsOnlyDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudFrontDistributionOriginHttpsOnlyDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsCloudFrontDistributionRootObjectNotExistsRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudFrontDistributionRootObjectNotExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsCloudFrontDistributionS3BucketOriginAccessControlDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudFrontDistributionS3BucketOriginAccessControlDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsCloudFrontDistributionWebAclNotExistsRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudFrontDistributionWebAclNotExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsCloudTrailTrailBucketEventDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudTrailTrailBucketEventDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsCloudTrailTrailBucketPublicAccessEnabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudTrailTrailBucketPublicAccessEnabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsCloudTrailTrailDigestDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudTrailTrailDigestDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsCloudTrailTrailEncryptionDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudTrailTrailEncryptionDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsCloudTrailTrailLogGroupDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudTrailTrailLogGroupDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsCloudTrailTrailMultiRegionNotExistRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudTrailTrailMultiRegionNotExistRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsCloudTrailTrailS3ObjectReadEventSelectorNotExistRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudTrailTrailS3ObjectReadEventSelectorNotExistRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsCloudTrailTrailS3ObjectWriteEventSelectorNotExistRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudTrailTrailS3ObjectWriteEventSelectorNotExistRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsCloudWatchAlarmCloudTrailChangesNotExistRisk extends AwsCloudWatchAlarmNotExistRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        logGroupMetricFilterPattern: string,
        requirementToSuccessMap: Dictionary<boolean>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudWatchAlarmCloudTrailChangesNotExistRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            logGroupMetricFilterPattern,
            requirementToSuccessMap);
    }
}

export class AwsCloudWatchAlarmCloudTrailChangesNotExistRiskModel extends AwsCloudWatchAlarmNotExistRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsCloudWatchAlarmNotExistRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        createAlarmDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudWatchAlarmCloudTrailChangesNotExistRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            createAlarmDocumentationUrl);
    }
}

export class AwsCloudWatchAlarmCloudTrailChangesNotExistRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudWatchAlarmCloudTrailChangesNotExistRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsCloudWatchAlarmConfigChangesNotExistRisk extends AwsCloudWatchAlarmNotExistRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        logGroupMetricFilterPattern: string,
        requirementToSuccessMap: Dictionary<boolean>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudWatchAlarmConfigChangesNotExistRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            logGroupMetricFilterPattern,
            requirementToSuccessMap);
    }
}

export class AwsCloudWatchAlarmConfigChangesNotExistRiskModel extends AwsCloudWatchAlarmNotExistRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsCloudWatchAlarmNotExistRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        createAlarmDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudWatchAlarmConfigChangesNotExistRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            createAlarmDocumentationUrl);
    }
}

export class AwsCloudWatchAlarmConfigChangesNotExistRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudWatchAlarmConfigChangesNotExistRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsCloudWatchAlarmEc2InternetGatewayChangesNotExistRisk extends AwsCloudWatchAlarmNotExistRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        logGroupMetricFilterPattern: string,
        requirementToSuccessMap: Dictionary<boolean>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudWatchAlarmEc2InternetGatewayChangesNotExistRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            logGroupMetricFilterPattern,
            requirementToSuccessMap);
    }
}

export class AwsCloudWatchAlarmEc2InternetGatewayChangesNotExistRiskModel extends AwsCloudWatchAlarmNotExistRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsCloudWatchAlarmNotExistRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        createAlarmDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudWatchAlarmEc2InternetGatewayChangesNotExistRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            createAlarmDocumentationUrl);
    }
}

export class AwsCloudWatchAlarmEc2InternetGatewayChangesNotExistRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudWatchAlarmEc2InternetGatewayChangesNotExistRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsCloudWatchAlarmEc2NetworkAclChangesNotExistRisk extends AwsCloudWatchAlarmNotExistRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        logGroupMetricFilterPattern: string,
        requirementToSuccessMap: Dictionary<boolean>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudWatchAlarmEc2NetworkAclChangesNotExistRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            logGroupMetricFilterPattern,
            requirementToSuccessMap);
    }
}

export class AwsCloudWatchAlarmEc2NetworkAclChangesNotExistRiskModel extends AwsCloudWatchAlarmNotExistRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsCloudWatchAlarmNotExistRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        createAlarmDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudWatchAlarmEc2NetworkAclChangesNotExistRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            createAlarmDocumentationUrl);
    }
}

export class AwsCloudWatchAlarmEc2NetworkAclChangesNotExistRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudWatchAlarmEc2NetworkAclChangesNotExistRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsCloudWatchAlarmEc2RouteTableChangesNotExistRisk extends AwsCloudWatchAlarmNotExistRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        logGroupMetricFilterPattern: string,
        requirementToSuccessMap: Dictionary<boolean>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudWatchAlarmEc2RouteTableChangesNotExistRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            logGroupMetricFilterPattern,
            requirementToSuccessMap);
    }
}

export class AwsCloudWatchAlarmEc2RouteTableChangesNotExistRiskModel extends AwsCloudWatchAlarmNotExistRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsCloudWatchAlarmNotExistRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        createAlarmDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudWatchAlarmEc2RouteTableChangesNotExistRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            createAlarmDocumentationUrl);
    }
}

export class AwsCloudWatchAlarmEc2RouteTableChangesNotExistRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudWatchAlarmEc2RouteTableChangesNotExistRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsCloudWatchAlarmEc2SecurityGroupChangesNotExistRisk extends AwsCloudWatchAlarmNotExistRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        logGroupMetricFilterPattern: string,
        requirementToSuccessMap: Dictionary<boolean>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudWatchAlarmEc2SecurityGroupChangesNotExistRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            logGroupMetricFilterPattern,
            requirementToSuccessMap);
    }
}

export class AwsCloudWatchAlarmEc2SecurityGroupChangesNotExistRiskModel extends AwsCloudWatchAlarmNotExistRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsCloudWatchAlarmNotExistRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        createAlarmDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudWatchAlarmEc2SecurityGroupChangesNotExistRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            createAlarmDocumentationUrl);
    }
}

export class AwsCloudWatchAlarmEc2SecurityGroupChangesNotExistRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudWatchAlarmEc2SecurityGroupChangesNotExistRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsCloudWatchAlarmEc2VpcChangesNotExistRisk extends AwsCloudWatchAlarmNotExistRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        logGroupMetricFilterPattern: string,
        requirementToSuccessMap: Dictionary<boolean>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudWatchAlarmEc2VpcChangesNotExistRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            logGroupMetricFilterPattern,
            requirementToSuccessMap);
    }
}

export class AwsCloudWatchAlarmEc2VpcChangesNotExistRiskModel extends AwsCloudWatchAlarmNotExistRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsCloudWatchAlarmNotExistRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        createAlarmDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudWatchAlarmEc2VpcChangesNotExistRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            createAlarmDocumentationUrl);
    }
}

export class AwsCloudWatchAlarmEc2VpcChangesNotExistRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudWatchAlarmEc2VpcChangesNotExistRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsCloudWatchAlarmIamPolicyChangesNotExistRisk extends AwsCloudWatchAlarmNotExistRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        logGroupMetricFilterPattern: string,
        requirementToSuccessMap: Dictionary<boolean>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudWatchAlarmIamPolicyChangesNotExistRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            logGroupMetricFilterPattern,
            requirementToSuccessMap);
    }
}

export class AwsCloudWatchAlarmIamPolicyChangesNotExistRiskModel extends AwsCloudWatchAlarmNotExistRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsCloudWatchAlarmNotExistRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        createAlarmDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudWatchAlarmIamPolicyChangesNotExistRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            createAlarmDocumentationUrl);
    }
}

export class AwsCloudWatchAlarmIamPolicyChangesNotExistRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudWatchAlarmIamPolicyChangesNotExistRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsCloudWatchAlarmKmsCustomerMasterKeyDisabledNotExistRisk extends AwsCloudWatchAlarmNotExistRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        logGroupMetricFilterPattern: string,
        requirementToSuccessMap: Dictionary<boolean>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudWatchAlarmKmsCustomerMasterKeyDisabledNotExistRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            logGroupMetricFilterPattern,
            requirementToSuccessMap);
    }
}

export class AwsCloudWatchAlarmKmsCustomerMasterKeyDisabledNotExistRiskModel extends AwsCloudWatchAlarmNotExistRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsCloudWatchAlarmNotExistRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        createAlarmDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudWatchAlarmKmsCustomerMasterKeyDisabledNotExistRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            createAlarmDocumentationUrl);
    }
}

export class AwsCloudWatchAlarmKmsCustomerMasterKeyDisabledNotExistRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudWatchAlarmKmsCustomerMasterKeyDisabledNotExistRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsCloudWatchAlarmManagementConsoleAuthenticationFailuresNotExistRisk extends AwsCloudWatchAlarmNotExistRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        logGroupMetricFilterPattern: string,
        requirementToSuccessMap: Dictionary<boolean>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudWatchAlarmManagementConsoleAuthenticationFailuresNotExistRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            logGroupMetricFilterPattern,
            requirementToSuccessMap);
    }
}

export class AwsCloudWatchAlarmManagementConsoleAuthenticationFailuresNotExistRiskModel extends AwsCloudWatchAlarmNotExistRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsCloudWatchAlarmNotExistRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        createAlarmDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudWatchAlarmManagementConsoleAuthenticationFailuresNotExistRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            createAlarmDocumentationUrl);
    }
}

export class AwsCloudWatchAlarmManagementConsoleAuthenticationFailuresNotExistRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudWatchAlarmManagementConsoleAuthenticationFailuresNotExistRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsCloudWatchAlarmManagementConsoleMfaDisabledNotExistRisk extends AwsCloudWatchAlarmNotExistRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        logGroupMetricFilterPattern: string,
        requirementToSuccessMap: Dictionary<boolean>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudWatchAlarmManagementConsoleMfaDisabledNotExistRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            logGroupMetricFilterPattern,
            requirementToSuccessMap);
    }
}

export class AwsCloudWatchAlarmManagementConsoleMfaDisabledNotExistRiskModel extends AwsCloudWatchAlarmNotExistRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsCloudWatchAlarmNotExistRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        createAlarmDocumentationUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudWatchAlarmManagementConsoleMfaDisabledNotExistRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            createAlarmDocumentationUrl);
    }
}

export class AwsCloudWatchAlarmManagementConsoleMfaDisabledNotExistRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudWatchAlarmManagementConsoleMfaDisabledNotExistRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsCloudWatchAlarmOrganizationsOrganizationChangesNotExistRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudWatchAlarmOrganizationsOrganizationChangesNotExistRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsCloudWatchAlarmRootUsageNotExistRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudWatchAlarmRootUsageNotExistRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsCloudWatchAlarmS3BucketPolicyChangesNotExistRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudWatchAlarmS3BucketPolicyChangesNotExistRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsCloudWatchAlarmUnauthorizedApiCallsNotExistRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCloudWatchAlarmUnauthorizedApiCallsNotExistRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsCognitoIdpUserPool extends AwsNotSyncedResource implements ITypeNameObject, IAwsResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsCognitoIdpUserPool",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsConfigRegionConfigurationRecorderNotExistRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsConfigRegionConfigurationRecorderNotExistRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsDataLeakageRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsDataLeakageRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsDeletePermissionSetAccountAssignmentsChange extends AwsResourceChange implements ITypeNameObject, ICloudProviderResourceChange, ICodeInfrastructureChange {
    constructor(
        entityIds: string[],
        id: string,
        resolved: boolean,
        resourceId: string,
        public accountIds: string[]) {
        super(
            "AwsDeletePermissionSetAccountAssignmentsChange",
            entityIds,
            id,
            resolved,
            resourceId);
    }
}

export class AwsDeletePermissionSetChange extends AwsResourceChange implements ITypeNameObject, ICloudProviderResourceChange, ICodeInfrastructureChange {
    constructor(
        entityIds: string[],
        id: string,
        resolved: boolean,
        resourceId: string) {
        super(
            "AwsDeletePermissionSetChange",
            entityIds,
            id,
            resolved,
            resourceId);
    }
}

export class AwsDeletePermissionSetInlinePolicyChange extends AwsResourceChange implements ITypeNameObject, ICloudProviderResourceChange, ICodeInfrastructureChange {
    constructor(
        entityIds: string[],
        id: string,
        resolved: boolean,
        resourceId: string) {
        super(
            "AwsDeletePermissionSetInlinePolicyChange",
            entityIds,
            id,
            resolved,
            resourceId);
    }
}

export class AwsDeletePrincipalChange extends AwsResourceChange implements ITypeNameObject, ICloudProviderResourceChange {
    constructor(
        typeName: string,
        entityIds: string[],
        id: string,
        resolved: boolean,
        resourceId: string) {
        super(
            typeName,
            entityIds,
            id,
            resolved,
            resourceId);
    }
}

export class AwsDeleteResourcePolicyChange extends AwsResourceChange implements ITypeNameObject, ICloudProviderResourceChange {
    constructor(
        typeName: string,
        entityIds: string[],
        id: string,
        resolved: boolean,
        resourceId: string) {
        super(
            typeName,
            entityIds,
            id,
            resolved,
            resourceId);
    }
}

export class AwsDeleteRoleChange extends AwsDeletePrincipalChange implements ITypeNameObject, ICloudProviderResourceChange, ICodeInfrastructureChange {
    constructor(
        entityIds: string[],
        id: string,
        resolved: boolean,
        resourceId: string) {
        super(
            "AwsDeleteRoleChange",
            entityIds,
            id,
            resolved,
            resourceId);
    }
}

export class AwsDeleteSecretPolicyChange extends AwsDeleteResourcePolicyChange implements ITypeNameObject, ICloudProviderResourceChange {
    constructor(
        entityIds: string[],
        id: string,
        resolved: boolean,
        resourceId: string) {
        super(
            "AwsDeleteSecretPolicyChange",
            entityIds,
            id,
            resolved,
            resourceId);
    }
}

export class AwsDeleteSecurityGroupChange extends AwsResourceChange implements ITypeNameObject, ICloudProviderResourceChange, ICodeInfrastructureChange {
    constructor(
        entityIds: string[],
        id: string,
        resolved: boolean,
        resourceId: string) {
        super(
            "AwsDeleteSecurityGroupChange",
            entityIds,
            id,
            resolved,
            resourceId);
    }
}

export class AwsDeleteSecurityGroupReferencingSecurityGroupRulesChange extends AwsResourceChange implements ITypeNameObject, ICloudProviderResourceChange, ICodeInfrastructureChange {
    constructor(
        entityIds: string[],
        id: string,
        resolved: boolean,
        resourceId: string,
        public referencedSecurityGroupId: string) {
        super(
            "AwsDeleteSecurityGroupReferencingSecurityGroupRulesChange",
            entityIds,
            id,
            resolved,
            resourceId);
    }
}

export class AwsDeleteUserChange extends AwsDeletePrincipalChange implements ITypeNameObject, ICloudProviderResourceChange, ICodeInfrastructureChange {
    constructor(
        entityIds: string[],
        id: string,
        resolved: boolean,
        resourceId: string) {
        super(
            "AwsDeleteUserChange",
            entityIds,
            id,
            resolved,
            resourceId);
    }
}

export class AwsDeleteUserGroupMembershipChange extends AwsResourceChange implements ITypeNameObject, ICloudProviderResourceChange {
    constructor(
        entityIds: string[],
        id: string,
        resolved: boolean,
        resourceId: string,
        public groupId: string) {
        super(
            "AwsDeleteUserGroupMembershipChange",
            entityIds,
            id,
            resolved,
            resourceId);
    }
}

export class AwsDeleteUserPasswordChange extends AwsResourceChange implements ITypeNameObject, ICloudProviderResourceChange, ICodeInfrastructureChange {
    constructor(
        entityIds: string[],
        id: string,
        resolved: boolean,
        resourceId: string) {
        super(
            "AwsDeleteUserPasswordChange",
            entityIds,
            id,
            resolved,
            resourceId);
    }
}

export class AwsDetachPermissionSetManagedPolicyChange extends AwsResourceChange implements ITypeNameObject, ICloudProviderResourceChange, ICodeInfrastructureChange {
    constructor(
        entityIds: string[],
        id: string,
        resolved: boolean,
        resourceId: string,
        public managedPolicyId: string) {
        super(
            "AwsDetachPermissionSetManagedPolicyChange",
            entityIds,
            id,
            resolved,
            resourceId);
    }
}

export class AwsDisableUserAccessKeyChange extends AwsResourceChange implements ITypeNameObject, ICloudProviderResourceChange, ICodeInfrastructureChange {
    constructor(
        entityIds: string[],
        id: string,
        resolved: boolean,
        resourceId: string,
        public accessKeyUniqueId: string,
        public deleteAccessKey: boolean) {
        super(
            "AwsDisableUserAccessKeyChange",
            entityIds,
            id,
            resolved,
            resourceId);
    }
}

export class AwsDocDbClusterBackupRetentionTimeFrameRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        public backupRetentionTimeFrame: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsDocDbClusterBackupRetentionTimeFrameRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsDynamoDbTableDeleteProtectionDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsDynamoDbTableDeleteProtectionDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsDynamoDbTableEncryptionDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsDynamoDbTableEncryptionDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsEc2CoipPool extends AwsEc2Resource implements ITypeNameObject, IAwsResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        rawId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2CoipPool",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            rawId);
    }
}

export class AwsEc2CoipPoolModel extends AwsEc2ResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2CoipPoolModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsEc2DatabricksInstanceGroupModel extends AwsTagResourceGroupModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IEntityGroupModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2DatabricksInstanceGroupModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsEc2ElasticIp extends AwsEc2Resource implements ITypeNameObject, IAwsDnsRecordResource, IAwsTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        rawId: string,
        public dnsNames: string[],
        public networkBorderGroupName: string,
        public networkInterfaceId: Optional<string>,
        public onPremiseIpAddress: Optional<string>,
        public onPremiseIpAddressPoolName: Optional<string>,
        public privateIpAddress: Optional<string>,
        public publicIpAddress: Optional<string>,
        public publicIpAddresses: string[],
        public publicIpAddressPoolName: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2ElasticIp",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            rawId);
    }
}

export class AwsEc2ElasticIpModel extends AwsEc2ResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public dnsZoneIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2ElasticIpModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsEc2FlowLog extends AwsEc2Resource implements ITypeNameObject, IAwsResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        rawId: string,
        public destinationArnPattern: Optional<string>,
        public destinationLogGroupName: Optional<string>,
        public filter: Optional<AwsEc2FlowLogFilter>,
        public resourceRawId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2FlowLog",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            rawId);
    }
}

export class AwsEc2FlowLogModel extends AwsEc2ResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2FlowLogModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsEc2Image extends AwsEc2Resource implements ITypeNameObject, IAwsAccessResource, IAwsTagResource, IVirtualMachineImage {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        rawId: string,
        public accessLevel: Optional<AwsResourceAccessLevel>,
        public architecture: string,
        public awsManaged: boolean,
        public description: Optional<string>,
        public devices: AwsEc2Device[],
        public operatingSystemDescription: Optional<string>,
        public operatingSystemType: Optional<OperatingSystemType>,
        public productCode: Optional<AwsResourceProductCode>,
        public rootDeviceType: AwsEc2DeviceType,
        public sharedTenantRawIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2Image",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            rawId);
    }
}

export class AwsEc2ImageModel extends AwsEc2ResourceModel implements ITypeNameObject, IUdmObjectModel, IAwsAccessResourceModel, IRelatedTenantsEntityModel, IVirtualMachineImageModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public accessLevel: Optional<AwsResourceAccessLevel>,
        public autoScalingGroupIds: string[],
        public instanceIds: string[],
        public operatingSystemReleaseNotesUrl: Optional<string>,
        public operatingSystemType: Optional<OperatingSystemType>,
        public scanTime: Optional<string>,
        public snapshotRawIdToIdMap: Dictionary<string>,
        public virtualMachineImageAccessLevel: Optional<VirtualMachineImageAccessLevel>,
        public vulnerabilities: Optional<VulnerabilityData[]>,
        public vulnerabilitySeveritiesSortValue: string,
        public workloadAnalysisError: Optional<WorkloadAnalysisError>,
        public workloadAnalysisOperatingSystem: Optional<WorkloadResourceScanOperatingSystem>,
        public workloadAnalysisOperatingSystemEndOfLifeDate: Optional<string>,
        public workloadAnalysisOperatingSystemExtendedSupport: Optional<boolean>,
        public workloadAnalysisPriorityScanRequested: boolean,
        public workloadAnalysisStatus: WorkloadAnalysisStatus,
        public workloadAnalysisStatusSortValue: number) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2ImageModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsEc2ImagePublicAccessExistsRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2ImagePublicAccessExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsEc2Instance extends AwsEc2Resource implements ITypeNameObject, IAwsDnsRecordResource, IAwsNetworkEvaluationNetworkedResource, IAwsOriginatorComputeResource, IAwsTagResource, IEntityGroupTagEntity, IVirtualMachine {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        rawId: string,
        public architecture: string,
        public availabilityZone: AwsAvailabilityZone,
        public cpuCoresCount: number,
        public dnsNames: string[],
        public enclaveEnabled: Optional<boolean>,
        public imageRawId: string,
        public instanceProfileReference: Optional<AwsResourceReference>,
        public karpenterNodeClassName: Optional<string>,
        public keyName: Optional<string>,
        public launchTemplateRevisionReference: Optional<AwsEc2LaunchTemplateRevisionReference>,
        public launchTime: string,
        public metadataService: AwsEc2InstanceMetadataService,
        public metadataServiceVersionV1UsageTime: Optional<string>,
        public metadataServiceVersionV1Used: boolean,
        public networkInterfaceIds: string[],
        public operatingSystemDescription: Optional<string>,
        public operatingSystemName: Optional<string>,
        public operatingSystemType: Optional<OperatingSystemType>,
        public privateDnsNames: string[],
        public privateIpAddresses: string[],
        public productCode: Optional<AwsResourceProductCode>,
        public publicIpAddressDnsNames: string[],
        public publicIpAddresses: string[],
        public roleReference: Optional<AwsResourceReference>,
        public rootVolumeId: Optional<string>,
        public rootVolumeRawId: Optional<string>,
        public securityGroupIds: string[],
        public status: AwsEc2InstanceStatus,
        public stopTime: Optional<string>,
        public subnetIds: string[],
        public typeData: AwsEc2InstanceTypeData,
        public userData: Optional<string>,
        public volumeIds: string[],
        public vpcIds: string[],
        public vpcRawId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2Instance",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            rawId);
    }
}

export class AwsEc2InstanceInstanceProfileNotExistRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2InstanceInstanceProfileNotExistRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsEc2InstanceMetadataServiceTokenHopMaxCountRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2InstanceMetadataServiceTokenHopMaxCountRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsEc2InstanceMetadataServiceVersionRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2InstanceMetadataServiceVersionRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsEc2InstanceModel extends AwsEc2ResourceModel implements ITypeNameObject, IUdmObjectModel, IAwsNetworkedResourceModel, IVirtualMachineModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        public entityNetwork: Optional<NetworkAccessResourceStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public autoScalingGroupId: Optional<string>,
        public clusterId: Optional<string>,
        public diskEncryptionVaultIdReference: Optional<string>,
        public dnsZoneIds: string[],
        public elasticIpIds: string[],
        public identityPermissionActionSeverity: Optional<Severity>,
        public imageId: Optional<string>,
        public imageName: Optional<string>,
        public kubernetesClusterSensor: boolean,
        public launchConfigurationId: Optional<string>,
        public loadBalancerIdReferences: string[],
        public operatingSystemDisplayName: Optional<string>,
        public operatingSystemReleaseNotesUrl: Optional<string>,
        public operatingSystemSupported: Optional<boolean>,
        public operatingSystemType: Optional<OperatingSystemType>,
        public scanTime: Optional<string>,
        public stopped: boolean,
        public stopTime: Optional<string>,
        public vulnerabilities: Optional<VulnerabilityData[]>,
        public vulnerabilitySeveritiesSortValue: string,
        public workloadAnalysisError: Optional<WorkloadAnalysisError>,
        public workloadAnalysisOperatingSystem: Optional<WorkloadResourceScanOperatingSystem>,
        public workloadAnalysisOperatingSystemEndOfLifeDate: Optional<string>,
        public workloadAnalysisOperatingSystemExtendedSupport: Optional<boolean>,
        public workloadAnalysisPriorityScanRequested: boolean,
        public workloadAnalysisStatus: WorkloadAnalysisStatus,
        public workloadAnalysisStatusSortValue: number) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2InstanceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsEc2InstanceUserDataSecretExistsRiskPolicyConfiguration extends SecretExistsRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        exclusionSecretPatterns: Optional<SecretExistsRiskPolicyConfigurationSecretExclusionSecretPattern[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2InstanceUserDataSecretExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            exclusionSecretPatterns);
    }
}

export class AwsEc2InternetGateway extends AwsEc2Resource implements ITypeNameObject, IAwsTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        rawId: string,
        public vpcId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2InternetGateway",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            rawId);
    }
}

export class AwsEc2InternetGatewayModel extends AwsEc2ResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2InternetGatewayModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsEc2IpamPool extends AwsEc2Resource implements ITypeNameObject, IAwsResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        rawId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2IpamPool",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            rawId);
    }
}

export class AwsEc2IpamPoolModel extends AwsEc2ResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2IpamPoolModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsEc2LaunchTemplate extends AwsEc2Resource implements ITypeNameObject, IAwsEncryptedResource, IAwsTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        rawId: string,
        public creatorIdentityReference: AwsResourceReference,
        public defaultRevisionId: number,
        public encryptionExists: boolean,
        public kmsEncryptionKeyOrAliasArns: string[],
        public latestRevisionId: number,
        public revisionMap: Dictionary<AwsEc2LaunchTemplateRevision>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2LaunchTemplate",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            rawId);
    }
}

export class AwsEc2LaunchTemplateModel extends AwsEc2ResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAwsEncryptedResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public deletedRevisionAutoScalingGroupIds: string[],
        public deletedRevisionInstanceIds: string[],
        public kmsEncryptionKeyIdReferences: string[],
        public kmsEncryptionKeyOrAliasArnToIdReferenceMap: Dictionary<string>,
        public revisionIdToDataMap: Dictionary<AwsEc2LaunchTemplateModelRevisionData>,
        public snapshotRawIdToIdMap: Dictionary<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2LaunchTemplateModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsEc2LoadBalancerListenerInsecureSslProtocolExistsRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2LoadBalancerListenerInsecureSslProtocolExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsEc2LocalGatewayRouteTable extends AwsEc2Resource implements ITypeNameObject, IAwsResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        rawId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2LocalGatewayRouteTable",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            rawId);
    }
}

export class AwsEc2LocalGatewayRouteTableModel extends AwsEc2ResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2LocalGatewayRouteTableModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsEc2NatGateway extends AwsEc2Resource implements ITypeNameObject, IAwsTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        rawId: string,
        public privateIpAddresses: string[],
        public publicIpAddresses: string[],
        public subnetId: string,
        public vpcId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2NatGateway",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            rawId);
    }
}

export class AwsEc2NatGatewayModel extends AwsEc2ResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2NatGatewayModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsEc2NetworkAcl extends AwsEc2Resource implements ITypeNameObject, IAwsTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        rawId: string,
        public defaultNetworkAcl: boolean,
        public inboundRules: AwsEc2NetworkAclRule[],
        public outboundRules: AwsEc2NetworkAclRule[],
        public vpcId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2NetworkAcl",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            rawId);
    }
}

export class AwsEc2NetworkAclInboundRuleSubnetAnyExistsRisk extends AwsEc2NetworkResourceInboundRuleSubnetAnyExistsRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        attachedResourceIds: string[],
        directWideRangeInboundExternalAttachedResourceIds: string[],
        lambdaFunctionConfigurationOnlyAttached: boolean,
        public unrestrictedInboundRules: AwsEc2NetworkAclRule[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2NetworkAclInboundRuleSubnetAnyExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            attachedResourceIds,
            directWideRangeInboundExternalAttachedResourceIds,
            lambdaFunctionConfigurationOnlyAttached);
    }
}

export class AwsEc2NetworkAclInboundRuleSubnetAnyExistsRiskModel extends AwsEc2NetworkResourceInboundRuleSubnetAnyExistsRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsEc2NetworkAclInboundRuleSubnetAnyExistsRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        allPorts: boolean,
        unrestrictedDestinationNetworkScopes: DestinationNetworkScope[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2NetworkAclInboundRuleSubnetAnyExistsRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            allPorts,
            unrestrictedDestinationNetworkScopes);
    }
}

export class AwsEc2NetworkAclInboundRuleSubnetAnyExistsRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2NetworkAclInboundRuleSubnetAnyExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsEc2NetworkAclModel extends AwsEc2ResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public subnetIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2NetworkAclModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsEc2NetworkInterface extends AwsEc2Resource implements ITypeNameObject, IAwsDnsRecordResource, IAwsNetworkedResource, IAwsTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        rawId: string,
        public availabilityZone: AwsAvailabilityZone,
        public description: Optional<string>,
        public dnsNames: string[],
        public fabricAdapter: boolean,
        public flowLogIds: string[],
        public macAddress: string,
        public privateIpAddresses: string[],
        public publicIpAddressDnsNames: string[],
        public publicIpAddresses: string[],
        public securityGroupIds: string[],
        public subnetIds: string[],
        public vpcIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2NetworkInterface",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            rawId);
    }
}

export class AwsEc2NetworkInterfaceModel extends AwsEc2ResourceModel implements ITypeNameObject, IUdmObjectModel, IAwsNetworkedResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public dnsZoneIds: string[],
        public elasticIpIds: string[],
        public flowLogDestinationResourceIdReferences: string[],
        public flowLogFilter: Optional<AwsEc2FlowLogFilter>,
        public instanceId: Optional<string>,
        public serviceIds: string[],
        public taskDefinitionReferences: AwsResourceReference[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2NetworkInterfaceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsEc2SecurityGroupAllowedInboundRuleRisk extends AwsEc2SecurityGroupInboundRuleRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2SecurityGroupAllowedInboundRuleRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType);
    }
}

export class AwsEc2SecurityGroupAllowedInboundRuleRiskModel extends AwsEc2SecurityGroupInboundRuleRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        risk: Risk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        networkScopes: DestinationNetworkScope[],
        securityGroupIds: string[],
        subnets: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2SecurityGroupAllowedInboundRuleRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            networkScopes,
            securityGroupIds,
            subnets);
    }
}

export class AwsEc2SecurityGroupInboundRuleRiskPolicyConfiguration extends CustomRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        description: Optional<string>,
        name: string,
        severity: Severity,
        updateTime: string,
        public allSecurityGroups: boolean,
        public anyIpV4AddressSubnet: boolean,
        public exactSubnets: Optional<string[]>,
        public inboundAccessScope: Optional<NetworkAccessScope>,
        public networkScopes: DestinationNetworkScope[],
        public overlapSubnets: Optional<string[]>,
        public securityGroupBuiltInAttributeTypeNames: Optional<string[]>,
        public securityGroupCustomAttributeDefinitionIds: Optional<string[]>,
        public securityGroupIds: Optional<string[]>,
        public securityGroupTags: Optional<ResourceTag[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            description,
            name,
            severity,
            updateTime);
    }
}

export class AwsEc2SecurityGroupInboundRuleSubnetAnyExistsRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2SecurityGroupInboundRuleSubnetAnyExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsEc2SecurityGroupManagementRisk extends AwsResourceUsageRisk implements ITypeNameObject, IActivityRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        eventNames: string[],
        identityIds: string[],
        resourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2SecurityGroupManagementRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            eventNames,
            identityIds,
            resourceIds);
    }
}

export class AwsEc2SecurityGroupNotAllowedInboundRuleRiskPolicyConfiguration extends AwsEc2SecurityGroupInboundRuleRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        description: Optional<string>,
        name: string,
        severity: Severity,
        updateTime: string,
        allSecurityGroups: boolean,
        anyIpV4AddressSubnet: boolean,
        exactSubnets: Optional<string[]>,
        inboundAccessScope: Optional<NetworkAccessScope>,
        networkScopes: DestinationNetworkScope[],
        overlapSubnets: Optional<string[]>,
        securityGroupBuiltInAttributeTypeNames: Optional<string[]>,
        securityGroupCustomAttributeDefinitionIds: Optional<string[]>,
        securityGroupIds: Optional<string[]>,
        securityGroupTags: Optional<ResourceTag[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2SecurityGroupNotAllowedInboundRuleRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            description,
            name,
            severity,
            updateTime,
            allSecurityGroups,
            anyIpV4AddressSubnet,
            exactSubnets,
            inboundAccessScope,
            networkScopes,
            overlapSubnets,
            securityGroupBuiltInAttributeTypeNames,
            securityGroupCustomAttributeDefinitionIds,
            securityGroupIds,
            securityGroupTags);
    }
}

export class AwsEc2SecurityGroupUnusedRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        public usageTimeFrame: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2SecurityGroupUnusedRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsEc2SnapshotEncryptionDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2SnapshotEncryptionDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsEc2SnapshotPublicAccessExistsRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2SnapshotPublicAccessExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsEc2SpotInstanceGroupModel extends AwsTagResourceGroupModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IEntityGroupModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2SpotInstanceGroupModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsEc2VolumeEncryptionDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2VolumeEncryptionDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsEc2VpcDefaultSecurityGroupRuleExistsRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2VpcDefaultSecurityGroupRuleExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsEc2VpcEndpointUnrestrictedServiceAccessRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2VpcEndpointUnrestrictedServiceAccessRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsEc2VpcRejectFlowLogNotExistRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2VpcRejectFlowLogNotExistRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsEcrRepository extends AwsEcrResource implements ITypeNameObject, IAwsAccessResource, IAwsDnsRecordResource, IAwsEncryptedResource, IAwsPolicyResource, IAwsTagResource, IContainerImageRepository {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public accessLevel: Optional<AwsResourceAccessLevel>,
        public containerImageCount: number,
        public containerImageIds: string[],
        public dnsNames: string[],
        public encryptionExists: boolean,
        public encryptionType: AwsEcrRepositoryEncryptionType,
        public kmsEncryptionKeyOrAliasArns: string[],
        public policyDocument: Optional<AwsIamPolicyDocument>,
        public policyDocumentExists: boolean,
        public publicAccessHighSeveritySensitivePermissionActions: string[],
        public publicAccessNonKmsEncryptedDataPermissionActions: string[],
        public publicAccessPermissionActions: string[],
        public publicIpAddresses: Optional<string[]>,
        public publicRegistry: boolean,
        public rawUrl: string,
        public scanningConfiguration: AwsEcrRepositoryScanningConfiguration,
        public scanOnPushEnabled: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEcrRepository",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsEcrRepositoryPublicAccessExistsRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEcrRepositoryPublicAccessExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsEcsCluster extends AwsEcsResource implements ITypeNameObject, IAwsResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public instanceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEcsCluster",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsEcsTaskDefinitionEnvironmentVariableSecretExistsRiskPolicyConfiguration extends SecretExistsRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        exclusionSecretPatterns: Optional<SecretExistsRiskPolicyConfigurationSecretExclusionSecretPattern[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEcsTaskDefinitionEnvironmentVariableSecretExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            exclusionSecretPatterns);
    }
}

export class AwsEksCluster extends AwsEksResource implements ITypeNameObject, IAwsDnsRecordResource, IAwsEncryptedResource, IAwsNetworkedResource, IAwsOriginatorComputeResource, IAwsTagResource, IManagedKubernetesCluster {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public dnsNames: string[],
        public encryptionExists: boolean,
        public kmsEncryptionKeyOrAliasArn: Optional<string>,
        public kmsEncryptionKeyOrAliasArns: string[],
        public network: AwsEksClusterNetwork,
        public oidcIdentityProviderIssuerUrl: Optional<string>,
        public platform: Optional<KubernetesClusterPlatform>,
        public platformVersion: string,
        public publicIpAddresses: Optional<string[]>,
        public securityGroupIds: string[],
        public serviceRoleReference: AwsResourceReference,
        public status: AwsEksClusterStatus,
        public subnetIds: string[],
        public url: Optional<string>,
        public version: string,
        public vpcIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEksCluster",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsEksClusterInboundExternalWideRangeRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEksClusterInboundExternalWideRangeRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsEksClusterInstanceInboundExternalRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEksClusterInstanceInboundExternalRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsEksClusterKubernetesSecretEncryptionDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEksClusterKubernetesSecretEncryptionDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsEksClusterNodeGroup extends AwsEksResource implements ITypeNameObject, IAwsTagResource, IAwsNetworkedResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public autoScalingGroupIds: string[],
        public clusterId: string,
        public clusterName: string,
        public node: AwsEksClusterNodeGroupNode,
        public nodeAutoscaling: AwsEksClusterNodeGroupNodeAutoscaling,
        public securityGroupIds: string[],
        public status: AwsEksClusterNodeGroupStatus,
        public subnetIds: string[],
        public vpcIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEksClusterNodeGroup",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsEksFargateProfile extends AwsEksResource implements ITypeNameObject, IAwsResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public clusterId: string,
        public podExecutionRoleReference: AwsResourceReference,
        public podSelectors: AwsEksFargateProfilePodSelector[],
        public status: AwsEksFargateProfileStatus,
        public subnetIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEksFargateProfile",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsElastiCacheReplicationGroupAutoFailoverDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsElastiCacheReplicationGroupAutoFailoverDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsElastiCacheReplicationGroupAutomaticBackupsDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        public backupRetentionTimeFrame: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsElastiCacheReplicationGroupAutomaticBackupsDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsElastiCacheReplicationGroupAutoUpgradeMinorVersionsDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsElastiCacheReplicationGroupAutoUpgradeMinorVersionsDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsElastiCacheReplicationGroupDefaultSubnetGroupRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsElastiCacheReplicationGroupDefaultSubnetGroupRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsElastiCacheReplicationGroupEncryptionDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsElastiCacheReplicationGroupEncryptionDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsElastiCacheReplicationGroupTransitEncryptionModeNotRequiredRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsElastiCacheReplicationGroupTransitEncryptionModeNotRequiredRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsElasticBeanstalkApplication extends AwsElasticBeanstalkResource implements ITypeNameObject, IAwsResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        description: Optional<string>,
        updateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsElasticBeanstalkApplication",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            description,
            updateTime);
    }
}

export class AwsElasticBeanstalkEnvironment extends AwsElasticBeanstalkResource implements ITypeNameObject, IAwsCloudWatchLoggingResource, IAwsDnsRecordResource, IAwsOriginatorComputeResource, IAwsTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        description: Optional<string>,
        updateTime: string,
        public applicationId: string,
        public applicationName: string,
        public autoScalingGroupIds: string[],
        public autoScalingGroupNames: string[],
        public cloudWatchLoggingEnabled: Optional<boolean>,
        public cloudWatchLogRetentionTimeFrame: Optional<string>,
        public cloudWatchLogTypes: string[],
        public dnsName: Optional<string>,
        public dnsNames: string[],
        public hostname: Optional<string>,
        public publicIpAddresses: string[],
        public rawId: string,
        public tier: AwsElasticBeanstalkEnvironmentTier) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsElasticBeanstalkEnvironment",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            description,
            updateTime);
    }
}

export class AwsElasticBeanstalkEnvironmentCloudWatchLoggingDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        public cloudWatchLogRetentionTimeFrame: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsElasticBeanstalkEnvironmentCloudWatchLoggingDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsElasticFileSystemFileSystemEncryptionDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsElasticFileSystemFileSystemEncryptionDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsElbApplicationLoadBalancerListenerModel extends AwsElbLoadBalancerListenerModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsElbApplicationLoadBalancerListenerModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsElbApplicationLoadBalancerModel extends AwsElbLoadBalancerModel implements ITypeNameObject, IUdmObjectModel, IAwsNetworkedResourceModel, INetworkAccessResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        public entityNetwork: Optional<NetworkAccessResourceStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        autoScalingGroupIds: string[],
        dnsZoneIds: string[],
        listenerIds: string[],
        targetGroupIds: string[],
        type: AwsElbLoadBalancerModelType,
        public webAclId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsElbApplicationLoadBalancerModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId,
            autoScalingGroupIds,
            dnsZoneIds,
            listenerIds,
            targetGroupIds,
            type);
    }
}

export class AwsElbClassicLoadBalancerListenerModel extends AwsElbLoadBalancerListenerModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsElbClassicLoadBalancerListenerModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsElbClassicLoadBalancerModel extends AwsElbLoadBalancerModel implements ITypeNameObject, IUdmObjectModel, IAwsNetworkedResourceModel, INetworkAccessResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        public entityNetwork: Optional<NetworkAccessResourceStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        autoScalingGroupIds: string[],
        dnsZoneIds: string[],
        listenerIds: string[],
        targetGroupIds: string[],
        type: AwsElbLoadBalancerModelType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsElbClassicLoadBalancerModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId,
            autoScalingGroupIds,
            dnsZoneIds,
            listenerIds,
            targetGroupIds,
            type);
    }
}

export class AwsElbLoadBalancer extends AwsElbResource implements ITypeNameObject, IAwsDnsRecordResource, IAwsNetworkEvaluationNetworkedResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public accessLogsEnabled: boolean,
        public availabilityZones: AwsAvailabilityZone[],
        public dnsName: string,
        public dnsNames: string[],
        public external: boolean,
        public networkInterfaceIds: string[],
        public privateIpAddresses: string[],
        public publicIpAddresses: string[],
        public securityGroupIds: string[],
        public subnetIds: string[],
        public vpcIds: string[],
        public vpcRawId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsElbLoadBalancerListener extends AwsElbResource implements ITypeNameObject, IAwsCertificateResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public certificateIds: string[],
        public loadBalancerName: string,
        public loadBalancerReference: AwsResourceReference,
        public port: number,
        public protocol: AwsElbProtocol,
        public securityPolicy: Optional<AwsElbLoadBalancerListenerSecurityPolicy>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsElbModernLoadBalancerListener extends AwsElbLoadBalancerListener implements ITypeNameObject, IAwsCertificateResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        certificateIds: string[],
        loadBalancerName: string,
        loadBalancerReference: AwsResourceReference,
        port: number,
        protocol: AwsElbProtocol,
        securityPolicy: Optional<AwsElbLoadBalancerListenerSecurityPolicy>,
        public targetGroupIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            certificateIds,
            loadBalancerName,
            loadBalancerReference,
            port,
            protocol,
            securityPolicy);
    }
}

export class AwsElbNetworkLoadBalancer extends AwsElbLoadBalancer implements ITypeNameObject, IAwsDnsRecordResource, IAwsNetworkEvaluationNetworkedResource, IAwsTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        accessLogsEnabled: boolean,
        availabilityZones: AwsAvailabilityZone[],
        dnsName: string,
        dnsNames: string[],
        external: boolean,
        networkInterfaceIds: string[],
        privateIpAddresses: string[],
        publicIpAddresses: string[],
        securityGroupIds: string[],
        subnetIds: string[],
        vpcIds: string[],
        vpcRawId: Optional<string>,
        public crossAvailabilityZoneEnabled: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsElbNetworkLoadBalancer",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            accessLogsEnabled,
            availabilityZones,
            dnsName,
            dnsNames,
            external,
            networkInterfaceIds,
            privateIpAddresses,
            publicIpAddresses,
            securityGroupIds,
            subnetIds,
            vpcIds,
            vpcRawId);
    }
}

export class AwsElbNetworkLoadBalancerListener extends AwsElbModernLoadBalancerListener implements ITypeNameObject, IAwsCertificateResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        certificateIds: string[],
        loadBalancerName: string,
        loadBalancerReference: AwsResourceReference,
        port: number,
        protocol: AwsElbProtocol,
        securityPolicy: Optional<AwsElbLoadBalancerListenerSecurityPolicy>,
        targetGroupIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsElbNetworkLoadBalancerListener",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            certificateIds,
            loadBalancerName,
            loadBalancerReference,
            port,
            protocol,
            securityPolicy,
            targetGroupIds);
    }
}

export class AwsEncryptedResourceKmsEncryptionNotExistsRiskPolicyConfiguration extends CustomRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        description: Optional<string>,
        name: string,
        severity: Severity,
        updateTime: string,
        public allEncryptedResources: boolean,
        public anyCustomerManagedKmsKey: boolean,
        public anyKmsKey: boolean,
        public encryptedResourceBuiltInAttributeTypeNames: Optional<string[]>,
        public encryptedResourceCustomAttributeDefinitionIds: Optional<string[]>,
        public encryptedResourceIds: Optional<string[]>,
        public encryptedResourceTags: Optional<ResourceTag[]>,
        public encryptedResourceTypeNames: Optional<string[]>,
        public kmsKeyBuiltInAttributeTypeNames: Optional<string[]>,
        public kmsKeyCustomAttributeDefinitionIds: Optional<string[]>,
        public kmsKeyIds: Optional<string[]>,
        public kmsKeyTags: Optional<ResourceTag[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEncryptedResourceKmsEncryptionNotExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            description,
            name,
            severity,
            updateTime);
    }
}

export class AwsEsDomainCloudWatchLoggingDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEsDomainCloudWatchLoggingDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsEsDomainEncryptionDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEsDomainEncryptionDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsEsDomainNodeCommunicationEncryptionDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEsDomainNodeCommunicationEncryptionDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsEsDomainPublicAccessExistsRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEsDomainPublicAccessExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsEventsRuleOnCustomEventBus extends AwsNotSyncedResource implements ITypeNameObject, IAwsResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEventsRuleOnCustomEventBus",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsEventsRuleOnDefaultEventBus extends AwsNotSyncedResource implements ITypeNameObject, IAwsResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEventsRuleOnDefaultEventBus",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsExcessivePermissionBucketRisk extends AwsExcessivePermissionResourceRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        existingPermissionActionServiceExists: boolean,
        existingPermissionActionVendorTenantIds: string[],
        newPolicyDocument: Optional<AwsIamPolicyDocument>,
        nonexcessivePermissionActionServiceExists: boolean,
        severityInfo: AwsExcessivePermissionResourceRiskSeverityInfo) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsExcessivePermissionBucketRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            existingPermissionActionServiceExists,
            existingPermissionActionVendorTenantIds,
            newPolicyDocument,
            nonexcessivePermissionActionServiceExists,
            severityInfo);
    }
}

export class AwsExcessivePermissionBucketRiskModel extends AwsExcessivePermissionResourceRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsExcessivePermissionBucketRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        descriptionType: AwsExcessivePermissionResourceRiskModelDescriptionType,
        infos: AwsExcessivePermissionResourceRiskModelInfo[],
        newPolicyFileTypeToFileMap: Dictionary<RiskFile>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsExcessivePermissionBucketRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            descriptionType,
            infos,
            newPolicyFileTypeToFileMap);
    }
}

export class AwsExcessivePermissionBucketRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsExcessivePermissionBucketRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsExcessivePermissionGroupRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsExcessivePermissionGroupRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsExcessivePermissionIamPrincipalRisk extends AwsExcessivePermissionPrincipalRisk implements ITypeNameObject, IAwsExcessivePermissionPrincipalRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        activityTime: Optional<string>,
        excessivePermissionActionSeverity: Optional<Severity>,
        groupUserIds: string[],
        permissionActions: string[],
        roleMostIndirectOriginatorResourceIds: string[],
        roleOriginatorResourceIds: string[],
        sensitiveResources: AwsAccessPrincipalRiskSensitiveResources,
        serviceIdToUsageTimeMap: Dictionary<Optional<string>>,
        policyAssociationAndInfos: AwsExcessivePermissionPrincipalRiskPolicyAssociationAndInfo[],
        resolutionChangeIdToPolicyInfoMap: Dictionary<AwsExcessivePermissionPrincipalRiskResolutionPolicyInfo>,
        resourceLevelServiceIds: string[],
        serviceLevel: Optional<AwsPrincipalNonexcessivePolicyGeneratorServiceLevel>,
        serviceResourceLevel: Optional<AwsPrincipalNonexcessivePolicyGeneratorServiceResourceLevel>,
        serviceResourceLevelNotUsedPermissionActionResourceIds: string[],
        serviceResourceLevels: AwsPrincipalNonexcessivePolicyGeneratorServiceResourceLevel[],
        public roleAssumingEntityIds: string[],
        public userExcessiveGroupMembershipGroupIds: string[],
        public userExcessiveGroupMembershipPermissionActionSeverity: Optional<Severity>,
        public userExcessiveGroupMembershipPermissionActionSeverityToGroupIdsMap: Dictionary<string[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            activityTime,
            excessivePermissionActionSeverity,
            groupUserIds,
            permissionActions,
            roleMostIndirectOriginatorResourceIds,
            roleOriginatorResourceIds,
            sensitiveResources,
            serviceIdToUsageTimeMap,
            policyAssociationAndInfos,
            resolutionChangeIdToPolicyInfoMap,
            resourceLevelServiceIds,
            serviceLevel,
            serviceResourceLevel,
            serviceResourceLevelNotUsedPermissionActionResourceIds,
            serviceResourceLevels);
    }
}

export class AwsExcessivePermissionIamPrincipalRiskModel extends AwsExcessivePermissionPrincipalRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsExcessivePermissionIamPrincipalRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        userActiveAccessKeyCount: Optional<number>,
        lastUsedServiceInfo: Optional<AwsExcessivePermissionPrincipalRiskModelServiceInfo>,
        serviceIds: string[],
        serviceInfos: AwsExcessivePermissionPrincipalRiskModelServiceInfo[],
        public infos: AwsExcessivePermissionIamPrincipalRiskModelInfo[],
        public lastUsedExcessivePermissionServiceInfo: Optional<AwsExcessivePermissionPrincipalRiskModelServiceInfo>,
        public policyIds: string[],
        public upsertPrincipalManagedPolicyChangeIdToFileTypeToFileMap: Dictionary<Dictionary<RiskFile>>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            userActiveAccessKeyCount,
            lastUsedServiceInfo,
            serviceIds,
            serviceInfos);
    }
}

export class AwsExcessivePermissionPermissionSetRisk extends AwsExcessivePermissionPrincipalRisk implements ITypeNameObject, IAwsExcessivePermissionPrincipalRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        activityTime: Optional<string>,
        excessivePermissionActionSeverity: Optional<Severity>,
        groupUserIds: string[],
        permissionActions: string[],
        roleMostIndirectOriginatorResourceIds: string[],
        roleOriginatorResourceIds: string[],
        sensitiveResources: AwsAccessPrincipalRiskSensitiveResources,
        serviceIdToUsageTimeMap: Dictionary<Optional<string>>,
        policyAssociationAndInfos: AwsExcessivePermissionPrincipalRiskPolicyAssociationAndInfo[],
        resolutionChangeIdToPolicyInfoMap: Dictionary<AwsExcessivePermissionPrincipalRiskResolutionPolicyInfo>,
        resourceLevelServiceIds: string[],
        serviceLevel: Optional<AwsPrincipalNonexcessivePolicyGeneratorServiceLevel>,
        serviceResourceLevel: Optional<AwsPrincipalNonexcessivePolicyGeneratorServiceResourceLevel>,
        serviceResourceLevelNotUsedPermissionActionResourceIds: string[],
        serviceResourceLevels: AwsPrincipalNonexcessivePolicyGeneratorServiceResourceLevel[],
        public assignedPrincipalIds: string[],
        public excessivePermissionAccountIds: string[],
        public excessivePermissionRoleIds: string[],
        public inactive: Optional<boolean>,
        public inlinePolicyExists: boolean,
        public managedPolicyIds: string[],
        public nonexcessivePermissionAccountIds: string[],
        public nonexcessivePermissionRoleIds: string[],
        public nonexcessivePermissionSeverity: Optional<Severity>,
        public unknownAccountIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsExcessivePermissionPermissionSetRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            activityTime,
            excessivePermissionActionSeverity,
            groupUserIds,
            permissionActions,
            roleMostIndirectOriginatorResourceIds,
            roleOriginatorResourceIds,
            sensitiveResources,
            serviceIdToUsageTimeMap,
            policyAssociationAndInfos,
            resolutionChangeIdToPolicyInfoMap,
            resourceLevelServiceIds,
            serviceLevel,
            serviceResourceLevel,
            serviceResourceLevelNotUsedPermissionActionResourceIds,
            serviceResourceLevels);
    }
}

export class AwsExcessivePermissionPermissionSetRiskModel extends AwsExcessivePermissionPrincipalRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsExcessivePermissionPermissionSetRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        userActiveAccessKeyCount: Optional<number>,
        lastUsedServiceInfo: Optional<AwsExcessivePermissionPrincipalRiskModelServiceInfo>,
        serviceIds: string[],
        serviceInfos: AwsExcessivePermissionPrincipalRiskModelServiceInfo[],
        public accountIds: string[],
        public descriptionType: AwsExcessivePermissionPermissionSetRiskModelDescriptionType,
        public excessivePermissionServiceMaxUsageTime: Optional<string>,
        public fileTypeToFileMap: Dictionary<RiskFile>,
        public infos: AwsExcessivePermissionPermissionSetRiskModelInfo[],
        public knownAccountIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsExcessivePermissionPermissionSetRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            userActiveAccessKeyCount,
            lastUsedServiceInfo,
            serviceIds,
            serviceInfos);
    }
}

export class AwsExcessivePermissionPermissionSetRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsExcessivePermissionPermissionSetRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsExcessivePermissionRepositoryRisk extends AwsExcessivePermissionResourceRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        existingPermissionActionServiceExists: boolean,
        existingPermissionActionVendorTenantIds: string[],
        newPolicyDocument: Optional<AwsIamPolicyDocument>,
        nonexcessivePermissionActionServiceExists: boolean,
        severityInfo: AwsExcessivePermissionResourceRiskSeverityInfo) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsExcessivePermissionRepositoryRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            existingPermissionActionServiceExists,
            existingPermissionActionVendorTenantIds,
            newPolicyDocument,
            nonexcessivePermissionActionServiceExists,
            severityInfo);
    }
}

export class AwsExcessivePermissionRepositoryRiskModel extends AwsExcessivePermissionResourceRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsExcessivePermissionResourceRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        descriptionType: AwsExcessivePermissionResourceRiskModelDescriptionType,
        infos: AwsExcessivePermissionResourceRiskModelInfo[],
        newPolicyFileTypeToFileMap: Dictionary<RiskFile>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsExcessivePermissionRepositoryRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            descriptionType,
            infos,
            newPolicyFileTypeToFileMap);
    }
}

export class AwsExcessivePermissionRepositoryRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsExcessivePermissionRepositoryRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsExcessivePermissionRoleGroupRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsExcessivePermissionRoleGroupRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsExcessivePermissionRoleRisk extends AwsExcessivePermissionIamPrincipalRisk implements ITypeNameObject, IAwsExcessivePermissionPrincipalRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        activityTime: Optional<string>,
        excessivePermissionActionSeverity: Optional<Severity>,
        groupUserIds: string[],
        permissionActions: string[],
        roleMostIndirectOriginatorResourceIds: string[],
        roleOriginatorResourceIds: string[],
        sensitiveResources: AwsAccessPrincipalRiskSensitiveResources,
        serviceIdToUsageTimeMap: Dictionary<Optional<string>>,
        policyAssociationAndInfos: AwsExcessivePermissionPrincipalRiskPolicyAssociationAndInfo[],
        resolutionChangeIdToPolicyInfoMap: Dictionary<AwsExcessivePermissionPrincipalRiskResolutionPolicyInfo>,
        resourceLevelServiceIds: string[],
        serviceLevel: Optional<AwsPrincipalNonexcessivePolicyGeneratorServiceLevel>,
        serviceResourceLevel: Optional<AwsPrincipalNonexcessivePolicyGeneratorServiceResourceLevel>,
        serviceResourceLevelNotUsedPermissionActionResourceIds: string[],
        serviceResourceLevels: AwsPrincipalNonexcessivePolicyGeneratorServiceResourceLevel[],
        roleAssumingEntityIds: string[],
        userExcessiveGroupMembershipGroupIds: string[],
        userExcessiveGroupMembershipPermissionActionSeverity: Optional<Severity>,
        userExcessiveGroupMembershipPermissionActionSeverityToGroupIdsMap: Dictionary<string[]>,
        public unknownTenantIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsExcessivePermissionRoleRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            activityTime,
            excessivePermissionActionSeverity,
            groupUserIds,
            permissionActions,
            roleMostIndirectOriginatorResourceIds,
            roleOriginatorResourceIds,
            sensitiveResources,
            serviceIdToUsageTimeMap,
            policyAssociationAndInfos,
            resolutionChangeIdToPolicyInfoMap,
            resourceLevelServiceIds,
            serviceLevel,
            serviceResourceLevel,
            serviceResourceLevelNotUsedPermissionActionResourceIds,
            serviceResourceLevels,
            roleAssumingEntityIds,
            userExcessiveGroupMembershipGroupIds,
            userExcessiveGroupMembershipPermissionActionSeverity,
            userExcessiveGroupMembershipPermissionActionSeverityToGroupIdsMap);
    }
}

export class AwsExcessivePermissionRoleRiskModel extends AwsExcessivePermissionIamPrincipalRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsExcessivePermissionIamPrincipalRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        userActiveAccessKeyCount: Optional<number>,
        lastUsedServiceInfo: Optional<AwsExcessivePermissionPrincipalRiskModelServiceInfo>,
        serviceIds: string[],
        serviceInfos: AwsExcessivePermissionPrincipalRiskModelServiceInfo[],
        infos: AwsExcessivePermissionIamPrincipalRiskModelInfo[],
        lastUsedExcessivePermissionServiceInfo: Optional<AwsExcessivePermissionPrincipalRiskModelServiceInfo>,
        policyIds: string[],
        upsertPrincipalManagedPolicyChangeIdToFileTypeToFileMap: Dictionary<Dictionary<RiskFile>>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsExcessivePermissionRoleRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            userActiveAccessKeyCount,
            lastUsedServiceInfo,
            serviceIds,
            serviceInfos,
            infos,
            lastUsedExcessivePermissionServiceInfo,
            policyIds,
            upsertPrincipalManagedPolicyChangeIdToFileTypeToFileMap);
    }
}

export class AwsExcessivePermissionRoleRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsExcessivePermissionRoleRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsExcessivePermissionSecretRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsExcessivePermissionSecretRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsExcessivePermissionUserRisk extends AwsExcessivePermissionIamPrincipalRisk implements ITypeNameObject, IAwsExcessivePermissionPrincipalRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        activityTime: Optional<string>,
        excessivePermissionActionSeverity: Optional<Severity>,
        groupUserIds: string[],
        permissionActions: string[],
        roleMostIndirectOriginatorResourceIds: string[],
        roleOriginatorResourceIds: string[],
        sensitiveResources: AwsAccessPrincipalRiskSensitiveResources,
        serviceIdToUsageTimeMap: Dictionary<Optional<string>>,
        policyAssociationAndInfos: AwsExcessivePermissionPrincipalRiskPolicyAssociationAndInfo[],
        resolutionChangeIdToPolicyInfoMap: Dictionary<AwsExcessivePermissionPrincipalRiskResolutionPolicyInfo>,
        resourceLevelServiceIds: string[],
        serviceLevel: Optional<AwsPrincipalNonexcessivePolicyGeneratorServiceLevel>,
        serviceResourceLevel: Optional<AwsPrincipalNonexcessivePolicyGeneratorServiceResourceLevel>,
        serviceResourceLevelNotUsedPermissionActionResourceIds: string[],
        serviceResourceLevels: AwsPrincipalNonexcessivePolicyGeneratorServiceResourceLevel[],
        roleAssumingEntityIds: string[],
        userExcessiveGroupMembershipGroupIds: string[],
        userExcessiveGroupMembershipPermissionActionSeverity: Optional<Severity>,
        userExcessiveGroupMembershipPermissionActionSeverityToGroupIdsMap: Dictionary<string[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsExcessivePermissionUserRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            activityTime,
            excessivePermissionActionSeverity,
            groupUserIds,
            permissionActions,
            roleMostIndirectOriginatorResourceIds,
            roleOriginatorResourceIds,
            sensitiveResources,
            serviceIdToUsageTimeMap,
            policyAssociationAndInfos,
            resolutionChangeIdToPolicyInfoMap,
            resourceLevelServiceIds,
            serviceLevel,
            serviceResourceLevel,
            serviceResourceLevelNotUsedPermissionActionResourceIds,
            serviceResourceLevels,
            roleAssumingEntityIds,
            userExcessiveGroupMembershipGroupIds,
            userExcessiveGroupMembershipPermissionActionSeverity,
            userExcessiveGroupMembershipPermissionActionSeverityToGroupIdsMap);
    }
}

export class AwsExcessivePermissionUserRiskModel extends AwsExcessivePermissionIamPrincipalRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsExcessivePermissionIamPrincipalRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        userActiveAccessKeyCount: Optional<number>,
        lastUsedServiceInfo: Optional<AwsExcessivePermissionPrincipalRiskModelServiceInfo>,
        serviceIds: string[],
        serviceInfos: AwsExcessivePermissionPrincipalRiskModelServiceInfo[],
        infos: AwsExcessivePermissionIamPrincipalRiskModelInfo[],
        lastUsedExcessivePermissionServiceInfo: Optional<AwsExcessivePermissionPrincipalRiskModelServiceInfo>,
        policyIds: string[],
        upsertPrincipalManagedPolicyChangeIdToFileTypeToFileMap: Dictionary<Dictionary<RiskFile>>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsExcessivePermissionUserRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            userActiveAccessKeyCount,
            lastUsedServiceInfo,
            serviceIds,
            serviceInfos,
            infos,
            lastUsedExcessivePermissionServiceInfo,
            policyIds,
            upsertPrincipalManagedPolicyChangeIdToFileTypeToFileMap);
    }
}

export class AwsExcessivePermissionUserRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsExcessivePermissionUserRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsFirehoseDeliveryStreamEncryptionDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsFirehoseDeliveryStreamEncryptionDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsIamGroupInactiveRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamGroupInactiveRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsIamGroupModel extends AwsIamPrincipalModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IGroupModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public access: Optional<GroupModelAccess>,
        analyzingReason: Optional<EntityAnalyzingReason>,
        inactive: boolean,
        permissionActionSeverity: Optional<Severity>,
        public memberPrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamGroupModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId,
            access,
            analyzingReason,
            inactive,
            permissionActionSeverity);
    }
}

export class AwsIamIdentityModel extends AwsIamPrincipalModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IIdentityModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public access: Optional<IdentityModelAccess>,
        analyzingReason: Optional<EntityAnalyzingReason>,
        inactive: boolean,
        permissionActionSeverity: Optional<Severity>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId,
            access,
            analyzingReason,
            inactive,
            permissionActionSeverity);
    }
}

export class AwsIamIdentityProvider extends AwsIamResource implements ITypeNameObject, IAwsResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        path: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            path);
    }
}

export class AwsIamInlinePolicyModel extends AwsIamPrincipalPolicyModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IRelatedTenantsEntityModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        management: AwsIamPrincipalPolicyModelManagement,
        permissionAttachedPrincipalIds: string[],
        permissionBoundaryAttachedIdentityIds: string[],
        permissionUsageTime: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamInlinePolicyModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId,
            management,
            permissionAttachedPrincipalIds,
            permissionBoundaryAttachedIdentityIds,
            permissionUsageTime);
    }
}

export class AwsIamInstanceProfile extends AwsIamResource implements ITypeNameObject, IAwsResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        path: string,
        public roleReference: Optional<AwsResourceReference>,
        public uniqueId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamInstanceProfile",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            path);
    }
}

export class AwsIamManagedPolicyModel extends AwsIamPrincipalPolicyModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IRelatedTenantsEntityModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        management: AwsIamPrincipalPolicyModelManagement,
        permissionAttachedPrincipalIds: string[],
        permissionBoundaryAttachedIdentityIds: string[],
        permissionUsageTime: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamManagedPolicyModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId,
            management,
            permissionAttachedPrincipalIds,
            permissionBoundaryAttachedIdentityIds,
            permissionUsageTime);
    }
}

export class AwsIamOidcIdentityProvider extends AwsIamIdentityProvider implements ITypeNameObject, IAwsResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        path: string,
        public clientIds: string[],
        public url: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamOidcIdentityProvider",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            path);
    }
}

export class AwsIamPasswordLengthPolicyRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        public passwordPolicyMinLength: Optional<number>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamPasswordLengthPolicyRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsIamPasswordReusePolicyRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        public passwordPolicyPreventReusePreviousPasswordCount: Optional<number>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamPasswordReusePolicyRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsIamPolicy extends AwsPermitter implements ITypeNameObject, IAwsResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public document: AwsIamPolicyDocument) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsIamPrincipal extends AwsIamResource implements ITypeNameObject, IAwsPrincipal {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        path: string,
        public inlinePolicyIds: string[],
        public managedPolicyIds: string[],
        public permissionUsageTime: Optional<string>,
        public permissionUsageTimeUpdateTime: string,
        public uniqueId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            path);
    }
}

export class AwsIamPrincipalAttachedPolicyPermissionScopeAnyRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamPrincipalAttachedPolicyPermissionScopeAnyRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsIamPrincipalCreationRisk extends AwsResourceUsageRisk implements ITypeNameObject, IActivityRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        eventNames: string[],
        identityIds: string[],
        resourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamPrincipalCreationRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            eventNames,
            identityIds,
            resourceIds);
    }
}

export class AwsIamPrincipalPolicy extends AwsIamPolicy implements ITypeNameObject, IAwsResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        document: AwsIamPolicyDocument) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            document);
    }
}

export class AwsIamRoleGroupModel extends AwsResourceGroupModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IEntityGroupModel, IPrincipalModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public access: Optional<PrincipalModelAccess>,
        public accountIds: string[],
        public activityTime: Optional<string>,
        public inactive: boolean,
        public oldestRoleCreationTime: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamRoleGroupModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsIamRoleInactiveRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamRoleInactiveRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsIamRoleManagementRisk extends AwsResourceUsageRisk implements ITypeNameObject, IActivityRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        eventNames: string[],
        identityIds: string[],
        resourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamRoleManagementRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            eventNames,
            identityIds,
            resourceIds);
    }
}

export class AwsIamRoleModel extends AwsIamIdentityModel implements ITypeNameObject, IUdmObjectModel, IAwsAccessResourceModel, IAwsPolicyResourceModel, IVendorServiceIdentityModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public access: Optional<ServiceIdentityModelAccess>,
        analyzingReason: Optional<EntityAnalyzingReason>,
        inactive: boolean,
        permissionActionSeverity: Optional<Severity>,
        public accessLevel: Optional<AwsResourceAccessLevel>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamRoleModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId,
            access,
            analyzingReason,
            inactive,
            permissionActionSeverity);
    }
}

export class AwsIamRolePublicAccessExistsRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamRolePublicAccessExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsIamRoleVendorAssumeRolePolicyDocumentExternalIdConditionNotExistRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamRoleVendorAssumeRolePolicyDocumentExternalIdConditionNotExistRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsIamRootUserAccessKeyEnabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamRootUserAccessKeyEnabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsIamRootUserActivityRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamRootUserActivityRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsIamRootUserMfaDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        public includeUnusedRootUser: Optional<boolean>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamRootUserMfaDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsIamSamlIdentityProvider extends AwsIamIdentityProvider implements ITypeNameObject, IAwsResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        path: string,
        public expirationTime: string,
        public metadataDocument: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamSamlIdentityProvider",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            path);
    }
}

export class AwsIamServerCertificateExpiredRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamServerCertificateExpiredRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsIamSupportRoleNotExistRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamSupportRoleNotExistRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsIamUserAttachedPolicyRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamUserAttachedPolicyRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsIamUserInactiveRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamUserInactiveRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsIamUserMfaDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamUserMfaDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsIamUserModel extends AwsIamIdentityModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IUserModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public access: Optional<UserModelAccess>,
        analyzingReason: Optional<EntityAnalyzingReason>,
        inactive: boolean,
        permissionActionSeverity: Optional<Severity>,
        public accessKeyUniqueIdSearchableDatas: StringSearchableData[],
        public groupIds: string[],
        public groupPolicyIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamUserModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId,
            access,
            analyzingReason,
            inactive,
            permissionActionSeverity);
    }
}

export class AwsIamUserMultipleEnabledAccessKeyRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamUserMultipleEnabledAccessKeyRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsIamUserUnrotatedAccessKeyRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        public accessKeyRotationTimeFrame: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamUserUnrotatedAccessKeyRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsIamUserUnusedAccessKeysEnabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        public accessKeyUsageTimeFrame: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamUserUnusedAccessKeysEnabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsIamUserUnusedPasswordEnabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        public passwordUsageTimeFrame: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamUserUnusedPasswordEnabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsInboundExternalEc2InstanceRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsInboundExternalEc2InstanceRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsInboundExternalElbLoadBalancerRisk extends AwsInboundExternalResourceRisk implements ITypeNameObject, IPublicRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        gatewayResourceIds: string[],
        networkScopes: AwsNetworkScope[],
        securityGroupIds: string[],
        public destinationNetworkScopes: DestinationNetworkScope[],
        public targetIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsInboundExternalElbLoadBalancerRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            gatewayResourceIds,
            networkScopes,
            securityGroupIds);
    }
}

export class AwsInboundExternalElbLoadBalancerRiskModel extends AwsInboundExternalResourceRiskModel implements ITypeNameObject, IUdmObjectModel, INetworkInboundExternalResourceRiskModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsInboundExternalElbLoadBalancerRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        destinationNetworkScopes: DestinationNetworkScope[],
        sourceSubnets: string[],
        public managementDestinationNetworkScopes: DestinationNetworkScope[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsInboundExternalElbLoadBalancerRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            destinationNetworkScopes,
            sourceSubnets);
    }
}

export class AwsInboundExternalElbLoadBalancerRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsInboundExternalElbLoadBalancerRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsInboundExternalRdsDatabaseInstanceRisk extends AwsInboundExternalResourceRisk implements ITypeNameObject, IPublicRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        gatewayResourceIds: string[],
        networkScopes: AwsNetworkScope[],
        securityGroupIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsInboundExternalRdsDatabaseInstanceRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            gatewayResourceIds,
            networkScopes,
            securityGroupIds);
    }
}

export class AwsInboundExternalRdsDatabaseInstanceRiskModel extends AwsInboundExternalResourceRiskModel implements ITypeNameObject, IUdmObjectModel, INetworkInboundExternalResourceRiskModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsInboundExternalRdsDatabaseInstanceRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        destinationNetworkScopes: DestinationNetworkScope[],
        sourceSubnets: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsInboundExternalRdsDatabaseInstanceRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            destinationNetworkScopes,
            sourceSubnets);
    }
}

export class AwsInboundExternalRdsDatabaseInstanceRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsInboundExternalRdsDatabaseInstanceRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsInboundExternalRedshiftClusterRisk extends AwsInboundExternalResourceRisk implements ITypeNameObject, IPublicRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        gatewayResourceIds: string[],
        networkScopes: AwsNetworkScope[],
        securityGroupIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsInboundExternalRedshiftClusterRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            gatewayResourceIds,
            networkScopes,
            securityGroupIds);
    }
}

export class AwsInboundExternalRedshiftClusterRiskModel extends AwsInboundExternalResourceRiskModel implements ITypeNameObject, IUdmObjectModel, INetworkInboundExternalResourceRiskModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsInboundExternalRedshiftClusterRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        destinationNetworkScopes: DestinationNetworkScope[],
        sourceSubnets: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsInboundExternalRedshiftClusterRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            destinationNetworkScopes,
            sourceSubnets);
    }
}

export class AwsInboundExternalRedshiftClusterRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsInboundExternalRedshiftClusterRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsInboundExternalSubnetRiskPolicyConfiguration extends CustomRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        description: Optional<string>,
        name: string,
        severity: Severity,
        updateTime: string,
        public allSubnets: boolean,
        public subnetBuiltInAttributeTypeNames: Optional<string[]>,
        public subnetCustomAttributeDefinitionIds: Optional<string[]>,
        public subnetIds: Optional<string[]>,
        public subnetTags: Optional<ResourceTag[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsInboundExternalSubnetRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            description,
            name,
            severity,
            updateTime);
    }
}

export class AwsInboundExternalVpcRiskPolicyConfiguration extends CustomRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        description: Optional<string>,
        name: string,
        severity: Severity,
        updateTime: string,
        public allVpcs: boolean,
        public vpcBuiltInAttributeTypeNames: Optional<string[]>,
        public vpcCustomAttributeDefinitionIds: Optional<string[]>,
        public vpcIds: Optional<string[]>,
        public vpcTags: Optional<ResourceTag[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsInboundExternalVpcRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            description,
            name,
            severity,
            updateTime);
    }
}

export class AwsIotDomainConfiguration extends AwsNotSyncedResource implements ITypeNameObject, IAwsResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIotDomainConfiguration",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsKafkaCluster extends AwsNotSyncedResource implements ITypeNameObject, IAwsResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKafkaCluster",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsKinesisStreamEncryptionDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKinesisStreamEncryptionDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsKmsAlias extends AwsKmsResource implements ITypeNameObject, IAwsResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public awsManaged: boolean,
        public keyArn: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKmsAlias",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsKmsKey extends AwsKmsResource implements ITypeNameObject, IAwsAccessResource, IAwsPolicyResource, IAwsTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public accessLevel: Optional<AwsResourceAccessLevel>,
        public aliasNames: string[],
        public awsManaged: boolean,
        public defaultAliasName: Optional<string>,
        public description: Optional<string>,
        public enabled: boolean,
        public grantListPartIds: string[],
        public nextRotationTime: Optional<string>,
        public originRawType: string,
        public policyDocument: AwsIamPolicyDocument,
        public policyDocumentExists: boolean,
        public publicAccessHighSeveritySensitivePermissionActions: string[],
        public publicAccessPermissionActions: string[],
        public rawId: string,
        public rawType: string,
        public rotationEnabled: Optional<boolean>,
        public rotationTimeFrame: Optional<string>,
        public symmetric: boolean,
        public usageRawType: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKmsKey",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsKmsKeyManagementRisk extends AwsResourceUsageRisk implements ITypeNameObject, IActivityRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        eventNames: string[],
        identityIds: string[],
        resourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKmsKeyManagementRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            eventNames,
            identityIds,
            resourceIds);
    }
}

export class AwsKmsKeyPublicAccessExistsRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKmsKeyPublicAccessExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsKmsKeyRotationDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        public keyRotationTimeFrame: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKmsKeyRotationDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsKubernetesClusterRoleBindingModel extends AwsKubernetesResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesRoleBindingModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public principalIdReferences: string[],
        public roleIdReference: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKubernetesClusterRoleBindingModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsKubernetesClusterRoleModel extends AwsKubernetesResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesRoleModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public principalIdReferences: string[],
        public roleBindingIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKubernetesClusterRoleModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsKubernetesGroupModel extends AwsKubernetesResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesResourceModel, IKubernetesPrincipalModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public roleBindingIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKubernetesGroupModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsKubernetesIngressClassModel extends AwsKubernetesResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKubernetesIngressClassModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsKubernetesNamespaceModel extends AwsKubernetesResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesNamespaceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public resourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKubernetesNamespaceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsKubernetesNamespaceResourceModel extends AwsKubernetesResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesNamespaceResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsKubernetesNamespaceRoleBindingModel extends AwsKubernetesNamespaceResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesNamespaceResourceModel, IKubernetesRoleBindingModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public principalIdReferences: string[],
        public roleIdReference: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKubernetesNamespaceRoleBindingModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsKubernetesNamespaceRoleModel extends AwsKubernetesNamespaceResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesNamespaceResourceModel, IKubernetesRoleModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public principalIdReferences: string[],
        public roleBindingIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKubernetesNamespaceRoleModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsKubernetesNetworkPolicyModel extends AwsKubernetesNamespaceResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesNamespaceResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKubernetesNetworkPolicyModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsKubernetesNodeModel extends AwsKubernetesResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesNodeModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKubernetesNodeModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsKubernetesPersistentVolumeModel extends AwsKubernetesResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKubernetesPersistentVolumeModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsKubernetesPodModel extends AwsKubernetesNamespaceResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesNamespaceResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKubernetesPodModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsKubernetesPodSecurityPolicyModel extends AwsKubernetesResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKubernetesPodSecurityPolicyModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsKubernetesPodTemplateModel extends AwsKubernetesNamespaceResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesNamespaceResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKubernetesPodTemplateModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsKubernetesResource<TKubernetesResourceData extends KubernetesResourceData> extends AwsKubernetesResourceBase implements ITypeNameObject, IAwsResource, IKubernetesResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: TKubernetesResourceData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class AwsKubernetesServiceAccountModel extends AwsKubernetesNamespaceResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesServiceAccountModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public awsRoleIds: string[],
        public originatorWorkloadResourceIds: string[],
        public roleBindingIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKubernetesServiceAccountModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsKubernetesServiceModel extends AwsKubernetesNamespaceResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesServiceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public ingressIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKubernetesServiceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsKubernetesUser extends AwsKubernetesResource<KubernetesUserData> implements ITypeNameObject, IAwsResource, IKubernetesUser {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesUserData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKubernetesUser",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class AwsKubernetesWorkloadResourceModel extends AwsKubernetesNamespaceResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesWorkloadResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public awsRoleIds: string[],
        public containerImageIdReferences: string[],
        public containerImageRawUrlToDataMap: Dictionary<ContainerImageResourceStateContainerImageData>,
        public containerImageScanStatus: Optional<ContainerImageDataScanStatus>,
        public containerImageVulnerabilities: VulnerabilityData[],
        public controllerResourceReference: Optional<EntitySearchableIdReference>,
        public fargateProfileId: Optional<string>,
        public nodeGroupIds: string[],
        public serviceAccountReference: EntitySearchableIdReference) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsLambdaFunctionConfigurationDeprecatedRuntimeRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsLambdaFunctionConfigurationDeprecatedRuntimeRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsLambdaFunctionConfigurationEnvironmentVariableSecretExistsRiskPolicyConfiguration extends SecretExistsRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        exclusionSecretPatterns: Optional<SecretExistsRiskPolicyConfigurationSecretExclusionSecretPattern[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsLambdaFunctionConfigurationEnvironmentVariableSecretExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            exclusionSecretPatterns);
    }
}

export class AwsLambdaFunctionConfigurationMultiAvailabilityZoneNotExistRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsLambdaFunctionConfigurationMultiAvailabilityZoneNotExistRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsLambdaFunctionConfigurationPublicAccessExistsRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        public apiGatewayRouteExclusionRoutePatterns: Optional<AwsLambdaFunctionConfigurationPublicAccessExistsApiGatewayRouteExclusionRoutePattern[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsLambdaFunctionConfigurationPublicAccessExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsLambdaFunctionConfigurationVpcNotExistsRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsLambdaFunctionConfigurationVpcNotExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsNetworkAccessManagementRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsNetworkAccessManagementRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsNetworkedResourceInboundExternalPortRiskPolicyConfiguration extends CustomRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        description: Optional<string>,
        name: string,
        severity: Severity,
        updateTime: string,
        public anyIpV4AddressSubnet: boolean,
        public destinationNetworkScopes: DestinationNetworkScope[],
        public exactSubnets: Optional<string[]>,
        public inboundAccessScope: Optional<NetworkAccessScope>,
        public overlapSubnets: Optional<string[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsNetworkedResourceInboundExternalPortRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            description,
            name,
            severity,
            updateTime);
    }
}

export class AwsOpenShiftBuildConfigModel extends AwsKubernetesNamespaceResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesNamespaceResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsOpenShiftBuildConfigModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsOpenShiftCatalogSourceModel extends AwsKubernetesNamespaceResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesNamespaceResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsOpenShiftCatalogSourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsOpenShiftDeploymentConfigModel extends AwsKubernetesWorkloadResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesWorkloadResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        awsRoleIds: string[],
        containerImageIdReferences: string[],
        containerImageRawUrlToDataMap: Dictionary<ContainerImageResourceStateContainerImageData>,
        containerImageScanStatus: Optional<ContainerImageDataScanStatus>,
        containerImageVulnerabilities: VulnerabilityData[],
        controllerResourceReference: Optional<EntitySearchableIdReference>,
        fargateProfileId: Optional<string>,
        nodeGroupIds: string[],
        serviceAccountReference: EntitySearchableIdReference) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsOpenShiftDeploymentConfigModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId,
            awsRoleIds,
            containerImageIdReferences,
            containerImageRawUrlToDataMap,
            containerImageScanStatus,
            containerImageVulnerabilities,
            controllerResourceReference,
            fargateProfileId,
            nodeGroupIds,
            serviceAccountReference);
    }
}

export class AwsOpenShiftImageStreamModel extends AwsKubernetesNamespaceResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesNamespaceResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsOpenShiftImageStreamModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsOpenShiftProject extends AwsKubernetesResource<OpenShiftProjectData> implements ITypeNameObject, IAwsResource, IOpenShiftProject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: OpenShiftProjectData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsOpenShiftProject",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class AwsOpenShiftRouteModel extends AwsKubernetesNamespaceResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesNamespaceResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsOpenShiftRouteModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsOpenShiftSecurityContextConstraint extends AwsKubernetesResource<OpenShiftSecurityContextConstraintData> implements ITypeNameObject, IAwsResource, IOpenShiftSecurityContextConstraint {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: OpenShiftSecurityContextConstraintData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsOpenShiftSecurityContextConstraint",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class AwsOpenShiftTemplateModel extends AwsKubernetesNamespaceResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesNamespaceResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsOpenShiftTemplateModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsOrganizationsAccount extends AwsOrganizationsScopeResource implements ITypeNameObject, IAwsResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: Optional<string>,
        rawId: string,
        resourceControlPolicyIds: Optional<string[]>,
        serviceControlPolicyIds: Optional<string[]>,
        public organizationId: string,
        public organizationJoinMethod: AwsOrganizationsAccountOrganizationJoinMethod,
        public rootUserMail: string,
        public status: AwsOrganizationsAccountStatus,
        public tenantRawId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsOrganizationsAccount",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            parentEntityId,
            rawId,
            resourceControlPolicyIds,
            serviceControlPolicyIds);
    }
}

export class AwsOrganizationsOrganization extends AwsOrganizationsResource implements ITypeNameObject, IAwsResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public masterAccountArn: string,
        public masterTenantRawId: string,
        public rawId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsOrganizationsOrganization",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsOrganizationsOrganizationalUnit extends AwsOrganizationsScopeResource implements ITypeNameObject, IAwsResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: Optional<string>,
        rawId: string,
        resourceControlPolicyIds: Optional<string[]>,
        serviceControlPolicyIds: Optional<string[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsOrganizationsOrganizationalUnit",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            parentEntityId,
            rawId,
            resourceControlPolicyIds,
            serviceControlPolicyIds);
    }
}

export class AwsOrganizationsPolicy extends AwsOrganizationsResource implements ITypeNameObject, IAwsTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public awsManaged: boolean,
        public description: Optional<string>,
        public policyDocument: AwsIamPolicyDocument,
        public rawId: string,
        public type: AwsOrganizationsPolicyType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsOrganizationsPolicy",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsOrganizationsRoot extends AwsOrganizationsScopeResource implements ITypeNameObject, IAwsResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: Optional<string>,
        rawId: string,
        resourceControlPolicyIds: Optional<string[]>,
        serviceControlPolicyIds: Optional<string[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsOrganizationsRoot",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            parentEntityId,
            rawId,
            resourceControlPolicyIds,
            serviceControlPolicyIds);
    }
}

export class AwsPermissionManagementRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsPermissionManagementRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsPrincipalAllowedPermissionRiskPolicyConfiguration extends CustomRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        description: Optional<string>,
        name: string,
        severity: Severity,
        updateTime: string,
        public actionNamePatterns: Optional<string[]>,
        public actionRiskCategories: Optional<ActionRiskCategory[]>,
        public actions: Optional<string[]>,
        public principalBuiltInAttributeTypeNames: Optional<string[]>,
        public principalCustomAttributeDefinitionIds: Optional<string[]>,
        public principalIds: Optional<string[]>,
        public principalTags: Optional<ResourceTag[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsPrincipalAllowedPermissionRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            description,
            name,
            severity,
            updateTime);
    }
}

export class AwsPrincipalAllowedResourcePermissionRisk extends AwsPrincipalResourcePermissionRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        principalResourcePermissionItems: AwsPrincipalResourcePermissionRiskItem[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsPrincipalAllowedResourcePermissionRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            principalResourcePermissionItems);
    }
}

export class AwsPrincipalAllowedResourcePermissionRiskModel extends AwsPrincipalResourcePermissionRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsPrincipalResourcePermissionRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        permissionActions: string[],
        principalIds: string[],
        resourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsPrincipalAllowedResourcePermissionRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            permissionActions,
            principalIds,
            resourceIds);
    }
}

export class AwsPrincipalNotAllowedResourcePermissionRisk extends AwsPrincipalResourcePermissionRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        principalResourcePermissionItems: AwsPrincipalResourcePermissionRiskItem[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsPrincipalNotAllowedResourcePermissionRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            principalResourcePermissionItems);
    }
}

export class AwsPrincipalNotAllowedResourcePermissionRiskModel extends AwsPrincipalResourcePermissionRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsPrincipalResourcePermissionRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        permissionActions: string[],
        principalIds: string[],
        resourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsPrincipalNotAllowedResourcePermissionRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            permissionActions,
            principalIds,
            resourceIds);
    }
}

export class AwsPrincipalPolicyChange extends AwsResourceChange implements ITypeNameObject, ICloudProviderResourceChange {
    constructor(
        typeName: string,
        entityIds: string[],
        id: string,
        resolved: boolean,
        resourceId: string,
        public principalId: string) {
        super(
            typeName,
            entityIds,
            id,
            resolved,
            resourceId);
    }
}

export class AwsPrincipalResourcePermissionRiskPolicyConfiguration extends CustomRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        description: Optional<string>,
        name: string,
        severity: Severity,
        updateTime: string,
        public actionNamePatterns: Optional<string[]>,
        public actionRiskCategories: Optional<ActionRiskCategory[]>,
        public actions: Optional<string[]>,
        public excludePrincipalMatchConditions: EntityMatchCondition[],
        public excludeResourceMatchConditions: EntityMatchCondition[],
        public principalMatchConditions: EntityMatchCondition[],
        public resourceMatchConditions: EntityMatchCondition[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            description,
            name,
            severity,
            updateTime);
    }
}

export class AwsPrivilegeEscalationRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsPrivilegeEscalationRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsProvisionPermissionSetChange extends AwsResourceChange implements ITypeNameObject, ICloudProviderResourceChange, ICodeInfrastructureChange {
    constructor(
        entityIds: string[],
        id: string,
        resolved: boolean,
        resourceId: string) {
        super(
            "AwsProvisionPermissionSetChange",
            entityIds,
            id,
            resolved,
            resourceId);
    }
}

export class AwsRdsAuroraGlobalClusterModel extends AwsRdsGlobalClusterResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAwsEncryptedResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        clusterIdReferences: string[],
        kmsEncryptionKeyIdReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsAuroraGlobalClusterModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId,
            clusterIdReferences,
            kmsEncryptionKeyIdReferences);
    }
}

export class AwsRdsClusterBackupRetentionTimeFrameRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        public backupRetentionTimeFrame: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsClusterBackupRetentionTimeFrameRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsRdsClusterCloudWatchLoggingDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsClusterCloudWatchLoggingDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsRdsClusterDeleteProtectionDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsClusterDeleteProtectionDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsRdsClusterMinorVersionUpgradeDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsClusterMinorVersionUpgradeDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsRdsClusterModel extends AwsRdsClusterResourceModel implements ITypeNameObject, IUdmObjectModel, IAwsCloudWatchLoggingResourceModel, IAwsEncryptedResourceModel, IAwsNetworkedResourceModel, IDataAnalysisResourceModel, INetworkAccessResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        public entityNetwork: Optional<NetworkAccessResourceStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        instanceIds: string[],
        kmsEncryptionKeyIdReferences: string[],
        snapshotIds: string[],
        public dataAnalysisError: Optional<DataAnalysisResourceModelDataAnalysisError>,
        public dataAnalysisPriorityScanRequested: boolean,
        public dataAnalysisResourceSensitivity: Optional<DataAnalysisSensitivity>,
        public dataAnalysisStatus: DataAnalysisResourceModelDataAnalysisStatus,
        public dataAnalysisStatusSortValue: number,
        public dataCategoryToDataSegmentCountMap: Optional<Dictionary<number>>,
        public dataCategoryToSensitivityMap: Optional<Dictionary<DataSensitivity>>,
        public dataClassifierIdToDataSegmentCountMap: Optional<Dictionary<number>>,
        public dataLastModified: Optional<string>,
        public dataSensitivities: Optional<DataSensitivity[]>,
        public dataSensitivityToDataSegmentCountMap: Optional<Dictionary<number>>,
        public highestDataSensitivity: Optional<DataSensitivity>,
        public scannedDataSegmentCount: Optional<number>,
        public scanTime: Optional<string>,
        public sensitiveDataSegmentCount: Optional<number>,
        public storageSize: Optional<number>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsClusterModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId,
            instanceIds,
            kmsEncryptionKeyIdReferences,
            snapshotIds);
    }
}

export class AwsRdsClusterResource extends AwsRdsResource implements ITypeNameObject, IAwsCloudWatchLoggingResource, IAwsEncryptedResource, IAwsTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        rawId: string,
        resourceId: string,
        public availabilityZones: AwsAvailabilityZone[],
        public backupRetentionTimeFrame: string,
        public cloudWatchLoggingEnabled: boolean,
        public cloudWatchLogTypes: string[],
        public deleteProtection: boolean,
        public encryptionExists: boolean,
        public engineVersion: string,
        public globalClusterId: Optional<string>,
        public kmsEncryptionKeyOrAliasArns: string[],
        public securityGroupIds: string[],
        public status: string,
        public storageKmsEncryptionKeyOrAliasArn: Optional<string>,
        public storageSize: number,
        public subnetIds: string[],
        public vpcIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            rawId,
            resourceId);
    }
}

export class AwsRdsClusterStorageEncryptionDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsClusterStorageEncryptionDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsRdsDatabaseInstanceBackupRetentionTimeFrameRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        public backupRetentionTimeFrame: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsDatabaseInstanceBackupRetentionTimeFrameRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsRdsDatabaseInstanceCloudWatchLoggingDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsDatabaseInstanceCloudWatchLoggingDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsRdsDatabaseInstanceDeleteProtectionDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsDatabaseInstanceDeleteProtectionDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsRdsDatabaseInstanceMinorVersionUpgradeDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsDatabaseInstanceMinorVersionUpgradeDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsRdsDatabaseInstanceModel extends AwsRdsDatabaseInstanceResourceModel implements ITypeNameObject, IUdmObjectModel, IAwsCloudWatchLoggingResourceModel, IAwsEncryptedResourceModel, IAwsNetworkedResourceModel, INetworkAccessResourceModel, IDataAnalysisResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        public entityNetwork: Optional<NetworkAccessResourceStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        dnsZoneIds: string[],
        kmsEncryptionKeyIdReferences: string[],
        public dataAnalysisError: Optional<DataAnalysisResourceModelDataAnalysisError>,
        public dataAnalysisPriorityScanRequested: boolean,
        public dataAnalysisResourceSensitivity: Optional<DataAnalysisSensitivity>,
        public dataAnalysisStatus: DataAnalysisResourceModelDataAnalysisStatus,
        public dataAnalysisStatusSortValue: number,
        public dataCategoryToDataSegmentCountMap: Optional<Dictionary<number>>,
        public dataCategoryToSensitivityMap: Optional<Dictionary<DataSensitivity>>,
        public dataClassifierIdToDataSegmentCountMap: Optional<Dictionary<number>>,
        public dataLastModified: Optional<string>,
        public dataSensitivities: Optional<DataSensitivity[]>,
        public dataSensitivityToDataSegmentCountMap: Optional<Dictionary<number>>,
        public highestDataSensitivity: Optional<DataSensitivity>,
        public scannedDataSegmentCount: Optional<number>,
        public scanTime: Optional<string>,
        public sensitiveDataSegmentCount: Optional<number>,
        public snapshotIds: string[],
        public storageSize: Optional<number>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsDatabaseInstanceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId,
            dnsZoneIds,
            kmsEncryptionKeyIdReferences);
    }
}

export class AwsRdsDatabaseInstanceResource extends AwsRdsResource implements ITypeNameObject, IAwsCloudWatchLoggingResource, IAwsDnsRecordResource, IAwsEncryptedResource, IAwsNetworkEvaluationNetworkedResource, IAwsTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        rawId: string,
        resourceId: string,
        public availabilityZone: AwsAvailabilityZone,
        public backupRetentionTimeFrame: string,
        public classicDatabaseSecurityGroupIds: string[],
        public cloudWatchLoggingEnabled: boolean,
        public cloudWatchLogTypes: string[],
        public clusterId: Optional<string>,
        public deleteProtection: boolean,
        public dnsNames: string[],
        public encryptionExists: boolean,
        public endpoint: string,
        public engineVersion: string,
        public enhancedMonitoringResourceArn: Optional<string>,
        public instanceRawType: string,
        public kmsEncryptionKeyOrAliasArns: string[],
        public minorVersionUpgradeEnabled: boolean,
        public monitoringRoleReference: Optional<AwsResourceReference>,
        public networkInterfaceIds: string[],
        public publicIpAddressEnabled: boolean,
        public publicIpAddresses: string[],
        public securityGroupIds: string[],
        public status: string,
        public storageKmsEncryptionKeyOrAliasArn: Optional<string>,
        public storageSize: number,
        public subnetIds: string[],
        public vpcIds: string[],
        public vpcRawId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            rawId,
            resourceId);
    }
}

export class AwsRdsDatabaseInstanceStorageEncryptionDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsDatabaseInstanceStorageEncryptionDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsRdsDatabaseSecurityGroup extends AwsRdsResource implements ITypeNameObject, IAwsClassicSecurityGroup {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        rawId: string,
        resourceId: string,
        public sourceEc2SecurityGroupIds: string[],
        public sourceSubnets: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsDatabaseSecurityGroup",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            rawId,
            resourceId);
    }
}

export class AwsRdsDocDbCluster extends AwsRdsClusterResource implements ITypeNameObject, IAwsTagResource, IAwsDocDbCluster, IAwsRdsDocDbResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        rawId: string,
        resourceId: string,
        availabilityZones: AwsAvailabilityZone[],
        backupRetentionTimeFrame: string,
        cloudWatchLoggingEnabled: boolean,
        cloudWatchLogTypes: string[],
        deleteProtection: boolean,
        encryptionExists: boolean,
        engineVersion: string,
        globalClusterId: Optional<string>,
        kmsEncryptionKeyOrAliasArns: string[],
        securityGroupIds: string[],
        status: string,
        storageKmsEncryptionKeyOrAliasArn: Optional<string>,
        storageSize: number,
        subnetIds: string[],
        vpcIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsDocDbCluster",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            rawId,
            resourceId,
            availabilityZones,
            backupRetentionTimeFrame,
            cloudWatchLoggingEnabled,
            cloudWatchLogTypes,
            deleteProtection,
            encryptionExists,
            engineVersion,
            globalClusterId,
            kmsEncryptionKeyOrAliasArns,
            securityGroupIds,
            status,
            storageKmsEncryptionKeyOrAliasArn,
            storageSize,
            subnetIds,
            vpcIds);
    }
}

export class AwsRdsDocDbClusterCloudWatchLoggingDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsDocDbClusterCloudWatchLoggingDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsRdsDocDbClusterDeleteProtectionDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsDocDbClusterDeleteProtectionDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsRdsDocDbClusterSnapshot extends AwsRdsSnapshotResource implements ITypeNameObject, IAwsAccessResource, IAwsTagResource, IAwsDocDbClusterSnapshot, IAwsRdsDocDbResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        rawId: string,
        resourceId: string,
        accessLevel: Optional<AwsResourceAccessLevel>,
        encryptionExists: boolean,
        kmsEncryptionKeyOrAliasArns: string[],
        rawStatus: string,
        sharedTenantRawIds: string[],
        storageKmsEncryptionKeyOrAliasArn: Optional<string>,
        storageSize: number,
        type: AwsResourceSnapshotType,
        vpcId: Optional<string>,
        public clusterId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsDocDbClusterSnapshot",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            rawId,
            resourceId,
            accessLevel,
            encryptionExists,
            kmsEncryptionKeyOrAliasArns,
            rawStatus,
            sharedTenantRawIds,
            storageKmsEncryptionKeyOrAliasArn,
            storageSize,
            type,
            vpcId);
    }
}

export class AwsRdsDocDbClusterSnapshotEncryptionDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsDocDbClusterSnapshotEncryptionDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsRdsDocDbClusterSnapshotModel extends AwsRdsSnapshotResourceModel implements ITypeNameObject, IUdmObjectModel, IAwsAccessResourceModel, IAwsDocDbClusterSnapshotModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        accessLevel: Optional<AwsResourceAccessLevel>,
        kmsEncryptionKeyIdReferences: string[],
        public clusterType: AwsDocDbClusterModelType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsDocDbClusterSnapshotModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId,
            accessLevel,
            kmsEncryptionKeyIdReferences);
    }
}

export class AwsRdsDocDbClusterSnapshotPublicAccessExistsRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsDocDbClusterSnapshotPublicAccessExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsRdsDocDbDatabaseInstance extends AwsRdsDatabaseInstanceResource implements ITypeNameObject, IAwsCloudWatchLoggingResource, IAwsDnsRecordResource, IAwsEncryptedResource, IAwsNetworkEvaluationNetworkedResource, IAwsTagResource, IAwsRdsDocDbResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        rawId: string,
        resourceId: string,
        availabilityZone: AwsAvailabilityZone,
        backupRetentionTimeFrame: string,
        classicDatabaseSecurityGroupIds: string[],
        cloudWatchLoggingEnabled: boolean,
        cloudWatchLogTypes: string[],
        clusterId: Optional<string>,
        deleteProtection: boolean,
        dnsNames: string[],
        encryptionExists: boolean,
        endpoint: string,
        engineVersion: string,
        enhancedMonitoringResourceArn: Optional<string>,
        instanceRawType: string,
        kmsEncryptionKeyOrAliasArns: string[],
        minorVersionUpgradeEnabled: boolean,
        monitoringRoleReference: Optional<AwsResourceReference>,
        networkInterfaceIds: string[],
        publicIpAddressEnabled: boolean,
        publicIpAddresses: string[],
        securityGroupIds: string[],
        status: string,
        storageKmsEncryptionKeyOrAliasArn: Optional<string>,
        storageSize: number,
        subnetIds: string[],
        vpcIds: string[],
        vpcRawId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsDocDbDatabaseInstance",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            rawId,
            resourceId,
            availabilityZone,
            backupRetentionTimeFrame,
            classicDatabaseSecurityGroupIds,
            cloudWatchLoggingEnabled,
            cloudWatchLogTypes,
            clusterId,
            deleteProtection,
            dnsNames,
            encryptionExists,
            endpoint,
            engineVersion,
            enhancedMonitoringResourceArn,
            instanceRawType,
            kmsEncryptionKeyOrAliasArns,
            minorVersionUpgradeEnabled,
            monitoringRoleReference,
            networkInterfaceIds,
            publicIpAddressEnabled,
            publicIpAddresses,
            securityGroupIds,
            status,
            storageKmsEncryptionKeyOrAliasArn,
            storageSize,
            subnetIds,
            vpcIds,
            vpcRawId);
    }
}

export class AwsRdsDocDbGlobalClusterModel extends AwsRdsGlobalClusterResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAwsEncryptedResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        clusterIdReferences: string[],
        kmsEncryptionKeyIdReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsDocDbGlobalClusterModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId,
            clusterIdReferences,
            kmsEncryptionKeyIdReferences);
    }
}

export class AwsRdsGlobalClusterResource extends AwsRdsResource implements ITypeNameObject, IAwsEncryptedResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        rawId: string,
        resourceId: string,
        public clusterArns: string[],
        public deleteProtection: boolean,
        public encryptionExists: boolean,
        public engineVersion: string,
        public kmsEncryptionKeyOrAliasArns: string[],
        public status: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            rawId,
            resourceId);
    }
}

export class AwsRdsNeptuneCluster extends AwsRdsClusterResource implements ITypeNameObject, IAwsCloudWatchLoggingResource, IAwsEncryptedResource, IAwsTagResource, IAwsNetworkEvaluationNetworkedResource, IAwsRdsNeptuneResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        rawId: string,
        resourceId: string,
        availabilityZones: AwsAvailabilityZone[],
        backupRetentionTimeFrame: string,
        cloudWatchLoggingEnabled: boolean,
        cloudWatchLogTypes: string[],
        deleteProtection: boolean,
        encryptionExists: boolean,
        engineVersion: string,
        globalClusterId: Optional<string>,
        kmsEncryptionKeyOrAliasArns: string[],
        securityGroupIds: string[],
        status: string,
        storageKmsEncryptionKeyOrAliasArn: Optional<string>,
        storageSize: number,
        subnetIds: string[],
        vpcIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsNeptuneCluster",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            rawId,
            resourceId,
            availabilityZones,
            backupRetentionTimeFrame,
            cloudWatchLoggingEnabled,
            cloudWatchLogTypes,
            deleteProtection,
            encryptionExists,
            engineVersion,
            globalClusterId,
            kmsEncryptionKeyOrAliasArns,
            securityGroupIds,
            status,
            storageKmsEncryptionKeyOrAliasArn,
            storageSize,
            subnetIds,
            vpcIds);
    }
}

export class AwsRdsNeptuneClusterBackupRetentionTimeFrameRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        public backupRetentionTimeFrame: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsNeptuneClusterBackupRetentionTimeFrameRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsRdsNeptuneClusterCloudWatchLoggingDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsNeptuneClusterCloudWatchLoggingDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsRdsNeptuneClusterDeleteProtectionDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsNeptuneClusterDeleteProtectionDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsRdsNeptuneClusterSnapshot extends AwsRdsSnapshotResource implements ITypeNameObject, IAwsAccessResource, IAwsEncryptedResource, IAwsTagResource, IAwsRdsNeptuneResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        rawId: string,
        resourceId: string,
        accessLevel: Optional<AwsResourceAccessLevel>,
        encryptionExists: boolean,
        kmsEncryptionKeyOrAliasArns: string[],
        rawStatus: string,
        sharedTenantRawIds: string[],
        storageKmsEncryptionKeyOrAliasArn: Optional<string>,
        storageSize: number,
        type: AwsResourceSnapshotType,
        vpcId: Optional<string>,
        public clusterId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsNeptuneClusterSnapshot",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            rawId,
            resourceId,
            accessLevel,
            encryptionExists,
            kmsEncryptionKeyOrAliasArns,
            rawStatus,
            sharedTenantRawIds,
            storageKmsEncryptionKeyOrAliasArn,
            storageSize,
            type,
            vpcId);
    }
}

export class AwsRdsNeptuneClusterSnapshotEncryptionDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsNeptuneClusterSnapshotEncryptionDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsRdsNeptuneClusterSnapshotModel extends AwsRdsSnapshotResourceModel implements ITypeNameObject, IUdmObjectModel, IAwsAccessResourceModel, IAwsEncryptedResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        accessLevel: Optional<AwsResourceAccessLevel>,
        kmsEncryptionKeyIdReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsNeptuneClusterSnapshotModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId,
            accessLevel,
            kmsEncryptionKeyIdReferences);
    }
}

export class AwsRdsNeptuneClusterSnapshotPublicAccessExistsRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsNeptuneClusterSnapshotPublicAccessExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsRdsNeptuneDatabaseInstance extends AwsRdsDatabaseInstanceResource implements ITypeNameObject, IAwsCloudWatchLoggingResource, IAwsDnsRecordResource, IAwsEncryptedResource, IAwsNetworkEvaluationNetworkedResource, IAwsTagResource, IAwsRdsNeptuneResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        rawId: string,
        resourceId: string,
        availabilityZone: AwsAvailabilityZone,
        backupRetentionTimeFrame: string,
        classicDatabaseSecurityGroupIds: string[],
        cloudWatchLoggingEnabled: boolean,
        cloudWatchLogTypes: string[],
        clusterId: Optional<string>,
        deleteProtection: boolean,
        dnsNames: string[],
        encryptionExists: boolean,
        endpoint: string,
        engineVersion: string,
        enhancedMonitoringResourceArn: Optional<string>,
        instanceRawType: string,
        kmsEncryptionKeyOrAliasArns: string[],
        minorVersionUpgradeEnabled: boolean,
        monitoringRoleReference: Optional<AwsResourceReference>,
        networkInterfaceIds: string[],
        publicIpAddressEnabled: boolean,
        publicIpAddresses: string[],
        securityGroupIds: string[],
        status: string,
        storageKmsEncryptionKeyOrAliasArn: Optional<string>,
        storageSize: number,
        subnetIds: string[],
        vpcIds: string[],
        vpcRawId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsNeptuneDatabaseInstance",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            rawId,
            resourceId,
            availabilityZone,
            backupRetentionTimeFrame,
            classicDatabaseSecurityGroupIds,
            cloudWatchLoggingEnabled,
            cloudWatchLogTypes,
            clusterId,
            deleteProtection,
            dnsNames,
            encryptionExists,
            endpoint,
            engineVersion,
            enhancedMonitoringResourceArn,
            instanceRawType,
            kmsEncryptionKeyOrAliasArns,
            minorVersionUpgradeEnabled,
            monitoringRoleReference,
            networkInterfaceIds,
            publicIpAddressEnabled,
            publicIpAddresses,
            securityGroupIds,
            status,
            storageKmsEncryptionKeyOrAliasArn,
            storageSize,
            subnetIds,
            vpcIds,
            vpcRawId);
    }
}

export class AwsRdsNeptuneDatabaseInstanceMinorVersionUpgradeDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsNeptuneDatabaseInstanceMinorVersionUpgradeDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsRdsNeptuneGlobalCluster extends AwsRdsGlobalClusterResource implements ITypeNameObject, IAwsEncryptedResource, IAwsRdsNeptuneResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        rawId: string,
        resourceId: string,
        clusterArns: string[],
        deleteProtection: boolean,
        encryptionExists: boolean,
        engineVersion: string,
        kmsEncryptionKeyOrAliasArns: string[],
        status: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsNeptuneGlobalCluster",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            rawId,
            resourceId,
            clusterArns,
            deleteProtection,
            encryptionExists,
            engineVersion,
            kmsEncryptionKeyOrAliasArns,
            status);
    }
}

export class AwsRdsSnapshot extends AwsRdsSnapshotResource implements ITypeNameObject, IAwsAccessResource, IAwsEncryptedResource, IAwsTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        rawId: string,
        resourceId: string,
        accessLevel: Optional<AwsResourceAccessLevel>,
        encryptionExists: boolean,
        kmsEncryptionKeyOrAliasArns: string[],
        rawStatus: string,
        sharedTenantRawIds: string[],
        storageKmsEncryptionKeyOrAliasArn: Optional<string>,
        storageSize: number,
        type: AwsResourceSnapshotType,
        vpcId: Optional<string>,
        public sourceResourceId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            rawId,
            resourceId,
            accessLevel,
            encryptionExists,
            kmsEncryptionKeyOrAliasArns,
            rawStatus,
            sharedTenantRawIds,
            storageKmsEncryptionKeyOrAliasArn,
            storageSize,
            type,
            vpcId);
    }
}

export class AwsRdsSnapshotEncryptionDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsSnapshotEncryptionDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsRdsSnapshotModel extends AwsRdsSnapshotResourceModel implements ITypeNameObject, IUdmObjectModel, IAwsAccessResourceModel, IAwsEncryptedResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        accessLevel: Optional<AwsResourceAccessLevel>,
        kmsEncryptionKeyIdReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId,
            accessLevel,
            kmsEncryptionKeyIdReferences);
    }
}

export class AwsRdsSnapshotPublicAccessExistsRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsSnapshotPublicAccessExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsReconnaissanceRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsReconnaissanceRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsRedshiftCluster extends AwsRedshiftResource implements ITypeNameObject, IAwsDnsRecordResource, IAwsEncryptedResource, IAwsNetworkEvaluationNetworkedResource, IAwsTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public availabilityZone: AwsAvailabilityZone,
        public backupRetentionTimeFrame: string,
        public classicClusterSecurityGroupIds: string[],
        public dnsNames: string[],
        public encryptionExists: boolean,
        public endpoint: string,
        public enhancedVpcRouting: boolean,
        public kmsEncryptionKeyOrAliasArns: string[],
        public networkInterfaceIds: string[],
        public publicIpAddressEnabled: boolean,
        public publicIpAddresses: string[],
        public roleReferences: AwsResourceReference[],
        public securityGroupIds: string[],
        public storageEncryptionType: Optional<AwsRedshiftClusterStorageEncryptionType>,
        public storageKmsEncryptionKeyOrAliasArn: Optional<string>,
        public storageSize: number,
        public subnetIds: string[],
        public vpcIds: string[],
        public vpcRawId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRedshiftCluster",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsRedshiftClusterBackupRetentionTimeFrameRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        public backupRetentionTimeFrame: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRedshiftClusterBackupRetentionTimeFrameRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsRedshiftClusterEncryptionDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRedshiftClusterEncryptionDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsRedshiftClusterSecurityGroup extends AwsRedshiftResource implements ITypeNameObject, IAwsClassicSecurityGroup {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public sourceEc2SecurityGroupIds: string[],
        public sourceSubnets: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRedshiftClusterSecurityGroup",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsRedshiftNamespace extends AwsRedshiftResource implements ITypeNameObject, IAwsEncryptedResource, IAwsTagResource, IAwsNetworkedResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        public encryptionExists: boolean,
        public kmsEncryptionKeyOrAliasArns: string[],
        public securityGroupIds: string[],
        public storageKmsEncryptionKeyOrAliasArn: Optional<string>,
        public subnetIds: string[],
        public vpcIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRedshiftNamespace",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags);
    }
}

export class AwsResourceCodeResourceNotExistsRiskPolicyConfiguration extends ResourceCodeResourceNotExistsRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        description: Optional<string>,
        name: string,
        severity: Severity,
        updateTime: string,
        allResources: boolean,
        resourceBuiltInAttributeTypeNames: Optional<string[]>,
        resourceCustomAttributeDefinitionIds: Optional<string[]>,
        resourceIds: Optional<string[]>,
        resourceTags: Optional<ResourceTag[]>,
        resourceTypeNames: Optional<string[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsResourceCodeResourceNotExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            description,
            name,
            severity,
            updateTime,
            allResources,
            resourceBuiltInAttributeTypeNames,
            resourceCustomAttributeDefinitionIds,
            resourceIds,
            resourceTags,
            resourceTypeNames);
    }
}

export class AwsResourceTagNotExistsRiskPolicyConfiguration extends ResourceTagNotExistsRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        description: Optional<string>,
        name: string,
        severity: Severity,
        updateTime: string,
        allResources: boolean,
        existsResourceTagKeys: Optional<string[]>,
        existsResourceTagPattern: Optional<ResourceTagPattern>,
        existsResourceTags: Optional<ResourceTag[]>,
        resourceBuiltInAttributeTypeNames: Optional<string[]>,
        resourceCustomAttributeDefinitionIds: Optional<string[]>,
        resourceIds: Optional<string[]>,
        resourceTags: Optional<ResourceTag[]>,
        resourceTypeNames: Optional<string[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsResourceTagNotExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            description,
            name,
            severity,
            updateTime,
            allResources,
            existsResourceTagKeys,
            existsResourceTagPattern,
            existsResourceTags,
            resourceBuiltInAttributeTypeNames,
            resourceCustomAttributeDefinitionIds,
            resourceIds,
            resourceTags,
            resourceTypeNames);
    }
}

export class AwsResourceTagSecretExistsRiskPolicyConfiguration extends SecretExistsRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        exclusionSecretPatterns: Optional<SecretExistsRiskPolicyConfigurationSecretExclusionSecretPattern[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsResourceTagSecretExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            exclusionSecretPatterns);
    }
}

export class AwsRoleTemplateMismatchRiskPolicyConfiguration extends CustomRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        description: Optional<string>,
        name: string,
        severity: Severity,
        updateTime: string,
        public roleBuiltInAttributeTypeNames: Optional<string[]>,
        public roleCustomAttributeDefinitionIds: Optional<string[]>,
        public roleIds: Optional<string[]>,
        public roleNamePattern: Optional<string>,
        public roleTags: Optional<ResourceTag[]>,
        public templateRoleId: string,
        public validateAssumeRolePolicy: boolean,
        public validatePermissionBoundaryPolicy: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRoleTemplateMismatchRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            description,
            name,
            severity,
            updateTime);
    }
}

export class AwsRoute53HostedZoneSigningDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRoute53HostedZoneSigningDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsS3BucketBlockPublicAccessDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsS3BucketBlockPublicAccessDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsS3BucketEncryptionDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsS3BucketEncryptionDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsS3BucketHttpsOnlyDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsS3BucketHttpsOnlyDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsS3BucketObjectMfaDeleteDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsS3BucketObjectMfaDeleteDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsS3BucketPublicAccessExistsRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsS3BucketPublicAccessExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsSageMakerNotebookInstanceDefaultEncryptionRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSageMakerNotebookInstanceDefaultEncryptionRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsSageMakerNotebookInstanceRootAccessEnabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSageMakerNotebookInstanceRootAccessEnabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsSageMakerNotebookInstanceUnrestrictedOutboundAccessRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSageMakerNotebookInstanceUnrestrictedOutboundAccessRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsSecretsManagerSecretPublicAccessExistsRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSecretsManagerSecretPublicAccessExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsSecretsManagerSecretRotationDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSecretsManagerSecretRotationDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsSecurityHubRegionHubDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSecurityHubRegionHubDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsSeverePermissionPrincipalRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSeverePermissionPrincipalRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsSnsTopicEncryptionDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSnsTopicEncryptionDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsSnsTopicHttpsOnlyDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSnsTopicHttpsOnlyDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsSnsTopicPublicAccessExistsRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSnsTopicPublicAccessExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsSqsQueueEncryptionDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSqsQueueEncryptionDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsSqsQueuePublicAccessExistsRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSqsQueuePublicAccessExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsSsmParameterSecretExistsRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSsmParameterSecretExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsSsoGroup extends AwsSsoPrincipal implements ITypeNameObject, IAwsResource, IDirectoryGroup, IPermissionManagementGroup {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        externalId: Optional<string>,
        permissionSetIdToTenantRawIdsMap: Dictionary<string[]>,
        rawId: string,
        public mail: Optional<string>,
        public userIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSsoGroup",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            externalId,
            permissionSetIdToTenantRawIdsMap,
            rawId);
    }
}

export class AwsSsoPermissionSetAssignmentEligibilityConfiguration extends PermissionAssignmentEligibilityConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        approval: Optional<PermissionEligibilityConfigurationApproval>,
        description: Optional<string>,
        expirationTimeFrame: string,
        name: string,
        principalTenantId: string,
        reasonRequired: boolean,
        public permissions: AwsSsoPermissionSetAssignmentEligibilityConfigurationPermissions,
        public permissionSetNamePrefix: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSsoPermissionSetAssignmentEligibilityConfiguration",
            scopeId,
            approval,
            description,
            expirationTimeFrame,
            name,
            principalTenantId,
            reasonRequired);
    }
}

export class AwsSsoPermissionSetAssignmentEligibilityModel extends PermissionAssignmentEligibilityModel implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        public configuration: AwsSsoPermissionSetAssignmentEligibilityConfiguration,
        scopeId: string,
        state: Optional<SystemEntityState>,
        approverPrincipalIdReferences: string[],
        existingGranteePrincipalIds: string[],
        granteePrincipalIdReferences: string[],
        levelToApproverPrincipalIdReferencesMap: Dictionary<string[]>,
        levelToExistingApproverPrincipalIdsMap: Dictionary<string[]>,
        permissionRequestScopeIds: string[],
        public permissions: AwsSsoPermissionSetAssignmentEligibilityModelPermissions) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSsoPermissionSetAssignmentEligibilityModel",
            configuration,
            scopeId,
            state,
            approverPrincipalIdReferences,
            existingGranteePrincipalIds,
            granteePrincipalIdReferences,
            levelToApproverPrincipalIdReferencesMap,
            levelToExistingApproverPrincipalIdsMap,
            permissionRequestScopeIds);
    }
}

export class AwsSsoPermissionSetUnusedRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSsoPermissionSetUnusedRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsTenantEntityCreationRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsTenantEntityCreationRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AwsUpsertBucketPolicyChange extends AwsUpsertResourcePolicyChange implements ITypeNameObject, ICloudProviderResourceChange {
    constructor(
        entityIds: string[],
        id: string,
        resolved: boolean,
        resourceId: string,
        rawDocument: string) {
        super(
            "AwsUpsertBucketPolicyChange",
            entityIds,
            id,
            resolved,
            resourceId,
            rawDocument);
    }
}

export class AwsUpsertPermissionSetInlinePolicyChange extends AwsUpsertPolicyChange implements ITypeNameObject, ICloudProviderResourceChange, ICodeInfrastructureChange {
    constructor(
        entityIds: string[],
        id: string,
        resolved: boolean,
        resourceId: string,
        rawDocument: string,
        public create: boolean) {
        super(
            "AwsUpsertPermissionSetInlinePolicyChange",
            entityIds,
            id,
            resolved,
            resourceId,
            rawDocument);
    }
}

export class AwsUpsertRepositoryPolicyChange extends AwsUpsertResourcePolicyChange implements ITypeNameObject, ICloudProviderResourceChange {
    constructor(
        entityIds: string[],
        id: string,
        resolved: boolean,
        resourceId: string,
        rawDocument: string) {
        super(
            "AwsUpsertRepositoryPolicyChange",
            entityIds,
            id,
            resolved,
            resourceId,
            rawDocument);
    }
}

export class AzureAadDomainServiceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAadDomainServiceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAadiamAzureAdMetricGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAadiamAzureAdMetricGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAadiamPrivateLinkForAzureAdGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAadiamPrivateLinkForAzureAdGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAadiamTenantGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAadiamTenantGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureActiveDirectoryB2cDirectoryGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureActiveDirectoryB2cDirectoryGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureActiveDirectoryCiamDirectoryGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureActiveDirectoryCiamDirectoryGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureActiveDirectoryGuestUsageGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureActiveDirectoryGuestUsageGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAgFoodPlatformFarmBeatGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAgFoodPlatformFarmBeatGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAgriculturePlatformAgriServiceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAgriculturePlatformAgriServiceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAlertsManagementActionRuleGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAlertsManagementActionRuleGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAlertsManagementPrometheusRuleGroupGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAlertsManagementPrometheusRuleGroupGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAlertsManagementSmartDetectorAlertRuleGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAlertsManagementSmartDetectorAlertRuleGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAnalysisServicesServerGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAnalysisServicesServerGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAnyBuildClusterGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAnyBuildClusterGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureApiCenterServiceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureApiCenterServiceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureApiManagementGatewayGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureApiManagementGatewayGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureApiManagementServiceResourceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureApiManagementServiceResourceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureApiManagementServiceResourceGenericResourceModel extends AzureResourcesGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureApiManagementServiceResourceGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            userManagedIdentityServicePrincipalIds);
    }
}

export class AzureAppAssessmentMigrateProjectGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAppAssessmentMigrateProjectGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAppBuilderGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAppBuilderGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAppConfigurationConfigurationStoreGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAppConfigurationConfigurationStoreGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAppConfigurationConfigurationStoreGenericResourceModel extends AzureResourcesGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAppConfigurationConfigurationStoreGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            userManagedIdentityServicePrincipalIds);
    }
}

export class AzureAppConnectedEnvironmentGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAppConnectedEnvironmentGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAppConnectedenvironmentsCertificateGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAppConnectedenvironmentsCertificateGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAppContainerAppGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAppContainerAppGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAppJobGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAppJobGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureApplicationAppSettingsSecretExistsRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureApplicationAppSettingsSecretExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureAppManagedEnvironmentGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAppManagedEnvironmentGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAppManagedEnvironmentsCertificateGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAppManagedEnvironmentsCertificateGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAppManagedenvironmentsManagedcertificateGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAppManagedenvironmentsManagedcertificateGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAppPlatformSpringGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAppPlatformSpringGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAppSecurityPolicyGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAppSecurityPolicyGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAppSessionPoolGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAppSessionPoolGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAppSpaceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAppSpaceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureArcDataDataControllerGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureArcDataDataControllerGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureArcDataPostgresInstanceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureArcDataPostgresInstanceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureArcDataSqlManagedInstanceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureArcDataSqlManagedInstanceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureArcDataSqlServerEsuLicenseGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureArcDataSqlServerEsuLicenseGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureArcDataSqlServerInstanceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureArcDataSqlServerInstanceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureArcDataSqlServerInstancesAvailabilityGroupGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureArcDataSqlServerInstancesAvailabilityGroupGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureArcDataSqlServerInstancesDatabaseGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureArcDataSqlServerInstancesDatabaseGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureArcDataSqlServerLicenseGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureArcDataSqlServerLicenseGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAstronomerAstroOrganizationGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAstronomerAstroOrganizationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAttestationAttestationProviderGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAttestationAttestationProviderGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAuthorizationDenyAssignment extends AzureExtensionResource implements ITypeNameObject, IAzureEntity, IResource, IAzureExtensionResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        scopeEntityReference: AzureScopeEntityReference,
        public appliedToChildScopes: boolean,
        public excludedPrincipalReferences: AadDirectoryPrincipalReference[],
        public permission: AzureAuthorizationPermission,
        public principalReferences: AadDirectoryPrincipalReference[],
        public systemProtected: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAuthorizationDenyAssignment",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            scopeEntityReference);
    }
}

export class AzureAuthorizationLock extends AzureExtensionResource implements ITypeNameObject, IAzureEntity, IResource, IAzureExtensionResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        scopeEntityReference: AzureScopeEntityReference,
        public type: Optional<AzureAuthorizationLockType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAuthorizationLock",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            scopeEntityReference);
    }
}

export class AzureAuthorizationResourceManagementPrivateLinkGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAuthorizationResourceManagementPrivateLinkGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAuthorizationRoleAssignmentClassicAdministratorModel extends AzureAuthorizationRoleAssignmentResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IRelatedTenantsEntityModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        containingRoleAssignmentResourceExists: boolean,
        containingRoleAssignmentResourceIds: string[],
        endTime: Optional<string>,
        principalIdReference: string,
        roleDefinitionName: string,
        roleDefinitionType: AzureAuthorizationRoleDefinitionType,
        roleManagementPolicyAssignmentId: Optional<string>,
        scopeEntityIdReference: string,
        scopeEntityPathType: AzureEntityPathType,
        scopeEntityTypeName: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAuthorizationRoleAssignmentClassicAdministratorModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            containingRoleAssignmentResourceExists,
            containingRoleAssignmentResourceIds,
            endTime,
            principalIdReference,
            roleDefinitionName,
            roleDefinitionType,
            roleManagementPolicyAssignmentId,
            scopeEntityIdReference,
            scopeEntityPathType,
            scopeEntityTypeName);
    }
}

export class AzureAuthorizationRoleAssignmentModel extends AzureAuthorizationRoleAssignmentResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IRelatedTenantsEntityModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        containingRoleAssignmentResourceExists: boolean,
        containingRoleAssignmentResourceIds: string[],
        endTime: Optional<string>,
        principalIdReference: string,
        roleDefinitionName: string,
        roleDefinitionType: AzureAuthorizationRoleDefinitionType,
        roleManagementPolicyAssignmentId: Optional<string>,
        scopeEntityIdReference: string,
        scopeEntityPathType: AzureEntityPathType,
        scopeEntityTypeName: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAuthorizationRoleAssignmentModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            containingRoleAssignmentResourceExists,
            containingRoleAssignmentResourceIds,
            endTime,
            principalIdReference,
            roleDefinitionName,
            roleDefinitionType,
            roleManagementPolicyAssignmentId,
            scopeEntityIdReference,
            scopeEntityPathType,
            scopeEntityTypeName);
    }
}

export class AzureAuthorizationRoleAssignmentResource extends AzureExtensionResource implements ITypeNameObject, IAzureEntity, IResource, IAzureExtensionResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        scopeEntityReference: AzureScopeEntityReference,
        public principalReference: AadDirectoryPrincipalReference,
        public roleDefinitionId: string,
        public status: AzureAuthorizationRoleAssignmentResourceStatus) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            scopeEntityReference);
    }
}

export class AzureAuthorizationRoleAssignmentSchedule extends AzureAuthorizationRoleAssignmentResource implements ITypeNameObject, IAzureEntity, IResource, IAzureExtensionResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        scopeEntityReference: AzureScopeEntityReference,
        principalReference: AadDirectoryPrincipalReference,
        roleDefinitionId: string,
        status: AzureAuthorizationRoleAssignmentResourceStatus,
        public endTime: Optional<string>,
        public roleAssignmentPath: string,
        public roleAssignmentRawPath: string,
        public roleEligibilitySchedulePath: Optional<string>,
        public startTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAuthorizationRoleAssignmentSchedule",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            scopeEntityReference,
            principalReference,
            roleDefinitionId,
            status);
    }
}

export class AzureAuthorizationRoleDefinition extends AzureExtensionResource implements ITypeNameObject, IAzureEntity, IResource, IAzureExtensionResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        scopeEntityReference: AzureScopeEntityReference,
        public assignableScopes: string[],
        public description: string,
        public json: string,
        public permissions: AzureAuthorizationPermission[],
        public rawShortId: string,
        public type: AzureAuthorizationRoleDefinitionType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAuthorizationRoleDefinition",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            scopeEntityReference);
    }
}

export class AzureAuthorizationRoleEligibilitySchedule extends AzureAuthorizationRoleAssignmentResource implements ITypeNameObject, IAzureEntity, IResource, IAzureExtensionResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        scopeEntityReference: AzureScopeEntityReference,
        principalReference: AadDirectoryPrincipalReference,
        roleDefinitionId: string,
        status: AzureAuthorizationRoleAssignmentResourceStatus,
        public endTime: Optional<string>,
        public rawId: string,
        public startTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAuthorizationRoleEligibilitySchedule",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            scopeEntityReference,
            principalReference,
            roleDefinitionId,
            status);
    }
}

export class AzureAuthorizationRoleManagementPolicyAssignment extends AzureExtensionResource implements ITypeNameObject, IAzureEntity, IResource, IAzureExtensionResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        scopeEntityReference: AzureScopeEntityReference,
        public activation: AzureAuthorizationRoleManagementPolicyAssignmentActivation,
        public modified: boolean,
        public policyRawId: string,
        public roleDefinitionId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAuthorizationRoleManagementPolicyAssignment",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            scopeEntityReference);
    }
}

export class AzureAutomanageConfigurationProfileGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAutomanageConfigurationProfileGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAutomanageConfigurationProfilesVersionGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAutomanageConfigurationProfilesVersionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAutomanagePatchJobConfigurationGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAutomanagePatchJobConfigurationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAutomanagePatchTierGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAutomanagePatchTierGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAutomationAutomationAccountGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAutomationAutomationAccountGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAutomationAutomationAccountsConfigurationGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAutomationAutomationAccountsConfigurationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAutomationAutomationAccountsRunbookGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAutomationAutomationAccountsRunbookGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAvsPrivateCloudGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAvsPrivateCloudGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorAccessAnalyzerAnalyzerGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorAccessAnalyzerAnalyzerGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorAcmCertificateSummaryGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorAcmCertificateSummaryGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorApiGatewayRestApiGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorApiGatewayRestApiGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorApiGatewayStageGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorApiGatewayStageGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorAppSyncGraphqlApiGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorAppSyncGraphqlApiGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorAutoScalingAutoScalingGroupGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorAutoScalingAutoScalingGroupGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorCloudFormationStackGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorCloudFormationStackGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorCloudFormationStackSetGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorCloudFormationStackSetGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorCloudFrontDistributionGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorCloudFrontDistributionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorCloudTrailTrailGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorCloudTrailTrailGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorCloudWatchAlarmGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorCloudWatchAlarmGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorCodeBuildProjectGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorCodeBuildProjectGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorCodeBuildSourceCredentialsInfoGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorCodeBuildSourceCredentialsInfoGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorConfigServiceConfigurationRecorderGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorConfigServiceConfigurationRecorderGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorConfigServiceConfigurationRecorderStatusGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorConfigServiceConfigurationRecorderStatusGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorConfigServiceDeliveryChannelGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorConfigServiceDeliveryChannelGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorDatabaseMigrationServiceReplicationInstanceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorDatabaseMigrationServiceReplicationInstanceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorDaxClusterGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorDaxClusterGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorDynamoDbContinuousBackupsDescriptionGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorDynamoDbContinuousBackupsDescriptionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorDynamoDbTableGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorDynamoDbTableGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorEc2AccountAttributeGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorEc2AccountAttributeGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorEc2AddressGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorEc2AddressGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorEc2FlowLogGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorEc2FlowLogGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorEc2ImageGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorEc2ImageGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorEc2InstanceStatusGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorEc2InstanceStatusGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorEc2IpamGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorEc2IpamGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorEc2KeyPairGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorEc2KeyPairGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorEc2NetworkAclGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorEc2NetworkAclGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorEc2NetworkInterfaceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorEc2NetworkInterfaceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorEc2RouteTableGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorEc2RouteTableGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorEc2SecurityGroupGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorEc2SecurityGroupGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorEc2SnapshotGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorEc2SnapshotGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorEc2SubnetGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorEc2SubnetGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorEc2VolumeGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorEc2VolumeGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorEc2VpcEndpointGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorEc2VpcEndpointGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorEc2VpcGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorEc2VpcGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorEc2VpcPeeringConnectionGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorEc2VpcPeeringConnectionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorEcrImageDetailGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorEcrImageDetailGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorEcrRepositoryGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorEcrRepositoryGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorEcsClusterGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorEcsClusterGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorEcsServiceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorEcsServiceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorEcsTaskDefinitionGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorEcsTaskDefinitionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorEfsFileSystemGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorEfsFileSystemGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorEfsMountTargetGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorEfsMountTargetGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorEksNodegroupGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorEksNodegroupGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorElasticBeanstalkApplicationGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorElasticBeanstalkApplicationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorElasticBeanstalkConfigurationTemplateGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorElasticBeanstalkConfigurationTemplateGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorElasticBeanstalkEnvironmentGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorElasticBeanstalkEnvironmentGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorElasticLoadBalancingV2ListenerGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorElasticLoadBalancingV2ListenerGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorElasticLoadBalancingV2LoadBalancerGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorElasticLoadBalancingV2LoadBalancerGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorElasticLoadBalancingV2TargetGroupGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorElasticLoadBalancingV2TargetGroupGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorElasticLoadBalancingV2TargetHealthDescriptionGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorElasticLoadBalancingV2TargetHealthDescriptionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorEmrClusterGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorEmrClusterGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorGuardDutyDetectorGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorGuardDutyDetectorGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorIamAccessKeyLastUsedGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorIamAccessKeyLastUsedGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorIamAccessKeyMetadatumGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorIamAccessKeyMetadatumGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorIamGroupGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorIamGroupGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorIamInstanceProfileGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorIamInstanceProfileGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorIamMfaDeviceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorIamMfaDeviceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorIamPasswordPolicyGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorIamPasswordPolicyGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorIamPolicyVersionGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorIamPolicyVersionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorIamRoleGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorIamRoleGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorIamServerCertificateGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorIamServerCertificateGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorIamVirtualMfaDeviceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorIamVirtualMfaDeviceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorKmsAliasGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorKmsAliasGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorKmsKeyGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorKmsKeyGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorLambdaFunctionCodeLocationGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorLambdaFunctionCodeLocationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorLambdaFunctionConfigurationGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorLambdaFunctionConfigurationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorLambdaFunctionGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorLambdaFunctionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorLightsailBucketGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorLightsailBucketGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorLightsailInstanceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorLightsailInstanceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorLogsLogGroupGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorLogsLogGroupGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorLogsLogStreamGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorLogsLogStreamGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorLogsMetricFilterGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorLogsMetricFilterGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorLogsSubscriptionFilterGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorLogsSubscriptionFilterGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorMacie2JobSummaryGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorMacie2JobSummaryGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorMacieAllowListGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorMacieAllowListGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorNetworkFirewallFirewallGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorNetworkFirewallFirewallGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorNetworkFirewallFirewallPolicyGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorNetworkFirewallFirewallPolicyGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorNetworkFirewallRuleGroupGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorNetworkFirewallRuleGroupGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorOpenSearchDomainStatusGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorOpenSearchDomainStatusGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorOrganizationsAccountGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorOrganizationsAccountGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorOrganizationsOrganizationGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorOrganizationsOrganizationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorRdsDbClusterGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorRdsDbClusterGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorRdsDbInstanceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorRdsDbInstanceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorRdsDbSnapshotAttributesResultGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorRdsDbSnapshotAttributesResultGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorRdsDbSnapshotGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorRdsDbSnapshotGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorRdsEventSubscriptionGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorRdsEventSubscriptionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorRdsExportTaskGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorRdsExportTaskGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorRedshiftClusterGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorRedshiftClusterGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorRedshiftClusterParameterGroupGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorRedshiftClusterParameterGroupGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorRoute53DomainsDomainSummaryGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorRoute53DomainsDomainSummaryGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorRoute53HostedZoneGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorRoute53HostedZoneGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorRoute53ResourceRecordSetGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorRoute53ResourceRecordSetGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorS3AccessControlPolicyGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorS3AccessControlPolicyGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorS3AccessPointGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorS3AccessPointGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorS3BucketGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorS3BucketGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorS3BucketPolicyGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorS3BucketPolicyGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorSageMakerAppGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorSageMakerAppGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorSageMakerNotebookInstanceSummaryGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorSageMakerNotebookInstanceSummaryGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorSecretsManagerResourcePolicyGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorSecretsManagerResourcePolicyGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorSecretsManagerSecretGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorSecretsManagerSecretGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorSnsSubscriptionGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorSnsSubscriptionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorSnsTopicGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorSnsTopicGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorSqsQueueGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorSqsQueueGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorSsmInstanceInformationGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorSsmInstanceInformationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorSsmParameterGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorSsmParameterGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorSsmResourceComplianceSummaryItemGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorSsmResourceComplianceSummaryItemGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureAwsConnectorWafv2LoggingConfigurationGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAwsConnectorWafv2LoggingConfigurationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureBackupSolutionsVMwareApplicationGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureBackupSolutionsVMwareApplicationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureBareMetalBareMetalConnectionGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureBareMetalBareMetalConnectionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureBareMetalConsoleConnectionGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureBareMetalConsoleConnectionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureBareMetalCrayServerGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureBareMetalCrayServerGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureBareMetalInfrastructureBareMetalInstanceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureBareMetalInfrastructureBareMetalInstanceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureBareMetalInfrastructureBareMetalStorageInstanceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureBareMetalInfrastructureBareMetalStorageInstanceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureBareMetalMonitoringServerGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureBareMetalMonitoringServerGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureBatchBatchAccountGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureBatchBatchAccountGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureBatchBatchAccountGenericResourceModel extends AzureResourcesGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureBatchBatchAccountGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            userManagedIdentityServicePrincipalIds);
    }
}

export class AzureBillingBenefitsCreditGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureBillingBenefitsCreditGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureBillingBenefitsIncentiveScheduleGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureBillingBenefitsIncentiveScheduleGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureBillingBenefitsMaccGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureBillingBenefitsMaccGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureBingAccountGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureBingAccountGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureBluefinInstanceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureBluefinInstanceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureBluefinInstancesDatasetGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureBluefinInstancesDatasetGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureBluefinInstancesPipelineGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureBluefinInstancesPipelineGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureBotServiceBotService extends AzureResourceGroupResource implements ITypeNameObject, IResource, IAzureEncryptedResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        public encryptionExists: boolean,
        public publicNetworkAccess: boolean,
        public sharedKeyAccess: boolean,
        public vaultKeyInfo: Optional<AzureKeyVaultVaultKeyInfo>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureBotServiceBotService",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId);
    }
}

export class AzureBotServiceBotServiceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureBotServiceBotServiceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureBotServiceBotServiceInboundExternalWideRangeRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureBotServiceBotServiceInboundExternalWideRangeRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureBotServiceBotServiceModel extends AzureResourceGroupResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureEncryptedResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        public vaultKeyIdReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureBotServiceBotServiceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureBotServiceBotServiceSharedKeyAccessEnabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureBotServiceBotServiceSharedKeyAccessEnabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureCacheRedisEnterpriseGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureCacheRedisEnterpriseGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureCacheRedisGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureCacheRedisGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureCacheRedisGenericResourceModel extends AzureResourcesGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureCacheRedisGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            userManagedIdentityServicePrincipalIds);
    }
}

export class AzureCdnCdnwebapplicationfirewallpolicyGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureCdnCdnwebapplicationfirewallpolicyGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureCdnEdgeActionGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureCdnEdgeActionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureCdnEdgeActionsAttachmentGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureCdnEdgeActionsAttachmentGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureCdnEdgeActionsExecutionFilterGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureCdnEdgeActionsExecutionFilterGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureCdnEdgeActionsVersionGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureCdnEdgeActionsVersionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureCdnProfileGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureCdnProfileGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureCdnProfileGenericResourceModel extends AzureResourcesGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureCdnProfileGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            userManagedIdentityServicePrincipalIds);
    }
}

export class AzureCdnProfilesAfdendpointGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureCdnProfilesAfdendpointGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureCdnProfilesEndpointGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureCdnProfilesEndpointGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureCertificateRegistrationCertificateOrderGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureCertificateRegistrationCertificateOrderGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureChaosApplicationGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureChaosApplicationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureChaosExperimentGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureChaosExperimentGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureChaosPrivateAccessGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureChaosPrivateAccessGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureChaosResilienceProfileGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureChaosResilienceProfileGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureClassicComputeDomainNameGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureClassicComputeDomainNameGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureClassicComputeVirtualMachineGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureClassicComputeVirtualMachineGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureClassicComputeVirtualMachineGenericResourceModel extends AzureResourcesGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureClassicComputeVirtualMachineGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            userManagedIdentityServicePrincipalIds);
    }
}

export class AzureClassicNetworkNetworkSecurityGroupGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureClassicNetworkNetworkSecurityGroupGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureClassicNetworkNetworkSecurityGroupGenericResourceModel extends AzureResourcesGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureClassicNetworkNetworkSecurityGroupGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            userManagedIdentityServicePrincipalIds);
    }
}

export class AzureClassicNetworkReservedIpGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureClassicNetworkReservedIpGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureClassicNetworkReservedIpGenericResourceModel extends AzureResourcesGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureClassicNetworkReservedIpGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            userManagedIdentityServicePrincipalIds);
    }
}

export class AzureClassicNetworkVirtualNetworkGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureClassicNetworkVirtualNetworkGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureClassicNetworkVirtualNetworkGenericResourceModel extends AzureResourcesGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureClassicNetworkVirtualNetworkGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            userManagedIdentityServicePrincipalIds);
    }
}

export class AzureClassicStorageStorageAccountGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureClassicStorageStorageAccountGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureClassicStorageStorageAccountGenericResourceModel extends AzureResourcesGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureClassicStorageStorageAccountGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            userManagedIdentityServicePrincipalIds);
    }
}

export class AzureCleanRoomCleanroomGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureCleanRoomCleanroomGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureCleanRoomMicroserviceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureCleanRoomMicroserviceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureCloudDevicePlatformDelegatedIdentityGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureCloudDevicePlatformDelegatedIdentityGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureCloudesAccountGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureCloudesAccountGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureCloudHealthHealthmodelGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureCloudHealthHealthmodelGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureCloudTestAccountGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureCloudTestAccountGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureCloudTestBuildcacheGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureCloudTestBuildcacheGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureCloudTestHostedpoolGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureCloudTestHostedpoolGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureCloudTestImageGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureCloudTestImageGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureCloudTestPoolGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureCloudTestPoolGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureCodeSigningCodeSigningAccountGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureCodeSigningCodeSigningAccountGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureCognitiveServicesAccount extends AzureResourceGroupResource implements ITypeNameObject, IResource, IAzureEncryptedResource, IAzureManagedIdentityOriginatorResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        public encryptionExists: boolean,
        public endpointUrl: string,
        public firewallRuleSubnets: string[],
        public managedIdentityExists: boolean,
        public outboundDnsNames: string[],
        public publicNetworkAccess: boolean,
        public restrictOutboundAccess: boolean,
        public sharedKeyAccess: boolean,
        public systemManagedIdentityServicePrincipalId: Optional<string>,
        public type: AzureCognitiveServicesAccountType,
        public userManagedIdentityPaths: string[],
        public vaultKeyInfo: Optional<AzureKeyVaultVaultKeyInfo>,
        public virtualNetworkPathToFirewallSubnetRulesMap: Dictionary<AzureResourceFirewallVirtualNetworkSubnetRule[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureCognitiveServicesAccount",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId);
    }
}

export class AzureCognitiveServicesAccountGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureCognitiveServicesAccountGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureCognitiveServicesAccountInboundExternalWideRangeRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureCognitiveServicesAccountInboundExternalWideRangeRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureCognitiveServicesAccountModel extends AzureResourceGroupResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureEncryptedResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        public networkResourcePathToIdReferencesMap: Dictionary<string>,
        public userManagedIdentityServicePrincipalIds: string[],
        public vaultKeyIdReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureCognitiveServicesAccountModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureCognitiveServicesAccountSharedKeyAccessEnabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureCognitiveServicesAccountSharedKeyAccessEnabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureCognitiveServicesAccountUnrestrictedOutboundAccessRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureCognitiveServicesAccountUnrestrictedOutboundAccessRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureCognitiveServicesCommitmentPlanGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureCognitiveServicesCommitmentPlanGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureCommunityCommunityTrainingGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureCommunityCommunityTrainingGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureComputeAvailabilitySetGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeAvailabilitySetGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureComputeCapacityReservationGroupGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeCapacityReservationGroupGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureComputeCapacityReservationGroupsCapacityReservationGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeCapacityReservationGroupsCapacityReservationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureComputeCloudServiceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeCloudServiceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureComputeCloudServicesRoleInstancesNetworkInterfaceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeCloudServicesRoleInstancesNetworkInterfaceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureComputeDiskAccessGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeDiskAccessGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureComputeDiskEncryptionSet extends AzureResourceGroupResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        public managedIdentityExists: boolean,
        public systemManagedIdentityServicePrincipalId: Optional<string>,
        public userManagedIdentityPaths: string[],
        public vaultKeyUrl: string,
        public vaultPath: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeDiskEncryptionSet",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId);
    }
}

export class AzureComputeDiskEncryptionSetGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeDiskEncryptionSetGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureComputeDiskEncryptionSetModel extends AzureResourceGroupResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        public userManagedIdentityServicePrincipalIds: string[],
        public vaultIdReference: string,
        public vaultKeyIdReference: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeDiskEncryptionSetModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureComputeDiskGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeDiskGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureComputeDiskResource extends AzureResourceGroupResource implements ITypeNameObject, IResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        public encryptionSetId: Optional<string>,
        public encryptionType: AzureComputeDiskResourceEncryptionType,
        public networkAccessPolicyType: AzureComputeDiskResourceNetworkAccessPolicyType,
        public source: Optional<AzureComputeDiskResourceSource>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId);
    }
}

export class AzureComputeDiskResourceModel extends AzureResourceGroupResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        public diskResourceIds: string[],
        public sourceResourceSearchableIdReference: Optional<EntitySearchableIdReference>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureComputeDiskSnapshot extends AzureComputeDiskResource implements ITypeNameObject, IResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        encryptionSetId: Optional<string>,
        encryptionType: AzureComputeDiskResourceEncryptionType,
        networkAccessPolicyType: AzureComputeDiskResourceNetworkAccessPolicyType,
        source: Optional<AzureComputeDiskResourceSource>,
        public type: AzureComputeDiskSnapshotType,
        public unauthenticatedDataAccess: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeDiskSnapshot",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            encryptionSetId,
            encryptionType,
            networkAccessPolicyType,
            source);
    }
}

export class AzureComputeDiskSnapshotModel extends AzureComputeDiskResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        diskResourceIds: string[],
        sourceResourceSearchableIdReference: Optional<EntitySearchableIdReference>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeDiskSnapshotModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            diskResourceIds,
            sourceResourceSearchableIdReference);
    }
}

export class AzureComputeGalleriesApplicationGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeGalleriesApplicationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureComputeGalleriesApplicationsVersionGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeGalleriesApplicationsVersionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureComputeGalleriesImageGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeGalleriesImageGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureComputeGalleriesImagesVersionGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeGalleriesImagesVersionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureComputeGalleriesInVmAccessControlProfileGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeGalleriesInVmAccessControlProfileGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureComputeGalleriesInVmAccessControlProfilesVersionGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeGalleriesInVmAccessControlProfilesVersionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureComputeGalleriesRemoteContainerImageGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeGalleriesRemoteContainerImageGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureComputeGalleriesServiceArtifactGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeGalleriesServiceArtifactGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureComputeGallery extends AzureResourceGroupResource implements ITypeNameObject, IAzureEntity, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeGallery",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId);
    }
}

export class AzureComputeGalleryGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeGalleryGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureComputeGalleryImageModel extends AzureResourceGroupResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeGalleryImageModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureComputeGalleryModel extends AzureResourceGroupResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeGalleryModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureComputeHostGroupGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeHostGroupGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureComputeHostGroupsHostGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeHostGroupsHostGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureComputeImage extends AzureResourceGroupResource implements ITypeNameObject, IAzureEntity, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        public info: Optional<AzureComputeImageInfo>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId);
    }
}

export class AzureComputeImageGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeImageGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureComputeManagedImage extends AzureComputeImage implements ITypeNameObject, IAzureEntity, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        info: Optional<AzureComputeImageInfo>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeManagedImage",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            info);
    }
}

export class AzureComputeManagedImageModel extends AzureResourceGroupResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeManagedImageModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureComputeProximityPlacementGroupGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeProximityPlacementGroupGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureComputeRestorePointCollectionGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeRestorePointCollectionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureComputeRestorePointCollectionGenericResourceModel extends AzureResourcesGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeRestorePointCollectionGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            userManagedIdentityServicePrincipalIds);
    }
}

export class AzureComputeScheduleAutoActionGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeScheduleAutoActionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureComputeSharedVmExtensionGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeSharedVmExtensionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureComputeSharedVmExtensionsVersionGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeSharedVmExtensionsVersionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureComputeSharedVmImageGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeSharedVmImageGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureComputeSharedVmImagesVersionGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeSharedVmImagesVersionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureComputeSnapshotGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeSnapshotGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureComputeSshPublicKeyGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeSshPublicKeyGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureComputeSshPublicKeyGenericResourceModel extends AzureResourcesGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeSshPublicKeyGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            userManagedIdentityServicePrincipalIds);
    }
}

export class AzureComputeVirtualMachineGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeVirtualMachineGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureComputeVirtualMachineResource extends AzureResourceGroupResource implements ITypeNameObject, IAzureManagedIdentityOriginatorResource, IAzureNetworkEvaluationResource, IAzureNetworkedResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        public applicationSecurityGroupIds: string[],
        public interfaceNetworkSecurityGroupIds: string[],
        public managedIdentityExists: boolean,
        public privateIpAddresses: string[],
        public publicIpAddressIds: string[],
        public rawId: string,
        public specification: Optional<AzureComputeVirtualMachineResourceSpecification>,
        public subnetIds: string[],
        public subnetNetworkSecurityGroupIds: string[],
        public systemManagedIdentityServicePrincipalId: Optional<string>,
        public userManagedIdentityPaths: string[],
        public virtualNetworkId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId);
    }
}

export class AzureComputeVirtualMachineResourceModel extends AzureResourceGroupResourceModel implements ITypeNameObject, IUdmObjectModel, IAzureManagedIdentityOriginatorResourceModel, IAzureNetworkedResourceModel, INetworkAccessResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        public entityNetwork: Optional<NetworkAccessResourceStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        public applicationGatewayIds: string[],
        public imageId: Optional<string>,
        public loadBalancerIds: string[],
        public userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureComputeVirtualMachineScaleSet extends AzureComputeVirtualMachineResource implements ITypeNameObject, IAzureManagedIdentityOriginatorResource, IAzureNetworkEvaluationResource, IAzureNetworkedResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        applicationSecurityGroupIds: string[],
        interfaceNetworkSecurityGroupIds: string[],
        managedIdentityExists: boolean,
        privateIpAddresses: string[],
        publicIpAddressIds: string[],
        rawId: string,
        specification: Optional<AzureComputeVirtualMachineResourceSpecification>,
        subnetIds: string[],
        subnetNetworkSecurityGroupIds: string[],
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[],
        virtualNetworkId: Optional<string>,
        public availabilityZones: number[],
        public type: AzureComputeVirtualMachineScaleSetType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeVirtualMachineScaleSet",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            applicationSecurityGroupIds,
            interfaceNetworkSecurityGroupIds,
            managedIdentityExists,
            privateIpAddresses,
            publicIpAddressIds,
            rawId,
            specification,
            subnetIds,
            subnetNetworkSecurityGroupIds,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths,
            virtualNetworkId);
    }
}

export class AzureComputeVirtualMachineScaleSetGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeVirtualMachineScaleSetGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureComputeVirtualMachineScaleSetModel extends AzureComputeVirtualMachineResourceModel implements ITypeNameObject, IUdmObjectModel, IAzureManagedIdentityOriginatorResourceModel, IAzureNetworkedResourceModel, INetworkAccessResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        public entityNetwork: Optional<NetworkAccessResourceStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        applicationGatewayIds: string[],
        imageId: Optional<string>,
        loadBalancerIds: string[],
        userManagedIdentityServicePrincipalIds: string[],
        public agentPoolId: Optional<string>,
        public virtualMachineIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeVirtualMachineScaleSetModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            applicationGatewayIds,
            imageId,
            loadBalancerIds,
            userManagedIdentityServicePrincipalIds);
    }
}

export class AzureComputeVirtualMachineScaleSetsVirtualMachinesNetworkInterfacesIpConfigurationsPublicIpAddressGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeVirtualMachineScaleSetsVirtualMachinesNetworkInterfacesIpConfigurationsPublicIpAddressGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureComputeVirtualMachineScaleSetVirtualMachine extends AzureComputeVirtualMachineResource implements ITypeNameObject, IAzureManagedIdentityOriginatorResource, IAzureNetworkEvaluationResource, IAzureNetworkedResource, IAzureTagResource, IAzureComputeVirtualMachine {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        applicationSecurityGroupIds: string[],
        interfaceNetworkSecurityGroupIds: string[],
        managedIdentityExists: boolean,
        privateIpAddresses: string[],
        publicIpAddressIds: string[],
        rawId: string,
        specification: Optional<AzureComputeVirtualMachineResourceSpecification>,
        subnetIds: string[],
        subnetNetworkSecurityGroupIds: string[],
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[],
        virtualNetworkId: Optional<string>,
        public availabilityZoneName: Optional<string>,
        public dnsNames: string[],
        public networkInterfaceIds: string[],
        public operatingSystemDisk: Optional<AzureComputeVirtualMachineScaleSetVirtualMachineDisk>,
        public publicIpAddresses: string[],
        public status: AzureComputeVirtualMachineStatus,
        public virtualMachineScaleSetId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeVirtualMachineScaleSetVirtualMachine",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            applicationSecurityGroupIds,
            interfaceNetworkSecurityGroupIds,
            managedIdentityExists,
            privateIpAddresses,
            publicIpAddressIds,
            rawId,
            specification,
            subnetIds,
            subnetNetworkSecurityGroupIds,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths,
            virtualNetworkId);
    }
}

export class AzureComputeVirtualMachineScaleSetVirtualMachineModel extends AzureComputeVirtualMachineResourceModel implements ITypeNameObject, IUdmObjectModel, IAzureManagedIdentityOriginatorResourceModel, IAzureNetworkedResourceModel, IVirtualMachineModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        public entityNetwork: Optional<NetworkAccessResourceStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        applicationGatewayIds: string[],
        imageId: Optional<string>,
        loadBalancerIds: string[],
        userManagedIdentityServicePrincipalIds: string[],
        public diskEncryptionVaultIdReference: Optional<string>,
        public dnsZoneIds: string[],
        public identityPermissionActionSeverity: Optional<Severity>,
        public kubernetesClusterSensor: boolean,
        public operatingSystemReleaseNotesUrl: Optional<string>,
        public operatingSystemType: Optional<OperatingSystemType>,
        public scanTime: Optional<string>,
        public stopped: boolean,
        public stopTime: Optional<string>,
        public vulnerabilities: Optional<VulnerabilityData[]>,
        public vulnerabilitySeveritiesSortValue: string,
        public workloadAnalysisError: Optional<WorkloadAnalysisError>,
        public workloadAnalysisOperatingSystem: Optional<WorkloadResourceScanOperatingSystem>,
        public workloadAnalysisOperatingSystemEndOfLifeDate: Optional<string>,
        public workloadAnalysisOperatingSystemExtendedSupport: Optional<boolean>,
        public workloadAnalysisPriorityScanRequested: boolean,
        public workloadAnalysisStatus: WorkloadAnalysisStatus,
        public workloadAnalysisStatusSortValue: number) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeVirtualMachineScaleSetVirtualMachineModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            applicationGatewayIds,
            imageId,
            loadBalancerIds,
            userManagedIdentityServicePrincipalIds);
    }
}

export class AzureComputeVirtualMachineScaleSetVirtualMachineNetworkInterface extends AzureResourceGroupResource implements ITypeNameObject, IAzureNetworkInterface {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        public applicationSecurityGroupIds: string[],
        public dnsNames: string[],
        public ipConfigurations: AzureNetworkNetworkInterfaceIpConfiguration[],
        public macAddress: Optional<string>,
        public networkSecurityGroupId: Optional<string>,
        public privateIpAddresses: string[],
        public publicIpAddresses: string[],
        public publicIpAddressIds: string[],
        public subnetId: string,
        public virtualNetworkId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeVirtualMachineScaleSetVirtualMachineNetworkInterface",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId);
    }
}

export class AzureComputeVirtualMachineScaleSetVirtualMachineNetworkInterfaceIpConfigurationPublicIpAddress extends AzureResourceGroupResource implements ITypeNameObject, IAzurePublicIpAddress {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        public attachedResourcePath: Optional<string>,
        public dnsName: Optional<string>,
        public dnsNames: string[],
        public ipAddress: Optional<string>,
        public publicIpAddresses: string[],
        public sku: AzureNetworkPublicIpAddressSku,
        public type: AzureNetworkPublicIpAddressType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeVirtualMachineScaleSetVirtualMachineNetworkInterfaceIpConfigurationPublicIpAddress",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId);
    }
}

export class AzureComputeVirtualMachineScaleSetVirtualMachineNetworkInterfaceIpConfigurationPublicIpAddressModel extends AzureResourceGroupResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeVirtualMachineScaleSetVirtualMachineNetworkInterfaceIpConfigurationPublicIpAddressModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureComputeVirtualMachineScaleSetVirtualMachineNetworkInterfaceModel extends AzureResourceGroupResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeVirtualMachineScaleSetVirtualMachineNetworkInterfaceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureComputeVirtualMachinesExtensionGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeVirtualMachinesExtensionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureComputeVirtualMachinesRunCommandGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeVirtualMachinesRunCommandGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureComputeVirtualMachinesVmApplicationGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeVirtualMachinesVmApplicationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureComputeVirtualMachineUnmanagedDiskExistsRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeVirtualMachineUnmanagedDiskExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureComputeVirtualMachineUserDataSecretExistsRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeVirtualMachineUserDataSecretExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureConfidentialLedgerLedgerGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureConfidentialLedgerLedgerGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureConfidentialLedgerManagedCcFGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureConfidentialLedgerManagedCcFGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureConfluentOrganizationGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureConfluentOrganizationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureConnectedCacheCacheNodeGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureConnectedCacheCacheNodeGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureConnectedCacheEnterpriseCustomerGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureConnectedCacheEnterpriseCustomerGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureConnectedCacheEnterpriseMccCustomerGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureConnectedCacheEnterpriseMccCustomerGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureConnectedCacheEnterpriseMccCustomersEnterpriseMccCacheNodeGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureConnectedCacheEnterpriseMccCustomersEnterpriseMccCacheNodeGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureConnectedCacheIspCustomerGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureConnectedCacheIspCustomerGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureConnectedCacheIspCustomersIspCacheNodeGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureConnectedCacheIspCustomersIspCacheNodeGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureConnectedCredentialsCredentialGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureConnectedCredentialsCredentialGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureConnectedopenstackHeatStackGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureConnectedopenstackHeatStackGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureConnectedopenstackOpenStackIdentityGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureConnectedopenstackOpenStackIdentityGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureConnectedVehiclePlatformAccountGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureConnectedVehiclePlatformAccountGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureConnectedVMwareVSphereClusterGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureConnectedVMwareVSphereClusterGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureConnectedVMwareVSphereDatastoreGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureConnectedVMwareVSphereDatastoreGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureConnectedVMwareVSphereHostGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureConnectedVMwareVSphereHostGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureConnectedVMwareVSphereResourcepoolGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureConnectedVMwareVSphereResourcepoolGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureConnectedVMwareVSphereVcenterGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureConnectedVMwareVSphereVcenterGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureConnectedVMwareVSphereVirtualmachineGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureConnectedVMwareVSphereVirtualmachineGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureConnectedVMwareVSphereVirtualmachinesExtensionGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureConnectedVMwareVSphereVirtualmachinesExtensionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureConnectedVMwareVSphereVirtualmachinetemplateGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureConnectedVMwareVSphereVirtualmachinetemplateGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureConnectedVMwareVSphereVirtualnetworkGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureConnectedVMwareVSphereVirtualnetworkGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureContainerInstanceContainerGroupGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureContainerInstanceContainerGroupGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureContainerInstanceContainerGroupGenericResourceModel extends AzureResourcesGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureContainerInstanceContainerGroupGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            userManagedIdentityServicePrincipalIds);
    }
}

export class AzureContainerInstanceContainerGroupProfileGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureContainerInstanceContainerGroupProfileGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureContainerInstanceContainerScaleSetGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureContainerInstanceContainerScaleSetGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureContainerInstanceNGroupGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureContainerInstanceNGroupGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureContainerRegistryRegistriesAgentpoolGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureContainerRegistryRegistriesAgentpoolGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureContainerRegistryRegistriesBuildTaskGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureContainerRegistryRegistriesBuildTaskGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureContainerRegistryRegistriesReplicationGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureContainerRegistryRegistriesReplicationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureContainerRegistryRegistriesTaskGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureContainerRegistryRegistriesTaskGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureContainerRegistryRegistriesTaskrunGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureContainerRegistryRegistriesTaskrunGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureContainerRegistryRegistriesWebhookGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureContainerRegistryRegistriesWebhookGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureContainerRegistryRegistry extends AzureResourceGroupResource implements ITypeNameObject, IResource, IAzureEncryptedResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        public accessKey: boolean,
        public allowAnyAzureTrustedServices: boolean,
        public encryptionExists: boolean,
        public firewallRuleSubnets: string[],
        public networkAccessType: AzureContainerRegistryRegistryNetworkAccessType,
        public repositoryIds: Optional<string[]>,
        public sku: AzureContainerRegistryRegistrySku,
        public url: string,
        public vaultKeyInfo: Optional<AzureKeyVaultVaultKeyInfo>,
        public wideRangeFirewallRuleSubnets: string[],
        public zoneRedundancy: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureContainerRegistryRegistry",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId);
    }
}

export class AzureContainerRegistryRegistryAccessKeyEnabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureContainerRegistryRegistryAccessKeyEnabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureContainerRegistryRegistryGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureContainerRegistryRegistryGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureContainerRegistryRegistryInboundExternalWideRangeRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureContainerRegistryRegistryInboundExternalWideRangeRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureContainerRegistryRegistryModel extends AzureResourceGroupResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureEncryptedResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        public vaultKeyIdReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureContainerRegistryRegistryModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureContainerRegistryRepository extends AzureResourceGroupResource implements ITypeNameObject, IAzureEntity, IResource, IContainerImageRepository {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        public containerImageCount: number,
        public containerImageIds: string[],
        public rawUrl: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureContainerRegistryRepository",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId);
    }
}

export class AzureContainerRegistryRepositoryModel extends AzureResourceGroupResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IContainerImageRepositoryModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        public containerImageResourceIds: string[],
        public containerImageScanStatus: Optional<ContainerImageDataScanStatus>,
        public containerImageVulnerabilities: VulnerabilityData[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureContainerRegistryRepositoryModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureContainerServiceContainerServiceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureContainerServiceContainerServiceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureContainerServiceFleetGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureContainerServiceFleetGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureContainerServiceManagedCluster extends AzureResourceGroupResource implements ITypeNameObject, IResource, IAzureEncryptedResource, IAzureManagedIdentityOriginatorResource, IAzureTagResource, IManagedKubernetesCluster {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        public agentPoolIds: string[],
        public applicationGatewayId: Optional<string>,
        public authentication: AzureContainerServiceManagedClusterAuthentication,
        public authorization: AzureContainerServiceManagedClusterAuthorization,
        public diskEncryptionSetId: Optional<string>,
        public dnsName: string,
        public encryptionExists: boolean,
        public loadBalancerIds: string[],
        public managedIdentityExists: boolean,
        public managedResourceResourceGroupId: Optional<string>,
        public network: AzureContainerServiceManagedClusterNetwork,
        public oidcIdentityProviderIssuerUrl: Optional<string>,
        public platform: Optional<KubernetesClusterPlatform>,
        public platformVersion: string,
        public servicePrincipalId: Optional<string>,
        public status: AzureContainerServiceManagedClusterStatus,
        public systemManagedIdentityServicePrincipalId: Optional<string>,
        public userManagedIdentityPaths: string[],
        public vaultKeyInfo: Optional<AzureKeyVaultVaultKeyInfo>,
        public version: string,
        public virtualNodeEnabled: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureContainerServiceManagedCluster",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId);
    }
}

export class AzureContainerServiceManagedClusterAgentPool extends AzureResourceGroupResource implements ITypeNameObject, IResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        public autoscaling: Optional<AzureContainerServiceManagedClusterAgentPoolAutoscaling>,
        public clusterId: string,
        public mode: AzureContainerServiceManagedClusterAgentPoolMode,
        public node: AzureContainerServiceManagedClusterAgentPoolNode,
        public nodeCount: number,
        public status: AzureContainerServiceManagedClusterAgentPoolStatus,
        public subnetId: Optional<string>,
        public virtualMachineScaleSetIds: string[],
        public virtualNetworkId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureContainerServiceManagedClusterAgentPool",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId);
    }
}

export class AzureContainerServiceManagedClusterAgentPoolModel extends AzureResourceGroupResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureContainerServiceManagedClusterAgentPoolModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureContainerServiceManagedClusterGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureContainerServiceManagedClusterGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureContainerServiceManagedClusterIdentityAuthenticationDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureContainerServiceManagedClusterIdentityAuthenticationDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureContainerServiceManagedClusterInboundExternalWideRangeRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureContainerServiceManagedClusterInboundExternalWideRangeRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureContainerServiceManagedClusterKubernetesSecretEncryptionDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureContainerServiceManagedClusterKubernetesSecretEncryptionDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureContainerServiceManagedClusterModel extends AzureResourceGroupResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureEncryptedResourceModel, IAzureManagedIdentityOriginatorResourceModel, IKubernetesClusterModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        public apiConnectivityStatus: Optional<SystemKubernetesClusterApiConnectivityStatus>,
        public authenticationAndAuthorizationMode: AzureContainerServiceManagedClusterAuthenticationAndAuthorizationMode,
        public authenticationAndAuthorizationModeValue: number,
        public helm: KubernetesClusterModelHelm,
        public resourceUpdateTime: Optional<string>,
        public userManagedIdentityServicePrincipalIds: string[],
        public vaultKeyIdReferences: string[],
        public virtualNetworkSearchableReference: Optional<EntitySearchableReference>,
        public wideRangeAuthorizedSubnets: Optional<string[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureContainerServiceManagedClusterModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureContainerServiceManagedclustersnapshotGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureContainerServiceManagedclustersnapshotGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureContainerServiceManagedClusterVirtualMachineInboundExternalRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureContainerServiceManagedClusterVirtualMachineInboundExternalRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureContainerServiceSnapshotGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureContainerServiceSnapshotGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureCostManagementConnectorGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureCostManagementConnectorGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureCreateRoleAssignmentResourceChange extends AzureResourceChange implements ITypeNameObject, ICloudProviderResourceChange {
    constructor(
        typeName: string,
        entityIds: string[],
        id: string,
        resolved: boolean,
        resourceId: string,
        public principalId: string,
        public scopeEntityId: string) {
        super(
            typeName,
            entityIds,
            id,
            resolved,
            resourceId);
    }
}

export class AzureCreateRoleAssignmentScheduleChange extends AzureCreateRoleAssignmentResourceChange implements ITypeNameObject, ICloudProviderResourceChange {
    constructor(
        entityIds: string[],
        id: string,
        resolved: boolean,
        resourceId: string,
        principalId: string,
        scopeEntityId: string,
        public endTime: Optional<string>) {
        super(
            "AzureCreateRoleAssignmentScheduleChange",
            entityIds,
            id,
            resolved,
            resourceId,
            principalId,
            scopeEntityId);
    }
}

export class AzureCreateRoleEligibilityScheduleChange extends AzureCreateRoleAssignmentResourceChange implements ITypeNameObject, ICloudProviderResourceChange {
    constructor(
        entityIds: string[],
        id: string,
        resolved: boolean,
        resourceId: string,
        principalId: string,
        scopeEntityId: string,
        public endTime: Optional<string>) {
        super(
            "AzureCreateRoleEligibilityScheduleChange",
            entityIds,
            id,
            resolved,
            resourceId,
            principalId,
            scopeEntityId);
    }
}

export class AzureCustomProvidersResourceproviderGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureCustomProvidersResourceproviderGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureD365CustomerInsightsInstanceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureD365CustomerInsightsInstanceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDashboardGrafanaGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDashboardGrafanaGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDashboardGrafanaManagedPrivateEndpointGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDashboardGrafanaManagedPrivateEndpointGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDatabaseFleetManagerFleetGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDatabaseFleetManagerFleetGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDatabaseWatcherWatcherGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDatabaseWatcherWatcherGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDataBoxEdgeDataBoxEdgeDeviceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDataBoxEdgeDataBoxEdgeDeviceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDataBoxJobGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDataBoxJobGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDatabricksAccessConnectorGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDatabricksAccessConnectorGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDatabricksWorkspaceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDatabricksWorkspaceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDatabricksWorkspaceGenericResourceModel extends AzureResourcesGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDatabricksWorkspaceGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            userManagedIdentityServicePrincipalIds);
    }
}

export class AzureDatadogMonitorGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDatadogMonitorGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDataFactoryFactoryGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDataFactoryFactoryGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDataFactoryFactoryGenericResourceModel extends AzureResourcesGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDataFactoryFactoryGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            userManagedIdentityServicePrincipalIds);
    }
}

export class AzureDataLakeAnalyticsAccountGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDataLakeAnalyticsAccountGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDataLakeStoreAccountGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDataLakeStoreAccountGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDataLakeStoreAccountGenericResourceModel extends AzureResourcesGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDataLakeStoreAccountGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            userManagedIdentityServicePrincipalIds);
    }
}

export class AzureDataMigrationMigrationServiceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDataMigrationMigrationServiceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDataMigrationServiceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDataMigrationServiceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDataMigrationServiceGenericResourceModel extends AzureResourcesGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDataMigrationServiceGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            userManagedIdentityServicePrincipalIds);
    }
}

export class AzureDataMigrationServicesProjectGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDataMigrationServicesProjectGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDataMigrationServicesProjectGenericResourceModel extends AzureResourcesGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDataMigrationServicesProjectGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            userManagedIdentityServicePrincipalIds);
    }
}

export class AzureDataMigrationSlotGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDataMigrationSlotGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDataMigrationSqlMigrationServiceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDataMigrationSqlMigrationServiceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDataProtectionBackupVaultGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDataProtectionBackupVaultGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDataProtectionBackupVaultGenericResourceModel extends AzureResourcesGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDataProtectionBackupVaultGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            userManagedIdentityServicePrincipalIds);
    }
}

export class AzureDataProtectionResourceGuardGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDataProtectionResourceGuardGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDataReplicationReplicationFabricGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDataReplicationReplicationFabricGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDataReplicationReplicationVaultGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDataReplicationReplicationVaultGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDataShareAccountGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDataShareAccountGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDataShareAccountGenericResourceModel extends AzureResourcesGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDataShareAccountGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            userManagedIdentityServicePrincipalIds);
    }
}

export class AzureDataTransferConnectionGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDataTransferConnectionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDataTransferConnectionsFlowGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDataTransferConnectionsFlowGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDataTransferPipelineGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDataTransferPipelineGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDbForMariaDbServerGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDbForMariaDbServerGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDbForMariaDbServerGenericResourceModel extends AzureResourcesGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDbForMariaDbServerGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            userManagedIdentityServicePrincipalIds);
    }
}

export class AzureDbForMySqlFlexibleServerGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDbForMySqlFlexibleServerGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDbForMySqlServerGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDbForMySqlServerGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDbForPostgreSqlFlexibleServerGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDbForPostgreSqlFlexibleServerGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDbForPostgreSqlServerGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDbForPostgreSqlServerGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDbForPostgreSqlServerGroupsv2GenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDbForPostgreSqlServerGroupsv2GenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDelegatedNetworkControllerGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDelegatedNetworkControllerGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDelegatedNetworkDelegatedSubnetGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDelegatedNetworkDelegatedSubnetGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDelegatedNetworkOrchestratorGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDelegatedNetworkOrchestratorGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDeleteManagedIdentityChange extends AzureResourceChange implements ITypeNameObject, ICloudProviderResourceChange {
    constructor(
        typeName: string,
        entityIds: string[],
        id: string,
        resolved: boolean,
        resourceId: string) {
        super(
            typeName,
            entityIds,
            id,
            resolved,
            resourceId);
    }
}

export class AzureDeleteRoleAssignmentResourceChange extends AzureResourceChange implements ITypeNameObject, ICloudProviderResourceChange {
    constructor(
        typeName: string,
        entityIds: string[],
        id: string,
        resolved: boolean,
        resourceId: string,
        public scopeEntityId: string) {
        super(
            typeName,
            entityIds,
            id,
            resolved,
            resourceId);
    }
}

export class AzureDeleteRoleAssignmentScheduleChange extends AzureDeleteRoleAssignmentResourceChange implements ITypeNameObject, ICloudProviderResourceChange {
    constructor(
        entityIds: string[],
        id: string,
        resolved: boolean,
        resourceId: string,
        scopeEntityId: string) {
        super(
            "AzureDeleteRoleAssignmentScheduleChange",
            entityIds,
            id,
            resolved,
            resourceId,
            scopeEntityId);
    }
}

export class AzureDeleteRoleEligibilityScheduleChange extends AzureDeleteRoleAssignmentResourceChange implements ITypeNameObject, ICloudProviderResourceChange {
    constructor(
        entityIds: string[],
        id: string,
        resolved: boolean,
        resourceId: string,
        scopeEntityId: string) {
        super(
            "AzureDeleteRoleEligibilityScheduleChange",
            entityIds,
            id,
            resolved,
            resourceId,
            scopeEntityId);
    }
}

export class AzureDeleteSystemManagedIdentityChange extends AzureDeleteManagedIdentityChange implements ITypeNameObject, ICloudProviderResourceChange {
    constructor(
        entityIds: string[],
        id: string,
        resolved: boolean,
        resourceId: string,
        public systemManagedIdentityScopeResourceTypeName: string) {
        super(
            "AzureDeleteSystemManagedIdentityChange",
            entityIds,
            id,
            resolved,
            resourceId);
    }
}

export class AzureDeleteUserManagedIdentityChange extends AzureDeleteManagedIdentityChange implements ITypeNameObject, ICloudProviderResourceChange {
    constructor(
        entityIds: string[],
        id: string,
        resolved: boolean,
        resourceId: string) {
        super(
            "AzureDeleteUserManagedIdentityChange",
            entityIds,
            id,
            resolved,
            resourceId);
    }
}

export class AzureDesktopVirtualizationAppattachpackageGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDesktopVirtualizationAppattachpackageGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDesktopVirtualizationApplicationgroupGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDesktopVirtualizationApplicationgroupGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDesktopVirtualizationApplicationgroupGenericResourceModel extends AzureResourcesGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDesktopVirtualizationApplicationgroupGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            userManagedIdentityServicePrincipalIds);
    }
}

export class AzureDesktopVirtualizationConnectionPolicyGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDesktopVirtualizationConnectionPolicyGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDesktopVirtualizationHostpoolGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDesktopVirtualizationHostpoolGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDesktopVirtualizationHostpoolGenericResourceModel extends AzureResourcesGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDesktopVirtualizationHostpoolGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            userManagedIdentityServicePrincipalIds);
    }
}

export class AzureDesktopVirtualizationScalingPlanGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDesktopVirtualizationScalingPlanGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDesktopVirtualizationScalingPlanGenericResourceModel extends AzureResourcesGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDesktopVirtualizationScalingPlanGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            userManagedIdentityServicePrincipalIds);
    }
}

export class AzureDesktopVirtualizationWorkspaceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDesktopVirtualizationWorkspaceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDesktopVirtualizationWorkspaceGenericResourceModel extends AzureResourcesGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDesktopVirtualizationWorkspaceGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            userManagedIdentityServicePrincipalIds);
    }
}

export class AzureDevCenterDevcenterGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDevCenterDevcenterGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDevCenterDevcentersDevboxdefinitionGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDevCenterDevcentersDevboxdefinitionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDevCenterNetworkConnectionGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDevCenterNetworkConnectionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDevCenterPlanGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDevCenterPlanGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDevCenterProjectGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDevCenterProjectGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDevCenterProjectsPoolGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDevCenterProjectsPoolGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDevHubIacProfileGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDevHubIacProfileGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDevHubWorkflowGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDevHubWorkflowGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDeviceRegistryAssetEndpointProfileGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDeviceRegistryAssetEndpointProfileGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDeviceRegistryAssetGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDeviceRegistryAssetGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDeviceRegistryDeviceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDeviceRegistryDeviceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDeviceRegistryDiscoveredAssetEndpointProfileGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDeviceRegistryDiscoveredAssetEndpointProfileGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDeviceRegistryDiscoveredAssetGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDeviceRegistryDiscoveredAssetGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDeviceRegistrySchemaRegistryGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDeviceRegistrySchemaRegistryGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDevicesIotHubGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDevicesIotHubGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDevicesIotHubGenericResourceModel extends AzureResourcesGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDevicesIotHubGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            userManagedIdentityServicePrincipalIds);
    }
}

export class AzureDevicesProvisioningServiceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDevicesProvisioningServiceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDevicesProvisioningServiceGenericResourceModel extends AzureResourcesGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDevicesProvisioningServiceGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            userManagedIdentityServicePrincipalIds);
    }
}

export class AzureDeviceUpdateAccountGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDeviceUpdateAccountGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDeviceUpdateAccountGenericResourceModel extends AzureResourcesGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDeviceUpdateAccountGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            userManagedIdentityServicePrincipalIds);
    }
}

export class AzureDeviceUpdateAccountsAgentGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDeviceUpdateAccountsAgentGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDeviceUpdateAccountsInstanceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDeviceUpdateAccountsInstanceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDeviceUpdateAccountsInstanceGenericResourceModel extends AzureResourcesGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDeviceUpdateAccountsInstanceGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            userManagedIdentityServicePrincipalIds);
    }
}

export class AzureDeviceUpdateUpdateAccountGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDeviceUpdateUpdateAccountGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDeviceUpdateUpdateAccountsActiveDeploymentGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDeviceUpdateUpdateAccountsActiveDeploymentGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDeviceUpdateUpdateAccountsAgentGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDeviceUpdateUpdateAccountsAgentGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDeviceUpdateUpdateAccountsDeploymentGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDeviceUpdateUpdateAccountsDeploymentGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDeviceUpdateUpdateAccountsDeviceClassGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDeviceUpdateUpdateAccountsDeviceClassGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDeviceUpdateUpdateAccountsUpdateGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDeviceUpdateUpdateAccountsUpdateGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDevOpsInfrastructurePoolGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDevOpsInfrastructurePoolGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDevTestLabLabGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDevTestLabLabGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDevTestLabLabsServiceRunnerGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDevTestLabLabsServiceRunnerGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDevTestLabLabsVirtualMachineGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDevTestLabLabsVirtualMachineGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDevTestLabScheduleGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDevTestLabScheduleGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDigitalTwinsDigitalTwinsInstanceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDigitalTwinsDigitalTwinsInstanceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDocumentDbCassandraClusterGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDocumentDbCassandraClusterGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDocumentDbCassandraClusterGenericResourceModel extends AzureResourcesGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDocumentDbCassandraClusterGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            userManagedIdentityServicePrincipalIds);
    }
}

export class AzureDocumentDbDatabaseAccount extends AzureResourceGroupResource implements ITypeNameObject, IResource, IAzureEncryptedResource, IAzureManagedIdentityOriginatorResource, IAzurePrivateEndpointResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        public allNetworkAccess: boolean,
        public allowAnyAzureInternalIpAddress: boolean,
        public backupType: AzureDocumentDbDatabaseAccountBackupType,
        public encryptionExists: boolean,
        public endpointUrl: string,
        public firewallRuleSubnets: string[],
        public managedIdentityExists: boolean,
        public managementKeyAccess: boolean,
        public publicNetworkAccess: boolean,
        public serverless: boolean,
        public sharedKeyAccess: boolean,
        public systemManagedIdentityServicePrincipalId: Optional<string>,
        public type: AzureDocumentDbDatabaseAccountType,
        public userManagedIdentityPaths: string[],
        public vaultKeyInfo: Optional<AzureKeyVaultVaultKeyInfo>,
        public virtualNetworkPathToFirewallSubnetRulesMap: Dictionary<AzureResourceFirewallVirtualNetworkSubnetRule[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDocumentDbDatabaseAccount",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId);
    }
}

export class AzureDocumentDbDatabaseAccountCassandraKeyspaceModel extends AzureResourceGroupResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDocumentDbDatabaseAccountCassandraKeyspaceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureDocumentDbDatabaseAccountGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDocumentDbDatabaseAccountGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDocumentDbDatabaseAccountGremlinDatabaseModel extends AzureResourceGroupResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDocumentDbDatabaseAccountGremlinDatabaseModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureDocumentDbDatabaseAccountManagementKeyAccessEnabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDocumentDbDatabaseAccountManagementKeyAccessEnabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureDocumentDbDatabaseAccountModel extends AzureResourceGroupResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureEncryptedResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        public databaseAccountResourceIds: string[],
        public networkResourcePathToIdReferencesMap: Dictionary<string>,
        public privateEndpointIdReferences: string[],
        public userManagedIdentityServicePrincipalIds: string[],
        public vaultKeyIdReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDocumentDbDatabaseAccountModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureDocumentDbDatabaseAccountMongoDbDatabaseModel extends AzureResourceGroupResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDocumentDbDatabaseAccountMongoDbDatabaseModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureDocumentDbDatabaseAccountPrivateEndpointNotExistsRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDocumentDbDatabaseAccountPrivateEndpointNotExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureDocumentDbDatabaseAccountResource extends AzureResourceGroupResource implements ITypeNameObject, IAzureEntity, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        public databaseAccountId: string,
        public throughput: Optional<number>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId);
    }
}

export class AzureDocumentDbDatabaseAccountSqlDatabase extends AzureDocumentDbDatabaseAccountResource implements ITypeNameObject, IAzureEntity, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        databaseAccountId: string,
        throughput: Optional<number>,
        public containerNames: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDocumentDbDatabaseAccountSqlDatabase",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            databaseAccountId,
            throughput);
    }
}

export class AzureDocumentDbDatabaseAccountSqlDatabaseModel extends AzureResourceGroupResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDocumentDbDatabaseAccountSqlDatabaseModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureDocumentDbDatabaseAccountTable extends AzureDocumentDbDatabaseAccountResource implements ITypeNameObject, IAzureEntity, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        databaseAccountId: string,
        throughput: Optional<number>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDocumentDbDatabaseAccountTable",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            databaseAccountId,
            throughput);
    }
}

export class AzureDocumentDbDatabaseAccountTableModel extends AzureResourceGroupResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDocumentDbDatabaseAccountTableModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureDocumentDbDatabaseAccountWideRangeInboundRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDocumentDbDatabaseAccountWideRangeInboundRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureDocumentDbGarnetClusterGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDocumentDbGarnetClusterGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDocumentDbMongoClusterGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDocumentDbMongoClusterGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDocumentDbThroughputPoolGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDocumentDbThroughputPoolGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDomainRegistrationDomainGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDomainRegistrationDomainGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDurableTaskNamespaceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDurableTaskNamespaceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDurableTaskSchedulerGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDurableTaskSchedulerGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureDynatraceObservabilityMonitorGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDynatraceObservabilityMonitorGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureEasmWorkspaceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureEasmWorkspaceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureEdgeCapabilityListGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureEdgeCapabilityListGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureEdgeConfigurationGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureEdgeConfigurationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureEdgeDeploymentTargetGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureEdgeDeploymentTargetGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureEdgeHierarchyListGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureEdgeHierarchyListGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureEdgeOrderAddressGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureEdgeOrderAddressGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureEdgeOrderBootstrapConfigurationGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureEdgeOrderBootstrapConfigurationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureEdgeOrderOrderItemGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureEdgeOrderOrderItemGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureEdgeSchemaGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureEdgeSchemaGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureEdgeSolutionBindingGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureEdgeSolutionBindingGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureEdgeSolutionGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureEdgeSolutionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureEdgeWinfieldGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureEdgeWinfieldGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureElasticMonitorGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureElasticMonitorGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureElasticSanElasticSanGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureElasticSanElasticSanGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureElasticSanElasticSansVolumeGroupGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureElasticSanElasticSansVolumeGroupGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureEnterpriseSupportEnterpriseSupportGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureEnterpriseSupportEnterpriseSupportGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureEventGridDomainGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureEventGridDomainGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureEventGridNamespaceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureEventGridNamespaceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureEventGridPartnerConfigurationGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureEventGridPartnerConfigurationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureEventGridPartnerDestinationGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureEventGridPartnerDestinationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureEventGridPartnerNamespaceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureEventGridPartnerNamespaceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureEventGridPartnerRegistrationGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureEventGridPartnerRegistrationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureEventGridPartnerTopicGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureEventGridPartnerTopicGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureEventGridSystemTopicGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureEventGridSystemTopicGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureEventGridTopicGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureEventGridTopicGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureEventHubClusterGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureEventHubClusterGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureEventHubNamespace extends AzureResourceGroupResource implements ITypeNameObject, IResource, IAzureEncryptedResource, IAzureTagResource, IAzureManagedIdentityOriginatorResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        public allowAnyAzureTrustedServices: Optional<boolean>,
        public encryptionExists: boolean,
        public endpointUrl: string,
        public firewallRuleSubnets: string[],
        public managedIdentityExists: boolean,
        public networkAccessType: AzureEventHubNamespaceNetworkAccessType,
        public sharedKeyAccess: boolean,
        public sku: AzureEventHubNamespaceSku,
        public status: AzureEventHubNamespaceStatus,
        public systemManagedIdentityServicePrincipalId: Optional<string>,
        public tlsMinVersion: Optional<string>,
        public userManagedIdentityPaths: string[],
        public vaultKeyInfo: Optional<AzureKeyVaultVaultKeyInfo>,
        public virtualNetworkPathToFirewallSubnetRulesMap: Dictionary<AzureResourceFirewallVirtualNetworkSubnetRule[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureEventHubNamespace",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId);
    }
}

export class AzureEventHubNamespaceEventHub extends AzureResourceGroupResource implements ITypeNameObject, IAzureEntity, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        public status: AzureEventHubNamespaceEventHubStatus) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureEventHubNamespaceEventHub",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId);
    }
}

export class AzureEventHubNamespaceEventHubModel extends AzureResourceGroupResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureEventHubNamespaceEventHubModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureEventHubNamespaceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureEventHubNamespaceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureEventHubNamespaceInboundExternalWideRangeRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureEventHubNamespaceInboundExternalWideRangeRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureEventHubNamespaceModel extends AzureResourceGroupResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureEncryptedResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        public eventHubIds: string[],
        public networkResourcePathToIdReferencesMap: Dictionary<string>,
        public userManagedIdentityServicePrincipalIds: string[],
        public vaultKeyIdReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureEventHubNamespaceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureExcessivePermissionApplicationServicePrincipalRisk extends AzureExcessivePermissionPrincipalRisk implements ITypeNameObject, IAzureExcessivePermissionPrincipalRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        groupIdentityIds: string[],
        identityEnabled: Optional<boolean>,
        originatorResourceIds: string[],
        removedExcessivePermissionActionsSeverity: Optional<Severity>,
        servicePrincipalApplicationId: Optional<string>,
        signInTime: Optional<string>,
        activityTime: Optional<string>,
        excessiveDirectMembershipGroupIds: string[],
        excessiveDirectMembershipGroupsPermissionActionSeverity: Optional<Severity>,
        excessivePermissionEvaluationStartDate: string,
        excessiveScopeRoleAssignmentResourceDatas: AzureExcessivePermissionPrincipalRiskScopeRoleAssignmentResourceData[],
        remainingPermissionActionSeverity: Optional<Severity>,
        roleAssignmentResourceResolutionToPermittersMap: Dictionary<AzureAccessPrincipalRiskPermitters>,
        sensitiveResources: AzureExcessivePermissionPrincipalRiskSensitiveResources,
        tenantEventStartDate: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureExcessivePermissionApplicationServicePrincipalRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            groupIdentityIds,
            identityEnabled,
            originatorResourceIds,
            removedExcessivePermissionActionsSeverity,
            servicePrincipalApplicationId,
            signInTime,
            activityTime,
            excessiveDirectMembershipGroupIds,
            excessiveDirectMembershipGroupsPermissionActionSeverity,
            excessivePermissionEvaluationStartDate,
            excessiveScopeRoleAssignmentResourceDatas,
            remainingPermissionActionSeverity,
            roleAssignmentResourceResolutionToPermittersMap,
            sensitiveResources,
            tenantEventStartDate);
    }
}

export class AzureExcessivePermissionApplicationServicePrincipalRiskModel extends AzureExcessivePermissionPrincipalRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureExcessivePermissionPrincipalRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        infos: AzureExcessivePermissionPrincipalRiskModelInfo[],
        roleAssignmentResourceResolutionToPermittersTypeMap: Dictionary<AzureExcessivePermissionPrincipalRiskModelResolutionPermittersType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureExcessivePermissionApplicationServicePrincipalRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            infos,
            roleAssignmentResourceResolutionToPermittersTypeMap);
    }
}

export class AzureExcessivePermissionGroupRisk extends AzureExcessivePermissionPrincipalRisk implements ITypeNameObject, IAzureExcessivePermissionPrincipalRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        groupIdentityIds: string[],
        identityEnabled: Optional<boolean>,
        originatorResourceIds: string[],
        removedExcessivePermissionActionsSeverity: Optional<Severity>,
        servicePrincipalApplicationId: Optional<string>,
        signInTime: Optional<string>,
        activityTime: Optional<string>,
        excessiveDirectMembershipGroupIds: string[],
        excessiveDirectMembershipGroupsPermissionActionSeverity: Optional<Severity>,
        excessivePermissionEvaluationStartDate: string,
        excessiveScopeRoleAssignmentResourceDatas: AzureExcessivePermissionPrincipalRiskScopeRoleAssignmentResourceData[],
        remainingPermissionActionSeverity: Optional<Severity>,
        roleAssignmentResourceResolutionToPermittersMap: Dictionary<AzureAccessPrincipalRiskPermitters>,
        sensitiveResources: AzureExcessivePermissionPrincipalRiskSensitiveResources,
        tenantEventStartDate: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureExcessivePermissionGroupRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            groupIdentityIds,
            identityEnabled,
            originatorResourceIds,
            removedExcessivePermissionActionsSeverity,
            servicePrincipalApplicationId,
            signInTime,
            activityTime,
            excessiveDirectMembershipGroupIds,
            excessiveDirectMembershipGroupsPermissionActionSeverity,
            excessivePermissionEvaluationStartDate,
            excessiveScopeRoleAssignmentResourceDatas,
            remainingPermissionActionSeverity,
            roleAssignmentResourceResolutionToPermittersMap,
            sensitiveResources,
            tenantEventStartDate);
    }
}

export class AzureExcessivePermissionGroupRiskModel extends AzureExcessivePermissionPrincipalRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureExcessivePermissionPrincipalRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        infos: AzureExcessivePermissionPrincipalRiskModelInfo[],
        roleAssignmentResourceResolutionToPermittersTypeMap: Dictionary<AzureExcessivePermissionPrincipalRiskModelResolutionPermittersType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureExcessivePermissionGroupRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            infos,
            roleAssignmentResourceResolutionToPermittersTypeMap);
    }
}

export class AzureExcessivePermissionManagedIdentityServicePrincipalRisk extends AzureExcessivePermissionPrincipalRisk implements ITypeNameObject, IAzureExcessivePermissionPrincipalRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        groupIdentityIds: string[],
        identityEnabled: Optional<boolean>,
        originatorResourceIds: string[],
        removedExcessivePermissionActionsSeverity: Optional<Severity>,
        servicePrincipalApplicationId: Optional<string>,
        signInTime: Optional<string>,
        activityTime: Optional<string>,
        excessiveDirectMembershipGroupIds: string[],
        excessiveDirectMembershipGroupsPermissionActionSeverity: Optional<Severity>,
        excessivePermissionEvaluationStartDate: string,
        excessiveScopeRoleAssignmentResourceDatas: AzureExcessivePermissionPrincipalRiskScopeRoleAssignmentResourceData[],
        remainingPermissionActionSeverity: Optional<Severity>,
        roleAssignmentResourceResolutionToPermittersMap: Dictionary<AzureAccessPrincipalRiskPermitters>,
        sensitiveResources: AzureExcessivePermissionPrincipalRiskSensitiveResources,
        tenantEventStartDate: Optional<string>,
        public managedIdentityId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureExcessivePermissionManagedIdentityServicePrincipalRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            groupIdentityIds,
            identityEnabled,
            originatorResourceIds,
            removedExcessivePermissionActionsSeverity,
            servicePrincipalApplicationId,
            signInTime,
            activityTime,
            excessiveDirectMembershipGroupIds,
            excessiveDirectMembershipGroupsPermissionActionSeverity,
            excessivePermissionEvaluationStartDate,
            excessiveScopeRoleAssignmentResourceDatas,
            remainingPermissionActionSeverity,
            roleAssignmentResourceResolutionToPermittersMap,
            sensitiveResources,
            tenantEventStartDate);
    }
}

export class AzureExcessivePermissionManagedIdentityServicePrincipalRiskModel extends AzureExcessivePermissionPrincipalRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureExcessivePermissionPrincipalRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        infos: AzureExcessivePermissionPrincipalRiskModelInfo[],
        roleAssignmentResourceResolutionToPermittersTypeMap: Dictionary<AzureExcessivePermissionPrincipalRiskModelResolutionPermittersType>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureExcessivePermissionManagedIdentityServicePrincipalRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            infos,
            roleAssignmentResourceResolutionToPermittersTypeMap);
    }
}

export class AzureExcessivePermissionPrincipalRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        public inactivePrincipalDeleteGroupMembershipEnabled: Optional<boolean>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureExcessivePermissionUserRiskPolicyConfiguration extends AzureExcessivePermissionPrincipalRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        inactivePrincipalDeleteGroupMembershipEnabled: Optional<boolean>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureExcessivePermissionUserRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            inactivePrincipalDeleteGroupMembershipEnabled);
    }
}

export class AzureExperimentationExperimentWorkspaceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureExperimentationExperimentWorkspaceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureExtendedLocationCustomLocationGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureExtendedLocationCustomLocationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureExtendedLocationCustomLocationsResourceSyncRuleGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureExtendedLocationCustomLocationsResourceSyncRuleGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureFabricCapacityGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureFabricCapacityGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureFabricPrivateLinkServicesForFabricGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureFabricPrivateLinkServicesForFabricGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureFairfieldGardensProvisioningResourceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureFairfieldGardensProvisioningResourceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureFairfieldGardensProvisioningResourcesProvisioningPolicyGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureFairfieldGardensProvisioningResourcesProvisioningPolicyGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureFalconNamespaceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureFalconNamespaceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureFileSharesFileShareGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureFileSharesFileShareGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureFleetFleetGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureFleetFleetGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureFluidRelayFluidRelayServerGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureFluidRelayFluidRelayServerGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureFootprintMonitoringProfileGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureFootprintMonitoringProfileGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureGitHubNetworkNetworkSettingGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureGitHubNetworkNetworkSettingGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureGraphServicesAccountGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureGraphServicesAccountGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureGuestUserAdministratorRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureGuestUserAdministratorRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureHanaOnHanaInstanceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureHanaOnHanaInstanceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureHardwareOrderGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureHardwareOrderGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureHardwareSecurityModulesCloudHsmClusterGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureHardwareSecurityModulesCloudHsmClusterGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureHardwareSecurityModulesDedicatedhsmGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureHardwareSecurityModulesDedicatedhsmGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureHardwareSecurityModulesDedicatedhsmGenericResourceModel extends AzureResourcesGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureHardwareSecurityModulesDedicatedhsmGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            userManagedIdentityServicePrincipalIds);
    }
}

export class AzureHdInsightClusterGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureHdInsightClusterGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureHdInsightClusterPoolGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureHdInsightClusterPoolGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureHdInsightClusterPoolsClusterGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureHdInsightClusterPoolsClusterGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureHealthBotHealthBotGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureHealthBotHealthBotGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureHealthcareApisServiceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureHealthcareApisServiceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureHealthcareApisServicesPrivateEndpointConnectionGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureHealthcareApisServicesPrivateEndpointConnectionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureHealthcareApisWorkspaceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureHealthcareApisWorkspaceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureHealthcareApisWorkspacesAnalyticsconnectorGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureHealthcareApisWorkspacesAnalyticsconnectorGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureHealthcareApisWorkspacesDicomserviceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureHealthcareApisWorkspacesDicomserviceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureHealthcareApisWorkspacesFhirserviceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureHealthcareApisWorkspacesFhirserviceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureHealthcareApisWorkspacesIotconnectorGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureHealthcareApisWorkspacesIotconnectorGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureHealthDataAiServicesDeidServiceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureHealthDataAiServicesDeidServiceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureHealthModelHealthmodelGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureHealthModelHealthmodelGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureHybridCloudCloudConnectionGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureHybridCloudCloudConnectionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureHybridCloudCloudConnectorGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureHybridCloudCloudConnectorGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureHybridComputeGatewayGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureHybridComputeGatewayGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureHybridComputeLicenseGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureHybridComputeLicenseGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureHybridComputeMachineGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureHybridComputeMachineGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureHybridComputeMachinesExtensionGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureHybridComputeMachinesExtensionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureHybridComputeMachinesLicenseProfileGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureHybridComputeMachinesLicenseProfileGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureHybridComputeMachinesRuncommandGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureHybridComputeMachinesRuncommandGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureHybridComputePrivateLinkScopeGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureHybridComputePrivateLinkScopeGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureHybridConnectivityPublicCloudConnectorGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureHybridConnectivityPublicCloudConnectorGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureHybridContainerServiceProvisionedClusterGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureHybridContainerServiceProvisionedClusterGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureHybridContainerServiceProvisionedClustersAgentPoolGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureHybridContainerServiceProvisionedClustersAgentPoolGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureHybridContainerServiceStorageSpaceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureHybridContainerServiceStorageSpaceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureHybridContainerServiceVirtualNetworkGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureHybridContainerServiceVirtualNetworkGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureHybridNetworkConfigurationGroupValueGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureHybridNetworkConfigurationGroupValueGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureHybridNetworkDeviceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureHybridNetworkDeviceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureHybridNetworkNetworkFunctionGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureHybridNetworkNetworkFunctionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureHybridNetworkPublisherGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureHybridNetworkPublisherGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureHybridNetworkPublishersArtifactStoreGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureHybridNetworkPublishersArtifactStoreGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureHybridNetworkPublishersArtifactStoresArtifactManifestGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureHybridNetworkPublishersArtifactStoresArtifactManifestGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureHybridNetworkPublishersConfigurationGroupSchemaGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureHybridNetworkPublishersConfigurationGroupSchemaGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureHybridNetworkPublishersNetworkFunctionDefinitionGroupGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureHybridNetworkPublishersNetworkFunctionDefinitionGroupGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureHybridNetworkPublishersNetworkFunctionDefinitionGroupsNetworkFunctionDefinitionVersionGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureHybridNetworkPublishersNetworkFunctionDefinitionGroupsNetworkFunctionDefinitionVersionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureHybridNetworkPublishersNetworkFunctionDefinitionGroupsPreviewSubscriptionGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureHybridNetworkPublishersNetworkFunctionDefinitionGroupsPreviewSubscriptionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureHybridNetworkPublishersNetworkServiceDesignGroupGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureHybridNetworkPublishersNetworkServiceDesignGroupGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureHybridNetworkPublishersNetworkServiceDesignGroupsNetworkServiceDesignVersionGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureHybridNetworkPublishersNetworkServiceDesignGroupsNetworkServiceDesignVersionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureHybridNetworkServiceManagementContainerGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureHybridNetworkServiceManagementContainerGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureHybridNetworkServiceManagementContainersRolloutSequenceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureHybridNetworkServiceManagementContainersRolloutSequenceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureHybridNetworkServiceManagementContainersRolloutTierGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureHybridNetworkServiceManagementContainersRolloutTierGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureHybridNetworkServiceManagementContainersUpdateSpecificationGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureHybridNetworkServiceManagementContainersUpdateSpecificationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureHybridNetworkServiceManagementContainersUpdateSpecificationsRolloutGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureHybridNetworkServiceManagementContainersUpdateSpecificationsRolloutGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureHybridNetworkSiteGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureHybridNetworkSiteGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureHybridNetworkSiteNetworkServiceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureHybridNetworkSiteNetworkServiceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureImageTestingForLinuxJobGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureImageTestingForLinuxJobGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureImageTestingForLinuxJobTemplateGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureImageTestingForLinuxJobTemplateGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureInboundExternalComputeVirtualMachineRisk extends AzureInboundExternalResourceRisk implements ITypeNameObject, IPublicRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        exposedManagementNetworkScopes: DestinationNetworkScope[],
        exposedNetworkScopes: DestinationNetworkScope[],
        interfaceNetworkSecurityGroupIds: string[],
        sourceSubnets: string[],
        subnetNetworkSecurityGroupIds: string[],
        public servicePrincipalPermissionsActionSeverity: Optional<Severity>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureInboundExternalComputeVirtualMachineRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            exposedManagementNetworkScopes,
            exposedNetworkScopes,
            interfaceNetworkSecurityGroupIds,
            sourceSubnets,
            subnetNetworkSecurityGroupIds);
    }
}

export class AzureInboundExternalComputeVirtualMachineRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureInboundExternalComputeVirtualMachineRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureInboundExternalComputeVirtualMachineScaleSetRisk extends AzureInboundExternalResourceRisk implements ITypeNameObject, IPublicRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        exposedManagementNetworkScopes: DestinationNetworkScope[],
        exposedNetworkScopes: DestinationNetworkScope[],
        interfaceNetworkSecurityGroupIds: string[],
        sourceSubnets: string[],
        subnetNetworkSecurityGroupIds: string[],
        public runningVirtualMachineIds: string[],
        public servicePrincipalPermissionsActionSeverity: Optional<Severity>,
        public virtualMachineIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureInboundExternalComputeVirtualMachineScaleSetRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            exposedManagementNetworkScopes,
            exposedNetworkScopes,
            interfaceNetworkSecurityGroupIds,
            sourceSubnets,
            subnetNetworkSecurityGroupIds);
    }
}

export class AzureInboundExternalComputeVirtualMachineScaleSetRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureInboundExternalComputeVirtualMachineScaleSetRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureInformaticaDataManagementOrganizationGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureInformaticaDataManagementOrganizationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureInsightsActionGroupGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureInsightsActionGroupGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureInsightsActivityLogAlertGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureInsightsActivityLogAlertGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureInsightsAutoscaleSettingGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureInsightsAutoscaleSettingGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureInsightsComponentGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureInsightsComponentGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureInsightsDataCollectionEndpointGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureInsightsDataCollectionEndpointGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureInsightsDataCollectionRuleGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureInsightsDataCollectionRuleGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureInsightsDiagnosticSettingsStorageAccountBlobContainerDefaultEncryptionRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureInsightsDiagnosticSettingsStorageAccountBlobContainerDefaultEncryptionRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureInsightsDiagnosticSettingsStorageAccountBlobContainerPublicAccessEnabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureInsightsDiagnosticSettingsStorageAccountBlobContainerPublicAccessEnabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureInsightsDiagnosticSettingsTenantLogCategoryAdministrativeNotExistsRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureInsightsDiagnosticSettingsTenantLogCategoryAdministrativeNotExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureInsightsGuestDiagnosticSettingGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureInsightsGuestDiagnosticSettingGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureInsightsMetricAlertGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureInsightsMetricAlertGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureInsightsPrivateLinkScopeGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureInsightsPrivateLinkScopeGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureInsightsScheduledQueryRuleGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureInsightsScheduledQueryRuleGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureInsightsWebtestGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureInsightsWebtestGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureInsightsWorkbookGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureInsightsWorkbookGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureInsightsWorkbookGenericResourceModel extends AzureResourcesGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureInsightsWorkbookGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            userManagedIdentityServicePrincipalIds);
    }
}

export class AzureInsightsWorkbookTemplateGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureInsightsWorkbookTemplateGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureIntegrationSpacesSpaceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureIntegrationSpacesSpaceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureIntegrationSpacesSpacesApplicationGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureIntegrationSpacesSpacesApplicationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureIotCentralIoTAppGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureIotCentralIoTAppGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureIoTFirmwareDefenseWorkspaceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureIoTFirmwareDefenseWorkspaceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureIoTOperationsDataProcessorInstanceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureIoTOperationsDataProcessorInstanceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureIoTOperationsDataProcessorInstancesDatasetGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureIoTOperationsDataProcessorInstancesDatasetGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureIoTOperationsDataProcessorInstancesPipelineGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureIoTOperationsDataProcessorInstancesPipelineGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureIoTOperationsInstanceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureIoTOperationsInstanceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureIoTOperationsMqMqBrokerAuthenticationGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureIoTOperationsMqMqBrokerAuthenticationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureIoTOperationsMqMqBrokerAuthorizationGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureIoTOperationsMqMqBrokerAuthorizationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureIoTOperationsMqMqBrokerGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureIoTOperationsMqMqBrokerGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureIoTOperationsMqMqBrokerListenerGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureIoTOperationsMqMqBrokerListenerGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureIoTOperationsMqMqDataLakeConnectorGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureIoTOperationsMqMqDataLakeConnectorGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureIoTOperationsMqMqDataLakeConnectorTopicMapGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureIoTOperationsMqMqDataLakeConnectorTopicMapGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureIoTOperationsMqMqDiagnosticServiceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureIoTOperationsMqMqDiagnosticServiceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureIoTOperationsMqMqGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureIoTOperationsMqMqGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureIoTOperationsMqMqKafkaConnectorGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureIoTOperationsMqMqKafkaConnectorGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureIoTOperationsMqMqKafkaConnectorTopicMapGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureIoTOperationsMqMqKafkaConnectorTopicMapGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureIoTOperationsMqMqMqttBridgeConnectorGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureIoTOperationsMqMqMqttBridgeConnectorGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureIoTOperationsMqMqMqttBridgeConnectorTopicMapGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureIoTOperationsMqMqMqttBridgeConnectorTopicMapGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureIoTOperationsOrchestratorInstanceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureIoTOperationsOrchestratorInstanceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureIoTOperationsOrchestratorSolutionGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureIoTOperationsOrchestratorSolutionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureIoTOperationsOrchestratorTargetGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureIoTOperationsOrchestratorTargetGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureKeyVaultHsmPoolGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKeyVaultHsmPoolGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureKeyVaultManagedHsMGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKeyVaultManagedHsMGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureKeyVaultVault extends AzureResourceGroupResource implements ITypeNameObject, IResource, IAzurePrivateEndpointResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        public accessPolicies: AzureKeyVaultVaultAccessPolicy[],
        public allNetworkAccess: boolean,
        public firewallRuleSubnets: string[],
        public publicNetworkAccess: boolean,
        public rbacEnabled: boolean,
        public softDelete: Optional<AzureKeyVaultVaultSoftDelete>,
        public url: string,
        public virtualNetworkPathToFirewallSubnetRulesMap: Dictionary<AzureResourceFirewallVirtualNetworkSubnetRule[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKeyVaultVault",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId);
    }
}

export class AzureKeyVaultVaultCertificateModelFilters extends AzureKeyVaultVaultObjectModelFilters implements IEntityModelFilters {
    constructor(
        attributeValueItems: FilterItems<string>,
        openRelatedEntityRiskTypeIdentifierItems: FilterItems<string>,
        regionIdItems: FilterItems<string>,
        tenantIdItems: FilterItems<string>,
        typeNameItems: FilterItems<string>,
        creationTimeRange: FilterTimeRange,
        expirationTimeRange: FilterTimeRange,
        public thumbprintValueItems: FilterItems<string>) {
        super(
            attributeValueItems,
            openRelatedEntityRiskTypeIdentifierItems,
            regionIdItems,
            tenantIdItems,
            typeNameItems,
            creationTimeRange,
            expirationTimeRange);
    }
}

export class AzureKeyVaultVaultCertificateNotRotatedRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        public certificateRotationTimeFrame: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKeyVaultVaultCertificateNotRotatedRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureKeyVaultVaultEventDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKeyVaultVaultEventDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureKeyVaultVaultGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKeyVaultVaultGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureKeyVaultVaultKeyExpirationDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKeyVaultVaultKeyExpirationDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureKeyVaultVaultKeyNotRotatedRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        public keyRotationTimeFrame: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKeyVaultVaultKeyNotRotatedRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureKeyVaultVaultKeyRotationDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        public keyRotationTimeFrame: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKeyVaultVaultKeyRotationDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureKeyVaultVaultModel extends AzureResourceGroupResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        public eventEnabled: boolean,
        public firstVaultObjectId: Optional<string>,
        public networkResourcePathToIdReferencesMap: Dictionary<string>,
        public privateEndpointIdReferences: string[],
        public vaultObjectCount: number,
        public wideRangeFirewallRuleSubnets: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKeyVaultVaultModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureKeyVaultVaultObject extends AzureResourceGroupResource implements ITypeNameObject, IResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        public currentVersionName: string,
        public enabled: boolean,
        public expirationTime: Optional<string>,
        public url: string,
        public vaultId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId);
    }
}

export class AzureKeyVaultVaultObjectModel extends AzureResourceGroupResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        public aadPrimaryDomainName: string,
        public azureManaged: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureKeyVaultVaultPrivateEndpointNotExistsRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKeyVaultVaultPrivateEndpointNotExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureKeyVaultVaultRbacDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKeyVaultVaultRbacDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureKeyVaultVaultSecret extends AzureKeyVaultVaultObject implements ITypeNameObject, IResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        currentVersionName: string,
        enabled: boolean,
        expirationTime: Optional<string>,
        url: string,
        vaultId: string,
        public versionNameToVersionMap: Dictionary<AzureKeyVaultVaultSecretVersion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKeyVaultVaultSecret",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            currentVersionName,
            enabled,
            expirationTime,
            url,
            vaultId);
    }
}

export class AzureKeyVaultVaultSecretExpirationDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKeyVaultVaultSecretExpirationDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureKeyVaultVaultSecretModel extends AzureKeyVaultVaultObjectModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: string,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        aadPrimaryDomainName: string,
        azureManaged: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKeyVaultVaultSecretModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            aadPrimaryDomainName,
            azureManaged);
    }
}

export class AzureKeyVaultVaultSoftDeleteDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKeyVaultVaultSoftDeleteDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureKeyVaultVaultWideRangeInboundRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKeyVaultVaultWideRangeInboundRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureKubernetesClusterRoleBindingModel extends AzureKubernetesResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesRoleBindingModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        public principalIdReferences: string[],
        public roleIdReference: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKubernetesClusterRoleBindingModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType);
    }
}

export class AzureKubernetesClusterRoleModel extends AzureKubernetesResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesRoleModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        public principalIdReferences: string[],
        public roleBindingIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKubernetesClusterRoleModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType);
    }
}

export class AzureKubernetesConfigurationPrivateLinkScopeGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKubernetesConfigurationPrivateLinkScopeGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureKubernetesConnectedClusterGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKubernetesConnectedClusterGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureKubernetesGroupModel extends AzureKubernetesResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesResourceModel, IKubernetesPrincipalModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        public roleBindingIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKubernetesGroupModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType);
    }
}

export class AzureKubernetesIngressClassModel extends AzureKubernetesResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKubernetesIngressClassModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType);
    }
}

export class AzureKubernetesNamespaceModel extends AzureKubernetesResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesNamespaceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        public resourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKubernetesNamespaceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType);
    }
}

export class AzureKubernetesNamespaceResourceModel extends AzureKubernetesResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesNamespaceResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType);
    }
}

export class AzureKubernetesNamespaceRoleBindingModel extends AzureKubernetesNamespaceResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesNamespaceResourceModel, IKubernetesRoleBindingModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        public principalIdReferences: string[],
        public roleIdReference: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKubernetesNamespaceRoleBindingModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType);
    }
}

export class AzureKubernetesNamespaceRoleModel extends AzureKubernetesNamespaceResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesNamespaceResourceModel, IKubernetesRoleModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        public principalIdReferences: string[],
        public roleBindingIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKubernetesNamespaceRoleModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType);
    }
}

export class AzureKubernetesNetworkPolicyModel extends AzureKubernetesNamespaceResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesNamespaceResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKubernetesNetworkPolicyModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType);
    }
}

export class AzureKubernetesNodeModel extends AzureKubernetesResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesNodeModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKubernetesNodeModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType);
    }
}

export class AzureKubernetesPersistentVolumeModel extends AzureKubernetesResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKubernetesPersistentVolumeModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType);
    }
}

export class AzureKubernetesPodModel extends AzureKubernetesNamespaceResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesNamespaceResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKubernetesPodModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType);
    }
}

export class AzureKubernetesPodSecurityPolicyModel extends AzureKubernetesResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKubernetesPodSecurityPolicyModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType);
    }
}

export class AzureKubernetesPodTemplateModel extends AzureKubernetesNamespaceResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesNamespaceResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKubernetesPodTemplateModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType);
    }
}

export class AzureKubernetesResource<TKubernetesResourceData extends KubernetesResourceData> extends AzureKubernetesResourceBase implements ITypeNameObject, IAzureEntity, IKubernetesResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: TKubernetesResourceData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class AzureKubernetesServiceAccountModel extends AzureKubernetesNamespaceResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesServiceAccountModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        public aadServicePrincipalIds: string[],
        public originatorWorkloadResourceIds: string[],
        public roleBindingIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKubernetesServiceAccountModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType);
    }
}

export class AzureKubernetesServiceModel extends AzureKubernetesNamespaceResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesServiceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        public ingressIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKubernetesServiceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType);
    }
}

export class AzureKubernetesUser extends AzureKubernetesResource<KubernetesUserData> implements ITypeNameObject, IAzureEntity, IKubernetesUser {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesUserData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKubernetesUser",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class AzureKubernetesWorkloadResourceModel extends AzureKubernetesNamespaceResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesWorkloadResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        public aadServicePrincipalIds: string[],
        public agentPoolIds: string[],
        public containerImageIdReferences: string[],
        public containerImageRawUrlToDataMap: Dictionary<ContainerImageResourceStateContainerImageData>,
        public containerImageScanStatus: Optional<ContainerImageDataScanStatus>,
        public containerImageVulnerabilities: VulnerabilityData[],
        public controllerResourceReference: Optional<EntitySearchableIdReference>,
        public serviceAccountReference: EntitySearchableIdReference) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType);
    }
}

export class AzureKustoClusterGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKustoClusterGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureKustoClusterGenericResourceModel extends AzureResourcesGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKustoClusterGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            userManagedIdentityServicePrincipalIds);
    }
}

export class AzureLabServicesLabAccountGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureLabServicesLabAccountGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureLabServicesLabGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureLabServicesLabGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureLabServicesLabPlanGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureLabServicesLabPlanGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureLargeInstanceAzureLargeInstanceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureLargeInstanceAzureLargeInstanceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureLargeInstanceAzureLargeStorageInstanceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureLargeInstanceAzureLargeStorageInstanceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureLoadTestServiceLoadTestGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureLoadTestServiceLoadTestGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureLogicBusinessprocessGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureLogicBusinessprocessGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureLogicIntegrationAccountGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureLogicIntegrationAccountGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureLogicIntegrationServiceEnvironmentGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureLogicIntegrationServiceEnvironmentGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureLogicIntegrationServiceEnvironmentsManagedApiGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureLogicIntegrationServiceEnvironmentsManagedApiGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureLogicWorkflow extends AzureResourceGroupResource implements ITypeNameObject, IResource, IAzureTagResource, IAzureManagedIdentityOriginatorResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        public contentIpAddressExists: boolean,
        public contentIpAddressRanges: IpAddressRange[],
        public contentIpAddressSubnets: string[],
        public endpointUrl: string,
        public managedIdentityExists: boolean,
        public rawDefinition: string,
        public runTime: Optional<string>,
        public status: AzureLogicWorkflowStatus,
        public systemManagedIdentityServicePrincipalId: Optional<string>,
        public triggerIpAddressExists: boolean,
        public triggerIpAddressRanges: IpAddressRange[],
        public triggerIpAddressSubnets: string[],
        public triggerNetworkAccessType: AzureLogicWorkflowTriggerNetworkAccessType,
        public updateTime: string,
        public userManagedIdentityPaths: string[],
        public zoneRedundancy: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureLogicWorkflow",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId);
    }
}

export class AzureLogicWorkflowGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureLogicWorkflowGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureLogicWorkflowInboundExternalWideRangeRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureLogicWorkflowInboundExternalWideRangeRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureLogicWorkflowModel extends AzureResourceGroupResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        public userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureLogicWorkflowModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureLogicWorkflowSecretExistsRiskPolicyConfiguration extends SecretExistsRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        exclusionSecretPatterns: Optional<SecretExistsRiskPolicyConfigurationSecretExclusionSecretPattern[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureLogicWorkflowSecretExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            exclusionSecretPatterns);
    }
}

export class AzureLogzMonitorGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureLogzMonitorGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureLogzMonitorsAccountGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureLogzMonitorsAccountGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureLogzMonitorsMetricsSourceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureLogzMonitorsMetricsSourceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureMachineLearningServicesRegistryGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMachineLearningServicesRegistryGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureMachineLearningServicesVirtualclusterGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMachineLearningServicesVirtualclusterGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureMachineLearningServicesWorkspaceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMachineLearningServicesWorkspaceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureMachineLearningServicesWorkspacesBatchEndpointGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMachineLearningServicesWorkspacesBatchEndpointGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureMachineLearningServicesWorkspacesBatchEndpointsDeploymentGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMachineLearningServicesWorkspacesBatchEndpointsDeploymentGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureMachineLearningServicesWorkspacesInferencePoolGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMachineLearningServicesWorkspacesInferencePoolGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureMachineLearningServicesWorkspacesInferencePoolsEndpointGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMachineLearningServicesWorkspacesInferencePoolsEndpointGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureMachineLearningServicesWorkspacesInferencePoolsGroupGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMachineLearningServicesWorkspacesInferencePoolsGroupGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureMachineLearningServicesWorkspacesOnlineEndpointGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMachineLearningServicesWorkspacesOnlineEndpointGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureMachineLearningServicesWorkspacesOnlineEndpointsDeploymentGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMachineLearningServicesWorkspacesOnlineEndpointsDeploymentGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureMachineLearningServicesWorkspacesServerlessEndpointGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMachineLearningServicesWorkspacesServerlessEndpointGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureMachineLearningWorkspace extends AzureResourceGroupResource implements ITypeNameObject, IResource, IAzureEncryptedResource, IAzureManagedIdentityOriginatorResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        public allNetworkAccess: boolean,
        public encryptionExists: boolean,
        public firewallRuleSubnets: string[],
        public highBusinessImpact: boolean,
        public managedIdentityExists: boolean,
        public publicNetworkAccess: boolean,
        public registryPath: Optional<string>,
        public storageAccountPath: Optional<string>,
        public systemManagedIdentityServicePrincipalId: Optional<string>,
        public userManagedIdentityPaths: string[],
        public vaultKeyInfo: Optional<AzureKeyVaultVaultKeyInfo>,
        public vaultPath: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMachineLearningWorkspace",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId);
    }
}

export class AzureMachineLearningWorkspaceDiagnosticSettingsLogCategoryNotEnabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMachineLearningWorkspaceDiagnosticSettingsLogCategoryNotEnabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureMachineLearningWorkspaceInboundExternalWideRangeRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMachineLearningWorkspaceInboundExternalWideRangeRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureMachineLearningWorkspaceModel extends AzureResourceGroupResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureEncryptedResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        public registryIdReference: Optional<string>,
        public storageAccountIdReference: Optional<string>,
        public userManagedIdentityServicePrincipalIds: string[],
        public vaultIdReference: Optional<string>,
        public vaultKeyIdReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMachineLearningWorkspaceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureMaintenanceMaintenanceConfigurationGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMaintenanceMaintenanceConfigurationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureManagedIdentityManagedIdentity extends AzureResourceGroupResource implements ITypeNameObject, IAzureEntity, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        public servicePrincipalId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId);
    }
}

export class AzureManagedIdentityManagedIdentityInactiveRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureManagedIdentityManagedIdentityInactiveRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureManagedIdentityManagedIdentityModel extends AzureResourceGroupResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        public originatorResourceIds: string[],
        public servicePrincipalActivity: Activity,
        public servicePrincipalContainedRoleAssignmentResourceIds: string[],
        public servicePrincipalRoleAssignmentResourceIds: string[],
        public servicePrincipalScopesActivities: PrincipalModelAccessScopesActivity[],
        public servicePrincipalSignInTime: Optional<string>,
        public type: AzureManagedIdentityManagedIdentityModelType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureManagedIdentityNotAllowedResourcePermissionRiskPolicyConfiguration extends CustomRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        description: Optional<string>,
        name: string,
        severity: Severity,
        updateTime: string,
        public actionNamePatterns: Optional<string[]>,
        public actionRiskCategories: Optional<ActionRiskCategory[]>,
        public actions: Optional<string[]>,
        public excludeManagedIdentityMatchConditions: EntityMatchCondition[],
        public excludeScopeResourceMatchConditions: EntityMatchCondition[],
        public managedIdentityMatchConditions: EntityMatchCondition[],
        public scopeResourceMatchConditions: EntityMatchCondition[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureManagedIdentityNotAllowedResourcePermissionRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            description,
            name,
            severity,
            updateTime);
    }
}

export class AzureManagedIdentitySystemManagedIdentity extends AzureManagedIdentityManagedIdentity implements ITypeNameObject, IAzureEntity, IResource, IAzureExtensionResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        servicePrincipalId: string,
        public scopeEntityReference: AzureScopeEntityReference) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureManagedIdentitySystemManagedIdentity",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            servicePrincipalId);
    }
}

export class AzureManagedIdentitySystemManagedIdentityModel extends AzureManagedIdentityManagedIdentityModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        originatorResourceIds: string[],
        servicePrincipalActivity: Activity,
        servicePrincipalContainedRoleAssignmentResourceIds: string[],
        servicePrincipalRoleAssignmentResourceIds: string[],
        servicePrincipalScopesActivities: PrincipalModelAccessScopesActivity[],
        servicePrincipalSignInTime: Optional<string>,
        type: AzureManagedIdentityManagedIdentityModelType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureManagedIdentitySystemManagedIdentityModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            originatorResourceIds,
            servicePrincipalActivity,
            servicePrincipalContainedRoleAssignmentResourceIds,
            servicePrincipalRoleAssignmentResourceIds,
            servicePrincipalScopesActivities,
            servicePrincipalSignInTime,
            type);
    }
}

export class AzureManagedIdentityUserAssignedIdentityGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureManagedIdentityUserAssignedIdentityGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureManagedIdentityUserManagedIdentity extends AzureManagedIdentityManagedIdentity implements ITypeNameObject, IResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        servicePrincipalId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureManagedIdentityUserManagedIdentity",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            servicePrincipalId);
    }
}

export class AzureManagedIdentityUserManagedIdentityModel extends AzureManagedIdentityManagedIdentityModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        originatorResourceIds: string[],
        servicePrincipalActivity: Activity,
        servicePrincipalContainedRoleAssignmentResourceIds: string[],
        servicePrincipalRoleAssignmentResourceIds: string[],
        servicePrincipalScopesActivities: PrincipalModelAccessScopesActivity[],
        servicePrincipalSignInTime: Optional<string>,
        type: AzureManagedIdentityManagedIdentityModelType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureManagedIdentityUserManagedIdentityModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            originatorResourceIds,
            servicePrincipalActivity,
            servicePrincipalContainedRoleAssignmentResourceIds,
            servicePrincipalRoleAssignmentResourceIds,
            servicePrincipalScopesActivities,
            servicePrincipalSignInTime,
            type);
    }
}

export class AzureManagedNetworkFabricAccessControlListGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureManagedNetworkFabricAccessControlListGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureManagedNetworkFabricInternetGatewayGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureManagedNetworkFabricInternetGatewayGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureManagedNetworkFabricInternetGatewayRuleGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureManagedNetworkFabricInternetGatewayRuleGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureManagedNetworkFabricIpCommunityGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureManagedNetworkFabricIpCommunityGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureManagedNetworkFabricIpCommunityListGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureManagedNetworkFabricIpCommunityListGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureManagedNetworkFabricIpExtendedCommunityGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureManagedNetworkFabricIpExtendedCommunityGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureManagedNetworkFabricIpPrefixGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureManagedNetworkFabricIpPrefixGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureManagedNetworkFabricIpPrefixListGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureManagedNetworkFabricIpPrefixListGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureManagedNetworkFabricL2IsolationDomainGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureManagedNetworkFabricL2IsolationDomainGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureManagedNetworkFabricL3IsolationDomainGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureManagedNetworkFabricL3IsolationDomainGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureManagedNetworkFabricNeighborGroupGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureManagedNetworkFabricNeighborGroupGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureManagedNetworkFabricNetworkDeviceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureManagedNetworkFabricNetworkDeviceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureManagedNetworkFabricNetworkFabricControllerGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureManagedNetworkFabricNetworkFabricControllerGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureManagedNetworkFabricNetworkFabricGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureManagedNetworkFabricNetworkFabricGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureManagedNetworkFabricNetworkPacketBrokerGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureManagedNetworkFabricNetworkPacketBrokerGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureManagedNetworkFabricNetworkRackGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureManagedNetworkFabricNetworkRackGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureManagedNetworkFabricNetworkTapGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureManagedNetworkFabricNetworkTapGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureManagedNetworkFabricNetworkTapRuleGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureManagedNetworkFabricNetworkTapRuleGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureManagedNetworkFabricRoutePolicyGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureManagedNetworkFabricRoutePolicyGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureManufacturingPlatformManufacturingDataServiceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureManufacturingPlatformManufacturingDataServiceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureMapsAccountGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMapsAccountGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureMapsAccountsCreatorGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMapsAccountsCreatorGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureMediaMediaserviceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMediaMediaserviceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureMediaMediaserviceGenericResourceModel extends AzureResourcesGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMediaMediaserviceGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            userManagedIdentityServicePrincipalIds);
    }
}

export class AzureMediaMediaservicesLiveEventGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMediaMediaservicesLiveEventGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureMediaMediaservicesStreamingEndpointGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMediaMediaservicesStreamingEndpointGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureMediaVideoAnalyzerGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMediaVideoAnalyzerGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureMessagingCatalogCatalogGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMessagingCatalogCatalogGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureMessagingConnectorsConnectorGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMessagingConnectorsConnectorGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureMigrateAssessmentProjectGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMigrateAssessmentProjectGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureMigrateMigrateProjectGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMigrateMigrateProjectGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureMigrateModernizeProjectGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMigrateModernizeProjectGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureMigrateMoveCollectionGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMigrateMoveCollectionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureMissionCatalogGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMissionCatalogGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureMissionCommunitiesCommunityEndpointGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMissionCommunitiesCommunityEndpointGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureMissionCommunitiesTransitHubGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMissionCommunitiesTransitHubGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureMissionCommunityGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMissionCommunityGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureMissionEnclaveConnectionGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMissionEnclaveConnectionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureMissionExternalConnectionGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMissionExternalConnectionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureMissionInternalConnectionGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMissionInternalConnectionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureMissionVirtualEnclafeGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMissionVirtualEnclafeGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureMissionVirtualEnclavesEnclaveEndpointGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMissionVirtualEnclavesEnclaveEndpointGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureMissionVirtualEnclavesEndpointGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMissionVirtualEnclavesEndpointGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureMissionVirtualEnclavesWorkloadGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMissionVirtualEnclavesWorkloadGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureMixedRealityObjectAnchorsAccountGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMixedRealityObjectAnchorsAccountGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureMixedRealityObjectUnderstandingAccountGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMixedRealityObjectUnderstandingAccountGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureMixedRealityRemoteRenderingAccountGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMixedRealityRemoteRenderingAccountGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureMixedRealitySpatialAnchorsAccountGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMixedRealitySpatialAnchorsAccountGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureMixedRealitySpatialMapsAccountGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMixedRealitySpatialMapsAccountGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureMobileNetworkMobileNetworkGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMobileNetworkMobileNetworkGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureMobileNetworkMobileNetworksDataNetworkGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMobileNetworkMobileNetworksDataNetworkGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureMobileNetworkMobileNetworksEdgeNetworkSecurityGroupGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMobileNetworkMobileNetworksEdgeNetworkSecurityGroupGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureMobileNetworkMobileNetworksServiceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMobileNetworkMobileNetworksServiceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureMobileNetworkMobileNetworksSimPolicyGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMobileNetworkMobileNetworksSimPolicyGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureMobileNetworkMobileNetworksSiteGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMobileNetworkMobileNetworksSiteGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureMobileNetworkMobileNetworksSliceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMobileNetworkMobileNetworksSliceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureMobileNetworkMobileNetworksWifiSsidGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMobileNetworkMobileNetworksWifiSsidGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureMobileNetworkPacketCoreControlPlaneGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMobileNetworkPacketCoreControlPlaneGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureMobileNetworkPacketCoreControlPlanesPacketCoreDataPlaneGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMobileNetworkPacketCoreControlPlanesPacketCoreDataPlaneGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureMobileNetworkPacketCoreControlPlanesPacketCoreDataPlanesAttachedDataNetworkGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMobileNetworkPacketCoreControlPlanesPacketCoreDataPlanesAttachedDataNetworkGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureMobileNetworkPacketCoreControlPlanesPacketCoreDataPlanesAttachedWifiSsidGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMobileNetworkPacketCoreControlPlanesPacketCoreDataPlanesAttachedWifiSsidGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureMobileNetworkPacketCoreControlPlanesPacketCoreDataPlanesEdgeVirtualNetworkGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMobileNetworkPacketCoreControlPlanesPacketCoreDataPlanesEdgeVirtualNetworkGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureMobileNetworkRadioAccessNetworkGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMobileNetworkRadioAccessNetworkGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureMobileNetworkSimGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMobileNetworkSimGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureMobileNetworkSimGroupGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMobileNetworkSimGroupGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureModSimWorkbenchInstanceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureModSimWorkbenchInstanceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureModSimWorkbenchInstancesChamberGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureModSimWorkbenchInstancesChamberGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureModSimWorkbenchInstancesChambersConnectorGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureModSimWorkbenchInstancesChambersConnectorGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureModSimWorkbenchInstancesChambersWorkloadGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureModSimWorkbenchInstancesChambersWorkloadGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureModSimWorkbenchWorkbenchesChamberGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureModSimWorkbenchWorkbenchesChamberGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureModSimWorkbenchWorkbenchesChambersConnectorGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureModSimWorkbenchWorkbenchesChambersConnectorGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureModSimWorkbenchWorkbenchesChambersStorageGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureModSimWorkbenchWorkbenchesChambersStorageGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureModSimWorkbenchWorkbenchesChambersWorkloadGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureModSimWorkbenchWorkbenchesChambersWorkloadGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureModSimWorkbenchWorkbenchesSharedStorageGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureModSimWorkbenchWorkbenchesSharedStorageGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureModSimWorkbenchWorkbenchGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureModSimWorkbenchWorkbenchGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureMonitorAccountGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMonitorAccountGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureMonitorPipelineGroupGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMonitorPipelineGroupGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureMySqlDiscoveryMySqlSiteGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMySqlDiscoveryMySqlSiteGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureMySqlFlexibleServerAadOnlyAuthenticationDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMySqlFlexibleServerAadOnlyAuthenticationDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureMySqlFlexibleServerAuditLogConnectionEventDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMySqlFlexibleServerAuditLogConnectionEventDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureMySqlFlexibleServerAuditLogDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMySqlFlexibleServerAuditLogDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureMySqlFlexibleServerInsecureTlsProtocolRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMySqlFlexibleServerInsecureTlsProtocolRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureMySqlServer extends AzureResourceGroupResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        public aadAuthenticationAdministratorPrincipalIdReference: Optional<string>,
        public administratorUserName: string,
        public allowAnyAzureInternalIpAddress: boolean,
        public authenticationType: AzureMySqlServerAuthenticationType,
        public endpoint: string,
        public firewallIpAddressRangeRules: AzureResourceFirewallIpAddressRangeRule[],
        public httpsOnly: Optional<boolean>,
        public managedIdentityExists: boolean,
        public nameToConfigurationMap: Optional<Dictionary<AzureMySqlServerConfiguration>>,
        public publicNetworkAccess: boolean,
        public skuTier: AzureMySqlServerSkuTier,
        public status: AzureMySqlServerStatus,
        public systemManagedIdentityServicePrincipalId: Optional<string>,
        public tlsMinVersion: Optional<string>,
        public userManagedIdentityPaths: string[],
        public version: string,
        public virtualNetworkPathToFirewallSubnetRulesMap: Dictionary<AzureResourceFirewallVirtualNetworkSubnetRule[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId);
    }
}

export class AzureMySqlServerHttpsOnlyDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMySqlServerHttpsOnlyDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureMySqlServerModel extends AzureResourceGroupResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        public networkResourcePathToIdReferencesMap: Dictionary<string>,
        public userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureMySqlServerWideRangeInboundRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMySqlServerWideRangeInboundRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureMySqlSingleServer extends AzureMySqlServer implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        aadAuthenticationAdministratorPrincipalIdReference: Optional<string>,
        administratorUserName: string,
        allowAnyAzureInternalIpAddress: boolean,
        authenticationType: AzureMySqlServerAuthenticationType,
        endpoint: string,
        firewallIpAddressRangeRules: AzureResourceFirewallIpAddressRangeRule[],
        httpsOnly: Optional<boolean>,
        managedIdentityExists: boolean,
        nameToConfigurationMap: Optional<Dictionary<AzureMySqlServerConfiguration>>,
        publicNetworkAccess: boolean,
        skuTier: AzureMySqlServerSkuTier,
        status: AzureMySqlServerStatus,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        tlsMinVersion: Optional<string>,
        userManagedIdentityPaths: string[],
        version: string,
        virtualNetworkPathToFirewallSubnetRulesMap: Dictionary<AzureResourceFirewallVirtualNetworkSubnetRule[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMySqlSingleServer",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            aadAuthenticationAdministratorPrincipalIdReference,
            administratorUserName,
            allowAnyAzureInternalIpAddress,
            authenticationType,
            endpoint,
            firewallIpAddressRangeRules,
            httpsOnly,
            managedIdentityExists,
            nameToConfigurationMap,
            publicNetworkAccess,
            skuTier,
            status,
            systemManagedIdentityServicePrincipalId,
            tlsMinVersion,
            userManagedIdentityPaths,
            version,
            virtualNetworkPathToFirewallSubnetRulesMap);
    }
}

export class AzureMySqlSingleServerModel extends AzureMySqlServerModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        networkResourcePathToIdReferencesMap: Dictionary<string>,
        userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMySqlSingleServerModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            networkResourcePathToIdReferencesMap,
            userManagedIdentityServicePrincipalIds);
    }
}

export class AzureNeonPostgresOrganizationGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNeonPostgresOrganizationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetAppNetAppAccountGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetAppNetAppAccountGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetAppNetAppAccountsBackupPolicyGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetAppNetAppAccountsBackupPolicyGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetAppNetAppAccountsBackupVaultGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetAppNetAppAccountsBackupVaultGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetAppNetAppAccountsCapacityPoolGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetAppNetAppAccountsCapacityPoolGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetAppNetAppAccountsCapacityPoolsVolumeGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetAppNetAppAccountsCapacityPoolsVolumeGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetAppNetAppAccountsSnapshotPolicyGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetAppNetAppAccountsSnapshotPolicyGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkAccessManagementRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkAccessManagementRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureNetworkApplicationGateway extends AzureResourceGroupResource implements ITypeNameObject, IAzureNetworkedResource, IAzureNetworkEvaluationResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        public applicationSecurityGroupIds: string[],
        public backendRules: AzureNetworkApplicationGatewayBackendRule[],
        public instanceCount: Optional<number>,
        public interfaceNetworkSecurityGroupIds: string[],
        public privateIpAddresses: string[],
        public publicIpAddressIds: string[],
        public redirectRules: AzureNetworkApplicationGatewayRedirectRule[],
        public sku: string,
        public subnetIds: string[],
        public subnetNetworkSecurityGroupIds: string[],
        public tlsMinVersion: Optional<string>,
        public virtualNetworkId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkApplicationGateway",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId);
    }
}

export class AzureNetworkApplicationGatewayGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkApplicationGatewayGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkApplicationGatewayModel extends AzureResourceGroupResourceModel implements ITypeNameObject, IUdmObjectModel, IAzureNetworkedResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkApplicationGatewayModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureNetworkApplicationGatewayWebApplicationFirewallPolicyGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkApplicationGatewayWebApplicationFirewallPolicyGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkApplicationSecurityGroup extends AzureResourceGroupResource implements ITypeNameObject, IResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkApplicationSecurityGroup",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId);
    }
}

export class AzureNetworkApplicationSecurityGroupGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkApplicationSecurityGroupGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkApplicationSecurityGroupModel extends AzureResourceGroupResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        public virtualMachineIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkApplicationSecurityGroupModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureNetworkAzurefirewallGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkAzurefirewallGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkAzurefirewallGenericResourceModel extends AzureResourcesGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkAzurefirewallGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            userManagedIdentityServicePrincipalIds);
    }
}

export class AzureNetworkBastionHostGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkBastionHostGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkBastionHostGenericResourceModel extends AzureResourcesGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkBastionHostGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            userManagedIdentityServicePrincipalIds);
    }
}

export class AzureNetworkCloudBareMetalMachineGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkCloudBareMetalMachineGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkCloudCloudServicesNetworkGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkCloudCloudServicesNetworkGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkCloudClusterGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkCloudClusterGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkCloudClusterManagerGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkCloudClusterManagerGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkCloudClustersBareMetalMachineKeySetGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkCloudClustersBareMetalMachineKeySetGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkCloudClustersBmcKeySetGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkCloudClustersBmcKeySetGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkCloudClustersMetricsConfigurationGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkCloudClustersMetricsConfigurationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkCloudEdgeClusterGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkCloudEdgeClusterGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkCloudEdgeClustersNodeGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkCloudEdgeClustersNodeGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkCloudKubernetesClusterGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkCloudKubernetesClusterGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkCloudKubernetesClustersAgentPoolGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkCloudKubernetesClustersAgentPoolGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkCloudKubernetesClustersFeatureGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkCloudKubernetesClustersFeatureGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkCloudL2NetworkGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkCloudL2NetworkGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkCloudL3NetworkGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkCloudL3NetworkGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkCloudRackGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkCloudRackGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkCloudRegistrationHubGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkCloudRegistrationHubGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkCloudRegistrationHubsImageGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkCloudRegistrationHubsImageGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkCloudRegistrationHubsMachineGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkCloudRegistrationHubsMachineGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkCloudStorageApplianceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkCloudStorageApplianceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkCloudTrunkedNetworkGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkCloudTrunkedNetworkGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkCloudVirtualMachineGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkCloudVirtualMachineGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkCloudVirtualMachinesConsoleGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkCloudVirtualMachinesConsoleGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkCloudVolumeGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkCloudVolumeGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkConnectionGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkConnectionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkCustomIpPrefixGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkCustomIpPrefixGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkDdosCustomPolicyGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkDdosCustomPolicyGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkDdosProtectionPlanGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkDdosProtectionPlanGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkDnsForwardingRulesetGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkDnsForwardingRulesetGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkDnsResolverDomainListGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkDnsResolverDomainListGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkDnsResolverGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkDnsResolverGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkDnsResolverPoliciesDnsSecurityRuleGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkDnsResolverPoliciesDnsSecurityRuleGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkDnsResolverPoliciesVirtualNetworkLinkGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkDnsResolverPoliciesVirtualNetworkLinkGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkDnsResolverPolicyGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkDnsResolverPolicyGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkDnsResolversInboundEndpointGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkDnsResolversInboundEndpointGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkDnsResolversOutboundEndpointGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkDnsResolversOutboundEndpointGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkDnsZone extends AzureResourceGroupResource implements ITypeNameObject, IResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkDnsZone",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId);
    }
}

export class AzureNetworkDnszoneGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkDnszoneGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkDnsZoneModel extends AzureResourceGroupResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        public recordSetCount: number,
        public recordSetIds: string[],
        public recordSetIdToDnsRecordEntityIdsMap: Dictionary<string[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkDnsZoneModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureNetworkDnsZoneRecordSetResource extends AzureResourceGroupResource implements ITypeNameObject, IAzureEntity, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        public values: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId);
    }
}

export class AzureNetworkDnsZoneRecordSetResourceModel extends AzureResourceGroupResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureNetworkDnsZoneRecordSetSoa extends AzureNetworkDnsZoneRecordSetResource implements ITypeNameObject, IAzureEntity, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        values: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkDnsZoneRecordSetSoa",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            values);
    }
}

export class AzureNetworkDnsZoneRecordSetSoaModel extends AzureNetworkDnsZoneRecordSetResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkDnsZoneRecordSetSoaModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureNetworkDnsZoneRecordSetSrv extends AzureNetworkDnsZoneRecordSetResource implements ITypeNameObject, IAzureEntity, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        values: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkDnsZoneRecordSetSrv",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            values);
    }
}

export class AzureNetworkDnsZoneRecordSetSrvModel extends AzureNetworkDnsZoneRecordSetResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkDnsZoneRecordSetSrvModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureNetworkDnsZoneRecordSetTxt extends AzureNetworkDnsZoneRecordSetResource implements ITypeNameObject, IAzureEntity, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        values: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkDnsZoneRecordSetTxt",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            values);
    }
}

export class AzureNetworkDnsZoneRecordSetTxtModel extends AzureNetworkDnsZoneRecordSetResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkDnsZoneRecordSetTxtModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureNetworkDnszonesAGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkDnszonesAGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkDnszonesCnameGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkDnszonesCnameGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkDscpConfigurationGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkDscpConfigurationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkExpressRouteCircuitGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkExpressRouteCircuitGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkExpressRouteCrossConnectionGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkExpressRouteCrossConnectionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkExpressRouteGatewayGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkExpressRouteGatewayGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkExpressRoutePortGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkExpressRoutePortGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkFirewallPoliciesRuleGroupGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkFirewallPoliciesRuleGroupGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkFirewallPolicyGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkFirewallPolicyGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkFirewallPolicyGenericResourceModel extends AzureResourcesGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkFirewallPolicyGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            userManagedIdentityServicePrincipalIds);
    }
}

export class AzureNetworkFrontDoorGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkFrontDoorGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkFrontDoorGenericResourceModel extends AzureResourcesGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkFrontDoorGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            userManagedIdentityServicePrincipalIds);
    }
}

export class AzureNetworkFrontDoorWebApplicationFirewallPolicyGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkFrontDoorWebApplicationFirewallPolicyGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkFrontDoorWebApplicationFirewallPolicyGenericResourceModel extends AzureResourcesGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkFrontDoorWebApplicationFirewallPolicyGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            userManagedIdentityServicePrincipalIds);
    }
}

export class AzureNetworkFunctionAzureTrafficCollectorGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkFunctionAzureTrafficCollectorGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkFunctionAzureTrafficCollectorsCollectorPolicyGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkFunctionAzureTrafficCollectorsCollectorPolicyGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkFunctionMeshVpnGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkFunctionMeshVpnGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkFunctionVpnBranchGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkFunctionVpnBranchGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkGatewayLoadBalancerAliasGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkGatewayLoadBalancerAliasGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkIpAllocationGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkIpAllocationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkIpGroupGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkIpGroupGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkIpGroupGenericResourceModel extends AzureResourcesGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkIpGroupGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            userManagedIdentityServicePrincipalIds);
    }
}

export class AzureNetworkLoadBalancer extends AzureResourceGroupResource implements ITypeNameObject, IAzureDnsRecordResource, IAzureNetworkEvaluationResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        public dnsNames: string[],
        public inboundNatRules: AzureNetworkLoadBalancerInboundNatRule[],
        public loadBalancingRules: AzureNetworkLoadBalancerLoadBalancingRule[],
        public privateIpAddresses: string[],
        public publicIpAddresses: string[],
        public publicIpAddressIds: string[],
        public publicIpPrefixIds: string[],
        public sku: AzureNetworkLoadBalancerSku,
        public type: AzureNetworkLoadBalancerType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkLoadBalancer",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId);
    }
}

export class AzureNetworkLoadBalancerGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkLoadBalancerGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkLoadBalancerModel extends AzureResourceGroupResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        public dnsZoneIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkLoadBalancerModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureNetworkLocalnetworkgatewayGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkLocalnetworkgatewayGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkLocalnetworkgatewayGenericResourceModel extends AzureResourcesGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkLocalnetworkgatewayGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            userManagedIdentityServicePrincipalIds);
    }
}

export class AzureNetworkNatGatewayGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkNatGatewayGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkNatGatewayGenericResourceModel extends AzureResourcesGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkNatGatewayGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            userManagedIdentityServicePrincipalIds);
    }
}

export class AzureNetworkNetworkExperimentProfileGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkNetworkExperimentProfileGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkNetworkIntentPolicyGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkNetworkIntentPolicyGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkNetworkInterface extends AzureResourceGroupResource implements ITypeNameObject, IAzureNetworkedResource, IAzureTagResource, IAzureNetworkInterface {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        public applicationSecurityGroupIds: string[],
        public dnsNames: string[],
        public interfaceNetworkSecurityGroupIds: string[],
        public ipConfigurations: AzureNetworkNetworkInterfaceIpConfiguration[],
        public macAddress: Optional<string>,
        public networkSecurityGroupId: Optional<string>,
        public privateIpAddresses: string[],
        public publicIpAddresses: string[],
        public publicIpAddressIds: string[],
        public subnetId: string,
        public subnetIds: string[],
        public subnetNetworkSecurityGroupIds: string[],
        public virtualMachinePath: Optional<string>,
        public virtualNetworkId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkNetworkInterface",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId);
    }
}

export class AzureNetworkNetworkInterfaceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkNetworkInterfaceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkNetworkInterfaceModel extends AzureResourceGroupResourceModel implements ITypeNameObject, IUdmObjectModel, IAzureNetworkedResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        public dnsZoneIds: string[],
        public virtualMachineId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkNetworkInterfaceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureNetworkNetworkManagerGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkNetworkManagerGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkNetworkManagerGenericResourceModel extends AzureResourcesGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkNetworkManagerGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            userManagedIdentityServicePrincipalIds);
    }
}

export class AzureNetworkNetworkManagersIpamPoolGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkNetworkManagersIpamPoolGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkNetworkManagersVerifierWorkspaceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkNetworkManagersVerifierWorkspaceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkNetworkProfileGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkNetworkProfileGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkNetworkSecurityGroup extends AzureResourceGroupResource implements ITypeNameObject, IResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        public inboundRules: AzureNetworkNetworkSecurityGroupRule[],
        public outboundRules: AzureNetworkNetworkSecurityGroupRule[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkNetworkSecurityGroup",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId);
    }
}

export class AzureNetworkNetworkSecurityGroupGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkNetworkSecurityGroupGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkNetworkSecurityGroupInboundRuleInsecureServiceTagRisk extends AzureNetworkNetworkSecurityGroupInsecureInboundRuleExistsRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        insecureNetworkSecurityGroupRulePaths: string[],
        sensitivePermissionActionSeverityNetworkedResourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkNetworkSecurityGroupInboundRuleInsecureServiceTagRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            insecureNetworkSecurityGroupRulePaths,
            sensitivePermissionActionSeverityNetworkedResourceIds);
    }
}

export class AzureNetworkNetworkSecurityGroupInboundRuleInsecureServiceTagRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkNetworkSecurityGroupInboundRuleInsecureServiceTagRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnyExistsRisk extends AzureNetworkNetworkSecurityGroupInsecureInboundRuleExistsRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        insecureNetworkSecurityGroupRulePaths: string[],
        sensitivePermissionActionSeverityNetworkedResourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            insecureNetworkSecurityGroupRulePaths,
            sensitivePermissionActionSeverityNetworkedResourceIds);
    }
}

export class AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnyRdpExistsRisk extends AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnyExistsRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        insecureNetworkSecurityGroupRulePaths: string[],
        sensitivePermissionActionSeverityNetworkedResourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnyRdpExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            insecureNetworkSecurityGroupRulePaths,
            sensitivePermissionActionSeverityNetworkedResourceIds);
    }
}

export class AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnyRdpExistsRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnyRdpExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnySshExistsRisk extends AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnyExistsRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        insecureNetworkSecurityGroupRulePaths: string[],
        sensitivePermissionActionSeverityNetworkedResourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnySshExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            insecureNetworkSecurityGroupRulePaths,
            sensitivePermissionActionSeverityNetworkedResourceIds);
    }
}

export class AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnySshExistsRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnySshExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnyUdpExistsRisk extends AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnyExistsRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        insecureNetworkSecurityGroupRulePaths: string[],
        sensitivePermissionActionSeverityNetworkedResourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnyUdpExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            insecureNetworkSecurityGroupRulePaths,
            sensitivePermissionActionSeverityNetworkedResourceIds);
    }
}

export class AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnyUdpExistsRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnyUdpExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureNetworkNetworkSecurityGroupModel extends AzureResourceGroupResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        public networkedResourceTypeNameToIdsMap: Dictionary<string[]>,
        public subnetIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkNetworkSecurityGroupModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureNetworkNetworkSecurityPerimeterGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkNetworkSecurityPerimeterGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkNetworkVerifierGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkNetworkVerifierGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkNetworkVirtualApplianceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkNetworkVirtualApplianceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkNetworkWatcherGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkNetworkWatcherGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkNetworkWatchersConnectionMonitorGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkNetworkWatchersConnectionMonitorGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkNetworkWatchersFlowLogGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkNetworkWatchersFlowLogGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkNetworkWatchersLensGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkNetworkWatchersLensGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkNetworkWatchersPingMeshGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkNetworkWatchersPingMeshGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkP2sVpnGatewayGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkP2sVpnGatewayGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkPrivateDnsZone extends AzureResourceGroupResource implements ITypeNameObject, IResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        public virtualNetworkIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkPrivateDnsZone",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId);
    }
}

export class AzureNetworkPrivateDnsZoneGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkPrivateDnsZoneGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkPrivateDnsZoneModel extends AzureResourceGroupResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        public recordSetCount: number,
        public recordSetIds: string[],
        public virtualNetworkIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkPrivateDnsZoneModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureNetworkPrivateDnsZoneRecordSetResource extends AzureResourceGroupResource implements ITypeNameObject, IAzureEntity, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        public values: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId);
    }
}

export class AzureNetworkPrivateDnsZoneRecordSetResourceModel extends AzureResourceGroupResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureNetworkPrivateDnsZoneRecordSetSoa extends AzureNetworkPrivateDnsZoneRecordSetResource implements ITypeNameObject, IAzureEntity, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        values: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkPrivateDnsZoneRecordSetSoa",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            values);
    }
}

export class AzureNetworkPrivateDnsZoneRecordSetSoaModel extends AzureNetworkPrivateDnsZoneRecordSetResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkPrivateDnsZoneRecordSetSoaModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureNetworkPrivateDnsZoneRecordSetSrv extends AzureNetworkPrivateDnsZoneRecordSetResource implements ITypeNameObject, IAzureEntity, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        values: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkPrivateDnsZoneRecordSetSrv",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            values);
    }
}

export class AzureNetworkPrivateDnsZoneRecordSetSrvModel extends AzureNetworkPrivateDnsZoneRecordSetResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkPrivateDnsZoneRecordSetSrvModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureNetworkPrivateDnsZoneRecordSetTxt extends AzureNetworkPrivateDnsZoneRecordSetResource implements ITypeNameObject, IAzureEntity, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        values: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkPrivateDnsZoneRecordSetTxt",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            values);
    }
}

export class AzureNetworkPrivateDnsZoneRecordSetTxtModel extends AzureNetworkPrivateDnsZoneRecordSetResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkPrivateDnsZoneRecordSetTxtModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureNetworkPrivateDnsZonesVirtualNetworkLinkGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkPrivateDnsZonesVirtualNetworkLinkGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkPrivateEndpoint extends AzureResourceGroupResource implements ITypeNameObject, IResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        public attachedResourcePath: string,
        public connectionDescription: string,
        public connectionStatus: AzureNetworkPrivateEndpointConnectionStatus) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkPrivateEndpoint",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId);
    }
}

export class AzureNetworkPrivateEndpointGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkPrivateEndpointGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkPrivateEndpointModel extends AzureResourceGroupResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        public attachedResourceIdReference: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkPrivateEndpointModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureNetworkPrivateEndpointRedirectMapGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkPrivateEndpointRedirectMapGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkPrivateLinkServiceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkPrivateLinkServiceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkPrivateLinkServiceGenericResourceModel extends AzureResourcesGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkPrivateLinkServiceGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            userManagedIdentityServicePrincipalIds);
    }
}

export class AzureNetworkPublicIpAddress extends AzureResourceGroupResource implements ITypeNameObject, IAzurePublicIpAddress, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        public attachedResourcePath: Optional<string>,
        public dnsName: Optional<string>,
        public dnsNames: string[],
        public ipAddress: Optional<string>,
        public publicIpAddresses: string[],
        public sku: AzureNetworkPublicIpAddressSku,
        public type: AzureNetworkPublicIpAddressType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkPublicIpAddress",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId);
    }
}

export class AzureNetworkPublicIpAddressGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkPublicIpAddressGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkPublicIpAddressModel extends AzureResourceGroupResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        public attachedResourceId: Optional<string>,
        public dnsZoneIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkPublicIpAddressModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureNetworkPublicIpPrefix extends AzureResourceGroupResource implements ITypeNameObject, IResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        public publicIpAddressIds: string[],
        public subnet: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkPublicIpPrefix",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId);
    }
}

export class AzureNetworkPublicIpPrefixGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkPublicIpPrefixGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkPublicIpPrefixModel extends AzureResourceGroupResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkPublicIpPrefixModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureNetworkRouteFilterGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkRouteFilterGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkRouteTable extends AzureResourceGroupResource implements ITypeNameObject, IResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        public routes: AzureNetworkRouteTableRoute[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkRouteTable",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId);
    }
}

export class AzureNetworkRouteTableGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkRouteTableGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkRouteTableModel extends AzureResourceGroupResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        public subnetIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkRouteTableModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureNetworkSecurityPartnerProviderGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkSecurityPartnerProviderGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkServiceEndpointPolicyGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkServiceEndpointPolicyGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkTrafficManagerProfileGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkTrafficManagerProfileGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkTrafficManagerProfileGenericResourceModel extends AzureResourcesGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkTrafficManagerProfileGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            userManagedIdentityServicePrincipalIds);
    }
}

export class AzureNetworkVirtualHubGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkVirtualHubGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkVirtualHubsBgpConnectionGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkVirtualHubsBgpConnectionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkVirtualHubsIpConfigurationGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkVirtualHubsIpConfigurationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkVirtualNetwork extends AzureResourceGroupResource implements ITypeNameObject, IResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        public hasNetworkedResources: boolean,
        public subnetIds: string[],
        public subnets: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkVirtualNetwork",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId);
    }
}

export class AzureNetworkVirtualnetworkgatewayGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkVirtualnetworkgatewayGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkVirtualnetworkgatewayGenericResourceModel extends AzureResourcesGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkVirtualnetworkgatewayGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            userManagedIdentityServicePrincipalIds);
    }
}

export class AzureNetworkVirtualNetworkGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkVirtualNetworkGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkVirtualNetworkModel extends AzureResourceGroupResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        public networkedResourceTypeNameToIdsMap: Dictionary<string[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkVirtualNetworkModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureNetworkVirtualNetworkSubnet extends AzureResourceGroupResource implements ITypeNameObject, IAzureEntity, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        public hasNetworkedResources: boolean,
        public networkSecurityGroupId: Optional<string>,
        public routeTableId: Optional<string>,
        public subnets: string[],
        public virtualNetworkId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkVirtualNetworkSubnet",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId);
    }
}

export class AzureNetworkVirtualNetworkSubnetModel extends AzureResourceGroupResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        public networkedResourceTypeNameToIdsMap: Dictionary<string[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkVirtualNetworkSubnetModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureNetworkVirtualNetworkSubnetUnrestrictedInboundAccessRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkVirtualNetworkSubnetUnrestrictedInboundAccessRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureNetworkVirtualNetworkTapGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkVirtualNetworkTapGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkVirtualRouterGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkVirtualRouterGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkVirtualWanGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkVirtualWanGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkVirtualWanGenericResourceModel extends AzureResourcesGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkVirtualWanGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            userManagedIdentityServicePrincipalIds);
    }
}

export class AzureNetworkVpnGatewayGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkVpnGatewayGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkVpnGatewayGenericResourceModel extends AzureResourcesGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkVpnGatewayGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            userManagedIdentityServicePrincipalIds);
    }
}

export class AzureNetworkVpnServerConfigurationGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkVpnServerConfigurationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNetworkVpnsiteGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkVpnsiteGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNewRelicObservabilityMonitorGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNewRelicObservabilityMonitorGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNexusIdentityIdentityControllerGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNexusIdentityIdentityControllerGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNexusIdentityIdentitySetGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNexusIdentityIdentitySetGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNginxNginxplusNginxDeploymentGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNginxNginxplusNginxDeploymentGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNotificationHubsNamespaceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNotificationHubsNamespaceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNotificationHubsNamespaceGenericResourceModel extends AzureResourcesGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNotificationHubsNamespaceGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            userManagedIdentityServicePrincipalIds);
    }
}

export class AzureNotificationHubsNamespacesNotificationHubGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNotificationHubsNamespacesNotificationHubGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNutanixInterfaceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNutanixInterfaceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureNutanixNodeGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNutanixNodeGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureObjectStoreOsNamespaceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureObjectStoreOsNamespaceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureOffApplianceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureOffApplianceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureOffHypervSiteGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureOffHypervSiteGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureOffImportSiteGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureOffImportSiteGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureOffMasterSiteGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureOffMasterSiteGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureOffServerSiteGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureOffServerSiteGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureOffSpringBootSpringbootsiteGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureOffSpringBootSpringbootsiteGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureOffVmwareSiteGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureOffVmwareSiteGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureOpenEnergyPlatformEnergyServiceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureOpenEnergyPlatformEnergyServiceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureOpenShiftBuildConfigModel extends AzureKubernetesNamespaceResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesNamespaceResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureOpenShiftBuildConfigModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType);
    }
}

export class AzureOpenShiftCatalogSourceModel extends AzureKubernetesNamespaceResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesNamespaceResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureOpenShiftCatalogSourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType);
    }
}

export class AzureOpenShiftDeploymentConfigModel extends AzureKubernetesWorkloadResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesWorkloadResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        aadServicePrincipalIds: string[],
        agentPoolIds: string[],
        containerImageIdReferences: string[],
        containerImageRawUrlToDataMap: Dictionary<ContainerImageResourceStateContainerImageData>,
        containerImageScanStatus: Optional<ContainerImageDataScanStatus>,
        containerImageVulnerabilities: VulnerabilityData[],
        controllerResourceReference: Optional<EntitySearchableIdReference>,
        serviceAccountReference: EntitySearchableIdReference) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureOpenShiftDeploymentConfigModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            aadServicePrincipalIds,
            agentPoolIds,
            containerImageIdReferences,
            containerImageRawUrlToDataMap,
            containerImageScanStatus,
            containerImageVulnerabilities,
            controllerResourceReference,
            serviceAccountReference);
    }
}

export class AzureOpenShiftImageStreamModel extends AzureKubernetesNamespaceResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesNamespaceResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureOpenShiftImageStreamModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType);
    }
}

export class AzureOpenShiftProject extends AzureKubernetesResource<OpenShiftProjectData> implements ITypeNameObject, IAzureEntity, IOpenShiftProject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: OpenShiftProjectData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureOpenShiftProject",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class AzureOpenShiftRouteModel extends AzureKubernetesNamespaceResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesNamespaceResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureOpenShiftRouteModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType);
    }
}

export class AzureOpenShiftSecurityContextConstraint extends AzureKubernetesResource<OpenShiftSecurityContextConstraintData> implements ITypeNameObject, IAzureEntity, IOpenShiftSecurityContextConstraint {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: OpenShiftSecurityContextConstraintData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureOpenShiftSecurityContextConstraint",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class AzureOpenShiftTemplateModel extends AzureKubernetesNamespaceResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesNamespaceResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureOpenShiftTemplateModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType);
    }
}

export class AzureOperationalInsightsClusterGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureOperationalInsightsClusterGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureOperationalInsightsQuerypackGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureOperationalInsightsQuerypackGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureOperationalInsightsWorkspaceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureOperationalInsightsWorkspaceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureOperationsManagementSolutionGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureOperationsManagementSolutionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureOperationsManagementViewGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureOperationsManagementViewGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureOracleDatabaseAutonomousDatabaseGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureOracleDatabaseAutonomousDatabaseGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureOracleDatabaseCloudExadataInfrastructureGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureOracleDatabaseCloudExadataInfrastructureGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureOracleDatabaseCloudVmClusterGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureOracleDatabaseCloudVmClusterGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureOracleDiscoveryOracleSiteGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureOracleDiscoveryOracleSiteGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureOrbitalCloudAccessRouterGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureOrbitalCloudAccessRouterGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureOrbitalContactProfileGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureOrbitalContactProfileGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureOrbitalEdgeSiteGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureOrbitalEdgeSiteGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureOrbitalGeoCatalogGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureOrbitalGeoCatalogGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureOrbitalGroundStationGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureOrbitalGroundStationGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureOrbitalL2ConnectionGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureOrbitalL2ConnectionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureOrbitalSdwanControllerGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureOrbitalSdwanControllerGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureOrbitalSpacecraftGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureOrbitalSpacecraftGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureOrbitalTerminalGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureOrbitalTerminalGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzurePaloAltoNetworksCloudngfwFirewallGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePaloAltoNetworksCloudngfwFirewallGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzurePaloAltoNetworksCloudngfwLocalRulestackGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePaloAltoNetworksCloudngfwLocalRulestackGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzurePeeringPeeringGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePeeringPeeringGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzurePeeringPeeringGenericResourceModel extends AzureResourcesGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePeeringPeeringGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            userManagedIdentityServicePrincipalIds);
    }
}

export class AzurePeeringPeeringServiceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePeeringPeeringServiceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzurePermissionManagementRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePermissionManagementRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzurePkiPkiGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePkiPkiGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzurePlaywrightServiceAccountGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePlaywrightServiceAccountGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzurePortalDashboardGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePortalDashboardGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzurePostgreSqlFlexibleServerAadOnlyAuthenticationDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePostgreSqlFlexibleServerAadOnlyAuthenticationDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzurePostgreSqlServer extends AzureResourceGroupResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        public aadAuthenticationAdministratorPrincipalIdReferences: string[],
        public administratorUserName: Optional<string>,
        public allowAnyAzureInternalIpAddress: boolean,
        public authenticationType: AzurePostgreSqlServerAuthenticationType,
        public endpoint: string,
        public firewallIpAddressRangeRules: AzureResourceFirewallIpAddressRangeRule[],
        public httpsOnly: Optional<boolean>,
        public managedIdentityExists: boolean,
        public nameToConfigurationMap: Optional<Dictionary<AzurePostgreSqlServerConfiguration>>,
        public publicNetworkAccess: boolean,
        public skuTier: AzurePostgreSqlServerSkuTier,
        public status: AzurePostgreSqlServerStatus,
        public systemManagedIdentityServicePrincipalId: Optional<string>,
        public tlsMinVersion: Optional<string>,
        public userManagedIdentityPaths: string[],
        public version: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId);
    }
}

export class AzurePostgreSqlServerConnectionThrottlingDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePostgreSqlServerConnectionThrottlingDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzurePostgreSqlServerHttpsOnlyDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePostgreSqlServerHttpsOnlyDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzurePostgreSqlServerLogCheckpointsDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePostgreSqlServerLogCheckpointsDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzurePostgreSqlServerLogConnectionsDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePostgreSqlServerLogConnectionsDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzurePostgreSqlServerLogDisconnectionsDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePostgreSqlServerLogDisconnectionsDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzurePostgreSqlServerLogRetentionRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePostgreSqlServerLogRetentionRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzurePostgreSqlServerModel extends AzureResourceGroupResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        public userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzurePostgreSqlServerWideRangeInboundRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePostgreSqlServerWideRangeInboundRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzurePostgreSqlSingleServer extends AzurePostgreSqlServer implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        aadAuthenticationAdministratorPrincipalIdReferences: string[],
        administratorUserName: Optional<string>,
        allowAnyAzureInternalIpAddress: boolean,
        authenticationType: AzurePostgreSqlServerAuthenticationType,
        endpoint: string,
        firewallIpAddressRangeRules: AzureResourceFirewallIpAddressRangeRule[],
        httpsOnly: Optional<boolean>,
        managedIdentityExists: boolean,
        nameToConfigurationMap: Optional<Dictionary<AzurePostgreSqlServerConfiguration>>,
        publicNetworkAccess: boolean,
        skuTier: AzurePostgreSqlServerSkuTier,
        status: AzurePostgreSqlServerStatus,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        tlsMinVersion: Optional<string>,
        userManagedIdentityPaths: string[],
        version: string,
        public logRetentionDays: Optional<number>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePostgreSqlSingleServer",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            aadAuthenticationAdministratorPrincipalIdReferences,
            administratorUserName,
            allowAnyAzureInternalIpAddress,
            authenticationType,
            endpoint,
            firewallIpAddressRangeRules,
            httpsOnly,
            managedIdentityExists,
            nameToConfigurationMap,
            publicNetworkAccess,
            skuTier,
            status,
            systemManagedIdentityServicePrincipalId,
            tlsMinVersion,
            userManagedIdentityPaths,
            version);
    }
}

export class AzurePostgreSqlSingleServerModel extends AzurePostgreSqlServerModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePostgreSqlSingleServerModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            userManagedIdentityServicePrincipalIds);
    }
}

export class AzurePowerBiDedicatedAutoScaleVCoreGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePowerBiDedicatedAutoScaleVCoreGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzurePowerBiDedicatedCapacityGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePowerBiDedicatedCapacityGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzurePowerBiPrivateLinkServicesForPowerBiGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePowerBiPrivateLinkServicesForPowerBiGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzurePowerBiTenantGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePowerBiTenantGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzurePowerBiWorkspaceCollectionGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePowerBiWorkspaceCollectionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzurePowerBiWorkspaceCollectionGenericResourceModel extends AzureResourcesGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePowerBiWorkspaceCollectionGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            userManagedIdentityServicePrincipalIds);
    }
}

export class AzurePowerPlatformAccountGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePowerPlatformAccountGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzurePowerPlatformEnterprisePolicyGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePowerPlatformEnterprisePolicyGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzurePrincipalAllowedResourcePermissionRisk extends AzurePrincipalResourcePermissionRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        principalResourcePermissionItems: AzurePrincipalResourcePermissionRiskItem[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePrincipalAllowedResourcePermissionRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            principalResourcePermissionItems);
    }
}

export class AzurePrincipalAllowedResourcePermissionRiskModel extends AzurePrincipalResourcePermissionRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzurePrincipalAllowedResourcePermissionRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        permissionActions: string[],
        principalIds: string[],
        resourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePrincipalAllowedResourcePermissionRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            permissionActions,
            principalIds,
            resourceIds);
    }
}

export class AzurePrincipalNotAllowedResourcePermissionRisk extends AzurePrincipalResourcePermissionRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        principalResourcePermissionItems: AzurePrincipalResourcePermissionRiskItem[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePrincipalNotAllowedResourcePermissionRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            principalResourcePermissionItems);
    }
}

export class AzurePrincipalNotAllowedResourcePermissionRiskModel extends AzurePrincipalResourcePermissionRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzurePrincipalNotAllowedResourcePermissionRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        permissionActions: string[],
        principalIds: string[],
        resourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePrincipalNotAllowedResourcePermissionRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            permissionActions,
            principalIds,
            resourceIds);
    }
}

export class AzurePrincipalNotAllowedResourceRoleDefinitionRiskPolicyConfiguration extends CustomRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        description: Optional<string>,
        name: string,
        severity: Severity,
        updateTime: string,
        public excludePrincipalMatchConditions: EntityMatchCondition[],
        public excludeScopeResourceMatchConditions: EntityMatchCondition[],
        public principalMatchConditions: EntityMatchCondition[],
        public roleDefinitions: AzurePrincipalNotAllowedResourceRoleDefinitionRiskPolicyConfigurationRoleDefinitions,
        public scopeResourceMatchConditions: EntityMatchCondition[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePrincipalNotAllowedResourceRoleDefinitionRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            description,
            name,
            severity,
            updateTime);
    }
}

export class AzurePrincipalResourcePermissionRiskPolicyConfiguration extends CustomRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        description: Optional<string>,
        name: string,
        severity: Severity,
        updateTime: string,
        public actionNamePatterns: Optional<string[]>,
        public actionRiskCategories: Optional<ActionRiskCategory[]>,
        public actions: Optional<string[]>,
        public principalBuiltInAttributeTypeNames: Optional<string[]>,
        public principalIds: Optional<string[]>,
        public resourceBuiltInAttributeTypeNames: Optional<string[]>,
        public resourceCustomAttributeDefinitionIds: Optional<string[]>,
        public resourceIds: Optional<string[]>,
        public resourceTags: Optional<ResourceTag[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            description,
            name,
            severity,
            updateTime);
    }
}

export class AzureProfessionalServiceResourceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureProfessionalServiceResourceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureProgrammableConnectivityGatewayGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureProgrammableConnectivityGatewayGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureProgrammableConnectivityOperatorApiConnectionGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureProgrammableConnectivityOperatorApiConnectionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureProgrammableConnectivityOperatorConnectionGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureProgrammableConnectivityOperatorConnectionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureProviderHubProviderMonitorSettingGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureProviderHubProviderMonitorSettingGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzurePurviewAccountGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePurviewAccountGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureQuantumWorkspaceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureQuantumWorkspaceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureQuantumWorkspaceGenericResourceModel extends AzureResourcesGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureQuantumWorkspaceGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            userManagedIdentityServicePrincipalIds);
    }
}

export class AzureQumuloStorageFileSystemGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureQumuloStorageFileSystemGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureRecommendationsServiceAccountGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureRecommendationsServiceAccountGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureRecommendationsServiceAccountGenericResourceModel extends AzureResourcesGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureRecommendationsServiceAccountGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            userManagedIdentityServicePrincipalIds);
    }
}

export class AzureRecommendationsServiceAccountsModelingGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureRecommendationsServiceAccountsModelingGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureRecommendationsServiceAccountsServiceEndpointGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureRecommendationsServiceAccountsServiceEndpointGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureRecoveryServicesVaultGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureRecoveryServicesVaultGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureRecoveryServicesVaultGenericResourceModel extends AzureResourcesGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureRecoveryServicesVaultGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            userManagedIdentityServicePrincipalIds);
    }
}

export class AzureRedHatOpenShiftOpenShiftClusterGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureRedHatOpenShiftOpenShiftClusterGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureRedHatOpenShiftOpenShiftClusterGenericResourceModel extends AzureResourcesGenericResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureRedHatOpenShiftOpenShiftClusterGenericResourceModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            userManagedIdentityServicePrincipalIds);
    }
}

export class AzureRelayNamespaceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureRelayNamespaceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureResourceCodeResourceNotExistsRiskPolicyConfiguration extends ResourceCodeResourceNotExistsRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        description: Optional<string>,
        name: string,
        severity: Severity,
        updateTime: string,
        allResources: boolean,
        resourceBuiltInAttributeTypeNames: Optional<string[]>,
        resourceCustomAttributeDefinitionIds: Optional<string[]>,
        resourceIds: Optional<string[]>,
        resourceTags: Optional<ResourceTag[]>,
        resourceTypeNames: Optional<string[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureResourceCodeResourceNotExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            description,
            name,
            severity,
            updateTime,
            allResources,
            resourceBuiltInAttributeTypeNames,
            resourceCustomAttributeDefinitionIds,
            resourceIds,
            resourceTags,
            resourceTypeNames);
    }
}

export class AzureResourceConnectorApplianceGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureResourceConnectorApplianceGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureResourceGraphQueryGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureResourceGraphQueryGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureResourceInboundExternalPortRiskPolicyConfiguration extends CustomRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        description: Optional<string>,
        name: string,
        severity: Severity,
        updateTime: string,
        public anyIpV4AddressSubnet: boolean,
        public destinationNetworkScopes: DestinationNetworkScope[],
        public exactSubnets: Optional<string[]>,
        public inboundAccessScope: Optional<NetworkAccessScope>,
        public overlapSubnets: Optional<string[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureResourceInboundExternalPortRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            description,
            name,
            severity,
            updateTime);
    }
}

export class AzureResourceManagerDeploymentScriptGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureResourceManagerDeploymentScriptGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureResourceManagerMoboBrokerGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureResourceManagerMoboBrokerGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureResourceManagerTemplateSpecGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureResourceManagerTemplateSpecGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureResourceManagerTemplateSpecsVersionGenericResource extends AzureResourcesGenericResource implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IGenericResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        managedIdentityExists: boolean,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureResourceManagerTemplateSpecsVersionGenericResource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            managedIdentityExists,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths);
    }
}

export class AzureResourceTagNotExistsRiskPolicyConfiguration extends ResourceTagNotExistsRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        description: Optional<string>,
        name: string,
        severity: Severity,
        updateTime: string,
        allResources: boolean,
        existsResourceTagKeys: Optional<string[]>,
        existsResourceTagPattern: Optional<ResourceTagPattern>,
        existsResourceTags: Optional<ResourceTag[]>,
        resourceBuiltInAttributeTypeNames: Optional<string[]>,
        resourceCustomAttributeDefinitionIds: Optional<string[]>,
        resourceIds: Optional<string[]>,
        resourceTags: Optional<ResourceTag[]>,
        resourceTypeNames: Optional<string[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureResourceTagNotExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            description,
            name,
            severity,
            updateTime,
            allResources,
            existsResourceTagKeys,
            existsResourceTagPattern,
            existsResourceTags,
            resourceBuiltInAttributeTypeNames,
            resourceCustomAttributeDefinitionIds,
            resourceIds,
            resourceTags,
            resourceTypeNames);
    }
}

export class AzureResourceTagSecretExistsRiskPolicyConfiguration extends SecretExistsRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        exclusionSecretPatterns: Optional<SecretExistsRiskPolicyConfigurationSecretExclusionSecretPattern[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureResourceTagSecretExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            exclusionSecretPatterns);
    }
}

export class AzureRoleAssignmentEligibilityConfiguration extends PermissionAssignmentEligibilityConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        approval: Optional<PermissionEligibilityConfigurationApproval>,
        description: Optional<string>,
        expirationTimeFrame: string,
        name: string,
        principalTenantId: string,
        reasonRequired: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureRoleAssignmentEligibilityConfiguration",
            scopeId,
            approval,
            description,
            expirationTimeFrame,
            name,
            principalTenantId,
            reasonRequired);
    }
}

export class AzureRoleAssignmentEligibilityModel extends PermissionAssignmentEligibilityModel implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        public configuration: AzureRoleAssignmentEligibilityConfiguration,
        scopeId: string,
        state: Optional<SystemEntityState>,
        approverPrincipalIdReferences: string[],
        existingGranteePrincipalIds: string[],
        granteePrincipalIdReferences: string[],
        levelToApproverPrincipalIdReferencesMap: Dictionary<string[]>,
        levelToExistingApproverPrincipalIdsMap: Dictionary<string[]>,
        permissionRequestScopeIds: string[],
        public existingRoleDefinitionIds: string[],
        public roleDefinitionIdReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureRoleAssignmentEligibilityModel",
            configuration,
            scopeId,
            state,
            approverPrincipalIdReferences,
            existingGranteePrincipalIds,
            granteePrincipalIdReferences,
            levelToApproverPrincipalIdReferencesMap,
            levelToExistingApproverPrincipalIdsMap,
            permissionRequestScopeIds);
    }
}

export class AzureSeverePermissionPrincipalRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSeverePermissionPrincipalRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureSqlServerAadAuthenticationAdministratorPrincipalNotExistsRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSqlServerAadAuthenticationAdministratorPrincipalNotExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureSqlServerAadOnlyAuthenticationDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSqlServerAadOnlyAuthenticationDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureSqlServerDatabaseEncryptionDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSqlServerDatabaseEncryptionDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureSqlServerDefaultEncryptionRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSqlServerDefaultEncryptionRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureSqlServerEventDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSqlServerEventDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureSqlServerEventRetentionTimeFrameRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        public eventRetentionTimeFrame: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSqlServerEventRetentionTimeFrameRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureSqlServerSecurityAlertPolicyDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSqlServerSecurityAlertPolicyDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureSqlServerWideRangeInboundRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureSqlServerWideRangeInboundRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureStorageStorageAccountAllowAnyAzureTrustedServicesNetworkAccessDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountAllowAnyAzureTrustedServicesNetworkAccessDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureStorageStorageAccountBlobContainer extends AzureStorageStorageAccountResource implements ITypeNameObject, IAzureTagResource, IObjectStore {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        storageAccountId: string,
        public defaultBlobEncryptionScope: string,
        public effectivePublicAccess: AzureStorageStorageAccountBlobContainerPublicAccess,
        public leaseState: AzureStorageStorageAccountBlobContainerLeaseState,
        public publicAccess: AzureStorageStorageAccountBlobContainerPublicAccess,
        public storageAccountPublicNetworkAccess: boolean,
        public tagsSynced: boolean,
        public updateTime: string,
        public url: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountBlobContainer",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            storageAccountId);
    }
}

export class AzureStorageStorageAccountBlobContainerModel extends AzureStorageStorageAccountResourceModel implements ITypeNameObject, IUdmObjectModel, IDataAnalysisResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        public dataAnalysisError: Optional<DataAnalysisResourceModelDataAnalysisError>,
        public dataAnalysisPriorityScanRequested: boolean,
        public dataAnalysisResourceSensitivity: Optional<DataAnalysisSensitivity>,
        public dataAnalysisStatus: DataAnalysisResourceModelDataAnalysisStatus,
        public dataAnalysisStatusSortValue: number,
        public dataCategoryToDataSegmentCountMap: Optional<Dictionary<number>>,
        public dataCategoryToSensitivityMap: Optional<Dictionary<DataSensitivity>>,
        public dataClassifierIdToDataSegmentCountMap: Optional<Dictionary<number>>,
        public dataLastModified: Optional<string>,
        public dataSensitivities: Optional<DataSensitivity[]>,
        public dataSensitivityToDataSegmentCountMap: Optional<Dictionary<number>>,
        public highestDataSensitivity: Optional<DataSensitivity>,
        public scannedDataSegmentCount: Optional<number>,
        public scanTime: Optional<string>,
        public sensitiveDataSegmentCount: Optional<number>,
        public storageSize: Optional<number>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountBlobContainerModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureStorageStorageAccountBlobContainerPublicAccessExistsRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountBlobContainerPublicAccessExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureStorageStorageAccountBlobServiceDiagnosticSettingsLogCategoryNotEnabledRisk extends AzureStorageStorageAccountServiceDiagnosticSettingsLogCategoryNotEnabledRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountBlobServiceDiagnosticSettingsLogCategoryNotEnabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureStorageStorageAccountBlobServiceDiagnosticSettingsLogCategoryNotEnabledRiskModel extends AzureStorageStorageAccountServiceDiagnosticSettingsLogCategoryNotEnabledRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureStorageStorageAccountBlobServiceDiagnosticSettingsLogCategoryNotEnabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountBlobServiceDiagnosticSettingsLogCategoryNotEnabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureStorageStorageAccountBlobServiceDiagnosticSettingsLogCategoryNotEnabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountBlobServiceDiagnosticSettingsLogCategoryNotEnabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureStorageStorageAccountDefaultEncryptionRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountDefaultEncryptionRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureStorageStorageAccountFileShare extends AzureStorageStorageAccountResource implements ITypeNameObject, IAzureEntity, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        storageAccountId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountFileShare",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            storageAccountId);
    }
}

export class AzureStorageStorageAccountFileShareModel extends AzureStorageStorageAccountResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountFileShareModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureStorageStorageAccountHttpsOnlyDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountHttpsOnlyDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureStorageStorageAccountInfrastructureEncryptionDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountInfrastructureEncryptionDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureStorageStorageAccountInsecureTlsProtocolRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountInsecureTlsProtocolRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureStorageStorageAccountPrivateEndpointNotExistsRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountPrivateEndpointNotExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureStorageStorageAccountQueue extends AzureStorageStorageAccountResource implements ITypeNameObject, IAzureEntity, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        storageAccountId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountQueue",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            storageAccountId);
    }
}

export class AzureStorageStorageAccountQueueModel extends AzureStorageStorageAccountResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountQueueModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureStorageStorageAccountQueueServiceDiagnosticSettingsLogCategoryNotEnabledRisk extends AzureStorageStorageAccountServiceDiagnosticSettingsLogCategoryNotEnabledRisk implements ITypeNameObject, IRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountQueueServiceDiagnosticSettingsLogCategoryNotEnabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason);
    }
}

export class AzureStorageStorageAccountQueueServiceDiagnosticSettingsLogCategoryNotEnabledRiskModel extends AzureStorageStorageAccountServiceDiagnosticSettingsLogCategoryNotEnabledRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AzureStorageStorageAccountServiceDiagnosticSettingsLogCategoryNotEnabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountQueueServiceDiagnosticSettingsLogCategoryNotEnabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class AzureStorageStorageAccountQueueServiceDiagnosticSettingsLogCategoryNotEnabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountQueueServiceDiagnosticSettingsLogCategoryNotEnabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureStorageStorageAccountSharedKeyAccessEnabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountSharedKeyAccessEnabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureStorageStorageAccountSoftDeleteDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountSoftDeleteDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureStorageStorageAccountTableServiceDiagnosticSettingsLogCategoryNotEnabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountTableServiceDiagnosticSettingsLogCategoryNotEnabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureStorageStorageAccountWideRangeInboundRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureStorageStorageAccountWideRangeInboundRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureTenantEntityCreationRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureTenantEntityCreationRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureWebApplicationHttpsOnlyDisabledRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWebApplicationHttpsOnlyDisabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureWebApplicationInboundExternalWideRangeRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWebApplicationInboundExternalWideRangeRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureWebApplicationInsecureTlsProtocolRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWebApplicationInsecureTlsProtocolRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class AzureWebApplicationScmInboundExternalWideRangeRiskPolicyConfiguration extends BuiltInRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureWebApplicationScmInboundExternalWideRangeRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap);
    }
}

export class BuiltInKubernetesAdmissionControllerRiskPolicyConfiguration extends KubernetesAdmissionControllerRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument, IKubernetesAdmissionControllerRiskPolicyConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        effect: Optional<KubernetesAdmissionControllerRiskPolicyEffect>,
        rawResourceTypeNames: Optional<string[]>,
        resourceOperations: KubernetesAdmissionControllerResourceOperation[],
        systemNamespacesEnabled: boolean,
        public rawResourcePluralTypeNames: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            effect,
            rawResourceTypeNames,
            resourceOperations,
            systemNamespacesEnabled);
    }
}

export class ContainerImageMaliciousFileRisk extends ContainerImageWorkloadAnalysisRisk implements ITypeNameObject, IRisk, IWorkloadResourceMaliciousFileRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        criticalVulnerabilities: WorkloadResourceRiskVulnerability[],
        highVulnerabilities: WorkloadResourceRiskVulnerability[],
        singleVirtualMachine: boolean,
        virtualMachineIds: string[],
        vulnerabilitySeverityToCountMap: Dictionary<number>,
        windowsUpdateDocumentationUrl: string,
        containerImageIds: string[],
        containerImageRepositoryReference: string,
        resolutionDeferTime: Optional<string>,
        public fileContentSha256Strings: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "ContainerImageMaliciousFileRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            criticalVulnerabilities,
            highVulnerabilities,
            singleVirtualMachine,
            virtualMachineIds,
            vulnerabilitySeverityToCountMap,
            windowsUpdateDocumentationUrl,
            containerImageIds,
            containerImageRepositoryReference,
            resolutionDeferTime);
    }
}

export class ContainerImageOperatingSystemEndOfLifeRisk extends ContainerImageWorkloadAnalysisRisk implements ITypeNameObject, IWorkloadResourceOperatingSystemRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        criticalVulnerabilities: WorkloadResourceRiskVulnerability[],
        highVulnerabilities: WorkloadResourceRiskVulnerability[],
        singleVirtualMachine: boolean,
        virtualMachineIds: string[],
        vulnerabilitySeverityToCountMap: Dictionary<number>,
        windowsUpdateDocumentationUrl: string,
        containerImageIds: string[],
        containerImageRepositoryReference: string,
        resolutionDeferTime: Optional<string>,
        public operatingSystemDisplayName: string,
        public operatingSystemEndOfLifeDate: string,
        public operatingSystemType: OperatingSystemType,
        public packageScope: WorkloadResourceOperatingSystemRiskPackageScope) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "ContainerImageOperatingSystemEndOfLifeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            criticalVulnerabilities,
            highVulnerabilities,
            singleVirtualMachine,
            virtualMachineIds,
            vulnerabilitySeverityToCountMap,
            windowsUpdateDocumentationUrl,
            containerImageIds,
            containerImageRepositoryReference,
            resolutionDeferTime);
    }
}

export class ContainerImageOperatingSystemUnpatchedRisk extends ContainerImageWorkloadAnalysisRisk implements ITypeNameObject, IWorkloadResourceOperatingSystemUnpatchedRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        criticalVulnerabilities: WorkloadResourceRiskVulnerability[],
        highVulnerabilities: WorkloadResourceRiskVulnerability[],
        singleVirtualMachine: boolean,
        virtualMachineIds: string[],
        vulnerabilitySeverityToCountMap: Dictionary<number>,
        windowsUpdateDocumentationUrl: string,
        containerImageIds: string[],
        containerImageRepositoryReference: string,
        resolutionDeferTime: Optional<string>,
        public criticalExploitableVulnerabilityPackageNames: string[],
        public criticalNonExploitableVulnerabilityPackageNames: string[],
        public highExploitableVulnerabilityPackageNames: string[],
        public highNonExploitableVulnerabilityPackageNames: string[],
        public operatingSystemDisplayName: string,
        public operatingSystemType: OperatingSystemType,
        public packageScope: WorkloadResourceOperatingSystemRiskPackageScope,
        public vulnerabilityPackageNames: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "ContainerImageOperatingSystemUnpatchedRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            criticalVulnerabilities,
            highVulnerabilities,
            singleVirtualMachine,
            virtualMachineIds,
            vulnerabilitySeverityToCountMap,
            windowsUpdateDocumentationUrl,
            containerImageIds,
            containerImageRepositoryReference,
            resolutionDeferTime);
    }
}

export class ContainerImageRepositoryVulnerabilityRisk extends ContainerImageRepositoryWorkloadAnalysisRisk implements ITypeNameObject, IWorkloadResourceVulnerabilityRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        criticalVulnerabilities: WorkloadResourceRiskVulnerability[],
        highVulnerabilities: WorkloadResourceRiskVulnerability[],
        singleVirtualMachine: boolean,
        virtualMachineIds: string[],
        vulnerabilitySeverityToCountMap: Dictionary<number>,
        windowsUpdateDocumentationUrl: string,
        containerImageId: string,
        public filePathToPackageNamesMap: Dictionary<string[]>,
        public filePathToVulnerabilityRawIdsMap: Dictionary<string[]>,
        public severeVulnerabilityMinSeverity: Severity,
        public vulnerabilityPackageNameToResolutionVersionsMap: Dictionary<string[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "ContainerImageRepositoryVulnerabilityRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            criticalVulnerabilities,
            highVulnerabilities,
            singleVirtualMachine,
            virtualMachineIds,
            vulnerabilitySeverityToCountMap,
            windowsUpdateDocumentationUrl,
            containerImageId);
    }
}

export class ContainerImageVulnerabilityRisk extends ContainerImageWorkloadAnalysisRisk implements ITypeNameObject, IWorkloadResourceVulnerabilityRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        criticalVulnerabilities: WorkloadResourceRiskVulnerability[],
        highVulnerabilities: WorkloadResourceRiskVulnerability[],
        singleVirtualMachine: boolean,
        virtualMachineIds: string[],
        vulnerabilitySeverityToCountMap: Dictionary<number>,
        windowsUpdateDocumentationUrl: string,
        containerImageIds: string[],
        containerImageRepositoryReference: string,
        resolutionDeferTime: Optional<string>,
        public filePathToPackageNamesMap: Dictionary<string[]>,
        public filePathToVulnerabilityRawIdsMap: Dictionary<string[]>,
        public resolutionContainerImageId: Optional<string>,
        public severeVulnerabilityMinSeverity: Severity,
        public vulnerabilityPackageNameToResolutionVersionsMap: Dictionary<string[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "ContainerImageVulnerabilityRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            criticalVulnerabilities,
            highVulnerabilities,
            singleVirtualMachine,
            virtualMachineIds,
            vulnerabilitySeverityToCountMap,
            windowsUpdateDocumentationUrl,
            containerImageIds,
            containerImageRepositoryReference,
            resolutionDeferTime);
    }
}

export class CustomActivityRiskPolicyConfiguration extends CustomRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        description: Optional<string>,
        name: string,
        severity: Severity,
        updateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            description,
            name,
            severity,
            updateTime);
    }
}

export class CustomKubernetesAdmissionControllerRiskPolicyConfiguration extends KubernetesAdmissionControllerRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument, IKubernetesAdmissionControllerRiskPolicyConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        effect: Optional<KubernetesAdmissionControllerRiskPolicyEffect>,
        rawResourceTypeNames: Optional<string[]>,
        resourceOperations: KubernetesAdmissionControllerResourceOperation[],
        systemNamespacesEnabled: boolean,
        public description: Optional<string>,
        public name: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            effect,
            rawResourceTypeNames,
            resourceOperations,
            systemNamespacesEnabled);
    }
}

export class EntityControllerGetEntityModelPageAwsDefaultKubernetesNamespaceResourceRequest extends EntityControllerGetEntityModelPageAwsKubernetesNamespaceResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        kubernetesResource: EntityControllerGetEntityModelPageEntityRequestKubernetesResource,
        kubernetesNamespaceResource: EntityControllerGetEntityModelPageEntityRequestKubernetesNamespaceResource) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag,
            kubernetesResource,
            kubernetesNamespaceResource);
    }
}

export class EntityControllerGetEntityModelPageAwsDefaultKubernetesNamespaceResourceRequestFilters extends EntityControllerGetEntityModelPageAwsKubernetesNamespaceResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public typeName: string) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAwsKubernetesIngressRequest extends EntityControllerGetEntityModelPageAwsKubernetesNamespaceResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        kubernetesResource: EntityControllerGetEntityModelPageEntityRequestKubernetesResource,
        kubernetesNamespaceResource: EntityControllerGetEntityModelPageEntityRequestKubernetesNamespaceResource,
        public kubernetesIngress: EntityControllerGetEntityModelPageEntityRequestKubernetesIngress) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag,
            kubernetesResource,
            kubernetesNamespaceResource);
    }
}

export class EntityControllerGetEntityModelPageAwsKubernetesIngressRequestFilters extends EntityControllerGetEntityModelPageAwsKubernetesNamespaceResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAzureComputeDiskRequest extends EntityControllerGetEntityModelPageAzureComputeDiskResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAzureComputeDiskRequestFilters extends EntityControllerGetEntityModelPageAzureComputeDiskResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        resourceGroupIdSelection: Optional<PagedItemSelection<string>>,
        networkAccessPolicyTypeSelection: Optional<ItemSelection<AzureComputeDiskResourceNetworkAccessPolicyType>>,
        sourceIdSelections: Optional<PagedItemSelection<string>>,
        public roleSelection: Optional<ItemSelection<AzureComputeResourceDiskRole>>,
        public virtualMachineIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            resourceGroupIdSelection,
            networkAccessPolicyTypeSelection,
            sourceIdSelections);
    }
}

export class EntityControllerGetEntityModelPageAzureComputeVirtualMachineRequest extends EntityControllerGetEntityModelPageAzureComputeVirtualMachineResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        public networkAccess: EntityControllerGetEntityModelPageEntityRequestNetworkAccess,
        public virtualMachineDiskResource: EntityControllerGetEntityModelPageEntityRequestVirtualMachineDiskResource,
        public virtualMachineResource: EntityControllerGetEntityModelPageEntityRequestVirtualMachineResource) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAzureComputeVirtualMachineRequestFilters extends EntityControllerGetEntityModelPageAzureComputeVirtualMachineResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        resourceGroupIdSelection: Optional<PagedItemSelection<string>>,
        applicationGatewayIdSelection: Optional<PagedItemSelection<string>>,
        loadBalancerIdSelection: Optional<PagedItemSelection<string>>,
        rawIdSelection: Optional<PagedItemSelection<string>>,
        virtualNetworkIdSelection: Optional<PagedItemSelection<string>>,
        public statusSelection: Optional<ItemSelection<AzureComputeVirtualMachineStatus>>,
        public stopTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            resourceGroupIdSelection,
            applicationGatewayIdSelection,
            loadBalancerIdSelection,
            rawIdSelection,
            virtualNetworkIdSelection);
    }
}

export class EntityControllerGetEntityModelPageAzureDefaultKubernetesNamespaceResourceRequest extends EntityControllerGetEntityModelPageAzureKubernetesNamespaceResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        kubernetesResource: EntityControllerGetEntityModelPageEntityRequestKubernetesResource,
        kubernetesNamespaceResource: EntityControllerGetEntityModelPageEntityRequestKubernetesNamespaceResource) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag,
            kubernetesResource,
            kubernetesNamespaceResource);
    }
}

export class EntityControllerGetEntityModelPageAzureDefaultKubernetesNamespaceResourceRequestFilters extends EntityControllerGetEntityModelPageAzureKubernetesNamespaceResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public typeName: string) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageAzureKeyVaultVaultCertificateRequest extends EntityControllerGetEntityModelPageAzureKeyVaultVaultObjectRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAzureKeyVaultVaultCertificateRequestFilters extends EntityControllerGetEntityModelPageAzureKeyVaultVaultObjectRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        resourceGroupIdSelection: Optional<PagedItemSelection<string>>,
        azureManaged: Optional<boolean>,
        enabled: Optional<boolean>,
        expirationTimeRangeSelection: Optional<TimeRangeSelection>,
        vaultIdSelection: Optional<PagedItemSelection<string>>,
        public thumbprintSelection: Optional<ItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            resourceGroupIdSelection,
            azureManaged,
            enabled,
            expirationTimeRangeSelection,
            vaultIdSelection);
    }
}

export class EntityControllerGetEntityModelPageAzureKeyVaultVaultKeyRequest extends EntityControllerGetEntityModelPageAzureKeyVaultVaultObjectRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageAzureKeyVaultVaultKeyRequestFilters extends EntityControllerGetEntityModelPageAzureKeyVaultVaultObjectRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        resourceGroupIdSelection: Optional<PagedItemSelection<string>>,
        azureManaged: Optional<boolean>,
        enabled: Optional<boolean>,
        expirationTimeRangeSelection: Optional<TimeRangeSelection>,
        vaultIdSelection: Optional<PagedItemSelection<string>>,
        public typeSelection: Optional<ItemSelection<AzureKeyVaultVaultKeyType>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            resourceGroupIdSelection,
            azureManaged,
            enabled,
            expirationTimeRangeSelection,
            vaultIdSelection);
    }
}

export class EntityControllerGetEntityModelPageAzureKubernetesIngressRequest extends EntityControllerGetEntityModelPageAzureKubernetesNamespaceResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        kubernetesResource: EntityControllerGetEntityModelPageEntityRequestKubernetesResource,
        kubernetesNamespaceResource: EntityControllerGetEntityModelPageEntityRequestKubernetesNamespaceResource,
        public kubernetesIngress: EntityControllerGetEntityModelPageEntityRequestKubernetesIngress) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag,
            kubernetesResource,
            kubernetesNamespaceResource);
    }
}

export class EntityControllerGetEntityModelPageAzureKubernetesIngressRequestFilters extends EntityControllerGetEntityModelPageAzureKubernetesNamespaceResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageGcpDefaultKubernetesNamespaceResourceRequest extends EntityControllerGetEntityModelPageGcpKubernetesNamespaceResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        kubernetesResource: EntityControllerGetEntityModelPageEntityRequestKubernetesResource,
        kubernetesNamespaceResource: EntityControllerGetEntityModelPageEntityRequestKubernetesNamespaceResource) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag,
            kubernetesResource,
            kubernetesNamespaceResource);
    }
}

export class EntityControllerGetEntityModelPageGcpDefaultKubernetesNamespaceResourceRequestFilters extends EntityControllerGetEntityModelPageGcpKubernetesNamespaceResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        updateTimeRangeSelection: Optional<TimeRangeSelection>,
        public typeName: string) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            updateTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageGcpKubernetesIngressRequest extends EntityControllerGetEntityModelPageGcpKubernetesNamespaceResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        kubernetesResource: EntityControllerGetEntityModelPageEntityRequestKubernetesResource,
        kubernetesNamespaceResource: EntityControllerGetEntityModelPageEntityRequestKubernetesNamespaceResource,
        public kubernetesIngress: EntityControllerGetEntityModelPageEntityRequestKubernetesIngress) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag,
            kubernetesResource,
            kubernetesNamespaceResource);
    }
}

export class EntityControllerGetEntityModelPageGcpKubernetesIngressRequestFilters extends EntityControllerGetEntityModelPageGcpKubernetesNamespaceResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        updateTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            updateTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageOciIamDynamicResourceGroupRequest extends EntityControllerGetEntityModelPageOciIamPrincipalRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageOciIamDynamicResourceGroupRequestFilters extends EntityControllerGetEntityModelPageOciIamPrincipalRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        descriptionSelection: Optional<PagedItemSelection<string>>,
        enabled: Optional<boolean>,
        domainIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            descriptionSelection,
            enabled,
            domainIdSelection);
    }
}

export class EntityControllerGetEntityModelPageOciIamGroupRequest extends EntityControllerGetEntityModelPageOciIamPrincipalRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag);
    }
}

export class EntityControllerGetEntityModelPageOciIamGroupRequestFilters extends EntityControllerGetEntityModelPageOciIamPrincipalRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        descriptionSelection: Optional<PagedItemSelection<string>>,
        enabled: Optional<boolean>,
        domainIdSelection: Optional<PagedItemSelection<string>>,
        public memberUserIdSelection: Optional<PagedItemSelection<string>>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection,
            descriptionSelection,
            enabled,
            domainIdSelection);
    }
}

export class EntityControllerGetEntityModelPageOpDefaultKubernetesNamespaceResourceRequest extends EntityControllerGetEntityModelPageOpKubernetesNamespaceResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        kubernetesResource: EntityControllerGetEntityModelPageEntityRequestKubernetesResource,
        kubernetesNamespaceResource: EntityControllerGetEntityModelPageEntityRequestKubernetesNamespaceResource) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag,
            kubernetesResource,
            kubernetesNamespaceResource);
    }
}

export class EntityControllerGetEntityModelPageOpDefaultKubernetesNamespaceResourceRequestFilters extends EntityControllerGetEntityModelPageOpKubernetesNamespaceResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>,
        public typeName: string) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class EntityControllerGetEntityModelPageOpKubernetesIngressRequest extends EntityControllerGetEntityModelPageOpKubernetesNamespaceResourceRequest {
    constructor(
        filters: EntityControllerGetEntityModelPageRequestFilters,
        limit: number,
        skip: number,
        sort: EntityControllerGetEntityModelPageRequestSort,
        tag: EntityControllerGetEntityModelPageEntityRequestTag,
        kubernetesResource: EntityControllerGetEntityModelPageEntityRequestKubernetesResource,
        kubernetesNamespaceResource: EntityControllerGetEntityModelPageEntityRequestKubernetesNamespaceResource) {
        super(
            filters,
            limit,
            skip,
            sort,
            tag,
            kubernetesResource,
            kubernetesNamespaceResource);
    }
}

export class EntityControllerGetEntityModelPageOpKubernetesIngressRequestFilters extends EntityControllerGetEntityModelPageOpKubernetesNamespaceResourceRequestFilters {
    constructor(
        attributeValueSelection: Optional<ItemSelection<string>>,
        idSelection: Optional<PagedItemSelection<string>>,
        propertyRawIdentifierToValueSelectionMap: Optional<Dictionary<PagedItemSelection<string>>>,
        regionIdSelection: Optional<ItemSelection<string>>,
        regionLocationSelection: Optional<ItemSelection<RegionLocation>>,
        relatedEntityRiskHighestSeveritySelection: Optional<ItemSelection<Severity>>,
        relatedEntityRiskTypeIdentifiers: Optional<ItemSelection<string>>,
        tenantIdSelection: Optional<ItemSelection<string>>,
        creationTimeRangeSelection: Optional<TimeRangeSelection>) {
        super(
            attributeValueSelection,
            idSelection,
            propertyRawIdentifierToValueSelectionMap,
            regionIdSelection,
            regionLocationSelection,
            relatedEntityRiskHighestSeveritySelection,
            relatedEntityRiskTypeIdentifiers,
            tenantIdSelection,
            creationTimeRangeSelection);
    }
}

export class GciDirectoryDirectoryRole extends GciDirectoryDirectoryRoleEntity implements ITypeNameObject, IDirectoryEntity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        creationTime: Optional<string>,
        public description: string,
        public rawRole: string,
        public type: GciDirectoryDirectoryRoleType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GciDirectoryDirectoryRole",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            creationTime);
    }
}

export class GciDirectoryDirectoryRoleAssignment extends GciDirectoryDirectoryRoleEntity implements ITypeNameObject, IDirectoryEntity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        creationTime: Optional<string>,
        public identityIdReference: string,
        public organizationalUnitId: Optional<string>,
        public roleId: string,
        public scopeType: GciDirectoryDirectoryRoleAssignmentScopeType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GciDirectoryDirectoryRoleAssignment",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            creationTime);
    }
}

export class GciDirectoryGroupMembershipEligibilityConfiguration extends GroupMembershipEligibilityConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        approval: Optional<PermissionEligibilityConfigurationApproval>,
        description: Optional<string>,
        expirationTimeFrame: string,
        name: string,
        principalTenantId: string,
        reasonRequired: boolean,
        groupReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GciDirectoryGroupMembershipEligibilityConfiguration",
            scopeId,
            approval,
            description,
            expirationTimeFrame,
            name,
            principalTenantId,
            reasonRequired,
            groupReferences);
    }
}

export class GcpAppEngineApplicationServiceEnvironmentVariableSecretExistsRiskPolicyConfiguration extends SecretExistsRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        exclusionSecretPatterns: Optional<SecretExistsRiskPolicyConfigurationSecretExclusionSecretPattern[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpAppEngineApplicationServiceEnvironmentVariableSecretExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            exclusionSecretPatterns);
    }
}

export class GcpArtifactRegistryPublicAccessExistsRisk extends GcpScopeResourcePublicAccessExistsRisk implements ITypeNameObject, IPublicRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        invocationOnly: boolean,
        permissionActionToHighSeverityMap: Dictionary<boolean>,
        principalIds: string[],
        roleIds: string[],
        public repositoryIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpArtifactRegistryPublicAccessExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            invocationOnly,
            permissionActionToHighSeverityMap,
            principalIds,
            roleIds);
    }
}

export class GcpBigQueryDatasetPublicAccessExistsRisk extends GcpScopeResourcePublicAccessExistsRisk implements ITypeNameObject, IPublicRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        invocationOnly: boolean,
        permissionActionToHighSeverityMap: Dictionary<boolean>,
        principalIds: string[],
        roleIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpBigQueryDatasetPublicAccessExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            invocationOnly,
            permissionActionToHighSeverityMap,
            principalIds,
            roleIds);
    }
}

export class GcpCloudRunServiceEnvironmentVariableSecretExistsRiskPolicyConfiguration extends SecretExistsRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        exclusionSecretPatterns: Optional<SecretExistsRiskPolicyConfigurationSecretExclusionSecretPattern[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpCloudRunServiceEnvironmentVariableSecretExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            exclusionSecretPatterns);
    }
}

export class GcpCloudRunServicePublicAccessExistsRisk extends GcpScopeResourcePublicAccessExistsRisk implements ITypeNameObject, IPublicRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        invocationOnly: boolean,
        permissionActionToHighSeverityMap: Dictionary<boolean>,
        principalIds: string[],
        roleIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpCloudRunServicePublicAccessExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            invocationOnly,
            permissionActionToHighSeverityMap,
            principalIds,
            roleIds);
    }
}

export class GcpComputeBackendBucket extends GcpComputeLoadBalancerResource implements ITypeNameObject, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        url: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeBackendBucket",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            url);
    }
}

export class GcpComputeBackendService extends GcpComputeLoadBalancerResource implements ITypeNameObject, IGcpNetworkedResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        url: string,
        public backendResourceUrls: string[],
        public identityAwareProxyEnabled: Optional<boolean>,
        public loadBalancingScheme: GcpComputeLoadBalancerLoadBalancingScheme,
        public loggingEnabled: boolean,
        public portName: Optional<string>,
        public protocol: GcpComputeBackendServiceProtocol) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeBackendService",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            url);
    }
}

export class GcpComputeFirewallRule extends GcpComputeNetworkResource implements ITypeNameObject, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        url: string,
        vpcUrl: string,
        public action: GcpComputeFirewallRuleAction,
        public direction: GcpComputeFirewallRuleDirection,
        public enabled: boolean,
        public filter: GcpComputeFirewallRuleFilter,
        public priority: number,
        public scopes: GcpComputeFirewallRuleScope[],
        public target: GcpComputeFirewallRuleTarget) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeFirewallRule",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            url,
            vpcUrl);
    }
}

export class GcpComputeForwardingRule extends GcpComputeLoadBalancerResource implements ITypeNameObject, IGcpDnsRecordEntity, IGcpNetworkedResource, IGcpTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        url: string,
        public description: Optional<string>,
        public dnsNames: Optional<string[]>,
        public ipAddress: string,
        public loadBalancingScheme: Optional<GcpComputeLoadBalancerLoadBalancingScheme>,
        public networkTier: GcpComputeForwardingRuleNetworkTier,
        public portRanges: IntRange[],
        public protocol: Optional<GcpComputeForwardingRuleProtocol>,
        public publicIpAddresses: string[],
        public subnetUrl: Optional<string>,
        public target: GcpComputeForwardingRuleTarget) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeForwardingRule",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            url);
    }
}

export class GcpComputeImagePublicAccessExistsRisk extends GcpScopeResourcePublicAccessExistsRisk implements ITypeNameObject, IPublicRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        invocationOnly: boolean,
        permissionActionToHighSeverityMap: Dictionary<boolean>,
        principalIds: string[],
        roleIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeImagePublicAccessExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            invocationOnly,
            permissionActionToHighSeverityMap,
            principalIds,
            roleIds);
    }
}

export class GcpComputeInstanceStartupScriptSecretExistsRiskPolicyConfiguration extends SecretExistsRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        exclusionSecretPatterns: Optional<SecretExistsRiskPolicyConfigurationSecretExclusionSecretPattern[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeInstanceStartupScriptSecretExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            exclusionSecretPatterns);
    }
}

export class GcpComputeTargetHttpProxy extends GcpComputeTargetProxy implements ITypeNameObject, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        url: string,
        targetResourceUrl: string,
        type: GcpComputeTargetProxyType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeTargetHttpProxy",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            url,
            targetResourceUrl,
            type);
    }
}

export class GcpComputeTargetHttpsProxy extends GcpComputeTargetProxy implements ITypeNameObject, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        assetPath: string,
        parentResourceId: Optional<string>,
        rawId: string,
        url: string,
        targetResourceUrl: string,
        type: GcpComputeTargetProxyType,
        public sslCertificateUrls: string[],
        public sslPolicyUrl: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpComputeTargetHttpsProxy",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            assetPath,
            parentResourceId,
            rawId,
            url,
            targetResourceUrl,
            type);
    }
}

export class GcpExcessivePermissionGroupRiskPolicyConfiguration extends GcpExcessivePermissionPrincipalRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        inactivePrincipalDeleteGroupMembershipEnabled: Optional<boolean>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpExcessivePermissionGroupRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            inactivePrincipalDeleteGroupMembershipEnabled);
    }
}

export class GcpFunctionsFunctionEnvironmentVariableSecretExistsRiskPolicyConfiguration extends SecretExistsRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        exclusionSecretPatterns: Optional<SecretExistsRiskPolicyConfigurationSecretExclusionSecretPattern[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpFunctionsFunctionEnvironmentVariableSecretExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            exclusionSecretPatterns);
    }
}

export class GcpFunctionsFunctionPublicAccessExistsRisk extends GcpScopeResourcePublicAccessExistsRisk implements ITypeNameObject, IPublicRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        invocationOnly: boolean,
        permissionActionToHighSeverityMap: Dictionary<boolean>,
        principalIds: string[],
        roleIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpFunctionsFunctionPublicAccessExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            invocationOnly,
            permissionActionToHighSeverityMap,
            principalIds,
            roleIds);
    }
}

export class GcpIdentityActivityRiskPolicyConfiguration extends CustomActivityRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        description: Optional<string>,
        name: string,
        severity: Severity,
        updateTime: string,
        public identityBuiltInAttributeTypeNames: Optional<string[]>,
        public identityCustomAttributeDefinitionIds: Optional<string[]>,
        public identityIds: Optional<string[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpIdentityActivityRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            description,
            name,
            severity,
            updateTime);
    }
}

export class GcpKmsKeyRingKeyPublicAccessExistsRisk extends GcpScopeResourcePublicAccessExistsRisk implements ITypeNameObject, IPublicRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        invocationOnly: boolean,
        permissionActionToHighSeverityMap: Dictionary<boolean>,
        principalIds: string[],
        roleIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpKmsKeyRingKeyPublicAccessExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            invocationOnly,
            permissionActionToHighSeverityMap,
            principalIds,
            roleIds);
    }
}

export class GcpKmsKeyRingPublicAccessExistsRisk extends GcpScopeResourcePublicAccessExistsRisk implements ITypeNameObject, IPublicRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        invocationOnly: boolean,
        permissionActionToHighSeverityMap: Dictionary<boolean>,
        principalIds: string[],
        roleIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpKmsKeyRingPublicAccessExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            invocationOnly,
            permissionActionToHighSeverityMap,
            principalIds,
            roleIds);
    }
}

export class GcpKubernetesClusterRole extends GcpKubernetesResource<KubernetesClusterRoleData> implements ITypeNameObject, IKubernetesClusterRole {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesClusterRoleData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpKubernetesClusterRole",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class GcpKubernetesClusterRoleBinding extends GcpKubernetesResource<KubernetesClusterRoleBindingData> implements ITypeNameObject, IKubernetesClusterRoleBinding {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesClusterRoleBindingData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpKubernetesClusterRoleBinding",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class GcpKubernetesConfigMapModel extends GcpKubernetesNamespaceResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesNamespaceResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpKubernetesConfigMapModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class GcpKubernetesCronJobModel extends GcpKubernetesWorkloadResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesWorkloadResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        containerImageIdReferences: string[],
        containerImageRawUrlToDataMap: Dictionary<ContainerImageResourceStateContainerImageData>,
        containerImageScanStatus: Optional<ContainerImageDataScanStatus>,
        containerImageVulnerabilities: VulnerabilityData[],
        controllerResourceReference: Optional<EntitySearchableIdReference>,
        gcpServiceAccountIds: string[],
        nodePoolIds: string[],
        serviceAccountReference: EntitySearchableIdReference) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpKubernetesCronJobModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            containerImageIdReferences,
            containerImageRawUrlToDataMap,
            containerImageScanStatus,
            containerImageVulnerabilities,
            controllerResourceReference,
            gcpServiceAccountIds,
            nodePoolIds,
            serviceAccountReference);
    }
}

export class GcpKubernetesDaemonSetModel extends GcpKubernetesWorkloadResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesWorkloadResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        containerImageIdReferences: string[],
        containerImageRawUrlToDataMap: Dictionary<ContainerImageResourceStateContainerImageData>,
        containerImageScanStatus: Optional<ContainerImageDataScanStatus>,
        containerImageVulnerabilities: VulnerabilityData[],
        controllerResourceReference: Optional<EntitySearchableIdReference>,
        gcpServiceAccountIds: string[],
        nodePoolIds: string[],
        serviceAccountReference: EntitySearchableIdReference) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpKubernetesDaemonSetModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            containerImageIdReferences,
            containerImageRawUrlToDataMap,
            containerImageScanStatus,
            containerImageVulnerabilities,
            controllerResourceReference,
            gcpServiceAccountIds,
            nodePoolIds,
            serviceAccountReference);
    }
}

export class GcpKubernetesDeploymentModel extends GcpKubernetesWorkloadResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesDeploymentModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        containerImageIdReferences: string[],
        containerImageRawUrlToDataMap: Dictionary<ContainerImageResourceStateContainerImageData>,
        containerImageScanStatus: Optional<ContainerImageDataScanStatus>,
        containerImageVulnerabilities: VulnerabilityData[],
        controllerResourceReference: Optional<EntitySearchableIdReference>,
        gcpServiceAccountIds: string[],
        nodePoolIds: string[],
        serviceAccountReference: EntitySearchableIdReference,
        public replicaSetIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpKubernetesDeploymentModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            containerImageIdReferences,
            containerImageRawUrlToDataMap,
            containerImageScanStatus,
            containerImageVulnerabilities,
            controllerResourceReference,
            gcpServiceAccountIds,
            nodePoolIds,
            serviceAccountReference);
    }
}

export class GcpKubernetesGroup extends GcpKubernetesResource<KubernetesGroupData> implements ITypeNameObject, IKubernetesGroup {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesGroupData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpKubernetesGroup",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class GcpKubernetesIngressClass extends GcpKubernetesResource<KubernetesIngressClassData> implements ITypeNameObject, IKubernetesIngressClass {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesIngressClassData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpKubernetesIngressClass",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class GcpKubernetesIngressModel extends GcpKubernetesNamespaceResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesIngressModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        public loadBalancerSearchableReference: Optional<EntitySearchableReference>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpKubernetesIngressModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class GcpKubernetesJobModel extends GcpKubernetesWorkloadResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesWorkloadResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        containerImageIdReferences: string[],
        containerImageRawUrlToDataMap: Dictionary<ContainerImageResourceStateContainerImageData>,
        containerImageScanStatus: Optional<ContainerImageDataScanStatus>,
        containerImageVulnerabilities: VulnerabilityData[],
        controllerResourceReference: Optional<EntitySearchableIdReference>,
        gcpServiceAccountIds: string[],
        nodePoolIds: string[],
        serviceAccountReference: EntitySearchableIdReference) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpKubernetesJobModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            containerImageIdReferences,
            containerImageRawUrlToDataMap,
            containerImageScanStatus,
            containerImageVulnerabilities,
            controllerResourceReference,
            gcpServiceAccountIds,
            nodePoolIds,
            serviceAccountReference);
    }
}

export class GcpKubernetesNamespace extends GcpKubernetesResource<KubernetesNamespaceData> implements ITypeNameObject, IKubernetesNamespace {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesNamespaceData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpKubernetesNamespace",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class GcpKubernetesNamespaceResourceBase extends GcpKubernetesResource<KubernetesNamespaceResourceData> implements ITypeNameObject, IKubernetesNamespaceResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesNamespaceResourceData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class GcpKubernetesNode extends GcpKubernetesResource<KubernetesNodeData> implements ITypeNameObject, IKubernetesNode {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesNodeData,
        public instanceAssetPath: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpKubernetesNode",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class GcpKubernetesPersistentVolume extends GcpKubernetesResource<KubernetesPersistentVolumeData> implements ITypeNameObject, IKubernetesPersistentVolume {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesPersistentVolumeData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpKubernetesPersistentVolume",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class GcpKubernetesPodSecurityPolicy extends GcpKubernetesResource<KubernetesPodSecurityPolicyData> implements ITypeNameObject, IKubernetesPodSecurityPolicy {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesPodSecurityPolicyData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpKubernetesPodSecurityPolicy",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class GcpKubernetesReplicaSetModel extends GcpKubernetesWorkloadResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesWorkloadResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        containerImageIdReferences: string[],
        containerImageRawUrlToDataMap: Dictionary<ContainerImageResourceStateContainerImageData>,
        containerImageScanStatus: Optional<ContainerImageDataScanStatus>,
        containerImageVulnerabilities: VulnerabilityData[],
        controllerResourceReference: Optional<EntitySearchableIdReference>,
        gcpServiceAccountIds: string[],
        nodePoolIds: string[],
        serviceAccountReference: EntitySearchableIdReference) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpKubernetesReplicaSetModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            containerImageIdReferences,
            containerImageRawUrlToDataMap,
            containerImageScanStatus,
            containerImageVulnerabilities,
            controllerResourceReference,
            gcpServiceAccountIds,
            nodePoolIds,
            serviceAccountReference);
    }
}

export class GcpKubernetesStatefulSetModel extends GcpKubernetesWorkloadResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesWorkloadResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: GcpEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        containerImageIdReferences: string[],
        containerImageRawUrlToDataMap: Dictionary<ContainerImageResourceStateContainerImageData>,
        containerImageScanStatus: Optional<ContainerImageDataScanStatus>,
        containerImageVulnerabilities: VulnerabilityData[],
        controllerResourceReference: Optional<EntitySearchableIdReference>,
        gcpServiceAccountIds: string[],
        nodePoolIds: string[],
        serviceAccountReference: EntitySearchableIdReference) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpKubernetesStatefulSetModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            containerImageIdReferences,
            containerImageRawUrlToDataMap,
            containerImageScanStatus,
            containerImageVulnerabilities,
            controllerResourceReference,
            gcpServiceAccountIds,
            nodePoolIds,
            serviceAccountReference);
    }
}

export class GcpPrincipalAllowedResourcePermissionRiskPolicyConfiguration extends GcpPrincipalResourcePermissionRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        description: Optional<string>,
        name: string,
        severity: Severity,
        updateTime: string,
        actionNamePatterns: Optional<string[]>,
        actionRiskCategories: Optional<ActionRiskCategory[]>,
        actions: Optional<string[]>,
        principalBuiltInAttributeTypeNames: Optional<string[]>,
        principalIds: Optional<string[]>,
        resourceBuiltInAttributeTypeNames: Optional<string[]>,
        resourceCustomAttributeDefinitionIds: Optional<string[]>,
        resourceIds: Optional<string[]>,
        resourceTags: Optional<ResourceTag[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpPrincipalAllowedResourcePermissionRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            description,
            name,
            severity,
            updateTime,
            actionNamePatterns,
            actionRiskCategories,
            actions,
            principalBuiltInAttributeTypeNames,
            principalIds,
            resourceBuiltInAttributeTypeNames,
            resourceCustomAttributeDefinitionIds,
            resourceIds,
            resourceTags);
    }
}

export class GcpPrincipalNotAllowedResourcePermissionRiskPolicyConfiguration extends GcpPrincipalResourcePermissionRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        description: Optional<string>,
        name: string,
        severity: Severity,
        updateTime: string,
        actionNamePatterns: Optional<string[]>,
        actionRiskCategories: Optional<ActionRiskCategory[]>,
        actions: Optional<string[]>,
        principalBuiltInAttributeTypeNames: Optional<string[]>,
        principalIds: Optional<string[]>,
        resourceBuiltInAttributeTypeNames: Optional<string[]>,
        resourceCustomAttributeDefinitionIds: Optional<string[]>,
        resourceIds: Optional<string[]>,
        resourceTags: Optional<ResourceTag[]>,
        public allPrincipals: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpPrincipalNotAllowedResourcePermissionRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            description,
            name,
            severity,
            updateTime,
            actionNamePatterns,
            actionRiskCategories,
            actions,
            principalBuiltInAttributeTypeNames,
            principalIds,
            resourceBuiltInAttributeTypeNames,
            resourceCustomAttributeDefinitionIds,
            resourceIds,
            resourceTags);
    }
}

export class GcpPubSubSubscriptionPublicAccessExistsRisk extends GcpScopeResourcePublicAccessExistsRisk implements ITypeNameObject, IPublicRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        invocationOnly: boolean,
        permissionActionToHighSeverityMap: Dictionary<boolean>,
        principalIds: string[],
        roleIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpPubSubSubscriptionPublicAccessExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            invocationOnly,
            permissionActionToHighSeverityMap,
            principalIds,
            roleIds);
    }
}

export class GcpPubSubTopicPublicAccessExistsRisk extends GcpScopeResourcePublicAccessExistsRisk implements ITypeNameObject, IPublicRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        invocationOnly: boolean,
        permissionActionToHighSeverityMap: Dictionary<boolean>,
        principalIds: string[],
        roleIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpPubSubTopicPublicAccessExistsRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            invocationOnly,
            permissionActionToHighSeverityMap,
            principalIds,
            roleIds);
    }
}

export class GcpResourceCodeResourceNotExistsRiskPolicyConfiguration extends ResourceCodeResourceNotExistsRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        description: Optional<string>,
        name: string,
        severity: Severity,
        updateTime: string,
        allResources: boolean,
        resourceBuiltInAttributeTypeNames: Optional<string[]>,
        resourceCustomAttributeDefinitionIds: Optional<string[]>,
        resourceIds: Optional<string[]>,
        resourceTags: Optional<ResourceTag[]>,
        resourceTypeNames: Optional<string[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpResourceCodeResourceNotExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            description,
            name,
            severity,
            updateTime,
            allResources,
            resourceBuiltInAttributeTypeNames,
            resourceCustomAttributeDefinitionIds,
            resourceIds,
            resourceTags,
            resourceTypeNames);
    }
}

export class GcpResourceTagNotExistsRiskPolicyConfiguration extends ResourceTagNotExistsRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        description: Optional<string>,
        name: string,
        severity: Severity,
        updateTime: string,
        allResources: boolean,
        existsResourceTagKeys: Optional<string[]>,
        existsResourceTagPattern: Optional<ResourceTagPattern>,
        existsResourceTags: Optional<ResourceTag[]>,
        resourceBuiltInAttributeTypeNames: Optional<string[]>,
        resourceCustomAttributeDefinitionIds: Optional<string[]>,
        resourceIds: Optional<string[]>,
        resourceTags: Optional<ResourceTag[]>,
        resourceTypeNames: Optional<string[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpResourceTagNotExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            description,
            name,
            severity,
            updateTime,
            allResources,
            existsResourceTagKeys,
            existsResourceTagPattern,
            existsResourceTags,
            resourceBuiltInAttributeTypeNames,
            resourceCustomAttributeDefinitionIds,
            resourceIds,
            resourceTags,
            resourceTypeNames);
    }
}

export class GcpResourceTagSecretExistsRiskPolicyConfiguration extends SecretExistsRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        exclusionSecretPatterns: Optional<SecretExistsRiskPolicyConfigurationSecretExclusionSecretPattern[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpResourceTagSecretExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            exclusionSecretPatterns);
    }
}

export class GcpRoleBindingEligibilityConfiguration extends PermissionAssignmentEligibilityConfiguration implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        approval: Optional<PermissionEligibilityConfigurationApproval>,
        description: Optional<string>,
        expirationTimeFrame: string,
        name: string,
        principalTenantId: string,
        reasonRequired: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpRoleBindingEligibilityConfiguration",
            scopeId,
            approval,
            description,
            expirationTimeFrame,
            name,
            principalTenantId,
            reasonRequired);
    }
}

export class GcpRoleBindingEligibilityModel extends PermissionAssignmentEligibilityModel implements IDocument, ITypeNameObject {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        public configuration: GcpRoleBindingEligibilityConfiguration,
        scopeId: string,
        state: Optional<SystemEntityState>,
        approverPrincipalIdReferences: string[],
        existingGranteePrincipalIds: string[],
        granteePrincipalIdReferences: string[],
        levelToApproverPrincipalIdReferencesMap: Dictionary<string[]>,
        levelToExistingApproverPrincipalIdsMap: Dictionary<string[]>,
        permissionRequestScopeIds: string[],
        public existingRoleIds: string[],
        public roleIdReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpRoleBindingEligibilityModel",
            configuration,
            scopeId,
            state,
            approverPrincipalIdReferences,
            existingGranteePrincipalIds,
            granteePrincipalIdReferences,
            levelToApproverPrincipalIdReferencesMap,
            levelToExistingApproverPrincipalIdsMap,
            permissionRequestScopeIds);
    }
}

export class KubernetesAdmissionControllerRequiredLabelsRiskPolicyConfiguration extends BuiltInKubernetesAdmissionControllerRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument, IKubernetesAdmissionControllerRiskPolicyConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        effect: Optional<KubernetesAdmissionControllerRiskPolicyEffect>,
        rawResourceTypeNames: Optional<string[]>,
        resourceOperations: KubernetesAdmissionControllerResourceOperation[],
        systemNamespacesEnabled: boolean,
        rawResourcePluralTypeNames: string[],
        public requiredLabelKeyToValueAllowedRegexMap: Optional<Dictionary<Optional<string>>>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesAdmissionControllerRequiredLabelsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            effect,
            rawResourceTypeNames,
            resourceOperations,
            systemNamespacesEnabled,
            rawResourcePluralTypeNames);
    }
}

export class KubernetesAdmissionControllerWorkloadResourceRiskPolicyConfiguration extends BuiltInKubernetesAdmissionControllerRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument, IKubernetesAdmissionControllerRiskPolicyConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        effect: Optional<KubernetesAdmissionControllerRiskPolicyEffect>,
        rawResourceTypeNames: Optional<string[]>,
        resourceOperations: KubernetesAdmissionControllerResourceOperation[],
        systemNamespacesEnabled: boolean,
        rawResourcePluralTypeNames: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            effect,
            rawResourceTypeNames,
            resourceOperations,
            systemNamespacesEnabled,
            rawResourcePluralTypeNames);
    }
}

export class KubernetesNamespaceWorkloadResourceContainerCapabilityRisk extends KubernetesNamespaceWorkloadResourceContainersRisk implements ITypeNameObject, IKubernetesRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        podSecurityStandard: KubernetesNamespaceDataPodSecurityStandard,
        workloadResourceIds: string[],
        containerNames: string[],
        public defaultCapabilities: string[],
        public sensitiveCapabilities: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesNamespaceWorkloadResourceContainerCapabilityRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            podSecurityStandard,
            workloadResourceIds,
            containerNames);
    }
}

export class KubernetesNamespaceWorkloadResourceContainerCapabilityRiskModel extends KubernetesNamespaceWorkloadResourceContainersRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: KubernetesNamespaceWorkloadResourceContainerCapabilityRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        public capabilitiesType: KubernetesNamespaceWorkloadResourceContainerCapabilityRiskModelCapabilitiesType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesNamespaceWorkloadResourceContainerCapabilityRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class KubernetesNamespaceWorkloadResourceContainerPrivilegedRisk extends KubernetesNamespaceWorkloadResourceContainersRisk implements ITypeNameObject, IKubernetesRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        podSecurityStandard: KubernetesNamespaceDataPodSecurityStandard,
        workloadResourceIds: string[],
        containerNames: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesNamespaceWorkloadResourceContainerPrivilegedRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            podSecurityStandard,
            workloadResourceIds,
            containerNames);
    }
}

export class KubernetesNamespaceWorkloadResourceContainerPrivilegedRiskModel extends KubernetesNamespaceWorkloadResourceContainersRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: KubernetesNamespaceWorkloadResourceContainerPrivilegedRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesNamespaceWorkloadResourceContainerPrivilegedRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class KubernetesNamespaceWorkloadResourceContainerPrivilegeEscalationRisk extends KubernetesNamespaceWorkloadResourceContainersRisk implements ITypeNameObject, IKubernetesRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        podSecurityStandard: KubernetesNamespaceDataPodSecurityStandard,
        workloadResourceIds: string[],
        containerNames: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesNamespaceWorkloadResourceContainerPrivilegeEscalationRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            podSecurityStandard,
            workloadResourceIds,
            containerNames);
    }
}

export class KubernetesNamespaceWorkloadResourceContainerPrivilegeEscalationRiskModel extends KubernetesNamespaceWorkloadResourceContainersRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: KubernetesNamespaceWorkloadResourceContainerPrivilegeEscalationRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesNamespaceWorkloadResourceContainerPrivilegeEscalationRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class KubernetesNamespaceWorkloadResourceContainerRootUserEnabledRisk extends KubernetesNamespaceWorkloadResourceContainersRisk implements ITypeNameObject, IKubernetesRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        podSecurityStandard: KubernetesNamespaceDataPodSecurityStandard,
        workloadResourceIds: string[],
        containerNames: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesNamespaceWorkloadResourceContainerRootUserEnabledRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            podSecurityStandard,
            workloadResourceIds,
            containerNames);
    }
}

export class KubernetesNamespaceWorkloadResourceContainerRootUserEnabledRiskModel extends KubernetesNamespaceWorkloadResourceContainersRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: KubernetesNamespaceWorkloadResourceContainerRootUserEnabledRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesNamespaceWorkloadResourceContainerRootUserEnabledRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState);
    }
}

export class OciIamDynamicResourceGroup extends OciIamPrincipal implements ITypeNameObject, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        description: Optional<string>,
        enabled: boolean,
        domainId: Optional<string>,
        domainOcid: Optional<string>,
        public membershipRule: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciIamDynamicResourceGroup",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            description,
            enabled,
            domainId,
            domainOcid);
    }
}

export class OciIamDynamicResourceGroupModel extends OciIamPrincipalModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciIamDynamicResourceGroupModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class OciIamGroup extends OciIamPrincipal implements ITypeNameObject, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: Optional<string>,
        ocid: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        description: Optional<string>,
        enabled: boolean,
        domainId: Optional<string>,
        domainOcid: Optional<string>,
        public allDomainUsers: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciIamGroup",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            ocid,
            tagExists,
            tags,
            description,
            enabled,
            domainId,
            domainOcid);
    }
}

export class OciIamGroupModel extends OciIamPrincipalModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        public memberUserIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciIamGroupModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class OciIamUserApiKeyNotRotatedRiskPolicyConfiguration extends OciIamUserCredentialNotRotatedRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        credentialRotationTimeFrame: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciIamUserApiKeyNotRotatedRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            credentialRotationTimeFrame);
    }
}

export class OciIamUserAuthTokenNotRotatedRiskPolicyConfiguration extends OciIamUserCredentialNotRotatedRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        credentialRotationTimeFrame: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OciIamUserAuthTokenNotRotatedRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            credentialRotationTimeFrame);
    }
}

export class OpKubernetesClusterRole extends OpKubernetesResource<KubernetesClusterRoleData> implements ITypeNameObject, IKubernetesClusterRole {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesClusterRoleData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpKubernetesClusterRole",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class OpKubernetesClusterRoleBinding extends OpKubernetesResource<KubernetesClusterRoleBindingData> implements ITypeNameObject, IKubernetesClusterRoleBinding {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesClusterRoleBindingData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpKubernetesClusterRoleBinding",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class OpKubernetesConfigMapModel extends OpKubernetesNamespaceResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesNamespaceResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpKubernetesConfigMapModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class OpKubernetesCronJobModel extends OpKubernetesWorkloadResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesWorkloadResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        containerImageIdReferences: string[],
        containerImageRawUrlToDataMap: Dictionary<ContainerImageResourceStateContainerImageData>,
        containerImageScanStatus: Optional<ContainerImageDataScanStatus>,
        containerImageVulnerabilities: VulnerabilityData[],
        controllerResourceReference: Optional<EntitySearchableIdReference>,
        serviceAccountReference: EntitySearchableIdReference) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpKubernetesCronJobModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            containerImageIdReferences,
            containerImageRawUrlToDataMap,
            containerImageScanStatus,
            containerImageVulnerabilities,
            controllerResourceReference,
            serviceAccountReference);
    }
}

export class OpKubernetesDaemonSetModel extends OpKubernetesWorkloadResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesWorkloadResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        containerImageIdReferences: string[],
        containerImageRawUrlToDataMap: Dictionary<ContainerImageResourceStateContainerImageData>,
        containerImageScanStatus: Optional<ContainerImageDataScanStatus>,
        containerImageVulnerabilities: VulnerabilityData[],
        controllerResourceReference: Optional<EntitySearchableIdReference>,
        serviceAccountReference: EntitySearchableIdReference) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpKubernetesDaemonSetModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            containerImageIdReferences,
            containerImageRawUrlToDataMap,
            containerImageScanStatus,
            containerImageVulnerabilities,
            controllerResourceReference,
            serviceAccountReference);
    }
}

export class OpKubernetesDeploymentModel extends OpKubernetesWorkloadResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesDeploymentModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        containerImageIdReferences: string[],
        containerImageRawUrlToDataMap: Dictionary<ContainerImageResourceStateContainerImageData>,
        containerImageScanStatus: Optional<ContainerImageDataScanStatus>,
        containerImageVulnerabilities: VulnerabilityData[],
        controllerResourceReference: Optional<EntitySearchableIdReference>,
        serviceAccountReference: EntitySearchableIdReference,
        public replicaSetIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpKubernetesDeploymentModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            containerImageIdReferences,
            containerImageRawUrlToDataMap,
            containerImageScanStatus,
            containerImageVulnerabilities,
            controllerResourceReference,
            serviceAccountReference);
    }
}

export class OpKubernetesGroup extends OpKubernetesResource<KubernetesGroupData> implements ITypeNameObject, IKubernetesGroup {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesGroupData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpKubernetesGroup",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class OpKubernetesIngressClass extends OpKubernetesResource<KubernetesIngressClassData> implements ITypeNameObject, IKubernetesIngressClass {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesIngressClassData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpKubernetesIngressClass",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class OpKubernetesIngressModel extends OpKubernetesNamespaceResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesIngressModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        public loadBalancerSearchableReference: Optional<EntitySearchableReference>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpKubernetesIngressModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference);
    }
}

export class OpKubernetesJobModel extends OpKubernetesWorkloadResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesWorkloadResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        containerImageIdReferences: string[],
        containerImageRawUrlToDataMap: Dictionary<ContainerImageResourceStateContainerImageData>,
        containerImageScanStatus: Optional<ContainerImageDataScanStatus>,
        containerImageVulnerabilities: VulnerabilityData[],
        controllerResourceReference: Optional<EntitySearchableIdReference>,
        serviceAccountReference: EntitySearchableIdReference) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpKubernetesJobModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            containerImageIdReferences,
            containerImageRawUrlToDataMap,
            containerImageScanStatus,
            containerImageVulnerabilities,
            controllerResourceReference,
            serviceAccountReference);
    }
}

export class OpKubernetesNamespace extends OpKubernetesResource<KubernetesNamespaceData> implements ITypeNameObject, IKubernetesNamespace {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesNamespaceData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpKubernetesNamespace",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class OpKubernetesNamespaceResourceBase extends OpKubernetesResource<KubernetesNamespaceResourceData> implements ITypeNameObject, IKubernetesNamespaceResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesNamespaceResourceData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class OpKubernetesNode extends OpKubernetesResource<KubernetesNodeData> implements ITypeNameObject, IKubernetesNode {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesNodeData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpKubernetesNode",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class OpKubernetesPersistentVolume extends OpKubernetesResource<KubernetesPersistentVolumeData> implements ITypeNameObject, IKubernetesPersistentVolume {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesPersistentVolumeData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpKubernetesPersistentVolume",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class OpKubernetesPodSecurityPolicy extends OpKubernetesResource<KubernetesPodSecurityPolicyData> implements ITypeNameObject, IKubernetesPodSecurityPolicy {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesPodSecurityPolicyData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpKubernetesPodSecurityPolicy",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class OpKubernetesReplicaSetModel extends OpKubernetesWorkloadResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesWorkloadResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        containerImageIdReferences: string[],
        containerImageRawUrlToDataMap: Dictionary<ContainerImageResourceStateContainerImageData>,
        containerImageScanStatus: Optional<ContainerImageDataScanStatus>,
        containerImageVulnerabilities: VulnerabilityData[],
        controllerResourceReference: Optional<EntitySearchableIdReference>,
        serviceAccountReference: EntitySearchableIdReference) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpKubernetesReplicaSetModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            containerImageIdReferences,
            containerImageRawUrlToDataMap,
            containerImageScanStatus,
            containerImageVulnerabilities,
            controllerResourceReference,
            serviceAccountReference);
    }
}

export class OpKubernetesStatefulSetModel extends OpKubernetesWorkloadResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesWorkloadResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        containerImageIdReferences: string[],
        containerImageRawUrlToDataMap: Dictionary<ContainerImageResourceStateContainerImageData>,
        containerImageScanStatus: Optional<ContainerImageDataScanStatus>,
        containerImageVulnerabilities: VulnerabilityData[],
        controllerResourceReference: Optional<EntitySearchableIdReference>,
        serviceAccountReference: EntitySearchableIdReference) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpKubernetesStatefulSetModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            containerImageIdReferences,
            containerImageRawUrlToDataMap,
            containerImageScanStatus,
            containerImageVulnerabilities,
            controllerResourceReference,
            serviceAccountReference);
    }
}

export class VirtualMachineImageOperatingSystemEndOfLifeRisk extends VirtualMachineImageWorkloadAnalysisRisk implements ITypeNameObject, IWorkloadResourceOperatingSystemRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        criticalVulnerabilities: WorkloadResourceRiskVulnerability[],
        highVulnerabilities: WorkloadResourceRiskVulnerability[],
        singleVirtualMachine: boolean,
        virtualMachineIds: string[],
        vulnerabilitySeverityToCountMap: Dictionary<number>,
        windowsUpdateDocumentationUrl: string,
        public operatingSystemDisplayName: string,
        public operatingSystemEndOfLifeDate: string,
        public operatingSystemType: OperatingSystemType,
        public packageScope: WorkloadResourceOperatingSystemRiskPackageScope) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "VirtualMachineImageOperatingSystemEndOfLifeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            criticalVulnerabilities,
            highVulnerabilities,
            singleVirtualMachine,
            virtualMachineIds,
            vulnerabilitySeverityToCountMap,
            windowsUpdateDocumentationUrl);
    }
}

export class VirtualMachineImageOperatingSystemUnpatchedRisk extends VirtualMachineImageWorkloadAnalysisRisk implements ITypeNameObject, IWorkloadResourceOperatingSystemUnpatchedRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        criticalVulnerabilities: WorkloadResourceRiskVulnerability[],
        highVulnerabilities: WorkloadResourceRiskVulnerability[],
        singleVirtualMachine: boolean,
        virtualMachineIds: string[],
        vulnerabilitySeverityToCountMap: Dictionary<number>,
        windowsUpdateDocumentationUrl: string,
        public criticalExploitableVulnerabilityPackageNames: string[],
        public criticalNonExploitableVulnerabilityPackageNames: string[],
        public highExploitableVulnerabilityPackageNames: string[],
        public highNonExploitableVulnerabilityPackageNames: string[],
        public operatingSystemDisplayName: string,
        public operatingSystemType: OperatingSystemType,
        public packageScope: WorkloadResourceOperatingSystemRiskPackageScope,
        public vulnerabilityPackageNames: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "VirtualMachineImageOperatingSystemUnpatchedRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            criticalVulnerabilities,
            highVulnerabilities,
            singleVirtualMachine,
            virtualMachineIds,
            vulnerabilitySeverityToCountMap,
            windowsUpdateDocumentationUrl);
    }
}

export class VirtualMachineImageVulnerabilityRisk extends VirtualMachineImageWorkloadAnalysisRisk implements ITypeNameObject, IWorkloadResourceVulnerabilityRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        criticalVulnerabilities: WorkloadResourceRiskVulnerability[],
        highVulnerabilities: WorkloadResourceRiskVulnerability[],
        singleVirtualMachine: boolean,
        virtualMachineIds: string[],
        vulnerabilitySeverityToCountMap: Dictionary<number>,
        windowsUpdateDocumentationUrl: string,
        public filePathToPackageNamesMap: Dictionary<string[]>,
        public filePathToVulnerabilityRawIdsMap: Dictionary<string[]>,
        public severeVulnerabilityMinSeverity: Severity,
        public vulnerabilityPackageNameToResolutionVersionsMap: Dictionary<string[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "VirtualMachineImageVulnerabilityRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            criticalVulnerabilities,
            highVulnerabilities,
            singleVirtualMachine,
            virtualMachineIds,
            vulnerabilitySeverityToCountMap,
            windowsUpdateDocumentationUrl);
    }
}

export class VirtualMachineMaliciousFileRisk extends VirtualMachineWorkloadAnalysisRisk implements ITypeNameObject, IRisk, IWorkloadResourceMaliciousFileRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        criticalVulnerabilities: WorkloadResourceRiskVulnerability[],
        highVulnerabilities: WorkloadResourceRiskVulnerability[],
        singleVirtualMachine: boolean,
        virtualMachineIds: string[],
        vulnerabilitySeverityToCountMap: Dictionary<number>,
        windowsUpdateDocumentationUrl: string,
        riskedVirtualMachineImage: boolean,
        virtualMachineImageId: Optional<string>,
        public fileContentSha256Strings: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "VirtualMachineMaliciousFileRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            criticalVulnerabilities,
            highVulnerabilities,
            singleVirtualMachine,
            virtualMachineIds,
            vulnerabilitySeverityToCountMap,
            windowsUpdateDocumentationUrl,
            riskedVirtualMachineImage,
            virtualMachineImageId);
    }
}

export class VirtualMachineOperatingSystemEndOfLifeRisk extends VirtualMachineWorkloadAnalysisRisk implements ITypeNameObject, IWorkloadResourceOperatingSystemRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        criticalVulnerabilities: WorkloadResourceRiskVulnerability[],
        highVulnerabilities: WorkloadResourceRiskVulnerability[],
        singleVirtualMachine: boolean,
        virtualMachineIds: string[],
        vulnerabilitySeverityToCountMap: Dictionary<number>,
        windowsUpdateDocumentationUrl: string,
        riskedVirtualMachineImage: boolean,
        virtualMachineImageId: Optional<string>,
        public operatingSystemDisplayName: string,
        public operatingSystemEndOfLifeDate: string,
        public operatingSystemType: OperatingSystemType,
        public packageScope: WorkloadResourceOperatingSystemRiskPackageScope) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "VirtualMachineOperatingSystemEndOfLifeRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            criticalVulnerabilities,
            highVulnerabilities,
            singleVirtualMachine,
            virtualMachineIds,
            vulnerabilitySeverityToCountMap,
            windowsUpdateDocumentationUrl,
            riskedVirtualMachineImage,
            virtualMachineImageId);
    }
}

export class VirtualMachineOperatingSystemUnpatchedRisk extends VirtualMachineWorkloadAnalysisRisk implements ITypeNameObject, IWorkloadResourceOperatingSystemUnpatchedRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        criticalVulnerabilities: WorkloadResourceRiskVulnerability[],
        highVulnerabilities: WorkloadResourceRiskVulnerability[],
        singleVirtualMachine: boolean,
        virtualMachineIds: string[],
        vulnerabilitySeverityToCountMap: Dictionary<number>,
        windowsUpdateDocumentationUrl: string,
        riskedVirtualMachineImage: boolean,
        virtualMachineImageId: Optional<string>,
        public criticalExploitableVulnerabilityPackageNames: string[],
        public criticalNonExploitableVulnerabilityPackageNames: string[],
        public highExploitableVulnerabilityPackageNames: string[],
        public highNonExploitableVulnerabilityPackageNames: string[],
        public operatingSystemDisplayName: string,
        public operatingSystemType: OperatingSystemType,
        public packageScope: WorkloadResourceOperatingSystemRiskPackageScope,
        public vulnerabilityPackageNames: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "VirtualMachineOperatingSystemUnpatchedRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            criticalVulnerabilities,
            highVulnerabilities,
            singleVirtualMachine,
            virtualMachineIds,
            vulnerabilitySeverityToCountMap,
            windowsUpdateDocumentationUrl,
            riskedVirtualMachineImage,
            virtualMachineImageId);
    }
}

export class VirtualMachineVulnerabilityRisk extends VirtualMachineWorkloadAnalysisRisk implements ITypeNameObject, IWorkloadResourceVulnerabilityRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        criticalVulnerabilities: WorkloadResourceRiskVulnerability[],
        highVulnerabilities: WorkloadResourceRiskVulnerability[],
        singleVirtualMachine: boolean,
        virtualMachineIds: string[],
        vulnerabilitySeverityToCountMap: Dictionary<number>,
        windowsUpdateDocumentationUrl: string,
        riskedVirtualMachineImage: boolean,
        virtualMachineImageId: Optional<string>,
        public filePathToPackageNamesMap: Dictionary<string[]>,
        public filePathToVulnerabilityRawIdsMap: Dictionary<string[]>,
        public severeVulnerabilityMinSeverity: Severity,
        public virtualMachineImageVulnerabilityPackageNameToResolutionVersionsMap: Optional<Dictionary<string[]>>,
        public vulnerabilityPackageNameToResolutionVersionsMap: Dictionary<string[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "VirtualMachineVulnerabilityRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            criticalVulnerabilities,
            highVulnerabilities,
            singleVirtualMachine,
            virtualMachineIds,
            vulnerabilitySeverityToCountMap,
            windowsUpdateDocumentationUrl,
            riskedVirtualMachineImage,
            virtualMachineImageId);
    }
}

export class AadDirectoryApplicationCertificate extends AadDirectoryApplicationObject implements ITypeNameObject, IDirectoryEntity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        creationTime: Optional<string>,
        rawId: string,
        applicationId: string,
        description: Optional<string>,
        expirationStatus: AadDirectoryApplicationObjectExpirationStatus,
        expirationTime: string,
        public thumbprint: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AadDirectoryApplicationCertificate",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            creationTime,
            rawId,
            applicationId,
            description,
            expirationStatus,
            expirationTime);
    }
}

export class AadDirectoryApplicationCertificateModel extends AadDirectoryApplicationObjectModel implements ITypeNameObject, IEntityModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        partitionType: AadPartitionType,
        applicationOwnerPrincipalIds: string[],
        applicationRawId: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AadDirectoryApplicationCertificateModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            partitionType,
            applicationOwnerPrincipalIds,
            applicationRawId);
    }
}

export class AadDirectoryApplicationServicePrincipal extends AadDirectoryServicePrincipal implements ITypeNameObject, IDirectoryEntity, ITagEntity, IVendorServiceIdentity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        creationTime: Optional<string>,
        rawId: string,
        groupRawIds: string[],
        enabled: boolean,
        applicationRawId: string,
        applicationRoleDefinitions: AadDirectoryApplicationRoleDefinition[],
        applicationTemplateRawId: Optional<string>,
        assignmentRequired: Optional<boolean>,
        homepageRawUrl: Optional<string>,
        ownerPrincipalIds: string[],
        ownerTenantId: Optional<string>,
        signInEventsDisabled: boolean,
        tagExists: boolean,
        tags: ResourceTag[],
        type: AadDirectoryServicePrincipalType,
        visible: Optional<boolean>,
        public aws: Optional<AadDirectoryApplicationServicePrincipalAws>,
        public vendor: boolean,
        public vendors: Vendor[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AadDirectoryApplicationServicePrincipal",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            creationTime,
            rawId,
            groupRawIds,
            enabled,
            applicationRawId,
            applicationRoleDefinitions,
            applicationTemplateRawId,
            assignmentRequired,
            homepageRawUrl,
            ownerPrincipalIds,
            ownerTenantId,
            signInEventsDisabled,
            tagExists,
            tags,
            type,
            visible);
    }
}

export class AadDirectoryApplicationServicePrincipalInactiveRiskPolicyConfiguration extends AadDirectoryIdentityInactiveRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        includeDisabledIdentity: Optional<boolean>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AadDirectoryApplicationServicePrincipalInactiveRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            includeDisabledIdentity);
    }
}

export class AadDirectoryApplicationServicePrincipalModel extends AadDirectoryServicePrincipalModel implements ITypeNameObject, IUdmObjectModel, ITagEntityModel, IVendorServiceIdentityModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        partitionType: AadPartitionType,
        public access: Optional<ServiceIdentityModelAccess>,
        analyzing: Optional<AadDirectoryPrincipalModelAnalyzing>,
        azureRoleAssignmentResourceIds: string[],
        azureRoleDefinitionIds: string[],
        containedAzureRoleAssignmentResourceIds: string[],
        directoryRoleAssignmentResourceIds: string[],
        directoryRoleDefinitionIds: string[],
        groupIds: string[],
        inactive: boolean,
        assignedPrincipalIds: string[],
        assignedPrincipalIdToApplicationRoleDefinitionRawIdsMap: Dictionary<string[]>,
        microsoftGraphRoleAssignmentRoleDefinitions: AadDirectoryApplicationRoleDefinition[],
        public applicationId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AadDirectoryApplicationServicePrincipalModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            partitionType,
            access,
            analyzing,
            azureRoleAssignmentResourceIds,
            azureRoleDefinitionIds,
            containedAzureRoleAssignmentResourceIds,
            directoryRoleAssignmentResourceIds,
            directoryRoleDefinitionIds,
            groupIds,
            inactive,
            assignedPrincipalIds,
            assignedPrincipalIdToApplicationRoleDefinitionRawIdsMap,
            microsoftGraphRoleAssignmentRoleDefinitions);
    }
}

export class AadDirectoryDirectoryRoleAssignmentModel extends AadDirectoryDirectoryRoleAssignmentResourceModel implements ITypeNameObject, IEntityModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        partitionType: AadPartitionType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AadDirectoryDirectoryRoleAssignmentModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            partitionType);
    }
}

export class AadDirectoryDirectoryRoleAssignmentResource extends AadDirectoryDirectoryRoleEntity implements ITypeNameObject, IDirectoryEntity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        creationTime: Optional<string>,
        rawId: string,
        public principalId: string,
        public roleDefinitionId: string,
        public scopeInfo: AadDirectoryDirectoryRoleAssignmentResourceScopeInfo,
        public status: AadDirectoryDirectoryRoleAssignmentResourceStatus) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            creationTime,
            rawId);
    }
}

export class AadDirectoryDirectoryRoleDefinition extends AadDirectoryDirectoryRoleEntity implements ITypeNameObject, IDirectoryEntity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        creationTime: Optional<string>,
        rawId: string,
        public description: Optional<string>,
        public enabled: boolean,
        public permissions: AadDirectoryDirectoryRoleDefinitionPermission[],
        public privileged: boolean,
        public rawDefinition: string,
        public templateId: string,
        public type: AadDirectoryDirectoryRoleDefinitionType,
        public version: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AadDirectoryDirectoryRoleDefinition",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            creationTime,
            rawId);
    }
}

export class AadDirectoryDirectoryRoleEligibilitySchedule extends AadDirectoryDirectoryRoleAssignmentResource implements ITypeNameObject, IDirectoryEntity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        creationTime: Optional<string>,
        rawId: string,
        principalId: string,
        roleDefinitionId: string,
        scopeInfo: AadDirectoryDirectoryRoleAssignmentResourceScopeInfo,
        status: AadDirectoryDirectoryRoleAssignmentResourceStatus) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AadDirectoryDirectoryRoleEligibilitySchedule",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            creationTime,
            rawId,
            principalId,
            roleDefinitionId,
            scopeInfo,
            status);
    }
}

export class AadDirectoryManagedIdentityServicePrincipal extends AadDirectoryServicePrincipal implements ITypeNameObject, IDirectoryEntity, IServiceIdentity, ITagEntity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        creationTime: Optional<string>,
        rawId: string,
        groupRawIds: string[],
        enabled: boolean,
        applicationRawId: string,
        applicationRoleDefinitions: AadDirectoryApplicationRoleDefinition[],
        applicationTemplateRawId: Optional<string>,
        assignmentRequired: Optional<boolean>,
        homepageRawUrl: Optional<string>,
        ownerPrincipalIds: string[],
        ownerTenantId: Optional<string>,
        signInEventsDisabled: boolean,
        tagExists: boolean,
        tags: ResourceTag[],
        type: AadDirectoryServicePrincipalType,
        visible: Optional<boolean>,
        public systemManagedIdentityScopeEntityPath: Optional<string>,
        public userManagedIdentityPath: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AadDirectoryManagedIdentityServicePrincipal",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            creationTime,
            rawId,
            groupRawIds,
            enabled,
            applicationRawId,
            applicationRoleDefinitions,
            applicationTemplateRawId,
            assignmentRequired,
            homepageRawUrl,
            ownerPrincipalIds,
            ownerTenantId,
            signInEventsDisabled,
            tagExists,
            tags,
            type,
            visible);
    }
}

export class AadDirectoryManagedIdentityServicePrincipalModel extends AadDirectoryServicePrincipalModel implements ITypeNameObject, IUdmObjectModel, IServiceIdentityModel, ITagEntityModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        partitionType: AadPartitionType,
        public access: Optional<ServiceIdentityModelAccess>,
        analyzing: Optional<AadDirectoryPrincipalModelAnalyzing>,
        azureRoleAssignmentResourceIds: string[],
        azureRoleDefinitionIds: string[],
        containedAzureRoleAssignmentResourceIds: string[],
        directoryRoleAssignmentResourceIds: string[],
        directoryRoleDefinitionIds: string[],
        groupIds: string[],
        inactive: boolean,
        assignedPrincipalIds: string[],
        assignedPrincipalIdToApplicationRoleDefinitionRawIdsMap: Dictionary<string[]>,
        microsoftGraphRoleAssignmentRoleDefinitions: AadDirectoryApplicationRoleDefinition[],
        public managedIdentityId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AadDirectoryManagedIdentityServicePrincipalModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            partitionType,
            access,
            analyzing,
            azureRoleAssignmentResourceIds,
            azureRoleDefinitionIds,
            containedAzureRoleAssignmentResourceIds,
            directoryRoleAssignmentResourceIds,
            directoryRoleDefinitionIds,
            groupIds,
            inactive,
            assignedPrincipalIds,
            assignedPrincipalIdToApplicationRoleDefinitionRawIdsMap,
            microsoftGraphRoleAssignmentRoleDefinitions);
    }
}

export class AwsDeleteBucketPolicyChange extends AwsDeleteResourcePolicyChange implements ITypeNameObject, ICloudProviderResourceChange {
    constructor(
        entityIds: string[],
        id: string,
        resolved: boolean,
        resourceId: string) {
        super(
            "AwsDeleteBucketPolicyChange",
            entityIds,
            id,
            resolved,
            resourceId);
    }
}

export class AwsDeleteGroupChange extends AwsDeletePrincipalChange implements ITypeNameObject, ICloudProviderResourceChange, ICodeInfrastructureChange {
    constructor(
        entityIds: string[],
        id: string,
        resolved: boolean,
        resourceId: string) {
        super(
            "AwsDeleteGroupChange",
            entityIds,
            id,
            resolved,
            resourceId);
    }
}

export class AwsDeleteInlinePolicyChange extends AwsPrincipalPolicyChange implements ITypeNameObject, ICloudProviderResourceChange, ICodeInfrastructureChange {
    constructor(
        entityIds: string[],
        id: string,
        resolved: boolean,
        resourceId: string,
        principalId: string) {
        super(
            "AwsDeleteInlinePolicyChange",
            entityIds,
            id,
            resolved,
            resourceId,
            principalId);
    }
}

export class AwsDeleteRepositoryPolicyChange extends AwsDeleteResourcePolicyChange implements ITypeNameObject, ICloudProviderResourceChange {
    constructor(
        entityIds: string[],
        id: string,
        resolved: boolean,
        resourceId: string) {
        super(
            "AwsDeleteRepositoryPolicyChange",
            entityIds,
            id,
            resolved,
            resourceId);
    }
}

export class AwsDetachPrincipalManagedPolicyChange extends AwsPrincipalPolicyChange implements ITypeNameObject, ICloudProviderResourceChange, ICodeInfrastructureChange {
    constructor(
        entityIds: string[],
        id: string,
        resolved: boolean,
        resourceId: string,
        principalId: string,
        public deletePolicy: boolean) {
        super(
            "AwsDetachPrincipalManagedPolicyChange",
            entityIds,
            id,
            resolved,
            resourceId,
            principalId);
    }
}

export class AwsEc2SecurityGroupAllowedInboundRuleRiskPolicyConfiguration extends AwsEc2SecurityGroupInboundRuleRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        description: Optional<string>,
        name: string,
        severity: Severity,
        updateTime: string,
        allSecurityGroups: boolean,
        anyIpV4AddressSubnet: boolean,
        exactSubnets: Optional<string[]>,
        inboundAccessScope: Optional<NetworkAccessScope>,
        networkScopes: DestinationNetworkScope[],
        overlapSubnets: Optional<string[]>,
        securityGroupBuiltInAttributeTypeNames: Optional<string[]>,
        securityGroupCustomAttributeDefinitionIds: Optional<string[]>,
        securityGroupIds: Optional<string[]>,
        securityGroupTags: Optional<ResourceTag[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2SecurityGroupAllowedInboundRuleRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            description,
            name,
            severity,
            updateTime,
            allSecurityGroups,
            anyIpV4AddressSubnet,
            exactSubnets,
            inboundAccessScope,
            networkScopes,
            overlapSubnets,
            securityGroupBuiltInAttributeTypeNames,
            securityGroupCustomAttributeDefinitionIds,
            securityGroupIds,
            securityGroupTags);
    }
}

export class AwsElbApplicationLoadBalancer extends AwsElbLoadBalancer implements ITypeNameObject, IAwsDnsRecordResource, IAwsNetworkEvaluationNetworkedResource, IAwsTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        accessLogsEnabled: boolean,
        availabilityZones: AwsAvailabilityZone[],
        dnsName: string,
        dnsNames: string[],
        external: boolean,
        networkInterfaceIds: string[],
        privateIpAddresses: string[],
        publicIpAddresses: string[],
        securityGroupIds: string[],
        subnetIds: string[],
        vpcIds: string[],
        vpcRawId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsElbApplicationLoadBalancer",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            accessLogsEnabled,
            availabilityZones,
            dnsName,
            dnsNames,
            external,
            networkInterfaceIds,
            privateIpAddresses,
            publicIpAddresses,
            securityGroupIds,
            subnetIds,
            vpcIds,
            vpcRawId);
    }
}

export class AwsElbApplicationLoadBalancerListener extends AwsElbModernLoadBalancerListener implements ITypeNameObject, IAwsCertificateResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        certificateIds: string[],
        loadBalancerName: string,
        loadBalancerReference: AwsResourceReference,
        port: number,
        protocol: AwsElbProtocol,
        securityPolicy: Optional<AwsElbLoadBalancerListenerSecurityPolicy>,
        targetGroupIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsElbApplicationLoadBalancerListener",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            certificateIds,
            loadBalancerName,
            loadBalancerReference,
            port,
            protocol,
            securityPolicy,
            targetGroupIds);
    }
}

export class AwsElbClassicLoadBalancer extends AwsElbLoadBalancer implements ITypeNameObject, IAwsDnsRecordResource, IAwsNetworkEvaluationNetworkedResource, IAwsTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        accessLogsEnabled: boolean,
        availabilityZones: AwsAvailabilityZone[],
        dnsName: string,
        dnsNames: string[],
        external: boolean,
        networkInterfaceIds: string[],
        privateIpAddresses: string[],
        publicIpAddresses: string[],
        securityGroupIds: string[],
        subnetIds: string[],
        vpcIds: string[],
        vpcRawId: Optional<string>,
        public crossAvailabilityZoneEnabled: boolean,
        public instanceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsElbClassicLoadBalancer",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            accessLogsEnabled,
            availabilityZones,
            dnsName,
            dnsNames,
            external,
            networkInterfaceIds,
            privateIpAddresses,
            publicIpAddresses,
            securityGroupIds,
            subnetIds,
            vpcIds,
            vpcRawId);
    }
}

export class AwsElbClassicLoadBalancerListener extends AwsElbLoadBalancerListener implements ITypeNameObject, IAwsCertificateResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        certificateIds: string[],
        loadBalancerName: string,
        loadBalancerReference: AwsResourceReference,
        port: number,
        protocol: AwsElbProtocol,
        securityPolicy: Optional<AwsElbLoadBalancerListenerSecurityPolicy>,
        public targetPort: number,
        public targetProtocol: AwsElbProtocol) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsElbClassicLoadBalancerListener",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            certificateIds,
            loadBalancerName,
            loadBalancerReference,
            port,
            protocol,
            securityPolicy);
    }
}

export class AwsExcessivePermissionGroupRisk extends AwsExcessivePermissionIamPrincipalRisk implements ITypeNameObject, IAwsExcessivePermissionPrincipalRisk {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        analysisGroupTypes: RiskPolicyTypeMetadataAnalysisGroupType[],
        ignoreExpirationDate: Optional<string>,
        items: RiskItem[],
        limitedResults: boolean,
        openStatusUpdateTime: string,
        policyId: string,
        relatedEntityIds: string[],
        resolutionChanges: Optional<ChangeBase[]>,
        resolutionReason: Optional<RiskResolutionReason>,
        riskedEntityIds: string[],
        scopeIds: string[],
        severity: Severity,
        status: RiskStatus,
        statusUpdateTime: string,
        subStatus: RiskSubStatus,
        tenantType: TenantType,
        entityId: string,
        severityReason: Optional<RiskSeverityReason>,
        activityTime: Optional<string>,
        excessivePermissionActionSeverity: Optional<Severity>,
        groupUserIds: string[],
        permissionActions: string[],
        roleMostIndirectOriginatorResourceIds: string[],
        roleOriginatorResourceIds: string[],
        sensitiveResources: AwsAccessPrincipalRiskSensitiveResources,
        serviceIdToUsageTimeMap: Dictionary<Optional<string>>,
        policyAssociationAndInfos: AwsExcessivePermissionPrincipalRiskPolicyAssociationAndInfo[],
        resolutionChangeIdToPolicyInfoMap: Dictionary<AwsExcessivePermissionPrincipalRiskResolutionPolicyInfo>,
        resourceLevelServiceIds: string[],
        serviceLevel: Optional<AwsPrincipalNonexcessivePolicyGeneratorServiceLevel>,
        serviceResourceLevel: Optional<AwsPrincipalNonexcessivePolicyGeneratorServiceResourceLevel>,
        serviceResourceLevelNotUsedPermissionActionResourceIds: string[],
        serviceResourceLevels: AwsPrincipalNonexcessivePolicyGeneratorServiceResourceLevel[],
        roleAssumingEntityIds: string[],
        userExcessiveGroupMembershipGroupIds: string[],
        userExcessiveGroupMembershipPermissionActionSeverity: Optional<Severity>,
        userExcessiveGroupMembershipPermissionActionSeverityToGroupIdsMap: Dictionary<string[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsExcessivePermissionGroupRisk",
            tenantId,
            analysisGroupTypes,
            ignoreExpirationDate,
            items,
            limitedResults,
            openStatusUpdateTime,
            policyId,
            relatedEntityIds,
            resolutionChanges,
            resolutionReason,
            riskedEntityIds,
            scopeIds,
            severity,
            status,
            statusUpdateTime,
            subStatus,
            tenantType,
            entityId,
            severityReason,
            activityTime,
            excessivePermissionActionSeverity,
            groupUserIds,
            permissionActions,
            roleMostIndirectOriginatorResourceIds,
            roleOriginatorResourceIds,
            sensitiveResources,
            serviceIdToUsageTimeMap,
            policyAssociationAndInfos,
            resolutionChangeIdToPolicyInfoMap,
            resourceLevelServiceIds,
            serviceLevel,
            serviceResourceLevel,
            serviceResourceLevelNotUsedPermissionActionResourceIds,
            serviceResourceLevels,
            roleAssumingEntityIds,
            userExcessiveGroupMembershipGroupIds,
            userExcessiveGroupMembershipPermissionActionSeverity,
            userExcessiveGroupMembershipPermissionActionSeverityToGroupIdsMap);
    }
}

export class AwsExcessivePermissionGroupRiskModel extends AwsExcessivePermissionIamPrincipalRiskModel implements ITypeNameObject, IRiskModel, IUdmObjectModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        customComplianceIds: string[],
        entityAttributes: EntityAttribute[],
        files: RiskFile[],
        openStatusTimeFrameHours: number,
        public risk: AwsExcessivePermissionIamPrincipalRisk,
        riskConfiguration: Optional<RiskConfiguration>,
        riskedEntityEnvironment: Optional<EntityStringProperty>,
        riskedEntityOwner: Optional<EntityPrincipalReferenceProperty>,
        riskedEntityTypeName: string,
        riskState: Optional<RiskState>,
        userActiveAccessKeyCount: Optional<number>,
        lastUsedServiceInfo: Optional<AwsExcessivePermissionPrincipalRiskModelServiceInfo>,
        serviceIds: string[],
        serviceInfos: AwsExcessivePermissionPrincipalRiskModelServiceInfo[],
        infos: AwsExcessivePermissionIamPrincipalRiskModelInfo[],
        lastUsedExcessivePermissionServiceInfo: Optional<AwsExcessivePermissionPrincipalRiskModelServiceInfo>,
        policyIds: string[],
        upsertPrincipalManagedPolicyChangeIdToFileTypeToFileMap: Dictionary<Dictionary<RiskFile>>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsExcessivePermissionGroupRiskModel",
            tenantId,
            customComplianceIds,
            entityAttributes,
            files,
            openStatusTimeFrameHours,
            risk,
            riskConfiguration,
            riskedEntityEnvironment,
            riskedEntityOwner,
            riskedEntityTypeName,
            riskState,
            userActiveAccessKeyCount,
            lastUsedServiceInfo,
            serviceIds,
            serviceInfos,
            infos,
            lastUsedExcessivePermissionServiceInfo,
            policyIds,
            upsertPrincipalManagedPolicyChangeIdToFileTypeToFileMap);
    }
}

export class AwsIamGroup extends AwsIamPrincipal implements ITypeNameObject, IAwsPrincipal {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        path: string,
        inlinePolicyIds: string[],
        managedPolicyIds: string[],
        permissionUsageTime: Optional<string>,
        permissionUsageTimeUpdateTime: string,
        uniqueId: string,
        public userIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamGroup",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            path,
            inlinePolicyIds,
            managedPolicyIds,
            permissionUsageTime,
            permissionUsageTimeUpdateTime,
            uniqueId);
    }
}

export class AwsIamIdentity extends AwsIamPrincipal implements ITypeNameObject, IAwsPrincipal, IAwsIdentity, IAwsOriginatorIdentity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        path: string,
        inlinePolicyIds: string[],
        managedPolicyIds: string[],
        permissionUsageTime: Optional<string>,
        permissionUsageTimeUpdateTime: string,
        uniqueId: string,
        public activityTime: Optional<string>,
        public permissionBoundaryPolicyId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            path,
            inlinePolicyIds,
            managedPolicyIds,
            permissionUsageTime,
            permissionUsageTimeUpdateTime,
            uniqueId);
    }
}

export class AwsIamInlinePolicy extends AwsIamPrincipalPolicy implements ITypeNameObject, IAwsResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        document: AwsIamPolicyDocument,
        public permissionUsageTime: Optional<string>,
        public principalReference: EntityReference,
        public serviceIdToPermissionUsageTimeMap: Dictionary<Optional<string>>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamInlinePolicy",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            document);
    }
}

export class AwsIamManagedPolicy extends AwsIamPrincipalPolicy implements ITypeNameObject, IAwsTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        document: AwsIamPolicyDocument,
        public awsManaged: boolean,
        public defaultRevisionId: string,
        public path: string,
        public permissionAttachedPrincipalCount: Optional<number>,
        public permissionBoundaryAttachedIdentityCount: Optional<number>,
        public permissionServiceIds: string[],
        public principalAttachable: boolean,
        public revisionMap: Dictionary<AwsIamManagedPolicyRevision>,
        public serviceLinkedRolePolicy: boolean,
        public uniqueId: string,
        public updateTime: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamManagedPolicy",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            document);
    }
}

export class AwsIamRole extends AwsIamIdentity implements ITypeNameObject, IAwsPrincipal, IAwsIdentity, IAwsOriginatorIdentity, IAwsAccessResource, IAwsPolicyResource, IAwsTagResource, IEntityGroupTagEntity, IVendorServiceIdentity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        path: string,
        inlinePolicyIds: string[],
        managedPolicyIds: string[],
        permissionUsageTime: Optional<string>,
        permissionUsageTimeUpdateTime: string,
        uniqueId: string,
        activityTime: Optional<string>,
        permissionBoundaryPolicyId: Optional<string>,
        public accessLevel: Optional<AwsResourceAccessLevel>,
        public assumeRolePolicyDocument: AwsIamPolicyDocument,
        public assumeRolePolicyDocumentAllowStatementGrantees: AwsIamPolicyDocumentStatementGrantees[],
        public policyDocument: AwsIamPolicyDocument,
        public policyDocumentExists: boolean,
        public serviceLinked: boolean,
        public sessionMaxDuration: number,
        public usageTime: Optional<string>,
        public vendor: boolean,
        public vendors: Vendor[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamRole",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            path,
            inlinePolicyIds,
            managedPolicyIds,
            permissionUsageTime,
            permissionUsageTimeUpdateTime,
            uniqueId,
            activityTime,
            permissionBoundaryPolicyId);
    }
}

export class AwsIamUser extends AwsIamIdentity implements ITypeNameObject, IAwsPrincipal, IAwsIdentity, IAwsOriginatorIdentity, IAwsTagResource, IUser {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        path: string,
        inlinePolicyIds: string[],
        managedPolicyIds: string[],
        permissionUsageTime: Optional<string>,
        permissionUsageTimeUpdateTime: string,
        uniqueId: string,
        activityTime: Optional<string>,
        permissionBoundaryPolicyId: Optional<string>,
        public accessKey1: Optional<AwsIamUserAccessKey>,
        public accessKey2: Optional<AwsIamUserAccessKey>,
        public mfaEnabled: boolean,
        public password: Optional<AwsIamUserPassword>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamUser",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            path,
            inlinePolicyIds,
            managedPolicyIds,
            permissionUsageTime,
            permissionUsageTimeUpdateTime,
            uniqueId,
            activityTime,
            permissionBoundaryPolicyId);
    }
}

export class AwsIdentityActivityRiskPolicyConfiguration extends CustomActivityRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        description: Optional<string>,
        name: string,
        severity: Severity,
        updateTime: string,
        public identityBuiltInAttributeTypeNames: Optional<string[]>,
        public identityCustomAttributeDefinitionIds: Optional<string[]>,
        public identityIds: Optional<string[]>,
        public identityTags: Optional<ResourceTag[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIdentityActivityRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            description,
            name,
            severity,
            updateTime);
    }
}

export class AwsKubernetesClusterRole extends AwsKubernetesResource<KubernetesClusterRoleData> implements ITypeNameObject, IAwsResource, IKubernetesClusterRole {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesClusterRoleData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKubernetesClusterRole",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class AwsKubernetesClusterRoleBinding extends AwsKubernetesResource<KubernetesClusterRoleBindingData> implements ITypeNameObject, IAwsResource, IKubernetesClusterRoleBinding {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesClusterRoleBindingData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKubernetesClusterRoleBinding",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class AwsKubernetesConfigMapModel extends AwsKubernetesNamespaceResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesNamespaceResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKubernetesConfigMapModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsKubernetesCronJobModel extends AwsKubernetesWorkloadResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesWorkloadResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        awsRoleIds: string[],
        containerImageIdReferences: string[],
        containerImageRawUrlToDataMap: Dictionary<ContainerImageResourceStateContainerImageData>,
        containerImageScanStatus: Optional<ContainerImageDataScanStatus>,
        containerImageVulnerabilities: VulnerabilityData[],
        controllerResourceReference: Optional<EntitySearchableIdReference>,
        fargateProfileId: Optional<string>,
        nodeGroupIds: string[],
        serviceAccountReference: EntitySearchableIdReference) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKubernetesCronJobModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId,
            awsRoleIds,
            containerImageIdReferences,
            containerImageRawUrlToDataMap,
            containerImageScanStatus,
            containerImageVulnerabilities,
            controllerResourceReference,
            fargateProfileId,
            nodeGroupIds,
            serviceAccountReference);
    }
}

export class AwsKubernetesDaemonSetModel extends AwsKubernetesWorkloadResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesWorkloadResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        awsRoleIds: string[],
        containerImageIdReferences: string[],
        containerImageRawUrlToDataMap: Dictionary<ContainerImageResourceStateContainerImageData>,
        containerImageScanStatus: Optional<ContainerImageDataScanStatus>,
        containerImageVulnerabilities: VulnerabilityData[],
        controllerResourceReference: Optional<EntitySearchableIdReference>,
        fargateProfileId: Optional<string>,
        nodeGroupIds: string[],
        serviceAccountReference: EntitySearchableIdReference) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKubernetesDaemonSetModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId,
            awsRoleIds,
            containerImageIdReferences,
            containerImageRawUrlToDataMap,
            containerImageScanStatus,
            containerImageVulnerabilities,
            controllerResourceReference,
            fargateProfileId,
            nodeGroupIds,
            serviceAccountReference);
    }
}

export class AwsKubernetesDeploymentModel extends AwsKubernetesWorkloadResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesDeploymentModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        awsRoleIds: string[],
        containerImageIdReferences: string[],
        containerImageRawUrlToDataMap: Dictionary<ContainerImageResourceStateContainerImageData>,
        containerImageScanStatus: Optional<ContainerImageDataScanStatus>,
        containerImageVulnerabilities: VulnerabilityData[],
        controllerResourceReference: Optional<EntitySearchableIdReference>,
        fargateProfileId: Optional<string>,
        nodeGroupIds: string[],
        serviceAccountReference: EntitySearchableIdReference,
        public replicaSetIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKubernetesDeploymentModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId,
            awsRoleIds,
            containerImageIdReferences,
            containerImageRawUrlToDataMap,
            containerImageScanStatus,
            containerImageVulnerabilities,
            controllerResourceReference,
            fargateProfileId,
            nodeGroupIds,
            serviceAccountReference);
    }
}

export class AwsKubernetesGroup extends AwsKubernetesResource<KubernetesGroupData> implements ITypeNameObject, IAwsResource, IKubernetesGroup {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesGroupData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKubernetesGroup",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class AwsKubernetesIngressClass extends AwsKubernetesResource<KubernetesIngressClassData> implements ITypeNameObject, IAwsResource, IKubernetesIngressClass {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesIngressClassData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKubernetesIngressClass",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class AwsKubernetesIngressModel extends AwsKubernetesNamespaceResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesIngressModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        public loadBalancerSearchableReference: Optional<EntitySearchableReference>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKubernetesIngressModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId);
    }
}

export class AwsKubernetesJobModel extends AwsKubernetesWorkloadResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesWorkloadResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        awsRoleIds: string[],
        containerImageIdReferences: string[],
        containerImageRawUrlToDataMap: Dictionary<ContainerImageResourceStateContainerImageData>,
        containerImageScanStatus: Optional<ContainerImageDataScanStatus>,
        containerImageVulnerabilities: VulnerabilityData[],
        controllerResourceReference: Optional<EntitySearchableIdReference>,
        fargateProfileId: Optional<string>,
        nodeGroupIds: string[],
        serviceAccountReference: EntitySearchableIdReference) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKubernetesJobModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId,
            awsRoleIds,
            containerImageIdReferences,
            containerImageRawUrlToDataMap,
            containerImageScanStatus,
            containerImageVulnerabilities,
            controllerResourceReference,
            fargateProfileId,
            nodeGroupIds,
            serviceAccountReference);
    }
}

export class AwsKubernetesNamespace extends AwsKubernetesResource<KubernetesNamespaceData> implements ITypeNameObject, IAwsResource, IKubernetesNamespace {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesNamespaceData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKubernetesNamespace",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class AwsKubernetesNamespaceResourceBase extends AwsKubernetesResource<KubernetesNamespaceResourceData> implements ITypeNameObject, IAwsResource, IKubernetesNamespaceResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesNamespaceResourceData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class AwsKubernetesNode extends AwsKubernetesResource<KubernetesNodeData> implements ITypeNameObject, IAwsResource, IKubernetesNode {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesNodeData,
        public instance: Optional<AwsKubernetesNodeInstance>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKubernetesNode",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class AwsKubernetesPersistentVolume extends AwsKubernetesResource<KubernetesPersistentVolumeData> implements ITypeNameObject, IAwsResource, IKubernetesPersistentVolume {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesPersistentVolumeData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKubernetesPersistentVolume",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class AwsKubernetesPodSecurityPolicy extends AwsKubernetesResource<KubernetesPodSecurityPolicyData> implements ITypeNameObject, IAwsResource, IKubernetesPodSecurityPolicy {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesPodSecurityPolicyData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKubernetesPodSecurityPolicy",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class AwsKubernetesReplicaSetModel extends AwsKubernetesWorkloadResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesWorkloadResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        awsRoleIds: string[],
        containerImageIdReferences: string[],
        containerImageRawUrlToDataMap: Dictionary<ContainerImageResourceStateContainerImageData>,
        containerImageScanStatus: Optional<ContainerImageDataScanStatus>,
        containerImageVulnerabilities: VulnerabilityData[],
        controllerResourceReference: Optional<EntitySearchableIdReference>,
        fargateProfileId: Optional<string>,
        nodeGroupIds: string[],
        serviceAccountReference: EntitySearchableIdReference) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKubernetesReplicaSetModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId,
            awsRoleIds,
            containerImageIdReferences,
            containerImageRawUrlToDataMap,
            containerImageScanStatus,
            containerImageVulnerabilities,
            controllerResourceReference,
            fargateProfileId,
            nodeGroupIds,
            serviceAccountReference);
    }
}

export class AwsKubernetesStatefulSetModel extends AwsKubernetesWorkloadResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesWorkloadResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        awsRoleIds: string[],
        containerImageIdReferences: string[],
        containerImageRawUrlToDataMap: Dictionary<ContainerImageResourceStateContainerImageData>,
        containerImageScanStatus: Optional<ContainerImageDataScanStatus>,
        containerImageVulnerabilities: VulnerabilityData[],
        controllerResourceReference: Optional<EntitySearchableIdReference>,
        fargateProfileId: Optional<string>,
        nodeGroupIds: string[],
        serviceAccountReference: EntitySearchableIdReference) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKubernetesStatefulSetModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId,
            awsRoleIds,
            containerImageIdReferences,
            containerImageRawUrlToDataMap,
            containerImageScanStatus,
            containerImageVulnerabilities,
            controllerResourceReference,
            fargateProfileId,
            nodeGroupIds,
            serviceAccountReference);
    }
}

export class AwsPrincipalAllowedResourcePermissionRiskPolicyConfiguration extends AwsPrincipalResourcePermissionRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        description: Optional<string>,
        name: string,
        severity: Severity,
        updateTime: string,
        actionNamePatterns: Optional<string[]>,
        actionRiskCategories: Optional<ActionRiskCategory[]>,
        actions: Optional<string[]>,
        excludePrincipalMatchConditions: EntityMatchCondition[],
        excludeResourceMatchConditions: EntityMatchCondition[],
        principalMatchConditions: EntityMatchCondition[],
        resourceMatchConditions: EntityMatchCondition[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsPrincipalAllowedResourcePermissionRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            description,
            name,
            severity,
            updateTime,
            actionNamePatterns,
            actionRiskCategories,
            actions,
            excludePrincipalMatchConditions,
            excludeResourceMatchConditions,
            principalMatchConditions,
            resourceMatchConditions);
    }
}

export class AwsPrincipalNotAllowedResourcePermissionRiskPolicyConfiguration extends AwsPrincipalResourcePermissionRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        description: Optional<string>,
        name: string,
        severity: Severity,
        updateTime: string,
        actionNamePatterns: Optional<string[]>,
        actionRiskCategories: Optional<ActionRiskCategory[]>,
        actions: Optional<string[]>,
        excludePrincipalMatchConditions: EntityMatchCondition[],
        excludeResourceMatchConditions: EntityMatchCondition[],
        principalMatchConditions: EntityMatchCondition[],
        resourceMatchConditions: EntityMatchCondition[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsPrincipalNotAllowedResourcePermissionRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            description,
            name,
            severity,
            updateTime,
            actionNamePatterns,
            actionRiskCategories,
            actions,
            excludePrincipalMatchConditions,
            excludeResourceMatchConditions,
            principalMatchConditions,
            resourceMatchConditions);
    }
}

export class AwsRdsAuroraGlobalCluster extends AwsRdsGlobalClusterResource implements ITypeNameObject, IAwsEncryptedResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        rawId: string,
        resourceId: string,
        clusterArns: string[],
        deleteProtection: boolean,
        encryptionExists: boolean,
        engineVersion: string,
        kmsEncryptionKeyOrAliasArns: string[],
        status: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsAuroraGlobalCluster",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            rawId,
            resourceId,
            clusterArns,
            deleteProtection,
            encryptionExists,
            engineVersion,
            kmsEncryptionKeyOrAliasArns,
            status);
    }
}

export class AwsRdsCluster extends AwsRdsClusterResource implements ITypeNameObject, IAwsCloudWatchLoggingResource, IAwsEncryptedResource, IAwsTagResource, IAwsNetworkEvaluationNetworkedResource, IDataAnalysisResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        rawId: string,
        resourceId: string,
        availabilityZones: AwsAvailabilityZone[],
        backupRetentionTimeFrame: string,
        cloudWatchLoggingEnabled: boolean,
        cloudWatchLogTypes: string[],
        deleteProtection: boolean,
        encryptionExists: boolean,
        engineVersion: string,
        globalClusterId: Optional<string>,
        kmsEncryptionKeyOrAliasArns: string[],
        securityGroupIds: string[],
        status: string,
        storageKmsEncryptionKeyOrAliasArn: Optional<string>,
        storageSize: number,
        subnetIds: string[],
        vpcIds: string[],
        public engineType: AwsRdsClusterEngineType,
        public instanceRawType: Optional<string>,
        public minorVersionUpgradeEnabled: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsCluster",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            rawId,
            resourceId,
            availabilityZones,
            backupRetentionTimeFrame,
            cloudWatchLoggingEnabled,
            cloudWatchLogTypes,
            deleteProtection,
            encryptionExists,
            engineVersion,
            globalClusterId,
            kmsEncryptionKeyOrAliasArns,
            securityGroupIds,
            status,
            storageKmsEncryptionKeyOrAliasArn,
            storageSize,
            subnetIds,
            vpcIds);
    }
}

export class AwsRdsClusterSnapshot extends AwsRdsSnapshot implements ITypeNameObject, IAwsAccessResource, IAwsEncryptedResource, IAwsTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        rawId: string,
        resourceId: string,
        accessLevel: Optional<AwsResourceAccessLevel>,
        encryptionExists: boolean,
        kmsEncryptionKeyOrAliasArns: string[],
        rawStatus: string,
        sharedTenantRawIds: string[],
        storageKmsEncryptionKeyOrAliasArn: Optional<string>,
        storageSize: number,
        type: AwsResourceSnapshotType,
        vpcId: Optional<string>,
        sourceResourceId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsClusterSnapshot",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            rawId,
            resourceId,
            accessLevel,
            encryptionExists,
            kmsEncryptionKeyOrAliasArns,
            rawStatus,
            sharedTenantRawIds,
            storageKmsEncryptionKeyOrAliasArn,
            storageSize,
            type,
            vpcId,
            sourceResourceId);
    }
}

export class AwsRdsClusterSnapshotModel extends AwsRdsSnapshotModel implements ITypeNameObject, IUdmObjectModel, IAwsAccessResourceModel, IAwsEncryptedResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        accessLevel: Optional<AwsResourceAccessLevel>,
        kmsEncryptionKeyIdReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsClusterSnapshotModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId,
            accessLevel,
            kmsEncryptionKeyIdReferences);
    }
}

export class AwsRdsDatabaseInstance extends AwsRdsDatabaseInstanceResource implements ITypeNameObject, IAwsCloudWatchLoggingResource, IAwsDnsRecordResource, IAwsEncryptedResource, IAwsNetworkEvaluationNetworkedResource, IAwsTagResource, IDataAnalysisResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        rawId: string,
        resourceId: string,
        availabilityZone: AwsAvailabilityZone,
        backupRetentionTimeFrame: string,
        classicDatabaseSecurityGroupIds: string[],
        cloudWatchLoggingEnabled: boolean,
        cloudWatchLogTypes: string[],
        clusterId: Optional<string>,
        deleteProtection: boolean,
        dnsNames: string[],
        encryptionExists: boolean,
        endpoint: string,
        engineVersion: string,
        enhancedMonitoringResourceArn: Optional<string>,
        instanceRawType: string,
        kmsEncryptionKeyOrAliasArns: string[],
        minorVersionUpgradeEnabled: boolean,
        monitoringRoleReference: Optional<AwsResourceReference>,
        networkInterfaceIds: string[],
        publicIpAddressEnabled: boolean,
        publicIpAddresses: string[],
        securityGroupIds: string[],
        status: string,
        storageKmsEncryptionKeyOrAliasArn: Optional<string>,
        storageSize: number,
        subnetIds: string[],
        vpcIds: string[],
        vpcRawId: Optional<string>,
        public engineType: AwsRdsDatabaseInstanceEngineType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsDatabaseInstance",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            rawId,
            resourceId,
            availabilityZone,
            backupRetentionTimeFrame,
            classicDatabaseSecurityGroupIds,
            cloudWatchLoggingEnabled,
            cloudWatchLogTypes,
            clusterId,
            deleteProtection,
            dnsNames,
            encryptionExists,
            endpoint,
            engineVersion,
            enhancedMonitoringResourceArn,
            instanceRawType,
            kmsEncryptionKeyOrAliasArns,
            minorVersionUpgradeEnabled,
            monitoringRoleReference,
            networkInterfaceIds,
            publicIpAddressEnabled,
            publicIpAddresses,
            securityGroupIds,
            status,
            storageKmsEncryptionKeyOrAliasArn,
            storageSize,
            subnetIds,
            vpcIds,
            vpcRawId);
    }
}

export class AwsRdsDatabaseInstanceSnapshot extends AwsRdsSnapshot implements ITypeNameObject, IAwsAccessResource, IAwsEncryptedResource, IAwsTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        rawId: string,
        resourceId: string,
        accessLevel: Optional<AwsResourceAccessLevel>,
        encryptionExists: boolean,
        kmsEncryptionKeyOrAliasArns: string[],
        rawStatus: string,
        sharedTenantRawIds: string[],
        storageKmsEncryptionKeyOrAliasArn: Optional<string>,
        storageSize: number,
        type: AwsResourceSnapshotType,
        vpcId: Optional<string>,
        sourceResourceId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsDatabaseInstanceSnapshot",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            rawId,
            resourceId,
            accessLevel,
            encryptionExists,
            kmsEncryptionKeyOrAliasArns,
            rawStatus,
            sharedTenantRawIds,
            storageKmsEncryptionKeyOrAliasArn,
            storageSize,
            type,
            vpcId,
            sourceResourceId);
    }
}

export class AwsRdsDatabaseInstanceSnapshotModel extends AwsRdsSnapshotModel implements ITypeNameObject, IUdmObjectModel, IAwsAccessResourceModel, IAwsEncryptedResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        entity: Entity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorEntityIdReference: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        stackId: Optional<string>,
        accessLevel: Optional<AwsResourceAccessLevel>,
        kmsEncryptionKeyIdReferences: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsDatabaseInstanceSnapshotModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorEntityIdReference,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            stackId,
            accessLevel,
            kmsEncryptionKeyIdReferences);
    }
}

export class AwsRdsDocDbGlobalCluster extends AwsRdsGlobalClusterResource implements ITypeNameObject, IAwsEncryptedResource, IAwsRdsDocDbResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        rawId: string,
        resourceId: string,
        clusterArns: string[],
        deleteProtection: boolean,
        encryptionExists: boolean,
        engineVersion: string,
        kmsEncryptionKeyOrAliasArns: string[],
        status: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsRdsDocDbGlobalCluster",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            rawId,
            resourceId,
            clusterArns,
            deleteProtection,
            encryptionExists,
            engineVersion,
            kmsEncryptionKeyOrAliasArns,
            status);
    }
}

export class AwsResourceUsageRiskPolicyConfiguration extends CustomActivityRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        description: Optional<string>,
        name: string,
        severity: Severity,
        updateTime: string,
        public allResources: Optional<boolean>,
        public resourceBuiltInAttributeTypeNames: Optional<string[]>,
        public resourceCustomAttributeDefinitionIds: Optional<string[]>,
        public resourceIds: Optional<string[]>,
        public resourceNamePattern: Optional<string>,
        public resourceTags: Optional<ResourceTag[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            description,
            name,
            severity,
            updateTime);
    }
}

export class AwsS3BucketManagementRiskPolicyConfiguration extends AwsResourceUsageRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        description: Optional<string>,
        name: string,
        severity: Severity,
        updateTime: string,
        allResources: Optional<boolean>,
        resourceBuiltInAttributeTypeNames: Optional<string[]>,
        resourceCustomAttributeDefinitionIds: Optional<string[]>,
        resourceIds: Optional<string[]>,
        resourceNamePattern: Optional<string>,
        resourceTags: Optional<ResourceTag[]>,
        public sensitiveBucket: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsS3BucketManagementRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            description,
            name,
            severity,
            updateTime,
            allResources,
            resourceBuiltInAttributeTypeNames,
            resourceCustomAttributeDefinitionIds,
            resourceIds,
            resourceNamePattern,
            resourceTags);
    }
}

export class AwsSecretsManagerSecretManagementRiskPolicyConfiguration extends AwsResourceUsageRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        description: Optional<string>,
        name: string,
        severity: Severity,
        updateTime: string,
        allResources: Optional<boolean>,
        resourceBuiltInAttributeTypeNames: Optional<string[]>,
        resourceCustomAttributeDefinitionIds: Optional<string[]>,
        resourceIds: Optional<string[]>,
        resourceNamePattern: Optional<string>,
        resourceTags: Optional<ResourceTag[]>,
        public sensitiveSecret: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSecretsManagerSecretManagementRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            description,
            name,
            severity,
            updateTime,
            allResources,
            resourceBuiltInAttributeTypeNames,
            resourceCustomAttributeDefinitionIds,
            resourceIds,
            resourceNamePattern,
            resourceTags);
    }
}

export class AwsSsoPermissionSetAssignmentRiskPolicyConfiguration extends AwsResourceUsageRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        description: Optional<string>,
        name: string,
        severity: Severity,
        updateTime: string,
        allResources: Optional<boolean>,
        resourceBuiltInAttributeTypeNames: Optional<string[]>,
        resourceCustomAttributeDefinitionIds: Optional<string[]>,
        resourceIds: Optional<string[]>,
        resourceNamePattern: Optional<string>,
        resourceTags: Optional<ResourceTag[]>,
        public permissionSetTenantIds: Optional<string[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSsoPermissionSetAssignmentRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            description,
            name,
            severity,
            updateTime,
            allResources,
            resourceBuiltInAttributeTypeNames,
            resourceCustomAttributeDefinitionIds,
            resourceIds,
            resourceNamePattern,
            resourceTags);
    }
}

export class AwsSsoPermissionSetManagementRiskPolicyConfiguration extends AwsResourceUsageRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        description: Optional<string>,
        name: string,
        severity: Severity,
        updateTime: string,
        allResources: Optional<boolean>,
        resourceBuiltInAttributeTypeNames: Optional<string[]>,
        resourceCustomAttributeDefinitionIds: Optional<string[]>,
        resourceIds: Optional<string[]>,
        resourceNamePattern: Optional<string>,
        resourceTags: Optional<ResourceTag[]>,
        public provisioned: Optional<boolean>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSsoPermissionSetManagementRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            description,
            name,
            severity,
            updateTime,
            allResources,
            resourceBuiltInAttributeTypeNames,
            resourceCustomAttributeDefinitionIds,
            resourceIds,
            resourceNamePattern,
            resourceTags);
    }
}

export class AwsSsoPrincipalCreationRiskPolicyConfiguration extends AwsResourceUsageRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        description: Optional<string>,
        name: string,
        severity: Severity,
        updateTime: string,
        allResources: Optional<boolean>,
        resourceBuiltInAttributeTypeNames: Optional<string[]>,
        resourceCustomAttributeDefinitionIds: Optional<string[]>,
        resourceIds: Optional<string[]>,
        resourceNamePattern: Optional<string>,
        resourceTags: Optional<ResourceTag[]>,
        public principalTypeNames: Optional<string[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsSsoPrincipalCreationRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            description,
            name,
            severity,
            updateTime,
            allResources,
            resourceBuiltInAttributeTypeNames,
            resourceCustomAttributeDefinitionIds,
            resourceIds,
            resourceNamePattern,
            resourceTags);
    }
}

export class AzureAuthorizationRoleAssignment extends AzureAuthorizationRoleAssignmentResource implements ITypeNameObject, IAzureEntity, IResource, IAzureExtensionResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        scopeEntityReference: AzureScopeEntityReference,
        principalReference: AadDirectoryPrincipalReference,
        roleDefinitionId: string,
        status: AzureAuthorizationRoleAssignmentResourceStatus) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAuthorizationRoleAssignment",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            scopeEntityReference,
            principalReference,
            roleDefinitionId,
            status);
    }
}

export class AzureAuthorizationRoleAssignmentClassicAdministrator extends AzureAuthorizationRoleAssignmentResource implements ITypeNameObject, IAzureEntity, IResource, IAzureExtensionResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        scopeEntityReference: AzureScopeEntityReference,
        principalReference: AadDirectoryPrincipalReference,
        roleDefinitionId: string,
        status: AzureAuthorizationRoleAssignmentResourceStatus) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureAuthorizationRoleAssignmentClassicAdministrator",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            scopeEntityReference,
            principalReference,
            roleDefinitionId,
            status);
    }
}

export class AzureComputeDisk extends AzureComputeDiskResource implements ITypeNameObject, IResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        encryptionSetId: Optional<string>,
        encryptionType: AzureComputeDiskResourceEncryptionType,
        networkAccessPolicyType: AzureComputeDiskResourceNetworkAccessPolicyType,
        source: Optional<AzureComputeDiskResourceSource>,
        public availabilityZoneName: Optional<string>,
        public encryptionVaultSecretUrl: Optional<string>,
        public operatingSystemType: Optional<OperatingSystemType>,
        public size: Optional<number>,
        public storageAccountPath: Optional<string>,
        public storageAccountSku: AzureComputeDiskStorageAccountSku,
        public virtualMachinePath: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeDisk",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            encryptionSetId,
            encryptionType,
            networkAccessPolicyType,
            source);
    }
}

export class AzureComputeDiskModel extends AzureComputeDiskResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        diskResourceIds: string[],
        sourceResourceSearchableIdReference: Optional<EntitySearchableIdReference>,
        public role: AzureComputeResourceDiskRole,
        public systemManagedIdentityId: Optional<string>,
        public virtualMachineId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeDiskModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            diskResourceIds,
            sourceResourceSearchableIdReference);
    }
}

export class AzureComputeGalleryImage extends AzureComputeImage implements ITypeNameObject, IAzureEntity, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        info: Optional<AzureComputeImageInfo>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeGalleryImage",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            info);
    }
}

export class AzureComputeVirtualMachine extends AzureComputeVirtualMachineResource implements ITypeNameObject, IAzureManagedIdentityOriginatorResource, IAzureNetworkEvaluationResource, IAzureNetworkedResource, IAzureTagResource, IAzureComputeVirtualMachine {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        applicationSecurityGroupIds: string[],
        interfaceNetworkSecurityGroupIds: string[],
        managedIdentityExists: boolean,
        privateIpAddresses: string[],
        publicIpAddressIds: string[],
        rawId: string,
        specification: Optional<AzureComputeVirtualMachineResourceSpecification>,
        subnetIds: string[],
        subnetNetworkSecurityGroupIds: string[],
        systemManagedIdentityServicePrincipalId: Optional<string>,
        userManagedIdentityPaths: string[],
        virtualNetworkId: Optional<string>,
        public availabilityZoneName: Optional<string>,
        public diskIds: string[],
        public dnsNames: string[],
        public networkInterfaceIds: string[],
        public operatingSystemDiskId: Optional<string>,
        public publicIpAddresses: string[],
        public status: AzureComputeVirtualMachineStatus,
        public unmanagedDisks: AzureComputeVirtualMachineUnmanagedDisk[],
        public virtualMachineScaleSetId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeVirtualMachine",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            applicationSecurityGroupIds,
            interfaceNetworkSecurityGroupIds,
            managedIdentityExists,
            privateIpAddresses,
            publicIpAddressIds,
            rawId,
            specification,
            subnetIds,
            subnetNetworkSecurityGroupIds,
            systemManagedIdentityServicePrincipalId,
            userManagedIdentityPaths,
            virtualNetworkId);
    }
}

export class AzureComputeVirtualMachineModel extends AzureComputeVirtualMachineResourceModel implements ITypeNameObject, IUdmObjectModel, IAzureManagedIdentityOriginatorResourceModel, IAzureNetworkedResourceModel, IVirtualMachineModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        public entityNetwork: Optional<NetworkAccessResourceStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        applicationGatewayIds: string[],
        imageId: Optional<string>,
        loadBalancerIds: string[],
        userManagedIdentityServicePrincipalIds: string[],
        public diskEncryptionVaultIdReference: Optional<string>,
        public dnsZoneIds: string[],
        public identityPermissionActionSeverity: Optional<Severity>,
        public kubernetesClusterSensor: boolean,
        public operatingSystemReleaseNotesUrl: Optional<string>,
        public operatingSystemType: Optional<OperatingSystemType>,
        public scanTime: Optional<string>,
        public stopped: boolean,
        public stopTime: Optional<string>,
        public vulnerabilities: Optional<VulnerabilityData[]>,
        public vulnerabilitySeveritiesSortValue: string,
        public workloadAnalysisError: Optional<WorkloadAnalysisError>,
        public workloadAnalysisOperatingSystem: Optional<WorkloadResourceScanOperatingSystem>,
        public workloadAnalysisOperatingSystemEndOfLifeDate: Optional<string>,
        public workloadAnalysisOperatingSystemExtendedSupport: Optional<boolean>,
        public workloadAnalysisPriorityScanRequested: boolean,
        public workloadAnalysisStatus: WorkloadAnalysisStatus,
        public workloadAnalysisStatusSortValue: number) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureComputeVirtualMachineModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            applicationGatewayIds,
            imageId,
            loadBalancerIds,
            userManagedIdentityServicePrincipalIds);
    }
}

export class AzureCreateRoleAssignmentChange extends AzureCreateRoleAssignmentResourceChange implements ITypeNameObject, ICloudProviderResourceChange {
    constructor(
        entityIds: string[],
        id: string,
        resolved: boolean,
        resourceId: string,
        principalId: string,
        scopeEntityId: string) {
        super(
            "AzureCreateRoleAssignmentChange",
            entityIds,
            id,
            resolved,
            resourceId,
            principalId,
            scopeEntityId);
    }
}

export class AzureDeleteRoleAssignmentChange extends AzureDeleteRoleAssignmentResourceChange implements ITypeNameObject, ICloudProviderResourceChange, ICodeInfrastructureChange {
    constructor(
        entityIds: string[],
        id: string,
        resolved: boolean,
        resourceId: string,
        scopeEntityId: string) {
        super(
            "AzureDeleteRoleAssignmentChange",
            entityIds,
            id,
            resolved,
            resourceId,
            scopeEntityId);
    }
}

export class AzureDeleteRoleAssignmentClassicAdministratorChange extends AzureDeleteRoleAssignmentResourceChange implements ITypeNameObject, ICloudProviderResourceChange {
    constructor(
        entityIds: string[],
        id: string,
        resolved: boolean,
        resourceId: string,
        scopeEntityId: string) {
        super(
            "AzureDeleteRoleAssignmentClassicAdministratorChange",
            entityIds,
            id,
            resolved,
            resourceId,
            scopeEntityId);
    }
}

export class AzureDocumentDbDatabaseAccountCassandraKeyspace extends AzureDocumentDbDatabaseAccountResource implements ITypeNameObject, IAzureEntity, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        databaseAccountId: string,
        throughput: Optional<number>,
        public tableNames: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDocumentDbDatabaseAccountCassandraKeyspace",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            databaseAccountId,
            throughput);
    }
}

export class AzureDocumentDbDatabaseAccountGremlinDatabase extends AzureDocumentDbDatabaseAccountResource implements ITypeNameObject, IAzureEntity, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        databaseAccountId: string,
        throughput: Optional<number>,
        public graphNames: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDocumentDbDatabaseAccountGremlinDatabase",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            databaseAccountId,
            throughput);
    }
}

export class AzureDocumentDbDatabaseAccountMongoDbDatabase extends AzureDocumentDbDatabaseAccountResource implements ITypeNameObject, IAzureEntity, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        databaseAccountId: string,
        throughput: Optional<number>,
        public collectionNames: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureDocumentDbDatabaseAccountMongoDbDatabase",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            databaseAccountId,
            throughput);
    }
}

export class AzureExcessivePermissionApplicationServicePrincipalRiskPolicyConfiguration extends AzureExcessivePermissionPrincipalRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        inactivePrincipalDeleteGroupMembershipEnabled: Optional<boolean>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureExcessivePermissionApplicationServicePrincipalRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            inactivePrincipalDeleteGroupMembershipEnabled);
    }
}

export class AzureExcessivePermissionGroupRiskPolicyConfiguration extends AzureExcessivePermissionPrincipalRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        inactivePrincipalDeleteGroupMembershipEnabled: Optional<boolean>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureExcessivePermissionGroupRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            inactivePrincipalDeleteGroupMembershipEnabled);
    }
}

export class AzureExcessivePermissionManagedIdentityServicePrincipalRiskPolicyConfiguration extends AzureExcessivePermissionPrincipalRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        inactivePrincipalDeleteGroupMembershipEnabled: Optional<boolean>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureExcessivePermissionManagedIdentityServicePrincipalRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            inactivePrincipalDeleteGroupMembershipEnabled);
    }
}

export class AzureKeyVaultVaultCertificate extends AzureKeyVaultVaultObject implements ITypeNameObject, IResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        currentVersionName: string,
        enabled: boolean,
        expirationTime: Optional<string>,
        url: string,
        vaultId: string,
        public thumbprint: string,
        public versionNameToVersionMap: Dictionary<AzureKeyVaultVaultCertificateVersion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKeyVaultVaultCertificate",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            currentVersionName,
            enabled,
            expirationTime,
            url,
            vaultId);
    }
}

export class AzureKeyVaultVaultCertificateModel extends AzureKeyVaultVaultObjectModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: string,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        aadPrimaryDomainName: string,
        azureManaged: boolean,
        public versionNameToDirectoryApplicationIdsMap: Dictionary<string[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKeyVaultVaultCertificateModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            aadPrimaryDomainName,
            azureManaged);
    }
}

export class AzureKeyVaultVaultKey extends AzureKeyVaultVaultObject implements ITypeNameObject, IResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        currentVersionName: string,
        enabled: boolean,
        expirationTime: Optional<string>,
        url: string,
        vaultId: string,
        public expirationDisabledVersionNames: string[],
        public rotationPolicy: Optional<AzureKeyVaultVaultKeyRotationPolicy>,
        public type: AzureKeyVaultVaultKeyType,
        public versionNameToVersionMap: Dictionary<AzureKeyVaultVaultKeyVersion>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKeyVaultVaultKey",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            currentVersionName,
            enabled,
            expirationTime,
            url,
            vaultId);
    }
}

export class AzureKeyVaultVaultKeyModel extends AzureKeyVaultVaultObjectModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: string,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        aadPrimaryDomainName: string,
        azureManaged: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKeyVaultVaultKeyModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            aadPrimaryDomainName,
            azureManaged);
    }
}

export class AzureKubernetesClusterRole extends AzureKubernetesResource<KubernetesClusterRoleData> implements ITypeNameObject, IAzureEntity, IKubernetesClusterRole {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesClusterRoleData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKubernetesClusterRole",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class AzureKubernetesClusterRoleBinding extends AzureKubernetesResource<KubernetesClusterRoleBindingData> implements ITypeNameObject, IAzureEntity, IKubernetesClusterRoleBinding {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesClusterRoleBindingData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKubernetesClusterRoleBinding",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class AzureKubernetesConfigMapModel extends AzureKubernetesNamespaceResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesNamespaceResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKubernetesConfigMapModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType);
    }
}

export class AzureKubernetesCronJobModel extends AzureKubernetesWorkloadResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesWorkloadResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        aadServicePrincipalIds: string[],
        agentPoolIds: string[],
        containerImageIdReferences: string[],
        containerImageRawUrlToDataMap: Dictionary<ContainerImageResourceStateContainerImageData>,
        containerImageScanStatus: Optional<ContainerImageDataScanStatus>,
        containerImageVulnerabilities: VulnerabilityData[],
        controllerResourceReference: Optional<EntitySearchableIdReference>,
        serviceAccountReference: EntitySearchableIdReference) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKubernetesCronJobModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            aadServicePrincipalIds,
            agentPoolIds,
            containerImageIdReferences,
            containerImageRawUrlToDataMap,
            containerImageScanStatus,
            containerImageVulnerabilities,
            controllerResourceReference,
            serviceAccountReference);
    }
}

export class AzureKubernetesDaemonSetModel extends AzureKubernetesWorkloadResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesWorkloadResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        aadServicePrincipalIds: string[],
        agentPoolIds: string[],
        containerImageIdReferences: string[],
        containerImageRawUrlToDataMap: Dictionary<ContainerImageResourceStateContainerImageData>,
        containerImageScanStatus: Optional<ContainerImageDataScanStatus>,
        containerImageVulnerabilities: VulnerabilityData[],
        controllerResourceReference: Optional<EntitySearchableIdReference>,
        serviceAccountReference: EntitySearchableIdReference) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKubernetesDaemonSetModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            aadServicePrincipalIds,
            agentPoolIds,
            containerImageIdReferences,
            containerImageRawUrlToDataMap,
            containerImageScanStatus,
            containerImageVulnerabilities,
            controllerResourceReference,
            serviceAccountReference);
    }
}

export class AzureKubernetesDeploymentModel extends AzureKubernetesWorkloadResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesDeploymentModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        aadServicePrincipalIds: string[],
        agentPoolIds: string[],
        containerImageIdReferences: string[],
        containerImageRawUrlToDataMap: Dictionary<ContainerImageResourceStateContainerImageData>,
        containerImageScanStatus: Optional<ContainerImageDataScanStatus>,
        containerImageVulnerabilities: VulnerabilityData[],
        controllerResourceReference: Optional<EntitySearchableIdReference>,
        serviceAccountReference: EntitySearchableIdReference,
        public replicaSetIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKubernetesDeploymentModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            aadServicePrincipalIds,
            agentPoolIds,
            containerImageIdReferences,
            containerImageRawUrlToDataMap,
            containerImageScanStatus,
            containerImageVulnerabilities,
            controllerResourceReference,
            serviceAccountReference);
    }
}

export class AzureKubernetesGroup extends AzureKubernetesResource<KubernetesGroupData> implements ITypeNameObject, IAzureEntity, IKubernetesGroup {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesGroupData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKubernetesGroup",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class AzureKubernetesIngressClass extends AzureKubernetesResource<KubernetesIngressClassData> implements ITypeNameObject, IAzureEntity, IKubernetesIngressClass {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesIngressClassData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKubernetesIngressClass",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class AzureKubernetesIngressModel extends AzureKubernetesNamespaceResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesIngressModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        public loadBalancerSearchableReference: Optional<EntitySearchableReference>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKubernetesIngressModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType);
    }
}

export class AzureKubernetesJobModel extends AzureKubernetesWorkloadResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesWorkloadResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        aadServicePrincipalIds: string[],
        agentPoolIds: string[],
        containerImageIdReferences: string[],
        containerImageRawUrlToDataMap: Dictionary<ContainerImageResourceStateContainerImageData>,
        containerImageScanStatus: Optional<ContainerImageDataScanStatus>,
        containerImageVulnerabilities: VulnerabilityData[],
        controllerResourceReference: Optional<EntitySearchableIdReference>,
        serviceAccountReference: EntitySearchableIdReference) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKubernetesJobModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            aadServicePrincipalIds,
            agentPoolIds,
            containerImageIdReferences,
            containerImageRawUrlToDataMap,
            containerImageScanStatus,
            containerImageVulnerabilities,
            controllerResourceReference,
            serviceAccountReference);
    }
}

export class AzureKubernetesNamespace extends AzureKubernetesResource<KubernetesNamespaceData> implements ITypeNameObject, IAzureEntity, IKubernetesNamespace {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesNamespaceData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKubernetesNamespace",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class AzureKubernetesNamespaceResourceBase extends AzureKubernetesResource<KubernetesNamespaceResourceData> implements ITypeNameObject, IAzureEntity, IKubernetesNamespaceResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesNamespaceResourceData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class AzureKubernetesNode extends AzureKubernetesResource<KubernetesNodeData> implements ITypeNameObject, IAzureEntity, IKubernetesNode {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesNodeData,
        public virtualMachineScaleSetVirtualMachineId: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKubernetesNode",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class AzureKubernetesPersistentVolume extends AzureKubernetesResource<KubernetesPersistentVolumeData> implements ITypeNameObject, IAzureEntity, IKubernetesPersistentVolume {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesPersistentVolumeData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKubernetesPersistentVolume",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class AzureKubernetesPodSecurityPolicy extends AzureKubernetesResource<KubernetesPodSecurityPolicyData> implements ITypeNameObject, IAzureEntity, IKubernetesPodSecurityPolicy {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesPodSecurityPolicyData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKubernetesPodSecurityPolicy",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class AzureKubernetesReplicaSetModel extends AzureKubernetesWorkloadResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesWorkloadResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        aadServicePrincipalIds: string[],
        agentPoolIds: string[],
        containerImageIdReferences: string[],
        containerImageRawUrlToDataMap: Dictionary<ContainerImageResourceStateContainerImageData>,
        containerImageScanStatus: Optional<ContainerImageDataScanStatus>,
        containerImageVulnerabilities: VulnerabilityData[],
        controllerResourceReference: Optional<EntitySearchableIdReference>,
        serviceAccountReference: EntitySearchableIdReference) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKubernetesReplicaSetModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            aadServicePrincipalIds,
            agentPoolIds,
            containerImageIdReferences,
            containerImageRawUrlToDataMap,
            containerImageScanStatus,
            containerImageVulnerabilities,
            controllerResourceReference,
            serviceAccountReference);
    }
}

export class AzureKubernetesStatefulSetModel extends AzureKubernetesWorkloadResourceModel implements ITypeNameObject, IUdmObjectModel, IKubernetesWorkloadResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        aadServicePrincipalIds: string[],
        agentPoolIds: string[],
        containerImageIdReferences: string[],
        containerImageRawUrlToDataMap: Dictionary<ContainerImageResourceStateContainerImageData>,
        containerImageScanStatus: Optional<ContainerImageDataScanStatus>,
        containerImageVulnerabilities: VulnerabilityData[],
        controllerResourceReference: Optional<EntitySearchableIdReference>,
        serviceAccountReference: EntitySearchableIdReference) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKubernetesStatefulSetModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            aadServicePrincipalIds,
            agentPoolIds,
            containerImageIdReferences,
            containerImageRawUrlToDataMap,
            containerImageScanStatus,
            containerImageVulnerabilities,
            controllerResourceReference,
            serviceAccountReference);
    }
}

export class AzureMySqlFlexibleServer extends AzureMySqlServer implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        aadAuthenticationAdministratorPrincipalIdReference: Optional<string>,
        administratorUserName: string,
        allowAnyAzureInternalIpAddress: boolean,
        authenticationType: AzureMySqlServerAuthenticationType,
        endpoint: string,
        firewallIpAddressRangeRules: AzureResourceFirewallIpAddressRangeRule[],
        httpsOnly: Optional<boolean>,
        managedIdentityExists: boolean,
        nameToConfigurationMap: Optional<Dictionary<AzureMySqlServerConfiguration>>,
        publicNetworkAccess: boolean,
        skuTier: AzureMySqlServerSkuTier,
        status: AzureMySqlServerStatus,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        tlsMinVersion: Optional<string>,
        userManagedIdentityPaths: string[],
        version: string,
        virtualNetworkPathToFirewallSubnetRulesMap: Dictionary<AzureResourceFirewallVirtualNetworkSubnetRule[]>,
        public databaseNames: string[],
        public databaseNameToUserSchemaMap: Dictionary<boolean>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMySqlFlexibleServer",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            aadAuthenticationAdministratorPrincipalIdReference,
            administratorUserName,
            allowAnyAzureInternalIpAddress,
            authenticationType,
            endpoint,
            firewallIpAddressRangeRules,
            httpsOnly,
            managedIdentityExists,
            nameToConfigurationMap,
            publicNetworkAccess,
            skuTier,
            status,
            systemManagedIdentityServicePrincipalId,
            tlsMinVersion,
            userManagedIdentityPaths,
            version,
            virtualNetworkPathToFirewallSubnetRulesMap);
    }
}

export class AzureMySqlFlexibleServerModel extends AzureMySqlServerModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        networkResourcePathToIdReferencesMap: Dictionary<string>,
        userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureMySqlFlexibleServerModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            networkResourcePathToIdReferencesMap,
            userManagedIdentityServicePrincipalIds);
    }
}

export class AzureNetworkDnsZoneRecordSetA extends AzureNetworkDnsZoneRecordSetResource implements ITypeNameObject, IAzureEntity, IResource, IAzureNetworkAliasDnsZoneRecordSetResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        values: string[],
        public alias: boolean,
        public aliasResourceEntityPath: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkDnsZoneRecordSetA",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            values);
    }
}

export class AzureNetworkDnsZoneRecordSetAaaa extends AzureNetworkDnsZoneRecordSetResource implements ITypeNameObject, IAzureEntity, IResource, IAzureNetworkAliasDnsZoneRecordSetResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        values: string[],
        public alias: boolean,
        public aliasResourceEntityPath: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkDnsZoneRecordSetAaaa",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            values);
    }
}

export class AzureNetworkDnsZoneRecordSetAaaaModel extends AzureNetworkDnsZoneRecordSetResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkDnsZoneRecordSetAaaaModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureNetworkDnsZoneRecordSetAModel extends AzureNetworkDnsZoneRecordSetResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkDnsZoneRecordSetAModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureNetworkDnsZoneRecordSetCaa extends AzureNetworkDnsZoneRecordSetResource implements ITypeNameObject, IAzureEntity, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        values: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkDnsZoneRecordSetCaa",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            values);
    }
}

export class AzureNetworkDnsZoneRecordSetCaaModel extends AzureNetworkDnsZoneRecordSetResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkDnsZoneRecordSetCaaModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureNetworkDnsZoneRecordSetCname extends AzureNetworkDnsZoneRecordSetResource implements ITypeNameObject, IAzureEntity, IResource, IAzureNetworkAliasDnsZoneRecordSetResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        values: string[],
        public alias: boolean,
        public aliasResourceEntityPath: Optional<string>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkDnsZoneRecordSetCname",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            values);
    }
}

export class AzureNetworkDnsZoneRecordSetCnameModel extends AzureNetworkDnsZoneRecordSetResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkDnsZoneRecordSetCnameModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureNetworkDnsZoneRecordSetMx extends AzureNetworkDnsZoneRecordSetResource implements ITypeNameObject, IAzureEntity, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        values: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkDnsZoneRecordSetMx",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            values);
    }
}

export class AzureNetworkDnsZoneRecordSetMxModel extends AzureNetworkDnsZoneRecordSetResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkDnsZoneRecordSetMxModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureNetworkDnsZoneRecordSetNs extends AzureNetworkDnsZoneRecordSetResource implements ITypeNameObject, IAzureEntity, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        values: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkDnsZoneRecordSetNs",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            values);
    }
}

export class AzureNetworkDnsZoneRecordSetNsModel extends AzureNetworkDnsZoneRecordSetResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkDnsZoneRecordSetNsModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureNetworkDnsZoneRecordSetPtr extends AzureNetworkDnsZoneRecordSetResource implements ITypeNameObject, IAzureEntity, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        values: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkDnsZoneRecordSetPtr",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            values);
    }
}

export class AzureNetworkDnsZoneRecordSetPtrModel extends AzureNetworkDnsZoneRecordSetResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkDnsZoneRecordSetPtrModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureNetworkPrivateDnsZoneRecordSetA extends AzureNetworkPrivateDnsZoneRecordSetResource implements ITypeNameObject, IAzureEntity, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        values: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkPrivateDnsZoneRecordSetA",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            values);
    }
}

export class AzureNetworkPrivateDnsZoneRecordSetAaaa extends AzureNetworkPrivateDnsZoneRecordSetResource implements ITypeNameObject, IAzureEntity, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        values: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkPrivateDnsZoneRecordSetAaaa",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            values);
    }
}

export class AzureNetworkPrivateDnsZoneRecordSetAaaaModel extends AzureNetworkPrivateDnsZoneRecordSetResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkPrivateDnsZoneRecordSetAaaaModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureNetworkPrivateDnsZoneRecordSetAModel extends AzureNetworkPrivateDnsZoneRecordSetResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkPrivateDnsZoneRecordSetAModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureNetworkPrivateDnsZoneRecordSetCname extends AzureNetworkPrivateDnsZoneRecordSetResource implements ITypeNameObject, IAzureEntity, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        values: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkPrivateDnsZoneRecordSetCname",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            values);
    }
}

export class AzureNetworkPrivateDnsZoneRecordSetCnameModel extends AzureNetworkPrivateDnsZoneRecordSetResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkPrivateDnsZoneRecordSetCnameModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureNetworkPrivateDnsZoneRecordSetMx extends AzureNetworkPrivateDnsZoneRecordSetResource implements ITypeNameObject, IAzureEntity, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        values: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkPrivateDnsZoneRecordSetMx",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            values);
    }
}

export class AzureNetworkPrivateDnsZoneRecordSetMxModel extends AzureNetworkPrivateDnsZoneRecordSetResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkPrivateDnsZoneRecordSetMxModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzureNetworkPrivateDnsZoneRecordSetPtr extends AzureNetworkPrivateDnsZoneRecordSetResource implements ITypeNameObject, IAzureEntity, IResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        values: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkPrivateDnsZoneRecordSetPtr",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            values);
    }
}

export class AzureNetworkPrivateDnsZoneRecordSetPtrModel extends AzureNetworkPrivateDnsZoneRecordSetResourceModel implements ITypeNameObject, IUdmObjectModel, IResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureNetworkPrivateDnsZoneRecordSetPtrModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds);
    }
}

export class AzurePostgreSqlFlexibleServer extends AzurePostgreSqlServer implements ITypeNameObject, IResource, IAzureManagedIdentityOriginatorResource, IAzureTagResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        parentEntityId: string,
        resourceGroupId: string,
        aadAuthenticationAdministratorPrincipalIdReferences: string[],
        administratorUserName: Optional<string>,
        allowAnyAzureInternalIpAddress: boolean,
        authenticationType: AzurePostgreSqlServerAuthenticationType,
        endpoint: string,
        firewallIpAddressRangeRules: AzureResourceFirewallIpAddressRangeRule[],
        httpsOnly: Optional<boolean>,
        managedIdentityExists: boolean,
        nameToConfigurationMap: Optional<Dictionary<AzurePostgreSqlServerConfiguration>>,
        publicNetworkAccess: boolean,
        skuTier: AzurePostgreSqlServerSkuTier,
        status: AzurePostgreSqlServerStatus,
        systemManagedIdentityServicePrincipalId: Optional<string>,
        tlsMinVersion: Optional<string>,
        userManagedIdentityPaths: string[],
        version: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePostgreSqlFlexibleServer",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            parentEntityId,
            resourceGroupId,
            aadAuthenticationAdministratorPrincipalIdReferences,
            administratorUserName,
            allowAnyAzureInternalIpAddress,
            authenticationType,
            endpoint,
            firewallIpAddressRangeRules,
            httpsOnly,
            managedIdentityExists,
            nameToConfigurationMap,
            publicNetworkAccess,
            skuTier,
            status,
            systemManagedIdentityServicePrincipalId,
            tlsMinVersion,
            userManagedIdentityPaths,
            version);
    }
}

export class AzurePostgreSqlFlexibleServerModel extends AzurePostgreSqlServerModel implements ITypeNameObject, IUdmObjectModel, IResourceModel, IAzureManagedIdentityOriginatorResourceModel {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        attributes: EntityModelAttributes,
        consoleUrl: Optional<string>,
        public entity: AzureEntity,
        entityConfiguration: Optional<EntityConfiguration>,
        entityNetwork: Optional<EntityStateNetwork>,
        entityProfile: Optional<EntityProfile>,
        permissionEvaluationEntity: boolean,
        properties: EntityProperty[],
        risks: EntityModelRisks,
        uiEntity: boolean,
        unknown: boolean,
        creationTime: Optional<string>,
        creatorIdentityIdReference: Optional<string>,
        creatorOriginatorEntityIdReference: Optional<string>,
        partitionType: AadPartitionType,
        roleAssignmentResourceIds: string[],
        userManagedIdentityServicePrincipalIds: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePostgreSqlFlexibleServerModel",
            tenantId,
            attributes,
            consoleUrl,
            entity,
            entityConfiguration,
            entityNetwork,
            entityProfile,
            permissionEvaluationEntity,
            properties,
            risks,
            uiEntity,
            unknown,
            creationTime,
            creatorIdentityIdReference,
            creatorOriginatorEntityIdReference,
            partitionType,
            roleAssignmentResourceIds,
            userManagedIdentityServicePrincipalIds);
    }
}

export class AzurePrincipalAllowedResourcePermissionRiskPolicyConfiguration extends AzurePrincipalResourcePermissionRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        description: Optional<string>,
        name: string,
        severity: Severity,
        updateTime: string,
        actionNamePatterns: Optional<string[]>,
        actionRiskCategories: Optional<ActionRiskCategory[]>,
        actions: Optional<string[]>,
        principalBuiltInAttributeTypeNames: Optional<string[]>,
        principalIds: Optional<string[]>,
        resourceBuiltInAttributeTypeNames: Optional<string[]>,
        resourceCustomAttributeDefinitionIds: Optional<string[]>,
        resourceIds: Optional<string[]>,
        resourceTags: Optional<ResourceTag[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePrincipalAllowedResourcePermissionRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            description,
            name,
            severity,
            updateTime,
            actionNamePatterns,
            actionRiskCategories,
            actions,
            principalBuiltInAttributeTypeNames,
            principalIds,
            resourceBuiltInAttributeTypeNames,
            resourceCustomAttributeDefinitionIds,
            resourceIds,
            resourceTags);
    }
}

export class AzurePrincipalNotAllowedResourcePermissionRiskPolicyConfiguration extends AzurePrincipalResourcePermissionRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        description: Optional<string>,
        name: string,
        severity: Severity,
        updateTime: string,
        actionNamePatterns: Optional<string[]>,
        actionRiskCategories: Optional<ActionRiskCategory[]>,
        actions: Optional<string[]>,
        principalBuiltInAttributeTypeNames: Optional<string[]>,
        principalIds: Optional<string[]>,
        resourceBuiltInAttributeTypeNames: Optional<string[]>,
        resourceCustomAttributeDefinitionIds: Optional<string[]>,
        resourceIds: Optional<string[]>,
        resourceTags: Optional<ResourceTag[]>,
        public allPrincipals: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzurePrincipalNotAllowedResourcePermissionRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            description,
            name,
            severity,
            updateTime,
            actionNamePatterns,
            actionRiskCategories,
            actions,
            principalBuiltInAttributeTypeNames,
            principalIds,
            resourceBuiltInAttributeTypeNames,
            resourceCustomAttributeDefinitionIds,
            resourceIds,
            resourceTags);
    }
}

export class CustomCodeBuilderKubernetesAdmissionControllerRiskPolicyConfiguration extends CustomKubernetesAdmissionControllerRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument, IKubernetesAdmissionControllerRiskPolicyConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        effect: Optional<KubernetesAdmissionControllerRiskPolicyEffect>,
        rawResourceTypeNames: Optional<string[]>,
        resourceOperations: KubernetesAdmissionControllerResourceOperation[],
        systemNamespacesEnabled: boolean,
        description: Optional<string>,
        name: string,
        public conditions: CustomCodeBuilderKubernetesAdmissionControllerRiskPolicyConfigurationCondition[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            effect,
            rawResourceTypeNames,
            resourceOperations,
            systemNamespacesEnabled,
            description,
            name);
    }
}

export class CustomCodeKubernetesAdmissionControllerRiskPolicyConfiguration extends CustomKubernetesAdmissionControllerRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument, IKubernetesAdmissionControllerRiskPolicyConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        effect: Optional<KubernetesAdmissionControllerRiskPolicyEffect>,
        rawResourceTypeNames: Optional<string[]>,
        resourceOperations: KubernetesAdmissionControllerResourceOperation[],
        systemNamespacesEnabled: boolean,
        description: Optional<string>,
        name: string,
        public ruleCode: string) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            effect,
            rawResourceTypeNames,
            resourceOperations,
            systemNamespacesEnabled,
            description,
            name);
    }
}

export class GcpKubernetesNamespaceResource<TKubernetesNamespaceResourceData extends KubernetesNamespaceResourceData> extends GcpKubernetesNamespaceResourceBase implements ITypeNameObject, IKubernetesNamespaceResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: TKubernetesNamespaceResourceData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class GcpKubernetesNamespaceRole extends GcpKubernetesNamespaceResource<KubernetesNamespaceRoleData> implements ITypeNameObject, IKubernetesNamespaceRole {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesNamespaceRoleData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpKubernetesNamespaceRole",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class GcpKubernetesNamespaceRoleBinding extends GcpKubernetesNamespaceResource<KubernetesNamespaceRoleBindingData> implements ITypeNameObject, IKubernetesNamespaceRoleBinding {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesNamespaceRoleBindingData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpKubernetesNamespaceRoleBinding",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class GcpKubernetesNetworkPolicy extends GcpKubernetesNamespaceResource<KubernetesNetworkPolicyData> implements ITypeNameObject, IKubernetesNetworkPolicy {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesNetworkPolicyData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpKubernetesNetworkPolicy",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class GcpKubernetesPod extends GcpKubernetesNamespaceResource<KubernetesPodData> implements ITypeNameObject, IKubernetesPod {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesPodData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpKubernetesPod",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class GcpKubernetesPodTemplate extends GcpKubernetesNamespaceResource<KubernetesPodTemplateData> implements ITypeNameObject, IKubernetesPodTemplate {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesPodTemplateData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpKubernetesPodTemplate",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class GcpKubernetesService extends GcpKubernetesNamespaceResource<KubernetesServiceData> implements ITypeNameObject, IKubernetesService {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesServiceData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpKubernetesService",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class GcpKubernetesServiceAccount extends GcpKubernetesNamespaceResource<KubernetesServiceAccountData> implements ITypeNameObject, IKubernetesServiceAccount {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesServiceAccountData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpKubernetesServiceAccount",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class GcpKubernetesWorkloadResourceBase extends GcpKubernetesNamespaceResource<KubernetesWorkloadResourceData> implements ITypeNameObject, IGcpServiceAccountOriginatorKubernetesResource, IKubernetesWorkloadResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesWorkloadResourceData,
        public containerImageRawUrls: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class GcpOpenShiftBuildConfig extends GcpKubernetesNamespaceResource<OpenShiftBuildConfigData> implements ITypeNameObject, IOpenShiftBuildConfig {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: OpenShiftBuildConfigData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpOpenShiftBuildConfig",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class GcpOpenShiftCatalogSource extends GcpKubernetesNamespaceResource<OpenShiftCatalogSourceData> implements ITypeNameObject, IOpenShiftCatalogSource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: OpenShiftCatalogSourceData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpOpenShiftCatalogSource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class GcpOpenShiftImageStream extends GcpKubernetesNamespaceResource<OpenShiftImageStreamData> implements ITypeNameObject, IOpenShiftImageStream {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: OpenShiftImageStreamData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpOpenShiftImageStream",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class GcpOpenShiftRoute extends GcpKubernetesNamespaceResource<OpenShiftRouteData> implements ITypeNameObject, IOpenShiftRoute {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: OpenShiftRouteData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpOpenShiftRoute",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class GcpOpenShiftTemplate extends GcpKubernetesNamespaceResource<OpenShiftTemplateData> implements ITypeNameObject, IOpenShiftTemplate {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: OpenShiftTemplateData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpOpenShiftTemplate",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class KubernetesAdmissionControllerWorkloadResourceContainerRiskPolicyConfiguration extends KubernetesAdmissionControllerWorkloadResourceRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument, IKubernetesAdmissionControllerRiskPolicyConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        effect: Optional<KubernetesAdmissionControllerRiskPolicyEffect>,
        rawResourceTypeNames: Optional<string[]>,
        resourceOperations: KubernetesAdmissionControllerResourceOperation[],
        systemNamespacesEnabled: boolean,
        rawResourcePluralTypeNames: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            effect,
            rawResourceTypeNames,
            resourceOperations,
            systemNamespacesEnabled,
            rawResourcePluralTypeNames);
    }
}

export class KubernetesAdmissionControllerWorkloadResourceContainerRootUserEnabledRiskPolicyConfiguration extends KubernetesAdmissionControllerWorkloadResourceContainerRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument, IKubernetesAdmissionControllerRiskPolicyConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        effect: Optional<KubernetesAdmissionControllerRiskPolicyEffect>,
        rawResourceTypeNames: Optional<string[]>,
        resourceOperations: KubernetesAdmissionControllerResourceOperation[],
        systemNamespacesEnabled: boolean,
        rawResourcePluralTypeNames: string[],
        public exemptImagePatterns: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesAdmissionControllerWorkloadResourceContainerRootUserEnabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            effect,
            rawResourceTypeNames,
            resourceOperations,
            systemNamespacesEnabled,
            rawResourcePluralTypeNames);
    }
}

export class KubernetesAdmissionControllerWorkloadResourceHostNamespaceInterProcessCommunicationEnabledRiskPolicyConfiguration extends KubernetesAdmissionControllerWorkloadResourceRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument, IKubernetesAdmissionControllerRiskPolicyConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        effect: Optional<KubernetesAdmissionControllerRiskPolicyEffect>,
        rawResourceTypeNames: Optional<string[]>,
        resourceOperations: KubernetesAdmissionControllerResourceOperation[],
        systemNamespacesEnabled: boolean,
        rawResourcePluralTypeNames: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesAdmissionControllerWorkloadResourceHostNamespaceInterProcessCommunicationEnabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            effect,
            rawResourceTypeNames,
            resourceOperations,
            systemNamespacesEnabled,
            rawResourcePluralTypeNames);
    }
}

export class KubernetesAdmissionControllerWorkloadResourceHostNamespaceNetworkEnabledRiskPolicyConfiguration extends KubernetesAdmissionControllerWorkloadResourceRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument, IKubernetesAdmissionControllerRiskPolicyConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        effect: Optional<KubernetesAdmissionControllerRiskPolicyEffect>,
        rawResourceTypeNames: Optional<string[]>,
        resourceOperations: KubernetesAdmissionControllerResourceOperation[],
        systemNamespacesEnabled: boolean,
        rawResourcePluralTypeNames: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesAdmissionControllerWorkloadResourceHostNamespaceNetworkEnabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            effect,
            rawResourceTypeNames,
            resourceOperations,
            systemNamespacesEnabled,
            rawResourcePluralTypeNames);
    }
}

export class KubernetesAdmissionControllerWorkloadResourceHostNamespaceProcessIdEnabledRiskPolicyConfiguration extends KubernetesAdmissionControllerWorkloadResourceRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument, IKubernetesAdmissionControllerRiskPolicyConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        effect: Optional<KubernetesAdmissionControllerRiskPolicyEffect>,
        rawResourceTypeNames: Optional<string[]>,
        resourceOperations: KubernetesAdmissionControllerResourceOperation[],
        systemNamespacesEnabled: boolean,
        rawResourcePluralTypeNames: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesAdmissionControllerWorkloadResourceHostNamespaceProcessIdEnabledRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            effect,
            rawResourceTypeNames,
            resourceOperations,
            systemNamespacesEnabled,
            rawResourcePluralTypeNames);
    }
}

export class OpKubernetesNamespaceResource<TKubernetesNamespaceResourceData extends KubernetesNamespaceResourceData> extends OpKubernetesNamespaceResourceBase implements ITypeNameObject, IKubernetesNamespaceResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: TKubernetesNamespaceResourceData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class OpKubernetesNamespaceRole extends OpKubernetesNamespaceResource<KubernetesNamespaceRoleData> implements ITypeNameObject, IKubernetesNamespaceRole {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesNamespaceRoleData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpKubernetesNamespaceRole",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class OpKubernetesNamespaceRoleBinding extends OpKubernetesNamespaceResource<KubernetesNamespaceRoleBindingData> implements ITypeNameObject, IKubernetesNamespaceRoleBinding {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesNamespaceRoleBindingData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpKubernetesNamespaceRoleBinding",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class OpKubernetesNetworkPolicy extends OpKubernetesNamespaceResource<KubernetesNetworkPolicyData> implements ITypeNameObject, IKubernetesNetworkPolicy {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesNetworkPolicyData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpKubernetesNetworkPolicy",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class OpKubernetesPod extends OpKubernetesNamespaceResource<KubernetesPodData> implements ITypeNameObject, IKubernetesPod {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesPodData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpKubernetesPod",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class OpKubernetesPodTemplate extends OpKubernetesNamespaceResource<KubernetesPodTemplateData> implements ITypeNameObject, IKubernetesPodTemplate {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesPodTemplateData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpKubernetesPodTemplate",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class OpKubernetesService extends OpKubernetesNamespaceResource<KubernetesServiceData> implements ITypeNameObject, IKubernetesService {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesServiceData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpKubernetesService",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class OpKubernetesServiceAccount extends OpKubernetesNamespaceResource<KubernetesServiceAccountData> implements ITypeNameObject, IKubernetesServiceAccount {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesServiceAccountData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpKubernetesServiceAccount",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class OpKubernetesWorkloadResourceBase extends OpKubernetesNamespaceResource<KubernetesWorkloadResourceData> implements ITypeNameObject, IKubernetesWorkloadResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesWorkloadResourceData,
        public containerImageRawUrls: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class OpOpenShiftBuildConfig extends OpKubernetesNamespaceResource<OpenShiftBuildConfigData> implements ITypeNameObject, IOpenShiftBuildConfig {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: OpenShiftBuildConfigData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpOpenShiftBuildConfig",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class OpOpenShiftCatalogSource extends OpKubernetesNamespaceResource<OpenShiftCatalogSourceData> implements ITypeNameObject, IOpenShiftCatalogSource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: OpenShiftCatalogSourceData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpOpenShiftCatalogSource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class OpOpenShiftImageStream extends OpKubernetesNamespaceResource<OpenShiftImageStreamData> implements ITypeNameObject, IOpenShiftImageStream {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: OpenShiftImageStreamData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpOpenShiftImageStream",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class OpOpenShiftRoute extends OpKubernetesNamespaceResource<OpenShiftRouteData> implements ITypeNameObject, IOpenShiftRoute {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: OpenShiftRouteData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpOpenShiftRoute",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class OpOpenShiftTemplate extends OpKubernetesNamespaceResource<OpenShiftTemplateData> implements ITypeNameObject, IOpenShiftTemplate {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: OpenShiftTemplateData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpOpenShiftTemplate",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class AadDirectoryDirectoryRoleAssignment extends AadDirectoryDirectoryRoleAssignmentResource implements ITypeNameObject, IDirectoryEntity {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        creationTime: Optional<string>,
        rawId: string,
        principalId: string,
        roleDefinitionId: string,
        scopeInfo: AadDirectoryDirectoryRoleAssignmentResourceScopeInfo,
        status: AadDirectoryDirectoryRoleAssignmentResourceStatus) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AadDirectoryDirectoryRoleAssignment",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            creationTime,
            rawId,
            principalId,
            roleDefinitionId,
            scopeInfo,
            status);
    }
}

export class AwsEc2SecurityGroupManagementRiskPolicyConfiguration extends AwsResourceUsageRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        description: Optional<string>,
        name: string,
        severity: Severity,
        updateTime: string,
        allResources: Optional<boolean>,
        resourceBuiltInAttributeTypeNames: Optional<string[]>,
        resourceCustomAttributeDefinitionIds: Optional<string[]>,
        resourceIds: Optional<string[]>,
        resourceNamePattern: Optional<string>,
        resourceTags: Optional<ResourceTag[]>,
        public sensitiveSecurityGroup: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsEc2SecurityGroupManagementRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            description,
            name,
            severity,
            updateTime,
            allResources,
            resourceBuiltInAttributeTypeNames,
            resourceCustomAttributeDefinitionIds,
            resourceIds,
            resourceNamePattern,
            resourceTags);
    }
}

export class AwsIamPrincipalCreationRiskPolicyConfiguration extends AwsResourceUsageRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        description: Optional<string>,
        name: string,
        severity: Severity,
        updateTime: string,
        allResources: Optional<boolean>,
        resourceBuiltInAttributeTypeNames: Optional<string[]>,
        resourceCustomAttributeDefinitionIds: Optional<string[]>,
        resourceIds: Optional<string[]>,
        resourceNamePattern: Optional<string>,
        resourceTags: Optional<ResourceTag[]>,
        public principalTypeNames: Optional<string[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamPrincipalCreationRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            description,
            name,
            severity,
            updateTime,
            allResources,
            resourceBuiltInAttributeTypeNames,
            resourceCustomAttributeDefinitionIds,
            resourceIds,
            resourceNamePattern,
            resourceTags);
    }
}

export class AwsIamRoleManagementRiskPolicyConfiguration extends AwsResourceUsageRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        description: Optional<string>,
        name: string,
        severity: Severity,
        updateTime: string,
        allResources: Optional<boolean>,
        resourceBuiltInAttributeTypeNames: Optional<string[]>,
        resourceCustomAttributeDefinitionIds: Optional<string[]>,
        resourceIds: Optional<string[]>,
        resourceNamePattern: Optional<string>,
        resourceTags: Optional<ResourceTag[]>,
        public criticalActionSeverityPermissionRole: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsIamRoleManagementRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            description,
            name,
            severity,
            updateTime,
            allResources,
            resourceBuiltInAttributeTypeNames,
            resourceCustomAttributeDefinitionIds,
            resourceIds,
            resourceNamePattern,
            resourceTags);
    }
}

export class AwsKmsKeyManagementRiskPolicyConfiguration extends AwsResourceUsageRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        description: Optional<string>,
        name: string,
        severity: Severity,
        updateTime: string,
        allResources: Optional<boolean>,
        resourceBuiltInAttributeTypeNames: Optional<string[]>,
        resourceCustomAttributeDefinitionIds: Optional<string[]>,
        resourceIds: Optional<string[]>,
        resourceNamePattern: Optional<string>,
        resourceTags: Optional<ResourceTag[]>,
        public sensitiveKey: boolean) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKmsKeyManagementRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            description,
            name,
            severity,
            updateTime,
            allResources,
            resourceBuiltInAttributeTypeNames,
            resourceCustomAttributeDefinitionIds,
            resourceIds,
            resourceNamePattern,
            resourceTags);
    }
}

export class AwsKubernetesNamespaceResource<TKubernetesNamespaceResourceData extends KubernetesNamespaceResourceData> extends AwsKubernetesNamespaceResourceBase implements ITypeNameObject, IAwsResource, IKubernetesNamespaceResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: TKubernetesNamespaceResourceData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class AwsKubernetesNamespaceRole extends AwsKubernetesNamespaceResource<KubernetesNamespaceRoleData> implements ITypeNameObject, IAwsResource, IKubernetesNamespaceRole {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesNamespaceRoleData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKubernetesNamespaceRole",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class AwsKubernetesNamespaceRoleBinding extends AwsKubernetesNamespaceResource<KubernetesNamespaceRoleBindingData> implements ITypeNameObject, IAwsResource, IKubernetesNamespaceRoleBinding {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesNamespaceRoleBindingData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKubernetesNamespaceRoleBinding",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class AwsKubernetesNetworkPolicy extends AwsKubernetesNamespaceResource<KubernetesNetworkPolicyData> implements ITypeNameObject, IAwsResource, IKubernetesNetworkPolicy {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesNetworkPolicyData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKubernetesNetworkPolicy",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class AwsKubernetesPod extends AwsKubernetesNamespaceResource<KubernetesPodData> implements ITypeNameObject, IAwsResource, IKubernetesPod {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesPodData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKubernetesPod",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class AwsKubernetesPodTemplate extends AwsKubernetesNamespaceResource<KubernetesPodTemplateData> implements ITypeNameObject, IAwsResource, IKubernetesPodTemplate {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesPodTemplateData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKubernetesPodTemplate",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class AwsKubernetesService extends AwsKubernetesNamespaceResource<KubernetesServiceData> implements ITypeNameObject, IAwsResource, IKubernetesService {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesServiceData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKubernetesService",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class AwsKubernetesServiceAccount extends AwsKubernetesNamespaceResource<KubernetesServiceAccountData> implements ITypeNameObject, IAwsResource, IKubernetesServiceAccount {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesServiceAccountData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKubernetesServiceAccount",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class AwsKubernetesWorkloadResourceBase extends AwsKubernetesNamespaceResource<KubernetesWorkloadResourceData> implements ITypeNameObject, IAwsResource, IAwsOriginatorKubernetesResource, IKubernetesWorkloadResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesWorkloadResourceData,
        public containerImageRawUrls: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class AwsOpenShiftBuildConfig extends AwsKubernetesNamespaceResource<OpenShiftBuildConfigData> implements ITypeNameObject, IAwsResource, IOpenShiftBuildConfig {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: OpenShiftBuildConfigData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsOpenShiftBuildConfig",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class AwsOpenShiftCatalogSource extends AwsKubernetesNamespaceResource<OpenShiftCatalogSourceData> implements ITypeNameObject, IAwsResource, IOpenShiftCatalogSource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: OpenShiftCatalogSourceData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsOpenShiftCatalogSource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class AwsOpenShiftImageStream extends AwsKubernetesNamespaceResource<OpenShiftImageStreamData> implements ITypeNameObject, IAwsResource, IOpenShiftImageStream {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: OpenShiftImageStreamData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsOpenShiftImageStream",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class AwsOpenShiftRoute extends AwsKubernetesNamespaceResource<OpenShiftRouteData> implements ITypeNameObject, IAwsResource, IOpenShiftRoute {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: OpenShiftRouteData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsOpenShiftRoute",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class AwsOpenShiftTemplate extends AwsKubernetesNamespaceResource<OpenShiftTemplateData> implements ITypeNameObject, IAwsResource, IOpenShiftTemplate {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: OpenShiftTemplateData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsOpenShiftTemplate",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class AzureKubernetesNamespaceResource<TKubernetesNamespaceResourceData extends KubernetesNamespaceResourceData> extends AzureKubernetesNamespaceResourceBase implements ITypeNameObject, IAzureEntity, IKubernetesNamespaceResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: TKubernetesNamespaceResourceData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class AzureKubernetesNamespaceRole extends AzureKubernetesNamespaceResource<KubernetesNamespaceRoleData> implements ITypeNameObject, IAzureEntity, IKubernetesNamespaceRole {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesNamespaceRoleData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKubernetesNamespaceRole",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class AzureKubernetesNamespaceRoleBinding extends AzureKubernetesNamespaceResource<KubernetesNamespaceRoleBindingData> implements ITypeNameObject, IAzureEntity, IKubernetesNamespaceRoleBinding {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesNamespaceRoleBindingData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKubernetesNamespaceRoleBinding",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class AzureKubernetesNetworkPolicy extends AzureKubernetesNamespaceResource<KubernetesNetworkPolicyData> implements ITypeNameObject, IAzureEntity, IKubernetesNetworkPolicy {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesNetworkPolicyData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKubernetesNetworkPolicy",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class AzureKubernetesPod extends AzureKubernetesNamespaceResource<KubernetesPodData> implements ITypeNameObject, IAzureEntity, IKubernetesPod {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesPodData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKubernetesPod",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class AzureKubernetesPodTemplate extends AzureKubernetesNamespaceResource<KubernetesPodTemplateData> implements ITypeNameObject, IAzureEntity, IKubernetesPodTemplate {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesPodTemplateData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKubernetesPodTemplate",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class AzureKubernetesService extends AzureKubernetesNamespaceResource<KubernetesServiceData> implements ITypeNameObject, IAzureEntity, IKubernetesService {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesServiceData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKubernetesService",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class AzureKubernetesServiceAccount extends AzureKubernetesNamespaceResource<KubernetesServiceAccountData> implements ITypeNameObject, IAzureEntity, IKubernetesServiceAccount {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesServiceAccountData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKubernetesServiceAccount",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class AzureKubernetesWorkloadResourceBase extends AzureKubernetesNamespaceResource<KubernetesWorkloadResourceData> implements ITypeNameObject, IAzureOriginatorKubernetesResource, IKubernetesWorkloadResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesWorkloadResourceData,
        public containerImageRawUrls: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class AzureOpenShiftBuildConfig extends AzureKubernetesNamespaceResource<OpenShiftBuildConfigData> implements ITypeNameObject, IAzureEntity, IOpenShiftBuildConfig {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: OpenShiftBuildConfigData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureOpenShiftBuildConfig",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class AzureOpenShiftCatalogSource extends AzureKubernetesNamespaceResource<OpenShiftCatalogSourceData> implements ITypeNameObject, IAzureEntity, IOpenShiftCatalogSource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: OpenShiftCatalogSourceData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureOpenShiftCatalogSource",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class AzureOpenShiftImageStream extends AzureKubernetesNamespaceResource<OpenShiftImageStreamData> implements ITypeNameObject, IAzureEntity, IOpenShiftImageStream {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: OpenShiftImageStreamData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureOpenShiftImageStream",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class AzureOpenShiftRoute extends AzureKubernetesNamespaceResource<OpenShiftRouteData> implements ITypeNameObject, IAzureEntity, IOpenShiftRoute {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: OpenShiftRouteData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureOpenShiftRoute",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class AzureOpenShiftTemplate extends AzureKubernetesNamespaceResource<OpenShiftTemplateData> implements ITypeNameObject, IAzureEntity, IOpenShiftTemplate {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: OpenShiftTemplateData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureOpenShiftTemplate",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class GcpKubernetesConfigMap extends GcpKubernetesNamespaceResource<KubernetesConfigMapData> implements ITypeNameObject, IKubernetesConfigMap {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesConfigMapData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpKubernetesConfigMap",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class GcpKubernetesIngress extends GcpKubernetesNamespaceResource<KubernetesIngressData> implements ITypeNameObject, IKubernetesIngress {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesIngressData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpKubernetesIngress",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class GcpKubernetesWorkloadResource<TKubernetesWorkloadResourceData extends KubernetesWorkloadResourceData> extends GcpKubernetesWorkloadResourceBase implements ITypeNameObject, IGcpServiceAccountOriginatorKubernetesResource, IKubernetesWorkloadResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: TKubernetesWorkloadResourceData,
        containerImageRawUrls: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data,
            containerImageRawUrls);
    }
}

export class GcpOpenShiftDeploymentConfig extends GcpKubernetesWorkloadResource<OpenShiftDeploymentConfigData> implements ITypeNameObject, IGcpServiceAccountOriginatorKubernetesResource, IOpenShiftDeploymentConfig {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: OpenShiftDeploymentConfigData,
        containerImageRawUrls: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpOpenShiftDeploymentConfig",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data,
            containerImageRawUrls);
    }
}

export class KubernetesAdmissionControllerWorkloadResourceContainerCapabilityRiskPolicyConfiguration extends KubernetesAdmissionControllerWorkloadResourceContainerRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument, IKubernetesAdmissionControllerRiskPolicyConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        effect: Optional<KubernetesAdmissionControllerRiskPolicyEffect>,
        rawResourceTypeNames: Optional<string[]>,
        resourceOperations: KubernetesAdmissionControllerResourceOperation[],
        systemNamespacesEnabled: boolean,
        rawResourcePluralTypeNames: string[],
        public allowedCapabilities: Optional<string[]>,
        public exemptImagePatterns: string[],
        public requiredDropCapabilities: Optional<string[]>) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesAdmissionControllerWorkloadResourceContainerCapabilityRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            effect,
            rawResourceTypeNames,
            resourceOperations,
            systemNamespacesEnabled,
            rawResourcePluralTypeNames);
    }
}

export class KubernetesAdmissionControllerWorkloadResourceContainerImageAllowedRepositoriesRiskPolicyConfiguration extends KubernetesAdmissionControllerWorkloadResourceContainerRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument, IKubernetesAdmissionControllerRiskPolicyConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        effect: Optional<KubernetesAdmissionControllerRiskPolicyEffect>,
        rawResourceTypeNames: Optional<string[]>,
        resourceOperations: KubernetesAdmissionControllerResourceOperation[],
        systemNamespacesEnabled: boolean,
        rawResourcePluralTypeNames: string[],
        public patterns: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesAdmissionControllerWorkloadResourceContainerImageAllowedRepositoriesRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            effect,
            rawResourceTypeNames,
            resourceOperations,
            systemNamespacesEnabled,
            rawResourcePluralTypeNames);
    }
}

export class KubernetesAdmissionControllerWorkloadResourceContainerImageDigestNotExistsRiskPolicyConfiguration extends KubernetesAdmissionControllerWorkloadResourceContainerRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument, IKubernetesAdmissionControllerRiskPolicyConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        effect: Optional<KubernetesAdmissionControllerRiskPolicyEffect>,
        rawResourceTypeNames: Optional<string[]>,
        resourceOperations: KubernetesAdmissionControllerResourceOperation[],
        systemNamespacesEnabled: boolean,
        rawResourcePluralTypeNames: string[],
        public exemptImagePatterns: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesAdmissionControllerWorkloadResourceContainerImageDigestNotExistsRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            effect,
            rawResourceTypeNames,
            resourceOperations,
            systemNamespacesEnabled,
            rawResourcePluralTypeNames);
    }
}

export class KubernetesAdmissionControllerWorkloadResourceContainerImageNotAllowedRepositoriesRiskPolicyConfiguration extends KubernetesAdmissionControllerWorkloadResourceContainerRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument, IKubernetesAdmissionControllerRiskPolicyConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        effect: Optional<KubernetesAdmissionControllerRiskPolicyEffect>,
        rawResourceTypeNames: Optional<string[]>,
        resourceOperations: KubernetesAdmissionControllerResourceOperation[],
        systemNamespacesEnabled: boolean,
        rawResourcePluralTypeNames: string[],
        public patterns: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesAdmissionControllerWorkloadResourceContainerImageNotAllowedRepositoriesRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            effect,
            rawResourceTypeNames,
            resourceOperations,
            systemNamespacesEnabled,
            rawResourcePluralTypeNames);
    }
}

export class KubernetesAdmissionControllerWorkloadResourceContainerPrivilegedRiskPolicyConfiguration extends KubernetesAdmissionControllerWorkloadResourceContainerRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument, IKubernetesAdmissionControllerRiskPolicyConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        effect: Optional<KubernetesAdmissionControllerRiskPolicyEffect>,
        rawResourceTypeNames: Optional<string[]>,
        resourceOperations: KubernetesAdmissionControllerResourceOperation[],
        systemNamespacesEnabled: boolean,
        rawResourcePluralTypeNames: string[],
        public exemptImagePatterns: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesAdmissionControllerWorkloadResourceContainerPrivilegedRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            effect,
            rawResourceTypeNames,
            resourceOperations,
            systemNamespacesEnabled,
            rawResourcePluralTypeNames);
    }
}

export class KubernetesAdmissionControllerWorkloadResourceContainerPrivilegeEscalationRiskPolicyConfiguration extends KubernetesAdmissionControllerWorkloadResourceContainerRiskPolicyConfiguration implements IDocument, ITypeNameObject, ILicensingDocument, IKubernetesAdmissionControllerRiskPolicyConfiguration {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        scopeId: string,
        codeEnabled: Optional<boolean>,
        codeExclusions: RiskPolicyConfigurationCodeExclusion[],
        creationTime: string,
        enabled: Optional<boolean>,
        entityCreationTimeAnalysisDelayTimeFrame: Optional<string>,
        entityTypeNameToExclusionsMap: Dictionary<RiskPolicyConfigurationEntityExclusion>,
        effect: Optional<KubernetesAdmissionControllerRiskPolicyEffect>,
        rawResourceTypeNames: Optional<string[]>,
        resourceOperations: KubernetesAdmissionControllerResourceOperation[],
        systemNamespacesEnabled: boolean,
        rawResourcePluralTypeNames: string[],
        public exemptImagePatterns: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "KubernetesAdmissionControllerWorkloadResourceContainerPrivilegeEscalationRiskPolicyConfiguration",
            scopeId,
            codeEnabled,
            codeExclusions,
            creationTime,
            enabled,
            entityCreationTimeAnalysisDelayTimeFrame,
            entityTypeNameToExclusionsMap,
            effect,
            rawResourceTypeNames,
            resourceOperations,
            systemNamespacesEnabled,
            rawResourcePluralTypeNames);
    }
}

export class OpKubernetesConfigMap extends OpKubernetesNamespaceResource<KubernetesConfigMapData> implements ITypeNameObject, IKubernetesConfigMap {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesConfigMapData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpKubernetesConfigMap",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class OpKubernetesIngress extends OpKubernetesNamespaceResource<KubernetesIngressData> implements ITypeNameObject, IKubernetesIngress {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesIngressData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpKubernetesIngress",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class OpKubernetesWorkloadResource<TKubernetesWorkloadResourceData extends KubernetesWorkloadResourceData> extends OpKubernetesWorkloadResourceBase implements ITypeNameObject, IKubernetesWorkloadResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: TKubernetesWorkloadResourceData,
        containerImageRawUrls: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data,
            containerImageRawUrls);
    }
}

export class OpOpenShiftDeploymentConfig extends OpKubernetesWorkloadResource<OpenShiftDeploymentConfigData> implements ITypeNameObject, IOpenShiftDeploymentConfig {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: OpenShiftDeploymentConfigData,
        containerImageRawUrls: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpOpenShiftDeploymentConfig",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data,
            containerImageRawUrls);
    }
}

export class AwsKubernetesConfigMap extends AwsKubernetesNamespaceResource<KubernetesConfigMapData> implements ITypeNameObject, IAwsResource, IKubernetesConfigMap {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesConfigMapData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKubernetesConfigMap",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class AwsKubernetesIngress extends AwsKubernetesNamespaceResource<KubernetesIngressData> implements ITypeNameObject, IAwsResource, IKubernetesIngress {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesIngressData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKubernetesIngress",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class AwsKubernetesWorkloadResource<TKubernetesWorkloadResourceData extends KubernetesWorkloadResourceData> extends AwsKubernetesWorkloadResourceBase implements ITypeNameObject, IAwsResource, IAwsOriginatorKubernetesResource, IKubernetesWorkloadResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: TKubernetesWorkloadResourceData,
        containerImageRawUrls: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data,
            containerImageRawUrls);
    }
}

export class AwsOpenShiftDeploymentConfig extends AwsKubernetesWorkloadResource<OpenShiftDeploymentConfigData> implements ITypeNameObject, IAwsResource, IAwsOriginatorKubernetesResource, IOpenShiftDeploymentConfig {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: OpenShiftDeploymentConfigData,
        containerImageRawUrls: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsOpenShiftDeploymentConfig",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data,
            containerImageRawUrls);
    }
}

export class AzureKubernetesConfigMap extends AzureKubernetesNamespaceResource<KubernetesConfigMapData> implements ITypeNameObject, IAzureEntity, IKubernetesConfigMap {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesConfigMapData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKubernetesConfigMap",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class AzureKubernetesIngress extends AzureKubernetesNamespaceResource<KubernetesIngressData> implements ITypeNameObject, IAzureEntity, IKubernetesIngress {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesIngressData) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKubernetesIngress",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data);
    }
}

export class AzureKubernetesWorkloadResource<TKubernetesWorkloadResourceData extends KubernetesWorkloadResourceData> extends AzureKubernetesWorkloadResourceBase implements ITypeNameObject, IAzureOriginatorKubernetesResource, IKubernetesWorkloadResource {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        typeName: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: TKubernetesWorkloadResourceData,
        containerImageRawUrls: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            typeName,
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data,
            containerImageRawUrls);
    }
}

export class AzureOpenShiftDeploymentConfig extends AzureKubernetesWorkloadResource<OpenShiftDeploymentConfigData> implements ITypeNameObject, IAzureOriginatorKubernetesResource, IOpenShiftDeploymentConfig {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: OpenShiftDeploymentConfigData,
        containerImageRawUrls: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureOpenShiftDeploymentConfig",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data,
            containerImageRawUrls);
    }
}

export class GcpKubernetesCronJob extends GcpKubernetesWorkloadResource<KubernetesCronJobData> implements ITypeNameObject, IGcpServiceAccountOriginatorKubernetesResource, IKubernetesCronJob {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesCronJobData,
        containerImageRawUrls: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpKubernetesCronJob",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data,
            containerImageRawUrls);
    }
}

export class GcpKubernetesDaemonSet extends GcpKubernetesWorkloadResource<KubernetesDaemonSetData> implements ITypeNameObject, IGcpServiceAccountOriginatorKubernetesResource, IKubernetesDaemonSet {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesDaemonSetData,
        containerImageRawUrls: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpKubernetesDaemonSet",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data,
            containerImageRawUrls);
    }
}

export class GcpKubernetesDeployment extends GcpKubernetesWorkloadResource<KubernetesDeploymentData> implements ITypeNameObject, IGcpServiceAccountOriginatorKubernetesResource, IKubernetesDeployment {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesDeploymentData,
        containerImageRawUrls: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpKubernetesDeployment",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data,
            containerImageRawUrls);
    }
}

export class GcpKubernetesJob extends GcpKubernetesWorkloadResource<KubernetesJobData> implements ITypeNameObject, IGcpServiceAccountOriginatorKubernetesResource, IKubernetesJob {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesJobData,
        containerImageRawUrls: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpKubernetesJob",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data,
            containerImageRawUrls);
    }
}

export class GcpKubernetesReplicaSet extends GcpKubernetesWorkloadResource<KubernetesReplicaSetData> implements ITypeNameObject, IGcpServiceAccountOriginatorKubernetesResource, IKubernetesReplicaSet {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesReplicaSetData,
        containerImageRawUrls: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpKubernetesReplicaSet",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data,
            containerImageRawUrls);
    }
}

export class GcpKubernetesStatefulSet extends GcpKubernetesWorkloadResource<KubernetesStatefulSetData> implements ITypeNameObject, IGcpServiceAccountOriginatorKubernetesResource, IKubernetesStatefulSet {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesStatefulSetData,
        containerImageRawUrls: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "GcpKubernetesStatefulSet",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data,
            containerImageRawUrls);
    }
}

export class OpKubernetesCronJob extends OpKubernetesWorkloadResource<KubernetesCronJobData> implements ITypeNameObject, IKubernetesCronJob {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesCronJobData,
        containerImageRawUrls: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpKubernetesCronJob",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data,
            containerImageRawUrls);
    }
}

export class OpKubernetesDaemonSet extends OpKubernetesWorkloadResource<KubernetesDaemonSetData> implements ITypeNameObject, IKubernetesDaemonSet {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesDaemonSetData,
        containerImageRawUrls: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpKubernetesDaemonSet",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data,
            containerImageRawUrls);
    }
}

export class OpKubernetesDeployment extends OpKubernetesWorkloadResource<KubernetesDeploymentData> implements ITypeNameObject, IKubernetesDeployment {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesDeploymentData,
        containerImageRawUrls: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpKubernetesDeployment",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data,
            containerImageRawUrls);
    }
}

export class OpKubernetesJob extends OpKubernetesWorkloadResource<KubernetesJobData> implements ITypeNameObject, IKubernetesJob {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesJobData,
        containerImageRawUrls: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpKubernetesJob",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data,
            containerImageRawUrls);
    }
}

export class OpKubernetesReplicaSet extends OpKubernetesWorkloadResource<KubernetesReplicaSetData> implements ITypeNameObject, IKubernetesReplicaSet {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesReplicaSetData,
        containerImageRawUrls: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpKubernetesReplicaSet",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data,
            containerImageRawUrls);
    }
}

export class OpKubernetesStatefulSet extends OpKubernetesWorkloadResource<KubernetesStatefulSetData> implements ITypeNameObject, IKubernetesStatefulSet {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        updateTime: Optional<string>,
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesStatefulSetData,
        containerImageRawUrls: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "OpKubernetesStatefulSet",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            name,
            tagExists,
            tags,
            updateTime,
            clusterDisplayReference,
            clusterId,
            data,
            containerImageRawUrls);
    }
}

export class AwsKubernetesCronJob extends AwsKubernetesWorkloadResource<KubernetesCronJobData> implements ITypeNameObject, IAwsResource, IAwsOriginatorKubernetesResource, IKubernetesCronJob {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesCronJobData,
        containerImageRawUrls: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKubernetesCronJob",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data,
            containerImageRawUrls);
    }
}

export class AwsKubernetesDaemonSet extends AwsKubernetesWorkloadResource<KubernetesDaemonSetData> implements ITypeNameObject, IAwsResource, IAwsOriginatorKubernetesResource, IKubernetesDaemonSet {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesDaemonSetData,
        containerImageRawUrls: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKubernetesDaemonSet",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data,
            containerImageRawUrls);
    }
}

export class AwsKubernetesDeployment extends AwsKubernetesWorkloadResource<KubernetesDeploymentData> implements ITypeNameObject, IAwsResource, IAwsOriginatorKubernetesResource, IKubernetesDeployment {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesDeploymentData,
        containerImageRawUrls: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKubernetesDeployment",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data,
            containerImageRawUrls);
    }
}

export class AwsKubernetesJob extends AwsKubernetesWorkloadResource<KubernetesJobData> implements ITypeNameObject, IAwsResource, IAwsOriginatorKubernetesResource, IKubernetesJob {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesJobData,
        containerImageRawUrls: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKubernetesJob",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data,
            containerImageRawUrls);
    }
}

export class AwsKubernetesReplicaSet extends AwsKubernetesWorkloadResource<KubernetesReplicaSetData> implements ITypeNameObject, IAwsResource, IAwsOriginatorKubernetesResource, IKubernetesReplicaSet {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesReplicaSetData,
        containerImageRawUrls: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKubernetesReplicaSet",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data,
            containerImageRawUrls);
    }
}

export class AwsKubernetesStatefulSet extends AwsKubernetesWorkloadResource<KubernetesStatefulSetData> implements ITypeNameObject, IAwsResource, IAwsOriginatorKubernetesResource, IKubernetesStatefulSet {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        arn: Optional<string>,
        crossTenantPermissionActionPossible: boolean,
        name: Optional<string>,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesStatefulSetData,
        containerImageRawUrls: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AwsKubernetesStatefulSet",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            arn,
            crossTenantPermissionActionPossible,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data,
            containerImageRawUrls);
    }
}

export class AzureKubernetesCronJob extends AzureKubernetesWorkloadResource<KubernetesCronJobData> implements ITypeNameObject, IAzureOriginatorKubernetesResource, IKubernetesCronJob {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesCronJobData,
        containerImageRawUrls: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKubernetesCronJob",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data,
            containerImageRawUrls);
    }
}

export class AzureKubernetesDaemonSet extends AzureKubernetesWorkloadResource<KubernetesDaemonSetData> implements ITypeNameObject, IAzureOriginatorKubernetesResource, IKubernetesDaemonSet {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesDaemonSetData,
        containerImageRawUrls: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKubernetesDaemonSet",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data,
            containerImageRawUrls);
    }
}

export class AzureKubernetesDeployment extends AzureKubernetesWorkloadResource<KubernetesDeploymentData> implements ITypeNameObject, IAzureOriginatorKubernetesResource, IKubernetesDeployment {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesDeploymentData,
        containerImageRawUrls: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKubernetesDeployment",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data,
            containerImageRawUrls);
    }
}

export class AzureKubernetesJob extends AzureKubernetesWorkloadResource<KubernetesJobData> implements ITypeNameObject, IAzureOriginatorKubernetesResource, IKubernetesJob {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesJobData,
        containerImageRawUrls: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKubernetesJob",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data,
            containerImageRawUrls);
    }
}

export class AzureKubernetesReplicaSet extends AzureKubernetesWorkloadResource<KubernetesReplicaSetData> implements ITypeNameObject, IAzureOriginatorKubernetesResource, IKubernetesReplicaSet {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesReplicaSetData,
        containerImageRawUrls: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKubernetesReplicaSet",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data,
            containerImageRawUrls);
    }
}

export class AzureKubernetesStatefulSet extends AzureKubernetesWorkloadResource<KubernetesStatefulSetData> implements ITypeNameObject, IAzureOriginatorKubernetesResource, IKubernetesStatefulSet {
    constructor(
        id: string,
        systemCreationTime: string,
        systemDeleted: boolean,
        systemDeletionTime: Optional<string>,
        systemUpdateTime: string,
        tenantId: string,
        displayName: string,
        displayReference: string,
        regionId: Optional<string>,
        scopeIds: string[],
        syncTime: string,
        path: Optional<string>,
        rawPath: Optional<string>,
        name: string,
        tagExists: boolean,
        tags: ResourceTag[],
        clusterDisplayReference: string,
        clusterId: string,
        public data: KubernetesStatefulSetData,
        containerImageRawUrls: string[]) {
        super(
            id,
            systemCreationTime,
            systemDeleted,
            systemDeletionTime,
            systemUpdateTime,
            "AzureKubernetesStatefulSet",
            tenantId,
            displayName,
            displayReference,
            regionId,
            scopeIds,
            syncTime,
            path,
            rawPath,
            name,
            tagExists,
            tags,
            clusterDisplayReference,
            clusterId,
            data,
            containerImageRawUrls);
    }
}